import { Component } from 'react';
import { InfoCircleOutlined } from "@ant-design/icons";

import { FileUploadContextManager, FileUploadError, FileUploadIconButton, FileUploadList } from 'components/Shared/FileUpload';
import { ZeroContext } from 'components/ZeroContext.js';
import Previewer from "../Previewer";
import ConfirmModal from "../Shared/ConfirmModal";

export default class FileUploadsField extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);
        
        /** @type {ZeroContext} */
        this.context;

        this.state = {
            fileListCount: 0,
            previewVisible: false,
            previewIndex: null,
            showDeleteConfirmation: false,
            deleteIndex: null,
            canEdit: props.is_builder,
        };
    }

    generateParamsCallback = file => ({
        feature_key: 'forms_template_field',
        form_uuid: this.props.form_uuid,
        field_uuid: this.props.field.id,
        content_type: file.type,
        file_name: file.name,
        embedded: false
    })

    onUploadSuccess = (file, pre_signed_url, file_key) => {
        const newAttachment = {
            file_path: file_key,
            file_name: file.name,
            public_url: pre_signed_url + "/" + file_key,
            mime_type: file.type,
        }

        this.props.onFileUpload?.([
            ...this.props.field.attachments,
            newAttachment
        ]);
    }

    onUploadError = (error) => {
        console.error(error);
    }

    onFileListChange = (fileList) => {
        this.setState({
            fileListCount: fileList.length
        });
    }

    handleRemove = (index) => {
        if (!this.state.canEdit || this.state.showDeleteConfirmation) return;

        this.setState({deleteIndex: index, showDeleteConfirmation: true});
    }

    deleteAttachment = () => {
        if (!this.state.canEdit) return;

        if (this.props.onFileUpload) {
            const attachments = [...this.props.field.attachments];
            attachments.splice(this.state.deleteIndex, 1);
            this.props.onFileUpload(attachments);
        }

        this.setState({deleteIndex: null, showDeleteConfirmation: false})
    }

    renderAttachments = () => {
        const attachments = this.props.field.attachments;

        if (attachments.length === 0 && this.state.fileListCount === 0) {
            return (
                <div className="zero-dark-grey mar-top-5">
                    <InfoCircleOutlined/>{" "}
                    { this.props.is_builder
                        ? <>This field type allows users to view files uploaded by you. It does not allow them upload files. To upload a file, click on the paperclip icon above.</>
                        : <>No files.</>
                    }
                </div>
            );
        }

        return (
            <>
                <FileUploadList
                    className="mar-top-10"
                    blobs={{...this.context.caches.submissionDrafts.blobs, ...this.context.caches.formsCache.blobs}}
                    onPreview={(index) => this.setState({previewVisible: true, previewIndex: index})}
                    onDelete={this.state.canEdit ? (index) => this.handleRemove(index) : null}
                />
                {this.props.is_builder &&
                    <div className="zero-dark-grey">
                        <InfoCircleOutlined/> This field type allows users to view files uploaded by you. It does not allow them to upload files.
                    </div>
                }
            </>
        );
    }

    renderUploader = () => {
        const {is_builder} = this.props;
        const {canEdit} = this.state;

        if (!is_builder) return null;

        return (
            <FileUploadIconButton
                hideWhenDragging={false}
                uploadProps={{
                    disabled: !canEdit
                }}
            />
        );
    }

    render() {
        const attachments = this.props.field.attachments;
        const {previewVisible, previewIndex, showDeleteConfirmation} = this.state;

        return (
            <FileUploadContextManager
                attachments={attachments}
                generateParamsCallback={this.generateParamsCallback}
                onUploadSuccess={this.onUploadSuccess}
                onUploadError={this.onUploadError}
                options={{
                    onFileListChange: this.onFileListChange
                }}
            >
                <div className="submission-attachments-field" style={{position: 'relative'}}>
                    {showDeleteConfirmation &&
                        <ConfirmModal
                            show={showDeleteConfirmation}
                            cancel={() => {
                                this.setState({showDeleteConfirmation: false, deleteIndex: null})
                            }}
                            confirm={this.deleteAttachment}
                            title={"Confirmation"}
                            body={"Are you sure you want to delete this attachment?"}
                            confirmButtonName={"Delete"}
                        />
                    }
                    {previewVisible &&
                        <Previewer
                            show={previewVisible}
                            close={() => {
                                this.setState({previewVisible: false})
                            }}
                            attachments={attachments}
                            index={previewIndex}
                        />
                    }
                    <div style={{fontWeight: 600, fontSize: 14, marginBottom: '1rem'}}>{this.props.field.content}</div>
                    {
                        this.renderUploader()
                    }
                    <FileUploadError />
                    {
                        this.renderAttachments()
                    }
                </div>
            </FileUploadContextManager>
        )
    }
}
