import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    delete_comment,
    edit_comment,
    get_comments,
    notifyError,
    post_notification_report
} from '../../api/zero-api.js';
import {
    dateFormatterNoTime,
    dateFormatterWithTime,
    fieldIsNotEmpty,
    getFileThumbnail,
    isAdminOrTeamLead,
    isImage,
    isObjEmpty,
    isVideo,
    isViewer,
    postImageContain,
    safeProfilePic
} from '../../other/Helper.js';
import safe_get from '../../other/SafeGet';

import linkifyHtml from 'linkify-html';
import moment from 'moment';

import Reactions from './Reactions';

import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloudDownloadOutlined,
    EditOutlined,
    ExportOutlined,
    MessageOutlined,
    PlusCircleOutlined,
    ShareAltOutlined,
    ToolOutlined,
    UserAddOutlined, UserDeleteOutlined
} from '@ant-design/icons';

import { Popover } from 'antd';

import LazyLoad from 'react-lazy-load';
import Skeleton from 'react-loading-skeleton';

import Previewer from '../Previewer';
import ButtonLoading from '../Shared/ButtonLoading.js';
import ConfirmModal from '../Shared/ConfirmModal.js';

import NotificationAlert from '../../other/NotificationAlert.js';

import $ from 'jquery';
import UserPopoverCard from '../Shared/UserPopoverCard.js';

import CommentDiff from 'components/Shared/CommentDiff.js';
import AttachmentCommentBox from 'components/Shared/NewAttachmentCommentBox.js';
import { buildDiffs } from 'components/Shared/SubmissionEditDiff.js';
import VideoThumbnail from 'components/Shared/VideoThumbnail.js';
import PostNotificationReportModal from './PostNotificationReportModal';


function extractPostCommentValue(text) {
    if (text.split(">").length > 1) {
        return text.split(">")[1].split("<")[0]
    } else {
        return text;
    }
}


function buildPostDiffs(comment, org) {
    const diffs = [];

    const before = safe_get(comment, "info.before_update", {});
    const after = safe_get(comment, "info.after_update", {});

    const titleMap = {
        title: org.post_field_names.title,
        body: org.post_field_names.body,
        category: org.post_field_names.category,
        sub_status: org.post_field_names.sub_status,
        sub_status_2: org.post_field_names.sub_status_2,
        tag: org.post_field_names.tag,
        due_date: "Due date",
        custom_field_value: org.custom_post_field.name,
        severity_level: org.post_field_names.severity_level,
        location: org.post_field_names.location,
        attachments: "Attachments",
    }

    for (const id of Object.keys(titleMap)) {
        let oldValue = before[id];
        let newValue = after[id];

        if (oldValue === undefined || newValue === undefined) {
            continue;
        }

        if (id === "body") {
            oldValue = <div dangerouslySetInnerHTML={{__html: oldValue}} />;
            newValue = <div dangerouslySetInnerHTML={{__html: newValue}} />;
        }

        let valueTransform = (value) => value;
        if (["category", "tag", "sub_status", "sub_status_2", "severity_level"].includes(id)) {
            valueTransform = extractPostCommentValue;
        } else if (id === "attachments") {
            valueTransform = (value) => <>Attachments: {value?.total_count ?? 0}</>;
        } else if (["location", "custom_field_value"].includes(id)) {
            valueTransform = (value) => value || "None";
        } else if (id === "due_date") {
            valueTransform = (value) => value ? moment.unix(value).format("MM/DD/YYYY") : "None";
        }

        diffs.push({
            key: id,
            oldLabel: titleMap[id],
            oldValue: valueTransform(oldValue),
            newLabel: titleMap[id],
            newValue: valueTransform(newValue),
        })
    }

    return diffs;
}

class PostComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reactions: [],
            attachmentsLoading: true,
            attachments: [],
            originalCommentAttachments: [],
            fileList: [],
            s3: {},
            fileIsUploading: false,
            show_updated_comment: false,
            showPostNotificationReportModal: false,
            diffs: null,
        };

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.commentInnerHTML = this.commentInnerHTML.bind(this);
        this.cancelCommentEdit = this.cancelCommentEdit.bind(this);

        this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);

        this.getUserUuid = this.getUserUuid.bind(this);
        this.getTeamRoute = this.getTeamRoute.bind(this);
    }

    generateCommentDiffs = () => {
        const formFields = this.props.post?.embedded_forms?.[0]?.form_fields ?? [];

        if (this.props.comment.comment_type === "updated") {
            let diffs = buildPostDiffs(this.props.comment, this.props.org);
            if (this.props.comment.submission_diffs && formFields.length > 0) {
                diffs = diffs.concat(buildDiffs(this.props.comment.submission_diffs, {fields: formFields}));
            }
            this.setState({diffs});
        } else if (this.props.comment.comment_type === "submission_edited" && formFields.length > 0) {
            this.setState({
                diffs: buildDiffs(this.props.comment.data.diffs, {fields: formFields})
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.comment.reactions?.length !== this.props.comment.reactions?.length || prevProps.updateRender !== this.props.updateRender) {
            this.setState({reactions: this.props.comment.reactions});
        }

        if (prevProps.comment !== this.props.comment) {
            this.generateCommentDiffs();
        }
    }

    componentDidMount() {
        this.generateCommentDiffs();
        this.setState({
            attachments: safe_get(this.props, "comment.attachments", []),
            originalCommentAttachments: safe_get(this.props, "comment.attachments", []),
            reactions: safe_get(this.props, "comment.reactions", []),
        });
    }

    dateFormatter(post_date) {
        var month = "";
        var day = "";
        var year = "";
        var time = "";

        if (!isNaN(parseFloat(post_date))) {
            var revised = new Date(post_date * 1000);
            var revised_date = revised.toDateString();
            var formatted_date = revised_date.split(" ");

            month = formatted_date[1];
            day = formatted_date[2];
            year = formatted_date[3];
            time = revised.toLocaleTimeString();
        } else {
            var new_date = post_date.toDateString().split(" ");
            month = new_date[1];
            day = new_date[2];
            year = new_date[3];
            time = post_date.toLocaleTimeString();
        }
        return month + " " + day + ", " + year + " at " + time.replace(/:\d+ /, ' ');
    }

    commentInnerHTML(comment) {
        let type = safe_get(comment, "comment_type", "");
        let text = safe_get(comment, "text", "");
        let info = safe_get(comment, "info", {})
        var content = "";

        if (type === "created") {
            return {__html: text}
        } else if (type === "close") {
            content = text
            return {__html: content}
        } else if (type === "reopen") {
            content = text
            return {__html: content}
        } else if (type === "moved") {
            return {__html: text}
        } else if (type === "shared") {
            content = "<p class='post-comment'>" + text + "</p>"
            return {__html: linkifyHtml(content)};
        } else if (type === "comment") {
            content = text
            if (comment.deleted_at) {
                content = "<p class='post-comment'>Comment was deleted by " + comment.created_by.first_name + " " + comment.created_by.last_name + "</p>"
            }
            return {__html: linkifyHtml(content)};
        } else if (type === "due_date") {
            content = `<p class='post-comment'>${text}</p>`
            return {__html: linkifyHtml(content)};
        } else if (type === "assign_users") {
            content = "<span class='fw-600'>" + text + "</span>"
            return {__html: linkifyHtml(content)};
        } else if (type === "remove_assigned_users" || type === "unassign_users") {
            content = "<span class='fw-600'>" + text + "</span>"
            return {__html: linkifyHtml(content)};
        }
            // else if (type === "updated" && !isObjEmpty(info)) {
            //     if (safe_get(info, "before"))
            //     content = "<div style='display: flex;'><div style='width: 49%; border-right: 1px solid #ECECEC;'><p class='post-comment-edit' style='padding-right: 10px;'><span>Previous Version:</span><br/> " + safe_get(info, "before_update.body","") + "</p></div><div style='width: 49%; padding-left: 10px;'><p class='post-comment-edit'><span>After Update:</span><br/>" + safe_get(info, "after_update.body","") + "</p></div></div>"
            //     return {__html: linkifyHtml(content)}
        // }
        else if (type === "exported" && !isObjEmpty(info)) {
            let url = safe_get(info, "url", null);
            content = '';
            if (url) {
                content = "<a class='link-hover zero-light-blue' href=\"" + url + "\">Download link</a>";
            }
            return {__html: linkifyHtml(content)};
        }
    }

    showAttachment(file, index) {
        var file_name = safe_get(file, "file_name", "");
        var thumbnail = getFileThumbnail(file);

        if (isVideo(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <VideoThumbnail
                        imgSrc={thumbnail}
                        onPlay={() => {
                            this.handleFilePreviewer(file, index);
                        }}
                    />
                </li>
            )
        } else if (isImage(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <button className="ButtonLink" onClick={() => {
                        this.handleFilePreviewer(file, index);
                    }}>
                        <img className="post-img" src={thumbnail} alt={file_name} style={{
                            objectFit: 'contain',
                            width: "100px",
                            height: "100px",
                            borderRadius: "2px",
                            backgroundColor: "#f6f6f6",
                            border: "1px solid #e5e5e5"
                        }}/>
                    </button>
                </li>
            )
        } else {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", height: "100px", width: "100px"}}>
                    <button className="ButtonLink" style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "2px",
                        backgroundColor: "#f6f6f6",
                        border: "1px solid #e5e5e5",
                        position: "absolute"
                    }} onClick={() => {
                        this.handleFilePreviewer(file, index);
                    }}>
                        <img src={thumbnail} alt={file_name} style={{
                            objectFit: 'cover',
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            display: "block",
                            margin: "0 auto"
                        }}/>
                        <p className="zero-dark-grey ellipsis-2-lines" style={{
                            margin: "0px",
                            padding: "0px 5px 5px 5px",
                            fontSize: "80%",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                        title={file_name}
                        >{file_name}</p>
                    </button>
                </li>
            )
        }
    }

    handleFilePreviewer = (file, index) => {
        this.setState({preview_visible: true, preview_index: index});
    }

    generateParamsCallback = file =>  ({
        feature_key: "bulletin_comment",
        post_uuid: this.props.post_uuid,
        content_type: file.type,
        file_name: file.name,
        embedded: false
    })

    onUploadSuccess = (file, pre_signed_url, file_key) => {
        const newAttachment = {
            file_path: file_key,
            file_name: file.name,
            public_url: pre_signed_url + "/" + file_key,
            mime_type: file.type,
        }

        this.setState(state => ({
            attachments: [
                ...state.attachments,
                newAttachment,
            ]
        }));
    }

    onUploadError = (error) => {
        console.error(error);
    }

    handleRemoveAttachment = (index) => {
        var edit_attachments = [...this.state.attachments];
        edit_attachments.splice(index, 1);
        this.setState({attachments: edit_attachments});
    }

    handleMenuClick(e) {
        switch (e.key) {
            case "0":
                this.openDeleteModal();
                break;
            case "1":
                this.handleEditPressed();
                break;
            default:
                break;
        }
    }

    cancelModal() {
        this.setState({
            showDeleteModal: false,
        });
    }

    openDeleteModal() {
        this.setState({
            showDeleteModal: true,
        });
    }

    deleteComment() {
        var self = this;
        var post_uuid = this.props.post_uuid;
        var comment_uuid = this.props.comment.comment_uuid;

        delete_comment(post_uuid, comment_uuid).then(function (success) {
            self.cancelModal();
            // self.props.deleteComment(self.props.index)
            var comment = self.props.comment;
            comment["deleted_at"] = Date.now() / 1000;
            self.props.updateComment(self.props.index, comment)
        }, function (error) {
            self.cancelModal();
            NotificationAlert("success", "", "Unable to delete comment.")
        })
    }

    handleEditPressed = () => {
        this.setState({edit_comment: true, edit_comment_text: this.props.comment.text});

    }

    saveComment() {
        var self = this;

        var text = this.state.edit_comment_text

        if ((fieldIsNotEmpty(text) || this.state.attachments.length > 0) && !this.state.savingComment) {
            this.setState({savingComment: true, commentError: false});
            var post_uuid = this.props.post_uuid;
            var comment_uuid = this.props.comment.comment_uuid;
            var body = JSON.stringify({
                text: text,
                attachments: this.state.attachments
            });

            edit_comment(post_uuid, comment_uuid, body).then(function (success) {
                success.json().then(success => {
                    var comment = safe_get(success, "comment", {})
                    self.props.updateComment(self.props.index, comment)
                    self.setState({
                        edit_comment: false,
                        savingComment: false,
                        commentError: false
                    });
                })
            }, function (error) {
                self.setState({savingComment: false});
                notifyError(error);
            });
        } else {
            this.setState({
                savingComment: false,
                commentError: "Please provide a comment or an attachment."
            });
        }

    }

    cancelCommentEdit() {
        this.setState((state, props) => ({
            attachments: state.originalCommentAttachments,
            edit_comment: false,
            commentError: false,
        }))
    }

    getTeamRoute(text) {
        return '/' + this.props.org_uuid + '/home/team/' + text.split("\"")[1];
    }

    getUserUuid(text) {
        return "/" + this.props.org_uuid + "/users/" + text.split("\"")[1]
    }

    getUserName(text) {
        if (text.split(">").length > 1) {
            return text.split(">")[1].split("<")[0]
        } else {
            return text;
        }
    }

    generateCreationNotificationReport = async () => {
        const body = {
            post_uuid: this.props.post_uuid,
            event_types: ['created', 'created-assigned']
        };
        try {
            const response = await post_notification_report(JSON.stringify(body));
        } catch (err) {
            NotificationAlert("error", "", "Unable to generate the report.");
        } finally {
            this.setState({hasConfirmedPostNotificationReport: true});
        }
    }

    render() {
        var deleted = this.props.comment.deleted_at;

        var author_uuid = this.props.comment.created_by.user_uuid || this.props.comment.created_by.uuid;
        var isAuthor = this.props.user.uuid === author_uuid;

        var comment_type = safe_get(this.props, "comment.comment_type", "");
        var isEditableComment = (comment_type === "comment" || comment_type === "close" || comment_type === "reopen") && !deleted;

        var canEdit = isAuthor && isEditableComment && !isViewer(this.props.user);
        var canDelete = isAuthor && comment_type === "comment" && !isViewer(this.props.user) && !deleted;
        var canReact = this.props.reactions_enabled && isEditableComment && !isViewer(this.props.user);

        if (this.props.postStatus === "closed" && comment_type !== "close") {
            canEdit = false;
            canDelete = false;
        }

        let notification_count = this.props.comment.notification_count;

        return (
            <div className="timeline-entry">
                {
                    this.state.preview_visible &&
                    <Previewer
                        show={this.state.preview_visible}
                        close={() => {
                            this.setState({preview_visible: false})
                        }}
                        attachments={this.props.comment.attachments}
                        index={this.state.preview_index}
                    />
                }

                {
                    this.state.showPostNotificationReportModal &&
                    <PostNotificationReportModal
                        cancel={() => {
                            this.setState({showPostNotificationReportModal: false});
                        }}
                        confirm={this.generateCreationNotificationReport}
                        hasConfirmed={this.state.hasConfirmedPostNotificationReport}
                    />
                }

                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        show={this.state.showDeleteModal}
                        cancel={() => {
                            this.setState({showDeleteModal: false});
                        }}
                        confirm={this.deleteComment}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this comment?"}
                        confirmButtonName={"Delete"}
                    />
                }
                <div className="timeline-stat">
                    <div className="timeline-icon">
                        {
                            comment_type === "created" &&
                            <PlusCircleOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "comment" &&
                            <MessageOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "moved" &&
                            <ExportOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "close" &&
                            <CheckCircleOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "reopen" &&
                            <ToolOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "due_date" &&
                            <ClockCircleOutlined className="mar-rgt-5"/>
                        }
                        {
                            (comment_type === "updated" || comment_type === "submission_edited") &&
                            <EditOutlined className="mar-rgt-5"/>
                        }
                        {
                            (comment_type === "status_update" || comment_type === "status_2_update") &&
                            <EditOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "shared" &&
                            <ShareAltOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "assign_users" &&
                            <UserAddOutlined className="mar-rgt-5"/>
                        }
                        {
                            (comment_type === "unassign_users" || comment_type === "remove_assigned_users") &&
                            <UserDeleteOutlined className="mar-rgt-5"/>
                        }
                        {
                            comment_type === "exported" &&
                            <CloudDownloadOutlined className="mar-rgt-5"/>
                        }
                    </div>
                </div>
                <div className="timeline-label" style={{padding: "10px 8px"}}>
                    {
                        this.props.comment.deleted_at === null &&
                        <div className="media-left">
                            {safeProfilePic(safe_get(this.props, "comment.created_by", {}), "img-circle img-sm bulletin", "bulletin", {marginTop: "2px"})}
                        </div>
                    }
                    <div className="media-body bulletin2" style={{paddingBottom: '0px'}}>
                        <h3 className="author bulletin comments" style={{marginTop: "0", width: "100%"}}>
                            {
                                this.props.comment.deleted_at === null &&
                                <UserPopoverCard user={this.props.comment.created_by}>
                                    <span
                                        className="link-hover">{safe_get(this.props.comment, "created_by.first_name", "")} {safe_get(this.props.comment, "created_by.last_name", "")}</span>
                                </UserPopoverCard>
                            }
                            {
                                comment_type === "created" &&
                                <span className="post-status assigned">&nbsp;opened this post in&nbsp;
                                    <span
                                        className="zero-blue fw-600">{safe_get(this.props, "comment.info.team.team_name", "")}</span>
                                    {
                                        safe_get(this.props, "comment.info.users", []).length > 0 &&
                                        " and assigned "
                                    }
                                    {
                                        safe_get(this.props, "comment.info.users", []).length <= 3 &&
                                        safe_get(this.props, "comment.info.users", []).map((user, index) => (
                                            <span key={index}>
                                          <UserPopoverCard user_uuid={user.user_uuid}>
                                            <span className='fw-600 link-hover'>{user.user_name}</span>
                                          </UserPopoverCard>
                                                {
                                                    index + 1 === safe_get(this.props, "comment.info.users", []).length - 1 ? " and " :
                                                        index + 1 < safe_get(this.props, "comment.info.users", []).length - 1 ? ", " : ""
                                                }
                                      </span>
                                        ))
                                    }
                                    {
                                        safe_get(this.props, "comment.info.users", []).length > 3 &&
                                        safe_get(this.props, "comment.info.users", []).slice(0, 2).map((user, index) => (
                                            <span key={index}>
                                          <UserPopoverCard user_uuid={user.user_uuid}>
                                            <span className='fw-600 link-hover'>{user.user_name}</span>
                                          </UserPopoverCard>
                                                {
                                                    index === safe_get(this.props, "comment.info.users", []).slice(0, 2).length - 1 &&
                                                    <Popover trigger="click" placement="bottom"
                                                             className="custom-popover"
                                                             content={
                                                                 <div>
                                                                     {
                                                                         safe_get(this.props, "comment.info.users", []).slice(2, safe_get(this.props, "comment.info.users", []).length).map((user, index) => (
                                                                             <Link className="link-hover zero-blue"
                                                                                   to={`/${this.props.org_uuid}/users/${user.user_uuid}`}
                                                                                   style={{display: "block"}}>
                                                                                 <span
                                                                                     className=''>{user.user_name}</span>
                                                                             </Link>
                                                                         ))
                                                                     }
                                                                 </div>
                                                             }
                                                    >
                                              <span>
                                                &nbsp;and <span
                                                  className="link-hover fw-600">{safe_get(this.props, "comment.info.users", []).length - 2} more</span>
                                              </span>
                                                    </Popover>
                                                }
                                                {
                                                    index < safe_get(this.props, "comment.info.users", []).slice(0, 2).length - 1 && ", "
                                                }
                                      </span>
                                        ))
                                    }
                                    {
                                        safe_get(this.props, "comment.info.users", []).length > 0 &&
                                        "."
                                    }
                                </span>
                            }
                            {
                                comment_type === "comment" && this.props.comment.deleted_at === null &&
                                <span className="post-status created">&nbsp;commented on this post.</span>
                            }
                            {
                                comment_type === "moved" &&
                                <span className="post-status moved">&nbsp;moved this post to <span
                                    dangerouslySetInnerHTML={this.commentInnerHTML(this.props.comment)}></span>.</span>
                            }
                            {
                                comment_type === "close" &&
                                <span className="post-status closed">&nbsp;closed this post.</span>
                            }
                            {
                                comment_type === "reopen" &&
                                <span className="post-status reopen">&nbsp;reopened this post.</span>
                            }
                            {
                                comment_type === "updated" &&
                                <span
                                    className="post-status updated">&nbsp;edited this post. {!isObjEmpty(safe_get(this.props.comment, "info", {})) &&
                                    <span className="blue-link dont-print" onClick={() => {
                                        this.setState(state => {
                                            return {show_updated_comment: !state.show_updated_comment}
                                        })
                                    }}>{this.state.show_updated_comment ? "Hide details" : "Show details"}</span>}</span>
                            }
                            { comment_type === "submission_edited" &&
                                <span className="post-status updated">
                                    &nbsp;edited this post.{" "}
                                    <span
                                        className="blue-link dont-print"
                                        onClick={() => {
                                            this.setState(state => {
                                                return {show_updated_comment: !state.show_updated_comment}
                                            })
                                        }}>{this.state.show_updated_comment ? "Hide details" : "Show details"}
                                    </span>
                                </span>
                            }
                            {
                                (comment_type === "status_update" || comment_type === "status_2_update") &&
                                <span className="post-status updated">&nbsp;changed the status of this post from <span className="zero-blue fw-600">{safe_get(this.props, "comment.info.before.name", <em>None</em>)}</span> to <span className="zero-blue fw-600">{safe_get(this.props, "comment.info.after.name", "")}</span>.</span>
                            }
                            {
                                comment_type === "shared" &&
                                <span className="post-status shared">&nbsp;shared this post.</span>
                            }
                            {
                                comment_type === "due_date" && this.props.comment.text &&
                                <span
                                    className="post-status created">&nbsp;set a due date for this post of {dateFormatterWithTime(this.props.comment.text)}.</span>
                            }
                            {
                                comment_type === "due_date" && !this.props.comment.text &&
                                <span className="post-status created">&nbsp;removed due date.</span>
                            }
                            {
                                (comment_type === "assign_users" || comment_type === "unassign_users") &&
                                <span
                                    className="post-status assigned">&nbsp;{comment_type === "assign_users" ? "assigned" : "unassigned"}&nbsp;
                                    {
                                        safe_get(this.props, "comment.info.users", []).length <= 3 &&
                                        safe_get(this.props, "comment.info.users", []).map((user, index) => (
                                            <span key={index}>
                                          <UserPopoverCard user_uuid={user.user_uuid}>
                                            <span className='fw-600 link-hover'>{user.user_name}</span>
                                          </UserPopoverCard>
                                                {
                                                    index + 1 === safe_get(this.props, "comment.info.users", []).length - 1 ? " and " :
                                                        index + 1 < safe_get(this.props, "comment.info.users", []).length - 1 ? ", " : ""
                                                }
                                      </span>
                                        ))
                                    }
                                    {
                                        safe_get(this.props, "comment.info.users", []).length > 3 &&
                                        safe_get(this.props, "comment.info.users", []).slice(0, 2).map((user, index) => (
                                            <span key={index}>
                                          <UserPopoverCard user_uuid={user.user_uuid}>
                                            <span className='fw-600 link-hover'>{user.user_name}</span>
                                          </UserPopoverCard>
                                                {
                                                    index === safe_get(this.props, "comment.info.users", []).slice(0, 2).length - 1 &&
                                                    <Popover trigger="click" placement="bottom"
                                                             className="custom-popover"
                                                             content={
                                                                 <div>
                                                                     {
                                                                         safe_get(this.props, "comment.info.users", []).slice(2, safe_get(this.props, "comment.info.users", []).length).map((user, index) => (
                                                                             <Link className="link-hover zero-blue"
                                                                                   to={`/${this.props.org_uuid}/users/${user.user_uuid}`}
                                                                                   style={{display: "block"}}>
                                                                                 <span
                                                                                     className=''>{user.user_name}</span>
                                                                             </Link>
                                                                         ))
                                                                     }
                                                                 </div>
                                                             }
                                                    >
                                              <span>
                                                &nbsp;and <span
                                                  className="link-hover fw-600">{safe_get(this.props, "comment.info.users", []).length - 2} more</span>
                                              </span>
                                                    </Popover>
                                                }
                                                {
                                                    index < safe_get(this.props, "comment.info.users", []).slice(0, 2).length - 1 && ", "
                                                }
                                      </span>
                                        ))
                                    }
                                    {comment_type === "assign_users"}.
                                </span>
                            }
                            {
                                comment_type === "exported" &&
                                <span className="post-status exported">
                                    &nbsp;exported this post. <span
                                    dangerouslySetInnerHTML={this.commentInnerHTML(this.props.comment)}></span>
                                </span>
                            }
                        </h3>

                        {
                            this.props.comment.deleted_at === null &&
                            <h2 className="author bulletin comment-date" style={{width: "fit-content"}}>
                                {dateFormatterWithTime(this.props.comment.created_at)}
                                {comment_type !== "submission_edited" && safe_get(this.props.comment, "edited_at", null) && <span>&nbsp;(edited)</span>}
                                {
                                    notification_count === parseInt(notification_count, 10) &&
                                    <span>
                                        &nbsp;&bull;&nbsp;
                                        {notification_count === 0 && "No one was notified"}
                                        {notification_count === 1 && "1 person was notified"}
                                        {notification_count > 1 && `${notification_count} people were notified`}
                                    </span>
                                }
                                {
                                    safe_get(this.props.comment, 'comment_type', '') === 'created' &&
                                    this.props.canGenerateNotificationReport &&
                                    isAdminOrTeamLead(this.props.user) &&
                                    <span>
                                        &nbsp;&bull;&nbsp;
                                        <span className="blue-link"
                                              onClick={() => this.setState({showPostNotificationReportModal: true})}>Generate Report</span>
                                    </span>
                                }
                            </h2>
                        }

                    </div>
                    {
                        this.state.edit_comment &&
                        <div style={{marginBottom: "5px", marginLeft: "36px", marginTop: "5px"}}>
                            <AttachmentCommentBox
                                attachments={this.state.attachments}
                                commentError={this.state.commentError}
                                onPreview={() => {}}
                                onDelete={(index) => this.handleRemoveAttachment(index)}
                                generateParamsCallback={this.generateParamsCallback}
                                onUploadSuccess={this.onUploadSuccess}
                                onUploadError={this.onUploadError}
                                textValue={this.state.edit_comment_text}
                                onTextChange={newValue => this.setState({edit_comment_text: newValue})}
                                buttonArea={(
                                    <div style={{textAlign: "right", marginTop: "5px"}}>
                                        <button className="btn btn-discard"
                                                style={{padding: "3px 5px", fontSize: "12px", marginLeft: "0px"}}
                                                onClick={this.cancelCommentEdit}>Cancel
                                        </button>
                                        <button className="btn btn-primary" disabled={this.state.fileIsUploading}
                                                style={{padding: "3px 5px", fontSize: "12px", marginLeft: "10px"}}
                                                onClick={this.saveComment}>
                                            {
                                                this.state.savingComment ? <ButtonLoading/> : "Save Changes"
                                            }
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    }
                    {
                        (comment_type === "comment" || comment_type === "close" || comment_type === "reopen" || comment_type === "shared") && (safe_get(this.props, "comment.text", "").length > 0) && this.props.comment.deleted_at === null &&
                        <br/>
                    }
                    {
                        !this.state.edit_comment && comment_type === "comment" && this.props.comment.deleted_at === null && safe_get(this.props, "comment.text", "") !== "" &&
                        <div style={{marginLeft: '36px', wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                            <div className="post-comment"
                               dangerouslySetInnerHTML={this.commentInnerHTML(this.props.comment)}/>
                        </div>
                    }
                    {
                        !this.state.edit_comment && comment_type === "comment" && this.props.comment.deleted_at &&
                        <div style={{marginTop: "5px", wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                            <p className="post-comment" style={{fontStyle: "italic", marginBottom: "0px"}}>
                                Comment was deleted
                                by {safe_get(this.props, "comment.created_by.first_name", "")} {safe_get(this.props, "comment.created_by.last_name", "")} on {dateFormatterNoTime(this.props.comment.deleted_at)}
                            </p>
                        </div>
                    }
                    {
                        !this.state.edit_comment && (comment_type === "close" || comment_type === "reopen" || comment_type === "shared") &&
                        <div className="post-comment" style={{marginLeft: '36px', wordWrap: 'break-word', whiteSpace: 'pre-line'}}
                             dangerouslySetInnerHTML={this.commentInnerHTML(this.props.comment)}></div>
                    }
                    {
                        !this.state.edit_comment && (comment_type === "updated" || comment_type === "submission_edited") && this.state.show_updated_comment &&
                        <div style={{marginLeft: '36px', marginTop: "15px", wordWrap: 'break-word'}}>
                            <CommentDiff diffs={this.state.diffs} />
                        </div>
                    }

                    {
                        this.state.attachments.length > 0 && !this.state.edit_comment && this.props.comment.deleted_at === null &&
                        <LazyLoad
                            offset={250}
                            onContentVisible={() => {
                                this.setState({attachmentsLoading: false});
                                postImageContain("img.post-img")
                            }}
                        >
                            <ul className="thumb" style={{marginLeft: '36px', marginTop: "8px"}}>
                                {
                                    this.state.attachments.map((attachment, index) => (
                                        <span key={index}>
                                            {this.showAttachment(attachment, index)}
                                        </span>
                                    ))
                                }
                            </ul>
                        </LazyLoad>
                    }
                    {
                        this.state.attachments.length > 0 && !this.state.edit_comment && this.props.comment.deleted_at === null && this.state.attachmentsLoading &&
                        <ul className="thumb" style={{marginLeft: '36px', marginTop: "8px"}}>
                            {
                                this.state.attachments.map((attachment, index) => (
                                    <li key={index} style={{marginRight: "5px"}}>
                                        <Skeleton height={100} width={100}/>
                                    </li>
                                ))
                            }
                        </ul>
                    }

                </div>
                <div style={{marginLeft: "55px", paddingTop: "5px"}}>
                    {
                        canReact &&
                        <div style={{display: "inline-flex", gap: 6}}>
                            <Reactions reactions={this.props.comment.reactions} post_uuid={this.props.post_uuid}
                                    comment_uuid={this.props.comment.comment_uuid}/>
                        </div>
                    }
                    {
                        canEdit &&
                        <p className="mar-btm-0 zero-blue dont-print" style={{display: "inline-block"}}
                           onClick={this.handleEditPressed}>
                            {canReact && <span>&nbsp;&bull;&nbsp;</span>}<span className="link-hover">Edit</span>
                        </p>
                    }
                    {
                        canDelete &&
                        <p className="mar-btm-0 zero-blue dont-print" style={{display: "inline-block"}}
                           onClick={this.openDeleteModal}>
                            &nbsp;&bull;&nbsp;<span className="link-hover">Delete</span>
                        </p>
                    }
                </div>
            </div>
        )

    }
}

class Comments extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            comments_loading: true,
            comments: [],
            updateRender: 0
        };

        this.addReasonComment = this.addReasonComment.bind(this);
        this.removeComment = this.removeComment.bind(this);
        this.updateComment = this.updateComment.bind(this);
    }

    componentDidUpdate(prevProps) {
        let comments = safe_get(this.props, "comments", []);

        if (comments.length !== safe_get(prevProps, "comments", []).length) {
            this.setState({comments: comments});
            this.adjustCommentView(comments);
        } else if (this.props.post_uuid !== prevProps.post_uuid) {
            this.setState({comments_loading: true}, () => {
                this.getComments();
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.comments && this.props.comments.length > 0) {
            this.setState({comments: this.props.comments, comments_loading: false});
            this.adjustCommentView(this.props.comments);
        } else {
            this.setState({comments_loading: true}, () => {
                this.getComments();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getComments = () => {
        var self = this;
        get_comments(this.props.post_uuid).then(function (success) {
            success.json().then(success => {
                if (self._isMounted) {
                    let comments = safe_get(success, "comments", []);
                    self.setState({
                        comments: comments,
                        comments_loading: false,
                        updateRender: self.state.updateRender + 1
                    });
                    self.adjustCommentView(comments);
                }
            });
        }, function (error) {
            console.log(error);
            self.setState({comments_loading: false, comments: []});
        });
    }

    adjustCommentView = (comments) => {
        if (comments.length > 0) {
            let last_comment = comments[comments.length - 1];
            let bottom = last_comment.attachments?.length > 0 ? "250px" : "110px";
            if (last_comment.comment_type.includes('assign_user')) {
                bottom = "75px";
            }
            $('head').append('<style>.timeline.closed:before{bottom:' + bottom + '!important;}</style>');
        }
    }

    addReasonComment(comment_uuid, text, isReason = false, commentType = "") {
        var today = new Date().getTime() / 1000;
        var comment = {}
        comment["comment_uuid"] = comment_uuid;
        comment["text"] = text;
        comment["created_at"] = today;
        comment["created_by"] = this.props.user;

        if (isReason) {
            comment["comment_type"] = commentType;
        } else {
            comment["comment_type"] = "comment";
        }

        var comments = this.state.comments
        comments.push(comment)
        this.setState({comments: comments})
    }

    removeComment(index) {
        var comments = this.state.comments;
        comments.splice(index, 1);
        this.setState({comments: comments});
        this.props.updateCommentsCount(false);

    }

    updateComment(index, comment) {
        var comments = this.state.comments;
        comments[index] = comment;
        this.setState({comments: comments});
    }


    render() {
        return (
            <div className="mar-top-10">
                {
                    this.state.comments_loading &&
                    <div>
                        <span>
                            <p className="zero-blue" style={{
                                display: 'inline-block',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '3px'
                            }}>
                                <Skeleton width={140} height={20}/>
                            </p>
                        </span>
                        <div className="timeline" style={{paddingBottom: "10px"}}>
                            {
                                [...Array(3)].map((e, index) =>
                                    <div className="timeline-entry" key={index}>

                                        <div className="timeline-stat">
                                            <div className="timeline-icon" style={{marginRight: "6px"}}>
                                                <Skeleton circle={true} height={24} width={24}/>
                                            </div>
                                        </div>

                                        <div className="timeline-label" style={{padding: "10px 8px"}}>
                                            <div className="media-left">
                                                <Skeleton circle={true} height={26} width={26}/>
                                            </div>
                                            <div className="media-body bulletin2" style={{paddingBottom: '0px'}}>
                                                <Skeleton width={"250px"} height={10}/>
                                                <br/>
                                                <Skeleton width={"150px"} height={10}/>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                }
                {
                    !this.state.comments_loading &&
                    <div>
                        <span>
                            <p className="zero-blue" style={{
                                display: 'inline-block',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '3px'
                            }}>Activity Timeline</p>
                        </span>
                        <div className={"timeline " + (this.props.postStatus === "closed" ? "closed" : "")}
                             style={{paddingBottom: "10px"}}>
                            {
                                this.state.comments.length > 0 &&
                                this.state.comments.map((comment, index) => (
                                    <PostComment
                                        key={comment.comment_uuid || comment.uuid || index}
                                        index={index}
                                        post={this.props.post}
                                        post_uuid={this.props.post_uuid}
                                        comment={comment}
                                        org={this.props.org}
                                        org_uuid={this.props.org_uuid}
                                        team_uuid={this.props.current_team.uuid}
                                        user={this.props.user}
                                        deleteComment={this.removeComment}
                                        updateComment={this.updateComment}
                                        reactions_enabled={this.props.reactions_enabled}
                                        postStatus={this.props.postStatus}
                                        updateRender={this.state.updateRender}
                                        canGenerateNotificationReport={this.props.canGenerateNotificationReport}
                                    />
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        org: safe_get(store, "org_helper.organization", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", {})
    }
}

export default connect(mapStateToProps)(Comments);
