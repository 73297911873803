import React, {useState} from 'react';
import {Image} from 'antd';

export default function FloorPlan({children, floorPlan}) {
    const [showImage, setShowImage] = useState(false);

    const handleClick = event => {
        event.stopPropagation();
        setShowImage(true);
    }

    return (
        <>
            {showImage &&
                <Image
                    preview={{
                        visible: true,
                        src: floorPlan,
                        onVisibleChange: visible => setShowImage(visible)
                    }}
                />
            }
            <span onClick={handleClick} className="link-hover" style={{marginLeft: 0}}>
                {children}
            </span>
        </>
    )
}