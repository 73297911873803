import {useContext} from "react";

import {FORM_ELEMENTS} from "other/Constants";
import {TimelineContext} from "other/ReactContexts";
import {safeProfilePic} from "other/Helper";
import CommentDiff from "./CommentDiff";
import moment from "moment";


function processValue(outerValue) {
    const {value, options = []} = outerValue;

    if (typeof value === 'string') return value;

    if (Array.isArray(value)) {
        const values = value.map(v => {
            const option = options[v];
            return option || 'N/A';
        })
        return values.join(', ');
    }

    return <em>None</em>;
}

function extractFieldValue(valueContainer, formField) {
    if (!valueContainer) return <em>None</em>;

    const element = valueContainer.element;
    if (element === 'UserDropdown') {
        const {value: users} = valueContainer;
        const userNames = users.map(user => `${user.first_name} ${user.last_name}`.trim());

        return (
            <div>
                {userNames.length === 0 &&
                    <em>None</em>
                }
                {userNames.length > 0 &&
                    userNames.join(', ')
                }
            </div>
        );
    } else if (element === 'DatePicker') {
        return moment(valueContainer.value, moment.HTML5_FMT.DATE).format('MM/DD/YYYY') || 'N/A';
    } else if (element === 'TimePicker') {
        return moment(valueContainer.value, moment.HTML5_FMT.TIME).format('h:mm A') || 'N/A';
    } else if (element === 'Attachments') {
        if (valueContainer.value === 0) {
            return <em>None</em>
        }

        return valueContainer.value;
    } else if (FORM_ELEMENTS.WITH_FREE_DATA.includes(element)) {
        return valueContainer.value;
    } else if (FORM_ELEMENTS.WITH_OPTIONS.includes(element)) {
        return valueContainer.value.map(valueKey => {
            // try to find option in form field options first
            let option;
            if (formField?.options) {
                option = formField.options.find(option => option.key === valueKey);
            }
            if (!option) {
                option = valueContainer.options[valueKey];
                if (option) {
                    return (
                        <><span className="strike-through">{option}</span> [Deleted]</>
                    )
                }
            }
            return option?.text || 'N/A';
        })
    } else if (element === 'Signature') {
        return <img style={{width: '100%'}} src={valueContainer.value}/>
    }

    return <em>None</em>
}

export function buildDiffs(commentDiffs, form) {
    const allowedElements = [...FORM_ELEMENTS.FOR_INPUT, 'Attachments']
    const formFieldIds = form.fields.filter(field => allowedElements.includes(field.element)).map(field => field.id);
    const existingFieldIds = Object.keys(commentDiffs).filter(id => formFieldIds.includes(id) === true);
    const deletedFieldIds = Object.keys(commentDiffs).filter(id => existingFieldIds.includes(id) === false);
    const existingFormFields = form.fields.reduce((map, field) => {
        map[field.id] = field;
        return map;
    }, {});

    const diffs = [];
    for (const id of formFieldIds) {
        const commentDiff = commentDiffs[id];
        const formField = existingFormFields[id];
        if (!commentDiff || !formField) {
            continue;
        }

        const label = formField?.label || commentDiff?.old_value?.label || commentDiff?.new_value?.label || 'N/A';
        let oldValue = extractFieldValue(commentDiff?.old_value, formField);
        let newValue = extractFieldValue(commentDiff?.new_value, formField);

        diffs.push({
            key: id,
            oldLabel: label,
            oldValue,
            newLabel: label,
            newValue,
        })
    }

    for (const id of deletedFieldIds) {
        const commentDiff = commentDiffs[id];
        const label = (
            <>
                <div>[Field deleted from template]</div>
                <div>
                    <span className="underline">
                        <span
                            className="strike-through">{commentDiff?.old_value?.label || commentDiff?.new_value?.label || 'N/A'}:</span>
                    </span>
                </div>
            </>
        )
        let oldValue = extractFieldValue(commentDiff?.old_value, null);
        let newValue = extractFieldValue(commentDiff?.new_value, null);

        diffs.push({
            key: id,
            oldLabel: label,
            oldValue,
            newLabel: label,
            newValue,
        })
    }

    return diffs;
}

export default function SubmissionEditDiff({data}) {
    const {submissions = [], showFormName = true} = useContext(TimelineContext);
    const {diffs, form_name, submission_uuid} = data;
    const submission = submissions.find(sub => sub.submission_uuid === submission_uuid);

    let extractedDiffs;
    if (diffs) {
        if (!submission) {
            return (
                <div className="zero-dark-grey mar-top-10">
                    <div><span className="underline">{form_name}</span></div>
                    <br/>
                    <div><em>This submission has been deleted.</em></div>
                </div>
            )
        }

        extractedDiffs = buildDiffs(diffs, submission.form);
    }

    return (
        <>
            { showFormName &&
                <div className="zero-dark-grey mar-top-10">
                    <div><span className="underline">{form_name}</span></div>
                </div>
            }
            {extractedDiffs &&
                <CommentDiff diffs={extractedDiffs}/>
            }
        </>
    );
}