import React, {Component} from 'react';
import {Route, Redirect, withRouter} from 'react-router';
import {Switch} from 'react-router-dom'
import {connect} from 'react-redux'

import safe_get from '../other/SafeGet';

import Dashboard from '../components/Dashboard/Dashboard.js'

import Library from '../components/Library/Library.js'
import NewPost from '../components/Library/NewPost.js'
import UploadAttachment from '../components/Library/UploadAttachment.js'
import EditPost from '../components/Library/EditPost.js'
import Article from '../components/Library/ArticleView.js'
import ArticleHistory from '../components/Library/ArticleHistory.js'

import Incidents from '../components/Incidents/Incidents.js';
import EditIncident from '../components/Incidents/Incident/EditIncident.js'
import ManageLocations from '../components/AdminPages/ManageLocations/ManageLocations';

import BulletinFeed from '../components/Bulletin/BulletinFeed.js'
import BulletinView from '../components/Bulletin/BulletinView.js'
import BulletinNewPost from '../components/Bulletin/BulletinNewPost.js'
import BulletinEditPost from '../components/Bulletin/BulletinEditPost.js'

import Forms from '../components/Forms/Forms.js';
import NewForm from '../components/Forms/NewForm.js';
import EditForm from '../components/Forms/EditForm.js';
import EditSubmission from '../components/Forms/EditSubmission.js';
import SubmissionView from '../components/Forms/SubmissionView';

// import Courses from '../components/Courses/Courses.js'

import ManageForms from '../components/AdminPages/ManageForms/ManageForms.js';
import ManageFormsAnalytics from '../components/AdminPages/ManageForms/ManageFormsAnalytics';
import ManageFormAlerts from '../components/AdminPages/ManageFormAlerts/ManageFormAlerts.js';
import ManageSchedules from '../components/AdminPages/ManageSchedules/ManageSchedules';
import ManageCalendar from '../components/AdminPages/ManageSchedules/ManageCalendar';
import ScheduleAnalytics from '../components/AdminPages/ManageSchedules/ScheduleAnalytics';

import Account from '../components/Account/Account.js';
import NewOrganization from '../components/AdminPages/AccountSettings/NewOrganization';
import OrgSettings from '../components/AdminPages/AccountSettings/Settings.js'
import ManageTeams from '../components/AdminPages/ManageTeams/ManageTeams.js';
import ManageMembers from '../components/AdminPages/ManageMembers.js';
import KioskModeSettings from '../components/AdminPages/ManageKiosks/KioskModeSettings';
import ManageAPIKeys from '../components/AdminPages/ManageAPIKeys/ManageAPIKeys';

import NoTeamPage from '../components/NoTeamPage.js';
import InlineSideBar from '../components/Navigation/InlineSideBar';

import {containerClass, isUser, isMobileApp, isIE, canManageSpaces, isAdminOrTeamLead} from '../other/Helper';

import * as membersFilterActions from '../store/actions/MembersFilterActions';

import {
    update_feed_tab,
    update_feed_position,
    update_feed_query,
    update_date_query
} from '../store/actions/FeedHelperActions';
import {
    updateFormsFilterQuery,
    updateFormsPeriodQuery,
    updateFormsTab,
    updateFormsPagePosition,
    updateFormsPagination
} from '../store/actions/FormsActions';

import {
    update_current_location,
    updateIncidentsFilterQuery,
    updateIncidentsPeriodQuery,
    updateIncidentsTab,
    updateIncidentsPagePosition,
    updateIncidentsPagination
} from '../store/actions/IncidentsActions';

import $ from 'jquery';
import CheckIn from 'components/Spaces/CheckIn';
import ManageDesks from 'components/AdminPages/ManageDesks/ManageDesks';
import DirectoryPage from 'components/Spaces/DirectoryPage';
import ViewMapPage from 'components/Spaces/ViewMapPage';
import MyCheckIns from 'components/Spaces/MyCheckIns';
import OfflineDashboard from 'components/Offline/OfflineDashboard';
import SideBarWrapper from 'components/Navigation/SideBarWrapper';
import OfflineDebugPage from 'components/Offline/OfflineDebugPage';
import PostSubmissionConfirmationPage from 'components/Bulletin/PostSubmissionConfirmationPage';
import ManageFormPage from 'components/AdminPages/ManageForms/ManageFormPage';

const manageTemplatesMatch = `manage_forms_templates|manage_post_templates|manage_incident_templates|manage_courses`;

class RoutesList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // var location = prevProps.location.pathname;
        // if (location.includes("dashboard") || location.includes("incident") || location.includes("edit") || location.includes("new") || location.includes("library") || (!location.includes("/home/team/") && !location.includes("/home/my_posts"))) {
        //   this.props.dispatch(update_feed_position(0));
        //   this.props.dispatch(update_feed_query("?team_uuid=" + this.props.currentTeam.uuid));
        //   this.props.dispatch(update_date_query("&period=all_time"));
        // }

        var location = safe_get(this.props, "location.pathname", "");

        if ((!location.includes("/feed") && !location.includes("/access_teams")) || location.includes("edit") || location.includes("new")) {
            this.props.dispatch(update_feed_position(0));
            this.props.dispatch(update_feed_tab("posts"));
            this.props.dispatch(update_feed_query("?team_uuid=my_teams"));
            this.props.dispatch(update_date_query("&period=all_time"));
        }

        if (location.includes("/feed/tab/analytics") && window.location.search.includes('export=true')) {
            this.props.dispatch(update_feed_query(window.location.search));
        }

        if (!location.includes("/forms") && !location.includes("/courses")) {
            this.props.dispatch(updateFormsPagination(1));
            this.props.dispatch(updateFormsPagePosition(0));
            this.props.dispatch(updateFormsFilterQuery("?team_uuid=my_teams"));
            this.props.dispatch(updateFormsPeriodQuery("&period=all_time"));
            this.props.dispatch(updateFormsTab("forms"));
        }

        if (!location.includes("/incidents")) {
            this.props.dispatch(updateIncidentsPagination(1));
            this.props.dispatch(updateIncidentsPagePosition(0));

            let location = {"location_uuid": "all_locations"};
            this.props.dispatch(update_current_location(location));
            this.props.dispatch(updateIncidentsFilterQuery(""));

            this.props.dispatch(updateIncidentsPeriodQuery("&period=all_time"));
            this.props.dispatch(updateIncidentsTab("incidents"));
        }

        if (!location.includes("directory") && !location.includes("/users/")) {
            this.props.dispatch(membersFilterActions.update_members_filter([]));
        }

    }

    render() {
        const AdminRoutes = [

            <Route path='/:org_uuid/home/settings/:tab?' component={OrgSettings}/>,

            <Route path='/:org_uuid/home/create_organization' component={NewOrganization}/>,

            <Route path='/:org_uuid/home/manage_kiosks' component={KioskModeSettings}/>,

            <Route path='/:org_uuid/home/manage_api_keys' component={ManageAPIKeys}/>,

        ];

        const TeamLeadRoutes = [

            <Route path='/:org_uuid/home/incidents/location/:location_uuid/:tab?' exact component={Incidents}/>,

            <Route path='/:org_uuid/home/incidents/location/:location_uuid/new_incident/:tab' exact
                   component={EditIncident}/>,

            <Route path='/:org_uuid/home/incidents/location/:location_uuid/edit_incident/:incident_uuid/:tab' exact
                   component={EditIncident}/>,

            <Route path='/:org_uuid/home/manage_schedules' exact component={ManageSchedules}/>,
            <Route path='/:org_uuid/home/manage_calendar/:pageType?' exact component={ManageCalendar}/>,
            
            <Route
                path={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})`}
                exact
                component={ManageForms}
            />,
            <Route
                path={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/new_form`}
                exact 
                component={NewForm}
            />,
            <Redirect
                from={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/edit_form/:form_uuid`}
                to='/:org_uuid/home/:manage_form_type/:form_uuid/editor'
                exact
            />,
            <Redirect
                from={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/analytics/:form_uuid`}
                to='/:org_uuid/home/:manage_form_type/:form_uuid/analytics'
                exact
            />,
            <Route
                path={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/:form_uuid/:tabName?`}
                exact
                component={ManageFormPage}
            />,

            <Route path='/:org_uuid/home/manage_courses_schedules' exact component={ManageSchedules}/>,

            <Route path='/:org_uuid/home/access_teams' component={ManageTeams}/>,

            <Route path='/:org_uuid/home/manage_incidents_locations' component={ManageLocations}/>,

            <Route path='/:org_uuid/home/directory' component={ManageMembers}/>,

            <Route path='/:org_uuid/home/manage_schedules/analytics/:schedule_uuid' exact
                   component={ScheduleAnalytics}/>,


            <Route path='/:org_uuid/home/manage_form_alerts' exact component={ManageFormAlerts}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/private_library/new_attachment' component={UploadAttachment}/>

        ];

        var NonAdminRoutes = [
            <Redirect
                exact
                from='/'
                to={{
                    pathname: `/${this.props.organization.organization_uuid}/home/dashboard`,
                    search: this.props.location.search
                }}
            />,

            // DASHBOARD
            <Route path='/:org_uuid/home/dashboard' exact component={Dashboard}/>,

            // LIBRARY
            <Route path='/:org_uuid/home/team/:team_uuid/private_library' exact component={Library}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/private_library_tag/:tag' component={Library}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/private_library/article/:article_uuid' exact
                   component={Article}/>,
            <Route path='/:org_uuid/home/team/:team_uuid/shared_library/article/:article_uuid' exact
                   component={Article}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/private_library/new_post' component={NewPost}/>,
            <Route path='/:org_uuid/home/team/:team_uuid/private_library/edit/:article_uuid' component={EditPost}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/private_library/article/:article_uuid/timeline' exact
                   component={ArticleHistory}/>,
            <Route path='/:org_uuid/home/team/:team_uuid/shared_library/article/:article_uuid/timeline' exact
                   component={ArticleHistory}/>,

            // BULLETIN
            // <Route path='/:org_uuid/home/my_posts' exact component={BulletinFeed} />,

            <Route path='/:org_uuid/home/team/:team_uuid/feed' exact component={BulletinFeed}/>,
            <Route path='/:org_uuid/home/team/:team_uuid/feed/tab/:tab' exact component={BulletinFeed}/>,

            <Route path='/:org_uuid/home/my_posts/post/:post_uuid' exact component={BulletinView}/>,
            // <Route path='/:org_uuid/home/my_posts/post/:post_uuid/print' exact component={BulletinView}/>,

            <Route path='/:org_uuid/home/my_posts/edit/:post_uuid' component={BulletinEditPost}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/feed/post/:post_uuid' exact component={BulletinView}/>,
            // <Route path='/:org_uuid/home/team/:team_uuid/feed/post/:post_uuid/print' exact
            //        component={BulletinView}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/feed/new_post' exact component={BulletinNewPost}/>,
            <Route path='/:org_uuid/home/team/:team_uuid/feed/new_post/:post_uuid' exact component={BulletinNewPost}/>,
            <Route path='/:org_uuid/home/team/:team_uuid/feed/post_submitted' exact component={PostSubmissionConfirmationPage} />,

            <Route path='/:org_uuid/home/team/:team_uuid/feed/edit/:post_uuid' component={BulletinEditPost}/>,

            // FORMS
            <Route path='/:org_uuid/home/team/:team_uuid/forms/:tab?' exact component={Forms}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/forms/submission_view/:submission_uuid' exact
                   component={SubmissionView}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/forms/:form_uuid/:tab' exact component={EditSubmission}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/forms/:form_uuid/assignment/:assignment_uuid/:tab' exact
                   component={EditSubmission}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/forms/:form_uuid/submission/:submission_uuid' exact
                   component={EditSubmission}/>,


            // COURESES
            <Route path='/:org_uuid/home/team/:team_uuid/courses/:tab?' exact component={Forms}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/courses/submission_view/:submission_uuid' exact
                   component={SubmissionView}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/courses/:form_uuid/:tab' exact component={EditSubmission}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/courses/:form_uuid/assignment/:assignment_uuid/:tab' exact
                   component={EditSubmission}/>,

            <Route path='/:org_uuid/home/team/:team_uuid/courses/:form_uuid/submission/:submission_uuid' exact
                   component={EditSubmission}/>,


            // USER ACCOUNT

            <Route path='/:org_uuid/home/myprofile/:user_uuid' component={Account}/>,

            <Route path='/:org_uuid/users/:user_uuid' exact component={Account}/>,

            <Route path='/:org_uuid/feed/users/:user_uuid/' exact component={Account}/>,

            <Route path='/:org_uuid/home/no_team' exact component={NoTeamPage}/>,

            // SPACES
            <Route path='/:org_uuid/home/user_directory' exact component={DirectoryPage}/>,
            <Route path='/:org_uuid/home/view_maps' exact component={ViewMapPage}/>,
            <Route path='/:org_uuid/checkin/:checkInId?' exact component={CheckIn}/>,
            <Route path='/:org_uuid/home/my_check_ins' exact component={MyCheckIns}/>,

            // OFFLINE
            <Route path='/:org_uuid/offline/dashboard' exact component={OfflineDashboard}/>,
            <Route path='/:org_uuid/offline/debug' exact component={OfflineDebugPage} />,
        ];

        const Special_routes = [
            <Route path='/:org_uuid/home/incidents/location/:location_uuid/:tab?' exact component={Incidents}/>,
            <Route path='/:org_uuid/home/incidents/location/:location_uuid/new_incident/:tab' exact
                   component={EditIncident}/>,
            <Route path='/:org_uuid/home/incidents/location/:location_uuid/edit_incident/:incident_uuid/:tab' exact
                   component={EditIncident}/>,

        ]

        if (!isAdminOrTeamLead(this.props.user) && this.props.organization.incidents_enabled) {
            NonAdminRoutes = NonAdminRoutes.concat(Special_routes)
        }

        if (canManageSpaces(this.props.user)) {
            NonAdminRoutes.push(
                <Route path='/:org_uuid/home/manage_desks/:tabName?' exact component={ManageDesks}/>,
            );
        }

        const TeamLeads_Routes = NonAdminRoutes.concat(TeamLeadRoutes)
        const Admin_Routes = TeamLeads_Routes.concat(AdminRoutes)


        return (
            <div id="content-container" className={containerClass()}
                 style={{maxWidth: "1100px", top: (this.props.teams.length === 0 ? "100px" : "")}}>
                {
                    !this.props.location.pathname.includes("/no_team") &&
                    this.props.isDesktopSize &&
                    <SideBarWrapper Child={InlineSideBar}/>
                }
                <div style={{minHeight: ($("#inline-sidebar").height() + 200) + "px"}}>
                    <Switch>
                        {[
                            !this.props.isTeamLead && NonAdminRoutes,
                            this.props.isTeamLead && Admin_Routes,
                            this.props.isAdmin && Admin_Routes,
                            <Redirect key={"redirect-main"} to='/'/>
                        ]}
                    </Switch>
                </div>


            </div>

        )
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        currentTeam: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        isDesktopSize: safe_get(store, "app.desktopSize", true),
    }
}

export default withRouter(connect(mapStateToProps)(RoutesList));