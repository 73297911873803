import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import NotificationAlert from '../../../other/NotificationAlert.js';

import {
    get_notification_settings,
    update_notification_settings,
    test_push_notifications,
    test_sms_notification
} from '../../../api/zero-api.js';
import {isMobileApp, safe_get} from '../../../other/Helper';

import {Checkbox} from 'antd';
import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import {WebSocketContext} from "../../../other/WebSockets";

class NotificationSettings extends Component {
    static contextType = WebSocketContext;

    constructor(props) {
        super(props);
        this.state = {
            pn_value: false,
            en_value: false,
            teams: [],
            loading: true,
            browser_enabled: false,
            browser_possible: ("Notification" in window)
        };
        this.saveChanges = this.saveChanges.bind(this);
        this.onSwitchEN = this.onSwitchEN.bind(this);
        this.onSwitchPN = this.onSwitchPN.bind(this);
        this.postCreatedFormatter = this.postCreatedFormatter.bind(this);
        this.post_created_toggle = this.post_created_toggle.bind(this);
        this.postSharedFormatter = this.postSharedFormatter.bind(this);
        this.post_shared_toggle = this.post_shared_toggle.bind(this);
    }

    componentDidMount() {
        var self = this;
        get_notification_settings().then(function (success) {
            success.json().then(success => {
                const browserNotificationsEnabled = self.state.browser_possible && Notification.permission === "granted" && safe_get(success, "browser_notifications.enabled", false);
                self.setState({
                    pn_value: safe_get(success, "push_notifications.enabled", false),
                    en_value: safe_get(success, "email_notifications.enabled", false),
                    sms_enabled: safe_get(success, "sms_notifications.enabled", false),
                    browser_enabled: browserNotificationsEnabled,
                    teams: safe_get(success, "teams", []),
                    loading: false
                });
                self.updateBrowserNotificationSettings(safe_get(success, "browser_notifications.enabled", false));
            })
        }, function (error) {
            console.log(error)
        });
    }

    onSwitchEN(e) {
        this.setState({en_value: e}, () => {
            this.saveChanges();
        });
    }

    onSwitchPN(e) {
        this.setState({pn_value: e}, () => {
            this.saveChanges();
        });
    }

    onSwitchSMS = (e) => {
        this.setState({sms_enabled: e}, () => {
            this.saveChanges();
        });
    }

    onSwitchBrowser = (e) => {
        const enabled = e;
        if (enabled) {
            try {
                Notification.requestPermission().then(this.handleNotificationPermission);
            } catch (err) {
                // Work-around for Safari
                Notification.requestPermission(this.handleNotificationPermission);
            }
        } else {
            this.setState({browser_enabled: false}, () => {
                this.saveChanges();
            });
            this.updateBrowserNotificationSettings(false);
        }
    }

    handleNotificationPermission = (permission) => {
        if (permission === 'granted') {
            this.setState({browser_enabled: true}, () => {
                this.saveChanges();
            });
            this.updateBrowserNotificationSettings(true);
        } else {
            this.setState({browser_enabled: false});
            this.updateBrowserNotificationSettings(false);
        }
    }

    updateBrowserNotificationSettings(value) {
        const org_uuid = this.props.organization.organization_uuid;
        const newSettings = {...this.context.browserNotificationSettings}
        newSettings[org_uuid] = value;

        this.context.browserNotificationSettings = newSettings;
    }

    saveChanges(body = undefined) {
        if (body === undefined) {
            body = JSON.stringify({
                push_notifications: {
                    enabled: this.state.pn_value
                },
                email_notifications: {
                    enabled: this.state.en_value
                },
                sms_notifications: {
                    enabled: this.state.sms_enabled
                },
                browser_notifications: {
                    enabled: this.state.browser_enabled
                }
            });
        }

        update_notification_settings(body).then(function (success) {
            NotificationAlert("success", "", "Notification settings updated.")
        }, function (error) {
            NotificationAlert("success", "", "Unable to update notification settings.")
        });
    }

    testPN = () => {
        let sessions = safe_get(this.props, "sessions", []).map(x => x.is_app ? x.session_uuid : "").filter(Boolean)
        var body = JSON.stringify({
            session_uuids: sessions
        });

        test_push_notifications(body).then(function (success) {
            NotificationAlert("success", "", "Test notification(s) sent!");
        });
    }

    testSMS = () => {
        test_sms_notification().then(function (success) {
            NotificationAlert("success", "", "Test notification sent! We sent it to the mobile number listed on your profile.");
        });
    }

    testBrowser = () => {
        try {
            new Notification("Test notification from ZERO");
        } catch (err) {
            NotificationAlert("error", "", "Unable to create a desktop notification.");
            console.error(err);
        }
    }

    isIE() {
        var userAgent, ieReg, ie;
        userAgent = window.navigator.userAgent;
        ieReg = /msie|Trident.*rv[ :]*11\./gi;
        ie = ieReg.test(userAgent);
        return ie;
    }

    isEdge() {
        var userAgent = window.navigator.userAgent;
        return userAgent.indexOf("Edge") > -1
    }

    postCreatedFormatter(cell, row) {
        if (this.isEdge() || this.isIE()) {
            return (
                <input type="checkbox" checked={row.post_created} onChange={() => {
                    this.post_created_toggle(row);
                }}/>
            )
        } else {
            return (
                <Checkbox checked={row.post_created} onChange={() => {
                    this.post_created_toggle(row);
                }}/>
            )
        }

    }

    post_created_toggle(row) {
        var teams = this.state.teams
        var team_uuid = row.team_uuid;
        for (var i in teams) {
            var team = teams[i];
            if (team.team_uuid === row.team_uuid) {
                team["post_created"] = !team["post_created"];
                teams[i] = team;
                break;
            }
        }

        this.setState({teams: teams}, () => {
            var body = {};
            var team_obj = {};
            team_obj[team_uuid] = {"post_created": row.post_created};
            body["teams"] = team_obj;
            body = JSON.stringify(body);
            this.saveChanges(body);
        })
    }

    postSharedFormatter(cell, row) {
        if (this.isEdge() || this.isIE()) {
            return (
                <input type="checkbox" checked={row.post_shared} onChange={() => {
                    this.post_shared_toggle(row);
                }}/>
            )
        } else {
            return (
                <Checkbox checked={row.post_shared} onChange={() => {
                    this.post_shared_toggle(row);
                }}/>
            )
        }
    }

    post_shared_toggle(row) {
        var teams = this.state.teams
        var team_uuid = row.team_uuid;
        for (var i in teams) {
            var team = teams[i];
            if (team.team_uuid === row.team_uuid) {
                team["post_shared"] = !team["post_shared"];
                teams[i] = team;
                break;
            }
        }

        this.setState({teams: teams}, () => {
            var body = {};
            var team_obj = {};
            team_obj[team_uuid] = {"post_shared": row.post_shared};
            body["teams"] = team_obj;
            body = JSON.stringify(body);
            this.saveChanges(body);
        })
    }

    render() {
        let has_app_sessions = this.props.sessions.map(x => x.is_app).includes(true)
        return (
            <div className="wnel article" style={{maxWidth: '800px', margin: '0 auto', padding: "0px"}}>

                {
                    this.state.loading &&
                    <LoadingIndicator/>
                }
                {
                    !this.state.loading &&
                    <div className="pad-btm">
                        <div className="account-header">
                            <h4 className="zero-blue">Notifications</h4>
                            <p className="zero-dark-grey">ZERO notifies you about things you are subscribed or assigned
                                to.</p>
                            <p className="zero-dark-grey">To learn more about notifications in ZERO, click <a
                                rel="noopener noreferrer"
                                onClick={() => FreshworksWidget('open', 'article', {'id': 70000407464})}
                                className="blue-link">here</a>.</p>
                            <hr/>
                            <p className="account-label" style={{
                                textTransform: "none",
                                textAlign: "left",
                                marginTop: "5px",
                                fontSize: "110%",
                                fontWeight: "600"
                            }}>How would you like to receive notifications?</p>

                            <div onClick={() => this.onSwitchEN(!this.state.en_value)} style={{cursor: 'pointer'}}>
                                {
                                    (this.isEdge() || this.isIE())
                                        ? <input type="checkbox" checked={this.state.en_value}
                                                 style={{marginTop: '0px', marginRight: "8px"}}/>
                                        : <Checkbox checked={this.state.en_value}
                                                    style={{marginTop: '0px', marginRight: "8px"}}/>

                                }
                                <p className="zero-dark-grey"
                                   style={{paddingTop: "2px", display: "inline-block", marginRight: "5px"}}>Send me
                                    email notifications</p>
                                {
                                    this.state.en_value ? <span className="label label-success">ON</span> :
                                        <span className="label label-danger">OFF</span>
                                }

                            </div>
                            <div>
                                <div 
                                    onClick={() => this.onSwitchPN(!this.state.pn_value)} style={{cursor: 'pointer'}}>
                                    {
                                        (this.isEdge() || this.isIE())
                                            ? <input type="checkbox" checked={this.state.pn_value}
                                                 style={{marginTop: '0px', marginRight: "8px"}}/>
                                            : <Checkbox checked={this.state.pn_value}
                                                    style={{marginTop: '0px', marginRight: "8px"}}/>
                                    }
                                    <p className="zero-dark-grey"
                                       style={{paddingTop: "2px", display: "inline-block", marginRight: "5px"}}>Send me push
                                        notifications (mobile)</p>
                                    {
                                        this.state.pn_value ? <span className="label label-success">ON</span> :
                                        <span className="label label-danger">OFF</span>
                                    }
                                </div>
                                {
                                    !this.props.kiosk_mode && has_app_sessions &&
                                    <span className="blue-link mar-lft-5"
                                          onClick={this.testPN}>Send test notification</span>
                                }
                            </div>
                            <div>
                                <div
                                    onClick={() => this.props.user.mobile_phone_number && this.onSwitchSMS(!this.state.sms_enabled)}
                                    style={{cursor: 'pointer', display: 'inline-block'}}>
                                    {
                                        (this.isEdge() || this.isIE())
                                            ? <input type="checkbox" checked={this.state.sms_enabled}
                                                     style={{marginTop: '0px', marginRight: "8px"}}
                                                     disabled={!this.props.user.mobile_phone_number}/>
                                            : <Checkbox checked={this.state.sms_enabled}
                                                        style={{marginTop: '0px', marginRight: "8px"}}
                                                        disabled={!this.props.user.mobile_phone_number}/>

                                    }
                                    <p className="zero-dark-grey"
                                       style={{paddingTop: "2px", display: "inline-block", marginRight: "5px"}}>Send me
                                        SMS (text) notifications (US only)</p>
                                    {
                                        this.state.sms_enabled ? <span className="label label-success">ON</span> :
                                            <span className="label label-danger">OFF</span>
                                    }
                                </div>
                                {
                                    this.props.user.mobile_phone_number &&
                                    <span className="blue-link mar-lft-5"
                                          onClick={this.testSMS}>Send test notification</span>
                                }
                                {
                                    !this.props.user.mobile_phone_number &&
                                    <span className="blue-link mar-lft-5" onClick={this.props.editProfile}>Add mobile number to enable SMS</span>
                                }
                            </div>
                            <div>
                                <div
                                    onClick={() => this.state.browser_possible && this.onSwitchBrowser(!this.state.browser_enabled)}
                                    style={{cursor: 'pointer', display: 'inline-block'}}>
                                    {
                                        (this.isEdge() || this.isIE())
                                            ? <input type="checkbox" checked={this.state.browser_enabled}
                                                     style={{marginTop: '0px', marginRight: "8px"}}
                                                     disabled={!this.state.browser_possible}/>
                                            : <Checkbox checked={this.state.browser_enabled}
                                                        style={{marginTop: '0px', marginRight: "8px"}}
                                                        disabled={!this.state.browser_possible}/>
                                    }
                                    <p className="zero-dark-grey"
                                       style={{paddingTop: "2px", display: "inline-block", marginRight: "5px"}}>Send me
                                        desktop notifications</p>
                                    {
                                        this.state.browser_enabled ? <span className="label label-success">ON</span> :
                                            <span className="label label-danger">OFF</span>
                                    }
                                </div>
                                {
                                    this.state.browser_possible &&
                                    <span className="blue-link mar-lft-5" onClick={this.testBrowser}>Send test notification</span>
                                }
                            </div>
                            <div>
                                {
                                    (this.isEdge() || this.isIE())
                                        ? <input type="checkbox" checked={true} disabled={true}
                                                 style={{marginTop: '0px', marginRight: "8px"}}/>
                                        : <Checkbox checked={true} disabled={true}
                                                    style={{marginTop: '0px', marginRight: "8px"}}/>

                                }
                                <p className="zero-dark-grey"
                                   style={{paddingTop: "2px", display: "inline-block", marginRight: "5px"}}>Send me
                                    in-app notifications</p>
                                <span className="label label-success">ALWAYS ON</span>
                            </div>
                            <p className="zero-dark-grey">Note: you will always receive notifications for assignments -
                                even if notifications are off.</p>
                        </div>
                    </div>

                }

            </div>
        )
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
        kiosk_mode: safe_get(store, "app.kiosk_mode", false)
    }
}

export default connect(mapStateToProps)(NotificationSettings);