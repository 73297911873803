import React, {useCallback, useEffect, useState} from 'react';

import {Bar} from 'react-chartjs-2';

import {get_incident_hourly_chart} from '../../../api/zero-api.js'
import safe_get from '../../../other/SafeGet';
import Skeleton from 'react-loading-skeleton'
import NotificationAlert from 'other/NotificationAlert.js';
import {unstable_batchedUpdates} from 'react-dom';


async function fetchChartData(query) {
    try {
        const response = await get_incident_hourly_chart(`?${query}`);
        const jsonData = await response.json();
        const data = {
            data: safe_get(jsonData, 'data', {}),
            options: safe_get(jsonData, 'options', {})
        };
        return [data, null]
    } catch (error) {
        return [null, error];
    }
}


export default function IncidentsHourlyChart({query, location_uuid, loading}) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);

    const handleChartDataReceived = useCallback(({data, options}) => {
        unstable_batchedUpdates(() => {
            setIsLoading(false);
            setData(data);
            setOptions(options);
        });
    }, [setIsLoading, setData, setOptions]);

    useEffect(() => {
        setIsLoading(true);

        fetchChartData(query).then(([data, error]) => {
            if (error) {
                setIsLoading(false);
                NotificationAlert('error', '', 'Could not load hourly incident chart.');
            } else {
                handleChartDataReceived(data);
            }
        })
    }, [query, location_uuid]);

    return (
        <div className="col-lg-12 col-xs-12 col-print-12 col-reduce-padding print-avoid-page-break">
            <div className="panel thin-border print-no-y-margins">
                <div className="panel-heading" style={{height: "25px"}}>
                    <h2 className="panel-title analytics" style={{display: 'inline-block', paddingRight: '0px'}}>
                        {(isLoading || loading) ? <Skeleton width={150}/> : "Incidents by Time of Day (24hr)"}
                    </h2>
                </div>
                <div className="pad-all no-padding-print print-incident-chart-wrapper" style={{paddingTop: "0px"}}>
                    {
                        (isLoading || loading) ?
                            <Skeleton width={"100%"} height={266}/> :
                            <Bar height={100} data={data} options={options}/>
                    }
                </div>
            </div>
        </div>
    );
}
