export default function reducer(state = {
    query: "",
    period_query: "&period=all_time",
    tab: "forms",
    forms: [],
    schedules: [],
    position: 0,
    pagination: 1
}, action) {

    switch (action.type) {

        case "updateFormsFilterQuery": {
            return Object.assign({}, state, {
                query: action.payload
            });
        }

        case "updateFormsPeriodQuery": {
            return Object.assign({}, state, {
                period_query: action.payload
            });
        }

        case "updateFormsTab": {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }

        case "updatePagePosition": {
            return Object.assign({}, state, {
                position: action.payload
            });
        }

        case "updatePagination" : {
            return Object.assign({}, state, {
                pagination: action.payload
            });
        }

        case "updateFiltersForms": {
            return Object.assign({}, state, {
                forms: action.payload
            });
        }

        case "updateFiltersSchedules": {
            return Object.assign({}, state, {
                schedules: action.payload
            });
        }

        default: {
            return state;
        }

    }

}