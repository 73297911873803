import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {Modal, Alert} from 'antd';

import {safe_get, dateFormatterWithTime} from '../../../other/Helper';

import FormFieldsRenderer from '../../Forms/FormFieldsRenderer.js';

import ButtonLoading from '../../Shared/ButtonLoading';
import LoadingIndicator from '../../Shared/LoadingIndicator';

import '../../../assets/css/antd-custom.css'
import { PageSelectDropdown } from 'components/Forms/PageSelectDropdown';
import { generateFormPages } from 'other/forms';

class FormPreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            form_name: "",
            selected_teams: [],
            pages: [],
            page: 0,
        };

    }

    componentDidMount() {
        this.initFormFields(this.props.fields);
    }

    initFormFields = (fields) => {
        const pages = generateFormPages(fields);

        this.setState({
            pages,
            submission_loading: false
        })
    }

    render() {
        return (
            (<Modal
                width={830}
                title="Preview"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                footer={null}
                maskTransitionName="maskTransitionName"
            >
                <Alert
                    message="Here's what people will see when they are submitting this form. No responses are collected in this preview."
                    type="warning"/>
                <br/>
                <div className="panel thin-border" style={{margin: '0 auto', display: 'flex', alignItems: 'center'}}>
                    <div className="nav-header-panel" style={{
                        width: "87%",
                        borderRight: "1px solid #e2e2e2",
                        borderRadius: "0px",
                        display: "inline-block"
                    }}>
                        <div className="team-name-header">
                            <h3 className={"section-titles admin-page-header"}
                                style={{display: "inline-block", margin: "0px"}}>
                                {this.props.title}
                            </h3>
                        </div>

                        <p className="header" style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                            Submitting to Example Team. Last modified by Example User
                            on {dateFormatterWithTime(+new Date() / 1000)}.
                        </p>

                    </div>
                    <div style={{width: "13%", minWidth: "90px", display: "inline-block"}}>
                        <p className="zero-blue text-center" style={{margin: '0 auto'}}>
                            <b>Score</b>
                            <br/>
                            --
                            {/* {
                              this.state.denominator === 0
                              ? '--'
                              : `${this.state.numerator} / ${this.state.denominator} (${this.state.score}%)`
                          }

                          <br/>
                          {
                              this.state.showSavedText && !this.state.edit_mode &&
                              <span >Saved</span>
                          }
                          {
                              this.state.showSavingText && !this.state.edit_mode &&
                              <span >Saving...</span>
                          } */}
                        </p>
                    </div>
                </div>
                <br/>
                <div className="panel pad-all thin-border">
                    <PageSelectDropdown
                        pageCount={this.state.pages?.length ?? 0}
                        currentPage={(this.state.page ?? 0) + 1}
                        onPageChange={(newPage) => {
                            this.setState({page: newPage});
                        }}
                    />
                    <FormFieldsRenderer
                        form_uuid={this.props.form_uuid}
                        submission_uuid=""
                        form_fields={this.props.fields}
                        pages={this.state.pages}
                        page={this.state.page}
                        field_answers={[]}
                        toolbar_items={[]}
                        is_preview={true}
                    />
                </div>
                <div className="panel clear mar-btm-10" style={{textAlign: "right"}}>
                    {
                        this.state.page > 0 &&
                        <button className="btn btn-discard" onClick={() => {
                            this.setState({page: this.state.page - 1});
                        }} style={{marginRight: "8px"}}>
                            Previous Page
                        </button>
                    }
                    {
                        this.state.page < this.state.pages.length - 1 &&
                        <button className="btn btn-primary" onClick={() => {
                            this.setState({page: this.state.page + 1});
                        }}>
                            Next Page
                        </button>
                    }
                </div>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(FormPreviewModal));