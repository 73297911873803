import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Modal, Switch} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

import {create_api_key, update_api_key} from '../../../api/zero-api.js'
import {safe_get} from '../../../other/Helper';

import NotificationAlert from '../../../other/NotificationAlert';

import Skeleton from 'react-loading-skeleton';
import ButtonLoading from '../../Shared/ButtonLoading';
import LoadingIndicator from '../../Shared/LoadingIndicator';

import '../../../assets/css/antd-custom.css';

class NewEditAPIKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api_key_name: safe_get(this.props, "key_to_edit.name", ""),
        };

    }

    componentDidMount() {
        this.setState({
            api_key_name: safe_get(this.props, "key_to_edit.name", ""),
            enabled: safe_get(this.props, "key_to_edit.enabled", true)
        })
    }

    keyNameChange = (e) => {
        this.setState({api_key_name: e.target.value});
    }

    toggleEnabled = (checked) => {
        this.setState({enabled: checked});
    }

    handleConfirm = () => {
        let self = this;

        if (!this.state.saving) {

            this.setState({saving: true});

            var body = JSON.stringify({
                name: this.state.api_key_name,
                enabled: this.state.enabled,
            });

            if (this.props.key_to_edit) {
                update_api_key(this.props.key_to_edit.api_key_uuid, body).then(success => {
                    NotificationAlert('success', 'Success!', "API Key updated.");
                    self.props.cancel()
                    self.props.refresh();
                }, error => {
                    NotificationAlert('success', 'Success!', "Unable to update API Key.");
                    self.setState({saving: false});
                });
            } else {
                create_api_key(body).then(_success => {
                    NotificationAlert('success', 'Success!', "API Key created.");
                    self.props.cancel()
                    self.props.refresh();
                }, _error => {
                    NotificationAlert('success', 'Success!', "Unable to create API Key.");
                    self.setState({saving: false});
                });
            }

        }

    }

    render() {

        return (
            (<Modal
                title={`${this.props.key_to_edit ? "Edit" : "Create"} API Key`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.handleConfirm} disabled={this.state.saving}>
                            {this.state.saving ? <ButtonLoading/> : this.props.key_to_edit ? "Save" : "Create"}
                        </button>
                    </div>
                }
            >
                <div className="">
                    <h3 className="titles"
                        style={{marginBottom: '2px', marginLeft: '0px', fontSize: '110%', marginTop: "0px"}}>Name</h3>
                    <input type={"text"} placeholder={"Name this API Key"} className={"form-control custom-placeholder"}
                           value={this.state.api_key_name} onChange={this.keyNameChange}/>
                    <div className="zero-dark-grey mar-top-10">
                        Enabled
                        <Switch
                            style={{marginLeft: "8px"}}
                            defaultChecked
                            checked={this.state.enabled}
                            onChange={this.toggleEnabled}
                            checkedChildren={<CheckOutlined/>}
                            unCheckedChildren={<CloseOutlined/>}/>
                    </div>


                </div>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(NewEditAPIKey);