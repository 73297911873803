import React from 'react';
import {useSelector} from 'react-redux';
import {CloseOutlined} from '@ant-design/icons';

import {PostFormTypes} from './PostForm';

export default function PostFormHeader({state, onModalClosed, formType}) {
    const user = useSelector(state => state.user.user);
    const isEditedPost = formType === PostFormTypes.EDIT_POST;
    const isModal = formType === PostFormTypes.COPY_POST || formType === PostFormTypes.NEW_POST_MODAL;
    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <h3 className="titles mar-no">{isEditedPost ? "Edit Post" : "New Post"}</h3>
            {!isModal &&
                <p className="hidden-xs zero-blue mar-no">
                    {
                        state.saving ? "Saving..." : state.showSavedText ? "Saved" : ""
                    }
                </p>
            }
            {isModal &&
                <CloseOutlined style={{cursor: 'pointer', alignSelf: 'center'}} onClick={onModalClosed}/>
            }
        </div>
    )
}