import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

import {
    get_incident_options, get_locations, get_incident, update_incident, create_incident, delete_incident, get_forms,
    get_incident_managers, get_incident_comments, move_incident, get_custom_fields
} from '../../../api/zero-api.js'

import {
    scrollToTop,
    mainContentContainerClass,
    hideFooterMobileApp,
    showFooterMobileApp,
    dateFormatterWithTime,
    safe_get,
    isAdminOrTeamLead,
    isAdmin,
    isTeamLead
} from '../../../other/Helper.js'

import moment from 'moment';

import {Collapse, Tabs, Menu, Dropdown} from 'antd';

import {IoIosMore as MoreIcon} from 'react-icons/io';

import {EditOutlined, DeleteOutlined, ArrowRightOutlined, CloudDownloadOutlined} from '@ant-design/icons';

import Skeleton from 'react-loading-skeleton'

import EmployeeInfo from './EmployeeInfo.js';
import CaseInfo from './CaseInfo.js';
import CaseInfo2 from './CaseInfo2.js';
import CaseClassification from './CaseClassification.js';
import BodyPartSelect from './BodyPartSelect.js';
import HealthCareInfo from './HealthCareInfo.js';
import CustomForm from './CustomForm.js';
import IncidentAttachments from './IncidentAttachments'
import CorrectiveActions from './CorrectiveActions.js';
import Subscribers from './Subscribers.js';

import ConfirmModal from '../../Shared/ConfirmModal.js'
import NotificationAlert from '../../../other/NotificationAlert.js';
import ButtonLoading from '../../Shared/ButtonLoading.js';

import error_img from '../../../assets/css/img/access-error.png'

import * as incidentsActions from '../../../store/actions/IncidentsActions';

import logo from "../../../assets/css/img/ZERO-(blue).png";
import IncidentStatusTag from '../IncidentStatusTag.js';
import {CUSTOM_FIELD_TYPES, IncidentStatus} from 'other/Constants.js';
import IncidentDetails, { IncidentStatusSelect } from '../IncidentDetails.js';
import IncidentTimeline from '../IncidentTimeline.js';

import DeleteIncidentModal from '../DeleteIncidentModal.js';
import ExportIncidentModal from '../ExportIncidentModal.js';
import MoveIncidentModal from '../MoveIncidentModal.js';

import styles from './EditIncident.module.css';
import AlternateWrapper from 'components/Shared/AlternateWrapper.js';

var saveContentTimeout = null;

function SaveText({showSavedText, showSavingText}) {
    let text;

    if (showSavedText) {
        text = "Saved";
    } else if (showSavingText) {
        text = "Saving...";
    } else {
        return null;
    }

    return (
        <p className="zero-dark-grey" style={{margin: "10px"}}>{text}</p>
    );
}

function PrintSafeWrapper({printView, NonPrintWrapper, PrintWrapper = React.Fragment, children, ...props}) {
    return (
        <AlternateWrapper
            MainWrapper={NonPrintWrapper}
            AltWrapper={PrintWrapper}
            useAltWrapper={printView}
            {...props}
        >
            {children}
        </AlternateWrapper>
    );
}

function IncidentOptionsMenu({
    incident,
    user,
    onDelete = () => {},
    onMove = () => {},
    onExport = () => {}
}) {
    const isDisabled = isAdminOrTeamLead(user) === false;
    const isAuthor = incident?.created_by?.uuid === user.uuid;
    const deleteIsDisabled = !(isAdmin(user) || (isTeamLead(user) && isAuthor));

    const handleMenuClick = ({key}) => {
        switch (key) {
            case 'delete_incident':
                onDelete();
                break;
            case 'move':
                onMove();
                break;
            case 'export_incident':
                onExport();
                break;
        }
    }

    const menu = {
        onClick: handleMenuClick,
        items: [
            {
                key: 'edit_incident',
                disabled: true,
                icon: <EditOutlined/>,
                label: 'Edit'
            },
            {
                key: 'export_incident',
                disabled: isDisabled,
                icon: <CloudDownloadOutlined/>,
                label: 'Export'
            },
            {
                key: 'move',
                disabled: isDisabled,
                icon: <ArrowRightOutlined/>,
                label: 'Move'
            },
            {
                type: 'divider'
            },
            {
                key: 'delete_incident',
                disabled: deleteIsDisabled,
                className: deleteIsDisabled ? "" : "zero-delete-red",
                icon: <DeleteOutlined/>,
                label: 'Delete'
            }
        ]
    }

    return (
        <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
            <button className="ButtonLink ant-dropdown-link more" style={{paddingBottom: '1px'}}>
                <MoreIcon style={{height: '20px', width: '20px', color: 'grey'}}/>
            </button>
        </Dropdown>
    );
}

class EditIncident extends Component {
    _self = this;

    constructor(props) {
        super(props);
        this.state = {
            tab: "",
            incident_error: false,
            error_message: "incident not found",
            loadingIncident: true,
            incident_uuid: "",
            case_number: "",
            full_name: "",
            job_title: "",
            date_of_birth: null,
            date_hired: null,
            sex: null,
            incident_types: [],
            incident_causes: [],
            injuries: [],
            case_classifications: [],
            illnesses: [],
            incident_type: null,
            date_of_injury: null,
            time_began_work: null,
            event_time: null,
            event_location: "",
            event_time_determined: undefined,
            date_of_death: null,
            before_incident: "",
            where_occurred: "",
            what_happened: "",
            what_body_part: "",
            what_object: "",
            health_care_name: "",
            health_care_facility: "",
            emergency_room_used: false,
            hospitalized: false,
            body_parts: [],
            related_post: {},
            event_types: [],
            event_type: {},
            corrective_actions: [],
            incident_forms: [],
            comments: [],
            managers: [],
            subscribers: [],
            showDeleteModal: false,
            showExportModal: false,
            showMoveModal: false,
            customField1: null,
            customField2: null,
            customField3: null,
            customField4: null,
            custom_field_1_uuid: null,
            custom_field_2_uuid: null,
            custom_field_3_uuid: null,
            custom_field_4_uuid: null,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.updateAttachments = this.updateAttachments.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.createIncident = this.createIncident.bind(this);
        this.getIncident = this.getIncident.bind(this);
        this.updateIncident = this.updateIncident.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.getIncident(this.props.match.params.incident_uuid);
        }

        if (this.state?.incident?.location_uuid !== prevState?.incident?.location_uuid) {
            this.getManagers(this.state.incident_uuid);
        }
    }

    componentDidMount() {
        var self = this;

        const params = new URLSearchParams(window.location.search);
        const multi_page_override = params.get('multi_page') === 'false';
        const tzOverride = params.get('tz_override');

        if (multi_page_override) {
            this.setState({print: true});
        }

        window.onbeforeprint = function () {
            self.setState({print: true});
        }

        window.onafterprint = function () {
            if (!multi_page_override) {
                self.setState({print: false});
            }
        }

        if (tzOverride) {
            this.setState({tzOverride});
        }

        scrollToTop("page-head");
        hideFooterMobileApp();

        var incident_uuid = this.props.match.params.incident_uuid;
        var location_uuid = this.props.match.params.location_uuid

        if (this.props.incident_locations.length === 0) {
            get_locations().then(function (success) {
                success.json().then(success => {
                    let locations = safe_get(success, "locations", []).sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });
                    self.props.dispatch(incidentsActions.update_incidents_locations(locations));
                });
            });
        }

        get_incident_options("").then(function (success) {
            success.json().then(success => {
                var incident_types = safe_get(success, "data.incident_types", []);
                var case_classifications = safe_get(success, "data.case_classifications", []);
                var illnesses = safe_get(success, "data.incident_illnesses", []);
                var injuries = safe_get(success, "data.incident_injuries", []);
                var incident_causes = safe_get(success, "data.incident_causes", []);
                var event_types = safe_get(success, "data.event_types", []);


                var count = 0
                for (var i in event_types) {
                    if (!event_types[i].enabled) {
                        count += 1
                    }
                }
                var event_types_disabled = count === event_types.length;

                self.setState({
                    incident_types: incident_types,
                    case_classifications: case_classifications,
                    illnesses: illnesses,
                    injuries: injuries,
                    incident_causes: incident_causes,
                    event_types: event_types,
                    event_types_disabled: event_types_disabled
                });
            });
        }, function (error) {
            console.log(error)
        });

        get_forms("?team_uuid=my_teams&all=true&form_types=incident&enabled=true").then(function (success) {
            success.json().then(success => {
                var incident_forms = safe_get(success, "forms", []);

                self.setState({
                    incident_forms: incident_forms,
                });
            })
        }, function (error) {
            console.log(error);
        });

        get_custom_fields()
            .then(response => response.json())
            .then(customFields => {
                const customField1 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_1);
                if (customField1) {
                    this.setState({customField1});
                }
                const customField2 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_2);
                if (customField2) {
                    this.setState({customField2});
                }
                const customField3 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_3);
                if (customField3) {
                    this.setState({customField3});
                }
                const customField4 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_4);
                if (customField4) {
                    this.setState({customField4});
                }
            })
            .catch(error => {
                console.error(error);
            });

        this.setState({location_uuid: location_uuid}, () => {
            if (this.props.location.pathname.includes("new_incident")) {
                this.createIncident();
            } else {
                this.getIncident(incident_uuid);
                this.getComments(incident_uuid);
            }
        });


    }

    componentWillUnmount() {
        showFooterMobileApp();
    }

    onNavBlock = (tx) => {
        let self = this;

        this.setState({navPath: safe_get(tx, "pathname", "/")});

        if (this.state.allowNav) {
            return true;
        } else if (this.state.has_been_updated && this.state.is_draft) {
            this.setState({showSaveDraftModal: true});
            return false;
        } else if (!this.state.has_been_updated && this.state.is_draft) {
            this.confirmDiscardDraft();
            return false
        } else {
            return true;
        }
    }

    createIncident() {
        var self = this;
        var body = this.generateFormBody();
        create_incident(body).then(function (success) {
            success.json().then(success => {
                var incident_uuid = safe_get(success, "incident_uuid", "");
                self.setState({incident_uuid: incident_uuid}, () => {
                    self.props.history.push("/" + self.props.organization.organization_uuid + "/home/incidents/location/" + self.state.location_uuid + "/edit_incident/" + incident_uuid + "/1");
                });
            });
        }, function (error) {
            console.log(error);
        });
    }

    getIncident(incident_uuid) {
        var self = this;

        this.props.history.block(this.onNavBlock);

        get_incident(incident_uuid).then(function (success) {
            success.json().then(success => {

                let street_address = safe_get(success, "incident.street_address", "");
                let city = safe_get(success, "incident.city", "");
                let state = safe_get(success, "incident.state", "");
                let zip = safe_get(success, "incident.zip", "");

                let health_care_street = safe_get(success, "incident.health_care_street", "");
                let health_care_city = safe_get(success, "incident.health_care_city", "");
                let health_care_state = safe_get(success, "incident.health_care_state", "");
                let health_care_zip = safe_get(success, "incident.health_care_zip", "");

                var corrective_actions = safe_get(success, "incident.corrective_actions", []).filter(function (obj) {
                    return obj.text !== ""
                })

                self.setState({
                    loadingIncident: false,
                    has_been_updated: true,
                    incident: safe_get(success, "incident", {}),
                    incident_uuid: incident_uuid,
                    location_uuid: safe_get(success, "incident.location_uuid", ""),
                    locationName: safe_get(success, "incident.location_name", ""),
                    full_name: safe_get(success, "incident.full_name", ""),
                    street_address: street_address,
                    city: city,
                    state: state,
                    zip: zip,
                    health_care_street: health_care_street,
                    health_care_city: health_care_city,
                    health_care_state: health_care_state,
                    health_care_zip: health_care_zip,
                    job_title: safe_get(success, "incident.job_title", ""),
                    date_of_birth: safe_get(success, "incident.date_of_birth", null),
                    date_hired: safe_get(success, "incident.date_hired", null),
                    sex: safe_get(success, "incident.sex", null),
                    case_number: safe_get(success, "incident.case_number", ""),
                    incident_type: safe_get(success, "incident.incident_type", null),
                    date_of_injury: safe_get(success, "incident.date_of_injury", null),
                    time_began_work: safe_get(success, "incident.time_began_work", null),
                    event_location: safe_get(success, "incident.event_location", ""),
                    event_time: safe_get(success, "incident.event_time", null),
                    event_time_determined: safe_get(success, "incident.event_time_determined", undefined),
                    date_of_death: safe_get(success, "incident.date_of_death", null),
                    before_incident: safe_get(success, "incident.before_incident", ""),
                    where_occurred: safe_get(success, "incident.where_occurred", ""),
                    what_happened: safe_get(success, "incident.what_happened", ""),
                    what_body_part: safe_get(success, "incident.what_body_part", ""),
                    what_object: safe_get(success, "incident.what_object", ""),
                    health_care_name: safe_get(success, "incident.health_care_name", ""),
                    health_care_facility: safe_get(success, "incident.health_care_facility", ""),
                    emergency_room_used: safe_get(success, "incident.emergency_room_used", false),
                    hospitalized: safe_get(success, "incident.hospitalized", false),
                    case_classification: safe_get(success, "incident.case_classification", undefined),
                    on_restriction_days: safe_get(success, "incident.on_restriction_days", undefined),
                    away_from_job_days: safe_get(success, "incident.away_from_job_days", undefined),
                    incident_injury: safe_get(success, "incident.incident_injury", {}),
                    incident_injury_uuid: safe_get(success, "incident.incident_injury.incident_injury_uuid", undefined),
                    incident_illness: safe_get(success, "incident.incident_illness", undefined),
                    incident_cause: safe_get(success, "incident.incident_cause", {}),
                    incident_cause_uuid: safe_get(success, "incident.incident_cause.incident_cause_uuid", null),
                    corrective_actions: corrective_actions,
                    attachments: safe_get(success, "incident.attachments", []),
                    body_front_parts: safe_get(success, "incident.body_front_parts", []),
                    body_front_parts_info: safe_get(success, "incident.body_front_parts_info", {}),
                    body_back_parts: safe_get(success, "incident.body_back_parts", []),
                    body_back_parts_info: safe_get(success, "incident.body_back_parts_info", {}),
                    related_post: safe_get(success, "incident.related_post", {}),
                    event_type_uuid: safe_get(success, "incident.event_type.event_type_uuid", ""),
                    form_submissions: safe_get(success, "incident.form_submissions", []),
                    is_draft: safe_get(success, "incident.draft", false),
                    subscribers: safe_get(success, 'incident.subscribers', []),
                    custom_field_1_uuid: safe_get(success, 'incident.custom_field_1.uuid', null),
                    custom_field_2_uuid: safe_get(success, 'incident.custom_field_2.uuid', null),
                    custom_field_3_uuid: safe_get(success, 'incident.custom_field_3.uuid', null),
                    custom_field_4_uuid: safe_get(success, 'incident.custom_field_4.uuid', null),
                });

            });
        }, function (error) {
            if (error.status === 422) {
                error.json().then(error2 => {
                    let error_msgs = safe_get(error2, "error_messages", [])
                    let error = safe_get(error_msgs[0], "message", "incident not found");
                    self.setState({error_message: error});
                })
            }
            self.setState({incident_error: true, loadingIncident: false});
        });
    }

    async getManagers(incident_uuid) {
        try {
            const res = await get_incident_managers(incident_uuid);
            const data = await res.json();
            const {managers} = data;
            this.setState({managers});
        } catch (err) {
            this.setState({managers: []});
        }
    }

    async getComments(incident_uuid) {
        try {
            const res = await get_incident_comments(incident_uuid);
            const comments = await res.json();
            this.setState({comments});
        } catch (err) {
            this.setState({comments: []});
        }
    }

    onInputChange(name, value) {

        let locationName = this.state.locationName || '';

        if (name === 'location_uuid') {
            let location = this.props.incident_locations.find(loc => loc.location_uuid === value);
            if (location) {
                locationName = safe_get(location, 'name', '');
            }
            this.setState({subscribers: []});
        }

        this.setState({
            [name]: value,
            showSavingText: true,
            showSavedText: false,
            locationName: locationName
        });

        clearTimeout(saveContentTimeout);
        saveContentTimeout = setTimeout(this.updateIncident, 1000);
    }

    updateAttachments(attachments) {
        this.setState({attachments: attachments}, () => {
            setTimeout(() => {
                this.updateIncident();
            }, 750);
        });
    }

    handleContinue() {
        var self = this
        if (this.props.current_location.location_uuid !== this.state.location_uuid) {
            var locations = this.props.incident_locations;
            var location = locations.find(function (obj) {
                return obj.location_uuid === self.state.location_uuid
            });

            if (location) {
                this.props.dispatch(incidentsActions.update_current_location(location));
            }
        }

        if (this.state.is_draft && !isAdminOrTeamLead(this.props.user)) {
            this.setState({showConfirmSubmitModal: true});
        } else {
            if (!this.state.updatingIncident) {
                this.setState({submitting: true, allowNav: true}, () => {
                    this.updateIncident(true);
                });
            }
        }
    }

    handleBack() {
        var self = this
        if (this.props.current_location.location_uuid !== this.state.location_uuid) {
            var locations = this.props.incident_locations;
            var location = locations.find(function (obj) {
                return obj.location_uuid === self.state.location_uuid
            });
        }

        self.props.history.push("/" + self.props.organization.organization_uuid + "/home/incidents/location/all_locations");

    }

    generateFormBody(finished = false) {
        var corrective_actions = [...this.state.corrective_actions].filter(function (obj) {
            return obj.text !== ""
        })
        var body = {
            location_uuid: this.state.location_uuid,
            full_name: this.state.full_name,
            job_title: this.state.job_title,
            health_care_name: this.state.health_care_name,
            health_care_facility: this.state.health_care_facility,
            health_care_street: this.state.health_care_street,
            health_care_city: this.state.health_care_city,
            health_care_state: this.state.health_care_state,
            case_number: this.state.case_number,
            incident_type: this.state.incident_type,
            before_incident: this.state.before_incident,
            where_occurred: this.state.where_occurred,
            what_happened: this.state.what_happened,
            what_body_part: this.state.what_body_part,
            what_object: this.state.what_object,
            street_address: this.state.street_address,
            city: this.state.city,
            state: this.state.state,
            sex: this.state.sex,
            zip: this.state.zip,
            health_care_zip: this.state.health_care_zip,
            event_time_determined: this.state.event_time_determined,
            emergency_room_used: this.state.emergency_room_used,
            hospitalized: this.state.hospitalized,
            time_began_work: this.state.time_began_work,
            event_time: this.state.event_time,
            event_location: this.state.event_location,
            date_of_birth: this.state.date_of_birth,
            date_hired: this.state.date_hired,
            date_of_injury: this.state.date_of_injury,
            date_of_death: this.state.date_of_death,
            incident_illness: this.state.incident_illness || null,
            incident_injury: this.state.incident_injury,
            incident_injury_uuid: this.state.incident_injury_uuid,
            incident_cause_uuid: this.state.incident_cause_uuid,
            event_type_uuid: this.state.event_type_uuid,
            away_from_job_days: this.state.away_from_job_days,
            on_restriction_days: this.state.on_restriction_days,
            case_classification: this.state.case_classification,
            corrective_actions: corrective_actions,
            attachments: this.state.attachments,
            body_front_parts: this.state.body_front_parts,
            body_back_parts: this.state.body_back_parts,
            finished: finished,
            custom_field_1: this.state.custom_field_1_uuid,
            custom_field_2: this.state.custom_field_2_uuid,
            custom_field_3: this.state.custom_field_3_uuid,
            custom_field_4: this.state.custom_field_4_uuid,

        };

        if (this.state.incident?.status === IncidentStatus.DRAFT) {
            body['subscriber_uuids'] = this.state.subscribers.map(user => user.uuid);
        }

        return JSON.stringify(body);
    }

    updateIncident(finished = false, confirmSaveDraft = false) {
        var self = this;
        if (!this.state.updatingIncident) {
            var body = this.generateFormBody(finished);
            this.setState({updatingIncident: true, showSavingText: true, showSavedText: false});

            if (finished || confirmSaveDraft) {
                var location = {"location_uuid": "all_locations"};
                self.props.dispatch(incidentsActions.update_current_location(location));
            }

            update_incident(this.state.incident_uuid, body).then(function (success) {
                success.json().then(success => {
                    self.setState({
                        showSavedText: true,
                        showSavingText: false,
                        incident: safe_get(success, "incident", {}),
                        case_number: safe_get(success, "incident.case_number", ""),
                        body_front_parts_info: safe_get(success, "incident.body_front_parts_info", {}),
                        body_back_parts_info: safe_get(success, "incident.body_back_parts_info", {}),
                        attachments: safe_get(success, "incident.attachments", []),
                        form_submissions: safe_get(success, "incident.form_submissions", []),
                        updatingIncident: false,
                        has_been_updated: true
                    });

                    if (!safe_get(success, "incident.draft", false)) {
                        self.getComments(self.state.incident_uuid);
                    }

                    if (finished) {
                        NotificationAlert("success", "", `Incident ${self.state.is_draft ? "submitted" : "updated"}.`);
                        self.props.history.push("/" + self.props.organization.organization_uuid + "/home/incidents/location/all_locations");
                    } else if (confirmSaveDraft) {
                        NotificationAlert("success", "", "Incident saved.");
                        self.props.history.push("/" + self.props.organization.organization_uuid + "/home/incidents/location/all_locations");
                    }

                });
            }, function (error) {
                NotificationAlert("success", "", "Unable to update incident.")
                self.setState({
                    updatingIncident: false,
                    showSavedText: false,
                    showSavingText: false,
                    submitting: false
                });
            });
        }

    }

    confirmSaveDraft = () => {
        this.setState({allowNav: true}, () => {
            this.updateIncident(false, true);
        });
    }

    confirmSubmit = () => {
        this.setState({allowNav: true}, () => {
            this.updateIncident(true);
        });
    }

    confirmDiscardDraft = () => {
        let self = this;
        delete_incident(this.state.incident_uuid).then(() => {
            self.setState({allowNav: true}, () => {
                if (self.state.navPath) {
                    self.props.history.push(self.state.navPath);
                } else {
                    self.props.history.push("/" + self.props.organization.organization_uuid + "/home/incidents/location/all_locations");
                }
            });
        }, () => {
            NotificationAlert("", "", "Unable to discard draft.")
        });
    }

    onSubscribersChanged = (uuids) => {
        const subscribers = this.state.managers.filter(user => uuids.includes(user.uuid));

        this.setState({
            subscribers,
            showSavingText: true,
            showSavedText: false,
        });

        clearTimeout(saveContentTimeout);
        saveContentTimeout = setTimeout(this.updateIncident, 1000);
    }

    moveIncident = async (location_uuid) => {
        try {
            const {incident_uuid} = this.state.incident;
            const body = JSON.stringify({
                location_uuid
            });

            await move_incident(incident_uuid, body);
            NotificationAlert("success", "", "Incident moved.");
            this.getIncident(incident_uuid);
            this.getComments(incident_uuid);
        } catch (err) {
            NotificationAlert("error", "", "Unable to move incident.")
        } finally {
            this.setState({showMoveModal: false});
        }
    }

    render() {
        const fieldsDisabled = !this.state.print && this.state.incident?.status === IncidentStatus.CLOSED;
        const isDraft = this.state.incident?.status === IncidentStatus.DRAFT;

        return (
            <div>
                {
                    this.state.showSaveDraftModal &&
                    <ConfirmModal
                        show={this.state.showSaveDraftModal}
                        cancel={() => {
                            this.setState({showSaveDraftModal: false});
                        }}
                        cancelAction={this.confirmDiscardDraft}
                        confirm={this.confirmSaveDraft}
                        title={"Save draft?"}
                        body={"This incident has not been submitted and contains unsaved changes. You can save it as a draft to submit later."}
                        cancelButtonName="Delete Draft"
                        confirmButtonName="Save Draft"
                    />
                }
                {
                    this.state.showConfirmSubmitModal &&
                    <ConfirmModal
                        show={this.state.showConfirmSubmitModal}
                        cancel={() => {
                            this.setState({showConfirmSubmitModal: false});
                        }}
                        cancelAction={() => {
                            this.setState({showConfirmSubmitModal: false});
                        }}
                        confirm={this.confirmSubmit}
                        title={"Confirmation"}
                        body={"Once you submit this incident, you will no longer be able to edit it. If you need to make further edits to this incident, please save a draft instead. Only managers will have access to this incident once you submit it."}
                        cancelButtonName="Cancel"
                        confirmButtonName="Confirm Submit"
                    />
                }
                {
                    this.state.showDeleteModal &&
                    <DeleteIncidentModal
                        incident={this.state.incident}
                        show={this.state.showDeleteModal}
                        cancel={() => {
                            this.setState({showDeleteModal: false});
                        }}
                        successCallback={() => this.props.history.push(`/${this.props.organization.organization_uuid}/home/incidents/location/all_locations`)}
                    />
                }
                {
                    this.state.showExportModal &&
                    <ExportIncidentModal
                        show={this.state.showExportModal}
                        onModalClosed={() => {
                            this.setState({showExportModal: false});
                        }}
                        incident={this.state.incident}
                    />
                }
                {
                    this.state.showMoveModal &&
                    <MoveIncidentModal
                        show={this.state.showMoveModal}
                        cancel={() => {
                            this.setState({showMoveModal: false});
                        }}
                        locations={this.props.incident_locations}
                        source_location={this.state.incident.location_uuid}
                        confirm={this.moveIncident}
                        canMove={isAdmin(this.props.user)}
                    />
                }

                <div className={mainContentContainerClass() + ' print-7in'}>
                    {
                        !this.state.incident_error &&
                        <div id="page-head" className="no-padding-mobile no-padding-print">
                            <div className="row">
                                <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                    <div id="page-title" style={{padding: '0px'}}>
                                        <div className="panel thin-border" style={{margin: '0 auto'}}>

                                            <div className="nav-header-panel no-padding-print">
                                                <div className="team-name-header" style={{display: "flex", justifyContent: "space-between"}}>
                                                    <h3 className={"section-titles admin-page-header  dont-print"}
                                                        style={{display: "inline-block", margin: "0px"}}>
                                                        { this.state.loadingIncident ? 
                                                            (
                                                                <Skeleton width={195}/>
                                                            ) : (
                                                                <>
                                                                    <>Incident Report&nbsp;</>
                                                                    { this.state.is_draft &&
                                                                        <IncidentStatusTag incident={this.state.incident}/>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </h3>

                                                    { this.state.is_draft &&
                                                        <div className="dont-print hidden-xs">
                                                            <SaveText
                                                                showSavedText={this.state.showSavedText}
                                                                showSavingText={this.state.showSavingText}
                                                            />
                                                        </div>
                                                    }

                                                    { !this.state.loadingIncident && !this.state.is_draft &&
                                                        <IncidentStatusSelect
                                                            incident={this.state.incident}
                                                            onStatusUpdated={(newStatus) => {
                                                                this.setState({
                                                                    incident: {
                                                                        ...this.state.incident,
                                                                        status: newStatus
                                                                    }
                                                                });
                                                                this.getComments(this.state.incident_uuid);
                                                                NotificationAlert('success', '', 'Status updated.');
                                                            }}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    this.state.loadingIncident &&
                                                    <Skeleton/>
                                                }
                                                {
                                                    !this.state.loadingIncident &&
                                                    <p className="header  dont-print" style={{
                                                        color: "#505050",
                                                        marginBottom: "0px",
                                                        paddingLeft: "2px",
                                                        whiteSpace: "pre-line"
                                                    }}>
                                                        Case Number: {this.state.case_number}.<br/>
                                                        {this.state.incident.submitted_at &&
                                                            <>Submitted
                                                                by {safe_get(this.state, "incident.created_by.first_name", "")} {safe_get(this.state, "incident.created_by.last_name", "")} on {dateFormatterWithTime(safe_get(this.state, "incident.submitted_at", ""), this.state.tzOverride)}. </>
                                                        }
                                                        {this.state.incident.edited_by &&
                                                            <>Last edited
                                                                by {this.state.incident.edited_by.first_name} {this.state.incident.edited_by.last_name} at {dateFormatterWithTime(this.state.incident.edited_at)}.</>
                                                        }
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div id="page-content" className="no-padding-mobile no-padding-print" style={{paddingTop: "10px"}}>
                        <div className="row">
                            <div className="col-lg-12 col-lg-offset-0 col-xs-12 col-xs-offset-0">
                                {
                                    this.state.loadingIncident &&
                                    <div className="" style={{margin: '0 auto'}}>
                                        <div className="panel bulletin-make-border incident"
                                             style={{margin: "0", maxWidth: 'unset'}}>
                                            <div className="panel-body bulletin-post">
                                                <Skeleton height={350}/>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    !this.state.loadingIncident && this.state.incident_error &&
                                    <div className="panel bulletin-post-border" style={{marginTop: "10px"}}>
                                        <img src={error_img} alt="error_img" style={{
                                            width: "150px",
                                            display: "block",
                                            margin: "auto",
                                            paddingTop: "10px"
                                        }}/>
                                        <h4 className="mar-btm zero-blue text-center">Sorry, {this.state.error_message}.</h4>
                                        <button className="btn btn-primary" style={{
                                            marginTop: "8px",
                                            display: "block",
                                            margin: "auto",
                                            marginBottom: "10px"
                                        }} onClick={() => {
                                            window.location = "/"
                                        }}>Back to ZERO
                                        </button>
                                    </div>
                                }

                                {
                                    !this.state.loadingIncident && !this.state.incident_error &&
                                    <div className="" style={{margin: '0 auto'}}>
                                        <div className="panel bulletin-make-border incident"
                                             style={{margin: "0", maxWidth: 'unset'}}>
                                            <div className="panel-body bulletin-post">

                                                <div className="print-only incident-cover-sheet">
                                                    <img src={logo} alt="Zerologo"
                                                         style={{height: "60px", marginBottom: "20px"}}/>
                                                    <h4>Incident Report</h4>
                                                    <div>Case Number: {this.state.case_number}</div>
                                                    <div>Location: {this.state.locationName}</div>
                                                    <div>Submitted
                                                        By: {this.state.incident.created_by.first_name} {this.state.incident.created_by.last_name}</div>
                                                    <div>Submitted
                                                        On: {dateFormatterWithTime(this.state.incident.submitted_at, this.state.tzOverride)}</div>
                                                    <div>Exported
                                                        On: {dateFormatterWithTime(Date.now() / 1000, this.state.tzOverride)}</div>
                                                </div>

                                                <div>
                                                    <AlternateWrapper
                                                        MainWrapper={Tabs}
                                                        useAltWrapper={this.state.print || isDraft}
                                                        defaultActiveKey="1"
                                                        destroyInactiveTabPane={true}
                                                        tabBarExtraContent={
                                                            <div style={{display: 'flex', gap: '0.5rem', alignItems: 'baseline'}}>
                                                                <div className="dont-print">
                                                                    <SaveText
                                                                        showSavedText={this.state.showSavedText}
                                                                        showSavingText={this.state.showSavingText}
                                                                    />
                                                                </div>
                                                                <IncidentOptionsMenu
                                                                    incident={this.state.incident}
                                                                    user={this.props.user}
                                                                    onDelete={() => {
                                                                        this.setState({showDeleteModal: true})
                                                                    }}
                                                                    onExport={() => {
                                                                        this.setState({showExportModal: true})
                                                                    }}
                                                                    onMove={() => {
                                                                        this.setState({showMoveModal: true})
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    >
                                                        <AlternateWrapper MainWrapper={Tabs.TabPane}
                                                                          useAltWrapper={this.state.print || isDraft}
                                                                          tab="Incident" key="1">
                                                            <PrintSafeWrapper NonPrintWrapper={Collapse}
                                                                              printView={this.state.print}
                                                                              className="incident-collapse"
                                                                              onChange={(key) => {
                                                                                  this.setState({activeKeys: key})
                                                                              }}>
                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Case Info" key={1}>
                                                                    <div className="incident-block">
                                                                        <CaseInfo
                                                                            onChange={this.onInputChange}
                                                                            location_uuid={this.state.location_uuid}
                                                                            incident_locations={this.props.incident_locations}
                                                                            case_number={this.state.case_number}
                                                                            incident_types={this.state.incident_types}
                                                                            incident_type={this.state.incident_type}
                                                                            event_types={this.state.event_types}
                                                                            event_types_disabled={this.state.event_types_disabled}
                                                                            event_type_uuid={this.state.event_type_uuid}
                                                                            date_of_injury={this.state.date_of_injury}
                                                                            time_began_work={this.state.time_began_work}
                                                                            event_location={this.state.event_location}
                                                                            event_time={this.state.event_time}
                                                                            event_time_determined={this.state.event_time_determined}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                            isDraft={isDraft}
                                                                            customField1={this.state.customField1}
                                                                            customField2={this.state.customField2}
                                                                            customField3={this.state.customField3}
                                                                            customField4={this.state.customField4}
                                                                            custom_field_1_uuid={this.state.custom_field_1_uuid}
                                                                            custom_field_2_uuid={this.state.custom_field_2_uuid}
                                                                            custom_field_3_uuid={this.state.custom_field_3_uuid}
                                                                            custom_field_4_uuid={this.state.custom_field_4_uuid}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Employee Info" key={2}>
                                                                    <div className="incident-block">
                                                                        <EmployeeInfo
                                                                            onChange={this.onInputChange}
                                                                            full_name={this.state.full_name}
                                                                            job_title={this.state.job_title}
                                                                            sex={this.state.sex}
                                                                            date_of_birth={this.state.date_of_birth}
                                                                            date_hired={this.state.date_hired}
                                                                            street_address={this.state.street_address}
                                                                            city={this.state.city}
                                                                            state={this.state.state}
                                                                            zip={this.state.zip}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Case Details" key={3}>
                                                                    <div className="incident-block">
                                                                        <CaseInfo2
                                                                            onChange={this.onInputChange}
                                                                            before_incident={this.state.before_incident}
                                                                            what_happened={this.state.what_happened}
                                                                            what_body_part={this.state.what_body_part}
                                                                            what_object={this.state.what_object}
                                                                            where_occurred={this.state.where_occurred}
                                                                            print={this.state.print}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Case Classification" key={4}>
                                                                    <div className="incident-block">
                                                                        <CaseClassification
                                                                            onChange={this.onInputChange}
                                                                            case_classification={this.state.case_classification}
                                                                            case_classifications={this.state.case_classifications}
                                                                            date_of_death={this.state.date_of_death}
                                                                            injuries={this.state.injuries}
                                                                            illnesses={this.state.illnesses}
                                                                            incident_injury={this.state.incident_injury}
                                                                            incident_injury_uuid={this.state.incident_injury_uuid}
                                                                            incident_illness={this.state.incident_illness}
                                                                            incident_cause={this.state.incident_cause}
                                                                            incident_causes={this.state.incident_causes}
                                                                            incident_cause_uuid={this.state.incident_cause_uuid}
                                                                            event_types={this.state.event_types}
                                                                            event_type_uuid={this.state.event_type_uuid}
                                                                            away_from_job_days={this.state.away_from_job_days}
                                                                            on_restriction_days={this.state.on_restriction_days}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Body Chart" key={5}>
                                                                    <div className="incident-block">
                                                                        <BodyPartSelect
                                                                            onChange={this.onInputChange}
                                                                            body_front_parts={this.state.body_front_parts}
                                                                            body_front_parts_info={this.state.body_front_parts_info}
                                                                            body_back_parts={this.state.body_back_parts}
                                                                            body_back_parts_info={this.state.body_back_parts_info}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Health Care Info" key={6}>
                                                                    <div className="incident-block">
                                                                        <HealthCareInfo
                                                                            onChange={this.onInputChange}
                                                                            health_care_name={this.state.health_care_name}
                                                                            health_care_facility={this.state.health_care_facility}
                                                                            health_care_city={this.state.health_care_city}
                                                                            health_care_state={this.state.health_care_state}
                                                                            health_care_street={this.state.health_care_street}
                                                                            health_care_zip={this.state.health_care_zip}
                                                                            emergency_room_used={this.state.emergency_room_used}
                                                                            hospitalized={this.state.hospitalized}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Attachments" key={7}>
                                                                    <div className="incident-block">
                                                                        <IncidentAttachments
                                                                            onChange={this.onInputChange}
                                                                            updateAttachments={this.updateAttachments}
                                                                            corrective_actions={this.state.corrective_actions}
                                                                            attachments={this.state.attachments}
                                                                            incident_uuid={this.state.incident_uuid}
                                                                            related_post={this.state.related_post}
                                                                            form_submissions={this.state.form_submissions}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                            isClosed={this.state.incident?.status === IncidentStatus.CLOSED}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                                <PrintSafeWrapper NonPrintWrapper={Collapse.Panel}
                                                                                  printView={this.state.print}
                                                                                  header="Corrective Actions and Notes"
                                                                                  key={8}>
                                                                    <div className="incident-block">
                                                                        <CorrectiveActions
                                                                            onChange={this.onInputChange}
                                                                            corrective_actions={this.state.corrective_actions}
                                                                            incident_uuid={this.state.incident_uuid}
                                                                            related_post={this.state.related_post}
                                                                            form_submissions={this.state.form_submissions}
                                                                            fieldsDisabled={fieldsDisabled}
                                                                            isClosed={this.state.incident?.status === IncidentStatus.CLOSED}
                                                                        />
                                                                    </div>
                                                                </PrintSafeWrapper>

                                                            </PrintSafeWrapper>
                                                            <div className="mar-top-10 incident-block">
                                                                <CustomForm
                                                                    onChange={this.onInputChange}
                                                                    incident_forms={this.state.incident_forms}
                                                                    form_submissions={this.state.form_submissions}
                                                                    incident_uuid={this.state.incident_uuid}
                                                                    updateIncident={this.updateIncident}
                                                                    fieldsDisabled={fieldsDisabled}
                                                                    print={this.state.print}
                                                                />
                                                            </div>

                                                            {isDraft &&
                                                                <div className="incident-block dont-print">
                                                                    <Subscribers
                                                                        subscribers={this.state.subscribers}
                                                                        availableSubscribers={this.state.managers}
                                                                        onSubscribersChanged={this.onSubscribersChanged}
                                                                    />
                                                                </div>
                                                            }
                                                        </AlternateWrapper>
                                                        {!isDraft &&
                                                            <PrintSafeWrapper NonPrintWrapper={Tabs.TabPane}
                                                                              printView={this.state.print}
                                                                              tab="Activity" key="2" forceRender={true}>
                                                                <div
                                                                    className={styles.activityFlexWrapper + " incident-block"}
                                                                    style={{paddingBottom: '2rem'}}>
                                                                    <IncidentDetails
                                                                        className={styles.statusSection}
                                                                        incident={this.state.incident}
                                                                        managers={this.state.managers}
                                                                        onCommentCreated={() => {
                                                                            this.getComments(this.state.incident_uuid);
                                                                            NotificationAlert('success', '', 'Comment added.');
                                                                        }}
                                                                        onStatusUpdated={(newStatus) => {
                                                                            this.setState({
                                                                                incident: {
                                                                                    ...this.state.incident,
                                                                                    status: newStatus
                                                                                }
                                                                            });
                                                                            this.getComments(this.state.incident_uuid);
                                                                            NotificationAlert('success', '', 'Status updated.');
                                                                        }}
                                                                    />
                                                                    <hr className={styles.timelineTopRule}/>
                                                                    <h3
                                                                        className="zero-blue print-only"
                                                                        style={{
                                                                            marginBottom: "20px",
                                                                            marginTop: "5px",
                                                                            fontSize: "120%",
                                                                            textDecoration: "underline"
                                                                        }}
                                                                    >Timeline</h3>
                                                                    <IncidentTimeline
                                                                        className={styles.timelineSection}
                                                                        comments={this.state.comments}
                                                                        submissions={this.state.form_submissions}
                                                                        options={{
                                                                            injuries: this.state.injuries,
                                                                            illnesses: this.state.illnesses,
                                                                            event_types: this.state.event_types,
                                                                            incident_types: this.state.incident_types,
                                                                            case_classifications: this.state.case_classifications,
                                                                            incident_causes: this.state.incident_causes,
                                                                            bodyParts: {...this.state.body_front_parts_info, ...this.state.body_back_parts_info},
                                                                            customField1: this.state.customField1,
                                                                            customField2: this.state.customField2,
                                                                            customField3: this.state.customField3,
                                                                            customField4: this.state.customField4,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </PrintSafeWrapper>
                                                        }
                                                    </AlternateWrapper>
                                                </div>
                                            </div>

                                            {
                                                !fieldsDisabled && !this.state.print &&
                                                <div className="panel clear" style={{
                                                    marginBottom: '5px',
                                                    marginTop: "10px",
                                                    textAlign: "right"
                                                }}>
                                                    <button className="btn btn-discard" style={{marginRight: "8px"}}
                                                            onClick={this.handleBack}>Cancel
                                                    </button>
                                                    <button className="btn btn-primary" onClick={this.handleContinue}>
                                                        {
                                                            this.state.submitting ?
                                                                <ButtonLoading/> : `${this.state.is_draft ? "Submit" : "Done"}`
                                                        }
                                                    </button>
                                                </div>
                                            }


                                        </div>
                                        {
                                            this.state.has_been_updated && this.state.is_draft &&
                                            <button className="ButtonLink btn-link pull-right mar-top-10 dont-print"
                                                    style={{paddingRight: "15px"}} onClick={() => {
                                                this.setState({showSaveDraftModal: true})
                                            }}>Save Draft</button>
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
        incident_locations: safe_get(store, "incidents.locations", []),
        current_location: safe_get(store, "incidents.location", {})

    }
}

export default withRouter(connect(mapStateToProps)(EditIncident));

