export default function safe_get(object, path, defaultValue) {
    let keys = path.split(".");
    var obj = object

    if (obj === undefined || obj === null || obj === "") {
        return defaultValue;
    } else {
        for (var i in keys) {
            let key = keys[i];

            if (obj === null || obj === undefined || obj === "" || !obj.hasOwnProperty(key)) {
                return defaultValue;
            } else {
                obj = obj[key];
            }
        }

        if (obj === undefined || obj === null || obj === "") {
            return defaultValue;
        }

        return obj;
    }
}