import React, {Component} from 'react';

import {Popover} from 'antd'

import {IoMdCheckmark as Check, IoMdClose as Close} from 'react-icons/io'


class PasswordRequirementsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upperCase: <Close color="red"/>,
            lowerCase: <Close color="red"/>,
            number: <Close color="red"/>,
            specialChar: <Close color="red"/>,
            minChars: <Close color="red"/>,
            passwordCheck: false,
            popoverVisible: false
        };
        this.passwordCheck = this.passwordCheck.bind(this);
    }

    passwordCheck(event) {
        let password = event.target.value;

        var hasNumber = <Close color="red"/>
        var hasUpperCase = <Close color="red"/>
        var hasLowerCase = <Close color="red"/>
        var hasSpecial = <Close color="red"/>
        var minChars = <Close color="red"/>

        var allPassed = true;

        if (password.length >= 8) {
            minChars = <Check color="#008C1B"/>
        } else {
            allPassed = false;
        }

        if (password.match(/\d+/g) !== null) {
            hasNumber = <Check color="#008C1B"/>;
        } else {
            allPassed = false;
        }

        if (password.match("^(?=.*[A-Z])") !== null) {
            hasUpperCase = <Check color="#008C1B"/>;
        } else {
            allPassed = false;
        }

        if (password.match("^(?=.*[a-z])") !== null) {
            hasLowerCase = <Check color="#008C1B"/>;
        } else {
            allPassed = false;
        }

        if (password.match(/[^\w\s]/gi) !== null) {
            hasSpecial = <Check color="#008C1B"/>;
        } else {
            allPassed = false;
        }

        var newState = {
            passwordCheck: allPassed,
            upperCase: hasUpperCase,
            lowerCase: hasLowerCase,
            number: hasNumber,
            specialChar: hasSpecial,
            minChars: minChars,
            password_value: password
        };

        if (allPassed) {
            newState['popoverVisible'] = false;
        }

        this.props.passwordMeetsRequirementsCallback(allPassed, password);
        this.setState(newState)
    }

    handleVisibleChange = (popoverVisible) => {
        this.setState({popoverVisible});
    }

    render() {
        var style = {maxWidth: (this.props.noMaxWidth ? '320px' : '250px'), margin: "0 auto"}
        if (this.props.customStyle) {
            style = this.props.customStyle
        }
        var placeholder = this.props.placeholder !== undefined ? this.props.placeholder : "Password"

        return (
            <Popover
                content={
                    <div>
                        {this.state.upperCase} 1 Uppercase Letter <br/>
                        {this.state.lowerCase} 1 Lowercase Letter <br/>
                        {this.state.number} 1 Number<br/>
                        {this.state.specialChar} 1 Special Character/Symbol<br/>
                        {this.state.minChars} 8 Characters Minimum
                    </div>
                }
                title="Password Requirements"
                trigger="focus"
                placement="bottom"
                open={this.state.popoverVisible}
                onOpenChange={this.handleVisibleChange}
                style={{zIndex: "10000000"}}>
                <input
                    value={this.state.password_value}
                    type="password"
                    placeholder={placeholder}
                    onChange={this.passwordCheck}
                    name="Password"
                    id="password"
                    className={"form-control signup-field custom-placeholder "} style={style} autoComplete="off"/>

            </Popover>
        );
    }
}

export default PasswordRequirementsPopup;