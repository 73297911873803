import React, {Component} from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router';


import * as userActions from '../store/actions/UserActions';
import * as teamsActions from '../store/actions/TeamsActions';
import * as organizationActions from '../store/actions/OrganizationActions';
import * as notificationActions from '../store/actions/NotificationsActions';
import * as dashboardActions from '../store/actions/DashboardActions';
import * as incidentActions from '../store/actions/IncidentsActions';
import * as feedHelperActions from '../store/actions/FeedHelperActions';
import * as libraryHelperActions from '../store/actions/LibraryActions';

import {
    login,
    update_auth_token,
    update_organization_uuid,
    oauth_login,
    update_redirect_route,
    deleteCookies,
    isAuthTokenForKiosk
} from '../api/zero-api.js';

import {safe_get, isIE} from '../other/Helper'
import {msalInstance, loginRequest} from '../other/MSAL_Config';

import {Alert} from 'antd';

import ButtonLoading from '../components/Shared/ButtonLoading';
import LoadingIndicator from '../components/Shared/LoadingIndicator';

import '../assets/css/Login.css';
import logo from '../assets/css/img/ZERO-(white)-small.png';
import AppStore from '../assets/css/img/AppStore.svg'
// import GoogleLogin from '../assets/css/img/btn_google_signin_light_normal_web@2x.png';
import {ReactComponent as MSLogin} from '../assets/css/img/ms_signin_light.svg';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectRoute: "",
            loadingOAuth: false,
            user: {},
            team: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;

        if (this.props.kiosk_mode && !search.includes("?from_401=true")) {
            this.props.history.replace("/");
            return;
        }

        if (window.location.href.includes("/login#")) {
            this.setState({loadingOAuth: true});
        }

        if (search.includes("?emailError=")) {
            var errorMsg = search.split("?emailError=").pop();
            this.setState({emailError: decodeURI(errorMsg)});
        }

        if (search.includes("?pwError=")) {
            var errorMsg = search.split("?pwError=").pop();
            this.setState({pwError: decodeURI(errorMsg)});
        }

        if (search.includes("?loginError=")) {
            var errorMsg = search.split("?loginError=").pop();
            this.setState({loginError: decodeURI(errorMsg)});
        }

        if (search.includes("?email=")) {
            var email = search.split("?email=").pop();
            this.refs.email.value = email
        }

        if (this.props.location.pathname.includes("/redirect=")) {
            var path = this.props.location.pathname.split("/redirect=").pop();
            if (path.includes("kiosk")) {
                path = "";
            }
            update_redirect_route(path);
            this.setState({redirectRoute: path});
        }

        update_auth_token(undefined);
        update_organization_uuid("");

        this.props.dispatch(organizationActions.reset());
        this.props.dispatch(teamsActions.reset_teams());
        this.props.dispatch(userActions.reset_user());
        this.props.dispatch(notificationActions.reset_notifications());
        this.props.dispatch(dashboardActions.reset_assignments());
        this.props.dispatch(feedHelperActions.update_feed_tab("posts"));
        this.props.dispatch(feedHelperActions.update_feed_query(""));
        this.props.dispatch(feedHelperActions.update_date_query(""));
        this.props.dispatch(feedHelperActions.update_feed_statuses([], 1));
        this.props.dispatch(feedHelperActions.update_feed_statuses([], 2));
        this.props.dispatch(feedHelperActions.update_feed_tags([]));
        this.props.dispatch(feedHelperActions.toggle_feed_view("posts"));
        this.props.dispatch(incidentActions.reset({}));
        this.props.dispatch(libraryHelperActions.update_topics([]));

        if (!window.FreshworksWidget) {
            const script = document.createElement("script");
            script.async = true;
            script.src = "https://widget.freshworks.com/widgets/70000002508.js";
            window.fwSettings = {'widget_id': '70000002508', 'locale': 'en'};
            document.head.appendChild(script);
            script.onload = function () {
                !function () {
                    if ("function" != typeof window.FreshworksWidget) {
                        var n = function () {
                            n.q.push(arguments)
                        };
                        n.q = [], window.FreshworksWidget = n
                    }
                }();
            }
        } else {
            window.FreshworksWidget('show', 'launcher');
        }
    }

    enableFreshdesk() {
        FreshworksWidget('identify', 'ticketForm', {
            name: safe_get(this.props, "user.full_name", ""),
            // email: safe_get(this.props, "user.email", ""),
            email: safe_get(this.props, "user.email", "")
        });
        FreshworksWidget('open');
    }


    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.api_loading) {
            var self = this;
            this.setState({api_loading: true})

            var body = JSON.stringify({
                email: document.getElementById('email').value,
                password: document.getElementById('password').value
            });

            login(body).then(function (success) {
                success.json().then(success => {

                    update_auth_token(safe_get(success, "jwt", undefined));
                    var user = safe_get(success, "user", {});
                    self.props.dispatch(userActions.update_current_user(user));
                    self.props.dispatch(dashboardActions.updateLoadingDashboard(true));

                    if (self.state.redirectRoute !== "" && self.state.redirectRoute !== "/login") {
                        self.props.history.push("/?redirect="+self.state.redirectRoute);
                    } else {
                        self.props.history.push("/");
                    }
                });
            }, function (error) {
                if (error.status === 422) {
                    error.json().then(error => {
                        const newState = {
                            pwError: null,
                            emailError: null,
                            loginError: null,
                            api_loading: false,
                            loadingOAuth: false,
                        };
                        let error_messages = safe_get(error, "error_messages", []);
                        if (error_messages.length !== 0) {
                            let errorField = safe_get(error_messages[0], "field", "");
                            let errorMsg = safe_get(error_messages[0], "message", "");
                            let errorCode = safe_get(error_messages[0], 'code', '');

                            if (errorCode === "pending-user") {
                                newState["emailError"] = <>
                                    Your profile is in a pending state.
                                    Please contact an Administrator on your account or reach out to
                                    {" "}<a className="blue-link" href={"mailto:support@teamzero.com"}>support@teamzero.com</a>.
                                </>;
                            } else if (errorField === "password") {
                                newState["pwError"] = errorMsg;
                            } else if (errorField === "email") {
                                newState["emailError"] = errorMsg;
                            } else {
                                newState["loginError"] = errorMsg
                            }
                            self.setState(newState);
                        }

                    });
                }

            });
        }

    }

    signInWithMS = () => {
        // msalInstance.acquireTokenRedirect(loginRequest); 
        this.props.history.push("/oauth_login");
    }

    microsoftAuthCallback = (error, response) => {
        console.log(error)
        console.log(response)
        this.setState({loadingOAuth: true});
        // Handle redirect response
        if (!error && response) {
            this.zeroLoginWithMS(response);
        } else if (error) {
            // console.log(error)
            this.props.history.push("/login");
            this.setState({loadingOAuth: false});
        }
    }

    zeroLoginWithMS = (loginResponse) => {
        var self = this;

        this.setState({loadingOAuth: true});

        deleteCookies();

        let body = JSON.stringify({
            access_token: loginResponse.accessToken,
            id_token: loginResponse.idToken,
            social_type: "microsoft-graph"
        });

        oauth_login(body).then(function (success) {
            success.json().then(success => {
                update_auth_token(safe_get(success, "jwt", undefined));

                var user = safe_get(success, "user", {});
                self.props.dispatch(userActions.update_current_user(user));

                if (self.state.redirectRoute && self.state.redirectRoute !== "" && self.state.redirectRoute !== "/login") {
                    self.props.history.push(self.state.redirectRoute);
                } else {
                    self.props.history.push("/");
                }

            });
        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {
                    var newState = {};
                    let error_messages = safe_get(error, "error_messages", []);
                    if (error_messages.length !== 0) {
                        let errorField = safe_get(error_messages[0], "field", "");
                        let errorMsg = safe_get(error_messages[0], "message", "");

                        if (errorField === "password") {
                            newState["pwError"] = errorMsg;
                            newState["emailError"] = null;
                        } else if (errorField === "email") {
                            newState["emailError"] = errorMsg;
                            newState["pwError"] = null;
                        } else {
                            newState["pwError"] = null;
                            newState["emailError"] = null;
                            newState["loginError"] = errorMsg;
                        }
                        newState["api_loading"] = false;
                        newState["loadingOAuth"] = false;
                        self.setState(newState);
                    }
                });
            }
        });
    }


    // signInWithMS = () => {
    //   var self = this;
    //   myMSALObj.loginPopup(loginRequest).then(loginResponse => {

    //     let body = JSON.stringify({
    //       access_token: loginResponse.accessToken,
    //       id_token: loginResponse.idToken,
    //       social_type: "microsoft-graph"
    //     });

    //     oauth_login(body).then(function(success) {
    //       success.json().then(success => {
    //         update_auth_token(safe_get(success, "jwt", undefined));
    //         var user = safe_get(success, "user", {});   
    //         self.props.dispatch(userActions.update_current_user(user));

    //         if (self.state.redirectRoute !== "" && self.state.redirectRoute !== "/login") {
    //           self.props.history.push(self.state.redirectRoute);
    //         }
    //         else {
    //           self.props.history.push("/");
    //         }    
    //       });              
    //     }, function(error) {
    //       if (error.status === 422) {
    //         error.json().then(error => {
    //           var newState = {};
    //           let error_messages = safe_get(error, "error_messages", []);
    //           if (error_messages.length !== 0) {
    //             let errorField = safe_get(error_messages[0], "field", "");
    //             let errorMsg = safe_get(error_messages[0], "message", "");

    //             if (errorField === "password") {
    //               newState["pwError"] = errorMsg;
    //               newState["emailError"] = null;
    //             }
    //             else if (errorField === "email") {
    //               newState["emailError"] = errorMsg;
    //               newState["pwError"] = null;
    //             }
    //             else {
    //               newState["pwError"] = null;
    //               newState["emailError"] = null;
    //               newState["loginError"] = errorMsg
    //             }
    //             newState["api_loading"] = false
    //             self.setState(newState);
    //           }

    //         });
    //       }
    //     });     

    //   }).catch(error => {
    //     console.error(error);
    //   });
    // }

    render() {

        var mobileScreen = window.innerWidth <= 740;

        var divStyle = {
            background: 'none',
            boxShadow: 'none',
            border: 'none',
            height: mobileScreen ? '130px' : '160px'
        };

        if (this.state.loadingOAuth) {
            return (
                <div id="container" className="cls-container" style={{backgroundColor: "white"}}>
                    <div className="cls-content auth">
                        <div className="cls-content-sm panel " style={{marginTop: "150px"}}>
                            <LoadingIndicator/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="container" className="cls-container auth login">
                    <div className="cls-content auth">
                        <div className="cls-content-sm panel img" style={divStyle}>
                            <div className="thumbnail noborder logo">
                                <img src={logo} alt="ZERO Logo"/>
                            </div>
                        </div>
                        <div className="cls-content-sm panel " style={{width: "95%", maxWidth: "350px"}}>
                            <div className="panel">
                                {
                                    isIE() &&
                                    <div className="panel-body" style={{padding: "15px", textAlign: "left"}}>
                                        <h4 className="zero-blue">ZERO no longer supports Internet Explorer (IE)</h4>
                                        <p className="zero-dark-grey">
                                            To access ZERO, please use a supported browser and go to app.teamzero.com.
                                            Google Chrome is preferred. <a className="blue-link"
                                                                           href="https://teamzero.freshdesk.com/a/solutions/articles/70000375390">Learn
                                            more</a>.
                                        </p>
                                        <br/>
                                        <p className="zero-dark-grey">
                                            Need help? Contact support@teamzero.com.
                                        </p>

                                        <hr className="mar-top-10 mar-btm-10"/>

                                        <div className="row text-center">
                                            <div style={{width: "50%", display: "inline-block"}}>
                                                <a href='https://play.google.com/store/apps/details?id=com.zero.app&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                                    <img alt='Get it on Google Play'
                                                         src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
                                                         style={{maxWidth: '135px'}}/>
                                                </a>
                                            </div>
                                            <div style={{width: "50%", display: "inline-block"}}>
                                                <a href='https://itunes.apple.com/us/app/zero-platform/id1353464306?platform=iphone&preserveScrollPosition=true#platform/iphone'>
                                                    <img alt='Download on the App Store' src={AppStore}
                                                         style={{paddingTop: "0px"}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    !isIE() &&
                                    <div className="panel-body">
                                        <div className="col-lg-12 col-lg-offset-0 col-xs-12 pad-btm">
                                            <h3 className="zero-blue">Login to ZERO</h3>
                                        </div>

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group mar-btm-10">
                                                <input ref="email" id="email" type="email"
                                                       className="form-control custom-placeholder" placeholder="Email"
                                                       style={{boxShadow: "none"}} autoFocus/>
                                                {
                                                    this.state.emailError &&
                                                    <small className="error">{this.state.emailError}</small>
                                                }
                                            </div>
                                            <div className="form-group mar-btm-10">
                                                <input id="password" type="password"
                                                       className="form-control custom-placeholder"
                                                       placeholder="Password"/>
                                                {
                                                    this.state.pwError &&
                                                    <small className="error">{this.state.pwError}</small>
                                                }
                                            </div>
                                            <button className="btn btn-primary blue btn-lg btn-block"
                                                    style={{padding: "5px 10px"}} type="submit">
                                                {
                                                    !this.state.api_loading ? "Login" : <ButtonLoading/>
                                                }
                                            </button>
                                        </form>

                                        <p className="mar-top-10">OR</p>

                                        <div className="mar-top-10" style={{cursor: "pointer"}}
                                             onClick={this.signInWithMS}>
                                            <MSLogin/>
                                        </div>

                                        <div className="row mar-ver">
                                            {
                                                this.state.loginError &&
                                                <div className="col-lg-12 col-lg-offset-0 col-xs-12 "
                                                     style={{paddingLeft: '0px'}}>
                                                    <small className="error">{this.state.loginError}</small>
                                                </div>
                                            }
                                            <div className="col-lg-12 col-lg-offset-0 col-xs-12 "
                                                 style={{paddingLeft: '0px'}}>
                                                <a href="/resetpassword" className="btn-link">Forgot password?</a>
                                            </div>
                                        </div>

                                        <hr className="mar-top-10 mar-btm-10"/>

                                        <div className="row">
                                            <div style={{width: "50%", display: "inline-block"}}>
                                                <a href='https://play.google.com/store/apps/details?id=com.zero.app&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                                    <img alt='Get it on Google Play'
                                                         src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
                                                         style={{maxWidth: '135px'}}/>
                                                </a>
                                            </div>
                                            <div style={{width: "50%", display: "inline-block"}}>
                                                <a href='https://itunes.apple.com/us/app/zero-platform/id1353464306?platform=iphone&preserveScrollPosition=true#platform/iphone'>
                                                    <img alt='Download on the App Store' src={AppStore}
                                                         style={{paddingTop: "0px"}}/>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                }


                            </div>

                        </div>
                    </div>
                </div>

            );
        }


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        kiosk_mode: safe_get(store, "app.kiosk_mode", false),
    };
}

export default withRouter(connect(mapStateToProps)(Login));

