import AttachmentCommentBox from 'components/Shared/NewAttachmentCommentBox.js';
import { Component } from 'react';
import { add_comment, } from '../../api/zero-api.js';
import {
    fieldIsNotEmpty,
    getErrorMessageFromResponse
} from '../../other/Helper.js';
import NotificationAlert from '../../other/NotificationAlert.js';
import Previewer from '../Previewer';
import ButtonLoading from '../Shared/ButtonLoading';
import ConfirmModal from "../Shared/ConfirmModal";

class CommentAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList: [],
            finalFileList: [],
            attachments: [],
            upload: null,
            s3: {},
            fileIsUploading: false,
            comment: ''
        };

        this.resetComment = this.resetComment.bind(this);
        this.submitComment = this.submitComment.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    handleCancel = () => this.setState({previewVisible: false})

    handlePreview = (index) => {
        this.setState({preview_visible: true, preview_index: index});
    }

    generateParamsCallback = file =>  ({
        feature_key: "bulletin_comment",
        post_uuid: this.props.post_uuid,
        content_type: file.type,
        file_name: file.name,
        embedded: false
    })

    onUploadSuccess = (file, pre_signed_url, file_key) => {
        const newAttachment = {
            file_path: file_key,
            file_name: file.name,
            public_url: pre_signed_url + "/" + file_key,
            mime_type: file.type,
        }

        this.setState(state => ({
            attachments: [
                ...state.attachments,
                newAttachment,
            ]
        }));
    }

    onUploadError = (error) => {
        console.error(error);
    }

    handleRemove = (index) => {
        this.setState({showDeleteConfirmation: true, deleteAttachmentIndex: index})
    }

    confirmDelete = () => {
        var attachments = this.state.attachments;
        attachments.splice(this.state.deleteAttachmentIndex, 1);
        this.setState({attachments: attachments, showDeleteConfirmation: false});
    }

    resetComment() {
        this.setState({attachments: [], fileList: [], comment: ''});
    }

    submitComment = () => {
        var self = this;
        var comment = this.state.comment;
        if ((fieldIsNotEmpty(comment) || this.state.attachments.length > 0) && !this.state.submittingComment) {
            this.setState({submittingComment: true, commentError: false}, () => {

                var body = JSON.stringify({
                    text: comment,
                    attachments: this.state.attachments
                });

                add_comment(this.props.post_uuid, body).then(function (success) {
                    success.json().then(success => {
                        var comment_uuid = success.comment_uuid
                        self.props.successfullComment(comment_uuid, comment);
                        self.setState({submittingComment: false, attachments: [], comment: ''});
                    });
                }, function (error) {
                    if (error.status === 403) {
                        NotificationAlert("error", "", "You do not have permission to post a comment.")
                    } else {
                        NotificationAlert("error", "", "Unable to post a comment.")
                    }

                    getErrorMessageFromResponse(error, "Unable to post comment.").then(message => {
                        self.setState({submittingComment: false, commentError: message});
                    });
                });

            });


        } else {
            this.setState({
                submittingComment: false,
                commentError: "Please provide a comment or an attachment."
            });
        }
    }

    render() {
        return (
            <div className="clearfix">
                {
                    this.state.showDeleteConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteConfirmation}
                        cancel={() => {
                            this.setState({showDeleteConfirmation: false});
                        }}
                        confirm={this.confirmDelete}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this attachment?"}
                        confirmButtonName={"Delete"}
                    />
                }

                {
                    this.state.preview_visible &&
                    <Previewer
                        show={this.state.preview_visible}
                        close={() => {
                            this.setState({preview_visible: false})
                        }}
                        attachments={this.state.attachments}
                        index={this.state.preview_index}
                    />
                }
                <div style={{marginTop: '12px'}}>
                    <div className="comments">
                        <AttachmentCommentBox
                            attachments={this.state.attachments}
                            commentError={this.state.commentError}
                            onPreview={this.handlePreview}
                            onDelete={this.handleRemove}
                            generateParamsCallback={this.generateParamsCallback}
                            onUploadSuccess={this.onUploadSuccess}
                            onUploadError={this.onUploadError}
                            textValue={this.state.comment}
                            onTextChange={(newValue) => this.setState({comment: newValue})}
                            buttonArea={(
                                <div style={{display: "flex", justifyContent: "right", marginTop: "5px", marginBottom: "10px"}}>
                                    <button className="btn btn-primary"
                                            onClick={this.submitComment} disabled={this.state.fileIsUploading}>
                                        {
                                            this.state.submittingComment ? <ButtonLoading/> : "Add comment"
                                        }
                                    </button>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default (CommentAttachments);



