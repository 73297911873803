import React, {Component} from 'react';

class RiskBadge extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        var name = ""
        // var className="label label-severity ";
        var color = "";

        if (this.props.value === 1) {
            // className = className + "low";
            name = "Low";
            color = "#A9D15C"
        } else if (this.props.value === 2) {
            // className = className + "medium";
            name = "Medium";
            color = "#FEE379"
        } else if (this.props.value === 3) {
            // className = className + "high"
            name = "High"
            color = "#F58768"
        }

        return (
            <span className={""}>
                <span className="badge badge-icon"
                      style={{backgroundColor: color, margin: "0px 5px", verticalAlign: "baseline"}}/>
                {name}
            </span>
        );
    }

}

export default RiskBadge;