import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import {
    CheckCircleOutlined,
    CloudDownloadOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    ShareAltOutlined,
    ToolOutlined
} from '@ant-design/icons';


import VideoThumbnail from 'components/Shared/VideoThumbnail';
import {
    add_reaction,
    assign_user,
    close_post,
    deleteBulletinPost,
    export_post,
    getBulletinPost,
    get_comments,
    get_filter_options,
    get_my_teams_data,
    get_notifications,
    get_post_subscribers,
    get_team,
    move_post,
    notifyError,
    reopen_post,
    share_post,
    subscribe_users,
    update_team_cookie
} from '../../api/zero-api.js';
import {
    formatNotifications,
    getFileThumbnail,
    isAdmin,
    isContributor,
    isImage,
    isObjEmpty,
    isRestrictedTeamUser,
    isTeamLead,
    isUser,
    isVideo,
    isViewer,
    mainContentContainerClass,
    profileImageContain,
    safe_get,
    scrollToTop,
    switchOrganization,
    updateAssignments
} from '../../other/Helper.js';
import NotificationAlert from '../../other/NotificationAlert.js';
import * as feedHelperActions from '../../store/actions/FeedHelperActions';
import * as notificationsActions from '../../store/actions/NotificationsActions';
import { update_current_team } from '../../store/actions/TeamsActions';
import GoogleMaps from '../GoogleMapsComp.js';
import Previewer from '../Previewer';
import ConfirmModal from '../Shared/ConfirmModal.js';
import PostViewsModal from '../Shared/PostViewsModal';
import CommentAttachment from './CommentAttachment.js';
import Comments from './Comments.js';
import CopyPostModal from './CopyPostModal';
import ExportPostModal from './ExportPostModal.js';
import MovePostModal from './MovePostModal.js';
import { PostNotificationsPopover } from './PostNotificationsPopover';
import PostsStatusModal from './PostStatusModal.js';
import PostContent from './PostView/PostContent';
import PostDetails from './PostView/PostDetails';
import SharePostModal from './SharePostModal.js';

import '../../assets/css/Library.css';
import ZEROlogo from '../../assets/css/img/ZERO-(blue)-small.png';
import access_error from '../../assets/css/img/access-error.png';
import error_img from '../../assets/css/img/dog_error.png';

function combinePostComments(postComments, submissionComments) {
    const submissionEditedComments = submissionComments
        .filter((c) => c.comment_type === "submission_edited")
        .map((c) => ({ ...c, created_at: parseFloat(c.created_at) })); // submission comment timestamp fields are strings
    const combinedComments = [...postComments, ...submissionEditedComments];
    const orderedComments = combinedComments.sort((a, b) => a.created_at - b.created_at);

    for (let i = 0; i < orderedComments.length; i++) {
        const comment = orderedComments[i];
        if (comment.comment_type !== "submission_edited") {
            continue;
        }

        let prevPostUpdateComment = null;
        let nextPostUpdateComment = null;
        for (let j = i; j >= 0; j--) {
            if (orderedComments[j].comment_type === "updated") {
                prevPostUpdateComment = orderedComments[j];
                break;
            }
        }
        for (let k = i; k < orderedComments.length; k++) {
            if (orderedComments[k].comment_type === "updated") {
                nextPostUpdateComment = orderedComments[k];
                break;
            }
        }

        let closestPostUpdateComment = null;

        if (prevPostUpdateComment && nextPostUpdateComment) {
            // find the closest post update comment
            closestPostUpdateComment =
                comment.created_at - prevPostUpdateComment.created_at <
                nextPostUpdateComment.created_at - comment.created_at
                    ? prevPostUpdateComment
                    : nextPostUpdateComment;
        } else if (prevPostUpdateComment) {
            closestPostUpdateComment = prevPostUpdateComment;
        } else if (nextPostUpdateComment) {
            closestPostUpdateComment = nextPostUpdateComment;
        }

        if (closestPostUpdateComment) {
            const timeDiff = Math.abs(comment.created_at - closestPostUpdateComment.created_at);
            if (timeDiff < 1000) {
                if (!closestPostUpdateComment.submission_diffs) {
                    closestPostUpdateComment.submission_diffs = {};
                }
                closestPostUpdateComment.submission_diffs = {
                    ...closestPostUpdateComment.submission_diffs,
                    ...comment.data.diffs,
                };
                comment.wasMerged = true;
            }
        }
    }

    const mergedComments = orderedComments.filter((c) => !c.wasMerged);

    return mergedComments;
}


class BulletinView extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            statusModal: false,
            coordinates: {},
            post: {},
            body: "",
            tag: {},
            attachments: [],
            assigns: [],
            author: {},
            formattedDate: "",
            user: {},
            isAdmin: false,
            isLead: false,
            team: {},
            comments: [],
            comments_loading: true,
            sourceTeam: {},
            query: "",
            canShowMap: false,
            displayMap: false,
            toggleMapText: "Show Map",
            bulletin_loading: true,
            bulletin_error: false,
            subscribers: [],
        };

        this.postDetailsRef = React.createRef();

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleEditPressed = this.handleEditPressed.bind(this);
        this.openViewersModal = this.openViewersModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.closePost = this.closePost.bind(this);
        this.reopenPost = this.reopenPost.bind(this);
        this.sharePost = this.sharePost.bind(this);
        this.movePost = this.movePost.bind(this);

        this.getComments = this.getComments.bind(this);
        this.updateCommentsCount = this.updateCommentsCount.bind(this);
        this.successfullComment = this.successfullComment.bind(this);

        this.updateNotifications = this.updateNotifications.bind(this);
        this.removeRelatedNotifications = this.removeRelatedNotifications.bind(this);

        this.toggleMapView = this.toggleMapView.bind(this);

    }

    componentDidUpdate(prevProps) {
        if ((this.props.match.params.team_uuid !== prevProps.match.params.team_uuid) && !this.props.location.pathname.includes("/my_posts")) {
            // switching teams & post
            scrollToTop("page-head");
            update_team_cookie(this.props.match.params.team_uuid);
            this.getTeam(this.props.match.params.team_uuid);
        } else if (this.props.match.params.post_uuid !== prevProps.match.params.post_uuid) {
            // switching post
            scrollToTop("page-head");
            this.getPost(this.props.match.params.post_uuid);
        }
    }


    componentDidMount() {
        this._isMounted = true;

        var route_team_uuid = this.props.match.params.team_uuid;
        var route_org_uuid = this.props.match.params.org_uuid;

        if (route_org_uuid !== this.props.org_uuid) {
            switchOrganization(this, route_org_uuid);
            this.getTeam(route_team_uuid);
        } else if (route_team_uuid === this.props.current_team.uuid) {
            this.getPost(this.props.match.params.post_uuid)
        } else if (this.props.location.pathname.includes("/my_posts")) {
            update_team_cookie("my_teams");
            this.getTeam("my_teams");
        } else {
            update_team_cookie(route_team_uuid);
            this.getTeam(route_team_uuid);
        }

        if (this.props.feed_tags.length === 0 || this.props.feed_statuses.length === 0) {
            const self = this;
            get_filter_options().then((success) => {
                success.json().then(success => {
                    let filters = safe_get(success, "filters", []);

                    let categories = filters.find(function (obj) {
                        return obj.filter_key === "category_uuid";
                    });
    
                    if (categories) {
                        categories = categories.options.filter(function (category) {
                            return category.enabled
                        });
                    }
    
                    self.props.dispatch(feedHelperActions.update_feed_categories(categories))
    
                    let tags = filters.find(function (obj) {
                        return obj.filter_key === "tag_uuid";
                    });
    
                    if (tags) {
                        tags = tags.options.filter(function (tag) {
                            return tag.enabled && tag.value !== -1
                        });
                    }
    
                    self.props.dispatch(feedHelperActions.update_feed_tags(tags));
    
                    let sub_status = filters.find(function (obj) {
                        return obj.filter_key === "sub_status_uuid";
                    });
    
                    if (sub_status) {
                        sub_status = sub_status.options.filter(function (status) {
                            return status.enabled && status.value !== -1
                        });
                    }
                    
                    self.props.dispatch(feedHelperActions.update_feed_statuses(sub_status, 1));

                    const sub_status_2 = filters
                        .find(({filter_key}) => filter_key === 'sub_status_2_uuid')
                        ?.options.filter(status => status.enabled && status.value !== -1);
                    self.props.dispatch(feedHelperActions.update_feed_statuses(sub_status_2 ?? [], 2));
                    
                });
            }, function (error) {
                console.error(error);
            });
        }

        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        var newState = {}
        if (window.innerWidth < 768) {
            newState["mobileDevice"] = true
        } else {
            newState["mobileDevice"] = false
        }

        if (this._isMounted) {
            this.setState(newState)
        }
    }

    getPost = (post_uuid) => {
        scrollToTop("page-head");

        var self = this;

        if (!post_uuid) {
            post_uuid = this.props.match.params.post_uuid
        }

        if (this._isMounted) {
            let newState = {
                "post": {},
                "bulletin_loading": true,
                "postContentHeight": "auto",
                "postDetailsHeight": "auto",
            }

            this.setState(newState, () => {
                getBulletinPost(post_uuid).then(function (success) {
                    success.json().then(success => {
                        var post = safe_get(success, "post", {});
                        var location = safe_get(post, "location", "");
                        var coordinates = safe_get(post, "coordinates", {});
                        var attachments = safe_get(post, "attachments", []);

                        if (self._isMounted) {
                            self.setState({
                                post: post,
                                body: safe_get(post, "body", ""),
                                assigns: safe_get(post, "assigns", []),
                                subscribers: safe_get(post, "subscribers", []),
                                subscribers_count: safe_get(post, "subscribers_count", 0),
                                tag: safe_get(post, "tag", {}),
                                category: safe_get(post, "category", {}),
                                attachments: attachments,
                                author: safe_get(post, "revised_by", {}),
                                severity_level: safe_get(post, "severity_level", -1),
                                location: location,
                                coordinates: coordinates,
                                canShowMap: (coordinates.lat && coordinates.lon && location),
                                post_status: safe_get(post, "status", ""),
                                comments_count: safe_get(post, "comments_count", 0),
                                reactions: safe_get(post, "reactions", []),
                                reactions_enabled: safe_get(post, "reactions_enabled", false),
                                reference_number: safe_get(post, "reference_number", ""),
                                sourceTeam: safe_get(post, "source_team", {}),
                                created_time_ago: safe_get(post, "created_time_ago", ""),
                                closed_time_ago: safe_get(post, "closed_time_ago", ""),
                                bulletin_error: false,
                                openResponderModal: false
                            });

                            self.setupPostView(attachments.length);
                            self.getComments(post_uuid);
                            self.updateNotifications(post_uuid);
                        }

                    });
                }, function (error) {
                    console.log(error)
                    self.setState({bulletin_loading: false, post: {}, bulletin_error: true});
                });
            });
        }

    }

    simpleGetPost = () => {
        var self = this
        getBulletinPost(this.state.post.post_uuid).then(function (success) {
            success.json().then(success => {
                self.setState({
                    post: safe_get(success, "post", {}),
                    reactions: safe_get(success, "post.reactions", []),
                    assigns: safe_get(success, "post.assigns", []),
                    subscribers: safe_get(success, "post.subscribers", []),
                    subscribers_count: safe_get(success, "post.subscribers_count", 0),
                    updatingReactions: false
                });
            });
        });

        this.getComments(this.state.post.post_uuid);
    }

    setupPostView = (attachmentCount) => {
        var postContent = document.getElementById("postContent")
        var postContentHeight = postContent.clientHeight;
        var newState = {};
        newState["bulletin_loading"] = false;
        newState["authorStyle"] = {}
        newState["postContentHeight"] = postContentHeight;
        if (this._isMounted) {
            this.setState(newState);
        }
    }

    getComments(post_uuid) {
        var self = this;
        if (this._isMounted) {
            this.setState({comments_loading: true});
            get_comments(post_uuid).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        const comments = combinePostComments(
                            safe_get(success, "comments", []),
                            safe_get(success, "submission_comments", [])
                        );

                        self.setState({
                            comments,
                            comments_loading: false
                        }, () => {
                            profileImageContain("img.img-circle.img-sm.bulletin", "26px");
                        });
                    }
                });
            }, function (error) {
                self.setState({comments_loading: false, comments: []})
            });

            // this.updateSubscribers();
        }
    }

    getTeam(team_uuid) {
        var self = this;
        update_team_cookie(team_uuid)
        this.setState({team_loading: true, comments_loading: true, bulletin_loading: true});
        if (team_uuid === "my_teams") {
            get_my_teams_data().then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var team = safe_get(success, "team", {});
                        self.props.dispatch(update_current_team(team));
                        self.getPost(self.props.match.params.post_uuid);
                        self.setState({team_loading: false, team_error: false});
                    }
                });
            }, function (error) {
                self.props.dispatch(update_current_team({}));
                self.setState({team_loading: false, comments_loading: false, team_error: true});
            });
        } else {
            get_team(team_uuid).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var team = safe_get(success, "team", {});
                        self.props.dispatch(update_current_team(team));
                        self.getPost(self.props.match.params.post_uuid);
                        self.setState({team_loading: false, team_error: false});
                    }
                });
            }, function (error) {
                self.props.dispatch(update_current_team({}));
                self.setState({team_loading: false, comments_loading: false, team_error: true});
            });
        }

    }

    updateResponders = (responders_uuids) => {
        let self = this;

        let body = JSON.stringify({
            user_uuids: responders_uuids
        });

        assign_user(this.state.post.post_uuid, body).then(function (success) {
            success.json().then(success => {
                self.setState({
                    assigns: safe_get(success, "post.assigns", [])
                });
                self.simpleGetPost();
            });
        }, function (error) {
            console.log(error)
        });

    }

    updateNotifications(post_uuid) {
        var self = this;
        get_notifications().then(function (success) {
            success.json().then(success => {
                let notifications = safe_get(success, "notifications", []);
                formatNotifications(self, notifications, self.props.user);

                let unread_notifications_counter = safe_get(success, "total_unread", 0)
                self.props.dispatch(notificationsActions.update_unread_notifications_counter(unread_notifications_counter))

            });
        }, function (error) {
            console.log(error);
        });
    }

    removeRelatedNotifications(post_uuid) {
        var unread_notifications = this.props.unread_notifications;
        unread_notifications = unread_notifications.filter(function (obj) {
            return obj.object_uuid !== post_uuid;
        });

        var read_notifications = this.props.read_notifications;
        read_notifications = read_notifications.filter(function (obj) {
            return obj.object_uuid !== post_uuid;
        });

        unread_notifications.sort(function (a, b) {
            return b.timestamp - a.timestamp;
        });

        read_notifications.sort(function (a, b) {
            return b.timestamp - a.timestamp;
        });

        this.props.dispatch(notificationsActions.update_notifications(unread_notifications));
        this.props.dispatch(notificationsActions.update_read_notifications(read_notifications));
    }

    handleEditPressed() {
        if (this.props.location.pathname.includes("/my_posts")) {
            this.props.history.push("/" + this.props.org_uuid + "/home/my_posts/edit/" + this.state.post.post_uuid);
        } else {
            this.props.history.push("/" + this.props.org_uuid + "/home/team/" + this.props.current_team.uuid + "/feed/edit/" + this.state.post.post_uuid);
        }
    }

    openViewersModal() {
        this.setState({
            showViews: true
        });
    }

    cancelModal() {
        this.setState({
            showModal: false,
            statusModal: false,
            shareModal: false,
            showViews: false,
            showDueDate: false,
            preview_visible: false,
            openResponderModal: false,
            showPostStatusModal: false,
            show_file_viewer: false,
            showSharePostModal: false,
            showMovePostModal: false,
            showExportModal: false,
            showDeleteModal: false,
            showCopyPostModal: false
        });
    }

    selectEmoji = (emoji) => {
        var self = this;
        let reactions = [...this.state.reactions];

        let exists = reactions.find((reaction) => {
            return reaction.emoji === emoji.native
        });

        let reaction = {}

        if (exists) {
            let index = reactions.indexOf(exists);

            reaction = exists;
            reaction["count"] = reaction.count + 1;
            reaction["liked"] = true;
            reaction["users"] = reaction.users.concat([this.props.user])

            reactions[index] = reaction;
        } else {
            reaction["emoji"] = emoji.native;
            reaction["count"] = 1;
            reaction["liked"] = true;
            reaction["users"] = [this.props.user];

            reactions.push(reaction);
        }

        this.setState({reactions: reactions, emojiPickerVisible: false});
        add_reaction(this.state.post.post_uuid, emoji.native);
    }

    deleteBulletin = () => {
        var self = this;
        var post_uuid = this.state.post.post_uuid;
        deleteBulletinPost(post_uuid).then(function (success) {
            self.props.dispatch(feedHelperActions.update_feed_position(0));
            self.removeRelatedNotifications(post_uuid);
            NotificationAlert('success', '', "Post deleted.");
            self.props.history.push("/" + self.props.org_uuid + "/home/team/" + self.props.current_team.uuid);
        }, function (error) {
            notifyError(error)
            self.cancelModal()
        });
    }

    closePost(reason, attachments) {
        var self = this;
        var post_uuid = this.state.post.post_uuid;
        // var reason = document.getElementById("post-status-reason").value
        var body = JSON.stringify({
            reason: reason,
            attachments: attachments
        });

        close_post(post_uuid, body).then(function (success) {
            success.json().then(success => {

                self.cancelModal();
                // self.comments.addReasonComment("", reason, true, "close");
                // self.updateCommentsCount(true);
                self.getComments(post_uuid);
                self.setState({
                    post_status: "closed",
                    closed_time_ago: "just now",
                });
                self.simpleGetPost();
                self.updateSubscribers();
                updateAssignments(self.props.dispatch)
                NotificationAlert('success', 'Success!', "Post closed.");
            });
        }, function (error) {
            self.cancelModal();
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to close post.");
        });
    }

    reopenPost(reason, attachments) {
        var self = this;

        var post_uuid = this.state.post.post_uuid;
        // var reason = document.getElementById("post-status-reason").value;
        var body = JSON.stringify({
            reason: reason,
            attachments: attachments
        });

        reopen_post(post_uuid, body).then(function (success) {
            success.json().then(success => {
                self.cancelModal();
                // self.comments.addReasonComment("", reason, true, "reopen");
                // self.updateCommentsCount(true);
                self.getComments(post_uuid);
                self.setState({post_status: "open"});
                self.simpleGetPost();
                self.updateSubscribers();
                updateAssignments(self.props.dispatch);
                NotificationAlert('success', 'Success!', "Post reopened.");
            });
        }, function (error) {
            self.cancelModal()
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to reopen post.")
        })
    }

    sharePost(team_uuids, user_uuids, select_all = false) {
        var self = this;
        var body = JSON.stringify({
            team_uuids: team_uuids,
            subscribe_all: select_all,
            subscribe_user_uuids: user_uuids
        });

        var post = this.state.post;
        share_post(post.post_uuid, body).then(function (success) {
            success.json().then(success => {
                self.cancelModal();
                self.setState({post: success.post});
            })
            self.updateSubscribers();
            self.getComments(post.post_uuid);
            NotificationAlert('success', 'Success!', "Post shared.");
        }, function (error) {
            self.cancelModal();
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to share post.");
        });
    }

    movePost(team_uuid, user_uuids, select_all = false) {
        var self = this;

        var body = JSON.stringify({
            new_team_uuid: team_uuid,
            subscribe_all: select_all,
            subscribe_user_uuids: select_all ? [] : user_uuids
        })

        var post_uuid = this.state.post.post_uuid
        move_post(post_uuid, body).then(function (success) {
            self.cancelModal();
            // self.getComments(post_uuid);
            NotificationAlert('success', 'Success!', "Post moved.");
            self.props.history.push("/" + self.props.org_uuid + "/home/team/" + team_uuid + "/feed/post/" + post_uuid);
            if (team_uuid === self.props.current_team.uuid) {
                self.getComments(post_uuid);
            }
        }, function (error) {
            self.cancelModal();
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to move post.");
        });
    }

    exportPost = () => {
        var self = this;

        var post_uuid = this.state.post.post_uuid;

        export_post(post_uuid).then(function (success) {
            success.json().then(success => {
                window.location = success.data.public_url;
                self.getComments(post_uuid);
                self.cancelModal();
            });
        }, function (error) {
            self.cancelModal()
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to export post.")
        })
    }

    handleMenuClick(e) {
        switch (e.key) {
            case "status":
                this.setState({showPostStatusModal: true});
                break;
            case "edit":
                this.handleEditPressed();
                break;
            case "share":
                this.setState({showSharePostModal: true});
                break;
            case "copy":
                this.setState({showCopyPostModal: true});
                break;
            case "move":
                this.setState({showMovePostModal: true});
                break;
            case "export":
                this.setState({showExportModal: true});
                break;
            case "delete":
                this.setState({showDeleteModal: true});
                break;
            default:
                return;
        }
    }

    updateSubscribers() {
        var self = this;

        get_post_subscribers(this.state.post.post_uuid).then(function (success) {
            success.json().then(success => {
                var subscribers = safe_get(success, "subscribers", []).sort(function (a, b) {
                    let a_name = `${a.first_name} ${a.last_name}`.toLowerCase()
                    let b_bame = `${b.first_name} ${b.last_name}`.toLowerCase()
                    return a_name > b_bame ? 1 : -1
                });

                if (self._isMounted) {
                    self.setState({
                        subscribers: subscribers,
                        subscribers_count: subscribers.length,
                    });
                }

            });
        }, function (error) {
            console.log(error);
        });

    }

    handlePostUpdated = (post) => {
        this.setState({post});
        this.getComments(post.post_uuid);
    }

    updateCommentsCount(add) {
        var comments_counts = this.state.comments_count;

        if (add) {
            comments_counts += 1;
        } else {
            comments_counts -= 1;
        }

        this.setState({
            comments_count: comments_counts
        })
    }

    successfullComment(comment_uuid, comment) {
        // this.comments.addReasonComment(comment_uuid, comment);
        // this.updateCommentsCount(true);
        this.getComments(this.props.match.params.post_uuid)
    }

    toggleMapView() {
        this.setState({
            displayMap: !this.state.displayMap,
            toggleMapText: !this.state.displayMap ? "Hide Map" : "Show Map"
        })
    }


    postContentHTML = (text) => {
        let links = safe_get(this.state, "post.links", [])
        if (links.length > 0) {
            const domParser = new DOMParser();
            const postBodyDom = domParser.parseFromString(text, 'text/html');
            const smartLinks = postBodyDom.querySelectorAll('a[data-zero-link]');

            for (let link of smartLinks) {
                const index = parseInt(link.dataset.zeroLink, 10);
                link.innerHTML = `<img src="${ZEROlogo}"/>${links[index]}`;
            }

            const fakeEl = document.createElement('div');
            fakeEl.append(...postBodyDom.body.childNodes);

            text = fakeEl.innerHTML;
        }
        return {__html: text}
    }

    showAttachment(file, index) {
        var file_name = safe_get(file, "file_name", "");
        var thumbnail = getFileThumbnail(file);

        if (isVideo(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <VideoThumbnail
                        imgSrc={thumbnail}
                        imgAlt={file_name}
                        onPlay={() => {
                            this.setState({preview_visible: true, preview_index: index})
                        }}
                    />
                </li>
            )
        } else if (isImage(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <button className="ButtonLink" onClick={() => {
                        this.setState({preview_visible: true, preview_index: index})
                    }}>
                        <img className="post-img" src={thumbnail} alt={file_name} style={{
                            objectFit: 'contain',
                            width: "100px",
                            height: "100px",
                            borderRadius: "2px",
                            backgroundColor: "#f6f6f6",
                            border: "1px solid #e5e5e5"
                        }}/>
                    </button>
                </li>
            )
        } else {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", height: "100px", width: "100px"}}>
                    <button className="ButtonLink" style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "2px",
                        backgroundColor: "#f6f6f6",
                        border: "1px solid #e5e5e5",
                        position: "absolute"
                    }} onClick={() => {
                        this.handleFilePreviewer(file, index);
                    }}>
                        <img src={thumbnail} alt={file_name} style={{
                            objectFit: 'cover',
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            display: "block",
                            margin: "0 auto",
                        }}/>
                        <p className="zero-dark-grey ellipsis-2-lines" style={{
                            margin: "0px",
                            padding: "0px 5px 5px 5px",
                            fontSize: "80%",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                        title={file_name}
                        >{file_name}</p>
                    </button>
                </li>
            )
        }

    }

    handleFilePreviewer = (file, index) => {
        this.setState({preview_visible: true, preview_index: index});
    }


    render() {
        var isMyTeams = (this.props.current_team.uuid === "my_teams" || this.props.match.params.team_uuid === "my_teams") && !this.props.location.pathname.includes("my_posts")
        var is_Admin = isAdmin(this.props.user);
        var is_TeamLead = isTeamLead(this.props.user);
        var is_Contributor = isContributor(this.props.user);
        var is_user = isUser(this.props.user);
        // var is_viewer = isViewer(this.props.user);

        var canEditTeam = (isAdmin(this.props.user) || isTeamLead(this.props.user)) && this.props.current_team_uuid !== "my_teams";

        var is_Author = (this.props.user.uuid === this.state.author.user_uuid);

        let canChangeStatus = (is_Admin || is_TeamLead || is_Contributor || is_user)
        let canShare = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && (this.props.teams.length > 1);
        let canMove = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && (this.props.teams.length > 1);
        let canEdit = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && this.state.post_status === "open";
        let canDelete = (is_Admin || is_Author) && this.state.post_status === "open";

        const menu = {
            onClick: this.handleMenuClick,
            items: [
                {
                    key: "status",
                    disabled: !canChangeStatus,
                    icon: this.state.post_status === "open" ? <CheckCircleOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/> : <ToolOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: this.state.post_status === "open" ? "Close" : "Reopen"
                },
                {
                    key: "share",
                    icon: <ShareAltOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Share"
                },
                {
                    key: "move",
                    icon: <ExportOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Move"
                },
                {
                    key: "copy",
                    icon: <CopyOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Copy"
                },
                {
                    key: "export",
                    icon: <CloudDownloadOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Export"
                },
                {
                    type: "divider"
                },
                {
                    key: "edit",
                    disabled: !canEdit,
                    icon: <EditOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Edit"
                },
                {
                    key: "delete",
                    disabled: !canDelete,
                    className: canDelete ? "zero-delete-red" : "",
                    icon: <DeleteOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Delete"
                }
            ]
        }

        return (

            <div className={mainContentContainerClass()}>
                {
                    !this.state.team_error && !this.state.bulletin_error &&
                    <div id="page-head" className="dont-print no-padding-mobile" style={{padding: "0px"}}></div>
                }
                {
                    this.state.showExportModal &&
                    <ExportPostModal
                        show={this.state.showExportModal}
                        cancel={this.cancelModal}
                        confirm={this.exportPost}
                    />
                }
                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this post? This action cannot be undone."}
                        show={this.state.showDeleteModal}
                        cancel={this.cancelModal}
                        confirm={this.deleteBulletin}
                        confirmButtonName={"Delete this post"}
                    />
                }

                {
                    this.state.showPostStatusModal &&
                    <PostsStatusModal
                        show={this.state.showPostStatusModal}
                        cancel={this.cancelModal}
                        post_status={this.state.post_status}
                        post_uuid={this.state.post.post_uuid}
                        subStatusUuid={this.state.post.sub_status.sub_status_uuid}
                        subStatus2Uuid={this.state.post.sub_status_2.sub_status_uuid}
                        user_uuid={this.props.user.uuid}
                        subscribers={this.state.subscribers}
                        closePost={this.closePost}
                        reopenPost={this.reopenPost}
                    />
                }
                {
                    this.state.showSharePostModal &&
                    <SharePostModal
                        show={this.state.showSharePostModal}
                        cancel={this.cancelModal}
                        user={this.props.user}
                        post_uuid={this.state.post.post_uuid}
                        teams={this.props.teams}
                        existing_subscribers={this.state.subscribers}
                        confirm={this.sharePost}
                        source_team={this.state.sourceTeam}
                        canShare={canShare}
                    />
                }
                {
                    this.state.showCopyPostModal &&
                    <CopyPostModal
                        show={this.state.showCopyPostModal}
                        cancel={this.cancelModal}
                        post={this.state.post}
                        callback={() => {
                        }}
                    />
                }
                {
                    this.state.showMovePostModal &&
                    <MovePostModal
                        show={this.state.showMovePostModal}
                        cancel={this.cancelModal}
                        teams={this.props.teams}
                        user={this.props.user}
                        source_team={this.state.sourceTeam.team_uuid}
                        post_uuid={this.state.post.post_uuid}
                        existing_subscribers={this.state.subscribers}
                        confirm={this.movePost}
                        canMove={canMove}
                    />
                }
                {
                    this.state.showViews &&
                    <PostViewsModal
                        show={this.state.showViews}
                        cancel={this.cancelModal}
                        post_uuid={this.state.post.post_uuid}
                    />
                }
                {
                    this.state.preview_visible &&
                    <Previewer
                        show={this.state.preview_visible}
                        close={() => {
                            this.setState({preview_visible: false})
                        }}
                        attachments={this.state.attachments}
                        index={this.state.preview_index}
                    />
                }

                <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>

                    {
                        isObjEmpty(this.state.post) && !this.state.bulletin_loading && this.state.bulletin_error &&
                        <div className="panel bulletin-post-border" style={{marginBottom: "10px"}}>
                            <img alt="error-img" src={error_img}
                                 style={{width: "150px", display: "block", margin: "auto", paddingTop: "10px"}}/>
                            <h4 className="mar-btm zero-blue text-center">Sorry, this post no longer exists. <br/>It was
                                most likely deleted or moved to another team.</h4>
                            <button className="btn btn-primary"
                                    style={{marginTop: "8px", display: "block", margin: "auto", marginBottom: "10px"}}
                                    onClick={() => {
                                        window.location = "/"
                                    }}>Back to ZERO
                            </button>
                        </div>
                    }
                    {
                        !this.state.team_loading && this.state.team_error &&
                        <div className="panel bulletin-post-border" style={{marginBottom: "10px"}}>
                            <img alt="access-error-img" src={access_error}
                                 style={{display: "block", width: "170px", margin: "auto", paddingBottom: "10px"}}/>
                            <h4 className="mar-btm zero-blue text-center">Sorry, you do not have access to this
                                team.</h4>
                            <button className="btn btn-primary"
                                    style={{marginTop: "8px", display: "block", margin: "auto", marginBottom: "10px"}}
                                    onClick={() => {
                                        window.location = "/"
                                    }}>Back to ZERO
                            </button>
                        </div>
                    }
                    {
                        (this.state.bulletin_loading || this.state.team_loading) &&
                        <div className="panel bulletin-post-border">
                            <div className="panel-body pad-no">
                                <div style={{minHeight: "120px"}}>
                                    <Skeleton width={200}/>

                                    <Skeleton height={13} width={"99%"}/>
                                    <Skeleton height={13} width={"93%"}/>
                                    <Skeleton height={13} width={"96%"}/>

                                    <ul className="thumb">
                                        <li style={{marginRight: "5px"}}>
                                            <Skeleton height={100} width={100}/>
                                        </li>
                                        <li style={{marginRight: "5px"}}>
                                            <Skeleton height={100} width={100}/>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                            <hr className="bulletin"/>
                            <div className="mar-top-10">
                                <Skeleton width={12} height={12}/>
                                <p className="zero-blue" style={{
                                    display: 'inline-block',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    marginLeft: '3px'
                                }}>
                                    <Skeleton width={140} height={20}/>
                                </p>
                                <div className="timeline" style={{paddingBottom: "10px"}}>
                                    {
                                        [...Array(3)].map((e, index) =>
                                            <div className="timeline-entry" key={index}>

                                                <div className="timeline-stat">
                                                    <div className="timeline-icon" style={{marginRight: "6px"}}>
                                                        <Skeleton circle={true} height={24} width={24}/>
                                                    </div>
                                                </div>

                                                <div className="timeline-label" style={{padding: "10px"}}>
                                                    <div className="media-left">
                                                        <Skeleton circle={true} height={26} width={26}/>
                                                    </div>
                                                    <div className="media-body bulletin2"
                                                         style={{paddingBottom: '0px'}}>
                                                        <Skeleton width={"250px"} height={10}/>
                                                        <br/>
                                                        <Skeleton width={"150px"} height={10}/>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !this.state.bulletin_error && !this.state.team_error &&
                        <div
                            style={{visibility: (!this.state.bulletin_loading && !isObjEmpty(this.state.post)) ? "visible" : "hidden"}}>
                            <div className="panel bulletin-post-border">
                                <div className="post-main-content-container">
                                    <div id="postContent" className="post-main-content">
                                        <PostContent
                                            post={this.state.post}
                                            postDetailsRef={this.postDetailsRef}
                                            canEditTeam={canEditTeam}
                                            menu={menu}
                                            postLink={null}
                                            attachments={this.state.attachments}
                                            onOpenPreview={(index) => {
                                                this.setState({preview_visible: true, preview_index: index})
                                            }}
                                            handleFilePreviewer={this.handleFilePreviewer}
                                        />
                                    </div>
                                </div>
                                
                                <PostDetails
                                    post={this.state.post}
                                    elRef={this.postDetailsRef}
                                    isLoading={this.state.bulletin_loading}
                                    onPostUpdate={this.handlePostUpdated}
                                    onViewerClick={this.openViewersModal}
                                    onMoreRespondersClick={() => { this.setState({openResponderModal: true}) }}
                                    onRefreshPost={this.simpleGetPost}
                                    onUpdateResponders={this.updateResponders}
                                    onDisplayMapChange={(displayMap) => this.setState({displayMap})}
                                />

                                {
                                    this.state.displayMap &&
                                    <Collapse isOpened={this.state.displayMap} style={{marginTop: "5px"}}>
                                        <GoogleMaps location={this.state.location}
                                                    lat={parseFloat(this.state.coordinates.lat)}
                                                    lng={parseFloat(this.state.coordinates.lon)}/>
                                    </Collapse>
                                }

                                <hr className="bulletin"/>

                                <Comments
                                    updateCommentsCount={this.updateCommentsCount}
                                    post={this.state.post}
                                    post_uuid={this.props.match.params.post_uuid}
                                    postStatus={this.state.post_status}
                                    reactions_enabled={this.state.reactions_enabled}
                                    comments={this.state.comments}
                                    sourceTeam={this.state.sourceTeam}
                                    canGenerateNotificationReport={safe_get(this.state, 'post.can_generate_notification_report', false)}
                                />

                                <div>
                                    {
                                        !isViewer(this.props.user) &&
                                        <div>
                                            {
                                                this.state.post_status === "closed" &&
                                                <div className="dont-print">
                                                    <hr className="bulletin"/>
                                                    <div className="panel-body">
                                                        <p className="text-md text-main text-center zero-dark-grey">
                                                            This post is closed.
                                                            {
                                                                !isRestrictedTeamUser(this.state.sourceTeam, this.props.user) &&
                                                                <> To update or add a comment, please <button
                                                                    className="ButtonLink underline-hover zero-light-blue"
                                                                    onClick={() => {
                                                                        this.setState({showPostStatusModal: true});
                                                                    }}>reopen it</button> first.</>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.post_status === "open" && !isRestrictedTeamUser(this.state.sourceTeam, this.props.user) &&
                                                <CommentAttachment
                                                    user_uuid={this.props.user.uuid}
                                                    post_uuid={this.props.match.params.post_uuid}
                                                    successfullComment={this.successfullComment}
                                                />
                                            }
                                            <hr className="bulletin dont-print"/>   
                               
                                            <PostNotificationsPopover 
                                                selectedSubscribers={this.state.subscribers}
                                                isLoading={this.state.bulletin_loading}
                                                handleOnChange={async (subscribers) => {
                                                    await subscribe_users(this.props.match.params.post_uuid, JSON.stringify({
                                                        user_uuids: subscribers
                                                    }))
                                                    this.updateSubscribers()
                                                }}
                                                orgUuid={this.props.org_uuid}
                                                postUuid={this.state.post?.post_uuid}
                                            />

                                        </div>
                                    }
                                </div>

                            </div>

                            {
                                this.state.reference_number !== "" &&
                                <div style={{margin: '0 auto', maxWidth: '700px', paddingLeft: '5px'}}>
                                    <p id="postidnumber" className="text-md text-main text-bold pad-top text-center"
                                       style={{color: '#BCBABC'}}>Full Post ID Number: {this.state.reference_number}</p>
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        org: safe_get(store, "org_helper.organization", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        unread_notifications: safe_get(store, "notifications.notifications", []),
        read_notifications: safe_get(store, "notifications.read_notifications", []),
        all_assignments: safe_get(store, "assignments", []),
        feed_tags: safe_get(store, "feed_helper.tags", []),
        feed_statuses: safe_get(store, "feed_helper.statuses", []),
        risk_enabled: safe_get(store, "org_helper.organization.risk_level_enabled", true),
        location_enabled: safe_get(store, "org_helper.organization.post_location_enabled", true),

    }
}

export default connect(mapStateToProps)(BulletinView);

