import React, {Component} from 'react';

import {get_templates, create_template, delete_template, update_template} from '../../api/zero-api.js'
import {safe_get, showFooterMobileApp, hideFooterMobileApp} from '../../other/Helper.js';

import LoadingIndicator from '../Shared/LoadingIndicator';

import {Modal} from 'antd';
import '../../assets/css/antd-custom.css'
import { ZeroQuill } from 'components/Shared/ZeroQuill';
import DOMPurify from 'dompurify';

class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            templates: [],
            activeTemplate: {},
            new_template_title: "",
            new_template_body: "",
            template_title: "",
            template_body: "",

        };

        this.onInputChange = this.onInputChange.bind(this);
        this.save = this.save.bind(this);
        this.createTemplate = this.createTemplate.bind(this);
        this.insertTemplate = this.insertTemplate.bind(this);
        this.editTemplate = this.editTemplate.bind(this);
        this.saveEdit = this.saveEdit.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.onNewTemplateBodyChange = this.onNewTemplateBodyChange.bind(this);
        this.onTitleInputChange = this.onTitleInputChange.bind(this);
    }

    componentDidMount() {
        var self = this;
        hideFooterMobileApp();

        get_templates().then(function (success) {
            success.json().then(success => {
                var templates = safe_get(success, "templates", []);
                var activeTemplate = templates.length > 0 ? templates[0] : {}
                self.setState({
                    templates: templates,
                    activeTemplate: activeTemplate,
                    activeTemplateIndex: 0,
                    loading: false
                });
            })
        }, function (error) {
            self.setState({
                loading: false
            });
        });

    }

    componentWillUnmount() {
        showFooterMobileApp();
    }

    onInputChange(content) {
        const value = content;
        this.setState({
            "template_body": value,
        });
    }

    onNewTemplateBodyChange(content) {
        const value = content;
        this.setState({
            "new_template_body": value,
        });
    }

    onTitleInputChange(content) {
        const value = content;
        this.setState({
            "new_template_title": value,
        });
    }

    save() {
        if (this.state.createTemplate) {
            this.createTemplate();
        }
    }

    createTemplate() {
        var self = this;

        var templates = this.state.templates;

        var body = JSON.stringify({
            name: this.state.new_template_title,
            post_body: this.state.new_template_body
        });

        create_template(body).then(function (success) {
            success.json().then(success => {
                var new_template = safe_get(success, "template", {});
                templates.push(new_template);
                self.setState({
                    templates: templates,
                    activeTemplate: new_template,
                    activeTemplateIndex: templates.length - 1,
                    createTemplate: false,
                    editMode: false,
                    new_template_title: "",
                    new_template_body: ""
                });
            });
        }, function (error) {
            console.log(error);
        })
    }

    insertTemplate() {
        // convert newlines into linebreak elements
        const body = this.state.activeTemplate.post_body.replaceAll("\n", "<br>");

        this.props.insertTemplate(body)
    }

    editTemplate() {
        this.setState({
            editMode: true,
            template_title: this.state.activeTemplate.name,
            template_body: this.state.activeTemplate.post_body
        })
    }

    saveEdit() {
        var self = this;
        var body = JSON.stringify({
            name: this.state.template_title,
            post_body: this.state.template_body
        });

        var templates = this.state.templates;

        update_template(this.state.activeTemplate.template_uuid, body).then(function (success) {
            success.json().then(success => {
                var updated_template = safe_get(success, "template", {});
                templates[self.state.activeTemplateIndex] = updated_template;

                self.setState({
                    templates: templates,
                    activeTemplate: updated_template,
                    template_body: "",
                    template_title: "",
                    editMode: false
                });
            })
        }, function (error) {
            console.log(error)
        })
    }


    deleteTemplate() {
        var self = this;
        var templates = this.state.templates;
        delete_template(this.state.activeTemplate.template_uuid).then(function (success) {
            var activeTemplate = {};
            templates.splice(self.state.activeTemplateIndex, 1);
            if (templates.length > 0) {
                activeTemplate = templates[0]
            }
            self.setState({
                templates: templates,
                activeTemplate: activeTemplate,
                activeTemplateIndex: 0,
                editMode: false,
                deleteMode: false
            });
        }, function (error) {
            console.log(error);
        });
    }

    render() {

        return (
            (<Modal
                title="Saved Templates"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                width={650}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        {
                            !this.state.createTemplate &&
                            <button className="btn btn-discard"
                                    style={{marginLeft: "0px", float: "left", marginTop: "0px"}} onClick={() => {
                                this.setState({createTemplate: true, activeTemplate: {}, activeTemplateIndex: -1});
                            }}>+ New</button>
                        }
                        {
                            this.state.createTemplate &&
                            <div>
                                <button className="btn btn-discard" style={{marginLeft: "0px",}} onClick={() => {
                                    this.setState({
                                        createTemplate: false,
                                        activeTemplate: this.state.templates[0] || {},
                                        activeTemplateIndex: 0
                                    });
                                }}>Cancel
                                </button>
                                <button className="btn btn-modal" onClick={this.save}>Save</button>
                            </div>
                        }
                        {
                            this.state.activeTemplate.template_uuid !== undefined && !this.state.editMode &&
                            <button className="btn btn-modal" style={{marginTop: "0px"}}
                                    onClick={this.insertTemplate}>Insert Template</button>
                        }
                        {
                            this.state.activeTemplate.template_uuid !== undefined && this.state.editMode &&
                            <div>
                                <button className="btn btn-discard" style={{marginLeft: "0px",}} onClick={() => {
                                    this.setState({editMode: false});
                                }}>Cancel
                                </button>
                                <button className="btn btn-modal" onClick={this.saveEdit}>Save</button>
                            </div>
                        }
                    </div>
                }
            >
                {
                    this.state.loading &&
                    <LoadingIndicator/>
                }
                {
                    !this.state.loading &&
                    <div style={{paddingTop: '0px', height: '280px', display: "flex", paddingLeft: "0px"}}>

                        {/* <p style={{margin: "0px"}}>Templates allow you to add saved text to the body of the post.</p> */}

                        <div style={{
                            width: "40%",
                            overflow: "auto",
                            borderRight: "1px solid #EDEDED",
                            verticalAlign: "top",
                            paddingTop: "10px"
                        }}>
                            {
                                this.state.templates.length > 0 &&
                                <ul id="mainnav-menu" className="list-group"
                                    style={{listStyle: "none", marginBottom: "0px"}}>
                                    {
                                        this.state.templates.map((template, index) => (
                                            <li key={index}>
                                                <a className={this.state.activeTemplateIndex === index ? "active-sidebar-item" : ""}
                                                   onClick={() => {
                                                       this.setState({
                                                           activeTemplate: template,
                                                           activeTemplateIndex: index,
                                                           createTemplate: false,
                                                           deleteMode: false
                                                       });
                                                   }}>
                                                    <p className="menu-title">{template.name}</p>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>

                            }
                            {
                                this.state.templates.length === 0 && !this.state.loading &&
                                <p style={{paddingLeft: "10px", paddingRight: "10px"}}>No saved templates found...</p>
                            }

                        </div>
                        <div style={{width: "60%", paddingLeft: "15px", verticalAlign: "top", paddingTop: "10px",}}>
                            {
                                this.state.createTemplate &&
                                <div className="" style={{marginBottom: '0px'}}>
                                    <input name="new_template_title" type="text"
                                           className="form-control custom-placeholder"
                                           placeholder="Name this template..." value={this.state.new_template_title}
                                           maxLength="100" onChange={(e) => this.onTitleInputChange(e.target.value)}
                                           style={{marginBottom: "10px"}}/>
                                    
                                    <ZeroQuill
                                            value={this.state.new_template_body}
                                            onChange={this.onNewTemplateBodyChange}
                                            height={90} />
                                </div>
                            }
                            {
                                this.state.activeTemplate.template_uuid !== undefined && !this.state.editMode &&
                                <div style={{height: "100%"}}>
                                    <h3 className="zero-blue"
                                        style={{lineHeight: "1em", fontSize: "15px", margin: "0px"}}>
                                        {this.state.activeTemplate.name}
                                    </h3>
                                    <p style={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-line',
                                        color: "#505050",
                                        paddingTop: "5px",
                                        height: (this.state.deleteMode ? "80px" : "130px"),
                                        overflow: "auto"
                                    }} class="quill-preview">
                                        <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.activeTemplate.post_body)}}></span>
                                    </p>
                                    {
                                        !this.state.deleteMode &&
                                        <div style={{marginTop: "20px"}}>
                                            <button className="btn zero-blue" style={{
                                                backgroundColor: "white",
                                                borderColor: "#EDEDED",
                                                padding: "2px 8px",
                                                marginLeft: "0px"
                                            }} onClick={this.editTemplate}>Edit
                                            </button>
                                            <button className="btn" style={{
                                                backgroundColor: "white",
                                                borderColor: "#EDEDED",
                                                padding: "2px 8px",
                                                color: "#FC4C56",
                                                marginLeft: "8px"
                                            }} onClick={() => {
                                                this.setState({deleteMode: true})
                                            }}>Delete
                                            </button>
                                        </div>
                                    }
                                    {
                                        this.state.deleteMode &&
                                        <div style={{}}>
                                            <p>Are you sure you want to delete this template? This action cannot be
                                                undone.</p>
                                            <button className="btn zero-blue" style={{
                                                backgroundColor: "white",
                                                borderColor: "#EDEDED",
                                                padding: "2px 8px",
                                                marginLeft: "0px"
                                            }} onClick={() => {
                                                this.setState({deleteMode: false})
                                            }}>Cancel
                                            </button>
                                            <button className="btn" style={{
                                                backgroundColor: "white",
                                                borderColor: "#EDEDED",
                                                padding: "2px 8px",
                                                color: "#FC4C56",
                                                marginLeft: "8px"
                                            }} onClick={this.deleteTemplate}>Yes
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                            {
                                this.state.activeTemplate.template_uuid !== undefined && this.state.editMode &&
                                <div className="" style={{marginBottom: '0px'}}>
                                    <input name="template_title" type="text" className="form-control custom-placeholder"
                                           placeholder="Name this template..." value={this.state.template_title}
                                           maxLength="100" onChange={this.onInputChange}
                                           style={{marginBottom: "10px"}}/>

                                           <ZeroQuill
                                            value={this.state.template_body}
                                            onChange={this.onInputChange}
                                            height={90}
                                        />
                                </div>
                            }

                        </div>

                    </div>
                }
            </Modal>)
        );


    }

}

export default Templates;