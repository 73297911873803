import {notification} from 'antd'

export default function NotificationAlert(type, title, message, duration = 5, options = {}) {
    const canClose = options.canClose ?? true;

    var key = options.key ?? `open${new Date()}`;

    let topDistance = 60;
    if (document.getElementById("ZERO-header")) {
        topDistance = document.getElementById("ZERO-header").clientHeight + 5
    }
    notification.config({
        top: topDistance,
        zIndex: 50000
    });

    notification.open({
        key: key,
        duration: duration,
        closeIcon: canClose,
        message: title,
        description: message,
        className: "simple-notification",
        placement: "topLeft",
        style: {
            padding: '16px 30px 16px 16px',
            backgroundColor: '#d6b17e',
            color: 'white !important',
        }
    });

    return notification;
};
