import React, {Component} from 'react';
import {connect} from 'react-redux'

import {add_reaction, delete_reaction, add_reaction_comment, delete_reaction_comment} from '../../api/zero-api.js';
import {safe_get} from '../../other/Helper.js';

import {SmileOutlined} from '@ant-design/icons';

import {Popover, Spin} from 'antd';

import Picker from '@emoji-mart/react'

class Reactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reactions: []
        };
    }

    componentDidMount() {
        this.setState({reactions: safe_get(this.props, "reactions", [])});
    }

    selectEmoji = (emoji) => {
        let reactions = [...this.state.reactions];

        let exists = reactions.find((reaction) => {
            return reaction.emoji === emoji.native
        });

        let reaction = {}

        if (exists) {
            reaction = exists;

            let index = reactions.indexOf(reaction);

            let user_liked = [...reaction.users].find((user) => {
                return user.user_uuid === this.props.user.uuid || user.user_uuid === this.props.user.user_uuid
            })

            if (!user_liked) {
                reaction["count"] = reaction.count + 1;
                reaction["liked"] = true;
                reaction["users"] = reaction.users.concat([this.props.user])

                reactions[index] = reaction;
            }
        } else {
            reaction["emoji"] = emoji.native;
            reaction["count"] = 1;
            reaction["liked"] = true;
            reaction["users"] = [this.props.user];

            reactions.push(reaction);
        }

        this.setState({reactions: reactions, emojiPickerVisible: false});

        // UPDATE BACKEND
        let post_uuid = this.props.post_uuid;
        let comment_uuid = this.props.comment_uuid;

        if (comment_uuid) {
            add_reaction_comment(post_uuid, comment_uuid, emoji.native);
        } else {
            add_reaction(post_uuid, emoji.native).then(success => {
                if (this.props.refresh_post) {
                    this.props.refresh_post();
                }
            });
        }
    }

    toggleReaction = (reaction_index, reaction, user_liked) => {
        let post_uuid = this.props.post_uuid;
        let comment_uuid = this.props.comment_uuid;

        let reactions = [...this.state.reactions];

        let reaction_users = [...reaction.users]

        if (user_liked) {
            // unliking reaction

            let count = reaction.count - 1;

            if (count <= 0) {
                reactions.splice(reaction_index, 1);
            } else {
                for (var i in reaction_users) {
                    if (reaction_users[i].user_uuid === this.props.user.uuid || reaction_users[i].uuid === this.props.user.uuid) {
                        reaction_users.splice(i, 1);
                        break;
                    }
                }

                reaction["liked"] = false;
                reaction["count"] = count;
                reaction["users"] = reaction_users;
            }

            // UPDATE BACKEND
            if (comment_uuid) {
                delete_reaction_comment(post_uuid, comment_uuid, reaction.emoji);
            } else {
                delete_reaction(post_uuid, reaction.emoji).then(success => {
                    if (this.props.refresh_post) {
                        this.props.refresh_post();
                    }
                });
            }
        } else {
            // liking reaction
            reaction_users.push(this.props.user)

            reaction["liked"] = true;
            reaction["count"] = reaction.count + 1;
            reaction["users"] = reaction_users


            // UPDATE BACKEND
            if (comment_uuid) {
                add_reaction_comment(post_uuid, comment_uuid, reaction.emoji);
            } else {
                add_reaction(post_uuid, reaction.emoji).then(success => {
                    if (this.props.refresh_post) {
                        this.props.refresh_post();
                    }
                });
            }
        }

        this.setState({reactions: reactions});
        if (this.props.refresh_post) {
            this.props.refresh_post();
        }
    }

    renderUsersList = (users) => {
        return (
            <div style={{maxHeight: "150px", overflow: "auto"}}>
                <span style={{fontWeight: "600"}}>Reacted:</span>
                {
                    users.map((user, index) => (
                        <div key={index}>
                            {user}
                        </div>
                    ))
                }
            </div>
        )
    }

    emojiPickerVisibleChange = (visible) => {
        this.setState({emojiPickerVisible: visible, loading: visible ? true : false});
    }

    emojiPickerRender() {       
        return <Picker set="apple" theme="light" onEmojiSelect={this.selectEmoji} emojiSize={22} perLine={8}/>
    }

    render() {


        let likedStyle = {
            padding: "2px 5px",
            borderRadius: "5px",
            backgroundColor: "#1d2a35",
            color: "white"
        };

        let unLikedStyle = {
            padding: "2px 5px",
            borderRadius: "5px",
            backgroundColor: "#f6f6f6",
            border: "1px solid #d6d6d6",
        };

        return (
            <>
                {
                    this.state.reactions.map((reaction, index) => {
                        let user_uuids = safe_get(reaction, "users", []).map(x => x.user_uuid);
                        let user_names = safe_get(reaction, "users", []).map(x => `${x.first_name} ${x.last_name}`).sort();
                        let user_liked = safe_get(reaction, "liked", undefined) !== undefined ? reaction.liked : user_uuids.includes(this.props.user.uuid);

                        if (reaction.removed || reaction.count <= 0) {
                            return null
                        }
                        return (
                            <button
                                key={index}
                                className={`ButtonLink reaction ${user_liked ? "liked" : ""}`}
                                // style={user_liked ? likedStyle : unLikedStyle}
                                onClick={() => {
                                    this.toggleReaction(index, reaction, user_liked)
                                }}
                            >
                                {
                                    safe_get(reaction, "count", 0) > 0 &&
                                    <Popover placement="bottom" content={this.renderUsersList(user_names)}>
                                        {safe_get(reaction, "emoji", "")}&nbsp;{safe_get(reaction, "count", 0)}
                                    </Popover>
                                }
                                {
                                    safe_get(reaction, "count", 0) === 0 &&
                                    safe_get(reaction, "emoji", "")
                                }
                            </button>
                        )
                    })
                }

                {
                    this.state.reactions.length < 3 &&
                    <Popover trigger="click" placement="bottom" className="custom-popover" 
                             overlayInnerStyle={{ padding: 0 }}
                             open={this.state.emojiPickerVisible} onOpenChange={this.emojiPickerVisibleChange}
                             content={this.emojiPickerRender()}
                    >
                        <button className={"ButtonLink reaction"}>
                            <SmileOutlined className="zero-dark-grey"
                                           style={{fontSize: "15px", verticalAlign: "text-bottom"}}/>
                        </button>
                    </Popover>
                }
            </>

        )
    }

}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
    }
}

export default connect(mapStateToProps)(Reactions);

