export default function reducer(state = {
    topics: []
}, action) {

    switch (action.type) {

        case "update_topics": {
            return Object.assign({}, state, {
                topics: action.payload
            });
        }

        default: {
            return state;
        }

    }

}