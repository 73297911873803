import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import {
    UsergroupAddOutlined, CalendarOutlined, KeyOutlined, FormOutlined,
    TabletOutlined, SettingOutlined, TeamOutlined, UserOutlined, AlertOutlined, FileExcelOutlined,
    GatewayOutlined, BankOutlined
} from '@ant-design/icons'

import {safe_get, isAdmin, isAdminOrTeamLead, canManageSpaces} from '../../other/Helper.js'
import {update_feed_tab} from '../../store/actions/FeedHelperActions'
import {updateFormsTab} from '../../store/actions/FormsActions'
import {updateIncidentsTab} from '../../store/actions/IncidentsActions'
import OfflineContext from 'components/Offline/OfflineContext.js';

class SettingsPanel extends Component {
    static contextType = OfflineContext;

    constructor(props) {
        super(props);
        this.state = {};

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // clicked outside of settings panel;
            this.props.close();
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    goToReports = (type) => {
        let org_uuid = this.props.organization.organization_uuid;

        if (type === "feed") {
            this.props.dispatch(update_feed_tab("reports"));
            this.props.history.push("/" + org_uuid + "/home/team/my_teams/feed/tab/reports");
        } else if (type === "forms") {
            this.props.dispatch(updateFormsTab("reports"));
            this.props.history.push("/" + org_uuid + "/home/team/my_teams/forms/reports");
        } else if (type === "incidents") {
            this.props.dispatch(updateIncidentsTab("reports"));
            this.props.history.push("/" + org_uuid + "/home/incidents/location/all_locations/reports");
        }
    }

    render() {
        const isOffline = this.context;
        let headerHeight = document.getElementById("ZERO-header").clientHeight;
        let topDistance = (headerHeight - 1) + "px";
        let is_admin = isAdmin(this.props.user);
        let is_admin_or_lead = isAdminOrTeamLead(this.props.user);
        let can_manage_spaces = canManageSpaces(this.props.user);
        const featureFlags = this.props.organization.feature_flags;
        const maxHeight = `calc(100vh - ${topDistance} - 70px - var(--sab))`;

        return (
            <div className="panel thin-border settings-panel" ref={this.setWrapperRef}
                 style={{position: "fixed", top: topDistance, zIndex: "3", overflowY: 'auto', maxHeight}}>
                {isOffline &&
                    <>
                        <div className='offline-sidebar-mask'></div>
                    </>
                }
                <div className="panel-body">
                    <div className="row">

                        <div className="col-lg-4 col-md-4">
                            <ul className="list-group settings-list mar-btm-0" style={{listStyle: "none"}}>
                                <li className="sidebar list-header" style={{padding: "10px 0px"}}>
                                    Account
                                </li>

                                <Link
                                    id="manage-locations"
                                    to={"/" + this.props.organization.organization_uuid + "/home/manage_incidents_locations"}
                                    style={{width: "100%"}}
                                    className={is_admin_or_lead ? "zero-dark-grey" : "disabled-link"}
                                >
                                    <li onClick={this.props.close}>
                                        <BankOutlined/>
                                        <span className="menu-title mar-lft-5">Manage Locations</span>
                                    </li>
                                </Link>

                                <Link id="access-teams"
                                      to={"/" + this.props.organization.organization_uuid + "/home/access_teams"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <TeamOutlined/>
                                        <span className="menu-title mar-lft-5">Manage Teams</span>
                                    </li>
                                </Link>

                                <Link id="directory"
                                      to={"/" + this.props.organization.organization_uuid + "/home/directory"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <UserOutlined/>
                                        <span className="menu-title mar-lft-5">Manage Members</span>
                                    </li>
                                </Link>

                                <Link to={"/" + this.props.organization.organization_uuid + "/home/manage_form_alerts"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <AlertOutlined/>
                                        <span className="menu-title mar-lft-5">Manage Alerts</span>
                                    </li>
                                </Link>

                                <Link id="reports"
                                      to={"/" + this.props.organization.organization_uuid + "/home/manage_kiosks"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <TabletOutlined/>
                                        <span className="menu-title mar-lft-5">Manage Kiosks</span>
                                    </li>
                                </Link>

                                <Link
                                    id="manage-desks"
                                    to={"/" + this.props.organization.organization_uuid + "/home/manage_desks"}
                                    style={{width: "100%"}}
                                    className={(featureFlags.desks && can_manage_spaces) ? "zero-dark-grey" : "disabled-link"}
                                >
                                    <li onClick={this.props.close}>
                                        <GatewayOutlined/>
                                        <span className="menu-title mar-lft-5">Manage Desks</span>
                                    </li>
                                </Link>

                                <Link id="api-keys"
                                      to={"/" + this.props.organization.organization_uuid + "/home/manage_api_keys"}
                                      style={{width: "100%"}} className={is_admin ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <KeyOutlined/>
                                        <span className="menu-title mar-lft-5">Manage API Keys</span>
                                    </li>
                                </Link>


                                <Link id="organization-settings"
                                      to={"/" + this.props.organization.organization_uuid + "/home/settings/account"}
                                      style={{width: "100%"}} className={is_admin ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <SettingOutlined/>
                                        <span className="menu-title mar-lft-5">Settings</span>
                                    </li>
                                </Link>


                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <ul className="list-group settings-list mar-btm-0" style={{listStyle: "none"}}>
                                <li className="sidebar list-header" style={{padding: "10px 0px"}}>
                                    Posts
                                </li>
                                <Link
                                    id="feed-templates"
                                    to={`/${this.props.organization.organization_uuid}/home/team/my_teams/feed/tab/templates`}
                                    style={{width: "100%"}}
                                    className={is_admin_or_lead && featureFlags.feed ? "zero-dark-grey" : "disabled-link"}
                                >
                                    <li onClick={this.props.close}>
                                        <FormOutlined/>
                                        <span className="menu-title mar-lft-5">Templates</span>
                                    </li>
                                </Link>

                                <Link id="feed-reports"
                                      to={"/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed/tab/reports"}
                                      style={{width: "100%"}}
                                      className={featureFlags.feed && is_admin_or_lead ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FileExcelOutlined/>
                                        <span className="menu-title mar-lft-5">Reports</span>
                                    </li>
                                </Link>
                                <Link id="feed-settings"
                                      to={"/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed/tab/settings"}
                                      style={{width: "100%"}}
                                      className={featureFlags.feed && is_admin ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <SettingOutlined/>
                                        <span className="menu-title mar-lft-5">Settings</span>
                                    </li>
                                </Link>
                                <li className="sidebar list-header" style={{padding: "10px 0px"}}>
                                    INCIDENTS
                                </li>
                                <Link
                                    to={"/" + this.props.organization.organization_uuid + "/home/manage_incident_templates"}
                                    style={{width: "100%"}}
                                    className={is_admin_or_lead && featureFlags.incidents ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FormOutlined/>
                                        <span className="menu-title mar-lft-5">Templates</span>
                                    </li>
                                </Link>
                                <Link id="incident-reports"
                                      to={"/" + this.props.organization.organization_uuid + "/home/incidents/location/all_locations/reports"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead && featureFlags.incidents ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FileExcelOutlined/>
                                        <span className="menu-title mar-lft-5">Reports</span>
                                    </li>
                                </Link>
                                <Link id="incident-settings"
                                      to={"/" + this.props.organization.organization_uuid + "/home/settings/incident"}
                                      style={{width: "100%"}}
                                      className={featureFlags.incidents && is_admin ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <SettingOutlined/>
                                        <span className="menu-title mar-lft-5">Settings</span>
                                    </li>
                                </Link>

                                {/* <button className="ButtonLink" style={{ height: "28px", width: "100%", textAlign: "left" }} onClick={() => { this.goToReports("incidents") }}>
                  <li onClick={this.props.close}>
                    <FileTextOutlined style={{ color: "#505050" }} />
                    <span className="menu-title mar-lft-5 zero-dark-grey">Reports</span>
                  </li>
                </button> */}
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <ul className="list-group settings-list mar-btm-0" style={{listStyle: "none"}}>
                                <li className="sidebar list-header" style={{padding: "10px 0px"}}>
                                    FORMS
                                </li>
                                <Link
                                    to={"/" + this.props.organization.organization_uuid + "/home/manage_forms_templates"}
                                    style={{width: "100%"}}
                                    className={is_admin_or_lead && featureFlags.forms ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FormOutlined/>
                                        <span className="menu-title mar-lft-5 ">Templates</span>
                                    </li>
                                </Link>
                                <Link to={"/" + this.props.organization.organization_uuid + "/home/manage_schedules"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead && featureFlags.forms ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <CalendarOutlined/>
                                        <span className="menu-title mar-lft-5">Schedules</span>
                                    </li>
                                </Link>
                                <Link id="form-reports"
                                      to={"/" + this.props.organization.organization_uuid + "/home/team/my_teams/forms/reports"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead && featureFlags.forms ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FileExcelOutlined/>
                                        <span className="menu-title mar-lft-5">Reports</span>
                                    </li>
                                </Link>
                                <Link id="form-settings"
                                      to={"/" + this.props.organization.organization_uuid + "/home/settings/forms"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead && featureFlags.forms && is_admin ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <SettingOutlined/>
                                        <span className="menu-title mar-lft-5">Settings</span>
                                    </li>
                                </Link>
                                <li className="sidebar list-header" style={{padding: "10px 0px"}}>
                                    COURSES
                                </li>
                                <Link to={"/" + this.props.organization.organization_uuid + "/home/manage_courses"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead && featureFlags.courses ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FormOutlined/>
                                        <span className="menu-title mar-lft-5">Courses</span>
                                    </li>
                                </Link>
                                <Link
                                    to={"/" + this.props.organization.organization_uuid + "/home/manage_courses_schedules"}
                                    style={{width: "100%"}}
                                    className={is_admin_or_lead && featureFlags.courses ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <CalendarOutlined/>
                                        <span className="menu-title mar-lft-5">Schedules</span>
                                    </li>
                                </Link>
                                <Link id="course-reports"
                                      to={"/" + this.props.organization.organization_uuid + "/home/team/my_teams/courses/reports"}
                                      style={{width: "100%"}}
                                      className={is_admin_or_lead && featureFlags.courses ? "zero-dark-grey" : "disabled-link"}>
                                    <li onClick={this.props.close}>
                                        <FileExcelOutlined/>
                                        <span className="menu-title mar-lft-5">Reports</span>
                                    </li>
                                </Link>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        );

    }

}

const mapStateToProps = store => {
    return {
        organization: safe_get(store, "org_helper.organization", {}),
        user: safe_get(store, "user.user", {}),
    }
}

export default withRouter(connect(mapStateToProps)(SettingsPanel));

