import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import React, {useEffect, useState} from 'react';
import {IoIosMore as MoreIcon} from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import {useSelector, useDispatch} from 'react-redux';
import DeskAssignedUsers from './DeskAssignedUsers';
import {actions as manageDesksActions} from 'store/slices/manageDesks';
import {PrettyDeskTypeMap} from 'other/Constants';


export default function DesksTable({
                                       onClickSpace = () => {
                                       }
                                   }) {
    const dispatch = useDispatch();
    const manageDesksState = useSelector(state => state.manageDesks);
    const {loading, error, desks} = manageDesksState;
    const [tableData, setTableData] = useState();

    const textOverflowStyle = {
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }

    const handleMenuClick = (key, desk) => {
        if (key === 'edit') {
            dispatch(manageDesksActions.showDeskModal(desk));
        } else if (key === 'delete') {
            dispatch(manageDesksActions.showDeleteDeskModal(desk));
        }
    }

    useEffect(() => {
        if (!loading && !error && desks !== null) {
            if (desks.length === 0) {
                setTableData(
                    <tr>
                        <td className="react-bs-table-no-data zero-dark-grey" colSpan="7">
                            No desks.
                        </td>
                    </tr>
                )
            } else {
                setTableData(desks.map(desk => (
                    <tr key={desk.uuid} className="tr-hover">
                        <td>
                            <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                                {
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            onClick: e => handleMenuClick(e.key, desk),
                                            items: [
                                                {
                                                    key: 'edit',
                                                    icon: <EditOutlined/>,
                                                    label: 'Edit Desk',
                                                },
                                                {
                                                    type: 'divider',
                                                },
                                                {
                                                    key: 'delete',
                                                    label: 'Delete Desk',
                                                    icon: <DeleteOutlined/>,
                                                    className: 'zero-delete-red',
                                                },
                                            ]
                                        }}
                                    >
                                        <button className="ButtonLink ant-dropdown-link">
                                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                                        </button>
                                    </Dropdown>
                                }
                            </div>
                        </td>
                        <td className="zero-dark-grey">{desk.name}</td>
                        <td className="zero-dark-grey" style={textOverflowStyle}>{desk.space.location.name}</td>
                        <td className="zero-dark-grey" style={textOverflowStyle}>
                            {desk.space.floor_plan ?
                                <span className="link-hover"
                                      onClick={() => onClickSpace(desk.space)}>{desk.space.name}</span>
                                :
                                <span>{desk.space.name}</span>
                            }
                        </td>
                        <td className="zero-dark-grey">{PrettyDeskTypeMap[desk.type] || 'Other'}</td>
                        <td className="zero-dark-grey">{desk.enabled ? 'Enabled' : 'Disabled'}</td>
                        <td className="zero-dark-grey"><DeskAssignedUsers desk={desk}/></td>
                    </tr>
                )))
            }
        } else if (error) {
            setTableData((
                <tr className="tr-hover">
                    <td colSpan="7">Could not load desks.</td>
                </tr>
            ))
        } else if (loading || desks === null) {
            setTableData([0, 1, 2, 3, 4, 5].map(index => (
                <tr key={index} className="tr-hover">
                    <td><Skeleton width={25}/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td><Skeleton/></td>
                    <td>
                        <Skeleton circle width={24} height={24} inline />
                        <span style={{marginRight: 5}}></span>
                        <Skeleton circle width={24} height={24} inline />
                        <span style={{marginRight: 5}}></span>
                        <Skeleton circle width={24} height={24} inline />
                        <span style={{marginRight: 5}}></span>
                        <Skeleton circle width={24} height={24} inline />
                    </td>
                </tr>
            )))
        }
    }, [desks, loading, error])

    return (
        <div className="table-responsive">
            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                <thead className="zero-blue">
                <tr>
                    <th className="zero-blue"></th>
                    <th className="zero-blue">Desk Name</th>
                    <th className="zero-blue">Location</th>
                    <th className="zero-blue">Space</th>
                    <th className="zero-blue" style={{width: "100px"}}>Type</th>
                    <th className="zero-blue" style={{width: "100px"}}>Status</th>
                    <th className="zero-blue" style={{minWidth: "150px"}}>Assigned</th>
                </tr>
                </thead>
                <tbody>
                {tableData}
                </tbody>
            </table>
        </div>
    )
}