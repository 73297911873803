import React, {Component} from 'react';
import {connect} from 'react-redux'

import {toggle_location_enabled, update_post_field_names} from '../../../api/zero-api.js'
import {safe_get} from '../../../other/Helper.js'

import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Switch} from 'antd';

import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import ButtonLoading from '../../Shared/ButtonLoading.js';

import NotificationAlert from '../../../other/NotificationAlert'

import * as organizationAction from '../../../store/actions/OrganizationActions'
import DelayedTextInput from 'components/Shared/DelayedTextInput.js';

class ManageLocationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            custom_field_name: this.props.organization.post_field_names.location
        };

    }

    componentDidMount() {
        this.setState({locationEnabled: this.props.organization.post_location_enabled});
    }

    fieldNameChange = (value) => {
        this.setState(
            {custom_field_name: value},
            () => {
                this.saveFieldName();
            }
        );
    }

    saveFieldName = () => {
        let self = this;
        let body = JSON.stringify({
            ...this.props.organization.post_field_names,
            location: this.state.custom_field_name,
        });

        update_post_field_names(body).then(success => {
            success.json().then(success => {
                let org = safe_get(success, "organization", {});
                self.props.dispatch(organizationAction.update_current_org(org));
                self.setState({editFieldName: false});
                NotificationAlert("success", "", "Changes saved.");
            });
        }, error => {

        });
    }

    toggleLocationEnabled = (value) => {
        let self = this;

        this.setState({locationEnabled: value})

        toggle_location_enabled().then(function (success) {
            success.json().then(success => {
                let organization = safe_get(success, "organization", {});
                self.props.dispatch(organizationAction.update_current_org(organization));
                NotificationAlert("success", "", "Changes saved.");
            })
        }, function (error) {
            this.setState({locationEnabled: !value})
            NotificationAlert("error", "", "Unable save changes.")
        });
    }


    render() {
        if (this.state.loading) {
            return (
                <LoadingIndicator/>
            )
        } else {
            return (
                <div>
                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
                    </div>
                    <div style={{marginBottom: "5px"}}>

                        <div style={{display: "inline-block"}}>
                            <DelayedTextInput
                                className="form-control topic-field"
                                defaultValue={this.state.custom_field_name}
                                onChange={this.fieldNameChange}
                                showCharacterCounter={true}
                                maxLength={15}
                            />
                        </div>

                        <Switch
                            checked={this.state.locationEnabled}
                            onChange={this.toggleLocationEnabled}
                            style={{marginTop: '6px', marginLeft: '10px', verticalAlign: "top"}}
                            checkedChildren={<CheckOutlined/>}
                            unCheckedChildren={<CloseOutlined/>}
                        />
                    </div>
                </div>

            );
        }


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        feed_tags: safe_get(store, "feed_helper.tags", []),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(ManageLocationSettings);
