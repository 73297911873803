import React, { useLayoutEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import useWindowSize from 'hooks/useWindowSize.js';

/**
 * @typedef {{
*  id: string | number;
*  label: string | JSX.Element;
*  value: string | number | JSX.Element;
*  badge?: string;
*  onClick?: React.MouseEventHandler;
* }} AnalyticsItem
*/

const skeletonItems = [...Array(10)].map((_, index) => /** @type {AnalyticsItem} */({
   id: index,
   label: <Skeleton width={160} />,
   value: <Skeleton width={20} />,
}))

/**
* 
* @param {{
*  fieldName: string;
*  Icon: JSX.Element;
*  loading: boolean;
*  extraGap?: boolean;
*  noLabelParagraph?: boolean;
*  items: AnalyticsItem[]
* }} props 
* @returns {JSX.Element}
*/
export default function AnalyticsBox({fieldName, Icon, items, loading, extraGap, noLabelParagraph}) {
   const [width, _] = useWindowSize();
   const widthThreshold = 650;
   const minimumItemsForSplit = 1;
   const [useTwoColumns, setUseTwoColumns] = useState(width >= widthThreshold);

   useLayoutEffect(() => {
       setUseTwoColumns(prevUseTwoColumns => {
           if (prevUseTwoColumns && width < widthThreshold) {
               return false;
           } else if (!prevUseTwoColumns && width >= widthThreshold) {
               return true;
           }
           return prevUseTwoColumns;
       });
   }, [width]);

   const columns = useMemo(() => {
       const itemsToRender = loading ? skeletonItems : items;
       if (!useTwoColumns) {
           return [itemsToRender];
       } else if (itemsToRender.length >= minimumItemsForSplit) {
           const halfIndex = Math.ceil(itemsToRender.length / 2);
           return [itemsToRender.slice(0, halfIndex), itemsToRender.slice(halfIndex)];
       } else {
            return [itemsToRender, []];
       }
   }, [items, useTwoColumns, loading]);

   return (
       <div className="AnalyticsBox panel analytics-bottom-border" style={{margin: '0 0.5rem 1rem'}}>
           <h2 className="panel-title analytics" style={{paddingLeft: '10px',}}>
               { loading 
                   ? <Skeleton width={150} />
                   : <><Icon className="mar-rgt-5"/>{fieldName ?? "Field"}</>
               }
           </h2>
           <div className="columnContainer">
               { columns.map((columnItems, index) => (
                   <div
                       key={index}
                       className={`column ${extraGap ? 'extraGap' : ''}`}
                   >
                       { columnItems.map(item => (
                           <div
                               key={item.id}
                               className="itemWrapper"
                               style={{cursor: item.onClick ? 'pointer' : undefined,}}
                               onClick={item.onClick}
                           >
                               <div>
                                    { noLabelParagraph && item.label}
                                    { !noLabelParagraph &&
                                        <p className="item lg analytics-tag zero-dark-grey mar-btm-0 v-middle">{item.label}</p>
                                    }
                                    { item.badge &&
                                        <span className="badge analytics dont-print" style={{verticalAlign: "baseline"}}>{item.badge}</span>
                                    }
                                </div>
                               <p className="item lg analytics-tag zero-dark-grey mar-btm-0" style={{padding: '0 1px'}}>{item.value}</p>
                           </div>
                       ))}
                   </div>
               ))}
           </div>
       </div>
   )
}