import React, {Component} from 'react';
import {connect} from 'react-redux'

import {createCategory, get_bulletin_categories, update_categories} from '../../../api/zero-api.js'
import {reorder, safe_get} from '../../../other/Helper.js'
import {update_feed_categories} from '../../../store/actions/FeedHelperActions';

import ManageCategory from './ManageCategoryComponent.js'
import NotificationAlert from '../../../other/NotificationAlert.js';
import LoadingIndicator from '../../Shared/LoadingIndicator.js';

import {update_post_field_names} from '../../../api/zero-api.js'
import {update_current_org} from '../../../store/actions/OrganizationActions'
import DelayedTextInput from 'components/Shared/DelayedTextInput.js';

import { ReorderableList } from 'components/Shared/ReorderableList.js';
import Button from 'components/Shared/Button.js';

class ManageCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            saveDisabled: true,
            showSave: false,
            isAdmin: true,
            orderedCategories: props.categories,
            indicator: false,
            topic_enabled: false,
            custom_field_name: this.props.org.post_field_names.category,
            autosaveTimer: null,
            creatingCategory: false,
        };

        this.updateMade = this.updateMade.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.categories !== prevProps.categories) {
            this.setState({orderedCategories: this.props.categories});
        }
    }

    fieldNameChange = (name) => {
        this.setState(
            {custom_field_name: name},
            () => {
                this.saveFieldName();
            }
        );
    }

    saveFieldName = () => {
        let self = this;
        let body = JSON.stringify({
            ...this.props.org.post_field_names,
            category: this.state.custom_field_name,
        });

        update_post_field_names(body).then(success => {
            success.json().then(success => {
                let org = safe_get(success, "organization", {});
                self.props.dispatch(update_current_org(org));
                self.setState({editFieldName: false});
                NotificationAlert("success", "", "Changes saved.");
            });
        }, error => {

        });
    }

    updateMade(newCategory) {
        if (this.state.autosaveTimer) {
            clearTimeout(this.state.autosaveTimer);
        }

        const orderedCategories = [...this.state.orderedCategories];
        const index = orderedCategories.findIndex(category => category.category_uuid === newCategory.category_uuid);
        if (index >= 0) {
            orderedCategories[index] = newCategory;
        } else {
            return;
        }

        this.setState({
            orderedCategories,
            autosaveTimer: setTimeout(() => {
                this.save()
            }, 500)
        });
    }

    save() {
        var self = this;

        this.setState({saving: true, autosaveTimer: false});

        var feed_categories = this.props.feed_categories;
        var body = JSON.stringify({
            categories: this.state.orderedCategories
        });

        update_categories(body).then(function (success) {
            success.json().then(success => {
                var new_categories = safe_get(success, "categories", []);

                new_categories.forEach(function (new_category) {
                    var old_category = feed_categories.find(function (obj) {
                        return obj.category_uuid === new_category.category_uuid
                    });

                    var index = feed_categories.indexOf(old_category);
                    if (index >= 0) {
                        new_category.enabled ? feed_categories[index] = new_category : feed_categories.splice(index, 1);
                    } else {
                        feed_categories.push(new_category);
                    }

                });

                self.setState({saving: false, saveDisabled: true});
                self.props.update();
                self.props.dispatch(update_feed_categories(feed_categories));
                NotificationAlert("success", "", "Changes saved.")
            });

        }, function (error) {
            self.setState({saving: false, error: "Must have at least one category."});
            NotificationAlert("success", "", "Could not save changes.")
        });
    }

    addNewCategory = async () => {
        try {
            this.setState({creatingCategory: true});
            const index = this.state.orderedCategories.length;
            const body = JSON.stringify({
                label: `New category #${index + 1}`,
                emoji: '🚗️',
                enabled: true,
                leading_indicator: false,
                order: index,
            });
        
            await createCategory(body);
            const res = await get_bulletin_categories("?all=1");
            const data = await res.json();
            const {categories} = data;
            this.props.update();
            this.props.dispatch(update_feed_categories(categories));
            NotificationAlert("success", "", `Added new ${this.state.custom_field_name}.`)
        } catch(err) {
            console.error(err);
            NotificationAlert("error", "", `Could not add new ${this.state.custom_field_name}.`)
        } finally {
            this.setState({creatingCategory: false});
        }
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const {source, destination} = result;

        if (source.index === destination.index) {
            return;
        }

        const orderedCategories = reorder(
            this.state.orderedCategories,
            source.index,
            destination.index
        );

        orderedCategories.forEach((category, index) => {
            category.order = index;
        });

        this.setState({orderedCategories}, () => {
            this.save();
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <LoadingIndicator/>
            );
        } else {
            return (
                <div>


                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
                    </div>
                    <div style={{marginBottom: "5px"}}>
                        <div style={{display: "inline-block"}}>
                            <DelayedTextInput
                                className="form-control topic-field"
                                defaultValue={this.state.custom_field_name}
                                onChange={this.fieldNameChange}
                                maxLength={15}
                                showCharacterCounter={true}
                            />
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                        maxWidth: '40rem'
                    }}>
                        <p className="zero-blue">
                            <b>Options</b>
                        </p>
                        <p className="zero-blue text-center" style={{marginRight: '4.8rem'}}>
                            <b>Leading<br/>Indicator?</b>
                        </p>
                    </div>

                    <ReorderableList
                        items={this.state.orderedCategories}
                        renderItem={(category, index) => (
                            <ManageCategory index={index} category={category} updateMade={this.updateMade}/>
                        )}
                        onReorder={(orderedCategories) => {
                            this.setState({orderedCategories}, () => {
                                this.save();
                            });
                        }}
                        itemKeyProp="category_uuid"
                    />

                    {
                        this.state.error &&
                        <small className="error">{this.state.error}</small>
                    }

                    <Button
                        type="primary"
                        disabled={this.state.creatingCategory || this.state.orderedCategories.length >= 25}
                        className="mar-top-15"
                        onClick={this.addNewCategory}
                    >Add option</Button>

                </div>
            );
        }

    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        feed_categories: safe_get(store, "feed_helper.categories", []),
        org: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(ManageCategories);
