import React, {Component} from 'react';
import {connect} from 'react-redux'

import {safe_get} from '../../../other/Helper.js'

import LimitedInputField from '../../LimitedInputField';

import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Switch} from 'antd';
import DragIcon from 'components/Shared/DragIcon.js';

class ManageTagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag_name: "",
            tag_enabled: false,
            inputTimer: null,
        };

        this.onSwitch = this.onSwitch.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.updateTag = this.updateTag.bind(this);
    }

    componentDidMount() {
        this.setState({
            tag_name: this.props.tag.name,
            tag_enabled: safe_get(this.props, "tag.enabled", false),
        });
    }

    onSwitch(checked) {
        this.setState({tag_enabled: checked}, () => {
            this.updateTag();
        });
    }

    onKeyDown(value) {
        if (this.state.inputTimer) clearTimeout(this.state.inputTimer);

        this.setState({
            tag_name: value,
            inputTimer: setTimeout(() => {
                this.updateTag()
            }, 1000),
        });
    }

    onBlur = (e) => {
        const newValue = e.target.value;
        if (newValue === this.props.tag.name) return;

        if (this.state.inputTimer) {
            clearTimeout(this.state.inputTimer);
        }

        this.setState({
            category_name: e.target.value,
            inputTimer: null,
        }, () => {
            this.updateTag();
        });
    }

    updateTag() {
        var tag = {
            tag_uuid: this.props.tag.tag_uuid,
            name: this.state.tag_name,
            enabled: this.state.tag_enabled
        }
        this.props.updateMade(tag)
    }

    render() {

        return (
            <div
                className="hover-parent"
                style={{marginBottom: "5px", display: 'flex', alignItems: 'center'}}
            >

                <LimitedInputField
                    id={"tag-input" + this.props.index}
                    ref="LimitedInput"
                    type={"text"}
                    class={"form-control topic-field"}
                    lengthLimit={30}
                    defaultValue={this.props.tag.name}
                    disabled={false}
                    onKey={this.onKeyDown}
                    onBlur={this.onBlur}
                />

                <Switch
                    checked={this.state.tag_enabled}
                    onChange={this.onSwitch}
                    style={{marginTop: '0px', marginLeft: '10px', float: 'none'}}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />

                <DragIcon />

            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        feed_categories: safe_get(store, "feed_helper.categories", [])
    }
}
export default connect(mapStateToProps)(ManageTagComponent);