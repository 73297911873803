import FormsCache from "offline/FormsCache";
import {sortByString} from "other/Helper";
import React, {useCallback, useEffect, useState} from "react";
import {unstable_batchedUpdates} from "react-dom";
import useDbChangeListener from "../useDbChangeListener";

export default function useFormsCache(isAppLoading, orgId, userId) {
    /** @type {[FormsCache, React.Dispatch<any>]} */
    const [cache, setCache] = useState(null);

    /** @type {[object[], React.Dispatch<any>]} */
    const [forms, setForms] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (orgId && userId && !isAppLoading) {
                const cache = new FormsCache(orgId, userId);
                const forms = await cache.getAll();

                unstable_batchedUpdates(() => {
                    setCache(cache);
                    setForms(sortByString(forms, 'name'));
                });
            }
        }

        fetchData();
    }, [orgId, userId, isAppLoading, setCache, setForms]);

    const listenerCallback = useCallback(async () => {
        if (cache) {
            const forms = await cache.getAll();
            setForms(sortByString(forms, 'name'));
        }
    }, [cache, setForms]);

    useDbChangeListener(cache?.db, 250, listenerCallback);

    return {cache, forms};
}
