import React, {Component} from 'react';

import ModalPopover from '../ModalPopover.js';
import {fileUpload, generateUUID, showLoadingTinyMCE, hideLoadingTinyMCE} from '../../other/Helper';
import {Editor} from '@tinymce/tinymce-react';
import safe_get from '../../other/SafeGet.js';

class TextEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: "0%",
            content: props.article_content || ''
        };

        this.addNewAttachment = this.addNewAttachment.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.article_content !== this.props.article_content) {
            this.setState({content: this.props.article_content});
        }
    }

    tinyMceImageUploadHandler = (blobInfo, success, failure, progress) => {
        const file = blobInfo.blob();

        let params = JSON.stringify({
            feature_key: "library_article",
            article_uuid: this.props.article_uuid,
            content_type: file.type,
            file_name: file.name,
            embedded: true

        });

        file["library_embedded"] = true;

        let onSuccess = (preSignedUrl, key) => {
            success(`${preSignedUrl}/${key}`);
        };

        let onFailure = (error) => {
            //console.error("tinyMceImageUploadHandler error:", error);
            failure("Could not upload image");
        };

        fileUpload(null, params, file, progress, onSuccess, onFailure);
    }

    handleFileUpload(file, cb) {
        var self = this;

        var params = JSON.stringify({
            feature_key: "library_article",
            article_uuid: self.props.article_uuid,
            content_type: file.type,
            file_name: file.name,
            embedded: true
        });

        file["library_embedded"] = true;

        showLoadingTinyMCE();

        fileUpload(this, params, file, function (progress) {
            // self.setState({ progress: progress });
        }, function (pre_signed_url, file_key) {
            // self.setState({ progress: '0%' });
            self.addNewAttachment(file, pre_signed_url, file_key);
            cb(pre_signed_url + "/" + file_key, {title: file.name});
            hideLoadingTinyMCE();
        }, function (error) {
            // self.setState({ showModal: false });   
            hideLoadingTinyMCE();
        });
    }

    addNewAttachment(file, pre_signed_url, file_key) {
        var attachment = {
            "file_path": file_key,
            "file_name": file.name,
            "public_url": pre_signed_url + "/" + file_key,
            "embedded": true
        }
        this.props.addEmbeddedAttachment(attachment)
    }

    handleEditorChange(content) {
        this.setState({content: content});
    }

    render() {
        var self = this;

        return (
            <div>
                <ModalPopover
                    show={this.state.showModal}
                    cancel={this.cancelModal}
                    confirm={this.confirmModal}
                    title="Upload Progress"
                    text={""}
                    progressBar={true}
                    progress={this.state.progress}
                    style={{zIndex: "10000000"}}
                />

                <Editor
                    apiKey='k8pzwxerydel2fec8qy1mel2q6ui0m6vduu5r57o1ok5yn1j'
                    init={{
                        content_style: "body { color: #505050; font-size: 13px; font-weight: normal;} @media screen and (max-width: 700px) { body { font-size: 16px !important; } } img {max-width: 100%;}",
                        menubar: 'edit view insert format table help',
                        branding: false,
                        height: "350",
                        file_picker_types: 'image media',
                        image_dimensions: false,
                        default_link_target: '_blank',
                        file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', '.jpeg, .jpg, .gif, .png, .mov, .mpeg, .mp4, .quicktime');


                            // Note: In modern browsers input[type="file"] is functional without 
                            // even adding it to the DOM, but that might not be the case in some older
                            // or quirky browsers like IE, so you might want to add it to the DOM
                            // just in case, and visually hide it. And do not forget do remove it
                            // once you do not need it anymore.

                            input.onchange = function () {
                                var file = this.files[0];
                                self.handleFileUpload(file, cb)
                            };

                            input.click();
                        },
                        block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4',
                        toolbar1: "undo redo | formatselect | fontsizeselect | bold italic underline strikethrough forecolor backcolor",
                        toolbar2: "alignleft aligncenter alignright alignjustify | bullist numlist | link image media | removeformat ",
                        automatic_uploads: true,
                        images_upload_handler: this.tinyMceImageUploadHandler,
                        paste_data_images: true,
                    }}
                    id="tinymce-editor"
                    value={this.state.content}
                    onEditorChange={this.handleEditorChange}
                    plugins={"autolink directionality visualblocks visualchars image link media table charmap hr pagebreak nonbreaking insertdatetime advlist lists checklist wordcount mediaembed textpattern help paste"}
                    media_live_embeds={true}
                />
            </div>
        );
    }

}

export default TextEditor;