function Table({children}) {
    return (
        <div className="table-responsive" style={{border: "none"}}>
            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                {children}
            </table>
        </div>
    )
}

function THeaderRow({children}) {
    return (
        <thead className="zero-blue">
        <tr>
            {children}
        </tr>
        </thead>
    );
}

function TBody({children}) {
    return (
        <tbody className="zero-dark-grey">
        {children}
        </tbody>
    )
}

Table.HeaderRow = THeaderRow;
Table.Body = TBody;

export default Table;