import React, {useCallback, useContext, useRef, useState} from "react";
import {useSelector} from "react-redux";

import useSubmissionDraftsCache from "./useSubmissionDraftsCache";
import InitialDataCache, {syncInitialData} from "offline/InitialDataCache";
import FormsCache, {syncForms} from "offline/FormsCache";
import {syncDirectory} from "offline/DirectoryCache";
import DynamicListCache, {syncDynamicLists} from "offline/DynamicListCache";
import SubmissionDraftsCache, {syncSubmissionDrafts, useDraftsCurrentlySyncing} from "offline/SubmissionDraftsCache";
import useFormsCacheLoader from "./cacheLoaderHooks/useFormsCacheLoader";
import useInitialDataCacheLoader from "./cacheLoaderHooks/useInitialDataCacheLoader";
import useDynamicListCacheLoader from "./cacheLoaderHooks/useDynamicListCacheLoader";
import useOrganizationId from "hooks/useOrganizationId";
import useFormAssignmentsCacheLoader from "./cacheLoaderHooks/useFormAssignmentsLoader";
import {checkApiStatus} from "api/zero-api";
import FormAssignmentsCache, {syncFormAssignments} from "offline/FormAssignmentCache";
import { isHeadlessChrome, isPublicUser } from "other/Helper";
import { getLocalDbService } from "services/localDb";
import { getPostService } from "services/postService";
import { LocalStorageHelper } from "other/Helper";

/** @returns {number} */
export function getStoredLastSyncAt() {
    const timestamp = LocalStorageHelper.get('last-offline-sync', "number");
    return Number.isFinite(timestamp) ? timestamp : 0;
}

function isAppOffline() {
    return document.body.classList.contains('offline-mode');
}

let syncCounter = 0;
let isSyncingInternal = false;

/**
 * @param {{
 *  initialDataCache: InitialDataCache;
 *  formsCache: FormsCache;
 *  submissionDraftsCache: SubmissionDraftsCache;
 *  dynamicListCache: DynamicListCache;
 *  formAssignmentsCache: FormAssignmentsCache;
 *  orgId: string;
 *  initialSync: boolean;
 *  forcePostSync?: boolean;
 * }} options;
 */
async function _sync({
    initialDataCache,
    formsCache,
    submissionDraftsCache,
    dynamicListCache,
    formAssignmentsCache,
    orgId, 
    userId,
    forcePostSync = false,
}) {
    if (!isSyncingInternal && await checkApiStatus()) {
        isSyncingInternal = true;
        const syncJobs = [];

        const postService = getPostService(orgId, userId, isAppOffline());

        if (syncCounter > 0) {
            // does not sync initial data on first call to sync()
            // app handled this on page load
            syncJobs.push(syncInitialData(initialDataCache));
        }
        syncJobs.push(syncForms(formsCache));
        syncJobs.push(syncSubmissionDrafts(submissionDraftsCache, () => syncFormAssignments(formAssignmentsCache), postService));
        syncJobs.push(syncDirectory(orgId));
        syncJobs.push(syncDynamicLists(dynamicListCache));
        syncJobs.push(getLocalDbService(orgId, userId).syncLocalDb());
        if (syncCounter === 0 || forcePostSync) {
            // only sync posts on initial page load
            syncJobs.push(postService.syncAllPosts());
        }

        await Promise.all(syncJobs);
        syncCounter++;
        isSyncingInternal = false;
    }
}

const initialContextData = {};

/** @type {OfflineDataCachesContext} */
export const OfflineDataCachesContext = React.createContext(initialContextData);

/** @returns {OfflineDataCachesContext} */
export function useOfflineDataCachesContext() {
    const context = useContext(OfflineDataCachesContext);
    return context;
}

export default function OfflineDataCaches({children}) {
    const orgId = useOrganizationId();
    const user = useSelector(state => state.user.user);
    const userId = user.uuid;
    const isAppLoading = useSelector(state => state.app.loading);
    const {cache: initialDataCache, initialData} = useInitialDataCacheLoader(isAppLoading, orgId, userId);
    const {cache: formsCache, forms} = useFormsCacheLoader(isAppLoading, orgId, userId);
    const {cache: formAssignmentsCache, formAssignments} = useFormAssignmentsCacheLoader(isAppLoading, orgId, userId);
    const dynamicListCache = useDynamicListCacheLoader(isAppLoading, orgId, userId);
    const submissionDrafts = useSubmissionDraftsCache(isAppLoading, orgId, userId);
    const [lastSyncAt, setLastSyncAt] = useState(getStoredLastSyncAt());
    const [isSyncing, setIsSyncing] = useState(false);
    const isSyncingRef = useRef(isSyncing);
    isSyncingRef.current = isSyncing;
    const draftsCurrentlySyncing = useDraftsCurrentlySyncing();

    const isReady = (
        !isAppLoading
        && orgId
        && userId
        && initialData !== null
        && forms !== null
        && submissionDrafts.cache.initialized
        && dynamicListCache !== null
        && formAssignments !== null
    );

    const updateLastSyncAt = useCallback(() => {
        const timestamp = Date.now() / 1000;
        setLastSyncAt(timestamp);
        setIsSyncing(false);

        LocalStorageHelper.set('last-offline-sync', timestamp);
    }, [setLastSyncAt, setIsSyncing]);

    const sync = useCallback((forcePostSync = false) => {
        if (!isReady || isSyncingRef.current || isHeadlessChrome() || isPublicUser(user)) return;
        setIsSyncing(true);
        _sync({
            initialDataCache,
            formsCache,
            submissionDraftsCache: submissionDrafts.cache,
            dynamicListCache,
            formAssignmentsCache,
            orgId,
            userId,
            forcePostSync
        })
        .then(updateLastSyncAt)
    }, [
        isReady,
        initialDataCache,
        formsCache,
        submissionDrafts.cache,
        dynamicListCache,
        formAssignmentsCache,
        orgId,
        updateLastSyncAt,
        setIsSyncing
    ]);

    const contextValue = {
        initialDataCache,
        initialData,
        formsCache,
        forms,
        formAssignments,
        submissionDrafts,
        dynamicListCache,
        formAssignmentsCache,
        lastSyncAt,
        isSyncing: isSyncing || draftsCurrentlySyncing.count > 0,
        sync
    }

    return (
        <OfflineDataCachesContext.Provider value={contextValue}>
            {isReady ? children : null}
        </OfflineDataCachesContext.Provider>
    )
}