import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Dropdown, Menu, Checkbox, Popover} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {IoIosMore as MoreIcon} from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import {CheckInAlertRepeatPeriod} from 'other/Constants';
import {actions as manageAlertsActions, updateAlert} from 'store/slices/manageCheckInAlerts'


function formatTeamNames(alert) {
    const teams = [...alert.subscribed_teams].sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });

    if (teams.length === 0) {
        return 'No teams assigned';
    } else if (teams.length === 1) {
        return teams[0].name;
    } else if (teams.length === 2) {
        return `${teams[0].name} and ${teams[1].name}`;
    } else {
        const popoverContent = teams.map(team => <div key={team.uuid}>{team.name}</div>)
        return (
            <Popover content={popoverContent} placement="bottom" overlayStyle={{maxWidth: '220px'}}>
                {`${teams[0].name} and ${teams.length - 1} more...`}
            </Popover>
        )
    }
}

function formatSchedule(alert) {
    const repeatPeriodMap = {
        [CheckInAlertRepeatPeriod.DAILY]: 'Daily',
        [CheckInAlertRepeatPeriod.WEEKDAY]: 'Weekdays',
        [CheckInAlertRepeatPeriod.WEEKEND]: 'Weekends',
    }

    const time = moment(alert.start_time, moment.HTML5_FMT.TIME_SECONDS).format("hA");
    return `${repeatPeriodMap[alert.repeat_period]} at ${time}`;
}

function formatStartDate(alert) {
    const date = moment(alert.start_date, moment.HTML5_FMT.DATE).format('MMM D, YYYY')
    return `${date}`;
}

export default function AlertsTable() {
    const dispatch = useDispatch();
    const alertState = useSelector(state => state.manageCheckInAlerts);
    const {loading, error, alerts} = alertState;
    const [tableData, setTableData] = useState();

    const handleMenuClick = (key, alert) => {
        if (key === 'edit') {
            dispatch(manageAlertsActions.showModal(alert));
        } else if (key === 'delete') {
            dispatch(manageAlertsActions.showDeleteModal(alert));
        }
    }

    const setDashboardAlert = (uuid, checked) => {
        const body = {
            show_dashboard_alerts: checked,
        }
        dispatch(updateAlert({uuid, body}));
    }

    const setEmailAlert = (uuid, checked) => {
        const body = {
            send_email_alerts: checked,
        }
        dispatch(updateAlert({uuid, body}));
    }

    useEffect(() => {
        if (error) {
            setTableData((
                <tr>
                    <td className="react-bs-table-no-data" colSpan="6">
                        Could not retrieve check-in alerts.
                    </td>
                </tr>
            ));
        } else if (!loading && alerts !== null) {
            if (alerts.length === 0) {
                setTableData((
                    <tr>
                        <td className="react-bs-table-no-data zero-dark-grey" colSpan="6">
                            No alerts.
                        </td>
                    </tr>
                ));
            } else {
                setTableData(
                    alerts.map(alert => {
                        return (
                            <tr key={alert.uuid} className="tr-hover">
                                <td>
                                    <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                                        {
                                            <Dropdown
                                                trigger={['click']}
                                                menu={{
                                                    onClick: e => handleMenuClick(e.key, alert),
                                                    items: [
                                                        {
                                                            key: 'edit',
                                                            icon: <EditOutlined/>,
                                                            label: 'Edit Alert'
                                                        },
                                                        {
                                                            type: 'divider',
                                                        },
                                                        {
                                                            key: 'delete',
                                                            className: "zero-delete-red",
                                                            icon: <DeleteOutlined/>,
                                                            label: 'Delete Alert'
                                                        },
                                                    ]
                                                }}
                                            >
                                                <button className="ButtonLink ant-dropdown-link">
                                                    <MoreIcon style={{height: '20px', width: '20px'}}/>
                                                </button>
                                            </Dropdown>
                                        }
                                    </div>
                                </td>
                                <td className="zero-dark-grey">{formatTeamNames(alert)}</td>
                                <td className="zero-dark-grey">{formatSchedule(alert)}</td>
                                <td className="zero-dark-grey">{formatStartDate(alert)}</td>
                                <td className="zero-dark-grey text-center">
                                    <Checkbox
                                        checked={alert.show_dashboard_alerts}
                                        onChange={e => setDashboardAlert(alert.uuid, e.target.checked)}
                                    />
                                </td>
                                <td className="zero-dark-grey text-center">
                                    <Checkbox
                                        checked={alert.send_email_alerts}
                                        onChange={e => setEmailAlert(alert.uuid, e.target.checked)}
                                    />
                                </td>
                            </tr>
                        );
                    })
                );
            }
        } else {
            setTableData(
                [0, 1, 2, 3, 4, 5].map(index => (
                    <tr key={index} className="tr-hover">
                        <td></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td className="text-center"><Skeleton width={20} height={20}/></td>
                        <td className="text-center"><Skeleton width={20} height={20}/></td>
                    </tr>
                ))
            );
        }
    }, [loading, error, alerts])

    return (
        <div className="table-responsive">
            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                <thead className="zero-blue">
                <tr>
                    <th></th>
                    <th className="zero-blue">Team</th>
                    <th className="zero-blue">Schedule</th>
                    <th className="zero-blue">Start Date</th>
                    <th className="zero-blue text-center" style={{width: 150}}>Dashboard Alert</th>
                    <th className="zero-blue text-center" style={{width: 120}}>Email Alert</th>
                </tr>
                </thead>
                <tbody>
                {tableData}
                </tbody>
            </table>
        </div>
    )

}