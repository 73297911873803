import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import moment from 'moment';

import {CheckInLocale, CheckInUserType} from "other/Constants";
import {create_check_in, update_check_in, create_future_check_ins} from 'api/zero-api.js';
import {extractErrorMessages} from "other/Helper";


const initialState = {
    selectedUserType: CheckInUserType.SELF,
    selectedLocale: null,
    selectedDesk: null,
    selectedSpace: null,
    selectedLocation: null,
    surveyCompleted: false,
    guestName: '',
    selectedUser: null,
    otherLocale: '',
    createCheckInStatus: {},
}

export const updateCheckIn = createAsyncThunk(
    'checkInOptions/updateCheckIn',
    async ({uuid, status}, {rejectWithValue}) => {
        try {
            const body = {
                status
            };

            const response = await update_check_in(uuid, JSON.stringify(body));
            const data = await response.json();

            return data;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

export const createCheckIn = createAsyncThunk(
    'checkInOptions/createCheckIn',
    async ({dates}, {rejectWithValue, getState}) => {
        try {
            const {checkInOptions} = getState();
            const {
                selectedUserType, selectedLocale, selectedDesk, selectedUser,
                guestName, otherLocale
            } = checkInOptions;

            let body;

            if (selectedLocale === CheckInLocale.REMOTE) {
                body = {
                    user_type: selectedUserType,
                    locale: selectedLocale,
                    user_uuid: selectedUser?.uuid
                }
            } else if (selectedLocale === CheckInLocale.OTHER) {
                body = {
                    user_type: selectedUserType,
                    locale: selectedLocale,
                    user_uuid: selectedUser?.uuid,
                    other_locale: otherLocale
                }
            } else {
                body = {
                    user_type: selectedUserType,
                    locale: selectedLocale,
                    other_locale: otherLocale,
                    desk_uuid: selectedDesk.uuid
                }

                if (selectedUserType !== CheckInUserType.GUEST) {
                    body.user_uuid = selectedUser.uuid;
                    body.guest_name = '';
                } else {
                    body.guest_name = guestName;
                }
            }

            let responseData = {};

            if (dates) {
                const momentDates = dates.map(date => moment(date).startOf('day'));
                const dateFormat = 'YYYY-MM-DD';
                const todaysDate = moment().startOf('day');
                const createCheckInForToday = momentDates.some(date => todaysDate.isSame(date));
                const futureDates = momentDates.filter(date => !todaysDate.isSame(date));

                if (createCheckInForToday) {
                    const response = await create_check_in(JSON.stringify(body));
                    responseData.today = await response.json();
                } else {
                    responseData.today = null;
                }

                if (futureDates.length > 0) {
                    body.future_dates = futureDates.map(date => date.format(dateFormat));
                    const response = await create_future_check_ins(JSON.stringify(body));
                    responseData = {...responseData, ...(await response.json())}
                }
            } else {
                const response = await create_check_in(JSON.stringify(body));
                responseData = await response.json();
            }

            return responseData;
        } catch (err) {
            console.error('err:', err);
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

const checkInOptionsSlice = createSlice({
    name: 'checkInOptions',
    initialState,
    reducers: {
        resetCheckIn: () => ({...initialState}),
        updateCheckInState: (state, {payload}) => {
            for (const [key, value] of Object.entries(payload)) {
                state[key] = value;
            }
        },
        setSurveyCompleted: (state, {payload}) => {
            state.surveyCompleted = payload;
        },
        selectDeskSpaceAndLocation: (state, {payload}) => {
            const {desk, space, location} = payload;
            if (state.selectedLocation !== location) {
                state.surveyCompleted = false;
            }
            state.selectedDesk = desk;
            state.selectedSpace = space;
            state.selectedLocation = location;
        },
        selectSpaceAndLocation: (state, {payload}) => {
            const {space, location} = payload;
            if (state.selectedLocation !== location) {
                state.surveyCompleted = false;
            }
            state.selectedDesk = null;
            state.selectedSpace = space;
            state.selectedLocation = location;
        },
        selectDesk: (state, {payload}) => {
            state.selectedDesk = payload;
        },
        selectSpace: (state, {payload}) => {
            state.selectedSpace = payload;
            state.selectedDesk = null;
        },
        selectLocation: (state, {payload}) => {
            state.selectedDesk = null;
            state.selectedSpace = null;
            state.selectedLocation = payload;
            state.surveyCompleted = false;
        },
        selectUserType: (state, {payload}) => {
            state.selectedUserType = payload;
            state.selectedDesk = null;
            state.selectedLocale = null;
            state.surveyCompleted = false;
        },
        selectLocale: (state, {payload}) => {
            state.selectedLocale = payload;
            state.selectedDesk = null;
            state.surveyCompleted = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createCheckIn.fulfilled, (state, {payload}) => {
            state.createCheckInStatus = {
                data: payload,
                ok: true
            }
        });
        builder.addCase(createCheckIn.pending, (state) => {
            state.createCheckInStatus = {
                loading: true
            }
        });
        builder.addCase(createCheckIn.rejected, (state, {payload}) => {
            state.createCheckInStatus = {
                data: payload,
                error: true
            }
        });

        builder.addCase(updateCheckIn.fulfilled, (state, {payload}) => {
            state.createCheckInStatus = {
                data: payload,
                ok: true
            }
        });

        builder.addCase(updateCheckIn.pending, (state) => {
            state.createCheckInStatus = {
                loading: true
            }
        });

        builder.addCase(updateCheckIn.rejected, (state, {payload}) => {
            state.createCheckInStatus = {
                data: payload,
                error: true
            }
        });
    }
});

export const {
    selectDesk,
    selectSpace,
    selectLocation,
    selectUserType,
    selectLocale,
    selectDeskSpaceAndLocation,
    setSurveyCompleted,
    resetCheckIn,
    selectSpaceAndLocation,
    updateCheckInState
} = checkInOptionsSlice.actions;

export const checkInActions = checkInOptionsSlice.actions;
export default checkInOptionsSlice.reducer;