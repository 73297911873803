import {checkApiStatus} from 'api/zero-api';
import {useIsOfflineRoute} from 'hooks/useIsOfflineRoute';
import React, {useState, useCallback, useEffect} from 'react';

import OfflineContext from "./OfflineContext";

/**
 * @callback onChangeCallback
 * @param {boolean} isOffline
 */

/**
 * Sets up
 * @param {onChangeCallback} onChange
 * @returns {function}
 */
function initNetworkStatusChecker(onChange) {
    const maxApiCheckTimeoutMs = 300_000; // 5 minutes
    const minApiCheckTimeoutMs = 5_000; // 5 seconds
    const apiCheckTimeoutStepMs = 10_000; // 10 seconds

    const state = {
        _isOffline: !navigator.onLine,
        _onlineDelayTimer: null,
        _apiCheckTimer: null,
        apiCheckTimeoutMs: minApiCheckTimeoutMs,
        lastCheckTimeMs: 0,

        set isOffline(value) {
            this._isOffline = value;
            onChange(value);
        },

        get isOffline() {
            return this._isOffline;
        },

        set onlineDelayTimer(timerId) {
            clearTimeout(this._onlineDelayTimer);
            this._onlineDelayTimer = timerId;
        },

        get onlineDelayTimer() {
            return this._onlineDelayTimer;
        },

        set apiCheckTimer(timerId) {
            clearTimeout(this._apiCheckTimer);
            this._apiCheckTimer = timerId;
        },

        get apiCheckTimer() {
            return this._apiCheckTimer;
        },
    };

    if (!window.zeroDebugToggleForceOfflineMode) {
        window.zeroDebugForceOfflineMode = false;
        window.zeroDebugToggleForceOfflineMode = () => {
            window.zeroDebugForceOfflineMode = !window.zeroDebugForceOfflineMode;
            state.isOffline = window.zeroDebugForceOfflineMode;
        }
    }

    const checkApi = async () => {
        if (!navigator.onLine) {
            if (state.apiCheckTimer) {
                clearTimeout(state.apiCheckTimer);
            }
            state.apiCheckTimer = setTimeout(checkApi, state.apiCheckTimeoutMs);
            return;
        }

        state.onlineDelayTimer = null;
        state.apiCheckTimer = null;
        state.isOffline = !(await checkApiStatus());
        state.lastCheckTimeMs = Date.now();

        if (state.isOffline) {
            state.apiCheckTimeoutMs = minApiCheckTimeoutMs;
        } else {
            state.apiCheckTimeoutMs = Math.min(state.apiCheckTimeoutMs + apiCheckTimeoutStepMs, maxApiCheckTimeoutMs);
        }

        if (state.apiCheckTimer) {
            clearTimeout(state.apiCheckTimer);
        }
        state.apiCheckTimer = setTimeout(checkApi, state.apiCheckTimeoutMs);
    };

    const handleOffline = () => {
        state.isOffline = true;
        state.apiCheckTimeoutMs = minApiCheckTimeoutMs;
    };

    const handleOnline = () => {
        // must remain on line for 1s, then pass an api status check before we mark them as online
        state.onlineDelayTimer = setTimeout(checkApi, 1000);
    };

    const handleVisibilityChange = () => {
        // If page is visible, start the checkApi timer if one doesn't already exist, also immediately sets isOffline
        // if we have no network connection. If page is not visible, stop the apiCheckTimer
        if (document.visibilityState === 'visible') {
            if (state.apiCheckTimer === null) {
                checkApi();
            }

            if (!navigator.onLine) {
                state.isOffline = true;
            }
        } else {
            state.apiCheckTimer = null;
        }
    }

    state.apiCheckTimer = setTimeout(checkApi, 1000);

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
        window.removeEventListener('offline', handleOffline);
        window.removeEventListener('online', handleOnline);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
}

export default function OfflineMonitor({children}) {
    const [isOffline, setIsOffline] = useState(!navigator.onLine);
    const isOfflineRoute = useIsOfflineRoute();
    const onSubmissionPage = location.pathname.includes('/submission/') && !location.pathname.includes('/courses/');
    const onEditPostPage = location.pathname.includes('feed/new_post');

    useEffect(() => {
        if (isOffline && !isOfflineRoute && !onSubmissionPage && !onEditPostPage) {
            document.body.classList.add('offline-mode');
        } else {
            document.body.classList.remove('offline-mode');
        }
    }, [isOffline, isOfflineRoute, onSubmissionPage]);

    useEffect(() => {
        return initNetworkStatusChecker(setIsOffline);
    }, [setIsOffline]);

    return (
        <OfflineContext.Provider value={isOffline}>
            {children}
        </OfflineContext.Provider>
    )
}
