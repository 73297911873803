import PouchDB from 'pouchdb';

import {pouchDbGet, pouchDbUpsert, pouchDbGetAll, defaultPouchDbOptions} from './pouchDbUtils';
import {compareLists, withRetry} from 'other/Helper';

/**
 * Provides a basic wrapper around PouchDB to be extended by concrete implementations.
 */
export default class BaseCache {
    /**
     * @param {string} dbName
     * @param {string} idPropertyName - The property name used to extract _id values from objects (usually the uuid of the object)
     * @param {string} [userId]
     * @param {PouchDB.Configuration.DatabaseConfiguration} [dbOptions]
     */
    constructor(dbName, idPropertyName, orgId, userId = '', dbOptions = defaultPouchDbOptions) {
        this.orgId = orgId;
        this.userId = userId;

        if (userId.length > 0) {
            this.orgPrefix = orgId.substring(0, 8);
            this.userPrefix = userId.substring(0, 8);
            this.db = new PouchDB(`${this.orgPrefix}:${this.userPrefix}:${dbName}`, dbOptions);
        } else {
            this.userPrefix = null;
            this.db = new PouchDB(dbName, dbOptions);
        }
        this.idPropertyName = idPropertyName;

        /** @type {BaseCache_Blobs} */
        this.blobs = {}
        this.initialized = true;
    }

    /**
     * Gets single document.
     * @param {string} id
     * @returns {Promise<BaseCache_Document>}
     */
    async get(id) {
        return await pouchDbGet(this.db, id);
    }

    /**
     * Returns true if document with id exists
     * @param {string} id 
     * @returns {Promise<boolean>}
     */
    async exists(id) {
        const doc = await this.get(id);
        return Object.keys(doc).length > 0;
    }

    /**
     * Gets all documents.
     * @returns {Promise<BaseCache_Document[]>}
     */
    async getAll() {
        return await pouchDbGetAll(this.db);
    }

    /**
     * Upserts single document.
     * @param {string} id
     * @param {object} newDocument
     */
    async set(id, newDocument) {
        await pouchDbUpsert(this.db, id, newDocument);
    }

    /**
     * Creates, updates, and removes documents based on newDocuments.
     * @param {object[]} newDocuments
     * @param {string} diffProperty - Name of the property used to determine if a document needs to be updated
     */
    async setAll(newDocuments, diffProperty = '') {
        const $syncedAt = Date.now() / 1000;

        const docs = await pouchDbGetAll(this.db);
        const docIds = docs.map(doc => doc._id);
        const mappedDocs = docs.reduce((map, doc) => {
            map[doc._id] = doc;
            return map;
        }, {});

        const newDocIds = newDocuments.map(newDoc => newDoc[this.idPropertyName]);
        const mappedNewDocuments = newDocuments.reduce((map, newDoc) => {
            map[newDoc[this.idPropertyName]] = newDoc;
            return map;
        }, {});
        const [idsToCreate, idsToDelete, idsToUpdate] = compareLists(docIds, newDocIds);

        for (const id of idsToDelete) {
            if (!id.startsWith('offline:')) {
                await this.db.remove(mappedDocs[id]);
            }
        }

        for (const id of idsToCreate) {
            await this.db.put({
                ...mappedNewDocuments[id],
                _id: id,
                $syncedAt,
            });
        }

        for (const id of idsToUpdate) {
            const oldDoc = mappedDocs[id];

            if (diffProperty && mappedNewDocuments[id][diffProperty] === oldDoc[diffProperty]) {
                continue;
            }

            const newDoc = this.createUpdatedDocument(oldDoc, mappedNewDocuments[id], $syncedAt);

            if ('_attachments' in oldDoc) {
                newDoc._attachments = oldDoc._attachments;
            }

            await this.db.put(newDoc);
        }
    }

    /**
     * Should return the new document (with _id and _rev) when an existing document is being updated via this.setAll()
     * @param {BaseCache_Document} oldDoc - The current document being updated
     * @param {Object} newData - The new data being inserted (does not contain _id, _rev, etc...)
     * @returns {BaseCache_Document}
     */
    createUpdatedDocument(oldDoc, newData, $syncedAt) {
        return {
            ...oldDoc,
            ...newData,
            _id: oldDoc._id,
            _rev: oldDoc._rev,
            $syncedAt,
        };
    }

    /**
     * Returns all attachment blobs with object URLs.
     * @param {BaseCache_Document[]} [freshDocs]
     * @returns {CacheBlobs}
     */
    async getAttachmentBlobs(freshDocs = null) {
        try {
            const docs = freshDocs || await this.getAll();
            const blobs = {...this.blobs};
            for (const doc of docs) {
                if ('_attachments' in doc) {
                    for (const attachmentId of Object.keys(doc._attachments)) {
                        try {
                            const blob = await this.db.getAttachment(doc._id, attachmentId);
                            if (!blobs[attachmentId]) {
                                blobs[attachmentId] = {
                                    blob,
                                    url: URL.createObjectURL(blob),
                                };
                            }
                        } catch (error) {
                            console.error('Could not retrieve attachment blob:', error);
                        }
                    }
                }
            }
            this.blobs = blobs;
            return blobs;
        } catch (error) {
            console.error('Could not retrieve attachment blobs:', error);
            return {};
        }
    }

    /**
     * Removes an attachment via attachmentId from a PouchDB document.
     * @param {string} documentId
     * @param {string} attachmentId
     */
    async deleteAttachment(documentId, attachmentId) {
        try {
            const doc = await this.get(documentId);
            URL.revokeObjectURL(this.blobs[attachmentId]?.url);
            const blobs = {...this.blobs};
            delete blobs[attachmentId];
            this.blobs = blobs;
            await this.db.removeAttachment(doc._id, attachmentId, doc._rev);
        } catch (error) {
            console.error('Could not delete attachment:', error);
        }
    }

    async downloadAttachment(attachmentId, attachmentData) {
        try {
            return await withRetry(3, 250, async () => {
                const url = attachmentData.public_url;
                const response = await fetch(url);
                const blob = await response.blob();
                const mimeType = attachmentData.mime_type;
                return {attachmentId, blob, mimeType};
            });
        } catch (err) {
            console.error('Could not download attachment:', err);
            return null;
        }
    }

    async downloadAttachments(attachmentIds, attachmentMap) {
        const jobs = [];

        for (const id of attachmentIds) {
            jobs.push(this.downloadAttachment(id, attachmentMap[id]));
        }

        const blobData = (await Promise.all(jobs)).filter(d => d !== null);
        return blobData;
    }

    /**
     * Attempts to download an attachment and save to PouchDB document.
     * @param {string} documentId
     * @param {string} attachmentId
     * @param {Blob} blob
     * @param {string} mimeType
     */
    async cacheAttachment(documentId, attachmentId, blob, mimeType) {
        try {
            const callback = async () => {
                const doc = await this.get(documentId);
                await this.db.putAttachment(doc._id, attachmentId, doc._rev, blob, mimeType);
                
                const blobUrl = URL.createObjectURL(blob);
                this.blobs = {
                    ...this.blobs,
                    [attachmentId]: {
                        blob,
                        url: blobUrl,
                    }
                }
            }

            await withRetry(3, 100, callback);
        } catch (error) {
            console.error('Could not cache attachment:', error);
        }
    }
}
