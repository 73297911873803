import React, {useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {Select} from 'antd';

import UserGeolocation from 'components/Bulletin/UserGeolocation';
import InfoIconComponent from 'components/InfoIconComponent';
import {actions} from './reducer';
import {safe_get, showFooterMobileApp, hideFooterMobileApp, loadGoogleMaps} from 'other/Helper';

export default function PostFormLocation({state, dispatch, postFieldNames, locationEnabled}) {
    const [loadingGps, setLoadingGps] = useState(false);
    const [gpsError, setGpsError] = useState(null);

    const gotCurrentLocation = position => {
        const myLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }

        loadGoogleMaps()
            .then(maps => {
                const geocoder = new maps.Geocoder();

                geocoder.geocode({'latLng': myLocation}, (results, status) => {
                    if (status === maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            const location = safe_get(results[0], "formatted_address", "")
                            const user_geo_location = location;

                            if (user_geo_location !== "") {
                                unstable_batchedUpdates(() => {
                                    dispatch([actions.LOCATION_CHANGED, {
                                        selectedLocations: [location],
                                        lat: `${myLocation.lat}`,
                                        lng: `${myLocation.lng}`
                                    }]);
                                    setGpsError(null);
                                    setLoadingGps(false);
                                });
                            } else {
                                unstable_batchedUpdates(() => {
                                    setGpsError('Cannot find your location.');
                                    setLoadingGps(false);
                                });
                            }
                        }
                    }
                });
            })
            .catch(err => {
                console.error(err);
                setGpsError('An error occurred.');
                setLoadingGps(false);
            })
    };

    if (!locationEnabled) return null;

    return (
        <>
            <div className="panel-heading bulletin-post">
                <h3 className="bulletin-post-headers">{safe_get(postFieldNames, "location", "Location")}</h3>
            </div>

            <div className="bulletin-post">
                <div className="input-group">
                    <Select
                        mode="tags"
                        virtual={false}
                        style={{width: '100%'}}
                        value={state.selectedLocations}
                        placeholder="Add or select an option (optional)"
                        onChange={locations => dispatch([actions.LOCATION_CHANGED, locations])}
                        // onDeselect={() => dispatch([actions.LOCATION_CHANGED, []])}
                        className="library-tags bulletin"
                        onFocus={hideFooterMobileApp}
                        onBlur={showFooterMobileApp}
                        notFoundContent={"No locations added yet"}
                        dropdownStyle={{zIndex: "999999999"}}
                    >
                        {
                            state.locations.map((location, index) => (
                                <Select.Option key={index} value={location}>{location}</Select.Option>
                            ))
                        }
                    </Select>
                    <span className="input-group-btn">
                    <UserGeolocation
                        gotCurrentLocation={gotCurrentLocation}
                        locationError={(error) => {
                            unstable_batchedUpdates(() => {
                                setLoadingGps(false);
                                setGpsError(error.message);
                            });
                        }}
                        loading={() => {
                            unstable_batchedUpdates(() => {
                                setLoadingGps(true);
                                setGpsError(null);
                            });
                        }}
                    />
                </span>
                </div>
                {
                    loadingGps &&
                    <div style={{paddingTop: "4px"}}>
                        <small className="">Finding your location...</small>
                    </div>
                }
                {
                    gpsError && !loadingGps &&
                    <small className="error">{gpsError}</small>
                }
            </div>
        </>
    );
}