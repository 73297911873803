export default class ZeroEventBus {
    constructor() {
        /** @type {Map<string, Function[]} */
        this.subscribers = new Map();
    }

    /**
     * @param {string} eventName 
     * @param {Function} callback 
     * @returns {Function}
     */
    subscribe(eventName, callback) {
        if (!this.subscribers.has(eventName)) {
            this.subscribers.set(eventName, []);
        }
        this.subscribers.get(eventName).push(callback);

        return () => { this.unsubscribe(eventName, callback); }
    }

    /**
     * @param {string} eventName 
     * @param {Function} callback 
     */
    unsubscribe(eventName, callback) {
        const callbacks = this.subscribers.get(eventName)
        if (callbacks) {
            this.subscribers.set(eventName, callbacks.filter(cb => cb !== callback));
        }
    }

    /**
     * @param {string} eventName 
     * @param {any} data 
     */
    emit(eventName, data) {
        const callbacks = this.subscribers.get(eventName);
        if (callbacks) {
            callbacks.forEach(cb => cb(data));
        }
    }
}