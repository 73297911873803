import {React} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Tag, Dropdown, Menu} from 'antd';
import Skeleton from 'react-loading-skeleton';
import {IoIosMore as MoreIcon} from 'react-icons/io';

import {safe_get, safeProfilePic, isAdmin} from 'other/Helper';
import InfoIconComponent from 'components/InfoIconComponent';
import TeamListPopover from 'components/Shared/TeamListPopover.js';
import UserPopoverCard from 'components/Shared/UserPopoverCard';
import {UserWithAvatar} from 'components/Spaces/DirectoryPage';


function LoaderTableData({column}) {
    if (column === 'name') {
        return (
            <div className="media">
                <div className="media-left profile-img">
                    <Skeleton circle={true} width={40} height={40}/>
                </div>
                <div className="media-body account-card">
                    <p className="text-md text-semibold profile name "
                       style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                        <Skeleton width={130}/>
                    </p>
                    <p className="text-sm profile email " style={{display: 'block', fontSize: '.9em'}}>
                        <Skeleton width={100}/>
                    </p>
                </div>
            </div>
        )
    } else if (column === 'role') {
        return (
            <p className="role-text">
                <Skeleton width={110}/>
            </p>
        )
    } else if (column === 'status') {
        return (
            <Skeleton height={21} width={75}/>
        )
    } else if (column === 'teams') {
        return (
            <Skeleton width={125}/>
        )
    } else if (column === 'action') {
        return (
            <div className="media-right options" style={{marginTop: '6px', padding: "0px"}}>
                <Skeleton width={28} height={20}/>
            </div>
        )
    }
}


function UserTableData({
                           user, onUserClick = user => {
    }, disableUserClick
                       }) {
    const currentUser = useSelector(state => state.user.user);
    const name = safe_get(user, "first_name", "") + " " + safe_get(user, "last_name", "");
    const email = safe_get(user, "email", "");
    const isDeactivated = disableUserClick || safe_get(user, 'status', '') === 'deactivated';

    const handleUserClick = () => {
        if (isDeactivated) return;
        onUserClick(user);
    }

    return (
        <UserWithAvatar
            user={user}
            popoverProps={{
                showExtraAction: !isDeactivated && isAdmin(currentUser),
                extraActionText: "Edit profile",
                onExtraAction: handleUserClick
            }}
        />
    )
}


function StatusTableData({user}) {
    const status = user.status;
    switch (status) {
        case "pending":
            return <Tag color="orange" style={{borderColor: "#FA8C13"}}>Invited</Tag>
        case "active":
            return <Tag color="green" style={{borderColor: "#52c41a"}}>Registered</Tag>
        case "deactivated":
            return <Tag style={{color: "#505050"}}>Deactivated</Tag>
        default:
            return null;
    }
}


function RoleTableData({user}) {
    let roles = safe_get(user, "roles", []);

    if (roles.includes("admin")) {
        return (
            <p className="role-text zero-dark-grey"><span className="badge badge-success badge-icon badge-fw "></span>Admin
            </p>)
    } else if (roles.includes("team_lead")) {
        return (<p className="role-text zero-dark-grey"><span className="badge badge-info badge-icon badge-fw "></span>Lead
        </p>)
    } else if (roles.includes("user")) {
        return (
            <p className="role-text zero-dark-grey"><span className="badge badge-purple badge-icon badge-fw "></span>Member
            </p>)
    } else if (roles.includes("viewer")) {
        return (
            <p className="role-text zero-dark-grey"><span className="badge badge-danger badge-icon badge-fw "></span>Viewer
            </p>)
    } else if (roles.includes("user_restricted")) {
        return (
            <p className="role-text zero-dark-grey"><span className="badge badge-warning badge-icon badge-fw "></span>Contributor
            </p>)
    } else {
        return null;
    }
}

export default function UsersTable({
                                       loading,
                                       users,
                                       showTeams = true,
                                       generateMenuOptions,
                                       onMenuClick,
                                       onUserClick = (user) => {
                                       },
                                       disableUserClick,
                                       total,
                                   }) {
    
    return (
        <div className="table-responsive" style={{border: "none"}}>
            <table className="table table-vcenter mar-btm-0" style={{tableLayout: "fixed"}}>
                <thead>
                <tr>
                    <th style={{width: "40px"}}></th>
                    <th className="zero-blue" style={{width: "280px"}}>
                        Members {!loading && <span>({total})</span>}
                    </th>
                    <th className="zero-blue" style={{width: "120px"}}>Role
                        <InfoIconComponent
                            position={"bottom"}
                            width={"155px"}
                            text={<span>To learn more about roles in ZERO, click <a rel="noopener noreferrer"
                                                                                    onClick={() => FreshworksWidget('open', 'article', {'id': 70000417097})}
                                                                                    className="blue-link">here</a>.</span>}
                        />
                    </th>
                    <th className="zero-blue" style={{width: "95px"}}>Status</th>
                    {showTeams &&
                        <th className="zero-blue" style={{width: "260px"}}>Teams</th>
                    }
                </tr>
                </thead>
                {
                    loading &&
                    <tbody>
                    {
                        [...Array(10)].map((_, index) => (
                            <tr key={index} className="tr-hover">
                                <td><Skeleton width={30} height={20}/></td>
                                <td style={{width: "280px"}}><LoaderTableData column="name"/></td>
                                <td style={{width: "120px"}}><LoaderTableData column="role"/></td>
                                <td style={{width: "95px"}}><LoaderTableData column="status"/></td>
                                {showTeams &&
                                    <td style={{width: "260px"}}><LoaderTableData column="teams"/></td>
                                }
                                <td style={{width: "40px"}}><LoaderTableData column="action"/></td>

                            </tr>
                        ))
                    }
                    </tbody>
                }
                {
                    !loading && users.length > 0 &&
                    <tbody>
                    {
                        users.map((user, index) => (
                            <tr key={index} className="tr-hover"
                                style={{backgroundColor: user.status === "deactivated" ? "#f6f6f6" : "white"}}>
                                <td style={{width: "40px"}}>
                                    <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                                        <Dropdown
                                            menu={{
                                                items: generateMenuOptions(user),
                                                onClick: ({key}) => onMenuClick(key, user)
                                            }}
                                            trigger={['click']}
                                        >
                                            <button className="ButtonLink ant-dropdown-link">
                                                <MoreIcon style={{height: '20px', width: '20px'}}/>
                                            </button>
                                        </Dropdown>
                                    </div>
                                </td>
                                <td className="zero-blue" style={{width: "280px"}}><UserTableData user={user}
                                                                                                  onUserClick={onUserClick}
                                                                                                  disableUserClick={disableUserClick}/>
                                </td>
                                <td style={{width: "120px"}}><RoleTableData user={user}/></td>
                                <td style={{width: "95px"}}><StatusTableData user={user}/></td>
                                {showTeams &&
                                    <td style={{whiteSpace: "normal", width: "260px", color: "var(--zero-dark-grey)"}}>
                                        <TeamListPopover teams={safe_get(user, 'teams', [])}/></td>
                                }
                               
                            </tr>
                        ))
                    }
                    </tbody>
                }
                {
                    !loading && users.length === 0 &&
                    <tbody>
                    <tr>
                        <td className="react-bs-table-no-data zero-dark-grey" colSpan={showTeams ? "5" : "4"}>
                            No users.
                        </td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    );
}