import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';

import {get_locations, update_location, notifyError} from '../../../api/zero-api.js'

import {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    UserAddOutlined,
    UserDeleteOutlined,
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import {Dropdown, Menu, Input, Popover, Switch} from 'antd'

import NotificationAlert from '../../../other/NotificationAlert.js';

import {IoIosMore as MoreIcon} from 'react-icons/io';
import Skeleton from 'react-loading-skeleton'

import AddMembersModal from './AddMembersModal.js';
import LocationMembersModal from './LocationMembersModal.js';
import DeleteLocationModal from '../../Incidents/DeleteLocationModal.js';

import {safe_get, scrollToTop, mainContentContainerClass, safeProfilePic, isAdmin} from '../../../other/Helper.js';
import {updateIncidentsFilterQuery, update_current_location} from '../../../store/actions/IncidentsActions';
import LocationModal from 'components/Incidents/LocationModal.js';
import UserPopoverCard from 'components/Shared/UserPopoverCard.js';

class ManageLocations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adminsAndLeads: [],
            locationUsers: [],
            locations: [],
            filtered_locations: Array(10).fill(0),
            isFiltered: false,
            team: {},
            myTeams: [],
            loading: true,
            tableWidth: 870,
            tableHeight: 500
        };

        this.getLocations = this.getLocations.bind(this);
        this.newLocation = this.newLocation.bind(this);
        this.searchTable = this.searchTable.bind(this);
        this.optionsFormatter = this.optionsFormatter.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    componentWillUnmount() {

    }

    componentDidMount() {
        var self = this;
        scrollToTop("page-head");
        this.getLocations();

        // get_list_users("?roles=admin,team_lead").then(function(success) {
        //     success.json().then(success => {

        //         var users = safe_get(success, "users", []).filter(function(obj) {
        //             return obj.status === "active" && obj.uuid !== "";
        //         });

        //         self.setState({ adminsAndLeads: users });
        //         self.getLocations();

        //     });
        // }, function (error) {
        //     console.log(error);
        //     self.getLocations();
        // });

    }

    getLocations() {
        var self = this;
        get_locations("?can_edit=true").then(function (success) {
            success.json().then(success => {
                var locations = safe_get(success, "locations", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                });

                self.setState({
                    all_locations: locations,
                    filtered_locations: locations,
                    showDeleteLocationModal: false,
                    showEditLocationModal: false,
                    showLocationModal: false,
                    loading: false
                });
            })
        }, function (error) {
            notifyError(error);
        });
    }

    loadingFormatter(data) {
        let column = data.columnIndex
        if (column === 0) {
            return (
                <div>
                    <Skeleton width={110}/>
                </div>
            )
        } else if (column === 1) {
            return (
                [...Array(6)].map((e, index) => (
                    <span key={index} style={{marginRight: "5px", marginTop: "5px"}}>
                        <Skeleton key={index} circle={true} width={26} height={26} inline />
                    </span>
                ))
            )
        } else if (column === 2) {
            return (
                <div className="media-right options" style={{marginTop: '6px'}}>
                    <Skeleton height={20} width={28}/>
                </div>
            )
        }
    }

    locationFormatter(location) {
        return (
            <div>
                <button
                    onClick={() => {
                        this.setState({showEditLocationModal: true, location})
                    }}
                    className="ButtonLink btn-link"
                    style={{fontWeight: "500", textAlign: "left"}}
                >
                    {location.name}
                </button>
            </div>
        );
    }

    usersFormatter(location) {
        let allUsers = safe_get(location, "access_list", []).sort(function (a, b) {
            let a_name = a.first_name + " " + a.last_name;
            let b_name = b.first_name + " " + b.last_name;
            return a_name.toLowerCase() > b_name.toLowerCase() ? 1 : -1;
        });

        let cutOff = 10;
        let users_count = allUsers.length;

        return (
            <div>
                {
                    [...allUsers].slice(0, cutOff).map((user, index) => (
                        <UserPopoverCard
                            key={index}
                            user={user}
                            showRemoveAction={true}
                            removeActionText="Remove Manager"
                            onRemoveUser={(user) => this.removeUser(user, location)}
                        >
                            <span className="hover-cursor">
                                {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                    marginRight: "5px",
                                    display: "inline-block"
                                })}
                            </span>
                        </UserPopoverCard>
                    ))
                }
                {
                    users_count > cutOff &&
                    <span onClick={() => {
                        this.setState({location: location, locationUsers: allUsers, showLocationMembersModal: true})
                    }} style={{cursor: "pointer"}}>
                        <div className={"default-avatar-circle bulletin"}
                             style={{marginRight: "5px", display: "inline-block", border: "1px solid #505050"}}>
                            <p className="initials" style={{color: "#505050"}}>
                                +{users_count - cutOff < 1000 ? users_count - cutOff : `${Math.round((users_count - cutOff) / 1000)}k`}
                            </p>
                        </div> 
                    </span>
                }
            </div>
        );
    }

    userAccessFormatter = (location) => {
        let access_list_users = location.access_list.map(user => user.uuid);
        let isInLocation = access_list_users.includes(this.props.user.uuid);

        return (
            <Switch
                checked={isInLocation}
                onChange={(checked) => {
                    this.toggleLocationAccess(checked, location)
                }}
                style={{float: "none", margin: "0 auto"}}
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        )
    }


    toggleLocationAccess(checked, location) {
        let {
            location_uuid,
            name, street_address, city,
            state, zip, industry_description,
            naics, number_of_employees, hours_worked,
            access_list, reset_used_desks,
            incidents_enabled, spaces_enabled,
        } = location

        let body = {
            name: name,
            street_address: street_address,
            city: city,
            state: state,
            zip: zip,
            industry_description: industry_description,
            naics: naics,
            number_of_employees: parseInt(number_of_employees),
            hours_worked: parseInt(hours_worked),
            reset_used_desks,
            incidents_enabled,
            spaces_enabled,
        }

        let location_users = [...access_list].map(user => user.uuid);

        if (checked) {
            let users = location_users.concat([this.props.user.uuid]);
            body["access_list_user_uuids"] = users;

            body = JSON.stringify(body);

            this.updateLocation(location_uuid, body);
        } else {
            let index = location_users.findIndex((uuid) => {
                return uuid === this.props.user.uuid
            });

            if (index >= 0) {
                location_users.splice(index, 1);
                body["access_list_user_uuids"] = location_users;

                body = JSON.stringify(body);
                this.updateLocation(location_uuid, body);
            }

        }
    }

    updateLocation = (location_uuid, body) => {
        let self = this;
        update_location(location_uuid, body).then(function (_success) {
            NotificationAlert("success", "", "Access updated.");
            self.getLocations();
        }, function (_error) {
            NotificationAlert("success", "", "Unable to update.");
        });
    }


    optionsFormatter(location) {
        const menu = {
            onClick: e => this.handleMenuClick(e.key, location),
            items: [
                {
                    key: 'add_members',
                    icon: <UserAddOutlined/>,
                    label: 'Add managers'
                },
                {
                    key: 'remove_members',
                    icon: <UserDeleteOutlined/>,
                    label: 'Remove managers'
                },
                {
                    key: 'edit_location',
                    icon: <EditOutlined/>,
                    label: 'Edit location'
                },
                {
                    key: 'delete_location',
                    icon: <DeleteOutlined/>,
                    label: 'Delete location',
                    className: 'zero-delete-red'
                }
            ]
        }

        let isInLocation = isAdmin(this.props.user);

        if (!isInLocation) {
            let access_list_users = location.access_list.map(user => user.uuid);
            isInLocation = access_list_users.includes(this.props.user.uuid);
        }

        if (isInLocation) {
            return (
                <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                    <Dropdown menu={menu} trigger={['click']}>
                        <button className="ButtonLink ant-dropdown-link">
                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                        </button>
                    </Dropdown>
                </div>
            );
        }

        return null


    }

    handleMenuClick(key, location) {
        switch (key) {
            case "edit_location":
                this.setState({showEditLocationModal: true, location: location});
                break;
            case "delete_location":
                this.setState({showDeleteLocationModal: true, location: location});
                break;
            case "add_members":
                this.setState({showAddMembersModal: true, location: location});
                break;
            case "remove_members":
                this.setState({
                    showLocationMembersModal: true,
                    location: location,
                    locationUsers: safe_get(location, "access_list", [])
                });
                break;
            default:
                break;
        }

    }

    searchTable(e) {
        let value = e.target.value
        var searchValue = value.toLowerCase();
        if (searchValue !== "") {
            var filterList = this.state.all_locations.filter(function (object) {
                return object.name.toLowerCase().includes(searchValue)
            });
            this.setState({filtered_locations: filterList});
        } else {
            this.setState({filtered_locations: this.state.all_locations});
        }

    }

    newLocation() {
        this.setState({showLocationModal: true});
    }

    goToLocation = (location) => {
        this.props.dispatch(update_current_location(location));
        this.props.dispatch(updateIncidentsFilterQuery("location_uuid=" + location.location_uuid));
        let path = "/" + this.props.org_uuid + "/home/incidents/location/all_locations";
        this.props.history.push(path)
    }

    removeUser = (userToRemove, location) => {
        const {
            name, street_address, city,
            state, zip, industry_description,
            naics, number_of_employees, hours_worked,
            access_list, reset_used_desks,
            incidents_enabled, spaces_enabled,
            location_uuid
        } = location;

        const users = [...access_list];
        const userIndex = users.findIndex(user => user.uuid === userToRemove.uuid);

        if (userIndex !== -1) {
            users.splice(userIndex, 1);
            const body = JSON.stringify({
                name: name,
                street_address: street_address,
                city: city,
                state: state,
                zip: zip,
                industry_description: industry_description,
                naics: naics,
                number_of_employees: parseInt(number_of_employees),
                hours_worked: parseInt(hours_worked),
                access_list_user_uuids: users.map(x => x.uuid),
                reset_used_desks,
                incidents_enabled,
                spaces_enabled,
            });

            update_location(location_uuid, body)
                .then(() => {
                    NotificationAlert("success", "", "Manager removed.");
                    this.getLocations();
                })
                .catch(() => {
                    NotificationAlert("success", "", "Unable to remove manager.");
                });
        } else {
            NotificationAlert("success", "", "Unable to remove manager.");
        }

    }


    render() {

        return (
            <div className={mainContentContainerClass()}>
                {
                    this.state.showLocationModal &&
                    <LocationModal
                        onClose={() => this.setState({showLocationModal: false})}
                        onSaveComplete={this.getLocations}
                    />
                }

                {
                    this.state.showEditLocationModal &&
                    <LocationModal
                        location={this.state.location}
                        onClose={() => this.setState({showEditLocationModal: false})}
                        onSaveComplete={this.getLocations}
                    />
                }

                {
                    this.state.showDeleteLocationModal &&
                    <DeleteLocationModal
                        show={this.state.showDeleteLocationModal}
                        cancel={() => {
                            this.setState({showDeleteLocationModal: false});
                        }}
                        location_uuid={this.state.location.location_uuid}
                        callback={this.getLocations}
                    />
                }

                {
                    this.state.showAddMembersModal &&
                    <AddMembersModal
                        show={this.state.showAddMembersModal}
                        cancel={() => {
                            this.setState({showAddMembersModal: false});
                        }}
                        location={this.state.location}
                        callback={this.getLocations}
                    />
                }

                {
                    this.state.showLocationMembersModal &&
                    <LocationMembersModal
                        show={this.state.showLocationMembersModal}
                        cancel={() => {
                            this.setState({showLocationMembersModal: false})
                        }}
                        location={this.state.location}
                        locationUsers={this.state.locationUsers}
                        callback={this.getLocations}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage Locations
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '135px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={this.newLocation}>
                                                <PlusCircleOutlined/> Create Location
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={this.newLocation}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            Locations are used to group teams together. Below you can assign teams to locations
                                            for easier searching and filtering on Posts, Forms, Courses, and Library. Locations are also used in incidents to log incident reports.
                                        </p>

                                        <div className="members-search" style={{width: "100%", marginTop: "8px"}}>
                                            {/* <Select
                                                mode="combobox"
                                                placeholder={"Search..."}
                                                style={{width: "100%"}}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onChange={this.searchTable}
                                                dropdownStyle={{display: "none"}}
                                            >
                                            </Select> */}
                                            <Input
                                                placeholder="Search..."
                                                onChange={this.searchTable}
                                                style={{width: "100%"}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        <div className="panel mar-btm-0">

                            <div className="table-responsive"
                                 style={{border: "1px solid #DDDDDD", borderRadius: "5px"}}>
                                <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                    <thead style={{color: "#1D2A35"}}>
                                    <tr>
                                        <th></th>
                                        <th className="zero-blue" style={{width: "200px"}}>
                                            {`Locations (${this.state.filtered_locations.length})`}
                                        </th>
                                        <th className="zero-blue">
                                            Managers
                                        </th>
                                        <th className="zero-blue text-center">
                                            Teams
                                        </th>
                                        <th className="zero-blue text-center">
                                            Your Access
                                        </th>
                                    </tr>
                                    </thead>
                                    {
                                        this.state.loading &&
                                        <tbody>
                                        {
                                            [...Array(10)].map((_item, index) => (
                                                <tr key={index} className="tr-hover">
                                                    <td style={{width: "50px"}}><Skeleton width={30} height={22}/></td>
                                                    <td><Skeleton width={130}/></td>
                                                    <td>
                                                        {
                                                            [...Array(6)].map((e, index) => (
                                                                <span key={index}
                                                                style={{marginRight: "5px", marginTop: "5px"}}>
                                                                        <Skeleton key={index} circle={true} width={26}
                                                                                  height={26} inline/>
                                                                    </span>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="text-center"><Skeleton width={70}/></td>
                                                    <td className="text-center"><Skeleton height={22} width={45}/></td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    }
                                    {
                                        !this.state.loading && this.state.filtered_locations.length > 0 &&
                                        <tbody>
                                        {
                                            this.state.filtered_locations.map((location, index) => (
                                                <tr key={index} className="tr-hover">
                                                    <td style={{width: "50px"}}>{this.optionsFormatter(location)}</td>
                                                    <td>{this.locationFormatter(location)}</td>
                                                    <td>{this.usersFormatter(location)}</td>
                                                    <td className="text-center">
                                                        <span className="blue-link" onClick={() => this.setState({
                                                            showEditLocationModal: true,
                                                            location
                                                        })}>{location.teams.length}</span>
                                                    </td>
                                                    <td className="text-center">{this.userAccessFormatter(location)}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    }
                                    {
                                        !this.state.loading && this.state.filtered_locations.length === 0 &&
                                        <tbody>
                                        <tr>
                                            <td className="react-bs-table-no-data" colSpan="6">
                                                No locations.
                                            </td>
                                        </tr>
                                        </tbody>
                                    }

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }


}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(ManageLocations);