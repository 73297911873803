import {Popover} from 'antd';
import {DragOutlined} from '@ant-design/icons'

export default function DragIcon() {
    return (
        <Popover content="Drag to reorder">
            <div style={{marginLeft: '6px'}}>
                <DragOutlined className="show-on-hover" style={{fontSize: 18, color: "#555"}} />
            </div>
        </Popover>
    )
}