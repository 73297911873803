import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';
import {useSearchParams} from "react-router-dom";

import {
    get_my_teams_data,
    get_team_promise,
    update_team_cookie,
    get_forms_filters,
    get_assignment_filters
} from '../../api/zero-api'
import {isAdmin, isTeamLead, safe_get} from '../../other/Helper.js';

import {update_current_team} from '../../store/actions/TeamsActions';
import {
    updateFormsFilterQuery,
    updateFormsPeriodQuery,
    updateFiltersForms,
    updateFiltersSchedules
} from '../../store/actions/FormsActions';

import {Select} from 'antd';
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';

import TimePeriodFilter from '../TimePeriodFilter.js';
import LocationFilter from 'components/Shared/LocationFilter';
import NotificationAlert from 'other/NotificationAlert';

class FormsSearchFilter extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            forms: [],
            loading_filters: true,
            schedules: [],
            categories: [],
            period_query: "all_time",
            selected_team: "my_teams",
            selected_filters: [],
            assignment_forms: [],
            assignment_schedules: [],
            location: '',
        };

        this.formFiltersLoaded = false;
        this.assignmentFiltersLoaded = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.page_type !== this.props.page_type) {
            this.formFiltersLoaded = false;
            this.assignmentFiltersLoaded = false;

            this.getFilters();
            this.addPrefilters();
            // clear form filter when swapping between forms and couurses / lms
            let filters = [];
            this.filterSelect(filters);
        }

        if (prevProps.activeTab !== this.props.activeTab) {
            this.getFilters();
        }

        if (prevProps.forms_query !== this.props.forms_query || prevProps.date_query !== this.props.date_query) {
            if (this.props.activeTab === 'analytics' && window.location.search.includes('export=true')) {
                this.addPrefilters();
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getFilters();
        this.addPrefilters();
        this.getUpdatedSelect();
    }

    getFilters = async () => {
        if (this.props.activeTab === "assignments") {
            await this.getAssignmentFilters();
        } else {
            await this.getFormsFilters();
        }
    }

    getFormsFilters = async () => {
        if (!this._isMounted || this.formFiltersLoaded) {
            return;
        }

        try {
            const response = await get_forms_filters(`?form_types=${this.props.page_type}`);
            const content = await response.json();
            
            const forms = safe_get(content, "forms", []);
            this.props.dispatch(updateFiltersForms(forms));

            const schedules = safe_get(content, "schedules", []);
            this.props.dispatch(updateFiltersSchedules(schedules));

            
            if(this.props.page_type === 'regular') {
                let categories = safe_get(content, "categories", []);
                this.setState({loading_filters: false, categories: categories});
            }
            this.formFiltersLoaded = true;
        } catch (err) {
            console.error(err);
            NotificationAlert("error", "", "Could not load form filters");
        }
    }

    getAssignmentFilters = async () => {
        if (this.assignmentFiltersLoaded) {
            return;
        }

        if (isAdmin(this.props.user) || isTeamLead(this.props.user)) {
            try {
                const response = await get_assignment_filters(`?form_types=${this.props.page_type}`);
                const content = await response.json();
                const assignment_forms = safe_get(content, "forms", []);
                const assignment_schedules = safe_get(content, "schedulers", []);
                this.setState({assignment_forms, assignment_schedules});
                this.assignmentFiltersLoaded = true;
            } catch (err) {
                console.error(err);
                NotificationAlert("error", "", "Could not load assignment filters");
            }
        }
    }

    addPrefilters = () => {
        var query = this.props.forms_query + this.props.period_query;
        var filters = query.substring(1).split("&");

        filters = filters.filter(function (n) {
            return n !== "" && n
        });

        var selected_team = this.props.team_uuid;

        var selected_filters = [];
        var selected_filters_UI = [];
        var lockMySubmissions = false;

        for (var i in filters) {
            var filter = filters[i].split("=");
            var filter_type = filter[0];
            var filter_value = filter[1];

            switch (filter_type) {
                case "team_uuid":
                    if (!lockMySubmissions) {
                        selected_team = filter_value;
                    }
                    break;
                case "form_uuid":
                    let form_uuids = filter_value.split(",");
                    form_uuids.forEach(uuid => {
                        selected_filters.push("form_uuid=" + uuid);
                        selected_filters_UI.push("form_uuid=" + uuid);
                    });
                    break;
                case "scheduler_uuid":
                    let schedule_uuids = filter_value.split(",");
                    schedule_uuids.forEach(uuid => {
                        selected_filters.push("scheduler_uuid=" + uuid);
                        selected_filters_UI.push("scheduler_uuid=" + uuid);
                    });
                    break;
                case "search":
                    selected_filters.push(filters[i]);
                    selected_filters_UI.push(filter_value);
                    break;
                case "my_submissions":
                    if (filter_value === 'true') {
                        selected_team = 'my_submissions';
                        lockMySubmissions = true;
                    }
                    break;
                default:
                    break;
            }

        }

        this.setState({
            selected_team: selected_team,
            selected_filters: selected_filters,
            selected_filters_UI: selected_filters_UI
        });
    }

    getUpdatedSelect = () => {
        if (this.props.forms_query && this.props.activeTab === 'forms' && this.props.forms_query.includes('category_uuids')) {
            const query = new URLSearchParams(this.props.forms_query);
            const category_uuids = query.get('category_uuids');
            if (category_uuids) {
                let filters = category_uuids.split(",");
                filters = filters.map(i => 'category_uuid=' + i);
                this.filterSelect(filters)
            }
        }
    }

    filterOption = (inputValue, option) => {
        let item = safe_get(option, "children", "");

        if (typeof item === "string") {
            return item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (typeof item === "object") {
            return item[1].toLowerCase().includes(inputValue.toLowerCase());
        }
    }

    filterSelect = (value) => {
        let selected_filters = [];
        let selected_filters_UI = []
        let existing_filters = [...this.state.selected_filters];

        // let formOptionsDisabled = existing_filters.find((item) => { return item.includes("form_uuid=") });
        // let scheduleOptionsDisabled = existing_filters.find((item) => { return item.includes("scheduler_uuid=") });
        let statusOptionsDisabled = existing_filters.find((item) => {
            return item.includes("status=")
        });

        value.forEach(element => {
            var filter = element
            if (!filter.includes("=")) {
                filter = "search=" + filter;
            }

            if (!existing_filters.includes(filter)) {
                let canAddFilter = Boolean(
                    filter.includes("form_uuid=") ||
                    filter.includes("scheduler_uuid=") ||
                    filter.includes("category_uuid=") ||
                    !statusOptionsDisabled && filter.includes("status=") ||
                    filter.includes("search=")
                )

                if (canAddFilter) {
                    selected_filters.push(filter)
                    selected_filters_UI.push(element)
                }
            } else {
                selected_filters.push(filter)
                selected_filters_UI.push(element)
            }
        });

        this.setState({selected_filters, selected_filters_UI});

        let form_uuids = [];
        let schedule_uuids = [];
        let category_uuids = []
        let status = [];
        let search = [];

        selected_filters.forEach(element => {

            if (element.includes("form_uuid=")) {
                form_uuids.push(element.split("=")[1])
            } else if (element.includes("scheduler_uuid=")) {
                schedule_uuids.push(element.split("=")[1]);
            } else if (element.includes("category_uuid=")) {
                category_uuids.push(element.split("=")[1]);
            } else if (element.includes("status=")) {
                status.push(element.split("=")[1])
            } else {
                search.push(element.split("=")[1])
            }
        });

        const query = new URLSearchParams(this.props.forms_query);

        if (form_uuids.length > 0) {
            query.set('form_uuid', form_uuids.join(','));
        } else {
            query.delete('form_uuid');
        }

        if (schedule_uuids.length > 0) {
            query.set('scheduler_uuid', schedule_uuids.join(','));
        } else {
            query.delete('scheduler_uuid');
        }

        if (category_uuids.length > 0) {
            query.set('category_uuids', category_uuids.join(','));
        } else {
            query.delete('category_uuids');
        }

        if (status.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }

        if (search.length > 0) {
            query.set('search', search.join(','));
        } else {
            query.delete('search');
        }

        this.props.dispatch(updateFormsFilterQuery(`?${query}`));
    }

    handleTeamSelect = (team_uuid) => {
        this.get_team(team_uuid);
        this.setState({selected_team: team_uuid});

        const query = new URLSearchParams(this.props.forms_query);

        if (team_uuid === "my_submissions") {
            query.set('team_uuid', 'my_teams');
            query.set('my_submissions', 'true');
        } else {
            query.set('team_uuid', team_uuid);
            query.delete('my_submissions');
        }

        this.props.dispatch(updateFormsFilterQuery(`?${query}`));
    }

    get_team = (team_uuid) => {
        var self = this;

        update_team_cookie(team_uuid);

        if (team_uuid === "my_teams" || team_uuid === "my_submissions") {
            get_my_teams_data().then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var my_teams = safe_get(success, "team", {});
                        self.props.dispatch(update_current_team(my_teams));
                    }
                });
            }, function (error) {
                var no_team = {
                    uuid: "Unknown",
                    name: "Unknown",
                    description: "",
                    users_count: 0,
                }
                self.props.dispatch(update_current_team(no_team));
            });
        } else if (team_uuid === "organization_teams") {
            var team = {
                uuid: "organization_teams",
                name: "All Account Teams",
                description: "",
                users_count: 0,
            }
            self.props.dispatch(update_current_team(team));
        } else {
            get_team_promise(team_uuid).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var team = safe_get(success, "team", {});
                        self.props.dispatch(update_current_team(team));
                    }
                });
            }, function (error) {
                var no_team = {
                    uuid: "Unknown",
                    name: "Unknown",
                    description: "",
                    users_count: 0,
                }
                self.props.dispatch(update_current_team(no_team));
            });
        }
    }

    periodSelected = (period) => {
        this.setState({period_query: period});
    }

    updatePeriodQuery = (query) => {
        this.props.dispatch(updateFormsPeriodQuery(query));
    }

    onLocationSelected = (location) => {
        this.setState({location});

        const query = new URLSearchParams(this.props.forms_query);

        if (location) {
            query.set('location_uuid', location);
        } else {
            query.delete('location_uuid');
        }

        this.props.dispatch(updateFormsFilterQuery(`?${query}`));
    }


    render() {

        let formsList = this.props.activeTab === "assignments" ? this.state.assignment_forms : this.props.forms;
        let schedulesList = this.props.activeTab === "assignments" ? this.state.assignment_schedules : this.props.schedules;

        let grey = "#B5B5B5";
        let red = "#f56946";
        let green = "#52c41a";
        let yellow = "#f3b44b"

        return (
            <div>
                <div className="row" style={{padding: "0 0.5rem"}}>
                    <Select
                        mode="tags"
                        placeholder={"Filter or search..."}
                        showArrow={false}
                        virtual={false}
                        // value={this.state.selected_filters_UI}
                        value={this.state.selected_filters.length > 0 ? this.state.selected_filters_UI : []}
                        onChange={this.filterSelect}
                        filterOption={this.filterOption}
                        loading={this.state.loading_filters}
                        className="col-xs-12 col-sm-6 col-reduce-padding"
                        style={{marginTop: '0.8rem'}}
                    >
                        {
                            this.props.activeTab === "assignments" &&
                            <Select.OptGroup label="Status">
                                <Select.Option value="status=completed">
                                    <CheckCircleTwoTone twoToneColor={green} className="mar-rgt-5"/>
                                    Completed
                                </Select.Option>
                                <Select.Option value="status=open">
                                    <CloseCircleTwoTone twoToneColor={grey} className="mar-rgt-5"/>
                                    Open
                                </Select.Option>
                                <Select.Option value="status=missed">
                                    <CloseCircleTwoTone twoToneColor={red} className="mar-rgt-5"/>
                                    Missed
                                </Select.Option>
                                <Select.Option value="status=excused">
                                    <CloseCircleTwoTone twoToneColor={yellow} className="mar-rgt-5"/>
                                    Excused
                                </Select.Option>
                            </Select.OptGroup>
                        }
                        {
                            this.state.categories.length > 0 &&
                            <Select.OptGroup label={"Category"}>
                                {
                                    this.state.categories.map((category, index) => (
                                        <Select.Option key={category.category_uuid}
                                                       value={`category_uuid=${category.category_uuid}`}>{category.category_name}</Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        }
                        <Select.OptGroup label={`${this.props.page_type === "lms" ? "Course" : "Template"} Name`}>
                            {
                                formsList.map((form, index) => (
                                    <Select.Option key={form.form_uuid || form.uuid}
                                                   value={"form_uuid=" + (form.form_uuid || form.uuid)}>{form.form_name || form.name}</Select.Option>
                                ))
                            }
                        </Select.OptGroup>
                        <Select.OptGroup label={"Schedule Name"}>
                            {
                                schedulesList.map((schedule, index) => (
                                    <Select.Option key={schedule.scheduler_uuid || schedule.uuid}
                                                   value={"scheduler_uuid=" + (schedule.scheduler_uuid || schedule.uuid)}>{schedule.scheduler_name || schedule.name}</Select.Option>
                                ))
                            }
                        </Select.OptGroup>
                    </Select>
                    <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                        <TimePeriodFilter noWrapperStyle={true} period_query={this.props.period_query}
                                          updateQuery={this.updatePeriodQuery} periodSelected={this.periodSelected}/>
                    </div>
                </div>
                <div className="row" style={{padding: "0 0.5rem"}}>
                    <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                        <LocationFilter
                            style={{width: '100%'}}
                            value={this.state.location}
                            onLocationSelected={this.onLocationSelected}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                        <Select
                            value={this.state.selected_team}
                            onChange={this.handleTeamSelect}
                            virtual={false}
                            style={{width: '100%'}}
                        >
                            {
                                isAdmin(this.props.user) &&
                                <Select.Option value={"organization_teams"}>All Account Teams</Select.Option>
                            }
                            <Select.Option value={"my_teams"}>All My Teams</Select.Option>
                            <Select.Option
                                value={"my_submissions"}>{this.props.activeTab === "assignments" ? "My Assignments" : "My Submissions"}</Select.Option>
                            <Select.Option value={""} disabled>
                                <hr style={{marginTop: "10px", marginBottom: "0px"}}/>
                            </Select.Option>
                            {
                                this.props.teams.map((team, index) => (
                                    <Select.Option key={index} value={team.uuid}>{team.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", []),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        user: safe_get(store, "user.user", {}),
        forms_query: safe_get(store, "forms.query", ""),
        period_query: safe_get(store, "forms.period_query", ""),
        forms: safe_get(store, "forms.forms", []),
        schedules: safe_get(store, "forms.schedules", []),
        organization: safe_get(store, "org_helper.organization", {}),
        activeTab: safe_get(store, "forms.tab", "forms"),
    }
}

export default withRouter(connect(mapStateToProps)(FormsSearchFilter));


