import React, { useMemo } from 'react';
import {Select} from 'antd';

import {actions} from './reducer';
import {safe_get} from 'other/Helper';
import { getPostSelectOptions } from './PostFormCategory';

export default function PostFormTag({state, dispatch, postFieldNames}) {
    const selectOptions = useMemo(() => {
        return getPostSelectOptions(state.tags, "tag_uuid", state.selectedTag);
    }, [state.tags, state.selectedTag]);

    if (state.tags.filter(t => t.enabled).length == 0) return null;

    return (
        <>
            <div className="panel-heading bulletin-post ">
                <h3 className="bulletin-post-headers required">{safe_get(postFieldNames, "tag", "Tag")}</h3>
            </div>
            <div className="panel-body bulletin-post">
                <Select
                    style={{maxWidth: "400px", width: "100%"}}
                    placeholder=""
                    value={state.selectedTag}
                    onChange={newTag => dispatch([actions.TAG_CHANGED, newTag])}
                    className="custom-bulletin"
                    virtual={false}
                    dropdownStyle={{zIndex: "999999999"}}
                    status={state.tagError ? "error" : null}
                >
                    { selectOptions }
                </Select>
                {
                    state.tagError &&
                    <small className="error" style={{display: "block"}}>{state.tagError}</small>
                }
            </div>
        </>
    )
}