import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {get_feed_activity, notifyError} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet';
import InfoIconComponent from '../InfoIconComponent.js';

import Skeleton from 'react-loading-skeleton';


import * as feedHelperActions from '../../store/actions/FeedHelperActions';

class BulletinPostsAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {},
            open_posts_total: 0,
            total_closed_posts: 0,
            loading: true
        };

        this.redirectFeed = this.redirectFeed.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.date_query !== prevProps.date_query) || this.props.feed_query !== prevProps.feed_query) {
            this.getAnalytics(this.props.feed_query, this.props.date_query);
        }
    }

    componentDidMount() {
        this.getAnalytics(this.props.feed_query, this.props.date_query);
    }

    getAnalytics = (feed_query = "", date_query = "") => {
        var self = this;
        var query = "";

        if (date_query === "") {
            date_query = "&period=all_time";
        }

        if (this.props.isMyPostsFeed) {
            var filters = feed_query.split("?team_uuid=" + this.props.team_uuid);
            if (filters.length === 2) {
                filters = filters[1]
            } else {
                filters = "";
            }
            query = "?user_uuid=" + this.props.user.uuid + filters + date_query;
        } else {
            query = feed_query + date_query;
        }

        this.setState({loading: true});

        get_feed_activity(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    open_posts_total: safe_get(success, "open_posts_total", 0),
                    open_posts_30_days: safe_get(success, "open_posts_0_30", 0),
                    open_posts_90_days: safe_get(success, "open_posts_30_90", 0),
                    open_posts_180_days: safe_get(success, "open_posts_90_180", 0),
                    open_posts_180: safe_get(success, "open_posts_180", 0),
                    avg_time_to_close: safe_get(success, "avg_time_to_close", 0),
                    total_closed_posts: safe_get(success, "closed_posts_total", 0),
                    closure_rate: safe_get(success, "closure_rate", 0),
                    total_shared_from_team: safe_get(success, "total_shared_from_team", 0),
                    total_shared_with_team: safe_get(success, "total_shared_with_team", 0),
                    loading: false
                });

            })
        }, function (error) {
            notifyError(error)
            self.setState({loading: false})
        })
    }

    redirectFeed(query) {
        // var final_query = "?team_uuid=" + this.props.team_uuid; 

        var final_query = this.props.feed_query;
        if (!this.props.feed_query.includes(query)) {
            final_query += query;
        }

        if (this.props.isMyPostsFeed && !final_query.includes("my_posts_only=1")) {
            final_query += "&my_posts_only=1";
        } else if (!final_query.includes("hide_shared=1")) {
            final_query += "&hide_shared=1";
        }


        this.props.dispatch(feedHelperActions.update_feed_query(final_query));
        this.props.dispatch(feedHelperActions.update_feed_tab("posts"));
        this.props.history.push("/" + this.props.organization_uuid + "/home/team/" + this.props.team_uuid + "/feed");

        // WAIT FOR DISPATCH
        setTimeout(() => {
            this.props.updateFeed();
        }, 50);
    }

    render() {
        var columnClass = this.props.isMyPostsFeed ? "col-md-5ths col-sm-5ths col-xs-6 col-print-4" : "col-md-6ths col-sm-6ths col-xs-6 col-print-4"

        if (this.state.loading) {
            return (
                <div className="panel" style={{margin: "0px"}}>
                    <div style={{padding: "10px"}}>
                        <div className="row justify-content-start">
                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px 5px 0px 5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"57px"}/>
                                    </p>
                                </div>

                            </div>
                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px 5px 0px 5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"57px"}/>
                                    </p>
                                </div>
                            </div>
                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px 5px 0px 5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"57px"}/>
                                    </p>
                                </div>
                            </div>
                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px 5px 0px 5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"57px"}/>
                                    </p>
                                </div>
                            </div>
                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px 5px 0px 5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"57px"}/>
                                    </p>
                                </div>
                            </div>
                            {
                                !this.props.isMyPostsFeed &&
                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px 5px 0px 5px"}}>
                                        <Skeleton width={"70px"}/>
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>
                                            <Skeleton width={"60px"} height={"57px"}/>
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                //<LoadingIndicator small={true} panelStyle={{margin: "0px"}}/>               
            );
        } else {
            return (
                <div className="panel" style={{margin: "0px"}}>
                    <div style={{padding: "10px"}}>
                        <div className="row justify-content-start">

                            <div className={columnClass} style={{cursor: "pointer"}} onClick={() => {
                                this.redirectFeed("")
                            }}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                    Total Posts
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>{this.state.open_posts_total + this.state.total_closed_posts}</p>
                                </div>
                            </div>

                            <div className={columnClass} style={{cursor: "pointer"}} onClick={() => {
                                if (!this.props.feed_query.includes("status=closed")) {
                                    this.redirectFeed("&status=open")
                                }
                            }}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Open
                                    Posts</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.open_posts_total}</p>
                                </div>
                            </div>

                            <div className={columnClass} style={{cursor: "pointer"}} onClick={() => {
                                if (!this.props.feed_query.includes("status=open")) {
                                    this.redirectFeed("&status=closed")
                                }
                            }}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Closed
                                    Posts</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.total_closed_posts}</p>
                                </div>
                            </div>

                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Closure
                                    Rate</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.closure_rate}<span
                                        style={{fontSize: "30%"}}>%</span></p>
                                </div>
                            </div>


                            <div className={columnClass} style={{padding: "0px"}}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Avg. Time
                                    to Close</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.avg_time_to_close}<span
                                        style={{fontSize: "20%"}}>days</span></p>
                                </div>
                            </div>

                            {
                                !this.props.isMyPostsFeed &&
                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                        Posts Shared
                                        <InfoIconComponent
                                            position={"bottom"}
                                            width={"170px"}
                                            text={
                                                <span>Total posts created in this team and shared with another team.</span>}
                                        />
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>{this.state.total_shared_from_team}</p>
                                    </div>
                                </div>
                            }


                        </div>

                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        organization_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", ""),
        user: safe_get(store, "user.user", {})
    }
}

export default withRouter(connect(mapStateToProps)(BulletinPostsAnalytics));
