import { Component } from 'react';

import NotificationAlert from '../../other/NotificationAlert';
import ModalPopover from '../ModalPopover.js';
import Previewer from '../Previewer';
import ConfirmModal from '../Shared/ConfirmModal.js';

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import {
    beforeFileUpload, fileHasZeroSize, fileUpload, safe_get
} from '../../other/Helper.js';

import AttachmentList from 'components/Shared/AttachmentList.js';

class AttachmentBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attachments: safe_get(this.props, "attachments", []),
            fileList: [],
            finalFileList: [],
            previewVisible: false,
            previewImage: '',
            upload: null,
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleFileSubmit = this.handleFileSubmit.bind(this);
        // this.getAPIReadyAttachments = this.getAPIReadyAttachments.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            attachments: safe_get(this.props, "attachments", [])
        });
    }

    handleCancel = () => this.setState({previewVisible: false})

    handlePreview = (index) => {
        this.setState({preview_visible: true, preview_index: index});
    }

    beforeUpload(file, fileList) {
        if (fileHasZeroSize(file)) {
            return false;
        }

        let [allow, error_msg] = beforeFileUpload(file, fileList, this.state.attachments.length);

        if (allow) {
            return true;
        } else {
            this.setState({uploadError: error_msg});
            // this.props.uploadError(error_msg)
            return false;
        }
    }

    handleFileSubmit(file) {
        var self = this

        var uploading_file = {
            uid: file.file.uid,
            status: "uploading",
            percent: 0
        }

        var fileList = this.state.fileList;
        fileList.push(uploading_file);
        this.setState({fileList: fileList});

        var params = JSON.stringify({
            feature_key: "library_article",
            article_uuid: self.props.article_uuid,
            content_type: file.file.type,
            file_name: file.file.name,
            embedded: false
        })

        fileUpload(this, params, file, function (progress) {
            var fileList = self.state.fileList;
            var index = fileList.indexOf(uploading_file);

            if (index >= 0) {
                var file = fileList[index];
                file["percent"] = progress;
                fileList[index] = file;
                self.setState({fileList: fileList});
            }

        }, function (pre_signed_url, file_key) {
            var fileList = self.state.fileList;
            var index = fileList.indexOf(uploading_file);

            if (index >= 0) {
                fileList.splice(index, 1);
            }

            self.setState({
                showModal: false,
                progress: '0%',
                fileList: fileList,
                uploadError: "",
            });

            self.handleAdd(file.file.name, pre_signed_url, file_key);
            NotificationAlert("", "", "File uploaded.")

        }, function (error) {
            var errorMsg = "An Error occured. Unable to upload file.";
            if (error.toString().includes("aborted")) {
                errorMsg = "Upload canceled.";
            }
            self.setState({showModal: false, uploadError: errorMsg, fileList: []});
        });
    }

    handleRemove = (index) => {
        this.setState({showDeleteConfirmation: true, deleteAttachmentIndex: index});
    }

    confirmDeleteAttachment = () => {
        var attachments = [...this.state.attachments];
        attachments.splice(this.state.deleteAttachmentIndex, 1);
        this.setState({attachments: attachments, showDeleteConfirmation: false, deleteAttachmentIndex: undefined});
        this.props.update_attachments(attachments);
    }

    handleAdd(name, pre_signed_url, file_key) {
        var returned_file = {}
        returned_file["file_path"] = file_key;
        returned_file["file_name"] = name
        returned_file["public_url"] = pre_signed_url + "/" + file_key;
        returned_file["embedded"] = false;

        var attachments = [...this.state.attachments];
        attachments.push(returned_file);

        this.setState({attachments: attachments});
        this.props.update_attachments(attachments);
    }

    cancelModal() {
        var upload = this.state.upload
        upload.abort();
    }

    render() {

        const {fileList} = this.state;
        // var uploadClassName = fileList.length < 5 ? "library-upload" : "library-upload max-limit"

        return (
            <div className="panel thin-border ">
                {
                    this.state.showDeleteConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteConfirmation}
                        cancel={() => {
                            this.setState({showDeleteConfirmation: false})
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this attachment?"}
                        confirmButtonName={"Delete"}
                        confirm={this.confirmDeleteAttachment}
                    />
                }


                <div className="panel-heading">
                    <h3 className="titles">Attachments</h3>
                </div>
                <Upload.Dragger
                    disabled={this.state.attachments.length >= 15}
                    action="/"
                    showUploadList={false}
                    multiple={true}
                    accept={""}
                    beforeUpload={this.beforeUpload}
                    onRemove={this.handleRemove}
                    customRequest={this.handleFileSubmit}
                    className={"library-upload mar-top"}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Upload.Dragger>
                <div className="" style={{paddingLeft: "15px", paddingRight: "15px"}}>
                    <ModalPopover
                        show={this.state.showModal}
                        cancel={this.cancelModal}
                        confirm={this.confirmModal}
                        title="Upload Progress"
                        text={""}
                        progressBar={true}
                        progress={this.state.progress}/>

                    <AttachmentList
                        attachments={this.state.attachments}
                        uploadingFileList={fileList}
                        className="mar-top-10"
                        onPreview={index => this.handlePreview(index)}
                        onDelete={index => this.handleRemove(index)}
                    />

                    {
                        this.state.uploadError &&
                        <small className="error" style={{marginTop: "5px"}}>{this.state.uploadError}</small>
                    }

                    {
                        this.state.preview_visible &&
                        <Previewer
                            show={this.state.preview_visible}
                            close={() => {
                                this.setState({preview_visible: false})
                            }}
                            attachments={this.state.attachments}
                            index={this.state.preview_index}
                        />
                    }

                </div>

                <div className="panel-footer team"></div>

            </div>
        );
    }

}

export default AttachmentBox;