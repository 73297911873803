import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import * as teamsActions from '../../store/actions/TeamsActions'
import * as feedHelperActions from '../../store/actions/FeedHelperActions'

import {create_team, update_team} from '../../api/zero-api.js'
import {fieldIsNotEmpty, dateFormatterWithTime, safe_get} from '../../other/Helper';

import {Modal, Checkbox} from 'antd';

import NotificationAlert from '../../other/NotificationAlert';

import membersImg from '../../assets/css/img/members.png'
import ButtonLoading from '../Shared/ButtonLoading';

import InfoIconComponent from '../InfoIconComponent';

import '../../assets/css/antd-custom.css';

class TeamModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            team: {},
            notify_all_when_posted: false,
            created_at: undefined
        };

        this.createTeam = this.createTeam.bind(this);
        this.editTeam = this.editTeam.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.type === "edit") {
            var team = this.props.team;
            this.setState({
                team: team,
                teams: safe_get(this.props, "teams", []),
                team_name: safe_get(team, "name", ""),
                team_description: safe_get(team, "description", ""),
                notify_all_when_posted: safe_get(team, "notify_all_when_posted", false),
                allow_admin_team_lead: safe_get(team, "minimum_role_to_post", "") === "team_lead",
                post_reactions_enabled: safe_get(team, "post_reactions_enabled", true),
                created_at: team.created_at
            });
        } else if (this.props.type === "new") {
            this.setState({
                notify_all_when_posted: true,
                post_reactions_enabled: true,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    createTeam() {
        var self = this;

        var team_name = safe_get(this.state, "team_name", "");
        var team_description = safe_get(this.state, "team_description", "");

        if (!fieldIsNotEmpty(team_name) && this._isMounted) {
            this.setState({nameError: "Please add a name for your team."})
        } else {
            this.setState({saving: true});

            var body = JSON.stringify({
                name: team_name,
                description: team_description,
                invite_link: {
                    enabled: true,
                    allowed_domains: ""
                },
                notify_all_when_posted: this.state.notify_all_when_posted,
                minimum_role_to_post: this.state.allow_admin_team_lead ? "team_lead" : "user_restricted"
            });

            create_team(body).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        let team = safe_get(success, "team", {})
                        self.updateTeamsList(team);
                        self.setState({saving: false});
                        NotificationAlert("success", "", "New team created.");
                    }
                });

            }, function (error) {
                if (self._isMounted) {
                    var newState = {};
                    self.setState({saving: false});
                    if (error.status === 422) {
                        error.json().then(error => {
                            let error_messages = safe_get(error, "error_messages", []);

                            for (var i in error_messages) {
                                var errorMsg = safe_get(error_messages[i], "message", "");
                                var errorField = safe_get(error_messages[i], "field", "");

                                if (errorField === "description") {
                                    newState["descError"] = "Please add a brief description.";
                                } else if (errorField === "name") {
                                    newState["nameError"] = errorMsg;
                                }
                                self.setState(newState);
                            }
                        });
                    }
                }
            });
        }

    }

    editTeam() {
        var self = this;
        var team_name = safe_get(this.state, "team_name", "");
        var team_description = safe_get(this.state, "team_description", "");

        if (fieldIsNotEmpty(team_name) && this._isMounted) {
            this.setState({saving: true});
            var body = JSON.stringify({
                name: team_name,
                description: team_description,
                invite_link: {
                    enabled: safe_get(this.state, "team.invite_link.enabled", false),
                    allowed_domains: safe_get(this.state, "team.invite_link.allowed_domains", "")
                },
                notify_all_when_posted: this.state.notify_all_when_posted,
                minimum_role_to_post: this.state.allow_admin_team_lead ? "team_lead" : "user_restricted",
                post_reactions_enabled: this.state.post_reactions_enabled
            });

            update_team(this.state.team.uuid, body).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var team = safe_get(success, "team", {});
                        self.updateTeamsList(team);
                        self.setState({saving: false});
                        NotificationAlert("success", "", "Team settings updated.");
                    }
                });
            }, function (error) {
                var newState = {};

                if (error.status === 422) {
                    self.setState({saving: false});
                    error.json().then(error => {
                        let error_messages = safe_get(error, "error_messages", []);

                        for (var i in error_messages) {
                            var errorMsg = safe_get(error_messages[i], "message", "");
                            var errorField = safe_get(error_messages[i], "field", "");

                            if (errorField === "description") {
                                newState["descError"] = "Please add a brief description.";
                            } else if (errorField === "name") {
                                newState["nameError"] = errorMsg;
                            }
                            self.setState(newState);
                        }
                    });
                }
            });
        } else {
            this.setState({nameError: "Please add a name for your team."});
        }

    }

    updateTeamsList(team) {
        var teams = [...this.props.teams];

        if (this.props.type === "new") {
            teams.push(team);
        } else {
            for (var i in teams) {
                var oldTeam = teams[i]
                if (team.uuid === oldTeam.uuid) {
                    teams[i] = team;
                    break;
                }
            }
        }

        teams.sort(function (a, b) {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        });

        this.props.dispatch(teamsActions.update_teams(teams));
        // this.props.dispatch(teamsActions.update_current_team(team));   
        // this.props.dispatch(feedHelperActions.update_feed_query("?team_uuid="+team.uuid));

        if (this.props.updateTeams) {
            this.props.updateTeams();
        }

        setTimeout(() => {
            this.props.cancel();
        }, 200);
    }

    inputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    render() {
        let teamNameLength = safe_get(this.state, "team_name", "").length;
        let teamDescriptionLength = safe_get(this.state, "team_description", "").length;

        return (
            (<Modal
                title={this.props.type === "new" ? "Create team" : "Edit team"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal"
                                onClick={this.props.type === "new" ? this.createTeam : this.editTeam}>
                            {this.state.saving ?
                                <ButtonLoading/> : this.props.type === "new" ? "Create team" : "Update team"}
                        </button>
                    </div>
                }
            >
                {
                    this.props.type === "new" &&
                    <p>Teams are typically organized by location, department, or job.</p>
                }
                <div className="">
                    <h3 className="titles"
                        style={{marginBottom: '2px', marginLeft: '0px', fontSize: '110%', marginTop: "5px"}}>Team
                        Name</h3>
                    <input
                        type={"text"}
                        name="team_name"
                        placeholder={"NYC Team"}
                        className={"form-control custom-placeholder"}
                        value={safe_get(this.state, "team_name", "")}
                        onChange={this.inputChange}
                        maxLength={30}
                    />
                    {
                        !this.state.nameError &&
                        <p className="text-sm" style={{
                            color: 'var(--zero-dark-grey)',
                            paddingTop: '3px',
                            float: 'left',
                            display: 'inline-block'
                        }}>Team names must be less than 30 characters</p>
                    }
                    {
                        this.state.nameError &&
                        <p className="text-sm error"
                           style={{paddingTop: '3px', display: 'inline-block'}}>{this.state.nameError}</p>
                    }
                    {
                        teamNameLength <= 30 &&
                        <p className="text-sm" style={{
                            color: 'var(--zero-dark-grey)',
                            paddingTop: '3px',
                            float: 'right',
                            display: 'inline-block'
                        }}>{30 - teamNameLength}</p>
                    }

                </div>
                {
                    this.props.type === "new" &&
                    <img src={membersImg} alt="members-img" style={{margin: "0 auto", display: "block", width: "70%"}}/>
                }
                <div style={{marginTop: "30px"}}>
                    <h3 className="titles" style={{
                        marginBottom: '2px',
                        marginLeft: '0px',
                        fontSize: '110%',
                        marginTop: "5px"
                    }}>Settings</h3>
                    <div>
                        <Checkbox
                            className="zero-dark-grey"
                            checked={this.state.notify_all_when_posted}
                            onChange={(e) => {
                                this.setState({notify_all_when_posted: !this.state.notify_all_when_posted});
                            }}
                        >
                            Subscribe all team members to new posts.
                            <InfoIconComponent
                                text={<span>To learn more, click <a rel="noopener noreferrer"
                                                                    onClick={() => FreshworksWidget('open', 'article', {'id': 70000407424})}
                                                                    className="blue-link">here</a>.</span>}
                            />
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox
                            className="zero-dark-grey"
                            checked={this.state.post_reactions_enabled}
                            onChange={(e) => {
                                this.setState({post_reactions_enabled: !this.state.post_reactions_enabled});
                            }}
                        >
                            Allow reactions on posts and comments
                            <InfoIconComponent
                                text={<span>To learn more, click <a rel="noopener noreferrer"
                                                                    onClick={() => FreshworksWidget('open', 'article', {'id': 70000407103})}
                                                                    className="blue-link">here</a>.</span>}
                            />
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox
                            className="zero-dark-grey"
                            checked={this.state.allow_admin_team_lead}
                            onChange={(e) => {
                                this.setState({allow_admin_team_lead: !this.state.allow_admin_team_lead});
                            }}
                        >
                            Restrict posting to Admins and Team Leads
                            <InfoIconComponent
                                text={<span>To learn more, click <a rel="noopener noreferrer"
                                                                    onClick={() => FreshworksWidget('open', 'article', {'id': 70000407328})}
                                                                    className="blue-link">here</a>.</span>}
                            />
                        </Checkbox>
                    </div>
                    {
                        this.props.type === "edit" &&
                        <p className="zero-dark-grey"
                           style={{marginTop: "15px", marginBottom: "0px"}}>Team created
                            on {dateFormatterWithTime(this.state.created_at)}.</p>
                    }
                </div>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(TeamModal));