import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux'

import {makeArticle, updateArticle, get_list_teams, notifyError} from '../../api/zero-api.js'

import TextEditor from './TextEditor.js'
import AttachmentBox from './AttachmentBox.js'
import InfoIconComponent from '../InfoIconComponent.js';
import ButtonLoading from '../Shared/ButtonLoading.js';

import {
    safe_get,
    getErrorMessage,
    fieldIsNotEmpty,
    mainContentContainerClass,
    showFooterMobileApp,
    hideFooterMobileApp,
    isAdmin,
    isTeamLead
} from '../../other/Helper.js';
import NotificationAlert from '../../other/NotificationAlert'
import LoadingIndicator from '../Shared/LoadingIndicator';

import '../../assets/css/Library.css';

import {Select} from 'antd';

const Option = Select.Option;

class NewPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [],
            tags: [],
            selectedTags: [],
            article_uuid: "",
            articleTitle: "",
            team: {},
            attachments: [],
            embeddedAttachments: [],
            team_uuids: [],
            user_teams: [],
            loading_post: true,
            loading_teams: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addEmbeddedAttachment = this.addEmbeddedAttachment.bind(this);
        this.removeEmbeddedAttachment = this.removeEmbeddedAttachment.bind(this);
        this.handleTagSelection = this.handleTagSelection.bind(this);
        this.handleTagDeselect = this.handleTagDeselect.bind(this);
        this.update_attachments = this.update_attachments.bind(this);
        this.discard = this.discard.bind(this);
    }

    componentWillUnmount() {
        showFooterMobileApp();
    }

    componentDidMount() {
        var self = this;

        window.scrollTo(0, 0);
        hideFooterMobileApp();

        if (isAdmin(this.props.user)) {
            this.getTeams();
        } else {
            this.setState({user_teams: this.props.teams, loading_teams: false});
        }

        makeArticle().then(function (success) {
            success.json().then(success => {
                let tags = self.props.topic ? [self.props.topic] : []
                self.setState({
                    article_uuid: safe_get(success, "article_uuid", ""),
                    articleTitle: safe_get(success, "article.title", ""),
                    tags: tags,
                    loading_post: false
                });
            });
        }, function (error) {
            notifyError(error);
        });
    }

    getTeams() {
        var self = this;
        get_list_teams().then(function (success) {
            success.json().then(success => {

                var teams = safe_get(success, "teams", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                });

                self.setState({
                    user_teams: teams,
                    loading_teams: false,
                });
            })
        }, function (error) {
            notifyError(error);
        });
    }

    onTitleChange = (e) => {
        this.setState({articleTitle: e.target.value});
    }

    addEmbeddedAttachment(file) {
        var embeddedAttachments = this.state.embeddedAttachments
        embeddedAttachments.push(file)
        this.setState({embeddedAttachments: embeddedAttachments})
    }

    removeEmbeddedAttachment(src) {
        var attachments = this.state.embeddedAttachments
        for (var i in attachments) {
            var file = attachments[i]
            if (file.public_url === src) {
                attachments.splice(i, 1);
                this.setState({embeddedAttachments: attachments})
                break;
            }
        }
    }

    update_attachments(attachments) {
        this.setState({attachments: attachments})
    }

    handleTagInput = (e) => {
        let value = e.target.value;
        if (value.length > 29) {
            e.preventDefault();
            return;
        }
    }

    handleTagSelection(value) {
        this.setState({tags: value});
    }

    handleTagDeselect(value) {
        let tags = [...this.state.tags];
        var index = tags.indexOf(value);
        tags.splice(index, 1);
        this.setState({tags: tags});
    }

    handleTeamSelect = (value) => {
        this.setState({team_uuids: value});
    }

    selectAllTeams = () => {
        let team_uuids = [...this.state.user_teams].map(team => team.uuid);
        this.setState({team_uuids: team_uuids});
    }

    selectNoTeams = () => {
        this.setState({team_uuids: []});
    }

    handleSubmit() {
        var self = this;

        var article_title = this.state.articleTitle;

        this.setState({saving: true});

        var tags = this.state.tags;
        var attachments = this.state.attachments;
        var content = this.refs.TextEditor.state.content;

        var body = JSON.stringify({
            title: article_title,
            body: content,
            tags: tags,
            attachments: attachments,
            team_uuids: this.state.team_uuids,
            all_teams: false
        });

        updateArticle(this.state.article_uuid, body).then(function (success) {
            NotificationAlert("success", "", "Article created.");
            self.props.history.push("/" + self.props.organization.organization_uuid + "/home/team/" + self.props.current_team.uuid + "/private_library/")
        }, function (error) {
            self.setState({saving: false});
            if (error.status === 422) {
                error.json().then(error => {
                    let field = error.error_messages[0].field;
                    let errorMessage = getErrorMessage(error, "An error occured.");
                    if (field === "title") {
                        self.setState({titleError: errorMessage})
                    } else if (field === "tags") {
                        self.setState({tagsError: errorMessage});
                    } else if (field === "team_uuids") {
                        self.setState({teamsError: errorMessage});
                    }
                });
            } else {
                NotificationAlert('error', 'Oops!', "An unexpected error occured.");
            }
        });

    }

    discard() {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/my_teams/private_library");
    }

    render() {
        let loading = this.state.loading_post || this.state.loading_teams

        return (
            <div className={mainContentContainerClass()}>

                <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>
                    <div className="row">
                        <div className="col-lg-12 col-lg-offset-0 col-xs-12 col-xs-offset-0">
                            {
                                loading &&
                                <LoadingIndicator style={{margin: '0 auto'}}/>
                            }
                            {
                                <div className="" style={{
                                    maxWidth: '850px',
                                    margin: '0 auto',
                                    visibility: (loading ? "hidden" : "visible")
                                }}>
                                    <div className="panel thin-border">
                                        <div className="panel-body pad-15">
                                            <h3 className="titles" style={{
                                                marginTop: "0px",
                                                marginLeft: "0px",
                                                marginBottom: "8px"
                                            }}>Title</h3>
                                            <div className="form-group" style={{marginBottom: "0px"}}>
                                                <input
                                                    type="text"
                                                    placeholder="Add a title..."
                                                    value={this.state.articleTitle}
                                                    className="form-control input-lg custom-placeholder thin-border"
                                                    maxLength="100"
                                                    onChange={this.onTitleChange}
                                                />
                                                <small
                                                    className="pull-right">{100 - this.state.articleTitle.length}</small>
                                                {
                                                    this.state.titleError &&
                                                    <small className="error"
                                                           style={{marginLeft: '5px'}}>{this.state.titleError}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel thin-border">

                                        <div className="panel-body pad-15">
                                            <h3 className="titles" style={{
                                                marginTop: "0px",
                                                marginLeft: "0px",
                                                marginBottom: "8px"
                                            }}>Teams</h3>
                                            {/* <InfoIconComponent
                                                position={"right"}
                                                width={"160px"}
                                                text={"You can add up to two topics. To create a new topic, type it below and hit enter."}
                                            /> */}
                                            <Select
                                                mode="multiple"
                                                style={{width: '100%'}}
                                                placeholder="Select team(s)..."
                                                onChange={this.handleTeamSelect}
                                                value={this.state.team_uuids}
                                                className="library-tags"
                                                virtual={false}
                                            >
                                                {
                                                    (isAdmin(this.props.user) || isTeamLead(this.props.user)) &&
                                                    <Option disabled>
                                                        <span className="blue-link" onClick={this.selectAllTeams}>Select All</span> &bull;
                                                        <span className="blue-link" onClick={this.selectNoTeams}>Select None</span>
                                                    </Option>
                                                }
                                                {
                                                    this.state.user_teams.map((team, index) => (
                                                        <Option key={team.uuid} value={team.uuid}>{team.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                            {
                                                this.state.teamsError &&
                                                <small className="error">{this.state.teamsError}</small>
                                            }
                                        </div>
                                    </div>

                                    <div className="panel thin-border">

                                        <div className="panel-body pad-15">
                                            <h3 className="titles" style={{
                                                marginTop: "0px",
                                                marginLeft: "0px",
                                                marginBottom: "8px"
                                            }}>Topics</h3>
                                            <InfoIconComponent
                                                position={"right"}
                                                width={"160px"}
                                                text={"You can select up to two topics. If you'd like to create a new topic, add it below and hit enter."}
                                            />
                                            <Select
                                                mode="tags"
                                                style={{width: '100%'}}
                                                value={this.state.tags}
                                                placeholder="Select topics"
                                                onChange={this.handleTagSelection}
                                                onDeselect={this.handleTagDeselect}
                                                onInputKeyDown={this.handleTagInput}
                                                className="library-tags"
                                                maxTagTextLength={30}
                                            >
                                                {
                                                    this.props.library_tags.map((tag, index) => (
                                                        <Option key={index} value={tag}>{tag}</Option>
                                                    ))
                                                }
                                            </Select>
                                            {
                                                this.state.tagsError &&
                                                <p className="zero-delete-red">{this.state.tagsError}</p>
                                            }
                                        </div>
                                    </div>

                                    <div className="panel thin-border ">
                                        <div className="panel-body" style={{padding: "15px"}}>
                                            <TextEditor
                                                ref="TextEditor"
                                                user_uuid={this.props.user.uuid}
                                                article_uuid={this.state.article_uuid}
                                                addEmbeddedAttachment={this.addEmbeddedAttachment}
                                                removeEmbeddedAttachment={this.removeEmbeddedAttachment}/>
                                        </div>

                                    </div>

                                    {
                                        !loading &&
                                        <AttachmentBox
                                            ref="AttachmentBox"
                                            user_uuid={this.props.user.uuid}
                                            article_uuid={this.state.article_uuid}
                                            update_attachments={this.update_attachments}
                                        />
                                    }


                                    <div className="panel clear">
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <button className="btn btn-primary btn-block discard-article"
                                                        onClick={this.discard}>Discard
                                                </button>
                                            </div>
                                            <div className="col-xs-6">
                                                <button className="btn btn-primary btn-block"
                                                        onClick={this.handleSubmit}>
                                                    {
                                                        this.state.saving ? <ButtonLoading/> : "Create article"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        topic: safe_get(store, "topic.topic", ""),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        library_tags: safe_get(store, "library.topics", []),
    }
}

export default withRouter(connect(mapStateToProps)(NewPost));

