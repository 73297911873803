import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom';

import {
    safe_get,
    dateFormatterNoTime,
    dateFormatterWithTime,
    scrollToTop,
    isAdmin,
    isTeamLead
} from '../../other/Helper.js';

import {
    get_all_form_assignments,
    get_assignment_analytics,
    delete_assignment,
    patchAssignment,
    get_schedule
} from '../../api/zero-api.js';

import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import {IoIosMore as MoreIcon} from 'react-icons/io';
import {EditOutlined, DeleteOutlined, CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';

import {Menu, Dropdown, Popover, Pagination} from 'antd'
// 
import ConfirmModal from '../Shared/ConfirmModal';
import ReAssignModal from '../AdminPages/ManageSchedules/ReAssignModal.js';
import NotificationAlert from 'other/NotificationAlert';


import '../../assets/css/form-builder.css'

class FormAssignmentsTab extends Component {
    constructor(props) {
        super(props);

        /** @type {CachedApiLoader} */
        this.cachedApiLoader = this.props.cachedApiLoader;
        
        this.state = {
            loading_analytics: true,
            loading_assignments: true,
            assignments: [],
            page: 1,
            max_results: 0,
            total_results: 0,
        };

    }

    componentDidUpdate(prevProps) {
        if (this.props.period_query !== prevProps.period_query
         || this.props.forms_query !== prevProps.forms_query
         || this.props.refreshSignal !== prevProps.refreshSignal) {
            // only bypass cache if refresh button was clicked
            const bypassCache = this.props.refreshSignal !== prevProps.refreshSignal;
            this.setState({loading_analytics: true, loading_assignments: true, page: 1}, () => {
                this.getAssignments(bypassCache);
                this.getAnalytics(bypassCache);
            });
        }
    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getAssignments();
        this.getAnalytics();
    }

    getAssignments = (bypassCache = false) => {
        let self = this;

        let query = this.props.forms_query + this.props.period_query + `&form_types=${this.props.page_type}&full_data=false&page=${this.state.page}`;
        this.cachedApiLoader.fetch(get_all_form_assignments, query, {bypassCache}).then(success => {
            self.setState({
                assignments: safe_get(success, "assignments", []),
                total_results: safe_get(success, "total", 0),
                max_results: safe_get(success, "max_results", 0),
                loading_assignments: false
            });
        }, (error) => {
            self.setState({
                loading_assignments: false
            });
        });
    }

    updateAssignment = (assignmentId, data) => {
        let assignments = this.state.assignments;
        const index = assignments.findIndex(a => a.assignment_uuid === assignmentId)
        if (index > -1) {
            assignments = assignments.slice();
            assignments[index] = data.assignment;
            this.setState({assignments});
        }
    }

    getAnalytics = (bypassCache = false) => {
        let self = this;

        let query = this.props.forms_query + this.props.period_query + `&form_types=${this.props.page_type}`;

        this.cachedApiLoader.fetch(get_assignment_analytics, query, {bypassCache}).then(success => {
            self.setState({
                analytics: safe_get(success, "analytics", {}),
                loading_analytics: false,
            });
        }, function (error) {
            console.log(error);
            self.setState({loading_analytics: false});
        });
    }

    onChangePage = (page) => {
        this.setState({
            page: page,
            loading_assignments: true,
        }, () => {
            this.getAssignments();
        });
    }

    renderTeams = (assignment) => {
        var teams = safe_get(assignment, "teams", []);
        var team_names = teams.map(team => team.name);
        team_names.sort();

        return (
            <Popover content={team_names.join(", ")} placement="topLeft" overlayStyle={{maxWidth: "220px"}}>
        <span className="zero-dark-grey">
          {[...team_names].splice(0, 1).join(", ")} {team_names.length - 1 > 0 && `and ${team_names.length - 1} more...`}
        </span>
            </Popover>
        )

    }

    renderStatus = (assignment) => {
        return (
            <AssignmentInfoPopover
                org_uuid={this.props.org_uuid}
                assignment={assignment}
                user={this.props.user}
                handleExcuseClick={this.handleExcuseClick}
                handleDeleteClick={this.handleDeleteClick}
                handleReassignClick={this.handleReassignClick}
            />
        )
    }

    handleExcuseClick = (assignmentId, excused) => {
        let self = this;

        let assignments = this.state.assignments;
        const index = assignments.findIndex(a => a.assignment_uuid === assignmentId)

        assignments = assignments.slice();
        assignments[index].loading = true;
        this.setState({assignments, loading_analytics: true});

        const body = JSON.stringify({
            excused
        })
        patchAssignment(assignmentId, body).then(success => {
            success.json().then(data => {
                self.updateAssignment(assignmentId, data);
                self.getAnalytics();
            })
        }, error => {
            NotificationAlert("error", "", "Could not excuse assignment.");
            self.setState({loading_analytics: false, loading_assignments: false});
        })
    }

    handleDeleteClick = (assignmentId) => {
        this.setState({showDeleteAssignmentConfirmationModal: true, assignmentIdToDelete: assignmentId});
    }

    handleReassignClick = (assignmentId) => {
        const assignment = this.state.assignments.find(assignment => assignment.assignment_uuid === assignmentId)

        if (assignment) {
            const {scheduler_uuid} = assignment;

            get_schedule(scheduler_uuid)
                .catch(err => {
                    console.error(err)
                })
                .then(response => response.json())
                .then(({scheduler}) => {
                    this.setState({
                        showReassignModal: true,
                        assignmentToReassign: assignment,
                        schedulerToReassign: scheduler,
                    })
                })
        }
    }

    deleteAssignment = () => {
        let self = this
        delete_assignment(this.state.assignmentIdToDelete).then(success => {
            self.getAssignments();
            self.getAnalytics();
            self.setState({showDeleteAssignmentConfirmationModal: false});
            NotificationAlert("error", "", "Assignment deleted.");
        }, _error => {
            NotificationAlert("error", "", "Could not delete assignment.");
            self.setState({showDeleteAssignmentConfirmationModal: false});
        });
    }

    rowOptionsFormatter(assignment) {
        const menu = {
            onClick: e => this.handleTableMenuClick(e, assignment),
            items: [
                {
                    key: 'edit_submission',
                    icon: <EditOutlined/>,
                    label: 'Edit'
                },
                {
                    key: 'delete_submission',
                    icon: <DeleteOutlined/>,
                    label: 'Delete'
                }
            ]
        }

        return (
            <div className="more pull-right" style={{display: "inline-block", color: "grey", lineHeight: 0}}>
                <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
                    <button className="ButtonLink ant-dropdown-link">
                        <MoreIcon style={{height: '20px', width: '20px'}}/>
                    </button>
                </Dropdown>
            </div>
        )
    }

    render() {
        let columnClass = "col-sm-3 col-xs-6"

        return (
            <div>
                {
                    this.state.showDeleteAssignmentConfirmationModal &&
                    <ConfirmModal
                        show={this.state.showDeleteAssignmentConfirmationModal}
                        cancel={() => {
                            this.setState({showDeleteAssignmentConfirmationModal: false});
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this assignment?"}
                        confirmButtonName={"Delete assignment"}
                        confirm={this.deleteAssignment}
                    />
                }
                {
                    this.state.showReassignModal &&
                    <ReAssignModal
                        show={this.state.showReassignModal}
                        cancel={() => {
                            this.setState({
                                showReassignModal: false,
                                assignmentToReassign: null,
                                schedulerToReassign: null
                            })
                        }}
                        assignmentId={this.state.assignmentToReassign.assignment_uuid}
                        reAssignUser={this.state.assignmentToReassign.assigned_to}
                        responders={this.state.schedulerToReassign.subscribers}
                        schedule={this.state.schedulerToReassign}
                        refresh={() => {
                            this.getAssignments();
                            this.getAnalytics();
                        }}
                    />
                }
                <div className="panel" style={{margin: "0px", marginBottom: "10px"}}>
                    <div className="panel" style={{padding: "10px", margin: "0px"}}>
                        <div className="row justify-content-start">

                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                    Total Assignments
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>
                                        {this.state.loading_analytics ? <Skeleton width={"60px"}
                                                                                  height={"57px"}/> : safe_get(this.state, "analytics.total", 0)}
                                    </p>
                                </div>
                            </div>

                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Completed
                                    Assignments</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        {this.state.loading_analytics ? <Skeleton width={"60px"}
                                                                                  height={"57px"}/> : safe_get(this.state, "analytics.completed", 0)}
                                    </p>
                                </div>
                            </div>

                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Open
                                    Assignments</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        {this.state.loading_analytics ? <Skeleton width={"60px"}
                                                                                  height={"57px"}/> : safe_get(this.state, "analytics.open", 0)}
                                    </p>
                                </div>
                            </div>

                            <div className={columnClass}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Missed
                                    Assignments</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>
                                        {this.state.loading_analytics ? <Skeleton width={"60px"}
                                                                                  height={"57px"}/> : safe_get(this.state, "analytics.missed", 0)}
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="panel mar-btm-0">
                    <div className="">
                        <div className="table-responsive" style={{border: "none"}}>
                            <table ref={this.props.tableRef} className="table table-vcenter" style={{marginBottom: "0px"}}>
                                <thead className="no-thead-borders" style={{color: "#1D2A35"}}>
                                <tr style={{display: 'none'}}>
                                    <td colSpan={5}>ZERO Assignments Export</td>
                                </tr>
                                <tr style={{display: 'none'}}>
                                    <td colSpan={5}>Max of 25 assignments exported</td>
                                </tr>
                                <tr style={{display: 'none'}}>
                                    <td colSpan={5}></td>
                                </tr>
                                <tr>
                                    <th className="zero-blue" style={{width: "180px"}}>Name</th>
                                    <th className="zero-blue">{this.props.page_type === "regular" ? "Template" : "Course"}</th>
                                    <th className="zero-blue">Schedule</th>
                                    <th className="zero-blue">Due Date</th>
                                    <th className="zero-blue">Status</th>
                                </tr>
                                </thead>
                                {
                                    this.state.loading_assignments &&
                                    <tbody>
                                    {
                                        [...Array(10)].map((_, index) => (
                                            <tr key={index} className="tr-hover">
                                                <td><Skeleton width={100} style={{width: "180px"}}/></td>
                                                <td><Skeleton width={100}/></td>
                                                <td><Skeleton width={100}/></td>
                                                <td><Skeleton width={80} style={{width: "85px"}}/></td>
                                                <td><Skeleton width={80} style={{width: "110px"}}/></td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.state.loading_assignments && this.state.assignments.length !== 0 &&
                                    <tbody>
                                    {
                                        this.state.assignments.map((assignment, index) => (
                                            <tr key={index} className="tr-hover">
                                                <td className="zero-dark-grey" style={{width: "180px"}}>
                                                    {assignment.assigned_to.first_name} {assignment.assigned_to.last_name}
                                                </td>
                                                <td className="zero-dark-grey">
                                                    {assignment.form_name}
                                                </td>
                                                <td className="zero-dark-grey">
                                                    {assignment.scheduler_name}
                                                </td>
                                                <td className="zero-dark-grey" style={{width: "110px"}}>
                                                    {dateFormatterNoTime(assignment.due_date_timestamp)}
                                                </td>
                                                <td className="zero-dark-grey" style={{width: "110px"}}>
                                                    {this.renderStatus(assignment)}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.state.loading_assignments && this.state.assignments.length === 0 &&
                                    <tbody>
                                    <tr>
                                        <td className="react-bs-table-no-data zero-dark-grey" colSpan="5">
                                            No assignments.
                                        </td>
                                    </tr>
                                    </tbody>
                                }

                            </table>
                            <div style={{float: "left", padding: "5px 0px 5px 0px"}}>
                                <Pagination
                                    size={"small"}
                                    showSizeChanger={false}
                                    hideOnSinglePage={true}
                                    pageSize={this.state.max_results}
                                    total={this.state.total_results}
                                    current={this.state.page}
                                    onChange={this.onChangePage}
                                />
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        );

    }

}


class AssignmentInfoPopover extends Component {
    popoverContent() {
        const {assignment, user} = this.props;

        const hrStyle = {
            marginTop: 10,
            marginBottom: 10
        };

        const bold = {
            fontWeight: 600
        };

        let due_date_timestamp = safe_get(assignment, 'due_date_timestamp', null)
        if (!due_date_timestamp) {
            let dt = new Date();
            due_date_timestamp = dt.setUTCHours(23, 59, 0, 0) / 1000;
        }

        let assignment_due_date = `${moment.unix(due_date_timestamp).format("MMM D, YYYY")} at ${moment.unix(due_date_timestamp).format("hh:mm A")}`;

        let isOverDue = moment.unix(due_date_timestamp).isBefore(moment());

        let team_uuid = 'my_teams';

        if (assignment.teams) {
            team_uuid = assignment.teams[0].uuid;
        }

        let form_type = assignment.form_type === 3 ? 'courses' : 'forms';

        const isAdminOrTeamLead = isAdmin(user) || isTeamLead(user);

        const canExcuse = isAdminOrTeamLead && !assignment.completed && !assignment.excused;
        const canRequire = isAdminOrTeamLead && assignment.excused;
        const canReassign = isAdminOrTeamLead && !assignment.completed && !assignment.excused && !isOverDue;
        const canDelete = isAdminOrTeamLead && !assignment.completed && !assignment.excused && !isOverDue;

        return (
            <div style={{padding: 8}}>
                <span className="zero-blue"
                      style={bold}>Assigned on: </span>{dateFormatterWithTime(assignment.assigned_at)}
                <hr style={hrStyle}/>
                <span className="zero-blue" style={bold}>Due on: </span>{assignment_due_date}
                {
                    !assignment.excused && (
                        <>
                            <hr style={hrStyle}/>
                            <span className="zero-blue"
                                  style={bold}>Completed on: </span>{assignment.completed ? dateFormatterWithTime(assignment.completed_at) : 'N/A'}
                            <hr style={hrStyle}/>
                            <span className="zero-blue"
                                  style={bold}>Submitted by: </span>{assignment.completed ? assignment.assigned_to.full_name : 'N/A'}
                            {
                                assignment.completed && (
                                    <>
                                        <hr style={hrStyle}/>
                                        <Link
                                            to={`/${this.props.org_uuid}/home/team/${team_uuid}/${form_type}/submission_view/${assignment.submission_uuid}`}
                                            className="btn-link">
                                            View Submission
                                        </Link>
                                    </>
                                )
                            }
                            {
                                canExcuse && (
                                    <>
                                        <hr style={hrStyle}/>
                                        <button className="btn-link"
                                                style={{padding: 0}}
                                                onClick={() => this.props.handleExcuseClick(assignment.assignment_uuid, true)}>
                                            Excuse this assignment
                                        </button>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    canRequire && (
                        <>
                            <hr style={hrStyle}/>
                            <span className="zero-blue"
                                  style={bold}>Excused on: </span>{assignment.excused_at ? dateFormatterWithTime(assignment.excused_at) : 'N/A'}
                            <hr style={hrStyle}/>
                            <span className="zero-blue"
                                  style={bold}>Excused by: </span>{assignment.excused_by ? assignment.excused_by.full_name : 'N/A'}
                            <hr style={hrStyle}/>
                            <button className="btn-link"
                                    style={{padding: 0}}
                                    onClick={() => this.props.handleExcuseClick(assignment.assignment_uuid, false)}>
                                Require this assignment
                            </button>
                        </>
                    )
                }
                {
                    canReassign && (
                        <>
                            <hr style={hrStyle}/>
                            <button className="btn-link"
                                    style={{padding: 0}}
                                    onClick={() => this.props.handleReassignClick(assignment.assignment_uuid, true)}
                            >
                                Reassign this assignment
                            </button>
                        </>
                    )
                }
                {
                    canDelete &&
                    <>
                        <hr style={hrStyle}/>
                        <button className="btn-link zero-delete-red"
                                style={{padding: 0}}
                                onClick={() => this.props.handleDeleteClick(assignment.assignment_uuid, true)}>
                            Delete this assignment
                        </button>
                    </>
                }

            </div>
        )
    }

    renderStatus() {
        let grey = "#B5B5B5";
        let red = "#f56946";
        let green = "#52c41a";
        let yellow = "#f3b44b"

        let assignment = this.props.assignment
        let isOverDue = moment.unix(assignment.due_date_timestamp).isBefore(moment());
        
        if (assignment.shared_submission_uuid) {
            return <span><CheckCircleTwoTone twoToneColor={grey} /> Completed</span>
        } else if (assignment.completed) {
            return <span><CheckCircleTwoTone twoToneColor={green}/> Completed</span>
        } else if (assignment.excused) {
            return <span><CloseCircleTwoTone twoToneColor={yellow}/> Excused</span>
        } else if (isOverDue) {
            return <span><CloseCircleTwoTone twoToneColor={red}/> Missed</span>
        } else {
            return <span><CloseCircleTwoTone twoToneColor={grey}/> Open</span>
        }
    }

    render() {
        if (this.props.assignment.state === 'unassigned') {
            return null;
        } else if (this.props.assignment.loading) {
            return <>{this.renderStatus()}</>
        }

        return (
            <>
                <Popover content={this.popoverContent()}>
                    {this.renderStatus()}
                </Popover>
            </>
        )
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        forms_query: safe_get(store, "forms.query", ""),
        period_query: safe_get(store, "forms.period_query", "")
    }
}

export default withRouter(connect(mapStateToProps)(FormAssignmentsTab));
