import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'

import {Checkbox} from "antd";
import InfoIconComponent from "../InfoIconComponent";
import {isAdmin, isTeamLead, safe_get} from "../../other/Helper";
import {AllowedFeatures} from 'other/Constants';

function VoiceNotificationsCheckbox(props) {
    const {urgentChecked = false, checked = false, onChange, user, style = {marginTop: '0.5rem'}, organization = {}} = props;

    const infoText = "[Admin and Team Lead only] If this box is checked, ZERO will call subscribers on their emergency phone number (if they have one on their profile) and read this post to them.";
    const disabledInfoText = "Voice calling is a premium feature. Please contact support@teamzero.com if you're interested in upgrading your package.";
    const voiceAllowed = safe_get(organization, 'allowed_features', []).includes(AllowedFeatures.VOICE_CALLS);

    if (!isAdmin(user) && !isTeamLead(user)) return null;
    if (!urgentChecked) return null;

    return (
        <Checkbox style={style} disabled={!voiceAllowed} checked={voiceAllowed && checked} onChange={onChange}>
            <>Notify via voice call</>
            <InfoIconComponent position={"top"} width={"350px"} text={voiceAllowed ? infoText : disabledInfoText}/>
        </Checkbox>
    )
}

VoiceNotificationsCheckbox.propTypes = {
    urgentChecked: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    style: PropTypes.object,
    organization: PropTypes.object
}

const mapStateToProps = store => ({
    organization: safe_get(store, 'org_helper.organization', {})
});

export default connect(mapStateToProps)(VoiceNotificationsCheckbox);