import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Radio, Space, Select, Tag, Cascader, Image} from 'antd';

const {Option, OptGroup} = Select;
import {FilterOutlined} from '@ant-design/icons';

import {PrettyDeskTypeMap} from '../../other/Constants.js';
import {selectDesk, selectLocation, selectSpace, selectSpaceAndLocation} from '../../store/slices/checkInOptions.js';


export default function DeskPool({deskPool}) {
    const checkInOptions = useSelector(state => state.checkInOptions);
    const dispatch = useDispatch();
    const {selectedDesk, selectedLocation, selectedSpace} = checkInOptions;

    const [filteredDesks, setFilteredDesks] = useState([]);
    const [showDeskFilter, setShowDeskFilter] = useState(false);
    const [rawDeskFilters, setRawDeskFilters] = useState([]);
    const [deskFilters, setDeskFilters] = useState({nameFilters: [], typeFilters: []});
    const [floorPlanVisible, setFloorPlanVisible] = useState(false);

    useEffect(() => {
        let tmpLocation = selectedLocation;
        let tmpSpace = selectedSpace;

        if (deskPool.ok && deskPool.locations.length > 0) {
            if (!tmpLocation) {
                tmpLocation = deskPool.locations[0];
            }

            if (tmpLocation && !tmpSpace && tmpLocation.spaces.length > 0) {
                tmpSpace = tmpLocation.spaces[0];
            }
        }

        dispatch(selectLocation(tmpLocation));
        dispatch(selectSpace(tmpSpace));
    }, [deskPool]);

    useEffect(() => {
        if (!selectedSpace || selectedSpace.desks.length === 0) {
            setFilteredDesks([]);
        } else {
            // let desks = selectedSpace.desks.filter(desk => !desk.is_assigned);
            let desks = selectedSpace.desks;
            if (deskFilters.typeFilters.length > 0) {
                desks = desks.filter(desk => deskFilters.typeFilters.includes(desk.type));
            }

            if (deskFilters.nameFilters.length > 0) {
                desks = desks.filter(desk => {
                    const deskName = desk.name.toLowerCase();
                    let found = false;
                    for (let name of deskFilters.nameFilters) {
                        if (deskName.includes(name)) {
                            found = true;
                            break;
                        }
                    }
                    return found;
                })
            }
            setFilteredDesks(desks);
        }
    }, [selectedSpace, deskFilters]);

    const resetSelectedDesk = () => {
        dispatch(selectDesk(null));
    }

    const changeSelectedSpace = space => {
        dispatch(selectSpace(space));
        resetSelectedDesk();
    }

    const onCascadeChange = ([locationId, spaceId]) => {
        const location = deskPool.locations.find(location => location.uuid === locationId);
        const space = location.spaces.find(space => space.uuid === spaceId);

        dispatch(selectSpaceAndLocation({space, location}));
    }

    const selectSpaceById = spaceId => {
        changeSelectedSpace(selectedLocation.spaces.find(space => space.uuid === spaceId))
    }

    const selectDeskById = event => {
        const deskId = event.target.value;
        const desk = selectedSpace.desks.find(desk => desk.uuid === deskId);
        dispatch(selectDesk(desk));
    }

    const handleDeskFiltersChange = values => {
        const filters = {
            nameFilters: [],
            typeFilters: [],
        };

        values.map(value => {
            if (value.startsWith('deskType=')) {
                filters.typeFilters.push(value.slice('deskType='.length));
            } else {
                filters.nameFilters.push(value.trim().toLowerCase());
            }
        });

        setRawDeskFilters(values);
        setDeskFilters(filters);
    }

    return (<>
        {floorPlanVisible &&
            <Image
                preview={{
                    visible: true,
                    src: selectedSpace.floor_plan,
                    onVisibleChange: visible => setFloorPlanVisible(visible)
                }}
            />
        }
        {!selectedLocation &&
            <p>No offices are available.</p>
        }
        {selectedLocation &&
            <Space direction="vertical" size={24} style={{width: '100%'}}>
                <div>
                    <p className="text-semibold mar-btm-0">Select office and floor</p>
                    <Cascader
                        fieldNames={{
                            label: 'cascadeName',
                            value: 'uuid',
                            children: 'spaces'
                        }}
                        options={deskPool.locations}
                        onChange={onCascadeChange}
                        placeholder="Please select"
                        value={[selectedLocation?.uuid, selectedSpace?.uuid]}
                        style={{width: '100%'}}
                        popupClassName="zero-blue"
                        allowClear={false}
                        className="zero-dark-grey"
                    />
                    {selectedSpace?.floor_plan &&
                        <span className="blue-link hover-cursor"
                              onClick={() => setFloorPlanVisible(true)}>View Map</span>
                    }
                </div>

                {!selectedSpace &&
                    <p className="zero-dark-grey">No spaces are available. Please choose a different office.</p>
                }
                {selectedSpace &&
                    <>
                        <div>
                            <p className="text-semibold mar-btm-0">Select desk <FilterOutlined onClick={() => {
                                setShowDeskFilter(!showDeskFilter)
                            }}/></p>
                            <Space direction="vertical" size={10} style={{width: '100%'}}>
                                {showDeskFilter &&
                                    <Select
                                        mode="tags"
                                        placeholder={"Filter or search..."}
                                        onChange={handleDeskFiltersChange}
                                        value={rawDeskFilters}
                                        style={{width: '100%'}}
                                        virtual={false}
                                    >
                                        <OptGroup label={<span className="text-semibold">Desk Type</span>}>
                                            {
                                                Object.entries(PrettyDeskTypeMap).map(entry => {
                                                    const [key, value] = entry;
                                                    return <Option key={key}
                                                                   value={`deskType=${key}`}>{value}</Option>
                                                })
                                            }
                                        </OptGroup>
                                    </Select>
                                }
                                {filteredDesks.length === 0 &&
                                    <p className="zero-dark-grey">No desks are available. Please choose a different
                                        office or change your search parameters.</p>
                                }
                                {filteredDesks.length > 0 &&
                                    <Radio.Group
                                        onChange={selectDeskById}
                                        value={selectedDesk ? selectedDesk.uuid : null}
                                        style={{width: '100%', wordBreak: 'keep-all'}}
                                    >
                                        <Space direction="vertical" size={4}>
                                            {
                                                filteredDesks.map(desk => (
                                                    <Radio key={desk.uuid} value={desk.uuid}
                                                           style={{width: '100%', wordBreak: 'keep-all'}}>
                                                        <span className="zero-dark-grey">{desk.name}</span>
                                                        <Tag style={{
                                                            marginLeft: '1rem',
                                                            textTransform: 'capitalize'
                                                        }}>{desk.type.replace('_', ' ')}</Tag>
                                                        {desk.is_assigned &&
                                                            <Tag color="green">Assigned</Tag>
                                                        }
                                                    </Radio>
                                                ))
                                            }
                                        </Space>
                                    </Radio.Group>
                                }
                            </Space>
                        </div>
                    </>
                }
            </Space>
        }
    </>);
}