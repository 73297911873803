import React, {useEffect, useState} from 'react';

import {actions} from './reducer';
import {safe_get, hideFooterMobileApp, showFooterMobileApp} from 'other/Helper';

let inputDebouncer = null;

export default function PostFormCustomField({state, dispatch, customPostField}) {
    const fieldName = safe_get(customPostField, 'name', '');
    const fieldEnabled = safe_get(customPostField, 'enabled', false);
    const [fieldValue, setFieldValue] = useState(state.customFieldValue);

    const onFieldChange = event => {
        const value = event.target.value;
        setFieldValue(value);

        clearTimeout(inputDebouncer);
        inputDebouncer = setTimeout(() => {
            dispatch([actions.CUSTOM_FIELD_CHANGED, value]);
            inputDebouncer = null;
        }, 1500);
    }

    useEffect(() => {
        return () => {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
    }, []);

    useEffect(() => {
        setFieldValue(state.customFieldValue);
    }, [state.customFieldValue]);

    useEffect(() => {
        if (state.flushInput && fieldValue !== state.customFieldValue) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
            dispatch([actions.CUSTOM_FIELD_CHANGED, fieldValue]);
        }
    }, [state.flushInput]);

    const onBlur = () => {
        showFooterMobileApp();
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
            dispatch([actions.CUSTOM_FIELD_CHANGED, fieldValue]);
        }
    }

    if (!fieldEnabled) return null;

    return (
        <>
            <div className="panel-heading bulletin-post">
                <h3 className="bulletin-post-headers ">{fieldName}</h3>
            </div>

            <div className="bulletin-post">
                <div className="form-group" style={{marginBottom: '0px', maxWidth: "400px"}}>
                    <input
                        name="custom_field_value"
                        type="text"
                        placeholder=""
                        className="form-control custom-placeholder"
                        maxLength="45"
                        value={fieldValue}
                        onFocus={hideFooterMobileApp}
                        onBlur={onBlur}
                        onChange={onFieldChange}
                        style={{maxWidth: "400px"}}
                    />
                    <p className="text-sm mar-btm-0"
                       style={{color: '#BCBABC', paddingTop: '3px', float: "right"}}>{45 - (fieldValue.length)}</p>
                </div>
            </div>
        </>
    );
}