import React, {useEffect, useState, useCallback} from 'react';
import Routes from './routes/index.js';
import {Workbox} from 'workbox-window';

import {isIE} from './other/Helper.js';

export const AppContext = React.createContext();

async function unregisterServiceWorkers() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for(let registration of registrations) {
        await registration.unregister();
    }
    location.pathname = "/";
}

export default function App() {
    const [hasUpdate, setHasUpdate] = useState(false);
    const [wb, setWb] = useState(null);

    useEffect(() => {
        document.body.style.removeProperty('background-color');

        if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
            const wb = new Workbox('/service-worker.js');

            fetch("/", {cache: "no-store", method: "HEAD"})
                .then(res => {
                    if (res.status === 503) {
                        // we're in maintenance mode or Heroku is having issues
                        unregisterServiceWorkers();
                    } else {
                        wb.addEventListener('waiting', (event) => {
                            wb.addEventListener('controlling', () => {
                                window.location.reload();
                            });
            
                            setHasUpdate(true);
                        });
            
                        console.log('Attempting to register service worker.');
            
                        wb.register().then(function (registration) {
                            console.log("Service Worker registered.");
                            setWb(wb);
                        }).catch(function (err) {
                            console.log('Service Worker registration failed: ', err);
                        });
                    }
                })
                .catch(err => {
                    console.error("fetch error:", err);
                });
        } else {
            console.log("Skipping Service Worker.");
        }
    }, []);

    useEffect(() => {
        let updateTimer = null;

        if (wb !== null) {
            updateTimer = setInterval(() => {
                try {
                    wb.update();
                    console.log("Service worker updated.");
                } catch (err) {
                    console.log("Service worker update failed.", err);
                }
            }, 900_000); // 15 min = 15 * 60 * 1000 = 900_000 ms
        }

        return () => {
            clearInterval(updateTimer);
        };
    }, [wb]);

    useEffect(() => {
        if (isIE()) {
            window.location = 'https://teamzero.freshdesk.com/support/solutions/articles/70000375390';
        } else {
            let lightSchemeIcon = document.querySelector('link#light-scheme-icon');
            let darkSchemeIcon = document.querySelector('link#dark-scheme-icon');
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                if (document.head.append && lightSchemeIcon && darkSchemeIcon) {
                    lightSchemeIcon.remove();
                    document.head.append(darkSchemeIcon);
                }
            } else {
                if (document.head.append && lightSchemeIcon && darkSchemeIcon) {
                    darkSchemeIcon.remove();
                    document.head.append(lightSchemeIcon);
                }
            }
        }
    }, []);

    if (isIE()) {
        return <div></div>
    }

    const contextValue = {
        hasUpdate,
        wb
    }

    return (
        <AppContext.Provider value={contextValue}>
            <Routes/>
        </AppContext.Provider>
    );
}
