import PouchDB from 'pouchdb';

import {pouchDbGet, pouchDbUpsert} from './pouchDbUtils';
import {get_initial_data} from 'api/zero-api';

/**
 * Gets initial_data from API and updates PouchDB
 * @param {InitialDataCache} cache
 */
export async function syncInitialData(cache) {
    try {
        const res = await get_initial_data();
        const initialData = await res.json();
        await cache.set(initialData);
    } catch (error) {
        console.error('Could not sync initial data:', error);
    }
}

export default class InitialDataCache {
    /**
     * @param {string} orgId
     * @param {string} userId
     */
    constructor(orgId, userId) {
        this.userId = userId;
        this.orgId = orgId;
        this.orgPrefix = orgId.substring(0, 8);
        this.db = new PouchDB(`${this.orgPrefix}:initial_data`, {auto_compaction: true, revs_limit: 1});
    }

    /**
     * Gets the initial_data cache we have stored for this user or returns a blank object if nothing cached.
     * @returns {Promise<object>}
     */
    async get() {
        return await pouchDbGet(this.db, this.userId);
    }

    /**
     * Sets the initial_data cache for this user, overwriting existing data if present.
     * @param {object} initialData
     */
    async set(initialData) {
        await pouchDbUpsert(this.db, this.userId, initialData);
    }
}