import * as teamsActions from 'store/actions/TeamsActions'

/**
 * Updates Redux "teams" store (current user's teams) after team membership changes
 * @param {object} currentUser 
 * @param {array} currentUserTeams 
 * @param {object} team 
 * @param {array} newTeamMemberIds 
 * @param {function} dispatch
 */
export function updateTeamsOnMembershipChange(currentUser, currentUserTeams, team, newTeamMemberIds, dispatch) {
    const teamId = team.uuid;
    const userInNewMembersList = newTeamMemberIds.includes(currentUser.uuid);

    if (currentUserTeams.some(team => team.uuid === teamId)) {
        // current user is in this team
        if (!userInNewMembersList) {
            // but was just removed
            const newTeams = currentUserTeams.filter(team => team.uuid !== teamId);
            dispatch(teamsActions.update_teams(newTeams));
        }
    } else {
        // current user is not in this team
        if (userInNewMembersList) {
            // but was just added
            const { members, ...newTeam } = team;
            const newTeams = [...currentUserTeams, newTeam];
            dispatch(teamsActions.update_teams(newTeams));
        }
    }
}