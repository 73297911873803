import React, {Component} from 'react';

import {
    get_forms,
    create_schedule,
    update_schedule,
    get_list_users,
    get_schedule_subscribers
} from '../../../api/zero-api.js'
import {safe_get, safeProfilePic, isViewer, scheudleFrequency, isAdmin} from '../../../other/Helper.js';

import NotificationAlert from '../../../other/NotificationAlert';
import ButtonLoading from '../../Shared/ButtonLoading.js';

import {Select, DatePicker, Modal, Radio, Space} from 'antd'

import LoadingIndicator from '../../Shared/LoadingIndicator.js';

import '../../../assets/css/antd-custom.css';
import RespondersSelector from "../../Shared/RespondersSelector";
import dayjs from "dayjs";

var searchTimeout = null;

class NewEditScheduleModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_edit: this.props.schedule !== undefined,
            loading_forms: true,
            forms: [],
            form_teams: [],
            available_teams: [],
            existing_team_uuid: [],
            selected_team_uuid: [],
            selected_form_uuid: undefined,
            selected_repeat_period: undefined,
            schedule_name: "",
            custom_start: dayjs().format("YYYY-MM-DD"),
            custom_start_moment: dayjs(),
            custom_start_time: "09:00:00",
            custom_end: undefined,
            custom_end_moment: undefined,
            all_users: [],
            availableUsers: [],
            filtered_users: [],
            selected_users: [],
            selected_users_uuids: [],
            existingResponders: [],
            allMembersAssigned: false,
            subscribers_count: 0,
            creating: false,
            step: 1,
            maxSteps: 2,
            schedulerType: props.schedule?.type ?? "regular",
        };

        this.setUpResponders = this.setUpResponders.bind(this);
        this.getForms = this.getForms.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.removeResponder = this.removeResponder.bind(this);
        this.scheduleNameChange = this.scheduleNameChange.bind(this);
        this.handleAvailableTeams = this.handleAvailableTeams.bind(this);
        this.fieldsCompleted = this.fieldsCompleted.bind(this);
        this.confirm = this.confirm.bind(this);

    }

    componentDidMount() {
        this.getForms()
        this.getUsers();
        if (this.state.is_edit) {
            this.setUpResponders();
        }
    }

    setUpResponders() {
        let assigned_to_team = safe_get(this.props, "schedule.assigned_to_team", false);
        let existingResponders = safe_get(this.props, "schedule.subscribers", []);
        let existingRespondersCount = safe_get(this.props, 'schedule.subscribers_count', 0);
        var selected_users_uuids = [];

        if (existingRespondersCount === existingResponders.length) {
            for (var i in existingResponders) {
                let responder = existingResponders[i];
                selected_users_uuids.push(responder.user_uuid)
            }

            this.setState({
                existingResponders: existingResponders,
                selected_users_uuids: assigned_to_team ? [] : selected_users_uuids
            });
        } else {
            this.loadResponders();
        }
    }

    async loadResponders() {
        try {
            this.setState({loading_responders: true});
            const schedule_uuid = safe_get(this.props, 'schedule.scheduler_uuid', null);
            const assigned_to_team = safe_get(this.props, "schedule.assigned_to_team", false);
            const response = await get_schedule_subscribers(schedule_uuid)
            const data = await response.json();
            const subscribers = safe_get(data, 'subscribers', [])

            this.setState({
                existingResponders: subscribers,
                selected_users_uuids: assigned_to_team ? [] : subscribers.map(user => user.uuid || user.user_uuid),
                loading_responders: false
            });
        } catch (error) {
            console.error('Unable to load subscribers:', error);
            NotificationAlert('error', '', 'Unable to load list of responders.');
            this.setState({
                loading_responders: false,
            })
        }
    }

    getForms() {
        var self = this;

        get_forms(`?all=true&form_types=${this.props.page_type}&enabled=true&exclude_restricted=true&include_fields=false`).then(function (success) {
            success.json().then(success => {
                var forms = safe_get(success, "forms", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                });

                var new_state = {};
                new_state["forms"] = forms;
                new_state["loading_forms"] = false;

                if (self.state.is_edit) {
                    let schedule = self.props.schedule;

                    let team_uuids = safe_get(schedule, "teams", []).sort((a, b) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    }).map(team => team.team_uuid)

                    // let custom_start_time = safe_get(schedule, "custom_start_time", "09:00:00");

                    let custom_start_timestamp = safe_get(schedule, 'custom_start_utc_timestamp', null)
                    if (!custom_start_timestamp) {
                        let dt = new Date();
                        custom_start_timestamp = dt.setUTCHours(10, 0, 0, 0) / 1000;
                    }

                    let custom_start_time = dayjs.unix(custom_start_timestamp).format("HH:mm:ss");

                    new_state["schedule_name"] = safe_get(schedule, "name", "");
                    new_state["selected_repeat_period"] = safe_get(schedule, "repeat_period", undefined);
                    new_state["custom_start"] = safe_get(schedule, "start_date", "");
                    new_state["custom_start_moment"] = dayjs(schedule.start_date, "YYYY-MM-DD");
                    new_state["custom_start_time"] = custom_start_time;
                    new_state["custom_end"] = safe_get(schedule, "end_date", undefined);
                    new_state["custom_end_moment"] = schedule.end_date ? dayjs(schedule.end_date, "YYYY-MM-DD") : undefined;
                    new_state["selected_form_uuid"] = safe_get(schedule, "form.form_uuid", undefined);
                    new_state["form_teams"] = safe_get(schedule, "form.teams", []);
                    new_state["schedule_teams"] = safe_get(schedule, "teams", []);
                    new_state["available_teams"] = safe_get(schedule, "teams", []);
                    new_state["selected_team_uuid"] = team_uuids;
                    new_state["existing_team_uuid"] = team_uuids;
                    new_state["allMembersAssigned"] = safe_get(schedule, "assigned_to_team", false);
                    new_state["assigned_to_team"] = safe_get(schedule, "assigned_to_team", false);
                    new_state["subscribers_count"] = safe_get(schedule, "subscribers_count", 0);
                }

                self.setState(new_state, () => {
                    self.handleAvailableTeams();
                    self.handleAvailableUsers();
                });
            });
        }, function (error) {
            self.setState({loading_forms: false})
            console.log(error);
        });
    }

    getUsers() {
        var self = this
        get_list_users("?status=active").then(function (success) {
            success.json().then(success => {
                let users = safe_get(success, "users", []);

                users = users.filter(function (user) {
                    return user.status === "active" && !isViewer(user)
                });

                self.setState({all_users: users, filtered_users: users, availableUsers: users}, () => {
                    self.handleAvailableUsers();
                });
            })
        }, function (error) {
            console.log(error)
        });
    }

    scheduleNameChange(e) {
        var name = e.target.value
        this.setState({schedule_name: name});
    }

    handleFormSelect = (value) => {
        let form = this.state.forms.find(function (form) {
            return form.form_uuid === value
        });

        this.setState({
            selected_form_uuid: value,
            form_teams: form.teams,
            selected_users: [],
            selected_users_uuids: [],
            selected_team_uuid: []
        }, () => {
            // this.setUpResponders();
            this.handleAvailableTeams();
            this.getUsers();
        });
    }

    handleAvailableTeams() {
        let teams = [];
        let user_teams = [...this.props.user_teams]
        let form_teams = [...this.state.form_teams];

        if (isAdmin(this.props.user)) {
            teams = form_teams;
        } else {
            for (var i in form_teams) {
                let team_uuid = form_teams[i].team_uuid;
                let team = user_teams.find(function (team) {
                    return team.uuid === team_uuid
                });

                if (team) {
                    teams.push(team);
                }
            }
        }

        if (this.state.schedule_teams) {
            for (const schedule_team of this.state.schedule_teams) {
                const team_uuid = schedule_team.team_uuid;
                const team = teams.find(t => (t.uuid || t.team_uuid) === team_uuid);
                if (!team) {
                    teams.push(schedule_team);
                }
            }
        }

        teams.sort(function (a, b) {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });

        this.setState({available_teams: teams});
    }

    handlePeriodSelect(value) {
        this.setState({
            selected_repeat_period: value
        });

        if (value === scheudleFrequency.WEEKEND) { // weekend
            // if selected/default start day is not a weekend
            if (this.state.custom_start_moment.day() !== 6 && this.state.custom_start_moment.day() !== 0) {
                let nextSaturday = dayjs().isoWeekday(6)
                this.setState({
                    custom_start_moment: nextSaturday,
                    custom_start: dayjs(nextSaturday, 'YYYY/MM/DD').format("YYYY-MM-DD")
                });
            }
        }
    }

    disabledStartDate(current) {
        let period = this.state.selected_repeat_period
        if (current) {
            if (current <= dayjs().startOf('day')) {
                return true
            } else if (period === scheudleFrequency.DAILY) {
                return current <= dayjs().startOf('day');
            } else if (period === scheudleFrequency.WEEKDAY) {
                return current.day() === 6 || current.day() === 0 || current <= dayjs().startOf('day');
            } else if (period === scheudleFrequency.WEEKEND) {
                return current.day() !== 6 && current.day() !== 0
            }
            // else if (period === 2) { // weekly
            //     return current <= moment().startOf('day') || current.day() !== 1
            // }
            // else if (period === 3) { // monthly           
            //     return current <= moment().startOf('day')  || current.date() !== 1
            // }
        }

    }

    disabledDate(current) {
        // Can not select day before today     
        return current < dayjs().subtract(1, "day").endOf('day');

    }

    handleStartTimeSelect = (value) => {
        // let custom_start_time = `${moment(value).format("HH:mm")}:00`;
        let custom_start_time = `${value}:00:00`;
        this.setState({custom_start_time: custom_start_time});
    }

    handleStartDateSelect(value) {
        this.setState({
            custom_start_moment: value,
            custom_start: dayjs(value, 'YYYY/MM/DD').format("YYYY-MM-DD")
        }, () => {

            // if (this.state.custom_end && value) {
            //     var diff = moment.duration(value.diff(this.state.custom_end_moment)).asDays();
            //     if (diff < -1 && diff > -92) {
            //         this.setState({ range_error: undefined });
            //     }  
            //     else if (diff < -92) {
            //         this.setState({
            //             custom_start_moment: undefined, 
            //             custom_start: "", 
            //             custom_end: undefined, 
            //             custom_end_moment: undefined, 
            //             range_error: "Max range of 3 months",                        
            //         });
            //     }
            //     else {
            //         // this.setState({ range_error: "End Date has to be after Start Date" });
            //     }
            // }
        })
    }

    handleEndDateSelect(value) {
        this.setState({
            custom_end_moment: value,
            custom_end: value ? dayjs(value, 'YYYY/MM/DD').format("YYYY-MM-DD") : value
        });
    }

    handleTeamSelect = (team_uuid) => {
        let selected_team_uuid = [...this.state.selected_team_uuid];
        selected_team_uuid.push(team_uuid);

        let teams = [...this.state.available_teams].filter((team) => {
            return selected_team_uuid.includes(team.uuid || team.team_uuid)
        });
        let sorted_team_uuids = teams.map(team => team.uuid || team.team_uuid);

        this.setState({selected_team_uuid: sorted_team_uuids}, () => {
            this.handleAvailableUsers();
        });
    }

    handleTeamDeselect = (team_uuid) => {
        let existing_teams = [...this.state.existing_team_uuid];

        let selected_team_uuid = [...this.state.selected_team_uuid];
        let index = selected_team_uuid.indexOf(team_uuid);

        selected_team_uuid.splice(index, 1);

        this.setState({selected_team_uuid: selected_team_uuid}, () => {
            this.checkTeamMembership();
            this.handleAvailableUsers();
        });

    }

    updateAllMembersValue() {
        if (this.state.allMembersAssigned && !this.state.is_edit) {
            // force a re-render of "All Members" select option if team updates
            const selection = {
                key: 'all_team_members',
                label: <span>All Members ({this.state.availableUsers.length})</span>,
                value: 'all_team_members'
            }

            this.setState({
                selected_users: [selection]
            });
        }
    }

    userCanBeAssignedToSchedule(userUuid) {
        const user = this.state.all_users.find(user => user.uuid === userUuid);
        if (!user) return false;

        let userTeams = user.teams;

        for (var i in userTeams) {
            let team = userTeams[i]
            if (this.state.selected_team_uuid.includes(team.uuid)) {
                return true;
            }
        }
        return false;
    }

    checkTeamMembership() {
        if (this.state.allMembersAssigned) return;

        let selected_users_to_keep = this.state.selected_users.filter(userObj => {
            return this.userCanBeAssignedToSchedule(userObj.key);
        });
        let selected_users_uuids = selected_users_to_keep.map(userObj => userObj.key);

        const newState = {
            selected_users_uuids: selected_users_uuids,
            selected_users: selected_users_to_keep
        };

        if (this.state.is_edit) {
            let existing_users_to_keep = this.state.existingResponders.filter(userObj => {
                return this.userCanBeAssignedToSchedule(userObj.user_uuid);
            });

            selected_users_uuids = selected_users_uuids.concat(existing_users_to_keep.map(userObj => userObj.user_uuid));
            newState.selected_users_uuids = [...new Set(selected_users_uuids)]

            newState.existingResponders = existing_users_to_keep;
        }

        this.setState(newState);
    }

    handleAvailableUsers = () => {
        let allUsers = [...this.state.all_users];
        let selected_teams = [...this.state.selected_team_uuid]

        let availableUsers = allUsers.filter(function (user) {
            let userTeams = user.teams;
            for (var i in userTeams) {
                let team = userTeams[i]
                if (selected_teams.includes(team.uuid)) {
                    return true
                }
            }
            return false;
        });

        this.setState({
            availableUsers: availableUsers,
            filtered_users: availableUsers
        }, () => {
            this.updateAllMembersValue();
        });
    }

    // searchUsers(value){
    //     var self = this;
    //     self.setState({ filtered_users: [], fetching: true })

    //     clearTimeout(searchTimeout);
    //     searchTimeout = setTimeout(function() {
    //         if (value !== "") {
    //             var users = [...self.state.availableUsers];
    //             var existingResponders = [...self.state.existingResponders];
    //             var searchValue = value.toLowerCase();

    //             // Remove existing responders from dropdown menu
    //             if (self.state.assigned_to_team) {

    //             }
    //             else {
    //                 users = users.filter(function(user) {                       
    //                     let found = existingResponders.find(function(obj) {
    //                         return obj.user_uuid === user.uuid
    //                     })
    //                     return !found
    //                 })
    //             }

    //             var filterList = users.filter(function(object) {
    //                 var first_name = object.first_name.toLowerCase();
    //                 var last_name = object.last_name.toLowerCase();
    //                 var complete_name = first_name + " " + last_name;
    //                 var email = object.email.toLowerCase();

    //                 return (complete_name.includes(searchValue) || email.includes(searchValue));
    //             });

    //             var newState = {};

    //             filterList.sort(function(a, b) {
    //                 var a_first_name = a.first_name.toLowerCase();
    //                 var a_last_name = a.last_name.toLowerCase();

    //                 var b_first_name = b.first_name.toLowerCase();
    //                 var b_last_name = b.last_name.toLowerCase();

    //                 if (a_first_name > b_first_name) return 1;
    //                 if (a_first_name < b_first_name) return -1;

    //                 if (a_last_name > b_last_name) return 1;
    //                 if (a_last_name < b_last_name) return -1;  

    //                 return 0;
    //             });

    //             newState["dropdownStyle"] = { display: "block", zIndex: "10000"};

    //             newState["filtered_users"] = filterList;
    //             newState["fetching"] = false;
    //             newState["searchingUsers"] = false;

    //             self.setState(newState);

    //         }
    //         else {
    //             self.setState({ filtered_users: self.state.availableUsers, fetching: false, });
    //         }
    //     }, 400);        

    // }

    filterList = (inputValue, option) => {
        return option.name.toLowerCase().includes(inputValue.toLowerCase())
    }

    handleUserSelect(value, option) {
        let hasAllTeamMembers = false;
        let allUsersIndex = -1;
        var selected_users_uuids = this.state.is_edit ? [...this.state.selected_users_uuids] : [];

        for (var i in value) {
            let item = value[i]
            let uuid = item.key

            if (uuid === "all_team_members") {
                hasAllTeamMembers = true;
                allUsersIndex = i;
                break;
            } else if (selected_users_uuids.indexOf(uuid) < 0) {
                selected_users_uuids.push(uuid)
            }
        }

        if (hasAllTeamMembers) {
            let allmembers = value[allUsersIndex];
            value = [allmembers]
        }

        this.setState({
            selected_users: value,
            filtered_users: this.state.availableUsers,
            selected_users_uuids: selected_users_uuids,
            allMembersAssigned: hasAllTeamMembers
        });
    }

    toggleAssignAll = (allAssigned = false) => {
        var newState = {};
        newState["allMembersAssigned"] = allAssigned;
        // if (allAssigned === false) {
        //     newState["existingResponders"] = []
        //     newState["selected_users_uuids"] = [];
        //     newState["selected_users"] = []
        //     newState["assigned_to_team"] = false;
        // }
        this.setState(newState);
    }

    removeResponder(user, allMembers) {
        var new_state = {};
        if (allMembers) {
            // this.toggleAssignAll(false);
            this.setState({
                selected_users: [],
                selected_users_uuids: [],
                existingResponders: [],
                allMembersAssigned: false,
                assigned_to_team: false
            });
        } else {
            var existing_responders = [...this.state.existingResponders];
            var responder_index = existing_responders.indexOf(user);

            if (responder_index >= 0) {
                existing_responders.splice(responder_index, 1);
                new_state["existingResponders"] = existing_responders
            }

            let user_uuid = user.user_uuid

            var selected_users_uuids = [...this.state.selected_users_uuids];
            let user_uuid_index = selected_users_uuids.indexOf(user_uuid);

            if (user_uuid_index >= 0) {
                selected_users_uuids.splice(user_uuid_index, 1);
                new_state["selected_users_uuids"] = selected_users_uuids
            }

            this.setState(new_state);
        }


    }

    fieldsCompleted() {
        if (this.state.schedule_name.length === 0) {
            return false;
        }
        if (this.state.selected_team_uuid === undefined || this.state.selected_team_uuid.length === 0) {
            return false;
        }
        if (this.state.selected_form_uuid === undefined) {
            return false;
        }
        if (this.state.selected_repeat_period === undefined) {
            return false;
        }
        if (!this.state.custom_start_moment) {
            return false;
        }
        if (this.state.selected_repeat_period === scheudleFrequency.SEND_ONCE && !this.state.custom_end) {
            return false;
        }

        return true;
    }

    async handleApiErrors(error) {
        if (error.status === 422) {
            try {
                const errorData = await error.json();
                let error_messages = safe_get(errorData, "error_messages", []);
                if (error_messages.length > 0) {
                    let error_msg = safe_get(error_messages[0], "message", "");
                    this.setState({step: 1, error_msg: error_msg});
                }
            } catch (err) {
                this.setState({step: 1, error_msg: 'Unknown error.'});
            }
        }
    }

    async confirm() {
        if (this.fieldsCompleted()) {
            this.setState({creating: true, error_msg: false});

            let body = JSON.stringify({
                name: this.state.schedule_name,
                enabled: true,
                team_uuids: this.state.selected_team_uuid,
                form_uuid: this.state.selected_form_uuid,
                user_uuids: this.state.selected_users_uuids,
                assigned_to_team: this.state.allMembersAssigned,
                repeat_period: this.state.selected_repeat_period,
                start_date: this.state.custom_start,
                custom_start_time: this.state.custom_start_time,
                custom_start_timezone: safe_get(this.props, "user.timezone", ""),
                end_date: this.state.custom_end,
                type: this.state.schedulerType,
            });

            if (this.state.is_edit) {
                try {
                    const res = await update_schedule(this.props.schedule.scheduler_uuid, body);
                    const data = await res.json();

                    NotificationAlert("success", "", "Schedule updated.");

                    if (this.props.setSchedule) {
                        this.props.setSchedule(data);
                    } else if (this.props.updateSchedules) {
                        this.props.updateSchedules();
                    }
                    this.props.cancel && this.props.cancel();
                } catch (error) {
                    NotificationAlert("error", "", "Unable to update schedule.");
                    await this.handleApiErrors(error);
                    this.setState({creating: false});
                }
            } else {
                try {
                    const res = await create_schedule(body);
                    NotificationAlert("success", "", "Schedule created.");
                    this.props.updateSchedules();
                    this.props.cancel();
                } catch (error) {
                    NotificationAlert("error", "", "Unable to create schedule.");
                    await this.handleApiErrors(error);
                    this.setState({creating: false});
                }
            }
        } else {
            this.setState({error_msg: "Please complete all fields."});
        }

    }

    goToNextStep = () => {
        const {step, maxSteps} = this.state;

        if (step === 1 && !this.fieldsCompleted()) {
            this.setState({error_msg: "Please complete all fields."});
        } else if (step < maxSteps) {
            this.setState({
                error_msg: null,
                step: step + 1
            })
        }
    }

    firstDueDate = () => {

        let start_time = `at ${dayjs(this.state.custom_start_time, "HH:mm:ss").format("h:mm A")}.`

        switch (this.state.selected_repeat_period) {
            case scheudleFrequency.DAILY:
                return "Repeats every day " + start_time;
            case scheudleFrequency.WEEKDAY:
                return "Repeats every weekday " + start_time;
            case scheudleFrequency.WEEKLY:
                return "Repeats every Monday " + start_time;
            case scheudleFrequency.WEEKEND:
                return "Repeats every Saturday and Sunday " + start_time;
            case scheudleFrequency.MONTHLY:
                return "Repeats on the 1st of every month " + start_time;
            case scheudleFrequency.SEND_ONCE:
                let due_date_str = `${dayjs(this.state.custom_end_moment, 'YYYY/MM/DD').format("MMM D, YYYY")}`;
                let string1 = this.state.selected_repeat_period === 4 ? "Assignment will be due on " : "First assignment will be due on "
                return (this.state.custom_end_moment && !this.state.is_edit) ? string1 + due_date_str + " at 11:59 PM." : "";
            case scheudleFrequency.QUARTERLY:
                return "Repeats on the 1st of every quarter " + start_time;
            case scheudleFrequency.BIANNUALLY:
                return "Repeats on the 1st of every half year " + start_time;
            case scheudleFrequency.ANNUALLY:
                return "Repeats on the 1st of every year " + start_time;
            default:
                break;
        }

    }

    handleResponderChange = (userId, isResponder) => {
        if (isResponder) {
            const index = this.state.selected_users_uuids.indexOf(userId);
            if (index >= 0) {
                const newList = [...this.state.selected_users_uuids];
                newList.splice(index, 1);
                this.setState({selected_users_uuids: newList, allMembersAssigned: false});
            }
        } else {
            const selectedCount = this.state.selected_users_uuids.length;
            const availableCount = this.state.availableUsers.length;
            const allMembersAssigned = selectedCount + 1 === availableCount;


            this.setState({
                selected_users_uuids: [...this.state.selected_users_uuids, userId],
                allMembersAssigned
            });
        }
    }

    handleAssignToTeamChange = (allMembersAssigned) => {
        this.setState({allMembersAssigned});
    }

    filterFormsSelectOptions = (input, option) => {
        const search = input.toLowerCase();
        return option.label.toLowerCase().includes(search);
    }

    filterTeamsSelectOptions = (input, option) => {
        const search = input.toLowerCase();
        return option.label.toLowerCase().includes(search);
    }

    render() {
        let h3Style = {marginBottom: '2px', marginLeft: '0px', fontSize: '100%', marginTop: "15px"}

        let now = dayjs(new Date, "HH:mm:ss");

        return (
            (<Modal
                title={this.state.is_edit ? "Edit schedule" : "Create schedule"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <footer style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                            <span className="zero-dark-grey">Step {this.state.step} of {this.state.maxSteps}</span>
                        </div>
                        <div>
                            {
                                this.state.error_msg &&
                                <small className="error"
                                       style={{display: "block", marginBottom: "5px"}}>{this.state.error_msg}</small>
                            }
                            {
                                this.state.step === 1 &&
                                <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                            }
                            {
                                this.state.step !== 1 &&
                                <button className="btn btn-discard"
                                        onClick={() => this.setState({step: this.state.step - 1})}>Back</button>
                            }
                            {
                                this.state.creating &&
                                <button className="btn btn-modal">
                                    <ButtonLoading/>
                                </button>
                            }
                            {
                                !this.state.creating && (this.state.step === this.state.maxSteps) &&
                                <button className="btn btn-modal" onClick={this.confirm}>
                                    {this.state.is_edit ? "Update schedule" : "Create schedule"}
                                </button>
                            }
                            {
                                !this.state.creating && (this.state.step !== this.state.maxSteps) &&
                                <button className="btn btn-modal" onClick={this.goToNextStep}>Next</button>
                            }
                        </div>
                    </footer>
                }
            >
                {
                    this.state.loading_forms &&
                    <LoadingIndicator/>
                }
                {
                    !this.state.loading_forms && this.state.step === 1 &&
                    <div>
                        <h3 className="titles required" style={{
                            marginBottom: '2px',
                            marginLeft: '0px',
                            fontSize: '100%',
                            marginTop: "0px"
                        }}>Name</h3>
                        <input type={"text"} id={"form-name"} placeholder="Example Schedule"
                               className={"form-control custom-placeholder"} value={this.state.schedule_name}
                               onChange={this.scheduleNameChange}/>

                        <h3 className="titles required"
                            style={h3Style}>{this.props.page_type === "lms" ? "Course" : "Template"}</h3>
                        <Select
                            style={{width: "100%",}}
                            className="move-team-select"
                            showArrow={true}
                            placeholder={`Select a ${this.props.page_type === "lms" ? "course" : "template"}`}
                            dropdownStyle={{zIndex: "10000"}}
                            value={this.state.selected_form_uuid}
                            notFoundContent={`No ${this.props.page_type === "lms" ? "courses" : "templates"} available...`}
                            disabled={this.state.is_edit}
                            onChange={this.handleFormSelect}
                            showSearch
                            filterOption={this.filterFormsSelectOptions}
                            virtual={false}
                            options={this.state.forms.map(form => ({
                                value: form.form_uuid,
                                label: form.name
                            }))}
                        />

                        <h3 className="titles required" style={h3Style}>Team</h3>
                        <Select
                            style={{width: "100%",}}
                            className="move-team-select"
                            showArrow={true}
                            showSearch={true}
                            filterOption={this.filterTeamsSelectOptions}
                            mode="multiple"
                            placeholder="Select a team"
                            dropdownStyle={{zIndex: "10000"}}
                            value={this.state.selected_team_uuid}
                            disabled={!this.state.selected_form_uuid}
                            onSelect={this.handleTeamSelect}
                            onDeselect={this.handleTeamDeselect}
                            virtual={false}
                            options={this.state.available_teams.map((team) => ({
                                value: team.uuid || team.team_uuid,
                                label: team.name
                            }))}
                        />

                        <div style={{verticalAlign: "bottom", display: "inline-block", width: "100%"}}>
                            <div style={{marginLeft: '0px', display: "inline-block", width: "50%"}}>
                                <h3 className="titles required" style={{
                                    marginBottom: '2px',
                                    marginLeft: '0px',
                                    fontSize: '110%',
                                    marginTop: "15px"
                                }}>Send Frequency</h3>
                                <Select
                                    style={{width: "100%"}}
                                    className="move-team-select"
                                    showArrow={true}
                                    placeholder="Select a repeat period"
                                    dropdownStyle={{zIndex: "10000"}}
                                    listHeight={290}
                                    value={this.state.selected_repeat_period}
                                    onChange={this.handlePeriodSelect.bind(this)}
                                    disabled={this.state.is_edit}
                                    virtual={false}
                                >
                                    <Select.Option key={4} value={4}>Once (Non-recurring)</Select.Option>
                                    <Select.Option key={0} value={0}>Daily (Every day)</Select.Option>
                                    <Select.Option key={1} value={1}>Weekdays (Mon-Fri)</Select.Option>
                                    <Select.Option key={8} value={8}>Weekends (Sat + Sun)</Select.Option>
                                    <Select.Option key={2} value={2}>Weekly (Every Monday)</Select.Option>
                                    <Select.Option key={3} value={3}>Monthly</Select.Option>
                                    <Select.Option key={5} value={5}>Quarterly</Select.Option>
                                    <Select.Option key={6} value={6}>Biannually</Select.Option>
                                    <Select.Option key={7} value={7}>Annually</Select.Option>
                                </Select>
                            </div>
                            <div style={{marginLeft: '1%', display: "inline-block", width: "49%"}}>
                                <p className="zero-dark-grey mar-btm-0" style={{
                                    position: "absolute",
                                    paddingTop: "5px",
                                    paddingLeft: "10px",
                                    zIndex: "100"
                                }}>at</p>
                                <Select
                                    style={{width: "100%"}}
                                    className="schedule-time-picker"
                                    showArrow={true}
                                    placeholder="Select a time"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.custom_start_time.split(":")[0]}
                                    onChange={this.handleStartTimeSelect.bind(this)}
                                    virtual={false}
                                >
                                    <Select.Option value={"00"}>12 AM</Select.Option>
                                    <Select.Option value={"01"}>1 AM</Select.Option>
                                    <Select.Option value={"02"}>2 AM</Select.Option>
                                    <Select.Option value={"03"}>3 AM</Select.Option>
                                    <Select.Option value={"04"}>4 AM</Select.Option>
                                    <Select.Option value={"05"}>5 AM</Select.Option>
                                    <Select.Option value={"06"}>6 AM</Select.Option>
                                    <Select.Option value={"07"}>7 AM</Select.Option>
                                    <Select.Option value={"08"}>8 AM</Select.Option>
                                    <Select.Option value={"09"}>9 AM</Select.Option>
                                    <Select.Option value={"10"}>10 AM</Select.Option>
                                    <Select.Option value={"11"}>11 AM</Select.Option>
                                    <Select.Option value={"12"}>12 PM</Select.Option>
                                    <Select.Option value={"13"}>1 PM</Select.Option>
                                    <Select.Option value={"14"}>2 PM</Select.Option>
                                    <Select.Option value={"15"}>3 PM</Select.Option>
                                    <Select.Option value={"16"}>4 PM</Select.Option>
                                    <Select.Option value={"17"}>5 PM</Select.Option>
                                    <Select.Option value={"18"}>6 PM</Select.Option>
                                    <Select.Option value={"19"}>7 PM</Select.Option>
                                    <Select.Option value={"20"}>8 PM</Select.Option>
                                    <Select.Option value={"21"}>9 PM</Select.Option>
                                    <Select.Option value={"22"}>10 PM</Select.Option>
                                    <Select.Option value={"23"}>11 PM</Select.Option>
                                </Select>
                            </div>
                        </div>

                        <div style={{verticalAlign: "bottom", display: "inline-block", width: "100%"}}>
                            <div style={{marginLeft: '0px', display: "inline-block", width: "50%"}}>
                                <h3 className="titles required" style={{
                                    marginBottom: '2px',
                                    marginLeft: '0px',
                                    fontSize: '110%',
                                    marginTop: "15px"
                                }}>Starts</h3>
                                <DatePicker
                                    placeholder="Start Date"
                                    className="custom-range-picker"
                                    style={{width: "100%"}}
                                    popupStyle={{zIndex: "10000"}}
                                    value={this.state.custom_start_moment}
                                    format={"MMM D, YYYY"}
                                    disabled={this.state.is_edit}
                                    onChange={this.handleStartDateSelect.bind(this)}
                                    disabledDate={this.disabledStartDate.bind(this)}
                                />
                            </div>
                            {
                                (this.state.is_edit || this.state.selected_repeat_period === 4) &&
                                <div style={{marginLeft: '1%', display: "inline-block", width: "49%"}}>
                                    <h3 className={"titles" + (this.state.selected_repeat_period === 4 ? " required" : "")}
                                        style={{
                                            marginBottom: '2px',
                                            marginLeft: '0px',
                                            fontSize: '110%',
                                            marginTop: "15px"
                                        }}>
                                        {
                                            this.state.selected_repeat_period === 4 ? "Due" : "Ends"
                                        }
                                    </h3>
                                    <DatePicker
                                        placeholder={this.state.selected_repeat_period === 4 ? "Due Date" : "End Date"}
                                        className="custom-range-picker"
                                        style={{width: "100%"}}
                                        popupStyle={{zIndex: "10000"}}
                                        value={this.state.custom_end_moment}
                                        format={"MMM D, YYYY"}
                                        onChange={this.handleEndDateSelect.bind(this)}
                                        disabledDate={this.disabledDate.bind(this)}
                                    />
                                </div>
                            }

                        </div>
                        {
                            !this.state.is_edit && this.state.custom_start && now.isBefore(dayjs(this.state.custom_start + " " + this.state.custom_start_time, "YYYY-MM-DD HH:mm:ss")) &&
                            <p className="zero-dark-grey mar-btm-0">
                                {
                                    this.state.selected_repeat_period === 4 ?
                                        `Assignment will send on ${dayjs(this.state.custom_start_moment, 'YYYY/MM/DD').format("MMM D, YYYY")} at ${dayjs(this.state.custom_start_time, "HH:mm:ss").format("h:mm A")}.` :
                                        `First assignment will send on ${dayjs(this.state.custom_start_moment, 'YYYY/MM/DD').format("MMM D, YYYY")} at ${dayjs(this.state.custom_start_time, "HH:mm:ss").format("h:mm A")}.`
                                }
                            </p>
                        }
                        {
                            !this.state.is_edit && this.state.custom_start && now.isAfter(dayjs(this.state.custom_start + " " + this.state.custom_start_time, "YYYY-MM-DD HH:mm:ss")) &&
                            <p className="zero-dark-grey mar-btm-0">
                                {
                                    this.state.selected_repeat_period === 4 ?
                                        "Assignment will send now." :
                                        "First assignment will send now."
                                }
                            </p>
                        }
                        {
                            this.state.custom_start &&
                            <p className="zero-dark-grey">
                                {this.firstDueDate()}
                            </p>
                        }
                        {
                            this.state.range_error &&
                            <small className="error">{this.state.range_error}</small>
                        }
                    </div>
                }
                {
                    !this.state.loading_forms && !this.state.loading_responders && this.state.step === 2 &&
                    <>
                        { this.props.page_type !== "lms" &&
                            <>
                                <h3 className="bulletin-post-headers" style={{margin: "0px", display: "block", marginBottom: '1rem'}}>
                                    Type
                                </h3>
                                <Radio.Group
                                    onChange={(e) => this.setState({schedulerType: e.target.value})}
                                    value={this.state.schedulerType}
                                    className="mar-btm-10"
                                    disabled={this.state.is_edit}
                                >
                                    <Space direction="vertical" size={5}>
                                        <Radio value="regular">Regular - <strong>All</strong> responders need to complete</Radio>
                                        <Radio value="shared">Shared - <strong>Only one</strong> responder needs to complete</Radio>
                                    </Space>
                                </Radio.Group>
                            </>
                        }
                        <RespondersSelector
                            availableResponders={this.state.availableUsers}
                            selectedResponderIds={this.state.selected_users_uuids}
                            assignedToTeam={this.state.allMembersAssigned}
                            hideSaveButton={true}
                            onSelect={this.handleResponderChange}
                            onSelectAll={this.handleAssignToTeamChange}
                            checkboxMinHeight={280}
                        />
                    </>
                }
            </Modal>)
        );

    }

}

export default NewEditScheduleModal;