export default function reducer(state = {
    locations: [],
    incident: {},
    location: {},
    filterQuery: "",
    periodQuery: "&period=all_time",
    pagination: 1,
    pagePosition: 0,
    tab: "incidents",
    incidentTypes: [],
    eventTypes: [],
}, action) {

    switch (action.type) {

        case "update_incidents_locations": {
            return Object.assign({}, state, {
                locations: action.payload
            });
        }

        case "reset_incidents_locations": {
            return Object.assign({}, state, {
                locations: [],
            });
        }

        case "update_current_location": {
            return Object.assign({}, state, {
                location: action.payload
            });
        }

        case "update_current_incident": {
            return Object.assign({}, state, {
                incident: action.payload
            });
        }

        case "updateIncidentsFilterQuery": {
            return Object.assign({}, state, {
                filterQuery: action.payload
            });
        }

        case "updateIncidentsPeriodQuery": {
            return Object.assign({}, state, {
                periodQuery: action.payload
            });
        }

        case "updateIncidentPagination" : {
            return Object.assign({}, state, {
                pagination: action.payload
            });
        }

        case "updateIncidentsPagePosition" : {
            return Object.assign({}, state, {
                pagePosition: action.payload
            });
        }

        case "updateIncidentsTab": {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }

        case "updateIncidentTypes": {
            return Object.assign({}, state, {
                incidentTypes: action.payload
            });
        }

        case "updateEventTypes": {
            return Object.assign({}, state, {
                eventTypes: action.payload
            });
        }

        case "RESET": {
            return Object.assign({}, state, {
                incident: {},
                locations: [],
                location: {}
            });
        }

        default: {
            return state
        }

    }

}
