import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import {
    safe_get, dateFormatterNoTime, scrollToTop, arrayIntersect, isViewer, getScheduleFrequency,
    mainContentContainerClass, safeProfilePic, dateFormatterFromString, dateFormatterWithTime
} from '../../../other/Helper.js';

import {
    get_list_users,
    get_schedule,
    schedule_analytics,
    archive_schedule,
    patchAssignment,
    delete_assignment,
    update_schedule, get_schedule_is_locked
} from '../../../api/zero-api.js';

import Skeleton from 'react-loading-skeleton';
import {IoIosMore as MoreIcon} from 'react-icons/io';

import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    EditOutlined,
    ControlOutlined,
    UserAddOutlined
} from '@ant-design/icons';

import {Popover, Menu, Dropdown, Pagination, Spin, Space, Tag} from 'antd'

import NotificationAlert from '../../../other/NotificationAlert.js';

import UserPopoverCard from '../../Shared/UserPopoverCard';
import RespondersPopover from '../../Shared/RespondersPopover';

import NewEditScheduleModal from './NewEditScheduleModal';
import ChangeScheduleStatusModal from './ChangeScheduleStatusModal';
import ConfirmModal from '../../Shared/ConfirmModal.js';
import RespondersModal from './RespondersModal.js';
import ReAssignModal from './ReAssignModal.js';
import moment from "moment";
import ScheduleReminderModal from "./ScheduleReminderModal";
import {WebSocketContext} from "../../../other/WebSockets";

class ScheduleAnalytics extends Component {
    static contextType = WebSocketContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.datesPerPage = 5;

        this.state = {
            page: 1,
            antdPage: 1, // Want AntD component to show as last page, but will actually be on page 1
            schedule: {},
            assigned_to_team: false,
            repeat_period: -1,
            responders: [],
            users: Array(5).fill(0),
            dates: [],
            tableWidth: 870,
            tableHeight: 500,
            loading_schedule: true,
            loading_table_data: true,
            loading_users: true,
        };

        this.lockPollTimeout = null
        this.lockPollAttempt = 0;

        this.getSchedule = this.getSchedule.bind(this);
        this.getScheduleAnalytics = this.getScheduleAnalytics.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
        this.context.removeMessageHandler(`scheduler_updated:${this.props.match.params.schedule_uuid}`, this.handleUpdateMessage);
        if (this.lockPollTimeout) {
            clearTimeout(this.lockPollTimeout);
            this.lockPollTimeout = null;
        }
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.context.addMessageHandler(`scheduler_updated:${this.props.match.params.schedule_uuid}`, this.handleUpdateMessage);
        scrollToTop("page-head");
        this.getAllData();
    }

    handleUpdateMessage = (message) => {
        this.refreshTimeout = setTimeout(this.getAllData, 250);
    }

    getAllData = () => {
        this.getSchedule();
        this.getScheduleAnalytics();
    }

    getLockStatus = () => {
        let retry = false;

        get_schedule_is_locked(this.props.match.params.schedule_uuid)
            .then(response => response.json()).then(data => {
            if (data.is_locked) {
                retry = true;
            } else {
                this.getAllData();
            }
        }).catch(err => {
            retry = true;
        }).finally(() => {
            if (retry) {
                if (++this.lockPollAttempt > 15) {
                    return;
                }

                const timeout = 5000 + 250 * this.lockPollAttempt; // slight easing function
                this.lockPollTimeout = setTimeout(this.getLockStatus, timeout);
            }
        })
    }

    getSchedule() {
        var self = this;
        get_schedule(this.props.match.params.schedule_uuid).then(function (success) {
            success.json().then(success => {
                if (self._isMounted) {

                    let schedule = safe_get(success, "scheduler", {});
                    let responders = safe_get(schedule, "subscribers", []).sort((a, b) => {
                        let a_name = `${a.first_name} ${a.last_name}`.toLowerCase()
                        let b_name = `${b.first_name} ${b.last_name}`.toLowerCase()

                        return a_name > b_name ? 1 : -1
                    });

                    self.setState({
                        loading_schedule: false,
                        schedule: schedule,
                        responders: responders,
                        assigned_to_team: safe_get(schedule, "assigned_to_team", false),
                        repeat_period: safe_get(schedule, "repeat_period", -1)
                    }, () => {
                        const isLocked = safe_get(schedule, 'is_locked', false);
                        if (isLocked) {
                            if (!self.context.socketIsOpen()) {
                                self.lockPollAttempt = 1;

                                if (self.lockPollTimeout) {
                                    clearTimeout(self.lockPollTimeout);
                                }

                                self.lockPollTimeout = setTimeout(self.getLockStatus, 5000);
                            }
                        } else {
                            if (self.lockPollTimeout) {
                                clearTimeout(self.lockPollTimeout);
                            }
                        }
                        self.getScheduleUsers();
                    });
                }
            });
        }, function (error) {
            console.log(error);
        });
    }

    getScheduleAnalytics() {
        var self = this;

        this.setState({
            loading_table_data: true
        });

        schedule_analytics(this.props.match.params.schedule_uuid, `?page=${this.state.page}&per_page=${this.datesPerPage}`).then(function (success) {
            success.json().then(success => {
                let data = safe_get(success, "data", {})
                let dates = safe_get(data, "dates", []).reverse();

                let startPoint = dates.length - 5
                let endPoint = dates.length;

                if (startPoint < 0) {
                    startPoint = 0;
                }

                const pageCount = Math.ceil(data.total_dates / self.datesPerPage);

                if (self._isMounted) {
                    self.setState({
                        loading_table_data: false,
                        users: safe_get(data, 'users_data', []),
                        allDates: dates,
                        dates: dates.slice((startPoint), endPoint),
                        dateStartPoint: startPoint,
                        dateEndPoint: endPoint,
                        total_dates: data.total_dates,
                        pageCount,
                        antdPage: pageCount - self.state.page + 1
                    });
                }

            });
        });
    }

    onChangePage = (antdPage) => {
        this.setState({
            page: this.state.pageCount - antdPage + 1,
            antdPage
        }, () => {
            this.getScheduleAnalytics()
        })
    }

    getScheduleUsers = () => {
        let self = this
        get_list_users("?status=active").then(function (success) {
            success.json().then(success => {
                var users = safe_get(success, "users", []);

                users = users.filter(function (user) {
                    let user_teams = user.teams.map(team => team.uuid)
                    let schedule_teams = safe_get(self.state, "schedule.teams", []).map(team => team.team_uuid);
                    let intersect = arrayIntersect(schedule_teams, user_teams)
                    return user.status === "active" && !isViewer(user) && intersect.length > 0

                });

                self.setState({
                    available_users: users,
                    loading_users: false,
                });

            });
        }, function (error) {
            console.log(error)
        });
    }

    previousDates = () => {
        let {allDates, dateStartPoint, dateEndPoint, page, loading_table_data} = this.state;
        if (dateStartPoint - 1 >= 0) {
            this.setState({
                dates: allDates.slice(dateStartPoint - 1, dateEndPoint - 1),
                dateStartPoint: dateStartPoint - 1,
                dateEndPoint: dateEndPoint - 1
            });
        } else if (!loading_table_data) {
            this.setState({
                page: page + 1,
                loading_table_data: true
            }, () => {
                this.getScheduleAnalytics()
            })
        }
    }

    nextDates = () => {
        let {allDates, dateStartPoint, dateEndPoint, page, loading_table_data} = this.state;
        console.log(allDates, dateStartPoint, dateEndPoint)
        if (dateEndPoint + 1 <= allDates.length) {
            this.setState({
                dates: allDates.slice(dateStartPoint + 1, dateEndPoint + 1),
                dateStartPoint: dateStartPoint + 1,
                dateEndPoint: dateEndPoint + 1
            });
        } else if (dateEndPoint === allDates.length && this.state.page > 1 && !loading_table_data) {
            this.setState({
                page: page - 1,
                loading_table_data: true
            }, () => {
                this.getScheduleAnalytics()
            })
        }
    }

    loadingFormatter(data) {
        if (data.dataKey.includes("user")) {
            return (
                <div className="media">
                    <div className="media-left profile-img">
                        <Skeleton circle width={40} height={40}/>
                    </div>
                    <div className="media-body account-card">
                        <p className="text-md text-semibold profile truncate name link-hover zero-blue"
                           style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                            <Skeleton width={100}/>
                        </p>
                        <p className="text-sm profile truncate email zero-dark-grey"
                           style={{display: 'block', fontSize: '.9em'}}>
                            <Skeleton width={120}/>
                        </p>
                    </div>
                </div>
            )
        } else {
            return (
                <span style={{paddingLeft: "35px"}}>
                    <Skeleton circle width={13} height={13}/>
                </span>
            )
        }

    }

    userFormatter(data) {
        var user = data.user;
        var name = safe_get(user, "first_name", "") + " " + safe_get(user, "last_name", "");
        var email = safe_get(user, "email", "");
        var user_uuid = safe_get(user, "user_uuid", "undefined");

        return (
            <div className="media">
                <div className="media-left profile-img hover-cursor">
                    <UserPopoverCard user={user}>
                        {safeProfilePic(user, "img-sm img-circle top-posters", "top-posters")}
                    </UserPopoverCard>
                </div>
                <div className="media-body account-card" style={{verticalAlign: (email ? "top" : "middle")}}>
                    <UserPopoverCard user={user}>
                        <p className="text-md text-semibold profile truncate name hover-cursor zero-blue"
                           style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                            {name}
                        </p>
                        <br/>
                        <p className="text-sm profile truncate email zero-dark-grey hover-cursor"
                           style={{display: 'inline-block', fontSize: '.9em'}}>{email}</p>
                    </UserPopoverCard>
                </div>
            </div>
        )
    }

    // completionFormatter = (data, columnIndex) => {
    //     let index = columnIndex
    //     var dates = data.dates

    //     var keys = Object.keys(dates);
    //     keys = keys.slice((this.state.dateStartPoint), this.state.dateEndPoint)

    //     let date = keys[index]
    //     let assignment = dates[date]

    //     return <AssignmentIndicator assignment={assignment} user={data.user} handleExcuseClick={this.handleExcuseClick} handleDeleteClick={this.handleDeleteClick} />
    // }


    completionFormatter = (data, assignment_date) => {
        var dates = data.dates
        let assignment = dates[assignment_date]
        return (
            <AssignmentIndicator
                org_uuid={this.props.org_uuid}
                form_type={safe_get(this.state.schedule, "form.form_type", 0)}
                assignment={assignment}
                user={data.user}
                handleReAssignClick={this.handleReAssignClick}
                handleExcuseClick={this.handleExcuseClick}
                handleDeleteClick={this.handleDeleteClick}
            />
        );
    }

    noDataRenderer() {
        return (
            <div className="mar-top">
                <h3 className="subtitle header">No assignments sent yet.</h3>
            </div>
        )
    }

    handleReAssignClick = (assignmentId, user) => {
        this.setState({
            reAssignUser: user,
            reAssignId: assignmentId,
            showReAssignModal: true
        })
    }

    handleExcuseClick = (assignmentId, excused) => {
        let self = this;
        this.setState({loading_table_data: true, loading_schedule: true});
        const body = JSON.stringify({
            excused
        })
        patchAssignment(assignmentId, body).then(success => {
            success.json().then(success => {
                self.getSchedule();
                self.getScheduleAnalytics();
            })
        }, error => {
            NotificationAlert("error", "", "Could not excuse assignment.");
            self.setState({loading_table_data: false});
        })
    }

    handleDeleteClick = (assignmentId) => {
        this.setState({showDeleteAssignmentConfirmationModal: true, assignmentIdToDelete: assignmentId});
    }

    deleteAssignment = () => {
        let self = this
        delete_assignment(this.state.assignmentIdToDelete).then(success => {
            self.getSchedule();
            self.getScheduleAnalytics();
            self.setState({showDeleteAssignmentConfirmationModal: false});
            NotificationAlert("error", "", "Assignment deleted.");
        }, _error => {
            NotificationAlert("error", "", "Could not delete assignment.");
            self.setState({loading_table_data: false, showDeleteAssignmentConfirmationModal: false});
        });
    }

    handleMenuClick = (key) => {
        if (key === "edit") {
            this.setState({showEditScheduleModal: true});
        } else if (key === "status") {
            this.setState({showChangeStatusModal: true});
        } else if (key === "delete") {
            this.setState({showDeleteScheduleModal: true});
        }
    }

    deleteSchedule = () => {
        var self = this;
        archive_schedule(this.state.schedule.scheduler_uuid).then(function (success) {
            self.setState({showDeleteScheduleModal: false});
            NotificationAlert("success", "", "Schedule archived.")
        }, function (error) {
            NotificationAlert("error", "", "Unable to archive schedule.")
        });
    }

    updateEmailReminderSettings = (enabled, daysBeforeReminder) => {
        const self = this;
        const body = JSON.stringify({
            name: this.state.schedule.name,
            start_date: this.state.schedule.start_date,
            end_date: this.state.schedule.end_date,
            enabled: this.state.schedule.enabled,
            repeat_period: this.state.schedule.repeat_period,
            assigned_to_team: this.state.schedule.assigned_to_team,
            custom_start_time: this.state.schedule.custom_start_time,
            custom_start_timezone: this.state.schedule.custom_start_timezone,
            send_reminders: enabled,
            days_before_reminder: daysBeforeReminder
        });
        update_schedule(this.state.schedule.scheduler_uuid, body).then(function (success) {
            self.setState({showEmailRemindersModal: false}, () => self.getSchedule());
            NotificationAlert("success", "", "Email reminder settings updated.")
        }, function (error) {
            self.setState({showEmailRemindersModal: false});
            NotificationAlert("error", "", "Unable to update email reminder settings.")
        });
    }

    updateResponders = (responder_uuids, assigned_to_team) => {
        var self = this;

        this.setState({assigning_responders: true});

        const body = JSON.stringify({
            name: this.state.schedule.name,
            start_date: this.state.schedule.start_date,
            end_date: this.state.schedule.end_date,
            enabled: this.state.schedule.enabled,
            repeat_period: this.state.schedule.repeat_period,
            user_uuids: responder_uuids,
            assigned_to_team: assigned_to_team,
            custom_start_time: this.state.schedule.custom_start_time,
            custom_start_timezone: this.state.schedule.custom_start_timezone,
            send_reminders: this.state.schedule.send_reminders,
            days_before_reminder: this.state.schedule.days_before_reminder
        });

        update_schedule(this.state.schedule.scheduler_uuid, body).then(function (success) {
            self.getAllData();
        }, function (error) {

        });
    }

    frequencyText() {
        let custom_start_timestamp = safe_get(this.state.schedule, 'custom_start_utc_timestamp', null)
        if (!custom_start_timestamp) {
            let dt = new Date();
            custom_start_timestamp = dt.setUTCHours(10, 0, 0, 0) / 1000;
        }

        let time = moment.unix(custom_start_timestamp).format("hA");
        let frequency = getScheduleFrequency(this.state.repeat_period)
        return `${frequency} at ${time}`
    }

    renderTeamsList = (teams) => {
        return (
            <div style={{maxHeight: "150px", overflow: "auto"}}>
                {
                    teams.map((team, index) => (
                        <div key={index}>
                            {team}
                        </div>
                    ))
                }
            </div>
        )
    }

    render() {
        var columnClass = "col-xs-6 col-md-3";

        const menu = {
            onClick: e => this.handleMenuClick(e.key),
            items: [
                {
                    key: "edit",
                    icon: <EditOutlined />,
                    label: "Edit Schedule"
                },
                {
                    key: "status",
                    icon: <ControlOutlined />,
                    label: "Change Status"
                }
            ]
        }

        const days_before_reminder = safe_get(this.state.schedule, 'days_before_reminder', 1);

        let team_names = safe_get(this.state.schedule, "teams", []).map(e => e.name).sort(function (a, b) {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1
        });
        const schedule_locked = safe_get(this.state.schedule, 'is_locked', false);

        return (
            <div className={mainContentContainerClass()}>
                {
                    this.state.showEditScheduleModal &&
                    <NewEditScheduleModal
                        show={this.state.showEditScheduleModal}
                        cancel={() => {
                            this.setState({showEditScheduleModal: false});
                        }}
                        updateSchedules={this.getAllData}
                        schedule={this.state.schedule}
                        user={this.props.user}
                        user_teams={this.props.teams}
                        page_type={this.state.schedule.form.form_type === 3 ? "lms" : "regular"}
                    />
                }
                {
                    this.state.showChangeStatusModal &&
                    <ChangeScheduleStatusModal
                        show={this.state.showChangeStatusModal}
                        cancel={() => {
                            this.setState({showChangeStatusModal: false});
                        }}
                        updateSchedules={this.getSchedule}
                        schedule={this.state.schedule}
                    />
                }
                {
                    this.state.showDeleteScheduleModal &&
                    <ConfirmModal
                        show={this.state.showDeleteScheduleModal}
                        cancel={() => {
                            this.setState({showDeleteScheduleModal: false});
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to archive this schedule?"}
                        confirmButtonName={"Archive schedule"}
                        confirm={this.deleteSchedule}
                    />
                }
                {
                    this.state.showRespondersModal &&
                    <RespondersModal
                        show={this.state.showRespondersModal}
                        cancel={() => {
                            this.setState({showRespondersModal: false});
                        }}
                        responders={safe_get(this.state, "responders", [])}
                        schedule={this.state.schedule}
                        updateSchedules={() => {
                            this.getSchedule();
                            this.getScheduleAnalytics();
                        }}
                    />
                }
                {
                    this.state.showReAssignModal &&
                    <ReAssignModal
                        show={this.state.showReAssignModal}
                        cancel={() => {
                            this.setState({showReAssignModal: false});
                        }}
                        assignmentId={this.state.reAssignId}
                        reAssignUser={this.state.reAssignUser}
                        responders={safe_get(this.state, "responders", [])}
                        schedule={this.state.schedule}
                        refresh={() => {
                            this.getSchedule();
                            this.getScheduleAnalytics();
                        }}
                    />
                }
                {
                    this.state.showEmailRemindersModal &&
                    <ScheduleReminderModal
                        confirm={this.updateEmailReminderSettings}
                        cancel={() => this.setState({showEmailRemindersModal: false})}
                        show={this.state.showEmailRemindersModal}
                        schedule={this.state.schedule}
                    />
                }
                {
                    this.state.showDeleteAssignmentConfirmationModal &&
                    <ConfirmModal
                        show={this.state.showDeleteAssignmentConfirmationModal}
                        cancel={() => {
                            this.setState({showDeleteAssignmentConfirmationModal: false});
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this assignment?"}
                        confirmButtonName={"Delete assignment"}
                        confirm={this.deleteAssignment}
                    />
                }

                <div id="page-head" className="no-padding-mobile" style={{padding: "0px"}}>

                </div>

                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{padding: "0 10px"}}>
                        <div className="panel thin-border">
                            <div className="panel-heading analytics">
                                <h2 className="panel-title analytics"
                                    style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                    Schedule Details
                                </h2>
                                <div className="media-right more" style={{
                                    marginTop: '10px',
                                    marginRight: "10px",
                                    color: "grey",
                                    float: "right",
                                    lineHeight: 0, 
                                    height: 'fit-content'
                                }}>
                                    <Dropdown menu={menu} trigger={['click']}>
                                        <button className="ButtonLink ant-dropdown-link">
                                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                                        </button>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="panel-body">
                                <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding analytics-schedule-details-flex"
                                >
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Name: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={100}/> :
                                                    safe_get(this.state.schedule, "name", "")
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        {safe_get(this.state.schedule, "form.form_type", 0) === 3 ? "Course" : "Template"}: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={120}/> :
                                                    safe_get(this.state.schedule, "form.name", "")
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Team: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={120}/> :
                                                    <span>
                                                        <Popover content={this.renderTeamsList(team_names)}
                                                                 placement="bottom" overlayStyle={{maxWidth: "220px"}}>
                                                            {team_names[0]} {team_names.length - 1 > 0 && "and " + (team_names.length - 1) + " more..."}
                                                        </Popover>
                                                    </span>
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Type: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={70}/> :
                                                    this.state.schedule.type === 'regular' ? 'Regular' : 'Shared'
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Frequency: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={70}/> :
                                                    this.frequencyText()
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Start Date: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={90}/> :
                                                    dateFormatterFromString(this.state.schedule.start_date)
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        End Date: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={90}/> :
                                                    dateFormatterFromString(this.state.schedule.end_date)
                                            }
                                        </span>
                                    </p>
                                </div>
                                <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding analytics-schedule-details-flex"
                                >
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Created on: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={90}/> :
                                                    dateFormatterWithTime(this.state.schedule.created_at)
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Created by: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={120}/> :
                                                    safe_get(this.state.schedule, "created_by.first_name", "")} {safe_get(this.state.schedule, "created_by.last_name", "")
                                        }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Last edited on: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={90}/> :
                                                    dateFormatterWithTime(this.state.schedule.edited_at)
                                            }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Last edited by: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={120}/> :
                                                    safe_get(this.state.schedule, "edited_by.first_name", "")} {safe_get(this.state.schedule, "edited_by.last_name", "")
                                        }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Status: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_schedule ?
                                                    <Skeleton width={120}/> :
                                                    this.state.schedule.enabled ?
                                                        <Tag color="green" style={{borderColor: "#52c41a"}}>Active</Tag>
                                                    :
                                                        <Tag color="gold">Paused</Tag>
                                        }
                                        </span>
                                    </p>
                                    <p className="zero-blue" style={{fontWeight: "600", display: "inline"}}>
                                        Email Reminders: &nbsp;
                                        {
                                            this.state.loading_schedule ?
                                                <Skeleton width={75}/> :
                                                this.state.repeat_period < 2 ?
                                                    <span className="zero-dark-grey"
                                                        style={{fontWeight: "400"}}>N/A</span> :
                                                    <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                                        {safe_get(this.state.schedule, "send_reminders", false) ? `On - ${days_before_reminder} ${days_before_reminder > 1 ? 'days' : 'day'} before due` : 'Off'}
                                                        <button className="ButtonLink btn-link" style={{marginLeft: 8}}
                                                                onClick={() => this.setState({showEmailRemindersModal: true})}>Edit</button>
                                                    </span>
                                        }
                                    </p>

                                    <div className="zero-blue"
                                         style={{fontWeight: "600", alignItems: "center", display: "flex"}}>
                                        Assignees: &nbsp;
                                        {
                                            (this.state.loading_users || this.state.loading_schedule) &&
                                            [...Array(3)].map((e, index) => (
                                                <span key={index} style={{marginRight: "5px", marginTop: "5px"}}>
                                                    <Skeleton key={index} circle={true} width={26} height={26} inline />
                                                </span>
                                            ))
                                        }
                                        {
                                            !this.state.loading_users && !this.state.loading_schedule && !this.state.assigned_to_team &&
                                            safe_get(this.state, "responders", []).slice(0, 5).map((user, index) => (
                                                <UserPopoverCard key={index} user={user} showRemoveScheduleResponder
                                                                 responders={this.state.responders}
                                                                 schedule={this.state.schedule}
                                                                 refresh={this.getAllData}>
                                                <span style={{cursor: "pointer"}}>
                                                  {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                                      marginRight: "5px",
                                                      display: "inline-block"
                                                  })}
                                                </span>
                                                </UserPopoverCard>
                                            ))
                                        }
                                        {
                                            !this.state.loading_users && !this.state.loading_schedule && !this.state.assigned_to_team &&
                                            safe_get(this.state, "responders", []).length > 5 &&
                                            <span>
                                              <RespondersPopover
                                                  schedule={this.state.schedule}
                                                  selected_responders={this.state.responders}
                                                  updateResponders={this.updateResponders}
                                              >
                                                <div className={"default-avatar-circle bulletin hover-cursor"} style={{
                                                    marginRight: "5px",
                                                    display: "inline-block",
                                                    border: "1px solid #505050"
                                                }}>
                                                    <p className="initials"
                                                       style={{color: "#505050"}}>+{safe_get(this.state, "responders", []).length - 5}</p>
                                                </div> 
                                              </RespondersPopover>
                                            </span>
                                        }
                                        {
                                            !this.state.loading_users && !this.state.loading_schedule && !this.state.assigned_to_team && safe_get(this.state, "responders", []).length <= 5 &&
                                            <RespondersPopover
                                                schedule={this.state.schedule}
                                                selected_responders={this.state.responders}
                                                updateResponders={this.updateResponders}
                                            >
                                                <button className="ButtonLink post-details-content" style={{
                                                    verticalAlign: "bottom",
                                                    height: "26px",
                                                    width: "26px"
                                                }}>
                                                    <UserAddOutlined style={{fontSize: "19px"}}/>
                                                </button>
                                            </RespondersPopover>
                                        }
                                        {
                                            !this.state.loading_users && !this.state.loading_schedule && this.state.assigned_to_team &&
                                            <RespondersPopover
                                                schedule={this.state.schedule}
                                                selected_responders={this.state.responders}
                                                updateResponders={this.updateResponders}
                                            >
                                            <span className="zero-dark-grey link-hover" style={{fontWeight: "400"}}>
                                              All Team Members ({safe_get(this.state, "available_users", []).length})                                        
                                            </span>
                                            </RespondersPopover>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{margin: "0px"}}>
                        <div className="tab-base" style={{marginBottom: "0px", padding: "0px"}}>

                            <div className="panel thin-border" style={{margin: "0px", marginBottom: "10px"}}>
                                <div className="panel" style={{padding: "10px", margin: "0px"}}>
                                    <div className="row justify-content-start">

                                        <div className={columnClass}>
                                            <p className="text-sm text-bold zero-blue text-center"
                                               style={{margin: "5px"}}>
                                                Total Assignments
                                            </p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                                   style={{fontSize: "3em"}}>
                                                    {
                                                        this.state.loading_schedule ?
                                                            <Skeleton width={60} height={58}/> :
                                                            safe_get(this.state.schedule, "assigns_count", "N/A")
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        <div className={columnClass}>
                                            <p className="text-sm text-bold zero-blue text-center"
                                               style={{margin: "5px"}}>Completed Assignments</p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                   style={{fontSize: "3em"}}>
                                                    {
                                                        this.state.loading_schedule ?
                                                            <Skeleton width={60} height={58}/> :
                                                            safe_get(this.state.schedule, "assigns_completed", "N/A")
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        <div className={columnClass}>
                                            <p className="text-sm text-bold zero-blue text-center"
                                               style={{margin: "5px"}}>Open Assignments</p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                   style={{fontSize: "3em"}}>
                                                    {
                                                        this.state.loading_schedule ?
                                                            <Skeleton width={60} height={58}/> :
                                                            safe_get(this.state.schedule, "assigns_open", "N/A")
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        <div className={columnClass}>
                                            <p className="text-sm text-bold zero-blue text-center"
                                               style={{margin: "5px"}}>Missed Assignments</p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                   style={{fontSize: "3em"}}>
                                                    {
                                                        this.state.loading_schedule ?
                                                            <Skeleton width={60} height={58}/> :
                                                            safe_get(this.state.schedule, "assigns_missed", "N/A")
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className={columnClass}>
                                            <p className="text-sm text-bold zero-blue text-center" style={{ margin: "5px" }}>Responders</p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0" style={{ fontSize: "3em" }}>
                                                    {
                                                        this.state.loading_schedule ?
                                                        <Skeleton width={60} height={58} /> :
                                                        safe_get(this.state.schedule, "subscribers_count", "")
                                                    }
                                                </p>
                                            </div>
                                        </div> */}


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="panel thin-border">
                        <div style={{marginRight: "5px", marginTop: "5px", textAlign: 'right'}}>
                            {/* <button className="btn" onClick={this.previousDates}>Previous</button>
                            <button className="btn" onClick={this.nextDates}>Next</button> */}
                            <Pagination
                                size={"small"}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                                pageSize={this.datesPerPage}
                                total={this.state.total_dates}
                                current={this.state.antdPage}
                                onChange={this.onChangePage}
                            />
                        </div>
                        {schedule_locked &&
                            <div style={{fontWeight: 600, color: "#03a9f4", padding: '0 8px'}}>
                                <Spin size="small"/>&nbsp;
                                Schedule updating...this table will refresh automatically when finished.
                            </div>
                        }
                        <div className="table-responsive" style={{border: "none"}}>
                            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                <thead style={{color: "#1D2A35"}}>
                                <tr>
                                    <th className="zero-blue">{schedule_locked ? '' : 'Name'}</th>
                                    {
                                        this.state.dates.map((date, index) => (
                                            <th className="zero-blue" style={{width: "115px"}} key={index}>{date}</th>
                                        ))
                                    }
                                </tr>
                                </thead>
                                {
                                    (this.state.loading_table_data || schedule_locked) &&
                                    <tbody>
                                    {
                                        [...Array(8)].map((submission, index) => (
                                            <tr key={index} className="tr-hover">
                                                <td style={{maxWidth: "270px"}}>
                                                    <div className="media">
                                                        <div className="media-left profile-img">
                                                            <Skeleton circle width={40} height={40}/>
                                                        </div>
                                                        <div className="media-body account-card">
                                                            <p className="text-md text-semibold profile truncate name link-hover zero-blue"
                                                               style={{
                                                                   display: 'inline-block',
                                                                   marginBottom: '0px',
                                                                   marginLeft: '2px'
                                                               }}>
                                                                <Skeleton width={100}/>
                                                            </p>
                                                            <p className="text-sm profile truncate email zero-dark-grey"
                                                               style={{display: 'block', fontSize: '.9em'}}>
                                                                <Skeleton width={120}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                {
                                                    [...Array(5)].map((date, index2) => (
                                                        <td key={index2} style={{width: "115px"}}>
                                                        <span style={{paddingLeft: "35px"}}>
                                                            <Skeleton circle width={13} height={13}/>
                                                        </span>
                                                        </td>
                                                    ))
                                                }


                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.state.loading_table_data && !schedule_locked && this.state.users.length !== 0 &&
                                    <tbody>
                                    {
                                        this.state.users.map((user, index) => (
                                            <tr key={index}>
                                                <td style={{maxWidth: "270px"}}>{this.userFormatter(user)}</td>
                                                {
                                                    this.state.dates.map((date, index2) => (
                                                        <td key={index2}
                                                            style={{width: "115px"}}>{this.completionFormatter(user, date)}</td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.state.loading_table_data && !schedule_locked && this.state.users.length === 0 &&
                                    <tbody>
                                    <tr>
                                        <td className="react-bs-table-no-data zero-dark-grey" colSpan="5">
                                            No assignments sent yet.
                                        </td>
                                    </tr>
                                    </tbody>
                                }

                            </table>
                            {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                              <Pagination
                                size={"small"}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                                pageSize={15}
                                total={this.state.total_dates}
                                current={this.state.page}
                                onChange={this.onChangePage}
                              />
                            </div> */}

                        </div>
                    </div>

                </div>


            </div>

        );
    }

}

class AssignmentIndicator extends Component {
    popoverContent() {
        const {assignment, user, form_type} = this.props;

        const hrStyle = {
            marginTop: 10,
            marginBottom: 10
        };

        const bold = {
            fontWeight: 600
        };

        let due_date_timestamp = safe_get(assignment, 'due_date_timestamp', null)
        if (!due_date_timestamp) {
            let dt = new Date();
            due_date_timestamp = dt.setUTCHours(23, 59, 0, 0) / 1000;
        }

        let assignment_due_date = `${moment.unix(due_date_timestamp).format("MMM D, YYYY")} at ${moment.unix(due_date_timestamp).format("hh:mm A")}`;

        let form_type_name = form_type === 3 ? 'courses' : 'forms';

        return (
            <div style={{padding: 8}}>
                <span className="zero-blue"
                      style={bold}>Assigned on: </span>{dateFormatterWithTime(assignment.assigned_at)}
                <hr style={hrStyle}/>
                <span className="zero-blue" style={bold}>Due on: </span>{assignment_due_date}
                <hr style={hrStyle}/>
                {
                    assignment.state !== 'excused' && (
                        <>
                            <span className="zero-blue"
                                  style={bold}>Completed on: </span>{assignment.state === 'completed' ? dateFormatterWithTime(assignment.completed_at) : 'N/A'}
                            <hr style={hrStyle}/>
                            <span className="zero-blue"
                                  style={bold}>Submitted by: </span>{assignment.state === 'completed' ? user.full_name : 'N/A'}
                            {
                                assignment.state === 'completed' && (
                                    <>
                                        <hr style={hrStyle}/>
                                        <Link
                                            to={`/${this.props.org_uuid}/home/team/${assignment.team_uuid}/${form_type_name}/submission_view/${assignment.submission_uuid}`}
                                            className="btn-link">
                                            View Submission
                                        </Link>
                                    </>
                                )
                            }
                            {
                                assignment.state !== 'completed' && (
                                    <>
                                        <hr style={hrStyle}/>
                                        <button className="btn-link"
                                                style={{padding: 0}}
                                                onClick={() => this.props.handleExcuseClick(assignment.assignment_uuid, true)}>
                                            Excuse this assignment
                                        </button>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    assignment.state === 'excused' && (
                        <>
                            <span className="zero-blue"
                                  style={bold}>Excused on: </span>{assignment.excused_at ? dateFormatterWithTime(assignment.excused_at) : 'N/A'}
                            <hr style={hrStyle}/>
                            <span className="zero-blue"
                                  style={bold}>Excused by: </span>{assignment.excused_by ? assignment.excused_by.full_name : 'N/A'}
                            <hr style={hrStyle}/>
                            <button className="btn-link"
                                    style={{padding: 0}}
                                    onClick={() => this.props.handleExcuseClick(assignment.assignment_uuid, false)}>
                                Require this assignment
                            </button>
                        </>
                    )
                }
                {
                    (assignment.state === 'assigned' && assignment.state !== 'excused' && assignment.state !== 'completed' && assignment.state !== 'missed') && (
                        <>
                            <hr style={hrStyle}/>
                            <button className="btn-link"
                                    style={{padding: 0}}
                                    onClick={() => {
                                        this.props.handleReAssignClick(assignment.assignment_uuid, user)
                                    }}>
                                Reassign this assignment
                            </button>
                        </>
                    )
                }
                {
                    assignment.state === 'assigned' &&
                    <>
                        <hr style={hrStyle}/>
                        <button className="btn-link zero-delete-red"
                                style={{padding: 0}}
                                onClick={() => this.props.handleDeleteClick(assignment.assignment_uuid, true)}>
                            Delete this assignment
                        </button>
                    </>
                }

            </div>
        )
    }

    renderIcon() {
        let grey = "#B5B5B5";
        let red = "#f56946";
        let green = "#52c41a";
        let yellow = "#f3b44b"

        if (this.props.assignment.shared_submission_uuid) {
            return <CheckCircleTwoTone twoToneColor={grey} />
        }

        switch (this.props.assignment.state) {
            case 'completed':
                return <CheckCircleTwoTone twoToneColor={green}/>
            case 'missed':
                return <CloseCircleTwoTone twoToneColor={red}/>
            case 'assigned':
                return <CloseCircleTwoTone twoToneColor={grey}/>
            case 'excused':
                return <CloseCircleTwoTone twoToneColor={yellow}/>
            default:
                return null;
        }
    }

    render() {
        if (this.props.assignment.state === 'unassigned') {
            return null;
        }
        return (
            <div style={{paddingLeft: 38}}>
                { this.props.assignment.shared_submission_uuid && this.renderIcon() }
                { !this.props.assignment.shared_submission_uuid &&
                    <Popover content={this.popoverContent()}>
                        {this.renderIcon()}
                    </Popover>
                }
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", []),
    }
}

export default withRouter(connect(mapStateToProps)(ScheduleAnalytics));
