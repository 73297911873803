import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import PostFormAttachments from './PostFormAttachments';
import PostFormBody from './PostFormBody';
import PostFormCategory from './PostFormCategory';
import PostFormControls from './PostFormControls';
import PostFormCustomField from './PostFormCustomField';
import PostFormDueDate from './PostFormDueDate';
import PostFormHeader from './PostFormHeader';
import PostFormLocation from './PostFormLocation';
import PostFormResponders from './PostFormResponders';
import PostFormRiskLevel from './PostFormRiskLevel';
import PostFormStatus from './PostFormStatus';
import PostFormSubscribers from './PostFormSubscribers';
import PostFormTag from './PostFormTag';
import PostFormTitle from './PostFormTitle';
import { actions, getCategoryError, getSubStatusError, getTagError, initialState, reducer } from './reducer';

import {
    copy_post,
    notifyError,
    update_submission,
} from 'api/zero-api.js';
import ConfirmModal from 'components/Shared/ConfirmModal';
import { blurActiveElement, fieldIsNotEmpty, isRestrictedTeamUser, mapErrorMessages, safe_get } from 'other/Helper';
import NotificationAlert from 'other/NotificationAlert';
import BulletinNewPostSkeleton from '../BulletinNewPostSkeleton';
import { useZeroContext } from 'components/ZeroContext';
import { FileUploadContextManager } from 'components/Shared/FileUpload';
import { PostEvents } from 'services/postService';
import PostFormSyncError from './PostFormSyncError';
import PostFormTeam from './PostFormTeam';
import PostFormEmbeddedForm from './PostFormEmbeddedForm';
import { submissionHasBlankRequiredFields } from 'other/forms';
import { FormType } from 'other/Constants';

const MAX_POST_TITLE_LENGTH = 100;

/**
 * 
 * @param {function} dispatch 
 * @param {PostService} postService 
 */
function loadInitialData(dispatch, postService) {
    postService.getPostOptions()
    .then(data => {
        dispatch([actions.STATUSES_LOADED, safe_get(data, "sub_statuses", [])])
        dispatch([actions.STATUSES_2_LOADED, safe_get(data, "sub_statuses_2", [])])
        dispatch([actions.TAGS_LOADED, safe_get(data, "tags", [])])
        dispatch([actions.CATEGORIES_LOADED, safe_get(data, "categories", [])])
        dispatch([actions.RISK_LEVELS_LOADED, safe_get(data, "severity_levels", [])]);
        dispatch([actions.LOCATIONS_LOADED, safe_get(data, "locations", [])]);
    })
    .catch(err => {
        console.error('Could not get post options: %o', err);
        dispatch([actions.STATUSES_LOADED, []]);
        dispatch([actions.STATUSES_2_LOADED, []]);
        dispatch([actions.TAGS_LOADED, []]);
        dispatch([actions.CATEGORIES_LOADED, []]);
        dispatch([actions.RISK_LEVELS_LOADED, []]);
        dispatch([actions.LOCATIONS_LOADED, []]);
    });
}


async function loadRespondersAndSubscribers(dispatch, postService, teamUuid) {
    dispatch([actions.LOADING_RESPONDERS_AND_SUBSCRIBERS]);

    try {
        const {
            availableSubscribers,
            availableResponders,
        } = await postService.getAvailableSubscribersAndResponders(teamUuid);

        dispatch([actions.SUBSCRIBERS_LOADED, availableSubscribers]);
        dispatch([actions.RESPONDERS_LOADED, availableResponders]);
        return {
            availableResponders,
            availableSubscribers
        };
    } catch {
        dispatch([actions.SUBSCRIBERS_LOADED, []]);
        dispatch([actions.RESPONDERS_LOADED, []]);
    }

    return {
        availableResponders: [],
        availableSubscribers: [],
    };
}

/**
 * 
 * @param {boolean} commit 
 * @param {object} state 
 * @param {function} dispatch 
 * @param {PostService} postService 
 * @param {boolean} includeDueDate 
 * @param {boolean} syncToRemote 
 * @returns 
 */
async function savePost(commit, state, dispatch, postService, includeDueDate = true, syncToRemote = false) {
    try {
        dispatch([actions.SAVING_POST]);

        const body = {
            team_uuid: state.selectedTeamUuid,
            title: state.title,
            body: state.body,
            body_is_html: true,
            sub_status_uuid: state.selectedStatus,
            sub_status_2_uuid: state.selectedStatus2,
            tag_uuid: state.selectedTag,
            category_uuid: state.selectedCategory,
            severity_level: state.selectedRiskLevel && state.selectedRiskLevel.value,
            location: state.selectedLocations.length === 1 ? state.selectedLocations[0] : "",
            coordinates: {lat: state.lat, lon: state.lng},
            attachments: state.attachments,
            assign_user_uuids: state.selectedResponderUuids,
            subscribe_all: state.subscribeAll,
            subscribe_user_uuids: state.selectedSubscriberUuids,
            custom_field_value: state.customFieldValue,
            auto_close: state.autoClose || false,
            commit
        };

        if (includeDueDate) {
            body.due_date = state.dueDateTimestamp;
        }

        if (state.isUrgent !== null) {
            body.is_urgent = state.isUrgent;
        }

        if (state.voiceNotifications !== null) {
            if (state.voiceNotifications && !state.isUrgent) {
                body.voice_notifications = false;
            } else {
                body.voice_notifications = state.voiceNotifications;
            }
        }

        const post = await postService.updatePost(state.postUuid, state.isDraft, body, {syncAfterSave: false});
        if (state.isDraft && (commit || syncToRemote)) {
            postService.syncLocalPostToRemote(state.postUuid);
        }
        dispatch([actions.POST_SAVED]);
        return post;
    } catch (error) {
        console.error('Could not save post: %o', error);
        dispatch([actions.POST_NOT_SAVED]);

        if (commit && error.status === 403) {
            NotificationAlert("error", "", "You do not have permission to create a post.")
        } else if (error.status === 422) {
            const errorState = await mapErrorMessages(error, {
                'due_date': 'dueDateError',
                'title': 'titleError'
            });
            dispatch([actions.SET_ERRORS, errorState]);
        } else {
            if (commit) {
                NotificationAlert("error", "", "Unable to create post.");
            } else {
                NotificationAlert('error', '', "Unable to save draft.");
            }
        }
        throw error;
    }
}

async function deletePost(state, dispatch, postService) {
    try {
        dispatch([actions.SET_DISCARDING, true]);
        if (!state.discarding && !state.saving) {
            await postService.deletePostDraft(state.postUuid);
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error(err);
        notifyError(err);
        return false;
    } finally {
        dispatch([actions.SET_DISCARDING, false]);
    }
}

async function copyPost(postUuidToCopy, dispatch, postService) {
    try {
        dispatch([actions.LOADING_POST]);
        const response = await copy_post(postUuidToCopy);
        const data = await response.json();
        const post = safe_get(data, 'post', {});
        const teamUuid = safe_get(post, 'source_team.team_uuid', null);
        await postService.cacheDraft(post.post_uuid, post);
        await loadRespondersAndSubscribers(dispatch, postService, teamUuid);
        dispatch([actions.POST_LOADED, post]);
        return post;
    } catch (err) {
        NotificationAlert('error', '', 'Unable to copy post.');
        return null;
    }
}

export const PostFormTypes = {
    NEW_POST: 'new_post',
    NEW_POST_MODAL: 'new_post_modal',
    EDIT_POST: 'edit_post',
    COPY_POST: 'copy_post'
}

export default function PostForm({
                                     teamUuid,
                                     formType,
                                     postUuidToCopy,
                                     onCancel,
                                     onPostCreated,
                                     isPreview,
                                     initialBody,
                                     initialAttachments
                                 }) {
    const history = useHistory();
    const params = useParams();
    const zeroContext = useZeroContext();
    const postService = zeroContext.services.post;
    const formService = zeroContext.services.forms;
    const [prevTeamUuid, setPrevTeamUuid] = useState(null);
    const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const updatePostTimeout = useRef(null);
    const user = useSelector(state => state.user.user);
    const teams = useSelector(state => state.teams_helper.teams);
    const org = useSelector(state => state.org_helper.organization);
    const unregisterBlock = useRef(null);
    const {
        post_field_names: postFieldNames,
        custom_post_field: customPostField,
        post_location_enabled: locationEnabled = true,
        risk_level_enabled: riskEnabled = true,
        post_due_date_enabled: dueDateEnabled,
        organization_uuid: orgUuid,
    } = org;

    const feedUrl = `/${orgUuid}/home/team/my_teams/feed`;
    const [nextPath, setNextPath] = useState(feedUrl);
    const isLoading = (
        state.loadingPost
        || state.loadingCategories
        || state.loadingTags
        || state.loadingStatuses
        || state.loadingLocations
        || state.loadingRiskLevels
    );
    
    const isNewPost = formType === PostFormTypes.NEW_POST;
    const isNewPostModal = formType === PostFormTypes.NEW_POST_MODAL;
    const isCopiedPost = formType === PostFormTypes.COPY_POST;
    const isEditedPost = formType === PostFormTypes.EDIT_POST;
    const isModal = isCopiedPost || isNewPostModal;

    const unblockHistory = () => {
        if (unregisterBlock.current) {
            unregisterBlock.current();
            unregisterBlock.current = null;
        }
    }

    const loadPost = (postUuid) => {
        if (!state.loadingPost) {
            dispatch([actions.LOADING_POST]);

            postService
                .getPost(postUuid)
                .then(post => {
                    if (isNewPostModal) {
                        if (initialBody) {
                            post.body = initialBody;
                        }
                        if (initialAttachments) {
                            post.attachments = initialAttachments.map(attachment => ({
                                ...attachment,
                                needs_clone: true,
                                clone_type: 'submission_comment'
                            }));
                        }
                    }

                    dispatch([actions.POST_LOADED, post]);
                    if (isNewPost) {
                        dispatch([actions.SET_AUTOSAVE, true])
                    }

                    loadRespondersAndSubscribers(dispatch, postService, post.source_team.team_uuid);
                    loadEmbeddedForms(post);
                })
                .catch(err => {
                    dispatch([actions.POST_NOT_FOUND])
                })
        }
    }

    /** @type {(post: Post) => Promise<void>} */
    const loadEmbeddedForms = async (post) => {
        const embeddedFormsToLoad = [
            ...(post.$meta?.unsyncedEmbeddedForms ?? []),
            ...(post.embedded_forms ?? []),
        ];
        const submissions = [];

        for (const embeddedForm of embeddedFormsToLoad) {
            try {
                const submission = await formService.loadEmbeddedSubmission(embeddedForm);
                submissions.push(submission);
            } catch (err) {
                console.error(err);
            }
        }

        dispatch([actions.EMBEDDED_SUBMISSIONS_LOADED, submissions]);
    }

    const updatePost = (commit) => {
        dispatch([actions.FLUSH_INPUT]);
        clearTimeout(updatePostTimeout.current);
        updatePostTimeout.current = setTimeout(() => {
            savePost(commit, state, dispatch, postService, (!isEditedPost && dueDateEnabled))
                .then(() => {
                })
                .catch(err => {
                });
        }, 500);
    };

    const onNavBlocked = useCallback((tx) => {
        // this.setState({ navPath: safe_get(tx, "pathname", this.state.feedPath) });
        blurActiveElement();

        const path = safe_get(tx, 'pathname', feedUrl);
        setNextPath(path);

        if (state.postNotFound) {
            return true;
        } else if (state.hasBeenUpdated && !state.allowNav) {
            setShowSaveDraftModal(true);
            return false;
        } else if (!state.hasBeenUpdated && state.postUuid && !state.discarding) {
            deletePost(state, dispatch, postService)
                .finally(() => {
                    if (unregisterBlock.current) {
                        unregisterBlock.current();
                        unregisterBlock.current = null;
                    }
                    history.push(path);
                });
            return false;
        } else {
            return state.allowNav;
        }
    }, [state]);

    useEffect(() => {
        if (!state.posting && (isNewPost && params.post_uuid)) {
            if (unregisterBlock.current) {
                unregisterBlock.current();
            }
            unregisterBlock.current = history.block(onNavBlocked);
        }
    }, [onNavBlocked, state.posting, isNewPost, params.post_uuid]);

    const onPostServiceMessage = useCallback((ev) => {
        const postId = state.postUuid;

        if (ev.data.type === PostEvents.LOCAL_POST_DELETED) {
            if (ev.data.localId === postId) {
                // remove history block and navigate back to feed
                unblockHistory();
                history.push(`/${orgUuid}/home/team/my_teams/feed`);
            }
        } else if (ev.data.type === PostEvents.POST_ID_CHANGE) {
            if (ev.data.oldId === postId) {
                // remove history block and navigate to new post ID
                unblockHistory();
                const currentPath = history.location.pathname;
                const newPath = currentPath.replace(postId, ev.data.newId);
                history.replace(newPath);
            }
        }
    }, [state]);

    useEffect(() => {
        const unsubscribe = postService.subscribe(onPostServiceMessage);
        return () => {
            unsubscribe();
        }
    }, [onPostServiceMessage]);

    useEffect(() => {
        if (!isNewPost) {
            dispatch([actions.SET_AUTOSAVE, false]);
        }

        if ((isNewPost || isNewPostModal) && teams.length > 0) {
            let selectedTeamUuid = teams[0].uuid;

            if (teamUuid === 'my_teams' || teamUuid === 'organization_teams' || !teamUuid) {
                for (const team of teams) {
                    if (!isRestrictedTeamUser(team, user)) {
                        selectedTeamUuid = team.uuid;
                        break;
                    }
                }
            } else if (teamUuid) {
                selectedTeamUuid = teamUuid;
            }

            loadRespondersAndSubscribers(dispatch, postService, selectedTeamUuid);
            dispatch([actions.TEAM_CHANGED, selectedTeamUuid]);
        }

        loadInitialData(dispatch, postService);

        if (isCopiedPost) {
            copyPost(postUuidToCopy, dispatch, postService).then((post) => {
                if (post) {
                    loadEmbeddedForms(post);
                }
            });
        }

        return () => {
            unblockHistory();
        }
    }, []);

    useEffect(() => {
        if (state.shouldSave) {
            updatePost(!state.isDraft);
        }
    }, [state.shouldSave]);

    useEffect(() => {
        if (prevTeamUuid !== state.selectedTeamUuid) {
            setPrevTeamUuid(state.selectedTeamUuid);

            if ((isNewPost && !params.post_uuid) || (isNewPostModal && !state.postUuid)) {
                dispatch([actions.LOADING_POST]);

                postService
                    .createNewPost(state.selectedTeamUuid)
                    .then(post => {
                        dispatch([actions.POST_CREATED, post.post_uuid]);
                        if (isNewPost) {
                            history.replace(`/${orgUuid}/home/team/my_teams/feed/new_post/${post.post_uuid}`);
                        } else if (isNewPostModal) {
                            loadPost(post.post_uuid);
                        }
                    })
                    .catch((err) => {
                        console.error('Create post error: %o', err);
                        dispatch([actions.POST_CREATED, null]);
                    })
            }
        }
    }, [state.selectedTeamUuid]);

    useEffect(() => {
        if (params.post_uuid) {
            loadPost(params.post_uuid);
        }
    }, [params.post_uuid]);

    const onConfirmDeleteDraft = () => {
        deletePost(state, dispatch, postService)
            .then(wasDeleted => {
                if (wasDeleted) {
                    dispatch([actions.UPDATE_STATE, {allowNav: true}]);
                    setTimeout(() => {
                        if (history.location.pathname !== nextPath) {
                            history.push(nextPath);
                        } else {
                            history.push(feedUrl)
                        }
                    }, 50);
                }
            })
    }

    const onConfirmSaveDraft = () => {
        dispatch([actions.FLUSH_INPUT]);
        setTimeout(async () => {
            try {
                await savePost(false, state, dispatch, postService, (!isEditedPost && dueDateEnabled), true);
                for (const submission of state.embeddedSubmissions) {
                    await formService.syncLocalDraft(submission.submission_uuid);
                }
                if (unregisterBlock.current) {
                    unregisterBlock.current();
                    unregisterBlock.current = null;
                }
                if (history.location.pathname !== nextPath) {
                    history.push(nextPath);
                } else {
                    history.push(feedUrl)
                }
            } catch (err) {
                // TODO: handle this error state
                console.error(err);
            }            
        }, 250);
    }

    const postOkayToSubmit = () => {
        if (state.posting || state.saving) {
            return false;
        }

        let title = state.title;
        let content = state.body;
        let status = state.selectedStatus;
        let status2 = state.selectedStatus2;
        let tag = state.selectedTag;
        let category = state.selectedCategory;

        const missingRequiredFieldError = "A response is required.";

        const newState = {
            titleError: null,
        };
        newState["teamError"] = state.selectedTeamUuid ? null : "Please select a team for your post.";
        newState["contentError"] = fieldIsNotEmpty(content) ? null : "Please add a few details for your post."
        newState["categoryError"] = category !== null ? null : missingRequiredFieldError;

        if (category === null) {
            newState["categoryError"] = missingRequiredFieldError;
        } else {
            newState["categoryError"] = getCategoryError(state, category) || null;
        }

        if (state.tags.filter(t => t.enabled).length > 0) {
            if (tag === null) {
                newState["tagError"] = missingRequiredFieldError;
            } else {
                newState["tagError"] = getTagError(state, tag) || null;
            }
        }

        if (state.statuses.filter(s => s.enabled).length > 0) {
            if (status === null) {
                newState["statusError"] = missingRequiredFieldError;
            } else {
                newState["statusError"] = getSubStatusError(state, status, 1) || null;
            }
        }

        if (state.statuses2.filter(s => s.enabled).length > 0) {
            if (status2 === null) {
                newState["status2Error"] = missingRequiredFieldError;
            } else {
                newState["status2Error"] = getSubStatusError(state, status2, 2) || null;
            }
        }

        if (state.dueDateError) {
            newState["dueDateError"] = state.dueDateError;
        }

        if (fieldIsNotEmpty(title)) {
            if (title.length > MAX_POST_TITLE_LENGTH) {
                newState.titleError = `Post title must be ${MAX_POST_TITLE_LENGTH} characters or less.`;
            }
        } else {
            newState.titleError = "Please add a short title for your post.";
        }

        for (const submission of state.embeddedSubmissions) {
            const formFields = submission.form.fields;
            const answersData = submission.fields;
            const [missingRequiredFields, newFormFields] = submissionHasBlankRequiredFields(formFields, answersData);
            if (missingRequiredFields) {
                const newSubmission = {...submission};
                newSubmission.form.fields = newFormFields;
                dispatch([actions.EMBEDDED_SUBMISSION_UPDATED, newSubmission]);
                newState["formError"] = "Please fill out all required fields.";
                break;
            }
        }

        dispatch([actions.UPDATE_STATE, newState]);

        var isOkay = true;
        for (const i in newState) {
            if (newState[i] !== null) {
                isOkay = false;
            }
        }

        if (!isOkay) {
            NotificationAlert("error", "", <><strong>Unable to save post</strong><br/>Please review error messages.</>)
        }

        return isOkay;
    }

    const onCancelPost = () => {
        if (!isModal) {
            history.push(nextPath)
        } else if (isModal) {
            if (state.postUuid) {
                postService.deletePostDraft(state.postUuid);
            }
            if (onCancel) onCancel();
        }
    }

    const onCreatePost = () => {
        if (isPreview) return;

        dispatch([actions.FLUSH_INPUT]);
        setTimeout(async () => {
            blurActiveElement();

            if (postOkayToSubmit()) {
                try {
                    dispatch([actions.UPDATE_STATE, {posting: true}]);

                    if ([PostFormTypes.EDIT_POST, PostFormTypes.COPY_POST].includes(formType)) {
                        for (const submission of state.embeddedSubmissions) {
                            const body = {
                                fields: submission.fields,
                                commit: true,
                            };
                            await update_submission(submission.submission_uuid, JSON.stringify(body));
                        }

                    }

                    const post = await savePost(true, state, dispatch, postService, (!isEditedPost && dueDateEnabled));
                    if (unregisterBlock.current) {
                        unregisterBlock.current();
                        unregisterBlock.current = null;
                    }

                    dispatch([actions.UPDATE_STATE, {allowNav: true}]);

                    setTimeout(() => {
                        if (isModal) {
                            if (onPostCreated) onPostCreated(post);
                        } else if (isNewPost) {
                            history.replace(`${feedUrl}/post_submitted`);
                        } else if (isEditedPost) {
                            NotificationAlert('success', '', 'Post updated.')
                            history.replace(`${feedUrl}/post/${state.postUuid}`);
                        }
                    }, 250);
                } catch (err) {
                    console.error(err);
                }
            }

        }, 250);
    }

    const onTeamChange = (newTeamUuid) => {
        dispatch([actions.UPDATE_STATE, {
            allowNav: false,
            selectedTeamUuid: newTeamUuid,
            selectedResponders: [user],
            selectedResponderUuids: [user.uuid],
        }]);

        loadRespondersAndSubscribers(dispatch, postService, newTeamUuid)
            .then(({availableResponders, availableSubscribers}) => {
                const team = teams.find(t => t.uuid === newTeamUuid);

                if (team) {
                    if (!state.hasBeenUpdated) {
                        dispatch([actions.SET_AUTOSAVE, false]);
                    }

                    if (availableSubscribers.length === 0 || !team.notify_all_when_posted) {
                        dispatch([actions.SUBSCRIBERS_CHANGED, [user.uuid]]);
                    } else {
                        dispatch([actions.SUBSCRIBE_ALL]);
                    }

                    if (!state.hasBeenUpdated) {
                        dispatch([actions.SET_AUTOSAVE, true]);
                    }
                }
            });
    }

    const onAttachmentUpload = async (file) => {
        try {
            const attachment = await postService.addAttachmentToPost(state.postUuid, file);
            dispatch([actions.ADD_ATTACHMENT, attachment]);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <FileUploadContextManager
            attachments={state.attachments}
            customUpload={state.isDraft ? onAttachmentUpload : null}
            generateParamsCallback={file =>  ({
                feature_key: "bulletin_post",
                post_uuid: state.postUuid,
                content_type: file.type,
                file_name: file.name,
                embedded: false
            })}
            onUploadSuccess={(file, pre_signed_url, file_key) => {
                const newAttachment = {
                    file_path: file_key,
                    file_name: file.name,
                    public_url: pre_signed_url + "/" + file_key,
                    mime_type: file.type,
                }

                dispatch([actions.ADD_ATTACHMENT, newAttachment]);
            }}
            onUploadError={(error) => {
                console.error(error);
            }}
        >
            {showSaveDraftModal &&
                <ConfirmModal
                    show={showSaveDraftModal}
                    cancel={() => {
                        setShowSaveDraftModal(false)
                    }}
                    cancelAction={() => {
                        onConfirmDeleteDraft()
                    }}
                    confirm={() => {
                        onConfirmSaveDraft()
                    }}
                    title={"Save draft?"}
                    body={"This post has not been submitted and contains unsaved changes. You can save it as a draft to submit later."}
                    cancelButtonName="Delete Draft"
                    confirmButtonName="Save Draft"
                />
            }
            {isLoading &&
                <BulletinNewPostSkeleton/>
            }
            {!isLoading &&
                <div className={isModal ? "" : "panel thin-border pad-15"}>
                    <PostFormHeader
                        state={state}
                        onModalClosed={() => {
                            isModal && onCancelPost()
                        }}
                        formType={formType}
                    />
                    <hr style={{margin: '1rem 0 1.5rem 0'}}/>
                    <PostFormSyncError state={state} dispatch={dispatch} />
                    { formType !== PostFormTypes.EDIT_POST &&
                        <PostFormTeam state={state} onTeamChange={onTeamChange} teams={teams} />
                    }
                    <PostFormCategory state={state} dispatch={dispatch} postFieldNames={postFieldNames}/>
                    <PostFormTag state={state} dispatch={dispatch} postFieldNames={postFieldNames}/>
                    <PostFormStatus state={state} dispatch={dispatch} postFieldNames={postFieldNames} group={1} />
                    <PostFormStatus state={state} dispatch={dispatch} postFieldNames={postFieldNames} group={2} />
                    <PostFormCustomField state={state} dispatch={dispatch} customPostField={customPostField}/>
                    <PostFormTitle state={state} dispatch={dispatch}/>
                    <PostFormBody state={state} dispatch={dispatch}/>
                    <PostFormAttachments state={state} dispatch={dispatch}/>
                    { state.embeddedSubmissions &&
                        state.embeddedSubmissions.map((submission) => (
                            <PostFormEmbeddedForm 
                                key={submission.submission_uuid}
                                submission={submission}
                                dispatch={dispatch} 
                                isEditable={true}
                            />
                        ))
                    }
                    <PostFormLocation state={state} dispatch={dispatch} postFieldNames={postFieldNames}
                                      locationEnabled={locationEnabled}/>
                    <PostFormRiskLevel state={state} dispatch={dispatch} postFieldNames={postFieldNames}
                                       riskEnabled={riskEnabled}/>
                    {!isEditedPost &&
                        <>
                            <PostFormDueDate state={state} dispatch={dispatch} dueDateEnabled={dueDateEnabled}/>
                            <PostFormResponders state={state} dispatch={dispatch} orgUuid={orgUuid}/>
                        </>
                    }
                    <PostFormControls
                        state={state}
                        dispatch={dispatch}
                        onCancel={onCancelPost}
                        onSavePost={onCreatePost}
                        formType={formType}
                    />
                    {!isEditedPost &&
                        <PostFormSubscribers state={state} dispatch={dispatch} orgUuid={orgUuid}/>
                    }
                </div>
            }
            {isNewPost && state.postUuid &&
                <span className="blue-link pull-right" style={{marginBottom: "80px", paddingRight: "15px"}} onClick={() => {
                    setShowSaveDraftModal(true)
                }}>
                    Save Draft
                </span>
            }
        </FileUploadContextManager>
    )
}