import React from 'react';
import {useParams} from 'react-router';
import {UserAddOutlined} from '@ant-design/icons';

import {actions} from './reducer';
import UserPopoverCard from 'components/Shared/UserPopoverCard';
import RespondersPopover from 'components/Shared/RespondersPopover';
import {safeProfilePic} from 'other/Helper';


export default function PostFormResponders({state, dispatch, orgUuid}) {
    const {post_uuid} = useParams();

    return (
        <div className="panel-heading bulletin-post" style={{marginBottom: "5px"}}>
            <h3 className="bulletin-post-headers">Assignees</h3>
            <p className="zero-dark-grey" style={{marginBottom: "0px"}}>
                {
                    "When I post this, " + state.selectedResponders.length + (((state.selectedResponders.length === 1) ? " person" : " people") + " will be assigned.")
                }
            </p>
            <div>
                {
                    state.selectedResponders.length > 0 &&
                    state.selectedResponders.map((user, index) => (
                        <UserPopoverCard
                            key={index}
                            user={user}
                            responders={state.selectedResponders}
                            post_uuid={post_uuid}
                            org_uuid={orgUuid}
                            updateResponders={uuids => dispatch([actions.RESPONDERS_CHANGED, uuids])}
                            showRemoveResponder={true}
                        >
                            <span
                                style={{cursor: "pointer"}}>{safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                                marginRight: "5px",
                                display: "inline-block"
                            })}</span>
                        </UserPopoverCard>
                    ))
                }
                {
                    !state.loadingPost && !state.loadingResponders &&
                    <RespondersPopover
                        available_responders={state.availableResponders}
                        selected_responders={state.selectedResponders}
                        updateResponders={uuids => dispatch([actions.RESPONDERS_CHANGED, uuids])}
                    >
                        <button className="ButtonLink post-details-content mar-top-5"
                                style={{verticalAlign: "bottom", height: "26px", width: "26px"}}>
                            <UserAddOutlined style={{fontSize: "19px"}}/>
                        </button>
                    </RespondersPopover>
                }
            </div>
        </div>
    )
}