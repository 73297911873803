import React, {useEffect, useState} from 'react';

import {actions} from './reducer';
import {hideFooterMobileApp, showFooterMobileApp} from 'other/Helper';
import { usePostFieldNames } from 'hooks/postHooks';

let inputDebouncer = null;

export default function PostFormTitle({state, dispatch}) {
    const [fieldValue, setFieldValue] = useState(state.title);
    const fieldNames = usePostFieldNames();

    const onFieldChange = event => {
        const value = event.target.value;
        setFieldValue(value);

        clearTimeout(inputDebouncer);
        inputDebouncer = setTimeout(() => {
            dispatch([actions.TITLE_CHANGED, value]);
            inputDebouncer = null;
        }, 1500);
    }

    useEffect(() => {
        return () => {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
    }, []);

    useEffect(() => {
        setFieldValue(state.title);
    }, [state.title]);

    useEffect(() => {
        if (state.flushInput && fieldValue !== state.title) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
            dispatch([actions.TITLE_CHANGED, fieldValue]);
        }
    }, [state.flushInput]);

    const onBlur = () => {
        showFooterMobileApp();
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
            dispatch([actions.TITLE_CHANGED, fieldValue]);
        }
    }

    return (
        <>
            <div className="panel-heading bulletin-post required">
                <h3 className="bulletin-post-headers ">{fieldNames.title}</h3>
            </div>

            <div className="bulletin-post">
                <div className="form-group" style={{marginBottom: '0px'}}>
                    <input
                        name="post_title"
                        type="text"
                        placeholder="Add a short title"
                        className="form-control custom-placeholder"
                        maxLength="100"
                        value={fieldValue}
                        onFocus={hideFooterMobileApp}
                        onBlur={onBlur}
                        onChange={onFieldChange}
                    />
                    {
                        state.titleError &&
                        <small className="error">{state.titleError}</small>
                    }
                </div>
            </div>
        </>
    );
}