import React, { useCallback, useState } from "react";
import { Modal } from "antd";
import Autocomplete from "components/Shared/GoogleMapsAutocomplete.js";

import {
  get_locations,
  update_location,
  create_location,
} from "api/zero-api.js";
import { safe_get } from "other/Helper.js";
import NotificationAlert from "other/NotificationAlert.js";
import ButtonLoading from "components/Shared/ButtonLoading.js";
import MultiTeamSelect from "components/Shared/MultiTeamSelect";

function extractAddressParts(address) {
  let components = safe_get(address, "address_components", []);
  let street = "";
  let city = "";
  let state = "";
  let zip = "";

  for (const item of components) {
    let types = safe_get(item, "types", []);

    if (types.indexOf("street_number") >= 0) {
      street = street + safe_get(item, "long_name", "");
    } else if (types.indexOf("route") >= 0) {
      street = street + " " + safe_get(item, "short_name", "");
    } else if (types.indexOf("locality") >= 0) {
      city = safe_get(item, "short_name", "");
    } else if (types.indexOf("administrative_area_level_1") >= 0) {
      state = safe_get(item, "short_name", "");
    } else if (types.indexOf("postal_code") >= 0) {
      zip = safe_get(item, "long_name", "");
    }
  }

  return {
    street_address: street,
    city,
    state,
    zip,
  };
}

function getInitialFields(location) {
  return {
    name: location?.name || "",
    industry_description: location?.industry_description || "",
    naics: location?.naics || "",
    number_of_employees: location?.number_of_employees || "",
    hours_worked: location?.hours_worked || "",
    reset_used_desks: location?.reset_used_desks || false,
    incidents_enabled: location?.incidents_enabled || true,
    spaces_enabled: location?.spaces_enabled || false,
  };
}

function getInitialAddress(location = {}) {
  const { street_address = "", city = "", state = "", zip = "" } = location;

  return {
    street_address,
    city,
    state,
    zip,
  };
}

function getInitialTeamIds(location = {}) {
  const { teams = [] } = location;
  return teams.map((team) => team.uuid);
}

function getDefaultAddressValue(location = {}) {
  const { street_address = "", city = "", state = "", zip = "" } = location;

  if (street_address || city || state || zip) {
    return `${street_address}, ${city}, ${state} ${zip}`;
  }
  return "";
}

function ErrorMessage({ message }) {
  if (!message) return null;
  return <small className="error">{message}</small>;
}

function LocationModalFooter({
  isNewLocation,
  isSaving,
  errorMessage,
  onCancel,
  onConfirm,
}) {
  if (isNewLocation) {
    return (
      <div>
        <div style={{ height: "33px" }}>
          <button className="btn btn-modal" onClick={onConfirm}>
            {isSaving ? <ButtonLoading /> : "Create location"}
          </button>
        </div>
        <ErrorMessage message={errorMessage} />
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <button className="btn btn-discard" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-modal" onClick={onConfirm}>
            {isSaving ? <ButtonLoading /> : "Update location"}
          </button>
        </div>
        <ErrorMessage message={errorMessage} />
      </div>
    );
  }
}

export default function LocationModal({
  location,
  onClose = () => {},
  onSaveComplete = () => {},
}) {
  const isNewLocation = !location;
  const [fields, setFields] = useState(getInitialFields(location));
  const [address, setAddress] = useState(getInitialAddress(location));
  const [teamIds, setTeamIds] = useState(getInitialTeamIds(location));
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [showMore, setShowMore] = useState(false);

  const handleAddressSelect = useCallback(
    (address) => setAddress(extractAddressParts(address)),
    [setAddress]
  );

  const handleInputChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      setFields({ ...fields, [name]: value });
    },
    [fields, setFields]
  );

  const onSave = useCallback(async () => {
    if (!isSaving) {
      setIsSaving(true);

      const body = {
        ...fields,
        ...address,
        team_uuids: teamIds,
      };
      body.number_of_employees = parseInt(body.number_of_employees, 10);
      body.hours_worked = parseInt(body.hours_worked, 10);

      let saveLocation = () => create_location(JSON.stringify(body));
      if (location) {
        saveLocation = () =>
          update_location(location.location_uuid, JSON.stringify(body));
      }

      try {
        await saveLocation();
        const notificationMessage = location
          ? "Location updated."
          : "New location created.";
        NotificationAlert("success", "", notificationMessage);
        onSaveComplete();
        onClose();
      } catch (error) {
        if (error.status === 422) {
          const newErrorState = {};
          const data = await error.json();

          const errorMessages = safe_get(data, "error_messages", []);
          errorMessages.forEach((err) => {
            if (typeof err === "string") {
              newErrorState.general = err;
            } else {
              const { field, message } = err;
              newErrorState[field] = message;
            }
          });

          setErrors(newErrorState);
        } else {
          setErrors({
            general: "An unexpected error occurred. Please try again later.",
          });
        }
        setIsSaving(false);
      }
    }
  }, [
    location,
    fields,
    address,
    teamIds,
    isSaving,
    setIsSaving,
    setErrors,
    onSaveComplete,
    onClose,
  ]);

  return (
    <Modal
      title={isNewLocation ? "Create location" : "Edit location"}
      open={true}
      maskClosable={false}
      onCancel={onClose}
      maskTransitionName="maskTransitionName"
      footer={
        <LocationModalFooter
          isNewLocation={isNewLocation}
          isSaving={isSaving}
          errorMessage={errors.general}
          onCancel={onClose}
          onConfirm={onSave}
        />
      }
    >
      <div>
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <h3
                className="mt-0 titles onboarding invite-link required"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                }}
              >
                Location Name
              </h3>
              <span
                className="blue-link"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Hide detail" : "Show detail"}
              </span>
            </div>
            <input
              name="name"
              className="form-control custom-placeholder"
              placeholder="e.g. Acme Contracting"
              onChange={handleInputChange}
              value={fields.name}
            />
            <ErrorMessage message={errors.name} />
          </div>
        </div>
        {showMore && (
          <>
            <div className="row" style={{ marginTop: 8 }}>
              <div className="col-md-12">
                <h3
                  className="mt-0 titles onboarding invite-link"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                  }}
                >
                  Location Address
                </h3>
                <Autocomplete
                  id="location_address"
                  className="form-control custom-placeholder"
                  style={{ width: "100%" }}
                  onPlaceSelected={handleAddressSelect}
                  types={["address"]}
                  componentRestrictions={{ country: ["us", "ca"] }}
                  defaultValue={getDefaultAddressValue(location)}
                />
                <ErrorMessage message={errors.street_address} />
              </div>
            </div>
            <div className="row" style={{ marginTop: 8 }}>
              <div className="col-md-12">
                <h3
                  className="mt-0 titles onboarding invite-link"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                  }}
                >
                  Industry Description
                </h3>
                <input
                  name="industry_description"
                  className="form-control custom-placeholder"
                  placeholder="e.g. Manufacturer of motor truck trailers"
                  onChange={handleInputChange}
                  value={fields.industry_description}
                />
                <ErrorMessage message={errors.industry_description} />
              </div>
            </div>
            <div className="row" style={{ marginTop: 8 }}>
              <div className="col-md-12">
                <h3
                  className="mt-0 titles onboarding invite-link"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                  }}
                >
                  North American Industrial Classification (NAICS)
                </h3>
                <input
                  name="naics"
                  className="form-control custom-placeholder"
                  placeholder="e.g. 226212"
                  onChange={handleInputChange}
                  style={{ maxWidth: "300px" }}
                  value={fields.naics}
                />
                <ErrorMessage message={errors.naics} />
              </div>
            </div>
            <div className="row" style={{ marginTop: 8 }}>
              <div className="col-md-12">
                <h3
                  className="mt-0 titles onboarding invite-link"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                  }}
                >
                  Average Annual Number of Employees
                </h3>
                <input
                  name="number_of_employees"
                  className="form-control custom-placeholder"
                  placeholder=""
                  onChange={handleInputChange}
                  value={fields.number_of_employees}
                  type="number"
                  style={{ maxWidth: "300px" }}
                  min="0"
                />
                <ErrorMessage message={errors.number_of_employees} />
              </div>
            </div>
            <div className="row" style={{ marginTop: 8 }}>
              <div className="col-md-12">
                <h3
                  className="mt-0 titles onboarding invite-link"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                  }}
                >
                  Total Hours Worked By All Employees Last Year
                </h3>
                <input
                  name="hours_worked"
                  className="form-control custom-placeholder"
                  placeholder=""
                  onChange={handleInputChange}
                  value={fields.hours_worked}
                  type="number"
                  style={{ maxWidth: "300px" }}
                  min="0"
                />
                <ErrorMessage message={errors.hours_worked} />
              </div>
            </div>
          </>
        )}
        <div className="row" style={{ marginTop: 8 }}>
          <div className="col-md-12">
            <h3
              className="mt-0 titles onboarding invite-link"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
              }}
            >
              Teams Assigned
            </h3>
            <MultiTeamSelect
              initialTeamOptions={location?.teams || []}
              selectedTeamIds={teamIds}
              onSelectedTeamChange={(ids) => setTeamIds(ids)}
              style={{ width: "100%" }}
              placeholder="Select team(s)..."
            />
            <ErrorMessage message={errors.team_uuid} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
