/**
 * Tries to get a document from PouchDB, returning blank object if not found.
 * @param {PouchDB.Database} db
 * @param {string} id
 * @param {any} [defaultValue]
 * @param {PouchDB.Core.GetOptions} [options]
 * @returns {Promise<object>}
 */
export async function pouchDbGet(db, id, defaultValue, options = {}) {
    try {
        return await db.get(id, options)
    } catch (error) {
        if (error.name === 'not_found') {
            return defaultValue === undefined ? {} : defaultValue;
        } else {
            throw error;
        }
    }
}

/**
 * Returns all data in the database as array of documents
 * @param {PouchDB.Database} db
 * @param {PouchDBAllDocsOptions} options
 * @returns {Promise<object[]>}
 */
export async function pouchDbGetAll(db, options = {}) {
    const results = await db.allDocs({include_docs: true, ...options});
    return results.rows.map(row => row.doc);
}

/**
 * Inserts or overwrites a document.
 * @param {PouchDB.Database} db
 * @param {string} id
 * @param {object} data
 */
export async function pouchDbUpsert(db, id, data) {
    try {
        const currentDoc = await db.get(id);
        await db.put({
            ...data,
            _id: id,
            _rev: currentDoc._rev,
        });
    } catch (error) {
        if (error.name === 'not_found') {
            await db.put({
                ...data,
                _id: id,
            });
        } else {
            throw error;
        }
    }
}

/** @type {PouchDB.Configuration.DatabaseConfiguration} */
export const defaultPouchDbOptions = {
    auto_compaction: true,
    revs_limit: 1,
}