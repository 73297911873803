import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import styles from './GenericDraftCard.module.css';
import { Popconfirm } from "antd";

/**
 * @typedef {object} EditAction
 * @property {function} [handler]
 * @property {string} [link]
 * @property {boolean} [disabled]
 * 
 * @typedef {object} DeleteAction
 * @property {function} handler
 * @property {boolean} [disabled]
 * @property {boolean} [showPopconfirm]
 * @property {string} [popconfirmText]
 */

/**
 * @param {object} props
 * @param {EditAction} props.editAction
*/
function EditPostButton({editAction}) {
    const innerEl = (
        <button
            disabled={editAction.disabled}
            className={`btn btn-link ${editAction.disabled ? "" : "zero-blue"} ${styles.draftActionButton}`}
            onClick={editAction.handler}
        >
            <EditOutlined style={{fontSize: '1.8rem'}}/>
        </button>
    );

    if (editAction.link && !editAction.handler) {
        return (
            <Link to={editAction.link}>
                {innerEl}
            </Link>
        );
    } else {
        return innerEl;
    }
}

/**
 * @param {object} props
 * @param {DeleteAction} props.deleteAction
 */
function DeletePostButton({deleteAction}) {
    const {handler, disabled, showPopconfirm, popconfirmText} = deleteAction;

    const buttonOnClick = showPopconfirm ? null : handler;

    const innerEl = (
        <button
            disabled={disabled}
            className={`btn btn-link ${disabled ? "" : "zero-delete-red"} ${styles.draftActionButton}`}
            onClick={buttonOnClick}
        >
            <DeleteOutlined style={{fontSize: '1.6rem', marginBottom: '5px'}}/>
        </button>
    )

    if (showPopconfirm && !disabled) {
        return (
            <Popconfirm
                title={popconfirmText}
                icon={<DeleteOutlined className="zero-delete-red" />}
                onConfirm={() => {handler();}}
                okText="Yes"
                okButtonProps={{
                    className: "btn-primary"
                }}
                cancelText="No"
                cancelButtonProps={{
                    className: "btn-discard"
                }}
            >{innerEl}</Popconfirm>
        )
    } else {
        return innerEl;
    }
}

/**
 * 
 * @param {object} props
 * @param {(string | JSX.Element)} props.title
 * @param {string} [props.titleLink]
 * @param {(string | JSX.Element)} props.subtitle1
 * @param {(string | JSX.Element)} props.subtitle2
 * @param {JSX.Element} [props.tag]
 * @param {EditAction} props.editAction
 * @param {DeleteAction} props.deleteAction
 * @returns {JSX.Element}
 */
export default function GenericDraftCard({title, titleLink, subtitle1, subtitle2, tag, editAction, deleteAction}) {
    const titleEl = (titleLink && !editAction?.disabled)
        ? (<Link className="zero-blue link-hover" to={titleLink}>{title}</Link>)
        : (title)

    return (
        <div className="zero-blue flex-col" style={{gap: '0.5rem'}}>
            <div>
                <strong>{titleEl}</strong>
            </div>
            <div>{subtitle1}</div>
            <div>{subtitle2}</div>
            <div className={styles.draftActionRow}>
                {tag ?? <div></div>}
                <div className="flex flex-align-center">
                    <EditPostButton editAction={editAction} />
                    <div className={styles.draftActionSeparator}></div>
                    <DeletePostButton deleteAction={deleteAction} />
                </div>
            </div>
            <hr style={{width: '100%', margin: '0.5rem 0'}}/>
        </div>
    );
}
