export default function reducer(state = {
    organization: {},
    organizations: [],
}, action) {

    switch (action.type) {

        case "update_current_org": {
            return Object.assign({}, state, {
                organization: action.payload
            });
        }

        case "update_organizations": {
            return Object.assign({}, state, {
                organizations: action.payload
            });

        }

        case "RESET": {
            return Object.assign({}, state, {
                organization: {},
                organiztions: []
            });
        }

        default: {
            return state;
        }

    }

}