import React, {Component} from 'react';
import {withRouter} from 'react-router'

import {get_incident_causes_analytics} from '../../../api/zero-api.js'
import safe_get from '../../../other/SafeGet';
import Skeleton from 'react-loading-skeleton'

class IncidentCauseAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };

    }

    componentDidUpdate(prevProps) {
        if ((this.props.query !== prevProps.query) || (this.props.location_uuid !== prevProps.location_uuid)) {
            this.getAnalytics();
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;
        this.setState({loading: true});

        // let query = "";
        // if (this.props.location_uuid === "all_locations") {
        //     query = "?" + this.props.query;
        // }
        // else {
        //     query = "?location_uuid=" + this.props.location_uuid + this.props.query;
        // }

        let query = "?" + this.props.query

        get_incident_causes_analytics(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    data: safe_get(success, "data", []),
                    loading: false
                })
            });
        }, function (error) {
            self.setState({loading: false});
        });
    }


    rowFormatter(injury, index) {
        return (
            <tr key={index} className="analytics-indicator">
                <td style={{borderTop: "none", padding: "0px"}}>
                    <p className="item lg analytics-tag zero-dark-grey" style={{marginBottom: "0px"}}>
                        {injury.name}
                    </p>
                </td>
                <td style={{borderTop: "none", padding: "0px"}}>
                    <p className="item lg pull-right zero-dark-grey" style={{marginBottom: "0px"}}>{injury.total}</p>
                </td>
            </tr>
        )
    }


    render() {
        if (this.state.loading) {
            return (
                // <LoadingIndicator columnClass={"col-lg-6 col-md-6 col-sm-6 col-xs-12"}/>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                    <div className="panel thin-border" style={{height: "210px"}}>
                        <div className="panel-heading analytics">
                            <h2 className="panel-title analytics"
                                style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                <Skeleton width={150}/>
                            </h2>
                        </div>
                        <div className="panel-body no-top-pad">
                            <table className="default-table table-unbordered table table-sm table-hover"
                                   style={{marginBottom: "0px"}}>
                                <tbody>
                                {
                                    [...Array(5)].map((e, index) =>
                                        <tr key={index} className="analytics-indicator">
                                            <td style={{borderTop: "none", padding: "0px"}}>
                                                <p className="item lg analytics-tag zero-dark-grey"
                                                   style={{marginBottom: "0px"}}>
                                                    <Skeleton width={160}/>
                                                </p>
                                            </td>
                                            <td style={{borderTop: "none", padding: "0px"}}>
                                                <p className="item lg pull-right zero-dark-grey"
                                                   style={{marginBottom: "0px"}}><Skeleton width={20}/></p>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.data.length === 1) {
            // Hide Incident Cause Analytics if there is only the None option
            return null
        } else {
            return (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-print-6 col-reduce-padding">
                    <div className="panel thin-border print-unset-height print-no-y-margins" style={{height: "210px"}}>
                        <div className="panel-heading analytics">
                            <h2 className="panel-title analytics"
                                style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                Incident Cause
                            </h2>
                        </div>
                        <div className="panel-body no-top-pad print-unset-height print-no-y-pad"
                             style={{height: " 160px", overflow: "auto"}}>
                            <table className="default-table table-unbordered table table-sm table-hover"
                                   style={{marginBottom: "0px"}}>
                                <tbody>
                                {

                                    this.state.data.length > 0 &&
                                    this.state.data.map((injury, index) => (
                                        this.rowFormatter(injury, index)
                                    ))
                                }
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>


            );
        }
    }
}

export default withRouter(IncidentCauseAnalytics);