import { Component } from 'react';
import { connect } from 'react-redux';

import { createTag, get_bulletin_tags, update_tags } from '../../../api/zero-api.js';
import { safe_get } from '../../../other/Helper.js';
import { update_feed_tags } from '../../../store/actions/FeedHelperActions';

import NotificationAlert from '../../../other/NotificationAlert';
import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import ManageTag from './ManageTagComponent.js';

import DelayedTextInput from 'components/Shared/DelayedTextInput.js';
import { ReorderableList } from 'components/Shared/ReorderableList.js';
import { update_post_field_names } from '../../../api/zero-api.js';
import { update_current_org } from '../../../store/actions/OrganizationActions';
import Button from 'components/Shared/Button.js';

class ManageTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedTags: props.tags,
            saveDisabled: true,
            loading: false,
            saving: false,
            custom_field_name: this.props.org.post_field_names.tag,
            autosaveTimer: null,
            creatingTag: false,
        };

        this.updateMade = this.updateMade.bind(this);
        this.save = this.save.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.tags !== prevProps.tags) {
            this.setState({updatedTags: this.props.tags});
        }
    }

    fieldNameChange = (name) => {
        this.setState(
            {custom_field_name: name},
            () => {
                this.saveFieldName();
            }
        );
    }

    saveFieldName = () => {
        let self = this;
        let body = JSON.stringify({
            ...this.props.org.post_field_names,
            tag: this.state.custom_field_name,
        });

        update_post_field_names(body).then(success => {
            success.json().then(success => {
                let org = safe_get(success, "organization", {});
                self.props.dispatch(update_current_org(org));
                self.setState({editFieldName: false});
                NotificationAlert("success", "", "Changes saved.");
            });
        }, error => {

        });
    }

    updateMade(new_tag) {
        if (this.state.autosaveTimer) {
            clearTimeout(this.state.autosaveTimer);
        }

        const updatedTags = [...this.state.updatedTags];
        const index = updatedTags.findIndex(function (obj) {
            return obj.tag_uuid === new_tag.tag_uuid
        });
        if (index >= 0) {
            updatedTags[index] = new_tag;
        } else {
            return;
        }

        this.setState({
            updatedTags,
            autosaveTimer: setTimeout(() => {
                this.save()
            }, 500)
        });
    }

    save() {
        var self = this;

        this.setState({saving: true, autosaveTimer: null});

        var feed_tags = this.props.feed_tags;
        var body = JSON.stringify({
            tags: this.state.updatedTags
        });

        update_tags(body).then(function (success) {
            success.json().then(success => {
                var new_tags = safe_get(success, "tags", []);
                new_tags.forEach(function (new_tag) {
                    var old_tag = feed_tags.find(function (obj) {
                        return obj.tag_uuid === new_tag.tag_uuid
                    });

                    var index = feed_tags.indexOf(old_tag);
                    if (index >= 0) {
                        new_tag.enabled ? feed_tags[index] = new_tag : feed_tags.splice(index, 1);
                    } else {
                        feed_tags.push(new_tag);
                    }
                });

                self.setState({saving: false});
                self.props.update();
                self.props.dispatch(update_feed_tags(feed_tags));
                NotificationAlert("success", "", "Changes saved.")

            })

        }, function (error) {
            self.setState({saving: false});
            NotificationAlert("success", "", "Could not save changes.")
        });
    }

    addNewTag = async () => {
        try {
            this.setState({creatingTag: true});
            const index = this.state.updatedTags.length;
            const body = JSON.stringify({
                name: `Tag ${index + 1}`,
                enabled: true,
                order: index,
            });
        
            await createTag(body);
            const res = await get_bulletin_tags("?all=1");
            const data = await res.json();
            const {tags} = data;
            this.props.update();
            this.props.dispatch(update_feed_tags(tags));
            NotificationAlert("success", "", `Added new ${this.state.custom_field_name}.`)
        } catch(err) {
            console.error(err);
            NotificationAlert("error", "", `Could not add new ${this.state.custom_field_name}.`)
        } finally {
            this.setState({creatingTag: false});
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <LoadingIndicator/>
            )
        } else {
            return (
                <div>

                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
                    </div>
                    <div style={{marginBottom: "5px"}}>
                        <div style={{display: "inline-block"}}>
                            <DelayedTextInput
                                className="form-control topic-field"
                                defaultValue={this.state.custom_field_name}
                                onChange={this.fieldNameChange}
                                maxLength={15}
                                showCharacterCounter={true}
                            />
                        </div>
                    </div>

                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}>
                            <b>Options</b>
                        </p>
                    </div>

                    <ReorderableList
                        items={this.state.updatedTags}
                        itemKeyProp="tag_uuid"
                        renderItem={(tag, index) => (
                            <ManageTag index={index} tag={tag} updateMade={this.updateMade}/>
                        )}
                        onReorder={(updatedTags) => {
                            this.setState({updatedTags}, () => {
                                this.save();
                            })
                        }}
                    />

                    <Button
                        type="primary"
                        disabled={this.state.creatingTag || this.state.updatedTags.length >= 25}
                        className="mar-top-15"
                        onClick={this.addNewTag}
                    >Add option</Button>
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        org: safe_get(store, "org_helper.organization", {}),
        feed_tags: safe_get(store, "feed_helper.tags", [])
    }
}

export default connect(mapStateToProps)(ManageTags);
