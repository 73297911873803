import {useEffect, useRef, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {Select} from 'antd';

import {get_dynamic_list} from 'api/zero-api';
import {useOfflineDataCachesContext} from 'components/Offline/OfflineDataCaches';
import { useZeroContext } from 'components/ZeroContext';
import { useSelector } from 'react-redux';
import { isPublicUser } from 'other/Helper';

async function loadDynamicList(cache, id, isOffline, user) {
    try {
        let list;

        if (isOffline || isPublicUser(user)) {
            list = await cache.get(id);
        } else {
            const res = await get_dynamic_list(id);
            list = await res.json();
        }

        return {
            status: 'ok',
            items: list.items,
        }
    } catch (err) {
        if (err.status === 404) {
            return {
                status: 'not-found',
                items: [],
            }
        }

        return {
            status: 'error',
            items: [],
        }
    }
}

const itemLimit = 50;

export default function DynamicList({listId, value, onChange, disabled}) {
    const [fetchStatus, setFetchStatus] = useState('loading');
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [filter, setFilter] = useState('');
    const originalValue = useRef(value);
    const {dynamicListCache} = useOfflineDataCachesContext();
    const {isOffline} = useZeroContext();
    const user = useSelector(state => state.user.user);

    // loads dynamic list on component mount if listId exists
    useEffect(() => {
        if (listId) {
            loadDynamicList(dynamicListCache, listId, isOffline, user).then(fetchState => {
                unstable_batchedUpdates(() => {
                    const {status} = fetchState
                    setFetchStatus(status);

                    if (status === 'ok') {
                        let itemsWithValue = [...fetchState.items];
                        if (originalValue.current && !itemsWithValue.includes(originalValue.current)) {
                            itemsWithValue.splice(0, 0, originalValue.current);
                        }
                        setItems(itemsWithValue);
                    } else if (['error', 'not-found'].includes(status)) {
                        const newOptions = [{
                            label: 'N/A',
                            value: 'N/A',
                        }];

                        if (originalValue.current) {
                            newOptions.splice(0, 0, {
                                label: originalValue.current,
                                value: originalValue.current,
                            });
                        }

                        setOptions(newOptions);
                    }
                })
            });
        }
    }, [listId, dynamicListCache]);

    // rebuilds options when items or filter changes
    useEffect(() => {
        let filteredItems = [...items];

        if (filter) {
            const lowerFilter = filter.toLowerCase();

            filteredItems = filteredItems.filter(value => {
                const lowerValue = value.toLowerCase();
                for (const part of lowerFilter.split(' ')) {
                    if (lowerValue.includes(part)) {
                        return true;
                    }
                }
                return false;
            });
        }

        const newOptions = filteredItems.slice(0, itemLimit).map(value => ({
            label: value,
            value
        }));

        if (items.length > itemLimit) {
            newOptions.push({
                label: 'Additional items are hidden. Change filter to see them.',
                value: '__this_should_never_be_an_option__d9407d5b-e0f7-42c3-bb40-1a99ac93b3b5',
                disabled: true
            })
        }

        setOptions(newOptions);
    }, [items, filter]);

    return (
        <div>
            <div>
                <Select
                    style={{width: '100%'}}
                    placeholder="Select or search..."
                    options={options}
                    loading={fetchStatus === 'loading'}
                    disabled={disabled || !['ok', 'not-found'].includes(fetchStatus)}
                    virtual={false}
                    dropdownStyle={{zIndex: '1200'}}
                    onChange={onChange}
                    value={value || null}
                    showSearch={true}
                    filterOption={false}
                    onSearch={setFilter}
                    allowClear={true}
                />
            </div>
            {fetchStatus === 'error' &&
                <p className='error mar-btm-0 mar-top-10'>Could not load dynamic list.</p>
            }
            {fetchStatus === 'not-found' &&
                <p className='error mar-btm-0 mar-top-10'>List has been deleted.</p>
            }
        </div>
    )
}