// @ts-check
import {
    CalendarOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PieChartOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import { Dropdown, Select } from "antd";
import { get_forms, update_post_embedded_form } from "api/zero-api";
import FormPreviewButton from 'components/AdminPages/ManageForms/FormPreviewButton';
import ManageFormsActions from 'components/AdminPages/ManageForms/ManageFormsActions';
import Button from "components/Shared/Button";
import Table from "components/Shared/Tables";
import ZeroTag from "components/Shared/ZeroTag";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import useOrganizationId from 'hooks/useOrganizationId';
import { dateFormatterNoTime, safe_get } from "other/Helper";
import { useEffect, useState } from "react";
import {IoIosMore as MoreIcon} from 'react-icons/io';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as organizationAction from "store/actions/OrganizationActions";


function generateFormOptions(forms) {
    return forms.map(form => ({label: form.name, value: form.form_uuid}));
}

async function updateEmbeddedForm(value) {
    const response = await update_post_embedded_form(value);
    const data = await response.json();
    const organization = safe_get(data, "organization", null);
    return organization;
}

function StatusTag({isActive}) {
    if (isActive) {
        return (
            <ZeroTag color="green">Active</ZeroTag>
        )
    }

    return (
        <ZeroTag color="grey">Inactive</ZeroTag>
    )
}

function TemplateRow({form, isActive, refresh}) {
    const orgId = useOrganizationId();

    return (
        <tr key={form.form_uuid}>
            <td>
                <ManageFormsActions
                    form={form}
                    onDelete={refresh}
                    refresh={refresh}
                />
            </td>
            <td>
                <div className="flex-col">
                    <Link
                        to={`/${orgId}/home/manage_post_templates/analytics/${form.form_uuid}`}
                        className="blue-link hover-cursor"
                    >
                        {form.name}
                    </Link>
                    <div>Last edited {dateFormatterNoTime(form.edited_at)}</div>
                </div>
            </td>
            <td>Post</td>
            <td><StatusTag isActive={isActive} /></td>
            <td><FormPreviewButton form={form} /></td>
        </tr>
    )
}

export default function BulletinTemplateTab() {
    const dispatch = useDispatch();
    const organization = useSelector(state => state.org_helper.organization);
    const embeddedFormId = organization.post_embedded_form_uuid;
    const formsLoader = useAsyncDataLoader();
    const [options, setOptions] = useState([]);

    const loadForms = () => {
        formsLoader.load(async () => {
            const response = await get_forms(`?form_types=post_embedded`);
            const data = await response.json();
            const forms = safe_get(data, "forms", []);
            const options = generateFormOptions(forms);
            setOptions(options);
            return forms;
        });
    }

    useEffect(() => {
        loadForms();
    }, []);

    const handleEmbeddedFormChange = async (value) => {
        const updatedOrg = await updateEmbeddedForm(value);
        if (updatedOrg) {
            dispatch(organizationAction.update_current_org(updatedOrg));
        }
    }

    return (
        <div>
            <h4 className="zero-blue" style={{marginTop: "0px"}}>Post Templates</h4>
            <p className="zero-dark-grey">Templates are an optional extension of your base post question set (controlled in <Link to={`/${organization.organization_uuid}/home/team/my_teams/feed/tab/settings`} className="blue-link">Settings</Link>). Templates allow you to ask additional fixed questions beyond the base post. You can create as many templates as you want, but only one template can be in use at a time. The in-use template will apply to all teams on the account.</p>
            <div className='flex' style={{alignItems: "center", gap: "0.5rem", marginBottom: '1rem'}}>
                <Select
                    placeholder="Select a template..."
                    style={{width: "100%", maxWidth: "400px"}}
                    value={formsLoader.status === "loading" ? "Loading..." : embeddedFormId}
                    loading={formsLoader.status === "loading"}
                    status={formsLoader.status === "error" ? "error" : undefined}
                    options={options}
                    onChange={handleEmbeddedFormChange}
                />
                { embeddedFormId &&
                    <Button
                        type="primary"
                        small
                        onClick={() => handleEmbeddedFormChange(null)}
                    >Disable</Button>
                }
            </div>
            <Table>
                <Table.HeaderRow>
                    <th style={{width: '5rem'}}>{/* Actions */}</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th style={{width: '4rem'}}>{/* Preview */}</th>
                </Table.HeaderRow>
                <Table.Body>
                { formsLoader.status === "success" && formsLoader.data.length > 0 &&
                    formsLoader.data.map(form => (
                        <TemplateRow
                            key={form.form_uuid}
                            form={form}
                            isActive={embeddedFormId === form.form_uuid}
                            refresh={loadForms}
                        />
                    ))
                }
                { formsLoader.status === "success" && formsLoader.data.length === 0 &&
                    <tr>
                        <td className="react-bs-table-no-data" colSpan={5}>
                            No Templates.
                        </td>
                    </tr>
                }
                </Table.Body>
            </Table>
        </div>
    )
}