import React, {Component} from 'react';

import {get_subscriber_user_options} from '../../api/zero-api.js'
import {safeProfilePic, isContributor, isAdmin, isTeamLead, isUser} from '../../other/Helper.js';
import safe_get from '../../other/SafeGet.js';

// import { Modal } from 'react-bootstrap';
import {Modal, Select, Checkbox} from 'antd';
import ButtonLoading from '../Shared/ButtonLoading.js';

import '../../assets/css/antd-custom.css'

class MovePostModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: "move",
            move_team_uuid: undefined,
            errorMsg: "",
            availableTeams: [],
            team_uuids: [],
            defaultValue: [],
            team_options: [],
            teams_list: [],
            select_all: false,
            selected_users: [],
            subscriber_options: [],
            viewers: [],
        };

        this.handleMoveTeamSelect = this.handleMoveTeamSelect.bind(this);
        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.selectAllUsers = this.selectAllUsers.bind(this);
        this.selectNoOne = this.selectNoOne.bind(this);
        this.confirmMove = this.confirmMove.bind(this);
    }

    componentDidMount() {

    }

    getSubscriberOptions(post_uuid) {
        var self = this;
        var body = JSON.stringify({
            team_uuids: [this.state.move_team_uuid]
        })
        get_subscriber_user_options(body).then(function (success) {
            success.json().then(success => {

                var users = safe_get(success, "users", []);

                self.setState({
                    subscriber_options: users,
                    step: "subscribe",
                    loading: false
                });

            });
        }, function (error) {

        });
    }

    handleMoveTeamSelect(value) {
        this.setState({
            move_team_uuid: value
        });
    }

    handleUserSelect(e) {
        let user_uuid = e.target.value;
        var selected_users = [...this.state.selected_users];
        var index = selected_users.indexOf(user_uuid);

        if (index >= 0) {
            selected_users.splice(index, 1);
        } else {
            selected_users.push(user_uuid)
        }

        this.setState({
            selected_users: selected_users,
            select_all: selected_users.length === this.state.subscriber_options.length
        });

    }

    selectAllUsers() {
        var selected_users = []
        for (var i in this.state.subscriber_options) {
            selected_users.push(this.state.subscriber_options[i].uuid)
        }

        this.setState({
            selected_users: selected_users,
            select_all: true
        });
    }

    selectNoOne() {
        this.setState({
            selected_users: [],
            select_all: false
        });
    }

    confirmMove() {
        if (this.state.step === "move") {
            this.setState({loading: true});
            this.getSubscriberOptions(this.props.post_uuid);
        } else {
            this.setState({loading: true});
            this.props.confirm(this.state.move_team_uuid, this.state.selected_users, this.state.select_all);
        }

    }

    render() {
        let is_Author = safe_get(this.props, "user.user_uuid", "") === safe_get(this.props, 'post.revised_by.user_uuid', null);

        let is_Admin = isAdmin(this.props.user);
        let is_TeamLead = isTeamLead(this.props.user);
        let is_Contributor = isContributor(this.props.user);
        let is_user = isUser(this.props.user);

        let canMove = (is_Admin || is_TeamLead || is_Author || is_Contributor || is_user) && (this.props.teams.length > 1);

        return (
            (<Modal
                title={this.state.step === "move" ? "Move post" : "Who should be notified?"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <p className="zero-dark-grey pull-left mar-top-5">Step {this.state.step === "move" ? "1" : "2"} of
                            2</p>
                        {
                            this.state.step === "move" &&
                            <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        }
                        {
                            this.state.step === "subscribe" &&
                            <button className="btn btn-discard" onClick={() => {
                                this.setState({step: "move"});
                            }}>Back</button>
                        }
                        <button className="btn btn-modal" onClick={this.confirmMove} disabled={!canMove}>
                            {
                                this.state.loading ?
                                    <ButtonLoading/> : this.state.step === "move" ? "Next" : "Move post"
                            }
                        </button>
                    </div>
                }
            >
                {
                    canMove && this.state.step === "move" &&
                    <div>
                        <p className="zero-dark-grey">Select the team you'd like to move this post to:</p>
                        <Select
                            style={{width: "100%",}}
                            className="move-team-select"
                            showArrow={true}
                            placeholder="Select a Team"
                            dropdownStyle={{zIndex: "10000"}}
                            value={this.state.move_team_uuid}
                            onChange={this.handleMoveTeamSelect.bind(this)}
                            virtual={false}
                        >
                            {
                                this.props.teams.map((team, index) => (
                                    <Select.Option key={team.uuid} value={team.uuid}
                                                   disabled={(team.uuid === this.props.source_team)}>{team.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                }
                {
                    canMove && this.state.step === "subscribe" &&
                    <div>
                        <p className="zero-dark-grey">When I move this post, the following people will be notified:</p>
                        <span className="blue-link" onClick={this.selectAllUsers}>Select everyone</span> &bull; <span
                        className="blue-link" onClick={this.selectNoOne}>Select no one</span>
                        <div className="subscribers-checkbox" style={{marginTop: "8px"}}>
                            {
                                this.state.subscriber_options.map((user, index) => (
                                    <div key={index} className="form-check" style={{height: "30px"}}>
                                        <Checkbox
                                            onChange={this.handleUserSelect}
                                            checked={this.state.selected_users.includes(user.uuid)}
                                            value={user.uuid}
                                        >
                                            {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                            {user.first_name} {user.last_name}
                                        </Checkbox>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    !canMove &&
                    <p className="zero-dark-grey">No teams available to move to. You're only a member of one team.</p>
                }
            </Modal>)
        );

    }

}

export default MovePostModal;