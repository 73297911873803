// @ts-check
import { getBulkSubmissions, get_bulletins, get_form } from "api/zero-api";

import _, { upperFirst } from "lodash";
import { FORM_ELEMENTS } from "other/Constants";
import { dateFormatterNoTime } from "other/Helper";
import NotificationAlert from "other/NotificationAlert";
import { AgGridFilter } from "other/agGridHelper";
import { getSubmissionAnswer, reorderFormFieldsWithSection } from "other/forms";
import {PostActionCell, RespondersCell, RiskCell, StatusCell, TitleCell} from "./FeedDataGrid.cells";

export const EventNames = {
    // Emitted when "Reset table" is clicked. Resets sorting and column order.
    RESET_TABLE: 'FeedDataGrid:resetTable',
    // Emitted when table should refetch datasource
    REFRESH_DATA: 'FeedDataGrid:refreshData',
}


/**
 * @param {any} organization
 * @returns {AgGrid.GridOptions['columnDefs']}
 */
export function getColumnDefs(organization, formHeaders) {
    const { post_field_names: fieldNames, custom_post_field: customPostField } = organization;

    /** @type {AgGrid.GridOptions['columnDefs']} */
    let defs = [
        {
            headerName: "",
            field: "_actions_",
            width: 50,
            cellRenderer: PostActionCell,
            resizable: false,
            sortable: false,
            suppressMovable: true,
            lockPosition: "left",
            cellStyle: {
                padding: 0,
            }
        },
        {
            headerName: fieldNames.title || "Title",
            field: "title",
            width: 200,
            cellRenderer: TitleCell,
            ...AgGridFilter.text,
        },
        {
            field: "status",
            width: 120,
            cellRenderer: StatusCell,
            valueFormatter: ({value}) => typeof value === "string" ? upperFirst(value) : "",
        },
        {
            headerName: "Opened Date",
            field: "revised_at",
            width: 200,
            valueFormatter: ({ value }) => value ? dateFormatterNoTime(value) : "",
            cellClass: "dateFormatterNoTime",
            initialSort: 'desc',
            ...AgGridFilter.date,
        },
        {
            headerName: "Closed Date",
            field: "closed_at",
            width: 200,
            valueFormatter: ({ value }) => (value ? dateFormatterNoTime(value) : ""),
            cellClass: "dateFormatterNoTime",
            ...AgGridFilter.date,
        },
        {
            headerName: "Due Date",
            field: "due_date",
            width: 200,
            valueFormatter: ({ value }) => (value ? dateFormatterNoTime(value) : ""),
            cellClass: "dateFormatterNoTime",
            ...AgGridFilter.date,
        },
        {
            headerName: "Post ID",
            field: "reference_number",
            width: 145,
            valueFormatter: ({value}) => value?.slice(-4),
            cellClass: 'stringType',
            ...AgGridFilter.contains,
        },
        {
            headerName: "Team",
            field: "source_team",
            valueFormatter: ({ value }) => value?.name ?? "",
            ...AgGridFilter.text,
        },
        {
            headerName: "Shared",
            field: "is_shared",
            width: 100,
            valueFormatter: ({ value }) => (value ? "Yes" : ""),
        },
        {
            headerName: fieldNames.category || "Category",
            field: "category",
            width: 200,
            valueFormatter: ({ value }) => value?.name ?? "",
            ...AgGridFilter.text,
        },
        {
            headerName: fieldNames.tag || "Tag",
            field: "tag",
            width: 160,
            valueFormatter: ({ value }) => value?.name ?? "",
            ...AgGridFilter.text,
        },
        {
            headerName: fieldNames.sub_status || "Status",
            field: "sub_status",
            width: 160,
            valueFormatter: ({ value }) => value?.name ?? "",
            ...AgGridFilter.text,
        },
        {
            headerName: fieldNames.sub_status_2 || "Status 2",
            field: "sub_status_2",
            width: 160,
            valueFormatter: ({ value }) => value?.name ?? "",
            ...AgGridFilter.text,
        },
        {
            headerName: fieldNames.severity_level || "Risk Level",
            field: "severity_level",
            cellRenderer: RiskCell,
            cellClass: "riskFormatter",
        },
        {
            headerName: customPostField.name,
            field: "custom_field_value",
            ...AgGridFilter.text,
        },
        {
            headerName: "Assignees",
            field: "assigns",
            cellRenderer: RespondersCell,
            valueFormatter: ({value}) => value ? value.map(u => `${u.first_name} ${u.last_name}`.trim()).join(', ') : "",
        },
        {
            headerName: "Attachments",
            field: "attachments",
            valueFormatter: ({ value }) => value?.length ?? 0,
        },
        {
            headerName: "Comments",
            field: "comments_count",
        },
        {
            headerName: "Views",
            field: "viewers_count",
        },
        {
            headerName: "Reactions",
            field: "reactions",
            valueFormatter: ({ value }) =>
                Array.isArray(value) ? value.reduce((sum, reaction) => sum + reaction.count, 0) : 0,
        },
    ];

    if (formHeaders?.length) {

        defs = defs.concat(formHeaders);
    }

    return defs;
}

export async function getEmbeddedForm(organization, kv) {
    const currentEmbeddedFormId = organization.post_embedded_form_uuid;
    if (currentEmbeddedFormId) {
        try {
            let form = kv.get(currentEmbeddedFormId);
            if (!form) {
                const res = await get_form(currentEmbeddedFormId);
                const data = await res.json();
                form = data?.form ?? null;
                kv.set(currentEmbeddedFormId, form);
            }
            return form;
        } catch (err) {
            NotificationAlert('error', '', 'Unable to load embedded form.');
        }
    }

    return null;
}

export function getFormFieldsForTable(form) {
    if (!form) {
        return {
            fields: [],
            headers: [],
        };
    }
    const fields = reorderFormFieldsWithSection(form.fields)
        .filter(field => FORM_ELEMENTS.forTableView.includes(field.element));
    const headers = fields.map(field => {
        const isCentered = FORM_ELEMENTS.centeredElements.includes(field.element);
        const isFilterable = FORM_ELEMENTS.filterable.includes(field.element);
        /** @type {AgGrid.ColDef} */
        const def = {
            headerName: field.label,
            headerTooltip: field.label,
            field: field.id,
            cellClass: field.element === 'DatePicker' ? "dateFormatterNoTime" : undefined,
            width: isCentered ? undefined : 200,
            sortable: false,
            filter: isFilterable,
            filterParams: {
                maxNumConditions: 1,
                filterOptions: [
                    {
                        displayKey: "formField",
                        displayName: "Contains",
                        predicate: () => true,
                    }
                ]
            },
            tooltipValueGetter: (p) => {
                if (p.location === "cell") {
                    if (!p.value || p.value === '-') {
                        return null;
                    }
                    return p.value;
                }
            },
        };

        return def;
    });
    
    return {
        fields,
        headers,
    }
}

export async function loadPosts(queryParams, kv, formId, formFields) {
    const res = await get_bulletins(`?${queryParams}`);
    const { posts, total_posts: totalPosts } = await res.json();
    await loadMissingEmbeddedSubmissions(kv, posts, formId);
    posts.forEach(post => {
        const subFields = getSubmissionFields(kv, post, formId, formFields?.fields ?? []);
        subFields.forEach(field => post[field.id] = field.value);
    })

    return {posts, totalPosts};
}

/**
 * 
 * @param {KeyValueCache} kv 
 * @param {any[]} posts 
 * @param {string} formId 
 */
async function loadMissingEmbeddedSubmissions(kv, posts, formId) {
    const submissionIds = [];
    posts.forEach(post => {
        const efs = post.embedded_forms.filter(ef => ef.form_uuid === formId);
        efs.forEach(ef => {
            if (!kv.get(ef.submission_uuid)) {
                submissionIds.push(ef.submission_uuid);
            }
        })
    });

    if (submissionIds.length > 0) {
        const res = await getBulkSubmissions(submissionIds);
        const submissions = await res.json();
        const entries = submissions.map(s => [s.submission_uuid, s]);
        kv.setMany(entries);
    }
}

function getSubmissionFields(kv, post, formId, formFields) {
    if (!formId) {
        return [];
    }

    try {
        const ef = post.embedded_forms.filter(ef => ef.form_uuid === formId);
        const defaultFormFields = () => {
            return formFields.map(formField => ({
                id: formField.id,
                value: "-",
            }));
        };

        if (ef.length > 0) {
            const submissionId = ef[0].submission_uuid;
            const submission = kv.get(submissionId);
            if (submission) {
                const submissionFields = _.keyBy(submission.fields, 'id');
                const answers = formFields.map(formField => {
                    const { id } = formField;
                    
                    let value = "-";
                    if (submissionFields[id]) {
                        value = getSubmissionAnswer(formField, submissionFields[id]) ?? "";
                    }

                    return {id, value};
                });
                return answers;
            } else {
                return defaultFormFields();
            }
        } else {
            return defaultFormFields();
        }
    } catch (err) {
        console.error(err);
    }
}