import React, {useEffect, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Collapse, Input, Upload, Tabs, Modal, Space, Checkbox} from 'antd';

const {Panel} = Collapse;
const {TabPane} = Tabs;
import {UploadOutlined, PaperClipOutlined, DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Editor} from '@tinymce/tinymce-react';

import FloorPlan from 'components/Spaces/FloorPlan';
import ButtonLoading from 'components/Shared/ButtonLoading';
import {createSurvey} from 'store/slices/manageSpaces';
import NotificationAlert from 'other/NotificationAlert';
import {beforeFileUpload, fileHasZeroSize, fileUpload, getErrorMessageFromResponse} from 'other/Helper';
import {update_space, create_space} from 'api/zero-api';


function getFloorPlanFileName(url) {
    if (url.includes('spaces/floor-plans/')) {
        const parts = url.split("/");
        let fileName = parts[parts.length - 1];
        return fileName.substring(37);
    } else {
        const parts = url.split("/");
        return parts[parts.length - 1];
    }
}

function SpacesTab({location}) {
    const [errorMessage] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [spaces, setSpaces] = useState(location.spaces);
    const [originalSpaces, setOriginalSpaces] = useState([...location.spaces]);
    const [uploadingIndex, setUploadingIndex] = useState(-1);
    const [saveCancelEnabled, setSaveCancelEnabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [finishedSaving, setFinishedSaving] = useState(false);

    useEffect(() => {
        let enabled = isDirty && uploadingIndex === -1 && !saving;
        setSaveCancelEnabled(enabled);
    }, [isDirty, uploadingIndex, saving]);

    useEffect(() => {
        if (finishedSaving) {
            setOriginalSpaces(spaces.map((space, index) => {
                if (!space._dirty && !space._error && !space._new) {
                    return {
                        uuid: space.uuid,
                        name: space.name,
                        floor_plan: space.floor_plan,
                    };
                } else if (!space._new) {
                    return originalSpaces[index];
                }
            }));
            setFinishedSaving(false);
        }
    }, [finishedSaving])

    const cancel = () => {
        setSpaces(originalSpaces);
        setIsDirty(false);
    }

    const save = () => {
        setSaving(true);
        const promises = spaces.map((space, index) => {
            return new Promise((resolve, reject) => {
                if (!space._dirty) {
                    return resolve([index, null, null]);
                } else {
                    let body = {
                        name: space.name,
                        floor_plan: space.floor_plan
                    };

                    if (space._new) {
                        body.location_uuid = location.uuid;
                    }

                    body = JSON.stringify(body);

                    const apiFunction = () => (space._new ? create_space(body) : update_space(space.uuid, body));

                    apiFunction()
                        .then(response => response.json())
                        .then(data => {
                            return resolve([index, data, null]);
                        })
                        .catch(async err => {
                            const errorMessage = await getErrorMessageFromResponse(err, "Could not update space.");
                            return resolve([index, null, errorMessage]);
                        })
                }
            })
        });

        Promise.all(promises).then(results => {
            let errorCount = 0;
            const newSpaces = [...spaces];

            results.map(result => {
                const [index, data, error] = result;

                if (!data && !error) return;

                if (data) {
                    newSpaces[index] = {
                        uuid: data.uuid,
                        name: data.name,
                        floor_plan: data.floor_plan,
                    };
                } else {
                    errorCount++;

                    newSpaces[index] = {
                        ...newSpaces[index],
                        _dirty: true,
                        _error: error,
                    }
                }
            });

            unstable_batchedUpdates(() => {
                setSaving(false);
                setFinishedSaving(true);
                setIsDirty(errorCount > 0 ? true : false);
                setSpaces(newSpaces);
            });

            if (errorCount === 0) {
                NotificationAlert('success', '', 'All spaces updated.');
            } else {
                NotificationAlert('error', '', 'Could not update all spaces.');
            }
        })
    }

    const beforeUpload = (file, index) => {
        if (fileHasZeroSize(file)) {
            return false;
        }
        setSpaceError(index, '');

        var name = file.name.toLowerCase();
        if (name.indexOf(".png") < 0 && name.indexOf(".jpg") < 0 && name.indexOf(".jpeg") < 0) {
            setSpaceError(index, 'Cannot upload this file type. Must be png, jpg, jpeg.');
            return false;
        }

        let [allow, error_msg] = beforeFileUpload(file, '', 0);

        if (allow) {
            return true;
        } else {
            setSpaceError(index, error_msg);
            return false;
        }
    }

    const floorPlanUploaded = (preSignedUrl, fileKey, index) => {
        const fileLocation = `${preSignedUrl}/${fileKey}`;

        if (index >= 0) {
            updateSpace(index, {
                floor_plan: fileLocation,
                _dirty: true
            });
            setUploadingIndex(-1);
            setIsDirty(true);
        }
    }

    const handleFileUpload = (file, index) => {
        setUploadingIndex(index);

        const params = JSON.stringify({
            feature_key: "spaces_floor_plan",
            content_type: file.file.type,
            file_name: file.file.name,
        });

        fileUpload(
            null, params, file,
            () => {
            },
            (preSignedUrl, fileKey) => floorPlanUploaded(preSignedUrl, fileKey, index),
            (error) => {
                setSpaceError(index, 'Could not upload file.');
                setUploadingIndex(-1);
            }
        );
    }

    const removeFloorPlan = (index) => {
        if (!saving) {
            updateSpace(index, {
                floor_plan: '',
                _dirty: true
            });
            setIsDirty(true);
        }
    }

    const changeName = (index, e) => {
        if (!saving) {
            updateSpace(index, {
                name: e.target.value,
                _dirty: true
            });
            setIsDirty(true);
        }
    }

    const updateSpace = (index, state) => {
        const currentSpace = {...spaces[index], ...state};
        const newSpaces = [...spaces];
        newSpaces.splice(index, 1, currentSpace);
        setSpaces(newSpaces);
    }

    const setSpaceError = (index, error) => {
        updateSpace(index, {_error: error});
    }

    const addNewSpace = () => {
        const newSpace = {
            uuid: null,
            name: '',
            floor_plan: '',
            _dirty: true,
            _new: true
        };

        setSpaces([...spaces, newSpace]);
    }

    return (
        <div>
            <div className="row">
                <div className="col-sm-6 pad-x-15 text-semibold">Spaces</div>
                <div className="col-sm-6 pad-x-15 text-semibold">Floor Plan</div>
            </div>
            {
                spaces.map((space, index) => (
                    <div key={index} className="row">
                        <div className="col-sm-6 pad-x-15 pad-y-5">
                            <Input
                                value={space.name}
                                onChange={e => changeName(index, e)}
                                disabled={saving}
                            />
                        </div>
                        <div className="col-sm-6 pad-x-15 pad-y-5">
                            {!space.floor_plan &&
                                <Upload
                                    accept=".png,.jpg,.jpeg"
                                    beforeUpload={file => beforeUpload(file, index)}
                                    customRequest={file => handleFileUpload(file, index)}
                                    disabled={saving || uploadingIndex !== -1}
                                    fileList={[]}
                                >
                                    <button className="btn btn-plain">
                                        {
                                            uploadingIndex === index ? <ButtonLoading className="inverted"/> :
                                                <span><UploadOutlined/> Upload</span>
                                        }
                                    </button>
                                </Upload>
                            }
                            {space.floor_plan &&
                                <div style={{marginTop: '0.5rem'}}>
                                    <FloorPlan floorPlan={space.floor_plan}>
                                        <PaperClipOutlined/>
                                        <span style={{paddingLeft: '0.5rem', paddingRight: '1.5rem'}}>
                                            {getFloorPlanFileName(space.floor_plan)}
                                        </span>
                                    </FloorPlan>
                                    <DeleteOutlined style={{color: 'red'}} onClick={() => removeFloorPlan(index)}/>
                                </div>
                            }
                        </div>
                        {space._error &&
                            <div className="col-xs-12">
                                <small className="error"
                                       style={{display: "block", marginBottom: "5px"}}>{space._error}</small>
                            </div>
                        }
                    </div>
                ))
            }
            <div className="mar-top-5">
                <button className="ButtonLink blue-link" onClick={addNewSpace}><PlusCircleOutlined/> Add space</button>
            </div>
            {errorMessage.length > 0 &&
                <small className="error" style={{display: "block", marginBottom: "5px"}}>{errorMessage}</small>
            }
            <div className="mar-top-15" style={{display: 'flex', justifyContent: 'space-between'}}>
                <button className="btn btn-discard mar-rgt-10" disabled={!saveCancelEnabled} onClick={cancel}>Cancel
                </button>
                <button className="btn btn-primary" disabled={!saveCancelEnabled} onClick={save}>
                    {
                        saving ? <ButtonLoading/> : 'Update'
                    }
                </button>
            </div>
        </div>
    )
}

function SurveyTab({location}) {
    const {survey} = location;
    const [originalStartParagraph, setOriginalStartParagraph] = useState('');
    const [startParagraph, setStartParagraph] = useState('');
    const [originalQuestions, setOriginalQuestions] = useState(['']);
    const [questions, setQuestions] = useState(['']);
    const [isDirty, setIsDirty] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const dispatch = useDispatch();
    const createSurveyStatus = useSelector(state => state.manageSpaces.createSurveyStatus);
    const {loading, error, ok} = createSurveyStatus;

    useEffect(() => {
        if (survey) {
            setOriginalStartParagraph(survey.start_paragraph);
            setStartParagraph(survey.start_paragraph);
            setOriginalQuestions(survey.questions);
            setQuestions(survey.questions);
        } else {
            setOriginalStartParagraph('');
            setStartParagraph('');
            setOriginalQuestions(['']);
            setQuestions(['']);
        }
    }, [survey]);

    useEffect(() => {
        if (hasSaved) {
            setHasSaved(false);
            setIsDirty(false);
            setOriginalStartParagraph(startParagraph);
            setOriginalQuestions(questions);

        }
    }, [error, ok, hasSaved])

    const updateQuestion = (e, index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1, e.target.value);
        setQuestions(newQuestions);
        setIsDirty(true);
    };

    const removeQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
        setIsDirty(true);
    };

    const addQuestion = () => {
        setQuestions([...questions, ''])
        setIsDirty(true);
    };

    const onEditorChange = content => {
        setStartParagraph(content);
        setIsDirty(true);
    };

    const cancel = () => {
        setStartParagraph(originalStartParagraph);
        setQuestions(originalQuestions);
        setIsDirty(false);
    }

    const save = () => {
        const payload = {
            locationId: location.uuid,
            body: {
                'questions': questions.filter(q => q.length > 0),
                'start_paragraph': startParagraph,
                'end_paragraph': location.end_paragraph
            }
        };
        setHasSaved(true);
        dispatch(createSurvey(payload));
    };

    return (
        (<div>
            {showPreview &&
                <Modal
                    open={true}
                    title="Survey Preview"
                    onCancel={() => setShowPreview(false)}
                    footer={null}
                    width={830}
                >
                    <div className="zero-dark-grey">
                        <Space direction="vertical" size={4}>
                            {startParagraph &&
                                <span
                                    className="location-survey"
                                    style={{margin: '0 0 1rem 0'}}
                                    dangerouslySetInnerHTML={{__html: startParagraph}}
                                ></span>
                            }
                            {
                                questions.filter(q => q.trim().length > 0).map((question, index) => (
                                    <Checkbox
                                        key={index}
                                        checked={false}
                                        className="zero-dark-grey"
                                        style={{textIndent: '-2.5rem', paddingLeft: '2.5rem'}}
                                    >
                                        {question}
                                    </Checkbox>
                                ))
                            }
                        </Space>
                    </div>
                </Modal>
            }
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="text-semibold mar-btm-5">Check-In Survey</div>
                <button className="btn btn-xs btn-default expand-button" onClick={() => setShowPreview(true)}>Preview
                </button>
            </div>
            <p className="zero-dark-grey" style={{margin: '0.4rem 0 2rem'}}>This page allows you to create a survey that
                end users must acknowledge before completing check-in. In order to enable this survey on the check-in
                page, you must have at least one checkbox response below. </p>
            <Editor
                apiKey='k8pzwxerydel2fec8qy1mel2q6ui0m6vduu5r57o1ok5yn1j'
                init={{
                    content_style: "body { color: #505050; font-size: 13px; font-weight: normal;} @media screen and (max-width: 700px) { body { font-size: 16px !important; } }",
                    branding: false,
                    elementpath: false,
                    menubar: false,
                    height: "300",
                    relative_urls: false,
                    convert_urls: true,
                    remove_script_host: false,
                    default_link_target: '_blank',
                    toolbar1: "undo redo | formatselect | fontsizeselect | bold italic underline strikethrough forecolor backcolor",
                    toolbar2: "alignleft aligncenter alignright alignjustify | bullist numlist | link image media | removeformat ",
                }}
                id={`tinymce-editor-${location.uuid}`}
                value={startParagraph}
                onEditorChange={onEditorChange}
                media_live_embeds={true}
                plugins={"autolink paste directionality visualblocks visualchars image link media table charmap hr pagebreak nonbreaking insertdatetime advlist lists checklist wordcount mediaembed textpattern help"}
            />
            <p className="zero-dark-grey" style={{margin: '2rem 0'}}></p>
            <div className="text-semibold mar-btm-5">Checkbox Responses</div>
            {
                questions.map((question, index) => (
                    <div key={index} style={{display: 'flex', marginBottom: '1rem'}}>
                        <Input
                            placeholder="Add response..."
                            style={{width: '50rem'}}
                            value={question}
                            onChange={e => updateQuestion(e, index)}
                        />
                        <div style={{marginLeft: '2rem'}}>
                            <DeleteOutlined
                                onClick={() => removeQuestion(index)}
                                style={{color: 'red', verticalAlign: 'bottom'}}
                            />
                        </div>
                    </div>

                ))
            }
            {
                questions.length < 10 &&
                <button className="ButtonLink blue-link" onClick={() => addQuestion()}>Add another response</button>
            }
            <div className="mar-top-15" style={{display: 'flex', justifyContent: 'space-between'}}>
                <button className="btn btn-discard mar-rgt-10" disabled={!isDirty || loading}
                        onClick={() => cancel()}>Cancel
                </button>
                <button className="btn btn-primary" disabled={!isDirty || loading} onClick={() => save()}>
                    {
                        loading ? <ButtonLoading/> : 'Update'
                    }
                </button>
            </div>
        </div>)
    );
}

const tabOption = {
    SPACES: 'spaces',
    SURVEY: 'survey',
    SETTINGS: 'settings'
}


export default function ManageSpacesLocations() {
    const locations = useSelector(state => state.manageSpaces.locations);
    const spacesLoader = useSelector(state => state.manageSpaces.spacesLoader);
    const {error, ok} = spacesLoader;

    if (error) {
        return <div>Could not load spaces.</div>
    }

    return (
        <div style={{padding: '1.5rem'}}>
            <h4 className="zero-blue">Office Locations</h4>
            <p className="zero-dark-grey" style={{fontSize: 14}}>Here is the list of available locations that users will
                be able to select from when they go to check in. You can edit the spaces and surveys within each
                location by clicking on the location name.</p>
            {ok &&
                <Collapse accordion>
                    {
                        Object.values(locations).map(location => (
                            <Panel key={location.uuid} header={<span className="text-semibold">{location.name}</span>}>
                                <Tabs defaultActiveKey={tabOption.SPACES}>
                                    <TabPane tab="Spaces" key={tabOption.SPACES}>
                                        <SpacesTab location={location}/>
                                    </TabPane>
                                    <TabPane tab="Survey" key={tabOption.SURVEY}>
                                        <SurveyTab location={location}/>
                                    </TabPane>
                                </Tabs>
                            </Panel>
                        ))
                    }
                </Collapse>
            }
        </div>
    )
}
