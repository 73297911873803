import { Alert } from "antd";
import { AlertOutlined } from '@ant-design/icons';
import { useMemo } from "react";

import { useOfflineDataCachesContext } from "../OfflineDataCaches";

export default function FormsSyncErrorAlert({description, ...props}) {
    const {submissionDrafts: {drafts}, sync, isSyncing} = useOfflineDataCachesContext();

    const errorCount = useMemo(() => {
        return drafts.filter(draft => draft.$error).length;
    }, [drafts]);

    if (errorCount === 0 || isSyncing) {
        return null;
    }

    const fullDescription = (
        <span className="zero-dark-grey">
            <p className="mar-no">A sync error can occur for a number of reasons — a bad connection, a dropped connection, template changes, etc. 
            Please make sure you have a stable connection, and your device remains "awake" until the sync is complete.</p>
            <p></p>
            <p className="mar-no">{description}</p>
        </span>
    );

    return (
        <Alert
            className="ant-alert-message-no-bottom-margin"
            message={<span className="text-semibold">Sync Error ({errorCount}) <button className="ButtonLink blue-link" onClick={() => {sync()}}>Retry sync</button></span>}
            description={fullDescription}
            type="warning"
            showIcon
            icon={<AlertOutlined />}
            {...props}
        />
    )
}