import React from "react";

import {mainContentContainerClass} from "other/Helper";

/**
 * @typedef {{addRoomForHelpWidget?: boolean}} MainContentProps
 * @param {React.PropsWithChildren<MainContentProps>} props
 * @returns 
 */
export function MainContent({addRoomForHelpWidget, children, ...props}) {
    const classNames = [mainContentContainerClass()];
    if (addRoomForHelpWidget) {
        classNames.push('room-for-help-widget');
    }

    return (
        <div className={classNames.join(' ')} {...props}>
            {children}
        </div>
    )
}

export function PageHead({children, ...props}) {
    return (
        <div id="page-head" className="no-padding-mobile" {...props}>
            {children}
        </div>
    )
}

export function PageContent({children, noTopPadding = false, style = {}, ...props}) {
    const defaultStyle = {...style}

    if (noTopPadding) {
        defaultStyle.paddingTop = 0;
    }

    return (
        <div id="page-content" className="no-padding-mobile" style={defaultStyle} {...props}>
            {children}
        </div>
    )
}

export function BorderedPanel({children, noPadding = false, topMargin = false, ...props}) {
    const classNames = [
        'panel',
        'panel-border'
    ];

    if (!noPadding) {
        classNames.push('panel-zero');
    }

    if (topMargin) {
        classNames.push('panel-top-margin');
    }

    if (props.className) {
        classNames.push(...props.className.split(' '));
        delete props.className;
    }


    return (
        <div className={classNames.join(' ')} {...props}>
            {children}
        </div>
    )
}
