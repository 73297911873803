import React, {Component} from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router';

import moment from 'moment'
import Skeleton from 'react-loading-skeleton';

import UserPopoverCard from '../Shared/UserPopoverCard.js';

import {dashboard_info, get_open_assignments, get_closed_assignments} from '../../api/zero-api.js'
import {
    safe_get, scrollToTop, mainContentContainerClass,
    dateFormatterFromString, formatAssignments, formatFormsAssignments,
    dateFormatterNoTime, safeProfilePic
} from '../../other/Helper.js';

import {
    ToolOutlined, CheckCircleOutlined, FormOutlined,
    SolutionOutlined, PushpinOutlined, ClockCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

import ButtonLoading from '../Shared/ButtonLoading.js';

import {update_current_user, update_user_analytics} from '../../store/actions/UserActions'
import {
    updateLatestActivity,
    updateAssignmentsCount,
    updateClosedAssignments,
    updateOpenAssignments,
    updateLoadingDashboard
} from '../../store/actions/DashboardActions'
import TeamListPopover from '../Shared/TeamListPopover.js';
import SeparatedAssignmentsLists from '../Shared/SeparatedAssignmentsLists.js';
import CheckInAlert from 'components/Spaces/CheckInAlert.js';
import Column from 'antd/es/table/Column.js';

class Dashboard extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "open",
            openAssignmentsPage: 1,
            totalOpenAssignments: 0,
            closedAssignmentsPage: 1,
            totalClosedAssignments: 0,
            latestActivityLoaded: false,
        };

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            let redirect = params.get('redirect');
            if (redirect) {
                if (!redirect.startsWith('/')) {
                    redirect = '/' + redirect;
                }
                this.props.history.replace(this.props.location.pathname); // remove redirect 
                this.props.history.push(redirect);
                return;
            }
        }

        scrollToTop("page-head");
        this.getDashboadInfo()
    }

    getDashboadInfo = () => {
        var self = this;
        self.props.dispatch(updateLoadingDashboard(true));
        dashboard_info().then(function (success) {
            success.json().then(success => {
                let stats = safe_get(success, "user_stats.user_stats", {});
                self.props.dispatch(update_user_analytics(stats));
                self.props.dispatch(updateAssignmentsCount(stats.open_assignments));

                let newUser = safe_get(success, "user_stats.user", self.props.user);
                self.props.dispatch(update_current_user(newUser));

                let openAssignments = safe_get(success, "open_assignments.assignments", []);
                self.props.dispatch(updateOpenAssignments(openAssignments));

                let closedAssignments = safe_get(success, "closed_assignments.assignments", []);
                self.props.dispatch(updateClosedAssignments(closedAssignments));

                let latestActivity = safe_get(success, "recent_posts", []).splice(0, 5);
                self.props.dispatch(updateLatestActivity(latestActivity));

                if (self._isMounted) {
                    self.setState({
                        totalOpenAssignments: safe_get(success, "open_assignments.total_assignments", 0),
                        totalClosedAssignments: safe_get(success, "closed_assignments.total_assignments", 0),
                        latestActivityLoaded: true
                    });
                }

                self.props.dispatch(updateLoadingDashboard(false));
            });
        }, function (error) {
            console.log(error);
        });
    }


    greetingText = () => {
        let name = this.props.user.first_name;

        let now = new Date();
        var h = now.getHours();
        if (h < 12) {
            return "Good morning, " + name;
        } else if (h < 18) {
            return "Good afternoon, " + name
        } else {
            return "Good evening, " + name
        }
    }

    handleMenuClick = (e) => {
        this.setState({
            selectedTab: e.key,
        });
    }

    goToAssignment = (assignment) => {

        let isOverDue = moment(assignment.due_date).isBefore(moment().format("YYYY-MM-DD"))
        let path = "/" + this.props.org_uuid + '/home/dashboard';

        if (assignment.type === "form") {
            if (assignment.completed) {
                path = '/' + this.props.org_uuid + '/home/team/' + assignment.team_uuids[0] + '/forms/submission_view/' + assignment.submission_uuid;
            } else if (!isOverDue) {
                if (assignment.submission_uuid) {
                    path = '/' + this.props.org_uuid + '/home/team/' + assignment.team_uuids[0] + '/forms/' + assignment.form_uuid + '/submission/' + assignment.submission_uuid;
                } else {
                    path = '/' + this.props.org_uuid + '/home/team/' + assignment.team_uuids[0] + '/forms/' + assignment.form_uuid + '/assignment/' + assignment.assignment_uuid + '/submit';
                }
            }
        } else if (assignment.type === "course") {
            if (assignment.completed) {
                path = '/' + this.props.org_uuid + '/home/team/' + assignment.team_uuids[0] + '/courses/submission_view/' + assignment.submission_uuid;
            } else if (!isOverDue) {
                if (assignment.submission_uuid) {
                    path = '/' + this.props.org_uuid + '/home/team/' + assignment.team_uuids[0] + '/courses/' + assignment.form_uuid + '/submission/' + assignment.submission_uuid;
                } else {
                    path = '/' + this.props.org_uuid + '/home/team/' + assignment.team_uuids[0] + '/courses/' + assignment.form_uuid + '/assignment/' + assignment.assignment_uuid + '/submit';
                }
            }


        } else if (assignment.type === "post" || assignment.post_uuid) {
            path = '/' + this.props.org_uuid + '/home/team/my_teams/feed/post/' + assignment.post_uuid;
        }

        this.props.history.push(path);
    }

    latestActivityFormatter = (post, index) => {
        let author = post.revised_by;
        return (
            <div key={index}>
                <div className="link-hover mar-btm-5 zero-blue" onClick={() => this.goToAssignment(post)}>
                    <strong>{post.title}</strong>
                </div>
                <p className="mar-btm-5 zero-dark-grey">
                    In {post.source_team.name}
                </p>

                <div>
                    <div className="media-left" style={{paddingRight: "7px"}}>
                        {safeProfilePic(author, "img-sm img-circle bulletin", "bulletin")}
                    </div>

                    <div className="media-body bulletin">
                        <UserPopoverCard user={author}>
                            <h3 className="author bulletin truncate name long link-hover zero-dark-grey"
                                style={{fontSize: '12px'}}>{author.first_name} {author.last_name}</h3>
                        </UserPopoverCard>
                        <br/>
                        <h3 className="author bulletin date">{dateFormatterNoTime(post.revised_at)}</h3>
                    </div>
                </div>

                <hr style={{margin: "10px 0px"}}/>
            </div>
        )
    }

    loadMoreOpenAssignments = () => {
        var self = this;
        this.setState({loadingOpenAssignments: true});
        let page = this.state.openAssignmentsPage + 1;
        get_open_assignments("?page=" + page).then(success => {
            success.json().then(success => {
                let assignments = safe_get(success, "assignments", []);
                let allOpenAssignments = self.props.open_assignments.concat(assignments);
                self.props.dispatch(updateOpenAssignments(allOpenAssignments));
                self.setState({openAssignmentsPage: page, loadingOpenAssignments: false});
            });
        });
    }

    loadMoreClosedAssignments = () => {
        var self = this;
        this.setState({loadingClosedAssignments: true});
        let page = this.state.closedAssignmentsPage + 1;
        get_closed_assignments("?page=" + page).then(success => {
            success.json().then(success => {
                let assignments = safe_get(success, "assignments", []);
                let allClosedAssignments = self.props.closed_assignments.concat(assignments);
                self.props.dispatch(updateClosedAssignments(allClosedAssignments));
                self.setState({closedAssignmentsPage: page, loadingClosedAssignments: false});
            });
        });
    }

    render() {
        let openAssignments = this.props.open_assignments;
        let pastAssignments = this.props.closed_assignments;

        return (
            <div className={mainContentContainerClass()}>
                <div id="page-head" className="no-padding-mobile"></div>

                <div id="page-content" className="no-padding-mobile" style={{paddingTop: 0}}>
                    {safe_get(this.props, "organization.feature_flags.desks", false) && this.props.user.show_check_in_dashboard_alert &&
                        <div className="row">
                            <div className="col-lg-12">
                                   {!this.state.latestActivityLoaded && 
                                    <div className="panel thin-border"
                                         style={{marginBottom: '1rem', padding: '15px 15px 15px 24px'}}>
                                        <div style={{display:'flex', gap:'1.5rem'}}>
                                                <Skeleton width={24} height={24} circle/>
                                                <Skeleton width={150} height={24}/>
                                        </div> 
                                        <div style={{marginTop:'1rem'}}>
                                            <div style={{marginLeft:'4rem'}}>
                                                <Skeleton width={200} height={14}/>         
                                            </div>
                                            <div  style={{marginLeft:'4rem'}}>
                                                <Skeleton width={150} height={14}/>         
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.latestActivityLoaded &&
                                    <div className="panel" style={{marginBottom: '1rem'}}>
                                        <CheckInAlert/>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <div className="row hidden-xs">
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div
                                className="panel panel-warning panel-colorful media middle pad-all mar-btm-10 zero-gold-background">
                                <div className="media-left">
                                    <div className="pad-hor">
                                        <ToolOutlined style={{fontSize: "35px"}}/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <p className="text-2x mar-no text-semibold">{safe_get(this.props, "user_stats.open_assignments", 0)}</p>
                                    <p className="mar-no">Open<br/>{safe_get(this.props, "user_stats.open_assignments", 1) === 1 ? "Assignment" : "Assignments"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div
                                className="panel panel-info panel-colorful media middle pad-all mar-btm-10 zero-plat-background">
                                <div className="media-left">
                                    <div className="pad-hor">
                                        <CheckCircleOutlined style={{fontSize: "35px"}}/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <p className="text-2x mar-no text-semibold">{safe_get(this.props, "user_stats.completed_assignments", 0)}</p>
                                    <p className="mar-no">Completed<br/>{safe_get(this.props, "user_stats.completed_assignments", 0) === 1 ? "Assignment" : "Assignments"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div
                                className="panel panel-info panel-colorful media middle pad-all mar-btm-10 zero-bronze-background">
                                <div className="media-left">
                                    <div className="pad-hor">
                                        <FormOutlined style={{fontSize: "35px"}}/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <p className="text-2x mar-no text-semibold">{safe_get(this.props, "user_stats.posts_created", 0)}</p>
                                    <p className="mar-no">Posts<br/>Created</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div
                                className="panel panel-danger panel-colorful media middle pad-all mar-btm-10 zero-blue-background">
                                <div className="media-left">
                                    <div className="pad-hor">
                                        <SolutionOutlined style={{fontSize: "35px"}}/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <p className="text-2x mar-no text-semibold">{safe_get(this.props, "user_stats.forms_submitted", 0)}</p>
                                    <p className="mar-no">Forms<br/>Submitted</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{paddingLeft: "5px", paddingRight: "5px"}}>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                            <SeparatedAssignmentsLists
                                loading={this.props.loading}
                                organization_uuid={this.props.org_uuid}
                                openAssignments={openAssignments}
                                pastAssignments={pastAssignments}
                                loadMoreOpenAssignments={this.loadMoreOpenAssignments}
                                showLoadMoreOpenAssignments={this.state.totalOpenAssignments > (25 * this.state.openAssignmentsPage)}
                                loadingOpenAssignments={this.state.loadingOpenAssignments}
                                loadMorePastAssignments={this.loadMoreClosedAssignments}
                                showLoadMorePastAssignments={this.state.totalClosedAssignments > (25 * this.state.closedAssignmentsPage)}
                                loadingPastAssignments={this.state.loadingClosedAssignments}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border" style={{height: "550px"}}>
                                <ul className="nav nav-tabs nav-justified toggle-tabs">
                                    <li className="active">
                                        <button className="ButtonLink" style={{height: "55px", cursor: "default"}}>
                                            Latest Activity
                                        </button>
                                    </li>
                                </ul>
                                {
                                    this.props.loading &&
                                    <div className="panel-body no-top-pad mar-top-5"
                                         style={{maxHeight: "480px", overflow: "auto"}}>
                                        {[...Array(5)].map((_item, index) =>
                                            <div key={index}>
                                                <h5 className="link-hover mar-btm-5 zero-blue">
                                                    <Skeleton width={150}/>
                                                </h5>
                                                <p className="mar-btm-5 zero-dark-grey">
                                                    <Skeleton width={120}/>
                                                </p>

                                                <div>
                                                    <div className="media-left" style={{paddingRight: "7px"}}>
                                                        <Skeleton circle width={26} height={26}/>
                                                    </div>
                                                    <div className="media-body bulletin">
                                                        <h3 className="author bulletin truncate name long link-hover">
                                                            <Skeleton width={80}/>
                                                        </h3>
                                                        <br/>
                                                        <h3 className="author bulletin date"><Skeleton width={60}/></h3>
                                                    </div>
                                                </div>
                                                <hr style={{margin: "10px 0px"}}/>
                                            </div>
                                        )}
                                    </div>
                                }
                                {
                                    !this.props.loading &&
                                    <div className="panel-body no-top-pad mar-top-10"
                                         style={{maxHeight: "480px", overflow: "auto"}}>
                                        {
                                            this.props.latest_activity.length === 0 &&
                                            <p className="zero-dark-grey">No activity yet.</p>
                                        }
                                        {
                                            this.props.latest_activity.length > 0 &&
                                            this.props.latest_activity.map((post, index) => (
                                                this.latestActivityFormatter(post, index)
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                    </div>

                </div>


            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        user_stats: safe_get(store, "user.user_stats", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", {}),
        open_assignments: safe_get(store, "dashboard.openAssignments", []),
        closed_assignments: safe_get(store, "dashboard.closedAssignments", []),
        latest_activity: safe_get(store, "dashboard.latestActivity", []),
        loading: safe_get(store, "dashboard.loading", false)
    }
}

export default withRouter(connect(mapStateToProps)(Dashboard));