import { useCallback, useState } from 'react';

import { update_incident_status } from 'api/zero-api';
import InfoIconComponent from 'components/InfoIconComponent';
import UserPopoverCard from 'components/Shared/UserPopoverCard';
import { IncidentStatus } from 'other/Constants';
import { safeProfilePic } from 'other/Helper';
import NotificationAlert from 'other/NotificationAlert';
import IncidentCommentInput from './Incident/IncidentCommentInput';
import StatusSelect from 'components/Shared/StatusSelect';


/** @type {StatusSelectOption[]} */
const incidentStatusOptions = [
    {
        value: IncidentStatus.OPEN,
        label: "Open",
        color: "grey",
    },
    {
        value: IncidentStatus.IN_REVIEW,
        label: "In Review",
        color: "yellow",
    },
    {
        value: IncidentStatus.CLOSED,
        label: "Closed",
        color: "green",
    },
];

export function IncidentStatusSelect({incident, onStatusUpdated}) {
    const [status, setStatus] = useState(incident.status);
    const [updating, setUpdating] = useState(false);

    const updateStatusHandler = useCallback((newStatus) => {
        if (!updating) {
            setStatus(newStatus);
            setUpdating(true);
            const body = JSON.stringify({status: newStatus});

            update_incident_status(incident.incident_uuid, body)
                .then(() => onStatusUpdated(newStatus))
                .catch(err => {
                    console.error(err);
                    NotificationAlert('error', '', 'Could not update status.');
                })
                .finally(() => {
                    setUpdating(false);
                })
        }
    }, [incident, onStatusUpdated, updating, setUpdating]);

    return (
        <StatusSelect
            value={status}
            options={incidentStatusOptions}
            disabled={updating}
            onChange={updateStatusHandler}
        />
    )
}


export default function IncidentDetails({
    incident,
    managers,
    onCommentCreated,
    style = {},
    className = ''
}) {
    return (
        <div style={style} className={className}>
            <div className="text-semibold">Managers<InfoIconComponent position={"bottom"} width={"200px"}
                                                                      text={'Managers are controlled via the "Manage Locations" page.'}/>
            </div>
            <div className="zero-dark-grey">{managers.length} {managers.length === 1 ? "person" : "people"} can view, edit, and comment.
            </div>
            {
                managers.map(user => (
                    <UserPopoverCard
                        key={user.uuid}
                        user={user}
                    >
                        <span style={{cursor: "pointer"}}>
                            {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                marginRight: "5px",
                                display: "inline-block"
                            })}
                        </span>
                    </UserPopoverCard>
                ))
            }

        <hr/>
             <IncidentCommentInput
                managers={managers}
                incidentId={incident.incident_uuid}
                onCommentCreated={onCommentCreated}
            />

        </div>

        
    );
}