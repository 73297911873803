import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Collapse} from 'react-collapse';
import ButtonLoading from '../../Shared/ButtonLoading';
import NotificationAlert from '../../../other/NotificationAlert.js';
import StatusComponent from './ManageStatusSettigs/StatusComponent'

import {Checkbox} from 'antd'

import {
    toggle_incidents_enabled,
    get_incident_injuries,
    update_incident_injuries,
    get_event_types,
    update_event_types,
    get_incident_causes,
    update_incident_causes,
    get_custom_fields,
    get_custom_field
} from '../../../api/zero-api.js';
import {scrollToTop, safe_get, getErrorMessage} from '../../../other/Helper.js'

import * as organizationAction from '../../../store/actions/OrganizationActions'
import {CUSTOM_FIELD_TYPES} from 'other/Constants';
import ManageCustomFields from 'components/Shared/ManageCustomFields';

class IncidentSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manageTypesOpen: false,
            manageCauseOpen: false,
            manageCustomField1Open: false,
            manageCustomField2Open: false,
            manageCustomField3Open: false,
            manageCustomField4Open: false,
            manageInjuryOpen: false,
            incident_causes: [],
            updated_causes: [],
            event_types: [],
            updated_events: [],
            incident_injuries: [],
            updated_injuries: [],
            injuryTypesError: '',
            incidentCausesError: '',
            eventTypesError: '',
            injuryAutosaveTimer: null,
            incidentAutosaveTimer: null,
            eventAutosaveTimer: null,
            customField1: null,
            customField2: null,
            customField3: null,
            customField4: null,
        };

        this.customField1Timeout = null;
        this.customField2Timeout = null;
        this.customField3Timeout = null;
        this.customField4Timeout = null;

        this.getCauses = this.getCauses.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.getInuries = this.getInuries.bind(this);

        this.optionSelect = this.optionSelect.bind(this);

        this.toggleManageInjury = this.toggleManageInjury.bind(this);
        this.toggleManageCause = this.toggleManageCause.bind(this);
        this.toggleManageTypes = this.toggleManageTypes.bind(this);

        this.causeUpdateMade = this.causeUpdateMade.bind(this);
        this.eventUpdateMade = this.eventUpdateMade.bind(this);
        this.injuriesUpdateMade = this.injuriesUpdateMade.bind(this);

        this.saveCauses = this.saveCauses.bind(this);
        this.saveEvents = this.saveEvents.bind(this);
        this.saveInjuries = this.saveInjuries.bind(this);
    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getCauses();
        this.getEvents();
        this.getInuries();
        this.getCustomFields();
    }


    getCauses() {
        var self = this;
        get_incident_causes("?all=1").then(function (success) {
            success.json().then(success => {
                let incident_causes = safe_get(success, "incident_causes", []);
                var incident_causes_enabled = incident_causes.map(x => x.enabled).includes(true)
                self.setState({
                    incident_causes: incident_causes,
                    incident_causes_enabled: incident_causes_enabled
                });
            });
        });
    }

    getEvents() {
        var self = this;
        get_event_types("?all=1").then(function (success) {
            success.json().then(success => {
                let event_types = safe_get(success, "event_types", []);
                let event_types_enabled = event_types.map(x => x.enabled).includes(true)
                self.setState({
                    event_types: event_types,
                    event_types_enabled: event_types_enabled
                });
            });
        });
    }

    getInuries() {
        var self = this;
        get_incident_injuries("?all=1").then(function (success) {
            success.json().then(success => {
                let incident_injuries = safe_get(success, "incident_injuries", []);
                let incident_injuries_enabled = incident_injuries.map(x => x.enabled).includes(true)
                self.setState({
                    incident_injuries: incident_injuries,
                    incident_injuries_enabled: incident_injuries_enabled
                });
            });
        });
    }

    getCustomFields = async () => {
        try {
            const response = await get_custom_fields();
            const customFields = await response.json();
            const customField1 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_1);
            const customField2 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_2);
            const customField3 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_3);
            const customField4 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_4);

            if (customField1) {
                this.setState({customField1});
            }
            if (customField2) {
                this.setState({customField2});
            }
            if (customField3) {
                this.setState({customField3});
            }
            if (customField4) {
                this.setState({customField4});
            }
        } catch (err) {
            console.error('Could not get custom fields:', err);
        }
    }

    refreshCustomField1 = () => {
        try {
            clearTimeout(this.customField1Timeout);
            this.customField1Timeout = setTimeout(async () => {
                NotificationAlert("success", "", "Changes saved.");
                const response = await get_custom_field(CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_1);
                const customField1 = await response.json();
                this.setState({customField1});
            }, 500);
        } catch (err) {
            console.error('Could not refresh custom field 1:', err);
        }
    }

    refreshCustomField2 = () => {
        try {
            clearTimeout(this.customField2Timeout);
            this.customField2Timeout = setTimeout(async () => {
                NotificationAlert("success", "", "Changes saved.");
                const response = await get_custom_field(CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_2);
                const customField2 = await response.json();
                this.setState({customField2});
            }, 500);
        } catch (err) {
            console.error('Could not refresh custom field 2:', err);
        }
    }

    refreshCustomField3 = () => {
        try {
            clearTimeout(this.customField3Timeout);
            this.customField3Timeout = setTimeout(async () => {
                NotificationAlert("success", "", "Changes saved.");
                const response = await get_custom_field(CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_3);
                const customField3 = await response.json();
                this.setState({customField3});
            }, 500);
        } catch (err) {
            console.error('Could not refresh custom field 3:', err);
        }
    }

    refreshCustomField4 = () => {
        try {
            clearTimeout(this.customField4Timeout);
            this.customField4Timeout = setTimeout(async () => {
                NotificationAlert("success", "", "Changes saved.");
                const response = await get_custom_field(CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_4);
                const customField1 = await response.json();
                this.setState({customField4});
            }, 500);
        } catch (err) {
            console.error('Could not refresh custom field 4:', err);
        }
    }

    optionSelect() {
        var self = this;
        toggle_incidents_enabled().then(function (success) {
            success.json().then(success => {
                let organization = safe_get(success, "organization", {});
                self.props.dispatch(organizationAction.update_current_org(organization));
                NotificationAlert("success", "", "Access updated.");
            })
        }, function (error) {
            NotificationAlert("error", "", "Unable to update access.")
        });
    }

    toggleManageTypes() {
        this.setState({manageTypesOpen: !this.state.manageTypesOpen});
    }

    toggleCustomField1 = () => {
        this.setState({manageCustomField1Open: !this.state.manageCustomField1Open});
    }
    toggleCustomField2 = () => {
        this.setState({manageCustomField2Open: !this.state.manageCustomField2Open});
    }
    toggleCustomField3 = () => {
        this.setState({manageCustomField3Open: !this.state.manageCustomField3Open});
    }
    toggleCustomField4 = () => {
        this.setState({manageCustomField4Open: !this.state.manageCustomField4Open});
    }

    toggleManageCause() {
        this.setState({manageCauseOpen: !this.state.manageCauseOpen});
    }

    toggleManageInjury() {
        this.setState({manageInjuryOpen: !this.state.manageInjuryOpen});
    }

    causeUpdateMade(new_cause) {
        if (this.state.incidentAutosaveTimer) {
            clearTimeout(this.state.incidentAutosaveTimer);
        }

        var updated_causes = [...this.state.updated_causes];
        var old_cause = updated_causes.find(function (obj) {
            return obj.incident_cause_uuid === new_cause.incident_cause_uuid
        });

        var index = updated_causes.indexOf(old_cause);
        if (index >= 0) {
            updated_causes[index] = new_cause;
        } else {
            updated_causes.push(new_cause)
        }

        this.setState({
            updated_causes: updated_causes,
            incidentAutosaveTimer: setTimeout(() => {
                this.saveCauses();
            }, 500),
        });
    }

    saveCauses() {
        var self = this;
        this.setState({saving: true, incidentCausesError: '', incidentAutosaveTimer: null});

        var body = JSON.stringify({
            incident_causes: this.state.updated_causes
        });

        update_incident_causes(body).then(function (success) {
            success.json().then(success => {
                // var event_types = safe_get(success, "event_types", [])
                self.setState({saving: false,});
                NotificationAlert("success", "", "Changes saved.");
            });

        }, function (error) {
            self.setState({saving: false});
            NotificationAlert("success", "", "Could not save changes.");
            if (error.status === 422) {
                error.json().then(errorJson => {
                    const message = getErrorMessage(errorJson, 'Could not save changes.');
                    self.setState({incidentCausesError: message});
                })
            }
        });
    }

    eventUpdateMade(new_event) {
        if (this.state.eventAutosaveTimer) {
            clearTimeout(this.state.eventAutosaveTimer);
        }

        var updated_events = [...this.state.updated_events];
        var old_event = updated_events.find(function (obj) {
            return obj.event_type_uuid === new_event.event_type_uuid
        });

        var index = updated_events.indexOf(old_event);
        if (index >= 0) {
            updated_events[index] = new_event;
        } else {
            updated_events.push(new_event)
        }

        this.setState({
            updated_events: updated_events,
            eventAutosaveTimer: setTimeout(() => {
                this.saveEvents();
            }, 500),
        });
    }

    saveEvents() {
        var self = this;
        this.setState({saving: true, eventTypesError: '', eventAutosaveTimer: null});

        var body = JSON.stringify({
            event_types: this.state.updated_events
        });

        update_event_types(body).then(function (success) {
            success.json().then(success => {
                var event_types_enabled = safe_get(success, "event_types", []).map(x => x.enabled).includes(true)
                self.setState({saving: false, event_types_enabled: event_types_enabled});
                NotificationAlert("success", "", "Changes saved.");
            });

        }, function (error) {
            self.setState({saving: false});
            NotificationAlert("success", "", "Could not save changes.");
            if (error.status === 422) {
                error.json().then(errorJson => {
                    const message = getErrorMessage(errorJson, 'Could not save changes.');
                    self.setState({eventTypesError: message});
                })
            }
        });
    }

    injuriesUpdateMade(new_injury) {
        if (this.state.injuryAutosaveTimer) {
            clearTimeout(this.state.injuryAutosaveTimer);
        }

        var updated_injuries = [...this.state.updated_injuries];
        var old_injury = updated_injuries.find(function (obj) {
            return obj.incident_injury_uuid === new_injury.incident_injury_uuid
        });

        var index = updated_injuries.indexOf(old_injury);
        if (index >= 0) {
            updated_injuries[index] = new_injury;
        } else {
            updated_injuries.push(new_injury)
        }

        this.setState({
            updated_injuries: updated_injuries,
            injuryAutosaveTimer: setTimeout(() => {
                this.saveInjuries();
            }, 500),
        });
    }

    saveInjuries() {
        var self = this;
        this.setState({saving: true, injuryTypesError: '', injuryAutosaveTimer: null});

        var body = JSON.stringify({
            incident_injuries: this.state.updated_injuries
        });

        update_incident_injuries(body).then(function (success) {
            success.json().then(success => {
                var incident_injuries_enabled = safe_get(success, "incident_injuries", []).map(x => x.enabled).includes(true)
                self.setState({saving: false, incident_injuries_enabled: incident_injuries_enabled});
                NotificationAlert("success", "", "Changes saved.");
            });

        }, function (error) {
            self.setState({saving: false});
            NotificationAlert("success", "", "Could not save changes.");
            if (error.status === 422) {
                error.json().then(errorJson => {
                    const message = getErrorMessage(errorJson, 'Could not save changes.');
                    self.setState({injuryTypesError: message});
                })
            }
        });
    }

    render() {
        return (

            <div className="panel pad-top pad-btm" style={{maxWidth: '800px', margin: '0 auto'}}>
                {this.props.activeTab === 'incident' &&
                    <>
                        <h4 className="mar-top-0 zero-blue" style={{display: "inline-block"}}>
                            Injury Type
                            &nbsp;{this.state.incident_injuries_enabled && <span className="label label-success"
                                                                                 style={{
                                                                                     padding: ".3em .6em .3em",
                                                                                     fontSize: "60%",
                                                                                     verticalAlign: "middle"
                                                                                 }}>Enabled</span>}
                        </h4>
                        <button className="btn btn-xs btn-default expand-button pull-right"
                                onClick={this.toggleManageInjury}>{this.state.manageInjuryOpen ? "Close" : "Expand"}</button>
                        <p className="zero-dark-grey">You can have up to 15 response options. This is a required field
                            and cannot be disabled.</p>

                        <Collapse key={'injurytypes'} isOpened={this.state.manageInjuryOpen}>
                            <div>
                                <p className="zero-blue" style={{display: 'inline-block'}}><b>Options</b></p>
                            </div>
                            {
                                this.state.incident_injuries.map((injury, index) => (
                                    <StatusComponent key={index} index={index} item={injury}
                                                     updateMade={this.injuriesUpdateMade} isIncidentInjury={true}/>
                                ))
                            }
                            <div>
                                {this.state.injuryTypesError &&
                                    <div><small className='error'>{this.state.injuryTypesError}</small></div>
                                }
                            </div>
                        </Collapse>

                        <hr/>

                        <h4 className="mar-top-0 zero-blue" style={{display: "inline-block"}}>
                            Incident Cause
                            &nbsp;{this.state.incident_causes_enabled && <span className="label label-success" style={{
                            padding: ".3em .6em .3em",
                            fontSize: "60%",
                            verticalAlign: "middle"
                        }}>Enabled</span>}
                        </h4>
                        <button className="btn btn-xs btn-default expand-button pull-right"
                                onClick={this.toggleManageCause}>{this.state.manageCauseOpen ? "Close" : "Expand"}</button>
                        <p className="zero-dark-grey">You can have up to 15 response options. This is a required field
                            and cannot be disabled.</p>

                        <Collapse key={'incidentcauses'} isOpened={this.state.manageCauseOpen}>
                            <div>
                                <p className="zero-blue" style={{display: 'inline-block'}}><b>Options</b></p>
                            </div>
                            {
                                this.state.incident_causes.map((cause, index) => (
                                    <StatusComponent key={index} index={index} item={cause}
                                                     updateMade={this.causeUpdateMade} isInjuryCause={true}/>
                                ))
                            }
                            <div>
                                {this.state.incidentCausesError &&
                                    <div><small className='error'>{this.state.incidentCausesError}</small></div>
                                }
                            </div>
                        </Collapse>

                        <hr/>

                        <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>
                            Event Type
                            &nbsp;{this.state.event_types_enabled && <span className="label label-success" style={{
                            padding: ".3em .6em .3em",
                            fontSize: "60%",
                            verticalAlign: "middle"
                        }}>Enabled</span>}
                        </h4>
                        <button className="btn btn-xs btn-default expand-button pull-right"
                                onClick={this.toggleManageTypes}>{this.state.manageTypesOpen ? "Close" : "Expand"}</button>
                        <p className="zero-dark-grey">You can have up to 15 response options. This field can be disabled
                            at any time.</p>

                        <Collapse key={'eventtypes'} isOpened={this.state.manageTypesOpen}>
                            <div>
                                <p className="zero-blue" style={{display: 'inline-block'}}><b>Options</b></p>
                            </div>
                            {
                                this.state.event_types.map((event, index) => (
                                    <StatusComponent key={index} index={index} item={event}
                                                     updateMade={this.eventUpdateMade} isEventType={true}/>
                                ))
                            }
                            <div>
                                {this.state.eventTypesError &&
                                    <div><small className='error'>{this.state.eventTypesError}</small></div>
                                }
                            </div>
                        </Collapse>

                        <hr/>

                        {this.state.customField1 &&
                            <>
                                <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>
                                    {this.state.customField1.name}
                                    &nbsp;{this.state.customField1.enabled && <span className="label label-success"
                                                                                    style={{
                                                                                        padding: ".3em .6em .3em",
                                                                                        fontSize: "60%",
                                                                                        verticalAlign: "middle"
                                                                                    }}>Enabled</span>}
                                </h4>
                                <button className="btn btn-xs btn-default expand-button pull-right"
                                        onClick={this.toggleCustomField1}>{this.state.manageCustomField1Open ? "Close" : "Expand"}</button>
                                <p className="zero-dark-grey">You can customize the name of this field and have up to 15
                                    response options. This field can be disabled at any time.</p>

                                <Collapse key={'customfield1'} isOpened={this.state.manageCustomField1Open}>
                                    <ManageCustomFields customField={this.state.customField1}
                                                        onUpdate={this.refreshCustomField1}/>
                                </Collapse>
                            </>
                        }
                        <hr/>
                        {this.state.customField2 &&
                            <>
                                <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>
                                    {this.state.customField2.name}
                                    &nbsp;{this.state.customField2.enabled && <span className="label label-success"
                                                                                    style={{
                                                                                        padding: ".3em .6em .3em",
                                                                                        fontSize: "60%",
                                                                                        verticalAlign: "middle"
                                                                                    }}>Enabled</span>}
                                </h4>
                                <button className="btn btn-xs btn-default expand-button pull-right"
                                        onClick={this.toggleCustomField2}>{this.state.manageCustomField2Open ? "Close" : "Expand"}</button>
                                <p className="zero-dark-grey">You can customize the name of this field and have up to 15
                                    response options. This field can be disabled at any time.</p>

                                <Collapse key={'customfield2'} isOpened={this.state.manageCustomField2Open}>
                                    <ManageCustomFields customField={this.state.customField2}
                                                        onUpdate={this.refreshCustomField2}/>
                                </Collapse>
                            </>
                        }
                        <hr/>
                        {this.state.customField3 &&
                            <>
                                <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>
                                    {this.state.customField3.name}
                                    &nbsp;{this.state.customField3.enabled && <span className="label label-success"
                                                                                    style={{
                                                                                        padding: ".3em .6em .3em",
                                                                                        fontSize: "60%",
                                                                                        verticalAlign: "middle"
                                                                                    }}>Enabled</span>}
                                </h4>
                                <button className="btn btn-xs btn-default expand-button pull-right"
                                        onClick={this.toggleCustomField3}>{this.state.manageCustomField3Open ? "Close" : "Expand"}</button>
                                <p className="zero-dark-grey">You can customize the name of this field and have up to 15
                                    response options. This field can be disabled at any time.</p>

                                <Collapse key={'customfield3'} isOpened={this.state.manageCustomField3Open}>
                                    <ManageCustomFields customField={this.state.customField3}
                                                        onUpdate={this.refreshCustomField3}/>
                                </Collapse>
                            </>
                        }
                        <hr/>
                        {this.state.customField4 &&
                            <>
                                <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>
                                    {this.state.customField4.name}
                                    &nbsp;{this.state.customField4.enabled && <span className="label label-success"
                                                                                    style={{
                                                                                        padding: ".3em .6em .3em",
                                                                                        fontSize: "60%",
                                                                                        verticalAlign: "middle"
                                                                                    }}>Enabled</span>}
                                </h4>
                                <button className="btn btn-xs btn-default expand-button pull-right"
                                        onClick={this.toggleCustomField4}>{this.state.manageCustomField4Open ? "Close" : "Expand"}</button>
                                <p className="zero-dark-grey">You can customize the name of this field and have up to 15
                                    response options. This field can be disabled at any time.</p>

                                <Collapse key={'customfield4'} isOpened={this.state.manageCustomField4Open}>
                                    <ManageCustomFields customField={this.state.customField4}
                                                        onUpdate={this.refreshCustomField4}/>
                                </Collapse>
                            </>
                        }
                    </>
                }

                {
                    this.props.activeTab === 'incident:access' &&
                    <>
                        <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>Member and
                            Contributor Access</h4>
                        <p className="zero-dark-grey">If the box below is checked, then members and contributors will be
                            able to create
                            incidents in ZERO. Members and contributors can never see incidents logged by other users.
                            They can only see
                            incidents that they created.</p>
                        <p className="zero-dark-grey">To block members and contributors from accessing the incidents
                            feature, uncheck
                            the box below.</p>

                        <div>
                            {/* <input className="form-check-input" id="incident_setting" type="checkbox" checked={this.props.organization.incidents_enabled} onChange={this.optionSelect} />
              <label className="form-check-label zero-dark-grey" htmlFor="incident_setting" style={{ marginLeft: "8px", verticalAlign: "middle" }}>
                Allow members to access incidents
              </label> */}
                            <p><Checkbox
                                checked={this.props.organization.incidents_enabled} onChange={this.optionSelect}
                            >
                                <span
                                    className="zero-dark-grey">Allow members and contributors to create incidents</span>
                            </Checkbox></p>

                            <a rel="noopener noreferrer"
                               onClick={() => FreshworksWidget('open', 'article', {'id': 70000407085})}
                               className="blue-link">Learn more about access permissions for incidents.</a>
                        </div>
                    </>
                }
            </div>
        )
            ;
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(IncidentSettings);