export function update_notifications(notifications) {
    return {
        type: "update_notifications",
        payload: notifications
    }
}


export function update_read_notifications(notifications) {
    return {
        type: "update_read_notifications",
        payload: notifications
    }
}

export function reset_notifications() {
    return {
        type: "RESET_NOTIFICATIONS",
        payload: []
    }
}

export function update_unread_notifications_counter(counter) {
    return {
        type: "update_unread_notifications_counter",
        payload: counter
    }
}