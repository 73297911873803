import React from 'react';

import styles from './CommentDiff.module.css';

function CommentValue({value}) {
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return <div><em>None</em></div>
        } else if (value.length === 1) {
            return <div>{value[0]}</div>
        } else {
            return (
                <div>
                    {
                        value.map((v, index) => (
                            <React.Fragment key={index}>
                                {v}
                                {(index !== value.length - 1) &&
                                    <>, </>
                                }
                            </React.Fragment>
                        ))
                    }
                </div>
            )
        }
    }

    return <div>{value}</div>
}

function CommentLabel({label}) {
    if (typeof label === 'string') {
        return <div><span className='underline'>{label}</span>:</div>
    }

    return <div>{label}</div>
}

/*
Param `diffs` should be an array of objects.
Each object should have the following properties:
    - key
    - oldLabel
    - oldValue
    - newLabel
    - newValue
*/
export default function CommentDiff({diffs}) {
    return (
        <div className={styles.commentBody}>
            {diffs.length === 0 &&
                <div className='mar-top-10'>
                    <em>The fields have been reverted back to their original values.</em>
                </div>
            }
            {diffs.length > 0 &&
                <div className={styles.diffContainer}>
                    <>
                        <strong className={styles.diffLeft}>Before:</strong>
                        <div className={styles.diffCenter} style={{gridRow: `1 / span ${diffs.length + 1}`}}></div>
                        <strong className={styles.diffRight}>After:</strong>
                        { diffs.map(({key, oldLabel, oldValue, newLabel, newValue}) => {
                            return (
                                <React.Fragment key={key}>
                                    <div className={styles.diffLeft}>
                                        <CommentLabel label={oldLabel}/>
                                        <CommentValue value={oldValue}/>
                                    </div>
                                    <div className={styles.diffRight}>
                                        <CommentLabel label={newLabel}/>
                                        <CommentValue value={newValue}/>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </>
                </div>
            }
        </div>
    )
}