import React, {Component} from 'react';


class ToggleRisk extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick() {
        this.props.onClickRisk(this.props.risk)
    }

    render() {
        var baseBtnClass = "btn btn-info risk"
        var btnClass = ""

        if (this.props.name === "N/A") {
            btnClass = baseBtnClass + " NA"
        } else {
            btnClass = baseBtnClass + " " + this.props.name
        }

        var btnClassActive = this.props.active ? btnClass + " active" : btnClass;

        return (
            <button className={btnClassActive} style={{display: 'inline-block'}} onClick={this.handleClick}>
                {this.props.name}
            </button>
        );
    }

}

export default ToggleRisk;