import React, {Component} from 'react';
import {withRouter} from 'react-router';

import {connect} from 'react-redux'

import {get_user_analytics, get_user} from '../../../api/zero-api.js'
import {
    safe_get,
    safeProfilePic,
    isAdmin,
    isTeamLead,
    isViewer,
    isContributor,
    profileImageContain,
    dateFormatterNoTime,
    dateFormatterWithTime
} from '../../../other/Helper.js'
import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import InfoIconComponent from '../../InfoIconComponent.js';
import SeparatedAssignmentsLists from '../../Shared/SeparatedAssignmentsLists.js';

import * as teamsActions from '../../../store/actions/TeamsActions';
import * as feedHelperActions from '../../../store/actions/FeedHelperActions';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            teams: [],
            loading: true,
            open_assignments: [],
            closed_assignments: []
        };

        this.getUser = this.getUser.bind(this);
    }

    componentDidMount() {
        this.getUser();
    }

    getUser() {
        var self = this
        this.setState({loading: true});
        get_user(this.props.user_uuid, "?include_deactivated=true&include_assignments=true").then(function (success) {
            success.json().then(success => {
                var user = safe_get(success, "user", {});
                var teams = safe_get(user, "teams", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                });

                self.setState({
                    profile: user,
                    teams: teams,
                    user_stats: safe_get(user, "user_stats", {}),
                    open_assignments: safe_get(user, 'open_assignments.assignments', []),
                    closed_assignments: safe_get(user, 'closed_assignments.assignments', []),
                    loading: false
                }, () => {
                    profileImageContain("img.img-big.img-circle.account", "100px");
                });

            });
        }, function (error) {
            self.setState({profile: {}, loading: false})
        });
    }

    userTeam(index, team) {
        return (
            <button key={index} className="ButtonLink list-group-item team " style={{padding: "5px", cursor: "default"}}
                    onClick={() => {
                        this.updateTeam(team)
                    }} disabled={true}>
                <p className="mar-no text-center zero-light-blue no-hover"
                   style={{fontSize: "105%", fontWeight: "500"}}>{safe_get(team, "name", "")}</p>
                <small
                    className="text-muteds text-center account-teams">{safe_get(team, "users_count", "")} Members</small>
            </button>
        )
    }

    updateTeam = (team) => {
        this.props.dispatch(teamsActions.update_current_team(team))
        this.props.dispatch(feedHelperActions.update_feed_position(0));
        this.props.dispatch(feedHelperActions.update_feed_query("?team_uuid=" + team.uuid));
        this.props.dispatch(feedHelperActions.update_date_query("&period=all_time"));
        this.props.dispatch(feedHelperActions.update_feed_tab("posts"));

        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed")
    }

    renderAccountHeader() {
        const canEdit = this.props.user_uuid === this.props.current_user.uuid;

        return (
            <div className="account-header">
                <div className="text-center">
                    {safeProfilePic(this.state.profile, "img-big img-circle account", "account")}
                </div>
                <h4 className="account-title pre"
                    style={{wordBreak: "break-all"}}>{this.state.profile.first_name} {this.state.profile.last_name}</h4>

                {
                    this.state.profile.status !== "deactivated" &&
                    <p className="account-description pre">
                        {
                            isAdmin(this.state.profile) ?
                                <span><span className="badge badge-success badge-icon badge-fw "></span>Admin</span> :
                                isTeamLead(this.state.profile) ?
                                    <span><span className="badge badge-info badge-icon badge-fw "></span>Lead</span> :
                                    isViewer(this.state.profile) ? <span><span
                                            className="badge badge-danger badge-icon badge-fw"></span>Viewer</span> :
                                        isContributor(this.state.profile) ?
                                            <span><span className="badge badge-warning badge-icon badge-fw"></span>Contributor</span> :
                                            <span><span className="badge badge-purple badge-icon badge-fw "></span>Member</span>
                        }
                    </p>

                }

                <p className="account-description pre" style={{wordBreak: 'break-all'}}>{this.state.profile.title}</p>
                <p className="account-description pre" style={{wordBreak: 'break-all'}}>{this.state.profile.email}</p>
                <p className="account-description pre">Joined
                    ZERO {dateFormatterNoTime(this.state.profile.created_at)}</p>
                { this.state.profile.last_activity_at &&
                    <p className="account-description pre">Last Active {dateFormatterWithTime(this.state.profile.last_activity_at)}</p>
                }
                {
                    canEdit &&
                    <button onClick={this.props.editProfile} className="btn btn-primary profile">Edit</button>
                }
                {
                    this.state.profile.status === "deactivated" &&
                    <p className="account-description pre">
                        <span className="label label-danger zero-orange-background">DEACTIVATED</span>
                    </p>
                }
            </div>
        );
    }

    renderStats() {
        const stats = {
            'Posts Opened': safe_get(this.state, "user_stats.posts_created", 0),
            'Posts Closed': safe_get(this.state, "user_stats.closed_posts", 0),
            'Posts Shared': safe_get(this.state, "user_stats.posts_shared", 0),
            'Post Comments': safe_get(this.state, "user_stats.post_comments", 0),
            'Open Post Assignments': safe_get(this.state, "user_stats.open_post_assignments", 0),
            'Forms Submitted': safe_get(this.state, "user_stats.forms_submitted", 0),
            'Open Form Assignments': safe_get(this.state, "user_stats.open_form_assignments", 0),
            'Completed Form Assignments': safe_get(this.state, "user_stats.completed_form_assignments", 0),
            'Incidents Created': safe_get(this.state, "user_stats.incidents_created", 0),
        }

        return (
            <div className="panel thin-border" style={{height: "550px", padding: '2rem', color: '#3c4045'}}>
                <p className="text-center text-bold"
                   style={{paddingBottom: '1.5rem', borderBottom: '1px solid black'}}>Activity</p>
                {Object.entries(stats).map(([key, value], index) => (
                    <div key={index} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '1rem',
                        fontSize: '1.5rem'
                    }}>
                        <div>{key}</div>
                        <div>{value}</div>
                    </div>
                ))}
            </div>
        )
    }

    render() {
        const ownProfile = this.props.user_uuid === this.props.current_user.uuid;

        return (
            <div>
                {
                    this.state.loading &&
                    <div className={"panel article thin-border"}>
                        <LoadingIndicator/>
                    </div>
                }
                {
                    (Object.keys(this.state.profile).length !== 0) && !this.state.loading &&
                    <>
                        <div className={"panel article thin-border"}>
                            {this.renderAccountHeader()}
                        </div>
                        <div className="row" style={{padding: "0 0.5rem"}}>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                                <SeparatedAssignmentsLists
                                    loading={false}
                                    openAssignments={this.state.open_assignments}
                                    pastAssignments={this.state.closed_assignments}
                                    organization_uuid={this.props.organization.organization_uuid}
                                    disableLinks={!ownProfile}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                                {this.renderStats()}
                            </div>
                        </div>

                        <div className="panel thin-border" style={{padding: '4rem 0'}}>
                            <p className="account-label" style={{marginTop: 0}}>Teams</p>
                            <div className="bottom-border-panel account">
                                <div style={{marginBottom: 0}}
                                     className={"list-group bg-trans teams account " + (this.state.teams.length <= 4 ? "no-scroll" : "")}>
                                    {
                                        this.state.teams.length > 0 &&
                                        this.state.teams.map((team, index) => (
                                            this.userTeam(index, team)
                                        ))
                                    }
                                    {
                                        this.state.teams.length === 0 &&
                                        <li className="list-group-item team">
                                            <p className="mar-no text-center" style={{fontSize: "105%"}}>No Teams</p>
                                        </li>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    (Object.keys(this.state.profile).length === 0) && !this.state.loading &&
                    <div className="panel article" style={{maxWidth: '800px', margin: '0 auto'}}>
                        <h4 className="mar-btm zero-blue text-center">Sorry, this content no longer exists. It was most
                            likely deleted or moved to another team.</h4>
                    </div>
                }

            </div>
        )
    }
}


const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(Profile));