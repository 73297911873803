import React, {useContext, useEffect, useState} from 'react';

import {actions} from './reducer';
import { ZeroQuill } from 'components/Shared/ZeroQuill';
import { usePostFieldNames } from 'hooks/postHooks';
import { FileUploadContext } from 'components/Shared/FileUpload';

let inputDebouncer = null;

export default function PostFormBody({state, dispatch}) {
    const fileUploadCtx = useContext(FileUploadContext)
    const [fieldValue, setFieldValue] = useState(state.body);
    const fieldNames = usePostFieldNames();

    const onFieldChange = content => {
        const value = content;
        setFieldValue(value);

        clearTimeout(inputDebouncer);
        inputDebouncer = setTimeout(() => {
            dispatch([actions.BODY_CHANGED, value]);
            inputDebouncer = null;
        }, 1000);
    }

    useEffect(() => {
        return () => {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
    }, []);

    useEffect(() => {
        setFieldValue(state.body);
    }, [state.body]);

    useEffect(() => {
        if (state.flushInput && fieldValue !== state.body) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
            dispatch([actions.BODY_CHANGED, fieldValue]);
        }
    }, [state.flushInput]);

    const onBlur = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
            dispatch([actions.BODY_CHANGED, fieldValue]);
        }
    }

    const onImagePaste = (file) => {
        if (fileUploadCtx.beforeUploadHandler(file, [file]) === true) {
            fileUploadCtx.handleFileUpload({file});
        }
    }

    return (
        <>
            <div className="panel-heading bulletin-post required">
                <h3 className="bulletin-post-headers">{fieldNames.body}</h3>
            </div>

            <div className="bulletin-post">
                <div className="form-group" style={{marginBottom: '0px', position: "relative"}}>
                    <ZeroQuill
                        value={fieldValue}
                        onChange={onFieldChange}
                        onBlur={onBlur}
                        onImagePaste={onImagePaste}
                    />
                </div>
                {
                    state.contentError &&
                    <small className="error">{state.contentError}</small>
                }
            </div>
        </>
    );
}