import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "react-collapse";

import {
    UserOutlined,
    QuestionCircleOutlined,
    AppstoreOutlined,
    LogoutOutlined,
    CheckCircleOutlined,
    HomeOutlined,
    LayoutOutlined,
    ExceptionOutlined,
    SolutionOutlined,
    ReadOutlined,
    GlobalOutlined,
} from "@ant-design/icons";

import { hiddenNavToOfflineDebugIsAllowed, isAdmin, isContributor, isTeamLead, isViewer, safe_get, switchOrganization } from "other/Helper";
import UserAvatar from "components/Shared/UserAvatar";
import { Menu, Popover } from "antd";
import { deleteCookies, logout, update_auth_token } from "api/zero-api";
import useOrganizationId from "hooks/useOrganizationId";


import logo from '../../assets/css/img/ZERO-(blue).png';


function UserSideBarContent({ closeSideMenu, draftErrorCount, orgSwitchContext }) {
    const [showUserOptions, setShowUserOptions] = useState(false);
    const [showOrgSwitcher, setShowOrgSwitcher] = useState(false);

    const location = useLocation();
    const user = useSelector((state) => state.user.user);
    const currentTeam = useSelector((state) => state.teams_helper.team);
    const organizations = useSelector((state) => state.org_helper.organizations);
    const organization = useSelector((state) => state.org_helper.organization);
    const kioskMode = useSelector((state) => state.app.kiosk_mode);
    const assignmentCount = useSelector((state) => state.dashboard.assignmentsCount);

    const orgId = organization.organization_uuid;
    const pathname = location.pathname;

    const onLogout = async () => {
        sessionStorage.clear();
        try {
            const res = await logout();
            const data = await res.json();
            deleteCookies();
            if (data.kiosk_mode_on) {
                update_auth_token(data.kiosk_mode_token);
                window.location = "/kiosk_mode";
            } else {
                window.location = "/login";
            }
        } catch (err) {
            console.log(err);
        }
    };

    const orgPopoverContent = useMemo(() => {
        const sortedOrganizations = organizations.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const onMenuClick = (e) => {
            setShowOrgSwitcher(false);
            setShowUserOptions(false);
            closeSideMenu();
            if (e.key === "new_org") {
                history.push(`/${orgId}/home/create_organization`);
            } else if (e.key !== orgId) {
                switchOrganization(orgSwitchContext, e.key);
            }
        }

        return (
            <Menu
                className="org-switch-menu"
                onClick={onMenuClick}
                style={{overflowX: "hidden", maxHeight: "50vh"}}
                items={sortedOrganizations.map((org) => ({
                    key: org.organization_uuid,
                    label: (
                        <div
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                            data-class="org-switch-menu-item"
                        >
                            <div>{org.name}</div>
                            { orgId === org.organization_uuid && <CheckCircleOutlined /> }
                        </div>
                    )
                }))}
            />
        )
    }, [organizations]);

    return (
        <>
            <div id="mainnav-profile" className="mainnav-profile" style={{ maxHeight: "460px", padding: "2px" }}>
                <div
                    className="profile-wrap text-center"
                    style={{ padding: "10px 10px", cursor: "pointer" }}
                    onClick={() => setShowUserOptions(!showUserOptions)}
                >
                    <div className="media">
                        <div className="media-left profile-img">
                            <UserAvatar user={user} imgClass="mg-md img-circle sidebar" initialsClass="sidebar" />
                        </div>
                        <div className="media-body account-card" style={{ verticalAlign: "middle" }}>
                            <button
                                className="box-block pointer-hover"
                                style={{
                                    border: "none",
                                    backgroundColor: "white",
                                    width: "100%",
                                    padding: "0px",
                                }}
                            >
                                <span className={"pull-right dropdown-toggle " + (showUserOptions ? "open" : "")}>
                                    <i className="dropdown-caret"></i>
                                </span>
                                <p
                                    className="mnp-name zero-blue"
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "160px",
                                        textAlign: "left",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {safe_get(user, "first_name", "")} {safe_get(user, "last_name", "")}
                                </p>
                                {isAdmin(user) ? (
                                    <p
                                        className="role-text zero-dark-grey "
                                        style={{ fontSize: "0.9em", textAlign: "left" }}
                                    >
                                        <span
                                            className="badge badge-success badge-icon badge-fw "
                                            style={{ margin: "2px 5px 5px 2px" }}
                                        ></span>
                                        Admin
                                    </p>
                                ) : isTeamLead(user) ? (
                                    <p
                                        className="role-text zero-dark-grey "
                                        style={{
                                            fontSize: "0.9em",
                                            textAlign: "left",
                                        }}
                                    >
                                        <span
                                            className="badge badge-info badge-icon badge-fw "
                                            style={{ margin: "2px 5px 5px 2px" }}
                                        ></span>
                                        Lead
                                    </p>
                                ) : isViewer(user) ? (
                                    <p
                                        className="role-text zero-dark-grey"
                                        style={{
                                            fontSize: "0.9em",
                                            textAlign: "left",
                                        }}
                                    >
                                        <span
                                            className="badge badge-danger badge-icon badge-fw"
                                            style={{ margin: "2px 5px 5px 2px" }}
                                        ></span>
                                        Viewer
                                    </p>
                                ) : isContributor(user) ? (
                                    <p
                                        className="role-text zero-dark-grey"
                                        style={{ fontSize: "0.9em", textAlign: "left" }}
                                    >
                                        <span
                                            className="badge badge-warning badge-icon badge-fw"
                                            style={{ margin: "2px 5px 5px 2px" }}
                                        ></span>
                                        Contributor
                                    </p>
                                ) : (
                                    <p
                                        className="role-text zero-dark-grey"
                                        style={{ fontSize: "0.9em", textAlign: "left" }}
                                    >
                                        <span
                                            className="badge badge-purple badge-icon badge-fw "
                                            style={{ margin: "2px 5px 5px 2px" }}
                                        ></span>
                                        Member
                                    </p>
                                )}
                                <p
                                    className="zero-dark-grey"
                                    style={{
                                        fontSize: "0.9em",
                                        display: "block",
                                        textAlign: "left",
                                    }}
                                >
                                    {safe_get(organization, "name", "")}
                                </p>
                            </button>
                        </div>
                    </div>
                    {kioskMode && (
                        <p
                            className="zero-dark-grey"
                            style={{ fontSize: "85%", paddingTop: "15px", marginBottom: "0px" }}
                        >
                            Logged in via Kiosk Mode
                        </p>
                    )}
                </div>

                <Collapse isOpened={showUserOptions}>
                    <div id="profile-nav" className="list-group" style={{ marginBottom: "0px", backgroundColor: "#fff" }}>
                        <NavLink
                            to={`/${orgId}/home/myprofile/${user.uuid}`}
                            id="profile-link"
                            className="list-group-item"
                            activeClassName="active-sidebar-item"
                            onClick={closeSideMenu}
                        >
                            <UserOutlined />
                            <span className="menu-title mar-lft-5">My Profile</span>
                        </NavLink>

                        {(isAdmin(user) || organizations.length > 1) && (
                            <button className="list-group-item">
                                <Popover
                                    placement={window.innerWidth < 365 ? "bottom" : "right"}
                                    content={orgPopoverContent}
                                    trigger="click"
                                    open={showOrgSwitcher}
                                    onClick={setShowOrgSwitcher}
                                    onOpenChange={setShowOrgSwitcher}
                                >
                                    <AppstoreOutlined />
                                    <span className="menu-title mar-lft-5">Switch Account</span>
                                </Popover>
                            </button>
                        )}

                        <a
                            className="list-group-item"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://help.teamzero.com"
                        >
                            <QuestionCircleOutlined />
                            <span className="menu-title mar-lft-5">Help Center</span>
                        </a>

                        <button onClick={onLogout} className="list-group-item">
                            <LogoutOutlined />
                            <span className="menu-title mar-lft-5">Logout</span>
                        </button>
                    </div>
                </Collapse>
            </div>

            <hr className="sidebar" />

            <ul id="mainnav-menu" className="list-group" style={{ listStyle: "none" }}>
                <li className="sidebar list-header">MAIN MENU</li>

                <li style={{ display: "inline" }}>
                    <NavLink
                        to={`/${orgId}/home/dashboard`}
                        strict
                        onClick={closeSideMenu}
                        activeClassName="active-sidebar-item"
                    >
                        <p className="menu-title">
                            <HomeOutlined />
                            <span className="mar-lft-5">Dashboard</span>
                            { assignmentCount > 0 && (
                                <span className="pull-right badge badge-warning" style={{ backgroundColor: "#d6b27e" }}>
                                    {assignmentCount}
                                </span>
                            )}
                        </p>
                    </NavLink>
                </li>

                { localStorage.getItem("show-offline-dashboard-menu") === "true" && (
                    <li style={{ display: "inline" }}>
                        <NavLink
                            to={`/${orgId}/offline/dashboard`}
                            strict
                            onClick={closeSideMenu}
                            activeClassName="active-sidebar-item"
                        >
                            <p className="menu-title">
                                <HomeOutlined />
                                <span className="mar-lft-5">Offline Dashboard</span>
                            </p>
                        </NavLink>
                    </li>
                )}

                { (organization.feature_flags.feed ?? true) && (
                    <li style={{ display: "inline" }}>
                        <NavLink
                            to={`/${orgId}/home/team/my_teams/feed`}
                            onClick={closeSideMenu}
                            className={
                                pathname.includes("feed") ||
                                pathname.includes("my_posts") ||
                                pathname.includes("manage_post_templates")
                                    ? "active-sidebar-item"
                                    : ""
                            }
                        >
                            <p className="menu-title">
                                <LayoutOutlined />
                                <span className="mar-lft-5">Posts</span>
                            </p>
                        </NavLink>
                    </li>
                )}

                { (organization.feature_flags.forms ?? true) && (
                    <li style={{ display: "inline" }}>
                        <NavLink
                            to={`/${orgId}/home/team/my_teams/forms`}
                            onClick={closeSideMenu}
                            className={
                                pathname.includes("forms") && !pathname.includes("courses") ? "active-sidebar-item" : ""
                            }
                        >
                            <p className="menu-title">
                                <SolutionOutlined />
                                <span className="mar-lft-5">Forms</span>
                                { draftErrorCount > 0 && (
                                    <span
                                        className="pull-right badge badge-warning"
                                        style={{ backgroundColor: "#d6b27e" }}
                                    >
                                        {draftErrorCount}
                                    </span>
                                )}
                            </p>
                        </NavLink>
                    </li>
                )}

                { (organization.feature_flags.incidents ?? true) &&
                    (isAdmin(user) || isTeamLead(user) || organization.incidents_enabled) && (
                        <li style={{ display: "inline" }}>
                            <NavLink
                                to={`/${orgId}/home/incidents/location/all_locations`}
                                onClick={closeSideMenu}
                                className={
                                    pathname.includes("/incidents") || pathname.includes("/manage_incident_templates")
                                        ? "active-sidebar-item"
                                        : ""
                                }
                            >
                                <p className="menu-title">
                                    <ExceptionOutlined />
                                    <span className="mar-lft-5">Incidents</span>
                                </p>
                            </NavLink>
                        </li>
                    )}

                { (organization.feature_flags.courses ?? true) && (
                    <li style={{ display: "inline" }}>
                        <NavLink
                            to={`/${orgId}/home/team/my_teams/courses`}
                            className={pathname.includes("courses") ? "active-sidebar-item" : ""}
                            onClick={closeSideMenu}
                        >
                            <p className="menu-title">
                                <SolutionOutlined />
                                <span className="mar-lft-5">Courses</span>
                            </p>
                        </NavLink>
                    </li>
                )}

                { (organization.feature_flags.library ?? true) && !isContributor(user) && (
                    <li style={{ display: "inline" }}>
                        <NavLink
                            to={`/${orgId}/home/team/${currentTeam.uuid}/private_library`}
                            className={pathname.includes("library") ? "active-sidebar-item" : ""}
                            onClick={closeSideMenu}
                        >
                            <p className="menu-title">
                                <ReadOutlined />
                                <span className="mar-lft-5">Library</span>
                            </p>
                        </NavLink>
                    </li>
                )}

                { (organization.feature_flags.desks ?? false) && (
                    <li style={{ display: "inline" }}>
                        <NavLink
                            to={`/${orgId}/home/user_directory`}
                            className={pathname.includes("user_directory") ? "active-sidebar-item" : ""}
                            onClick={closeSideMenu}
                        >
                            <p className="menu-title">
                                <GlobalOutlined />
                                <span className="mar-lft-5">Directory</span>
                            </p>
                        </NavLink>
                    </li>
                )}
            </ul>
        </>
    );
}

function AnonUserSideBarContent({}) {
    return (
        <div style={{minHeight: '460px'}}>
            <div className="sidebar list-header">WELCOME TO ZERO</div>
            <p className="zero-blue" style={{padding: "0 1.5rem"}}>Hello! You are using the public version of the ZERO app. You are not logged in. Please complete the requested information on the right side of this page and either save or submit at your convenience. Thank you for using ZERO.</p>
        </div>
    )
}

function SideBarWrapper({isInline, children, onClickOutside}) {
    const navRef = useRef(null);

    const handleClickOutside = (event) => {
        if (navRef.current && !navRef.current.contains(event.target)) {
            onClickOutside(event);
        }
    };

    useEffect(() => {
        if (!isInline) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchend', handleClickOutside);
            document.addEventListener('touchmove', handleClickOutside);
        }

        return () => {
            if (!isInline) {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('touchend', handleClickOutside);
                document.removeEventListener('touchmove', handleClickOutside);
            }
        }
    }, [isInline]);

    if (isInline) {
        return children;
    }

    return (
        <div id="mainnav" ref={navRef}>
            <div id="mainnav-menu-wrap">
                <div className="nano">
                    <div className="nano-content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function SideBarInternals({ isOffline, isPublicView, draftErrorCount, closeSideMenu = () => {}, isInline }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const organization = useSelector((state) => state.org_helper.organization);
    const orgId = useOrganizationId();

    const orgSwitchContext = {
        props: {
            history,
            dispatch,
        },
    };

    const isOrgSwitchMenuItem = (element) => {
        return element.dataset.class === "org-switch-menu-item" || element.parentElement?.dataset?.class === "org-switch-menu-item";
    }

    const onClickOutside = (event) => {
        if (!isOrgSwitchMenuItem(event.target)) {
            closeSideMenu();
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }

    return (
        <>
            {isOffline && (
                <>
                    <div className="offline-sidebar-mask"></div>
                    <div className="offline-sidebar-header">You're currently offline</div>
                </>
            )}
            <SideBarWrapper isInline={isInline} onClickOutside={onClickOutside}>
                {safe_get(organization, "logo", null) && (
                    <div id="mainnav-profile" className="mainnav-profile" style={{ padding: "2px" }}>
                        <div className="profile-wrap text-center" style={{ padding: "20px 20px 0px 20px" }}>
                            <img className="img-org-logo" src={organization.logo} alt="Organization Logo" />
                        </div>
                        <hr className="sidebar" />
                    </div>
                )}
                {isPublicView ? (
                    <AnonUserSideBarContent />
                ) : (
                    <UserSideBarContent
                        closeSideMenu={closeSideMenu}
                        draftErrorCount={draftErrorCount}
                        orgSwitchContext={orgSwitchContext}
                    />
                )}
                <hr className="sidebar" />
                { !isInline &&
                    <div className="profile-wrap text-center mar-btm">
                        <img src={logo} alt="Zerologo" className="brand-logo-sidebar"
                            style={{height: "35px"}}/>
                    </div>
                }
                <div className="zero-grey text-center" style={{marginBottom: "2rem"}}>
                    <span
                        onDoubleClick={(ev) => {
                            if (!isPublicView && hiddenNavToOfflineDebugIsAllowed()) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                closeSideMenu();
                                history.push(`/${orgId}/offline/debug`);
                            }
                        }}
                    >
                        Version 3.0-{window.zeroVersion}
                    </span>
                </div>
            </SideBarWrapper>
        </>
    );
}
