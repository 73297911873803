import React, {Component} from 'react';

import {InfoCircleOutlined} from '@ant-design/icons'

import {Popover} from 'antd';

class InfoIconComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Popover
                trigger={["click", "hover"]}
                content={<p style={{margin: '0px', fontSize: '90%', maxWidth: this.props.width}}>{this.props.text}</p>}
                title=""
                placement={this.props.position}
                className="custom-popover"
                style={{backgroundColor: '#eaeaea !important', padding: '5px'}}
            >
                <InfoCircleOutlined style={{fontSize: "13px", marginLeft: "5px"}}/>
            </Popover>
        );
    }
}

export default InfoIconComponent;