import {useEffect, useState} from 'react';

import styles from './AppLoader.module.css';
import logo from 'assets/css/img/ZERO-(white).png';
import Button from './Shared/Button';

const reloadButtonTimeoutMs = 10_000; // 10 seconds

export default function AppLoader({loading, children}) {
    const [animationFinished, setAnimationFinished] = useState(false);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [showReloadButton, setShowReloadButton] = useState(false);

    useEffect(() => {
        if (!loading) {
            let timer = setTimeout(() => {
                setFinishedLoading(true);
                timer = setTimeout(() => {
                    setAnimationFinished(true);
                }, 500);
            }, 250);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [loading]);

    useEffect(() => {
        let timer = setTimeout(() => {
            setShowReloadButton(true);
        }, reloadButtonTimeoutMs);

        return () => {
            clearTimeout(timer);
        }
    }, [setShowReloadButton]);

    // Comment this block out if you need to work on the Zero loading screen locally
    if (process.env.REACT_APP_LOCAL_SERVER === '1') {
        if (loading) return null;
        return children;
    }

    return (
        <>
            {!loading &&
                <div style={{visibility: finishedLoading ? 'visible' : 'hidden'}}>
                    {children}
                </div>
            }
            <div className={`${styles.container} ${loading ? '' : styles.finished}`}
                 style={{display: animationFinished ? 'none' : 'flex'}}>
                <div className={styles.navBar}>
                    {showReloadButton &&
                        <div className={styles.buttonWrapper}>
                            <div style={{fontSize: '15px'}}>Not loading?</div>
                            <Button type="primary" onClick={() => window.location.reload()}>Reload</Button>
                        </div>
                    }
                </div>
                <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={logo} alt="ZERO"/>
                </div>
            </div>
        </>
    )
}