import React, {Component} from 'react';
import {connect} from 'react-redux'

import {update_sub_statuses, update_post_field_names, createSubStatus, get_sub_status} from '../../../../api/zero-api.js'
import {safe_get, getErrorMessage} from '../../../../other/Helper.js'
import {update_feed_statuses} from '../../../../store/actions/FeedHelperActions';
import {update_current_org} from '../../../../store/actions/OrganizationActions'

import StatusComponent from './StatusComponent'

import NotificationAlert from '../../../../other/NotificationAlert';

import LoadingIndicator from '../../../Shared/LoadingIndicator.js';
import ButtonLoading from '../../../Shared/ButtonLoading.js';
import DelayedTextInput from 'components/Shared/DelayedTextInput.js';
import { ReorderableList } from 'components/Shared/ReorderableList.js';
import Button from 'components/Shared/Button.js';


class ManageStatusSettings extends Component {
    constructor(props) {
        super(props);

        this.fieldKey = this.props.group === 2 ? "sub_status_2" : "sub_status";

        this.state = {
            updatedStatuses: props.statuses,
            saveDisabled: true,
            fieldNameSaveDisabled: true,
            loading: false,
            custom_field_name: this.props.org.post_field_names[this.fieldKey],
            error: '',
            autosaveTimer: null,
            creatingStatus: false,
        };

        this.updateMade = this.updateMade.bind(this);
        this.save = this.save.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.statuses !== prevProps.statuses) {
            this.setState({updatedStatuses: this.props.statuses});
        }
    }

    fieldNameChange = (name) => {
        this.setState(
            {custom_field_name: name},
            () => {
                this.saveFieldName();
            }
        );
    }

    saveFieldName = () => {
        let self = this;
        let body = JSON.stringify({
            ...this.props.org.post_field_names,
            [this.fieldKey]: this.state.custom_field_name,
        });

        update_post_field_names(body).then(success => {
            success.json().then(success => {
                let org = safe_get(success, "organization", {});
                self.props.dispatch(update_current_org(org));
                self.setState({fieldNameSaveDisabled: true});
                NotificationAlert("success", "", "Changes saved.");
            });
        }, error => {

        });
    }

    updateMade(newStatus) {
        if (this.state.autosaveTimer) {
            clearTimeout(this.state.autosaveTimer);
        }

        const newStatuses = [...this.state.updatedStatuses];
        const index = newStatuses.findIndex(status => status.sub_status_uuid === newStatus.sub_status_uuid);
        if (index >= 0) {
            newStatuses[index] = newStatus;
        } else {
            return;
        }

        this.setState({
            updatedStatuses: newStatuses,
            autosaveTimer: setTimeout(() => {
                this.save();
            }, 500),
        });
    }

    save() {
        var self = this;
        this.setState({saving: true, error: '', autosaveTimer: null});

        var feed_statuses = [...(this.props.group === 2 ? this.props.feed_statuses_2 : this.props.feed_statuses)];
        var body = JSON.stringify({
            group: this.props.group,
            sub_statuses: this.state.updatedStatuses,
        });

        update_sub_statuses(body).then(function (success) {
            success.json().then(success => {
                var new_sub_statuses = safe_get(success, "sub_statuses", [])

                for (var i in new_sub_statuses) {
                    let new_status = new_sub_statuses[i];
                    var old_status = feed_statuses.find(function (obj) {
                        return obj.sub_status_uuid === new_status.sub_status_uuid
                    });

                    var index = feed_statuses.indexOf(old_status);
                    if (index >= 0) {
                        new_status.enabled ? (feed_statuses[index] = new_status) : (feed_statuses.splice(index, 1));
                    } else {
                        feed_statuses.push(new_status);
                    }
                }

                self.setState({saving: false, saveDisabled: true});
                self.props.update();
                self.props.dispatch(update_feed_statuses(feed_statuses, self.props.group));
                NotificationAlert("success", "", "Changes saved.");
            });

        }, function (error) {
            self.setState({saving: false});
            NotificationAlert("success", "", "Could not save changes.");
            if (error.status === 422) {
                error.json().then(errorJson => {
                    const message = getErrorMessage(errorJson, 'Could not save changes.');
                    self.setState({error: message});
                })
            }
        });
    }

    addNewStatus = async () => {
        try {
            this.setState({creatingStatus: true});
            const index = this.state.updatedStatuses.length;
            const body = JSON.stringify({
                name: `Option ${index + 1}`,
                enabled: true,
                order: index,
                group: this.props.group,
            });
        
            await createSubStatus(body);
            const res = await get_sub_status(`?all=1&group=${this.props.group}`);
            const data = await res.json();
            const {sub_statuses} = data;
            this.props.update();
            this.props.dispatch(update_feed_statuses(sub_statuses, this.props.group));
            NotificationAlert("success", "", `Added new ${this.state.custom_field_name}.`)
        } catch(err) {
            console.error(err);
            NotificationAlert("error", "", `Could not add new ${this.state.custom_field_name}.`)
        } finally {
            this.setState({creatingStatus: false});
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <LoadingIndicator/>
            )
        } else {
            return (
                <div>
                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
                    </div>
                    <div style={{marginBottom: "5px"}}>
                        <div style={{display: "inline-block"}}>
                            <DelayedTextInput
                                className="form-control topic-field"
                                defaultValue={this.state.custom_field_name}
                                onChange={this.fieldNameChange}
                                maxLength={15}
                                showCharacterCounter={true}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}><b>Options</b></p>
                    </div>
                    <ReorderableList
                        items={this.state.updatedStatuses}
                        itemKeyProp="sub_status_uuid"
                        renderItem={(status, index) => (
                            <StatusComponent
                                index={index}
                                item={status}
                                updateMade={this.updateMade}
                                isStatus={true}
                                isReorderable={true}
                            />
                        )}
                        onReorder={(updatedStatuses) => {
                            this.setState({updatedStatuses}, () => {
                                this.save();
                            })
                        }}
                    />
                    <div>
                        {this.state.error &&
                            <div>
                                <small className="error">{this.state.error}</small>
                            </div>
                        }
                    </div>

                    <Button
                        type="primary"
                        disabled={this.state.creatingStatus || this.state.updatedStatuses.length >= 25}
                        className="mar-top-15"
                        onClick={this.addNewStatus}
                    >Add option</Button>

                </div>
            );
        }


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        org: safe_get(store, "org_helper.organization", {}),
        feed_statuses: safe_get(store, "feed_helper.statuses", []),
        feed_statuses_2: safe_get(store, "feed_helper.statuses2", []),
    }
}

export default connect(mapStateToProps)(ManageStatusSettings);
