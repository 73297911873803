import { Result } from 'antd';
import { BorderedPanel, MainContent, PageHead } from 'components/Shared/PageParts';

export default function Public404Page() {
    return (
        <MainContent>
            <PageHead>
                <BorderedPanel className="zero-blue">
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, this page could not be found."
                />
                </BorderedPanel>
            </PageHead>
        </MainContent>
    );
}
