import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

import {create_corrective_action, update_corrective_action, delete_corrective_action} from '../../../api/zero-api.js';
import {safe_get, isRestrictedTeamUser} from '../../../other/Helper'

import NotificationAlert from '../../../other/NotificationAlert.js';
import NewPostModal from '../../Bulletin/NewPostModal.js';

import {InboxOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined, CheckOutlined} from '@ant-design/icons';
import {List} from 'antd';

import ConfirmModal from '../../Shared/ConfirmModal.js';

import '../../../assets/css/antd-custom.css'

var saveContentTimeout = null

class CorrectiveActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideCreatePostIndexes: [],
            corrective_actions: [],
            related_post: this.props.related_post,
            showCreatePost: true,
        }

        this.addAction = this.addAction.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.saveCorrectiveAction = this.saveCorrectiveAction.bind(this);
        this.postCreated = this.postCreated.bind(this);
        this.goToPost = this.goToPost.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.updateKey !== prevProps.updateKey) {
            this.setState({corrective_actions: this.props.corrective_actions});
        }
    }

    componentDidMount() {
        var actions = this.props.corrective_actions;
        this.setState({corrective_actions: actions});
    }

    addAction(text) {
        var self = this;

        var body = JSON.stringify({
            text: text
        });

        create_corrective_action(this.props.incident_uuid, body).then(function (success) {
            success.json().then(success => {
                let actions = [...self.state.corrective_actions];
                actions.push(safe_get(success, "corrective_action", {}));
                self.setState({corrective_actions: actions, showNewField: false, newActionText: ""});
                self.props.onChange("", "");
            })
        }, function (error) {
            console.log(error);
        });
    }

    handleNewInputChange = (event) => {
        const target = event.target;
        const text = target.value;
        this.setState({newActionText: text});
    }

    handleInputChange = (action_uuid, text, index) => {
        // clearTimeout(saveContentTimeout);

        // saveContentTimeout = setTimeout(() => {
        //   this.saveCorrectiveAction(action_uuid, text, index)
        // }, 500);
        this.setState({edit_action_text: text});
    }

    saveCorrectiveAction = () => {
        let self = this;

        this.setState({savingAction: true});

        if (!this.state.savingAction) {
            let index = this.state.editActionIndex
            let action = this.state.editAction

            let actions = [...this.state.corrective_actions]

            let text = safe_get(this.state, "edit_action_text", safe_get(action, "text", ""))

            var body = JSON.stringify({
                text: text
            });

            update_corrective_action(this.props.incident_uuid, action.corrective_action_uuid, body).then(() => {
                action["text"] = text;
                actions[index] = action;
                self.setState({
                    corrective_actions: actions,
                    editAction: null,
                    editActionIndex: null,
                    edit_action_text: null,
                    savingAction: false
                });
            });

            this.props.onChange("", "");
        }


    }

    postCreated(post) {
        var self = this;

        var actions = [...this.state.corrective_actions];
        var index = actions.indexOf(this.state.selected_action);

        var action = this.state.selected_action;
        var body = JSON.stringify({
            // corrective_action_uuid: action.corrective_action_uuid,
            text: action.text,
            post_uuid: post.post_uuid.replace("offline:", ""),
        });

        update_corrective_action(this.props.incident_uuid, action.corrective_action_uuid, body).then(function (success) {
            success.json().then(success => {
                var updated_action = safe_get(success, "corrective_action", {});
                actions[index] = updated_action;
                self.setState({corrective_actions: actions, showNewPost: false});
                NotificationAlert("success", "", "Post created.");
            });
        }, function (error) {
            self.setState({showNewPost: false});
            NotificationAlert("error", "", "Unable to create post.");
        });

    }

    goToPost(post_uuid) {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed/post/" + post_uuid)
    }

    deleteAction() {
        var self = this;
        delete_corrective_action(this.props.incident_uuid, this.state.action_to_delete.corrective_action_uuid).then(function (success) {
            self.setState(state => {
                var actions = state.corrective_actions;
                let action = self.state.action_to_delete
                var index = actions.indexOf(action);
                actions.splice(index, 1);
                self.props.onChange("", "");
                return {corrective_action: actions, showDeleteActionConfirmation: false}
            });
        }, function (error) {
            NotificationAlert("error", "", "Unable to delete corrective action.")
        });
    }

    getOptions(action, index, hasAvailableTeamToPost) {
        const {fieldsDisabled} = this.props;
        const options = [];

        if (fieldsDisabled) return [];

        if (this.state.editActionIndex === index) {
            options.push((
                <button onClick={this.saveCorrectiveAction}
                        style={{padding: 0, border: "none", background: "transparent"}}>
                    <CheckOutlined className="zero-blue" style={{pointerEvents: "none"}}/>
                </button>
            ))
        } else {
            if (!action.related_post && action.text !== "" && (this.state.hideCreatePostIndexes.indexOf(index) < 0) && hasAvailableTeamToPost) {
                options.push((
                    <span className="link-hover zero-light-blue"
                          onClick={() => this.setState({selected_action: action, showNewPost: true})}>Post</span>
                ));
            } else if (action.related_post) {
                options.push((
                    <>
                        <span className="link-hover zero-light-blue" onClick={() => {
                            this.goToPost(action.related_post.post_uuid)
                        }}>Post created</span>
                        <span>&nbsp;({action.related_post.status})</span>
                    </>
                ));
            }

            options.push((
                <button onClick={() => {
                    this.setState({editAction: action, editActionIndex: index})
                }} style={{padding: 0, border: "none", background: "transparent"}}>
                    <EditOutlined className="zero-blue" style={{pointerEvents: "none"}}/>
                </button>
            ));

            options.push((
                <DeleteOutlined
                    className="zero-delete-red"
                    onClick={event => {
                        event.stopPropagation();
                        this.setState({showDeleteActionConfirmation: true, action_to_delete: action});
                    }}
                />
            ));
        }

        return options;
    }

    render() {
        const {fieldsDisabled} = this.props;

        let hasAvailableTeamToPost = false;
        let teams = this.props.teams;
        for (var i in teams) {
            let team = teams[i];
            if (!isRestrictedTeamUser(team, this.props.user)) {
                hasAvailableTeamToPost = true;
                break;
            }
        }

        return (
            <div>
                {
                    this.state.showDeleteConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteConfirmation}
                        cancel={() => {
                            this.setState({showDeleteConfirmation: false});
                        }}
                        confirm={this.confirmDelete}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this attachment?"}
                        confirmButtonName={"Delete"}
                    />
                }

                {
                    this.state.showDeleteActionConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteActionConfirmation}
                        cancel={() => {
                            this.setState({showDeleteActionConfirmation: false});
                        }}
                        confirm={this.deleteAction}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this corrective action?"}
                        confirmButtonName={"Delete"}
                    />
                }

                {
                    this.state.showNewPost &&
                    <NewPostModal
                        show={this.state.showNewPost}
                        cancel={() => {
                            this.setState({showNewPost: false});
                        }}
                        description={this.state.selected_action.text}
                        incident_uuid={this.props.incident_uuid}
                        postCreated={this.postCreated}
                    />
                }

                {/* {
          this.state.showCorrectiveActionModal &&
          <CorrectiveActionTextModal
            show={this.state.showCorrectiveActionModal}
            cancel={() => { this.setState({ showCorrectiveActionModal: false }); }}
            selectedAction={this.state.selectedCorrectiveAction}
            saveAction={this.saveCorrectiveAction}
            addAction={this.addAction}
          />
        } */}

                <h3 className="zero-blue print-only"
                    style={{marginBottom: "20px", marginTop: "5px", fontSize: "120%", textDecoration: "underline"}}>
                    Corrective Actions and Notes
                </h3>

                {
                    this.state.corrective_actions.length > 0 &&
                    <List
                        className=""
                        itemLayout="horizontal"
                        bordered
                        dataSource={this.state.corrective_actions}
                        renderItem={(action, index) => (
                            <List.Item actions={this.getOptions(action, index, hasAvailableTeamToPost)}>
                                <div style={{width: "100%"}}>
                                    {
                                        index !== this.state.editActionIndex &&
                                        <h3 className="titles onboarding invite-link mt-0" style={{
                                            marginBottom: "5px",
                                            marginTop: "0px",
                                            fontSize: "100%",
                                            lineHeight: "1.4"
                                        }}>
                                            <span className="zero-blue"
                                                  style={{fontWeight: "500"}}>{index + 1}. {action.text}</span>
                                        </h3>
                                    }

                                    {
                                        index === this.state.editActionIndex &&
                                        <textarea
                                            name="corrective_actions"
                                            className="form-control custom-placeholder"
                                            placeholder="Please describe the corrective action or long-term improvement solution."
                                            rows="2"
                                            maxLength={2000}
                                            defaultValue={action.text}
                                            onChange={(e) => {
                                                this.handleInputChange(action.corrective_action_uuid, e.target.value, index);
                                            }}
                                            // onBlur={() => { this.setState({ editActionIndex: null }); }}
                                        />
                                    }
                                </div>
                            </List.Item>
                        )}
                    />
                }

                {this.props.isClosed && this.state.corrective_actions.length === 0 &&
                    <p className="mar-btm-0 zero-dark-grey">No corrective actions.</p>
                }

                {
                    this.state.showNewField &&
                    <div className="mar-top-10">
            <textarea
                name="corrective_actions"
                className="form-control custom-placeholder"
                placeholder="Please describe the corrective action, countermeasure, or long-term improvement solution."
                onChange={this.handleNewInputChange}
                // value={safe_get(this.state, "action.text", "")}
                rows="2"
                maxLength={2000}
            />
                    </div>
                }
                {
                    this.state.showNewField &&
                    <div className="" style={{marginTop: "10px", textAlign: "right"}}>
                        <button className="btn btn-discard" style={{marginRight: "8px"}} onClick={() => {
                            this.setState({showNewField: false})
                        }}>Cancel
                        </button>
                        <button className="btn btn-primary" onClick={() => {
                            this.addAction(this.state.newActionText)
                        }}>Add
                        </button>
                    </div>
                }

                {
                    !fieldsDisabled && !this.state.showNewField &&
                    <button className="ButtonLink blue-link mar-top-10 dont-print" style={{fontSize: "13px"}}
                            onClick={() => {
                                this.setState({showNewField: true})
                            }}>
                        <PlusCircleOutlined className="mar-rgt-5"/>
                        Add a new action or note
                    </button>
                }
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        all_assignments: safe_get(store, "assignments", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(CorrectiveActions));

