import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {extractErrorMessages} from 'other/Helper';
import {update_desk, create_desk, delete_desk} from "api/zero-api";

const initialState = {
    desks: null,
    directory: null,
    filters: null,
    deskFilterQuery: '',
    loading: false,
    error: false,
    pagination: {
        currentPage: 1,
        maxResults: 0,
        total: 0
    },
    deskToEdit: null,
    deskModalVisible: false,
    deleteDeskModalVisible: false,
    modifyDeskStatus: {}
}

export const updateDesk = createAsyncThunk(
    'manageDesks/updateDesk',
    async ({uuid, body}, {rejectWithValue}) => {
        try {
            const response = await update_desk(uuid, JSON.stringify(body));
            const data = await response.json();
            return data;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

export const createDesk = createAsyncThunk(
    'manageDesks/createDesk',
    async ({body}, {rejectWithValue}) => {
        try {
            const response = await create_desk(JSON.stringify(body));
            const data = await response.json();
            return data;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

export const deleteDesk = createAsyncThunk(
    'manageDesks/deleteDesk',
    async ({uuid}, {rejectWithValue}) => {
        try {
            const response = await delete_desk(uuid);
            return uuid;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

const slice = createSlice({
    name: 'manageDesks',
    initialState,
    reducers: {
        resetManagedDesksState: () => initialState,
        updateManagedDesksState: (state, {payload}) => {
            for (const [key, value] of Object.entries(payload)) {
                state[key] = value;
            }
        },
        setDesks: (state, {payload}) => {
            state.desks = payload;
        },
        setDirectory: (state, {payload}) => {
            state.directory = payload;
        },
        setLoading: (state, {payload}) => {
            state.loading = payload;
        },
        setError: (state, {payload}) => {
            state.error = payload;
        },
        setPage: (state, {payload}) => {
            state.pagination.currentPage = payload;
        },
        setDeskFilterQuery: (state, {payload}) => {
            state.deskFilterQuery = payload;
        },
        showDeskModal: (state, {payload}) => {
            if (state.loading) return;

            state.deskModalVisible = true;
            state.deskToEdit = payload || null;
        },
        hideDeskModal: (state) => {
            state.deskModalVisible = false;
            state.deskToEdit = null;
            state.modifyDeskStatus = {};
        },
        showDeleteDeskModal: (state, {payload}) => {
            if (state.loading) return;

            state.deleteDeskModalVisible = true;
            state.deskToEdit = payload;
        },
        hideDeleteDeskModal: (state) => {
            state.deleteDeskModalVisible = false;
            state.deskToEdit = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateDesk.fulfilled, (state, {payload}) => {
            state.modifyDeskStatus = {
                data: payload,
                ok: true
            }

            state.deskToEdit = null;
            state.deskModalVisible = false;

            if (state.desks !== null) {
                const index = state.desks.findIndex(desk => desk.uuid === payload.uuid);
                if (index !== -1) {
                    state.desks[index] = payload;
                }
            }
        });
        builder.addCase(updateDesk.pending, (state) => {
            state.modifyDeskStatus = {
                loading: true
            }
        });
        builder.addCase(updateDesk.rejected, (state, {payload}) => {
            state.modifyDeskStatus = {
                data: payload,
                error: true
            }
        });

        builder.addCase(createDesk.fulfilled, (state, {payload}) => {
            state.modifyDeskStatus = {
                data: payload,
                ok: true
            }

            state.deskToEdit = null;
            state.deskModalVisible = false;

            if (state.desks !== null) {
                state.desks.splice(0, 0, payload);

                state.desks.sort((a, b) => {
                    const aName = a.name.toLowerCase();
                    const bName = b.name.toLowerCase();

                    return aName < bName ? -1 : 1;
                })
            }
        });
        builder.addCase(createDesk.pending, (state) => {
            state.modifyDeskStatus = {
                loading: true
            }
        });
        builder.addCase(createDesk.rejected, (state, {payload}) => {
            state.modifyDeskStatus = {
                data: payload,
                error: true
            }
        });

        builder.addCase(deleteDesk.fulfilled, (state, {payload}) => {
            if (state.desks !== null) {
                const index = state.desks.findIndex(desk => desk.uuid === payload);
                if (index !== -1) {
                    state.desks.splice(index, 1);
                }
            }
            state.deleteDeskModalVisible = false;
            state.deskToEdit = null;
        });

        builder.addCase(deleteDesk.rejected, (state, {payload}) => {
            state.deleteDeskModalVisible = false;
            state.deskToEdit = null;
        });
    }
});


export const {actions} = slice;
export default slice.reducer;