import { createContext, useEffect, useState, useCallback, useContext } from 'react';
import { Modal, Radio, Space, Spin, Tag } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom';

import { dateFormatterWithTime, titleCase } from "other/Helper";
import { useZeroContext } from 'components/ZeroContext';
import GenericDraftCard from '../GenericDraftCard';
import useOrganizationId from 'hooks/useOrganizationId';
import { usePostIsSyncing } from 'hooks/offlineHooks';
import Button from 'components/Shared/Button';
import { PostEvents } from 'services/postService';

export const PostDraftsListContext = createContext({
    insideModal: false,
    subModalVisible: false,
    setSubModalVisible: () => {},
});

/**
 * @param {{
 *  draft: LocalPost;
 *  setShowResolveDraftErrorModal: function;
 * }} props
 */
function PostStatusTag({draft, setShowResolveDraftErrorModal}) {
    let text = 'In Sync';
    let color = null;
    let style = {
        color: 'var(--zero-dark-grey)'
    };

    if (draft.$meta.error) {
        // const { type } = draft.$meta.error;
        text = (
            <span className="link-hover" onClick={() => setShowResolveDraftErrorModal(true)}>
                <InfoCircleOutlined className="mar-rgt-5"/>Sync Error ({titleCase(draft.$meta.error.type)})
            </span>
        );
        color = 'orange';
        style = {
            borderColor: '#FA8C13'
        };
    } else if (draft.$meta.submitted) {
        text = 'Submit Pending';
        color = 'green';
        style = null;
    } else if (draft.$meta.updated) {
        text = 'Sync Required';
    } else if (draft.$meta.offline) {
        text = 'Offline Only';
        color = 'blue';
        style = null;
    }

    return (
        <Tag style={style} color={color}>{text}</Tag>
    )
}

/**
 * @param {{
 *  post: LocalPost;
 *  onCancel: function;
 *  onReload?: function;
 * }} props 
 * @returns {JSX.Element}
 */
function ResolvePostDraftErrorModal({post, onCancel, onReload}) {
    const context = useZeroContext();
    const { isOffline } = context;
    const postService = context.services.post;
    const postError = post.$meta.error;

    const {insideModal} = useContext(PostDraftsListContext);
    const history = useHistory();
    const orgId = useOrganizationId();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [versionToKeep, setVersionToKeep] = useState(null);
    const [remoteDraft, setRemoteDraft] = useState(null);
    
    useEffect(() => {
        async function fetchData() {
            if (postError.type === 'conflict') {
                try {
                    const remotePost = await postService.getRemotePost(post.post_uuid);
                    setRemoteDraft(remotePost);
                } catch (err) {
                    console.error('Could not load remote draft:', err);
                    setErrorMessage('Could not load remote draft at this time.');
                }
            }
        }

        fetchData();
    }, [postError.type, post.post_uuid]);

    // const deleteDraft = useCallback(async () => {
    //     try {
    //         setIsLoading(true);
    //         cache.onChangeCallbacks.push(() => {
    //             onCancel()
    //         });
    //         await cache.delete(post._id, {immediate: true, includeAttachments: true});
    //     } catch (err) {
    //         console.error('Could not delete draft:', err);
    //         setIsLoading(false);
    //         setErrorMessage('Could not delete draft. Please contact support.');
    //     }
    // }, [post, setErrorMessage, setIsLoading]);

    const retrySync = useCallback(async (event) => {
        try {
            setIsLoading(true);
            const { success } = await postService.syncLocalPostToRemote(post._id, {ignorePrevSyncError: true});
            if (!success) {
                history.push(`/${orgId}/home/team/my_teams/feed/new_post/${post.post_uuid}`);
            } else {
                onReload?.();
                onCancel();
            }
        } catch (err) {
            console.error('Could not sync draft:', err);
            setIsLoading(false);
            setErrorMessage('Could not sync draft. Please retry when connected to wifi, or contact support.');
            onCancel();
        }
    }, [post, setErrorMessage, setIsLoading]);

    const retrySubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            const { success } = await postService.syncLocalPostToRemote(post._id, {ignorePrevSyncError: true});
            if (!success) {
                history.push(`/${orgId}/home/team/my_teams/feed/new_post/${post.post_uuid}`);
            } else {
                onReload?.();
                onCancel();
            }
        } catch (err) {
            console.error('Could not sync draft:', err);
            setIsLoading(false);
            setErrorMessage('Could not sync draft. Please contact support.');
            onCancel();
        }
    }, [history, orgId, post, postService]);

    const resolveSyncConflict = useCallback(async () => {
        try {
            setIsLoading(true);

            if (versionToKeep === 'local') {
                await postService.syncLocalPostToRemote(post.post_uuid, {ignorePrevSyncError: true});
                onReload?.();
                onCancel();
            } else {
                await postService.deletePostDraft(post.post_uuid, {force: true});
                await postService.cacheDraft(remoteDraft.post_uuid, remoteDraft);
                onReload?.();
                onCancel();
            }
        } catch (err) {
            console.error('Could not resolve sync conflict:', err);
            setIsLoading(false);
            setErrorMessage('Could not resolve sync conflict. Please contact support.');
            onCancel();
        }
    }, [post, remoteDraft, versionToKeep, setIsLoading, setErrorMessage]);

    const title = postError.type === 'conflict' ? 'Sync Conflict' : `Sync Error (${titleCase(postError.type)})`;
    let buttons = [];
    let text = '';
    const retryButton = <Button key="retry" type="primary" disabled={isLoading || isOffline}
                                onClick={retrySync}>Retry</Button>
    switch (postError.type) {
        case 'create':
            text = 'Could not create this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(retryButton);
            break;
        case 'update':
            text = 'Could not update this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(retryButton);
            break;
        case 'delete':
            text = 'Could not delete this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions';
            buttons.push(retryButton);
            break;
        case 'submit':
            text = 'Could not submit this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(
                <Button key="goToDraft" type="primary" disabled={isLoading || isOffline}
                        onClick={retrySubmit}>Retry</Button>
            );
            break;
        case 'conflict':
            text = (
                <>
                    <div>You have 2 versions of this draft (one local and one remote). Which version would you like to keep?</div>
                    <Radio.Group className="mar-top-10" onChange={e => setVersionToKeep(e.target.value)}
                                    value={versionToKeep}>
                        <Space direction="vertical" size={0}>
                            <Radio value="local">
                                <span className="text-semibold">Local version</span>: last edited
                                on {dateFormatterWithTime(post.revised_at)}
                            </Radio>
                            <Radio disabled={remoteDraft === null} value="remote">
                                <span className="text-semibold">Remote version</span>:
                                {remoteDraft ? (
                                    <> last edited on {dateFormatterWithTime(remoteDraft.revised_at)}</>
                                ) : (
                                    <Spin/>
                                )}
                            </Radio>
                        </Space>
                    </Radio.Group>
                </>
            );
            buttons.push(
                <Button key="create" type="primary" disabled={isLoading || versionToKeep === null || isOffline}
                        onClick={resolveSyncConflict}>Confirm</Button>,
            );
            break;
        default:
            text = 'Could not sync this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(retryButton);
    }

    if (!postError) {
        onCancel();
        return null;
    }

    const footer = (
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button type="discard" onClick={onCancel}>Cancel</Button>
                {buttons}
            </div>
        </div>
    )

    // if (showDeleteConfirmation) {
    //     return <ConfirmDeleteModal
    //         onConfirm={deleteDraft}
    //         onCancel={() => setShowDeleteConfirmation(false)}
    //         isDeleting={isLoading}
    //     />
    // }

    return (
        (<Modal
            title={title}
            open={true}
            maskClosable={false}
            closable={false}
            onCancel={onCancel}
            footer={footer}
            transitionName={insideModal ? "" : "maskTransitionName"} // https://ant.design/components/modal/#How-to-disable-motion
            maskTransitionName={insideModal ? "" : "maskTransitionName"}
            mask={!insideModal}
        >
            <div>{text}</div>
            {isOffline &&
                <>
                    <br/>
                    <div>You're offline, please cancel and try again later.</div>
                </>
            }
            {errorMessage &&
                <small className="error">{errorMessage}</small>
            }
        </Modal>)
    );
}

export function PostDraftListItem({draft, onEdit, onDelete, onReload}) {
    const context = useZeroContext();
    const orgId = useOrganizationId();
    const isSyncing = usePostIsSyncing(context.services.post);
    const [showResolveDraftErrorModal, _setShowResolveDraftErrorModal] = useState(false);
    const { subModalVisible, setSubModalVisible } = useContext(PostDraftsListContext);

    function setShowResolveDraftErrorModal(value) {
        _setShowResolveDraftErrorModal(value);
        setSubModalVisible(value);
    }
    
    const editPostLink = `/${orgId}/home/team/my_teams/feed/new_post/${draft.post_uuid}`;

    return (
        <>
            { showResolveDraftErrorModal &&
                <ResolvePostDraftErrorModal
                    post={draft}
                    onCancel={() => setShowResolveDraftErrorModal(false)}
                    onReload={onReload}
                />
            }
            { !subModalVisible &&
                <GenericDraftCard
                    title={(draft?.title || "Untitled Draft")}
                    titleLink={editPostLink}
                    subtitle1={<>Saved post in {draft?.source_team?.name ?? 'N/A'}</>}
                    subtitle2={<>Last edited on {dateFormatterWithTime(draft?.revised_at ?? 0)}</>}
                    tag={<PostStatusTag draft={draft} setShowResolveDraftErrorModal={setShowResolveDraftErrorModal} />}
                    editAction={{
                        handler: onEdit,
                        link: editPostLink,
                        disabled: isSyncing,
                    }}
                    deleteAction={{
                        handler: onDelete,
                        showPopconfirm: true,
                        popconfirmText: "Are you sure you want to delete this post?",
                        disabled: isSyncing,
                    }}
                />
            }
        </>
    )
}

/**
 * 
 * @param {object} props
 * @param {LocalPost[]} props.drafts
 * @param {(draft: LocalPost) => void} [props.onEdit]
 * @param {(draft: LocalPost) => void} [props.onDelete]
 * @param {() => void} [props.onReload]
 * @returns 
 */
export function PreloadedPostDraftsList({drafts, onEdit, onDelete, onReload}) {
    const onEditHandler = onEdit ? (draft) => onEdit(draft) : null;
    return (
        <div>
            { drafts.map((draft) => (
                <PostDraftListItem
                    key={draft.post_uuid}
                    draft={draft}
                    onEdit={onEditHandler}
                    onDelete={() => {
                        onDelete?.(draft);
                    }}
                    onReload={onReload}
                />
            )) }
        </div>
    )
}

export function PostDraftsList({cachedDrafts, setCachedDrafts}) {
    const zeroContext = useZeroContext();
    const postService = zeroContext.services.post;
    /** @type {[LocalPost[], function]} */
    const [drafts, setDrafts] = useState(cachedDrafts ?? []);
    const [isLoading, setIsLoading] = useState(drafts.length === 0);

    async function loadDrafts() {
        const newDrafts = await postService.getLocalPosts({onlyDrafts: true, includeDeleted: false});
        setCachedDrafts?.(newDrafts);
        setDrafts(newDrafts);
        setIsLoading(false);
    }

    useEffect(() => {
        loadDrafts();

        const unsubscribe = postService.subscribe(({data}) => {
            if (data.type === PostEvents.IS_SYNCING_CHANGE && data.isSyncing === false) {
                loadDrafts();
            }
        });

        return () => {
            unsubscribe();
        }
    }, []);

    if (isLoading) {
        return null;
    }

    if (drafts.length === 0) {
        return <div className="text-center zero-dark-grey">No drafts saved to device.</div>
    }

    return (
        <PreloadedPostDraftsList
            drafts={drafts}
            onDelete={async (draft) => {
                await postService.deletePostDraft(draft.post_uuid);
                await loadDrafts();
            }}
            onReload={async () => {
                await loadDrafts();
            }}
        />
    )
}
