import { Select } from "antd";
import { useMemo } from "react";

/**
 * 
 * @param {{
 * options: StatusSelectOption[],
 * value: string,
 * disabled: boolean,
 * print: boolean,
 * onChange: (value: string) => {}
 * }} props 
 */
export default function StatusSelect({options, value, disabled, print, onChange}) {
    const selectOptions = useMemo(() => {
        return options.map(option => ({
            value: option.value,
            label: <StatusIndicator color={option.color}>{option.label}</StatusIndicator>
        }))
    }, [options]);

    return (
        <Select
            value={value}
            onChange={onChange}
            options={selectOptions}
            style={{width: "12rem"}}
            className={print ? "" : "dont-print"}
            disabled={disabled}
        />
    )
}

/**
 * 
 * @param {{
*  color: StatusIndicatorColor
* }} props 
* 
* @returns {JSX.Element}
*/
function StatusIndicator({color, children}) {
   let colorValue = color;

   if (color === "grey") {
       colorValue = "#b6b6b6";
   } else if (color === "yellow") {
       colorValue = "#ffb300";
   } else if (color === "green") {
       colorValue = "#8bc34a";
   }

   const wrapperStyle = {
       display: 'flex',
       alignItems: 'baseline',
       gap: '0.8rem'
   };

   const elStyle = {
       width: 8,
       height: 8,
       backgroundColor: colorValue,
       borderRadius: 25,
   };
   return <div style={wrapperStyle}><div style={elStyle}></div>{children}</div>
}