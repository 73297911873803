// @ts-check

import { useOrgAndUserCacheKey } from "hooks/reduxHooks";
import { useCallback, useEffect, useState } from "react";

/**
 * 
 * @param {string} dataGridName 
 * @returns {[any, (key: string, value: any) => void]}
 */
export function useDataGridSettings(dataGridName) {
    const cacheKeyPrefix = useOrgAndUserCacheKey();
    const cacheKey = `zero-data-grid:${cacheKeyPrefix}:${dataGridName}`;
    const rawSettings = localStorage.getItem(cacheKey);
    const dataGridSettings = rawSettings ? JSON.parse(rawSettings) : null;

    const [settings, setSettings] = useState(dataGridSettings);

    const setAndSaveSettings = useCallback((key, value) => {
        setSettings(currentSettings => {
            const newSettings = {
                ...(currentSettings ?? {}),
                [key]: value,
            };
            localStorage.setItem(cacheKey, JSON.stringify(newSettings));
            return newSettings;
        });
    }, [cacheKey]);

    return [settings, setAndSaveSettings];
}