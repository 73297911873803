import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {Modal, Select} from 'antd';

import {update_auth_token, kiosk_turn_on, kiosk_rename_device} from '../../../api/zero-api.js'
import {safe_get, fieldIsNotEmpty} from '../../../other/Helper';

import NotificationAlert from '../../../other/NotificationAlert';

import Skeleton from 'react-loading-skeleton';
import ButtonLoading from '../../Shared/ButtonLoading';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import InfoIconComponent from '../../InfoIconComponent';

import '../../../assets/css/antd-custom.css';

class NewEditKioskModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kiosk_name: safe_get(this.props, "editKiosk.device_name", ""),
        };

    }

    componentDidMount() {
        var self = this;

    }

    kioskNameChange = (e) => {
        this.setState({kiosk_name: e.target.value});
    }

    handleConfirm = () => {
        let self = this;

        if (!this.state.savingKiosk) {

            this.setState({savingKiosk: true});

            let device_name = safe_get(this.state, "kiosk_name", "");
            if (fieldIsNotEmpty(device_name)) {
                var body = JSON.stringify({
                    device_name: device_name
                });

                if (this.props.editKiosk) {
                    kiosk_rename_device(this.props.editKiosk.session_uuid, body).then(function (success) {
                        NotificationAlert('success', 'Success!', "Kiosk name has been changed.");
                        self.props.cancel()
                        self.props.refresh();
                    }, function (error) {
                        NotificationAlert('error', 'Oops!', "An Error occurred. Unable to change kiosk name.");
                        self.props.cancel()
                    });

                } else {
                    kiosk_turn_on(body).then(function (success) {
                        success.json().then(success => {
                            self.setState({savingKiosk: false});
                            update_auth_token(safe_get(success, "kiosk_mode_token", undefined))
                            window.location = "/kiosk_mode"
                        });
                    }, function (error) {
                        console.log(error);
                        self.setState({savingKiosk: false});
                    });
                }

            }

        }

    }

    render() {

        return (
            (<Modal
                title={"Create Kiosk"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.handleConfirm}
                                disabled={this.state.savingKiosk}>
                            {this.state.savingKiosk ? <ButtonLoading/> : this.props.editKiosk ? "Save" : "Create"}
                        </button>
                    </div>
                }
            >
                <div className="">
                    <h3 className="titles"
                        style={{marginBottom: '2px', marginLeft: '0px', fontSize: '110%', marginTop: "0px"}}>Name</h3>
                    <input type={"text"} placeholder={"Name this device (e.g., Operations iPad)"}
                           className={"form-control custom-placeholder"} value={this.state.kiosk_name}
                           onChange={this.kioskNameChange}/>
                </div>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(NewEditKioskModal));