import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Select, Input, Radio} from 'antd';

import ButtonLoading from 'components/Shared/ButtonLoading';
import {PrettyDeskTypeMap} from 'other/Constants';
import {actions as manageDesksActions, updateDesk, createDesk} from 'store/slices/manageDesks';

const initialErrorState = {
    name: '',
    space_uuid: '',
    type: '',
};

const BLANK_FIELD = 'This field may not be blank.'
const NULL_FIELD = 'This field may not be null.'

const errorMap = {
    name: {
        [BLANK_FIELD]: 'Please enter a name.',
        'Duplicate desk name.': 'This name already exists.'
    },
    space_uuid: {
        [NULL_FIELD]: 'Please select a space.'
    },
    type: {
        [NULL_FIELD]: 'Please select a type.'
    }
}

function mapError(field, message) {
    const fieldMap = errorMap[field];
    if (fieldMap) {
        const mappedError = fieldMap[message];
        if (mappedError) {
            return mappedError;
        }
    }
    return message;
}


export default function CreateEditDeskModal({existingDesk}) {
    const dispatch = useDispatch();
    const filterOptions = useSelector(state => state.manageDesks.filters);
    const {locations, spaces} = filterOptions;
    const modifyDeskStatus = useSelector(state => state.manageDesks.modifyDeskStatus);
    const {loading, error, data} = modifyDeskStatus;

    const [name, setName] = useState('');
    const [locationId, setLocationId] = useState(null);
    const [spaceId, setSpaceId] = useState(null);
    const [type, setType] = useState(null);
    const [enabled, setEnabled] = useState(true);

    const [errorState, setErrorState] = useState(initialErrorState);

    useEffect(() => {
        if (existingDesk) {
            const {name, enabled, type, space} = existingDesk;
            const {location} = space;

            setName(name);
            setLocationId(location.uuid);
            setSpaceId(space.uuid);
            setEnabled(enabled);
            setType(type);
        }
    }, [existingDesk]);

    useEffect(() => {
        if (error) {
            const newErrorState = {...initialErrorState};

            for (const errorData of data) {
                const {field, message} = errorData;
                newErrorState[field] = mapError(field, message);
            }

            setErrorState(newErrorState);
        } else {
            setErrorState(initialErrorState);
        }
    }, [error]);

    const save = () => {
        if (existingDesk) {
            const payload = {
                uuid: existingDesk.uuid,
                body: {
                    name,
                    type,
                    enabled
                }
            }
            dispatch(updateDesk(payload));
        } else {
            const payload = {
                body: {
                    name,
                    type,
                    enabled,
                    location_uuid: locationId,
                    space_uuid: spaceId,
                    assigned_user_uuids: []
                }
            };
            dispatch(createDesk(payload));
        }
    }

    const onCancel = () => {
        dispatch(manageDesksActions.hideDeskModal());
    }

    const h3Style = {marginBottom: '2px', marginLeft: '0px', fontSize: '100%', marginTop: '15px'};

    return (
        (<Modal
            title={existingDesk ? 'Edit desk' : 'Create desk'}
            maskClosable={false}
            open={true}
            onCancel={onCancel}
            footer={
                <div>
                    <button className="btn btn-discard" disabled={loading} onClick={onCancel}>Cancel</button>
                    {
                        loading &&
                        <button className="btn btn-modal">
                            <ButtonLoading/>
                        </button>
                    }
                    {
                        !loading &&
                        <button className="btn btn-modal" onClick={save}>
                            {existingDesk ? "Update" : "Create"}
                        </button>
                    }
                </div>
            }
        >
            <h3 className="titles required" style={{...h3Style, marginTop: 0}}>Desk Name</h3>
            <Input
                value={name}
                onChange={e => setName(e.target.value)}
            />
            {errorState.name &&
                <small className="error" style={{display: "block", marginBottom: "0.5rem"}}>{errorState.name}</small>
            }
            <h3 className="titles required" style={h3Style}>Location</h3>
            <Select
                value={locationId}
                onChange={setLocationId}
                dropdownStyle={{zIndex: "10000"}}
                style={{width: '100%'}}
                virtual={false}
                disabled={existingDesk}
            >
                {
                    locations.map(location => {
                        const {uuid, name} = location;
                        return <Select.Option key={uuid} value={uuid}>{name}</Select.Option>
                    })
                }
            </Select>
            <h3 className="titles required" style={h3Style}>Space</h3>
            <Select
                value={spaceId}
                onChange={setSpaceId}
                dropdownStyle={{zIndex: "10000"}}
                style={{width: '100%'}}
                virtual={false}
                disabled={existingDesk || !locationId}
            >
                {
                    spaces.filter(space => space.location_uuid === locationId).map(space => {
                        const {uuid, name} = space;
                        return <Select.Option key={uuid} value={uuid}>{name}</Select.Option>
                    })
                }
            </Select>
            {errorState.space_uuid &&
                <small className="error"
                       style={{display: "block", marginBottom: "0.5rem"}}>{errorState.space_uuid}</small>
            }
            <h3 className="titles required" style={h3Style}>Type</h3>
            <Select
                value={type}
                onChange={setType}
                dropdownStyle={{zIndex: "10000"}}
                style={{width: '100%'}}
                virtual={false}
            >
                {
                    Object.entries(PrettyDeskTypeMap).map(entry => {
                        const [key, value] = entry;
                        return <Select.Option key={key} value={key}>{value}</Select.Option>
                    })
                }
            </Select>
            {errorState.type &&
                <small className="error" style={{display: "block", marginBottom: "0.5rem"}}>{errorState.type}</small>
            }
            <h3 className="titles required" style={h3Style}>Status</h3>
            <div>
                <Radio.Group value={enabled} onChange={e => setEnabled(e.target.value)}>
                    <Radio value={true}>Enabled</Radio>
                    <Radio value={false}>Disabled</Radio>
                </Radio.Group>
            </div>
        </Modal>)
    );
}