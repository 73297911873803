import React, {Component} from 'react';

import {Select, Modal} from 'antd'
import ButtonLoading from '../Shared/ButtonLoading.js';

import '../../assets/css/antd-custom.css';

class MoveIncidentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_location: undefined,
        };

        this.handleLocationSelect = this.handleLocationSelect.bind(this);
        this.confirmMove = this.confirmMove.bind(this);
    }

    componentDidMount() {

    }

    handleLocationSelect(value) {
        this.setState({
            selected_location: value
        });
    }

    confirmMove() {
        this.setState({movingIncidnet: true});
        this.props.confirm(this.state.selected_location);
    }

    render() {
        return (
            (<Modal
                title="Move this incident..."
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.confirmMove} disabled={!this.props.canMove}>
                            {
                                this.state.movingIncidnet ? <ButtonLoading/> : "Move this incident"
                            }
                        </button>
                    </div>
                }
            >
                {
                    this.props.canMove &&
                    <div>
                        <p className="zero-dark-grey">Select the location you'd like to move this incident to:</p>
                        <Select
                            style={{width: "100%",}}
                            className="move-team-select"
                            showArrow={true}
                            placeholder="Select a location"
                            dropdownStyle={{zIndex: "10000"}}
                            value={this.state.selected_location}
                            onChange={this.handleLocationSelect.bind(this)}>
                            {
                                this.props.locations.map((location, index) => (
                                    <Select.Option key={location.location_uuid} value={location.location_uuid}
                                                   disabled={(location.location_uuid === this.props.source_location)}>{location.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                }
                {
                    !this.props.canMove &&
                    <p className="zero-dark-grey">Cannot move this incident. You are only a member of one team.</p>
                }
            </Modal>)
        );
    }

}

export default MoveIncidentModal;