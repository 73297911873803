import styles from './ChartLabels.module.css';

/**
 * @param {string} label
 */
function splitLabel(label) {
    const index = label.lastIndexOf(':');
    const text = label.substring(0, index).trim();
    const stats = label.substring(index + 1, label.length).trim();
    return [text, stats];
}

export default function ChartLabels({data}) {
    const {labels} = data;
    let backgroundColors = data.datasets[0].backgroundColor;
    if (!Array.isArray(backgroundColors)) {
        backgroundColors = [backgroundColors];
    }

    return (
        <div className={styles.labelWrapper}>
            {
                labels.map((label, index) => {
                    const [text, stats] = splitLabel(label);
                    const labelColor = backgroundColors[index % backgroundColors.length];
                    return (
                        <div className={styles.label} key={index}>
                            <div
                                className={styles.labelColor}
                                ref={(node) => {
                                    node?.style?.setProperty('background-color', labelColor, 'important');
                                }}
                            ></div>
                            <span className={styles.labelText}>{text}</span>:<span
                            className={styles.labelStats}>{stats}</span>
                        </div>
                    );
                })
            }
        </div>
    )
}