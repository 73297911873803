import React, {Component} from "react";
import PropTypes from 'prop-types';
import ButtonLoading from "../../Shared/ButtonLoading";
import {InputNumber, Modal, Switch} from "antd";
import safe_get from "../../../other/SafeGet";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {dateFormatterNoTime, dateFormatterWithTime, scheudleFrequency} from "../../../other/Helper";

export default class ScheduleReminderModal extends Component {
    static propTypes = {
        confirm: PropTypes.func.isRequired,
        cancel: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        schedule: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            confirming: false,
            sendReminders: safe_get(props.schedule, 'send_reminders', false),
            daysBeforeReminder: safe_get(props.schedule, 'days_before_reminder', 1)
        };
    }

    cancel = () => {
        if (this.state.confirming) return;

        this.props.cancel();
    }

    confirm = () => {
        if (this.state.confirming) return;

        this.setState({confirming: true});
        this.props.confirm(this.state.sendReminders, this.state.daysBeforeReminder);
    }

    onDaysBeforeChange = value => {
        this.setState({daysBeforeReminder: value});
    }

    renderReminderHistory() {
        const {schedule} = this.props;
        const recent_reminders = safe_get(schedule, 'recent_reminders', null);

        if (!recent_reminders || !recent_reminders.total_reminders) {
            return <p>No reminders sent yet for this schedule.</p>
        }

        return (
            <>
                {recent_reminders.reminders.map((reminder, index) => (
                    <p key={index}>Reminder
                        sent {dateFormatterWithTime(reminder.date)} to {reminder.sent_count} {reminder.sent_count === 1 ? 'responder' : 'responders'}.</p>
                ))}
                {recent_reminders.total_reminders > 3 &&
                    <p>And {recent_reminders.total_reminders - 3} more...</p>
                }
            </>
        )
    }

    render() {
        const {show, cancel, schedule} = this.props;
        const {confirming, daysBeforeReminder, sendReminders} = this.state;
        const initialDaysBeforeReminder = safe_get(schedule, 'days_before_reminder', 1);

        // 30 days max, except for weekly schedules, which have a max of 6 days
        const maxDays = schedule.repeat_period === scheudleFrequency.WEEKLY ? 6 : 30;

        return (
            (<Modal
                title="Email Reminders"
                open={show}
                maskClosable={false}
                onCancel={cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button disabled={confirming} className="btn btn-discard" onClick={this.cancel}>Cancel</button>
                        <button disabled={confirming} className="btn btn-modal" onClick={this.confirm}>{confirming ?
                            <ButtonLoading/> : 'Update'}</button>
                    </div>
                }
            >
                <div>
                    {/*<span className="fw-600">Enabled:&nbsp;</span>*/}
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        defaultChecked={sendReminders}
                        onChange={checked => this.setState({sendReminders: checked})}
                    />
                    {!sendReminders &&
                        <span>&nbsp;Email reminders will not be sent.</span>
                    }
                    {sendReminders &&
                        <>
                            <span>&nbsp;Send email reminders </span>
                            <InputNumber disabled={!sendReminders} style={{maxWidth: 60}} size="small" min={1}
                                         max={maxDays} defaultValue={initialDaysBeforeReminder}
                                         onChange={this.onDaysBeforeChange}/>
                            <span> day{daysBeforeReminder > 1 ? 's' : ''} before the due date.</span>
                            <p></p>
                            <p><span>Reminders will send at the start time on the schedule and will only be sent for open assignments.</span>
                                <span> The next due date is {dateFormatterNoTime(schedule.next_due_date)}.</span></p>
                        </>
                    }
                </div>
                <p style={{fontSize: '120%', fontWeight: 600, marginTop: 10}}>History</p>
                {this.renderReminderHistory()}
            </Modal>)
        );
    }
}

