import React, {Component} from 'react';

import ButtonLoading from './ButtonLoading';

import {InfoCircleOutlined} from '@ant-design/icons'
import {Select, Modal} from 'antd'

import {set_role,} from '../../api/zero-api.js'
import {isTeamLead, safe_get} from '../../other/Helper'

import '../../assets/css/antd-custom.css';

class ChangeRoleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatingRole: false,
            selectedRole: undefined
        };

        this.onRoleSelectUpdate = this.onRoleSelectUpdate.bind(this)
        this.updateRole = this.updateRole.bind(this);
        this.setRole = this.setRole.bind(this);
    }

    onRoleSelectUpdate(value) {
        this.setState({selectedRole: value})
    }

    updateRole() {
        this.setState({updatingRole: true}, () => {
            this.setRole();
        });
    }

    setRole() {
        var self = this;
        let role = this.state.selectedRole;
        var body = JSON.stringify({role: role});
        set_role(this.props.userToUpdate.uuid, body).then((success) => {
            self.props.roleUpdateCallback(role);
        }, (error) => {
            if (error.status === 422) {
                error.json().then(error => {
                    self.setState({
                        errorMsg: error.error_messages[0],
                        updatingRole: false
                    });
                });
            } else {
                self.setState({
                    errorMsg: "Unable to change role.",
                    updatingRole: false
                });
            }
        });
    }


    render() {
        return (
            (<Modal
                title={"Change role"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        {this.props.isAdmin && <button className="ButtonLink pull-left zero-delete-red link-hover"
                                                       onClick={this.props.deactivate}>Deactivate User</button>}
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.updateRole} disabled={!this.state.selectedRole}>
                            {
                                this.state.updatingRole ? <ButtonLoading/> : "Change"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">Change role
                    for {safe_get(this.props, "userToUpdate.first_name", "")} {safe_get(this.props, "userToUpdate.last_name", "")}:</p>
                <Select
                    style={{width: "100%", marginBottom: "5px"}}
                    className="move-team-select"
                    showArrow={true}
                    placeholder="Select a role"
                    dropdownStyle={{zIndex: "10000"}}
                    onChange={this.onRoleSelectUpdate}
                    value={this.state.selectedRole}
                >
                    <Select.Option key={"admin"} value={"admin"}
                                   disabled={this.props.userCurrentRole === "Admin" || isTeamLead(this.props.loggedInUser)}>
                        <span className="badge badge-success badge-icon  "
                              style={{margin: "0px 5px 2px 0px"}}/>Admin &bull;&nbsp;<small>can manage the whole
                        account</small>
                    </Select.Option>
                    <Select.Option key={"team_lead"} value={"team_lead"}
                                   disabled={this.props.userCurrentRole === "Lead" || isTeamLead(this.props.loggedInUser)}>
                        <span className="badge badge-info badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Team
                        Lead &bull;&nbsp;<small>can manage only assigned teams</small>
                    </Select.Option>
                    <Select.Option key={"user"} value={"user"} disabled={this.props.userCurrentRole === "User"}>
                        <span className="badge badge-purple badge-icon "
                              style={{margin: "0px 5px 2px 0px"}}/>Member &bull;&nbsp;<small>can perform actions, cannot
                        manage</small>
                    </Select.Option>
                    <Select.Option key={"user_restricted"} value={"user_restricted"}
                                   disabled={this.props.userCurrentRole === "Contributor"}>
                        <span className="badge badge-warning badge-icon  "
                              style={{margin: "0px 5px 2px 0px"}}/>Contributor &bull;&nbsp;<small>can perform actions
                        only on assigned content</small>
                    </Select.Option>
                    <Select.Option key={"viewer"} value={"viewer"} disabled={this.props.userCurrentRole === "Viewer"}>
                        <span className="badge badge-danger badge-icon badge-fw "
                              style={{margin: "0px 5px 2px 0px"}}/>Viewer &bull;&nbsp;<small>can view only, cannot
                        perform actions</small>
                    </Select.Option>
                </Select>
                <small><InfoCircleOutlined className="mar-rgt-5"/>For more information on roles in ZERO, click <a
                    rel="noopener noreferrer" onClick={() => FreshworksWidget('open', 'article', {'id': 70000417097})}
                    className="blue-link">here</a>.</small>
                {
                    this.state.errorMsg &&
                    <div>
                        <small className="error">{this.state.errorMsg}</small>
                    </div>
                }
            </Modal>)
        );
    }
}

export default ChangeRoleModal;