export default function reducer(state = {
    loading: true,
    desktopSize: true,
    kiosk_mode: false
}, action) {

    switch (action.type) {

        case "update_loading": {
            return Object.assign({}, state, {
                loading: action.payload
            });
        }

        case "is_desktop_size": {
            return Object.assign({}, state, {
                desktopSize: action.payload
            });
        }


        case "UPDATE_KIOSK_MODE": {
            return Object.assign({}, state, {
                kiosk_mode: action.payload
            });
        }

        default: {
            return state;
        }

    }
}