import React, {useEffect, useMemo} from 'react';
import {Select} from 'antd';
import {useTeamsLoader} from 'hooks/teams';

export default function MultiTeamSelect({
                                            initialTeamOptions = [],
                                            selectedTeamIds = [],
                                            onSelectedTeamChange = (ids) => {
                                            },
                                            ...props
                                        }) {
    const [fetchTeams, teamsLoader] = useTeamsLoader();
    const {loading, error, ok, data} = teamsLoader;

    useEffect(() => {
        fetchTeams();
    }, []);

    const options = useMemo(() => {
        if (!ok) return [];
        const {teams = []} = data;

        const userTeamIds = teams.map(team => team.uuid);
        const nonUserTeamOptions = (
            initialTeamOptions
                .filter(t => userTeamIds.includes(t.uuid) === false)
                .map(t => ({
                    value: t.uuid,
                    label: t.name,
                    disabled: true
                }))
        );
        const userTeamOptions = teams.map(team => ({
            value: team.uuid,
            label: team.name
        }));
        const combinedOptions = [...userTeamOptions, ...nonUserTeamOptions];

        combinedOptions.sort((a, b) => {
            const aName = a.label.toLowerCase();
            const bName = b.label.toLowerCase();
            return aName < bName ? -1 : 1;
        });

        return combinedOptions;
    }, [ok, data, initialTeamOptions]);

    return (
        <Select
            mode="multiple"
            value={ok ? selectedTeamIds : []}
            onChange={onSelectedTeamChange}
            virtual={false}
            disabled={loading || error}
            options={options}
            optionFilterProp="label"
            dropdownStyle={{zIndex: "10000"}}
            {...props}
        />
    );
}