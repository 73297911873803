import { Modal } from "antd";
import Button from "./Button";

/**
 * 
 * @param {{
 *  title: string;
 *  footer: JSX.Element | "negative-affirmative" | "affirmative";
 *  onNegative?: () => void;
 *  onAffirmative?: () => void;
 *  negativeText?: string | JSX.Element;
 *  affirmativeText?: string | JSX.Element;
 *  negativeProps?: object;
 *  affirmativeProps?: object;
 *  hideCloseButton?: boolean;
 *  children: JSX.Element[];
 * }} props 
 * @returns 
 */
export default function SimpleModal({
    title,
    footer,
    onNegative = () => {}, onAffirmative = () => {},
    negativeText = "Cancel", affirmativeText = "Confirm",
    negativeProps = {}, affirmativeProps = {},
    hideCloseButton = false,
    children,
}) {

    let _footer;
    if (footer === "negative-affirmative" || footer === "affirmative") {
        _footer = (
            <>
                { footer === "negative-affirmative" &&
                    <Button onClick={onNegative} {...negativeProps}>{negativeText}</Button>
                }
                <Button type="primary" onClick={onAffirmative} {...affirmativeProps}>{affirmativeText}</Button>
            </>
        )
    } else {
        _footer = footer;
    }

    return (
        <Modal
            open={true}
            title={title}
            maskClosable={false}
            closable={!hideCloseButton}
            maskTransitionName="maskTransitionName"
            footer={_footer}
        >{children}</Modal>
    );
}