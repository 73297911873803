import React, {Component} from 'react';

import {Modal} from 'react-bootstrap';

import {safe_get} from '../other/Helper.js';
import {getPostViewers, getArticleViewers} from '../api/zero-api.js'

import LoadingIndicator from './Shared/LoadingIndicator.js';
import ButtonLoading from './Shared/ButtonLoading.js';

class ModalPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: "",
            availableTeams: [],
            team_uuids: [],
            defaultValue: [],
            team_options: [],
            teams_list: [],
            selectedTeams: [],
            viewers: [],
            loading_viewers: true,
        };

        this.checkForCompletion = this.checkForCompletion.bind(this);
        this.teamSelect = this.teamSelect.bind(this);
        this.deactivateUser = this.deactivateUser.bind(this);
        this.getViewers = this.getViewers.bind(this);
    }

    componentDidMount() {
        if (this.props.viewsModal) {
            this.getViewers(this.props.post_uuid);
        }
    }

    checkForCompletion() {
        var text = document.getElementById('post-status-reason').value
        if (/\S/.test(text)) {
            this.setState({saving: true, errorMsg: ""});
            this.props.confirm();
        } else {
            this.setState({errorMsg: "Please provide a comment."});
        }
    }


    teamSelect(value) {
        this.setState({selectedTeams: value})
    }

    getViewers(post_uuid) {
        var self = this;

        if (this.props.articleViews) {
            getArticleViewers(post_uuid).then(function (success) {
                success.json().then(success => {
                    var viewers = safe_get(success, "viewers", []);
                    viewers.sort(function (a, b) {
                        return a.viewed_at - b.viewed_at
                    });
                    self.setState({viewers: viewers, loading_viewers: false});
                });
            }, function (error) {
                console.log(error);
            });
        } else {
            getPostViewers(post_uuid).then(function (success) {
                success.json().then(success => {
                    var viewers = safe_get(success, "viewers", []);
                    viewers.sort(function (a, b) {
                        return a.viewed_at - b.viewed_at
                    });
                    self.setState({viewers: viewers, loading_viewers: false});
                });
            }, function (error) {
                console.log(error);
            });
        }

    }

    deactivateUser() {
        if (!this.state.deactivating) {
            this.setState({deactivating: true});
            this.props.confirm();
        }
    }

    render() {
        if (this.props.textfield) {
            return (
                <Modal show={this.props.show} onHide={this.props.cancel} className="modal fade"
                       dialogClassName={this.props.small ? "small-popover" : ""} backdrop="static">
                    <Modal.Header closeButton style={{paddingBottom: '5px'}}>
                        <Modal.Title className="zero-blue">{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{paddingTop: '0px', minHeight: '50px'}}>
                        <p className="zero-dark-grey">{this.props.text}</p>
                        <div className="form-group">
                            <textarea required ref="post_status_reason" id="post-status-reason"
                                      className="form-control custom-placeholder" placeholder="Add your reason here..."
                                      rows="3"></textarea>
                            {
                                this.state.errorMsg &&
                                <small className="error">{this.state.errorMsg}</small>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.checkForCompletion}>
                            {
                                this.state.saving ? <ButtonLoading/> : this.props.confirmButtonName
                            }
                        </button>
                    </Modal.Footer>
                </Modal>
            )
        } else if (this.props.deactivateUser) {
            return (
                <Modal show={this.props.show} onHide={this.props.cancel} className="modal fade "
                       dialogClassName={"small-popover"}>
                    <Modal.Header closeButton style={{paddingBottom: '5px'}}>
                        <Modal.Title className="zero-blue">{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{paddingTop: '0px', minHeight: '50px'}}>
                        <p className="zero-dark-grey">Here's what happens when you deactivate someone:</p>
                        <ul style={{paddingLeft: "15px"}}>
                            <li>The member will be removed from all teams.</li>
                            <li>The member will no longer be able to login to this account in the future.</li>
                            <li>The member's data will still be accessible in ZERO.</li>
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.deactivateUser}>
                            {
                                this.state.deactivating ? <ButtonLoading/> : "Deactivate"
                            }
                        </button>
                    </Modal.Footer>
                </Modal>
            )
        } else {
            return (
                <Modal show={this.props.show} onHide={this.props.cancel} className="modal fade"
                       dialogClassName={this.props.small ? "small-popover" : ""} style={this.props.style}>
                    <Modal.Header closeButton style={{paddingBottom: '5px'}}>
                        <Modal.Title className="zero-blue">{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{paddingTop: '0px', minHeight: '50px'}}>
                        <p className="zero-dark-grey" style={{wordBreak: "break-word"}}>{this.props.text}</p>
                        {
                            this.props.progressBar &&
                            <div className="progress">
                                <div style={{width: this.props.progress}}
                                     className="progress-bar progress-bar-primary"></div>
                            </div>
                        }
                    </Modal.Body>
                    {
                        !this.props.progressBar &&
                        <Modal.Footer>
                            <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                            {
                                this.state.saving ?
                                    <button className="btn btn-modal"><ButtonLoading/></button> :
                                    <button className="btn btn-modal" onClick={() => {
                                        this.setState({saving: true});
                                        this.props.confirm();
                                    }}>{this.props.confirmButtonName}</button>
                            }
                        </Modal.Footer>
                    }

                </Modal>
            );
        }

    }

}

export default ModalPopover;