export function update_current_user(user) {
    return function(dispatch) {
        dispatch({
            type: "update_user",
            payload: user
        });
        return Promise.resolve();
    }
}

export function update_user_analytics(user_stats) {
    return {
        type: "update_user_analytics",
        payload: user_stats
    }
}

export function reset_user() {
    return {
        type: "RESET_USER",
        payload: {}
    }
}
