import React, {Component} from 'react';
import CONFIG from "./Config";
import {get_all_browser_notification_settings, get_auth_token} from "../api/zero-api";
import useOfflineMode from 'components/Offline/useOfflineMode';

export const WebSocketContext = React.createContext({});

export function WebSocketHandler({children, ...props}) {
    const {isOffline} = useOfflineMode();

    return (
        <WebSocketHandlerInner isOffline={isOffline}>
            {children}
        </WebSocketHandlerInner>
    )
}

class WebSocketHandlerInner extends Component {
    constructor(props) {
        super(props);

        this.addMessageHandler = this.addMessageHandler.bind(this);
        this.removeMessageHandler = this.removeMessageHandler.bind(this);

        this.context_value = {
            addMessageHandler: this.addMessageHandler,
            removeMessageHandler: this.removeMessageHandler,
            socketIsOpen: () => (this.socket && this.socket.readyState === 1),
            browserNotificationSettings: {}
        }
        this.socket = null;
        this.handlers = {};
        this.retries = 0;
        this.maxRetries = 3;
    }

    componentDidMount() {
        window.sendMockSocketMessage = this.sendMockSocketMessage;

        if (!this.props.isOffline) {
            this.initialize();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOffline !== this.props.isOffline) {
            if (this.props.isOffline) {
                this.cleanup();
            } else {
                this.initialize();
            }
        }
    }

    componentWillUnmount() {
        this.cleanup();
    }

    initialize() {
        this.createSocket();

        get_all_browser_notification_settings().then((success) => {
            success.json().then(success => {
                this.context_value.browserNotificationSettings = success;
            })
        }, function (error) {
            console.error(error)
        });
    }

    cleanup() {
        if (this.socket?.readyState === WebSocket.OPEN) {
            this.socket.close();
        }
        this.retries = this.maxRetries;
        this.socket = null;
    }

    sendMockSocketMessage = (orgId, type, data) => {
        const message = {
            organization_uuid: orgId,
            type,
            data
        };
        this.handleMessage(message);
    }

    createSocket() {
        if (!CONFIG.WEBSOCKET_URL()) return;
        const authToken = get_auth_token();
        if (!authToken) return;

        let socket = new WebSocket(CONFIG.WEBSOCKET_URL(), authToken);

        socket.addEventListener('open', (event) => {
            this.socket = socket;
            this.retries = 0;
        });

        socket.addEventListener('message', (event) => {
            try {
                const message = JSON.parse(event.data);
                this.handleMessage(message);
            } catch (err) {
                console.error('WS parsing Error:', err);
            }
        });

        socket.addEventListener('error', function (event) {
            console.error('WS error event:', event)
        });

        socket.addEventListener('close', (event) => {
            if (this.retries < this.maxRetries) {
                setTimeout(() => {
                    this.retries++;
                    this.createSocket();
                }, 500);
            } else {
                console.error('Maximum retries reached for websocket connection.');
            }
        });
    }

    handleMessage(message) {
        try {
            const handlers = this.handlers[message.type];

            if (handlers) {
                for (let handler of handlers) {
                    handler(message);
                }
            }
        } catch (err) {
            console.error('WS Message Error:', err);
        }
    }

    addMessageHandler(type, handler) {
        if (!this.handlers[type]) {
            this.handlers[type] = [];
        }

        this.handlers[type].push(handler);
    }

    removeMessageHandler(type, handler) {
        if (!this.handlers[type]) {
            return;
        }

        this.handlers[type] = this.handlers[type].filter(h => h !== handler);
    }

    render() {
        return (
            <WebSocketContext.Provider value={this.context_value}>
                {this.props.children}
            </WebSocketContext.Provider>
        );
    }
}