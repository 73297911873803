import '../../assets/css/sidebar.css';
import SideBarInternals from './SideBarInternals.js';

export default function SideBar({isOffline, isPublicView, draftErrorCount, closeSideMenu}) {
    return (
        <nav id="mainnav-container" style={{zIndex: '1102'}}>
            <SideBarInternals
                isOffline={isOffline}
                isPublicView={isPublicView}
                draftErrorCount={draftErrorCount}
                closeSideMenu={closeSideMenu}
                isInline={false}
            />
        </nav>
    );
}
