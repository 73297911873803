import {useEffect, useState} from 'react';
import moment from 'moment';
import {get_check_in_dates, get_desk_pool, get_desk_pool_multiple_dates} from 'api/zero-api';
import {CheckInUserType} from 'other/Constants.js';
import {safe_get} from 'other/Helper.js';

export default function useDeskPool(userType, user, dates = null) {
    const [deskData, setDeskData] = useState({loading: false});

    const fetchDeskPool = async () => {
        try {
            let response;
            if (dates) {
                const body = {
                    dates: dates.map(date => moment(date).format('YYYY-MM-DD')),
                    user_uuid: user?.uuid
                };
                response = await get_desk_pool_multiple_dates(JSON.stringify(body));
            } else {
                response = await get_desk_pool(user?.uuid);
            }
            const deskPool = await response.json();
            const locations = deskPool.locations;
            const assignedDesks = [];

            locations.map(location => {
                location.cascadeName = location.name;

                location.spaces.map(space => {
                    const desksToKeep = [];
                    space.desks.map(desk => {
                        desk.fullName = `${location.name} / ${space.name} / ${desk.name}`;
                        if (safe_get(desk, 'is_assigned', false)) {
                            if (userType !== CheckInUserType.GUEST) {
                                desksToKeep.push(desk);

                                assignedDesks.push({
                                    uuid: desk.uuid,
                                    name: desk.fullName,
                                    desk,
                                    space,
                                    location
                                });
                            }
                        } else {
                            desksToKeep.push(desk);
                        }
                    });

                    space.desks = desksToKeep;
                    space.cascadeName = `${space.name} (${space.desks.length} open)`;
                });
            });

            let disabledDates = [];

            if (userType !== CheckInUserType.GUEST) {
                response = await get_check_in_dates({query: `?user_uuid=${user.uuid}`});
                disabledDates = await response.json();
            }

            setDeskData({
                assignedDesks,
                locations,
                disabledDates,
                ok: true
            });
        } catch (err) {
            setDeskData({
                error: true
            });
        }
    }

    useEffect(() => {
        if (!deskData.loading) {
            if (!(userType !== CheckInUserType.GUEST && user === null)) {
                deskData.loading = true;
                fetchDeskPool().then(r => deskData.loading = false);
            }
        }
    }, [userType, user, dates]);

    return deskData;
}
