import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import {update_auth_token, create_admin, update_organization_uuid} from '../../../api/zero-api.js';
import {validatePhoneNumber, safe_get} from '../../../other/Helper.js';

import ButtonLoading from '../../../components/Shared/ButtonLoading';
import PasswordRequirementsPopup from '../../../components/Shared/PasswordRequirementsPopup';

import * as userActions from "../../../store/actions/UserActions";

class AdminSignupInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            passwordCheck: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordMeetsRequirements = this.passwordMeetsRequirements.bind(this);
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.name === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]: value,
        });
    }

    passwordMeetsRequirements(value, password) {
        this.setState({password: password});
    }

    checkErrors() {
        let {
            password,
            repeatPassword,
            phone,
            organizationName,
            positionTitle,
            firstName,
            lastName,
            checkbox
        } = this.state;
        var newState = {};

        // let validPassword = password && repeatPassword && (password === repeatPassword);

        // newState["pwError"] = validPassword ? null : "Password does not match"

        // newState["phoneError"] = validatePhoneNumber(phone) ? null : "Invalid Phone Number";

        // newState["organizationError"] = organizationName ? null : "Invalid Organization";

        // newState["positionTitleError"] = positionTitle ? null : "Invalid Title";

        // newState["firstNameError"] = firstName ? null : "Invalid Name";

        // newState["lastNameError"] = lastName ? null : "Invalid Name";

        newState["checkBoxError"] = checkbox ? null : "To create an account, you must accept the terms and policies.";

        this.setState(newState);

        var allPassed = true;
        let keys = Object.keys(newState)
        for (var index in keys) {
            let error = keys[index];
            if (newState[error] != null) {
                allPassed = false;
                break;
            }
        }
        return allPassed;
    }

    handleSubmit() {
        this.setState({
            api_loading: true,
            emailError: null,
            firstNameError: null,
            lastNameError: null,
            positionTitleError: null,
            pwError: null,
            organizationError: null,
            errorMsg: null
        });

        if (this.checkErrors()) {
            this.inviteAdmin();
        } else {
            this.setState({api_loading: false});
            return false;
        }
    }

    createUserBody() {
        var phone_value = this.state.phone;
        phone_value = phone_value.replace(/\s+/g, '');
        phone_value = phone_value.replace(/-/g, '');
        phone_value = phone_value.replace(/\(/g, '');
        phone_value = phone_value.replace(/\)/g, '');

        return JSON.stringify({
            email: this.props.email,
            confirmation_code: this.props.inviteCode,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            phone_number: phone_value,
            password: this.state.password,
            title: this.state.positionTitle,
            organization_name: this.state.organizationName,
            avatar: ""
        });
    }

    inviteAdmin() {
        var self = this;
        let body = this.createUserBody();

        create_admin(body).then(function (success) {
            success.json().then(success => {
                update_organization_uuid("");
                update_auth_token(safe_get(success, "jwt", ""))
                let user = safe_get(success, "user", {});
                self.props.dispatch(userActions.update_current_user(user));
                self.props.history.push("/");
            });
        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {

                    let newState = {};
                    newState["api_loading"] = false;

                    let error_messages = safe_get(error, "error_messages", []);

                    error_messages.forEach(error_msg => {
                        switch (error_msg.field) {
                            case "email":
                                newState["emailError"] = error_msg.message
                                break;
                            case "first_name":
                                newState["firstNameError"] = error_msg.message
                                break;
                            case "last_name":
                                newState["lastNameError"] = error_msg.message
                                break;
                            case "password":
                                newState["pwError"] = error_msg.message
                                break;
                            case "title":
                                newState["positionTitleError"] = error_msg.message
                                break;
                            case "organization_name":
                                newState["organizationError"] = error_msg.message
                                break;
                            case "phone_number":
                                newState["phoneError"] = error_msg.message;
                                break;
                            default:
                                newState["errorMsg"] = error_msg.message
                                break;
                        }
                    });
                    self.setState(newState);

                });
            } else {
                self.setState({errorMsg: "An unexpected error occurred.", api_loading: false});
            }
        });
    }

    render() {
        // var mobileScreen = window.innerWidth <= 740;
        var mobileScreen = true;
        return (
            <div>
                <form className="form-horizontal" style={{marginTop: "15px"}}>

                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <input type="email" className={"form-control signup-field custom-placeholder "}
                                   id="email-input2" disabled={this.props.email} name="email" placeholder="Email"
                                   value={this.props.email} required style={{maxWidth: '250px', margin: "0 auto"}}/>
                            {
                                this.state.emailError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.emailError}</small>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <input type="text" placeholder="Company Name" name="organizationName"
                                   className={"form-control signup-field custom-placeholder "} required
                                   style={{maxWidth: '250px', margin: "0 auto"}} onChange={this.onInputChange}/>
                            {
                                this.state.organizationError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.organizationError}</small>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <input type="text" placeholder="First Name" name="firstName"
                                   className={"form-control signup-field custom-placeholder "} required
                                   style={{maxWidth: '250px', margin: "0 auto"}} onChange={this.onInputChange}/>
                            {
                                this.state.firstNameError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.firstNameError}</small>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <input type="text" placeholder="Last Name" name="lastName"
                                   className={"form-control signup-field custom-placeholder "} required
                                   style={{maxWidth: '250px', margin: "0 auto"}} onChange={this.onInputChange}/>
                            {
                                this.state.lastNameError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.lastNameError}</small>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <input type="text" placeholder="Title" name="positionTitle"
                                   className={"form-control signup-field custom-placeholder "} required
                                   style={{maxWidth: '250px', margin: "0 auto"}} onChange={this.onInputChange}/>
                            {
                                this.state.positionTitleError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.positionTitleError}</small>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <input type="text" placeholder="Phone (optional)" name="phone"
                                   className={"form-control signup-field custom-placeholder "}
                                   style={{maxWidth: '250px', margin: "0 auto"}} onChange={this.onInputChange}/>
                            {
                                this.state.phoneError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.phoneError}</small>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12 col-sm-12 signup-input">
                            <PasswordRequirementsPopup
                                passwordMeetsRequirementsCallback={this.passwordMeetsRequirements}/>
                            {
                                this.state.pwError &&
                                <small
                                    className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.pwError}</small>
                            }
                        </div>
                    </div>
                    {/* <div className="form-group">
            <div className="col-lg-12 col-sm-12 signup-input">
              <input type="password" placeholder="Confirm Password" name="repeatPassword" className={"form-control signup-field custom-placeholder "} onChange={this.onInputChange} required style={{ maxWidth: '250px', margin: "0 auto" }} />
              {
                this.state.pwError &&
                <small className={"error " + (mobileScreen ? "" : "pull-left")}>{this.state.pwError}</small>
              }
            </div>
          </div> */}
                    {
                        this.state.errorMsg &&
                        <small className={"error "}>{this.state.errorMsg}</small>
                    }
                    <div className="checkbox pad-btm text-center">
                        <div className="col-lg-12">
                            <input id="demo-form-checkbox" className="magic-checkbox" type="checkbox" name="checkbox"
                                   onChange={this.onInputChange} checked={this.state.checkbox}/>
                            <label htmlFor="demo-form-checkbox">I accept the <a
                                href="https://teamzero.com/customer-terms" target="_blank" rel="noopener noreferrer"
                                className="btn-link sign-up text-bold">Customer Terms of Service</a> and <a
                                href="https://teamzero.com/privacy-policy" target="_blank" rel="noopener noreferrer"
                                className="btn-link sign-up text-bold">Privacy Policy</a></label>
                            <br/>
                            {
                                this.state.checkBoxError &&
                                <small className={"error "}>{this.state.checkBoxError}</small>
                            }
                        </div>
                    </div>
                </form>
                <div className="box" style={{marginBottom: "20px", marginTop: "18px"}}>
                    <button
                        type="button"
                        className="finish btn btn-primary" style={{display: "block", margin: "0 auto", width: "250px"}}
                        onClick={this.handleSubmit}
                    >
                        {
                            !this.state.api_loading ? "Finish" : <ButtonLoading/>
                        }
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {}
}
export default withRouter(connect(mapStateToProps)(AdminSignupInfo));

