import React from 'react';
import {useSelector} from 'react-redux';
import {Select} from 'antd';

import {safe_get, isAdmin, isTeamLead} from 'other/Helper';

export default function PostFormTeam({state, onTeamChange, teams}) {
    const user = useSelector(state => state.user.user);

    return (
        <>
            <div className="panel-heading bulletin-post ">
                <h3 className="bulletin-post-headers required">Team</h3>
            </div>
            <div className="panel-body bulletin-post">
                <Select
                    style={{maxWidth: "400px", width: "100%"}}
                    placeholder="Select a Team"
                    value={state.selectedTeamUuid}
                    onChange={onTeamChange}
                    className="custom-bulletin"
                    virtual={false}
                    disabled={false}
                    dropdownStyle={{zIndex: "999999999"}}
                >
                    {
                        teams.map((team) => (
                            <Select.Option
                                key={team.uuid}
                                value={team.uuid}
                                disabled={safe_get(team, "minimum_role_to_post", "") === "team_lead" && !(isAdmin(user) || isTeamLead(user))}
                            >
                                {team.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </div>
        </>
    )
}