import { PlusCircleOutlined } from '@ant-design/icons';

import LoadingIndicator from "components/Shared/LoadingIndicator"
import SubmissionEditDiff from 'components/Shared/SubmissionEditDiff';
import { Timeline } from "components/Shared/Timeline"
import UserPopoverCard from "components/Shared/UserPopoverCard";
import { TimelineContext } from 'other/ReactContexts';

export function mapSubmissionStatus(value) {
    switch(value) {
        case "submitted": return "Submitted";
        case "in_review": return "In Review";
        case "closed": return "Closed";
        default: return "Invalid";
    }
}

function CommentAction({comment}) {
    const {created_by, comment_type, data} = comment;

    const who = (
        <UserPopoverCard user={created_by}>
            <span style={{fontWeight: 600}}>{created_by.full_name}</span>
        </UserPopoverCard>
    );

    let where = '';
    if (data.team_names && data.team_names.length > 0) {
        if (data.team_names.length === 1) {
            where = ` in ${data.team_names[0]}`;
        } else if (data.team_names.length === 2) {
            where = ` in ${data.team_names[0]} and ${data.team_names[1]}`;
        } else if (data.team_names.length > 2) {
            where = ` in ${data.team_names[0]} and ${data.team_names.length - 1} others`;
        }
    }

    switch(comment_type) {
        case "submission_created":
            return <>{who} created this submission{where}.</>
        case "submission_deleted":
            return <>{who} deleted this submission.</>
        case "submission_edited":
            return <>{who} edited this submission.</>
        case "status_updated":
            return <>{who} changed the status to <span className="text-semibold">{mapSubmissionStatus(data.new_status)}</span>.</>
    }
}

function TimelineEntry({comment}) {
    const {created_by, created_at, edited_at, body, comment_type, data, users_notified} = comment;
    const showDetailsLink = comment_type === 'submission_edited';
    const timeToDisplay = (edited_at && showDetailsLink) ? edited_at : created_at;

    let icon;
    let details;

    if (comment_type === "submission_created") {
        icon = PlusCircleOutlined;
    } else if (comment_type === "submission_edited") {
        details = <SubmissionEditDiff data={data} />
    }

    const usersNotified = comment_type === "submission_created" ? users_notified : null;


    return (
        <Timeline.Entry
            icon={icon}
            author={created_by}
            time={timeToDisplay}
            showDetailsLink={showDetailsLink}
            action={<CommentAction comment={comment} />}
            usersNotified={usersNotified}
            body={body}
            details={details}
        />
    )
}

export default function SubmissionTimeline({loading, error, comments, submission}) {
    if (loading) {
        return <LoadingIndicator />
    } else if (error) {
        return <div>Could not load activity.</div>
    } else if (comments.length === 0) {
        return <div>No history for this submission.</div>
    }

    const contextValue = {
        submissions: [submission],
        showFormName: false,
    }

    return (
        <TimelineContext.Provider value={contextValue}>
            <Timeline>
                {
                    comments.map(comment => (
                        <TimelineEntry comment={comment} key={comment.uuid}/>
                    ))
                }
            </Timeline>
        </TimelineContext.Provider>
    )
}