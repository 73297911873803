import {isIE, isEdge} from "./Helper";

import * as msal from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "804f1f0e-e5d6-44c5-83db-fe591830859d",
        clientSecret: "",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: `${window.location.origin}/oauth_login`,
        // postLogoutRedirectUri: `${window.location.origin}/login`,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: isIE() || isEdge(), // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const loginRequest = {
    scopes: ["User.Read"],
    prompt: "select_account"
};

const tokenRequest = {
    scopes: ["User.Read"],
    forceRefresh: false // set this to "true" if you would like to skip a cached token and go to the server
};

const msalInstance = new msal.PublicClientApplication(msalConfig);
msalInstance.initialize();

export {
    loginRequest,
    tokenRequest,
    msalInstance
}