export default function reducer(state = {
    position: 0,
    query: "",
    date_query: "period=all_time",
    tab: "posts",
    categories: [],
    statuses: [],
    statuses2: [],
    tags: [],
    feed_view: "posts",
    options: {
        categories: [],
        tags: [],
        sub_statuses: [],
        sub_statuses_2: [],
        severity_levels: [],
        locations: [],
    }
}, action) {

    switch (action.type) {

        case "update_feed_position": {
            return Object.assign({}, state, {
                position: action.payload
            });
        }

        case "update_feed_query": {
            return Object.assign({}, state, {
                query: action.payload
            });
        }

        case "update_date_query": {
            return Object.assign({}, state, {
                date_query: action.payload
            });
        }

        case "update_feed_and_date_queries": {
            return Object.assign({}, state, {
                query: action.payload,
                date_query: action.payload
            })
        }

        case "update_feed_tab": {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }

        case "update_feed_categories": {
            return Object.assign({}, state, {
                categories: action.payload
            });
        }

        case "update_feed_statuses": {
            return Object.assign({}, state, {
                statuses: action.payload
            });
        }

        case "update_feed_statuses_2": {
            return Object.assign({}, state, {
                statuses2: action.payload
            });
        }

        case "update_feed_tags": {
            return Object.assign({}, state, {
                tags: action.payload
            });
        }

        case "toggle_feed_view": {
            return Object.assign({}, state, {
                feed_view: action.payload
            });
        }

        case "update_options": {
            return {
                ...state,
                options: action.payload,
            }
        }

        default: {
            return state;
        }

    }

}