import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Tabs} from 'antd';

const {TabPane} = Tabs;

import {mainContentContainerClass} from 'other/Helper';
import {useMyCheckInsLoader} from 'hooks/spaces';
import { CheckInUserType } from 'other/Constants';
import {delete_check_in} from 'api/zero-api';
import NotificationAlert from 'other/NotificationAlert';
import CheckInsTable, { tabOption } from './CheckInsTable';

export default function MyCheckIns() {
    const user = useSelector(state => state.user.user);
    const [fetchPastCheckIns, pastCheckInsLoader] = useMyCheckInsLoader();
    const [fetchActiveCheckIns, activeCheckInsLoader] = useMyCheckInsLoader();
    const [fetchColleagueCheckIns, colleagueCheckInsLoader] = useMyCheckInsLoader();
    const [fetchGuestCheckIns, guestCheckInsLoader] = useMyCheckInsLoader();

    const loadPastCheckIns = () => {
        const params = new URLSearchParams();
        params.set('user_uuid', user.uuid);
        params.set('per_page', '25');
        params.set('state', 'expired');

        fetchPastCheckIns({query: `?${params}`});
    }

    const loadActiveCheckIns = () => {
        const params = new URLSearchParams();
        params.set('user_uuid', user.uuid);
        params.set('per_page', '25');
        params.set('state', 'not-expired');
        params.set('order', 'asc');

        fetchActiveCheckIns({query: `?${params}`});
    }

    const loadColleagueCheckIns = () => {
        const params = new URLSearchParams();
        params.set('per_page', '25');
        params.set('created_by_uuid', user.uuid);
        params.set('state', 'all');

        params.set('user_type', CheckInUserType.USER);

        fetchColleagueCheckIns({query: `?${params}`});
    }

    const loadGuestCheckIns = () => {
        const params = new URLSearchParams();
        params.set('per_page', '25');
        params.set('created_by_uuid', user.uuid);
        params.set('state', 'all');

        params.set('user_type', CheckInUserType.GUEST);

        fetchGuestCheckIns({query: `?${params}`});
    }

    useEffect(() => {
        loadPastCheckIns();
        loadActiveCheckIns();
        loadColleagueCheckIns();
        loadGuestCheckIns();
    }, []);

    const onDelete = (checkInId, tab) => {
        delete_check_in(checkInId)
            .then(response => {
                if (tab === tabOption.ACTIVE) {
                    loadActiveCheckIns();
                } else if (tab === tabOption.COLLEAGUE) {
                    loadColleagueCheckIns();
                } else if (tab === tabOption.GUEST) {
                    loadGuestCheckIns();
                }
            })
            .catch(error => {
                NotificationAlert('error', '', 'Could not delete check-in.');
            })
    }

    return (
        <div className={mainContentContainerClass()}>
            <div id="page-head" className="no-padding-mobile">
                <div className="row">
                    <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                        <div id="page-title" style={{padding: '0px'}}>
                            <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                <div className="pad-all">
                                    <div className="team-name-header">
                                        <h3 className={"section-titles admin-page-header"}
                                            style={{display: "inline-block", margin: "0px"}}>
                                            My Check-Ins
                                        </h3>
                                    </div>
                                    <p className="header"
                                       style={{color: 'var(--zero-dark-grey', marginBottom: "0px", paddingLeft: "2px"}}>
                                        See active and past check-ins, as well as check-ins you've made for colleagues.
                                    </p>
                                    <div>
                                        <Tabs defaultActiveKey={tabOption.ACTIVE}>
                                            <TabPane tab="Active Check-Ins" key={tabOption.ACTIVE}>
                                                <CheckInsTable
                                                    currentTab={tabOption.ACTIVE}
                                                    dataLoader={activeCheckInsLoader}
                                                    onDelete={onDelete}
                                                />
                                            </TabPane>
                                            <TabPane tab="Past Check-Ins" key={tabOption.PAST}>
                                                <CheckInsTable
                                                    currentTab={tabOption.PAST}
                                                    dataLoader={pastCheckInsLoader}
                                                />
                                            </TabPane>
                                            <TabPane tab="Colleague Check-Ins" key={tabOption.COLLEAGUE}>
                                                <CheckInsTable
                                                    currentTab={tabOption.COLLEAGUE}
                                                    dataLoader={colleagueCheckInsLoader}
                                                    onDelete={onDelete}
                                                />
                                            </TabPane>
                                            <TabPane tab="Guest Check-Ins" key={tabOption.GUEST}>
                                                <CheckInsTable
                                                    currentTab={tabOption.GUEST}
                                                    dataLoader={guestCheckInsLoader}
                                                    onDelete={onDelete}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="page-content" className="no-padding-mobile"></div>
        </div>
    )
}
