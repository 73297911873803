import React, {useState, useEffect} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useSelector} from 'react-redux';
import {Menu} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

import {isAdmin} from 'other/Helper';
import {get_spaces_managers, set_spaces_managers} from 'api/zero-api';
import NotificationAlert from 'other/NotificationAlert';
import ConfirmModal from 'components/Shared/ConfirmModal';
import UsersTable from '../UsersTable';
import AddManagersModal from './AddManagersModal';


function useManagerApi() {
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modifying, setModifying] = useState(false);

    const actions = {
        fetchList: async () => {
            try {
                setLoading(true);

                const response = await get_spaces_managers();
                const managers = await response.json();

                unstable_batchedUpdates(() => {
                    setLoading(false);
                    setError(null);
                    setManagers(managers);
                });
            } catch (err) {
                NotificationAlert('error', '', 'Could not load managers.');

                unstable_batchedUpdates(() => {
                    setLoading(false);
                    setError(err);
                    setManagers([]);
                });
            }
        },
        addManagers: async (userUuids, onManagersUpdated, existingManagers = null) => {
            try {
                setModifying(true);
                if (!existingManagers) existingManagers = managers;

                const managerUuids = existingManagers.map(manager => manager.uuid);
                const newManagerUuids = managerUuids.concat(userUuids);

                const response = await set_spaces_managers(JSON.stringify(newManagerUuids));
                const newManagers = await response.json();

                unstable_batchedUpdates(() => {
                    setManagers(newManagers);
                    setModifying(false);
                });

                if (onManagersUpdated) onManagersUpdated();
            } catch (err) {
                NotificationAlert('error', '', 'Could not add managers.');
                setModifying(false);
            }
        },
        removeManager: async (user, existingManagers = null) => {
            try {
                setModifying(true);
                if (!existingManagers) existingManagers = managers;

                const managerUuids = existingManagers.map(manager => manager.uuid).filter(managerUuid => managerUuid !== user.uuid);

                const response = await set_spaces_managers(JSON.stringify(managerUuids));
                const newManagers = await response.json();

                unstable_batchedUpdates(() => {
                    setManagers(newManagers);
                    setModifying(false);
                });
            } catch (err) {
                NotificationAlert('error', '', 'Could not remove manager.');
                setModifying(false);
            }
        }
    };

    return {
        managers,
        loading,
        error,
        modifying,
        actions
    }
}


export default function ManagersTable({showAddManagersModal, setShowAddManagersModal}) {
    const managerApi = useManagerApi();
    const {managers, loading, modifying} = managerApi;
    const directory = useSelector(state => state.manageDesks.directory);
    const directoryLoading = useSelector(state => state.manageDesks.loading);
    const [managerToRemove, setManagerToRemove] = useState(null);

    useEffect(() => {
        managerApi.actions.fetchList();
    }, []);

    const generateMenuOptions = user => {
        const disabled = isAdmin(user);
        return [
            {
                key: 'remove-manager',
                className: disabled ? "" : "zero-delete-red",
                disabled: disabled,
                icon: <DeleteOutlined/>,
                label: 'Remove Manager'
            }
        ];
    }

    const handleMenuClick = (key, user) => {
        switch (key) {
            case 'remove-manager':
                setManagerToRemove(user);
                break;
        }
    }

    return (
        <div style={{padding: '1.5rem'}}>
            {showAddManagersModal && !directoryLoading &&
                <AddManagersModal
                    users={directory}
                    managers={managers}
                    onCancel={() => setShowAddManagersModal(false)}
                    onConfirm={selectedUserUuids => managerApi.actions.addManagers(selectedUserUuids, () => setShowAddManagersModal(false))}
                    modifying={modifying}
                />
            }
            {managerToRemove !== null &&
                <ConfirmModal
                    show={true}
                    cancel={() => {
                        setManagerToRemove(null);
                    }}
                    confirm={() => {
                        managerApi.actions.removeManager(managerToRemove);
                        setManagerToRemove(null);
                    }}
                    title={"Remove Manager"}
                    body={"Are you sure you want to remove this manager?"}
                    confirmButtonName={"Remove"}
                />
            }
            <h4 className="zero-blue">Managers</h4>
            <p className="zero-dark-grey" style={{fontSize: 14}}>The people listed below are "managers" for the check-in
                feature. Managers can create, edit, and assign desks as well as control all settings for this feature
                (locations, spaces, alerts, etc.).</p>
            <UsersTable
                loading={loading}
                users={managers}
                showTeams={false}
                generateMenuOptions={generateMenuOptions}
                onMenuClick={handleMenuClick}
                disableUserClick={true}
                total={managers.length}
            />
        </div>
    )
}
