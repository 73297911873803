export default function reducer(state = {
    notifications: [],
    read_notifications: [],
    unread_notifications_counter: 0,
}, action) {

    switch (action.type) {

        case "update_notifications": {
            return Object.assign({}, state, {
                notifications: action.payload
            });
        }

        case "update_read_notifications": {
            return Object.assign({}, state, {
                read_notifications: action.payload
            });
        }

        case "update_unread_notifications_counter": {
            return Object.assign({}, state, {
                unread_notifications_counter: action.payload
            });
        }

        case "RESET_NOTIFICATIONS": {
            return Object.assign({}, state, {
                notifications: [],
                read_notifications: [],
                unread_notifications_counter: 0
            });
        }

        default: {
            return state
        }

    }

}
