import { LocalStorageHelper } from "./Helper";

export class DebugLogger {
    static iteration = 0;
    static filters = [];
    
    constructor(name) {
        this.name = name;
        this.iteration = DebugLogger.iteration++;
        this.enabled = localStorage.getItem("zero-enable-debug-logger") !== null;
    }

    static getLogger(name) {
        return new DebugLogger(name);
    }

    /**
     * @param {string} name
     * @returns {boolean}
     */
    nameIsFiltered(name) {
        for (const filter of DebugLogger.filters) {
            if (name.startsWith(filter)) {
                return true;
            }
        }
        return false;
    }

    static addFilter(name) {
        DebugLogger.filters.push(name);
        LocalStorageHelper.set('debug-logger-filters', DebugLogger.filters);
    }

    static removeFilter(name) {
        const index = DebugLogger.filters.findIndex(n => n === name);
        if (index !== -1) {
            DebugLogger.filters.splice(index, 1);
            LocalStorageHelper.set('debug-logger-filters', DebugLogger.filters);
        }
    }

    static clearFilters() {
        DebugLogger.filters = [];
        LocalStorageHelper.set('debug-logger-filters', DebugLogger.filters);
    }

    log(message, ...args) {
        if (!this.enabled || this.nameIsFiltered(this.name)) {
            return;
        }

        console.debug(`${this.name}[${this.iteration}]: ${message}`, ...args);
    }

    branch(name) {
        if (!this.enabled) {
            return () => {}
        }

        return (message, ...args) => {
            if (this.nameIsFiltered(this.name) || this.nameIsFiltered(name)) {
                return;
            }
            console.debug(`${this.name}[${this.iteration}] > ${name}: ${message}`, ...args);
        }
    }
}

if (window.zero_debugLogger === undefined) {
    window.zero_debugLogger = DebugLogger;
    DebugLogger.filters = LocalStorageHelper.get('debug-logger-filters', 'object', []);
}
