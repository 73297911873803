import useOrganizationId from "hooks/useOrganizationId"
import { Link } from "react-router-dom";

/**
 * @param {{
 *  currentTab: "templates" | "schedules" | "calendar",
 *  pageType: "lms" | "regular" | "incident"
 * }} props 
 * @returns 
 */
export default function ManageFormsNavigationTabs({currentTab, pageType}) {
    const orgId = useOrganizationId();

    if (pageType === "incident") {
        return null;
    }

    const templatesPage = pageType === "lms" ? "manage_courses" : "manage_forms_templates";
    const templatesLabel = pageType === "lms" ? "Courses" : "Templates";
    const schedulesPage = pageType === "lms" ? "manage_courses_schedules" : "manage_schedules";
    const calendarPage = pageType === "lms" ? "manage_calendar/lms" : "manage_calendar/regular";

    const links = [
        {
            label: templatesLabel,
            page: templatesPage,
            isActive: currentTab === "templates",
        },
        {
            label: "Schedules",
            page: schedulesPage,
            isActive: currentTab === "schedules",
        },
        {
            label: "Calendar",
            page: calendarPage,
            isActive: currentTab === "calendar",
        }
    ]

    return (
        <ul className="nav nav-tabs">
            {links.map((link) => (
                <li key={link.page} className={link.isActive ? "active" : ""}>
                    <Link to={`/${orgId}/home/${link.page}`}>
                        <button className="ButtonLink">{link.label}</button>
                    </Link>
                </li>
            ))}
        </ul>
    )
}