import React from 'react';

import {mainContentContainerClass, safe_get} from '../other/Helper';

import {Result} from 'antd';

import '../assets/css/antd-custom.css'

class Error403 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div id="content-container" className="container-with-header">
                <div className={mainContentContainerClass()} style={{marginLeft: "0px"}}>
                    <div id="page-content" style={{paddingTop: "10px"}}>
                        <div className="panel bulletin-post-border">
                            <Result
                                status="403"
                                title="403"
                                subTitle="Sorry, you do not have access to this page."
                                extra={
                                    <button className="btn btn-primary" style={{
                                        marginTop: "8px",
                                        display: "block",
                                        margin: "auto",
                                        marginBottom: "10px"
                                    }} onClick={() => {
                                        window.location = "/";
                                    }}>Back to ZERO</button>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default Error403;