import { useCallback, useMemo, useRef } from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {v4 as uuid4} from 'uuid';

import { reorder } from 'other/Helper';

export function ReorderableList({items, renderItem, onReorder, itemKeyProp}) {
    const idRef = useRef(`droppable-${uuid4()}`);

    const onDragEnd = useCallback(result => {
        const {source, destination} = result;

        if (!destination) {
            return;
        }

        if (source.index === destination.index) {
            return;
        }

        const reorderedItems = reorder(
            items,
            source.index,
            destination.index
        );

        reorderedItems.forEach((item, index) => {
            item.order = index;
        });

        onReorder(reorderedItems);
    }, [items, onReorder]);

    const renderedItems = useMemo(() => {
        return items.map((item, index) => (
            <ReorderableListItem
                index={index}
                key={itemKeyProp ? item[itemKeyProp] : index}
            >
                {renderItem(item, index)}
            </ReorderableListItem>
        ));
    }, [items, renderItem, itemKeyProp]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={idRef.current}>
                {(provided) => (
                    <div ref={provided.innerRef}>
                        {renderedItems}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}


function ReorderableListItem({children, index}) {
    const idRef = useRef(`draggable-${uuid4()}`);

    return (
        <Draggable draggableId={idRef.current} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {children}
                </div>
            )}
        </Draggable>
    );
}