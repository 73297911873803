import React, {Component, useState, useCallback} from 'react';

import {Modal, Tag} from 'antd'
import LoadingIndicator from "../Shared/LoadingIndicator";
import {delete_submission, get_form_drafts} from "../../api/zero-api";
import safe_get from "../../other/SafeGet";
import NotificationAlert from "../../other/NotificationAlert";
import {dateFormatterWithTime} from "../../other/Helper";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import ButtonLoading from "../Shared/ButtonLoading";

import moment from 'moment';
import {useZeroContext, ZeroContext} from 'components/ZeroContext';
import SubmissionDraftsList from 'components/Offline/Forms/SubmissionDraftsList';
import SyncInProgressSpinner from 'components/Offline/SyncInProgressSpinner';

function DraftsModalTitle() {
    const {caches, isOffline} = useZeroContext();
    const [syncError, setSyncError] = useState("");

    const {lastSyncAt, isSyncing, sync} = caches;

    const tryToSync = useCallback(() => {
        if (isOffline) {
            setSyncError("You're offline. Please try again later.");
        } else if (!isSyncing) {
            setSyncError('');
            sync();
        }
    }, [isOffline, isSyncing, sync, setSyncError]);

    return (
        <>
            <div>My Drafts ({caches.submissionDrafts.regularDrafts.length})</div>
            <small className="ant-modal-sub-title">
                <>Last sync:&nbsp;</>
                {
                    lastSyncAt > 0 ? (
                        <>{dateFormatterWithTime(lastSyncAt)}</>
                    ) : (
                        <>Not Synced</>
                    )
                }
                { !isSyncing &&
                    <span className="blue-link mar-lft-10" onClick={tryToSync}>Sync now</span>
                }
                <SyncInProgressSpinner style={{paddingTop: '1rem'}}/>
                {syncError &&
                    <>
                        <br/>
                        <small className="error">{syncError}</small>
                    </>
                }
            </small>
        </>
    )
}

class MyDraftsModal extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        this.state = {
            drafts: this.props.drafts,
            loadingDrafts: false,
            showDeleteConfirm: false,
            indexToDelete: null,
            isDeleting: false,
            showModalBody: true,
        };
    }

    componentDidMount() {

    }

    getDrafts() {
        this.setState({
            loadingDrafts: true
        })
        const filter = this.props.pageType === 'regular' ? 'regular' : 'lms';
        get_form_drafts(`?form_types=${filter}`).then(success => {
            success.json().then(data => {
                const drafts = safe_get(data, 'drafts', []).sort(function (a, b) {
                    return a.edited_at < b.edited_at ? 1 : -1
                });

                this.setState({
                    drafts,
                    loadingDrafts: false
                });
            }).catch(err => {
                NotificationAlert("error", "", "Unable to load your drafts.");
                this.setState({
                    loadingDrafts: false
                });
            })
        })
    }

    showDeleteConfirmation(index) {
        this.setState({
            showDeleteConfirm: true,
            indexToDelete: index
        });
    }

    cancelDelete() {
        this.setState({
            showDeleteConfirm: false,
            indexToDelete: null,
            isDeleting: false
        });
    }

    async deleteLocalDraft(id) {
        try {
            const cache = this.context.caches.submissionDrafts.cache;
            await cache.delete(id, {immediate: true, includeAttachments: true});
        } catch (err) {
            // most likely a draft that doesn't exist locally
        }
    }

    confirmDelete() {
        const draftToDelete = this.state.drafts[this.state.indexToDelete];
        this.setState({
            isDeleting: true
        });
        const id = safe_get(draftToDelete, 'submission_uuid', '');
        delete_submission(id)
            .then(success => {
                this.deleteLocalDraft(id);
                this.cancelDelete();
                this.getDrafts();
            })
            .catch(err => {
                this.cancelDelete();
                NotificationAlert('error', '', 'Unable to delete draft.');
            });
    }

    editDraft(draft) {
        this.props.cancel();
        this.props.editDraft(draft);
    }

    renderFooter() {
        if (this.state.showDeleteConfirm) {
            return (
                <>
                    <button className="btn btn-discard" onClick={() => this.cancelDelete()}
                            disabled={this.state.isDeleting}>
                        Cancel
                    </button>
                    <button className="btn btn-modal" onClick={() => this.confirmDelete()}
                            disabled={this.state.isDeleting}>
                        {this.state.isDeleting ? <ButtonLoading/> : 'Delete'}
                    </button>
                </>
            )
        }
        return null;
    }

    assingmentDueDate(draft) {
        let due_date_timestamp = safe_get(draft, 'assignment.due_date_timestamp', null)
        if (!due_date_timestamp) {
            let dt = new Date();
            due_date_timestamp = dt.setUTCHours(23, 59, 0, 0) / 1000;
        }

        let due_date_time = `${moment.unix(due_date_timestamp).format("MMM D")}`;

        return (
            <span>{due_date_time}</span>
        )
    }

    renderModalBody() {
        if (this.state.loadingDrafts) {
            return <LoadingIndicator/>
        } else if (this.state.showDeleteConfirm) {
            return <div>Are you sure you want to delete this draft?</div>
        } else if (this.state.drafts.length === 0) {
            return <div>You currently do not have any drafts. When you do, they will be listed here.</div>
        } else {
            return (
                <div>
                    {this.state.drafts.map((draft, index) => {
                        return (
                            <div
                                key={index}
                                className="panel panel-light panel-colorful user-card-size thin-border"
                                style={{
                                    padding: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div>
                                    <p className="text-md text-semibold link-hover zero-blue mar-btm-0 draft-title-modal"
                                       onClick={() => this.editDraft(draft)}>
                                        {safe_get(draft, 'form.name', '')}
                                    </p>
                                    <span
                                        className="zero-dark-grey">Saved submission in {safe_get(draft, 'team.name', 'N/A')}</span>
                                    <br/>
                                    <span
                                        className="zero-dark-grey">Last edited on {dateFormatterWithTime(safe_get(draft, 'edited_at', safe_get('draft', 'created_at', 0)))}</span>
                                    <br/>
                                    {
                                        draft.assignment &&
                                        <Tag style={{color: "#505050"}}>Assignment
                                            Due {this.assingmentDueDate(draft)}</Tag>
                                    }
                                </div>
                                <div className="text-lg" style={{minWidth: "50px"}}>
                                    <EditOutlined className="link-hover zero-blue"
                                                  onClick={() => this.editDraft(draft)}/>
                                    <span className="v-divide"></span>
                                    <DeleteOutlined className="link-hover zero-delete-red"
                                                    onClick={() => this.showDeleteConfirmation(index)}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }
    }

    render() {
        const showOfflineDraftList = this.props.pageType === 'regular';

        let title;
        if (this.state.showDeleteConfirm) {
            title = "Confirmation";
        } else if (showOfflineDraftList) {
            title = <DraftsModalTitle />;
        } else {
            title = `My Drafts (${this.state.drafts.length})`;
        }

        return (
            (<Modal
                title={title}
                open={this.props.show}
                maskClosable={false}
                closable={!this.state.showDeleteConfirm}
                onCancel={this.props.cancel}
                footer={this.renderFooter()}
                maskTransitionName="maskTransitionName"
            >
                {
                    showOfflineDraftList ? (
                        <SubmissionDraftsList insideModal={true}/>
                    ) : (
                        this.renderModalBody()
                    )
                }
            </Modal>)
        );
    }
}

export default MyDraftsModal;