import FormAssignmentsCache from "offline/FormAssignmentCache";
import React, {useCallback, useEffect, useState} from "react";
import {unstable_batchedUpdates} from "react-dom";
import useDbChangeListener from "../useDbChangeListener";

export default function useFormAssignmentsCacheLoader(isAppLoading, orgId, userId) {
    /** @type {[FormAssignmentsCache, React.Dispatch<any>]} */
    const [cache, setCache] = useState(null);

    /** @type {[object[], React.Dispatch<any>]} */
    const [formAssignments, setFormAssignments] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (orgId && userId && !isAppLoading) {
                const cache = new FormAssignmentsCache(orgId, userId);
                const formAssignments = await cache.getAll();

                unstable_batchedUpdates(() => {
                    setCache(cache);
                    setFormAssignments(formAssignments);
                });
            }
        }

        fetchData();
    }, [orgId, userId, isAppLoading, setCache, setFormAssignments]);

    const listenerCallback = useCallback(async () => {
        if (cache) {
            const formAssignments = await cache.getAll();
            setFormAssignments(formAssignments);
        }
    }, [cache, setFormAssignments]);

    useDbChangeListener(cache?.db, 250, listenerCallback);

    return {cache, formAssignments};
}
