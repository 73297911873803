import React, {Component} from 'react';
import {connect} from 'react-redux'

import * as userActions from '../store/actions/UserActions';
import * as teamsActions from '../store/actions/TeamsActions';
import * as organizationActions from '../store/actions/OrganizationActions';
import * as notificationActions from '../store/actions/NotificationsActions';
import * as dashboardActions from '../store/actions/DashboardActions';
import * as incidentActions from '../store/actions/IncidentsActions';
import * as feedHelperActions from '../store/actions/FeedHelperActions';
import * as libraryHelperActions from '../store/actions/LibraryActions';

import {
    login,
    update_auth_token,
    update_organization_uuid,
    oauth_login,
    update_redirect_route,
    get_redirect_route_cookie,
    deleteCookies
} from '../api/zero-api.js';

import {safe_get, isIE} from '../other/Helper'
import {msalInstance, loginRequest} from '../other/MSAL_Config';


import ButtonLoading from '../components/Shared/ButtonLoading';
import LoadingIndicator from '../components/Shared/LoadingIndicator';

import '../assets/css/Login.css';
// import logo from '../assets/css/img/ZERO-(white)-small.png';
// import AppStore from '../assets/css/img/AppStore.svg'
// import GoogleLogin from '../assets/css/img/btn_google_signin_light_normal_web@2x.png';
// import { ReactComponent as MSLogin } from '../assets/css/img/ms_signin_light.svg';

class OAuthLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectRoute: "",
            loadingOAuth: true,
        };

    }

    componentDidMount() {
        var self = this;

        // if (this.props.location.pathname.includes("/redirect=")) {
        //     var path = this.props.location.pathname.split("/redirect=").pop();
        //     if (path.includes("kiosk")) {
        //         path = "";
        //     }
        //     console.log(path);
        //     update_redirect_route(path);
        // }      

        if (!window.location.href.includes("/oauth_login#")) {
            msalInstance.acquireTokenRedirect(loginRequest);
        } else {
            msalInstance.handleRedirectPromise().then((tokenResponse) => {
                // Check if the tokenResponse is null
                // If the tokenResponse !== null, then you are coming back from a successful authentication redirect. 
                // If the tokenResponse === null, you are not coming back from an auth redirect.
                if (tokenResponse) {
                    self.zeroLoginWithMS(tokenResponse);
                } else {
                    self.props.history.push("/login");
                }
            }).catch((error) => {
                // handle error, either in the library or coming back from the server
                console.log("error", error)
                self.props.history.push("/login");
                self.setState({loadingOAuth: false});
            });
        }

    }

    zeroLoginWithMS = (loginResponse) => {
        var self = this;

        let route = get_redirect_route_cookie();

        deleteCookies();

        let body = JSON.stringify({
            access_token: loginResponse.accessToken,
            id_token: loginResponse.idToken,
            social_type: "microsoft-graph"
        });

        oauth_login(body).then(function (success) {
            success.json().then(success => {
                update_auth_token(safe_get(success, "jwt", undefined));

                var user = safe_get(success, "user", {});
                self.props.dispatch(userActions.update_current_user(user));

                if (route && route !== "/login") {
                    self.props.history.push(route);
                } else {
                    self.props.history.push("/");
                }

            });
        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {
                    let error_messages = safe_get(error, "error_messages", []);
                    if (error_messages.length !== 0) {
                        let errorField = safe_get(error_messages[0], "field", "");
                        let errorMsg = safe_get(error_messages[0], "message", "");

                        if (errorField === "password") {
                            // newState["pwError"] = errorMsg;
                            // newState["emailError"] = null;
                            self.props.history.push('/login?pwError=' + errorMsg)
                        } else if (errorField === "email") {
                            // newState["emailError"] = errorMsg;
                            // newState["pwError"] = null;
                            self.props.history.push('/login?emailError=' + errorMsg)

                        } else {
                            // newState["pwError"] = null;
                            // newState["emailError"] = null;
                            // newState["loginError"] = errorMsg;
                            self.props.history.push('/login?loginError=' + errorMsg)
                        }
                    }
                });
            }
        });
    }


    render() {

        return (
            <div id="container" className="cls-container" style={{backgroundColor: "white"}}>
                <div className="cls-content auth">
                    <div className="cls-content-sm panel " style={{marginTop: "150px"}}>
                        <LoadingIndicator/>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
    };
}

export default connect(mapStateToProps)(OAuthLogin);

