import React from 'react';

import {Checkbox} from "antd";
import InfoIconComponent from "../InfoIconComponent";

export default function AutoCloseCheckbox(props) {
    const {checked, onChange, style = {}} = props;
    const infoText = "If this box is checked, ZERO will automatically close this post after creation. " +
        "Check this box if the post requires no follow up or closure activity.";

    return (
        <Checkbox style={style} checked={checked} onChange={onChange}>
            <>Auto-close post</>
            <InfoIconComponent position={"top"} width={"350px"} text={infoText}/>
        </Checkbox>
    )
}