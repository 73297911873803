import {useEffect} from "react";

export default function useDbChangeListener(db, timeoutMs, callback) {
    useEffect(() => {
        if (db) {
            let timer = null;

            const listener = db.changes({
                since: 'now',
                live: true,
            }).on('change', change => {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                timer = setTimeout(callback, timeoutMs);
            });

            // cleanup
            return () => {
                if (timer) {
                    clearTimeout(timer);
                }

                listener.cancel();
            }
        }
    }, [db, timeoutMs, callback]);
}