import React, {Component} from 'react';

import {safe_get} from '../../../other/Helper.js'
import {CheckOutlined, CloseOutlined, DragOutlined} from '@ant-design/icons'
import {Switch, Select, Popover} from 'antd';
import Picker from '@emoji-mart/react'

import DragIcon from 'components/Shared/DragIcon.js';

class ManageCategoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            category_name: "",
            category_enabled: false,
            emoji: "",
            indicator: false,
            visible: false,
            inputTimer: null,
        };

        this.onSwitch = this.onSwitch.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
    }

    componentDidMount() {
        var category_name = safe_get(this.props, "category.label", "");
        var emoji = safe_get(this.props, "category.emoji", "")

        this.setState({
            category_name: category_name,
            category_enabled: safe_get(this.props, "category.enabled", false),
            indicator: safe_get(this.props, "category.leading_indicator", false),
            emoji: emoji
        });
    }

    onSwitch(checked) {
        this.setState({category_enabled: checked}, () => {
            this.updateCategory();
        });
    }

    handleSelectChange(value) {
        var boolean = value === "yes" ? true : false
        this.setState({indicator: boolean}, () => {
            this.updateCategory();
        });
    }

    selectEmoji = (emoji) => {
        this.setState({emoji: emoji.native}, () => {
            this.updateCategory();
        });
    }

    onChange(e) {
        if (this.state.inputTimer) {
            clearTimeout(this.state.inputTimer);
        }

        this.setState({
            category_name: e.target.value,
            inputTimer: setTimeout(() => {
                this.updateCategory()
            }, 1000)
        });
    }

    onInputBlur = (e) => {
        const newValue = e.target.value;
        if (newValue === safe_get(this.props, "category.label", "")) return;

        if (this.state.inputTimer) {
            clearTimeout(this.state.inputTimer);
        }

        this.setState({
            category_name: e.target.value,
            inputTimer: null,
        }, () => {
            this.updateCategory();
        });
    }

    updateCategory() {
        var category = {
            category_uuid: this.props.category.category_uuid,
            emoji: this.state.emoji,
            label: this.state.category_name,
            enabled: this.state.category_enabled,
            leading_indicator: this.state.indicator
        }
        this.props.updateMade(category)
    }

    render() {
        const emojiInputStyle = {
            borderRight: "0px",
            maxWidth: "32px",
            display: "inline-block",
            padding: "0px",
            textAlign: "center",
            verticalAlign: "bottom",
            cursor: "pointer",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px"
        }

        const mainInputStyle = {
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            marginRight: "6px",
            maxWidth: '250px',
        }

        return (
            <div
                className="hover-parent"
                style={{marginBottom: "6px", display: 'flex', alignItems: 'center'}}
            >

                <Popover
                    content={
                        <Picker
                        set="apple" 
                        theme="light" 
                        onEmojiSelect={this.selectEmoji} 
                        emojiSize={22} 
                        perLine={8} />
                    }
                    trigger="click"
                    title=""
                    placement="bottom"
                    className="custom-popover"
                    style={{backgroundColor: '#eaeaea !important', padding: '5px'}}
                    overlayInnerStyle={{ padding: 0 }} >

                    <input id={"emoji-select" + this.props.index} type={"text"}
                        className={"form-control emoji-field"} style={emojiInputStyle} defaultValue={this.state.emoji}/>
                </Popover>

                <input
                    id={"category-input" + this.props.index}
                    type={"text"}
                    className={"form-control topic-field"}
                    onChange={this.onChange}
                    onBlur={this.onInputBlur}
                    value={this.state.category_name}
                    maxLength={30}
                    style={mainInputStyle}
                />

                <Select className="leading-indicator-select" 
                        value={this.state.indicator ? "yes" : "no"} style={{minWidth: 67}}
                        onChange={this.handleSelectChange}>
                    <Select.Option value="yes">Yes</Select.Option>
                    <Select.Option value="no">No</Select.Option>
                </Select>

                <Switch
                    checked={this.state.category_enabled}
                    onChange={this.onSwitch}
                    style={{marginTop: '0px', marginLeft: '6px', float: 'none'}}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />

                <DragIcon />

            </div>
        );
    }
}

export default ManageCategoryComponent;