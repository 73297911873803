import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Collapse} from 'react-collapse';

import {get_form_categories, update_form_categories} from '../../../api/zero-api.js'
import {safe_get} from '../../../other/Helper.js';

import ButtonLoading from '../../Shared/ButtonLoading';
import NotificationAlert from '../../../other/NotificationAlert';

import {Switch} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

class FormSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            updated_categories: [],
            categoriesEnabled: false,
            manageCategoriesOpen: false,
        };


    }

    componentDidMount() {
        this.getFormCategeries();
    }

    getFormCategeries = () => {
        let self = this;
        get_form_categories().then(success => {
            success.json().then(success => {
                console.log(success)
                self.setState({
                    categories: safe_get(success, "categories", [])
                });
            });
        }, error => {

        })
    }

    updateCategory = (new_category) => {
        var updated_categories = [...this.state.updated_categories];

        var old_category = updated_categories.find(function (obj) {
            return obj.category_uuid === new_category.category_uuid
        });
        var index = updated_categories.indexOf(old_category);

        if (index >= 0) {
            updated_categories[index] = new_category;
        } else {
            updated_categories.push(new_category)
        }

        this.setState({saveDisabled: false, updated_categories: updated_categories});
    }

    save = () => {
        var self = this;

        this.setState({saving: true});

        var body = JSON.stringify({
            categories: this.state.updated_categories
        });

        update_form_categories(body).then(function (success) {
            success.json().then(success => {
                self.setState({saving: false});
                NotificationAlert("success", "", "Changes saved.")
            });

        }, function (error) {
            self.setState({saving: false});
            NotificationAlert("success", "", "Could not save changes.")
        });
    }

    render() {

        var {manageCategoriesOpen} = this.state;

        return (
            <div className="panel pad-top pad-btm" style={{maxWidth: '800px', margin: '0 auto'}}>

                <h4 className="mar-top-0 zero-blue" style={{display: "inline-block"}}>
                    Category
                    &nbsp;{this.state.categoriesEnabled && <span className="label label-success" style={{
                    padding: ".3em .6em .3em",
                    fontSize: "60%",
                    verticalAlign: "middle"
                }}>Enabled</span>}
                </h4>
                <button className="btn btn-xs btn-default expand-button pull-right"
                        onClick={this.toggleManageCategories}>{manageCategoriesOpen ? "Close" : "Expand"}</button>
                <p className="zero-dark-grey">Categories for forms allow you to classify form templates into specific
                    buckets. ZERO will display any enabled categories in the filters dropdown on the main forms
                    page.</p>

                <div>
                    <p className="zero-blue" style={{display: 'inline-block'}}><b>Category</b></p>
                </div>

                {
                    this.state.categories.map((category, index) => (
                        <ManageCategoryComponent key={index} category={category} updateCategory={this.updateCategory}/>
                    ))
                }

                <div>
                    <button className="btn btn-primary"
                            style={{display: 'inline-block', padding: "5px 10px", fontSize: "14px"}}
                            disabled={this.state.saveDisabled} onClick={this.save}>
                        {
                            this.state.saving ? <ButtonLoading/> : "Save"
                        }
                    </button>
                </div>

                {
                    this.state.error &&
                    <small className="error">{this.state.error}</small>
                }


            </div>
        )
    }
}


class ManageCategoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            category_name: "",
            category_enabled: false,
            emoji: "",
            indicator: false,
            visible: false,
        };

        this.onSwitch = this.onSwitch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
    }

    componentDidMount() {

        this.setState({
            category_name: safe_get(this.props, "category.name", ""),
            category_enabled: safe_get(this.props, "category.enabled", false),
        });
    }

    onSwitch(checked) {
        this.setState({category_enabled: checked}, () => {
            this.updateCategory();
        });
    }

    onChange(e) {
        this.setState({
            category_name: e.target.value
        }, () => {
            this.updateCategory();
        });
    }

    updateCategory() {
        var category = {
            category_uuid: this.props.category.category_uuid,
            name: this.state.category_name,
            enabled: this.state.category_enabled,
        }
        this.props.updateCategory(category)
    }

    render() {
        const mainInputStyle = {
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            marginRight: "6px",
            verticalAlign: "bottom"
        }

        return (

            <div style={{marginBottom: "6px", display: 'flex', alignItems: 'center'}}>

                <input
                    id={"category-input" + this.props.index}
                    type={"text"}
                    className={"form-control topic-field"}
                    onChange={this.onChange}
                    value={this.state.category_name} maxLength={30}
                    style={mainInputStyle}
                />

                <Switch
                    checked={this.state.category_enabled}
                    onChange={this.onSwitch}
                    style={{marginLeft: '6px'}}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />

            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(FormSettings);