import React, {Component} from 'react';

import {connect} from 'react-redux'

import {beforeFileUpload, fileHasZeroSize, fileUpload, safe_get} from '../../other/Helper.js';
import {update_user, get_timezones, get_user} from '../../api/zero-api.js'

import ChangePassword from './ChangePassword.js';
import ButtonLoading from '../Shared/ButtonLoading'

import {PlusOutlined} from '@ant-design/icons';
import {Upload, Modal, Select} from 'antd';

import * as userActions from '../../store/actions/UserActions'

import NewAccountEditFields from '../Shared/AccountEditFields'
import Previewer from '../Previewer.js';

class AccountEditFields extends Component {
    constructor(props) {
        super(props);

        const fileList = [];
        if (safe_get(this.props, "user.avatar_data.img_uploaded", false)) {
            const logo = {
                key: 1,
                uid: 1111,
                name: 'profile-pic.png',
                status: 'done',
                url: this.props.user.avatar_data.urls.original,
                Location: this.props.user.avatar_data.urls.original
            }
            fileList.push(logo)
        }

        this.fields = null;
        this.state = {
            redirectToAccount: false,
            profilePic: null,
            previewVisible: false,
            previewImage: '',
            PresignedInfo: {
                url: "/",
                fields: {}
            },
            fileList,
            profile_pic_location: this.props.user.avatar_data.urls.original,
            fieldErrors: {}
        };

        this.update_user = this.update_user.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleImageSubmit = this.handleImageSubmit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleCancel = () => this.setState({previewVisible: false})

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleRemove(file) {
        var profilePic = {}
        profilePic["Location"] = "";
        this.setState({fileList: [], profile_pic_location: null}, () => {
            // self.update_user();
        });
    }

    beforeUpload(file) {
        if (fileHasZeroSize(file)) {
            return false;
        }
        var self = this;
        var name = file.name.toLowerCase();
        if (name.indexOf(".png") < 0 && name.indexOf(".jpg") < 0 && name.indexOf(".jpeg") < 0) {
            this.setState({uploadError: 'Cannot upload this file type.'});
            return false;
        }

        let [allow, error_msg] = beforeFileUpload(file, '', 0);

        if (allow) {
            self.setState({file: file});
            return true;
        } else {
            this.setState({uploadError: error_msg});
            this.props.uploadError(error_msg);
            return false;
        }
    }

    handleImageSubmit(file) {
        var self = this;

        var params = JSON.stringify({
            feature_key: "user_profile",
            content_type: file.file.type,
            file_name: file.file.name,
            embedded: false
        });

        var uploading_file = {
            key: file.file.uid,
            uid: file.file.uid,
            status: "uploading",
            name: "Uploading...",
            percent: 0
        }

        var fileList = [uploading_file];
        this.setState({fileList: fileList});

        fileUpload(this, params, file, function (progress) {
            // upload progress
            var index = fileList.indexOf(uploading_file);
            if (index >= 0) {
                var file = fileList[index];
                file["percent"] = progress;
                fileList[index] = file;
                self.setState({fileList: fileList});
            }

        }, function (pre_signed_url, file_key) {
            var file_location = pre_signed_url + '/' + file_key;
            var new_pic = {
                key: file_location,
                uid: file_location,
                name: file.file.name,
                status: 'done',
                url: file_location,
                Location: file_location,
            }

            var fileList = self.state.fileList;
            fileList[0] = new_pic;

            self.setState({
                profile_pic_location: file_location,
                fileList: fileList,
                uploadError: null
            }, () => {
                // var user = self.props.user;
                // user["avatar_data"] = {
                //     "img_uploaded": true,
                //     "urls": {
                //         "original": file_location,
                //         "small": file_location,
                //         "medium": file_location,
                //     }
                // }
                // self.props.updateUserProfile(user);
            })
        }, function (error) {
            console.log(error);
        });

    }

    async update_user() {
        if (this.fields && !this.state.updating) {
            this.setState({updating: true, fieldErrors: {}});

            const fieldsToUpdate = [
                'first_name',
                'last_name',
                'title',
                'phone_number',
                'mobile_phone_number_parts',
                'emergency_phone_parts',
                'alternate_email',
                'timezone'
            ];

            const body = {
                avatar: this.state.profile_pic_location
            };

            for (const name in this.fields) {
                if (fieldsToUpdate.includes(name)) {
                    if (name === 'phone_number') {
                        body[name] = this.fields[name].replace(/\s+/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '');
                    } else {
                        body[name] = this.fields[name];
                    }
                }
            }

            if (body.mobile_phone_number_parts && !body.mobile_phone_number_parts.phone) {
                body.mobile_phone_number_parts = {};
            }

            if (body.emergency_phone_parts && !body.emergency_phone_parts.phone) {
                body.emergency_phone_parts = {};
            }

            let newState = {};

            try {
                const response = await update_user(this.props.user.uuid, JSON.stringify(body));
                const data = await response.json();

                const user = safe_get(data, 'user', {});

                const avatarData = user.avatar_data;
                if (this.state.profile_pic_location) {
                    avatarData['img_uploaded'] = true;
                    user['avatar_data'] = avatarData;
                } else {
                    avatarData["img_uploaded"] = false;
                    user["avatar_data"] = avatarData;
                }

                newState = {user, updating: false};
                this.props.updateUser(user);
            } catch (errorResponse) {
                newState = {updating: false};
                if (errorResponse.status === 422) {
                    const errorData = await errorResponse.json();
                    const errors = safe_get(errorData, 'error_messages', []);
                    const fieldErrors = {};

                    for (const error of errors) {
                        const field = safe_get(error, 'field', '');
                        const message = safe_get(error, 'message', '');
                        if (fieldsToUpdate.includes(field)) {
                            fieldErrors[field] = message;
                        }
                    }

                    newState.fieldErrors = fieldErrors;
                }
            } finally {
                this.setState(newState);
            }
        }
    }

    render() {
        const {previewVisible, previewImage, fileList} = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            (<div className="panel article thin-border" style={{maxWidth: '800px'}}>
                <div className="panel-body" style={{maxWidth: '150px', display: 'block', margin: '0 auto'}}>
                    <div>
                        <Upload
                            className="profile-avatar-upload"
                            action="/"
                            accept={".jpeg, .jpg, .png"}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            customRequest={this.handleImageSubmit}
                            beforeUpload={this.beforeUpload}
                            onRemove={this.handleRemove}
                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>

                        <Previewer  
                            show={previewVisible}
                            close={this.handleCancel}
                            attachments={[{
                                file_name: 'account.png',
                                public_url: previewImage
                            }]}                            
                        />
                    </div>
                </div>
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <Upload action="/" beforeUpload={this.beforeUpload} customRequest={this.handleImageSubmit}
                            fileList={[]} accept={".jpeg, .jpg, .png"} className="profile-change-avatar">
                        <button className="btn btn-xs btn-default add-team-sidebar ant-upload-text"
                                style={{display: 'block', margin: '0 auto', color: "#555"}}>Change Avatar
                        </button>
                    </Upload>
                </div>
                {
                    this.state.uploadError &&
                    <small className="error" style={{
                        display: 'block',
                        textAlign: 'center',
                        marginTop: '4px'
                    }}>{this.state.uploadError}</small>
                }
                <form className="form-horizontal" style={{marginTop: '5px'}}>
                    <div className="tab-content">
                        <NewAccountEditFields
                            user={this.props.user}
                            emailEditable={false}
                            showTimezone={true}
                            showPassword={true}
                            changePasswordOnClick={() => this.props.changePW()}
                            onFieldsChange={fields => this.fields = fields}
                            fieldErrors={this.state.fieldErrors}
                        />
                    </div>

                    {
                        this.state.responseError &&
                        <small className="error mar-top-5 mar-btm-10"
                               style={{display: 'block', textAlign: 'center'}}>{this.state.responseError}</small>
                    }
                </form>
                <div className="panel clear" style={{marginBottom: "0px"}}>
                    <div className="row">
                        <div className="col-xs-6">
                            <button onClick={this.props.completed} className="btn btn-primary btn-block discard-article"
                                    style={{maxWidth: '150px', float: 'right'}}>Cancel
                            </button>
                        </div>
                        <div className="col-xs-6">
                            <button onClick={this.update_user} className="btn btn-primary btn-block"
                                    style={{maxWidth: '150px'}}>
                                {
                                    this.state.updating ? <ButtonLoading/> : "Save Changes"
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>)
        );
    }
}


class AccountEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changePW: false
        }
        this.getUser = this.getUser.bind(this);
        this.update_user = this.update_user.bind(this);
        this.updateUserProfile = this.updateUserProfile.bind(this);
    }

    update_user(user) {
        this.props.dispatch(userActions.update_current_user(user))
        .then(() => {
            this.props.completed();
        });
    }

    updateUserProfile(user) {
        this.getUser()
    }

    getUser() {
        var self = this
        get_user(this.props.user_uuid).then(function (success) {
            success.json().then(success => {
                var user = safe_get(success, "user", {});
                self.props.dispatch(userActions.update_current_user(user));
                setTimeout(() => {
                    self.props.completed()
                }, 50);
            });
        }, function (error) {

        });
    }


    render() {
        if (this.state.changePW) {
            return (
                <ChangePassword
                    completed={this.props.completed}
                    back={() => {
                        this.setState({changePW: false});
                    }}
                />
            )
        } else {
            return (
                <AccountEditFields
                    user={this.props.user}
                    updateUser={this.update_user}
                    updateUserProfile={this.updateUserProfile}
                    completed={this.props.completed}
                    changePW={() => {
                        this.setState({changePW: true})
                    }}/>
            );
        }

    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(AccountEdit);
