import {
    CheckCircleOutlined, ClockCircleOutlined,
    CloseCircleOutlined, PushpinOutlined
} from '@ant-design/icons';
import {Tag} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    dateFormatterFromString,
    dateFormatterNoTime, safe_get
} from '../../other/Helper.js';
import ButtonLoading from '../Shared/ButtonLoading.js';
import TeamListPopover from '../Shared/TeamListPopover.js';
import UrgentContactDetailsAlert from '../Shared/UrgentContactDetailsAlert';
import {withRouter} from 'react-router';

function Assignment({assignment, organization_uuid, history, disableLinks}) {
    let team_names = assignment.team_names.sort((a, b) => {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });

    let due_date_timestamp = safe_get(assignment, 'due_date_timestamp', null)
    if (!due_date_timestamp) {
        let dt = new Date();
        due_date_timestamp = dt.setUTCHours(23, 59, 0, 0) / 1000;
    }

    let due_date_time = `${moment.unix(due_date_timestamp).format("MMM D")} at ${moment.unix(due_date_timestamp).format("hh:mm A")}`;

    let excused_timestamp = safe_get(assignment, 'excused_at', null)
    let excused_date = `${moment.unix(excused_timestamp).format("MMM D, YYYY")}`

    let isOverDue = moment.unix(due_date_timestamp).isBefore(moment());

    const titleClassNames = 'mar-btm-5 zero-blue' + (disableLinks ? '' : ' link-hover ');

    const goToAssignment = (assignment) => {
        if (disableLinks) {
            return;
        }

        let isOverDue = moment(assignment.due_date).isBefore(moment().format("YYYY-MM-DD"))
        let path = "/" + organization_uuid + '/home/dashboard';

        if (assignment.type === "form") {
            if (assignment.completed) {
                path = '/' + organization_uuid + '/home/team/' + assignment.team_uuids[0] + '/forms/submission_view/' + assignment.submission_uuid;
            } else if (!isOverDue) {
                if (assignment.submission_uuid) {
                    path = '/' + organization_uuid + '/home/team/' + assignment.team_uuids[0] + '/forms/' + assignment.form_uuid + '/submission/' + assignment.submission_uuid + '?showPreviousDraftAssignmentAlert=1';
                } else {
                    path = '/' + organization_uuid + '/home/team/' + assignment.team_uuids[0] + '/forms/' + assignment.form_uuid + '/assignment/' + assignment.assignment_uuid + '/submit';
                }
            }
        } else if (assignment.type === "course") {
            if (assignment.completed) {
                path = '/' + organization_uuid + '/home/team/' + assignment.team_uuids[0] + '/courses/submission_view/' + assignment.submission_uuid;
            } else if (!isOverDue) {
                if (assignment.submission_uuid) {
                    path = '/' + organization_uuid + '/home/team/' + assignment.team_uuids[0] + '/courses/' + assignment.form_uuid + '/submission/' + assignment.submission_uuid;
                } else {
                    path = '/' + organization_uuid + '/home/team/' + assignment.team_uuids[0] + '/courses/' + assignment.form_uuid + '/assignment/' + assignment.assignment_uuid + '/submit';
                }
            }


        } else if (assignment.type === "post" || assignment.post_uuid) {
            path = '/' + organization_uuid + '/home/team/my_teams/feed/post/' + assignment.post_uuid;
        }

        history.push(path);
    }

    if (assignment.type === "form" || assignment.type === "course") {
        return (
            <div>
                <div className={titleClassNames} style={{wordBreak: 'break-word'}}
                     onClick={() => goToAssignment(assignment)}>
                    <strong>{assignment.title}</strong>
                </div>
                <p className="mar-btm-5 zero-dark-grey">
                    {assignment.form_name}
                </p>
                <p className="mar-btm-5 zero-dark-grey">
                    In <TeamListPopover teamNames={team_names} sorted/>
                </p>
                <p className="zero-dark-grey">
                    {
                        assignment.completed &&
                        <span>
                            <CheckCircleOutlined className="mar-rgt-5" style={{color: "#52c41a"}}/>
                            Completed {dateFormatterNoTime(assignment.completed_at)}
                        </span>
                    }
                    {
                        (!assignment.completed && isOverDue) && !assignment.excused &&
                        <span>
                            <CloseCircleOutlined className="mar-rgt-5" style={{color: "#f56946"}}/>
                            Missed {dateFormatterFromString(assignment.due_date)}
                        </span>
                    }
                    {
                        assignment.excused &&
                        <span className="zero-blue">
                            <CloseCircleOutlined className="mar-rgt-5" style={{color: "#f3b44b"}}/>
                            Excused {excused_date}
                        </span>
                    }
                    {
                        !assignment.completed && !isOverDue && !assignment.excused &&
                        <span className="zero-dark-grey">
                            <ClockCircleOutlined className="mar-rgt-5"/>
                            Available now &bull; Due by {due_date_time}
                        </span>
                    }
                </p>
                { assignment.is_shared &&
                    <Tag className="tag-blue">Shared assignment</Tag>
                }
                {
                    !assignment.completed && !isOverDue && assignment.submission_uuid &&
                    <Tag style={{color: "#505050"}}>Draft created</Tag>
                }
                <hr style={{margin: "10px 0px"}}/>
            </div>
        );
    } else if (assignment.type === "post") {
        return (
            <div>
                <div className={titleClassNames} style={{wordBreak: 'break-word'}}
                     onClick={() => goToAssignment(assignment)}>
                    <strong>{assignment.title}</strong>
                    {isOverDue && <Tag color="red" style={{
                        fontSize: "11px",
                        lineHeight: "1.42857143",
                        marginLeft: "5px"
                    }}>Overdue</Tag>
                    }
                </div>
                <p className="mar-btm-5 zero-dark-grey">
                    In <TeamListPopover teamNames={team_names} sorted/>
                </p>
                {
                    (assignment.post_status === "closed" || assignment.completed) &&
                    <p className="zero-dark-grey">
                        <CheckCircleOutlined className="mar-rgt-5" style={{color: "#52c41a"}}/>
                        Closed {dateFormatterNoTime(assignment.completed_at)}
                    </p>
                }
                {
                    ((assignment.post_status === "open" || !assignment.completed) && !assignment.due_date) &&
                    <p className="zero-dark-grey">
                        <PushpinOutlined className="mar-rgt-5"/>
                        Assigned {dateFormatterNoTime(assignment.assigned_at)}
                    </p>
                }
                {
                    ((assignment.post_status === "open" || !assignment.completed) && assignment.due_date) &&
                    <p className="zero-dark-grey">
                        <ClockCircleOutlined className="mar-rgt-5"/>
                        Due {due_date_time}
                    </p>
                }
                <hr style={{margin: "10px 0px"}}/>
            </div>
        );
    }
}

function SeparatedAssignmentsLists({
                                       loading = false,
                                       organization_uuid,
                                       history,
                                       disableLinks = false,
                                       openAssignments,
                                       loadMoreOpenAssignments = () => {},
                                       showLoadMoreOpenAssignments = false,
                                       loadingOpenAssignments = false,
                                       pastAssignments,
                                       loadMorePastAssignments = () => {},
                                       showLoadMorePastAssignments = false,
                                       loadingPastAssignments = false,
                                   }) {
    const [selectedTab, setSelectedTab] = useState('open');

    return (
        <div className="panel thin-border" style={{height: "550px"}}>
            <ul className="nav nav-tabs nav-justified toggle-tabs">
                <li className={selectedTab === "open" ? "active" : ""}>
                    <button className="ButtonLink" style={{height: "55px"}} onClick={() => {
                        setSelectedTab('open')
                    }}>
                        Open Assignments
                    </button>
                </li>
                <li className={selectedTab === "past" ? "active" : ""}>
                    <button className="ButtonLink" style={{height: "55px"}} onClick={() => {
                        setSelectedTab('past')
                    }}>
                        Past Assignments
                    </button>
                </li>
            </ul>

            {
                loading &&
                <div className="panel-body no-top-pad mar-top-5" style={{maxHeight: "480px", overflow: "auto"}}>
                    {[...Array(5)].map((_item, index) =>
                        <div key={index}>
                            <h5 className="link-hover mar-btm-5 zero-blue">
                                <Skeleton width={100}/>
                            </h5>
                            <p className="mar-btm-5 zero-dark-grey">
                                <Skeleton width={70}/>
                            </p>
                            <p>
                                <Skeleton width={150}/>
                            </p>
                            <hr style={{margin: "10px 0px"}}/>
                        </div>
                    )}
                </div>
            }
            {
                !loading &&
                <div className="panel-body no-top-pad mar-top-10" style={{maxHeight: "480px", overflow: "auto"}}>
                    {
                        selectedTab === 'open' &&
                        <UrgentContactDetailsAlert/>
                    }
                    {
                        selectedTab === "open" &&
                        openAssignments.length === 0 &&
                        <p className="zero-dark-grey">No open assignments.</p>
                    }
                    {
                        selectedTab === "open" &&
                        openAssignments.length > 0 &&
                        openAssignments.map((assignment, index) => (
                            <Assignment
                                key={index}
                                assignment={assignment}
                                history={history}
                                organization_uuid={organization_uuid}
                                disableLinks={disableLinks}
                            />
                        ))
                    }
                    {
                        selectedTab === "open" &&
                        showLoadMoreOpenAssignments &&
                        <button className="btn btn-primary" style={{display: "block", margin: "0 auto"}}
                                onClick={loadMoreOpenAssignments}>
                            {
                                loadingOpenAssignments ? <ButtonLoading/> : "Load More"
                            }
                        </button>
                    }
                    {
                        selectedTab === "past" &&
                        pastAssignments.length === 0 &&
                        <p className="zero-dark-grey">No past assignments.</p>
                    }
                    {
                        selectedTab === "past" &&
                        pastAssignments.length > 0 &&
                        pastAssignments.map((assignment, index) => (
                            <Assignment
                                key={index}
                                assignment={assignment}
                                history={history}
                                organization_uuid={organization_uuid}
                                disableLinks={disableLinks}
                            />
                        ))
                    }
                    {
                        selectedTab === "past" &&
                        showLoadMorePastAssignments &&
                        <button className="btn btn-primary" style={{display: "block", margin: "0 auto"}}
                                onClick={loadMorePastAssignments}>
                            {
                                loadingPastAssignments ? <ButtonLoading/> : "Load More"
                            }
                        </button>
                    }
                </div>
            }
        </div>
    );
}

SeparatedAssignmentsLists.propTypes = {
    loading: PropTypes.bool,
    organization_uuid: PropTypes.string.isRequired,
    disableLinks: PropTypes.bool,
    openAssignments: PropTypes.array.isRequired,
    loadMoreOpenAssignments: PropTypes.func,
    showLoadMoreOpenAssignments: PropTypes.bool,
    loadingOpenAssignments: PropTypes.bool,
    pastAssignments: PropTypes.array.isRequired,
    loadMorePastAssignments: PropTypes.func,
    showLoadMorePastAssignments: PropTypes.bool,
    loadingPastAssignments: PropTypes.bool,
}

export default withRouter(SeparatedAssignmentsLists);