import React from 'react';
import {Modal} from 'antd';

import PostForm, {PostFormTypes} from './PostForm/PostForm.js';


export default function CopyPostModal({post, cancel, callback}) {

    const handleCancel = () => {
        if (cancel) cancel();
    }

    const handlePostCreated = () => {
        if (callback) callback();
        handleCancel();
    }

    return (
        (<Modal
            title={null}
            footer={null}
            open={true}
            closable={false}
            maskClosable={false}
            maskTransitionName="maskTransitionName"
            width={750}
            style={{top: "50px"}}
        >
            <PostForm
                formType={PostFormTypes.COPY_POST}
                postUuidToCopy={post.post_uuid}
                onCancel={handleCancel}
                onPostCreated={handlePostCreated}
            />
        </Modal>)
    );
}
