import {Tag} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';

import {IncidentStatus} from 'other/Constants';

export default function IncidentStatusTag({incident}) {
    const {status} = incident;

    const style = {
        fontWeight: 'normal',
        verticalAlign: 'bottom'
    }

    switch (status) {
        case IncidentStatus.DRAFT:
            return <Tag style={style}>Draft</Tag>
        case IncidentStatus.OPEN:
            return <Tag style={style}>Open</Tag>
        case IncidentStatus.IN_REVIEW:
            return <Tag style={style} color="gold">In Review</Tag>
        case IncidentStatus.CLOSED:
            return <Tag style={style} color="green"><CheckCircleOutlined/> Closed</Tag>
        default:
            return <Tag>N/A</Tag>
    }
}
