import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {Modal} from 'antd';

import NotificationAlert from '../../other/NotificationAlert.js';
import ButtonLoading from '../Shared/ButtonLoading';

import {delete_incident} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet.js';

import '../../assets/css/antd-custom.css';

class DeleteIncidentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.deleteIncident = this.deleteIncident.bind(this);

    }

    componentDidMount() {

    }

    deleteIncident() {
        var self = this;
        if (!this.state.deleting) {
            this.setState({deleting: true});
            delete_incident(this.props.incident.incident_uuid).then(function (success) {
                self.props.successCallback();
                self.props.cancel();
                NotificationAlert("success", "", "Incident deleted.");
            }, function (error) {
                self.setState({deleting: false});
                self.props.cancel();
                NotificationAlert("success", "", "An error occurred. Unable to delete incident.");
            });
        }

    }

    render() {
        return (
            (<Modal
                title="Confirmation"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.deleteIncident}>
                            {
                                this.state.deleting ? <ButtonLoading/> : "Delete"
                            }
                        </button>
                    </div>
                }
            >
                <p>Are you sure you want to delete this incident? This action cannot be undone.</p>
                <p>Case Number: {this.props.incident.case_number}</p>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        user: safe_get(store, "user.user", {}),
        incident_locations: safe_get(store, "incidents.locations", [])
    }
}

export default withRouter(connect(mapStateToProps)(DeleteIncidentModal));