import React, {Component} from 'react';
import {connect} from 'react-redux'

import {
    severity_levels,
    update_severity_levels,
    toggle_risklevel_enabled,
    update_post_field_names
} from '../../../api/zero-api.js'
import {safe_get} from '../../../other/Helper.js'

import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Switch} from 'antd';
import {EditOutlined} from '@ant-design/icons';

import ToggleRisk from '../../Shared/ToggleRisk';
import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import ButtonLoading from '../../Shared/ButtonLoading.js';

import NotificationAlert from '../../../other/NotificationAlert'

import * as organizationAction from '../../../store/actions/OrganizationActions'
import DelayedTextInput from 'components/Shared/DelayedTextInput.js';

class ManageRiskSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            editMode: false,
            riskEnabled: true,
            severity_levels: [],
            activeRisk: {
                value: 0,
                description: ""
            },
            custom_field_name: this.props.organization.post_field_names.severity_level
        };

        this.onClickRisk = this.onClickRisk.bind(this);
        this.editRisk = this.editRisk.bind(this);
        this.riskInputChange = this.riskInputChange.bind(this);
        this.save = this.save.bind(this);

    }

    componentDidMount() {
        var self = this;
        this.setState({riskEnabled: this.props.organization.risk_level_enabled});

        severity_levels("").then(function (success) {
            success.json().then(success => {
                var severity_levels = safe_get(success, "severity_levels", [])
                self.setState({
                    severity_levels: severity_levels,
                    activeRisk: severity_levels[0],
                    loading: false
                });
            });
        }, function (error) {
            console.log(error);
            self.setState({loading: false});
        });
    }

    fieldNameChange = (value) => {
        this.setState(
            {custom_field_name: value},
            () => {
                this.saveFieldName()
            }
        );
    }

    saveFieldName = () => {
        let self = this;
        let body = JSON.stringify({
            ...this.props.organization.post_field_names,
            severity_level: this.state.custom_field_name
        });

        update_post_field_names(body).then(success => {
            success.json().then(success => {
                let org = safe_get(success, "organization", {});
                self.props.dispatch(organizationAction.update_current_org(org));
                self.setState({editFieldName: false});
                NotificationAlert("success", "", "Changes saved.");
            });
        }, error => {

        });
    }


    onClickRisk(risk) {
        this.setState({
            activeRisk: risk,
            editMode: false
        });
    }

    editRisk() {
        this.setState({
            editMode: true
        });
    }

    riskInputChange(e) {
        this.setState({
            newRiskDescription: e.target.value
        });
    }

    toggleRiskEnabled = (value) => {
        let self = this;

        this.setState({riskEnabled: value})

        toggle_risklevel_enabled().then(function (success) {
            success.json().then(success => {
                let organization = safe_get(success, "organization", {});
                self.props.dispatch(organizationAction.update_current_org(organization));
                NotificationAlert("success", "", "Changes saved.");
            })
        }, function (error) {
            // NotificationAlert("error", "", "Unable to update access.")
        });
    }

    save() {
        var self = this;
        this.setState({saving: true});

        var body = JSON.stringify({
            severity_levels: [
                {
                    value: this.state.activeRisk.value,
                    description: this.state.newRiskDescription
                }
            ]
        })

        update_severity_levels(body).then(function (success) {
            success.json().then(success => {
                var severity_levels = safe_get(success, "severity_levels", []);

                var updated_risk = severity_levels.find(function (obj) {
                    return obj.value === self.state.activeRisk.value
                });

                self.setState({
                    saving: false,
                    severity_levels: severity_levels,
                    activeRisk: updated_risk,
                    editMode: false
                });
            });
        }, function (error) {
            console.log(error);
        });

    }

    render() {
        if (this.state.loading) {
            return (
                <LoadingIndicator/>
            )
        } else {
            return (
                <div>
                    <div>
                        <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
                    </div>
                    <div style={{marginBottom: "5px"}}>

                        <div style={{display: "inline-block"}}>
                            <DelayedTextInput
                                className="form-control topic-field"
                                defaultValue={this.state.custom_field_name}
                                onChange={this.fieldNameChange}
                                showCharacterCounter={true}
                                maxLength={15}
                            />
                        </div>

                        <Switch
                            checked={this.state.riskEnabled}
                            onChange={this.toggleRiskEnabled}
                            style={{marginTop: '6px', marginLeft: '10px', verticalAlign: "top"}}
                            checkedChildren={<CheckOutlined/>}
                            unCheckedChildren={<CloseOutlined/>}
                        />
                    </div>

                    <div style={{opacity: (this.state.riskEnabled ? "1" : "0.4"), marginTop: "10px"}}>
                        {
                            this.state.severity_levels.map((risk, index) => (
                                <ToggleRisk key={index} risk={risk} name={risk.name} description={risk.description}
                                            onClickRisk={this.onClickRisk}
                                            active={this.state.activeRisk.value === risk.value}/>
                            ))
                        }
                        {
                            !this.state.editMode &&
                            <div>
                                <p className="zero-dark-grey">{this.state.activeRisk.description}</p>
                                <button className="ButtonLink post-details-content" style={{
                                    textDecoration: "underline",
                                    fontWeight: '500',
                                    verticalAlign: "baseline"
                                }} disabled={!this.state.riskEnabled} onClick={this.editRisk}>Edit description
                                </button>
                            </div>
                        }
                        {
                            this.state.editMode &&
                            <div>
                                <textarea type="text" placeholder="Risk description" className="form-control risk"
                                          style={{marginBottom: "10px"}} row={2}
                                          defaultValue={this.state.activeRisk.description}
                                          onChange={this.riskInputChange} maxLength={250}/>
                                <button className="btn btn-discard"
                                        style={{display: 'inline-block', marginLeft: "0px", padding: "5px 10px"}}
                                        onClick={() => {
                                            this.setState({editMode: false})
                                        }}>Cancel
                                </button>
                                <button className="btn btn-primary"
                                        style={{display: 'inline-block', marginLeft: '5px', padding: "5px 10px"}}
                                        onClick={this.save}>
                                    {
                                        this.state.saving ? <ButtonLoading/> : "Save"
                                    }
                                </button>
                            </div>
                        }

                    </div>
                </div>

            );
        }


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        feed_tags: safe_get(store, "feed_helper.tags", []),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(ManageRiskSettings);
