import React, {Component} from 'react';
import {connect} from 'react-redux'

import {kiosk_get_sessions} from '../../../api/zero-api.js';
import {safe_get, scrollToTop, mainContentContainerClass} from '../../../other/Helper.js'

import OrgAccount from './OrgAccount.js';
import FeatureSettings from './FeatureSettings.js';
import PostSettings from './PostSettings.js';
import IncidentSettings from './IncidentSettings.js';
import FormSettings from './FormSettings.js';

import NewPostModal from '../../Bulletin/NewPostModal.js';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "account",
            settingsPage: "account",
        };

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.tab !== prevProps.match.params.tab) {
            this.setState({
                activeTab: safe_get(this.props, "match.params.tab", "account"),
                settingsPage: safe_get(this.props, "match.params.tab", "account")
            });
        }
    }

    componentDidMount() {
        scrollToTop("page-head");
        this.setState({
            activeTab: safe_get(this.props, "match.params.tab", "account"),
            settingsPage: safe_get(this.props, "match.params.tab", "account")
        });
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    }


    render() {
        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.showPostPreview &&
                    <NewPostModal
                        show={this.state.showPostPreview}
                        cancel={() => {
                            this.setState({showPostPreview: false});
                        }}
                        isPreview={true}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: '850px', margin: '0 auto'}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                {
                                                    this.state.settingsPage === "feed" ? "Post Settings" :
                                                        this.state.settingsPage === "forms" ? "Form Settings" :
                                                            "Settings"
                                                }
                                            </h3>
                                            {
                                                this.state.settingsPage === "feed" &&
                                                <button className="btn btn-xs btn-default expand-button pull-right"
                                                        onClick={() => {
                                                            this.setState({showPostPreview: true});
                                                        }}>Preview</button>
                                            }
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            {
                                                this.state.settingsPage === "feed" ? "Customize the post layout for your account. This page controls what everyone will see when creating a post." :
                                                    this.state.settingsPage === "forms" ? "Manage your form settings below." :
                                                        "Manage your global settings for ZERO."
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div className="tab-base " style={{maxWidth: '850px', margin: '0 auto'}}>
                                {
                                    this.state.settingsPage !== "feed" && this.state.settingsPage !== "forms" &&
                                    <ul className="nav nav-tabs">
                                        {
                                            this.state.settingsPage === "account" &&
                                            <>
                                                <li className={this.state.activeTab === "account" ? "active" : ""}>
                                                    <button className="ButtonLink" onClick={() => {
                                                        this.changeTab("account");
                                                    }}>Account Settings
                                                    </button>
                                                </li>
                                                <li className={this.state.activeTab === "feature" ? "active" : ""}>
                                                    <button className="ButtonLink" onClick={() => {
                                                        this.changeTab("feature");
                                                    }}>Feature Visibility
                                                    </button>
                                                </li>
                                            </>
                                        }
                                        {
                                            this.state.settingsPage === "incident" &&
                                            <>
                                                <li className={this.state.activeTab === "incident" ? "active" : ""}>
                                                    <button className="ButtonLink" onClick={() => {
                                                        this.changeTab("incident");
                                                    }}>Settings
                                                    </button>
                                                </li>
                                                <li className={this.state.activeTab === "incident:access" ? "active" : ""}>
                                                    <button className="ButtonLink" onClick={() => {
                                                        this.changeTab("incident:access");
                                                    }}>Access
                                                    </button>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                }


                                <div className="tab-content" style={{border: "1px solid #e2e2e2"}}>
                                    <div className="tab-pane fade active in">
                                        {
                                            this.state.activeTab === "account" &&
                                            <OrgAccount/>
                                        }
                                        {
                                            this.state.activeTab === "feature" &&
                                            <FeatureSettings/>
                                        }
                                        {
                                            this.state.activeTab === "feed" &&
                                            <PostSettings/>
                                        }
                                        {
                                            this.state.activeTab.startsWith("incident") &&
                                            <IncidentSettings activeTab={this.state.activeTab}/>
                                        }
                                        {
                                            this.state.activeTab === "forms" &&
                                            <FormSettings/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(Settings);

// export default Account;