import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useZeroContext } from 'components/ZeroContext.js';
import { useCurrentUser } from 'hooks/reduxHooks.js';
import { generateUUID, isPublicUser } from 'other/Helper.js';
import ConfirmModal from '../Shared/ConfirmModal.js';
import NewAttachmentCommentBox from '../Shared/NewAttachmentCommentBox.js';

export default function FieldComment({
    initialAttachments,
    initialComment,
    onCancel,
    onSave,
    submissionId: propsSubmissionId,
    formId,
    fieldId,
}) {
    const context = useZeroContext();
    const user = useCurrentUser();
    const {submission_uuid: paramsSubmissionId} = useParams();

    const [comment, setComment] = useState(initialComment ?? "");
    const [attachments, setAttachments] = useState(initialAttachments ?? []);
    const [attachmentIndexToDelete, setAttachmentIndexToDelete] = useState(null);
    const showDeleteConfirmation = attachmentIndexToDelete !== null;

    const isOfflineRoute = window.location.pathname.includes('/offline/');
    const submissionId = propsSubmissionId || paramsSubmissionId; 

    const handleDeleteConfirmation = () => {
        const newAttachments = [...attachments];
        const deletedAttachments = newAttachments.splice(attachmentIndexToDelete, 1);
        if (isOfflineRoute && deletedAttachments.length > 0) {
            context.services.forms.deleteLocalSubmissionAttachment(submissionId, deletedAttachments[0].attachment_uuid);
        }
        setAttachments(newAttachments);
        setAttachmentIndexToDelete(null);
    };

    const handleRemove = (index) => setAttachmentIndexToDelete(index);
    
    const generateParamsCallback = file =>  ({
        feature_key: "forms_submission",
        form_uuid: formId,
        field_uuid: fieldId,
        content_type: file.type,
        file_name: file.name,
        embedded: false
    });

    const onUploadSuccess = (file, preSignedUrl, fileKey) => {
        const newAttachment = {
            file_path: fileKey,
            file_name: file.name,
            public_url: preSignedUrl + "/" + fileKey,
            mime_type: file.type,
        }

        setAttachments(attachments => [...attachments, newAttachment]);
    };

    const onUploadError = (error) => {
        console.error(error);
    };

    const customUploadHandler = useMemo(() => {
        if (isPublicUser(user)) {
            return (file) => {
                const attachmentId = generateUUID();
                const url = URL.createObjectURL(file)
                const attachment = {
                    attachment_uuid: attachmentId,
                    file_path: url,
                    file_name: file.name,
                    public_url: url,
                    mime_type: file.type,
                    file: file,
                };

                setAttachments(attachments => [...attachments, attachment]);
            }
        } else if (submissionId && context.caches.submissionDrafts.drafts.filter(draft => draft._id === submissionId).length > 0) {
            return async (file) => {
                const attachment = await context.services.forms.drafts.saveOfflineAttachment(submissionId, file);
                setAttachments(attachments => [...attachments, attachment]);
            }
        }

        return null;
    }, [user, submissionId, context]);

    return (
        <div className="clearfix">
            { showDeleteConfirmation &&
                <ConfirmModal
                    show={showDeleteConfirmation}
                    cancel={() => setAttachmentIndexToDelete(null)}
                    confirm={handleDeleteConfirmation}
                    title={"Confirmation"}
                    body={"Are you sure you want to delete this attachment?"}
                    confirmButtonName={"Delete"}
                />
            }
            <div style={{marginTop: '12px'}}>
                <div className="comments">
                    <NewAttachmentCommentBox 
                        attachments={attachments}
                        textValue={comment}
                        onTextChange={(value) => setComment(value)}
                        buttonArea={(ctx) => (
                            <div style={{marginTop: "5px"}}>
                                <button
                                    className="btn btn-discard"
                                    style={{padding: "6px 12px", fontSize: "12px", marginLeft: "0px"}}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary"
                                    style={{padding: "6px 12px", fontSize: "12px", marginLeft: "8px"}}
                                    disabled={ctx.fileList.length > 0 || (attachments.length === 0 && comment.length === 0)}
                                    onClick={() => {onSave(comment, attachments)}}
                                >
                                    Save
                                </button>
                            </div>
                        )}
                        onPreview={() => {}}
                        onDelete={handleRemove}
                        generateParamsCallback={generateParamsCallback}
                        onUploadSuccess={onUploadSuccess}
                        onUploadError={onUploadError}
                        customUpload={customUploadHandler}
                    />
                </div>
            </div>
        </div>
    );
}
