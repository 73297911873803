import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    beforeFileUpload, fileHasZeroSize, fileUpload
} from '../../../other/Helper.js';
import safe_get from '../../../other/SafeGet.js';

import NotificationAlert from '../../../other/NotificationAlert.js';
import Previewer from '../../Previewer';

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import AttachmentList from 'components/Shared/AttachmentList.js';
import ConfirmModal from '../../Shared/ConfirmModal.js';
import CorrectiveActionTextModal from './CorrectiveActionTextModal.js';

const MAX_ATTACHMENT_COUNT = 25;

class IncidentAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideCreatePostIndexes: [],
            corrective_actions: [],
            uploaded_files: this.props.attachments.length,
            fileList: [],
            attachments: this.props.attachments,
            related_post: this.props.related_post,
            showCreatePost: true,
        }

        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleFileSubmit = this.handleFileSubmit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    beforeUpload(file, fileList) {
        if (fileHasZeroSize(file)) {
            return false;
        }

        let [allow, error_msg] = beforeFileUpload(file, fileList, this.props.attachments.length);

        if (allow) {
            return true;
        } else {
            this.setState({uploadError: error_msg});
            // this.props.uploadError(error_msg)
            return false;
        }
    }

    handleFileSubmit(file) {
        var self = this;

        var uploading_file = {
            uid: file.file.uid,
            status: "uploading",
            percent: 0
        }

        this.setState(prevState => ({fileList: [...prevState.fileList, uploading_file]}));

        var params = JSON.stringify({
            feature_key: "incident",
            incident_uuid: this.props.incident_uuid,
            content_type: file.file.type || 'application/octet-stream',
            file_name: file.file.name,
            embedded: false
        });

        fileUpload(this, params, file, function (progress) {
            var fileList = [...self.state.fileList];
            var index = fileList.indexOf(uploading_file);

            if (index >= 0) {
                var file = fileList[index];
                file["percent"] = progress;
                fileList[index] = file;

                self.setState({fileList: fileList});
            }
        }, function (pre_signed_url, file_key) {
            var fileList = [...self.state.fileList];
            var index = fileList.indexOf(uploading_file);

            if (index >= 0) {
                fileList.splice(index, 1);
            }

            self.setState({
                fileList: fileList,
                uploadError: "",
            });

            self.handleAdd(file, pre_signed_url, file_key);
            NotificationAlert("", "", "File uploaded.");

        }, function (error) {
            var errorMsg = "An Error occured. Unable to upload file.";
            if (error.toString().includes("aborted")) {
                errorMsg = "Upload canceled.";
            }
            self.setState({uploadError: errorMsg, fileList: []});
        });

    }

    handleAdd(file, pre_signed_url, file_key) {
        var returned_file = {}
        returned_file["file_path"] = file_key;
        returned_file["file_name"] = file.file.name
        returned_file["public_url"] = pre_signed_url + "/" + file_key;
        returned_file["embedded"] = false;
        returned_file["mime_type"] = file.file.type || 'application/octet-stream';

        setTimeout(() => {
            var attachments = [...this.props.attachments]
            attachments.push(returned_file);
            this.props.updateAttachments(attachments)
        }, 200);

    }

    handleRemove(index) {
        this.setState({
            showDeleteConfirmation: true,
            delete_index: index
        });
    }

    confirmDelete() {
        var attachments = this.props.attachments;
        attachments.splice(this.state.delete_index, 1);
        this.props.updateAttachments(attachments);
        this.setState({
            uploaded_files: this.state.uploaded_files - 1,
            showDeleteConfirmation: false
        });
    }

    handlePreview = (index) => {
        this.setState({preview_visible: true, preview_index: index});
    }

    render() {
        const {fieldsDisabled} = this.props;

        return (
            <div>
                {
                    this.state.showDeleteConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteConfirmation}
                        cancel={() => {
                            this.setState({showDeleteConfirmation: false});
                        }}
                        confirm={this.confirmDelete}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this attachment?"}
                        confirmButtonName={"Delete"}
                    />
                }

                {
                    this.state.showDeleteActionConfirmation &&
                    <ConfirmModal
                        show={this.state.showDeleteActionConfirmation}
                        cancel={() => {
                            this.setState({showDeleteActionConfirmation: false});
                        }}
                        confirm={this.deleteAction}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this corrective action?"}
                        confirmButtonName={"Delete"}
                    />
                }


                {
                    this.state.showCorrectiveActionModal &&
                    <CorrectiveActionTextModal
                        show={this.state.showCorrectiveActionModal}
                        cancel={() => {
                            this.setState({showCorrectiveActionModal: false});
                        }}
                        selectedAction={this.state.selectedCorrectiveAction}
                        saveAction={this.saveCorrectiveAction}
                        addAction={this.addAction}
                    />
                }

                <h3 className="zero-blue print-only"
                    style={{marginBottom: "20px", marginTop: "5px", fontSize: "120%", textDecoration: "underline"}}>
                    Attachments
                </h3>

                {
                    this.state.uploadError &&
                    <small className="error" style={{marginTop: "10px"}}>{this.state.uploadError}</small>
                }

                {
                    !fieldsDisabled && this.props.attachments.length < MAX_ATTACHMENT_COUNT &&
                    <Upload.Dragger
                        action="/"
                        multiple={true}
                        showUploadList={false}
                        accept={""}
                        beforeUpload={this.beforeUpload}
                        onRemove={this.handleRemove}
                        customRequest={this.handleFileSubmit}
                        className={"library-upload mar-top dont-print"}>
                        <div>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="zero-dark-grey">Click or drag file to this
                                area to upload</p>
                        </div>
                    </Upload.Dragger>
                }

                {this.props.isClosed && this.props.attachments.length === 0 &&
                    <p className="mar-btm-0 zero-dark-grey">No attachments.</p>
                }

                <div>
                    <AttachmentList
                        attachments={this.props.attachments}
                        uploadingFileList={this.state.fileList}
                        className="mar-top-10"
                        onPreview={index => this.handlePreview(index)}
                        onDelete={index => this.handleRemove(index)}
                    />
                    {
                        this.props.attachments.length >= MAX_ATTACHMENT_COUNT &&
                        <p className="ant-upload-text" style={{color: "#fc4c56"}}>File limit
                            reached</p>

                    }
                    {
                        this.state.preview_visible &&
                        <Previewer
                            show={this.state.preview_visible}
                            close={() => {
                                this.setState({preview_visible: false})
                            }}
                            attachments={this.props.attachments}
                            index={this.state.preview_index}
                        />
                    }
                </div>


            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        all_assignments: safe_get(store, "assignments", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(IncidentAttachments));

