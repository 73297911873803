import React, { Component } from "react";
import { connect } from "react-redux";

import safe_get from "../../../other/SafeGet.js";

import $ from "jquery";
import moment from "moment";
import Autocomplete from "components/Shared/GoogleMapsAutocomplete.js";

import { Select, Radio, DatePicker, TimePicker, Checkbox } from "antd";
import dayjs from "dayjs";

class CaseInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_location_street: "",
      event_location_city: "",
      event_location_state: "",
      event_location_zip: "",
    };

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInjuryDate = this.handleInjuryDate.bind(this);
    this.handleDoDChange = this.handleDoDChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleWorkTime = this.handleWorkTime.bind(this);
    this.handleEventTime = this.handleEventTime.bind(this);
    this.handleTimeDetermined = this.handleTimeDetermined.bind(this);
    this.eventTypeSelect = this.eventTypeSelect.bind(this);
  }

  componentDidMount() {
    let event_location = this.props.event_location;
    if (event_location.length > 0) {
      let parts = event_location.split(", ");
      $("#event_location_street_address").val(parts[0]);
      this.setState({
        event_location_street: parts[0],
        event_location_city: parts[1],
        event_location_state: parts[2],
        event_location_zip: parts[3],
      });
    }
  }

  handleLocationChange(value) {
    this.props.onChange("location_uuid", value);
  }

  handleAddressSelect = (location) => {
    let address_components = safe_get(location, "address_components", []);

    var street = "";
    var city = "";
    var state = "";
    var zip = "";

    for (var i in address_components) {
      let item = address_components[i];
      let types = safe_get(item, "types", []);

      if (types.indexOf("street_number") >= 0) {
        street = street + safe_get(item, "long_name", "");
      } else if (types.indexOf("route") >= 0) {
        street = street + " " + safe_get(item, "short_name", "");
      } else if (types.indexOf("locality") >= 0) {
        city = safe_get(item, "short_name", "");
      } else if (types.indexOf("administrative_area_level_1") >= 0) {
        state = safe_get(item, "short_name", "");
      } else if (types.indexOf("postal_code") >= 0) {
        zip = safe_get(item, "long_name", "");
      }
    }

    $("#event_location_street_address").val(street);

    this.setState({
      event_location_street: street,
      event_location_city: city,
      event_location_state: state,
      event_location_zip: zip,
    });

    this.props.onChange(
      "event_location",
      `${street}, ${city}, ${state}, ${zip}`
    );
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    if (name.includes("event_location")) {
      var newState = {};
      newState[name] = value;
      this.setState(newState, () => {
        this.props.onChange(
          "event_location",
          `${this.state.event_location_street}, ${this.state.event_location_city}, ${this.state.event_location_state}, ${this.state.event_location_zip}`
        );
      });
    } else {
      this.props.onChange(name, value);
    }
  }

  handleInjuryDate(value) {
    var date = null;
    if (value) {
      date = value.format("YYYY-MM-DD");
    }
    this.props.onChange("date_of_injury", date);
  }

  disabledDate(current) {
    let tomorrow = moment();
    return current > tomorrow.endOf("day");
  }

  handleDoDChange(value) {
    var date = null;
    if (value) {
      date = value.format("YYYY-MM-DD");
    }
    this.props.onChange("date_of_death", date);
  }

  handleWorkTime(value, timeString) {
    var time = null;
    if (value) {
      time = value.format("HH:mm");
    }
    this.props.onChange("time_began_work", time);
  }

  handleEventTime(value) {
    var time = null;
    if (value) {
      time = value.format("HH:mm");
      this.props.onChange("event_time_determined", true);
    }
    this.props.onChange("event_time", time);
  }

  handleTimeDetermined(event) {
    var value = event.target.checked;
    this.props.onChange("event_time_determined", !value);
    if (value) {
      this.props.onChange("event_time", undefined);
    }
  }

  handleRadioChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.props.onChange(name, value);
  }

  eventTypeSelect(value) {
    this.props.onChange("event_type_uuid", value);
  }

  customField1Select = (value) => {
    this.props.onChange("custom_field_1_uuid", value);
  };

  customField2Select = (value) => {
    this.props.onChange("custom_field_2_uuid", value);
  };

  customField3Select = (value) => {
    this.props.onChange("custom_field_3_uuid", value);
  };
  customField4Select = (value) => {
    this.props.onChange("custom_field_4_uuid", value);
  };

  resetCaseRecordable() {
    this.setState({ incident_type: undefined });
    this.props.onChange("incident_type", null);
  }

  render() {
    const { fieldsDisabled } = this.props;

    return (
      <>
        <h3
          className="zero-blue print-only"
          style={{
            marginBottom: "20px",
            marginTop: "5px",
            fontSize: "120%",
            textDecoration: "underline",
          }}
        >
          Case Info
        </h3>

        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{
              marginBottom: "5px",
              marginTop: "0px",
              fontSize: "100%",
              width: "50%",
            }}
          >
            Location*
          </h3>
          <div style={{ height: "38px" }}>
            <Select
              disabled={fieldsDisabled || !this.props.isDraft}
              style={{
                maxWidth: "300px",
                width: "100%",
                float: "left",
                marginTop: "3px",
              }}
              placeholder="Select a location"
              value={this.props.location_uuid}
              onChange={this.handleLocationChange}
              className="custom-bulletin"
              virtual={false}
            >
              {this.props.incident_locations.map((location, index) => (
                <Select.Option key={index} value={location.location_uuid}>
                  {location.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          {!fieldsDisabled &&
            !(
              this.state.add_address || this.props.event_location.length > 0
            ) && (
              <p
                className="blue-link mar-top-10 mar-btm-0 dont-print"
                style={{ fontWeight: "500" }}
                onClick={() => {
                  this.setState({ add_address: true });
                }}
              >
                Add address
              </p>
            )}
        </div>

        {(this.state.add_address || this.props.event_location.length > 0) && (
          <div className="row mar-btm-15">
            <div className="col-md-5">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                  width: "50%",
                }}
              >
                Street
              </h3>
              <Autocomplete
                id="event_location_street_address"
                className="form-control custom-placeholder"
                style={{ width: "100%" }}
                onPlaceSelected={this.handleAddressSelect}
                onChange={this.handleInputChange}
                types={["address"]}
                name="event_location_street"
                componentRestrictions={{ country: ["us", "ca"] }}
                disabled={fieldsDisabled}
              />
              {this.state.street_address_error && (
                <small className="error">
                  {this.state.street_address_error}
                </small>
              )}
            </div>
            <div className=" col-md-3">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                  width: "50%",
                }}
              >
                City
              </h3>
              <input
                name="event_location_city"
                className="form-control custom-placeholder"
                placeholder=""
                onChange={this.handleInputChange}
                value={this.state.event_location_city}
                disabled={fieldsDisabled}
              />
            </div>
            <div className=" col-md-2">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                  width: "50%",
                }}
              >
                State
              </h3>
              <input
                name="event_location_state"
                className="form-control custom-placeholder"
                placeholder=""
                onChange={this.handleInputChange}
                value={this.state.event_location_state}
                disabled={fieldsDisabled}
              />
            </div>
            <div className=" col-md-2">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                  width: "50%",
                }}
              >
                ZIP
              </h3>
              <input
                name="event_location_zip"
                className="form-control custom-placeholder"
                placeholder=""
                onChange={this.handleInputChange}
                value={this.state.event_location_zip}
                disabled={fieldsDisabled}
              />
            </div>
          </div>
        )}
        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{
              marginBottom: "5px",
              marginTop: "0px",
              fontSize: "100%",
              width: "50%",
            }}
          >
            Case Type
          </h3>
          <div>
            <Radio.Group
              disabled={fieldsDisabled}
              name="incident_type"
              onChange={this.handleRadioChange}
              value={this.props.incident_type}
            >
              {this.props.incident_types.map((type, index) => (
                <Radio
                  className="zero-dark-grey"
                  key={index}
                  value={type.value}
                >
                  {type.name}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          {!fieldsDisabled && (
            <small
              className="blue-link dont-print"
              onClick={this.resetCaseRecordable.bind(this)}
            >
              Clear
            </small>
          )}
          {this.state.incident_type_error && (
            <small className="error">{this.state.incident_type_error}</small>
          )}
        </div>
        {this.props.event_types.length > 0 &&
          !this.props.event_types_disabled && (
            <div className="mar-btm-15">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                  width: "50%",
                }}
              >
                Event Type
              </h3>
              <div style={{ height: "38px" }}>
                <Select
                  disabled={fieldsDisabled}
                  className="custom-bulletin"
                  style={{
                    maxWidth: "300px",
                    width: "100%",
                    float: "left",
                    marginTop: "3px",
                  }}
                  name="event_type_uuid"
                  value={this.props.event_type_uuid}
                  onChange={this.eventTypeSelect}
                  virtual={false}
                >
                  {this.props.event_types
                    .filter(
                      (type) =>
                        type.enabled ||
                        type.event_type_uuid === this.props.event_type_uuid
                    )
                    .map((event_type, index) => (
                      <Select.Option
                        key={index}
                        value={event_type.event_type_uuid}
                        disabled={!event_type.enabled}
                      >
                        {event_type.name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </div>
          )}
        {this.props.customField1?.enabled && (
          <div className="mar-btm-15">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              {this.props.customField1.name}
            </h3>
            <div style={{ height: "38px" }}>
              <Select
                disabled={fieldsDisabled}
                className="custom-bulletin"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  float: "left",
                  marginTop: "3px",
                }}
                name="custom_field_1_uuid"
                value={this.props.custom_field_1_uuid}
                onChange={this.customField1Select}
                virtual={false}
              >
                {this.props.customField1.options
                  .filter(
                    (option) =>
                      option.enabled ||
                      option.uuid === this.props.custom_field_1_uuid
                  )
                  .map((option) => (
                    <Select.Option
                      key={option.uuid}
                      value={option.uuid}
                      disabled={!option.enabled}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        )}

        {this.props.customField2?.enabled && (
          <div className="mar-btm-15">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              {this.props.customField2.name}
            </h3>
            <div style={{ height: "38px" }}>
              <Select
                disabled={fieldsDisabled}
                className="custom-bulletin"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  float: "left",
                  marginTop: "3px",
                }}
                name="custom_field_2_uuid"
                value={this.props.custom_field_2_uuid}
                onChange={this.customField2Select}
                virtual={false}
              >
                {this.props.customField2.options
                  .filter(
                    (option) =>
                      option.enabled ||
                      option.uuid === this.props.custom_field_2_uuid
                  )
                  .map((option) => (
                    <Select.Option
                      key={option.uuid}
                      value={option.uuid}
                      disabled={!option.enabled}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        )}

        {this.props.customField3?.enabled && (
          <div className="mar-btm-15">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              {this.props.customField3.name}
            </h3>
            <div style={{ height: "38px" }}>
              <Select
                disabled={fieldsDisabled}
                className="custom-bulletin"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  float: "left",
                  marginTop: "3px",
                }}
                name="custom_field_3_uuid"
                value={this.props.custom_field_3_uuid}
                onChange={this.customField3Select}
                virtual={false}
              >
                {this.props.customField3.options
                  .filter(
                    (option) =>
                      option.enabled ||
                      option.uuid === this.props.custom_field_3_uuid
                  )
                  .map((option) => (
                    <Select.Option
                      key={option.uuid}
                      value={option.uuid}
                      disabled={!option.enabled}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        )}

        {this.props.customField4?.enabled && (
          <div className="mar-btm-15">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              {this.props.customField4.name}
            </h3>
            <div style={{ height: "38px" }}>
              <Select
                disabled={fieldsDisabled}
                className="custom-bulletin"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  float: "left",
                  marginTop: "3px",
                }}
                name="custom_field_4_uuid"
                value={this.props.custom_field_4_uuid}
                onChange={this.customField4Select}
                virtual={false}
              >
                {this.props.customField4.options
                  .filter(
                    (option) =>
                      option.enabled ||
                      option.uuid === this.props.custom_field_4_uuid
                  )
                  .map((option) => (
                    <Select.Option
                      key={option.uuid}
                      value={option.uuid}
                      disabled={!option.enabled}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        )}

        <div className="mar-btm-15">
          <h3
            className="titles onboarding required invite-link mt-0"
            style={{ marginBottom: "5px", marginTop: "0px", fontSize: "100%" }}
          >
            Date of Event
          </h3>
          <div>
            <DatePicker
              className="custom-range-picker"
              placeholder="MM/DD/YYYY"
              format={"MM/DD/YYYY"}
              value={
                this.props.date_of_injury
                  ? dayjs(this.props.date_of_injury, "YYYY-MM-DD")
                  : null
              }
              onChange={this.handleInjuryDate}
              style={{ width: "200px" }}
              allowClear={false}
              disabled={fieldsDisabled}
            />
          </div>
          {this.state.date_of_injury_error && (
            <small className="error">{this.state.date_of_injury_error}</small>
          )}
        </div>

        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{ marginBottom: "5px", marginTop: "0px", fontSize: "100%" }}
          >
            Time employee began work
          </h3>
          <div>
            <TimePicker
              onChange={this.handleWorkTime}
              value={
                this.props.time_began_work
                  ? dayjs(this.props.time_began_work, "HH:mm")
                  : null
              }
              format={"h:mm A"}
              style={{ width: "200px" }}
              allowClear={true}
              disabled={fieldsDisabled}
            />
          </div>
          {this.state.time_began_work_error && (
            <small className="error">{this.state.time_began_work_error}</small>
          )}
        </div>

        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{
              marginBottom: "5px",
              marginTop: "0px",
              fontSize: "100%",
              width: "50%",
            }}
          >
            Time of event
          </h3>
          <div>
            <TimePicker
              onChange={this.handleEventTime}
              format={"h:mm A"}
              value={
                this.props.event_time
                  ? dayjs(this.props.event_time, "HH:mm")
                  : null
              }
              style={{ marginRight: "10px", width: "200px" }}
              disabled={fieldsDisabled}
            />
            <Checkbox
              disabled={fieldsDisabled}
              onChange={this.handleTimeDetermined}
              checked={!this.props.event_time_determined}
            >
              Check if time cannot be determined
            </Checkbox>
          </div>
          {this.state.name_error && (
            <small className="error">{this.state.name_error}</small>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: safe_get(store, "user.user", {}),
    current_team: safe_get(store, "teams_helper.team", {}),
    teams: safe_get(store, "teams_helper.teams", []),
    all_assignments: safe_get(store, "assignments", {}),
  };
};

export default connect(mapStateToProps)(CaseInfo);
