import React, {useEffect, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Modal, Select} from 'antd';

import ButtonLoading from '../../Shared/ButtonLoading';
import {isAdminOrTeamLead} from '../../../other/Helper';
import {safeProfilePic} from '../../../other/Helper'

export default function AddManagersModal({users, managers, onCancel, onConfirm, modifying}) {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUserUuids, setSelectedUserUuids] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const organizationUuid = useSelector(state => state.org_helper.organization.organization_uuid);
    const currentUser = useSelector(state => state.user.user);

    useEffect(() => {
        const managerUuids = managers.map(manager => manager.uuid);
        setAvailableUsers(users.filter(user => !managerUuids.includes(user.uuid)));
    }, [users, managers]);

    const onUserChange = (values) => {
        const userUuids = values.map(v => v.value);
        unstable_batchedUpdates(() => {
            setSelectedUsers(values);
            setSelectedUserUuids(userUuids);
        });
    }

    const filterUsers = (inputValue, option) => {
        const search = inputValue.toLowerCase();
        return option.name.toLowerCase().includes(search);
    }

    return (
        (<Modal
            title="Add Managers for Desks"
            open={true}
            maskClosable={false}
            onCancel={onCancel}
            maskTransitionName="maskTransitionName"
            footer={
                <div>
                    <button className="btn btn-discard" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="btn btn-modal" onClick={() => onConfirm(selectedUserUuids)} disabled={modifying}>
                        {
                            modifying ? <ButtonLoading/> : "Add"
                        }
                    </button>
                </div>
            }
        >
            <p className="zero-dark-grey">Managers will be able to access the entire "Manage Desks" page and will be
                able to create, edit, and assign desks as well as create alerts for check-ins.</p>
            <Select
                mode="multiple"
                value={selectedUsers}
                placeholder="Type a name..."
                onChange={onUserChange}
                filterOption={filterUsers}
                style={{width: '100%', display: 'block', margin: '0 auto'}}
                className="user-select-dropdown"
                labelInValue={true}
                notFoundContent={"No users found. Please try again..."}
                dropdownStyle={{zIndex: "10000"}}
                optionLabelProp="user"
                loading={false}
                virtual={false}
            >
                {
                    availableUsers.map((user, index) => (
                        <Select.Option key={"user: " + user.uuid} value={user.uuid} user={
                            <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.first_name} </span>}
                                       name={`${user.first_name} ${user.last_name}`}>
                            {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                            {user.first_name} {user.last_name}
                            {user.email &&
                                <>&bull; <span style={{fontSize: '85%'}}>{user.email || user.title}</span></>
                            }
                        </Select.Option>
                    ))
                }
            </Select>
            {isAdminOrTeamLead(currentUser) &&
                <p className="zero-dark-grey" style={{fontSize: "11px", paddingTop: "2px", fontStyle: "italic"}}>To add
                    people not on ZERO, you'll need to <Link className="link-hover zero-light-blue"
                                                             to={"/" + organizationUuid + "/home/directory?invite=true"}>invite
                        them first.</Link></p>
            }
        </Modal>)
    );
};
