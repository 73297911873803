import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Select, DatePicker, Checkbox} from 'antd';

import {isAdmin} from 'other/Helper';
import {CheckInAlertRepeatPeriod} from 'other/Constants';
import InfoIconComponent from 'components/InfoIconComponent';
import ButtonLoading from 'components/Shared/ButtonLoading';
import {actions as alertsActions, updateAlert, createAlert} from 'store/slices/manageCheckInAlerts';
import dayjs from "dayjs";

export default function CheckInAlertModal({existingAlert}) {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();
    const modifyAlertStatus = useSelector(state => state.manageCheckInAlerts.modifyAlertStatus);
    const {loading, error, ok, data} = modifyAlertStatus;
    const usersTeams = useSelector(state => state.manageCheckInAlerts.teams);

    const [availableTeams, setAvailableTeams] = useState({});
    const [orderedAvailableTeams, setOrderedAvailableTeams] = useState([]);
    const [selectedTeamIds, setSelectedTeamsIds] = useState([]);
    const [repeatPeriod, setRepeatPeriod] = useState(existingAlert?.repeat_period || CheckInAlertRepeatPeriod.DAILY);
    const [startTime, setStartTime] = useState(existingAlert?.start_time || '08:00:00');
    const [startDate, setStartDate] = useState(existingAlert?.start_date || dayjs().format('YYYY-MM-DD'));
    const [rawStartDate, setRawStartDate] = useState(dayjs());
    const [dashboardAlerts, setDashboardAlerts] = useState(existingAlert ? existingAlert.show_dashboard_alerts : true);
    const [emailAlerts, setEmailAlerts] = useState(existingAlert ? existingAlert.send_email_alerts : true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const newAvailableTeams = {};

        for (const team of usersTeams) {
            newAvailableTeams[team.uuid] = team;
        }

        if (existingAlert) {
            setSelectedTeamsIds(existingAlert.subscribed_teams.map(team => team.uuid));

            try {
                const initialRawStartDate = dayjs(existingAlert.start_date, 'YYYY-MM-DD');
                setRawStartDate(initialRawStartDate);
            } catch (err) {
                console.error(err);
            }

            for (const team of existingAlert.subscribed_teams) {
                if (newAvailableTeams[team.uuid]) continue;
                newAvailableTeams[team.uuid] = {...team};
                if (!isAdmin(user)) {
                    newAvailableTeams[team.uuid].disabled = true;
                }
            }
        }

        setAvailableTeams(newAvailableTeams);

        const orderedTeams = Object.values(newAvailableTeams).sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        })
        setOrderedAvailableTeams(orderedTeams);

        return () => {
            dispatch(alertsActions.updateState({modifyAlertStatus: {}}));
        }
    }, []);

    useEffect(() => {
        if (error) {
            const {message} = data[0];
            setErrorMessage(message);
        } else if (errorMessage) {
            setErrorMessage('');
        }
    }, [error]);

    const save = () => {
        const body = {
            enabled: true,
            subscribed_user_uuids: [],
            subscribed_team_uuids: selectedTeamIds,
            repeat_period: repeatPeriod,
            start_time: startTime,
            start_date: startDate,
            show_dashboard_alerts: dashboardAlerts,
            send_email_alerts: emailAlerts
        }

        if (existingAlert) {
            dispatch(updateAlert({uuid: existingAlert.uuid, body}));
        } else {
            dispatch(createAlert({body}));
        }
    }

    const onCancel = () => {
        dispatch(alertsActions.hideModal());
    }

    const onStartDateChange = value => {
        let convertedDate = dayjs(value, 'MMM D, YYYY').format('YYYY-MM-DD');
        if (convertedDate === 'Invalid date') {
            convertedDate = null;
        }

        setRawStartDate(value);
        setStartDate(convertedDate);
    }

    const h3Style = {marginBottom: '2px', marginLeft: '0px', fontSize: '100%', marginTop: "15px"}

    return (
        (<Modal
            title={existingAlert ? 'Edit alert' : 'Create alert'}
            maskClosable={false}
            open={true}
            onCancel={onCancel}
            footer={
                <div>
                    {errorMessage &&
                        <small className="error" style={{display: "block", marginBottom: "5px"}}>{errorMessage}</small>
                    }
                    <button className="btn btn-discard" disabled={loading} onClick={onCancel}>Cancel</button>
                    {
                        loading &&
                        <button className="btn btn-modal">
                            <ButtonLoading/>
                        </button>
                    }
                    {
                        !loading &&
                        <button className="btn btn-modal" onClick={save}>
                            {existingAlert ? "Update alert" : "Create alert"}
                        </button>
                    }
                </div>
            }
        >
            <h3 className="titles required" style={h3Style}>Team</h3>
            <Select
                mode="multiple"
                style={{width: '100%'}}
                dropdownStyle={{zIndex: "10000"}}
                placeholder="Select a team"
                value={selectedTeamIds}
                onChange={setSelectedTeamsIds}
                virtual={false}
                filterOption={true}
                optionFilterProp={'label'}
                options={orderedAvailableTeams.map((team) => ({
                    value: team.uuid,
                    disabled: team.disabled,
                    label: team.name
                }))}
            />
            <h3 className="titles required" style={h3Style}>Schedule</h3>
            <div className="row" style={{padding: '0 0.5rem'}}>
                <div className="col-sm-8 col-xs-8 col-reduce-padding">
                    <Select
                        value={repeatPeriod}
                        onChange={setRepeatPeriod}
                        placeholder="Select a repeat period"
                        dropdownStyle={{zIndex: "10000"}}
                        style={{width: '100%'}}
                        virtual={false}
                    >
                        <Select.Option value={CheckInAlertRepeatPeriod.DAILY}>Daily</Select.Option>
                        <Select.Option value={CheckInAlertRepeatPeriod.WEEKDAY}>Weekdays (M-F)</Select.Option>
                        <Select.Option value={CheckInAlertRepeatPeriod.WEEKEND}>Weekends (Sat/Sun)</Select.Option>
                    </Select>
                </div>
                {/* <div className="visible-xs-block col-xs-12" style={{height: "1rem"}}></div> */}
                <div className="col-sm-4 col-xs-4 col-reduce-padding">
                    <Select
                        value={startTime}
                        onChange={setStartTime}
                        dropdownStyle={{zIndex: "10000"}}
                        style={{width: '100%'}}
                        virtual={false}
                    >
                        <Select.Option value={"00:00:00"}>12 AM</Select.Option>
                        <Select.Option value={"01:00:00"}>1 AM</Select.Option>
                        <Select.Option value={"02:00:00"}>2 AM</Select.Option>
                        <Select.Option value={"03:00:00"}>3 AM</Select.Option>
                        <Select.Option value={"04:00:00"}>4 AM</Select.Option>
                        <Select.Option value={"05:00:00"}>5 AM</Select.Option>
                        <Select.Option value={"06:00:00"}>6 AM</Select.Option>
                        <Select.Option value={"07:00:00"}>7 AM</Select.Option>
                        <Select.Option value={"08:00:00"}>8 AM</Select.Option>
                        <Select.Option value={"09:00:00"}>9 AM</Select.Option>
                        <Select.Option value={"10:00:00"}>10 AM</Select.Option>
                        <Select.Option value={"11:00:00"}>11 AM</Select.Option>
                        <Select.Option value={"12:00:00"}>12 PM</Select.Option>
                        <Select.Option value={"13:00:00"}>1 PM</Select.Option>
                        <Select.Option value={"14:00:00"}>2 PM</Select.Option>
                        <Select.Option value={"15:00:00"}>3 PM</Select.Option>
                        <Select.Option value={"16:00:00"}>4 PM</Select.Option>
                        <Select.Option value={"17:00:00"}>5 PM</Select.Option>
                        <Select.Option value={"18:00:00"}>6 PM</Select.Option>
                        <Select.Option value={"19:00:00"}>7 PM</Select.Option>
                        <Select.Option value={"20:00:00"}>8 PM</Select.Option>
                        <Select.Option value={"21:00:00"}>9 PM</Select.Option>
                        <Select.Option value={"22:00:00"}>10 PM</Select.Option>
                        <Select.Option value={"23:00:00"}>11 PM</Select.Option>
                    </Select>
                </div>
            </div>
            <div className="zero-dark-grey">Emails will send at the specificed time in the user's local timezone.</div>
            <h3 className="titles required" style={h3Style}>Start Date</h3>
            <DatePicker
                placeholder="Start Date"
                className="custom-range-picker"
                style={{width: "100%"}}
                popupStyle={{zIndex: "10000"}}
                value={rawStartDate}
                format={"MMM D, YYYY"}
                onChange={onStartDateChange}
                allowClear={false}
            />
            <h3 className="titles" style={h3Style}>Alert Types</h3>
            <div>
                <Checkbox checked={dashboardAlerts} onChange={e => setDashboardAlerts(e.target.checked)}>
                    Dashboard Alert
                    <InfoIconComponent
                        position={"bottom"}
                        width={"200px"}
                        text="If this box is checked, users in the selected team(s) will see a check-in alert display on their Dashboard."
                    />
                </Checkbox>
            </div>
            <div>
                <Checkbox checked={emailAlerts} onChange={e => setEmailAlerts(e.target.checked)}>
                    Email Alert
                    <InfoIconComponent
                        position={"bottom"}
                        width={"200px"}
                        text="If this box is checked, users in the selected team(s) will receive an email alert at the scheduled time."
                    />
                </Checkbox>
            </div>
        </Modal>)
    );
}