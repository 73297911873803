import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import {
    get_user, get_list_users, get_list_teams,
    get_deactivated_users, resend_invite, deactivate_user,
    activate_user, delete_pending_user, notifyError,
    get_importing_users_async
} from '../../api/zero-api.js'

import ConfirmModal from '../Shared/ConfirmModal';
import ChangeRoleModal from '../Shared/ChangeRoleModal.js';
import ChangeTeamsModal from '../Shared/ChangeTeamsModal.js';
import EditProfileModal from '../Shared/EditProfileModal.js';
import TeamListPopover from '../Shared/TeamListPopover.js';
import InfoIconComponent from '../InfoIconComponent'
import EmailInvite from '../People/EmailInvite';

import {PlusCircleOutlined, ReloadOutlined} from '@ant-design/icons';
import {Select, Dropdown, Menu, Popover, Pagination, Tag, Alert, Spin} from 'antd'

import Skeleton from 'react-loading-skeleton'

import NotificationAlert from '../../other/NotificationAlert';
import {
    safe_get,
    scrollToTop,
    safeProfilePic,
    mainContentContainerClass,
    isAdmin,
    isTeamLead
} from '../../other/Helper.js';

import UsersTable from './UsersTable.js';

import $ from 'jquery';

import * as userActions from '../../store/actions/UserActions'
import * as teamsActions from '../../store/actions/TeamsActions';
// import * as membersFilterActions from '../../store/actions/MembersFilterActions';
import {WebSocketContext} from "other/WebSockets";

var resizeTimer = null;

const USERS_PER_PAGE = 100;

function getPageData(filteredUsers, currentPage) {
    const userCount = filteredUsers.length;
    let firstUserIndexOnPage = (currentPage - 1) * USERS_PER_PAGE;

    if (firstUserIndexOnPage >= userCount && currentPage > 1) {
        currentPage--;
        firstUserIndexOnPage = (currentPage - 1) * USERS_PER_PAGE;
    }

    return [firstUserIndexOnPage, currentPage];
}


async function fetchImportingUsersAsync() {
    try {
        const response = await get_importing_users_async();
        const data = await response.json();
        return safe_get(data, 'importing_users_async', false);
    } catch {
        return false;
    }
}

async function fetchPaginatedUsers() {
    const perPage = 5000;
    const params = new URLSearchParams();
    params.set('include_deactivated', 'true');
    params.set('per_page', perPage.toString());

    const fetchPage = async (page) => {
        params.set('page', page.toString());
        const res = await get_list_users(`?${params}`);
        const data = await res.json();
        return data;
    }

    const { users, total_users: totalUsers } = await fetchPage(1);
    const pageCount = Math.ceil(totalUsers / perPage);
    const queue = [];
    for (let page = 2; page <= pageCount; page++) {
        queue.push((async () => {
            const { users } = await fetchPage(page);
            return users;
        })());
    }
    const results = await Promise.all(queue);
    const allUsers = [...users].concat(...results);
    return allUsers;
}

class ManageMembers extends Component {
    static contextType = WebSocketContext;

    constructor(props) {
        super(props);
        this.state = {
            error_messages: [],
            users: [],
            deactivated_users: [],
            all_users: [],
            filtered_users: [],
            filtered_users_page: [],
            total_users: 0,
            pagination: 1,
            teams_list: [],
            filtered_teams_list: [],
            selected_teams: [],
            peopleFilter: ["status=active"],
            loading_users: true,
            loading_deactivated_users: false,
            tableWidth: 870,
            tableHeight: 500,
            asyncImportingUsers: false,
            filter_active: true,
        };


        this.getUsers = this.getUsers.bind(this);
        this.onChangePeopleFilter = this.onChangePeopleFilter.bind(this);

        this.userFormatter = this.userFormatter.bind(this)
        this.generateMenuOptions = this.generateMenuOptions.bind(this);

        this.handleMenuClick = this.handleMenuClick.bind(this);

        this.roleUpdateCallback = this.roleUpdateCallback.bind(this);

        this.deactivateUser = this.deactivateUser.bind(this);
        this.activateUser = this.activateUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);


        this.inviteCallback = this.inviteCallback.bind(this);

    }

    componentDidMount() {
        scrollToTop("page-head");
        this.context.addMessageHandler('async_user_creation_done', this.handleAsyncUserCreationMessage);

        this.getUsers();
        this.getTeams();
        this.getImportingUsersAsync();

        if (this.props.location.search.includes("invite=true")) {
            setTimeout(() => {
                this.setState({showInviteBlock: true});
            }, 700);
        }
    }

    componentWillUnmount() {
        this.context.removeMessageHandler('async_user_creation_done', this.handleAsyncUserCreationMessage);
    }

    getImportingUsersAsync() {
        fetchImportingUsersAsync().then(value => this.setState({asyncImportingUsers: value}));
    }

    handleAsyncUserCreationMessage = (message) => {
        if (message.organization_uuid === this.props.organization.organization_uuid) {
            const running = safe_get(message, 'data.running', false);
            this.setState({asyncImportingUsers: running});

            if (!running) {
                this.setState({loading_users: true});
                this.getUsers();
            } else if (running && this.state.showInviteBlock) {
                this.setState({showInviteBlock: false});
            }
        }
    }

    getTeams() {
        var self = this;
        get_list_teams().then(function (success) {
            success.json().then(success => {
                var teams = safe_get(success, "teams", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                });
                self.setState({teams_list: teams, filtered_teams_list: teams});
            });
        }, function (error) {
            console.log(error);
        });
    }

    getUsers() {
        fetchPaginatedUsers()
        .then((users) => {
            users.sort((a, b) => {
                const a_name = `${a.first_name} ${a.last_name}`.toLowerCase();
                const b_name = `${b.first_name} ${b.last_name}`.toLowerCase();

                if (a.status === "pending" && b.status === "pending") {
                    return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1;
                }
                if (a.status === "pending" && b.status !== "pending") {
                    return 1
                }
                if (a.status !== "pending" && b.status === "pending") {
                    return -1
                }

                return a_name > b_name ? 1 : -1;
            });

            const [firstUserIndex, page] = getPageData(users, this.state.pagination);

            this.setState({
                all_users: users,
                filtered_users: users,
                filtered_users_page: users.slice(firstUserIndex, firstUserIndex + USERS_PER_PAGE),
                total_users: users.length,
                users: users,
                loading_users: false,
                pagination: page
            }, () => {
                this.onChangePeopleFilter(this.state.peopleFilter)
            });
        })
        .catch(error => {
            notifyError(error);
            this.setState({loading_users: false});
        });
    }

    reload = () => {
        this.setState({loading_users: true});
        this.getUsers();
        this.getTeams();
        this.getImportingUsersAsync();
    }

    onChangePeopleFilter = (filters) => {
        let all_users = [...this.state.all_users];
        const filtersHaveChanged = filters !== this.state.peopleFilter;
        if (filters.length === 0) {
            let firstUserIndex = 0;
            let page = 1;

            if (!filtersHaveChanged) {
                [firstUserIndex, page] = getPageData(all_users, this.state.pagination);
            }

            this.setState({
                filtered_users: all_users,
                filtered_users_page: all_users.slice(firstUserIndex, firstUserIndex + USERS_PER_PAGE),
                pagination: page,
                total_users: all_users.length,
                peopleFilter: filters,
                filter_active: false
            });
        } else {
            let filtered_users = all_users;
            filters.forEach(rawFilter => {
                let filter = rawFilter.trim();
                let filterValue = filter.split("=")[1]; // status, role, team

                if (filter.includes("status=")) {
                    let status = filterValue;
                    filtered_users = filtered_users.filter(user => {
                        return safe_get(user, "status", "").toLowerCase().includes(status);
                    });
                } else if (filter.includes("role=")) {
                    let role = filterValue;
                    filtered_users = filtered_users.filter(user => {
                        return safe_get(user, "roles", []).includes(role)
                    });
                } else if (filter.includes("team=")) {
                    let team_uuid = filterValue;
                    filtered_users = filtered_users.filter(user => {
                        let user_team_uuids = safe_get(user, "teams", []).map(team => team.uuid);
                        if (team_uuid === 'none') {
                            return user_team_uuids.length === 0;
                        } else {
                            return user_team_uuids.includes(team_uuid)
                        }
                    });
                } else {
                    filtered_users = filtered_users.filter(user => {
                        return (user.first_name + " " + user.last_name).toLowerCase().includes(filter.toLowerCase()) || safe_get(user, "email", "").toLowerCase().includes(filter.toLowerCase());
                    })
                }
            });

            let firstUserIndex = 0;
            let page = 1;

            if (!filtersHaveChanged) {
                [firstUserIndex, page] = getPageData(filtered_users, this.state.pagination);
            }

            this.setState({
                filtered_users: filtered_users,
                filtered_users_page: filtered_users.slice(firstUserIndex, firstUserIndex + USERS_PER_PAGE),
                total_users: filtered_users.length,
                peopleFilter: filters,
                pagination: page,
                filter_active: true
            });
        }
    }

    onChangePage = (page) => {
        // let users = this.state.filter_active ? [...this.state.filtered_users] : [...this.state.all_users];
        let filtered_users_page = [...this.state.filtered_users].slice(USERS_PER_PAGE * (page - 1), USERS_PER_PAGE * page)
        this.setState({
            pagination: page,
            filtered_users_page: filtered_users_page
        });
    }

    loadingFormatter(column) {
        if (column === 0) {
            return (
                <div className="media">
                    <div className="media-left profile-img">
                        <Skeleton circle={true} width={40} height={40}/>
                    </div>
                    <div className="media-body account-card">
                        <p className="text-md text-semibold profile  name "
                           style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                            <Skeleton width={130}/>
                        </p>
                        <p className="text-sm profile  email " style={{display: 'block', fontSize: '.9em'}}>
                            <Skeleton width={100}/>
                        </p>
                    </div>
                </div>
            )
        } else if (column === 1) {
            return (
                <p className="role-text">
                    <Skeleton width={110}/>
                </p>
            )
        } else if (column === 2) {
            return (
                <Skeleton height={21} width={75}/>
            )
        } else if (column === 3) {
            return (
                <Skeleton width={125}/>
            )
        } else if (column === 4) {
            return (
                <div className="media-right options" style={{marginTop: '6px', padding: "0px"}}>
                    <Skeleton width={28} height={20}/>
                </div>
            )
        }
    }

    userFormatter(user) {
        var name = safe_get(user, "first_name", "") + " " + safe_get(user, "last_name", "");
        var email = safe_get(user, "email", "");
        var user_uuid = safe_get(user, "uuid", "undefined");
        return (
            <div className="media">
                <div className="media-left profile-img">
                    <Link to={"/" + this.props.organization.organization_uuid + "/users/" + user_uuid}
                          className="btn-link zero-blue">
                        {safeProfilePic(user, "img-sm img-circle top-posters", "top-posters")}
                    </Link>
                </div>
                <div className="media-body account-card" style={{verticalAlign: (user.email ? "top" : "middle")}}>
                    <Link to={"/" + this.props.organization.organization_uuid + "/users/" + user_uuid}
                          className="btn-link zero-blue">
                        <p className={"text-md text-semibold profile truncate name link-hover " + (user.status === "deactivated" ? "zero-dark-grey" : "zero-blue")}
                           style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                            {name}
                        </p>
                    </Link>
                    {
                        email &&
                        <p className="text-sm profile truncate email zero-dark-grey"
                           style={{display: 'block', fontSize: '.9em'}}>{email}</p>
                    }
                </div>
            </div>
        )
    }

    roleFormatter(user) {
        let roles = safe_get(user, "roles", []);

        if (roles.includes("admin")) {
            return (<p className="role-text zero-dark-grey"><span
                className="badge badge-success badge-icon badge-fw "></span>Admin</p>)
        } else if (roles.includes("team_lead")) {
            return (
                <p className="role-text zero-dark-grey"><span className="badge badge-info badge-icon badge-fw "></span>Lead
                </p>)
        } else if (roles.includes("user")) {
            return (<p className="role-text zero-dark-grey"><span
                className="badge badge-purple badge-icon badge-fw "></span>Member</p>)
        } else if (roles.includes("viewer")) {
            return (<p className="role-text zero-dark-grey"><span
                className="badge badge-danger badge-icon badge-fw "></span>Viewer</p>)
        } else if (roles.includes("user_restricted")) {
            return (<p className="role-text zero-dark-grey"><span
                className="badge badge-warning badge-icon badge-fw "></span>Contributor</p>)
        }
    }

    statusFormatter(user) {
        let status = user.status;
        switch (status) {
            case "pending":
                return <Tag color="orange" style={{borderColor: "#FA8C13"}}>Invited</Tag>
            // return <p className="badge" style={{margin: 'unset', float:'unset', width: '70px', backgroundColor: '#FDB22B'}}>Invited</p>
            case "active":
                return <Tag color="green" style={{borderColor: "#52c41a"}}>Registered</Tag>
            // return <p className="badge" style={{margin: 'unset', float:'unset', width: '70px', backgroundColor: '#8CC152'}}>Active</p>
            case "deactivated":
                return <Tag style={{color: "#505050"}}>Deactivated</Tag>
            // return <p className="badge" style={{margin: 'unset', float:'unset', width: '77px', backgroundColor: '#9c9c9c'}}>Deactivated</p>
            default:
                return null;
        }
    }

    teamsFormatter(user) {
        var teams = safe_get(user, "teams", []);
        return <TeamListPopover teams={teams}/>;
    }

    noDataRenderer() {
        return (
            <div className="mar-top">
                <h3 className="subtitle header">No members. Please clear filters.</h3>
            </div>
        )
    }

    getUserRole(user) {
        let roles = safe_get(user, "roles", []);
        let role = "User";

        if (roles.indexOf("admin") >= 0) {
            role = "Admin";
        } else if (roles.indexOf("team_lead") >= 0) {
            role = "Lead";
        } else if (roles.indexOf("user") >= 0) {
            role = "User";
        } else if (roles.indexOf("viewer") >= 0) {
            role = "Viewer"
        } else if (roles.indexOf("user_restricted") >= 0) {
            role = "Contributor"
        }

        return role;
    }

    generateMenuOptions(user) {
        const { status } = user;
        const deactivated = status === "deactivated";
        const pending = status === 'pending';
        const currentUserIsAdmin = isAdmin(this.props.user);
        let canChangeRole = true;
        if (isAdmin(user) && !currentUserIsAdmin) {
            canChangeRole = false;
        }
        
        let menuProps = [];
        
        if (deactivated) {
            menuProps = [
                currentUserIsAdmin && {
                    key: "reactivate",
                    label: "Reactivate User",
                },
            ];
        } else {
            menuProps = [
                {
                    key: "edit_profile",
                    label: "Edit Profile",
                    disabled: !currentUserIsAdmin || pending,
                },
                {
                    key: "change_role",
                    disabled: !canChangeRole,
                    label: "Change Role",
                },
                {
                    key: "change_teams",
                    label: "Change Teams",
                },
                {
                    type: "divider",
                },
                !pending && {
                    key: "deactivate",
                    disabled: !currentUserIsAdmin,
                    label: (
                        <span style={currentUserIsAdmin ? { color: "#ee0808" } : {}}>Deactivate User</span>
                    ),
                },
                pending && {
                    key: "resend_invite",
                    label: "Resend Invite",
                },
                pending && {
                    key: "delete_invite",
                    className: "zero-delete-red",
                    label: "Delete Invite",
                },
            ];
        }
        
        return menuProps.filter((props) => props !== false);
    }

    handleMenuClick(key, user) {
        let current_role = this.getUserRole(user);

        if (key === "change_role") {
            this.setState({showChangeRoleModal: true, userToUpdate: user, userCurrentRole: current_role});
        } else if (key === "change_teams") {
            this.setState({showChangeTeamsModal: true, userToUpdate: user});
        } else if (key === "edit_profile") {
            this.setState({showEditProfileModal: true, userToUpdate: user});
        } else if (key === "deactivate") {
            this.setState({
                user_to_deactivate: user,
                showDeactivateModal: true
            });
        } else if (key === "resend_invite") {
            var team_uuid = safe_get(user.teams[0], "uuid", "undefined");
            this.resendInvite(team_uuid, user.uuid);
        } else if (key === "delete_invite") {
            this.setState({
                showDeleteModal: true,
                deleteModalText: "Are you sure you want to delete invite for " + user.email + "?",
                deleteInviteUser: user,
            });
            // this.deleteUser(user);
        } else if (key === "reactivate") {
            this.activateUser(user);
        } else {
            return;
        }
    }

    roleUpdateCallback(role) {

        var self = this;
        var users = [...this.state.all_users];
        var userToUpdate = this.state.userToUpdate

        var index = users.indexOf(userToUpdate);

        if (index >= 0) {
            userToUpdate["roles"] = [role]
            users[index] = userToUpdate;
            self.setState({
                all_users: users,
                filtered_users: users,
            }, () => {
                this.onChangePeopleFilter(this.state.peopleFilter)
            });

            var new_role = "an Admin";
            if (role === "team_lead") {
                new_role = "a Team Lead";
            } else if (role === "user") {
                new_role = "a Member";
            } else if (role === "viewer") {
                new_role = "a Viewer";
            } else if (role === "user_restricted") {
                new_role = "a Contributor";
            }

            let name = userToUpdate.first_name;
            name = (name === "" ? "Pending user" : name);

            NotificationAlert("success", "", name + " is now " + new_role);

        }

        this.setState({showChangeRoleModal: false, userToUpdate: null});
    }

    resendInvite(team_uuid, user_uuid) {
        resend_invite(team_uuid, user_uuid).then(function (success) {
            NotificationAlert("success", "", "Invitations sent.")
        }, function (error) {
            NotificationAlert("error", "", "Could not resend invite.")
        })
    }

    deactivateUser() {
        var self = this;
        if (!this.state.deactivating_user) {
            this.setState({deactivating_user: true});

            var user = this.state.user_to_deactivate;
            deactivate_user(user.uuid).then((_success) => {
                var users = [...self.state.all_users];
                var index = users.indexOf(user);

                if (index >= 0) {
                    user["status"] = "deactivated"
                    users[index] = user;
                    self.setState({
                        all_users: users,
                        filtered_users: users,
                        user_to_deactivate: null,
                        deactivating_user: false,
                        showDeactivateModal: false
                    }, () => {
                        this.onChangePeopleFilter(this.state.peopleFilter)
                    });
                }
                NotificationAlert("success", "", user.first_name + " is now deactivated.");

                this.getUsers();
            }, (_error) => {
                NotificationAlert("error", "", "Could not deactivate user.");
                self.setState({showDeactivateModal: false, user_to_deactivate: null, deactivating_user: false});
            });
        }
    }

    activateUser(user) {
        var self = this;
        activate_user(user.uuid).then(function (_success) {
            var users = [...self.state.all_users];
            var index = users.indexOf(user);

            if (index >= 0) {
                user["status"] = "active"
                users[index] = user;
                self.setState({
                    all_users: users,
                    filtered_users: users,
                    user_to_deactivate: null,
                    deactivating_user: false,
                }, () => {
                    self.onChangePeopleFilter(self.state.peopleFilter);
                });
            }

            let name = user.first_name;
            name = (name === "" ? "Pending user" : name);
            NotificationAlert("success", "", name + " has been reactivated.");
            self.getUsers();
        }, function (_error) {
            NotificationAlert("error", "", "Could not reactivate user.");
        });
    }

    deleteUser() {
        var self = this;
        var user = this.state.deleteInviteUser;
        delete_pending_user(user.uuid).then(function (success) {
            var users = [...self.state.all_users];
            var index = users.indexOf(user);
            if (index >= 0) {
                users.splice(index, 1);
            }

            self.setState({
                all_users: users,
                filtered_users: users.slice(0, USERS_PER_PAGE),
                total_users: users.length,
                deleteInviteUser: null,
                showDeleteModal: false
            }, () => {
                self.onChangePeopleFilter(self.state.peopleFilter);
            });

            let name = user.first_name || user.email;
            NotificationAlert("success", "", name + " has been removed.");

        }, function (error) {
            NotificationAlert("error", "", "Could not delete invite.");
            self.setState({showDeleteModal: false});

        })
    }

    changeTeamsCallback = (success, data) => {
        var self = this;
        if (success) {
            if (data === this.props.user.uuid) {
                get_user(data).then(function (success) {
                    success.json().then(success => {
                        var teams = safe_get(success, "user.teams", [])
                        self.props.dispatch(teamsActions.update_teams(teams));
                    })
                }, function (error) {
                    console.log(error);
                });
            }

            this.getUsers();
            this.setState({showChangeTeamsModal: false});
            NotificationAlert("success", "", "Teams updated.");
        } else {
            this.setState({showChangeTeamsModal: false});
            data.json().then(error => {
                let error_messages = safe_get(error, "error_messages", []);
                if (error_messages.length > 0) {
                    NotificationAlert("error", "", error_messages[0])
                } else {
                    NotificationAlert("error", "", "An unexpected error occurred.");
                }
            })
        }
    }

    editProfileCallback = (success, data) => {
        var self = this;
        if (success) {
            if (data.uuid === this.props.user.uuid) {
                get_user(this.props.user.uuid).then(function (success) {
                    success.json().then(success => {
                        let user = safe_get(success, "user", {})
                        self.props.dispatch(userActions.update_current_user(user));
                    })
                }, function (error) {
                    console.log(error);
                });
            }

            var all_users = [...self.state.all_users];
            let index = all_users.findIndex(user => {
                return user.uuid === data.uuid
            });

            if (index >= 0) {
                let user = all_users[index];
                user["first_name"] = data.first_name;
                user["last_name"] = data.last_name;
                user["phone_number"] = data.phone_number;
                user["mobile_phone_number"] = data.mobile_phone_number;
                user["title"] = data.title;
                user["email"] = data.email;
                user['roles'] = data.roles;
                all_users[index] = user;
            }

            this.setState({
                all_users: all_users,
                filtered_users: all_users.slice(0, USERS_PER_PAGE),
                total_users: all_users.length,
            }, () => {
                this.onChangePeopleFilter(this.state.peopleFilter);
            });

            this.getUsers();
            this.setState({showEditProfileModal: false});
        }
    }

    inviteCallback(success, message, team = null) {
        if (success) {
            this.getUsers();
            this.setState({showInviteBlock: false});
            NotificationAlert('success', "", message);
        } else {
            NotificationAlert('error', "Oops!", message)
        }
    }

    render() {

        var members_count_className = ""
        if (this.state.total_users > 99) {
            members_count_className = "hundred"
        }
        if (this.state.total_users > 999) {
            members_count_className = "thousand"
        }

        let loading = this.state.loading_users || this.state.loading_deactivated_users

        return (
            <div>
                {
                    this.state.showDeactivateModal &&
                    <ConfirmModal
                        show={this.state.showDeactivateModal}
                        cancel={() => {
                            this.setState({showDeactivateModal: false});
                        }}
                        confirm={this.deactivateUser}
                        title={`Deactivate ${safe_get(this.state, "user_to_deactivate.first_name", "user")}?`}
                        deactivateUser={true}
                        confirmButtonName={"Deactivate"}

                    />
                }

                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        show={this.state.showDeleteModal}
                        cancel={() => {
                            this.setState({showDeleteModal: false});
                        }}
                        confirm={this.deleteUser}
                        title={"Delete Invite"}
                        body={this.state.deleteModalText}
                        confirmButtonName={"Delete"}
                    />
                }

                {
                    this.state.showChangeRoleModal &&
                    <ChangeRoleModal
                        show={this.state.showChangeRoleModal}
                        cancel={() => {
                            this.setState({showChangeRoleModal: false});
                        }}
                        userCurrentRole={this.state.userCurrentRole}
                        userToUpdate={this.state.userToUpdate}
                        roleUpdateCallback={this.roleUpdateCallback}
                        loggedInUser={this.props.user}
                        isAdmin={isAdmin(this.props.user)}
                        deactivate={() => {
                            this.setState({
                                user_to_deactivate: this.state.userToUpdate,
                                showChangeRoleModal: false,
                                showDeactivateModal: true
                            })
                        }}
                    />
                }

                {
                    this.state.showChangeTeamsModal &&
                    <ChangeTeamsModal
                        teams={this.props.teams}
                        isAdmin={isAdmin(this.props.user)}
                        show={this.state.showChangeTeamsModal}
                        cancel={() => {
                            this.setState({showChangeTeamsModal: false});
                        }}
                        userToUpdate={this.state.userToUpdate}
                        changeTeamsCallback={this.changeTeamsCallback}
                        deactivate={() => {
                            this.setState({
                                user_to_deactivate: this.state.userToUpdate,
                                showChangeTeamsModal: false,
                                showDeactivateModal: true
                            })
                        }}
                    />
                }

                {
                    this.state.showEditProfileModal &&
                    <EditProfileModal
                        isAdmin={isAdmin(this.props.user)}
                        show={this.state.showEditProfileModal}
                        cancel={() => {
                            this.setState({showEditProfileModal: false});
                        }}
                        userToUpdate={this.state.userToUpdate}
                        user={this.props.user}
                        editProfileCallback={this.editProfileCallback}
                    />
                }

                {
                    this.state.showInviteBlock &&
                    <EmailInvite
                        show={this.state.showInviteBlock}
                        cancel={() => {
                            this.setState({showInviteBlock: false})
                        }}
                        inviteCallback={this.inviteCallback}
                        team={this.props.current_team}
                        teams={this.props.teams}
                        user={this.props.user}
                        directory={true}
                    />
                }

                <div className={mainContentContainerClass()}>

                    <div id="page-head" className="no-padding-mobile">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                <div id="page-title" style={{padding: '0px'}}>
                                    <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                        <div className="nav-header-panel no-padding-print">
                                            <div className="team-name-header">
                                                <h3 className={"section-titles admin-page-header"}
                                                    style={{display: "inline-block", margin: "0px"}}>
                                                    Manage Members
                                                </h3>
                                                <button className="btn btn-primary pull-right hidden-xs" style={{
                                                    width: '145px',
                                                    textAlign: 'center',
                                                    display: 'inline-block',
                                                    padding: "6px"
                                                }} onClick={() => {
                                                    this.setState({showInviteBlock: true});
                                                }} disabled={this.state.asyncImportingUsers}>
                                                    <PlusCircleOutlined className="mar-rgt-5"/>Invite members
                                                </button>
                                                <button className="btn btn-primary pull-right visible-xs" style={{
                                                    width: '50px',
                                                    textAlign: 'center',
                                                    display: 'inline-block',
                                                    padding: "6px"
                                                }} onClick={() => {
                                                    this.setState({showInviteBlock: true});
                                                }} disabled={this.state.asyncImportingUsers}>
                                                    <PlusCircleOutlined/>
                                                </button>
                                                <div className="hidden-xs pull-right"
                                                     style={{display: 'flex', alignItems: 'center', height: '3.3rem'}}>
                                                    <ReloadOutlined
                                                        className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                                        style={{fontSize: '18px'}} onClick={() => {
                                                        this.reload()
                                                    }}/>
                                                </div>
                                            </div>
                                            <p className="header"
                                               style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                                Here's everyone on the account.
                                            </p>

                                            <div className="members-search" style={{width: "100%", marginTop: "8px"}}>
                                                <Select
                                                    value={this.state.peopleFilter}
                                                    mode="tags"
                                                    placeholder="Filter or search..."
                                                    onChange={this.onChangePeopleFilter}
                                                    style={{
                                                        width: "100%",
                                                        verticalAlign: "bottom"
                                                    }}
                                                    showArrow={false}
                                                    virtual={false}
                                                    optionFilterProp="children"
                                                >
                                                    <Select.OptGroup label="Status">
                                                        <Select.Option key={7}
                                                                       value={"status=active"}>Registered</Select.Option>
                                                        <Select.Option key={5}
                                                                       value={"status=pending"}>Invited/Pending</Select.Option>
                                                        <Select.Option key={6} value={"status=deactivated"}
                                                                       disabled={isTeamLead(this.props.user)}>Deactivated</Select.Option>
                                                    </Select.OptGroup>
                                                    <Select.OptGroup label="Role">
                                                        <Select.Option key={0} value={"role=admin"}>
                                                            <span className="badge badge-success badge-icon "
                                                                  style={{margin: "0px 5px 2px 0px"}}/>Admins
                                                        </Select.Option>
                                                        <Select.Option key={1} value={"role=team_lead"}>
                                                            <span className="badge badge-info badge-icon "
                                                                  style={{margin: "0px 5px 2px 0px"}}/>Team Leads
                                                        </Select.Option>
                                                        <Select.Option key={2} value={"role=user"}>
                                                            <span className="badge badge-purple badge-icon"
                                                                  style={{margin: "0px 5px 2px 0px"}}/>Members
                                                        </Select.Option>
                                                        <Select.Option key={3} value={"role=user_restricted"}>
                                                            <span className="badge badge-warning badge-icon"
                                                                  style={{margin: "0px 5px 2px 0px"}}/>Contributors
                                                        </Select.Option>
                                                        <Select.Option key={4} value={"role=viewer"}>
                                                            <span className="badge badge-danger badge-icon"
                                                                  style={{margin: "0px 5px 2px 0px"}}/>Viewers
                                                        </Select.Option>
                                                    </Select.OptGroup>
                                                    <Select.OptGroup label="Team">
                                                        {
                                                            this.state.teams_list.map((team, index) => (
                                                                <Select.Option key={"team=" + index}
                                                                               value={"team=" + team.uuid}>{team.name}</Select.Option>
                                                            ))
                                                        }
                                                        <Select.Option value={"team=none"}>No Teams</Select.Option>
                                                    </Select.OptGroup>

                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="page-content" className="no-padding-mobile">
                        {this.state.asyncImportingUsers &&
                            <Alert
                                type="info"
                                style={{marginBottom: '1rem'}}
                                message={<span><Spin size="small" style={{marginRight: '0.5rem'}}/> User import currently in progress. This page will refresh automatically once complete.</span>}
                            />
                        }
                        <div className="panel thin-border mar-btm-0" style={{maxWidth: "850px", margin: "0 auto"}}>
                            <UsersTable
                                loading={loading}
                                users={this.state.filtered_users_page}
                                generateMenuOptions={this.generateMenuOptions}
                                onMenuClick={this.handleMenuClick}
                                onUserClick={user => this.setState({showEditProfileModal: true, userToUpdate: user})}
                                total={this.state.total_users}
                            />
                            <div style={{marginTop: "10px", marginBottom: "10px"}}>
                                <Pagination
                                    size={"small"}
                                    showSizeChanger={false}
                                    hideOnSinglePage={true}
                                    pageSize={USERS_PER_PAGE}
                                    total={this.state.filtered_users.length}
                                    current={this.state.pagination}
                                    onChange={this.onChangePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        members_filter: safe_get(store, "members_filter.filter", [])
    }
}

export default connect(mapStateToProps)(ManageMembers);


