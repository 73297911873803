import React, {Component} from 'react';
import Geolocation from "react-geolocation";

import {IoLocation as LocationIcon} from 'react-icons/io5'
import { PiSpinnerGapBold as LoadingIcon } from "react-icons/pi";

class UserGeolocation extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    render() {
        return (
            <Geolocation
                lazy
                // enableHighAccuracy={true}
                // timeout={30000000}
                onError={(error) => {
                    this.props.locationError(error)
                }}
                onSuccess={(position) => {
                    this.props.gotCurrentLocation(position)
                }}
                render={({
                             fetchingPosition,
                             //position: { coords: { latitude, longitude } = {} } = {},
                             error,
                             getCurrentPosition
                         }) =>
                    <button className="btn btn-search location" onClick={() => {
                        getCurrentPosition();
                        this.props.loading();
                    }}>
                        {
                            fetchingPosition
                                ? <LoadingIcon  style={{height: '15px', width: '15px'}}/>
                                : <LocationIcon style={{height: '15px', width: '15px'}}/>
                        }
                    </button>

                }
            />
        );
    }
}

export default UserGeolocation

