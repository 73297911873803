import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CorrectiveActionsModal from './CorrectiveActionsModal.js';
import DeleteIncidentModal from './DeleteIncidentModal.js';
import ExportIncidentModal from './ExportIncidentModal.js';
import MoveIncidentModal from './MoveIncidentModal.js';

import { get_custom_fields } from '../../api/zero-api.js';
import NotificationAlert from '../../other/NotificationAlert.js';

import {IoIosMore as MoreIcon} from 'react-icons/io';

import { ArrowRightOutlined, CloudDownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Dropdown, Menu } from 'antd';

import Skeleton from 'react-loading-skeleton';

import { CUSTOM_FIELD_TYPES } from 'other/Constants';
import { incidents_report, move_incident } from '../../api/zero-api';
import {
    dateFormatterFromString, isAdmin, isAdminOrTeamLead, isTeamLead, safeProfilePic
} from '../../other/Helper.js';

import safe_get from '../../other/SafeGet.js';

import * as incidentsActions from '../../store/actions/IncidentsActions.js';
import UserPopoverCard from '../Shared/UserPopoverCard.js';
import IncidentStatusTag from './IncidentStatusTag.js';

class IncidentsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customField1: null,
            customField2: null,
            customField3: null,
            customField4: null,
        };

        this.exportIncident = this.exportIncident.bind(this);
        this.moveIncident = this.moveIncident.bind(this);
        this.rowOptionsFormatter = this.rowOptionsFormatter.bind(this);
        this.goToEditIncident = this.goToEditIncident.bind(this);
        this.goToPost = this.goToPost.bind(this);
    }

    componentDidMount() {
        this.getCustomFields();
    }


    rowOptionsFormatter(incident) {
        const isDisabled = isAdminOrTeamLead(this.props.user) === false;
        const isAuthor = incident.created_by.uuid === this.props.user.uuid;
        const deleteIsDisabled = !(isAdmin(this.props.user) || (isTeamLead(this.props.user) && isAuthor));

        const tableMenu = {
            onClick: ({key}) => {
                this.handleTableMenuClick(key, incident)
            },
            items: [
                {
                    key: "edit_incident",
                    disabled: isDisabled,
                    icon: <EditOutlined/>,
                    label: "Edit"
                },
                {
                    key: "export_incident",
                    disabled: isDisabled,
                    icon: <CloudDownloadOutlined/>,
                    label: "Export"
                },
                {
                    key: "move",
                    disabled: isDisabled,
                    icon: <ArrowRightOutlined/>,
                    label: "Move"
                },
                {
                    type: "divider"
                },
                {
                    key: "delete_incident",
                    disabled: deleteIsDisabled,
                    className: deleteIsDisabled ? "" : "zero-delete-red",
                    icon: <DeleteOutlined/>,
                    label: "Delete"
                }
            ]
        }

        if (this.props.isReportTable) {
            return (
                <CloudDownloadOutlined onClick={() => {
                    this.handleTableMenuClick("export_incident", incident)
                }}/>
            )
        } else {
            return (
                <div className="more pull-right" style={{display: "inline-block", color: "grey", lineHeight: 0}}>
                    <Dropdown menu={tableMenu} trigger={['click']} placement="bottomRight">
                        <button className="ButtonLink ant-dropdown-link">
                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                        </button>
                    </Dropdown>
                </div>
            )
        }

    }

    getCustomFields = async () => {
        try {
            const response = await get_custom_fields();
            const customFields = await response.json();
            const customField1 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_1);
            const customField2 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_2);
            const customField3 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_3);
            const customField4 = customFields.find(field => field.type === CUSTOM_FIELD_TYPES.INCIDENT_CUSTOM_FIELD_4);

            if (customField1) {
                this.setState({customField1});
            }
            if (customField2) {
                this.setState({customField2});
            }
            if (customField3) {
                this.setState({customField3});
            }
            if (customField4) {
                this.setState({customField4});
            }
        } catch (err) {
            console.error('Could not get custom fields:', err);
        }
    }

    getEmployeeName = (full_name) => {
        let names = full_name.split(" ");
        let first_name = names[0];
        let last_name = names[names.length - 1];
        return <span>{first_name} {last_name.charAt(0)}</span>
    }

    getIncidentType = (incidentType) => {
        let typeText = ""
        this.props.incident_types.forEach(element => {
            if (element.value === incidentType) {
                typeText = element.name
            }
        });
        return <span className="zero-dark-grey">{typeText}</span>
    }

    userFormatter = (user) => {
        return (
            <UserPopoverCard user={user}>
            <span style={{cursor: "pointer"}}>
              {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                  marginRight: "5px",
                  display: "inline-block"
              })}
            </span>
            </UserPopoverCard>
        )
    }

    handleTableMenuClick(key, incident) {
        if (key === "edit_incident") {
            this.props.dispatch(incidentsActions.update_current_incident(incident));
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/incidents/location/" + this.props.current_location.location_uuid + "/edit_incident/" + incident.incident_uuid + "/1");
        } else if (key === "move") {
            this.setState({
                showMoveIncidentModal: true,
                incident_to_move: incident
            })
        } else if (key === "delete_incident") {
            this.setState({
                showDeleteIncidentModal: true,
                incident_to_delete: incident
            });
        } else if (key === "export_incident") {
            this.setState({
                exportModal: true,
                incident_to_export: incident
            });
        }
    }

    goToEditIncident(incident) {
        this.props.dispatch(incidentsActions.update_current_incident(incident));
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/incidents/location/" + this.props.current_location.location_uuid + "/edit_incident/" + incident.incident_uuid + "/1");
    }

    exportIncident(docuType) {
        var self = this;

        if (docuType === "pdf" || docuType === 'xls2') {
            self.setState({exportModal: false})

        } else {
            var body = JSON.stringify({
                export_type: "301",
                location_uuid: this.state.incident_to_export.location_uuid,
                incident_uuid: this.state.incident_to_export.incident_uuid,
                period: "all_time"
            });

            incidents_report(body).then(function (success) {
                success.json().then(success => {
                    window.location = success.data.file_url;
                    self.setState({exportModal: false})
                });
            }, function (error) {
                self.setState({exportModal: false});
            });
        }
    }

    moveIncident(location_uuid) {
        var self = this;
        var body = JSON.stringify({
            location_uuid: location_uuid
        });

        move_incident(this.state.incident_to_move.incident_uuid, body).then(function (success) {
            self.setState({showMoveIncidentModal: false});
            self.props.getIncidents();
            NotificationAlert("success", "", "Incident moved.");
        }, function (error) {
            self.setState({showMoveIncidentModal: false});
            NotificationAlert("success", "", "Unable to move incident.")

        });
    }

    goToPost(post_uuid) {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed/post/" + post_uuid)
    }

    render() {
        return (
            <div>
                {
                    this.state.showDeleteIncidentModal &&
                    <DeleteIncidentModal
                        incident={this.state.incident_to_delete}
                        show={this.state.showDeleteIncidentModal}
                        cancel={() => {
                            this.setState({showDeleteIncidentModal: false});
                        }}
                        successCallback={this.props.getIncidents}
                    />
                }
                {
                    this.state.showMoveIncidentModal &&
                    <MoveIncidentModal
                        show={this.state.showMoveIncidentModal}
                        cancel={() => {
                            this.setState({showMoveIncidentModal: false});
                        }}
                        locations={this.props.incidents_locations}
                        source_location={this.state.incident_to_move.location_uuid}
                        confirm={this.moveIncident}
                        canMove={isAdmin(this.props.user)}
                    />
                }
                {
                    this.state.showCorrectiveActionsModal &&
                    <CorrectiveActionsModal
                        show={this.state.showCorrectiveActionsModal}
                        cancel={() => {
                            this.setState({showCorrectiveActionsModal: false});
                        }}
                        incident_uuid={this.state.selected_incident}
                        goToPost={this.goToPost}
                    />
                }
                {
                    this.state.exportModal &&
                    <ExportIncidentModal
                        show={this.state.exportModal}
                        onModalClosed={() => {
                            this.setState({exportModal: false});
                        }}
                        incident={this.state.incident_to_export}
                    />
                }
                <div className="panel mar-btm-0">
                    <div className="" style={{overflow: "auto"}}>
                        <div className="table-responsive" style={{border: "none"}}>
                            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                <thead style={{color: "#1D2A35"}}>
                                <tr>
                                    <th></th>
                                    <th className="zero-blue" style={{minWidth: '12rem'}}>Case #</th>
                                    <th className="zero-blue" style={{minWidth: '12rem'}}>Location</th>
                                    <th className="zero-blue" style={{minWidth: '9.5rem'}}>Logged By</th>
                                    <th className="zero-blue" style={{minWidth: '10rem'}}>Status</th>
                                    <th className="zero-blue" style={{minWidth: '12rem'}}>Date of Event</th>
                                    <th className="zero-blue" style={{minWidth: '10rem'}}>Employee</th>
                                    <th className="zero-blue" style={{minWidth: '12rem'}}>Case Type</th>
                                    <th className="zero-blue" style={{minWidth: '10rem'}}>Incident Type</th>
                                    <th className="zero-blue" style={{minWidth: '12rem'}}>Injury Type</th>

                                    {this.props.event_types.length > 0 &&
                                        <th className="zero-blue" style={{minWidth: '10rem'}}>Event Type</th>
                                    }
                                    {this.props.incidents.length > 0 && this.state.customField1 && this.state.customField1.enabled &&
                                        <th className="zero-blue"
                                            style={{minWidth: '10rem'}}>{this.state.customField1.name}</th>
                                    }
                                    {this.props.incidents.length > 0 && this.state.customField2 && this.state.customField2.enabled &&
                                        <th className="zero-blue"
                                            style={{minWidth: '10rem'}}>{this.state.customField2.name}</th>
                                    }
                                    {this.props.incidents.length > 0 && this.state.customField3 && this.state.customField3.enabled &&
                                        <th className="zero-blue"
                                            style={{minWidth: '10rem'}}>{this.state.customField3.name}</th>
                                    }
                                    {this.props.incidents.length > 0 && this.state.customField4 && this.state.customField4.enabled &&
                                        <th className="zero-blue"
                                            style={{minWidth: '10rem'}}>{this.state.customField4.name}</th>
                                    }
                                    <th className="zero-blue">Actions + Notes</th>
                                </tr>
                                </thead>
                                {
                                    this.props.loading &&
                                    <tbody>
                                    {
                                        [...Array(8)].map((_item, index) => (
                                            <tr key={index} className="tr-hover">
                                                <td><Skeleton width={25}/></td>
                                                <td style={{minWidth: "86px"}}><Skeleton width={83}/></td>
                                                <td><Skeleton width={100}/></td>
                                                <td><Skeleton circle width={26} height={26}/></td>
                                                <td><Skeleton width={90}/></td>
                                                <td style={{minWidth: "100px"}}><Skeleton width={93}/></td>
                                                <td><Skeleton width={90}/></td>
                                                <td><Skeleton width={90}/></td>
                                                <td><Skeleton width={75}/></td>
                                                {this.props.event_types.length > 0 && <td><Skeleton width={60}/></td>}
                                                {this.props.incidents.length > 0 && <td><Skeleton width={60}/></td>}
                                                <td><Skeleton width={25}/></td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.props.loading && this.props.incidents.length !== 0 &&
                                    <tbody>
                                    {
                                        this.props.incidents.map((incident, index) => (
                                            <tr key={index} className="tr-hover">
                                                <td className="min-width">
                                                    {this.rowOptionsFormatter(incident)}
                                                </td>
                                                <td>
                                                    <button className="ButtonLink btn-link"
                                                            style={{fontSize: "95%", minWidth: "86px"}}
                                                            disabled={!isAdminOrTeamLead(this.props.user)}
                                                            onClick={() => {
                                                                this.goToEditIncident(incident)
                                                            }}>
                                                        {incident.case_number}
                                                    </button>
                                                </td>
                                                <td className="zero-dark-grey">{incident.location_name}</td>
                                                <td className="zero-dark-grey ">{this.userFormatter(incident.created_by)}</td>
                                                <td className="zero-dark-grey"><IncidentStatusTag incident={incident}/>
                                                </td>
                                                <td className="zero-dark-grey" style={{
                                                    fontSize: "95%",
                                                    minWidth: "100px"
                                                }}>{dateFormatterFromString(incident.date_of_injury)}</td>
                                                <td className="truncate zero-dark-grey name">{this.getEmployeeName(safe_get(incident, "full_name", ""))}</td>
                                                <td className="zero-dark-grey">{this.getIncidentType(incident.incident_type)}</td>
                                                <td className="zero-dark-grey">{incident.incident_illness ? "Illness" : incident.incident_injury ? "Injury" : ""}</td>
                                                <td className="zero-dark-grey break-word">{incident.incident_injury?.name ?? ''}</td>
                                                {
                                                    this.props.event_types.length > 0 &&
                                                    <td className="zero-dark-grey">{safe_get(incident, "event_type.name", "")}</td>
                                                }
                                                {
                                                    this.props.incidents.length > 0 && this.state.customField1 && this.state.customField1.enabled &&
                                                    <td className="zero-dark-grey">{safe_get(incident, "custom_field_1.label", "")}</td>
                                                }
                                                {
                                                    this.props.incidents.length > 0 && this.state.customField2 && this.state.customField2.enabled &&
                                                    <td className="zero-dark-grey">{safe_get(incident, "custom_field_2.label", "")}</td>
                                                }
                                                {
                                                    this.props.incidents.length > 0 && this.state.customField3 && this.state.customField3.enabled &&
                                                    <td className="zero-dark-grey">{safe_get(incident, "custom_field_3.label", "")}</td>
                                                }
                                                {
                                                    this.props.incidents.length > 0 && this.state.customField4 && this.state.customField4.enabled &&
                                                    <td className="zero-dark-grey">{safe_get(incident, "custom_field_4.label", "")}</td>
                                                }
                                                <td>
                                                        <span className="zero-dark-grey">
                                                            {
                                                                incident.corrective_actions_count > 0 ?
                                                                    <button className="ButtonLink btn-link"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    selected_incident: incident.incident_uuid,
                                                                                    showCorrectiveActionsModal: true
                                                                                })
                                                                            }}>Yes
                                                                        ({incident.corrective_actions_count})</button> : "No"
                                                            }
                                                        </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.props.loading && this.props.incidents.length === 0 &&
                                    <tbody>
                                    <tr>
                                        <td className="react-bs-table-no-data" colSpan="11"
                                            style={{color: 'var(--zero-dark-grey)'}}>
                                            No incidents.
                                        </td>
                                    </tr>
                                    </tbody>
                                }

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", {}),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        current_location: safe_get(store, "incidents.location", {}),
        incidents_locations: safe_get(store, "incidents.locations", []),
        incident_types: safe_get(store, "incidents.incidentTypes", []),
        event_types: safe_get(store, "incidents.eventTypes", []),
    }
}

export default withRouter(connect(mapStateToProps)(IncidentsTable));