import React, {Component} from 'react';

import Skeleton from 'react-loading-skeleton';
import '../../assets/css/Library.css';

class BulletinNewPostSkeleton extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="" style={{margin: '0 auto'}}>
                <div className="panel bulletin-make-border thin-border">
                    <div className="panel-body bulletin-post">
                        <div className="media-body">
                            <div className="panel-heading text-center">
                                <h3 className="titles pull-left"
                                    style={{marginTop: '10px', marginLeft: '0px', marginRight: "8px"}}>
                                    <Skeleton width={98}/>
                                </h3>
                                <span className="pull-left"> 
                                    <Skeleton width={288} height={32}/>                                                    
                                </span>
                            </div>
                        </div>
                    </div>

                    <hr style={{marginBottom: '15px', marginTop: '5px'}}/>

                    <div className="panel-heading bulletin-post ">
                        <h3 className="bulletin-post-headers">
                            <Skeleton width={68}/>
                        </h3>
                    </div>
                    <div className="panel-body bulletin-post">
                        <Skeleton width={400} height={32}/>
                    </div>


                    <div className="panel-heading bulletin-post ">
                        <h3 className="bulletin-post-headers">
                            <Skeleton width={30}/>
                        </h3>
                    </div>
                    <div className="panel-body bulletin-post">
                        <Skeleton width={400} height={32}/>
                    </div>


                    <div className="panel-heading bulletin-post ">
                        <h3 className="bulletin-post-headers">
                            <Skeleton width={50}/>
                        </h3>
                    </div>
                    <div className="panel-body bulletin-post">
                        <Skeleton width={400} height={32}/>
                    </div>


                    <div className="panel-heading bulletin-post">
                        <h3 className="bulletin-post-headers ">
                            <Skeleton width={34}/>
                        </h3>
                    </div>
                    <div className="bulletin-post">
                        <div className="form-group" style={{marginBottom: '0px'}}>
                            <Skeleton height={33}/>
                        </div>
                    </div>

                    <div className="panel-heading bulletin-post">
                        <h3 className="bulletin-post-headers">
                            <Skeleton width={34}/>
                        </h3>
                    </div>
                    <div className="bulletin-post">
                        <div className="form-group" style={{marginBottom: '0px', position: "relative"}}>
                            <Skeleton height={110}/>
                        </div>
                    </div>


                    {/* <div className="ant-upload-list ant-upload-list-picture-card picture-offset-row" style={{marginTop: "10px", height: (attachmentsSectionHeight + "px")}}>                                              
                        {
                            this.state.attachments.map((file, index) => (
                                <div key={index} style={{float: "left"}}>
                                    {this.filesRender(file, index)}
                                </div>                               
                            ))
                        }                                                               
                    </div>  */}


                    <div className="panel-heading bulletin-post">
                        <h3 className="bulletin-post-headers">
                            <Skeleton width={50}/>
                        </h3>
                    </div>
                    <div className="panel-body bulletin-post">
                        <Skeleton height={32}/>
                    </div>

                    <div className="panel-heading bulletin-post">
                        <h3 className="bulletin-post-headers">
                            <Skeleton width={70}/>
                        </h3>
                    </div>
                    <div className="panel-body bulletin-post">    
                        <span style={{marginRight: "5px"}}>
                            <Skeleton width={75} height={26}/>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <Skeleton width={75} height={26}/>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <Skeleton width={75} height={26}/>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <Skeleton width={75} height={26}/>
                        </span>
                        <p className="zero-dark-grey">
                            <Skeleton width={250}/>
                        </p>
                    </div>

                    <div className="panel-heading bulletin-post" style={{marginBottom: "5px"}}>
                        <h3 className="bulletin-post-headers"><Skeleton width={80}/></h3>
                        <p className="zero-dark-grey" style={{marginBottom: "0px"}}>
                            <Skeleton width={350}/>
                        </p>
                        <div>
                            {
                                [...Array(5)].map((e, index) =>
                                        <span key={index} style={{marginRight: "5px"}}>
                                    <Skeleton circle width={26} height={26} inline/>
                                </span>
                                )
                            }
                        </div>
                    </div>

                    <div className="panel clear" style={{marginBottom: '5px', marginTop: "15px"}}>
                        <div className="row">
                            <div className="col-xs-6">
                                <Skeleton height={33}/>
                            </div>
                            <div className="col-xs-6">
                                <Skeleton height={33}/>
                            </div>
                        </div>
                    </div>

                    <div style={{marginBottom: "5px"}}>
                        <hr/>
                        <div style={{marginBottom: "5px", marginTop: "15px"}}>
                            <div className="panel-heading bulletin-post">
                                <h3 className="bulletin-post-headers"><Skeleton width={80}/></h3>
                            </div>
                            <p className="zero-dark-grey" style={{marginBottom: "0px"}}>
                                <Skeleton width={350}/>
                            </p>
                            <div style={{marginBottom: "5px"}}>
                                {
                                    [...Array(5)].map((e, index) =>
                                            <span key={index} style={{marginRight: "5px"}}>
                                        <Skeleton circle width={26} height={26} inline />
                                    </span>
                                    )
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default BulletinNewPostSkeleton;

