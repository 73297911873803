import {combineReducers} from '@reduxjs/toolkit';

import app from './AppReducer.js';
import org_helper from './OrgReducer.js';
import user from './UserHelperReducer.js';
import teams_helper from './TeamsHelperReducer.js';
import notifications from './NotificationsReducer.js';
import dashboard from './DashboardReducer.js';
import feed_helper from './FeedHelperReducer.js';
import forms from './FormsReducer.js';
import members_filter from './MembersFilterReducer.js';
import incidents from './IncidentsReducer.js';
import library from './LibraryReducer.js';
import checkInOptions from '../slices/checkInOptions';
import manageDesks from 'store/slices/manageDesks.js';
import manageCheckInAlerts from 'store/slices/manageCheckInAlerts.js';
import manageSpaces from 'store/slices/manageSpaces.js';

const reducers = combineReducers({
    app, org_helper, user, teams_helper,
    dashboard, feed_helper, forms, library, incidents,
    notifications, members_filter, checkInOptions,
    manageDesks, manageCheckInAlerts, manageSpaces
});

export default reducers;