import ButtonLoading from "components/Shared/ButtonLoading";
import { scrollToTop } from "other/Helper";

/**
 * @param {{
 *  isDiscarding: boolean,
 *  onDiscard: (event: any) => any,
 *  currentPage: number,
 *  pages: any[],
 *  setPage: (newPage: number) => any,
 *  onSubmit: () => any,
 *  isSubmitDisabled: boolean,
 *  isSubmitting: boolean,
 * }} props 
 */
export default function FormSubmissionControls({
    isDiscarding, onDiscard,
    currentPage, pages, setPage,
    onSubmit, isSubmitDisabled, isSubmitting
}) {
    return (
        <div
            className="panel clear mar-btm-10 mar-top-5 dont-print"
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
            }}
        >
            <button
                className="btn btn-discard pull-left"
                onClick={onDiscard}
                disabled={isDiscarding}
                style={{marginRight: "8px"}}
            >
                { isDiscarding ? <ButtonLoading/> : "Cancel" }
            </button>
            <div>
                {
                    currentPage > 0 &&
                    <button
                        className="btn btn-discard"
                        onClick={() => {
                            setPage(currentPage - 1);
                            scrollToTop("page-head");
                        }}
                        style={{marginRight: "8px"}}
                    >
                        Previous Page
                    </button>
                }
                {
                    currentPage < pages.length - 1 &&
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setPage(currentPage + 1);
                            scrollToTop("page-head");
                        }}
                    >
                        Next Page
                    </button>
                }
                {
                    currentPage === pages.length - 1 &&
                    <button
                        className="btn btn-primary"
                        onClick={onSubmit}
                        disabled={isSubmitDisabled}
                    >
                        { isSubmitting ? <ButtonLoading/> : "Submit" }
                    </button>
                }
            </div>
        </div>
    )
}