// @ts-check
import FormFieldsRenderer from "components/Forms/FormFieldsRenderer";
import { useZeroContext } from "components/ZeroContext";
import { generateFormPages, useSubmissionAutoSaveManager } from "other/forms";
import { useEffect, useMemo, useState } from "react";
import { actions } from "./reducer";
import { FormType } from "other/Constants";

/**
 * 
 * @param {{
 *  submission: object,
 *  dispatch: React.Dispatch<[string, any?]>,
 *  isEditable: boolean,
 * }} props 
 */
export default function PostFormEmbeddedForm({submission, dispatch, isEditable}) {
    const submissionId = submission.submission_uuid;
    const context = useZeroContext();
    const formService = context.services.forms;
    const [refreshKey, setRefreshKey] = useState(1);
    const [answers, autosaveManager] = useSubmissionAutoSaveManager({
        timeoutMs: 500,
        saveFn: async (answers) => {
            if (!submission.draft) {
                const updatedSubmission = {
                    ...submission,
                    fields: answers,
                };
                dispatch([actions.EMBEDDED_SUBMISSION_UPDATED, updatedSubmission]);
                context.kv.invalidate(submissionId);
                return answers;
            }

            const body = {
                commit: false,
                fields: answers,
            };
            const updatedSubmission = await formService.updateDraft(submissionId, body, FormType.POST, {syncNow: false});
            dispatch([actions.EMBEDDED_SUBMISSION_UPDATED, updatedSubmission]);
            return updatedSubmission.fields;
        }
    });

    useEffect(() => {
        setRefreshKey(rk => rk + 1);
    }, [submission]);

    const pages = useMemo(() => {
        return generateFormPages(submission.form.fields);
    }, [submission.form.fields])

    const form = submission.form;

    return (
        <>
            <FormFieldsRenderer
                refreshKey={refreshKey}
                className="pad-no"
                form_uuid={form.form_uuid}
                submission_uuid={submissionId}
                form_fields={form.fields}
                field_answers={answers ?? submission.fields}
                pages={pages}
                page={0}
                is_submission={isEditable}
                toolbar_items={[]}
                isSubmissionView={!isEditable}
                updateAnswers={(answers) => {
                    if (isEditable) {
                        autosaveManager.setAnswers(answers);
                    }
                }}
            />
        </>
    );
}