import React, {Component} from 'react';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {get_sessions} from '../../api/zero-api.js';
import {scrollToTop, mainContentContainerClass, safe_get} from '../../other/Helper.js'

import Profile from './AccountTabs/Profile.js';
import ProfileEdit from './AccountEdit.js';
import NotificationSettings from './AccountTabs/Notifications.js';
import Devices from './AccountTabs/Devices.js';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            profile_role: "",
            teams: [],
            account_uuid: "",
            activeTab: "profile",
            minContentHeight: "1000px",
            sessions: []
        };

        this.changeTab = this.changeTab.bind(this);
    }

    componentDidMount() {
        scrollToTop("page-content");

        this.getDeviceSessions();
        this.handleLocationChange();

        let sidebar = document.getElementById('inline-sidebar');
        if (sidebar) {
            let sidebarHeight = sidebar.clientHeight + 150 + "px";
            this.setState({minContentHeight: sidebarHeight});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.handleLocationChange();
        }
    }

    handleLocationChange() {
        if (this.props.location.search !== "") {
            var tab = this.props.location.search.split("?")[1].split("=")[1]
            if (tab === 'editProfile') {
                this.setState({
                    editProfile: true,
                    activeTab: 'profile'
                })
            } else {
                this.setState({activeTab: tab});
            }
        }
    }

    getDeviceSessions = () => {
        var self = this;
        get_sessions().then(function (success) {
            success.json().then(success => {
                var sessions = safe_get(success, "sessions", []);
                self.setState({sessions: sessions});
            });
        }, function (error) {
            console.log(error);
        });
    }

    changeTab(tab) {
        this.setState({activeTab: tab});
    }

    render() {

        let tabContentStyle = {border: "1px solid #e2e2e2"};
        if (this.state.activeTab === 'profile') {
            tabContentStyle = {
                backgroundColor: 'transparent',
                padding: 0
            }
        }

        return (
            <div className={mainContentContainerClass()} style={{minHeight: this.state.minContentHeight}}>

                <div id="page-content" className="no-padding-mobile-account">
                    <div className="row" style={{paddingTop: "10px"}}>
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">

                            {
                                this.props.match.params.user_uuid === this.props.current_user.uuid &&
                                <div className="tab-base " style={{maxWidth: '850px', margin: '0 auto'}}>
                                    <ul className="nav nav-tabs">
                                        <li className={this.state.activeTab === "profile" ? "active" : ""}>
                                            <button className="ButtonLink" onClick={() => {
                                                this.changeTab("profile")
                                            }}>My Profile
                                            </button>
                                        </li>
                                        <li className={this.state.activeTab === "notification_settings" ? "active" : ""}>
                                            <button className="ButtonLink" onClick={() => {
                                                this.changeTab("notification_settings")
                                            }}>Notifications
                                            </button>
                                        </li>
                                        <li className={this.state.activeTab === "devices" ? "active" : ""}>
                                            <button className="ButtonLink" onClick={() => {
                                                this.changeTab("devices")
                                            }}>Devices
                                            </button>
                                        </li>
                                    </ul>


                                    <div className="tab-content" style={tabContentStyle}>
                                        {
                                            this.state.activeTab === "profile" &&
                                            <div id="profile-tab" className={"tab-pane fade active in"}>
                                                {
                                                    !this.state.editProfile &&
                                                    <Profile user_uuid={this.props.match.params.user_uuid}
                                                             editProfile={() => {
                                                                 this.setState({editProfile: true})
                                                             }}/>
                                                }
                                                {
                                                    this.state.editProfile &&
                                                    <ProfileEdit user_uuid={this.props.match.params.user_uuid}
                                                                 completed={() => {
                                                                     this.setState({editProfile: false});
                                                                 }}/>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.activeTab === "notification_settings" &&
                                            <div id="notifications-tab" className={"tab-pane fade active in"}>
                                                <NotificationSettings sessions={this.state.sessions}
                                                                      editProfile={() => {
                                                                          this.setState({
                                                                              activeTab: "profile",
                                                                              editProfile: true
                                                                          })
                                                                      }}/>
                                            </div>
                                        }
                                        {
                                            this.state.activeTab === "devices" &&
                                            <div id="sessions-tab" className="tab-pane fade active in">
                                                <Devices sessions={this.state.sessions}/>
                                            </div>
                                        }
                                    </div>


                                </div>
                            }

                            {
                                this.props.match.params.user_uuid !== this.props.current_user.uuid &&
                                <Profile user_uuid={this.props.match.params.user_uuid}/>
                            }


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(Account);

// export default Account;