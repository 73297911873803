import { Fragment } from "react"

export default function AlternateWrapper({
    MainWrapper = Fragment,
    AltWrapper = Fragment,
    useAltWrapper = false,
    children,
    altProps = {},
    ...props
}) {
if (useAltWrapper) return <AltWrapper {...altProps}>{children}</AltWrapper>

return <MainWrapper {...props}>{children}</MainWrapper>
}