import React, {Component} from 'react';
import {connect} from 'react-redux';

import {safe_get, dateFormatterWithTimeNoYear, intToMonth} from '../../../other/Helper.js';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {CalendarOutlined, ReloadOutlined} from '@ant-design/icons'
import ReportScheduleModal from './ReportScheduleModal.js';

class ReportHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.downloadFormatter = this.downloadFormatter.bind(this);

    }


    // dateFormatter(report) {
    //     var date = report.created_at;
    //     var revisedDate = new Date(date * 1000);
    //     var revised = revisedDate.toDateString();
    //     var formatDate = revised.split(" ");       

    //     revised = formatDate[1] + " " + formatDate[2] + ", " + formatDate[3];

    //     return (
    //         <span key={report.created_at}>{revised}</span>
    //     )
    // }

    userFormatter(report) {
        var user = report.created_by;
        if (this.props.isForms || this.props.isFeed || this.props.isIncidents) {
            return (
                <div>
                    <p className="zero-blue mar-btm-0"
                       style={{textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{user.first_name} {user.last_name}</p>
                    <small className="zero-dark-grey">{dateFormatterWithTimeNoYear(report.created_at)}</small>
                </div>
            )
        }
        return (
            <span style={{textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{user.first_name} {user.last_name}</span>
        )
    }

    reportTypeFormatter(report) {
        let type = report.report_type;
        switch (type) {
            case "teams":
                type = "Team"
                break;
            case "directory":
                type = "Members"
                break;
            case "posts":
                type = "Feed"
                break;
            case "open_posts":
                type = "Open Posts"
                break;
            case "submission":
                type = "Template"
                break;
            case "post_notification":
                type = "Post Notification";
                break;
            default:
                break;
        }
        return (
            <span style={{textTransform: "capitalize"}}>{type} report</span>
        )
    }

    reportPeriodFormatter(report) {
        let params = report.report_params;
        let period = params.period;

        switch (period) {
            case "all_time":
                period = "All Time";
                break;
            case "month":
                period = `Monthly (${intToMonth(params.month)} ${params.year})`;
                break;
            case "quarter":
                period = `Quarterly (Q${params.quarter} ${params.year})`;
                break;
            case "annual":
                period = `Annual (${params.year})`;
                break;
            case "custom":
                let a = moment(params.from_date)
                let b = moment(params.to_date)
                if (a.diff(b, 'days') === -6) {
                    period = `Weekly (${a.format("MM/DD/YYYY")} - ${b.format("MM/DD/YYYY")})`;
                } else {
                    period = `Custom (${a.format("MM/DD/YYYY")} - ${b.format("MM/DD/YYYY")})`;
                }
                break;
            default:
                period = 'N/A';
                break;
        }

        return (
            <span style={{textTransform: "capitalize"}}>{period}</span>
        )
    }

    scheduleFormatter = (report) => {
        if (report.schedule && report.schedule.enabled) {
            let custom_start_time = moment.unix(report.schedule.start_utc_timestamp).format("h:mm a");

            return (
                <div>
                    <CalendarOutlined className="mar-rgt-5"/>
                    <button className="ButtonLink link-hover" onClick={() => {
                        this.setState({
                            showScheduleModal: true,
                            isCreate: false,
                            report_type_selected: report.report_type,
                            reportJSON: report.report_params,
                            history_uuid: report.history_uuid,
                            schedule: report.schedule
                        });
                    }}
                    >
                        <span
                            style={{textTransform: "capitalize"}}>{report.schedule.repeat_period}</span> at {custom_start_time}
                    </button>
                </div>
            )

        } else {
            return (
                <div>
                    <CalendarOutlined className="mar-rgt-5"/>
                    <button className="ButtonLink link-hover" onClick={() => {
                        this.setState({
                            showScheduleModal: true,
                            isCreate: true,
                            report_type_selected: report.report_type,
                            reportJSON: report.report_params,
                            history_uuid: report.history_uuid
                        });
                    }}
                    >
                        No
                    </button>
                </div>
            )
        }

    }

    downloadFormatter(report) {
        if (this.props.isIncidents) {
            return (
                <a className="btn-link" href={report.file_url}
                   target="_blank">{safe_get(report, "report_type", "Download")}</a>
            )
        } else if (this.props.isForms || this.props.isFeed || this.props.isIncidents) {
            return (
                <a className="btn-link" href={report.report_url} target="_blank">Download</a>
            )
        } else {
            return (
                <a className="btn-link" href={report.report_url}
                   target="_blank">{safe_get(report, "report_name", "Download")}</a>
            )
        }

    }

    render() {
        if (this.props.isForms || this.props.isFeed || this.props.isIncidents) {
            return (
                <div className="pad-btm " style={{maxWidth: '850px', margin: '0 auto'}}>
                    {
                        this.state.showScheduleModal &&
                        <ReportScheduleModal
                            show={this.state.showScheduleModal}
                            cancel={() => {
                                this.setState({showScheduleModal: false});
                            }}
                            isCreate={this.state.isCreate}
                            history_uuid={this.state.history_uuid}
                            schedule={this.state.schedule}
                            reportType={this.state.report_type_selected}
                            reportParams={this.state.reportJSON}
                            user={this.props.user}
                            refresh={() => this.props.refresh(false)}
                        />
                    }
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <button className="ButtonLink link-hover zero-light-blue mar-btm-10 mar-top-10 mar-lft-10"
                                onClick={this.props.back}>
                            Back to reports
                        </button>
                        <div className="hidden-xs" style={{display: 'flex', alignItems: 'center'}}>
                            <ReloadOutlined className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                            style={{fontSize: '18px'}} onClick={() => this.props.refresh(true)}/>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                            <thead style={{color: "#1D2A35"}}>
                            <tr>
                                <th className="zero-blue" style={{width: "180px"}}>
                                    Generated By
                                </th>
                                <th className="zero-blue" style={{width: "150px"}}>
                                    Report Type
                                </th>
                                <th className="zero-blue">
                                    Report Period
                                </th>
                                {
                                    (this.props.isForms || this.props.isFeed || this.props.isIncidents) &&
                                    <th className="zero-blue" style={{width: "180px"}}>
                                        Scheduled
                                    </th>
                                }

                                <th className="zero-blue" style={{width: "100px"}}>

                                </th>
                            </tr>
                            </thead>
                            {
                                !this.props.reports &&
                                <tbody>
                                {
                                    [...Array(10)].map((_item, index) => (
                                        <tr key={index} className="tr-hover">
                                            <td style={{width: "180px"}}>
                                                <Skeleton width={115}/>
                                                <Skeleton width={115}/>
                                            </td>
                                            <td style={{width: "150px"}}>
                                                <Skeleton width={120}/>
                                            </td>
                                            <td>
                                                <Skeleton width={150}/>
                                            </td>
                                            {
                                                (this.props.isForms || this.props.isFeed || this.props.isIncidents) &&
                                                <td style={{width: "180px"}}>
                                                    <Skeleton width={130}/>
                                                </td>
                                            }
                                            <td style={{width: "90px"}}>
                                                <Skeleton width={90}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                this.props.reports && this.props.reports.length > 0 &&
                                <tbody>
                                {
                                    this.props.reports.map((report, index) => (
                                        <tr key={index} className="tr-hover">
                                            <td style={{width: "200px"}}>{this.userFormatter(report)}</td>
                                            <td style={{color: "var(--zero-dark-grey)"}}>{this.reportTypeFormatter(report)}</td>
                                            <td style={{color: "var(--zero-dark-grey)"}}>{this.reportPeriodFormatter(report)}</td>
                                            {
                                                (this.props.isForms || this.props.isFeed || this.props.isIncidents) &&
                                                <td style={{color: "var(--zero-dark-grey)"}}>{this.scheduleFormatter(report)}</td>
                                            }
                                            <td>{this.downloadFormatter(report)}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                this.props.reports && this.props.reports.length === 0 &&
                                <tbody>
                                <tr>
                                    <td className="react-bs-table-no-data" colSpan="3"
                                        style={{color: 'var(--zero-dark-grey)'}}>
                                        No reports.
                                    </td>
                                </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <button className="ButtonLink link-hover zero-light-blue mar-btm-10 mar-top-10 mar-lft-10"
                            onClick={this.props.back}>
                        Back to reports
                    </button>
                    <div className="hidden-xs" style={{display: 'flex', alignItems: 'center'}}>
                        <ReloadOutlined className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                        style={{fontSize: '18px'}} onClick={() => this.props.refresh(true)}/>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                        <thead style={{color: "#1D2A35"}}>
                        <tr>
                            <th className="zero-blue" style={{width: "200px"}}>
                                Generated On
                            </th>
                            <th className="zero-blue" style={{width: "200px"}}>
                                Generated By
                            </th>
                            <th className="zero-blue">
                                Report
                            </th>
                        </tr>
                        </thead>
                        {
                            !this.props.reports &&
                            <tbody>
                            {
                                [...Array(10)].map((_item, index) => (
                                    <tr key={index} className="tr-hover">
                                        <td style={{width: "200px"}}>
                                            <Skeleton width={115}/>
                                        </td>
                                        <td style={{width: "200px"}}>
                                            <Skeleton width={130}/>
                                        </td>
                                        <td>
                                            <Skeleton width={270}/>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        }
                        {
                            this.props.reports && this.props.reports.length > 0 &&
                            <tbody>
                            {
                                this.props.reports.map((report, index) => (
                                    <tr key={index} className="tr-hover">
                                        <td style={{
                                            width: "200px",
                                            color: "var(--zero-dark-grey"
                                        }}>{dateFormatterWithTimeNoYear(report.created_at)}</td>
                                        <td style={{
                                            width: "200px",
                                            color: "var(--zero-dark-grey"
                                        }}>{this.userFormatter(report)}</td>
                                        <td>{this.downloadFormatter(report)}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        }
                        {
                            this.props.reports && this.props.reports.length === 0 &&
                            <tbody>
                            <tr>
                                <td className="react-bs-table-no-data" colSpan="3"
                                    style={{color: 'var(--zero-dark-grey)'}}>
                                    No reports.
                                </td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
    }
}

export default connect(mapStateToProps)(ReportHistory);
  