import React, {Component} from 'react';
import {connect} from 'react-redux';

import safe_get from '../../other/SafeGet.js';
import {scrollToTop, showFooterMobileApp, hideFooterMobileApp} from '../../other/Helper.js';
import {get_form} from '../../api/zero-api.js';
import FormSubmitComponent from './FormSubmitComponent.js';
import FormsCache from 'offline/FormsCache';
import { ZeroContext } from 'components/ZeroContext.js';

class EditSubmission extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        this.state = {
            tab: safe_get(this.props, "match.params.tab", "submit"),
            form: undefined,
            form_title: "",
            loading: true,
        };
    }

    componentDidMount() {
        scrollToTop("page-head");
        hideFooterMobileApp();
        this.getForm(this.props.match.params.form_uuid);
    }

    componentWillUnmount() {
        showFooterMobileApp();
    }

    getForm = async (form_uuid) => {
        try {
            const form = await this.context.services.forms.getForm(form_uuid);
            this.setState({
                form,
                edited_at: form?.edited_at,
                loading: false
            });
        } catch (error) {
            this.setState({loading: false});
        }
    }

    render() {
        if (this.state.loading) {
            return null
        } else {
            return (
                <FormSubmitComponent form_data={this.state.form} tab={this.state.tab} />
            );
        }
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        current_team: safe_get(store, "teams_helper.team", ""),
        teams: safe_get(store, "teams_helper.teams", []),
    }
}

export default connect(mapStateToProps)(EditSubmission);
  