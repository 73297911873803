import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Popover, Radio, Input} from 'antd'

import {CheckInUserType} from 'other/Constants';
import {updateCheckInState, resetCheckIn} from 'store/slices/checkInOptions';
import Directory from './Directory';

export default function UserTypePopup({directoryLoader, children}) {
    const currentUser = useSelector(state => state.user.user);
    const selectedUserType = useSelector(state => state.checkInOptions.selectedUserType);
    const guestName = useSelector(state => state.checkInOptions.guestName);
    const selectedUser = useSelector(state => state.checkInOptions.selectedUser);
    const dispatch = useDispatch();
    const {search} = useLocation();

    const [visible, setVisible] = useState(false);
    const [tmpUserType, setTmpUserType] = useState(selectedUserType);
    const [tmpGuestName, setTmpGuestName] = useState(guestName);
    const [tmpUser, setTmpUser] = useState(null);
    const [saveOnRender, setSaveOnRender] = useState(false);

    useEffect(() => {
        setTmpUserType(selectedUserType);
        setTmpGuestName(guestName);
        setTmpUser(selectedUser);
    }, [selectedUserType, guestName, selectedUser])

    useEffect(() => {
        if (saveOnRender) {
            save();
        }
    }, [saveOnRender])

    useEffect(() => {
        if (search && search.length > 0) {
            const params = new URLSearchParams(search);
            if (params.get('colleague') === '1') {
                setVisible(true);
                setTmpUserType(CheckInUserType.USER);
            }
            if (params.get('guest') === '1') {
                setVisible(true);
                setTmpUserType(CheckInUserType.GUEST);
            }
        }
    }, [search]);

    const isSaveDisabled = () => {
        if (tmpUserType === CheckInUserType.USER && !tmpUser) return true;
        if (tmpUserType === CheckInUserType.GUEST && tmpGuestName.length === 0) return true;
        return false;
    }

    const save = () => {
        setVisible(false);
        setSaveOnRender(false);

        const savedTmpUser = tmpUser;
        if (selectedUserType !== tmpUserType) {
            dispatch(resetCheckIn());
        } else if (tmpUserType === CheckInUserType.USER && savedTmpUser !== selectedUser) {
            dispatch(resetCheckIn());
        }

        dispatch(updateCheckInState({
            selectedUserType: tmpUserType,
            selectedUser: tmpUserType === CheckInUserType.USER ? savedTmpUser : currentUser,
            guestName: tmpUserType === CheckInUserType.GUEST ? tmpGuestName : ''
        }));
    }

    const onUserSelected = user => {
        setTmpUser(user);
        if (user) {
            setSaveOnRender(true);
        }
    }

    const content = (
        <>
            <div style={{marginBottom: '1rem'}}>
                <div className="text-semibold">Who are you checking in?</div>
            </div>
            <Radio.Group
                value={tmpUserType}
                onChange={e => setTmpUserType(e.target.value)}
                style={{marginBottom: '1rem', width: '100%', display: 'flex', justifyContent: 'space-between'}}
            >
                <Radio value={CheckInUserType.SELF}>Myself</Radio>
                <Radio value={CheckInUserType.USER}>Colleague</Radio>
                <Radio value={CheckInUserType.GUEST}>Guest</Radio>
            </Radio.Group>
            {tmpUserType === CheckInUserType.GUEST &&
                <Input
                    value={tmpGuestName}
                    onChange={e => setTmpGuestName(e.target.value)}
                    placeholder="Enter guest's full name"
                    maxLength={50}
                    style={{marginBottom: '1rem'}}
                />
            }
            {tmpUserType === CheckInUserType.USER &&
                <Directory
                    directoryLoader={directoryLoader}
                    allowSelection={true}
                    onUserSelected={onUserSelected}
                    enableFloorPlan={false}
                    hideUsersUntilSearch={true}
                    limit={5}
                    showCheckin={false}
                />
            }
            {tmpUserType !== CheckInUserType.USER &&
                <div>
                    <button
                        disabled={isSaveDisabled()}
                        onClick={save}
                        className="btn btn-primary"
                    >
                        Save
                    </button>
                </div>
            }
        </>
    );

    return (
        <Popover
            trigger="click"
            content={content}
            placement="bottom"
            overlayStyle={{width: 350}}
            open={visible}
            onOpenChange={setVisible}
        >
            {children}
        </Popover>
    )
}