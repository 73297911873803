const { FormType } = require("./Constants");

function Config() {
};

let production = window.location.href.includes("app.teamzero.com") || window.location.href.includes("zero-production.herokuapp.com") || window.location.href.includes("zero-beta.herokuapp.com");

// Only used when connecting to localhost server
const localServer = process.env.REACT_APP_LOCAL_SERVER === '1' && window.location.href.includes("localhost");

Config.BASE_URL = ""
Config.BASE_API_URL = "";
Config.WEBSOCKET_URL = "";
Config.NEWRELIC_ID = ""


if (production) {
    Config.BASE_API_URL = "https://api.teamzero.com";
    Config.WEBSOCKET_URL = "wss://mwc0yf4ce5.execute-api.us-east-1.amazonaws.com/production";
    Config.NEWRELIC_ID = "133550042";
} else if (localServer) {
    Config.BASE_API_URL = "http://localhost:8000";
    Config.WEBSOCKET_URL = "";
    Config.NEWRELIC_ID = "";
} else {
    Config.BASE_API_URL = "https://api-dev.teamzero.com";
    Config.WEBSOCKET_URL = "wss://d51tk6htkg.execute-api.us-east-1.amazonaws.com/dev";
    Config.NEWRELIC_ID = "133649449";
}

let bulletinMIME = [
    ".gif",
    ".jpeg",
    ".jpg",
    ".png",
    ".mpeg",
    ".mp4",
    ".mov",
    ".quicktime"
]

let libraryMIME = [
    "text/plain",
    "text/richtext",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

let base_report_year = 2018;

// var months = [
//   'January', 'February', 'March', 'April', 'May',
//   'June', 'July', 'August', 'September',
//   'October', 'November', 'December'
// ];

var months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];


module.exports = {
    BASE_API_URL: function () {
        return Config.BASE_API_URL;
    },

    WEBSOCKET_URL: function () {
        return Config.WEBSOCKET_URL;
    },

    NEW_RELIC: function () {
        return Config.NEWRELIC_ID;
    },

    BULLETIN_MIME: function () {
        return bulletinMIME.toString();
    },

    LIBRARY_MIME: function () {
        return libraryMIME.concat(bulletinMIME).toString();
    },

    REPORT_YEARS: function () {
        var report_years = [];
        var current_year = new Date().getFullYear();
        for (var i = base_report_year; i <= current_year; i++) {
            report_years.push(i)
        }
        return report_years;
    },

    REPORT_QUARTERS: function (report_years) {
        var now = new Date();
        var current_year = now.getFullYear();
        var current_quarter = Math.floor((now.getMonth() / 3)) + 1;
        var years = report_years;

        var quarters = []

        for (var i in years) {
            var year = years[i];

            if (year === current_year) {
                for (var j = 1; j <= current_quarter; j++) {
                    quarters.push("Q" + j + " " + year)
                }
            } else {
                quarters.push("Q1 " + year)
                quarters.push("Q2 " + year)
                quarters.push("Q3 " + year)
                quarters.push("Q4 " + year)
            }

        }

        return quarters.reverse();
    },

    REPORT_MONTHS: function (report_years) {
        var now = new Date();
        var current_month = now.getMonth();
        var current_year = now.getFullYear();
        var years = report_years;

        var available_months = []

        for (var i in years) {
            var year = years[i];

            if (year === current_year) {
                for (var j = 0; j <= current_month; j++) {
                    let month_name = months[j]
                    available_months.push(month_name + " " + year)
                }
            } else {
                for (var x in months) {
                    let month_name = months[x]
                    available_months.push(month_name + " " + year)
                }
            }
        }
        return available_months.reverse();
    },

    CompleteFormBuilderItems: function (formType) {
        return [
            {
                element: 'Header',
                text: 'Header Text',
                icon: 'FontColorsOutlined',
                static: true,
                required: false,
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                content: 'Header Text...',
            },
            {
                element: 'Paragraph',
                text: 'Paragraph Text',
                static: true,
                required: false,
                canHaveDisplayHorizontal: false,
                icon: 'AlignCenterOutlined',
                content: "<p>Paragraph text...</p>"
            },
            {
                required: false,
                element: 'RadioButtons',
                text: 'Multiple Choice',
                icon: 'OrderedListOutlined',
                label: 'Multiple Choice',
                field_name: 'radiobuttons_',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                options: [
                    {
                        "value": "1",
                        "text": "Response 1",
                        "key": "radiobuttons_option_1"
                    },
                    {
                        "value": "2",
                        "text": "Response 2",
                        "key": "radiobuttons_option_2"
                    },
                    {
                        "value": "3",
                        "text": "Response 3",
                        "key": "radiobuttons_option_3"
                    }
                ],
                is_scored: false,
            },
            {
                required: false,
                element: 'RadioButtons',
                text: 'Ranking Question',
                icon: 'OrderedListOutlined',
                label: 'Ranking Question',
                inline: true,
                field_name: 'radiobuttons_',
                disableAttachments: true,
                canHaveDisplayHorizontal: true,
                options: [
                    {
                        "value": "na",
                        "text": "N/A",
                        "key": "radiobuttons_option_na"
                    },
                    {
                        "value": "1",
                        "text": "1",
                        "key": "radiobuttons_option_1"
                    },
                    {
                        "value": "2",
                        "text": "2",
                        "key": "radiobuttons_option_2"
                    },
                    {
                        "value": "3",
                        "text": "3",
                        "key": "radiobuttons_option_3"
                    },
                    {
                        "value": "4",
                        "text": "4",
                        "key": "radiobuttons_option_4"
                    },
                    {
                        "value": "5",
                        "text": "5",
                        "key": "radiobuttons_option_5"
                    }
                ],
                is_scored: false,
            },
            {
                required: true,
                element: 'RadioButtons',
                audit: true,
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Audit (Yes, No, N/A)',
                icon: 'UnorderedListOutlined',
                label: 'Audit (Yes, No, N/A)',
                field_name: 'radiobuttons_',
                options: [
                    {
                        "value": "yes",
                        "text": "YES",
                        "key": "radiobuttons_option_yes"
                    },
                    {
                        "value": "no",
                        "text": "NO",
                        "key": "radiobuttons_option_no"
                    },
                    {
                        "value": "na",
                        "text": "N/A",
                        "key": "radiobuttons_option_na"
                    }
                ],
                is_scored: true,
            },
            {
                required: false,
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                element: 'Dropdown',
                text: 'Dropdown',
                icon: 'DownSquareOutlined',
                label: 'Dropdown',
                field_name: 'dropdown_',
                options: [
                    {
                        "value": "1",
                        "text": "Response 1",
                        "key": "dropdown_option_1"
                    },
                    {
                        "value": "2",
                        "text": "Response 2",
                        "key": "dropdown_option_2"
                    },
                    {
                        "value": "3",
                        "text": "Response 3",
                        "key": "dropdown_option_3"
                    }
                ],
                is_scored: false,
            },
            {
                required: false,
                element: 'Checkboxes',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Checkboxes',
                icon: 'CheckSquareOutlined',
                label: 'Checkboxes',
                field_name: 'checkboxes_',
                options: [
                    {
                        "value": "1",
                        "text": "Response 1",
                        "key": "checkboxes_option_1"
                    },
                    {
                        "value": "2",
                        "text": "Response 2",
                        "key": "checkboxes_option_2"
                    },
                    {
                        "value": "3",
                        "text": "Response 3",
                        "key": "checkboxes_option_3"
                    }
                ],
                is_scored: false,
            },
            {
                required: false,
                element: 'TextInput',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Text Input',
                label: 'Text Input',
                icon: 'BorderOutlined',
                field_name: 'text_input_',
            },
            {
                required: false,
                element: 'TextArea',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Multi-line Input',
                label: 'Multi-line Input',
                icon: 'AlignLeftOutlined',
                field_name: 'text_area_',
            },
            {
                required: false,
                element: 'NumberInput',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Number Input',
                label: 'Number Input',
                icon: 'NumberOutlined',
                field_name: 'number_input_',
            },
            {
                required: false,
                element: 'Attachments',
                text: 'Attachments',
                label: 'Attachments',
                icon: 'PictureOutlined',
                field_name: 'attachment_',
                readOnly: false,
                canHaveDisplayHorizontal: false,
                disableAttachments: true,
            },
            {
                element: 'FileUploads',
                text: 'File Upload',
                static: true,
                required: false,
                canHaveDisplayHorizontal: false,
                icon: 'PaperClipOutlined',
                content: 'File Upload',
                attachments: []
            },
            {
                required: false,
                element: 'DatePicker',
                dateFormat: 'MM/dd/yyyy',
                timeFormat: 'hh:mm aa',
                showTimeSelect: false,
                showTimeSelectOnly: false,
                readOnly: false,
                defaultToday: false,
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Date',
                icon: 'CalendarOutlined',
                label: 'Date',
                field_name: 'date_picker_',
            },
            {
                required: false,
                element: 'TimePicker',
                timeFormat: 'h:mm A',
                showTimeSelect: false,
                showTimeSelectOnly: false,
                readOnly: false,
                defaultToday: false,
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Time',
                icon: 'FieldTimeOutlined',
                label: 'Time',
                field_name: 'time_picker_',
            },
            {
                required: false,
                element: 'Signature',
                text: 'Signature',
                label: 'Signature',
                icon: 'EditOutlined',
                field_name: 'signature_',
                readOnly: false,
                canHaveDisplayHorizontal: false,
                disableAttachments: true,
            },

            {
                element: 'LineBreak',
                text: 'Line Break',
                static: true,
                required: false,
                canHaveDisplayHorizontal: false,
                icon: 'LineOutlined',
            },

            {
                element: 'Section',
                text: 'Section',
                label: 'Section Title',
                static: true,
                required: false,
                canHaveDisplayHorizontal: false,
                icon: 'ColumnHeightOutlined',
                children: [],
                disableAttachments: true,
            },

            formType !== FormType.POST && {
                element: 'PageBreak',
                text: 'Page Break',
                static: true,
                required: false,
                canHaveDisplayHorizontal: false,
                icon: 'CopyOutlined',
            },
            {
                required: false,
                element: 'DynamicListInput',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Dropdown (List API)',
                label: 'Dropdown (List API)',
                icon: 'DatabaseOutlined',
                field_name: 'dynamic_dropdown_',
            },
            {
                required: false,
                element: 'UserDropdown',
                disableAttachments: true,
                canHaveDisplayHorizontal: false,
                text: 'Dropdown (Directory)',
                label: 'Dropdown (Directory)',
                icon: 'UserOutlined',
                field_name: 'user_dropdown_',
            },


            // {
            //   required: false,
            //   element: 'Tags',
            //   canHaveDisplayHorizontal: false,
            //   disableAttachments: false,
            //   text: 'Tags',
            //   icon: 'TagsOutlined',
            //   label: 'Question or Title',
            //   field_name: 'tags_',
            //   options: [
            //     {
            //       "value": "1",
            //       "text": "Placeholder Option 1",
            //       "key": "tags_option_1"
            //     },
            //     {
            //       "value": "2",
            //       "text": "Placeholder Option 2",
            //       "key": "tags_option_2"
            //     },
            //     {
            //       "value": "3",
            //       "text": "Placeholder Option 3",
            //       "key": "tags_option_3"
            //     }
            //   ],
            // },


        ].filter(props => typeof props === "object");
    },

    ConfirmationFormBuilderItems: function () {
        return [
            {
                element: 'Paragraph',
                text: 'Paragraph Text',
                static: true,
                required: false,
                canHaveDisplayHorizontal: false,
                icon: 'AlignCenterOutlined',
                content: 'Paragraph placeholder text...',
            }
        ]
    }
}