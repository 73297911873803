import { QRCodeCanvas } from "qrcode.react";
import { useId } from "react";

export default function QRCode({link, downloadTitle, ...props}) {
    const canvasId = useId();

    const downloadQR = () => {
        const canvas = document.getElementById(canvasId);
        const downloadLink = document.createElement("a");
        downloadLink.href = canvas.toDataURL();
        downloadLink.download = downloadTitle || "ZeroQRCode.png";
        downloadLink.click();
    };

    return (
        <div {...props}>
            <div style={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem"
            }}>
                <QRCodeCanvas
                    value={link}
                    level={"M"}
                    includeMargin={false}
                    id={canvasId}
                />
                <span className="blue-link" onClick={downloadQR}>Download QR</span>
            </div>
        </div>
    )
}