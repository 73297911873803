import { DebugLogger } from "other/DebugLogger";
import {safe_get} from "other/Helper";
import dayjs from "dayjs";

const debugLogger = new DebugLogger('PostForm reducer');

const autoSaveEnabledState = {
    allowNav: false,
    shouldSave: true
};

function getDueDateError(state) {
    return state.dueDateError;
}

export function getCategoryError(state, categoryId) {
    if (!categoryId) { 
        return "";
    }
    const category = state.categories.find(c => c.category_uuid === categoryId);
    if (!category) {
        return "Category not found";
    }
    if (!category.enabled) {
        return "Category is disabled";
    }
    return "";
}

export function getTagError(state, tagId) {
    if (!tagId) { 
        return "";
    }
    const tag = state.tags.find(t => t.tag_uuid === tagId);
    if (!tag) {
        return "Tag not found";
    }
    if (!tag.enabled) {
        return "Tag is disabled";
    }
    return "";
}

export function getSubStatusError(state, statusId, group) {
    if (!statusId) { 
        return "";
    }
    const statuses = group === 2 ? state.statuses2 : state.statuses;
    const status = statuses.find(s => s.sub_status_uuid === statusId);
    if (!status) {
        return "Sub Status not found";
    }
    if (!status.enabled) {
        return "Sub Status is disabled";
    }
    return "";
}

const POST_ERROR_BE_TO_FE_FIELD_MAPPING = new Map([
    ["category_uuid", "categoryError"],
    ["tag_uuid", "tagError"],
    ["sub_status_uuid", "statusError"],
    ["custom_field_value", "customFieldError"],
    ["title", "titleError"],
    ["due_date", "dueDateError"],
])

/**
 * 
 * @param {Post} post 
 */
function extractErrorState(post) {
    const errorMessages = post.$meta?.error?.response?.errorMessages;
    if (!errorMessages) {
        return {};
    }

    const errorState = {};
    errorMessages.forEach(({field, message}) => {
        const feField = POST_ERROR_BE_TO_FE_FIELD_MAPPING.get(field);
        if (feField) {
            errorState[feField] = message;
        }
    });
    return errorState;
}

export const initialState = {
    postUuid: null,
    allowNav: true,
    shouldSave: false,
    flushInput: false, // input fields debounce their input, setting this to true will cause them to update immediately
    saving: false,
    showSavedText: false,
    isDraft: false,
    hasBeenUpdated: false,
    discarding: false,
    posting: false,
    navigateToFeed: false,

    loadingPost: false,
    postNotFound: false,
    loadingCategories: true,
    loadingTags: true,
    loadingStatuses: true,
    loadingStatuses2: true,
    loadingLocations: true,
    loadingRiskLevels: true,
    loadingResponders: false,
    loadingSubscribers: false,

    categories: [],
    tags: [],
    statuses: [],
    statuses2: [],
    locations: [],
    riskLevels: [],
    availableResponders: [],
    availableSubscribers: [],

    selectedTeamUuid: null,
    selectedCategory: null,
    selectedTag: null,
    selectedStatus: null,
    selectedStatus2: null,
    customFieldValue: '',
    title: '',
    body: '',
    attachments: [],
    selectedLocations: [],
    lat: '',
    lng: '',
    selectedRiskLevel: null,
    dueDateMoment: null,
    dueDateTimestamp: null,
    selectedResponders: [],
    selectedResponderUuids: [],
    selectedSubscribers: [],
    selectedSubscriberUuids: [],
    subscribeAll: false,
    autoClose: false,
    isUrgent: false,
    voiceNotifications: false,

    categoryError: '',
    dueDateError: '',
    statusError: '',
    status2Error: '',
    tagError: '',
    titleError: '',
    customFieldError: '',

    autoSaveState: {},

    embeddedForms: [],
    embeddedSubmissions: [],
};

export const actions = {
    UPDATE_STATE: '',
    TEAM_CHANGED: '',
    CATEGORY_CHANGED: '',
    TAG_CHANGED: '',
    STATUS_CHANGED: '',
    STATUS_2_CHANGED: '',
    CUSTOM_FIELD_CHANGED: '',
    TITLE_CHANGED: '',
    BODY_CHANGED: '',
    LOCATION_CHANGED: '',
    RISK_LEVEL_CHANGED: '',
    DUE_DATE_CHANGED: '',
    RESPONDERS_CHANGED: '',
    SUBSCRIBERS_CHANGED: '',
    SUBSCRIBE_ALL: '',
    AUTO_CLOSE_CHANGED: '',
    IS_URGENT_CHANGED: '',
    VOICE_NOTIFICATIONS_CHANGED: '',
    ATTACHMENTS_CHANGED: '',
    ADD_ATTACHMENT: '',

    POST_LOADED: '',
    POST_SAVED: '',
    POST_NOT_SAVED: '',
    POST_NOT_FOUND: '',
    POST_CREATED: '',
    CATEGORIES_LOADED: '',
    TAGS_LOADED: '',
    STATUSES_LOADED: '',
    STATUSES_2_LOADED: '',
    LOCATIONS_LOADED: '',
    RISK_LEVELS_LOADED: '',
    RESPONDERS_LOADED: '',
    SUBSCRIBERS_LOADED: '',
    EMBEDDED_SUBMISSIONS_LOADED: '',
    EMBEDDED_SUBMISSION_UPDATED: '',

    SET_AUTOSAVE: '',

    LOADING_RESPONDERS_AND_SUBSCRIBERS: '',
    LOADING_POST: '',
    SAVING_POST: '',

    SET_ERRORS: '',
    FLUSH_INPUT: '',
    SET_DISCARDING: '',
};

for (let key in actions) {
    actions[key] = key;
}

export function reducer(state, [type, payload]) {
    debugLogger.log('type: %s\npayload: %o\nstate: %o', type, payload, state);

    switch (type) {
        case actions.UPDATE_STATE: {
            return {
                ...state,
                ...payload,
            };
        }
        case actions.TEAM_CHANGED:
            return {
                ...state,
                selectedTeamUuid: payload
            };
        case actions.CATEGORY_CHANGED:
            return {
                ...state,
                selectedCategory: payload,
                categoryError: getCategoryError(state, payload),
                ...state.autoSaveState
            }
        case actions.TAG_CHANGED:
            return {
                ...state,
                selectedTag: payload,
                tagError: getTagError(state, payload),
                ...state.autoSaveState
            }
        case actions.STATUS_CHANGED:
            return {
                ...state,
                selectedStatus: payload,
                statusError: getSubStatusError(state, payload, 1),
                ...state.autoSaveState
            }
        case actions.STATUS_2_CHANGED:
            return {
                ...state,
                selectedStatus2: payload,
                status2Error: getSubStatusError(state, payload, 2),
                ...state.autoSaveState
            }
        case actions.CUSTOM_FIELD_CHANGED:
            return {
                ...state,
                customFieldValue: payload,
                ...state.autoSaveState
            }
        case actions.TITLE_CHANGED:
            return {
                ...state,
                title: payload,
                ...state.autoSaveState
            }
        case actions.BODY_CHANGED: {
            return {
                ...state,
                body: payload,
                ...state.autoSaveState
            }
        }
        case actions.LOCATION_CHANGED:
            let newState = {};

            if (Array.isArray(payload)) {
                newState = {
                    selectedLocations: payload,
                    lat: '',
                    lng: '',
                }
            } else {
                newState = payload
            }

            return {
                ...state,
                ...newState,
                ...state.autoSaveState
            }
        case actions.RISK_LEVEL_CHANGED:
            return {
                ...state,
                selectedRiskLevel: payload,
                ...state.autoSaveState
            }
        case actions.DUE_DATE_CHANGED: {
            return {
                ...state,
                dueDateMoment: payload,
                dueDateTimestamp: payload ? dayjs(payload).unix() : null,
                dueDateError: getDueDateError(state, payload),
                ...state.autoSaveState
            }
        }
        case actions.RESPONDERS_CHANGED: {
            const newResponderUuids = payload;

            let selectedResponders = [];
            let selectedResponderUuids = [];

            let selectedSubscribers = [...state.selectedSubscribers];
            let selectedSubscriberUuids = [...state.selectedSubscriberUuids];

            newResponderUuids.map(uuid => {
                const user = state.availableResponders.find(user => user.uuid === uuid);
                if (user) {
                    selectedResponderUuids.push(uuid);
                    selectedResponders.push(user);

                    if (selectedSubscriberUuids.indexOf(uuid) === -1) {
                        selectedSubscriberUuids.push(uuid);
                        selectedSubscribers.push(user);
                    }
                }
            });

            return {
                ...state,
                selectedResponders,
                selectedResponderUuids,
                selectedSubscribers,
                selectedSubscriberUuids,
                ...state.autoSaveState
            };
        }
        case actions.SUBSCRIBERS_CHANGED: {
            const newSubscriberUuids = payload;

            let selectedSubscribers = [];
            let selectedSubscriberUuids = [];

            newSubscriberUuids.map(uuid => {
                const user = state.availableSubscribers.find(user => user.uuid === uuid);
                if (user) {
                    selectedSubscriberUuids.push(uuid);
                    selectedSubscribers.push(user);
                }
            });

            return {
                ...state,
                selectedSubscribers,
                selectedSubscriberUuids,
                subscribeAll: false,
                ...state.autoSaveState
            };
        }
        case actions.ATTACHMENTS_CHANGED:
            return {
                ...state,
                attachments: payload,
                ...state.autoSaveState
            }
        case actions.ADD_ATTACHMENT:
            return {
                ...state,
                attachments: [
                    ...state.attachments,
                    payload,
                ],
                ...state.autoSaveState,
            }
        case actions.SUBSCRIBE_ALL:
            return {
                ...state,
                selectedSubscribers: state.availableSubscribers,
                selectedSubscriberUuids: state.availableSubscribers.map(user => user.uuid),
                subscribeAll: true,
                ...state.autoSaveState
            };
        case actions.POST_LOADED: {
            /** @type {Post} */
            const post = payload;

            const location = safe_get(post, 'location', '');
            const selectedLocations = location === '' ? [] : [location];

            const attachments = safe_get(post, "attachments", []);

            const severityLevel = safe_get(post, "severity_level", 0);
            const selectedRiskLevel = state.riskLevels.find(riskLevel => riskLevel.value === severityLevel);

            const sortByFullName = (a, b) => {
                const fullNameA = `${a.first_name || ''} ${a.last_name || ''}`.toLowerCase();
                const fullNameB = `${b.first_name || ''} ${b.last_name || ''}`.toLowerCase();
                return fullNameA > fullNameB ? 1 : -1;
            }

            const selectedSubscribers = safe_get(post, "subscribers", []);
            selectedSubscribers.sort(sortByFullName);
            const selectedSubscriberUuids = selectedSubscribers.map(user => user.user_uuid || user.uuid);

            const selectedResponders = safe_get(post, "assigns", []);
            const selectedResponderUuids = selectedResponders.map(user => user.user_uuid || user.uuid);

            const dueDate = safe_get(post, "due_date", null);
            
            const selectedCategory = post.category;
            const selectedTag = post.tag;
            const selectedStatus = post.sub_status;
            const selectedStatus2 = post.sub_status_2;

            const errorState = {
                ...extractErrorState(post),
                categoryError: getCategoryError(state, selectedCategory?.category_uuid),
                tagError: getTagError(state, selectedTag?.tag_uuid),
                statusError: getSubStatusError(state, selectedStatus?.sub_status_uuid, 1),
                status2Error: getSubStatusError(state, selectedStatus2?.sub_status_uuid, 2),
            };
            
            return {
                ...state,
                postUuid: post.post_uuid,
                loadingPost: false,
                allowNav: !post.has_been_updated,
                hasBeenUpdated: post.has_been_updated,
                isDraft: safe_get(post, 'status', '') === 'draft',
                selectedTeamUuid: post.source_team.team_uuid,
                selectedCategory: selectedCategory?.category_uuid ?? null,
                selectedTag: selectedTag?.tag_uuid ?? null,
                selectedStatus: selectedStatus?.sub_status_uuid ?? null,
                selectedStatus2: selectedStatus2?.sub_status_uuid ?? null,
                customFieldValue: safe_get(post, "custom_field_value", ""),
                attachments,
                title: safe_get(post, "title", ""),
                body: safe_get(post, "body", ""),
                selectedLocations,
                lat: safe_get(post, "coordinates.lat", ""),
                lng: safe_get(post, "coordinates.lon", ""),
                selectedRiskLevel,
                dueDateMoment: dueDate ? dayjs.unix(dueDate) : null,
                dueDateTimestamp: dueDate,
                dueDateError: getDueDateError(state, dueDate),
                selectedResponders,
                selectedResponderUuids,
                selectedSubscribers,
                selectedSubscriberUuids,
                subscribeAll: false,
                autoClose: false,
                isUrgent: false,
                voiceNotifications: false,
                autoClose: safe_get(post, 'auto_close', false),
                isUrgent: safe_get(post, 'is_urgent', false),
                voiceNotifications: safe_get(post, 'voice_notifications', false),
                embeddedForms: safe_get(post, '$meta.unsyncedEmbeddedForms', safe_get(post, 'embedded_forms', [])),
                $meta: post.$meta,
                ...errorState,
            }
        }
        case actions.POST_SAVED:
            return {
                ...state,
                saving: false,
                posting: false,
                showSavedText: true,
                flushInput: false,
                hasBeenUpdated: true,
            }
        case actions.POST_NOT_SAVED:
            return {
                ...state,
                saving: false,
                posting: false,
                showSavedText: false,
                flushInput: false,
            }
        case actions.POST_NOT_FOUND:
            return {
                ...state,
                postNotFound: true
            }
        case actions.POST_CREATED:
            return {
                ...state,
                loadingPost: false,
                isDraft: true,
                // postUuid: payload
            }
        case actions.AUTO_CLOSE_CHANGED:
            return {
                ...state,
                autoClose: payload,
                ...state.autoSaveState
            }
        case actions.IS_URGENT_CHANGED:
            return {
                ...state,
                isUrgent: payload,
                voiceNotifications: payload === false ? false : state.voiceNotifications,
                ...state.autoSaveState
            }
        case actions.VOICE_NOTIFICATIONS_CHANGED:
            return {
                ...state,
                voiceNotifications: payload,
                ...state.autoSaveState
            }
        case actions.CATEGORIES_LOADED:
            return {
                ...state,
                categories: payload,
                loadingCategories: false
            }
        case actions.TAGS_LOADED:
            return {
                ...state,
                tags: payload,
                loadingTags: false
            }
        case actions.STATUSES_LOADED:
            return {
                ...state,
                statuses: payload,
                loadingStatuses: false
            }
        case actions.STATUSES_2_LOADED:
            return {
                ...state,
                statuses2: payload,
                loadingStatuses2: false
            }
        case actions.LOCATIONS_LOADED:
            return {
                ...state,
                locations: payload,
                loadingLocations: false
            }
        case actions.RISK_LEVELS_LOADED:
            return {
                ...state,
                riskLevels: payload,
                loadingRiskLevels: false,
                selectedRiskLevel: state.selectedRiskLevel || (payload.length > 0 && payload[0]) || null
            }
        case actions.RESPONDERS_LOADED:
            return {
                ...state,
                availableResponders: payload,
                loadingResponders: false
            }
        case actions.SUBSCRIBERS_LOADED:
            return {
                ...state,
                availableSubscribers: payload,
                loadingSubscribers: false
            }
        case actions.EMBEDDED_SUBMISSIONS_LOADED:
            return {
                ...state,
                embeddedSubmissions: payload,
            }
        case actions.EMBEDDED_SUBMISSION_UPDATED:
            return {
                ...state,
                embeddedSubmissions: state.embeddedSubmissions.map(submission => {
                    if (submission.submission_uuid === payload.submission_uuid) {
                        return payload;
                    }
                    return submission;
                }),
            }
        case actions.SET_AUTOSAVE: {
            const enabled = payload;

            return {
                ...state,
                autoSaveState: enabled ? autoSaveEnabledState : {}
            }
        }
        case actions.LOADING_RESPONDERS_AND_SUBSCRIBERS:
            return {
                ...state,
                loadingResponders: true,
                loadingSubscribers: true
            }
        case actions.LOADING_POST:
            return {
                ...state,
                loadingPost: true,
            }
        case actions.SAVING_POST:
            return {
                ...state,
                saving: true,
                shouldSave: false,
            }
        case actions.SET_ERRORS:
            return {
                ...state,
                ...payload,
            }
        case actions.FLUSH_INPUT:
            return {
                ...state,
                flushInput: true
            }
        case actions.SET_DISCARDING:
            return {
                ...state,
                discarding: payload
            }
        default:
            console.error('Invalid reducer action:', type);
            return {};
    }
}