import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Bar} from 'react-chartjs-2';

import {get_categories_analytics_chart, notifyError} from '../../../api/zero-api.js'
import safe_get from '../../../other/SafeGet';
import Skeleton from 'react-loading-skeleton'

class TagsChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {},
            loading: true
        };

    }

    componentDidUpdate(prevProps) {
        if ((this.props.date_query !== prevProps.date_query) || this.props.feed_query !== prevProps.feed_query) {
            this.getAnalytics();
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;

        var feed_query = this.props.feed_query;
        var date_query = this.props.date_query;

        var query = "";
        if (date_query === "") {
            date_query = "&period=all_time";
        }

        if (this.props.isMyPostsFeed) {
            var filters = feed_query.split("?team_uuid=" + this.props.team_uuid);
            if (filters.length === 2) {
                filters = filters[1]
            } else {
                filters = "";
            }
            query = "?user_uuid=" + this.props.user.uuid + filters + date_query;
        } else {
            query = feed_query + date_query;
        }

        get_categories_analytics_chart(query).then(function (success) {
            success.json().then(success => {

                self.setState({
                    data: safe_get(success, "data", {}),
                    options: safe_get(success, "options", {}),
                    from_date: safe_get(success, "from_date", ""),
                    to_date: safe_get(success, "to_date", ""),
                    subtitle: safe_get(success, "subtitle", ""),
                    loading: false
                });
            });
        }, function (error) {
            notifyError(error);
            self.setState({loading: false});
        });

    }

    render() {
        return (
            <div className="col-lg-12 col-xs-12 col-reduce-padding">
                <div className="panel analytics-bottom-border">
                    <div className="panel-heading" style={{height: "25px"}}>
                        <h2 className="panel-title analytics" style={{display: 'inline-block', paddingRight: '0px'}}>
                            {(this.state.loading || this.props.loading) ?
                                <Skeleton width={250}/> : " Leading Posts vs. Total Posts "}
                        </h2>
                    </div>
                    <div className="pad-all no-padding-print print-chart-wrapper" style={{paddingTop: "0px"}}>
                        {
                            (this.state.loading || this.props.loading) ?
                                <Skeleton width={"100%"} height={266}/> :
                                <Bar height={100} data={this.state.data} options={this.state.options}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", "")
    }
}

export default connect(mapStateToProps)(TagsChart);

  
