export function update_main_loading(loading) {
    return {
        type: "update_loading",
        payload: loading
    }
}

export function is_desktop_size(value) {
    return {
        type: "is_desktop_size",
        payload: value
    }
}

export function update_kiosk_mode(payload) {
    return {
        type: "UPDATE_KIOSK_MODE",
        payload: payload
    }
}