import React, {Component} from 'react';


import {Select, Modal} from 'antd';

import {get_list_users, update_schedule, delete_assignment} from '../../../api/zero-api.js'
import {safeProfilePic, safe_get, isViewer, arrayIntersect} from '../../../other/Helper.js';

import NotificationAlert from '../../../other/NotificationAlert';
import ButtonLoading from '../../Shared/ButtonLoading.js';

class ReAssignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingUsers: true,
            schedule_teams: this.props.schedule.teams.map(team => team.team_uuid),
            existing_users_uuids: safe_get(this.props, "schedule.subscribers", []).map(user => user.user_uuid),
            available_users: [],
        };

    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        var self = this
        get_list_users("?status=active").then(function (success) {
            success.json().then(success => {
                var users = safe_get(success, "users", []);

                users = users.filter(function (user) {
                    let user_teams = user.teams.map(team => team.uuid)
                    let intersect = arrayIntersect(self.state.schedule_teams, user_teams)
                    return user.status === "active" && !isViewer(user) && intersect.length > 0 && !self.state.existing_users_uuids.includes(user.uuid) && user.uuid !== (self.props.reAssignUser.user_uuid || self.props.reAssignUser.uuid)

                }).sort(function (a, b) {
                    let a_name = (safe_get(a, "first_name", "") + " " + safe_get(a, "last_name", "")).toLowerCase();
                    let b_name = (safe_get(b, "first_name", "") + " " + safe_get(b, "last_name", "")).toLowerCase();
                    return a_name > b_name ? 1 : -1;
                });

                self.setState({
                    available_users: users,
                    loadingUsers: false
                });

            });
        }, function (error) {
            console.log(error)
        });
    }

    searchUsers = (inputValue, option) => {
        let input = inputValue.toLowerCase();
        let name = option.name.toLowerCase();
        let email = safe_get(option, "email", "").toLowerCase();
        return name.includes(input) || email.includes(input);
    }

    selectResponder = (user, option) => {
        var selected_users_uuids = [...this.state.existing_users_uuids]

        if (selected_users_uuids.indexOf(user.value) < 0) {
            selected_users_uuids.push(user.value)
        }

        this.setState({
            selected_users: user,
            selected_users_uuids: selected_users_uuids,
        });

    }

    assignResponders = () => {
        var self = this;

        this.setState({assigning_responders: true});

        let body = JSON.stringify({
            name: this.props.schedule.name,
            enabled: true,
            team_uuids: this.props.schedule.teams.map(team => team.team_uuid),
            form_uuid: this.props.schedule.form.form_uuid,
            user_uuids: this.state.selected_users_uuids,
            assigned_to_team: false,
            repeat_period: this.props.schedule.repeat_period,
            start_date: this.props.schedule.start_date,
            custom_start_time: this.props.schedule.custom_start_time,
            custom_start_timezone: this.props.schedule.custom_start_timezone,
            end_date: this.props.schedule.end_date
        });


        update_schedule(this.props.schedule.scheduler_uuid, body).then(function (success) {
            self.deleteAssignment();
        }, function (error) {
            NotificationAlert("success", "", "Unable to update schedule.");
            self.props.cancel();
        });

    }

    deleteAssignment = () => {
        let self = this
        delete_assignment(this.props.assignmentId).then(success => {
            self.props.refresh();
            self.props.cancel();
            NotificationAlert("success", "", "Assignment reassigned.");
        }, _error => {

        });
    }

    render() {
        return (
            (<Modal
                title={`Reassign Schedule Assignment`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.assignResponders}>
                            {
                                this.state.assigning_responders ? <ButtonLoading/> : "Reassign"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">The new responder will receive a notification they've been assigned.</p>
                <Select
                    id="invite-emails"
                    mode=""
                    virtual={false}
                    value={this.state.selected_users}
                    placeholder="Type a name..."
                    onChange={this.selectResponder}
                    showSearch
                    filterOption={this.searchUsers}
                    style={{width: '100%', display: 'block', margin: '0 auto'}}
                    className="user-select-dropdown"
                    labelInValue={true}
                    dropdownStyle={{zIndex: "10000"}}
                    loading={this.state.loadingUsers}
                    // notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                    optionLabelProp="user"
                >
                    {
                        this.state.available_users.map((user, index) => (
                            <Select.Option key={"user: " + user.uuid} value={user.uuid} user={
                                <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.first_name}</span>}
                                           name={`${user.first_name} ${user.last_name}`} email={user.email}>
                                {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                {user.first_name} {user.last_name} &bull; <span
                                style={{fontSize: '85%'}}>{user.email || user.title}</span>
                            </Select.Option>
                        ))
                    }
                </Select>
            </Modal>)
        );

    }

}

export default ReAssignModal;