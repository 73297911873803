import React, {Component} from 'react';

import {connect} from 'react-redux'

import {update_user_password} from '../../api/zero-api.js'
import {getErrorMessage, safe_get} from '../../other/Helper.js';

import PasswordRequirementsPopup from '../Shared/PasswordRequirementsPopup.js';
import NotificationAlert from '../../other/NotificationAlert.js';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: safe_get(this.props, "user", {}),
            old_password: "",
            new_password: "",
            confirm_password: ""
        };

        this.update_user = this.update_user.bind(this);
        this.oldPasswordChange = this.oldPasswordChange.bind(this);
        this.passwordMeetsRequirements = this.passwordMeetsRequirements.bind(this);
        this.confirmPasswordCheck = this.confirmPasswordCheck.bind(this);
    }

    oldPasswordChange(event) {
        this.setState({old_password: event.target.value});
    }

    passwordMeetsRequirements(value, new_password) {
        this.setState({passwordCheck: value, new_password: new_password});
    }

    confirmPasswordCheck(event) {
        var new_password = this.state.new_password;

        if (event.target.value !== new_password) {
            this.setState({passwordError: "Password does not match"});
        } else {
            this.setState({passwordError: null, confirm_password: event.target.value});
        }
    }

    update_user() {
        var self = this;
        var new_password = this.state.new_password;
        var confirm_password = this.state.confirm_password;

        if (new_password === confirm_password) {
            this.setState({passwordError: null})
            var body = JSON.stringify({
                password: this.state.old_password,
                new_password: new_password,
                new_password2: confirm_password
            });

            update_user_password(this.state.user.uuid, body).then(function (success) {
                success.json().then(success => {
                    NotificationAlert("success", "", "Password reset.");
                    self.props.completed();
                });
            }, function (error) {
                if (error.status === 422) {
                    error.json().then(error => {
                        let errorMsg = getErrorMessage(error, "Password does not meet requirements.")
                        self.setState({passwordError: errorMsg});
                    });
                }
            });
        } else {
            this.setState({passwordError: "Password does not match."})
        }

    }

    render() {

        return (

            <div className="panel article thin-border" style={{maxWidth: '800px'}}>
                <div className="account-header">
                    <h4 className="account-title pre" style={{fontSize: '160%'}}>Change Password</h4>
                    <p className="account-description pre" style={{fontSize: '100%'}}>Please complete the fields below
                        to set a new password.</p>
                </div>
                <hr className="bulletin" style={{maxWidth: '400px'}}/>
                <div className="panel-heading account">
                    <h3 className="titles account center-panel">Enter your old password</h3>
                </div>
                <div className="form-group">
                    <input type="password" id="old_password" className="form-control custom-placeholder"
                           onChange={this.oldPasswordChange} placeholder=""
                           style={{maxWidth: '215px', display: 'block', margin: '0 auto'}}/>
                </div>

                <div className="panel-heading account">
                    <h3 className="titles account center-panel">Create a new password</h3>
                </div>
                <div className="form-group ">
                    <PasswordRequirementsPopup placeholder=""
                                               passwordMeetsRequirementsCallback={this.passwordMeetsRequirements}
                                               customStyle={{margin: '0 auto', maxWidth: '215px'}}/>
                </div>

                <div className="panel-heading account">
                    <h3 className="titles account center-panel">Confirm your new password</h3>
                </div>
                <div className="form-group ">
                    <input type="password" placeholder=" " name="repeatpassword" id="confirm-new-password"
                           className="form-control signup-field custom-placeholder" onChange={this.confirmPasswordCheck}
                           required style={{maxWidth: '215px', margin: '0 auto'}}/>
                </div>
                {
                    this.state.passwordError &&
                    <small className="error text-center" style={{display: 'block'}}>{this.state.passwordError}</small>
                }

                <div className="panel clear pad-top" style={{maxWidth: '215px', margin: '0 auto'}}>
                    <div className="row">
                        <div className="col-xs-6">
                            <button onClick={this.props.back} className="btn btn-primary btn-block discard-article"
                                    style={{padding: "6px 10px"}}>Back
                            </button>
                        </div>
                        <div className="col-xs-6">
                            <button onClick={this.update_user} className="btn btn-primary btn-block">Save</button>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(ChangePassword);
