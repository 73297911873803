import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Link} from 'react-router-dom';

import {Popover} from 'antd'
import Skeleton from 'react-loading-skeleton'


import {assign_user, get_user, remove_user_from_team, subscribe_users, update_schedule} from '../../api/zero-api.js'

import {
    isViewer, isUser, isAdmin, isTeamLead, isContributor, isRestrictedTeamUser,
    safeProfilePic, safe_get
} from '../../other/Helper.js';
import NotificationAlert from "../../other/NotificationAlert";

class UserPopoverCard extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: this.props.user
        };
    }


    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        let prev_user_uuid = safe_get(prevProps, "user.uuid", null) || safe_get(prevProps, "user.user_uuid", null);
        let new_user_uuid = safe_get(this.props, "user.uuid", null) || safe_get(this.props, "user.user_uuid", null);

        if (prev_user_uuid !== new_user_uuid) {
            this.setState({
                user: this.props.user
            });
        }
    }

    getUser = () => {
        let self = this;
        get_user(this.props.user_uuid).then(success => {
            success.json().then(success => {
                let user = safe_get(success, "user", {});
                self.setState({user: user});
            });
        })
    }

    removeSubscriber = () => {
        let subscriber_uuids = [...this.props.subscribers].map(user => user.user_uuid || user.uuid)

        let index = subscriber_uuids.indexOf(this.state.user.user_uuid || this.state.user.uuid);
        if (index >= 0) {
            subscriber_uuids.splice(index, 1);
        }

        if (this.props.updateSubscribers) {
            this.props.updateSubscribers(subscriber_uuids);
            this.setState({popoverVisible: false});
        } else {
            let body = JSON.stringify({
                user_uuids: subscriber_uuids
            });

            subscribe_users(this.props.post_uuid, body).then(response => {
                this.setState({popoverVisible: false});
                if (this.props.refresh) {
                    this.props.refresh();
                }
            }).catch(error => {
                this.setState({assigning_subscribers: false});
                console.log(error)
            });
        }
    }

    removeResponder = () => {
        let self = this;

        let responders_uuids = [...this.props.responders].map(user => user.user_uuid || user.uuid)

        let index = responders_uuids.indexOf(this.state.user.user_uuid || this.state.user.uuid);
        if (index >= 0) {
            responders_uuids.splice(index, 1);
        }

        if (self.props.updateResponders) {
            self.props.updateResponders(responders_uuids);
        } else {
            let body = JSON.stringify({
                user_uuids: responders_uuids
            });

            assign_user(this.props.post_uuid, body).then(function (success) {
                self.setState({popoverVisible: false});
                if (self.props.refresh) {
                    self.props.refresh();
                }

            }, function (error) {
                self.setState({assigning_responders: false});
                console.log(error)
            });
        }
    }

    removeScheduleResponder = async () => {
        let self = this;

        let responders_uuids = [...this.props.responders].map(user => user.user_uuid || user.uuid)

        let index = responders_uuids.indexOf(this.state.user.user_uuid || this.state.user.uuid);
        if (index >= 0) {
            responders_uuids.splice(index, 1);
        }

        let body = JSON.stringify({
            name: this.props.schedule.name,
            enabled: true,
            team_uuids: this.props.schedule.teams.map(team => team.team_uuid),
            form_uuid: this.props.schedule.form.form_uuid,
            user_uuids: responders_uuids,
            assigned_to_team: false,
            repeat_period: this.props.schedule.repeat_period,
            start_date: this.props.schedule.start_date,
            custom_start_time: this.props.schedule.custom_start_time,
            custom_start_timezone: this.props.schedule.custom_start_timezone,
            end_date: this.props.schedule.end_date
        });

        try {
            this.setState({popoverVisible: false});

            const res = await update_schedule(this.props.schedule.scheduler_uuid, body);
            const data = await res.json();

            if (this.props.refresh) {
                this.props.refresh();
            } else if (this.props.setSchedule) {
                this.props.setSchedule(data);
            }
        } catch (error) {
            NotificationAlert("error", "", "Unable to update schedule.");
        }
    }

    removeUserFromTeam = () => {
        let self = this;

        let body = JSON.stringify({
            user_uuid: this.props.user.uuid
        });

        remove_user_from_team(this.props.team.uuid, body).then(success => {
            self.props.refresh();
            self.setState({popoverVisible: false});
        }, error => {

        });
    }

    handleVisibleChange = popoverVisible => {
        this.setState({popoverVisible});

        if (popoverVisible && !this.props.user && this.props.user_uuid) {
            this.getUser()
        }
    };

    renderUserCard = () => {
        return (
            <div style={{width: "190px"}}>
                {!this.state.user &&
                    <div style={{display: 'flex', gap: '0 1rem', alignItems: 'center'}}>
                        <Skeleton circle={true} width={50} height={50}/>
                        <div>
                            <Skeleton width={130}/>
                            <Skeleton width={130}/>
                        </div>
                    </div>
                }
                {this.state.user &&
                    <>
                        <div className="media">
                            <div className="media-left profile-img">
                                {safeProfilePic(safe_get(this.state, "user", {}), "img-sm img-circle user-card", "user-card")}
                            </div>
                            <div className="media-body account-card " style={{verticalAlign: 'middle'}}>
                                {
                                    // safe_get(this.state, "user.status", "") === "active" &&
                                    <div>
                                        <p className="text-md text-semibold profile truncate name zero-blue" style={{
                                            display: 'inline-block',
                                            marginBottom: '0px',
                                            marginLeft: '2px',
                                            maxWidth: "130px",
                                            fontSize: "13px",
                                            lineHeight: "1em"
                                        }}>
                                            {safe_get(this.state, "user.first_name", "")} {safe_get(this.state, "user.last_name", "")}
                                        </p>
                                        {
                                            isAdmin(this.state.user) ? <p className="role-text zero-dark-grey" style={{
                                                    fontSize: "0.9em",
                                                    lineHeight: "1em"
                                                }}><span className="badge badge-success badge-icon badge-fw " style={{
                                                    margin: "2px 5px 5px 2px",
                                                    width: "0.6em",
                                                    height: "0.6em"
                                                }}></span>Admin</p> :
                                                isTeamLead(this.state.user) ? <p className="role-text zero-dark-grey"
                                                                                 style={{
                                                                                     fontSize: "0.9em",
                                                                                     lineHeight: "1em"
                                                                                 }}><span
                                                        className="badge badge-info badge-icon badge-fw " style={{
                                                        margin: "2px 5px 5px 2px",
                                                        width: "0.6em",
                                                        height: "0.6em"
                                                    }}></span>Lead</p> :
                                                    isViewer(this.state.user) ? <p className="role-text zero-dark-grey"
                                                                                   style={{
                                                                                       fontSize: "0.9em",
                                                                                       lineHeight: "1em"
                                                                                   }}><span
                                                            className="badge badge-danger badge-icon badge-fw" style={{
                                                            margin: "2px 5px 5px 2px",
                                                            width: "0.6em",
                                                            height: "0.6em"
                                                        }}></span>Viewer</p> :
                                                        isContributor(this.state.user) ?
                                                            <p className="role-text zero-dark-grey"
                                                               style={{fontSize: "0.9em", lineHeight: "1em"}}><span
                                                                className="badge badge-warning badge-icon badge-fw"
                                                                style={{
                                                                    margin: "2px 5px 5px 2px",
                                                                    width: "0.6em",
                                                                    height: "0.6em"
                                                                }}></span>Contributor</p> :
                                                            isUser(this.state.user) ?
                                                                <p className="role-text zero-dark-grey"
                                                                   style={{fontSize: "0.9em", lineHeight: "1em"}}><span
                                                                    className="badge badge-purple badge-icon badge-fw "
                                                                    style={{
                                                                        margin: "2px 5px 5px 2px",
                                                                        width: "0.6em",
                                                                        height: "0.6em"
                                                                    }}></span>Member</p> :
                                                                <p className="role-text zero-dark-grey"
                                                                   style={{fontSize: "0.9em", lineHeight: "1em"}}><span
                                                                    className="badge badge-danger badge-icon badge-fw"
                                                                    style={{
                                                                        margin: "2px 5px 5px 2px",
                                                                        width: "0.6em",
                                                                        height: "0.6em"
                                                                    }}></span>Deactivated</p>
                                        }
                                        <p className="text-sm profile title truncate title zero-dark-grey">{safe_get(this.state, "user.title", "")}</p>
                                    </div>
                                }
                            </div>

                        </div>
                        <Link
                            to={"/" + this.props.org_uuid + "/users/" + (safe_get(this.state, "user.user_uuid", null) || safe_get(this.state, "user.uuid", null))}
                            style={{color: "white"}}>
                            <button className="btn btn-primary mar-top-10" style={{width: "100%"}}>
                                View Profile
                            </button>
                        </Link>
                        {
                            this.props.showRemoveResponder &&
                            <button className="ButtonLink zero-delete-red mar-top-10" style={{width: "100%"}}
                                    onClick={this.removeResponder}>
                                Remove as responder
                            </button>
                        }
                        {
                            this.props.showRemoveScheduleResponder &&
                            <button className="ButtonLink zero-delete-red mar-top-10" style={{width: "100%"}}
                                    onClick={this.removeScheduleResponder}>
                                Remove as responder
                            </button>
                        }
                        {
                            this.props.removeUserFromTeam &&
                            <button className="ButtonLink zero-delete-red mar-top-10" style={{width: "100%"}}
                                    onClick={this.removeUserFromTeam}>
                                Remove from team
                            </button>
                        }
                        {
                            this.props.showRemoveSubscriber &&
                            <button className="ButtonLink zero-delete-red mar-top-10" style={{width: "100%"}}
                                    onClick={this.removeSubscriber}>
                                Remove as subscriber
                            </button>
                        }
                        {
                            this.props.showRemoveAssignment &&
                            <button className="ButtonLink zero-delete-red mar-top-10" style={{width: "100%"}}
                                    onClick={this.removeSubscriber}>
                                Remove assignment
                            </button>
                        }
                        {
                            this.props.showRemoveAction &&
                            <button
                                className="ButtonLink zero-delete-red mar-top-10"
                                style={{width: "100%"}}
                                onClick={() => this.props.onRemoveUser(this.props.user)}
                            >
                                {this.props.removeActionText || "Remove"}
                            </button>
                        }
                        {
                            this.props.showExtraAction &&
                            <button
                                className="ButtonLink mar-top-10 link-hover"
                                style={{width: "100%"}}
                                onClick={() => {
                                    this.setState({popoverVisible: false});
                                    this.props.onExtraAction(this.props.user)
                                }}
                            >
                                {this.props.extraActionText || ""}
                            </button>
                        }
                    </>
                }
            </div>
        )
    }

    render() {
        return (
            <Popover trigger="click" placement="bottomLeft" content={this.renderUserCard}
                     style={{padding: "0", width: "150px"}} onOpenChange={this.handleVisibleChange}
                     open={this.state.popoverVisible}>
                {this.props.children}
            </Popover>
        )
    }

}

const mapStateToProps = store => {
    return {
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
    }
}
export default connect(mapStateToProps)(UserPopoverCard);
