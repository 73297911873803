import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import Tag from '../Tag.js'
import {safe_get, safeProfilePic, dateFormatterNoTime} from '../../other/Helper.js'

import {FileTextOutlined} from '@ant-design/icons';

import ZEROLogo from "../../assets/css/img/ZERO-(blue)-small.png"

class LibraryPost extends Component {
    constructor(props, context) {
        super(props, context);
        this.onClickTag = this.onClickTag.bind(this);
    }

    onClickTag(value) {
        // this.props.onTagSelect(value);
        return;
    }

    render() {
        let {article, org_uuid, current_team} = this.props
        let author = safe_get(article, "created_by", undefined)
        let authorName = `${safe_get(author, "first_name", "ZERO")} ${safe_get(author, "last_name", "")}`;

        let articleURL = `/${org_uuid}/home/team/${current_team.uuid}/${article.status}_library/article/${article.uuid}`

        return (
            <li>
                <Link to={articleURL}>
                    <div className="file-details" style={{marginRight: '10px'}}>
                        <div className="media-block">
                            <div className="media-left">
                                <FileTextOutlined/>
                            </div>
                            <div className="media-body">
                                <span className="file-name zero-blue"
                                      style={{paddingBottom: '5px'}}>{article.title}</span>
                                {
                                    author ?
                                        safeProfilePic(author, "img-sm img-circle article small", "article small") :
                                        <img src={ZEROLogo} className="img-sm img-circle article small"
                                             alt="Profile Pic"/>
                                }
                                <small>{authorName}</small>
                                <small> | {dateFormatterNoTime(this.props.article.created_at)}</small>
                                {
                                    article.status === "private" &&
                                    <small> | {article.viewers_count} {article.viewers_count === 1 ? "view" : "views"}</small>
                                }
                                <small className="pull-right hidden-xs">
                                    {
                                        article.tags.map((category, index) => (
                                            <Tag key={index} value={category} onClickTag={this.onClickTag}
                                                 bulletin={false}/>
                                        ))
                                    }
                                </small>
                                <small className="visible-xs mar-top">
                                    {
                                        article.tags.map((category, index) => (
                                            <Tag key={index} value={category} onClickTag={this.onClickTag}
                                                 bulletin={false}/>
                                        ))
                                    }
                                </small>
                            </div>
                        </div>
                    </div>
                </Link>
            </li>

        );
    }
}

// export default LibraryPost;


const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", "")
    }
}

export default connect(mapStateToProps)(LibraryPost);

