import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { get_top_posters, notifyError, } from '../../api/zero-api.js';
import { isAdmin, isContributor, isTeamLead, isViewer, safeProfilePic } from '../../other/Helper';
import safe_get from '../../other/SafeGet';

import { TrophyOutlined } from '@ant-design/icons';
import UserPopoverCard from '../Shared/UserPopoverCard.js';
import AnalyticsBox from './AnalyticsBox.js';

class TopPosters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {},
            topPosters: [],
            skeletonCount: 4,
            loading: true
        };
    }

    componentDidUpdate(prevPtops) {
        if ((this.props.date_query !== prevPtops.date_query) || (this.props.feed_query !== prevPtops.feed_query)) {
            this.getAnalytics();
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;

        var query = this.props.feed_query + this.props.date_query;

        if (query === "") {
            query = "?team_uuid=" + this.props.team_uuid + "&period=all_time";
        }

        this.setState({loading: true});

        get_top_posters(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    topPosters: safe_get(success, "top_posters", []),
                    loading: false
                });
            })
        }, function (error) {
            notifyError(error)
            self.setState({loading: false})
        })
    }

    renderRole = (user) => {
        let badgeClass = 'badge-purple';
        let label = 'Member';

        if (isAdmin(user)) {
            badgeClass = 'badge-success';
            label = 'Admin';
        } else if (isTeamLead(user)) {
            badgeClass = 'badge-info';
            label = 'Lead';
        } else if (isViewer(user)) {
            badgeClass = 'badge-danger';
            label = 'Viewer';
        } else if (isContributor(user)) {
            badgeClass = 'badge-warning';
            label = 'Contributor';
        }

        return (
            <p className="role-text zero-dark-grey" style={{fontSize: "0.9em"}}>
                <span className={`badge ${badgeClass} badge-icon badge-fw`} style={{margin: "4px 5px 5px 2px"}} />
                {label}
            </p>
        )
    }

    renderUser = (user) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                <div className="profile-img dont-print v-middle">
                    {safeProfilePic(user, "img-sm img-circle top-posters", "top-posters")}
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <UserPopoverCard user={user}>
                        <p className="text-md text-semibold profile truncate name link-hover zero-blue"
                        style={{
                            display: 'inline-block',
                            marginBottom: '0px',
                            marginLeft: '2px'
                        }}>
                            {user.first_name} {user.last_name}
                        </p>
                    </UserPopoverCard>
                    {this.renderRole(user)}
                </div>
            </div>
        )
    }

    render() {
        return (
            <AnalyticsBox
                fieldName="Top Posters"
                Icon={TrophyOutlined}
                extraGap
                noLabelParagraph
                loading={this.state.loading}
                items={this.state.topPosters.slice(0, 8).map(poster => ({
                    id: poster.user.uuid,
                    label: this.renderUser(poster.user),
                    value: poster.total_posts,
                }))}
            />
        )
    }
}

const mapStateToProps = store => {
    return {
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        user: safe_get(store, "user.user", {}),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", "")
    }
}

export default withRouter(connect(mapStateToProps)(TopPosters));
