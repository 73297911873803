import React, {Component} from 'react';
import {connect} from 'react-redux'

import LimitedInputField from '../../LimitedInputField.js';

import {create_organization, update_organization_uuid, get_list_teams} from '../../../api/zero-api.js'
import {safe_get, fieldIsNotEmpty, mainContentContainerClass, scrollToTop} from '../../../other/Helper';

import * as organizationActions from '../../../store/actions/OrganizationActions';
import * as teamsActions from '../../../store/actions/TeamsActions';

import NotificationAlert from '../../../other/NotificationAlert.js';

class NewOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: {},
            teams: [],
            inputLength: 0,
            characters: 30,
            inputLength2: 0,
            characters2: 125
        };

        this.cancel = this.cancel.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.createOrg = this.createOrg.bind(this);

    }

    componentDidMount() {
        scrollToTop("page-head");
    }

    cancel() {
        this.props.history.goBack();
    }

    onKeyDown(value) {
        // var length = this.refs.LimitedInput.state.input.length;
        this.setState({
            characters: 30 - value.length,
            inputLength: value.length
        });
    }

    createOrg() {
        var self = this;
        var org_name = document.getElementById('organization-name').value;

        if (fieldIsNotEmpty(org_name)) {
            var body = JSON.stringify({
                name: org_name
            });

            create_organization(body).then(function (success) {
                success.json().then(success => {

                    var organizations = self.props.organizations

                    var new_organization = safe_get(success, "organization", {});
                    var org_uuid = safe_get(success, "organization.organization_uuid", "undefined");

                    organizations.push(new_organization);

                    update_organization_uuid(org_uuid);
                    self.props.dispatch(organizationActions.update_current_org(new_organization));
                    self.props.dispatch(organizationActions.update_organizations(organizations));

                    self.getTeams(org_uuid);

                })
            }, function (error) {
                NotificationAlert("success", "", "Unable to create new account.")
            });
        } else {
            this.setState({nameError: "Please add a name for your organization."});
        }
    }


    getTeams = (org_uuid) => {
        let self = this;
        get_list_teams().then(function (success) {
            success.json().then(success => {
                let teams = safe_get(success, "teams", []);
                let team = teams[0];

                self.props.dispatch(teamsActions.update_teams(teams));
                self.props.dispatch(teamsActions.update_current_team(team));
                self.props.history.push("/" + org_uuid + "/home/team/" + team.uuid);
            })
        }, function (error) {
            console.log(error)
        });
    }

    render() {

        return (
            <div className={mainContentContainerClass()}>
                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: '850px', margin: '0 auto'}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Create a new account
                                            </h3>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            This page should only be used to create a completely separate instance of
                                            ZERO from the account you're currently in.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="page-content" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12 col-lg-offset-0 col-xs-12 col-xs-offset-0">
                            <div className="panel article thin-border" style={{maxWidth: '850px', margin: '0 auto'}}>
                                <div className="panel-body">
                                    <h3 className="titles"
                                        style={{marginBottom: '2px', marginLeft: '0px', fontSize: '110%'}}>New Account
                                        Name</h3>
                                    <LimitedInputField
                                        ref="LimitedInput"
                                        placeholder={""}
                                        type={"text"}
                                        id={"organization-name"}
                                        class={"form-control custom-placeholder"}
                                        lengthLimit={30}
                                        onKey={this.onKeyDown}/>
                                    {
                                        this.state.inputLength <= 30 &&
                                        <p className="text-sm" style={{
                                            color: '#BCBABC',
                                            paddingTop: '3px',
                                            float: 'right',
                                            display: 'inline-block'
                                        }}>{this.state.characters}</p>
                                    }
                                    {
                                        this.state.nameError &&
                                        <small className="error">{this.state.nameError}</small>
                                    }
                                </div>

                                <div className="panel clear mar-top">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <button onClick={this.cancel}
                                                    className="btn btn-primary btn-block discard-article"
                                                    style={{maxWidth: '120px', float: 'right'}}>Nevermind
                                            </button>
                                        </div>
                                        <div className="col-xs-6">
                                            <button onClick={this.createOrg} className="btn btn-primary btn-block"
                                                    style={{maxWidth: '120px'}}>Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        organizations: safe_get(store, "org_helper.organizations", [])
    }
}
export default connect(mapStateToProps)(NewOrganization);

