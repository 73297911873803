import React, {Component} from 'react';
import {connect} from 'react-redux'

import {get_users_analytics_panel, notifyError} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet';
import InfoIconComponent from '../InfoIconComponent.js';

import Skeleton from 'react-loading-skeleton';

class MembersAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            panels_info: {},
            loading_analytics: true,
            loading_panels: true,
            showChart: false
        };

    }

    componentDidUpdate(prevProps) {
        if ((this.props.team_uuid !== prevProps.team_uuid)
            || (this.props.membersFilter.length !== prevProps.membersFilter.length)
            || (this.props.locationId !== prevProps.locationId)) {
            this.getPanelsInfo();
        }
    }

    componentDidMount() {
        this.getPanelsInfo();
    }

    getPanelsInfo = () => {
        var self = this;
        this.setState({loading_panels: true});

        let roles = safe_get(this.props, "membersFilter", []).filter(item => ["admin", "team_lead", "user", "contributor", "viewer"].includes(item)).join(",")
        let search = safe_get(this.props, "membersFilter", []).filter(item => !["admin", "team_lead", "user", "contributor", "viewer"].includes(item)).join(",")
        const locationId = this.props.locationId;

        const query = new URLSearchParams();
        query.set('team_uuid', this.props.team_uuid);
        if (roles) query.set('roles', roles);
        if (search) query.set('search', search);
        if (locationId) query.set('location_uuid', locationId);


        get_users_analytics_panel(`?${query}`).then(function (success) {
            success.json().then(success => {
                self.setState({
                    total_users: safe_get(success, "total", 0),
                    pending: safe_get(success, "pending", 0),
                    mau: safe_get(success, "mau", 0),
                    dau: safe_get(success, "dau", 0),
                    participation_rate: safe_get(success, "participation_rate", 0),
                    loading_panels: false
                });
            });
        }, function (error) {
            notifyError(error);
            self.setState({loading_panels: false});
        });
    }


    render() {
        if (this.state.loading_panels) {
            return (
                // <LoadingIndicator small={true} panelStyle={{margin: "0px"}}/>
                <div className="panel" style={{margin: "0px"}}>
                    <div style={{padding: "10px"}}>
                        <div className="row justify-content-start">

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                    <Skeleton width={"70px"}/>
                                </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 " style={{fontSize: "3em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>    */}

                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="panel" style={{margin: "0px"}}>
                    <div style={{padding: "10px"}}>
                        <div className="row justify-content-start">

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px"}}>Registered Members</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>{this.state.total_users}</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Active
                                    Members <InfoIconComponent text={"Active last 30 days"} width={"120px"}
                                                               position={"top"}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.mau}</p>
                                </div>
                                {/* {
                                    this.props.team_uuid !== "my_teams" &&
                                    <a className="link-hover text-center" style={{color: '#03a9f4', display: "block"}} onClick={() => {this.setState({showChart : !this.state.showChart}); this.props.toggleChart()}}>{this.state.showChart ? "Hide" : "Show"} chart</a>
                                } */}
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <p className="text-sm text-bold zero-blue text-center"
                                   style={{margin: "5px"}}>Participation Rate <InfoIconComponent
                                    text={"% of total members that are active"} width={"120px"} position={"top"}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.participation_rate}<span
                                        style={{fontSize: "30%"}}>%</span></p>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 hover-cursor" onClick={() => {this.props.updatePeopleFilter("pending")}}>
                                <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Pending Members <InfoIconComponent text={"Invited but not yet registered"} width={"120px"} position={"top"}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0" style={{fontSize: "3em"}}>{this.state.pending}</p>
                                </div>                                
                            </div>   */}

                        </div>
                    </div>
                </div>
            );
        }

    }
}

const mapStateToProps = store => {
    return {
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
    }
}

export default connect(mapStateToProps)(MembersAnalytics);