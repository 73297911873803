import React from 'react';
import {Tag, Popover} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';

import FloorPlan from './FloorPlan';
import {CheckInLocale, CheckInStatus} from 'other/Constants';
import {safe_get} from 'other/Helper';

const tagStyle = {
    marginRight: 0,
    fontWeight: 400,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle'
}

const extractDeskName = (obj, enableFloorPlan, normalizedDeskName) => {
    let name;

    if (normalizedDeskName) {
        name = `${obj.location_name} / ${obj.space_name} / ${obj.desk_name}`;
    } else {
        name = `${obj.desk.space.location.name} / ${obj.desk.space.name} / ${obj.desk.name}`;
    }

    if (enableFloorPlan) {
        const floorPlan = safe_get(obj, 'floor_plan', safe_get(obj, 'desk.space.floor_plan', null));
        if (floorPlan) {
            return <FloorPlan floorPlan={floorPlan}>{name}</FloorPlan>
        }
    }

    return name;
}

export function CheckInTag({checkIn, style, enableFloorPlan, normalizedDeskName = true}) {
    let text = '';
    let color = 'green';
    let icon = <CheckCircleOutlined/>
    let withPopover = false;
    const now = Math.floor(Date.now() / 1000);

    if (checkIn.locale === CheckInLocale.OFFICE) {
        text = extractDeskName(checkIn, enableFloorPlan, normalizedDeskName);

        if (checkIn.status === CheckInStatus.PENDING) {
            color = 'gold';
            icon = null;
        } else if (checkIn.status === CheckInStatus.FAILED) {
            color = 'red';
            icon = null;
        }
    } else if (checkIn.locale === CheckInLocale.REMOTE) {
        text = "Remote";
    } else {
        text = "Other";
        withPopover = true;
    }

    const tag = <Tag color={color} style={tagStyle}>{icon} {text}</Tag>

    if (withPopover) {
        return (
            <div style={style}>
                <Popover content={<span>{checkIn.other_locale || "No description given."}</span>}>{tag}</Popover>
            </div>
        );
    }

    return <div style={style}>{tag}</div>
}

export default function DirectoryTag({user, style, enableFloorPlan = true}) {
    const checkIn = user.last_check_in;
    const assignedDesk = user.first_assigned_desk;

    if (!checkIn) {
        if (!assignedDesk) {
            return <div style={style}><Tag style={tagStyle}>Not checked in</Tag></div>;
        } else {
            return <div style={style}><Tag style={tagStyle}>{extractDeskName(assignedDesk, enableFloorPlan, true)}</Tag>
            </div>;
        }
    } else {
        return <CheckInTag checkIn={checkIn} style={style} enableFloorPlan={enableFloorPlan}/>
    }
}
