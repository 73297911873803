import SubmissionDraftsCache from "offline/SubmissionDraftsCache";
import React, {useCallback, useEffect, useState} from "react";
import {unstable_batchedUpdates} from "react-dom";
import {useSelector} from "react-redux";
import useDbChangeListener from "./useDbChangeListener";
import { FormType } from "other/Constants";

function getEditedAt(draft) {
    return draft.$editedAt || draft.edited_at || 0;
}

async function loadDrafts(cache) {
    const drafts = await cache.getAll();
    return drafts
        .filter(draft => draft.submission_uuid !== undefined)
        .filter(draft => !draft.$deleted || (draft.$deleted && draft.$error))
        // .filter(draft => !draft.form_type === FormType.POST)
        .sort((a, b) => getEditedAt(b) - getEditedAt(a));
}

export default function useSubmissionDraftsCache(isAppLoading, orgId, userId) {
    /** @type {useState<SubmissionDraftsCache>} */
    const [cache, setCache] = useState({});

    /** @type {useState<object[]>} */
    const [drafts, setDrafts] = useState([]);

    /** @type {useState<object[]>} */
    const [regularDrafts, setRegularDrafts] = useState([]);

    /** @type {useState<BaseCache_Blobs>} */
    const [blobs, setBlobs] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (orgId && userId && !isAppLoading) {
                const cache = new SubmissionDraftsCache(orgId, userId);
                window.zeroDebug_draftCache = cache;
                const drafts = await loadDrafts(cache);

                await cache.getAttachmentBlobs(drafts);

                unstable_batchedUpdates(() => {
                    setDrafts(drafts);
                    setCache(cache);
                });

                // cleanup
                return () => {
                    if (cache) {
                        for (const {url} of Object.values(cache.blobs)) {
                            URL.revokeObjectURL(url);
                        }
                    }
                }
            }
        }

        fetchData();
    }, [orgId, userId, isAppLoading, setCache]);

    const listenerCallback = useCallback(async () => {
        const drafts = await loadDrafts(cache);

        setDrafts(drafts);

        if (cache.onChangeCallbacks.length > 0) {
            const callbacks = [...cache.onChangeCallbacks];
            cache.onChangeCallbacks = [];

            for (const callback of callbacks) {
                callback();
            }
        }
    }, [cache, cache.onChangeCallbacks, getEditedAt, setDrafts]);

    useDbChangeListener(cache.db, 500, listenerCallback);

    useEffect(() => {
        if (cache.blobs !== undefined) {
            setBlobs(cache.blobs);
            for (const draft of drafts) {
                const combinedFields = [
                    ...draft.fields,
                    ...draft.form.fields
                ];

                for (const field of combinedFields) {
                    if ('attachments' in field) {
                        for (const attachment of field.attachments) {
                            const blobData = cache.blobs[attachment.attachment_uuid];
                            if (blobData?.url) {
                                attachment.public_url = blobData.url;
                            }
                        }
                    }
                }
            }
        }
    }, [cache.blobs, drafts, setBlobs]);

    useEffect(() => {
        setRegularDrafts(drafts.filter(draft => draft.form.form_type === FormType.REGULAR));
    }, [drafts]);

    return {drafts, regularDrafts, blobs, cache};
}
