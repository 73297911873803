import React, {Component} from 'react';
import {Modal} from 'antd'

import safe_get from '../../../other/SafeGet'

class CorrectiveActionTextModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: this.props.selectedAction
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateAction = this.updateAction.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const text = target.value;

        var action = this.state.action
        action["text"] = text;
        this.setState({action: action});
    }


    updateAction() {
        if (this.state.action.corrective_action_uuid) {
            this.props.saveAction(this.state.action.corrective_action_uuid, this.state.action.text)
        } else {
            this.props.addAction(this.state.action.text)
        }
        this.props.cancel()
    }


    render() {
        return (
            (<Modal
                title={safe_get(this.state, "action.corrective_action_uuid", "") ? "Edit Actions & Notes" : "Actions & Notes"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                focusTriggerAfterClose={true}
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.updateAction}>Save</button>
                    </div>
                }
            >
                <div>
            <textarea
                name="corrective_actions"
                className="form-control custom-placeholder"
                placeholder="Please describe the corrective action, countermeasure, or long-term improvement solution."
                onChange={this.handleInputChange}
                value={safe_get(this.state, "action.text", "")}
                rows="2"
                maxLength={2000}
            />
                </div>
            </Modal>)
        );
    }

}

export default CorrectiveActionTextModal;