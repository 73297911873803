import React, {Component} from 'react';
import {get_short_link} from "../api/zero-api";
import {mainContentContainerClass} from "../other/Helper";
import {Result} from "antd";

class LinkRedirect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            linkNotFound: false
        };
    }

    componentDidMount() {
        this.getShortLink(this.props.match.params.linkId);
    }

    getShortLink(linkId) {
        get_short_link(linkId)
            .then(success => {
                success.json().then(success => {
                    if (success.link) {
                        this.props.history.replace(success.link);
                    } else {
                        this.setState({linkNotFound: true});
                    }
                });
            })
            .catch(err => {
                this.setState({linkNotFound: true});
            });
    }

    render() {
        if (!this.state.linkNotFound) {
            return null;
        }
        return (
            <div id="content-container" className="container-with-header">
                <div className={mainContentContainerClass()} style={{marginLeft: "0px"}}>
                    <div id="page-content" style={{paddingTop: "10px"}}>
                        <div className="panel bulletin-post-border">
                            <Result
                                status="404"
                                title="404"
                                subTitle="Link not found"
                                extra={
                                    <button
                                        className="btn btn-primary"
                                        style={{
                                            marginTop: "8px",
                                            display: "block",
                                            margin: "auto",
                                            marginBottom: "10px"
                                        }}
                                        onClick={() => {
                                            window.location = "/";
                                        }}
                                    >Back to ZERO</button>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinkRedirect;
