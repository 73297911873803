import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {} from '../api/zero-api'
import {safe_get, monthToInt, intToMonth} from '../other/Helper.js';
import {REPORT_YEARS, REPORT_QUARTERS, REPORT_MONTHS} from '../other/Config.js'

import NotificationAlert from '../other/NotificationAlert';

import moment from 'moment';
import {Select, DatePicker} from 'antd';
import dayjs from "dayjs";

class TimePeriodFilter extends Component {
    constructor(props) {
        super(props);

        var today_moment = dayjs();
        var current_year = new Date().getFullYear();
        var available_years = REPORT_YEARS();
        var available_quarters = REPORT_QUARTERS(available_years);
        var available_months = REPORT_MONTHS(available_years);

        this.state = {
            value: [],
            activeRisk: undefined,
            activeTag: undefined,
            activeCategory: undefined,
            activeStatus: undefined,
            date_range_selected: "all_time",
            year: current_year,
            quarter: available_quarters[0],
            month: available_months[0],
            weekly: today_moment,
            custom_start: formatDate(new Date()),
            custom_start_moment: undefined,
            custom_end: formatDate(new Date()),
            custom_end_moment: undefined,
            report_years: available_years.reverse(),
            report_quarters: available_quarters,
            report_months: available_months,
            search_queries: [],
            filter_query: "",
            date_query: ""
        };

        this.addPrefilters = this.addPrefilters.bind(this);

        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleYearSelect = this.handleYearSelect.bind(this);
        this.handleQuarterSelect = this.handleQuarterSelect.bind(this);
        this.handleMonthSelect = this.handleMonthSelect.bind(this);
        this.handleWeekSelect = this.handleWeekSelect.bind(this);
        this.handleStartDateSelect = this.handleStartDateSelect.bind(this);
        this.handleEndDateSelect = this.handleEndDateSelect.bind(this);

        this.generateDateQuery = this.generateDateQuery.bind(this);

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
    }


    componentDidMount() {
        const search = this.props.location.search;
        let url = this.props.location.pathname
        if (!url.includes("incidents") || (search.includes('export=true') && this.props.period_query)) {
            this.addPrefilters();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.period_query !== this.props.period_query) {
            const pathname = this.props.location.pathname
            if (pathname.includes("/feed/tab/analytics") ||
                pathname.includes("/location/all_locations/analytics") ||
                pathname.includes("/location/all_locations/body") ||
                pathname.includes("/forms/analytics")) {
                this.addPrefilters();
            }
        }
    }

    addPrefilters() {
        var activeFilters = []

        var filters = this.props.period_query.substring(1).split("&");
        filters = filters.filter(function (n) {
            return n !== ""
        });

        var newState = {};
        for (var i in filters) {
            var filter = filters[i].split("=");
            var filter_type = filter[0];
            var filter_value = filter[1];

            switch (filter_type) {
                case "period":
                    newState["date_range_selected"] = filter_value;
                    this.props.periodSelected(filter_value);
                    break;
                case "year":
                    newState["year"] = filter_value;
                    break;
                case "quarter":
                    var quarter = "Q" + filter_value + " " + newState.year;
                    newState["quarter"] = quarter;
                    break;
                case "month":
                    var month = intToMonth(parseInt(filter_value)) + " " + newState.year
                    newState["month"] = month;
                    break;
                case "from_date":
                    newState["custom_start"] = filter_value;
                    newState["custom_start_moment"] = dayjs(filter_value);
                    break;
                case "to_date":
                    newState["custom_end"] = filter_value;
                    newState["custom_end_moment"] = dayjs(filter_value);
                    break;
                case "hide_shared":
                    activeFilters.push("Hide Shared");
                    break;
                default:
                    break;
            }

        }

        newState["activeFilters"] = activeFilters;
        this.setState(newState);
    }

    handleDateRangeChange(value) {
        this.props.periodSelected(value);
        this.setState({date_range_selected: value}, () => {
            if (value) {
                if (value !== "custom") {
                    this.generateDateQuery();
                }
                this.setState({
                    custom_start: "",
                    custom_end: ""
                });
            }
        });
    }

    handleYearSelect(value) {
        this.setState({year: value}, () => {
            this.generateDateQuery();
        });
    }

    handleQuarterSelect(value) {
        this.setState({quarter: value}, () => {
            this.generateDateQuery();
        });
    }

    handleMonthSelect(value) {
        this.setState({month: value}, () => {
            this.generateDateQuery();
        });
    }

    handleWeekSelect(value, dateString) {
        console.log(value, dateString)
        this.setState({weekly: value}, () => {
            this.generateDateQuery();
        });
    }

    handleDateRangeSelect = (dates, dateString) => {
        let custom_start_moment = dates[0];
        let custom_end_moment = dates[1];

        this.setState({
            custom_start_moment: custom_start_moment,
            custom_start: dayjs(custom_start_moment, 'YYYY/MM/DD').format("YYYY-MM-DD"),
            custom_end_moment: custom_end_moment,
            custom_end: dayjs(custom_end_moment, 'YYYY/MM/DD').format("YYYY-MM-DD")
        }, () => {

            var diff = custom_start_moment.diff(custom_end_moment, 'day');
            // console.log(diff)
            if (diff < 0 && diff > -185) {
                this.generateDateQuery();
            } else if (diff < -185) {
                this.setState({
                    custom_start_moment: undefined,
                    custom_start: "",
                    custom_end: "",
                    custom_end_moment: undefined,
                    range_error: true
                });
                // NotificationAlert("error", "", "Max range of 3 months");
            } else {
                NotificationAlert("error", "", "End Date has to be after Start Date");
            }

        });


    }

    handleStartDateSelect(value) {
        this.setState({
            custom_start_moment: value,
            custom_start: dayjs(value, 'YYYY/MM/DD').format("YYYY-MM-DD")
        }, () => {
            if (this.state.custom_end !== "" && value) {
                var diff = dayjs.duration(value.diff(this.state.custom_end_moment)).asDays();
                if (diff < -1 && diff > -185) {
                    this.generateDateQuery();
                } else if (diff < -185) {
                    this.setState({
                        custom_start_moment: undefined,
                        custom_start: "",
                        custom_end: "",
                        custom_end_moment: undefined,
                        range_error: true
                    });
                    // NotificationAlert("error", "", "Max range of 3 months");
                } else {
                    NotificationAlert("error", "", "End Date has to be after Start Date");
                }
            }
        })
    }

    handleEndDateSelect(value) {
        this.setState({custom_end_moment: value, custom_end: dayjs(value, 'YYYY/MM/DD').format("YYYY-MM-DD")}, () => {
            if (this.state.custom_start !== "" && value) {
                var diff = dayjs.duration(this.state.custom_start_moment.diff(value)).asDays();
                if (diff < -1 && diff > -185) {
                    this.generateDateQuery();
                } else if (diff < -185) {
                    this.setState({
                        custom_start_moment: undefined,
                        custom_start: "",
                        custom_end: "",
                        custom_end_moment: undefined,
                        range_error: true
                    });
                    // NotificationAlert("error", "", "Max range of 3 months");
                } else {
                    NotificationAlert("error", "", "End Date has to be after Start Date");
                }
            }
        });
    }

    disabledWeek(current) {
        // Can not select week after today 
        return current > dayjs().endOf('week')
    }

    disabledDate(current) {
        // Can not select day after tmrw -> this allows to search for today 
        let tomorrow = dayjs().add(1, 'days');
        return current > tomorrow.endOf('day');
    }

    generateDateQuery() {
        var query = ""
        switch (this.state.date_range_selected) {
            case "all_time":
                query = "&period=all_time";
                break;
            case "annual":
                query = "&period=annual&year=" + this.state.year;
                break;
            case "quarter":
                var range = this.state.quarter.split(" ");
                query = "&period=quarter&year=" + range[1] + "&quarter=" + range[0][1];
                break;
            case "month":
                var date = this.state.month.split(" ");
                query = "&period=month&year=" + date[1] + "&month=" + monthToInt(date[0]);
                break;
            case "weekly":
                const value = this.state.weekly;
                var from_date = value.startOf('week').format("YYYY-MM-DD");
                var to_date = value.endOf('week').format("YYYY-MM-DD");
                query = "&period=custom&from_date=" + from_date + "&to_date=" + to_date;
                break;
            case "custom":
                query = "&period=custom&from_date=" + this.state.custom_start + "&to_date=" + this.state.custom_end;
                break;
            default:
                break;
        }

        this.setState({date_query: query, range_error: false});
        this.props.updateQuery(query);
    }


    render() {
        const wrapperStyle = {
            marginLeft: (this.props.mobile ? "" : "1%"),
            marginTop: (this.props.mobile ? "8px" : "0"),
            display: "inline-block",
            width: (this.props.mobile ? "100%" : this.state.date_range_selected === "all_time" ? "19%" : this.state.date_range_selected === "custom" ? "46%" : "36%")
        };

        return (
            <div style={this.props.noWrapperStyle ? {} : wrapperStyle}>
                <Select className="period-select" value={this.state.date_range_selected}
                        style={{width: (this.state.date_range_selected === "all_time" ? "100%" : this.state.date_range_selected === "custom" ? "42%" : "50%")}}
                        onChange={this.handleDateRangeChange}>
                    <Select.Option value="all_time">All Time</Select.Option>
                    <Select.Option value="annual">Annually</Select.Option>
                    <Select.Option value="quarter">Quarterly</Select.Option>
                    <Select.Option value="month">Monthly</Select.Option>
                    <Select.Option value="weekly">Weekly</Select.Option>
                    <Select.Option value="custom">Custom Range</Select.Option>
                </Select>

                {
                    this.state.date_range_selected === "annual" &&
                    <div style={{marginLeft: '1%', display: "inline-block", width: "49%"}}>
                        <Select defaultValue={this.state.year} style={{width: "100%"}} onChange={this.handleYearSelect}>
                            {
                                this.state.report_years.map((year, index) => (
                                    <Select.Option key={index} value={year}>{year}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                }

                {
                    this.state.date_range_selected === "quarter" &&
                    <div style={{marginLeft: '1%', display: "inline-block", width: "49%"}}>
                        <Select defaultValue={this.state.quarter} style={{width: "100%"}}
                                onChange={this.handleQuarterSelect}>
                            {
                                this.state.report_quarters.map((quarter, index) => (
                                    <Select.Option key={index} value={quarter}>{quarter}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                }

                {
                    this.state.date_range_selected === "month" &&
                    <div style={{marginLeft: '1%', display: "inline-block", width: "49%"}}>
                        <Select className="month-select" value={this.state.month} style={{width: "100%"}}
                                onChange={this.handleMonthSelect}>
                            {
                                this.state.report_months.map((month, index) => (
                                    <Select.Option key={index} value={month}>{month}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                }
                {
                    this.state.date_range_selected === "weekly" &&
                    <div style={{marginLeft: '1%', display: "inline-block", verticalAlign: "bottom", width: "49%"}}>
                        <DatePicker.WeekPicker className="analytics-range-picker" value={this.state.weekly}
                                               style={{width: "100%", height: "32px"}} format={"w (YYYY)"}
                                               placeholder="Select a week" onChange={this.handleWeekSelect}
                                               disabledDate={this.disabledWeek} allowClear={false}/>
                    </div>
                }
                {
                    this.state.date_range_selected === "custom" &&
                    // <div style={{verticalAlign: "bottom", display: "inline-block", width: "65%"}}>
                    //     <div style={{marginLeft: '5px', display: "inline-block", width: "47%"}}>
                    //         <DatePicker placeholder="Start Date" className="custom-range-picker" style={{width: "100%" }} value={this.state.custom_start_moment} format={"MM/DD/YYYY"} onChange={this.handleStartDateSelect} disabledDate={this.disabledDate} allowClear={false}/>
                    //     </div>
                    //     <div style={{marginLeft: '5px', display: "inline-block",  width: "48%"}}>
                    //         <DatePicker placeholder="End Date" className="custom-range-picker" style={{width: "100%" }} value={this.state.custom_end_moment} format={"MM/DD/YYYY"} onChange={this.handleEndDateSelect} disabledDate={this.disabledDate} allowClear={false}/>
                    //     </div>
                    // </div>
                    <div style={{verticalAlign: "bottom", display: "inline-block", marginLeft: "1%", width: "57%"}}>
                        <DatePicker.RangePicker
                            className="custom-range-picker"
                            style={{width: "100%", height: "32px"}}
                            // value={this.state.custom_start_moment}
                            format={"MM/DD/YYYY"}
                            onChange={this.handleDateRangeSelect}
                            disabledDate={this.disabledDate}
                            allowClear={false}
                        />
                    </div>
                }
                {
                    this.state.range_error &&
                    <small className="error hidden-xs" style={{display: "block"}}>Max range of 6 months</small>
                }
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        user: safe_get(store, "user.user", {}),
    }
}

export default withRouter(connect(mapStateToProps)(TimePeriodFilter));


