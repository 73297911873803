import { Progress } from "antd";
import Attachment from "./Attachment";

function UploadingBlock({percent}) {
    const style = {
        marginRight: "5px",
        marginBottom: "5px",
        height: "100px",
        width: "100px",
        position: "relative",
        overflow: 'hidden',
        borderRadius: "2px",
        backgroundColor: "#f6f6f6",
        border: "1px dashed #e5e5e5",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <li style={style}>
            <span className="zero-blue">Uploading...</span>
            <Progress
                percent={percent}
                showInfo={false}
                style={{
                    padding: '0 1rem',
                    margin: 0,
                }}
            />
        </li>
    )
}

export default function AttachmentList({
    attachments = [],
    blobs,
    uploadingFileList = [],
    className = "",
    onPreview = () => {},
    onDelete = () => {},
}) {
    if (attachments.length === 0) {
        return null;
    }

    return (
        <div>
            <ul className={`thumb ${className}`}>
                {attachments.map((attachment, index) => (
                    <Attachment
                        key={attachment.attachment_uuid || index}
                        attachment={attachment}
                        blobs={blobs}
                        onPreview={() => onPreview(index)}
                        onDelete={() => onDelete(index)}
                        canDelete={typeof(onDelete) === 'function'}
                    />
                ))}
                {uploadingFileList.map(({key, percent}, index) => <UploadingBlock key={key ?? index} percent={percent} />)}
            </ul>
        </div>
    )
}