import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import {
    get_members_analytics,
    get_user,
    resend_invite,
    delete_pending_user,
    deactivate_user,
    notifyError
} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet';
import {safeProfilePic, isAdmin} from '../../other/Helper';

import InfoIconComponent from '../InfoIconComponent.js'

import ModalPopover from '../ModalPopover.js';
import ChangeRoleModal from '../Shared/ChangeRoleModal.js';
import ChangeTeamsModal from '../Shared/ChangeTeamsModal.js';

import NotificationAlert from '../../other/NotificationAlert'

import {Pagination} from 'antd'

import Skeleton from 'react-loading-skeleton'

import * as teamsActions from '../../store/actions/TeamsActions';

class MembersAnalyticsTable extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filter_query: "",
            pagination: 1,
            all_members: [],
            filtered_members: [],
        };

        this.userFormatter = this.userFormatter.bind(this);
        this.filterTable = this.filterTable.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.membersFilter !== prevProps.membersFilter) {
            if (this.props.membersFilter) {
                this.filterTable(this.props.membersFilter)
            }
        } else if (this.props.query !== prevProps.query
            || this.props.team_uuid !== prevProps.team_uuid
            || this.props.locationId !== prevProps.locationId) {
            this.setState({loading: true});
            this.getAnalytics();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;

        const query = new URLSearchParams();
        query.set('team_uuid', this.props.team_uuid);
        if (this.props.locationId) query.set('location_uuid', this.props.locationId);
        query.set('status', 'active');
        query.set('period', 'all_time');
        query.set('page', this.state.pagination);

        get_members_analytics(`?${query}${this.state.filter_query}`).then(function (success) {
            success.json().then(success => {
                var members = safe_get(success, "members", []).sort(function (a, b) {
                    if (a.user.status > b.user.status) return 1;
                    if (a.user.status < b.user.status) return -1;

                    var a_name = a.user.first_name + " " + a.user.last_name;
                    var b_name = b.user.first_name + " " + b.user.last_name;

                    return a_name.toLowerCase() > b_name.toLowerCase() ? 1 : -1;
                });

                if (self._isMounted) {
                    self.setState({
                        all_members: members,
                        filtered_members: members,
                        total_users: success.total_members,
                        loading: false
                    }, () => {
                        // self.filterTable(safe_get(self, "props.membersFilter", ""))
                    });
                }

            });
        }, function (error) {
            notifyError(error);
            self.setState({loading_analytics: false});
        });
    }

    onChangePage = (page) => {
        this.setState({
            pagination: page,
            loading: true,
        }, () => {
            this.getAnalytics();
        });
    }

    filterTable(value) {
        // console.log(value)
        let query = ""
        let search_query = "";
        let status_query = "";
        let roles_query = [];
        let roles = ["admin", "team_lead", "user", "contributor", "viewer"];
        value.forEach(element => {
            if (roles.includes(element)) {
                roles_query.push(element)
            } else if (element === "pending") {
                status_query = "&status=pending";
            } else {
                search_query = "&search=" + element
            }
        });

        if (roles_query.length > 0) {
            roles_query = "&roles=" + roles_query.join(",")
            query += roles_query;
        }

        if (status_query.length > 0) {
            query += status_query;
        }

        if (search_query.length > 0) {
            query += search_query;
        }

        this.setState({filter_query: query, pagination: 1, loading: true}, () => {
            this.getAnalytics();
        })

        // var filterListMembers = [...this.state.all_members];

        // var searchValue = value ? value.toLowerCase() : "";

        // if (searchValue === "admin") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         return object.user.roles.indexOf("admin") >= 0;
        //     });
        // }
        // else if (searchValue === "team_lead") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         return object.user.roles.indexOf("team_lead") >= 0;
        //     });
        // }
        // else if (searchValue === "user") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         return object.user.roles.indexOf("user") >= 0;
        //     });
        // }
        // else if (searchValue === "contributors") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         return object.user.roles.indexOf("user_restricted") >= 0;
        //     });
        // }
        // else if (searchValue === "viewer") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         return object.user.roles.indexOf("viewer") >= 0;
        //     });
        // }
        // else if (searchValue === "pending") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         return object.user.status.toLowerCase() === "pending";
        //     });
        // }
        // else if (searchValue !== "") {
        //     filterListMembers = filterListMembers.filter(function (object) {
        //         var name = object.user.first_name + " " + object.user.last_name;
        //         return name.toLowerCase().includes(searchValue) || object.user.email.toLowerCase().includes(searchValue)
        //     });
        // }
        // else {

        // }

        // this.setState({
        //     filtered_members: filterListMembers.slice(0,100),
        //     total_users: filterListMembers.length,
        //     pending_filter_active: false,
        //     role_filter_active: false,
        //     removeFilters: false
        // });
    }

    headerRenderer({className, columns, style}) {
        var new_columns = [];
        for (var i in columns) {
            var col = columns[i];
            if (i > 0) {
                col.props.style["textAlign"] = "center"
            }
            new_columns.push(col);
        }
        return (
            <div className={className} role='row' style={style}>
                {new_columns}
            </div>
        )
    }

    userFormatter(user) {
        return (
            <div className="media">

                <div className="media-left profile-img" style={{paddingRight: "5px"}}>
                    <Link to={`/${this.props.org_uuid}/feed/users/${user.uuid}`} className="btn-link zero-blue">
                        {safeProfilePic(user, "img-sm img-circle top-posters", "top-posters")}
                    </Link>
                </div>

                <div className="media-body account-card" style={{verticalAlign: (user.email ? "top" : "middle")}}>
                    <Link to={`/${this.props.org_uuid}/feed/users/${user.uuid}`} className="btn-link zero-blue">
                        <p className="text-md text-semibold profile truncate name link-hover zero-blue"
                           style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                            {user.first_name} {user.last_name}
                        </p>
                    </Link>
                    {
                        user.email &&
                        <p className="text-sm profile truncate email zero-dark-grey"
                           style={{display: 'block', fontSize: '.9em', opacity: 0.75}}>{user.email}</p>
                    }
                </div>
            </div>

        )
    }

    createdAtFormatter(user) {

        if (user.status.toLowerCase() === "pending") {
            return "N/A";
        } else {
            var date = user.created_at

            var revisedDate = new Date(date * 1000);
            var revised = revisedDate.toDateString();
            var formatDate = revised.split(" ");

            revised = formatDate[1] + " " + formatDate[2] + ", " + formatDate[3];

            return (
                <span>
          {formatDate[1] + " " + formatDate[2]}
                    <br/>
                    {formatDate[3]}
        </span>
            );
        }

    }

    lastActiveFormatter(user) {
        if (user.status.toLowerCase() === "pending" || user.last_activity_at === null || user.last_activity_at === undefined) {
            return "N/A";
        } else {
            var date = user.last_activity_at

            var revisedDate = new Date(date * 1000);
            var revised = revisedDate.toDateString();
            var formatDate = revised.split(" ");

            revised = formatDate[1] + " " + formatDate[2] + "\n" + formatDate[3];

            return (
                <span>
          {formatDate[1] + " " + formatDate[2]}
                    <br/>
                    {formatDate[3]}
        </span>
            );
        }

    }

    roleFormatter(user) {
        let roles = safe_get(user, "user.roles", []);

        if (roles.includes("admin")) {
            return (<p className="role-text"><span className="badge badge-success badge-icon badge-fw"></span>Admin</p>)
        } else if (roles.includes("team_lead")) {
            return (<p className="role-text"><span className="badge badge-info badge-icon badge-fw "></span>Lead</p>)
        } else if (roles.includes("user")) {
            return (
                <p className="role-text"><span className="badge badge-purple badge-icon badge-fw "></span>Member</p>)
        } else if (roles.includes("viewer")) {
            return (
                <p className="role-text"><span className="badge badge-danger badge-icon badge-fw "></span>Viewer</p>)
        } else if (roles.includes("user_restricted")) {
            return (<p className="role-text"><span className="badge badge-warning badge-icon badge-fw "></span>Contributor
            </p>)
        }
    }

    noDataRenderer() {
        return (
            <div className="mar-top">
                <h3 className="subtitle header">No members. Please clear filters.</h3>
            </div>
        )
    }

    loadingFormatter(column) {
        switch (column) {
            case 0:
                return (
                    <div className="media">
                        <div className="media-left profile-img">
                            <Skeleton circle={true} width={40} height={40}/>
                        </div>
                        <div className="media-body account-card">
                            <p className="text-md text-semibold profile  name "
                               style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                                <Skeleton width={130}/>
                            </p>
                            <p className="text-sm profile  email " style={{display: 'block', fontSize: '.9em'}}>
                                <Skeleton width={100}/>
                            </p>
                        </div>
                    </div>
                )
            case 1:
            case 2:
                return (
                    <Skeleton width={20}/>
                )
            case 3:
            case 4:
                return (
                    <div>
                        <Skeleton width={45}/>
                        <br/>
                        <Skeleton width={45}/>
                    </div>
                )
            case 5:
                return (
                    <Skeleton width={100}/>
                )
            default:
                break;
        }
    }

    render() {


        return (
            <div>

                <div className="panel thin-border mar-btm-0" style={{maxWidth: "850px", margin: "0 auto"}}>

                    <div className="table-responsive" style={{border: "none"}}>
                        <table className="table table-vcenter mar-btm-0" style={{tableLayout: "fixed"}}>
                            <thead>
                            <tr>
                                <th className="zero-blue" style={{width: "230px"}}>Name</th>
                                <th className="zero-blue text-center" style={{width: "100px"}}>Posts<br/>Created</th>
                                <th className="zero-blue text-center" style={{width: "100px"}}>Posts<br/>Shared</th>
                                <th className="zero-blue text-center" style={{width: "100px"}}>Member<br/>Since</th>
                                <th className="zero-blue text-center" style={{width: "100px"}}>Last<br/>Active</th>

                                <th className="zero-blue text-center" style={{width: "120px"}}>
                                    Role
                                    <InfoIconComponent
                                        position={"bottom"}
                                        width={"155px"}
                                        text={<span>To learn more about roles in ZERO, click <a
                                            rel="noopener noreferrer"
                                            onClick={() => FreshworksWidget('open', 'article', {'id': 70000417097})}
                                            className="blue-link">here</a>.</span>}
                                    />
                                </th>
                            </tr>
                            </thead>
                            {
                                this.state.loading &&
                                <tbody>
                                {
                                    [...Array(10)].map((_, index) => (
                                        <tr key={index} className="tr-hover">
                                            <td style={{width: "230px"}}>{this.loadingFormatter(0)}</td>
                                            <td className="text-center zero-dark-grey">{this.loadingFormatter(1)}</td>
                                            <td className="text-center zero-dark-grey">{this.loadingFormatter(2)}</td>
                                            <td className="text-center zero-dark-grey">{this.loadingFormatter(3)}</td>
                                            <td className="text-center zero-dark-grey">{this.loadingFormatter(4)}</td>
                                            <td>{this.loadingFormatter(5)}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                !this.state.loading && this.state.filtered_members.length > 0 &&
                                <tbody>
                                {
                                    this.state.filtered_members.map((user, index) => (
                                        <tr key={index} className="tr-hover"
                                            style={{backgroundColor: user.status === "deactivated" ? "#f6f6f6" : "white"}}>
                                            <td style={{width: "230px"}}>{this.userFormatter(user.user)}</td>
                                            <td className="text-center zero-dark-grey">{user.total_posts}</td>
                                            <td className="text-center zero-dark-grey">{user.total_shared}</td>
                                            <td className="text-center zero-dark-grey">{this.createdAtFormatter(user.user)}</td>
                                            <td className="text-center zero-dark-grey">{this.lastActiveFormatter(user.user)}</td>
                                            <td className="zero-dark-grey"
                                                style={{paddingLeft: "20px"}}>{this.roleFormatter(user)}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                !this.state.loading && this.state.filtered_members.length === 0 &&
                                <tbody>
                                <tr>
                                    <td className="react-bs-table-no-data zero-dark-grey" colSpan="5">
                                        No users.
                                    </td>
                                </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    <div style={{marginTop: "10px", marginBottom: "10px"}}>
                        <Pagination
                            size={"small"}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                            pageSize={50}
                            total={this.state.total_users}
                            current={this.state.pagination}
                            onChange={this.onChangePage}
                        />
                    </div>


                </div>


            </div>

        );


    }
}


const mapStateToProps = store => {
    return {
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", [])
    }
}

export default connect(mapStateToProps)(MembersAnalyticsTable);