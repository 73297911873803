import {CloseCircleFilled} from "@ant-design/icons";
import {getFileThumbnail, isImage, isVideo} from "other/Helper";
import VideoThumbnail from "./VideoThumbnail";


export default function Attachment({
    attachment, blobs, canDelete,
    onPreview, onDelete,
}) {
    const thumbnail = getFileThumbnail(attachment, blobs);
    const file_name = attachment.file_name;
    const deleteElement = canDelete ? <CloseCircleFilled onClick={onDelete} className="dont-print" style={{
        position: "absolute",
        right: "2px",
        top: "2px",
        zIndex: "1",
        backgroundColor: "white",
        borderRadius: "8px",
        color: "#1d2a35"
    }}/> : null;

        let element = null;

        if (isVideo(file_name)) {
            element = (
                <VideoThumbnail
                    imgSrc={thumbnail}
                    imgAlt={file_name}
                    onPlay={onPreview}
                />
            )
        } else if (isImage(file_name)) {
            element = (
                <button
                    className="ButtonLink"
                    onClick={onPreview}
                    style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "2px",
                        backgroundColor: "#f6f6f6",
                        border: "1px solid #e5e5e5"
                    }}
                >
                    <img className="post-img" src={thumbnail} alt={file_name} style={{objectFit: 'contain', width: "100%", height: "100%"}}/>
                </button>
            )
        } else {
            element = (
                <button className="ButtonLink" style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "2px",
                    backgroundColor: "#f6f6f6",
                    border: "1px solid #e5e5e5",
                    position: "absolute"
                }} onClick={onPreview}>
                    <img src={thumbnail} alt={file_name} style={{
                        objectFit: 'cover',
                        width: "55px",
                        height: "55px",
                        padding: "5px",
                        display: "block",
                        margin: "0 auto",
                    }}/>
                    <p className="zero-dark-grey ellipsis-2-lines" style={{
                        margin: "0px",
                        padding: "0px 5px 5px 5px",
                        fontSize: "80%",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        overflow: "hidden",
                        fontWeight: "500",
                        textAlign: "center"
                    }}
                    title={file_name}
                    >{file_name}</p>
                </button>
            )
        }

        return (
            <li style={{
                marginRight: "5px",
                marginBottom: "5px",
                height: "100px",
                width: "100px",
                position: "relative",
                overflow: 'hidden',
                border: attachment.error && '1px solid red',
            }}>
                {element}
                {deleteElement}
            </li>
        )
}