import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Component } from 'react';
import { withRouter } from 'react-router';

import { FileUploadContextManager, FileUploadDragger, FileUploadError, FileUploadList } from "components/Shared/FileUpload";
import { ZeroContext } from 'components/ZeroContext';
import { generateUUID, isPublicPage } from "../../other/Helper";
import safe_get from "../../other/SafeGet";
import Previewer from "../Previewer";
import ConfirmModal from "../Shared/ConfirmModal";


class SubmissionAttachmentField extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context

        this.state = {
            attachments: safe_get(props.answer_data, 'attachments', []),
            fileListCount: 0,
            previewVisible: false,
            previewIndex: null,
            showDeleteConfirmation: false,
            deleteIndex: null,
            canEdit: !props.is_builder && !props.is_preview && !props.isSubmissionView && !props.isComment && !props.isAnalytics
        }

        this.submissionId = this.props.submission_uuid || this.props.match.params.submission_uuid;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.answer_data?.attachments !== this.props.answer_data?.attachments) {
            this.setState({attachments: safe_get(this.props.answer_data, 'attachments', [])});
        }
    }

    generateParamsCallback = file => ({
        feature_key: 'forms_submission',
        form_uuid: this.props.form_uuid,
        field_uuid: this.props.field_uuid,
        content_type: file.type,
        file_name: file.name,
        embedded: false
    })

    customUploadHandlerGenerator = () => {
        if (isPublicPage()) {
            return (file) => {
                const attachmentId = generateUUID();
                const url = URL.createObjectURL(file)
                const attachment = {
                    attachment_uuid: attachmentId,
                    file_path: url,
                    file_name: file.name,
                    public_url: url,
                    mime_type: file.type,
                    file: file,
                };
                this.handleNewAttachment(attachment);
            }
        } else if (this.submissionId && this.context.caches.submissionDrafts.drafts.filter(draft => draft._id === this.submissionId).length > 0) {
            return async (file) => {
                const attachment = await this.context.services.forms.drafts.saveOfflineAttachment(this.submissionId, file)
                this.handleNewAttachment(attachment);
            }
        }
        
        return null;
    }

    onFileListChange = (fileList) => {
        this.setState({
            fileListCount: fileList.length
        });
    }

    handleNewAttachment = (attachment) => {
        if (this.props.onFileUpload) {
            const answer_data = {...this.props.answer_data};
            answer_data.attachments.push(attachment);
            this.props.onFileUpload(answer_data);
        }
    }

    handleUploadSuccess = (file, pre_signed_url, file_key) => {
        const attachment = {
            file_path: file_key,
            file_name: file.name,
            public_url: `${pre_signed_url}/${file_key}`,
            mime_type: file.type
        };
        
        this.handleNewAttachment(attachment);
    }

    handleUploadError = (error) => {
        console.error(`Error uploading file:`, error);
    }

    handleRemove = (index) => {
        if (!this.state.canEdit || this.state.showDeleteConfirmation) return;

        this.setState({deleteIndex: index, showDeleteConfirmation: true});
    }

    deleteAttachment = () => {
        if (!this.state.canEdit) return;

        if (this.props.onFileUpload) {
            const answer_data = {...this.props.answer_data};
            const attachments = answer_data.attachments;
            const [attachment] = attachments.splice(this.state.deleteIndex, 1);
            if (attachment && !attachment.file) {
                // attachment.file is only used with public forms
                if (!attachment.file) {
                    this.context.services.forms.deleteLocalSubmissionAttachment(this.submissionId, attachment.attachment_uuid);
                } else {
                    URL.revokeObjectURL(attachment.public_url);
                }
            }
            this.props.onFileUpload(answer_data);
        }

        this.setState({deleteIndex: null, showDeleteConfirmation: false})
    }

    renderAttachments = () => {
        const {attachments, fileListCount} = this.state;
        const {is_builder, is_preview, is_submission} = this.props

        if (is_builder) {
            return (
                <div className="zero-dark-grey"><InfoCircleOutlined/> This field type allows users to upload files.</div>
            );
        }

        if (attachments.length === 0 && fileListCount === 0) {
            if (is_preview || is_submission) {
                return null;
            }

            return <div className="zero-dark-grey">No attachments.</div>;
        }

        const onDelete = this.state.canEdit ? index => this.handleRemove(index) : null;

        return (
            <FileUploadList
                className="mar-top-10"
                blobs={{...this.context.caches.submissionDrafts.blobs, ...this.context.caches.formsCache.blobs}}
                onPreview={(index) => this.setState({previewVisible: true, previewIndex: index})}
                onDelete={onDelete}
            />
        );
    }

    renderUploader = () => {
        const {is_builder, is_preview} = this.props;
        const {canEdit} = this.state;

        if (!(canEdit || is_builder || is_preview)) return null;

        return (
            <FileUploadDragger
                alwaysShow
                uploadProps={{
                    disabled: !canEdit
                }}
            >
                <p className="zero-dark-grey" style={{margin: '10px 0'}}><UploadOutlined/> Click or drag files to upload to ZERO.</p>
            </FileUploadDragger>
        )
    }

    render() {
        const {
            previewVisible,
            previewIndex,
            attachments,
            showDeleteConfirmation
        } = this.state;

        return (
            <FileUploadContextManager
                attachments={this.state.attachments}
                generateParamsCallback={this.generateParamsCallback}
                customUpload={this.customUploadHandlerGenerator()}
                onUploadSuccess={this.handleUploadSuccess}
                onUploadError={this.handleUploadError}
                options={{
                    onFileListChange: this.onFileListChange
                }}
            >
            <div className="submission-attachments-field" style={{position: 'relative'}}>
                { showDeleteConfirmation &&
                    <ConfirmModal
                        show={showDeleteConfirmation}
                        cancel={() => {
                            this.setState({showDeleteConfirmation: false, deleteIndex: null})
                        }}
                        confirm={this.deleteAttachment}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this attachment?"}
                        confirmButtonName={"Delete"}
                    />
                }
                { previewVisible &&
                    <Previewer
                        show={previewVisible}
                        close={() => {
                            this.setState({previewVisible: false})
                        }}
                        attachments={attachments}
                        index={previewIndex}
                    />
                }
                {
                    this.renderUploader()
                }
                <FileUploadError />
                {
                    this.renderAttachments()
                }
            </div>
            </FileUploadContextManager>
        )
    }
}

export default withRouter(SubmissionAttachmentField);
