import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    delete_team, get_list_teams, notifyError
} from '../../../api/zero-api.js';

import {
    DeleteOutlined, EditOutlined, PlusCircleOutlined,
    UserAddOutlined,
    UserDeleteOutlined
} from '@ant-design/icons';

import { Dropdown, Menu } from 'antd';
import '../../../assets/css/antd-custom.css';

import Skeleton from 'react-loading-skeleton';

import {IoIosMore as MoreIcon} from 'react-icons/io';

import {
    defaultMyTeams,
    isAdmin, mainContentContainerClass,
    safeProfilePic, safe_get,
    scrollToTop
} from '../../../other/Helper.js';
import NotificationAlert from '../../../other/NotificationAlert.js';

import ConfirmModal from '../../Shared/ConfirmModal.js';
import TeamModal from '../../Teams/TeamModal.js';
import AddMembersModal from './AddMembersModal';
import TeamMembersModal from './TeamMembersModal.js';

import DelayedTextInput from 'components/Shared/DelayedTextInput';
import * as feedHelperActions from '../../../store/actions/FeedHelperActions';
import * as teamsActions from '../../../store/actions/TeamsActions';
import UserPopoverCard from '../../Shared/UserPopoverCard.js';


class ManageTeams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            myTeams: this.props.teams.map(team => team.uuid),
            loading: true,
            tableWidth: 870,
            tableHeight: 500,
            searchString: "",
            skeletonLength: 10,
        };

        this.getTeams = this.getTeams.bind(this);
        this.searchTable = this.searchTable.bind(this);
        this.optionsFormatter = this.optionsFormatter.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.deleteTeam = this.deleteTeam.bind(this);
        this.deleteTeamFromList = this.deleteTeamFromList.bind(this);
        this.newTeam = this.newTeam.bind(this);

        this.usersFormatter = this.usersFormatter.bind(this);
    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getTeams();
    }

    getSearchQuery() {
        if (this.state.searchString.length > 0) {
            return `s=${this.state.searchString}`;
        }
        return '';
    }

    async getMyTeams() {
        try {
            const response = await get_list_teams('my_teams=true');
            const content = await response.json();
            const teams = content?.teams ?? [];
            this.setState({myTeams: teams.map(team => team.uuid)});
        } catch (err) {
            console.error(err);
        }
    }

    async getTeams() {
        try {
            this.setState({loading: true});
            const myTeamsLoader = this.getMyTeams();
            const response = await get_list_teams(this.getSearchQuery());
            const content = await response.json();
            const teams = safe_get(content, "teams", []).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            await myTeamsLoader;
            this.setState({
                teams,
                skeletonLength: teams.length || 1,
                loading: false
            });
        } catch (error) {
            notifyError(error);
        }
    }

    yourAccessFormatter = (team) => {
        const isInTeam = this.state.myTeams.indexOf(team.uuid) >= 0;

        return (
            <span className="zero-dark-grey text-thin">{isInTeam ? "Yes" : "No"}</span>
        )
    }

    teamFormatter(team) {
        var users_count = team.active_users_count;
        return (
            <div>
                <button onClick={() => {
                    this.goToTeam(team);
                }} className="ButtonLink btn-link" style={{fontWeight: "500", textAlign: "left"}}>
                    {team.name}
                </button>
                <span className="zero-dark-grey"
                      style={{display: 'block', fontSize: '.9em'}}>Members: {users_count}</span>
            </div>
        );
    }


    goToTeam = (team) => {
        this.setState({
            teamToEdit: team,
            showTeamModal: true,
            teamModalType: 'edit'
        });
    }

    usersFormatter(team) {
        let users = [...team.members].filter((user) => {
            return user.status === "active";
        });

        let isInTeam = this.state.myTeams.indexOf(team.uuid) >= 0;

        return (
            <div>
                {
                    users.slice(0, 19).map((user, index) => (
                        <UserPopoverCard key={index} user={user} team={team} refresh={this.getTeams} removeUserFromTeam={isInTeam}>
                            <span style={{cursor: "pointer"}}>
                            {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                marginRight: "5px",
                                display: "inline-block"
                            })}
                            </span>
                        </UserPopoverCard>
                    ))
                }
                {
                    team.active_users_count > 19 &&
                    <span onClick={() => {
                        this.setState({teamToEdit: team, showTeamMembersModal: true})
                    }} style={{cursor: "pointer"}}>
                        <div className={"default-avatar-circle bulletin"}
                             style={{marginRight: "5px", display: "inline-block", border: "1px solid #505050"}}>
                            <p className="initials" style={{color: "#505050"}}>
                                +{team.active_users_count - 19 < 1000 ? team.active_users_count - 19 : `${Math.round((team.active_users_count - 19) / 1000)}k`}
                            </p>
                        </div>
                    </span>
                }
            </div>

        )
    }

    optionsFormatter(team) {
        var self = this;
        let isInTeam = this.state.myTeams.indexOf(team.uuid) >= 0;
        const deleteIsDisabled = isAdmin(this.props.user) === false;

        const menu = {
            onClick: e => self.handleMenuClick(e.key, team),
            items: [
                // insert the menu items here using menuOptions variable below as a guide
                {
                    key: 'add_members',
                    icon: <UserAddOutlined/>,
                    label: 'Add people',
                },
                {
                    key: 'remove_members',
                    icon: <UserDeleteOutlined/>,
                    label: 'Remove people',
                },
                {
                    key: 'edit_team',
                    icon: <EditOutlined/>,
                    label: 'Edit team settings',
                },
                {
                    key: 'delete_team',
                    label: 'Archive team',
                    icon: <DeleteOutlined/>,
                    className: deleteIsDisabled ? "" : "zero-delete-red",
                    disabled: deleteIsDisabled,
                },
            ]
        }

        return (
            <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                {
                    (isInTeam || isAdmin(this.props.user)) &&
                    <Dropdown menu={menu} trigger={['click']}>
                        <button className="ButtonLink ant-dropdown-link">
                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                        </button>
                    </Dropdown>
                }
            </div>
        )
    }

    handleMenuClick(key, team) {
        var newState = {}
        newState["teamToEdit"] = team;
        if (key === "edit_team") {
            newState["showTeamModal"] = true;
            newState["teamModalType"] = 'edit'
        } else if (key === "delete_team") {
            newState["showDeleteModal"] = true;
        } else if (key === "add_members") {
            newState["showAddMembersModal"] = true;
        } else if (key === "remove_members") {
            newState["showTeamMembersModal"] = true;
        }

        this.setState(newState);
    }

    newTeam() {
        this.setState({
            showTeamModal: true,
            teamModalType: 'new'
        });
    }

    deleteTeam() {
        var self = this
        var current_team_uuid = this.state.teamToEdit.uuid;
        delete_team(current_team_uuid).then(function (success) {
            var teams = self.props.teams

            for (var i in teams) {
                var team = teams[i]
                if (team.uuid === current_team_uuid) {
                    self.getTeams();
                    self.deleteTeamFromList(i)
                    break;
                }
            }

            self.props.dispatch(teamsActions.update_current_team(defaultMyTeams));

            self.props.dispatch(feedHelperActions.update_feed_tab("posts"));
            self.props.dispatch(feedHelperActions.update_feed_query("?team_uuid=my_teams"));
            self.props.dispatch(feedHelperActions.update_date_query("&period=all_time"));

            self.setState({showDeleteModal: false});
            NotificationAlert("success", "", "Team deleted.");

            self.getTeams();
        }, function (error) {
            NotificationAlert("success", "", "Oops! Unable to delete team.")
            self.setState({showDeleteModal: false});
        });
    }

    deleteTeamFromList(index) {
        var teams = this.props.teams
        if (teams.length - 1 > 0) {
            teams.splice(index, 1);
            var team = teams[0]
            this.props.dispatch(teamsActions.update_current_team(team));
            this.props.dispatch(teamsActions.update_teams(teams));

        } else {
            this.props.dispatch(teamsActions.update_current_team({}))
            this.props.dispatch(teamsActions.update_teams([]))
        }
    }


    searchTable(value) {
        this.setState({searchString: value.toLowerCase()}, () => {this.getTeams()});
    }

    render() {
        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.showTeamModal &&
                    <TeamModal
                        show={this.state.showTeamModal}
                        cancel={() => {
                            this.setState({showTeamModal: false})
                        }}
                        type={this.state.teamModalType}
                        team={this.state.teamToEdit}
                        teams={this.props.teams}
                        updateTeams={this.getTeams}
                    />
                }

                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        show={this.state.showDeleteModal}
                        cancel={() => {
                            this.setState({showDeleteModal: false})
                        }}
                        title={`Archive team: "${this.state.teamToEdit.name}"`}
                        body={"Are you sure you want to archive this team? All posts, forms, templates, schedules (everything) in this team will also be archived. This data will no longer be visible in ZERO."}
                        confirmButtonName={"Delete team"}
                        confirm={this.deleteTeam}
                    />
                }

                {
                    this.state.showAddMembersModal &&
                    <AddMembersModal
                        show={this.state.showAddMembersModal}
                        cancel={() => {
                            this.setState({showAddMembersModal: false})
                        }}
                        team={this.state.teamToEdit}
                        refresh={this.getTeams}
                    />
                }

                {
                    this.state.showTeamMembersModal &&
                    <TeamMembersModal
                        show={this.state.showTeamMembersModal}
                        cancel={() => {
                            this.setState({showTeamMembersModal: false})
                        }}
                        team={this.state.teamToEdit}
                        callback={this.getTeams}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage Teams
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '115px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={this.newTeam}>
                                                <PlusCircleOutlined/> Create Team
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={this.newTeam}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                            style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            Teams are groups of people that facilitate controlled information sharing. Teams are used in Posts, Forms, Courses, and
                                            Library, but not Incidents.
                                        </p>

                                        <div className="members-search" style={{width: "100%", marginTop: "8px"}}>
                                            <DelayedTextInput
                                                delay={750}
                                                onChange={this.searchTable}
                                                defaultValue={this.state.searchString}
                                                useAntInput={true}
                                                placeholder="Search..."
                                                style={{width: "100%"}}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        <div className="panel mar-btm-0">

                            <div className="table-responsive"
                                    style={{border: "1px solid #DDDDDD", borderRadius: "5px"}}>
                                <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                    <thead style={{color: "#1D2A35"}}>
                                    <tr>
                                        <th></th>
                                        <th className="zero-blue">
                                            {this.state.loading ? <Skeleton
                                                width={100}/> : `Teams (${this.state.teams.length})`}
                                        </th>
                                        <th className="zero-blue">
                                            {this.state.loading ? <Skeleton width={110}/> : "Team Members"}
                                        </th>
                                        <th className="zero-blue text-center">
                                            {this.state.loading ? <Skeleton width={80}/> : "Your Access"}
                                        </th>
                                    </tr>
                                    </thead>
                                    {
                                        this.state.loading &&
                                        <tbody>
                                        {
                                            [...Array(this.state.skeletonLength)].map((_item, index) => (
                                                <tr key={index} className="tr-hover">
                                                    <td><Skeleton width={25}/></td>
                                                    <td style={{width: "200px"}}>
                                                        <Skeleton width={50}/>
                                                        <span style={{display: 'block', fontSize: '.9em'}}><Skeleton
                                                            width={80}/></span>
                                                    </td>
                                                    <td style={{width: "350px"}}>
                                                        {
                                                            [...Array(7)].map((e, index) => (
                                                                <span key={index} style={{
                                                                    marginRight: "5px",
                                                                    marginTop: "5px"
                                                                }}>
                                                                        <Skeleton key={index} circle={true}
                                                                                    width={26} height={26} inline />
                                                                    </span>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="text-center"><Skeleton height={22} width={45}/>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    }
                                    {
                                        !this.state.loading && this.state.teams.length > 0 &&
                                        <tbody>
                                        {
                                            this.state.teams.map((team, index) => (
                                                <tr key={index} className="tr-hover">
                                                    <td>{this.optionsFormatter(team)}</td>
                                                    <td style={{width: "200px"}}>{this.teamFormatter(team)}</td>
                                                    <td style={{width: "350px"}}>{this.usersFormatter(team)}</td>
                                                    <td className="text-center">{this.yourAccessFormatter(team)}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    }
                                    {
                                        !this.state.loading && this.state.teams.length === 0 &&
                                        <tbody>
                                        <tr>
                                            <td className="react-bs-table-no-data zero-dark-grey" colSpan="6">
                                                No teams.
                                            </td>
                                        </tr>
                                        </tbody>
                                    }

                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        );
    }

}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(ManageTeams);
