import {useCallback, useRef, useState} from "react";
import {Switch} from "antd";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

import DelayedTextInput from "./DelayedTextInput";
import {patch_custom_field_option, patch_custom_field} from "api/zero-api";

function CustomFieldOption({option, maxLength = 50, onChange, onError}) {
    const {uuid, label, enabled} = option;

    const labelRef = useRef(label);
    const enabledRef = useRef(enabled);
    const debounceRef = useRef(null);

    const debounceUpdate = useCallback(() => {
        clearTimeout(debounceRef.current);

        debounceRef.current = setTimeout(async () => {
            try {
                const body = {
                    label: labelRef.current,
                    enabled: enabledRef.current
                }
                const response = await patch_custom_field_option(uuid, body);
                const updatedOption = await response.json();
                onChange && onChange(updatedOption);
            } catch (err) {
                console.error(err);
                onError && onError('Could not update custom field options.');
            }
        }, 500);
    }, [debounceRef, labelRef, enabledRef, uuid, onChange]);

    const handleLabelChange = useCallback((label) => {
        labelRef.current = label;
        debounceUpdate();
    }, [labelRef, debounceUpdate]);

    const handleEnabledChange = useCallback((checked) => {
        enabledRef.current = checked;
        debounceUpdate();
    }, [enabledRef, debounceUpdate]);

    return (
        <div style={{marginBottom: "5px", display: 'flex', alignItems: 'center'}}>
            <DelayedTextInput
                className="form-control topic-field"
                defaultValue={label}
                onChange={handleLabelChange}
                maxLength={maxLength}
            />
            <Switch
                defaultChecked={enabled}
                onChange={handleEnabledChange}
                style={{marginTop: '0px', marginLeft: '10px', float: 'none'}}
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        </div>
    )
}

export default function ManageCustomFields({
                                               customField, onUpdate = () => {
    }
                                           }) {
    const {type, name, options} = customField;
    const [error, setError] = useState('');

    const handleNameChange = useCallback(async (name) => {
        try {
            setError('');
            await patch_custom_field(type, {name});
            onUpdate();
        } catch (err) {
            console.error(err);
            setError('Could not update custom field name.');
        }
    }, [setError]);

    const handleOptionChange = useCallback((option) => {
        setError('');
        onUpdate();
    }, []);

    return (
        <div>
            <div>
                <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
            </div>
            <div style={{marginBottom: "5px"}}>
                <div style={{display: "inline-block"}}>
                    <DelayedTextInput
                        className="form-control topic-field"
                        defaultValue={name}
                        onChange={handleNameChange}
                        maxLength={15}
                        showCharacterCounter={true}
                    />
                </div>
            </div>
            <div>
                <p className="zero-blue" style={{display: 'inline-block'}}><b>Options</b></p>
            </div>
            {
                options.map((option) => (
                    <CustomFieldOption
                        key={option.uuid}
                        option={option}
                        onChange={handleOptionChange}
                        onError={setError}
                    />
                ))
            }
            <div>
                {error &&
                    <div><small className="error">{error}</small></div>
                }
            </div>
        </div>
    )
}
