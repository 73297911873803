import { useContext, useEffect } from 'react';

import { FileUploadContext, FileUploadDragger, FileUploadFullPageDrop, FileUploadIconButton } from 'components/Shared/FileUpload.js';

export default function PostUpload({attachments, update_attachments}) {
    const context = useContext(FileUploadContext);

    useEffect(() => {
        fixOfflineAttachments();
    }, []);

    function fixOfflineAttachments() {
        if (!attachments) {
            return;
        }

        const offlineFormAttachments = attachments.filter(a => a.needs_clone && a.attachment_uuid?.startsWith("offline:"));
        const newAttachments = attachments.filter(a => offlineFormAttachments.includes(a) === false);

        const newFilesToUpload = offlineFormAttachments.map(async a => {
            const blob = await fetch(a.public_url).then(r => r.blob());

            return {
                file: new File([blob], a.file_name, {type: a.mime_type})
            }
        });

        update_attachments(newAttachments);
        newFilesToUpload.forEach(async file => {
            context.handleFileUpload(await file);
        });
    }

    return (
        <div style={{display: (context.showDragger ? "block" : "inline-block"), verticalAlign: "bottom"}}>
            <FileUploadIconButton hideWhenDragging />
            <FileUploadDragger />
            <FileUploadFullPageDrop />
        </div>
    );
}
