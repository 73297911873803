import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {get_spaces_locations, create_location_survey, get_location_survey} from "api/zero-api";
import {extractErrorMessages} from 'other/Helper';
import NotificationAlert from 'other/NotificationAlert';


const initialState = {
    spacesLoader: {},
    locations: null,
    createSurveyStatus: {}
};

export const fetchLocations = createAsyncThunk(
    'manageSpaces/fetchLocations',
    async (payload, {rejectWithValue}) => {
        try {
            const response = await get_spaces_locations();
            const data = await response.json();
            return data;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

export const createSurvey = createAsyncThunk(
    'manageSpaces/createSurvey',
    async ({locationId, body}, {rejectWithValue}) => {
        try {
            await create_location_survey(locationId, JSON.stringify(body));
            const response = await get_location_survey(locationId);
            const data = await response.json();
            return {
                locationId,
                survey: data.survey
            };
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
)

const slice = createSlice({
    name: 'manageSpaces',
    initialState,
    reducers: {
        resetState: () => initialState,
        updateState: (state, {payload}) => {
            for (const [key, value] of Object.entries(payload)) {
                state[key] = value;
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchLocations.pending, (state) => {
            state.spacesLoader = {
                loading: true
            };
        });
        builder.addCase(fetchLocations.fulfilled, (state, {payload}) => {
            state.spacesLoader = {ok: true};
            state.locations = payload;
        });
        builder.addCase(fetchLocations.rejected, (state, {payload}) => {
            state.spacesLoader = {
                error: true,
                errorMessage: payload
            };
        });

        builder.addCase(createSurvey.pending, (state) => {
            state.createSurveyStatus = {
                loading: true
            };
        });
        builder.addCase(createSurvey.fulfilled, (state, {payload}) => {
            state.createSurveyStatus = {
                ok: true
            };

            const {locationId, survey} = payload;
            const index = state.locations.findIndex(location => location.uuid === locationId);
            if (index !== -1) {
                if (!survey) {
                    state.locations[index].survey = null;
                } else if (state.locations !== null && state.locations[locationId]) {
                    state.locations[index].survey.questions = survey.questions;
                    state.locations[index].survey.start_paragraph = survey.start_paragraph;
                }
            }
            NotificationAlert('success', '', 'Survey updated.');
        });
        builder.addCase(createSurvey.rejected, (state, {payload}) => {
            state.createSurveyStatus = {
                data: payload,
                error: true
            };
            NotificationAlert('error', '', 'Could not update survey.');
        });
    }
});

export const {actions} = slice;
export default slice.reducer;