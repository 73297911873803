import React, {Component} from 'react';


class NoTeamPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};


    }

    render() {
        return (
            <div id="content-container" className="container-with-header">
                <div id="page-head" style={{marginBottom: '20px'}}>
                    <div className="row">
                        <div className="col-lg-10 col-lg-offset-1 col-xs-12 col-xs-offset-0 hidden">
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile-account">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <h4 className="text-center" style={{maxWidth: "800px", margin: "0 auto"}}>You're not
                                currently a member of any teams in this account! To join a team, you must first create
                                one or be invited to an existing team by an Administrator.</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default NoTeamPage;
