import PouchDB from 'pouchdb';

import {get_user_directory} from 'api/zero-api';
import {pouchDbGet, pouchDbGetAll, pouchDbUpsert} from './pouchDbUtils';

export async function syncDirectory(orgId) {
    try {
        const res = await get_user_directory('?for_directory_cache=1');
        const directory = await res.json();
        const cache = new DirectoryCache(orgId);
        await cache.bulkSet(directory);
    } catch (error) {
        console.error('Could not sync directory:', error);
    }
}

function createDb(orgPrefix) {
    return new PouchDB(`${orgPrefix}:directory`, {auto_compaction: false, revs_limit: 1});
}

export default class DirectoryCache {
    constructor(orgId) {
        this.orgPrefix = orgId.substring(0, 8);
        this.db = createDb(this.orgPrefix);
    }

    async getAll() {
        return pouchDbGetAll(this.db);
    }

    /**
     * @param {string} userId
     * @returns
     */
    async get(userId) {
        return pouchDbGet(this.db, userId);
    }

    /**
     * @param {string} userId
     * @param {Object} data
     */
    async set(userId, data) {
        await pouchDbUpsert(this.db, userId, data);
    }

    async bulkSet(directoryData) {
        await this.resetDb();
        const directoryDocs = directoryData.map(user => ({...user, _id: user.uuid}));
        await this.db.bulkDocs(directoryDocs);
    }

    async resetDb() {
        try {
            await this.db.destroy();
            this.db = createDb(this.orgPrefix);
        } catch (err) {
            console.error('Could not destroy DB:', err);
        }
    }
}
