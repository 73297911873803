import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {UserAddOutlined} from '@ant-design/icons';

import UserPopoverCard from 'components/Shared/UserPopoverCard';
import RespondersPopover from 'components/Shared/RespondersPopover';
import {safeProfilePic} from 'other/Helper';

export default function AssignUsersWithPopover(
    {
        assignedUsers,
        availableUsers,
        onChange,
        excludeCurrentUser = true,
        userPopoverProps = {},
        respondersPopoverTitle = 'Assigned Users:'
    }
) {
    const currentUser = useSelector(state => state.user.user);

    const filteredAvailableSubscribers = useMemo(
        () => {
            if (excludeCurrentUser) {
                return availableUsers.filter(user => user.uuid !== currentUser.uuid);
            }
            return availableUsers;
        },
        [availableUsers, currentUser]
    );

    const handleRemoveUser = useCallback(
        (removedUser) => {
            const newAssignedUsers = assignedUsers.filter(user => user.uuid !== removedUser.uuid)
            onChange(newAssignedUsers.map(user => user.uuid));
        },
        [assignedUsers, onChange]
    );

    return (
        <>
            <div>
                {
                    assignedUsers.map(user => (
                        <UserPopoverCard
                            key={user.uuid}
                            user={user}
                            onRemoveUser={handleRemoveUser}
                            showRemoveAction={true}
                            {...userPopoverProps}
                        >
                            <span
                                style={{cursor: "pointer"}}>{safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                                marginRight: "5px",
                                display: "inline-block"
                            })}</span>
                        </UserPopoverCard>
                    ))
                }
                <RespondersPopover
                    available_responders={filteredAvailableSubscribers}
                    selected_responders={assignedUsers}
                    updateResponders={onChange}
                    title={respondersPopoverTitle}
                >
                    <button className="ButtonLink post-details-content mar-top-5"
                            style={{verticalAlign: "bottom", height: "26px", width: "26px"}}>
                        <UserAddOutlined style={{fontSize: "19px"}}/>
                    </button>
                </RespondersPopover>
            </div>
        </>
    );
}