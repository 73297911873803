import PouchDB from 'pouchdb';

import {get_dynamic_lists} from 'api/zero-api';
import {pouchDbGet, pouchDbGetAll, pouchDbUpsert} from './pouchDbUtils';

export async function syncDynamicLists(cache) {
    try {
        const res = await get_dynamic_lists('?include_items=1');
        const lists = await res.json();
        for (const list of lists) {
            await cache.set(list.uuid, list);
        }
    } catch (error) {
        console.error('Could not sync dynamic lists:', error);
    }
}

export default class DynamicListCache {
    constructor(orgId) {
        this.orgPrefix = orgId.substring(0, 8);
        this.db = new PouchDB(`${this.orgPrefix}:dynamic_lists`, {auto_compaction: true, revs_limit: 1});
    }

    async getAll() {
        return pouchDbGetAll(this.db);
    }

    /**
     * @param {string} listId
     * @returns
     */
    async get(listId) {
        return pouchDbGet(this.db, listId);
    }

    /**
     * @param {string} listId
     * @param {Object} data
     */
    async set(listId, data) {
        await pouchDbUpsert(this.db, listId, data);
    }
}
