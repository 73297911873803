// @ts-check

import { useCurrentOrganization, useCurrentTeam, useCurrentUser } from "hooks/reduxHooks";
import PostStatusModal from "../PostStatusModal";
import { useFeedDataGridContext } from "./FeedDataGrid.context";
import { useCallback } from "react";
import { close_post, deleteBulletinPost, export_post, move_post, reopen_post, share_post } from "api/zero-api";
import NotificationAlert from "other/NotificationAlert";
import { updateAssignments } from "other/Helper";
import { useDispatch } from "react-redux";
import SharePostModal from "../SharePostModal";
import useZeroSelector from "hooks/useZeroSelector";
import { useZeroContext } from "components/ZeroContext";
import { EventNames } from "./FeedDataGrid.utils";
import CopyPostModal from "../CopyPostModal";
import ExportPostModal from "../ExportPostModal";
import MovePostModal from "../MovePostModal";
import { useHistory } from "react-router";
import ConfirmModal from "components/Shared/ConfirmModal";

export function FeedDataGridModals() {
    const { events } = useZeroContext();
    const user = useCurrentUser();
    const organization = useCurrentOrganization();
    const team = useCurrentTeam();
    const { activeModal, setActiveModal, gridRef } = useFeedDataGridContext();
    const history = useHistory();
    
    if (!activeModal) {
        return null;
    }
    
    const {key, post} = activeModal;
    const closeModal = () => setActiveModal(null);
    const refreshDataGrid = () => events.emit(EventNames.REFRESH_DATA);
    const onEditClick = () => {
        history.replace(history.location.pathname, {gridState: gridRef.current.api.getState()});
        history.push(`/${organization.organization_uuid}/home/team/${team.uuid}/feed/edit/${post.post_uuid}`);
    };

    const sharedProps = {
        closeModal,
        refreshDataGrid,
        user,
        post,
    }

    switch (key) {
        case "status":
            return <StatusModal {...sharedProps} />
        case "edit":
            onEditClick();
            break;
        case "share":
            return <ShareModal {...sharedProps} />
        case "copy":
            return <CopyPostModal
                cancel={closeModal}
                post={post}
                callback={refreshDataGrid}
            />
        case "move":
            return <MoveModal {...sharedProps} />
        case "export":
            return <ExportModal {...sharedProps} />
        case "delete":
            return <DeleteModal {...sharedProps} />
        default:
            console.error(`Unknown post table modal '${key}'`);
            return null;
    }
}

function StatusModal({closeModal, refreshDataGrid, user, post}) {
    const dispatch = useDispatch();

    const closePost = async (reason, attachments) => {
        const body = JSON.stringify({
            reason,
            attachments,
        });

        try {
            await close_post(post.post_uuid, body);
            refreshDataGrid();
            updateAssignments(dispatch);
            NotificationAlert('success', 'Success!', "Post closed.");
        } catch (err) {
            NotificationAlert("error", "Oops!", "An error occurred. Unable to close post.");
        } finally {
            closeModal();
        }
    };

    const reopenPost = async (reason, attachments) => {
        const body = JSON.stringify({
            reason,
            attachments,
        });

        try {
            await reopen_post(post.post_uuid, body);
            refreshDataGrid();
            updateAssignments(dispatch);
            NotificationAlert('success', 'Success!', "Post reopened.");
        } catch (err) {
            NotificationAlert("error", "Oops!", "An error occurred. Unable to reopen post.");
        } finally {
            closeModal();
        }
    };

    return (
        <PostStatusModal
            show={true}
            cancel={closeModal}
            user_uuid={user.uuid}
            post_status={post.status}
            subStatusUuid={post.sub_status.sub_status_uuid}
            post_uuid={post.post_uuid}
            responders={post.assigns}
            closePost={closePost}
            reopenPost={reopenPost}
        />
    )
}

function ShareModal({closeModal, refreshDataGrid, user, post}) {
    const teams = useZeroSelector(state => state.teams_helper.teams);

    const sharePost = async (teamIds, userIds, selectAll) => {
        try {
            const body = JSON.stringify({
                team_uuids: teamIds,
                subscribe_all: selectAll,
                subscribe_user_uuids: userIds,
            });

            await share_post(post.post_uuid, body);
            refreshDataGrid();
            NotificationAlert('success', 'Success!', 'Post shared.');
        } catch (err) {
            NotificationAlert('error', 'Oops!', "An error occurred. Unable to share post.");
        } finally {
            closeModal();
        }
    }

    return (
        <SharePostModal
            show={true}
            cancel={closeModal}
            post={post}
            post_uuid={post.post_uuid}
            teams={teams}
            confirm={sharePost}
            source_team={post.source_team}
            user={user}
        />
    )
}

function ExportModal({closeModal, refreshDataGrid, user, post}) {
    const exportPost = async () => {
        try {
            const res = await export_post(post.post_uuid);
            const data = await res.json();
            window.location = data.data.public_url;
        } catch (err) {
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to export post.");
        } finally {
            closeModal();
        }
    }

    return (
        <ExportPostModal
            show
            cancel={closeModal}
            confirm={exportPost}
        />
    )
}

function MoveModal({closeModal, refreshDataGrid, user, post}) {
    const organization = useCurrentOrganization();
    const teams = useZeroSelector(state => state.teams_helper.teams);
    const history = useHistory();

    const movePost = async (teamId, userIds, selectAll = false) => {
        try {
            const body = JSON.stringify({
                new_team_uuid: teamId,
                subscribe_all: selectAll,
                subscribe_user_uuids: userIds,
            });

            const postId = post.post_uuid;

            const res = await move_post(postId, body);
            NotificationAlert('success', 'Success!', "Post moved.");
            history.push(`/${organization.organization_uuid}/home/team/${teamId}/feed/post/${postId}`);
        } catch (err) {
            NotificationAlert('error', 'Oops!', "An error occurred. Unable to move post.");
        } finally {
            closeModal();
        }
    }
    
    return (
        <MovePostModal
            show
            cancel={closeModal}
            teams={teams}
            source_team={post.source_team.team_uuid}
            post={post}
            post_uuid={post.post_uuid}
            confirm={movePost}
            user={user}
        />
    )
}

function DeleteModal({closeModal, refreshDataGrid, user, post}) {
    const deletePost = async () => {
        try {
            await deleteBulletinPost(post.post_uuid);
            refreshDataGrid();
            NotificationAlert('success', '', "Post deleted.");
        } catch (err) {
            NotificationAlert('error', 'Oops!', "An error occurred. Unable to delete post.");
        } finally {
            closeModal();
        }
    }

    return (
        <ConfirmModal
            title="Confirmation"
            body="Are you sure you want to delete this post? This action cannot be undone."
            show
            cancel={closeModal}
            confirm={deletePost}
            confirmButtonName="Delete this post"
        />
    )
}