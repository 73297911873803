import { Component } from 'react';

import copy from 'copy-to-clipboard';

import { get_list_teams, mass_invite, mass_invite_warnings, update_team } from '../../api/zero-api.js';
import { isAdmin, isTeamLead, safe_get } from '../../other/Helper.js';

import NotificationAlert from '../../other/NotificationAlert';
import CreateUserModal from '../CreateUserModal.js';

import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Popover, Select, Tag } from 'antd';

import ButtonLoading from '../Shared/ButtonLoading.js';

import QRCode from 'components/Shared/QRCode.js';
import '../../assets/css/antd-custom.css';

var noDropdown = {display: "none"}

class EmailInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInviteLink: false,
            inviteMany: true,
            all_users: [],
            selected_users: [],
            filtered_users: [],
            selected_teams: [],
            error_messages: [],
            share_link_teams: this.props.teams,
            team_options: [],
            filtered_teams_list: [],
            dropdownStyle: noDropdown,
            email_fields: [{email: "", role: undefined}, {email: "", role: undefined}, {email: "", role: undefined}],
            multipleEmails: "",
        };

        this.searchTeam = this.searchTeam.bind(this);
        this.onTeamChange = this.onTeamChange.bind(this)
        this.emailInvite = this.emailInvite.bind(this);
        this.confirmInvite = this.confirmInvite.bind(this);
        this.inviteManyRoleSelect = this.inviteManyRoleSelect.bind(this);
    }

    componentDidMount() {
        var self = this;
        if (isAdmin(this.props.user)) {
            get_list_teams().then(function (success) {
                success.json().then(success => {
                    var teams = safe_get(success, "teams", []);
                    teams.sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });

                    var newState = {}
                    newState["team_options"] = teams;
                    newState["filtered_teams_list"] = teams;

                    if (self.props.team.uuid !== "my_teams" && !self.props.directory) {
                        newState["selected_teams"] = [self.props.team.uuid];
                    }
                    self.setState(newState);
                });
            }, function (error) {
                console.log(error);
            });
        } else {
            var newState = {}
            newState["team_options"] = this.props.teams;
            newState["filtered_teams_list"] = this.props.teams;

            if (this.props.team.uuid !== "my_teams") {
                newState["selected_teams"] = [this.props.team.uuid];
            }
            this.setState(newState);
        }


    }


    onTeamChange(value) {
        this.setState({selected_teams: value, filtered_teams_list: this.state.team_options});
    }

    searchTeam(value) {
        var self = this;

        if (value !== "") {
            var searchValue = value.toLowerCase();

            var filterList = this.state.team_options.filter(function (object) {
                return object.name.toLowerCase().includes(searchValue)
            });

            var newState = {};

            filterList.sort(function (a, b) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            });

            newState["filtered_teams_list"] = filterList;

            self.setState(newState);
        } else {
            self.setState({filtered_teams_list: this.state.team_options});
        }

    }

    showShareLink = () => {
        let teams = [...this.state.share_link_teams];
        this.setState({
            showInviteLink: true,
            inviteMany: false,
        });
        this.onTeamSelect(teams[0].uuid)
    }

    selectAllTeams = () => {
        let teams = this.state.filtered_teams_list;
        let team_uuids = teams.map(team => team.uuid);
        this.setState({selected_teams: team_uuids, filtered_teams_list: this.state.team_options});
    }

    selectNoTeams = () => {
        this.setState({selected_teams: []});
    }

    onTeamSelect = (value) => {
        let teams = [...this.state.share_link_teams];
        let team = teams.find((team) => team.uuid === value);

        this.setState({
            team_selected: team,
            invite_link: safe_get(team, "invite_link.link", ""),
            link_enabled: safe_get(team, "invite_link.enabled", ""),
            link_enabled_text: (safe_get(team, "invite_link.enabled", "") ? "On" : "Off"),
            allowed_domains: safe_get(team, "invite_link.allowed_domains", ""),
            characters: 250 - safe_get(team, "invite_link.allowed_domains", "").length
        });

    }

    onChangeShareLink = (checked) => {
        this.setState({
            link_enabled: checked,
            link_enabled_text: (checked ? "On" : "Off"),
        }, () => {
            var self = this;

            var body = JSON.stringify({
                name: self.state.team_selected.name,
                description: self.state.team_selected.description,
                post_reactions_enabled: self.state.team_selected.post_reactions_enabled,
                invite_link: {
                    enabled: self.state.link_enabled,
                    allowed_domains: "" //document.getElementById('emailDomains').value
                }
            })

            update_team(this.state.team_selected.uuid, body).then(function (success) {
                success.json().then(success => {
                    var team = safe_get(success, "team", {});
                    var link_enabled = safe_get(team, "invite_link.enabled", false);

                    let teams = [...self.state.share_link_teams];
                    let index = teams.findIndex((obj) => obj.uuid === team.uuid);

                    if (index >= 0) {
                        teams[index] = team;
                    }

                    self.setState({
                        invite_link: safe_get(team, "invite_link.link", ""),
                        link_enabled: link_enabled,
                        allowed_domains: safe_get(team, "invite_link.allowed_domains", ""),
                        share_link_teams: teams
                    });

                    // var message = (link_enabled ? "Team is now public. Anyone with the URL link can join." : "Team is now private. Only invitees can join.")
                    // self.props.inviteCallback(true, message , team)
                });

            }, function (error) {
                // self.props.inviteCallback(false, "An Error occurred. Unable to update team.")
            });
        });
    }

    copyLink = () => {
        copy(this.state.invite_link);
        NotificationAlert("success", "", "Link copied.");
    }

    emailInvite() {
        var self = this;

        if (!this.state.inviting) {
            this.setState({
                error_messages: null,
                email_error: null,
                teamsError: null,
                inviteError: undefined,
                inviting: true
            });

            var team_uuids = this.state.selected_teams;

            var emails = [];
            let emails_arr = [...this.state.emails]
            for (var i in emails_arr) {
                let email = emails_arr[i]
                emails.push({
                    "email": email,
                    "role": this.state.inviteManyRole
                });
            }

            let error = false;
            if (team_uuids.length === 0) {
                self.setState({teamsError: "At least one team must be selected.", inviting: false});
                error = true;
            }

            var body = JSON.stringify({
                emails_data: emails,
                team_uuids: team_uuids
            });

            mass_invite_warnings(body).then(function (success) {
                success.json().then(success => {
                    var warnings = safe_get(success, "warnings", []);
                    if (warnings.length > 0) {
                        let emailWarnings = warnings.map(w => w.email)
                        console.log(emailWarnings)
                        self.setState({emailWarnings: emailWarnings, inviting: false})
                        // self.setState({showConfirmChanges: true, emailWarnings: warnings, inviting: false });
                    } else {
                        self.confirmInvite(body);
                    }
                })
            }, function (error) {
                if (error.status === 422) {
                    error.json().then(error => {
                        let error_messages = safe_get(error, "error_messages", []);
                        self.setState({error_messages: error_messages, inviting: false});

                        if (error_messages.length > 0) {
                            let error = error_messages[0];
                            if (error.field === "team_uuids") {
                                self.setState({teamsError: error.message});
                            } else if (error.field === "emails_data") {
                                self.setState({email_error: error.message});
                            } else {
                                let emailsError = []
                                let emailWarnings = []
                                error_messages.forEach(error => {
                                    if (error.field) {
                                        emailsError.push(error.field)
                                    }
                                    if (error.email) {
                                        emailWarnings.push(error.email)
                                    }
                                });
                                self.setState({
                                    email_error: error.message,
                                    emailErrors: emailsError,
                                    emailWarnings: emailWarnings,
                                });
                            }
                            // else if (emails_arr.includes(error.field)) {
                            //   self.setState({ email_error: error.message, emailErrors: [error.field] });
                            // }
                        }
                    });
                } else {
                    self.setState({inviteError: "An error occured. Cannot invite user(s).", inviting: false});
                }
            });
        }


    }

    confirmInvite(body) {
        let self = this;

        this.setState({confirming: true});

        mass_invite(body).then(function (success) {
            self.setState({confirming: false});
            self.props.inviteCallback(true, "Invitations sent.");
        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {
                    let error_messages = safe_get(error, "error_messages", []);
                    self.setState({error_messages: error_messages, inviting: false, confirming: false});

                    if (error_messages.length > 0) {
                        let error = error_messages[0];
                        if (error.field === "team_uuids") {
                            self.setState({teamsError: error.message});
                        }
                    }
                });
            } else {
                self.setState({inviteError: "An unexpected error occured. Cannot invite user(s).", inviting: false});
            }
        });
    }

    onRoleSelectUpdate = (idx) => (evt) => {
        const newEmails = this.state.email_fields.map((obj, sidx) => {
            if (idx !== sidx) return obj;
            return {...obj, role: evt};
        });
        this.setState({email_fields: newEmails});
    }

    emailChange = (value) => {
        let emails = []
        value.forEach(element => {
            let trimmed = element.trim()
            if (trimmed.length > 0) {
                emails.push(trimmed)
            }
        });
        this.setState({emails: emails});
    }

    resolveEmailWarnings = () => {
        let emails = [...this.state.emails];
        let emailWarnings = [...safe_get(this.state, "emailWarnings", [])]

        let difference = emails.filter(x => !emailWarnings.includes(x));

        this.setState({emails: difference, emailWarnings: []});
    }

    resolveEmailErrors = () => {
        let emails = [...this.state.emails];
        let emailErrors = [...safe_get(this.state, "emailErrors", [])]

        let difference = emails.filter(x => !emailErrors.includes(x));

        this.setState({emails: difference, emailErrors: [], email_error: null});
    }

    tagRender = (props) => {
        const {label, closable, onClose} = props;
        let isErrorEmail = safe_get(this.state, "emailWarnings", []).includes(label) || safe_get(this.state, "emailErrors", []).includes(label)

        if (isErrorEmail) {
            return (
                <Tag color={"red"} closable={closable} onClose={onClose} style={{marginRight: 4}}>
                    {label}
                </Tag>
            );
        } else {
            return (
                <Tag closable={closable} onClose={onClose}
                     style={{marginRight: 4, borderColor: "#505050", color: "#505050", backgroundColor: "#50505014"}}>
                    {label}
                </Tag>
            );
        }

    }

    inviteManyRoleSelect(value) {
        this.setState({inviteManyRole: value});
    }

    errorMessage(email) {
        var errors = this.state.error_messages;
        for (var i in errors) {
            let error = errors[i];
            if (error.field === email) {
                return error.message;
            }
        }
        return "";
    }

    userAddedCallBack = (isAsync) => {
        if (!isAsync) {
            this.props.inviteCallback(true, "User created.");
        } else {
            NotificationAlert('success', '', 'User import in progress.')
        }
        this.props.cancel();
    }

    render() {
        if (this.state.showCreateUserModal) {
            return (
                <CreateUserModal
                    show={this.state.showCreateUserModal}
                    cancel={() => {
                        this.setState({showCreateUserModal: false});
                    }}
                    userAddedCallBack={this.userAddedCallBack}
                    teams={this.state.team_options}
                />
            )
        }
        return (
            (<Modal
                title={"Invite Members to ZERO"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        {
                            this.state.inviteMany && !this.state.showConfirmChanges &&
                            <div>
                                <span style={{fontWeight: "500", float: "left"}}>
                                    <span className="blue-link" onClick={() => {
                                        this.setState({showCreateUserModal: true})
                                    }}>
                                      <PlusCircleOutlined className="mar-rgt-5"/>Create members
                                    </span>
                                    <span>
                                        &nbsp;or&nbsp;
                                        <span className="blue-link" onClick={this.showShareLink}>
                                        share signup link
                                        </span>
                                    </span>
                                </span>
                            </div>
                        }
                        {
                            this.state.showInviteLink &&
                            <div style={{float: "left"}}>
                                {
                                    this.state.link_enabled_text === "On" &&
                                    <p className="blue-link" onClick={() => {
                                        this.onChangeShareLink(false)
                                    }}>Turn shared link off...</p>
                                }
                                {
                                    this.state.link_enabled_text === "Off" &&
                                    <p className="blue-link" onClick={() => {
                                        this.onChangeShareLink(true)
                                    }}>Turn shared link on...</p>
                                }
                            </div>
                        }
                        {
                            (this.state.showConfirmChanges || this.state.showInviteLink) &&
                            <button className="btn btn-discard" onClick={() => {
                                this.setState({inviteMany: true, showConfirmChanges: false, showInviteLink: false});
                            }}>Back</button>
                        }
                        {
                            this.state.showInviteLink && this.state.link_enabled_text === "On" &&
                            <button className="btn btn-modal" onClick={this.copyLink}>
                                Copy Link
                            </button>
                        }
                        {
                            !this.state.showConfirmChanges && !this.state.showInviteLink &&
                            <div>
                                <button className="btn btn-modal hidden-xs" onClick={this.emailInvite}>
                                    {
                                        this.state.inviting ? <ButtonLoading/> : "Send Invitations"
                                    }
                                </button>
                                <button className="btn btn-modal visible-xs" onClick={this.emailInvite}>
                                    {
                                        this.state.inviting ? <ButtonLoading/> : "Send"
                                    }
                                </button>
                            </div>
                        }

                        {
                            this.state.showConfirmChanges &&
                            <button className="btn btn-modal" onClick={this.confirmInvite}>
                                {
                                    this.state.confirming ? <ButtonLoading/> : "Confirm and send invitations"
                                }
                            </button>
                        }

                    </div>
                }
            >
                {
                    !this.state.showConfirmChanges && !this.state.showCreateUserModal && !this.state.showInviteLink &&
                    <div>
                        <h3 className="titles onboarding invite-link mt-0"
                            style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%", width: "50%"}}>
                            {this.state.inviteMany ? "Emails:" : "Email"}
                        </h3>

                        {
                            this.state.inviteMany &&
                            <div>
                                <Select
                                    mode="tags"
                                    className="move-team-select"
                                    open={false}
                                    tokenSeparators={[",", " "]}
                                    tagRender={this.tagRender}
                                    placeholder="name@company.com, name2@company.com"
                                    onChange={this.emailChange}
                                    style={{width: "100%"}}
                                    value={safe_get(this.state, "emails", [])}
                                    virtual={false}
                                />
                                {
                                    safe_get(this.state, "emailWarnings", []).length > 0 &&
                                    <small className="error" style={{display: "block", marginTop: "5px"}}>
                                        {safe_get(this.state, "emailWarnings", []).length} existing
                                        member{safe_get(this.state, "emailWarnings", []).length > 1 ? "s" : ""}. <span
                                        className="blue-link"
                                        onClick={this.resolveEmailWarnings}>Remove duplicates</span>
                                    </small>
                                }
                            </div>
                        }
                        {
                            safe_get(this.state, "emailErrors", []).length > 0 &&
                            <small className="error" style={{display: "block", marginTop: "5px"}}>
                                {safe_get(this.state, "emailErrors", []).length} invalid
                                email{safe_get(this.state, "emailErrors", []).length > 1 ? "s" : ""}. <span
                                className="blue-link" onClick={this.resolveEmailErrors}>Remove invalid emails</span>
                            </small>
                        }
                        {
                            this.state.inviteMany &&
                            <div>
                                <h3 className="titles onboarding invite-link mt-0"
                                    style={{marginBottom: "5px", fontSize: "100%"}}>
                                    Role:
                                    <Popover
                                        content={
                                            <small>To learn more about roles in ZERO, click <a rel="noopener noreferrer"
                                                                                               onClick={() => FreshworksWidget('open', 'article', {'id': 70000417097})}
                                                                                               className="blue-link">here</a>.</small>
                                        }
                                        trigger="hover"
                                        title=""
                                        placement="top"
                                        className="custom-popover"
                                        style={{backgroundColor: '#eaeaea !important', padding: '5px'}}>
                                        <InfoCircleOutlined className="mar-lft-5" style={{fontSize: "10px"}}/>
                                    </Popover>
                                </h3>
                                <Select
                                    style={{width: "100%", display: "block", zIndex: "10"}}
                                    dropdownStyle={{zIndex: "10000"}}
                                    className="move-team-select"
                                    showArrow={true}
                                    placeholder="Select a role"
                                    onChange={this.inviteManyRoleSelect}
                                    virtual={false}
                                >
                                    <Select.Option key={"admin"} value={"admin"} disabled={isTeamLead(this.props.user)}>
                                        <span className="badge badge-success badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Admin
                                        &bull;&nbsp;<small>can manage the whole account</small>
                                    </Select.Option>
                                    <Select.Option key={"team_lead"} value={"team_lead"}
                                                   disabled={isTeamLead(this.props.user)}>
                                        <span className="badge badge-info badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Team Lead
                                        &bull;&nbsp;<small>can manage only assigned teams</small>
                                    </Select.Option>
                                    <Select.Option key={"user"} value={"user"}>
                                        <span className="badge badge-purple badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Member
                                        &bull;&nbsp;<small>can perform actions, cannot manage</small>
                                    </Select.Option>
                                    <Select.Option key={"contributor"} value={"user_restricted"}>
                                        <span className="badge badge-warning badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Contributor
                                        &bull;&nbsp;<small>can perform actions only on assigned content</small>
                                    </Select.Option>
                                    <Select.Option key={"viewer"} value={"viewer"}>
                                        <span className="badge badge-danger badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Viewer
                                        &bull;&nbsp;<small>can view only, cannot perform actions</small>
                                    </Select.Option>
                                </Select>
                            </div>
                        }


                        <div className="members-search">
                            <h3 className="titles onboarding invite-link mt-0"
                                style={{marginBottom: "5px", fontSize: "100%"}}>Teams:</h3>
                            <Select
                                value={this.state.selected_teams}
                                mode="multiple"
                                placeholder="Select a team..."
                                onChange={this.onTeamChange}
                                onSearch={this.searchTeam}
                                style={{width: "100%", verticalAlign: "bottom"}}
                                dropdownStyle={{zIndex: "10000"}}
                                showArrow={false}
                                filterOption={false}
                                onFocus={() => {
                                    this.setState({filtered_teams_list: this.state.team_options})
                                }}
                                virtual={false}
                            >
                                <Select.Option disabled>
                                    <span className="blue-link" onClick={this.selectAllTeams}>Select All</span> &bull;
                                    <span className="blue-link" onClick={this.selectNoTeams}>&nbsp;Select None</span>
                                </Select.Option>
                                {
                                    this.state.filtered_teams_list.map((team, index) => (
                                        <Select.Option key={team.uuid} value={team.uuid}>{team.name}</Select.Option>
                                    ))
                                }
                            </Select>
                            {
                                this.state.teamsError &&
                                <small className="error" style={{display: "block"}}>{this.state.teamsError}</small>
                            }
                        </div>
                        {
                            this.state.inviteError &&
                            <small className="error">{this.state.inviteError}</small>
                        }
                    </div>
                }
                {
                    this.state.showInviteLink &&
                    <div className="">
                        <div>
                            <p>Anyone can use this link to join ZERO.</p>
                        </div>
                        <h3 className="titles onboarding invite-link mt-0"
                            style={{marginBottom: "5px", fontSize: "100%"}}>
                            Team
                        </h3>
                        <Select
                            style={{width: "100%", display: "block", zIndex: "10"}}
                            dropdownStyle={{zIndex: "10000"}}
                            className="move-team-select"
                            showArrow={true}
                            placeholder="Select a team"
                            onChange={this.onTeamSelect}
                            value={this.state.team_selected.uuid}
                            virtual={false}
                        >
                            {
                                this.state.share_link_teams.map((team, index) => (
                                    <Select.Option key={team.uuid} value={team.uuid}>{team.name}</Select.Option>
                                ))
                            }
                        </Select>
                        {
                            this.state.link_enabled_text === "On" &&
                            (
                                <div>
                                    <input id="invite_link" readOnly
                                           className="form-control custom-placeholder mar-top-10" type="text"
                                           value={this.state.invite_link}/>
                                    <QRCode
                                        link={this.state.invite_link}
                                        downloadTitle="QRCodeMemberInvite.png"
                                        className="mar-top-10"
                                    />
                                </div>
                            )
                        }
                        {
                            this.state.link_enabled_text === "Off" &&
                            <input type="text" id="emailDomains" className="form-control custom-placeholder mar-top-10"
                                   disabled placeholder="Shared link turned off..."/>
                        }
                    </div>
                }
            </Modal>)
        );
    }
}

export default EmailInvite;