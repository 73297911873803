import React, {Component} from 'react';

class Tag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleToggle() {
        this.setState({active: !this.state.active});
        this.props.onToggleTag(this.props.uuid);
    }

    handleClick() {
        if (this.props.onClickTag) {
            this.props.onClickTag(this.props.value);
        }
    }

    render() {

        var margin = {
            marginTop: '10px',
            marginBottom: '0px'
        };

        return (

            <div style={{display: 'inline-block'}}>
                {
                    this.props.bulletin &&
                    <span style={margin} className="label label-tag" onClick={this.handleClick}>
                    {this.props.value}
                </span>
                }
                {
                    !this.props.bulletin &&
                    <span className="label label-success" style={{backgroundColor: '#03a9f4', marginRight: '5px'}}
                          onClick={this.handleClick}>
                   #{this.props.value}
                </span>
                }
            </div>

        );
    }

}

export default Tag;