import PouchDB from 'pouchdb';
import { defaultPouchDbOptions, pouchDbGet, pouchDbUpsert } from 'offline/pouchDbUtils';
import { getTeamMemberships, getPostBulkOptions } from 'api/zero-api';
import pLimit from 'p-limit';
import { getReduxStore } from 'other/Helper';
import { update_feed_options } from 'store/actions/FeedHelperActions';

const localDbService = {
    /** @type {PouchDB.Database} */
    db: undefined,
    /** @type {string} */
    dbName: undefined,

    init(orgId, userId) {
        const orgPrefix = orgId.substring(0, 8);
        const userPrefix = userId.substring(0, 8);
        const dbName = `localdb:${orgPrefix}:${userPrefix}`;
    
        if (this.dbName !== dbName) {
            this.dbName = dbName;
            this.db = new PouchDB(dbName, defaultPouchDbOptions);
        }
        
        return this;
    },

    async syncLocalDb() {
        const limit = pLimit(10);
        const queue = [];
        queue.push(limit(() => this.syncPostOptions()));
        queue.push(limit(() => this.syncTeamMemberships()));
        await Promise.all(queue);
    },

    /** Fetches bulk post options and saves to local DB */
    async syncPostOptions(preFetchedData = null) {
        try {
            let content = preFetchedData;
            if (content === null) {
                const response = await getPostBulkOptions();
                content = await response.json();
            }
            const store = getReduxStore();
            store.dispatch(update_feed_options(content));
            await pouchDbUpsert(this.db, 'post-options', content);
        } catch (err) {
            console.error("Could not sync post options:", err);
        }
    },

    async getPostOptions() {
        const options = await pouchDbGet(this.db, 'post-options', null);
        
        if (options === null) {
            await this.syncPostOptions();
            return pouchDbGet(this.db, 'post-options');
        }

        return options;
    },

    /** Fetches team membership data and saves to local DB */
    async syncTeamMemberships() {
        try {
            const response = await getTeamMemberships();
            const content = await response.json();
            await pouchDbUpsert(this.db, `team-members`, content);
        } catch (err) {
            console.error("Could not sync post options:", err);
        }
    },

    /**
     * Returns an array of user UUIDs that are members of the provided team.
     * @param {string} teamId 
     * @returns {Promise<string[]>} Array of user UUIDs
     */
    async getTeamMemberships(teamId) {
        const memberships = await this.getAllTeamMemberships();
        return memberships[teamId] ?? [];
    },

    async getAllTeamMemberships() {
        return await pouchDbGet(this.db, `team-members`);
    }
}

export function getLocalDbService(orgId, userId) {
    return localDbService.init(orgId, userId);
}
