import { DatePicker, Dropdown, Popover, Tag } from "antd";
import UserPopoverCard from "components/Shared/UserPopoverCard";
import {
  dateFormatterNoTime,
  dateFormatterWithTime,
  endOfDayUtc,
  isRestrictedTeamUser,
  isViewer,
  safeProfilePic,
  safe_get,
} from "other/Helper";
import {
  DownOutlined,
  ShareAltOutlined,
  CheckCircleTwoTone,
  MessageOutlined,
  EyeOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import RiskBadge from "../RiskBadge";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { set_due_date, updatePostStatus } from "api/zero-api";
import NotificationAlert from "other/NotificationAlert";
import Reactions from "../Reactions";
import RespondersPopover from "components/Shared/RespondersPopover";

export default function PostDetails({
  elRef,
  style,
  post,
  isLoading = false,
  onPostUpdate,
  onViewerClick = () => {},
  onCommentsClick,
  onRefreshPost,
  onUpdateResponders,
  onDisplayMapChange,
}) {
  const user = useSelector((state) => state.user.user);
  const org = useSelector((state) => state.org_helper.organization);
  const feed_tags = useSelector((state) => safe_get(state, "feed_helper.tags", []));
  const feed_statuses = useSelector((state) => safe_get(state, "feed_helper.statuses", []));
  const feed_statuses_2 = useSelector((state) => state.feed_helper.statuses2 ?? []);

  const [showMap, setShowMap] = useState(false);
  const [updatingStatus1, setUpdatingStatus1] = useState(false);
  const [updatingStatus2, setUpdatingStatus2] = useState(false);
  const [updatingDueDate, setUpdatingDueDate] = useState(false);

  useEffect(() => {
    if (onDisplayMapChange) {
      onDisplayMapChange(showMap);
    }
  }, [showMap]);

  if (Object.keys(post).length === 0) {
    return null;
  }

  const author = post.revised_by;
  const canShowMap = onDisplayMapChange && post.coordinates?.lat && post.coordinates?.lon && post.location;
  const dueDateObj = post.due_date ? dayjs.unix(post.due_date) : undefined;

  const updateStatus = async (value, group) => {
    const updatingStatus = group === 2 ? updatingStatus2 : updatingStatus1;
    const setUpdatingStatus = group === 2 ? setUpdatingStatus2 : setUpdatingStatus1
    const statuses = group === 2 ? feed_statuses_2 : feed_statuses;

    if (updatingStatus) {
      return;
    }

    try {
      const statusId = value.key;
      const status = statuses.find((obj) => obj.value === statusId);
      if (!status) {
        return;
      }

      setUpdatingStatus(true);

      const res = await updatePostStatus(post.post_uuid, statusId, group);
      const data = await res.json();
      const newPost = safe_get(data, "post", null);
      if (newPost) {
        onPostUpdate(newPost);
        NotificationAlert("success", "", "Post status updated.");
      }
    } catch (err) {
      NotificationAlert("error", "", "Could not update post status");
    } finally {
      setUpdatingStatus(false);
    }
  };

  const updateDueDate = async (value) => {
    if (updatingDueDate) {
      return;
    }

    try {
      setUpdatingDueDate(true);

      const body = JSON.stringify({
        due_date: endOfDayUtc(value).unix(),
      });

      const res = await set_due_date(post.post_uuid, body);
      const data = await res.json();
      const newPost = safe_get(data, "post", null);
      if (newPost) {
        onPostUpdate(newPost);
        NotificationAlert("success", "", "Due date updated.");
      }
    } catch (error) {
      if (error.status === 422) {
        const errorData = await error.json();
        const errorMessages = errorData.error_messages;
        NotificationAlert("error", "", errorMessages[0].message);
      } else {
        NotificationAlert("error", "", "Unable to set due date.");
      }
    } finally {
      setUpdatingDueDate(false);
    }
  };

  const statusMenu = {
    onClick: value => updateStatus(value, 1),
    items: feed_statuses.map((status) => {
      if (status.enabled) {
        return {
          key: status.value,
          disabled: status.value === safe_get(post, "sub_status.sub_status_uuid", ""),
          label: status.name,
        };
      } else {
        return null;
      }
    }),
  };

  const status2Menu = {
    onClick: value => updateStatus(value, 2),
    items: feed_statuses_2.map((status) => {
      if (status.enabled) {
        return {
          key: status.value,
          disabled: status.value === safe_get(post, "sub_status_2.sub_status_uuid", ""),
          label: status.name,
        };
      } else {
        return null;
      }
    }),
  };

  return (
    <div ref={elRef} className="post-details-content" style={style}>
      <div style={{ marginBottom: "10px" }}>
        <div className="media-left">{safeProfilePic(author, "img-circle img-sm bulletin", "bulletin")}</div>
        <div className="media-body bulletin">
          {!isLoading && (
            <UserPopoverCard user={author}>
              <h3 className="author bulletin truncate name long link-hover">
                {author.first_name} {author.last_name}
              </h3>
            </UserPopoverCard>
          )}
          <br />
          <h3 className="author bulletin date">{dateFormatterWithTime(post.revised_at)}</h3>
        </div>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <p className="post-details-content" style={{ textTransform: "none" }}>
          {!isLoading && post.status === "closed" && (
            <Tag color="green" style={{ borderColor: "#52c41a", fontSize: "11px" }}>
              <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Closed {post.closed_time_ago}
            </Tag>
          )}
          {!isLoading && post.status === "open" && (
            <Tag
              style={{
                color: "#505050",
                fontSize: "11px",
              }}
            >
              Opened {post.created_time_ago}
            </Tag>
          )}
          {!isLoading && post.reference_number !== "" && (
            <Tag
              style={{
                color: "#505050",
                fontSize: "11px",
                margin: "0 3px 0 0",
              }}
            >
              {post.reference_number.slice(-4)}
            </Tag>
          )}
        </p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <h3 className="post-details-header">Team:&nbsp;</h3>
        <p className="post-details-content">{post.source_team.name}</p>
        {post.is_shared && (
          <span className="mar-lft-5">
            <Popover
              content={
                <span style={{ fontWeight: "600" }}>
                  Shared with:
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: safe_get(post, "shared_teams", [])
                        .map((team) => team.team_name)
                        .join("<br/>"),
                    }}
                    style={{ fontWeight: "400" }}
                  ></span>
                </span>
              }
              placement="topLeft"
              overlayStyle={{ maxWidth: "220px" }}
            >
              <button className="ButtonLink reaction zero-dark-grey" style={{ cursor: "default" }}>
                <ShareAltOutlined /> {safe_get(post, "shared_teams", []).length}
              </button>
            </Popover>
          </span>
        )}
      </div>
      {/*// CATEGORY*/}
      <div style={{ marginBottom: "10px" }}>
        <p className="post-details-content">
          <span style={{ fontWeight: "600" }}>{safe_get(org, "post_field_names.category", "Category")}:</span>
          &nbsp;{safe_get(post, "category.name", "N/A")}
        </p>
      </div>

      {feed_tags.length > 0 && (
        <div style={{ marginBottom: "10px" }}>
          <h3 className="post-details-header">{safe_get(org, "post_field_names.tag", "Tag")}:&nbsp;</h3>
          <p className="post-details-content">{safe_get(post, "tag.name", "N/A")}</p>
        </div>
      )}

      {feed_statuses.length > 0 && (
        <div style={{ marginBottom: "10px" }}>
          <h3 className="post-details-header">{safe_get(org, "post_field_names.sub_status", "Status")}:&nbsp;</h3>
          {post.status === "closed" && <span>{safe_get(post, "sub_status.name", "None")}</span>}
          {post.status === "open" && (
            <Dropdown
              menu={statusMenu}
              trigger={["click"]}
              disabled={isViewer(user) || isRestrictedTeamUser(post.source_team, user)}
            >
              <p className="post-details-content hover-cursor">
                {safe_get(post, "sub_status.name", "None")}{" "}
                {!isViewer(user) && !isRestrictedTeamUser(post.source_team, user) && <DownOutlined />}
              </p>
            </Dropdown>
          )}
        </div>
      )}

      {feed_statuses_2.length > 0 && (
        <div style={{ marginBottom: "10px" }}>
          <h3 className="post-details-header">{safe_get(org, "post_field_names.sub_status_2", "Status 2")}:&nbsp;</h3>
          {post.status === "closed" && <span>{safe_get(post, "sub_status_2.name", "None")}</span>}
          {post.status === "open" && (
            <Dropdown
              menu={status2Menu}
              trigger={["click"]}
              disabled={isViewer(user) || isRestrictedTeamUser(post.source_team, user)}
            >
              <p className="post-details-content hover-cursor">
                {safe_get(post, "sub_status_2.name", "None")}{" "}
                {!isViewer(user) && !isRestrictedTeamUser(post.source_team, user) && <DownOutlined />}
              </p>
            </Dropdown>
          )}
        </div>
      )}

      {org.custom_post_field.enabled && safe_get(post, "custom_field_value", null) && (
        <div style={{ marginBottom: "10px" }}>
          <p className="post-details-content">
            <span style={{ fontWeight: "600" }}>{safe_get(org, "custom_post_field.name", "")}:</span>&nbsp;
            {safe_get(post, "custom_field_value", "")}
          </p>
        </div>
      )}
      {post.location && org.post_location_enabled && (
        <div style={{ marginBottom: "10px" }}>
          <p className="post-details-content">
            <span style={{ fontWeight: "600" }}>{safe_get(org, "post_field_names.location", "Location")}:</span>
            &nbsp;{post.location}
            {canShowMap && (
              <>
                <span style={{ fontWeight: "500" }}>&nbsp;&middot;&nbsp;</span>
                <span
                  onClick={() => setShowMap(!showMap)}
                  style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {showMap ? "Hide Map" : "Show Map"}
                </span>
              </>
            )}
          </p>
        </div>
      )}
      {post.severity_level >= 1 && org.risk_level_enabled && (
        <div style={{ marginBottom: "10px" }}>
          <h3 className="post-details-header">
            {safe_get(org, "post_field_names.severity_level", "Risk Level")}:&nbsp;
          </h3>
          <div style={{ display: "inline-block" }}>
            <RiskBadge value={post.severity_level} />
          </div>
        </div>
      )}

      {org.post_due_date_enabled && (post.status === "open" || post.due_date) && (
        <div style={{ marginBottom: "10px" }}>
          <h3 className="post-details-header">Due:</h3>
          <DatePicker
            size="small"
            placeholder="Set date"
            className="dont-print due-date"
            format={"MM/DD/YYYY"}
            onChange={updateDueDate}
            disabled={post.status === "closed" || isViewer(user) || isRestrictedTeamUser(post.source_team, user)}
            value={dueDateObj}
            bordered={false}
            showTime={false}
            showToday={false}
            style={{ width: "100px", height: "17px" }}
          />
          {post.due_date && (
            <p className="post-details-content print-only">&nbsp;{dateFormatterNoTime(post.due_date)}</p>
          )}
        </div>
      )}
      <div>
        <div className="media-body bulletin" style={{ marginTop: "8px" }}>
          <div
            className="bulletin-activity"
            style={{
              marginTop: "4px",
              display: "flex",
              gap: "5px",
            }}
          >
            {post.reactions_enabled && <Reactions reactions={post.reactions} post_uuid={post.post_uuid} />}
            <button
              className="ButtonLink reaction zero-dark-grey"
              style={{ cursor: onCommentsClick ? "pointer" : "default" }}
              onClick={() => onCommentsClick?.()}
            >
              <MessageOutlined style={{ fontSize: "15px" }} />
              <p className="comments-count">{post.comments_count}</p>
            </button>
            <button className="ButtonLink reaction zero-dark-grey" onClick={onViewerClick}>
              <EyeOutlined style={{ fontSize: "15px", verticalAlign: "sub" }} />
              <p className="views-count" style={{ marginRight: "0px" }}>
                {post.viewers_count}
              </p>
            </button>
          </div>
          <div style={{ marginTop: "1rem" }}>
            {post.assigns.length !== 0 &&
              [...post.assigns].slice(0, 4).map((user, index) => (
                <UserPopoverCard
                  key={index}
                  user={user}
                  responders={post.assigns}
                  post_uuid={post.post_uuid}
                  org_uuid={org.org_uuid}
                  refresh={onRefreshPost}
                  showRemoveResponder={true}
                >
                  <span style={{ cursor: "pointer" }}>
                    {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                      marginRight: "5px",
                      display: "inline-block",
                    })}
                  </span>
                </UserPopoverCard>
              ))}
            {post.assigns.length > 4 &&
              !isViewer(user) &&
              !isRestrictedTeamUser(post.source_team, user) && (
                <RespondersPopover
                  post_uuid={post.post_uuid}
                  selected_responders={post.assigns}
                  updateResponders={onUpdateResponders}
                >
                  <span style={{ cursor: "pointer" }}>
                    <div
                      className={"default-avatar-circle bulletin"}
                      style={{
                        marginRight: "5px",
                        display: "inline-block",
                        border: "1px solid #505050",
                      }}
                    >
                      <p className="initials" style={{ color: "#505050" }}>
                        +{post.assigns.length - 4}
                      </p>
                    </div>
                  </span>
                </RespondersPopover>
              )}
            {post.assigns.length <= 4 && !isViewer(user) && !isRestrictedTeamUser(post.source_team, user) && !isLoading && (
              <RespondersPopover
                post_uuid={post.post_uuid}
                selected_responders={post.assigns}
                updateResponders={onUpdateResponders}
              >
                <button
                  className="ButtonLink post-details-content mar-top-5"
                  style={{
                    verticalAlign: "bottom",
                    height: "26px",
                    width: "26px",
                  }}
                >
                  <UserAddOutlined style={{ fontSize: "19px" }} />
                </button>
              </RespondersPopover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
