import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Redirect} from 'react-router'

import PasswordRequirementsPopup from '../components/Shared/PasswordRequirementsPopup.js';
import ButtonLoading from '../components/Shared/ButtonLoading';

import logo from '../assets/css/img/ZERO-(white)-small.png';

import TextConfig from '../other/TextConfiguration.js'
import safe_get from '../other/SafeGet.js'

import '../assets/css/Login.css';

import {request_password_restore, password_restore} from '../api/zero-api.js';

import $ from 'jquery'

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            continue: false,
            redirect: false,
            email: "",
            subhead: TextConfig.forgot_password_desc(),
            popoverVisible: false,
            passwordCheck: false,
            confirmation_window: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.passwordMeetsRequirements = this.passwordMeetsRequirements.bind(this);
        this.confirmPasswordCheck = this.confirmPasswordCheck.bind(this);
    }

    componentDidMount() {
        var params = safe_get(this.props, "location.search", "")
        if (params !== "") {
            params = params.split("?")[1];
            var email_param = params.split("&")[0];
            var email = email_param.split("=")[1]

            var code_param = params.split("&")[1]
            var code = code_param.split("=")[1]

            this.setState({
                subhead: TextConfig.reset_password_desc(),
                email: email,
                continue: true,
            }, () => {
                $("#code").prop("value", code);
            })

        }
    }

    passwordMeetsRequirements(value) {
        this.setState({passwordCheck: value});
    }

    confirmPasswordCheck(event) {
        let password = document.getElementById('password').value;

        if (event.target.value !== password) {
            this.setState({pwError: "Password does not match"});
            return false;
        } else {
            this.setState({pwError: null});
            return true;
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({api_loading1: true});

        var self = this;
        var email = document.getElementById('email').value;
        var body = JSON.stringify({
            email: email,
        })

        request_password_restore(body).then(function (success) {
            success.json().then(success => {
                self.setState({
                    emailError: null,
                    email: email,
                    continue: false,
                    api_loading1: false,
                    confirmation_window: true
                })
            })

        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {
                    self.setState({
                        emailError: error.error_messages[0].message,
                        api_loading1: false
                    })
                })
            }
        });

    }

    handleReset(event) {
        var self = this;

        event.preventDefault();

        if (this.state.pwError) {
            return false;
        }

        this.setState({api_loading2: true});

        var body = JSON.stringify({
            email: this.state.email,
            confirmation_code: parseFloat(document.getElementById('code').value),
            password: document.getElementById('password').value
        });

        password_restore(body).then(function (success) {
            success.json().then(success => {
                // NotificationAlert('success', '', "Your password has been reset!");  
                self.setState({api_loading2: false, redirect: true});
            });
        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {
                    let field = error.error_messages[0].field;
                    let errorMsg = error.error_messages[0].message;

                    if (field === "confirmation_code") {
                        self.setState({
                            pwError: null,
                            codeError: errorMsg,
                            api_loading2: false
                        });
                    } else if (field === "new_password" || field === "password") {
                        self.setState({
                            codeError: null,
                            pwError: errorMsg,
                            api_loading2: false
                        });
                    }
                });
            } else {
                self.setState({
                    codeError: null,
                    pwError: "An unexpected error occurred.",
                    api_loading2: false
                });
            }
        });

    }

    render() {

        var divStyle = {
            background: 'none',
            boxShadow: 'none',
            border: 'none',
            height: '160px'
        };


        if (this.state.redirect) {
            return <Redirect to='/login'/>;
        }

        return (
            <div id="container" className="cls-container auth reset-pw">
                <div className="cls-content">
                    {/* <a href="https://teamzero.com/" target="_blank" rel="noopener noreferrer" style={{marginTop: '-62px'}} className="btn-link white text-bold">Back to teamzero.com</a> */}
                    <div className="cls-content-sm panel img" style={divStyle}>
                        <div className="thumbnail noborder logo">
                            <img src={logo} alt=""/>
                        </div>
                    </div>
                    <div className="cls-content-sm panel mar-top" style={{width: "95%", maxWidth: "350px"}}>
                        <div className="row">
                            <div className="col-lg-12 col-lg-offset-0 col-xs-12 col-xs-offset-0">
                                <div className="panel">
                                    <div className="panel-body">

                                        {
                                            !this.state.confirmation_window &&
                                            <div className="mar-ver">
                                                <h1 className="h3 zero-blue">Reset password</h1>
                                                <p>{this.state.subhead}</p>
                                            </div>
                                        }


                                        {
                                            !this.state.continue && !this.state.confirmation_window &&
                                            <form className="mar-btm" onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <input type="email" id="email"
                                                           className="form-control custom-placeholder"
                                                           placeholder="Email"/>
                                                    {
                                                        this.state.emailError &&
                                                        <small className="error">{this.state.emailError}</small>
                                                    }
                                                </div>
                                                <button className="btn btn-primary btn-lg btn-block" type="submit">
                                                    {
                                                        !this.state.api_loading1 ? "Reset Password" : <ButtonLoading/>
                                                    }
                                                </button>
                                            </form>
                                        }
                                        {
                                            this.state.confirmation_window &&
                                            <div className="panel-body">
                                                <h1 className="h3 zero-blue" style={{marginTop: "10px"}}>Email
                                                    Sent!</h1>
                                                <p style={{fontSize: "14px", paddingBottom: "10px"}}>Check your <span
                                                    style={{fontWeight: "700"}}>{this.state.email}</span> inbox for
                                                    instructions on how to reset your password.</p>
                                            </div>
                                        }
                                        {
                                            this.state.continue &&
                                            <form className="mar-btm" onSubmit={this.handleReset} autocomplete="off">

                                                <div className="form-group">
                                                    <input type="hidden" id="code"
                                                           className="form-control custom-placeholder"
                                                           placeholder="Confirmation Code"/>
                                                    {
                                                        this.state.codeError &&
                                                        <small className="error">{this.state.codeError}</small>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <PasswordRequirementsPopup
                                                        passwordMeetsRequirementsCallback={this.passwordMeetsRequirements}
                                                        noMaxWidth={true}/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" placeholder="Confirm Password"
                                                           name="repeatpassword" id="repeat-password"
                                                           className={"form-control signup-field custom-placeholder "}
                                                           onChange={this.confirmPasswordCheck} required/>
                                                    {
                                                        this.state.pwError &&
                                                        <small className={"error "}>{this.state.pwError}</small>
                                                    }
                                                </div>
                                                <button className="btn btn-primary btn-lg btn-block" type="submit">
                                                    {
                                                        !this.state.api_loading2 ? "Save New Password" :
                                                            <ButtonLoading/>
                                                    }
                                                </button>

                                            </form>
                                        }
                                        <div className="mar-ver">
                                            <NavLink to="/login" className="btn-link text-bold text-main "> Back to
                                                Login</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ResetPassword;
