import React, {Component} from 'react';
import {Modal, Select} from 'antd';
import ButtonLoading from '../Shared/ButtonLoading';
import {safe_get} from '../../other/Helper';

import '../../assets/css/antd-custom.css'
import {incidents_report, incident_export, incident_export_xls} from "../../api/zero-api";

class ExportIncidentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentType: undefined,
            loading: false,
            exported: false
        };

    }

    componentDidMount() {

    }

    documentSelect = (value) => {
        this.setState({documentType: value});
    }

    confirm = async () => {
        this.setState({loading: true});
        const {documentType} = this.state;

        if (documentType === "pdf") {
            try {
                await incident_export(this.props.incident.incident_uuid);
            } finally {
                this.setState({loading: false, exported: true});
            }
        } else if (documentType === 'xls2') {
            try {
                await incident_export_xls(this.props.incident.incident_uuid);
            } finally {
                this.setState({loading: false, exported: true});
            }
        } else {
            try {
                const body = JSON.stringify({
                    export_type: "301",
                    location_uuid: this.props.incident.location_uuid,
                    incident_uuid: this.props.incident.incident_uuid,
                    period: "all_time"
                });

                const response = await incidents_report(body);
                const content = await response.json();

                window.location = content.data.file_url;
            } catch (err) {
                console.error(err);
            } finally {
                this.props.onModalClosed();
            }
        }
    }

    cancel = () => {
        this.props.onModalClosed();
    }

    renderFooter() {
        if (this.state.exported) {
            return (
                <div>
                    <button className="btn btn-primary" onClick={this.cancel}>Done</button>
                </div>
            )
        } else {
            return (
                <div>
                    <button className="btn btn-discard" onClick={this.cancel}>Cancel</button>
                    <button className="btn btn-primary" onClick={this.confirm}
                            disabled={this.state.documentType === undefined || this.state.loading}>
                        {
                            this.state.loading ? <ButtonLoading/> : "Export"
                        }
                    </button>
                </div>
            );
        }
    }

    renderBody() {
        if (this.state.exported) {
            return <>We're generating that export for you! It should be in your inbox shortly. Please contact <a
                className="blue-link" href={"mailto:support@teamzero.com"}>support@teamzero.com</a> with any
                questions.</>
        } else {
            return (
                <>
                    <p className="zero-dark-grey">Export case number: {this.props.incident.case_number}. Please select a
                        file type below. We'll send you an email with the file.</p>

                    <Select
                        placeholder="Select file type..."
                        style={{width: "100%"}}
                        dropdownStyle={{zIndex: "10000"}}
                        onChange={this.documentSelect}
                    >
                        <Select.Option value={"pdf"}>
                            Incident Report (PDF)
                        </Select.Option>
                        <Select.Option value={"xls2"}>
                            Incident Report (XLS)
                        </Select.Option>
                        <Select.Option value={"xls"}>
                            OSHA Form 301 (XLS)
                        </Select.Option>
                    </Select>
                </>
            );
        }
    }

    render() {
        return (
            (<Modal
                title={"Export incident"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.cancel}
                maskTransitionName="maskTransitionName"
                footer={this.renderFooter()}
            >{this.renderBody()}</Modal>)
        );
    }
}

export default ExportIncidentModal;