import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import safe_get from '../../other/SafeGet.js';
import {dateFormatterNoTime, scrollToTop} from '../../other/Helper.js';

import {get_form_analytics, get_form_charts} from '../../api/zero-api.js';

import Skeleton from 'react-loading-skeleton';
import {Bar} from 'react-chartjs-2';
import {Popover} from 'antd';

import '../../assets/css/form-builder.css'

class FromAnalyticsTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: {},
            active_tab: "summary",
            total_submissions: 0,
            total_failed_fields: 0,
            avg_score: 0,
            total_users: 0,
            selected_submission: "",
            selected_index: 0,
            submissions: [],
            submission: {},
            submission_fields: [],
            failed_items: [],
            lowest_scoring_subs: [],
            analytics: []
        };

        this.viewSubmission = this.viewSubmission.bind(this);

    }

    componentDidUpdate(prevProps) {
        scrollToTop("page-head");
        if (this.props.period_query !== prevProps.period_query || this.props.forms_query !== prevProps.forms_query) {
            this.setState({loading: true});
            this.getAnalytics();
        }
    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;

        var query = this.props.forms_query + this.props.period_query + `&form_types=${this.props.page_type}`;

        get_form_analytics(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    analytics: safe_get(success, "fields", []),
                    total_submissions: safe_get(success, "total_submissions", 0),
                    avg_score: safe_get(success, "avg_score", ""),
                    total_users: safe_get(success, "total_users", 0),
                    total_failed_fields: safe_get(success, "total_failed_fields", 0),
                    failed_items: safe_get(success, "failed_items", []),
                    lowest_scoring_subs: safe_get(success, "submissions", []),
                    loading: false
                });
            })
        }, function (error) {
            console.log(error);
            self.setState({loading: false});
        });

        get_form_charts(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    data: safe_get(success, "data", {}),
                    options: safe_get(success, "options", {}),
                    chart_title: safe_get(success, "title", ""),
                    subtitle: safe_get(success, "subtitle", ""),
                    loadingChart: false,
                })
            })
        }, function (error) {
            console.log(error)
        })
    }

    viewSubmission(submission) {
        this.props.history.push("/" + this.props.org_uuid + "/home/team/" + submission.team.uuid + "/forms/submission_view/" + submission.submission_uuid);
    }

    render() {
        var columnClass = "col-sm-3 col-xs-6"

        if (this.state.loading) {
            return (
                <div>
                    <div className="panel" style={{margin: "0px", marginBottom: "10px"}}>
                        <div className="panel" style={{padding: "10px", margin: "0px"}}>
                            <div className="row justify-content-start">
                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px 5px 0px 5px"}}>
                                        <Skeleton width={"70px"}/>
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>
                                            <Skeleton width={"60px"} height={"57px"}/>
                                        </p>
                                    </div>
                                </div>
                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px 5px 0px 5px"}}>
                                        <Skeleton width={"70px"}/>
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>
                                            <Skeleton width={"60px"} height={"57px"}/>
                                        </p>
                                    </div>
                                </div>
                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px 5px 0px 5px"}}>
                                        <Skeleton width={"70px"}/>
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>
                                            <Skeleton width={"60px"} height={"57px"}/>
                                        </p>
                                    </div>
                                </div>
                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px 5px 0px 5px"}}>
                                        <Skeleton width={"70px"}/>
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>
                                            <Skeleton width={"60px"} height={"57px"}/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingLeft: "5px", paddingRight: "5px"}}>
                        <div className="col-lg-12 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border">
                                <div className="panel-heading" style={{height: "25px"}}>
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px'}}>
                                        <Skeleton width={"150px"}/>
                                    </h2>
                                    {/* <p onClick={this.props.closeChart} className="chart-subtitle zero-blue"><i className="pli-close" style={{fontSize: '1.3em', cursor: 'pointer'}}/></p>                             */}
                                </div>
                                <div className="pad-all" style={{paddingTop: "0px"}}>
                                    <Skeleton width={"100%"} height={266}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{paddingLeft: "5px", paddingRight: "5px"}}>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border" style={{minHeight: "380px"}}>
                                <div className="panel-heading analytics">
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                        <Skeleton width={"200px"}/>
                                    </h2>
                                </div>
                                <div className="panel-body no-top-pad">
                                    <table className="default-table table-unbordered table table-sm table-hover"
                                           style={{marginBottom: "0px"}}>
                                        <tbody>
                                        {
                                            [...Array(10)].map((submission, index) => (
                                                <tr key={index} className="analytics-indicator">
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg analytics-tag" style={{
                                                            color: 'var(--zero-dark-grey)',
                                                            marginBottom: "0px"
                                                        }}>
                                                            <Skeleton width={"120px"}/>
                                                        </p>
                                                    </td>
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg pull-right" style={{
                                                            color: 'var(--zero-dark-grey)',
                                                            marginBottom: "0px"
                                                        }}><Skeleton width={"20px"}/></p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border" style={{minHeight: "380px"}}>
                                <div className="panel-heading analytics">
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                        <Skeleton width={"200px"}/>
                                    </h2>
                                </div>
                                <div className="panel-body no-top-pad">
                                    <table className="default-table table-unbordered table table-sm table-hover"
                                           style={{marginBottom: "0px"}}>
                                        <tbody>
                                        {
                                            [...Array(10)].map((submission, index) => (
                                                <tr key={index} className="analytics-indicator">
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg analytics-tag" style={{
                                                            color: 'var(--zero-dark-grey)',
                                                            marginBottom: "0px"
                                                        }}>
                                                            <Skeleton width={"120px"}/>
                                                        </p>
                                                    </td>
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg pull-right" style={{
                                                            color: 'var(--zero-dark-grey)',
                                                            marginBottom: "0px"
                                                        }}><Skeleton width={"20px"}/></p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        } else {
            return (
                <div>
                    <div className="panel" style={{margin: "0px", marginBottom: "10px"}}>
                        <div className="panel" style={{padding: "10px", margin: "0px"}}>
                            <div className="row justify-content-start">

                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>
                                        Total Submissions
                                    </p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                           style={{fontSize: "3em"}}>{this.state.total_submissions}</p>
                                    </div>
                                </div>

                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px"}}>Average Score</p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>{this.state.avg_score}<span
                                            style={{fontSize: "30%"}}>%</span></p>
                                    </div>
                                </div>

                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center"
                                       style={{margin: "5px"}}>Unique Authors</p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>{this.state.total_users}</p>
                                    </div>
                                </div>

                                <div className={columnClass}>
                                    <p className="text-sm text-bold zero-blue text-center" style={{margin: "5px"}}>Total
                                        Failed Items</p>
                                    <div className="text-lg">
                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                           style={{fontSize: "3em"}}>{this.state.total_failed_fields}</p>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>


                    <div className="row" style={{paddingLeft: "5px", paddingRight: "5px"}}>
                        <div className="col-lg-12 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border">
                                <div className="panel-heading" style={{height: "25px"}}>
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px'}}>
                                        Submissions Over Time
                                    </h2>
                                </div>
                                <div className="pad-all print-no-x-pad form-submissions-chart"
                                     style={{paddingTop: "0px"}}>
                                    {
                                        !this.state.loadingChart &&
                                        <Bar height={100} data={this.state.data} options={this.state.options}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{paddingLeft: "5px", paddingRight: "5px"}}>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-print-6 col-reduce-padding">
                            <div className="panel thin-border print-unset-height" style={{height: "360px"}}>
                                <div className="panel-heading analytics">
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                        Lowest Scoring Submissions
                                    </h2>
                                </div>

                                <div className="panel-body no-top-pad">
                                    <table className="default-table table-unbordered table table-sm table-hover"
                                           style={{marginBottom: "0px"}}>
                                        <tbody>
                                        {
                                            this.state.lowest_scoring_subs.length === 0 &&
                                            <tr>
                                                <td className="react-bs-table-no-data" style={{border: "none"}}
                                                    colSpan="2">
                                                    {/* <span style={{padding: "8px 12px", height: "200px"}}> */}
                                                    <p className="item lg pull-left"
                                                       style={{color: 'var(--zero-dark-grey)', marginBottom: "0px"}}>No
                                                        submissions.</p>
                                                    {/* </span> */}
                                                </td>
                                            </tr>
                                        }
                                        {

                                            this.state.lowest_scoring_subs.length !== 0 &&
                                            this.state.lowest_scoring_subs.slice(0, 10).map((submission, index) => (
                                                <tr key={index} className="analytics-indicator">
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg analytics-tag"
                                                           style={{color: 'var(--zero-dark-grey)', marginBottom: "0px"}}
                                                           onClick={() => {
                                                               this.viewSubmission(submission)
                                                           }}>
                                                            <span
                                                                className="blue-link">{submission.created_by.first_name} {submission.created_by.last_name}</span>&nbsp;
                                                            <span className="zero-dark-grey"
                                                                  style={{fontSize: "11px"}}>{dateFormatterNoTime(submission.created_at)}</span>
                                                        </p>
                                                    </td>
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg pull-right" style={{
                                                            color: 'var(--zero-dark-grey)',
                                                            marginBottom: "0px"
                                                        }}>{submission.score}%</p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-print-6 col-reduce-padding">
                            <div className="panel thin-border print-unset-height" style={{height: "360px"}}>
                                <div className="panel-heading analytics">
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                        Frequently Failed Items
                                    </h2>
                                </div>
                                <div className="panel-body no-top-pad">
                                    <table className="default-table table-unbordered table table-sm table-hover"
                                           style={{marginBottom: "0px"}}>
                                        <tbody>
                                        {
                                            this.state.failed_items.length === 0 &&
                                            <tr>
                                                <td className="react-bs-table-no-data" style={{border: "none"}}
                                                    colSpan="2">
                                                    {/* <span style={{padding: "8px 12px", height: "200px"}}> */}
                                                    <p className="item lg pull-left"
                                                       style={{color: 'var(--zero-dark-grey)', marginBottom: "0px"}}>No
                                                        failed items.</p>
                                                    {/* </span> */}
                                                </td>
                                            </tr>
                                        }
                                        {
                                            this.state.failed_items.length !== 0 &&
                                            this.state.failed_items.slice(0, 10).map((item, index) => (
                                                <tr key={index} className="analytics-indicator">
                                                    <Popover content={item.text.replace(/&nbsp;/g, '')}
                                                             overlayStyle={{maxWidth: "280px"}} placement="topLeft">
                                                        <td style={{
                                                            borderTop: "none",
                                                            padding: "0px",
                                                            paddingRight: "10px"
                                                        }}>
                                                            <p className="item lg analytics-tag" style={{
                                                                color: 'var(--zero-dark-grey)',
                                                                marginBottom: "0px",
                                                                maxWidth: "33ch"
                                                            }}>
                                                                {item.text.replace(/&nbsp;/g, '')}
                                                            </p>
                                                        </td>
                                                    </Popover>
                                                    <td style={{borderTop: "none", padding: "0px"}}>
                                                        <p className="item lg pull-right" style={{
                                                            color: 'var(--zero-dark-grey)',
                                                            marginBottom: "0px"
                                                        }}>{item.count}</p>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            );
        }

    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        forms_query: safe_get(store, "forms.query", ""),
        period_query: safe_get(store, "forms.period_query", "")
    }
}

export default withRouter(connect(mapStateToProps)(FromAnalyticsTab));
  