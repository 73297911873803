import React, { Component } from 'react';
import { connect } from 'react-redux';


import ZEROlogo from '../../assets/css/img/ZERO-(blue)-small.png';

import {
    cssClasses,
    getFileThumbnail,
    isAdmin,
    isContributor,
    isImage,
    isRestrictedTeamUser,
    isTeamLead,
    isUser,
    isVideo,
    isViewer,
    safe_get,
    updateAssignments
} from '../../other/Helper.js';

import {
    add_post_view,
    add_reaction,
    assign_user,
    close_post,
    deleteBulletinPost,
    export_post,
    getBulletinPost,
    get_comments,
    get_post_subscribers,
    move_post,
    notifyError,
    reopen_post,
    share_post,
    subscribe_users
} from '../../api/zero-api.js';

import {
    CheckCircleOutlined,
    CloudDownloadOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    ShareAltOutlined,
    ToolOutlined
} from '@ant-design/icons';


import '../../assets/css/antd-custom.css';

import CommentAttachment from './CommentAttachment.js';
import Comments from './Comments.js';
import Previewer from '../Previewer';

import ConfirmModal from '../Shared/ConfirmModal';
import PostViewsModal from '../Shared/PostViewsModal';
import CopyPostModal from './CopyPostModal.js';
import ExportPostModal from './ExportPostModal.js';
import MovePostModal from './MovePostModal.js';
import PostsStatusModal from './PostStatusModal.js';
import SharePostModal from './SharePostModal.js';

import NotificationAlert from '../../other/NotificationAlert.js';

import VideoThumbnail from 'components/Shared/VideoThumbnail';
import dayjs from "dayjs";
import PostContent from './PostView/PostContent';
import PostDetails from './PostView/PostDetails';
import { PostNotificationsPopover } from './PostNotificationsPopover';

const MAX_CONTRACTED_HEIGHT = parseInt(
    getComputedStyle(document.body)
    .getPropertyValue('--contracted-post-max-height')
);

class BulletinPost extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            contentHeight: 0,
            contentHeightMobile: 0,
            uuid: "",
            showMap: false,
            preview_visible: false,
            files: [],
            assigns: [],
            post_status: "open",
            attachmentsLoading: true,
            showComments: false,
            post: props.post,
            isContracted: false,
            canBeContracted: false,
        };

        this.postContentRef = React.createRef();
        this.postDetailsRef = React.createRef();

        this.resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === this.postContentRef.current) {
                    if (entry.contentRect.height > MAX_CONTRACTED_HEIGHT) {
                        this.setState({canBeContracted: true, isContracted: true});
                        this.resizeObserver.unobserve(this.postContentRef.current);
                    }
                }
            }
        });

        this.openPreview = this.openPreview.bind(this);
        this.refreshPost = this.refreshPost.bind(this);
        this.closePost = this.closePost.bind(this);
        this.reopenPost = this.reopenPost.bind(this);
        this.deleteBulletin = this.deleteBulletin.bind(this);
        this.movePost = this.movePost.bind(this);
        this.sharePost = this.sharePost.bind(this);
        this.exportPost = this.exportPost.bind(this);

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.post !== this.props.post) {
            this.setState({post: this.props.post});
        }
    }

    componentDidMount() {
        this.resizeObserver.observe(this.postContentRef.current);

        let post = this.state.post;

        let newState = {};
        newState["post"] = post;
        newState["source_team"] = post.source_team;
        newState["reactions"] = post.reactions;
        newState["reactions_enabled"] = post.reactions_enabled;
        newState["assigns"] = post.assigns;
        newState["due_date"] = post.due_date;
        newState["due_date_moment"] = post.due_date ? dayjs.unix(post.due_date) : undefined;
        newState["is_shared"] = post.is_shared;
        newState["sub_status"] = post.sub_status
        newState["post_status"] = post.status;
        newState["viewers_count"] = post.viewers_count;
        newState["comments_count"] = post.comments_count
        newState["closed_time_ago"] = post.closed_time_ago
        newState["showMap"] = (post.coordinates.lat && post.coordinates.lon)
        newState["subscribers_count"] = post.subscribers_count
        newState["subscribers"] = safe_get(post, "subscribers", [])

        this.setState(newState);
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    handleMenuClick(e) {
        switch (e.key) {
            case "status":
                this.openStatusModal();
                break;
            case "edit":
                if (this.props.isMyPostsFeed) {
                    this.props.goTo("/" + this.props.organization.organization_uuid + "/home/my_posts/edit/" + this.state.post.post_uuid);
                } else {
                    this.props.goTo("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/feed/edit/" + this.state.post.post_uuid)
                }
                break;
            case "share":
                this.setState({showSharePostModal: true});
                break;
            case "copy":
                this.setState({showCopyPostModal: true});
                break;
            case "move":
                this.setState({showMovePostModal: true});
                break;
            case "export":
                this.setState({showExportModal: true});
                break;
            case "delete":
                this.setState({showDeleteModal: true})
                break;
            default:
                return;
        }
    }

    getSubscribers = () => {
        var self = this;
        get_post_subscribers(this.state.post.post_uuid).then(success => {
            success.json().then(success => {
                self.setState({
                    subscribers: safe_get(success, "subscribers", []),
                    lodaing_subscribers: false
                });
            })
        });
    }

    openStatusModal = () => {
        this.setState({showPostStatusModal: true});
    }

    toggleComments = () => {
        if (!this.state.showComments) {
            this.setState({lodaing_subscribers: true});
            this.getSubscribers();
            this.refreshPost();
        }
        this.setState({showComments: !this.state.showComments});
    }

    openViewersModal = () => {
        this.setState({showViews: true});
        this.refreshPost();
    }

    openDeleteModal() {
        this.setState({
            showModal: true,
            modalText: "Are you sure you want to delete this post?",
            modalTitle: "Confirmation",
            confirmButtonName: "Delete this post",
            deleteModal: true,
            statusModal: false,
            showViews: false,
        });
    }

    cancelModal() {
        this.setState({
            showModal: false,
            deleteModal: false,
            showViews: false,
            preview_visible: false,
            openResponderModal: false,
            showPostStatusModal: false,
            showExportModal: false,
            showSharePostModal: false,
            showMovePostModal: false,
            showDeleteModal: false,
            showCopyPostModal: false
        });
    }

    openPreview(index) {
        if (this.state.post.attachments.length === 1) {
            index = 0;
        }

        if (isVideo(this.state.post.attachments[index].file_name)) {
            var video = document.getElementById("mini-video-player");
            if (video) {
                video.pause();
            }
        }

        this.addPostView();
        this.setState({preview_visible: true, preview_index: index});
    }

    postContentHTML = (text) => {
        let links = safe_get(this.props, "post.links", [])
        if (links.length > 0) {
            const domParser = new DOMParser();
            const postBodyDom = domParser.parseFromString(text, 'text/html');
            const smartLinks = postBodyDom.querySelectorAll('a[data-zero-link]');

            for (let link of smartLinks) {
                const index = parseInt(link.dataset.zeroLink, 10);
                link.innerHTML = `<img src="${ZEROlogo}"/>${links[index]}`;
            }

            const fakeEl = document.createElement('div');
            fakeEl.append(...postBodyDom.body.childNodes);

            text = fakeEl.innerHTML;
        }
        return {__html: text}
    }

    dragAttachment(ev, file) {
        return;
        // const {mime_type, file_name, public_url} = file;
        // const url = `${CONFIG.BASE_API_URL()}/download_file?url=${encodeURIComponent(public_url)}`;
        // ev.dataTransfer.clearData();
        // ev.dataTransfer.setData('DownloadURL', `${mime_type}:${file_name}:${url}`);
    }

    showAttachment(file, index) {
        var file_name = safe_get(file, "file_name", "");
        var thumbnail = getFileThumbnail(file);

        if (isVideo(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <VideoThumbnail
                        imgSrc={thumbnail}
                        imgAlt={file_name}
                        onPlay={() => {
                            this.openPreview(index)
                        }}
                        imgProps={{
                            onDragStart: (ev) => this.dragAttachment(ev, file)
                        }}
                    />
                </li>
            )
        } else if (isImage(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <button className="ButtonLink" onClick={(e) => {
                        this.openPreview(index);
                    }}>
                        <img className="post-img" src={thumbnail} alt={file_name}
                             onDragStart={(ev) => this.dragAttachment(ev, file)} style={{
                            objectFit: 'contain',
                            width: "100px",
                            height: "100px",
                            borderRadius: "2px",
                            backgroundColor: "#f6f6f6",
                            border: "1px solid #e5e5e5"
                        }}/>
                    </button>
                </li>
            )
        } else {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", height: "100px", width: "100px"}}>
                    <button className="ButtonLink" style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "2px",
                        backgroundColor: "#f6f6f6",
                        border: "1px solid #e5e5e5",
                        position: "absolute"
                    }} onClick={() => {
                        this.handleFilePreviewer(file, index);
                    }}>
                        <img src={thumbnail} alt={file_name} onDragStart={(ev) => this.dragAttachment(ev, file)}
                             style={{
                                 objectFit: 'cover',
                                 width: "55px",
                                 height: "55px",
                                 padding: "5px",
                                 display: "block",
                                 margin: "0 auto"
                             }}/>
                        <p className="zero-dark-grey ellipsis-2-lines" style={{
                            margin: "0px",
                            padding: "0px 5px 5px 5px",
                            fontSize: "80%",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                        title={file_name}
                        >{file_name}</p>
                    </button>
                </li>
            )
        }
    }

    handleFilePreviewer = (file, index) => {
        this.addPostView();
        this.setState({preview_visible: true, preview_index: index});
    }


    deleteBulletin() {
        var self = this;
        var post_uuid = this.state.post.post_uuid;
        deleteBulletinPost(post_uuid).then(function (success) {
            // self.props.dispatch(feedHelperActions.update_feed_position(0));
            // self.removeRelatedNotifications(post_uuid);
            self.cancelModal();
            NotificationAlert('success', '', "Post deleted.");
            self.props.refreshFeed();
        }, function (error) {
            notifyError(error)
            self.cancelModal()
        });
    }

    sharePost(team_uuids, user_uuids, select_all = false) {
        var self = this;
        var body = JSON.stringify({
            team_uuids: team_uuids,
            subscribe_all: select_all,
            subscribe_user_uuids: select_all ? [] : user_uuids
        });

        var post_uuid = this.state.post.post_uuid;
        share_post(post_uuid, body).then(function (success) {
            self.cancelModal();
            self.setState({is_shared: true});
            self.refreshPost();
            NotificationAlert('success', 'Success!', "Post shared.");
        }, function (error) {
            self.cancelModal();
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to share post.");
        });
    }

    addPostView() {
        add_post_view(this.state.post.post_uuid, '').then(success => {
            if (success.status == 201) {
                this.refreshPost();
            }
        })
    }

    movePost(team_uuid, user_uuids, select_all = false) {
        var self = this;

        var body = JSON.stringify({
            new_team_uuid: team_uuid,
            subscribe_all: select_all,
            subscribe_user_uuids: select_all ? [] : user_uuids
        })

        var post_uuid = this.state.post.post_uuid
        move_post(post_uuid, body).then(function (success) {
            self.cancelModal();
            self.refreshPost();
            NotificationAlert('success', 'Success!', "Post moved.");
        }, function (error) {
            self.cancelModal();
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to move post.");
        });
    }

    exportPost() {
        var self = this;
        var post_uuid = this.state.post.post_uuid;
        export_post(post_uuid).then(function (success) {
            success.json().then(success => {
                window.location = success.data.public_url;
                self.refreshPost();
                self.cancelModal();
            });
        }, function (error) {
            self.cancelModal()
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to export post.")
        })
    }

    selectEmoji = (emoji) => {
        var self = this;
        let reactions = [...this.state.reactions];

        let exists = reactions.find((reaction) => {
            return reaction.emoji === emoji.native
        });

        let reaction = {}

        if (exists) {
            let index = reactions.indexOf(exists);

            reaction = exists;
            reaction["count"] = reaction.count + 1;
            reaction["liked"] = true;
            reaction["users"] = reaction.users.concat([this.props.user])

            reactions[index] = reaction;
        } else {
            reaction["emoji"] = emoji.native;
            reaction["count"] = 1;
            reaction["liked"] = true;
            reaction["users"] = [this.props.user];

            reactions.push(reaction);
        }

        this.setState({reactions: reactions, emojiPickerVisible: false});
        add_reaction(this.state.post.post_uuid, emoji.native);
    }

    refreshPost() {
        var self = this;
        getBulletinPost(this.state.post.post_uuid).then(function (success) {
            success.json().then(success => {
                self.setState({
                    post: safe_get(success, "post", {}),
                    sub_status: safe_get(success, "post.sub_status", {}),
                    assigns: safe_get(success, "post.assigns", []),
                    subscribers: safe_get(success, "post.subscribers", []),
                    subscribers_count: safe_get(success, "post.subscribers_count", 0),
                    viewers_count: safe_get(success, "post.viewers_count", 0),
                    reactions: safe_get(success, "post.reactions", []),
                    source_team: safe_get(success, "post.source_team", {}),
                    openResponderModal: false,
                });

            });
        }, function (error) {
            self.setState({openResponderModal: false});
            notifyError(error);
        });

        get_comments(this.state.post.post_uuid).then(function (success) {
            success.json().then(success => {
                let comments = safe_get(success, "comments", []);
                self.setState({
                    comments: comments,
                    comments_count: comments.length
                });
            });
        }, function (error) {
            console.log(error);
        })
    }

    closePost(reason, attachments) {
        var self = this;
        var post_uuid = this.state.post.post_uuid;

        var body = JSON.stringify({
            reason: reason,
            attachments: attachments
        });

        close_post(post_uuid, body).then(function (success) {
            success.json().then(success => {
                self.cancelModal();
                self.refreshPost();
                self.setState({post_status: "closed", closed_time_ago: "just now"});
                updateAssignments(self.props.dispatch);
                NotificationAlert('success', 'Success!', "Post closed.");
            });
        }, function (error) {
            self.cancelModal();
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to close post.");
        });
    }

    reopenPost(reason, attachments) {
        var self = this;
        var post_uuid = this.state.post.post_uuid;
        var body = JSON.stringify({
            reason: reason,
            attachments: attachments
        });

        reopen_post(post_uuid, body).then(function (success) {
            success.json().then(success => {
                self.cancelModal();
                self.refreshPost();
                self.setState({post_status: "open"});
                updateAssignments(self.props.dispatch);
                NotificationAlert('success', 'Success!', "Post reopened.");
            });
        }, function (error) {
            self.cancelModal()
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to reopen post.")
        })
    }

    updateResponders = (responders_uuids) => {
        let self = this;

        let body = JSON.stringify({
            user_uuids: responders_uuids
        });

        assign_user(this.state.post.post_uuid, body).then(function (success) {
            success.json().then(success => {
                self.setState({
                    assigns: safe_get(success, "post.assigns", [])
                });
                self.refreshPost();
            });
        }, function (error) {
            console.log(error)
        });
    }

    render() {
        var titleStyle = {
            fontWeight: '600',
            lineHeight: '1.4',
            marginBottom: '0px',
            marginTop: "0px",
            wordBreak: 'break-word'
        }

        let is_Admin = isAdmin(this.props.user);
        let is_TeamLead = isTeamLead(this.props.user);
        let is_Contributor = isContributor(this.props.user);
        let is_user = isUser(this.props.user);
        // var is_viewer = isViewer(this.props.user);

        let author = safe_get(this.props, "post.revised_by", {});
        let is_Author = (this.props.user.uuid === safe_get(author, "user_uuid", ""));

        var canEditTeam = (isAdmin(this.props.user) || isTeamLead(this.props.user)) && this.props.current_team_uuid !== "my_teams";

        let canChangeStatus = (is_Admin || is_TeamLead || is_Contributor || is_user)
        let canShare = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && (this.props.teams.length > 1);
        let canMove = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && (this.props.teams.length > 1);
        let canEdit = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && this.state.post_status === "open";
        let canDelete = (is_Admin || is_Author || is_TeamLead) && this.state.post_status === "open";
        
        let postLink = this.props.isMyPostsFeed ? ("/" + this.props.organization.organization_uuid + "/home/my_posts/post/" + this.state.post.post_uuid) : ("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/feed/post/" + this.state.post.post_uuid);

        let attachments = safe_get(this.props, "post.attachments", []);

        const menu = {
            onClick: this.handleMenuClick,
            items: [
                {
                    key: "status",
                    disabled: !canChangeStatus,
                    icon: this.state.post_status === "open" ? <CheckCircleOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/> : <ToolOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: this.state.post_status === "open" ? "Close" : "Reopen"
                },
                {
                    key: "share",
                    icon: <ShareAltOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Share"
                },
                {
                    key: "move",
                    icon: <ExportOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Move"
                },
                {
                    key: "copy",
                    icon: <CopyOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Copy"
                },
                {
                    key: "export",
                    icon: <CloudDownloadOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Export"
                },
                {
                    type: "divider"
                },
                {
                    key: "edit",
                    disabled: !canEdit,
                    icon: <EditOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Edit"
                },
                {
                    key: "delete",
                    disabled: !canDelete,
                    className: canDelete ? "zero-delete-red" : "",
                    icon: <DeleteOutlined className="mar-rgt-5" style={{fontSize: "15px"}}/>,
                    label: "Delete"
                }
            ]
        }

        return (
            <div className="bulletin-feed-post">
                {
                    this.state.preview_visible &&
                    <Previewer
                        show={this.state.preview_visible}
                        close={() => {
                            this.setState({preview_visible: false});
                        }}
                        attachments={attachments}
                        index={this.state.preview_index}
                    />
                }
                {/* {
                    this.state.openResponderModal &&
                    <AssignResponderModal
                        show={this.state.openResponderModal}
                        cancel={() => { this.setState({ openResponderModal: false }) }}
                        post_uuid={this.state.post.post_uuid}
                        existingResponders={this.state.assigns}
                        complete={this.refreshPost}
                    />
                }     */}

                {
                    this.state.showPostStatusModal &&
                    <PostsStatusModal
                        show={this.state.showPostStatusModal}
                        cancel={this.cancelModal}
                        post_status={this.state.post_status}
                        post_uuid={this.state.post.post_uuid}
                        subStatusUuid={this.state.post.sub_status.sub_status_uuid}
                        subStatus2Uuid={this.state.post.sub_status_2.sub_status_uuid}
                        user_uuid={this.props.user.uuid}
                        subscribers={this.state.subscribers}
                        responders={this.state.assigns}
                        closePost={this.closePost}
                        reopenPost={this.reopenPost}
                    />
                }
                {
                    this.state.showMovePostModal &&
                    <MovePostModal
                        show={this.state.showMovePostModal}
                        cancel={this.cancelModal}
                        teams={this.props.teams}
                        source_team={this.state.source_team.team_uuid}
                        post_uuid={this.state.post.post_uuid}
                        existing_subscribers={this.state.post.subscribers}
                        confirm={this.movePost}
                        canMove={canMove}
                        user={this.props.user}
                    />
                }
                {
                    this.state.showSharePostModal &&
                    <SharePostModal
                        show={this.state.showSharePostModal}
                        cancel={this.cancelModal}
                        user={this.props.user}
                        post_uuid={this.state.post.post_uuid}
                        teams={this.props.teams}
                        existing_subscribers={this.state.post.subscribers}
                        confirm={this.sharePost}
                        source_team={this.state.source_team}
                        canShare={canShare}
                    />
                }
                {
                    this.state.showCopyPostModal &&
                    <CopyPostModal
                        show={this.state.showCopyPostModal}
                        cancel={this.cancelModal}
                        post={this.state.post}
                        callback={this.props.refreshFeed}
                    />
                }
                {
                    this.state.showExportModal &&
                    <ExportPostModal
                        show={this.state.showExportModal}
                        cancel={this.cancelModal}
                        confirm={this.exportPost}
                    />
                }
                {
                    this.state.showViews &&
                    <PostViewsModal
                        show={this.state.showViews}
                        cancel={this.cancelModal}
                        post_uuid={this.state.post.post_uuid}
                    />
                }
                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        title={"Delete post"}
                        body={"Are you sure you want to delete this post? This action cannot be undone."}
                        show={this.state.showDeleteModal}
                        cancel={this.cancelModal}
                        confirm={this.deleteBulletin}
                        confirmButtonName={"Delete post"}
                    />
                }

                <div>

                    <div className="panel-body pad-no bulletin-post">
                        <div
                            className={cssClasses({
                                $base: "post-main-content-container",
                                "contracted": this.state.isContracted
                            })}
                        >
                            <div className="container-fade">
                                <div ref={this.postContentRef} className="post-main-content">
                                    <PostContent
                                        post={this.state.post}
                                        postDetailsRef={this.postDetailsRef}
                                        canEditTeam={canEditTeam}
                                        menu={menu}
                                        postLink={postLink}
                                        attachments={attachments}
                                        onOpenPreview={this.openPreview}
                                        handleFilePreviewer={this.handleFilePreviewer}
                                    />
                                </div>
                            </div>
                            { this.state.canBeContracted &&
                                <div className="text-center">
                                    <button
                                        className="ButtonLink blue-link"
                                        onClick={() => {
                                            const { isContracted } = this.state;
                                            this.setState({isContracted: !isContracted});
                                            if (!isContracted) {
                                                /** @type {HTMLElement} */
                                                const postContentEl = this.postContentRef.current;
                                                postContentEl.scrollIntoView();
                                                window.scrollBy(0, -80)
                                            }
                                        }}
                                    >View {this.state.isContracted ? "more" : "less"}...</button>
                                </div>
                            }
                        </div>

                        <PostDetails
                            elRef={this.postDetailsRef}
                            post={this.state.post}
                            isLoading={false}
                            onPostUpdate={this.refreshPost}
                            onViewerClick={this.openViewersModal}
                            onCommentsClick={this.toggleComments}
                            onUpdateResponders={this.updateResponders}
                            onRefreshPost={this.refreshPost}
                        />
                    </div>

                    {
                        this.state.showComments &&
                        <div>
                            <hr className="bulletin"/>
                            <Comments
                                updateCommentsCount={this.updateCommentsCount}
                                post_uuid={this.state.post.post_uuid}
                                postStatus={this.state.post_status}
                                reactions_enabled={this.state.reactions_enabled}
                                comments={this.state.comments}
                            />

                            <div>
                                {
                                    !isViewer(this.props.user) && !isRestrictedTeamUser(this.state.source_team, this.props.user) &&
                                    <div>
                                        {
                                            this.state.post_status === "closed" &&
                                            <div>
                                                <hr className="bulletin"/>
                                                <div className="panel-body">
                                                    <p className="text-md text-main text-center zero-dark-grey">
                                                        This post is closed. To update or add a comment, please <button
                                                        className="ButtonLink underline-hover zero-light-blue"
                                                        onClick={this.openStatusModal}>reopen it</button> first.
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.post_status === "open" &&
                                            <CommentAttachment
                                                user_uuid={this.props.user.uuid}
                                                post_uuid={this.state.post.post_uuid}
                                                successfullComment={this.refreshPost}
                                            />
                                        }
                                        <hr className="bulletin"/>
                              
                                        <PostNotificationsPopover
                                            selectedSubscribers={this.state.subscribers}
                                            isLoading={this.state.lodaing_subscribers}
                                            handleOnChange={async (subscribers) => {
                                                await subscribe_users(this.state.post.post_uuid, JSON.stringify({
                                                    user_uuids: subscribers
                                                }))
                                                this.getSubscribers()
                                            }}
                                            orgUuid={this.props.org_uuid}
                                            teamUuid={this.state.source_team.team_uuid}
                                        />

                                    </div>
                                }
                            </div>

                        </div>
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
        feed_tags: safe_get(store, "feed_helper.tags", []),
        feed_statuses: safe_get(store, "feed_helper.statuses", []),
        risk_enabled: safe_get(store, "org_helper.organization.risk_level_enabled", true),
        location_enabled: safe_get(store, "org_helper.organization.post_location_enabled", true),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
    }
}

export default connect(mapStateToProps)(BulletinPost);




