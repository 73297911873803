import React, {useEffect, useState} from 'react';
import {Input} from 'antd';

import DirectoryTag from './DirectoryTag';
import LoadingIndicator from 'components/Shared/LoadingIndicator.js';
import {CheckInLocale} from 'other/Constants';
import {safeProfilePic} from 'other/Helper';
import Skeleton from 'react-loading-skeleton';

function UserWithAvatar({user, enableFloorPlan, showCheckin}) {
    const wrapperStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    return (
        <div className="media">
            {showCheckin === true && <div className="media-left profile-img" style={{verticalAlign: 'middle'}}>
                {safeProfilePic(user, "img-sm img-circle user-card", "user-card text-semibold")}
            </div>
            }
            <div className="media-body account-card ">
                <div>
                    <p className="text-md text-semibold profile truncate name zero-blue" style={{
                        marginBottom: '0.5rem',
                        marginLeft: '2px',
                        maxWidth: "130px",
                        fontSize: "13px",
                        lineHeight: "1em"
                    }}>
                        {user.first_name} {user.last_name}
                    </p>
                    {user.email &&
                        <p className="text-sm profile title truncate title zero-dark-grey">
                            {user.email}
                        </p>
                    }
                    {showCheckin === true &&
                        <DirectoryTag user={user} enableFloorPlan={enableFloorPlan}
                                      style={{marginTop: '0.5rem', maxWidth: '245px'}}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default function Directory({
                                      directoryLoader,
                                      onUserSelected,
                                      defaultSelectedUser,
                                      allowSelection = false,
                                      limit = 10,
                                      hideUsersUntilSearch = false,
                                      enableFloorPlan = true,
                                      showCheckin = true
                                  }) {
    const [userSearch, setUserSearch] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(defaultSelectedUser);

    useEffect(() => {
        if (directoryLoader.ok) {
            let users = directoryLoader.data;

            if (userSearch) {
                const search = userSearch.toLowerCase();
                users = users.filter(user => {
                    const fullName = `${user.first_name} ${user.last_name}`
                    if (fullName.toLowerCase().includes(search)) return true;
                    if (user.email && user.email.toLowerCase().includes(search)) return true;
                    if (user.last_check_in && user.last_check_in.locale === CheckInLocale.OFFICE) {
                        if (user.last_check_in.desk_name.toLowerCase().includes(search)) return true;
                    }
                    if (user.first_assigned_desk && user.first_assigned_desk.desk_name.toLowerCase().includes(search)) {
                        return true;
                    }
                })
            }

            const newUserList = users.slice(0, limit)

            if (!newUserList.find(user => user === selectedUser)) {
                setSelectedUser(null);
            }
            setFilteredUsers(newUserList);
        }
    }, [directoryLoader, userSearch])

    useEffect(() => {
        if (onUserSelected) {
            onUserSelected(selectedUser);
        }
    }, [selectedUser]);

    const rowFlexStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem',
        marginBottom: '1rem',
    }

    const generateRowClassName = (user) => {
        let className = '';

        if (allowSelection) {
            className += 'spaces-directory-user';

            if (user.uuid === selectedUser?.uuid) {
                className += ' spaces-directory-selected-user';
            }
        }

        return className;
    }

    if (directoryLoader.error) {
        return <div>Could not load directory.</div>;
    } else if (directoryLoader.ok && directoryLoader.data.length === 0) {
        return <div>Directory is empty.</div>
    } else if (!directoryLoader.ok) {
        return <div style={{marginBottom: '1rem'}}><Skeleton height={30}/></div>;
    }

    return (
        <div>
            <Input
                value={userSearch}
                onChange={e => setUserSearch(e.target.value)}
                placeholder="Enter colleague's name..."
                allowClear
                className="zero-dark-grey"
                style={{marginBottom: '1rem'}}
            />
            <div>
                {(!hideUsersUntilSearch || userSearch.length > 0) &&
                    filteredUsers.map(user => (
                        <div
                            className={generateRowClassName(user)}
                            style={rowFlexStyle}
                            key={user.uuid}
                            onClick={() => {
                                allowSelection && setSelectedUser(user)
                            }}
                        >
                            <UserWithAvatar user={user} enableFloorPlan={enableFloorPlan} showCheckin={showCheckin}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}