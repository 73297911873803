import { Select } from "antd";
import { get_submission } from "api/zero-api";
import FormFieldsRenderer from "components/Forms/FormFieldsRenderer";
import Button from "components/Shared/Button";
import ChartLabels from "components/Shared/ChartLabels";
import LoadingIndicator from "components/Shared/LoadingIndicator";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import { FormType } from "other/Constants";
import { dateFormatterNoTime, dateFormatterWithTime, safe_get } from "other/Helper";
import { fieldHasResponses } from "other/forms";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

const beforePrintHandler = () => {
    const containers = document.querySelectorAll('.responsive-chart-container');
    for (const container of containers) {
        container.style.width = '80%';
    }
}

const afterPrintHandler = () => {
    const containers = document.querySelectorAll('.responsive-chart-container');
    for (const container of containers) {
        container.style.width = '100%';
    }
}

async function loadSubmission(submissionId) {
    const res = await get_submission(submissionId);
    const data = await res.json();
    return data.submission;
}

const submissionCache = new Map();

export default function FormAnalytics({form, analyticsLoader, onTeamChange}) {
    const [selectedTab, setSelectedTab] = useState("summary");
    const [selectedTeam, setSelectedTeam] = useState("all_teams");
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
    const [selectedSubmissionIndex, setSelectedSubmissionIndex] = useState(0);
    const [maxSubmissionIndex, setMaxSubmissionIndex] = useState(0);
    const submissionLoader = useAsyncDataLoader();

    useEffect(() => {
        window.addEventListener('beforeprint', beforePrintHandler);
        window.addEventListener('afterprint', afterPrintHandler);
        return () => {
            submissionCache.clear();
            window.removeEventListener('beforeprint', beforePrintHandler);
            window.removeEventListener('afterprint', afterPrintHandler);
        }
    }, []);

    useEffect(() => {
        submissionLoader.load(async () => {
            if (selectedSubmissionId === null) {
                return null;
            }
            const index = analyticsLoader.data.submissionStubs.findIndex(stub => stub.submission_uuid === selectedSubmissionId);
            setSelectedSubmissionIndex(index);
            if (submissionCache.has(selectedSubmissionId)) {
                return submissionCache.get(selectedSubmissionId);
            }
            const submission = await loadSubmission(selectedSubmissionId);
            submissionCache.set(selectedSubmissionId, submission);
            return submission;
        });
    }, [selectedSubmissionId]);

    useEffect(() => {
        if (analyticsLoader.status === "success") {
            const stubs = analyticsLoader.data?.submissionStubs ?? [];
            console.log('>> stubs', stubs);
            setMaxSubmissionIndex(stubs.length - 1);
            if (stubs.length > 0) {
                setSelectedSubmissionId(stubs[0].submission_uuid);
            }
        }
    }, [analyticsLoader.status])

    const teams = safe_get(form, "submissions_teams", []);

    const tabChange = (e) => {
        setSelectedTab(e.target.value);
    }

    const teamChange = (value) => {
        setSelectedTeam(value);
        onTeamChange(value);
    }

    if (analyticsLoader.status !== "success") {
        return <LoadingIndicator />
    }

    const nextPrevSubmission = (indexDelta) => {
        const stubs = analyticsLoader.data.submissionStubs;
        const newIndex = selectedSubmissionIndex + indexDelta;
        if (newIndex === maxSubmissionIndex + 1 || newIndex < 0) {
            return;
        }
        setSelectedSubmissionId(stubs[newIndex].submission_uuid);
        setSelectedSubmissionIndex(newIndex);
    }

    const { analytics, submissionStubs } = analyticsLoader.data;
    const submission = submissionLoader.data ?? null;

    return (
        <div className="row zero-blue" style={{maxWidth: "850px", margin: "0 auto"}}>
            <div className="btn-group btn-group-toggle form-analytics mar-top-10" data-toggle="buttons">
                <label
                    className={"btn btn-secondary " + (selectedTab === "summary" && "active")}>
                    <input type="radio" name="options" value="summary"
                            onChange={tabChange}/>
                    Summary
                </label>
                <label
                    className={"btn btn-secondary " + (selectedTab === "individual" && "active")}>
                    <input type="radio" name="options" value="individual"
                            onChange={tabChange}/>
                    Individual
                </label>
            </div>

            { FormType.hasTeamAssignments(form.form_type) &&
                <div className="mar-top-10">
                    <Select
                        style={{maxWidth: "300px", width: "100%"}}
                        placeholder="Select a team"
                        onChange={teamChange}
                        value={selectedTeam}
                        options={[
                            {
                                label: "All Teams",
                                value: "all_teams"
                            },
                            ...teams.map(team => ({
                                label: team.name,
                                value: team.team_uuid
                            }))
                        ]}
                    />
                </div>
            }

            { analytics.total_submissions === 0 &&
                <p className="mar-top-10">No submissions yet.</p>
            }

            { selectedTab === "summary" && analytics.total_submissions > 0 &&
                <div style={{marginTop: "10px"}}>
                    <hr/>
                    {
                        analytics.fields.map((field, index) => (
                            field.is_chart &&
                            <div className="mar-top-10 print-avoid-breaks" key={index}>
                                <h4 className="zero-blue">
                                    {field.label}
                                </h4>
                                {
                                    fieldHasResponses(field) === false ? (
                                        <div>No responses yet.</div>
                                    ) : (
                                        <>
                                            <ChartLabels data={field.data.data}/>
                                            <div
                                                className="responsive-chart-container"
                                                style={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '350px'
                                                }}
                                            >
                                                <Doughnut
                                                    data={field.data.data}
                                                    options={{
                                                        ...field.data.options,
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        legend: {
                                                            display: false
                                                        },
                                                        tooltips: {
                                                            callbacks: {
                                                                label: (tooltipItem, data) => {
                                                                    let label = data.labels[tooltipItem.index] || '';
                                                                    return label;
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                {index !== analytics.fields.length - 1 &&
                                    <hr style={{margin: '35px 0'}}/>
                                }
                            </div>
                        ))
                    }
                </div>
            }

            { selectedTab === "individual" && analytics.total_submissions > 0 &&
                <div>
                    <div className="mar-top-10">
                        <Select
                            style={{maxWidth: "300px", width: "100%"}}
                            placeholder="Select a submissions"
                            onChange={setSelectedSubmissionId}
                            value={selectedSubmissionId}
                            options={
                                submissionStubs.map(submission => ({
                                    label: `${submission.user_name}, ${dateFormatterNoTime(submission.submission_date)}`,
                                    value: submission.submission_uuid
                                }))
                            }
                        />
                        <div className="pull-right hidden-xs">
                            <Button
                                type="plain"
                                round
                                className="mar-rgt-5"
                                onClick={() => nextPrevSubmission(-1)}
                                disabled={submissionLoader.status !== "success" || selectedSubmissionIndex === 0}
                            >Previous</Button>
                            <Button
                                type="plain"
                                round
                                onClick={() => nextPrevSubmission(1)}
                                disabled={submissionLoader.status !== "success" || selectedSubmissionIndex === maxSubmissionIndex}
                            >Next</Button>
                        </div>
                    </div>

                    { submissionLoader.status === "success" && submission &&
                        <div>
                            <hr/>
                            <p className="zero-dark-grey">
                                {safe_get(submission, "edited_by.first_name", "")} {safe_get(submission, "edited_by.last_name", "")} submitted {safe_get(submission, "form.name", "")} {submission.team && `in ${safe_get(submission, "team.name", "")}`} on {dateFormatterWithTime(submission.edited_at)}
                            </p>
                            <div className="panel thin-border"
                                    style={{margin: "0px", marginBottom: "10px"}}>
                                <div className="panel" style={{padding: "10px", margin: "0px"}}>
                                    <div className="row justify-content-start">
                                        <div className="col-xs-4">
                                            <p className="text-sm text-bold zero-blue text-center"
                                                style={{margin: "5px"}}>
                                                Submission Score
                                            </p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                                    style={{fontSize: "3em"}}>
                                                    {submission.score}{!isNaN(submission.score) &&
                                                    <span style={{fontSize: "30%"}}>%</span>}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-xs-4">
                                            <p className="text-sm text-bold zero-blue text-center"
                                                style={{margin: "5px"}}>Failed Items</p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                    style={{fontSize: "3em"}}>
                                                    {submission.failed_items_count ? submission.failed_items_count : "0"}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-xs-4">
                                            <p className="text-sm text-bold zero-blue text-center"
                                                style={{margin: "5px"}}>Comments</p>
                                            <div className="text-lg">
                                                <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                    style={{fontSize: "3em"}}>
                                                    {submission.comment_count}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="thin-border" style={{padding: "15px"}}>
                                <FormFieldsRenderer
                                    form_uuid={safe_get(submission, "form.form_uuid", "undefined")}
                                    submission_uuid={safe_get(submission, "submission_uuid", "")}
                                    form_fields={safe_get(submission, "form.fields", [])}
                                    pages={[safe_get(submission, "form.fields", [])]}
                                    page={0}
                                    field_answers={safe_get(submission, "fields", [])}
                                    saveDraft={() => {
                                    }}
                                    isAnalytics={true}
                                    enableCustomLogicForAnalytics={true}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}