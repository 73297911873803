import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isVideo, isImage } from "../other/Helper.js";
import pdf_icon from "../assets/css/img/pdf.svg";
import ppt_icon from "../assets/css/img/ppt.svg";
import excel_icon from "../assets/css/img/xls.svg";
import zip_icon from "../assets/css/img/zip.svg";
import doc_icon from "../assets/css/img/doc.svg";
import safe_get from "../other/SafeGet.js";
import { RiFullscreenExitFill } from "react-icons/ri";
import { useZeroContext } from "components/ZeroContext.js";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Scrollbar, A11y, Keyboard, Zoom } from "swiper/modules";
import { AiOutlineClose, AiOutlineDownload } from "react-icons/ai";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { AiOutlineFullscreen } from "react-icons/ai";
import { AiOutlineExport } from "react-icons/ai";
import { pdfjs, Document, Page } from "react-pdf";
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { saveAs } from 'file-saver';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/keyboard";
import "swiper/css/zoom";
import "swiper/css/a11y";
import "./Previewer.css";

const ESCAPE_CODE = 27;

export default function Previewer({ attachments, index, show, close }) {
  const { isOffline, getBlobUrl } = useZeroContext();
  const [currentSlide, setCurrentSlide] = useState(index);
  const currentFile = useMemo(() => attachments[currentSlide], [attachments, currentSlide]);

  useEffect(() => {
    function onEscapeKeyPressed(e) {
      if (e.which === ESCAPE_CODE) {
        close();
      }
    }

    if (show) {
      window.addEventListener("keydown", onEscapeKeyPressed);
    }

    // Cleanup
    return () => {
      window.removeEventListener("keydown", onEscapeKeyPressed);
    };
  }, [show]);

  function getPublicUrl(file) {
    const attachmentId = safe_get(file, "attachment_uuid", "");
    const blobUrl = getBlobUrl(attachmentId);
    if (isOffline && blobUrl) {
      return blobUrl;
    }
    return file.public_url;
  }

 
  if (!show) {
    return null;
  }

  return (
    <div className="swiper">
      <Swiper
        initialSlide={currentSlide}
        modules={[Navigation, Scrollbar, A11y, Keyboard, Zoom]}
        keyboard
        navigation
        scrollbar
        zoom
        onClick={(_swiper, e)=> {
          if(e && e.target.classList.contains('swiper-slide')) {
            close();
          }
        }}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.realIndex);
        }}
      >
       {attachments.map((item, index) => {
          const fileUrl = getPublicUrl(item);
          return (
            <SwiperSlide
              key={item.file_name + "-" + index}
              className="slider-preview"
              virtualIndex={index}
            >
              {isImage(item.file_name) && (
                <div className="swiper-zoom-container">
                  <img src={fileUrl} alt={item.file_name} />
                </div>
              )}

              {isVideo(item.file_name) && (
                <VideoSlide videoUrl={item.public_url} />
              )}

              {isPdf(item.file_name) && (
                <PdfSlide
                  fileUrl={fileUrl}
                  errorComponent={
                    <DocumentSlide file={item} fileUrl={fileUrl} />
                  }
                />
              )}

              {!isImage(item.file_name) &&
                !isVideo(item.file_name) &&
                  !isPdf(item.file_name) && <DocumentSlide file={item} fileUrl={fileUrl}/>}
            </SwiperSlide>
          );
        })}
        <div className="swiper__header-menu">
          <NumOfSlides totalSlides={attachments.length} />
          <ExternalButton currentFile={currentFile} />
          <DownloadButton currentFile={currentFile} />
          <ZoomIn currentFile={currentFile} />
          <FullScreen />
          <button className="swiper__button" onClick={close}>
            <AiOutlineClose />
          </button>
        </div>
      </Swiper>
    </div>
  );
}

function ExternalButton({ currentFile }) {
  return (
    <a href={currentFile.public_url} target="_blank" className="swiper__anchor">
      <AiOutlineExport />
    </a>
  );
}

function DownloadButton({ currentFile }) {
  function handleClick() {
    fetch(currentFile.public_url, {cache: "no-store"})
      .then(res => res.blob())
      .then(blob => saveAs(blob, currentFile.file_name));
  }
  
  return (
    <button onClick={handleClick} className="swiper__button">
      <AiOutlineDownload />
    </button>
  );
}

function isPdf(filename) {
    return filename.toLowerCase().endsWith(".pdf");
}

function VideoSlide({ videoUrl }) {
  return (
    <video
      id="video-player"
      style={{
        display: "block",
        margin: "0 auto",
      }}
      width="70%"
      height="100%"
      controls
    >
      <source src={videoUrl} type="video/mp4" />
      <source src={videoUrl} type="video/ogg" />
      Your browser does not support the video tag.
    </video>
  );
}

function DocumentSlide({ file, fileUrl }) {
  function getFileTypeIcon() {
    if (file.file_name.endsWith(".pdf")) {
      return pdf_icon;
    } else if (file.file_name.endsWith(".xls")) {
      return excel_icon;
    } else if (file.file_name.endsWith(".ppt")) {
      return ppt_icon;
    } else if (file.file_name.endsWith(".zip")) {
      return zip_icon;
    } else {
      return doc_icon;
    }
  }

  return (
    <div className="swiper__file">
      <div className="swiper__file-icon-container">
        <img
          src={getFileTypeIcon()}
          style={{ width: "50px", height: "50px" }}
        />
      </div>

      <p className="swiper__file_text">
        {file.file_name} could not be displayed.
      </p>

      <div>
        <a href={fileUrl} target="_blank" className="swiper__file_link">
          Click here to open or download.
        </a>
      </div>
    </div>
  );
}

function ZoomIn({ currentFile }) {
  const swiper = useSwiperReactive();

  const [isZoomed, setIsZoomed] = useState(false);

  if (!isImage(currentFile?.file_name)) {
    return null;
  }

  if (isZoomed) {
    return (
      <button
        className="swiper__button"
        onClick={() => {
          swiper.zoom.out();
          setIsZoomed(false);
        }}
      >
        <AiOutlineZoomOut />
      </button>
    );
  }

  return (
    <>
      <button
        className="swiper__button"
        onClick={() => {
          swiper.zoom.in();
          setIsZoomed(true);
        }}
      >
        <AiOutlineZoomIn />
      </button>
    </>
  );
}

function NumOfSlides({ totalSlides }) {
  const swiper = useSwiperReactive();

  return (
    <div className="swiper__counter">
      <p className="swiper__counter-number">
        {swiper.realIndex + 1} / {totalSlides}
      </p>
    </div>
  );
}

// There is an issue where the useSwiper doesn't update on slide changes
// See https://github.com/nolimits4web/swiper/issues/5577
export const useSwiperReactive = () => {
  const swiper = useSwiper();

  // State to force a rerender.
  const [, setSignal] = useState({});
  const forceRerender = () => setSignal({});

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", forceRerender);
      return () => {
        swiper.off("slideChange", forceRerender);
      };
    }
  }, [swiper]);

  return swiper;
};

function FullScreen() {
  let [isFullScreen, setIsFullScreen] = useState(false);
  function isFullScreenSupported() {
    return (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    );
  }

  function requestFullScreen() {
    let element = document.documentElement; // Whole document
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen();
    }
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  if (!isFullScreenSupported()) {
    return null;
  }

  if (isFullScreen) {
    return (
      <button
        className="swiper__button"
        onClick={() => {
          exitFullScreen();
          setIsFullScreen(false);
        }}
      >
        <RiFullscreenExitFill />
      </button>
    );
  }

  return (
    <>
      <button
        className="swiper__button"
        onClick={() => {
          requestFullScreen();
          setIsFullScreen(true);
        }}
      >
        <AiOutlineFullscreen />
      </button>
    </>
  );
}



/**
 * PDF Slide
 */
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

const maxWidth = 800;

// PDF Previewer
const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",

};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export function PdfSlide({ fileUrl, errorComponent }) {
  const [error, setError] = useState();
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, {}, onResize);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  if (error) {
    return errorComponent;
  }

  return (
    <div ref={setContainerRef} className="swiper__pdf">
      <Document
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(err) => {
          setError(err);
        }}
        onload
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
        />
      ))}
      </Document>
    </div>
  );
}
