import {
    FontColorsOutlined, AlignLeftOutlined, LineOutlined, UnorderedListOutlined,
    CheckSquareOutlined, TagsOutlined, DownSquareOutlined, NumberOutlined,
    CalendarOutlined, AlignCenterOutlined, BlockOutlined, BorderOutlined,
    CopyOutlined, EditOutlined, OrderedListOutlined, ColumnHeightOutlined,
    PaperClipOutlined, FieldTimeOutlined, PictureOutlined, DatabaseOutlined,
    UserOutlined,
} from '@ant-design/icons'

import styles from './FormFieldMenu.module.css';

const icons = {
    FontColorsOutlined,
    AlignLeftOutlined,
    LineOutlined,
    UnorderedListOutlined,
    CheckSquareOutlined,
    TagsOutlined,
    DownSquareOutlined,
    NumberOutlined,
    CalendarOutlined,
    AlignCenterOutlined,
    BorderOutlined,
    BlockOutlined,
    EditOutlined,
    OrderedListOutlined,
    ColumnHeightOutlined,
    CopyOutlined,
    PaperClipOutlined,
    FieldTimeOutlined,
    PictureOutlined,
    DatabaseOutlined,
    UserOutlined,
};

export default function FormFieldMenu({items, onClick}) {
    return (
        <div>
            <div className={styles.itemContainer}>
                {
                    items.map((item, index) => {
                        const IconComponent = icons[item.icon];
                        return (
                            <div key={index} onClick={() => onClick(index)} className={styles.item}>
                                {IconComponent && <IconComponent className={styles.itemIcon}/>}{item.text}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}