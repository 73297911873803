import moment from "moment";
import {useCallback, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    CheckCircleOutlined, ClockCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton'

import TeamListPopover from "components/Shared/TeamListPopover";
import {dateFormatterFromString, dateFormatterNoTime, safe_get} from "other/Helper";
import {useOfflineDataCachesContext} from "../OfflineDataCaches";
import {Tag} from "antd";
import NotificationAlert from "other/NotificationAlert";


function FormAssignmentStatus({assignment}) {
    let due_date_timestamp = safe_get(assignment, 'due_date_timestamp', null)
    if (!due_date_timestamp) {
        let dt = new Date();
        due_date_timestamp = dt.setUTCHours(23, 59, 0, 0) / 1000;
    }

    let due_date_time = `${moment.unix(due_date_timestamp).format("MMM D")} at ${moment.unix(due_date_timestamp).format("hh:mm A")}`;
    let excused_timestamp = safe_get(assignment, 'excused_at', null)
    let excused_date = `${moment.unix(excused_timestamp).format("MMM D, YYYY")}`
    let isOverDue = moment.unix(due_date_timestamp).isBefore(moment());

    return (
        <>
            <div>
                {
                    assignment.completed &&
                    <span>
                        <CheckCircleOutlined className="mar-rgt-5" style={{color: "#52c41a"}}/>
                        Completed {dateFormatterNoTime(assignment.completed_at)}
                    </span>
                }
                {
                    (!assignment.completed && isOverDue) && !assignment.excused &&
                    <span>
                        <CloseCircleOutlined className="mar-rgt-5" style={{color: "#f56946"}}/>
                        Missed {dateFormatterFromString(assignment.due_date)}
                    </span>
                }
                {
                    assignment.excused &&
                    <span className="zero-blue">
                        <CloseCircleOutlined className="mar-rgt-5" style={{color: "#f3b44b"}}/>
                        Excused {excused_date}
                    </span>
                }
                {
                    !assignment.completed && !isOverDue &&
                    <span className="zero-dark-grey">
                        <ClockCircleOutlined className="mar-rgt-5"/>
                        Available now &bull; Due by {due_date_time}
                    </span>
                }
            </div>
            {
                !assignment.completed && !isOverDue && assignment.submission_uuid &&
                <div>
                    <Tag style={{color: "#505050"}}>Draft created</Tag>
                </div>
            }
        </>
    );
}

// TODO: There are 2 serializers on the back-end for form assignments. One of them
// does not return team_names/team_uuids. Need to fix that, this will work for now.
function fixTeamNamesAndIds(assignment) {
    if (assignment.team_names === undefined) {
        assignment.team_names = assignment.teams.map(team => team.name);
    }

    if (assignment.team_uuids === undefined) {
        assignment.team_uuids = assignment.teams.map(team => team.uuid);
    }
}

function FormAssignmentCard({assignment}) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const organizationUuid = useSelector(state => state.org_helper.organization.organization_uuid);
    const {
        initialData,
        forms,
        formAssignmentsCache,
        submissionDrafts: {cache: submissionDraftsCache},
    } = useOfflineDataCachesContext();

    fixTeamNamesAndIds(assignment);

    const teamNames = assignment.team_names.sort((a, b) => {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });

    const createNewSubmission = useCallback(async () => {
        setIsLoading(true);

        const teamId = assignment.team_uuids[0];
        const formId = assignment.form_uuid;
        const assignmentId = assignment.assignment_uuid;
        const team = initialData.teams.find(team => team.uuid === teamId);
        const form = forms.find(form => form.form_uuid === formId);
        const user = initialData.user;

        if (!team || !form) {
            const message = 'Could not create new offline submission: Invalid team or form'
            console.error(message);
            NotificationAlert('error', '', message);
            setIsLoading(false);
            return;
        }

        const draftId = await submissionDraftsCache.createDraft(form, team, user, assignmentId);

        const navigateToDraft = () => {
            formAssignmentsCache.linkSubmission(assignmentId, draftId, false);
            history.push(`/${organizationUuid}/home/team/${team.uuid}/forms/${formId}/submission/${draftId}`);
            setIsLoading(false);
        };

        submissionDraftsCache.onChange(navigateToDraft);
    }, [assignment, initialData.teams, forms, initialData.user, submissionDraftsCache, history]);

    return (
        <div className="zero-blue flex-col" style={{gap: '0.5rem'}}>
            {
                isLoading ? (
                    <>
                        <Skeleton width={150}/>
                        <Skeleton width={120}/>
                        <Skeleton width={200}/>
                        <Skeleton width={250}/>
                    </>
                ) : (
                    <>
                        <div>
                            <strong>
                                {
                                    assignment.submission_uuid ? (
                                        <Link
                                            className="zero-blue link-hover"
                                            to={`/${organizationUuid}/home/team/${assignment.team_uuids[0]}/forms/${assignment.form_uuid}/submission/${assignment.submission_uuid}`}
                                        >
                                            {assignment.scheduler_name}
                                        </Link>
                                    ) : (
                                        <button
                                            className="ButtonLink link-hover"
                                            onClick={createNewSubmission}
                                        >
                                            {assignment.scheduler_name}
                                        </button>
                                    )
                                }
                            </strong>
                        </div>
                        <div>{assignment.form_name}</div>
                        <div>In <TeamListPopover teamNames={teamNames} sorted/></div>
                        <FormAssignmentStatus assignment={assignment}/>
                    </>
                )
            }
            <hr style={{width: '100%', margin: '0.5rem 0'}}/>
        </div>
    );
}


export default function FormAssignmentsList() {
    const {formAssignments} = useOfflineDataCachesContext();

    return (
        <>
            {
                formAssignments.length > 0 ? (
                    formAssignments.map(assignment => (
                        <FormAssignmentCard key={assignment.assignment_uuid} assignment={assignment}/>
                    ))
                ) : (
                    <div className="text-center zero-dark-grey">No assignments saved to device.</div>
                )
            }
        </>
    );
}