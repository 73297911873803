import ButtonLoading from "./ButtonLoading";

export default function Button({type, small = false, round = false, children, className = "", loading = false, ...props}) {
    const classNames = ['btn'];

    if (type) {
        classNames.push(`btn-${type}`);
    } else {
        classNames.push('btn-discard'); // dark border, no fill
    }

    if (small) {
        classNames.push('btn-sm');
    }

    if (round) {
        classNames.push('btn-rounded');
    }

    return (
        <button className={classNames.join(' ') + " " + className} {...props}>
            {loading ? <ButtonLoading/> : children}
        </button>
    )
}