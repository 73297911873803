import React, {Component} from 'react';

import {Modal, Select} from 'antd';

const {Option} = Select;
import ButtonLoading from './ButtonLoading';
import PasswordRequirementsPopup from "./PasswordRequirementsPopup";
import NotificationAlert from "../../other/NotificationAlert";

import {safe_get, isAdmin, safeProfilePic} from '../../other/Helper.js';
import {update_user_password_admin, update_user} from '../../api/zero-api.js'

import AccountEditFields from '../Shared/AccountEditFields';

import '../../assets/css/antd-custom.css';

class EditProfileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "profile",
            updatingProfile: false
        };

        this.fields = {};

        this.updateProfile = this.updateProfile.bind(this);
        this.changeTab = this.changeTab.bind(this);

        this.passwordMeetsRequirements = this.passwordMeetsRequirements.bind(this);
        this.confirmPasswordCheck = this.confirmPasswordCheck.bind(this);
        this.updateUserPassword = this.updateUserPassword.bind(this);
    }

    async updateProfile() {
        if (this.fields && !this.state.updatingProfile) {
            this.setState({updatingProfile: true, fieldErrors: {}});

            const fieldsToUpdate = [
                'first_name',
                'last_name',
                'title',
                'phone_number',
                'mobile_phone_number_parts',
                'emergency_phone_parts',
                'alternate_email',
                'email',
                'role',
            ];

            const body = {};

            for (const name in this.fields) {
                if (fieldsToUpdate.includes(name)) {
                    if (name === 'phone_number') {
                        body[name] = this.fields[name].replace(/\s+/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '');
                    } else {
                        body[name] = this.fields[name];
                    }
                }
            }

            if (body.mobile_phone_number_parts && !body.mobile_phone_number_parts.phone) {
                body.mobile_phone_number_parts = {};
            }

            if (body.emergency_phone_parts && !body.emergency_phone_parts.phone) {
                body.emergency_phone_parts = {};
            }

            if (body.role && body.role === this.props.userToUpdate.roles?.[0]) {
                delete body.role
            }

            let newState = {};

            try {
                const response = await update_user(this.props.userToUpdate.uuid, JSON.stringify(body));
                const data = await response.json();

                const user = safe_get(data, 'user', {});

                newState = {updatingProfile: false};
                NotificationAlert("success", "", "Profile information updated.")
                this.props.editProfileCallback(true, user);
            } catch (errorResponse) {
                newState = {updatingProfile: false};
                if (errorResponse.status === 422) {
                    const errorData = await errorResponse.json();
                    const errors = safe_get(errorData, 'error_messages', []);
                    const fieldErrors = {};

                    for (const error of errors) {
                        const field = safe_get(error, 'field', '');
                        const message = safe_get(error, 'message', '');
                        if (fieldsToUpdate.includes(field)) {
                            fieldErrors[field] = message;
                        }
                    }

                    newState.fieldErrors = fieldErrors;
                }
            } finally {
                this.setState(newState);
            }
        }
    }

    changeTab(tab) {
        this.setState({activeTab: tab});
    }

    passwordMeetsRequirements(value, new_password) {
        this.setState({passwordCheck: value, new_password: new_password});
    }

    confirmPasswordCheck(event) {
        let new_password = this.state.new_password;
        let confirm_password = event.target.value;
        this.setState({confirm_password: confirm_password});

        if (confirm_password !== new_password) {
            this.setState({passwordError: "Password does not match"});
        } else {
            this.setState({passwordError: null, confirm_password: confirm_password});
        }
    }

    updateUserPassword() {
        let self = this;
        let new_password = this.state.new_password;
        let confirm_password = this.state.confirm_password

        if (new_password === confirm_password) {
            this.setState({passwordError: null})
            let body = JSON.stringify({
                new_password: new_password,
                new_password2: confirm_password
            });

            self.setState({updatingPassword: true});

            update_user_password_admin(this.props.userToUpdate.uuid, body).then(function (success) {
                success.json().then(success => {
                    NotificationAlert("success", "", "Password updated.")
                    self.setState({updatingPassword: false});
                    self.props.editProfileCallback(true, self.props.userToUpdate);
                });
            }, function (error) {
                self.setState({updatingPassword: false});
                if (error.status === 422) {
                    error.json().then(error => {
                        let error_messages = safe_get(error, "error_messages", [])
                        let error_msg = error_messages[0].message
                        self.setState({passwordError: error_msg})
                    });
                }
            });
        } else {
            this.setState({passwordError: "Password does not match."})
        }
    }

    render() {
        let pendingUser = safe_get(this.state, "userToUpdate.status", "") === "pending" || safe_get(this.props, "userToUpdate.status", "") === "pending"
        return (
            (<Modal
                title=""
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                width={580}
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        {
                            this.state.activeTab === "profile" &&
                            <button className="btn btn-modal" onClick={this.updateProfile}
                                    disabled={this.state.updatingProfile}>
                                {
                                    this.state.updatingProfile ? <ButtonLoading/> : "Save"
                                }
                            </button>
                        }
                        {
                            this.state.activeTab === "reset_password" &&
                            <button className="btn btn-modal" onClick={this.updateUserPassword}>
                                {
                                    this.state.updatingPassword ? <ButtonLoading/> : "Save New Password"
                                }
                            </button>
                        }
                    </div>
                }
            >
                <div className="tab-base " style={{maxWidth: '850px', margin: '0 auto'}}>
                    <ul className="nav nav-tabs">
                        <li className={this.state.activeTab === "profile" ? "active" : ""}>
                            <button className="ButtonLink" onClick={() => {
                                this.changeTab("profile");
                            }}>
                                Edit Profile
                            </button>
                        </li>
                        {
                            isAdmin(this.props.user) && !pendingUser &&
                            <li className={this.state.activeTab === "reset_password" ? "active" : ""}>
                                <button className="ButtonLink" onClick={() => {
                                    this.changeTab("reset_password");
                                }}>
                                    Reset Password
                                </button>
                            </li>
                        }
                    </ul>

                    <div className="tab-content" style={{border: "1px solid #e2e2e2"}}>
                        <div className="account-header">
                            <div className="text-center">
                                {safeProfilePic(this.props.userToUpdate, "img-sm img-circle account", "account")}
                            </div>
                        </div>
                        <br/>
                        {
                            this.state.activeTab === "reset_password" &&
                            <form className="form-horizontal" style={{marginTop: '5px'}}>
                                <div className="form-group ">
                                    <label className="col-lg-5 col-sm-4 col-xs-12 control-label account zero-blue">
                                        New password
                                    </label>
                                    <div className="col-lg-7 col-sm-7 signup-input">
                                        <PasswordRequirementsPopup ref="newPassword"
                                                                   placeholder=""
                                                                   passwordMeetsRequirementsCallback={this.passwordMeetsRequirements}
                                                                   customStyle={{margin: '0 auto', maxWidth: '215px'}}
                                        />
                                    </div>
                                </div>

                                <div className="form-group ">
                                    <label className="col-lg-5 col-sm-4 col-xs-12 control-label account zero-blue">
                                        Repeat Password
                                    </label>
                                    <div className="col-lg-7 col-sm-7 signup-input">
                                        <input value={this.state.confirm_password}
                                               type="password"
                                               placeholder=""
                                               name="repeatpassword"
                                               id="confirm-new-password"
                                               className="form-control signup-field custom-placeholder"
                                               onChange={this.confirmPasswordCheck}
                                               required
                                               style={{maxWidth: '215px', margin: '0 auto'}}/>
                                    </div>
                                </div>
                                {
                                    this.state.passwordError &&
                                    <small className="error text-center"
                                           style={{display: 'block'}}>{this.state.passwordError}</small>
                                }
                            </form>
                        }
                        {
                            this.state.activeTab === "profile" &&
                            <form className="form-horizontal" style={{marginTop: '5px'}}>
                                <AccountEditFields
                                    user={this.props.userToUpdate}
                                    emailEditable={true}
                                    showTimezone={false}
                                    showRole={true}
                                    showPassword={isAdmin(this.props.user)}
                                    changePasswordOnClick={() => this.changeTab("reset_password")}
                                    onFieldsChange={fields => this.fields = fields}
                                    fieldErrors={this.state.fieldErrors}
                                />
                            </form>
                        }
                    </div>
                </div>
            </Modal>)
        );

    }
}

export default EditProfileModal;
