import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import {getSharedLibrary, getLibrary, getCategories, notifyError} from '../../api/zero-api.js'

import {
    safe_get, isMobileApp, scrollToTop, mainContentContainerClass,
    isViewer, floatingButtonMargin, isTeamLead, isAdmin, isUser
} from '../../other/Helper.js';

import {update_topics} from '../../store/actions/LibraryActions';

import Post from './LibraryPost.js';

import {Select} from 'antd'
import {PlusCircleOutlined} from '@ant-design/icons';

import InfoIconComponent from '../InfoIconComponent.js';
import Skeleton from 'react-loading-skeleton';

import '../../assets/css/Library.css';
import LocationFilter from 'components/Shared/LocationFilter';

class Library extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            sharedArticles: [],
            allArticles: [],
            filteredArticles: [],
            tags: [],
            sharedTags: [],
            allTags: [],
            activeTags: [],
            tagQuery: "",
            teamQuery: "",
            searchQuery: "",
            locationQuery: "",
            loadingArticles: true,
            loadingShared: true,
            location: ''
        };

        this.updateLibrary = this.updateLibrary.bind(this);
        this.onTagSelect = this.onTagSelect.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        scrollToTop("page-head");
    }

    componentDidMount() {
        this._isMounted = true;
        scrollToTop("page-head");

        var query = "?status=private";
        this.updateLibrary(query);
        this.getSharedLibrary();
        this.getTopics();
    }

    getTopics = () => {
        var self = this;
        getCategories().then(function (success) {
            success.json().then(success => {
                self.setState({
                    tags: safe_get(success, "tags", [])
                }, () => {
                    self.mergeTags();
                });
            });
        }, function (error) {
            console.log(error);
        });
    }

    updateLibrary() {
        var self = this;

        let query = `?${this.state.tagQuery}${this.state.teamQuery}${this.state.searchQuery}${this.state.locationQuery}`

        getLibrary(query).then(function (success) {
            success.json().then(success => {
                var articles = safe_get(success, "articles", [])

                if (self._isMounted) {
                    self.setState({
                        articles: articles,
                        loadingArticles: false,
                    }, () => {
                        self.mergeArticles();
                    });
                }
            });
        }, function (error) {
            self.setState({loading: false});
            notifyError(error)
        });

    }

    getSharedLibrary = () => {
        if (this.props.organization.enable_zero_library) {
            let query = `?${this.state.tagQuery}${this.state.teamQuery}${this.state.searchQuery}`;
            getSharedLibrary(query).then(success => {
                success.json().then(success => {
                    if (this._isMounted) {
                        this.setState({
                            sharedArticles: safe_get(success, "articles", []),
                            sharedTags: safe_get(success, "tags", []),
                            loadingShared: false,
                        }, () => {
                            this.mergeArticles();
                            this.mergeTags();
                        });
                    }
                });
            })
        } else {
            this.setState({
                loadingShared: false
            })
        }
    }

    mergeArticles = () => {
        let shared = [...this.state.sharedArticles];
        let articles = [...this.state.articles];

        let allArticles = articles.concat(shared).sort(function (a, b) {
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
        });

        this.setState({
            allArticles: allArticles,
            filteredArticles: allArticles,
            loading: false
        });
    }

    mergeTags = () => {
        let sharedTags = [...this.state.sharedTags];
        let tags = [...this.state.tags];

        let allTags = [...new Set(sharedTags.concat(tags))].sort(function (a, b) {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        });

        this.props.dispatch(update_topics(allTags));
        this.setState({
            allTags: allTags
        });
    }


    onTagSelect(value) {
        let tags = [];
        let search = [];
        for (var i in value) {
            let searchVal = value[i];

            if (searchVal.includes("tag:")) {
                let tag = searchVal.split("tag:")[1];
                tags.push(tag)
            } else {
                search.push(searchVal)
            }
        }

        let tagQuery = ""
        if (tags.length > 0) {
            tagQuery = `&tag=${tags.join(",")}`;
        }

        let searchQuery = "";
        if (search.length > 0) {
            searchQuery = `&s=${search.join(",")}`;
        }

        this.setState({tagQuery: tagQuery, searchQuery: searchQuery, activeTags: value, loadingArticles: true}, () => {
            this.updateLibrary();
            this.getSharedLibrary()
        });

    }


    onTeamSelect = (team_uuid) => {
        this.setState({teamQuery: `&team_uuid=${team_uuid}`, loadingArticles: true}, () => {
            this.updateLibrary();
            this.getSharedLibrary()
        });
    }

    goToNewArticle = () => {
        this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library/new_post");
    }

    onLocationSelected = (location) => {
        this.setState({
            location,
            locationQuery: location ? `&location_uuid=${location}` : ''
        }, () => {
            this.updateLibrary();
        });
    }

    render() {
        let {allTags, loadingArticles, loadingShared} = this.state;

        let loading = loadingArticles || loadingShared

        return (
            <div className={mainContentContainerClass()}>
                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{marginBottom: "0px"}}>
                                    <div className="nav-header-panel">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles team-name-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Library
                                                <InfoIconComponent
                                                    position={"bottom"}
                                                    width={"170px"}
                                                    text={<span>Anyone can access the Library, but only Admins and Leads can create articles.</span>}
                                                />
                                            </h3>
                                            {
                                                !isMobileApp() && (isAdmin(this.props.user) || isTeamLead(this.props.user)) &&
                                                <button className="btn btn-primary pull-right" style={{
                                                    textAlign: 'center',
                                                    display: 'inline-block',
                                                    padding: "4px 6px 3px 6px"
                                                }} onClick={this.goToNewArticle}>
                                                    <span className=""><PlusCircleOutlined className="mar-rgt-5"/>New Article</span>
                                                </button>
                                            }
                                        </div>
                                        <p className=""
                                           style={{marginBottom: "0px", color: "#505050", paddingTop: "3px"}}>A place to
                                            store documents and reference materials.</p>
                                        <div className="row" style={{padding: "0 0.5rem"}}>
                                            <Select
                                                mode={"tags"}
                                                placeholder={"Filter or search..."}
                                                onChange={this.onTagSelect}
                                                value={this.state.activeTags}
                                                showArrow={false}
                                                virtual={false}
                                                className="col-xs-12 col-sm-6 col-reduce-padding"
                                                style={{marginTop: '0.8rem'}}
                                            >
                                                <Select.OptGroup label="Topics">
                                                    {
                                                        allTags.map((topic, index) => (
                                                            <Select.Option key={index}
                                                                           value={"tag:" + topic}>{topic}</Select.Option>
                                                        ))
                                                    }
                                                </Select.OptGroup>
                                            </Select>
                                            <LocationFilter
                                                className="col-xs-12 col-sm-3 col-reduce-padding"
                                                style={{marginTop: '0.8rem'}}
                                                value={this.state.location}
                                                onLocationSelected={this.onLocationSelected}
                                            />
                                            <Select
                                                value={this.state.selected_team}
                                                onChange={this.onTeamSelect}
                                                className="col-xs-12 col-sm-3 col-reduce-padding"
                                                style={{marginTop: '0.8rem'}}
                                                defaultValue={"my_teams"}
                                                virtual={false}
                                            >
                                                <Select.Option value={"my_teams"}>All My Teams</Select.Option>
                                                {
                                                    this.props.teams.map((team, index) => (
                                                        <Select.Option key={index}
                                                                       value={team.uuid}>{team.name}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>
                    <div className="panel thin-border ">
                        <div className="pad-all file-manager">
                            <div>
                                {
                                    !loading && this.state.filteredArticles.length === 0 &&
                                    <p style={{marginTop: "20px", textAlign: "center", fontSize: "18px"}}>There are no
                                        documents in the library.</p>
                                }
                                {
                                    !loading && this.state.filteredArticles.length !== 0 &&
                                    <ul id="" className="file-list" style={{paddingTop: '0px', marginBottom: '8px'}}>
                                        {
                                            this.state.filteredArticles.map((article, index) => (
                                                <Post
                                                    key={article.uuid}
                                                    article={article}
                                                    onTagSelect={this.onTagSelect}
                                                />
                                            ))
                                        }
                                    </ul>
                                }
                                {
                                    loading &&
                                    <ul className="file-list" style={{paddingTop: '0px', marginBottom: '8px'}}>
                                        {
                                            [...Array(5)].map((e, index) =>
                                                <li key={index} className="">
                                                    <div className="file-details" style={{marginRight: '10px'}}>
                                                        <div className="media-block">
                                                            <div className="media-left">
                                                                <Skeleton width={28} height={35}/>
                                                            </div>
                                                            <div className="media-body">
                                                                <span className="file-name zero-blue"
                                                                      style={{paddingBottom: '5px'}}>
                                                                    <Skeleton width={250}/>
                                                                </span>
                                                                <Skeleton circle={true} width={20} height={20}/>
                                                                <p style={{
                                                                    paddingLeft: "5px",
                                                                    margin: "0px",
                                                                    display: "inline-block",
                                                                    verticalAlign: "bottom"
                                                                }}><Skeleton width={200}/></p>

                                                                <small className="pull-right hidden-xs">
                                                                    <Skeleton width={80}/>
                                                                </small>
                                                                <small className="visible-xs mar-top">
                                                                    <Skeleton width={80}/>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                }

                            </div>

                        </div>
                    </div>
                </div>


                {
                    isMobileApp() && (!isViewer(this.props.user) && !isUser(this.props.user)) &&
                    <Link
                        to={"/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library/new_post"}>
                        <div id="floating-button" style={{bottom: floatingButtonMargin()}}>
                            <p className="plus-sign">+</p>
                        </div>
                    </Link>
                }
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        tab: safe_get(store, "tab.tab", ""),
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        library_topics: safe_get(store, "library.topics", []),
    }
}

export default (connect(mapStateToProps)(Library));
