import React, {Component} from 'react';

import {update_schedule,} from '../../../api/zero-api.js'

import {Select, Modal} from 'antd';
import ButtonLoading from '../../Shared/ButtonLoading';
import NotificationAlert from '../../../other/NotificationAlert';

import moment from 'moment'

import '../../../assets/css/antd-custom.css';

class ChangeScheduleStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatingStatus: false,
            selectedStatus: undefined,
            selected_users_uuids: []
        };

        this.setUpResponders = this.setUpResponders.bind(this);
    }

    componentDidMount() {
        this.setUpResponders();
    }

    setUpResponders() {
        let existingResponders = this.props.schedule.subscribers;
        var selected_users_uuids = [];

        for (var i in existingResponders) {
            let responder = existingResponders[i];
            selected_users_uuids.push(responder.user_uuid)
        }

        this.setState({
            selected_users_uuids: selected_users_uuids
        });

    }

    onStatusSelect(value) {
        this.setState({selectedStatus: value});
    }

    updateStatus = () => {
        var self = this;

        let schedule = this.props.schedule
        console.log(schedule.end_date)

        if (moment(schedule.end_date).isBefore(moment())) {
            this.setState({errorMsg: "The End Date is in the past. You must change the End Date first."})
        } else {
            this.setState({updatingStatus: true}, () => {
                let team_uuids = schedule.teams.map(e => e.team_uuid)

                let body = JSON.stringify({
                    name: schedule.name,
                    enabled: this.state.selectedStatus,
                    team_uuids: team_uuids,
                    form_uuid: schedule.form.form_uuid,
                    user_uuids: this.state.selected_users_uuids,
                    repeat_period: schedule.repeat_period,
                    start_date: schedule.start_date,
                    end_date: schedule.end_date
                });

                update_schedule(schedule.scheduler_uuid, body).then(function (success) {
                    NotificationAlert("success", "", "Schedule status updated.");
                    self.props.updateSchedules();
                    self.props.cancel();
                }, function (error) {
                    NotificationAlert("success", "", "Unable to update schedule.");
                    self.props.cancel();
                });
            });
        }

    }


    render() {
        return (
            (<Modal
                title="Change status..."
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.updateStatus}>
                            {
                                this.state.updatingStatus ? <ButtonLoading/> : "Update Status"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">Change status for this schedule:</p>
                <Select
                    style={{width: "100%", marginBottom: "5px"}}
                    className="move-team-select"
                    showArrow={true}
                    placeholder="Select a status"
                    dropdownStyle={{zIndex: "10000"}}
                    onChange={this.onStatusSelect.bind(this)}
                    value={this.state.selectedStatus}
                >
                    <Select.Option key={"admin"} value={true} disabled={this.props.schedule.enabled}>
                        <span className="badge badge-success badge-icon  " style={{margin: "0px 5px 2px 0px"}}/>Active
                    </Select.Option>
                    <Select.Option key={"team_lead"} value={false} disabled={!this.props.schedule.enabled}>
                        <span className="badge badge-warning badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Paused
                    </Select.Option>
                </Select>
                {
                    this.state.errorMsg &&
                    <div>
                        <small className="error">{this.state.errorMsg}</small>
                    </div>
                }
            </Modal>)
        );
    }
}

export default ChangeScheduleStatusModal;