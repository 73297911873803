import { useSelector } from "react-redux";

export function useCurrentUser() {
    return useSelector(state => state.user.user);
}

export function useCurrentOrganization() {
    return useSelector(state => state.org_helper.organization);
}

export function useCurrentTeam() {
    return useSelector(state => state.teams_helper.team);
}

export function useOrgAndUserCacheKey() {
    const organization = useCurrentOrganization();
    const user = useCurrentUser();
    const cacheKey = `${organization.organization_uuid.substring(0, 8)}:${user.uuid.substring(0, 8)}`;

    return cacheKey;
}