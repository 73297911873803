import { useZeroContext } from "components/ZeroContext";
import { useCallback, useEffect, useRef } from "react";

export function useFormSubmissionEvents() {
    const zeroContext = useZeroContext();
    const eventTarget = zeroContext.caches.submissionDrafts.cache.target;
    const unsubscribeCallbacks = useRef([]);

    const subscribe = useCallback((eventType, callback) => {
        eventTarget.addEventListener(eventType, callback);
        const unsubscribe = () => {
            eventTarget.removeEventListener(eventType, callback);
        }
        unsubscribeCallbacks.current = [
            ...unsubscribeCallbacks.current,
            unsubscribe,
        ]
        return () => {
            unsubscribe();
            unsubscribeCallbacks.current = unsubscribeCallbacks.current.filter(cb => cb !== unsubscribe);
        }
    }, [eventTarget]);

    useEffect(() => {
        return () => {
            // unsubscribe all listeners when component is unmounted
            for (const unsub of unsubscribeCallbacks.current) {
                unsub();
            }
            unsubscribeCallbacks.current = [];
        }
    }, []);

    return subscribe;
}