export default function reducer(state = {
    teams: [],
    team: {}
}, action) {

    switch (action.type) {

        case "update_current_team": {
            return Object.assign({}, state, {
                team: action.payload
            });
        }

        case "update_teams": {
            const sortedTeams = action.payload.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            return Object.assign({}, state, {
                teams: sortedTeams
            });
        }

        case "RESET_TEAMS": {
            return Object.assign({}, state, {
                teams: []
            });
        }

        default: {
            return Object.assign({}, state, {
                teams: state.teams
            });
        }

    }
}