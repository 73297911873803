// @ts-check
import { useCallback, useMemo, useState } from "react";

/**
 * @typedef {'idle' | 'loading' | 'success' | 'error'} AsyncDataLoaderStatus
 */

/**
 * @template T
 * @typedef {{
*  status: AsyncDataLoaderStatus,
*  data: T,
*  error: any,
*  load: (loader: () => Promise<any>) => Promise<void>
* }} AsyncDataLoaderResult
*/

/**
 * @template T
 * @param {{
 *  initialData?: T,
 *  useBackgroundRefresh?: boolean,
 * }} props
 * @returns {AsyncDataLoaderResult<T>}
 */
export function useAsyncDataLoader({initialData, useBackgroundRefresh}={}) {
    /** @type {useState<AsyncDataLoaderStatus>} */
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState(initialData ?? null);
    const [error, setError] = useState(null);
    const [initialLoadSuccess, setInitialLoadSuccess] = useState(false);

    const load = useCallback(async (loader) => {
        if (!useBackgroundRefresh || !initialLoadSuccess) {
            setStatus('loading');
        }
        try {
            const newData = await loader();
            setData(newData);
            setStatus('success');
            setInitialLoadSuccess(true);
        } catch (err) {
            setStatus('error');
            setError(err);
        }
    }, [initialLoadSuccess, useBackgroundRefresh]);

    return useMemo(() => ({status, data, error, load}), [status, data, error, load]);
}