import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux'
import {AlertOutlined} from '@ant-design/icons';

import safe_get from '../../other/SafeGet.js';
import {safeProfilePic} from '../../other/Helper.js';
import {mark_notification_as_read} from '../../api/zero-api.js';

class NotificationItem extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            notification_post: {},
            notification_team: {},
            loading_team_success: false,
        };

        this.goToPost = this.goToPost.bind(this);
    }

    getTeamName() {
        var teams = this.props.teams
        for (var i in teams) {
            var team = teams[i]
            if (team.uuid === this.props.notification.team_uuid) {
                return team.name
            }
        }
        return ""
    }

    goToPost() {
        var self = this;
        var notification_uuid = this.props.notification.notification_uuid

        if (this.props.notification.read_at === undefined) {
            mark_notification_as_read(notification_uuid).then(function (success) {
                self.props.closeAside();
                self.props.history.push('/' + self.props.organization.organization_uuid + '/home/team/' + self.props.notification.team_uuid + '/feed/post/' + self.props.notification.object_uuid);

            }, function (error) {
                error.json().then(error => {
                    var errors = safe_get(error, "error_messages", []);
                    if (errors.length > 0) {
                        var error_obj = errors[0];
                        if (error_obj.field === "notification_uuid" && error_obj.message.toLowerCase() === "not found") {
                            self.props.closeAside();
                            self.props.history.push('/' + self.props.organization.organization_uuid + '/home/team/' + self.props.notification.team_uuid + '/feed/post/' + self.props.notification.object_uuid);
                        }
                    }

                })
            });
        } else {
            this.props.closeAside();
            this.props.history.push('/' + this.props.organization.organization_uuid + '/home/team/' + this.props.notification.team_uuid + '/feed/post/' + this.props.notification.object_uuid);
        }
    }

    render() {
        return (
            <li onClick={this.goToPost} className="list-group-item notification"
                style={{cursor: 'pointer', padding: "10px 12px"}}>
                <div className="media-left pos-rel" style={{verticalAlign: 'top', padding: '0px'}}>
                    {
                        (this.props.notification.is_urgent && this.props.notification.notification_type === 'post_created') ?
                            <AlertOutlined style={{color: '#1D2A35', fontSize: 20, width: 32, paddingRight: 6}}/>
                            :
                            safeProfilePic(this.props.notification.user, "img-circle notification", "notification", {
                                marginRight: '8px',
                                marginTop: "4px"
                            })
                    }
                </div>
                <div className="media-body">
                    <p className="notification-title">{this.props.notification.title}</p>
                    <p className="notification-date">{this.props.notification.created_time_ago}</p>
                    <p className="notification-date"
                       style={{display: "block"}}>"{this.props.notification.body}"</p>
                </div>
            </li>
        );

    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
        unread_notifications: safe_get(store, "notifications.notifications", []),
        read_notifications: safe_get(store, "notifications.read_notifications", []),
        unread_notifications_counter: safe_get(store, "notifications.unread_notifications_counter", 0),
    }
}

export default withRouter(connect(mapStateToProps)(NotificationItem));

// export default (NotificationItem);

