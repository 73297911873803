import React from 'react';
import {DatePicker} from 'antd';

import {actions} from './reducer';
import { endOfDayUtc } from 'other/Helper';

export default function PostFormDueDate({state, dispatch, dueDateEnabled}) {
    if (!dueDateEnabled) return null;

    const onChange = (value) => {
        const modifiedValue = endOfDayUtc(value);
        dispatch([actions.DUE_DATE_CHANGED, modifiedValue]);
    }

    return (
        <>
            <div className="panel-heading bulletin-post">
                <h3 className="bulletin-post-headers">Due Date</h3>
            </div>
            <div className="panel-body bulletin-post">
                <DatePicker
                    placeholder="Set date"
                    className="due-date"
                    format={"MM/DD/YYYY"}
                    value={state.dueDateMoment}
                    bordered={true}
                    showTime={false}
                    onChange={onChange}
                    showToday={false}
                    style={{width: '20rem'}}
                    popupStyle={{zIndex: 1150}}
                    status={state.dueDateError ? 'error' : undefined}
                />
            </div>
            {
                state.dueDateError &&
                <small className="error" style={{display: "block"}}>{state.dueDateError}</small>
            }
        </>
    )
}