import { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Popconfirm, Select, Tabs } from "antd";
import Button from "components/Shared/Button";
import QRCode from "components/Shared/QRCode";
import useOrganizationId from "hooks/useOrganizationId";
import NotificationAlert from "other/NotificationAlert";
import copy from "copy-to-clipboard";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import { create_public_link_for_form, disable_short_link, get_public_links_for_form } from "api/zero-api";
import LoadingIndicator from "components/Shared/LoadingIndicator";
import { generateUrlForLocation, getErrorMessageFromResponse } from "other/Helper";
import { useSelector } from "react-redux";
import ZeroTag from "components/Shared/ZeroTag";


/**
 * @param {{
 *  link: string;
 *  onDisable: () => void;
 * }} props 
 */
function DisablePublicLinkAction({link, onDisable}) {
    const isDisabling = useRef(false);

    const disableLink = async () => {
        try {
            if (isDisabling.current) {
                return;
            }
            isDisabling.current = true;
            const linkId = link.split('/').pop();
            if (linkId) {
                await disable_short_link(linkId);
                onDisable();
            }
        } catch (err) {
            NotificationAlert("error", "", "Could not disable this link.");
        } finally {
            isDisabling.current = false;
        }
    }

    return (
        <>
            <div>
                <Popconfirm
                    title="Are you sure you want to disable this link?"
                    okText="Yes"
                    onConfirm={disableLink}
                    cancelText="No"
                >
                    <span className="text-danger hover-cursor">Disable public link for this team</span>
                </Popconfirm>
            </div>
        </>
    )
}

export default function ShareFormLinkModal({form, cancel}) {
    const orgId = useOrganizationId();
    const sortedTeams = useMemo(() => {
        return form.teams.sort((a, b) => a.name.localeCompare(b.name));
    }, [form.teams]);
    const [selectedTeamId, setSelectedTeamId] = useState(sortedTeams.length > 0 ? sortedTeams[0].team_uuid : undefined);
    const [link, setLink] = useState("");
    const [activeTab, setActiveTab] = useState("private");
    const isPublicTab = activeTab === 'public';
    const publicLinksLoader = useAsyncDataLoader({initialData: {}});
    const [isCreatingLink, setIsCreatingLink] = useState(false);
    const publicFormsEnabled = useSelector(state => state.org_helper.organization.feature_flags?.public_forms ?? false);
    const [error, setError] = useState("");
    const teamSelectOptions = useMemo(() => {
        const publicTeams = Object.keys(publicLinksLoader.data);
        return sortedTeams.map(team => {
            return {
                label: <span>
                    {team.name}
                    { (isPublicTab && publicTeams.includes(team.team_uuid)) &&
                        <ZeroTag color="green" className="mar-lft-10 text-normal">Public</ZeroTag>
                    }
                </span>,
                value: team.team_uuid,
            };
        })
    }, [sortedTeams, publicLinksLoader.data, isPublicTab]);

    const loadPublicLinks = () => {
        publicLinksLoader.load(async () => {
            const res = await get_public_links_for_form(form.form_uuid);
            const data = await res.json();
            return data;
        });
    }

    useEffect(() => {
        loadPublicLinks();
    }, [form.form_uuid]);

    useEffect(() => { setError("") }, [selectedTeamId]); // Clear error when team changes

    useEffect(() => {
        if (selectedTeamId) {
            if (isPublicTab) {
                if (!publicFormsEnabled) {
                    setLink("");
                } else {
                    const link = publicLinksLoader.data[selectedTeamId];
                    if (link) {
                        setLink(generateUrlForLocation(link));
                    } else {
                        setLink("");
                    }
                }
            } else {
                setLink(`${window.location.origin}/${orgId}/home/team/${selectedTeamId}/${form.form_type === "lms" ? "courses" : "forms"}/${form.form_uuid}/submit`);
            }
        } else {
            setLink("");
        }
    }, [selectedTeamId, form, orgId, publicFormsEnabled, isPublicTab, publicLinksLoader.data]);

    const generatePublicLink = () => {
        if (!isCreatingLink) {
            setIsCreatingLink(true);
            setError("");
            create_public_link_for_form(form.form_uuid, selectedTeamId)
                .then(_res => {
                    loadPublicLinks();
                })
                .catch(err => {
                    NotificationAlert("error", "", "Could not generate public link.");
                    getErrorMessageFromResponse(err, "")
                        .then(message => {
                            setError(message);
                        });
                })
                .finally(() => {
                    setIsCreatingLink(false);
                });
        }
    }

    const copyLink = () => {
        copy(link);
        NotificationAlert("success", "", "Link copied.");
    }

    const cannotDisplayPublicTab = !publicFormsEnabled && isPublicTab;
    const isScheduleOnly = form.schedule_only;
    const hideCopyLinkButton = cannotDisplayPublicTab || isScheduleOnly;

    const tabContent = (() => {
        if (cannotDisplayPublicTab) {
            return <p className="mar-no">
                This is a premium feature that allows you to share a public link to your form and collect responses
                anonymously from anyone that has the link. Please contact your account representative or
                {" "}<a className="blue-link" href={"mailto:support@teamzero.com"}>support@teamzero.com</a> to inquire about
                this feature.
            </p>
        }

        if (isScheduleOnly) {
            return <p className="mar-no">
                This is a <b>schedule only</b> template. Since submissions can only be made via schedule,
                there are no links available for this template. To create a public link, you will need to update this template
                 to a <b>regular</b> template in the template settings.
            </p>
        }
        
        return (
            <div className="flex-col" style={{gap: '1rem'}}>
                <p className="mar-no">
                    Select a team below. Then copy the link and share it. Each team requires a separate link.
                    { !isPublicTab ? 
                        <> Any logged-in user with access to the team will be able to use this link to submit.</> :
                        <> <b>ANY user (both logged-in AND anonymous/public users)</b> will be able to use this link to submit.</>
                    }
                </p>
                { isPublicTab && publicLinksLoader.status !== 'success' &&
                    <LoadingIndicator />
                }
                { (!isPublicTab || publicLinksLoader.status === 'success') &&
                    <>
                        <h3 className="titles mar-no">Team</h3>
                        <Select
                            style={{width: "100%", display: "block", zIndex: "10"}}
                            dropdownStyle={{zIndex: "10000"}}
                            placeholder="Select a team"
                            options={teamSelectOptions}
                            value={selectedTeamId}
                            onChange={setSelectedTeamId}
                        />
                        { link &&
                            <>
                                <input className="form-control custom-placeholder" value={link} readOnly />
                                { isPublicTab &&
                                    <DisablePublicLinkAction link={link} onDisable={loadPublicLinks} />
                                }
                                <QRCode link={link} downloadTitle="QRCodeFormSubmission" />
                            </>
                        } 
                        { isPublicTab && !link &&
                            <span className="blue-link" onClick={generatePublicLink}>Generate public link for this team</span>
                        }
                    </>
                }
            </div>
        );
    })();
    
    return (
        <Modal
            open={true}
            title="Share Link/QR"
            maskClosable={false}
            maskTransitionName="maskTransitionName"
            onCancel={cancel}
            footer={
                <>
                    <Button onClick={cancel}>Back</Button>
                    { !hideCopyLinkButton &&
                        <Button type="primary" onClick={copyLink} disabled={form.teams.length === 0}>Copy Link</Button>
                    }
                </>
            }
        >
            { form.teams.length === 0 &&
                <p className="mar-no">You must enable at least one team for this form before it can be shared.</p>
            }
            { form.teams.length > 0 &&
                <Tabs
                    defaultActiveKey="private"
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    items={[
                        {
                            key: 'private',
                            label: 'Private Link',
                            children: tabContent,
                        },
                        {
                            key: 'public',
                            label: 'Public Link',
                            children: tabContent,
                        }
                    ]}
                />
            }
            { (isPublicTab && error) &&
                <small className="error">{error}</small>
            }
        </Modal>
    )
}