import React, {Component} from 'react';
import {connect} from 'react-redux'

import safe_get from '../../../other/SafeGet.js'

import moment from 'moment';

import {InputNumber, Radio, Select, DatePicker} from 'antd';

class CaseClassification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body_parts: [],
            injury_illness: this.props.incident_illness !== undefined ? 1 : this.props.incident_injury_uuid !== undefined ? 0 : undefined,
            injuries: [],
            incident_causes: []
        }

        this.handleInput1Change = this.handleInput1Change.bind(this);
        this.handleInput2Change = this.handleInput2Change.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.injuryTypeSelect = this.injuryTypeSelect.bind(this);
        this.illnessTypeSelect = this.illnessTypeSelect.bind(this);
        this.eventTypeSelect = this.eventTypeSelect.bind(this);
        this.incidentCauseSelect = this.incidentCauseSelect.bind(this);
    }

    resetCaseClassification() {
        this.setState({case_classification: undefined});
        this.props.onChange("case_classification", null);
    }

    resetIncidentCause() {
        this.setState({incident_injury_uuid: undefined});
        this.props.onChange("incident_injury_uuid", null);
        this.props.onChange("incident_cause_uuid", null);
    }

    resetIncidentType() {
        this.setState({injury_illness: undefined});
        this.props.onChange("incident_injury", null)
        this.props.onChange("incident_illness", undefined);
        this.props.onChange("incident_injury_uuid", undefined);
    }

    handleRadioChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        if (name === "injury_illness") {
            this.setState({
                injury_illness: value
            });

            if (value === 0) {
                this.props.onChange("incident_injury_uuid", this.props.injuries[0].incident_injury_uuid);
                this.props.onChange("incident_illness", undefined);
            } else {
                this.props.onChange("incident_illness", this.props.illnesses[0].value);
                this.props.onChange("incident_injury_uuid", undefined);
            }
            this.props.onChange("incident_injury", null)

        } else {
            this.props.onChange(name, value);
        }
    }

    handleDoDChange = (value) => {
        var date = null
        if (value) {
            date = moment(value, 'YYYY/MM/DD').format("YYYY-MM-DD");
        }
        this.props.onChange("date_of_death", date);
    }

    handleInput1Change(value) {
        this.props.onChange("away_from_job_days", value);
    }

    handleInput2Change(value) {
        this.props.onChange("on_restriction_days", value);
    }

    injuryTypeSelect(value) {

        var injury = this.props.injuries.find(function (obj) {
            return obj.incident_injury_uuid === value
        })

        this.props.onChange("incident_injury", injury)
        this.props.onChange("incident_injury_uuid", value)
        this.props.onChange("incident_illness", undefined)
    }

    illnessTypeSelect(value) {
        this.props.onChange("incident_illness", value)
        this.props.onChange("incident_injury", null)
        this.props.onChange("incident_injury_uuid", undefined)

    }

    incidentCauseSelect(value) {
        this.props.onChange("incident_cause_uuid", value);
    }

    eventTypeSelect(value) {
        this.props.onChange("event_type_uuid", value);
    }

    render() {
        const {fieldsDisabled} = this.props;

        return (
            <>
                <h3 className="zero-blue print-only"
                    style={{marginBottom: "20px", marginTop: "5px", fontSize: "120%", textDecoration: "underline"}}>
                    Case Classification
                </h3>
                <div className="mar-btm-15">
                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%", width: "50%"}}>
                        Incident Type
                    </h3>
                    <div style={{marginBottom: "5px"}}>
                        <Radio.Group disabled={fieldsDisabled} name="injury_illness" onChange={this.handleRadioChange}
                                     value={this.state.injury_illness}>
                            <Radio className="zero-dark-grey" value={0}
                            >Injury</Radio>
                            <Radio className="zero-dark-grey" value={1}
                            >Illness</Radio>
                        </Radio.Group>
                    </div>
                    {
                        this.state.injury_illness === 0 &&
                        <div style={{height: "38px"}}>
                            <Select
                                className="custom-bulletin"
                                style={{maxWidth: "300px", width: "100%", marginTop: "3px"}}
                                name="incident_injury"
                                placeholder="Select injury type"
                                value={this.props.incident_injury_uuid}
                                defaultActiveFirstOption={true}
                                onChange={this.injuryTypeSelect}
                                virtual={false}
                                disabled={fieldsDisabled}
                            >
                                {
                                    this.props.injuries.filter(injury => injury.enabled || injury.incident_injury_uuid === this.props.incident_injury_uuid).map((injury, index) => (
                                        <Select.Option key={index} value={injury.incident_injury_uuid}
                                                       disabled={!injury.enabled}>{injury.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        this.state.injury_illness === 1 &&
                        <div style={{height: "38px"}}>
                            <Select
                                virtual={false}
                                className="custom-bulletin"
                                style={{maxWidth: "300px", width: "100%", marginTop: "3px"}}
                                name="incident_illness"
                                placeholder="Select illness type"
                                value={this.props.incident_illness}
                                onChange={this.illnessTypeSelect}
                                disabled={fieldsDisabled}
                            >
                                {
                                    this.props.illnesses.map((illness, index) => (
                                        <Select.Option key={index} value={illness.value}>{illness.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    }
                    {
                        !fieldsDisabled && (this.state.injury_illness === 1 || this.state.injury_illness === 0) &&
                        <small className="blue-link  dont-print"
                               onClick={this.resetIncidentType.bind(this)}>Clear</small>
                    }

                </div>

                {
                    this.props.incident_causes.length > 0 &&
                    <div className="mar-btm-15">
                        <h3 className="titles onboarding invite-link mt-0"
                            style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%", width: "50%"}}>
                            Incident Cause
                        </h3>
                        <div style={{height: "38px"}}>
                            <Select
                                virtual={false}
                                className="custom-bulletin"
                                style={{maxWidth: "300px", width: "100%", float: "left", marginTop: "3px"}}
                                name="incident_cause_uuid"
                                value={this.props.incident_cause_uuid}
                                onChange={this.incidentCauseSelect}
                                disabled={fieldsDisabled}
                            >

                                {
                                    this.props.incident_causes.filter(cause => cause.enabled || cause.incident_cause_uuid === this.props.incident_cause_uuid).map((cause, index) => (
                                        <Select.Option key={index} value={cause.incident_cause_uuid}
                                                       disabled={!cause.enabled}>{cause.name}</Select.Option>
                                    ))
                                }
                            </Select>

                        </div>
                        {
                            !fieldsDisabled &&
                            <small className="blue-link dont-print"
                                   onClick={this.resetIncidentCause.bind(this)}>Clear</small>
                        }
                    </div>
                }
                <div className="mar-btm-15">
                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "15px", marginTop: "0px", fontSize: "100%", width: "50%"}}>
                        Classify the Case
                    </h3>
                    <div>
                        <Radio.Group disabled={fieldsDisabled} name="case_classification"
                                     onChange={this.handleRadioChange} value={this.props.case_classification}>
                            {
                                this.props.case_classifications.map((type, index) => (
                                    <Radio key={index} className="zero-dark-grey" value={type.value}>{type.name}</Radio>
                                ))
                            }
                        </Radio.Group>
                    </div>
                    {
                        !fieldsDisabled &&
                        <small className="blue-link dont-print"
                               onClick={this.resetCaseClassification.bind(this)}>Clear</small>
                    }
                </div>
                {
                    this.props.case_classification === 0 &&
                    <div className="mar-btm-15">
                        <h3 className="titles onboarding invite-link mt-0"
                            style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%"}}>
                            If the employee died, when did the death occur?
                        </h3>
                        <div>
                            <DatePicker
                                className="custom-range-picker"
                                placeholder="MM/DD/YYYY"
                                format={"MM/DD/YYYY"}
                                onChange={this.handleDoDChange}
                                value={this.props.date_of_death ? moment(this.props.date_of_death) : undefined}
                                style={{width: "200px"}}
                                disabled={fieldsDisabled}
                            />
                        </div>
                        {
                            this.state.date_of_death_error &&
                            <small className="error">{this.state.date_of_death_error}</small>
                        }
                    </div>
                }
                <div className="mar-btm-15">
                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "15px", marginTop: "0px", fontSize: "100%",}}>
                        Number of days the injured or ill worker was:
                    </h3>
                    <div style={{marginBottom: "10px"}}>
                        <p className="zero-dark-grey"
                           style={{marginBottom: "0px", display: "inline-block", marginRight: "5px"}}>Away from
                            work:</p>
                        <InputNumber
                            name="away_from_job_days"
                            min={0}
                            onChange={this.handleInput1Change}
                            value={this.props.away_from_job_days}
                            style={{width: "60px"}}
                            size="small"
                            step={1}
                            disabled={fieldsDisabled}
                        />
                        <p className="zero-dark-grey"
                           style={{marginBottom: "0px", display: "inline-block", marginLeft: "5px"}}>days</p>
                    </div>
                    <div>
                        <p className="zero-dark-grey"
                           style={{marginBottom: "0px", display: "inline-block", marginRight: "5px"}}>On job transfer or
                            restriction:</p>
                        <InputNumber
                            name="on_restriction_days"
                            min={0}
                            onChange={this.handleInput2Change}
                            value={this.props.on_restriction_days ? this.props.on_restriction_days : 0}
                            style={{width: "60px"}}
                            size="small"
                            step={1}
                            disabled={fieldsDisabled}
                        />
                        <p className="zero-dark-grey"
                           style={{marginBottom: "0px", display: "inline-block", marginLeft: "5px"}}>days</p>
                    </div>
                </div>

            </>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        all_assignments: safe_get(store, "assignments", {}),
    }
}

export default connect(mapStateToProps)(CaseClassification);

