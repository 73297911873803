import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import '../../assets/css/Library.css';

import linkifyHtml from 'linkify-html'
import LazyLoad from 'react-lazy-load';

import {getArticle, deleteArticle, getSharedArticle} from '../../api/zero-api.js'

import NotificationAlert from '../../other/NotificationAlert.js';

import {
    safe_get, isAdmin, isTeamLead, isVideo, isImage,
    safeProfilePic, scrollToTop, mainContentContainerClass,
    getFileThumbnail, postImageContain, dateFormatterNoTime
} from '../../other/Helper.js'

import LoadingIndicator from '../Shared/LoadingIndicator';
import PostViewsModal from '../Shared/PostViewsModal';
import ConfirmModal from '../Shared/ConfirmModal';
import Previewer from '../Previewer';

import {HistoryOutlined, EyeOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {Menu, Dropdown} from 'antd';

import {IoIosMore as MoreIcon} from 'react-icons/io';
import ZEROLogo from "../../assets/css/img/ZERO-(blue)-small.png";
import Attachment from "../Shared/Attachment";
import QRCode from 'components/Shared/QRCode';

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preview_index: 0,
            preview_visible: false,
            value: "",
            showModal: false,
            modalText: "",
            article: {},
            categories: [],
            attachments: [],
            author: undefined,
            formattedDate: "",
            loading: true,
            user: {},
            team: {},
            showQR: false
        };

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.openViewersModal = this.openViewersModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.articleBodyContent = this.articleBodyContent.bind(this)
        this.articleInnerHTML = this.articleInnerHTML.bind(this);

    }

    componentDidMount() {
        var self = this;
        scrollToTop("page-head");

        if (this.props.location.pathname.includes("private")) {
            getArticle(this.props.match.params.article_uuid).then(function (success) {
                success.json().then(success => {
                    var article = safe_get(success, "article", {});
                    var attachments = safe_get(article, "attachments", []).filter(function (object) {
                        return !object.embedded
                    });

                    self.setState({
                        article: article,
                        attachments: attachments,
                        categories: safe_get(article, "tags", []),
                        author: safe_get(article, "created_by", {}),
                        views: safe_get(article, "viewers_count", 0),
                        loading: false,
                    });

                });
            }, function (error) {
                self.setState({article: {}, loading: false});
            });
        } else {
            getSharedArticle(this.props.match.params.article_uuid).then(function (success) {
                success.json().then(success => {
                    var article = safe_get(success, "article", {});
                    var attachments = safe_get(article, "attachments", []).filter(function (object) {
                        return !object.embedded
                    });

                    self.setState({
                        article: article,
                        attachments: attachments,
                        categories: safe_get(article, "tags", []),
                        views: safe_get(article, "viewers_count", 0),
                        loading: false,
                    });

                });
            }, function (error) {
                self.setState({article: {}, loading: false});
            });
        }


    }

    openViewersModal() {
        this.setState({
            showViews: true
        });
    }

    cancelModal() {
        this.setState({showModal: false, showViews: false, showDeleteModal: false});
    }

    deleteArticle = () => {
        var self = this;
        deleteArticle(this.props.match.params.article_uuid).then(function (success) {
            NotificationAlert('success', '', "Article deleted.");
            self.props.history.push("/" + self.props.organization.organization_uuid + "/home/team/" + self.props.current_team.uuid + "/private_library")
        }, function (error) {
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to delete article.")
        });
    }

    articleInnerHTML() {
        var options = {
            target: {
                url: "_blank"
            }
        }
        return {__html: linkifyHtml(this.state.article.body, options)};
    }

    articleBodyContent() {
        return (
            <div dangerouslySetInnerHTML={this.articleInnerHTML()}></div>
        );
    }

    showAttachment(file, index) {
        var element = null;
        var thumbnail = getFileThumbnail(file);
        var file_name = safe_get(file, "file_name", "");

        if (isVideo(file_name)) {
            element = <div>
                <button className="ButtonLink ant-upload-list-item-thumbnail" onClick={() => {
                    this.handlePreview(index)
                }}>
                    <div className="overlay">
                        <video src={file.public_url} alt={file_name} width="48" height="48"/>
                        <span className="playWrapper-new-edit-article"></span>
                    </div>
                </button>
            </div>

        } else if (isImage(file_name)) {
            element = <button className="ButtonLink ant-upload-list-item-thumbnail" onClick={() => {
                this.handlePreview(index)
            }}>
                <img className="library-img" src={thumbnail} alt={file_name} style={{objectFit: "contain"}}/>
            </button>
        } else {
            element = <button className="ButtonLink ant-upload-list-item-thumbnail" onClick={() => {
                this.handlePreview(index)
            }}>
                <img src={thumbnail} alt={file_name} style={{objectFit: "contain"}}/>
            </button>
        }

        return (
            <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture">
                <div className="ant-upload-list-item-info">
                    <span className="ant-upload-span">
                        {element}
                        <span className="ant-upload-list-item-name" title={file_name}>{file_name}</span>
                    </span>
                </div>
            </div>
        )

    }

    handlePreview = (index) => {
        this.setState({preview_visible: true, preview_index: index});
    }

    handleMenuClick(e) {
        if (e.key === "edit") {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library/edit/" + this.state.article.uuid);
        } else if (e.key === "delete") {
            this.setState({showDeleteModal: true});
        } else if (e.key === "history") {
            this.props.history.push("/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library/article/" + this.state.article.uuid + "/timeline");
        }
    }

    render() {
        let author = this.state.author;
        let authorName = safe_get(author, "first_name", "ZERO") + safe_get(author, "last_name", "");

        let showEdit = (this.props.user.uuid === safe_get(author, "uuid", "")) || isAdmin(this.props.user) || isTeamLead(this.props.user);
        const deleteIsDisabled = isAdmin(this.props.user) === false;

        const menu = {
            onClick: this.handleMenuClick,
            items: [
                showEdit && {
                    key: "edit",
                    icon: <EditOutlined/>,
                    label: "Edit"
                },
                {
                    key: "history",
                    icon: <HistoryOutlined/>,
                    label: "View Edit History"
                },
                showEdit && {
                    key: "delete",
                    icon: <DeleteOutlined/>,
                    label: "Delete",
                    disabled: deleteIsDisabled,
                    className: deleteIsDisabled ? "" : "zero-delete-red"
                }
            ].filter(item => item !== false)
        }

        return (
            <div className={mainContentContainerClass()}>
                <div id="page-head" className="no-padding-mobile" style={{padding: "0px"}}></div>
                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        show={this.state.showDeleteModal}
                        cancel={this.cancelModal}
                        confirm={this.deleteArticle}
                        title="Confirmation"
                        body="Are you sure you want to delete this article?"
                        confirmButtonName="Delete this article"
                    />
                }
                {
                    this.state.showViews &&
                    <PostViewsModal
                        show={this.state.showViews}
                        cancel={this.cancelModal}
                        post_uuid={this.state.article.uuid}
                        isLibrary={true}
                    />
                }
                {
                    this.state.preview_visible &&
                    <Previewer
                        show={this.state.preview_visible}
                        close={() => {
                            this.setState({preview_visible: false})
                        }}
                        attachments={this.state.attachments}
                        index={this.state.preview_index}
                    />
                }
                {
                    this.state.loading &&
                    <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>
                        <LoadingIndicator/>
                    </div>
                }
                {
                    !this.state.loading && (Object.keys(this.state.article).length !== 0) &&
                    <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>
                        <div className="panel article thin-border" style={{maxWidth: '850px', margin: '0 auto'}}>
                            <div className="panel-heading" style={{minHeight: "35px"}}>
                                <div className="blog-title media-block">
                                    {
                                        this.state.article.status === "private" &&
                                        <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                                            <Dropdown menu={menu} trigger={['click']}>
                                                <button className="ButtonLink ant-dropdown-link">
                                                    <MoreIcon style={{height: '20px', width: '20px'}}/>
                                                </button>
                                            </Dropdown>
                                        </div>
                                    }

                                    <div className="media-body">
                                        <h2 className="articleTitle zero-blue">{this.state.article.title}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-heading" style={{minHeight: "30px"}}>
                                <div className="media-left">
                                    {
                                        author ?
                                            <Link
                                                to={"/" + this.props.organization.organization_uuid + "/users/" + author.uuid}>
                                                {safeProfilePic(author, "img-sm img-circle article", "article", {marginTop: "0px"})}
                                            </Link> :
                                            <img src={ZEROLogo} className="img-sm img-circle article"
                                                 style={{marginTop: "0px"}} alt="Profile Pic"/>
                                    }

                                </div>
                                <div className="media-body bulletin" style={{verticalAlign: "middle"}}>
                                    <small style={{fontSize: "95%", color: "#505050"}}>
                                        {
                                            author ?
                                                <Link
                                                    to={"/" + this.props.organization.organization_uuid + "/users/" + this.state.author.uuid}
                                                    style={{color: "#505050"}}>
                                                    {authorName}
                                                </Link> :
                                                authorName
                                        }
                                        &nbsp;|&nbsp;{dateFormatterNoTime(this.state.article.created_at)}
                                        {
                                            this.state.article.status === "private" &&
                                            (
                                                <span>
                                                    <span onClick={this.openViewersModal}
                                                          style={{cursor: "pointer"}}>&nbsp;|&nbsp;{this.state.views} {this.state.views === 1 ? "view" : "views"}&nbsp;|&nbsp;</span>
                                                    <span style={{cursor: "pointer"}} onClick={() => {
                                                        this.setState({showQR: !this.state.showQR});
                                                    }}>Get QR Code</span>
                                                </span>
                                            )
                                        }
                                    </small>
                                </div>
                                { this.state.showQR &&
                                    <QRCode
                                        link={window.location.href}
                                        downloadTitle={"QRCodeLibaryArticle.png"}
                                        className="mar-top-10"
                                    />
                                }
                            </div>
                            <hr className="bulletin"/>
                            <div className="panel-body pad-no zero-blue" style={{marginTop: '8px'}}>
                                <div style={{wordWrap: 'break-word'}} className="article-body-content">
                                    {this.articleBodyContent()}
                                </div>
                            </div>
                            <hr className="bulletin" style={{marginBottom: '10px'}}/>
                            <div style={{marginBottom: '13px'}}>
                                <div className="tag-div">
                                    {
                                        this.state.categories.map((category, key) => (
                                            <span className='label label-success library'
                                                  style={{backgroundColor: '#03a9f4'}} key={key}>#{category}</span>
                                        ))
                                    }
                                </div>
                                {
                                    this.state.article.status === "private" &&
                                    <span className="pull-right"
                                          style={{marginTop: '2px', marginRight: '12px', cursor: "pointer"}}
                                          onClick={this.openViewersModal}>
                                        <EyeOutlined className="mar-rgt-5"/>
                                        <small style={{fontSize: '105%'}}>{this.state.article.viewers_count}</small>
                                    </span>
                                }
                            </div>

                            {
                                this.state.attachments.length !== 0 &&
                                <div>
                                    <hr className="bulletin"/>
                                    <div className="panel-heading pad-no">
                                        <h3 className="titles" style={{marginLeft: '0px'}}>Attachments</h3>
                                    </div>
                                    {/*<div className="panel-body pad-no">*/}
                                    {/*    <div className="ant-upload-list ant-upload-list-picture picture-offset-row">*/}
                                    <div className="panel-body pad-no">
                                        <div style={{display: "inline-flex", listStyle: "none", flexWrap: "wrap"}}>
                                            {/*<ul style={{listStyle: "none"}}>*/}
                                            {
                                                this.state.attachments.map((file, index) => (
                                                    // <div key={index}>
                                                    //     <LazyLoad onContentVisible={() => {
                                                    //         postImageContain("img.library-img")
                                                    //     }}>
                                                    //         {this.showAttachment(file, index)}
                                                    //     </LazyLoad>
                                                    // </div>
                                                    <Attachment
                                                        attachment={file}
                                                        key={index}
                                                        canDelete={false}
                                                        onPreview={() => this.handlePreview(index)}
                                                    />
                                                ))
                                            }
                                            {/*</ul>*/}
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                }
                {
                    (Object.keys(this.state.article).length === 0) && !this.state.loading &&
                    <div id="page-content" className="no-padding-mobile ">
                        <div className="panel article" style={{maxWidth: '700px', margin: '0 auto'}}>
                            <h4 className="mar-btm zero-blue text-center">Sorry, this content no longer exists. It was
                                most likely deleted or moved to another team.</h4>
                        </div>
                    </div>
                }
            </div>

        );
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(Article);

