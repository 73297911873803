import React, {Component} from 'react';
import {safe_get} from '../other/Helper.js';

import Textarea from "react-textarea-autosize";

import $ from 'jquery'

class LimitedInputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: safe_get(this.props, "defaultValue", ""),
            emoji: {}
        };

        this.setInput = this.setInput.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.insertAtCursor = this.insertAtCursor.bind(this);
    }

    setInput(event) {
        this.setState({input: event.target.value});
        this.props.onKey(event.target.value);
    }

    resetInput(text) {
        this.setState({input: text});
    }

    insertAtCursor() {
        var cursorPos = $('#' + this.props.id).prop('selectionStart');
        var v = $('#' + this.props.id).val();
        var textBefore = v.substring(0, cursorPos);
        var textAfter = v.substring(cursorPos, v.length);

        // $('#'+this.props.id).val(textBefore + this.state.emoji.emoji + textAfter);

        if (v.length <= this.props.lengthLimit) {
            this.setState({
                input: textBefore + this.state.emoji.emoji + textAfter
            });
        }

    }

    onBlur = (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    render() {
        if (this.props.autoExpand) {
            return (
                <Textarea id={this.props.id} type={this.props.type} placeholder={this.props.placeholder}
                          className={this.props.class}
                          defaultValue={this.state.input} onChange={this.setInput} maxLength={this.props.lengthLimit}
                          onKeyUp={this.onKeyDown} style={{resize: 'none'}}/>
            );
        } else {
            return (
                <input
                    id={this.props.id}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    className={this.props.class}
                    defaultValue={this.state.input}
                    onChange={this.setInput}
                    onBlur={this.onBlur}
                    maxLength={this.props.lengthLimit}
                    disabled={this.props.disabled}
                    style={this.props.style}
                />
            );
        }

    }

}

export default LimitedInputField;