import { Modal, Popover, Select } from 'antd';
import { Component, useMemo } from 'react';

import AttachmentCommentBox from 'components/Shared/NewAttachmentCommentBox.js';
import { get_post_subscribers, get_subscriber_user_options, updatePostStatus } from '../../api/zero-api';
import { beforeFileUpload, fileHasZeroSize, fileUpload, safeProfilePic, safe_get } from '../../other/Helper.js';
import ButtonLoading from '../Shared/ButtonLoading.js';
import SubscribersModal from './SubscribersModal';

import '../../assets/css/antd-custom.css';
import { useSelector } from 'react-redux';
import { getPostSelectOptions } from './PostForm/PostFormCategory';

function SubStatusSelect({value, onChange, group}) {
    const postFieldNames = useSelector(state => state?.org_helper?.organization?.post_field_names ?? {});
    const statusOptions = useSelector(state => {
        if (group === 2) {
            return state.feed_helper.options.sub_statuses_2;
        } else {
            return state.feed_helper.options.sub_statuses;

        }
    });
    const selectOptions = useMemo(() => {
        return getPostSelectOptions(statusOptions, "sub_status_uuid", value);
    }, [statusOptions, value]);
    const fieldNameKey = group === 2 ? 'sub_status_2' : 'sub_status';
    const fieldNameDefault = group === 2 ? 'Status 2' : 'Status';

    

    if (statusOptions.filter(s => s.enabled).length == 0) return null;

    return (
        <>
            <div className="ant-modal-title zero-blue">
                {safe_get(postFieldNames, fieldNameKey, fieldNameDefault)}
            </div>
            <div className="panel-body bulletin-post">
                <Select
                    style={{maxWidth: "400px", width: "100%"}}
                    placeholder=""
                    value={value}
                    onChange={newStatus => onChange(newStatus)}
                    className="custom-bulletin"
                    virtual={false}
                    dropdownStyle={{zIndex: "999999999"}}
                >
                    { selectOptions }
                </Select>
            </div>
        </>
    )
}

class PostsStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            upload: false,
            errorMsg: "",
            attachments: [],
            fileList: [],
            subscribers: [],
            subscriber_options: [],
            subStatusUuid: props.subStatusUuid,
            subStatus2Uuid: props.subStatus2Uuid,
        };

        this.checkForCompletion = this.checkForCompletion.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        this.getSubscribers();
        this.getSubscriberOptions();
    }

    getSubscribers = () => {
        let self = this;
        get_post_subscribers(this.props.post_uuid).then(success => {
            success.json().then(success => {
                let subscribers = safe_get(success, "subscribers", []).sort(function (a, b) {
                    let a_name = `${a.first_name} ${a.last_name}`.toLowerCase()
                    let b_bame = `${b.first_name} ${b.last_name}`.toLowerCase()
                    return a_name > b_bame ? 1 : -1
                });
                self.setState({subscribers: subscribers, showSubscribersModal: false});
            })
        })
    }

    getSubscriberOptions = () => {
        let self = this;
        let body = JSON.stringify({post_uuid: this.props.post_uuid});
        get_subscriber_user_options(body).then(function (success) {
            success.json().then(success => {
                var options = safe_get(success, "users", []).sort(function (a, b) {
                    var a_first_name = a.first_name.toLowerCase();
                    var a_last_name = a.last_name.toLowerCase();

                    var b_first_name = b.first_name.toLowerCase();
                    var b_last_name = b.last_name.toLowerCase();

                    if (a_first_name > b_first_name) return 1;
                    if (a_first_name < b_first_name) return -1;

                    if (a_last_name > b_last_name) return 1;
                    if (a_last_name < b_last_name) return -1;

                    return 0;
                });
                self.setState({
                    subscriber_options: options,
                });
            });
        });
    }

    async updateSubStatus(group) {
        try {
            const propertyName = group === 2 ? 'subStatus2Uuid' : 'subStatusUuid';
            if (this.state[propertyName] !== this.props[propertyName]) {
                await updatePostStatus(this.props.post_uuid, this.state[propertyName], group);
            }
        } catch (err) {
            console.error(err);
            this.setState({saving: false, errorMsg: "Unable to update post status."});
            throw err;
        }
    }

    checkForCompletion() {
        var text = this.state.text
        if (/\S/.test(text)) {
            this.setState({saving: true, errorMsg: ""});
            const queue = [this.updateSubStatus(1), this.updateSubStatus(2)];

            Promise.all(queue)
                .then(() => {
                    if (this.props.post_status === "open") {
                        this.props.closePost(text, this.state.attachments);
                    } else {
                        this.props.reopenPost(text, this.state.attachments);
                    }
                })
                .catch(() => {}); // errors already handled
        } else {
            this.setState({errorMsg: "Please provide a comment."});
        }
    }

    beforeUpload = (file, fileList) => {
        if (fileHasZeroSize(file)) {
            return false;
        }
        let [allow, error_msg] = beforeFileUpload(file, fileList, this.state.attachments.length);

        if (allow) {
            return true;
        } else {
            this.setState({uploadError: error_msg});
            // this.props.uploadError(error_msg);
            return false;
        }

    }

    generateParamsCallback = file =>  ({
        feature_key: "bulletin_comment",
        post_uuid: this.props.post_uuid,
        content_type: file.type,
        file_name: file.name,
        embedded: false
    })

    onUploadSuccess = (file, pre_signed_url, file_key) => {
        const newAttachment = {
            file_path: file_key,
            file_name: file.name,
            public_url: pre_signed_url + "/" + file_key,
            mime_type: file.type,
        }

        this.setState(state => ({
            attachments: [
                ...state.attachments,
                newAttachment,
            ]
        }));
    }

    onUploadError = (error) => {
        console.error(error);
    }

    handleFileUpload = (file) => {
        var self = this;

        var uploading_file = {
            key: file.file.uid,
            uid: file.file.uid,
            status: "uploading",
            name: "Uploading...",
            percent: 0
        }

        var fileList = this.state.fileList;
        fileList.push(uploading_file);
        this.setState({fileList: fileList});

        var params = JSON.stringify({
            feature_key: "bulletin_comment",
            post_uuid: self.props.post_uuid,
            content_type: file.file.type,
            file_name: file.file.name,
            embedded: false
        });

        fileUpload(this, params, file, function (progress) {
            var fileList = self.state.fileList;
            var index = fileList.indexOf(uploading_file);

            if (index >= 0) {
                var file = fileList[index];
                file["percent"] = progress;
                fileList[index] = file;
                self.setState({fileList: fileList});
            }

        }, function (pre_signed_url, file_key) {
            var fileList = self.state.fileList;
            var index = fileList.indexOf(uploading_file);

            if (index >= 0) {
                fileList.splice(index, 1);
            }

            self.setState({
                fileList: fileList,
                uploadError: false,
                showModal: false,
                progress: '0%'
            });


            self.handleAdd(file, pre_signed_url, file_key);

        }, function (error) {
            var errorMsg = "Unable to upload file.";
            if (error.toString().includes("aborted")) {
                errorMsg = "Upload canceled.";
            }
            self.setState({showModal: false, uploadError: errorMsg, fileList: [], uploading: false});
        });
    }

    handleAdd(file, pre_signed_url, file_key) {
        var returned_file = {}
        returned_file["file_path"] = file_key;
        returned_file["file_name"] = file.file.name;
        returned_file["public_url"] = pre_signed_url + "/" + file_key;
        returned_file["mime_type"] = file.file.type;

        var attachments = this.state.attachments
        attachments.push(returned_file);
        this.setState({attachments: attachments});

    }

    handleRemove(index) {
        var attachments = this.state.attachments;
        attachments.splice(index, 1);
        this.setState({attachments: attachments, showDeleteConfirmation: false});
        // this.props.update_attachments(attachments);
    }

    render() {
        if (this.state.showSubscribersModal) {
            return <SubscribersModal
                show={this.state.showSubscribersModal}
                cancel={() => {
                    this.setState({showSubscribersModal: false});
                }}
                post_uuid={this.props.post_uuid}
                subscriber_options={this.state.subscriber_options}
                existing_subscribers={this.state.subscribers}
                existing_responders={this.props.responders}
                updateSubscribers={this.getSubscribers}
            />
        }

        return (
            (<Modal
                title={this.props.post_status === "open" ? "Close post" : "Reopen post"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.checkForCompletion}>
                            {
                                this.state.saving ?
                                    <ButtonLoading/> : this.props.post_status === "open" ? "Close post" : "Reopen post"
                            }
                        </button>
                    </div>
                }
            >
                {
                    this.props.post_status === "open" &&
                    <p className="zero-dark-grey">Please list any corrective actions or long-term improvement solutions,
                        if applicable.</p>
                }
                <AttachmentCommentBox
                    attachments={this.state.attachments}
                    commentError={this.state.errorMsg}
                    onPreview={() => {}}
                    onDelete={this.handleRemove}
                    generateParamsCallback={this.generateParamsCallback}
                    onUploadSuccess={this.onUploadSuccess}
                    onUploadError={this.onUploadError}
                    textValue={this.state.text}
                    onTextChange={newValue => this.setState({text: newValue})}
                />

                <SubStatusSelect
                    group={1}
                    value={this.state.subStatusUuid}
                    onChange={(subStatusUuid) => {
                        this.setState({subStatusUuid});
                    }}
                />

                <div className='mar-top-10'>
                    <SubStatusSelect
                        group={2}
                        value={this.state.subStatus2Uuid}
                        onChange={(subStatus2Uuid) => {
                            this.setState({subStatus2Uuid});
                        }}
                    />
                </div>


                <div className="ant-modal-title zero-blue" style={{marginTop: "10px"}}>Subscribers</div>
                <p className="mar-btm-0 zero-dark-grey">
                    {this.state.subscribers.length} {this.state.subscribers.length === 1 ? "person" : "people"} will be
                    notified when I {this.props.post_status === "open" ? "close" : "reopen"} this post. <span
                    className="post-details-content dont-print"
                    style={{textDecoration: "underline", fontWeight: '500', cursor: "pointer"}} onClick={() => {
                    this.setState({showSubscribersModal: true})
                }}>Change...</span>
                </p>
                {
                    !this.state.show_all && this.state.subscribers.length > 0 &&
                    [...this.state.subscribers].slice(0, 10).map((user, index) => (
                        <Popover key={index} placement="bottom" content={user.first_name + " " + user.last_name}>
                            {/* <Link to={"/" + this.props.organization.organization_uuid + "/users/" + user.user_uuid} >
                                {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {marginRight: "5px", display: "inline-block"})}
                            </Link> */}
                            <span>
                {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                    marginRight: "5px",
                    display: "inline-block"
                })}
              </span>
                        </Popover>
                    ))
                }
                {
                    !this.state.show_all && this.state.subscribers.length > 10 &&
                    <span onClick={() => {
                        this.setState({show_all: true})
                    }} style={{cursor: "pointer"}}>
            <div className={"default-avatar-circle bulletin"}
                 style={{marginRight: "5px", display: "inline-block", border: "1px solid #505050"}}>
              <p className="initials" style={{color: "#505050"}}>
                +{this.state.subscribers.length - 10 < 1000 ? this.state.subscribers.length - 10 : `${Math.round((this.state.subscribers.length - 10) / 1000)}k`}
              </p>
            </div>
          </span>
                }
                {
                    this.state.show_all &&
                    this.state.subscribers.map((user, index) => (
                        <Popover key={index} placement="bottom" content={user.first_name + " " + user.last_name}>
                            {/* <Link to={"/" + this.props.organization.organization_uuid + "/users/" + user.user_uuid} > */}
                            <span>
                {safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                    marginRight: "5px",
                    display: "inline-block"
                })}
              </span>
                            {/* </Link> */}
                        </Popover>
                    ))
                }
            </Modal>)
        );


    }

}

export default PostsStatusModal;