import { useContext, createContext } from "react";

export const PublicContext = createContext({});

/**
 * @returns {{
 *  publicOrgId: string;
 * }}
 */
export function usePublicContext() {
    return useContext(PublicContext);
}