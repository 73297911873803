import '../../assets/css/sidebar.css';
import SideBarInternals from './SideBarInternals.js';

export default function InlineSideBar({isOffline, isPublicView, draftErrorCount}) {
    return (
        <div
            id="inline-sidebar"
            className="panel thin-border"
            style={{
                width: "250px",
                marginTop: "10px",
                backgroundColor: "white",
                position: "fixed"
            }}
        >
            <SideBarInternals
                isOffline={isOffline}
                isPublicView={isPublicView}
                draftErrorCount={draftErrorCount}
                isInline={true}
            />
        </div>
    );
}
