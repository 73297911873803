import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {safe_get} from '../../other/Helper.js';
import {Modal} from 'react-bootstrap';
import {Select} from 'antd'

class SelecTeamModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {

    }

    handleTeamSelect(value) {
        this.setState({selected_team_uuid: value});
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.cancel} className="modal fade"
                   dialogClassName={"small-popover"} backdrop="static">
                <Modal.Header closeButton style={{paddingBottom: '5px'}}>
                    <Modal.Title className="zero-blue">Select team</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop: '0px', minHeight: '50px'}}>
                    <p>Select a team to submit form:</p>
                    <Select
                        style={{width: "100%",}}
                        className="move-team-select"
                        showArrow={true}
                        placeholder="Select a Team"
                        dropdownStyle={{zIndex: "10000"}}
                        onChange={this.handleTeamSelect.bind(this)}>
                        {
                            this.props.teams.map((team, index) => (
                                <Select.Option key={team.uuid} value={team.uuid}
                                               disabled={(team.uuid === this.props.source_team)}>{team.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-discard" onClick={this.props.cancel}>
                        Cancel
                    </button>
                    <button className="btn btn-modal" disabled={this.state.selected_team_uuid === undefined}
                            onClick={() => this.props.confirm(this.state.selected_team_uuid)}>
                        Next
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
    }
}

export default withRouter(connect(mapStateToProps)(SelecTeamModal));