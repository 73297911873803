import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {create_check_in_alert, update_check_in_alert, delete_check_in_alert} from 'api/zero-api';
import {extractErrorMessages} from 'other/Helper';

const initialState = {
    alerts: null,
    teams: null,
    loading: false,
    error: false,
    modifyAlertStatus: {},
    alertModalVisible: false,
    alertToEdit: null,
    deleteAlertModalVisible: false
}

export const updateAlert = createAsyncThunk(
    'manageCheckInAlerts/updateAlert',
    async ({uuid, body}, {rejectWithValue}) => {
        try {
            const response = await update_check_in_alert(uuid, JSON.stringify(body));
            const data = await response.json();

            return data;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
);

export const createAlert = createAsyncThunk(
    'manageCheckInAlerts/createAlert',
    async ({body}, {rejectWithValue}) => {
        try {
            const response = await create_check_in_alert(JSON.stringify(body));
            const data = await response.json();

            return data;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
);

export const deleteAlert = createAsyncThunk(
    'manageCheckInAlerts/deleteAlert',
    async ({uuid}, {rejectWithValue}) => {
        try {
            const response = await delete_check_in_alert(uuid);
            return uuid;
        } catch (err) {
            return rejectWithValue(await extractErrorMessages(err));
        }
    }
);

const slice = createSlice({
    name: 'manageCheckInAlerts',
    initialState,
    reducers: {
        resetState: () => initialState,
        updateState: (state, {payload}) => {
            for (const [key, value] of Object.entries(payload)) {
                state[key] = value;
            }
        },
        setAlerts: (state, {payload}) => {
            state.alerts = payload;
        },
        setTeams: (state, {payload}) => {
            state.teams = payload;
        },
        setError: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },
        setLoading: (state, {payload}) => {
            state.loading = payload;
        },
        showModal: (state, {payload}) => {
            if (state.loading) return;

            state.alertModalVisible = true;
            state.alertToEdit = payload || null;
        },
        showDeleteModal: (state, {payload}) => {
            if (state.loading) return;

            state.deleteAlertModalVisible = true;
            state.alertToEdit = payload;
        },
        hideModal: (state) => {
            state.alertModalVisible = false;
            state.alertToEdit = null;
        },
        hideDeleteModal: (state) => {
            state.deleteAlertModalVisible = false;
            state.alertToEdit = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createAlert.fulfilled, (state, {payload}) => {
            state.modifyAlertStatus = {};

            if (state.alerts !== null) {
                state.alerts.splice(0, 0, payload);
            }

            state.alertModalVisible = false;
            state.alertToEdit = null;
        });

        builder.addCase(createAlert.pending, (state) => {
            state.modifyAlertStatus = {
                loading: true
            };
        });

        builder.addCase(createAlert.rejected, (state, {payload}) => {
            state.modifyAlertStatus = {
                data: payload,
                error: true
            }
        });

        builder.addCase(updateAlert.fulfilled, (state, {payload}) => {
            state.modifyAlertStatus = {};

            if (state.alerts !== null) {
                const index = state.alerts.findIndex(alert => alert.uuid === payload.uuid);
                if (index !== -1) {
                    state.alerts[index] = payload;
                }
            }
            state.alertModalVisible = false;
            state.alertToEdit = null;
        });

        builder.addCase(updateAlert.pending, (state) => {
            state.modifyAlertStatus = {
                loading: true
            };
        });

        builder.addCase(updateAlert.rejected, (state, {payload}) => {
            state.modifyAlertStatus = {
                data: payload,
                error: true
            }
        });

        builder.addCase(deleteAlert.fulfilled, (state, {payload}) => {
            if (state.alerts !== null) {
                const index = state.alerts.findIndex(alert => alert.uuid === payload);
                if (index !== -1) {
                    state.alerts.splice(index, 1);
                }
            }
            state.deleteAlertModalVisible = false;
            state.alertToEdit = null;
        });

        builder.addCase(deleteAlert.rejected, (state, {payload}) => {
            state.deleteAlertModalVisible = false;
            state.alertToEdit = null;
        });
    }
});

export const {actions} = slice;
export default slice.reducer;