import { useEffect, useMemo, useState } from 'react';
import { safe_get, safeProfilePic } from '../../other/Helper.js';
import { UserAddOutlined } from '@ant-design/icons';
import RespondersPopover from 'components/Shared/RespondersPopover';
import { useParams } from "react-router";
import UserPopoverCard from "../Shared/UserPopoverCard.js";
import { useZeroContext } from '../ZeroContext.js';
import { useSelector } from 'react-redux';
import ButtonLoading from '../Shared/ButtonLoading.js';
import { get_subscriber_user_options } from 'api/zero-api.js';

const SHOW_LIMITED_SUBSCRIBERS_COUNT = 49;

export  function PostNotificationsPopover({orgUuid, selectedSubscribers, handleOnChange, isLoading, teamUuid, postUuid }) {

    const { post_uuid } = useParams();
    const [showAll, setShowAll] = useState(false);
    const [availableSubscribers, setAvailableSubscribers] = useState([]);
    const zeroContext = useZeroContext();
    const postService = zeroContext.services.post;
    const user = useSelector(state => safe_get(state, "user.user", {}));
    const userCanSubscribe = useMemo(() => {
        const userFound = availableSubscribers.find((u) => u.uuid === user.uuid);
        return Boolean(userFound);
    }, [availableSubscribers, user]);
    
    const userSubscribed = useMemo(() => {
        const userFound = selectedSubscribers.find((u) => u.user_uuid === user.uuid);
        return Boolean(userFound);
    }, [selectedSubscribers, user]);

 
    const subscribeMe = () => {
            handleOnChange([...selectedSubscribers.map(u => u.user_uuid), user.uuid]);
    }

    const unsubscribeMe = () => {
            handleOnChange(selectedSubscribers.map(u => u.user_uuid).filter(uuid => uuid !== user.uuid));
    }

    // load available subscribers based on team or post id
    useEffect(() => {
        if (teamUuid) {
            postService.getAvailableSubscribersAndResponders(teamUuid)
            .then((res) => {
                setAvailableSubscribers(res.availableSubscribers);
            })
        } else if (postUuid) {
            get_subscriber_user_options(JSON.stringify({post_uuid: postUuid}))
            .then(res => res.json())
            .then(data => {
                setAvailableSubscribers(data?.users ?? []);
            });
        }
    }, [teamUuid, postUuid])

    return (
        <div className="panel-heading bulletin-post" style={{ marginBottom: "5px" }}>
            <h3 className="bulletin-post-headers">Notifications</h3>
            <p className="zero-dark-grey" style={{ marginBottom: "0px" }}>
                {
                    "When I post this, " + selectedSubscribers.length + (((selectedSubscribers.length === 1) ? " person" : " people") + " will be notified.")
                }
                {' '}
                <RespondersPopover
                    title="Notifications"
                    available_responders={availableSubscribers}
                    selected_responders={selectedSubscribers}
                    updateResponders={uuids => handleOnChange(uuids)}
                >
                    
                    <button className="ButtonLink ButtonLink-hover-underline zero-light-blue">Change...
                    </button>
                </RespondersPopover>
            </p>
            <div>
                {
                    selectedSubscribers.length > 0 &&
                    selectedSubscribers.slice(0, showAll ? selectedSubscribers.length: SHOW_LIMITED_SUBSCRIBERS_COUNT).map((user, index) => (
                        <UserPopoverCard
                            key={index}
                            user={user}
                            subscribers={selectedSubscribers}
                            post_uuid={post_uuid}
                            org_uuid={orgUuid}
                            updateSubscribers={uuids => handleOnChange(uuids)}
                            showRemoveSubscriber={true}
                        >
                            <span
                                style={{ cursor: "pointer" }}>{safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                                    marginRight: "5px",
                                    display: "inline-block"
                                })}</span>
                        </UserPopoverCard>
                    ))
                }
                  
                {
                    !isLoading &&
                    <RespondersPopover
                        title="Notifications"
                        available_responders={availableSubscribers}
                        selected_responders={selectedSubscribers}
                        updateResponders={uuids => handleOnChange(uuids)}
                    >
                        <button className="ButtonLink post-details-content mar-top-5"
                            style={{ verticalAlign: "bottom", height: "26px", width: "26px" }}>
                            <UserAddOutlined style={{ fontSize: "19px" }} />
                        </button>
                    </RespondersPopover>
                }
                {selectedSubscribers.length > SHOW_LIMITED_SUBSCRIBERS_COUNT && !showAll &&
                    <button
                        className="ButtonLink zero-dark-grey"
                        style={{textDecoration: "underline", fontWeight: '500', fontSize: "95%"}}
                        onClick={() => {
                            setShowAll(true);
                        }}
                    >
                        Show all...
                    </button>
                }
            </div>

            <div className="dont-print" style={{marginTop: "15px"}}>
                    <h3 className="post-details-header zero-blue">{userSubscribed ? "You'll be notified" : "You won't be notified"}</h3>
                    <p className="zero-dark-grey">
                        {
                            userSubscribed ?
                                "You'll be notified when someone comments on this post." :
                                "You won't be notified when someone comments on this post."
                        }
                    </p>
                    <button className="btn btn-discard"
                            disabled={!userSubscribed && !userCanSubscribe}
                            onClick={userSubscribed ? unsubscribeMe : subscribeMe}>
                        {
                            isLoading ? <ButtonLoading
                                className="inverted"/> : (userSubscribed ? "Don't notify me" : "Notify me")
                        }
                    </button>
                </div>
              
        </div>
    )
}

