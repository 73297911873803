import React, {Component} from 'react';
import {connect} from 'react-redux'


import {Checkbox, Switch} from 'antd'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

import {toggle_feature_flag, toggle_zero_library} from '../../../api/zero-api.js'
import {safe_get} from '../../../other/Helper.js';

import * as organizationActions from '../../../store/actions/OrganizationActions'
import {AllowedFeatures} from 'other/Constants.js';
import InfoIconComponent from 'components/InfoIconComponent.js';

class FeatureSettings extends Component {
    constructor(props) {
        super(props);

        const zeroControlledFeatures = {};
        for (const key of Object.values(AllowedFeatures)) {
            if (key === AllowedFeatures.VOICE_CALLS) {
                continue;
            }
            zeroControlledFeatures[key] = safe_get(this.props.organization, 'allowed_features', []).includes(key);
        }

        this.state = {
            feature_flags: this.props.organization.feature_flags,
            zeroControlledFeatures,
            zeroLibraryEnabled: this.props.organization.enable_zero_library,
        };
    }

    onSwitch = (feature) => {
        let self = this;

        let body = JSON.stringify({
            enabled: !this.state.feature_flags[feature]
        });

        toggle_feature_flag(feature, body).then(success => {
            success.json().then(success => {
                let feature_flags = safe_get(success, "feature_flags", {});

                let updated_org = {...self.props.organization};
                updated_org["feature_flags"] = feature_flags;

                self.setState({feature_flags: feature_flags});

                self.props.dispatch(organizationActions.update_current_org(updated_org));

            });
        }, error => {

        });

    }

    onZeroLibraryToggle = (e) => {
        this.setState({zeroLibraryEnabled: e.target.checked});

        toggle_zero_library()
            .then(response => response.json())
            .then(content => {
                this.setState({zeroLibraryEnabled: content.organization.enable_zero_library});
                this.props.dispatch(organizationActions.update_current_org(content.organization));
            })
            .catch(err => console.error(err));
    }

    getPremiumFeatureInfoText = (allowedFeature) => {
        if (this.state.zeroControlledFeatures[allowedFeature]) {
            return "This premium feature has been enabled on your account. You can enable/disable this feature at any time.";
        }
        return "This is a premium feature. Please contact support@teamzero.com if you're interested in upgrading your package.";
    }

    getOfflineInfoText = () => {
        if (this.state.feature_flags.offline) {
            return "This is a premium feature. Please contact support@teamzero.com if you're interested in upgrading your package to permanently include this feature. You can enable/disable this feature at any time.";
        }
        return "This is a premium feature. Please contact support@teamzero.com if you're interested in upgrading your package to permanently include this feature. You can enable/disable this feature at any time.";
    }

    showFreshDeskContact = () => {
        if (window.FreshworksWidget) {
            window.FreshworksWidget('open', 'ticketForm');
        }
    }

    render() {

        const switchWrapperStyle = {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.2rem',
        }

        const {
            zeroControlledFeatures,
            feature_flags,
        } = this.state;

        return (
            <div className="panel pad-top pad-btm" style={{maxWidth: '800px', margin: '0 auto'}}>

                <h3 className="titles account" style={{marginRight: "15px", marginTop: "0px"}}>Managing Feature
                    Visibility in ZERO</h3>
                <p className="zero-dark-grey">There are five core features included with a standard ZERO subscription.
                    Below you can control which features will be visible to users on your account. You can
                    enable/disable any feature at any time. If you disable a feature, it will no longer be visible in
                    the main navigation menu, including on the mobile apps. Historical data will be preserved.</p>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>Posts</div>
                    <Switch
                        id="feed"
                        checked={this.state.feature_flags.feed}
                        onChange={() => {
                            this.onSwitch("feed")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>Forms</div>
                    <Switch
                        id="forms"
                        checked={this.state.feature_flags.forms}
                        onChange={() => {
                            this.onSwitch("forms")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>Incidents</div>
                    <Switch
                        id="incidents"
                        checked={this.state.feature_flags.incidents}
                        onChange={() => {
                            this.onSwitch("incidents")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>Courses</div>
                    <Switch
                        id="courses"
                        checked={this.state.feature_flags.courses}
                        onChange={() => {
                            this.onSwitch("courses")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>Library</div>
                    <Switch
                        style={{marginRight: '1.5rem'}}
                        checked={this.state.feature_flags.library}
                        onChange={() => {
                            this.onSwitch("library")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                    <Checkbox
                        className="zero-dark-grey"
                        checked={this.state.zeroLibraryEnabled}
                        onChange={this.onZeroLibraryToggle}
                    >
                        <>ZERO Library</>
                    </Checkbox>
                </div>

                <hr/>
                <h3 className="titles account" style={{marginRight: "15px", marginTop: "0px"}}>Premium Features</h3>
                <p className="zero-dark-grey">Premium features can be added for an additional cost. To learn more,
                    please <span className="blue-link" onClick={() => {
                        this.showFreshDeskContact()
                    }}>contact support</span>.</p>
                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>
                        Offline
                        <InfoIconComponent position={"top"} width={"350px"} text={this.getOfflineInfoText()}/>
                    </div>
                    <Switch
                        id="courses"
                        checked={this.state.feature_flags.offline}
                        onChange={() => {
                            this.onSwitch("offline")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>
                        Public Forms
                        <InfoIconComponent position={"top"} width={"350px"} text={this.getPremiumFeatureInfoText(AllowedFeatures.PUBLIC_FORMS)}/>
                    </div>
                    <Switch
                        checked={zeroControlledFeatures[AllowedFeatures.PUBLIC_FORMS] && this.state.feature_flags.public_forms}
                        disabled={!zeroControlledFeatures[AllowedFeatures.PUBLIC_FORMS]}
                        onChange={() => {
                            this.onSwitch("public_forms")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>

                <div style={switchWrapperStyle}>
                    <div className="zero-dark-grey" style={{width: "10rem"}}>
                        Check-in
                        <InfoIconComponent position={"top"} width={"350px"} text={this.getPremiumFeatureInfoText(AllowedFeatures.DESKS)}/>
                    </div>
                    <Switch
                        checked={zeroControlledFeatures[AllowedFeatures.DESKS] && this.state.feature_flags.desks}
                        disabled={!zeroControlledFeatures[AllowedFeatures.DESKS]}
                        onChange={() => {
                            this.onSwitch("desks")
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(FeatureSettings);