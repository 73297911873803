import SelectFormModal from "components/Forms/SelectFormModal";
import Button from "components/Shared/Button";
import {MainContent, PageContent, PageHead, BorderedPanel} from "components/Shared/PageParts";
import {dateFormatterWithTime, hiddenNavToOfflineDebugIsAllowed} from "other/Helper";
import NotificationAlert from "other/NotificationAlert";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import FormAssignmentsList from "./Forms/FormAssignmentsList";
import SubmissionDraftsList from "./Forms/SubmissionDraftsList";
import {useOfflineDataCachesContext} from "./OfflineDataCaches";
import useOfflineMode from "./useOfflineMode";
import Skeleton from 'react-loading-skeleton'
import { useZeroContext } from "components/ZeroContext";
import SyncInProgressSpinner from "./SyncInProgressSpinner";
import FormsSyncErrorAlert from "./Forms/FormsSyncErrorAlert";
import { Space } from "antd";
import { PostDraftsList } from "./Posts/PostDraftsList";


function SyncSkeleton() {
    return (
        <div className="zero-blue flex-col" style={{gap: '0.5rem'}}>
            <>
                <Skeleton width={150}/>
                <Skeleton width={120}/>
                <Skeleton width={200}/>
                <Skeleton width={250}/>
            </>
            <hr style={{width: '100%', margin: '0.5rem 0'}}/>
        </div>
    )
}

function DraftLists({showSkeletons, panelHeight}) {
    /**
     * @typedef {"submission" | "post"} TabType
     * @type {[TabType, (TabType) => void]}
     */
    const [activeTab, setActiveTab] = useState("post");
    const [postDrafts, setPostDrafts] = useState([]);
    const {submissionDrafts} = useOfflineDataCachesContext();


    const buttonProps = {
        className: "ButtonLink",
        style: {height: "55px"},
    }

    return (
        <BorderedPanel noPadding>
            <ul className="nav nav-tabs nav-justified toggle-tabs">
                <li className={activeTab === 'post' ? "active" : ""}>
                    <button {...buttonProps} onClick={() => setActiveTab('post')}>
                        Post Drafts ({postDrafts.length})
                    </button>
                </li>
                <li className={activeTab === 'submission' ? "active" : ""}>
                    <button {...buttonProps} onClick={() => setActiveTab('submission')}>
                        Form Drafts ({submissionDrafts.regularDrafts.length})
                    </button>
                </li>
            </ul>
            <div className="panel-body" style={{height: panelHeight, overflow: "auto"}}>
                {
                    showSkeletons ? (
                        <>
                            <SyncSkeleton/>
                            <SyncSkeleton/>
                            <SyncSkeleton/>
                        </>
                    ) : (
                        activeTab === 'submission' ? <SubmissionDraftsList/> : <PostDraftsList cachedDrafts={postDrafts} setCachedDrafts={setPostDrafts} />
                    )
                }
            </div>
        </BorderedPanel>
    )
}

export default function OfflineDashboard({history}) {
    const panelHeight = '480px';
    const {isOffline} = useOfflineMode();
    const organizationUuid = useSelector(state => state.org_helper.organization.organization_uuid);
    const userUuid = useSelector(state => state.user.user.uuid);
    const [activeModal, setActiveModal] = useState(null);
    const [syncError, setSyncError] = useState('');
    const {
        lastSyncAt, sync, isSyncing,
        initialData,
        forms,
        submissionDrafts: {cache: submissionDraftsCache},
    } = useOfflineDataCachesContext();
    const [showSkeletons, setShowSkeletons] = useState(isSyncing);
    const {services} = useZeroContext();
    const creatingSubmissionRef = useRef(false);

    const createNewSubmission = useCallback(async (teamId, formId) => {
        if (creatingSubmissionRef.current) {
            return;
        }
        creatingSubmissionRef.current = true;
        const team = initialData.teams.find(team => team.uuid === teamId);
        const form = forms.find(form => form.form_uuid === formId);

        if (!team || !form) {
            const message = 'Could not create new offline submission: Invalid team or form'
            console.error(message);
            NotificationAlert('error', '', message);
            return;
        }

        const draftId = await services.forms.createDraft(formId, teamId, 0)

        const navigateToDraft = () => {
            setActiveModal(null);
            creatingSubmissionRef.current = false;
            history.push(`/${organizationUuid}/home/team/${teamId}/forms/${formId}/submission/${draftId}`);
        };

        submissionDraftsCache.onChange(navigateToDraft);
    }, [initialData.teams, forms, initialData.user, submissionDraftsCache, history, setActiveModal]);

    const tryToSync = useCallback(() => {
        if (isOffline) {
            setSyncError("You're offline. Please try again later.");
        } else if (!isSyncing) {
            setSyncError('');
            sync();
        }
    }, [isOffline, isSyncing, sync, setSyncError]);

    useEffect(() => {
        if (!isOffline) {
            setSyncError('');
        }
    }, [isOffline]);

    useEffect(() => {
        let timer = null;
        if (isSyncing) {
            clearTimeout(timer);
            setShowSkeletons(true);
        } else {
            setTimeout(() => {
                setShowSkeletons(false)
            }, 500);
        }

        return () => {
            clearTimeout(timer);
        }
    }, [isSyncing, setShowSkeletons]);

    return (
        <MainContent>
            {activeModal === 'new-submission' &&
                <SelectFormModal
                    show={activeModal === 'new-submission'}
                    cancel={() => {
                        setActiveModal(null);
                    }}
                    current_team_uuid="my_teams"
                    confirm={createNewSubmission}
                    teams={initialData.teams}
                    page_type={"regular"}
                />
            }
            <PageHead>
                <BorderedPanel>
                    <h3 className="page-title zero-blue">
                        <span onDoubleClick={() => {
                            if (hiddenNavToOfflineDebugIsAllowed()) {
                                history.push(`/${organizationUuid}/offline/debug`)
                            }
                        }}>Offline Dashboard</span>
                    </h3>
                    <p className="page-description">Submit posts and forms offline. ZERO will sync your
                        work when you're back online.</p>
                    <p></p>
                    <p className="page-description">
                        <>Last sync:&nbsp;</>
                        {
                            lastSyncAt > 0 ? (
                                <>{dateFormatterWithTime(lastSyncAt)}</>
                            ) : (
                                <>Not Synced</>
                            )
                        }
                        {isSyncing === false &&
                            <span className="blue-link mar-lft-10" onClick={tryToSync}>Sync now</span>
                        }
                    </p>
                    <SyncInProgressSpinner style={{paddingTop: '1rem'}}/>
                    {syncError &&
                        <small style={{display: 'block'}} className="error">{syncError}</small>
                    }
                    <Space>
                        <Button type="primary" className="mar-top-10" onClick={() => {
                            history.push(`/${organizationUuid}/home/team/my_teams/feed/new_post`);
                        }}>Create Post</Button>
                        <Button type="primary" className="mar-top-10" onClick={() => {
                            setActiveModal('new-submission')
                        }}>Submit Form</Button>
                    </Space>
                </BorderedPanel>
            </PageHead>
            <PageContent>
                <FormsSyncErrorAlert
                    className="mar-btm-10"
                    description={<>If the sync error persists, you may need to edit your draft and re-submit it (see the <span className="text-semibold">My Drafts</span> panel below).</>}
                />
                <div className="row" style={{padding: '0 0.5rem'}}>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                        <BorderedPanel noPadding>
                            <ul className="nav nav-tabs nav-justified toggle-tabs">
                                <li className="active">
                                    <button className="ButtonLink" style={{height: "55px", cursor: "default"}}>
                                        My Assignments
                                    </button>
                                </li>
                            </ul>
                            <div className="panel-body" style={{height: panelHeight, overflow: "auto"}}>
                                {
                                    showSkeletons ? (
                                        <>
                                            <SyncSkeleton/>
                                            <SyncSkeleton/>
                                        </>
                                    ) : (
                                        <FormAssignmentsList/>
                                    )
                                }
                            </div>
                        </BorderedPanel>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                        <DraftLists
                            showSkeletons={showSkeletons}
                            panelHeight={panelHeight}
                        />
                    </div>
                </div>
            </PageContent>
        </MainContent>
    )
}