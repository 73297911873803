import React, {useEffect, useState} from 'react';

import {DeleteOutlined} from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import {UserWithAvatar} from "components/Spaces/DirectoryPage";
import {CheckInStatus, CheckInLocale} from 'other/Constants';
import {CheckInTag} from './DirectoryTag';


export const tabOption = {
    PAST: 'past',
    ACTIVE: 'active',
    COLLEAGUE: 'colleague',
    GUEST: 'guest',
    FUTURE: 'future',
}

function getCheckInStatus(checkIn) {
    const now = Math.floor(Date.now() / 1000);

    if (checkIn.status === CheckInStatus.PENDING && checkIn.expires_at > now) {
        return 'Pending';
    } else if (checkIn.status === CheckInStatus.FAILED) {
        return 'Declined';
    } else if (checkIn.locale !== CheckInLocale.OFFICE && checkIn.starts_at > now) {
        return 'Scheduled';
    } else if (checkIn.expires_at <= now) {
        return 'Expired';
    } else {
        return 'Completed';
    }
}

function getCheckInDate(checkIn, currentTab) {
    const date = moment(checkIn.starts_at_date, 'YYYY-MM-DD').format('MMM DD, YYYY');
    const isToday = currentTab === tabOption.ACTIVE && moment().format('YYYY-MM-DD') === checkIn.starts_at_date;

    return `${date}${isToday ? ' (Today)' : ''}`;
}


export default function CheckInsTable({currentTab, dataLoader, onDelete}) {
    const {ok, loading, error, data} = dataLoader;
    const [tableData, setTableData] = useState(null);

    let columnCount = 3;
    if (currentTab === tabOption.ACTIVE) columnCount = 4;
    else if (currentTab === tabOption.COLLEAGUE) columnCount = 5;
    else if (currentTab === tabOption.GUEST) columnCount = 6;
    else if (currentTab === tabOption.FUTURE) columnCount = 5;

    function canDeleteCheckIn(checkIn) {
        const now = Math.floor(Date.now() / 1000);
        const isExpired = checkIn.expires_at <= now;
        const isInFuture = checkIn.starts_at > now;
        const isPending = checkIn.status === CheckInStatus.PENDING;

        if (currentTab === tabOption.PAST || isExpired) {
            // should not be able to delete any check-ins on the past check-ins tab
            return false;
        }

        if (isInFuture || isPending) {
            // can only delete future check-ins, or if they are pending
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (error) {
            setTableData(
                <tr>
                    <td colSpan={columnCount}>Could not load data.</td>
                </tr>
            );
        } else if (loading || !ok) {
            setTableData(
                <tr>
                    <td>
                        <Skeleton width={75}/>
                    </td>
                    {currentTab === tabOption.GUEST &&
                        <td>
                            <Skeleton width={150}/>
                        </td>
                    }
                    {currentTab === tabOption.COLLEAGUE &&
                        <td>
                            <Skeleton width={150}/>
                        </td>
                    }
                    <td>
                        <Skeleton width={150}/>
                    </td>
                    <td>
                        <Skeleton width={75}/>
                    </td>
                    {currentTab === tabOption.ACTIVE &&
                        <td></td>
                    }
                </tr>
            );
        } else if (ok && data.results.length === 0) {
            setTableData(
                <tr>
                    <td colSpan={columnCount} className="zero-dark-grey">No check-ins.</td>
                </tr>
            );
        } else if (ok) {
            setTableData(
                data.results.map(checkIn => (
                    <tr key={checkIn.uuid}>
                        <td className="zero-dark-grey">
                            {getCheckInDate(checkIn, currentTab)}
                        </td>
                        {currentTab === tabOption.GUEST &&
                            <td className="zero-dark-grey">
                                {checkIn.guest_name}
                            </td>
                        }
                        {currentTab === tabOption.COLLEAGUE &&
                            <td className="zero-dark-grey">
                                <UserWithAvatar user={checkIn.user}/>
                            </td>
                        }
                        {currentTab === tabOption.FUTURE && 
                            <td className="zero-dark-grey">
                                {checkIn.user
                                    ? <UserWithAvatar user={checkIn.user}/>
                                    : <span>{checkIn.guest_name} (Guest)</span>
                                }
                            </td>
                        }
                        <td>
                            <CheckInTag checkIn={checkIn} enableFloorPlan normalizedDeskName={false}/>
                        </td>
                        <td className="zero-dark-grey">
                            {getCheckInStatus(checkIn)}
                        </td>
                        {currentTab !== tabOption.PAST &&
                            <td style={{width: "40px", padding: "0px", fontSize: '18px'}}>
                                {canDeleteCheckIn(checkIn) &&
                                    <DeleteOutlined
                                        className="zero-delete-red hover-cursor"
                                        onClick={() => onDelete(checkIn.uuid, currentTab)}
                                    />
                                }
                            </td>
                        }
                    </tr>
                ))
            );
        }
    }, [dataLoader, onDelete]);

    return (
        <div className="table-responsive" style={{border: "none"}}>
            <table className="table table-vcenter mar-btm-0" style={{tableLayout: "fixed"}}>
                <thead>
                <tr>
                    <th className="zero-blue" style={{width: 120}}>Date</th>
                    {currentTab === tabOption.GUEST &&
                        <th className="zero-blue" style={{width: 250}}>Guest</th>
                    }
                    {currentTab === tabOption.COLLEAGUE &&
                        <th className="zero-blue" style={{width: 250}}>Colleague</th>
                    }
                    {currentTab === tabOption.FUTURE &&
                        <th className="zero-blue" style={{width: 250}}>User</th>
                    }
                    <th className="zero-blue" style={{width: 250}}>Location</th>
                    <th className="zero-blue" style={{width: 120}}>Status</th>
                    {currentTab !== tabOption.PAST &&
                        <th className="zero-blue" style={{width: 40}}></th>
                    }
                </tr>
                </thead>
                <tbody>
                {tableData}
                </tbody>
            </table>
        </div>
    )
}