import React from 'react';
import {connect} from 'react-redux'
import {Alert} from 'antd';
import {safe_get} from '../../other/Helper';
import {withRouter} from 'react-router';

const orgUuids = [
//    'b4f96ad0-c4ff-4f1e-9b73-d7a73542c4a1', // Everest
//    '8015efd8-93ef-47f9-a4e4-99a609432644', // Staging 1
//    '2f096df1-ba70-480f-84a3-b2964333736c'  // Tim's local dev org
];

class UrgentContactDetailsAlert extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick = () => {
        const {organizationUuid, user, history} = this.props;
        history.push(`/${organizationUuid}/home/myprofile/${user.uuid}?tab=editProfile`);
    }

    render() {
        const {organizationUuid, user} = this.props;
        if (orgUuids.includes(organizationUuid)) {
            const altEmail = safe_get(user, 'alternate_email', null);
            const mobilePhone = safe_get(user, 'mobile_phone_number', null);

            if (!altEmail || !mobilePhone) {
                return (
                    <Alert
                        className="hover-cursor"
                        style={{margin: '2rem 0'}}
                        message="Everest Alert: your profile does not have a mobile phone number (SMS) and/or alternate email. Please click here to add this information and receive future urgent announcements."
                        type="info"
                        onClick={this.onClick}
                    />
                );
            }
        }

        return null;
    }

}

const mapStateToProps = store => {
    return {
        organizationUuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        user: safe_get(store, "user.user", "")
    }
}

export default withRouter(connect(mapStateToProps)(UrgentContactDetailsAlert));
