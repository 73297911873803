import React, { useState } from 'react';
import { useParams } from 'react-router';
import { UserAddOutlined } from '@ant-design/icons';

import { actions } from './reducer';
import UserPopoverCard from 'components/Shared/UserPopoverCard';
import RespondersPopover from 'components/Shared/RespondersPopover';
import { safeProfilePic } from 'other/Helper';

const SHOW_LIMITED_SUBSCRIBERS_COUNT = 49;

export default function PostFormSubscribers({ state, dispatch, orgUuid }) {
    const { post_uuid } = useParams();
    const [showAll, setShowAll] = useState(false);
    
    return (
        <div className="panel-heading bulletin-post" style={{ marginBottom: "5px" }}>
            <h3 className="bulletin-post-headers">Notifications</h3>
            <p className="zero-dark-grey" style={{ marginBottom: "0px" }}>
                {
                    "When I post this, " + state.selectedSubscribers.length + (((state.selectedSubscribers.length === 1) ? " person" : " people") + " will be notified.")
                }
                   {' '}
                   <RespondersPopover
                        title="Notifications"
                        available_responders={state.availableSubscribers}
                        selected_responders={state.selectedSubscribers}
                        updateResponders={uuids => dispatch([actions.SUBSCRIBERS_CHANGED, uuids])}
                    >
                    
                    <button className="ButtonLink ButtonLink-hover-underline zero-light-blue">Change...</button>
                </RespondersPopover>
            </p>
            <div>
                {
                    state.selectedSubscribers.length > 0 &&
                    state.selectedSubscribers.slice(0, showAll ? state.selectedSubscribers.length: SHOW_LIMITED_SUBSCRIBERS_COUNT).map((user, index) => (
                        <UserPopoverCard
                            key={index}
                            user={user}
                            subscribers={state.selectedSubscribers}
                            post_uuid={post_uuid}
                            org_uuid={orgUuid}
                            updateSubscribers={uuids => dispatch([actions.SUBSCRIBERS_CHANGED, uuids])}
                            showRemoveSubscriber={true}
                        >
                            <span
                                style={{ cursor: "pointer" }}>{safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                                    marginRight: "5px",
                                    display: "inline-block"
                                })}</span>
                        </UserPopoverCard>
                    ))
                }
                  
                {
                    !state.loadingPost && !state.loadingSubscribers &&
                    <RespondersPopover
                        title="Notifications"
                        available_responders={state.availableSubscribers}
                        selected_responders={state.selectedSubscribers}
                        updateResponders={uuids => dispatch([actions.SUBSCRIBERS_CHANGED, uuids])}
                    >
                        <button className="ButtonLink post-details-content mar-top-5"
                            style={{ verticalAlign: "bottom", height: "26px", width: "26px" }}>
                            <UserAddOutlined style={{ fontSize: "19px" }} />
                        </button>
                    </RespondersPopover>
                }
                {state.selectedSubscribers.length > SHOW_LIMITED_SUBSCRIBERS_COUNT && !showAll &&
                    <button
                        className="ButtonLink zero-dark-grey"
                        style={{textDecoration: "underline", fontWeight: '500', fontSize: "95%"}}
                        onClick={() => {
                            setShowAll(true);
                        }}
                    >
                        Show all...
                    </button>
                }
            </div>
              
        </div>
    )
}
