import React, {useEffect, useState} from "react";
import DynamicListCache from "offline/DynamicListCache";

export default function useDynamicListCacheLoader(isAppLoading, orgId, userId) {
    /** @type {[DynamicListCache, React.Dispatch<any>]} */
    const [cache, setCache] = useState(null);

    useEffect(() => {
        if (orgId && userId && !isAppLoading) {
            const cache = new DynamicListCache(orgId);
            setCache(cache);
        }
    }, [orgId, userId, isAppLoading, setCache,]);

    return cache;
}