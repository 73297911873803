import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {Radio, Space, Input, Collapse, Tag, Alert, Modal, Popover} from 'antd';

const {Panel} = Collapse;
import {CheckCircleOutlined, CloseCircleOutlined, CloseCircleFilled} from '@ant-design/icons';
import {Calendar} from "react-multi-date-picker"

import CheckInAlert from './CheckInAlert.js';
import DeskPool from './DeskPool.js';
import CheckInSurvey from './CheckInSurvey';
import UserTypePopup from './UserTypePopup.js';
import SkeletonLoader from 'components/Shared/SkeletonLoader.js';
import ButtonLoading from 'components/Shared/ButtonLoading.js';
import NotificationAlert from 'other/NotificationAlert.js';
import {mainContentContainerClass, safe_get, dateFormatterWithDayNoYear} from 'other/Helper.js';
import {CheckInUserType, CheckInStatus, CheckInLocale} from 'other/Constants.js';
import {
    selectDesk,
    selectLocale,
    selectDeskSpaceAndLocation,
    resetCheckIn,
    createCheckIn,
    updateCheckInState,
    updateCheckIn,
    selectSpace,
    selectLocation
} from 'store/slices/checkInOptions.js';
import {useDeskPool, useLocationSurveyLoader, useDirectoryLoader, useCheckInLoader} from 'hooks/spaces';
import Directory from './Directory.js';
import moment from 'moment';
import {update_check_in} from 'api/zero-api.js';


function generateCheckInUserText(userType, user, guestName) {
    if (userType === CheckInUserType.SELF) {
        return `Me - ${safe_get(user, "full_name", safe_get(user, "name", ""))}`;
    } else if (userType === CheckInUserType.USER) {
        return `Colleague - ${user.first_name} ${user.last_name}`;
    } else if (userType === CheckInUserType.GUEST) {
        return `Guest - ${guestName}`;
    }

    return '';
}

function generateSelectDeskHeader(selectedUserType, existingCheckIn) {
    if (existingCheckIn || selectedUserType === CheckInUserType.SELF) {
        return 'Where are you working today?';
    } else if (selectedUserType === CheckInUserType.USER) {
        return 'Where is your colleague working today?';
    } else {
        return 'Where is your guest working today?';
    }
}

const greetingText = () => {
    return dateFormatterWithDayNoYear(new Date());
}

function OfficeTag({locale}) {
    if (locale !== CheckInLocale.OFFICE) return null;

    return (
        <Tag color="green" className="text-normal" style={{marginLeft: '1rem'}}><CheckCircleOutlined/> Office</Tag>
    )
}

function DeskTag({desk}) {
    if (desk === null) return null;

    let deskName = desk.fullName || `${desk.space.location.name} / ${desk.space.name} / ${desk.name}`;
    return (
        <Tag color="green" className="text-normal" style={{marginLeft: '1rem'}}><CheckCircleOutlined/> {deskName}</Tag>
    )
}

const PanelKeys = {
    CHECK_IN: 1,
    SELECT_DESK: 2,
    SURVEY: 3
};

function CalendarPopup({
                           children, maxDays = 30, dates, onDatesChanged = () => {
    }, disabledDates = []
                       }) {
    const [calendarVisible, setCalendarVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [datesHaveChanged, setDatesHaveChanged] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const minDate = new Date();
    const maxDate = moment().add(maxDays, 'days').toDate();

    const saveCalendarDates = () => {
        const filteredSelectedDates = selectedDates.filter(date => !disabledDates.includes(date.format('YYYY-MM-DD')));
        if (filteredSelectedDates.length === 0) {
            setErrorMessage('You must select at least one date.');
        } else {
            setErrorMessage('');
            setCalendarVisible(false);
            setDatesHaveChanged(true);
            onDatesChanged(filteredSelectedDates.map(date => date.toDate()));
        }
    }

    const cancelCalendarDates = () => {
        setErrorMessage('');
        setCalendarVisible(false);
        setSelectedDates(datesHaveChanged ? dates : []);
    }

    const mapCalendarDays = ({date}) => {
        const disabled = disabledDates.includes(date.format('YYYY-MM-DD'));
        return {disabled}
    }

    const onOpenChange = visible => {
        if (visible) {
            setCalendarVisible(true);
        } else {
            cancelCalendarDates();
        }
    }

    return (
        <Popover
            trigger="click"
            open={calendarVisible}
            onOpenChange={onOpenChange}
            content={
                <div>
                    <Calendar
                        multiple
                        minDate={minDate}
                        maxDate={maxDate}
                        className="no-box-shadow"
                        value={selectedDates}
                        onChange={setSelectedDates}
                        mapDays={mapCalendarDays}
                    />
                    {errorMessage &&
                        <small className="error"
                               style={{display: 'block', margin: '-1rem 0 0.5rem'}}>{errorMessage}</small>
                    }
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button style={{fontSize: 12}} className="btn btn-discard btn-sm mar-rgt-5"
                                onClick={cancelCalendarDates}>Cancel
                        </button>
                        <button style={{fontSize: 12}} className="btn btn-primary btn-sm"
                                onClick={saveCalendarDates}>Save
                        </button>
                    </div>
                </div>
            }
        >
            {children}
        </Popover>
    )
}

export default function CheckIn() {
    const {checkInId} = useParams();
    const history = useHistory();
    const orgId = useSelector(state => state.org_helper.organization.organization_uuid);
    const user = useSelector(state => state.user.user);
    const checkInOptions = useSelector(state => state.checkInOptions);
    const dispatch = useDispatch();
    const {
        selectedLocale, selectedUserType, selectedDesk, surveyCompleted, guestName, selectedUser, createCheckInStatus
    } = checkInOptions;

    const [showDeskPool, setShowDeskPool] = useState(false);
    const [otherLocale, setOtherLocale] = useState('');
    const [checkInUserText, setCheckInUserText] = useState('');
    const [activePanel, setActivePanel] = useState(PanelKeys.CHECK_IN);
    const [existingCheckIn, setExistingCheckIn] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [accessError, setAccessError] = useState('');
    const [selectedDates, setSelectedDates] = useState([moment().startOf('day').toDate()]);
    const [checkInForToday, setCheckInForToday] = useState(true);
    const [disabledDates, setDisabledDates] = useState([]);
    const [selectedDisabledDate, setSelectedDisabledDate] = useState(false);
    const [declineCheckInAfterNavigate, setDeclineCheckInAfterNavigate] = useState(false);

    const deskPool = useDeskPool(selectedUserType, selectedUser, selectedDates);
    const surveyLoader = useLocationSurveyLoader(existingCheckIn);
    const [fetchDirectory, directoryLoader] = useDirectoryLoader();
    const checkInLoader = useCheckInLoader(checkInId);

    useEffect(() => {
        fetchDirectory();
        dispatch(updateCheckInState({selectedUser: user}));
        return () => {
            dispatch(resetCheckIn());
        }
    }, []);

    useEffect(() => {
        return () => {
            if (declineCheckInAfterNavigate) {
                const checkInId = checkInLoader?.data?.uuid;
                if (checkInId) {
                    update_check_in(checkInId, JSON.stringify({status: CheckInStatus.FAILED}));
                }
            }
        }
    }, [declineCheckInAfterNavigate, checkInLoader])

    useEffect(() => {
        if (deskPool.ok && selectedUser && selectedUser.preferred_location_uuid) {
            const location = deskPool.locations.find(location => location.uuid === selectedUser.preferred_location_uuid);

            if (location) {
                dispatch(selectLocation(location));

                if (selectedUser.preferred_space_uuid) {
                    const space = location.spaces.find(space => space.uuid === selectedUser.preferred_space_uuid);

                    if (space) {
                        dispatch(selectSpace(space));
                    }
                }
            }

        }
    }, [selectedUser, deskPool]);

    useEffect(() => {
        if (checkInLoader.ok) {
            const checkIn = checkInLoader.data;

            if (checkIn.user.uuid !== user.uuid) {
                dispatch(resetCheckIn());
                dispatch(updateCheckInState({selectedUser: user}));
                history.replace(`/${orgId}/checkin`);
            } else if (checkIn.status !== CheckInStatus.PENDING) {
                setAccessError('This check-in has already been completed or declined. To create a new check-in, please go back to the Dashboard.');
            } else if (checkIn.expires_at < Math.round(Date.now() / 1000)) {
                setAccessError('This check-in has expired. To create a new check-in, please go back to the Dashboard.');
            } else if (!checkIn.is_valid) {
                setAccessError('This desk is no longer available. To create a new check-in, please go back to the Dashboard.');
                setDeclineCheckInAfterNavigate(true);
            } else {
                setExistingCheckIn(checkIn);
                setActivePanel(PanelKeys.SURVEY);
                dispatch(updateCheckInState({
                    selectedUserType: checkIn.user_type,
                    selectedLocale: checkIn.locale,
                    selectedDesk: checkIn.desk,
                    surveyCompleted: checkIn.status === CheckInStatus.COMPLETED,
                    guestName: checkIn.guestName,
                    selectedUser: checkIn.user,
                    otherLocale: checkIn.otherLocale
                }));
            }
        }
    }, [checkInLoader])

    useEffect(() => {
        if (existingCheckIn) {
            setCheckInUserText(generateCheckInUserText(CheckInUserType.SELF, selectedUser, guestName));
        } else {
            setCheckInUserText(generateCheckInUserText(selectedUserType, selectedUser, guestName));
        }
    }, [selectedUser, selectedUserType, guestName]);

    useEffect(() => {
        if (!existingCheckIn) {
            setActivePanel(PanelKeys.CHECK_IN);
        }

        let newSelectedUser = null;
        if (selectedUserType === CheckInUserType.SELF) {
            newSelectedUser = user;
        } else if (selectedUserType === CheckInUserType.USER) {
            newSelectedUser = selectedUser;
        }

        dispatch(updateCheckInState({selectedUser: newSelectedUser}));
    }, [selectedUserType]);

    useEffect(() => {
        if (!existingCheckIn) {
            setActivePanel(PanelKeys.CHECK_IN);
            setSelectedDates([moment().startOf('day').toDate()]);
        }
    }, [selectedUser]);

    useEffect(() => {
        dispatch(selectDesk(null));
    }, [showDeskPool]);

    useEffect(() => {
        if (!existingCheckIn && selectedLocale === CheckInLocale.OFFICE) {
            setActivePanel(PanelKeys.SELECT_DESK);
        }
    }, [selectedLocale]);

    useEffect(() => {
        if (selectedDesk) {
            setActivePanel(PanelKeys.SURVEY);
        }
    }, [selectedDesk]);

    useEffect(() => {
        if (createCheckInStatus.ok) {
            let status = safe_get(createCheckInStatus.data, 'status', null);
            if (!status) status = safe_get(createCheckInStatus.data, 'today.status', null);

            if (!status || status !== CheckInStatus.FAILED) {
                let alertText = "You're checked in.";
                if (selectedUserType === CheckInUserType.USER) {
                    if (existingCheckIn) {
                        alertText = "You've completed check-in.";
                    } else {
                        alertText = "Your colleague has been checked in.";
                    }
                } else if (selectedUserType === CheckInUserType.GUEST) {
                    alertText = "Your guest has been checked in.";
                }
                NotificationAlert("success", "", alertText);
                history.push(`/${orgId}/home/dashboard`);
            }
        } else if (createCheckInStatus.error) {
            NotificationAlert("error", "", "Could not create check-in.");
            const error = createCheckInStatus.data[0];
            const {field, message, code} = error;
            if (field === 'future_dates' && code === 'dates-already-scheduled') {
                setErrorMessage((
                    <>
                        <span>{selectedUserType === CheckInUserType.SELF ? "You already have" : "Your colleague already has"} a check-in scheduled on: </span>
                        <span>{error.dates.map(date => moment(date, 'YYYY-MM-DD').format('MMM D')).join(', ')}</span>
                        <br/>
                        <span>Please update your date selection and try again.</span>
                    </>
                ))
            } else {
                setErrorMessage(message);
                dispatch(updateCheckInState({createCheckInStatus: {error: false}}));
            }
        } else if (createCheckInStatus.loading) {
            setErrorMessage('');
        }
    }, [createCheckInStatus]);

    useEffect(() => {
        const todaysDate = moment().startOf('day');
        setCheckInForToday(selectedDates.some(date => moment(date).startOf('day').isSame(todaysDate)));
        if (!existingCheckIn) {
            setActivePanel(PanelKeys.CHECK_IN);
            dispatch(selectLocale(null));
        }
    }, [selectedDates]);

    useEffect(() => {
        if (deskPool.disabledDates !== undefined) {
            let disabledDates = [];
            if (selectedUserType !== CheckInUserType.GUEST) {
                disabledDates = safe_get(deskPool, 'disabledDates', []);
                if (selectedUserType === CheckInUserType.SELF) {
                    disabledDates = disabledDates.filter(dateStr => dateStr !== moment().format('YYYY-MM-DD'));
                }

                if (selectedDates && selectedUserType === CheckInUserType.USER) {
                    setSelectedDisabledDate(selectedDates.map(date => moment(date).format('YYYY-MM-DD')).some(date => disabledDates.includes(date)));
                } else {
                    setSelectedDisabledDate(false);
                }
            }
            setDisabledDates(disabledDates);
        }
    }, [deskPool.disabledDates, selectedDates]);

    const changeLocale = event => {
        const newLocale = event.target.value;
        dispatch(selectLocale(newLocale));
    }

    const selectAssignedDesk = event => {
        const deskId = event.target.value;
        const desk = deskPool.assignedDesks.find(desk => desk.uuid === deskId);
        if (desk) {
            dispatch(selectDeskSpaceAndLocation(desk));
        }
    }

    const checkInDisabled = () => {
        if (createCheckInStatus.loading) return true;
        if (selectedDisabledDate && !existingCheckIn) return true;

        if (selectedLocale === CheckInLocale.OFFICE) {
            if (checkInForToday && (existingCheckIn || selectedUserType === CheckInUserType.SELF)) {
                return !surveyCompleted;
            } else {
                return selectedDesk === null;
            }
        } else {
            return selectedLocale === null;
        }
    }

    const completeCheckIn = () => {
        if (existingCheckIn) {
            dispatch(updateCheckIn({
                uuid: existingCheckIn.uuid,
                status: CheckInStatus.COMPLETED
            }));
        } else {
            dispatch(updateCheckInState({otherLocale}));
            dispatch(createCheckIn({dates: selectedDates}));
        }
    }

    const changeActivePanel = key => {
        if (!existingCheckIn && key) {
            setActivePanel(key)
        }
    }

    const showSurveyPanel = (selectedDesk !== null || existingCheckIn);

    const renderWhenClause = () => {
        if (selectedDisabledDate) {
            return <span className="blue-link">Select date</span>
        }

        if (selectedDates.length === 1) {
            const date = moment(selectedDates[0]).startOf('day');
            const today = moment().startOf('day');
            return <span
                className="zero-dark-grey text-normal">{date.isSame(today) ? 'Today - ': ''}{moment(selectedDates[0]).format('dddd, MMM D, YYYY ')}
                <span className="blue-link hover-cursor text-normal">(change)</span></span>
                
        }       

        const datesFormatted = (
            selectedDates
                .map(date => moment(date))
                .sort((a, b) => a.isBefore(b) ? -1 : 1)
                .map(date => date.format('M/D'))
                .join(', ')
        );

        return <span className="blue-link text-normal">{selectedDates.length} dates selected ({datesFormatted})</span>;
    }

    return (
        (<div className={mainContentContainerClass()}>
            {accessError &&
                <Modal
                    title={<span><CloseCircleOutlined
                        style={{marginRight: "5px", color: "#f56946"}}/>Access Error</span>}
                    open={accessError}
                    maskClosable={false}
                    closable={false}
                    footer={
                        <div style={{height: "33px"}}>
                            <button className="btn btn-modal"
                                    onClick={() => history.push(`/${orgId}/home/dashboard`)}>Back to Dashboard
                            </button>
                        </div>
                    }
                >
                    <p className="zero-dark-grey">{accessError}</p>
                </Modal>
            }
            <div id="page-head" className="no-padding-mobile" style={{paddingBottom: '10rem'}}>
                <CheckInAlert checkInPage style={{marginBottom: '1rem'}}/>
                <div className="panel thin-border zero-blue" style={{fontSize: 14}}>
                    {!deskPool.ok && deskPool.loading &&
                        <SkeletonLoader/>
                    }
                    {deskPool.error &&
                        <div>Could not load desk pool.</div>
                    }
                    {deskPool.ok &&
                        <div className="row">
                            <div className="col-lg-12">
                                <div style={{padding: '0 2rem'}}>
                                    <h3 className="zero-blue" style={{marginBottom: 0}}>Check-In</h3>

                                    {!existingCheckIn &&
                                        <div className="zero-blue text-semibold">Who: <UserTypePopup
                                            directoryLoader={directoryLoader}><span
                                            className="zero-dark-grey text-normal">{checkInUserText} </span><span
                                            className="blue-link hover-cursor text-normal">(change)</span></UserTypePopup>
                                        </div>
                                    }
                                    {existingCheckIn &&
                                        <div className="zero-blue text-semibold">Who: <span
                                            className="zero-dark-grey text-normal">Me - {existingCheckIn.user.full_name}</span></div>
                                    }
                                    {!existingCheckIn &&
                                        <>
                                            <div className="zero-blue text-semibold">
                                                When:&nbsp;
                                                <CalendarPopup
                                                    dates={selectedDates}
                                                    onDatesChanged={setSelectedDates}
                                                    disabledDates={disabledDates}
                                                >
                                                    {renderWhenClause()}
                                                </CalendarPopup>
                                            </div>
                                            {selectedDisabledDate &&
                                                <small className="error" style={{}}>Please select one or more dates
                                                    above.</small>
                                            }
                                        </>
                                    }
                                    {existingCheckIn &&
                                        <div
                                            className="zero-blue text-semibold">When: <span
                                            className="zero-dark-grey text-normal">Today - {moment().format('dddd, MMM D, YYYY')}</span></div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12" style={{marginTop: '2rem'}}>
                                <Collapse
                                    accordion
                                    activeKey={activePanel}
                                    onChange={changeActivePanel}
                                    bordered={false}
                                    style={{borderTop: '1px solid #d9d9d9'}}
                                    className="form-collapse"
                                >
                                    <Panel
                                        header={<span>Location<OfficeTag locale={selectedLocale}/></span>}
                                        key={PanelKeys.CHECK_IN}
                                        style={{background: 'white'}}
                                    >
                                        <div style={{padding: '0 0.4rem'}}>
                                            <p className="text-semibold">{generateSelectDeskHeader(selectedUserType, existingCheckIn)}</p>
                                            <Radio.Group onChange={changeLocale} value={selectedLocale}>
                                                <Space direction="vertical" size={4}>
                                                    <Radio className="zero-dark-grey"
                                                           value={CheckInLocale.OFFICE}>Office</Radio>
                                                    <Radio className="zero-dark-grey"
                                                           disabled={selectedUserType === CheckInUserType.GUEST}
                                                           value={CheckInLocale.REMOTE}>Remote</Radio>
                                                    <Radio className="zero-dark-grey"
                                                           disabled={selectedUserType === CheckInUserType.GUEST}
                                                           value={CheckInLocale.OTHER}>Other</Radio>
                                                </Space>
                                            </Radio.Group>
                                            {selectedLocale === CheckInLocale.OTHER &&
                                                <>
                                                    <Input
                                                        value={otherLocale}
                                                        onChange={e => setOtherLocale(e.target.value)}
                                                        maxLength={50}
                                                        style={{
                                                            marginTop: '1rem',
                                                            maxWidth: '50rem',
                                                            'display': 'block'
                                                        }}
                                                        placeholder="Add description (optional)"
                                                    />
                                                    <div className="text-sm zero-dark-grey" style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        paddingTop: '3px',
                                                        maxWidth: '50rem'
                                                    }}>
                                                        <p>Status will be visible on the directory</p>
                                                        <p>{50 - (otherLocale?.length || 0)}</p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Panel>
                                    {selectedLocale === CheckInLocale.OFFICE &&
                                        <Panel
                                            header={<span>Desk<DeskTag
                                                desk={existingCheckIn ? existingCheckIn.desk : selectedDesk}/></span>}
                                            key={PanelKeys.SELECT_DESK}
                                            style={{background: 'white'}}
                                        >
                                            <div style={{padding: '0 0.4rem'}}>
                                                {deskPool.assignedDesks.length > 0 &&
                                                    <>
                                                        {!showDeskPool &&
                                                            <>
                                                                <p className="text-semibold">
                                                                    {
                                                                        (existingCheckIn || selectedUserType === CheckInUserType.SELF)
                                                                            ? 'You have an assigned desk available.'
                                                                            : 'Your colleague has an assigned desk available.'
                                                                    }
                                                                </p>
                                                                <Radio.Group onChange={selectAssignedDesk}
                                                                             value={selectedDesk?.uuid}>
                                                                    <Space direction="vertical" size={4}>
                                                                        {
                                                                            deskPool.assignedDesks.map(desk => (
                                                                                <Radio key={desk.uuid}
                                                                                       value={desk.uuid}>{desk.name}</Radio>
                                                                            ))
                                                                        }
                                                                    </Space>
                                                                </Radio.Group>
                                                                <p style={{marginTop: '1rem'}}>
                                                                    <em>Need a different office or desk? </em>
                                                                    <span className="blue-link hover-cursor"
                                                                          onClick={() => setShowDeskPool(true)}>See available options.</span>
                                                                </p>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {(deskPool.assignedDesks.length === 0 || showDeskPool) &&
                                                    <>
                                                        <div className="row">
                                                            <div className="col-sm-6 col-xs-12">
                                                                <div className="panel"> 
                                                                    <DeskPool deskPool={deskPool}/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-sm-6 col-xs-12 check-in-directory-border">
                                                                <div className="panel">
                                                                    <div className="text-semibold">Directory</div>
                                                                    <div className="zero-dark-grey"
                                                                         style={{marginBottom: '1rem'}}>Enter a
                                                                        colleague's name to see their check-in details.
                                                                    </div>
                                                                    <Directory
                                                                        directoryLoader={directoryLoader}
                                                                        limit={20}
                                                                        allowSelection={false}
                                                                        hideUsersUntilSearch={true}
                                                                        showCheckin={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Panel>
                                    }
                                    {showSurveyPanel &&
                                        <Panel
                                            header="Survey"
                                            key={PanelKeys.SURVEY}
                                            style={{background: 'white'}}
                                        >
                                            <div style={{padding: '0 0.4rem'}}>
                                                <CheckInSurvey surveyLoader={surveyLoader}
                                                               existingCheckIn={existingCheckIn}
                                                               selectedUserType={selectedUserType}
                                                               checkInForToday={checkInForToday}/>
                                                {/*moved into CheckInSurvey*/}
                                                {/*{(existingCheckIn || (selectedUserType === CheckInUserType.SELF && checkInForToday)) &&*/}
                                                {/*    <CheckInSurvey surveyLoader={surveyLoader}/>*/}
                                                {/*}*/}
                                                {/*{(selectedUserType === CheckInUserType.SELF && !checkInForToday) &&*/}
                                                {/*    <div>You will need to complete a check-in survey for each day that*/}
                                                {/*        you come to the office. You will receive an email notification*/}
                                                {/*        to complete the survey on the selected dates.</div>*/}
                                                {/*}*/}
                                                {/*{(!existingCheckIn && selectedUserType === CheckInUserType.USER) &&*/}
                                                {/*    <div>Your colleague will need to complete a check-in survey in order*/}
                                                {/*        to come to the office. Your colleague will receive an email*/}
                                                {/*        notification to complete the survey.</div>*/}
                                                {/*}*/}
                                                {/*{*/}
                                                {/*    selectedUserType === CheckInUserType.GUEST &&*/}
                                                {/*    <div>Your guest will need to complete a check-in survey in order to*/}
                                                {/*        come to the office.</div>*/}
                                                {/*}*/}
                                            </div>
                                        </Panel>
                                    }
                                </Collapse>
                            </div>
                            <div className="col-lg-12" style={{marginTop: '3rem'}}>
                                {errorMessage &&
                                    <Alert
                                        message={errorMessage}
                                        type="error"
                                        showIcon
                                        icon={<div style={{alignSelf: 'flex-start'}}><CloseCircleFilled color="red"/>
                                        </div>}
                                        style={{margin: '0 1.5rem'}}
                                    />
                                }
                                <div className="pad-15" style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <button className="btn btn-discard"
                                            onClick={() => history.replace(`/${orgId}/home/dashboard`)}>Cancel
                                    </button>
                                    <button className="btn btn-primary" disabled={checkInDisabled()}
                                            onClick={completeCheckIn}>
                                        {
                                            createCheckInStatus.loading ? <ButtonLoading/> : 'Check In'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>)
    );
}