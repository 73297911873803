
import { deleteBulletinPost, getBulletinPost, make_bulletin, update_bulletin, get_post_drafts, addAttachmentToPost, deletePostAttachments } from "api/zero-api";
import DirectoryCache from "offline/DirectoryCache";
import InitialDataCache from "offline/InitialDataCache";
import { pouchDbGet, pouchDbGetAll, pouchDbUpsert } from 'offline/pouchDbUtils';
import { DebugLogger } from "other/DebugLogger";
import { LocalStorageHelper, extractErrorMessages, generateUUID, getCurrentTimestamp, getErrorMessageFromResponse, getReduxState, indexArrayUsingProperty, isOfflineAllowed, simpleArraysMatch, withRetry } from "other/Helper";
import { getLocalDbService } from "./localDb";
import { uploadPostAttachment } from "offline/utils";
import pLimit from "p-limit";
import { useCallback, useEffect, useState, useMemo } from "react";
import debounce from 'lodash/debounce';
import { AxiosError } from "axios";
import BaseService from "./baseServices";
import { syncSubmissionDrafts } from "offline/SubmissionDraftsCache";
import { FormType } from "other/Constants";


const debugLogger = new DebugLogger('Post Service');
const useBroadcastChannel = window.BroadcastChannel !== undefined;
/** @type {PouchDB.Core.Changes | undefined} */
let dbChangesSubscription;

export const PostEvents = {
    POST_ID_CHANGE: 'post-id-change',
    LOCAL_POST_DELETED: 'local-post-deleted',
    LOCAL_POST_SAVED: 'local-post-saved',
    POSTS_SYNCED: 'posts-synced',
    IS_SYNCING_CHANGE: 'is-syncing-change',
    ATTACHMENT_PROCESSED: 'attachment-processed',
};

/**
 * @param {PostService} postService 
 * @param {PostSvcGetLocalPostsOptions & {debounceMs?: number}} [options]
 * @returns {{drafts: LocalPost[], refresh: () => {}}}
 */
export function useCachedPostDrafts(postService, options) {
    const [drafts, setDrafts] = useState([]);
    const {debounceMs, ...getLocalPostsOptions} = options;


    const loadPosts = useCallback(async () => {
        setDrafts(await postService.getLocalPosts(getLocalPostsOptions));
    }, [postService]);

    const debouncedLoadPosts = useMemo(() => debounce(() => loadPosts(), debounceMs ?? 1000), [loadPosts]);

    useEffect(() => {
        loadPosts();

        const unsubscribe = postService.subscribe((ev) => {
            if (ev.data.type === PostEvents.LOCAL_POST_SAVED) {
                debouncedLoadPosts();
            }
        });
        return () => { unsubscribe() };
    }, [postService, loadPosts]);

    return {
        drafts,
        refresh: () => {loadPosts()},
    };
}

export class PostService extends BaseService {
    /**
     * Initializes the PostService singleton
     * @param {string} orgId 
     * @param {string} userId 
     * @param {boolean} isOffline 
     * @returns {PostService}
     */
    init(orgId, userId, isOffline) {
        this.offlineAllowed = isOfflineAllowed();
        this.isOffline = isOffline;

        if (this.orgId === orgId && this.userId === userId) {
            // We've already initialized with these same values
            return this;
        }

        this.orgId = orgId;
        this.userId = userId;

        this.localDbService = getLocalDbService(orgId, userId);
        this.db = this.localDbService.db;

        if (dbChangesSubscription) {
            dbChangesSubscription.cancel();
        }

        dbChangesSubscription = this.db.changes({
            since: 'now',
            live: true,
            include_docs: true,
        }).on('change', change => {
            if (change.id.startsWith('post:')) {
                /** @type {LocalPost} */
                const post = change.doc;
                if (post?.post_uuid) {
                    this.dispatchLocalPostSaved(post.post_uuid);
                }
            }
        });

        if (useBroadcastChannel) {
            this.channel = new BroadcastChannel("post-service");
        } else {
            this.channel = new EventTarget();
        }

        /** @type {Set<string>} */
        this.draftsCurrentlySyncing = new Set();
        this.isBulkSyncing = false;
        this.isSyncing = false;

        this.attachmentQueue = [];
        this.isSavingAttachments = false;
        
        this.objectUrls = {};
        window.zeroDebugPostService = this;
        
        return this;
    }

    //////////////////////////////////////
    // SECTION: Event dispatch functions
    //////////////////////////////////////

    dispatchPostIdChange(oldId, newId) {
        this.dispatch({
            type: PostEvents.POST_ID_CHANGE,
            oldId,
            newId,
        });
    }

    dispatchLocalPostDeleted(localId) {
        this.dispatch({
            type: PostEvents.LOCAL_POST_DELETED,
            localId
        });
    }

    dispatchLocalPostSaved(localId) {
        this.dispatch({
            type: PostEvents.LOCAL_POST_SAVED,
            localId,
        });
    }

    dispatchIsSyncingChange(isSyncing) {
        this.dispatch({
            type: PostEvents.IS_SYNCING_CHANGE,
            isSyncing
        })
    }

    dispatchPostsSynced() {
        this.dispatch({
            type: PostEvents.POSTS_SYNCED,
        });
    }

    dispatchAttachmentProcessed(attachmentId, error = null) {
        this.dispatch({
            type: PostEvents.ATTACHMENT_PROCESSED,
            attachmentId,
            error,
        })
    }

    dispatch(data) {
        const dlog = debugLogger.branch("dispatch");
        dlog("data:", data);

        const event = new CustomEvent("message");
        // make it match broadcast channel event interface
        event.data = data;
        this.channel.dispatchEvent(event);

        if (useBroadcastChannel) {
            this.channel.postMessage(data);
        }
    }

    /**
     * 
     * @param {function(Event & {data: {type: string}}):void} callback 
     * @returns {function(): void} unsubscribe callback
     */
    subscribe(callback) {
        this.channel.addEventListener("message", callback);

        return () => {
            this.channel.removeEventListener("message", callback);
        }
    }

    //////////////////////////////////////
    // SECTION: Other cache helpers
    //////////////////////////////////////

    getInitialDataCache() {
        return new InitialDataCache(this.orgId, this.userId);
    }

    getDirectoryCache() {
        return new DirectoryCache(this.orgId);
    }

    async getPostOptions(onlyEnabled) {
        const options = await this.localDbService.getPostOptions();

        const excludeDisabledOptions = (property) => {
            if (options[property]) {
                options[property] = options[property].filter(o => o.enabled);
            }
        }
        
        if (onlyEnabled) {
            // exclude disabled options
            excludeDisabledOptions('categories');
            excludeDisabledOptions('tags');
            excludeDisabledOptions('sub_statuses');
            excludeDisabledOptions('sub_statuses_2');
        }

        return options;
    }

    /**
     * @param {string} teamId 
     */
    async getAvailableSubscribersAndResponders(teamId) {
        const dlog = debugLogger.branch('getAvailableSubscribersAndResponders');
        dlog('teamId:', teamId);
        const directory = await this.getDirectoryCache().getAll();
        const teamMembers = await this.localDbService.getTeamMemberships(teamId);
        dlog('teamMembers:', teamMembers);
        const availableSubscribers = directory.filter(user => teamMembers.includes(user.uuid));
        const availableResponders = availableSubscribers.filter(user => user.role !== 'viewer');
        return {
            availableSubscribers,
            availableResponders,
        };
    }

    //////////////////////////////////////
    // SECTION: Debug helpers
    //////////////////////////////////////

    async _debug_deleteLocalPosts() {
        const docs = await this.getLocalPosts({skipObjectUrls: true});
        for (const doc of docs) {
            await this.db.remove(doc);
        }
    }

    //////////////////////////////////////
    // SECTION: Post loaders
    //////////////////////////////////////

    /**
     * 
     * @param {PostSvcGetLocalPostsOptions} [options]
     * @returns 
     */
    async getLocalPosts(options) {
        /** @type {LocalPost[]} */
        let posts = await pouchDbGetAll(this.db, {startkey: "post:", endkey: "post:\uffff", attachments: true});

        if (!options?.includeDeleted) {
            posts = posts.filter(post => !post.$meta?.deleted);
        }

        if (options?.onlyDrafts) {
            posts = posts.filter(post => post.status === 'draft');
        }

        posts.sort((a, b) => a.revised_at - b.revised_at).reverse();

        if (options?.skipObjectUrls) {
            return posts;
        }

        // replace attachment urls with local blob urls
        return await Promise.all(posts.map(async post => await addBlobUrlToPostAttachments(this.db, post, this.objectUrls)));
    }

    /**
     * @param {string} postId 
     * @param {{throwIfMissing: [boolean]}} [options]
     * @returns {Promise<LocalPost>}
     */
    async getLocalPost(postId, { throwIfMissing } = {}) {
        const post = await pouchDbGet(this.db, getLocalPostId(postId), null);
        if (post === null) {
            if (throwIfMissing) {
                throw new Error(`Could not find local post ${postId}`);
            } else {
                return null;
            }
        } else {
            return await addBlobUrlToPostAttachments(this.db, post, this.objectUrls);
        }
    }

    /**
     * @param {string} postId 
     * @returns {Promise<(Post | null)>}
     */
    async getRemotePost(postId) {
        const remotePostId = getRemotePostId(postId);
        if (remotePostId.startsWith('offline:')) {
            throw new Error(`${postId} is not a remote post ID`);
        }
        const res = await getBulletinPost(remotePostId);
        const data = await res.json();
        return data.post ?? null;
    }

    /**
     * Gets a post from local or remote source
     * @param {string} postId 
     * @returns {Promise<Post>}
     */
    async getPost(postId) {
        debugLogger.log(`getPost ${postId}`);

        /** @type {LocalPost} */
        const localPost = await this.getLocalPost(postId);
        debugLogger.log('getPost localPost %o', localPost);

        if (this.isOffline || localPost?.$meta.offline) {
            if (localPost === null) {
                throw new Error(`Local post ${postId} does not exist`);
            }
            return localPost;
        }

        const res = await getBulletinPost(postId);
        const data = await res.json();
        const post = data.post ?? {};
        debugLogger.log('getPost remote post %o', post);

        return await this.cacheDraft(postId, post, localPost);
    }

    //////////////////////////////////////
    // SECTION: Post caching
    //////////////////////////////////////

    /**
     * @param {string} postId 
     * @param {Post} remotePost 
     * @param {LocalPost} [localPost]
     * @returns {Post}
     */
    async cacheDraft(postId, remotePost, localPost) {
        const dlog = debugLogger.branch('cacheDraft')
        if (!this.shouldCachePost(remotePost)) {
            // this post should not be cached
            dlog(`Not caching post ${postId}`);
            return remotePost;
        }

        if (localPost === undefined) {
            localPost = await this.getLocalPost(postId, {throwIfMissing: false});
        }
        
        if (localPost?._id) {
            // already have a cached version
            dlog(`Need to update local post with remote data. ID: ${postId}`);
            localPost = await this.updateLocalPostUsingRemotePost(localPost, remotePost);
        } else {
            // don't have a cached version
            dlog(`Caching post ${postId}`);
            remotePost.$meta = {}
            await pouchDbUpsert(this.db, getLocalPostId(postId), remotePost);
        }
        await this.downloadAttachmentsForPost(postId);
        return this.getLocalPost(postId);
    }

    /**
     * 
     * @param {LocalPost} localPost 
     * @param {Post} remotePost 
     * @returns {LocalPost}
     */
    async updateLocalPostUsingRemotePost(localPost, remotePost) { 
        const dlog = debugLogger.branch('updateLocalPostUsingRemotePost');
        /*

        Four scenarios to handle:

        1. local post has a new value, diff value equals original post value
            Do nothing to local post here, local post value has not been synced yet
        2. local post value has not been changed, diff value does not match
            Remote post was updated after local post was synced, update local post value
        3. local post value has changed, diff has also changed, they don't match
            This is a sync conflict, how to handle TBD
        4. local post value has changed, diff has also changed, they match
            Nothing to do here

        */
        dlog('localPost:', localPost);

        const diffOfLocalAndRemote = await this.getDiffResultsForPost(localPost, remotePost);
        dlog('diffOfLocalAndRemote:', diffOfLocalAndRemote);

        // Need to catch case where attachment has been uploaded and saved remotely
        // The data comes back with an attachment_uuid that looks new, current attachment won't
        // have an attachment_uuid. BE should be altered to return the file_path

        const diffOfRemoteAndOriginal = await this.getDiffResultsForPost(localPost.$meta.originalPost ?? localPost, remotePost);
        dlog('diffOfRemoteAndOriginal', diffOfRemoteAndOriginal);
        if (diffOfRemoteAndOriginal === null) {
            return;
        }
        const diffOfLocalAndOriginal = await this.getDiffResultsForPost(localPost, localPost.$meta.originalPost ?? localPost);
        dlog('diffOfLocalAndOriginal', diffOfLocalAndOriginal);
        const combinedLocalDiff = generateCombinedDiff(localPost, diffOfLocalAndOriginal) ?? {};
        dlog('combinedLocalDiff', combinedLocalDiff);

        const keysToUpdate = [];
        const keysWithConflicts = [];

        for (const [key, val] of Object.entries(diffOfRemoteAndOriginal)) {
            // TODO: team_uuid changes should be handled first, as it will probably change subscribers/assignments
            dlog("%s: %o", key, val);
            if (key === 'embedded_forms') {
                // ignore
            } else if (key === 'embedded_forms_hash') {
                keysToUpdate.push('embedded_forms_hash', 'embedded_forms');
            } else if (key in combinedLocalDiff) {
                // local and remote have both changed this value
                // check for scenarios 3 + 4
                dlog(`Need to compare "${key}" field on local post to ${val}`);
                const localValue = combinedLocalDiff[key].local;
                if (postValuesMatch(localValue, val, key)) {
                    dlog(`Local and remote values match for "${key}"`);
                    continue;
                }
                dlog(`sync conflict on "${key}" field - local %o - remote %o`, localValue, val);
                keysWithConflicts.push(key);
            } else {
                let actualKey;
                switch (key) {
                    case 'assignIds':
                        actualKey = 'assigns';
                        break;
                    case 'subscriberIds':
                        actualKey = 'subscribers';
                        break;
                    default:
                        actualKey = key;
                }

                dlog(`Would update "${actualKey}" field on local post to ${val}`);
                keysToUpdate.push(actualKey);
            }
        }

        dlog('final diff results:', {
            keysToUpdate,
            keysWithConflicts
        });

        if (keysWithConflicts.length > 0) {
            localPost.$meta.error = {
                type: 'conflict',
                keysWithConflicts
            }

            return await this.saveLocalPost(localPost);
        }

        if (keysToUpdate.length === 0) {
            return localPost;
        }

        for (const key of keysToUpdate) {
            dlog("updating %s with %o", key, remotePost[key])

            localPost[key] = remotePost[key];
        }

        localPost.revised_at = remotePost.revised_at;
        localPost.$meta.originalPost = remotePost;

        return this.saveLocalPost(localPost);
    }

    /**
     * @param {Post} post 
     * @returns {boolean}
     */
    shouldCachePost(post) {
        return post.status === 'draft';
    }

    /**
     * @param {LocalPost} localPost 
     * @param {Post} remotePost 
     * @returns {Promise<PostDiff>}
     */
    async getDiffResultsForPost(localPost, remotePost) {
        const dlog = debugLogger.branch('getDiffResultsForPost')
        const data = convertRemotePostToPostUpdateBody(remotePost);
        dlog('data:', data);
        const postOptions = await this.localDbService.getPostOptions();
        const diff = generatePostDiff(localPost, data, postOptions);
        dlog('diff:', diff);
        return diff;
    }

    //////////////////////////////////////
    // SECTION: Post creation
    //////////////////////////////////////

    /**
     * @param {string} teamId 
     * @returns {Promise<Post>}
     */
    async createPostDraftObject(teamId) {
        const { teams, organization, user } = await this.getInitialDataCache().get();
        const team = teams.find(t => t.uuid === teamId);
        if (team === undefined) {
            throw new Error("Could not find team in teams cache.");
        }
        
        const {availableSubscribers} = await this.getAvailableSubscribersAndResponders(teamId);
        let additionalSubscribers = [];
        if (team.notify_all_when_posted) {
            additionalSubscribers = availableSubscribers.filter(sub => sub.uuid !== user.uuid ).map(convertInitialDataUserToPostUser);
        }

        const { custom_post_field: customPostField } = organization;
        let customFieldName = "";
        if (customPostField.enabled) {
            customFieldName = customPostField.name;
        }

        const myPostUser = convertInitialDataUserToPostUser(user);

        /** @type {Post} */
        const newPost = {
            assigns: [myPostUser],
            attachments: [],
            auto_close: false,
            body: "",
            can_generate_notification_report: true,
            category: {},
            closed_at: null,
            closed_time_ago: "",
            comments_count: 0,
            coordinates: { lat: "", lon: "" },
            created_time_ago: "just now",
            custom_field_name: customFieldName,
            custom_field_value: "",
            due_date: null,
            has_been_updated: false,
            is_shared: false,
            is_urgent: false,
            links: [],
            location: "",
            post_uuid: `offline:${generateUUID()}`,
            reactions: [],
            reactions_enabled: team.post_reactions_enabled,
            reference_number: generatePostReferenceNumber(),
            revised_at: getCurrentTimestamp(),
            revised_by: myPostUser,
            severity_level: 0,
            shared_teams: [],
            source_team: {
                team_uuid: team.uuid,
                name: team.name,
                minimum_role_to_post: team.minimum_role_to_post,
            },
            status: "draft",
            sub_status: {},
            sub_status_2: {},
            subscribers: [myPostUser, ...additionalSubscribers],
            subscribers_count: 0,
            tag: {},
            title: "",
            viewers_count: 0,
            voice_notifications: false,
            $meta: {
                offline: true,
            }
        };

        return newPost;
    }

    /**
     * @param {string} postId 
     * @param {string} teamId
     * @returns {PostEmbeddedForm[]}
     */
    async createEmbeddedForms(postId, teamId) {
        const dlog = debugLogger.branch('createEmbeddedForms');
        const state = getReduxState();
        const orgEmbeddedFormId = state?.org_helper?.organization?.post_embedded_form_uuid ?? null;

        dlog('orgEmbeddedFormId:', orgEmbeddedFormId);
        if (!orgEmbeddedFormId) {
            return [];
        }

        const formService = this.getServices().forms;
        const form = await formService.getForm(orgEmbeddedFormId);
        dlog('form:', form);
        if (!form) {
            // Should we return something to indicate we know there is a custom form, but can't load it?
            return [];
        }
        const submissionId = await formService.createDraft(orgEmbeddedFormId, teamId, FormType.POST);
        await formService.drafts.updateDraft(submissionId, {
            parent_post_uuid: postId,
        });

        return [{
            post_uuid: postId,
            form_uuid: orgEmbeddedFormId,
            submission_uuid: submissionId,
            form_fields: form?.fields ?? null,
        }]
    }

    /**
     * Creates a new post draft and saves locally
     * @param {string} teamId 
     */
    async createNewPost(teamId) {
        debugLogger.log('Creating new post with team ID %s', teamId)
        const postData = await this.createPostDraftObject(teamId);
        const embeddedForms = await this.createEmbeddedForms(postData.post_uuid, teamId);
        postData.embedded_forms = embeddedForms;
        return await this.saveLocalPost(postData);
    }

    //////////////////////////////////////
    // SECTION: Post saving
    //////////////////////////////////////

    /**
     * @param {Post} post
     * @returns {Promise<LocalPost>}
     */
    async saveLocalPost(post) {
        const localPostId = getLocalPostId(post);
        debugLogger.log('Saving local post ID %s: %o', localPostId, post);
        await pouchDbUpsert(this.db, localPostId, post);
        return await pouchDbGet(this.db, localPostId);
    }

    /**
     * 
     * @param {string} postId
     * @param {boolean} isDraft
     * @param {PostUpdateBody} data
     * @param {object} [options] 
     * @param {boolean} [options.syncAfterSave] 
     * @param {function} [options.postSaveCallback] 
     * @returns 
     */
    async updatePost(postId, isDraft, data, options) {
        const dlog = debugLogger.branch('updatePost');

        if (!isDraft) {
            // handle non-draft update
            const response = await update_bulletin(postId, JSON.stringify(data));
            const {post} = await response.json();
            return post;
        }

        const post = await this.getLocalPost(postId, {throwIfMissing: true});
        const postOptions = await this.localDbService.getPostOptions();
        
        const directory = await this.getDirectoryCache().getAll();
        dlog('directory: %o', directory);

        const diff = generatePostDiff(post, data, postOptions);

        let sourceTeam = post.source_team;

        if (diff?.team_uuid) {
            const { teams } = await this.getInitialDataCache().get();
            const team = teams.find(t => t.uuid === diff.team_uuid);
            if (!team) {
                throw new Error(`Could not find team ${diff.team_uuid}`);
            }

            sourceTeam = {
                team_uuid: team.uuid,
                name: team.name,
                minimum_role_to_post: team.minimum_role_to_post,
            }
        }

        const teamMembers = await this.localDbService.getTeamMemberships(sourceTeam.team_uuid);
        dlog('teamMembers: %o', teamMembers);

        const availableSubscribers = directory.filter(user => teamMembers.includes(user.uuid));
        const availableResponders = availableSubscribers.filter(user => user.role !== 'viewer');
        dlog('availableSubscribers: %o', availableSubscribers);

        const availableSubscribersMapping = indexArrayUsingProperty(availableSubscribers, 'uuid');
        dlog('availableSubscribersMapping: %o', availableSubscribersMapping);
        const availableRespondersMapping = indexArrayUsingProperty(availableResponders, 'uuid');

        const newSubscribers = getNewRespondersOrSubscribers(diff?.subscriberIds, availableSubscribersMapping);
        dlog('newSubscribers: %o', newSubscribers);
        if (newSubscribers !== null) {
            diff.subscribers = newSubscribers;
            delete diff.subscriberIds;
        }

        const newResponders = getNewRespondersOrSubscribers(diff?.assignIds, availableRespondersMapping);
        if (newResponders !== null) {
            diff.assigns = newResponders;
            delete diff.assignIds;
        }
        
        
        return await withRetry(5, 100, async () => {
            const post = await this.getLocalPost(postId, {throwIfMissing: true});
            post.source_team = sourceTeam;
            const attachmentIdsToRemove = getUnusedAttachmentIds(post, diff);
            // take diff data and apply it to post
            modifyPostUsingDiff(post, diff);
            if (data.commit) {
                post.$meta.submitted = true;
            }
            const remoteAttachmentIdsToRemove = attachmentIdsToRemove.filter(id => !id.startsWith('offline:'));
            post.$meta.deletedAttachmentIds = Array.from(new Set([
                ...(post.$meta?.deletedAttachmentIds ?? []),
                ...remoteAttachmentIdsToRemove
            ]));
            post.revised_at = getCurrentTimestamp();
            let newPost = await this.saveLocalPost(post);

            for (const attachmentId of attachmentIdsToRemove) {
                withRetry(3, 100, async () => {
                    const post = await this.getLocalPost(newPost._id, {throwIfMissing: true});
                    if (!attachmentId.startsWith('offline:')) {
                        if (!post.$meta.deletedAttachmentIds) {
                            post.$meta.deletedAttachmentIds = [attachmentId];
                        } else {
                            post.$meta.deletedAttachmentIds.push(attachmentId);
                        }
                    }
                    await this.db.removeAttachment(post._id, attachmentId, post._rev);
                });
            }

            if (options?.syncAfterSave) {
                const {localPost} = await this.syncLocalPostToRemote(post._id);
                newPost = localPost;
            } else if (attachmentIdsToRemove.length > 0) {
                // make sure we have the latest post revision
                newPost = await this.getLocalPost(newPost._id, {throwIfMissing: true});
            }
    
            return newPost;
        });
    }

    //////////////////////////////////////
    // SECTION: Post deletion
    //////////////////////////////////////
    
    async deletePostDraft(postId, {reverse, force} = {reverse: false, force: false}) {
        const localPost = await this.getLocalPost(postId, {throwIfMissing: true});
        if (localPost.status !== 'draft') {
            throw new Error('Cannot use deletePostDraft to delete non-draft post');
        }

        if (reverse) {
            localPost.$meta.deleted = false;
            await pouchDbUpsert(this.db, localPost._id, localPost);
        } else {
            if (localPost.$meta.offline || force) {
                // offline post, no need to do anything on remote
                await this.cleanupEmbeddedForms(localPost);
                await this.db.remove(localPost);
            } else {
                // post exists on remote, mark as deleted for later sync
                localPost.$meta.deleted = true;
                await pouchDbUpsert(this.db, localPost._id, localPost);
            }
        }

    }

    async cleanupEmbeddedForms(post) {
        const formService = this.getServices().forms;
        for (const pef of (post.$meta.unsyncedEmbeddedForms ?? [])) {
            await formService.drafts.delete(pef.submission_uuid, { immediate: true, includeAttachments: true });
        }
        for (const pef of (post.embedded_forms ?? [])) {
            await formService.drafts.delete(pef.submission_uuid, { includeAttachments: true });
        }
    }
    //////////////////////////////////////
    // SECTION: Attachments
    //////////////////////////////////////

    /**
     * 
     * @param {string} postId 
     * @param {File} file 
     * @returns {Promise<Attachment>}
     */
    async addAttachmentToPost(postId, file) {
        const dlog = debugLogger.branch('addAttachmentToPost');

        const attachmentId = `offline:${generateUUID()}`;
        dlog('attachmentId: %o', attachmentId);

        // create object url for file and store in objectUrls map
        const url = URL.createObjectURL(file);
        dlog('url: %o', url);

        // create attachment metadata
        const attachment = {
            attachment_uuid: attachmentId,
            file_name: file.name,
            mime_type: file.type,
            public_url: url,
            thumbnails: {
                small: url,
                medium: url,
            },
        };
        dlog('attachment: %o', attachment);

        const promise = new Promise((resolve, reject) => {
            const unsubscribe = this.subscribe(ev => {
                if (ev.data.type === PostEvents.ATTACHMENT_PROCESSED) {
                    if (ev.data.attachmentId === attachmentId) {
                        unsubscribe();
                        if (ev.data.error) {
                            reject(ev.data.error);
                        } else {
                            resolve(attachment);
                        }
                    }
                }
            });
        });

        this.attachmentQueue.push([postId, file, attachmentId, url]);
        if (!this.isSavingAttachments) {
            this.isSavingAttachments = true;

            setTimeout(async () => {
                while (this.attachmentQueue.length > 0) {
                    try {
                        const [postId, file, attachmentId, url] = this.attachmentQueue.splice(0, 1)[0];
                        // get local post doc
                        const post = await this.getLocalPost(postId, {throwIfMissing: true});
                        dlog('post: %o', post);

                        if (post.status !== 'draft') {
                            throw new Error('Can only use this function for draft posts.')
                        }

                        if (post.attachments.length === 15) {
                            throw new Error('Maximum of 15 attachments allowed.')
                        }

                        // generate attachment ID and add blob to DB
                        await withRetry(5, 100, async () => {
                            const post = await this.getLocalPost(postId, {throwIfMissing: true});
                            await this.db.putAttachment(post._id, attachmentId, post._rev, file, file.type);
                        });

                        // add attachment metadata to post attachments and save
                        await withRetry(5, 100, async () => {
                            const post = await this.getLocalPost(postId, {throwIfMissing: true});
                            post.$meta.updated = true;
                            post.attachments.push(attachment);
                            await pouchDbUpsert(this.db, post._id, post);
                            dlog('post.attachments updated');
                        });

                        this.objectUrls[attachmentId] = url;
                        this.dispatchAttachmentProcessed(attachmentId);
                    } catch (err) {
                        console.error(err);
                        this.dispatchAttachmentProcessed(attachmentId, err);
                    }
                }
                this.isSavingAttachments = false;
            }, 250);
        }

        return promise;
    }

    async attachCloneAttachments(postId) {
        const dlog = debugLogger.branch('attachCloneAttachments');
        dlog('postId:', postId);

        let post = await this.getLocalPost(postId, {throwIfMissing: true});
        dlog('post:', post);
        const attachments = post.attachments.filter(att => att.needs_clone);
        dlog('attachments:', attachments);

        const limit = pLimit(10);
        const queue = attachments.map(attachment => limit(async () => {
            const result = {
                success: false,
                attachmentId: attachment.attachment_uuid,
                newAttachment: null
            };

            try {
                /** @type {Attachment} */
                const newAttachment = await withRetry(3, 100, async () => {
                    const res = await addAttachmentToPost(post.post_uuid, JSON.stringify(attachment));
                    if (!res.ok) {
                        const errorMessage = await getErrorMessageFromResponse(res);
                        throw new Error(`Could not add attachment: ${errorMessage}`);
                    }
                    return res.json();
                });
    
                result.success = true;
                result.newAttachment = newAttachment;
            } catch (err) {
                console.error("failed to attach clonable post attachment:", err);
            } finally {
                return result;
            }
        }));

        const results = await Promise.all(queue);
        post = await this.getLocalPost(postId, {throwIfMissing: true});
        const originalRev = post._rev;

        for (const result of results) {
            if (result.success) {
                dlog('result:', result);
                dlog('post:', post);
                const attToRemove = post.attachments.findIndex(att => att.attachment_uuid === result.attachmentId);
                dlog('attToRemove:', attToRemove);
                if (attToRemove >= 0) {
                    const removedAttachments = post.attachments.splice(attToRemove, 1);
                    dlog('removedAttachments:', removedAttachments);
                    const removeResult = await this.db.removeAttachment(post._id, removedAttachments[0].attachment_uuid, post._rev);
                    post._rev = removeResult?.rev ?? post._rev;

                    const objUrl = this.objectUrls[result.attachmentId];
                    if (objUrl) {
                        URL.revokeObjectURL(objUrl);
                        delete this.objectUrls[result.attachmentId];
                    }
                }
                post.attachments.push(result.newAttachment);
                post.$meta.updated = true;
            }
        }

        if (post._rev !== originalRev) {
            const {_attachments, _rev} = await this.getLocalPost(post, {throwIfMissing: true});
            post._attachments = _attachments,
            post._rev = _rev;
        }

        return this.saveLocalPost(post);
    }

    /**
     * 
     * @param {string} postId
     */
    async uploadOfflineAttachments(postId) {
        const dlog = debugLogger.branch('uploadOfflineAttachments');
        dlog('postId:', postId);

        let post = await this.getLocalPost(postId, {throwIfMissing: true});
        dlog('post:', post);
        const attachments = post.attachments.filter(att => att.attachment_uuid?.startsWith('offline:'));
        dlog('attachments:', attachments);
        const limit = pLimit(10);
        const queue = attachments.map(attachment => limit(async () => {
            const result = {
                success: false,
                offlineAttachmentId: attachment.attachment_uuid,
                newAttachment: null
            };

            try {
                let uploadedAttachment;
                /** @type {Attachment} */
                const newAttachment = await withRetry(3, 100, async () => {
                    const blob = await this.db.getAttachment(post._id, attachment.attachment_uuid);
                    if (!blob) {
                        throw new Error(`Could not find blob for attachment ${attachment.attachment_uuid} in post ${post._id}`);
                    }
                    if (!uploadedAttachment) {
                        uploadedAttachment = await uploadPostAttachment(post.post_uuid, attachment, blob);
                    }
                    const res = await addAttachmentToPost(post.post_uuid, JSON.stringify(uploadedAttachment));
                    if (!res.ok) {
                        const errorMessage = await getErrorMessageFromResponse(res);
                        throw new Error(`Could not add attachment: ${errorMessage}`);
                    }
                    return res.json();
                });
    
                result.success = true;
                result.newAttachment = newAttachment;
            } catch (err) {
                console.error("failed to upload offline post attachment:", err);
            } finally {
                return result;
            }
        }));

        const results = await Promise.all(queue);
        post = await this.getLocalPost(postId, {throwIfMissing: true});
        const originalRev = post._rev;

        for (const result of results) {
            if (result.success) {
                dlog('result:', result);
                dlog('post:', post);
                const attToRemove = post.attachments.findIndex(att => att.attachment_uuid === result.offlineAttachmentId);
                dlog('attToRemove:', attToRemove);
                if (attToRemove >= 0) {
                    const removedAttachments = post.attachments.splice(attToRemove, 1);
                    const {rev} = await this.db.removeAttachment(post._id, removedAttachments[0].attachment_uuid, post._rev);
                    post._rev = rev;

                    const objUrl = this.objectUrls[result.offlineAttachmentId];
                    if (objUrl) {
                        URL.revokeObjectURL(objUrl);
                        delete this.objectUrls[result.offlineAttachmentId];
                    }
                }
                post.attachments.push(result.newAttachment);
                post.$meta.updated = true;
            }
        }

        if (post._rev !== originalRev) {
            const {_attachments, _rev} = await this.getLocalPost(post, {throwIfMissing: true});
            post._attachments = _attachments,
            post._rev = _rev;
        }

        return this.saveLocalPost(post);
    }

    /**
     * Downloads and stores post attachments in local db
     * @param {(string | Post)} postOrPostId 
     * @returns {boolean} true if new attachments were cached, false otherwise
     */
    async downloadAttachmentsForPost(postOrPostId) {
        const dlog = debugLogger.branch('downloadAttachmentsForPost');
        dlog('postOrPostId:', postOrPostId);
        const post = typeof(postOrPostId) === 'string' ? (await this.getLocalPost(postOrPostId, {throwIfMissing: true})) : postOrPostId;
        dlog('post:', post);
        const postId = post.post_uuid;
        const attachments = post.attachments.filter(att => att.attachment_uuid && !att.attachment_uuid.startsWith('offline:'));
        dlog('attachments:', attachments);
        let attachmentWasCached = false;

        const limit = pLimit(5);
        const queue = attachments.map(attachment => limit(async () => {
            const attId = attachment.attachment_uuid;
            dlog('attId:', attId);
            if (!post._attachments?.[attId]) {
                const blob = await withRetry(3, 100, () => downloadAttachment(attachment));
                if (blob) {
                    try {
                        await withRetry(3, 100, async () => {
                            const res = await this.db.putAttachment(post._id, attId, post._rev, blob, attachment.mime_type);
                            attachmentWasCached = true;
                            post._rev = res.rev;
                            dlog(`attachment ${attId} added to post ${post._id}`);
                        });
                    } catch (err) {
                        console.error(err);
                        console.error(`Could not add attachment ${attId} for post ${postId}`);
                        return false;
                    }
                }
            } else {
                dlog(`attachment ${attId} already saved to post ${post._id}`);
            }

            if (!this.objectUrls[attId]) {
                try {
                    dlog(`adding post attachment ${attId} to objectUrls`);
                    const blob = await this.db.getAttachment(post._id, attId);
                    this.objectUrls[attId] = URL.createObjectURL(blob);
                } catch (err) {
                    console.error(err);
                    console.error(`Could not retrieve blob for attachment ${attId} for post ${postId}`);
                    return false;
                }
            }

            return true;
        }));

        await Promise.all(queue);
        return attachmentWasCached;
    }

    async deleteUnusedRemoteAttachments(postId) {
        const dlog = debugLogger.branch('deleteRemoteAttachments');
        dlog('postId:', postId);

        const post = await this.getLocalPost(postId, {throwIfMissing: true});
        const deletedAttachmentIds = post.$meta.deletedAttachmentIds;
        dlog('deletedAttachmentIds:', deletedAttachmentIds);

        if (!deletedAttachmentIds || deletedAttachmentIds.length === 0) {
            return post;
        }

        const response = await deletePostAttachments(post.post_uuid, JSON.stringify(deletedAttachmentIds));
        const {count} = await response.json();
        dlog('count:', count);
        // TODO: handle case where count of deleted attachments doesn't match deletedAttachmentIds.length

        post.$meta.deletedAttachmentIds = [];
        return this.saveLocalPost(post);
    }

    //////////////////////////////////////
    // SECTION: Syncing
    //////////////////////////////////////

    async syncLocalPostToRemote(postId, {bulkSync = false, ignorePrevSyncError = false} = {}) {
        const dlog = debugLogger.branch('syncLocalPostToRemote');
        dlog('postId: ', postId);

        let state = 'initial';
        /** @type {LocalPostError['type']} */
        let errorType = 'unknown';
        let success = true;
        let localPost;
        let remotePostId;
        let dispatchIdChange;

        const transitionState = (newState) => {
            dlog(`state transition: ${newState} <- ${state}`);
            dlog('state transition - localPost:', localPost);
            state = newState;
        }
        
        try {
            this.draftsCurrentlySyncing.add(postId);
            this._updateIsSyncing();

            localPost = await this.getLocalPost(postId, {throwIfMissing: true});
            dlog('localPost: ', localPost);

            if (!this.isOffline && !bulkSync && !localPost.$meta.offline) {
                const remotePost = await this.getRemotePost(localPost.post_uuid);
                transitionState("remote-fetched");
                if (!remotePost || remotePost.status !== 'draft') {
                    // delete local post and do not continue with sync
                    await this.deletePostDraft(localPost._id, {force: true});
                    transitionState("local-force-deleted");
                    success = false;
                    localPost = null;
                    return;
                }
                localPost = await this.cacheDraft(localPost._id, remotePost, localPost);
                transitionState("remote-cached");
            }

            const localPostState = getLocalPostState(localPost);
            dlog('localPostState: ', localPostState);

            if (this.isOffline) {
                transitionState("offline");
                success = false;
            } /* else if (localPostState === 'error' && !ignorePrevSyncError) {
                transitionState("previous-sync-error");
                success = false;
            } */ else if (localPostState === 'synced') {
                transitionState('synced');
            } else if (localPostState === 'deleted') {
                errorType = 'delete';

                if (!localPost.$meta.offline) {
                    try {
                        const remoteDeleted = await deleteRemotePost(localPost.post_uuid);
                        dlog('remoteDeleted: ', remoteDeleted);
                    } catch (err) {
                        if (err instanceof Response) {
                            const message = await getErrorMessageFromResponse(err);
                            if (message !== 'Post Not Found') {
                                throw err;
                            }
                        } else {
                            throw err;
                        }
                    }
                }
                
                await this.cleanupEmbeddedForms(localPost);
                await this.db.remove(localPost);

                this.dispatchLocalPostDeleted(localPost.post_uuid);
            } else {
                if (localPostState === 'offline') {
                    errorType = 'create';
                    remotePostId = await createRemotePost(localPost.source_team.team_uuid, localPost.post_uuid.replace("offline:", ""));
                    transitionState('remote-stub-created');

                    dlog('remotePostId: ', remotePostId);

                    const existingEmbeddedForms = localPost.embedded_forms ?? [];
                    const { embedded_forms: newEmbeddedForms } = await this.getRemotePost(remotePostId);

                    /** @type {LocalPost} */
                    const newLocalPost = {
                        ...localPost,
                        _id: undefined,
                        _rev: undefined,
                        post_uuid: remotePostId,
                        embedded_forms: newEmbeddedForms,
                        $meta: {
                            updated: true,
                            submitted: localPost.$meta.submitted,
                            subscribe_all: localPost.$meta.subscribe_all,
                            unsyncedEmbeddedForms: existingEmbeddedForms,
                        }
                    }

                    dlog('newLocalPost: ', newLocalPost);

                    const oldId = localPost.post_uuid;

                    await pouchDbUpsert(this.db, getLocalPostId(remotePostId), newLocalPost);
                    transitionState('remote-saved-to-db');

                    await this.db.remove(localPost);
                    transitionState('local-post-deleted');

                    localPost = await this.getLocalPost(remotePostId, {throwIfMissing: true});
                    transitionState('local-post-reassigned');

                    dlog('localPost: ', localPost);

                    // sync forms if there are custom forms
                    if (newEmbeddedForms.length > 0) {
                        const formService = this.getServices().forms;
                        await syncSubmissionDrafts(formService.caches.submissionDrafts.cache, () => {}, this);
                        delete localPost.$meta.unsyncedEmbeddedForms;
                        await pouchDbUpsert(this.db, localPost._id, localPost);
                    }
                    
                    // we might need to call this later, if submit fails and we haven't dispatched already
                    dispatchIdChange = () => {
                        this.dispatchPostIdChange(oldId, localPost.post_uuid);
                    }
                } else {
                    remotePostId = localPost.post_uuid;
                }
                errorType = 'update';
                transitionState('remote-created');

                localPost = await this.deleteUnusedRemoteAttachments(localPost._id);
                transitionState('unused-remote-attachments-removed');

                localPost = await this.uploadOfflineAttachments(localPost._id);
                transitionState('attachments-uploaded');

                localPost = await this.attachCloneAttachments(localPost._id);
                transitionState('clone-attachments-attached');

                let remotePost = await updateRemotePost(remotePostId, localPost, {ignoreAttachments: true});
                transitionState('fields-updated');

                // don't dispatch an id change event if we're submitting the post
                if (!localPost.$meta.submitted) {
                    dispatchIdChange?.();
                    dispatchIdChange = undefined;
                }
    
                const offlineAttachments = localPost.attachments.filter(att => att.attachment_uuid?.startsWith('offline'));
                localPost.attachments = [
                    ...remotePost.attachments,
                    ...offlineAttachments,
                ]
                transitionState('attachments-merged');

                localPost.revised_at = remotePost.revised_at;
                localPost = await this.saveLocalPost(localPost);
                const newAttachmentsCached = await this.downloadAttachmentsForPost(localPost);
                if (newAttachmentsCached) {
                    localPost = await this.getLocalPost(localPost._id);
                }
                transitionState('remote-attachments-cached');

                if (!localPost.$meta.submitted) {
                    localPost.$meta.updated = false;
                    localPost.$meta.error = false;
                    localPost.$meta.originalPost = remotePost;
                    localPost = await this.saveLocalPost(localPost);
                } else {
                    errorType = 'submit';

                    transitionState('embedded-forms-processing');
                    // submit embedded forms before moving on
                    const formService = this.getServices().forms
                    for (const pef of (localPost.embedded_forms ?? [])) {
                        const submissionId = pef.submission_uuid;
                        const submission = await formService.drafts.get(submissionId);
                        const body = {
                            fields: submission.fields,
                            commit: true,
                        }
                        await formService.updateDraft(submissionId, body, FormType.POST, {syncNow: true})
                    }

                    transitionState('pre-submission');
                    const submittedPost = await updateRemotePost(remotePostId, localPost, {ignoreAttachments: true, submitPost: true});
                    dlog('post committed');
                    await this.db.remove(await this.getLocalPost(localPost._id));
                    dlog('local post deleted');
                    for (const attachment of submittedPost.attachments) {
                        if (attachment.attachment_uuid in this.objectUrls) {
                            URL.revokeObjectURL(this.objectUrls[attachment.attachment_uuid]);
                            delete this.objectUrls[attachment.attachment_uuid];
                        }
                    }
                    dlog('object urls cleaned-up');

                    this.dispatchLocalPostDeleted(localPost.post_uuid);
                }
            }
        } catch (err) {
            console.error('syncLocalPostToRemote err:', err)
            success = false;

            let responseErrorMessages;
            let responseStatus;
            if (err instanceof Response && err.status === 422) {
                responseStatus = err.status;
                if (err.status === 422) {
                    responseErrorMessages = await extractErrorMessages(err);
                }
            }

            let networkLossError = false;
            if (err instanceof TypeError || err instanceof AxiosError) {
                // Went offline during sync
                console.error("Network loss during sync");
                networkLossError = true;
            }

            if (!networkLossError) {
                localPost.$meta.error = {
                    type: errorType,
                    state,
                    response: {
                        status: responseStatus,
                        errorMessages: responseErrorMessages,
                    },
                };
    
                localPost = await this.saveLocalPost(localPost);
                
            }

            if (state === 'pre-submission' && dispatchIdChange) {
                dispatchIdChange();
            }

        } finally {
            dlog('final state:', state);
            this.draftsCurrentlySyncing.delete(postId);
            this._updateIsSyncing();

            return {
                state,
                success,
                localPost,
            }
        }
    }

    async syncAllPosts() {
        const dlog = debugLogger.branch('syncAllPosts');

        if (LocalStorageHelper.get('post-sync-enabled', 'boolean', true) === false) {
            dlog('post sync disabled');
            return;
        }

        if (window.location.pathname.includes("/feed/new_post/")) {
            console.log("skipping post sync because we're on the new post page");
            return;
        }

        try {
            this.isBulkSyncing = true;
            this._updateIsSyncing();

            /** @type {Post[]} */
            let remoteDrafts = [];

            try {
                const getPaginatedPosts = async (page) => {
                    const res = await get_post_drafts(`?include_subscribers=true&page=${page}`);
                    const data = await res.json();
                    remoteDrafts.push(...data.posts);
                    const maxPages = Math.ceil(data.total_posts / data.max_results);
                    if (page < maxPages) {
                        await getPaginatedPosts(page + 1);
                    }
                }
                await getPaginatedPosts(1);
                dlog('remoteDrafts:', remoteDrafts);
            } catch (err) {
                console.error("could not fetch remote post drafts:", err);
                throw new Error("Could not fetch remote drafts during post sync");
            }

            const cacheDraftQueue = remoteDrafts.map(draft => this.cacheDraft(draft.post_uuid, draft));
            await Promise.all(cacheDraftQueue);

            let localPosts = await this.getLocalPosts({includeDeleted: true});
            const uploadedLocalPosts = localPosts.filter(post => !post.$meta.offline);
            const remoteDraftIds = remoteDrafts.map(post => post.post_uuid);
            const localPostsToDelete = uploadedLocalPosts.filter(post => remoteDraftIds.includes(post.post_uuid) === false);
            for (const post of localPostsToDelete) {
                await this.deletePostDraft(post._id, {force: true});
            }

            // Then sync local posts that are offline, updated, or deleted
            localPosts = await this.getLocalPosts({includeDeleted: true});
            const limit = pLimit(10);
            const syncLocalQueue = localPosts.map(localPost => limit(() => this.syncLocalPostToRemote(localPost._id, {bulkSync: true})));
            await Promise.all(syncLocalQueue);
            this.dispatchPostsSynced();
        } finally {
            this.isBulkSyncing = false;
            this._updateIsSyncing();
        }
    }

    _updateIsSyncing() {
        const isSyncing = this.isBulkSyncing || this.draftsCurrentlySyncing.size > 0;
        if (this.isSyncing !== isSyncing) {
            this.isSyncing = isSyncing;
            this.dispatchIsSyncingChange(isSyncing);
        }
    }
}

const postService = new PostService();

export function getPostService(orgId, userId, isOffline) {
    return postService.init(orgId, userId, isOffline);
}

/**
 * Picks fields from user stored in intial data and renames them as necessary
 * @param {object} initialDataUser 
 * @returns {object}
 */
function convertInitialDataUserToPostUser(initialDataUser) {
    const {
        uuid,
        email,
        first_name,
        last_name,
        phone_number,
        mobile_phone_number,
        avatar_data,
        status,
        title,
        roles,
    } = initialDataUser;

    return {
        user_uuid: uuid,
        email,
        first_name,
        last_name,
        phone_number,
        mobile_phone_number,
        avatar_data,
        status,
        title,
        roles,
    };
}

function generatePostReferenceNumber() {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const year = now.getFullYear().toString().substring(2, 4);

    const charBank = 'abcde1234567890';
    let randomChars = '';
    for (let i = 0; i < 4; i++) {
        const index = Math.floor(Math.random() * charBank.length);
        randomChars += charBank[index];
    }

    return `${month}${day}${year}-${randomChars}`;
}

/**
 * Gets or creates the PouchDB id for this post
 * @param {(Post|string)} postOrId 
 * @returns {string}
 */
export function getLocalPostId(postOrId) {
    if (typeof(postOrId) === 'string') {
        return postOrId.startsWith('post:') ? postOrId : `post:${postOrId}`;
    }
    return postOrId._id ?? `post:${postOrId.post_uuid}`;
}

/**
 * @param {LocalPost} localPost 
 * @returns {("deleted" | "offline" | "updated" | "synced" | "submitted" | "error")}
 */
function getLocalPostState(localPost) {
    if (localPost.$meta.error) {
        return 'error';
    } else if (localPost.$meta.deleted) {
        return 'deleted';
    } else if (localPost.$meta.offline) {
        return 'offline';
    } else if (localPost.$meta.submitted) {
        return 'submitted';
    }  else if (localPost.$meta.updated) {
        return 'updated';
    }

    return 'synced';
}

/**
 * Strips the "post:" prefix from the postId if it exists.
 * @param {string} postId 
 * @returns {string}
 */
function getRemotePostId(postId) {
    return postId.replace(/^post:/, '');
}


/**
 * Creates a remote post and returns new uuid
 * @param {string} teamId 
 * @returns {Promise<string>}
 */
async function createRemotePost(teamId, postId) {
    const dlog = debugLogger.branch("createRemotePost");
    dlog('teamId:', teamId);
    dlog('postId:', postId);

    const body = JSON.stringify({
        team_uuid: teamId,
        post_uuid: postId,
    });

    let newPostId;
    const response = await make_bulletin(body);
    if (response.status === 409) {
        // this post has already been created
        newPostId = postId;
    } else {
        const content = await response.json();
        newPostId = content?.post?.post_uuid;
    }
    dlog('newPostId:', newPostId);

    if (!newPostId) {
        throw new Error(`Did not get a remote post ID when creating post for ${post._id}`);
    }

    return newPostId
}

/**
 * Updates fields of remote post ID with data from local post
 * @param {string} remotePostId 
 * @param {LocalPost} localPost 
 * @param {object} [options]
 * @param {boolean} [options.ignoreAttachments]
 * @param {boolean} [options.submitPost]
 * @returns {Promise<Post>}
 */
async function updateRemotePost(remotePostId, localPost, options = {}) {
    const {ignoreAttachments, submitPost} = options;

    remotePostId = remotePostId.replace("offline:", "");

    const body = {
        commit: submitPost === true,
        team_uuid: localPost.source_team.team_uuid,
        title: localPost.title,
        body: localPost.body,
        body_is_html: true,
        sub_status_uuid: localPost.sub_status?.sub_status_uuid,
        sub_status_2_uuid: localPost.sub_status_2?.sub_status_uuid,
        tag_uuid: localPost.tag?.tag_uuid,
        category_uuid: localPost.category?.category_uuid,
        severity_level: localPost.severity_level,
        location: localPost.location,
        coordinates: localPost.coordinates,
        assign_user_uuids: (localPost.assigns ?? []).map(user => user.user_uuid),
        subscribe_all: false,
        subscribe_user_uuids: (localPost.subscribers ?? []).map(user => user.user_uuid),
        custom_field_value: localPost.custom_field_value,
        auto_close: localPost.auto_close,
    };

    if (typeof localPost.due_date === 'number') {
        body.due_date = localPost.due_date;
    }

    if (typeof localPost.is_urgent === 'boolean') {
        body.is_urgent = localPost.is_urgent;
    }

    if (typeof localPost.voice_notifications === 'boolean') {
        body.voice_notifications = localPost.is_urgent && localPost.voice_notifications;
    }

    if (!ignoreAttachments) {
        body.attachments = localPost.attachments.filter(a => a.attachment_uuid?.startsWith('offline:') ?? false === false);
    }

    debugLogger.log('updateRemotePost body: %o', body);

    const response = await update_bulletin(remotePostId, JSON.stringify(body));
    debugLogger.log('updateRemotePost response: %o', response);
    const content = await response.json();
    debugLogger.log('updateRemotePost content: %o', content);
    return content.post;
}

async function deleteRemotePost(remotePostId) {
    const response = await deleteBulletinPost(remotePostId);
    return response.ok;
}

/**
 * 
 * @param {Post} remotePost 
 * @returns {PostUpdateBody}
 */
function convertRemotePostToPostUpdateBody(remotePost) {
    return {
        title: remotePost.title,
        body: remotePost.body,
        severity_level: remotePost.severity_level,
        location: remotePost.location ?? "",
        custom_field_value: remotePost.custom_field_value,
        auto_close: remotePost.auto_close,
        due_date: remotePost.due_date,
        is_urgent: remotePost.is_urgent,
        voice_notifications: remotePost.voice_notifications,
        due_date: remotePost.due_date,
        sub_status_uuid: remotePost.sub_status?.sub_status_uuid,
        sub_status_2_uuid: remotePost.sub_status_2?.sub_status_uuid,
        tag_uuid: remotePost.tag?.tag_uuid,
        category_uuid: remotePost.category?.category_uuid,
        coordinates: {
            lat: remotePost.coordinates.lat ?? "",
            lon: remotePost.coordinates.lon ?? "",
        },
        assign_user_uuids: (remotePost.assigns ?? []).map(user => user.user_uuid),
        subscribe_user_uuids: (remotePost.subscribers ?? []).map(user => user.user_uuid),
        attachments: remotePost.attachments,
        team_uuid: remotePost.source_team.team_uuid,
        embedded_forms: remotePost.embedded_forms ?? [],
        embedded_forms_hash: remotePost.embedded_forms_hash ?? '',
    };
}

/**
 * 
 * @param {LocalPost} localPost 
 * @param {PostDiff} diff 
 */
export function generateCombinedDiff(localPost, diff) {
    if (!diff) {
        return null;
    }

    const combinedDiff = {};
    for (const key in diff) {
        let localValue = localPost[key];

        if (key === "assignIds") {
            localValue = localPost.assigns.map(u => u.user_uuid);
        } else if (key === "subscriberIds") {
            localValue = localPost.subscribers.map(u => u.user_uuid);
        }

        combinedDiff[key] = {
            original: diff[key],
            local: localValue,
        }
    }
    return combinedDiff;
}

/**
 * 
 * @param {Post} post 
 * @param {PostUpdateBody} data 
 * @param {PostOptions} postOptions
 * @returns {?PostDiff}
 */
function generatePostDiff(post, data, postOptions) {
    const dlog = debugLogger.branch('generatePostDiff');
    dlog('post: %o', post);
    dlog('data: %o', data);
    dlog('postOptions: %o', postOptions);
    /** @type {PostDiff} */
    const diff = {};

    function handleSimpleProperty(property) {
        if (post[property] !== data[property]) {
            diff[property] = data[property];
        }
    }

    handleSimpleProperty('title');
    handleSimpleProperty('body');
    handleSimpleProperty('severity_level');
    handleSimpleProperty('location');
    handleSimpleProperty('custom_field_value');
    handleSimpleProperty('auto_close');
    handleSimpleProperty('due_date');

    if (data.is_urgent !== undefined) {
        handleSimpleProperty('is_urgent');
    }

    if (data.voice_notifications !== undefined) {
        handleSimpleProperty('voice_notifications');
    }

    // sub_status_uuid
    if (data.sub_status_uuid !== post.sub_status?.sub_status_uuid) {
        const subStatus = postOptions?.sub_statuses?.find(subStatus => subStatus.sub_status_uuid === data.sub_status_uuid);
        diff.sub_status = subStatus ? {...subStatus} : null;
    }

    // sub_status_2_uuid
    if (data.sub_status_2_uuid !== post.sub_status_2?.sub_status_uuid) {
        const subStatus = postOptions?.sub_statuses_2?.find(subStatus => subStatus.sub_status_uuid === data.sub_status_2_uuid);
        diff.sub_status_2 = subStatus ? {...subStatus} : null;
    }

    // tag_uuid
    if (data.tag_uuid !== post.tag?.tag_uuid) {
        const tag = postOptions?.tags?.find(tag => tag.tag_uuid === data.tag_uuid);
        diff.tag = tag ? {...tag} : null;
    }

    // category_uuid
    if (data.category_uuid !== post.category?.category_uuid) {
        const category = postOptions?.categories?.find(category => category.category_uuid === data.category_uuid);
        diff.category = category ? {...category} : null;
    }

    // coordinates
    if (data.coordinates.lat !== post.coordinates.lat || data.coordinates.lon !== post.coordinates.lon) {
        diff.coordinates = {...data.coordinates}
    }

    // assign_user_uuids
    const postAssignIds = (post.assigns ?? []).map(user => user.user_uuid);
    if (!simpleArraysMatch(data.assign_user_uuids, postAssignIds)) {
        diff.assignIds = [...data.assign_user_uuids];
    }

    // subscribe_user_uuids
    const postSubscriberIds = (post.subscribers ?? []).map(user => user.user_uuid);
    if (!simpleArraysMatch(data.subscribe_user_uuids, postSubscriberIds)) {
        diff.subscriberIds = [...data.subscribe_user_uuids];
    }

    // attachments
    const oldAttachmentIds = post.attachments.map(a => a.attachment_uuid);
    const newAttachmentIds = data.attachments.map(a => a.attachment_uuid);
    if (!simpleArraysMatch(oldAttachmentIds, newAttachmentIds)) {
        diff.attachments = [...data.attachments];
    }

    // team_uuid
    if (data.team_uuid !== post.source_team.team_uuid) {
        diff.team_uuid = data.team_uuid;
    }

    // embedded_forms
    if (data.embedded_forms_hash !== post.embedded_forms_hash) {
        diff.embedded_forms = data.embedded_forms;
        diff.embedded_forms_hash = data.embedded_forms_hash;
    }

    dlog('diff: %o', diff);
    // returns diff if it has entries, otherwise null
    return Object.keys(diff).length > 0 ? diff : null;
}

function postValuesMatch(a, b, key) {
    switch (key) {
        case 'coordinates':
            return a?.lat === b?.lat && a?.lon === b?.lon;
        case 'subscriberIds':
        case 'assignIds':
            return simpleArraysMatch(a, b);
        case 'sub_status':
        case 'sub_status_2':
            return a.sub_status_uuid === b.sub_status_uuid;
        case 'tag':
            return a.tag_uuid === b.tag_uuid;
        case 'category':
            return a.category_uuid === b.category_uuid;
        case 'attachments':
            return simpleArraysMatch(a.map(att => att.attachment_uuid), b.map(att => att.attachment_uuid));
        default:
            return a === b;
    }
}

/**
 * 
 * @param {string[]} [diffArray] 
 * @param {object[]} userMapping 
 * @returns {?any[]}
 */
function getNewRespondersOrSubscribers(diffArray, userMapping) {
    const dlog = debugLogger.branch('getNewRespondersOrSubscribers');
    dlog('diffArray: %o', diffArray);
    dlog('userMapping: %o', userMapping);
    if (diffArray !== undefined) {
        const newUsers = [];
        for (const id of diffArray) {
            const user = userMapping[id];
            if (user !== undefined) {
                // make directory user look like what post components expect
                const postUser = {
                    ...user,
                    user_uuid: user.uuid,
                    roles: [user.role],
                    mobile_phone_number: "",
                }
                newUsers.push(postUser);
            } else {
                dlog('undefined subscriber:', id);
            }
        }
        return newUsers;
    }
    return null;
}

/**
 * 
 * @param {LocalPost} post 
 * @param {PostDiff} diff 
 */
function modifyPostUsingDiff(post, diff) {
    if (diff === null) {
        return;
    }

    for (const [key, value] of Object.entries(diff)) {
        debugLogger.log('modifyPostUsingDiff: %s: %o', key, value);
        if (!post.$meta.offline) {
            // Post exists on remote, store original values in $meta
            if (!post.$meta.originalPost) {
                post.$meta.originalPost = {...post};
                post.$meta.originalPost.attachments = post.$meta.originalPost.attachments.filter(a => a.attachment_uuid.startsWith("offline") === false)
                delete post.$meta.originalPost._id;
                delete post.$meta.originalPost._rev;
                delete post.$meta.originalPost.$meta;
            }
            post.$meta.updated = true;
        }
        post[key] = value;
    }
}

/**
 * 
 * @param {LocalPost} post 
 * @param {PostDiff} diff 
 */
function getUnusedAttachmentIds(post, diff) {
    if (diff === null || !Array.isArray(diff.attachments)) {
        return [];
    }

    const postAttachmentIds = post.attachments.map(attachment => attachment.attachment_uuid);
    const diffAttachmentIds = diff.attachments.map(attachment => attachment.attachment_uuid);

    return postAttachmentIds.filter(id => !diffAttachmentIds.includes(id));
}

/**
 * 
 * @param {PouchDB.Database} db 
 * @param {LocalPost} post 
 * @param {{[attachmentId: string]: string}} objectUrls
 */
async function addBlobUrlToPostAttachments(db, post, objectUrls) {
    /** @type {Attachment[]} */
    const newAttachments = [];

    if (!post.attachments) {
        return post;
    }

    for (const attachment of post.attachments) {
        const attachmentId = attachment.attachment_uuid;
        let attachmentBlob;
        try {
            attachmentBlob = await db.getAttachment(post._id, attachmentId);
        } catch (err) {
            // This attachment is not cached locally, do nothing with it
            newAttachments.push({...attachment});
            continue;
        }

        if (objectUrls[attachmentId] === undefined) {
            objectUrls[attachmentId] = URL.createObjectURL(attachmentBlob);
        }

        newAttachments.push({
            ...attachment,
            public_url: objectUrls[attachmentId],
            thumbnails: {
                small: objectUrls[attachmentId],
                medium: objectUrls[attachmentId],
            },
        });
    }

    post.attachments = newAttachments;
    return post;
}

/**
 * 
 * @param {Attachment} attachment 
 * @returns {Promise<null | {attachmentId: string, blob: Blob, mimeType: string>}}
 */
async function downloadAttachment(attachment) {
    try {
        const url = attachment.public_url;
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    } catch (err) {
        console.error('Could not download attachment:', err);
        return null;
    }
}

const syncTransitionStates = [
    "initial",
    "synced",
    "remote-stub-created",
    "remote-saved-to-db",
    "local-post-deleted",
    "local-post-reassigned",
    "remote-created",
    "unused-remote-attachments-removed",
    "attachments-uploaded",
    "fields-updated",
    "attachments-merged",
    "remote-attachments-cached",
    "pre-submission",
];
