import { Link } from "react-router-dom";
import { BorderedPanel, MainContent, PageHead } from "components/Shared/PageParts";

import useOrganizationId from "hooks/useOrganizationId";
import { useEffect } from "react";
import { jumpToTopOfPage } from "other/Helper";
import { useZeroContext } from "components/ZeroContext";
import { usePostIsSyncing } from "hooks/offlineHooks";
import LogicLessSyncSpinner from "components/Shared/LogicLessSyncSpinner";


export default function PostSubmissionConfirmationPage() {
    const orgId = useOrganizationId();
    const zeroContext = useZeroContext();
    const { isOffline } = zeroContext;
    const postService = zeroContext.services.post;
    const isSyncing = usePostIsSyncing(postService);

    const backToPostsLink = isOffline ? `/${orgId}/offline/dashboard` : `/${orgId}/home/team/my_teams/feed`;

    useEffect(() => {
        jumpToTopOfPage();
    }, []);

    return (
        <MainContent>
            <PageHead>
                <BorderedPanel className="zero-blue">
                    <Link to={backToPostsLink} style={{border: 0}}>
                        <button className="ButtonLink blue-link">Back to Posts</button>
                    </Link>
                    <br/><br/>
                    <div className="panel-heading article text-center" style={{height: "auto"}}>
                        <img style={{height: '100px'}} src="/img/ZERO-(blue)-small.png" alt="ZERO Logo"/>
                    </div>
                    <div className="panel-heading article" style={{height: "auto"}}>
                        <h3 className="directory teams header">Post {isSyncing ? "Submitting" : "Submitted"}</h3>
                        <p className="zero-blue text-center" style={{maxWidth: '350px', margin: '0 auto'}}>
                            {isSyncing &&
                                <>
                                    Please stay on this page until the sync is complete.
                                </>
                            }
                            {!isSyncing &&
                                <>
                                    Your post has been successfully submitted.
                                    <br/><br/>
                                    Have a wonderful rest of the day!
                                </>
                            }
                        </p>
                    </div>
                    {isSyncing &&
                        <LogicLessSyncSpinner style={{textAlign: 'center', marginTop: '3rem'}} />
                    }
                </BorderedPanel>
            </PageHead>
        </MainContent>
    )
}