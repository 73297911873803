import React, {useCallback, useContext, useEffect} from "react";
import { useOfflineDataCachesContext } from "./Offline/OfflineDataCaches";
import useOfflineMode from "./Offline/useOfflineMode";
import FormsService from "services/formsService";
import {useSelector} from "react-redux";
import { getPostService } from "services/postService";
import { useKeyValueCache } from "hooks/useKeyValueCache";
import { useConstant } from "hooks/useConstant";
import ZeroEventBus from "other/ZeroEventBus";

/** @type {React.Context<ZeroContext>} */
export const ZeroContext = React.createContext({});

export default function ZeroContextManager({children}) {
    const {isOffline} = useOfflineMode();
    const caches = useOfflineDataCachesContext();
    const offlineAllowed = useSelector(state => state.org_helper.organization.feature_flags.offline);
    const orgId = useSelector(state => state.org_helper.organization.organization_uuid);
    const userId = useSelector(state => state.user.user.uuid);
    const kv = useKeyValueCache();
    const events = useConstant(new ZeroEventBus());

    const isPublicUser = userId === undefined;

    const getBlob = useCallback((/** @type {string} */attachmentId) => {
        if (isPublicUser) return null;

        if (attachmentId in caches.submissionDrafts.blobs) {
            return caches.submissionDrafts.blobs[attachmentId];
        } else if (attachmentId in caches.formsCache.blobs) {
            return caches.formsCache.blobs[attachmentId];
        }
        return null;
    }, [caches.submissionDrafts?.blobs, caches.formsCache?.blobs]);

    const getBlobUrl = useCallback((/** @type {string} */attachmentId) => {
        return getBlob(attachmentId)?.url;
    }, [getBlob]);

    const initializeServices = () => {
        if (isPublicUser) return {};

        return {
            forms: FormsService.getFormsService(isOffline, caches),
            post: getPostService(orgId, userId, isOffline),
        };
    }

    /** @type {ZeroContextServices} */
    const services = initializeServices();

    const getServices = () => services;
    for (const svc of Object.values(services)) {
        svc.getServices = getServices;
    }

    return (
        <ZeroContext.Provider value={{caches, services, isOffline, offlineAllowed, getBlob, getBlobUrl, kv, events}}>
            {children}
        </ZeroContext.Provider>
    )
}

/**
 * @returns {ZeroContext}
 */
export function useZeroContext() {
    const context = useContext(ZeroContext);
    return context;
}

export function useZeroEventListener(eventName, callback) {
    const { events } = useZeroContext();

    useEffect(() => {
        return events.subscribe(eventName, callback);
    }, [eventName, callback]);
}