import {useEffect, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useSelector} from 'react-redux';

import {get_location_survey} from 'api/zero-api.js';


export default function useLocationSurveyLoader(existingCheckIn) {
    const selectedLocation = useSelector(state => state.checkInOptions.selectedLocation);
    const [cache, setCache] = useState({});
    const [survey, setSurvey] = useState({});

    const fetchSurvey = async (locationId) => {
        try {
            const response = await get_location_survey(locationId);
            const data = await response.json();

            unstable_batchedUpdates(() => {
                setCache(Object.assign({}, cache, {
                    [locationId]: {
                        fetchedAt: new Date(),
                        survey: data.survey
                    }
                }));

                setSurvey({
                    ok: true,
                    survey: data.survey
                });
            });
        } catch (err) {
            console.error(err);
            setSurvey({
                data: null,
                error: true
            });
        }
    };

    useEffect(() => {
        if (selectedLocation || existingCheckIn) {
            const locationId = existingCheckIn ? existingCheckIn?.desk?.space?.location?.uuid : selectedLocation.uuid;
            const cacheHit = cache[locationId];
            if (cacheHit) {
                const now = new Date();
                const diff = now - cacheHit.fetchedAt;
                if (diff < 5000) {
                    setSurvey({
                        survey: cacheHit.survey,
                        ok: true
                    });
                } else {
                    fetchSurvey(locationId);
                }
            } else {
                fetchSurvey(locationId);
            }

        } else {
            setSurvey({});
        }
    }, [selectedLocation, existingCheckIn]);

    return survey;
}