import React, {useEffect, useMemo, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useSelector} from 'react-redux';
import {Input, Pagination, Select, DatePicker} from 'antd';

const {Option, OptGroup} = Select;
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';

import styles from './DirectoryPage.module.css';

import DirectoryTag from './DirectoryTag';
import UserPopoverCard from 'components/Shared/UserPopoverCard';
import {mainContentContainerClass, safeProfilePic, isAdminOrTeamLead, safe_get, canManageSpaces} from 'other/Helper';
import {CheckInLocale} from 'other/Constants';
import {useDirectoryLoader, useDesksFiltersLoader} from 'hooks/spaces';
import {useTeamsLoader} from 'hooks/teams';
import dayjs from "dayjs";

export function UserWithAvatar({user, popoverProps = {}}) {
    return (
        <div style={{display: 'flex'}}>
            <div className="hover-cursor">
                <UserPopoverCard user={user} {...popoverProps}>
                    {safeProfilePic(user, "img-sm img-circle top-posters", "top-posters", {
                        marginRight: "10px",
                        display: "inline-block"
                    })}
                </UserPopoverCard>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: 200}}>
                <UserPopoverCard user={user} {...popoverProps}>
                    <p className={"text-md text-semibold profile truncate name hover-cursor"}
                       style={{display: 'inline-block', marginBottom: '0px', marginLeft: '2px'}}>
                        {user.first_name} {user.last_name}
                    </p>
                </UserPopoverCard>
                {
                    user.email &&
                    <a href={`mailto:${user.email}`} target="_blank" rel="noreferrer"
                       className="text-sm profile truncate zero-dark-grey link-hover"
                       style={{display: 'inline-block', fontSize: '.9em', marginLeft: 2, marginTop: 4}}>
                        {user.email}
                    </a>
                }
            </div>
            <span className="zero-dark-grey"></span>
        </div>
    )
}

function filterUsers(users, rawSearch, filters) {
    if (!Array.isArray(users)) return [];

    const search = rawSearch.toLowerCase().trim();
    const checkedInFilter = filters.checked_in;
    const localeFilters = filters.locale;
    const locationFilters = filters.location;

    return users.filter(user => {
        if (checkedInFilter !== null) {
            if (checkedInFilter && user.last_check_in === null) return false;
            if (!checkedInFilter && user.last_check_in !== null) return false;
        }

        if (localeFilters.length > 0) {
            if (!user.last_check_in) return false;

            const usersLocale = user.last_check_in.locale;
            if (!localeFilters.includes(usersLocale)) return false;
        }

        if (locationFilters.length > 0) {
            if (user.last_check_in?.locale !== CheckInLocale.OFFICE) return false;

            const usersLocation = user.last_check_in.location_name;
            if (!locationFilters.includes(usersLocation)) return false;
        }

        if (search.length > 0) {
            const fullName = `${user.first_name} ${user.last_name}`
            if (fullName.toLowerCase().includes(search)) return true;
            if (user?.email.toLowerCase().includes(search)) return true;
            if (user.last_check_in && user.last_check_in.locale === CheckInLocale.OFFICE) {
                const {desk_name, space_name, location_name} = user.last_check_in;
                if (desk_name?.toLowerCase()?.includes(search)) return true;
                if (space_name?.toLowerCase()?.includes(search)) return true;
                if (location_name?.toLowerCase()?.includes(search)) return true;
            }
            if (!user.last_check_in && user.first_assigned_desk) {
                const {desk_name, space_name, location_name} = user.first_assigned_desk;
                if (desk_name?.toLowerCase()?.includes(search)) return true;
                if (space_name?.toLowerCase()?.includes(search)) return true;
                if (location_name?.toLowerCase()?.includes(search)) return true;
            }
            return false;
        }

        return true;
    });
}

function disabledDates(current) {
    return current > dayjs().add(90, 'days').endOf('day');
}

const initialUserFilters = () => ({
    checked_in: null,
    locale: [],
    location: [],
});

function AnalyticsBlockItem({title, value}) {
    return (
        <div className={styles.analyticsBlockItem}>
            <p className={"text-bold zero-blue text-center " + styles.analyticsBlockItemTitle}>{title}</p>
            <p className={"text-thin zero-blue text-center mar-btm-0 " + styles.analyticsBlockItemValue}>{value}</p>
        </div>
    )
}

function AnalyticsBlock({items}) {
    return (
        <div className={styles.analyticsBlock}>
            {items}
        </div>
    )
}

export default function DirectoryPage() {
    const user = useSelector(state => state.user.user);

    const initialShowAnalytics = localStorage.getItem('directory:show-analytics');

    const [fetchDeskFilters, deskFiltersLoader] = useDesksFiltersLoader();
    const [fetchDirectory, directoryLoader] = useDirectoryLoader();
    const {loading, error, ok, data: users} = directoryLoader;
    const [fetchTeams, teamsLoader] = useTeamsLoader();
    const [tableData, setTableData] = useState();
    const [userSearch, setUserSearch] = useState('');
    const [rawUserFilters, setRawUserFilters] = useState([]);
    const [userFilters, setUserFilters] = useState(initialUserFilters());
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [showAnalytics, setShowAnalytics] = useState(initialShowAnalytics === 'true');
    const [currentPage, setCurrentPage] = useState(1);
    const [date, setDate] = useState(dayjs().startOf('day'));
    const [teamUuids, setTeamUuids] = useState([]);
    const [teams, setTeams] = useState([]);
    const usersPerPage = 100;

    useEffect(() => {
        fetchTeams();
        fetchDeskFilters();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('include_self', 'true');
        params.set('full_data', 'true');
        params.set('date', date.format('YYYY-MM-DD'));
        if (teamUuids.length > 0) {
            params.append('team_uuid', teamUuids.join(','));
        }

        fetchDirectory('?' + params.toString());
    }, [date, teamUuids]);

    useEffect(() => {
        if (error) {
            setTableData((
                <tr>
                    <td className="react-bs-table-no-data" colSpan="3">
                        Could not load directory. Please try again.
                    </td>
                </tr>
            ))
        } else if (ok) {
            if (filteredUsers.length === 0) {
                setTableData((
                    <tr>
                        <td className="react-bs-table-no-data zero-dark-grey" colSpan="3">
                            No users.
                        </td>
                    </tr>
                ))
            } else {
                setTableData(
                    filteredUsers.slice(usersPerPage * (currentPage - 1), currentPage * usersPerPage).map(user => (
                        <tr key={user.uuid} className="tr-hover">
                            <td className="zero-blue">
                                <UserWithAvatar user={user}/>
                            </td>
                            <td className="zero-dark-grey" style={{maxWidth: '250px'}}>
                                <DirectoryTag user={user} showCheckin={true}/>
                            </td>
                            <td className="zero-dark-grey">
                                {user.phone_number.length > 0 &&
                                    <a
                                        href={`tel:${user.phone_number}`}
                                        className="link-hover zero-dark-grey"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {user.phone_number}
                                    </a>
                                }
                            </td>
                        </tr>
                    ))
                )
            }
        } else {
            setTableData(
                [0, 1, 2, 3, 4, 5].map(index => (
                    <tr key={index} className="tr-hover">
                        <td style={{width: '33.3%'}}><Skeleton/></td>
                        <td style={{width: '33.3%'}}><Skeleton/></td>
                        <td style={{width: '33.3%'}}><Skeleton/></td>
                    </tr>
                ))
            );
        }
    }, [loading, error, filteredUsers, currentPage]);

    useEffect(() => {
        setFilteredUsers(filterUsers(users, userSearch, userFilters, date));
    }, [users, userSearch, userFilters]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredUsers]);

    useEffect(() => {
        if (teamsLoader.ok) {
            setTeams(teamsLoader.data.teams.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)));
        }
    }, [teamsLoader.ok]);

    const onPageChange = page => {
        setCurrentPage(page);
    }

    const onFilterChange = filterValues => {
        const newRawFilters = [];
        let lastCheckedInIndex = -1;
        const newFilters = initialUserFilters();

        for (const filter of filterValues) {
            const key = filter.split('=')[0];
            const value = filter.split('=')[1];

            if (key === 'checked_in') {
                newFilters.checked_in = value === 'true';

                if (lastCheckedInIndex >= 0) {
                    newRawFilters.splice(lastCheckedInIndex, 1);
                }

                newRawFilters.push(filter);
                lastCheckedInIndex = newRawFilters.length - 1;
            } else {
                newFilters[key].push(value);
                newRawFilters.push(filter);
            }
        }

        setRawUserFilters(newRawFilters);
        setUserFilters(newFilters);
    }

    const toggleShowAnalytics = () => {
        localStorage.setItem('directory:show-analytics', (!showAnalytics) ? 'true' : 'false');
        setShowAnalytics(!showAnalytics);
    }

    const analyticsBlockItems = useMemo(() => {
        const total = filteredUsers.length;
        const checkedIn = filteredUsers.filter(u => u.last_check_in !== null).length;
        const notCheckedIn = filteredUsers.filter(u => u.last_check_in === null).length;
        const office = filteredUsers.filter(u => safe_get(u, 'last_check_in.locale', null) === CheckInLocale.OFFICE).length;
        const remote = filteredUsers.filter(u => safe_get(u, 'last_check_in.locale', null) === CheckInLocale.REMOTE).length;
        const other = filteredUsers.filter(u => safe_get(u, 'last_check_in.locale', null) === CheckInLocale.OTHER).length;

        return (
            <>
                <AnalyticsBlockItem title="Total Members" value={total}/>
                <AnalyticsBlockItem title="Checked In" value={checkedIn}/>
                <AnalyticsBlockItem title={<>Not Checked&nbsp;In</>} value={notCheckedIn}/>
                <AnalyticsBlockItem title="Office" value={office}/>
                <AnalyticsBlockItem title="Remote" value={remote}/>
                <AnalyticsBlockItem title="Other" value={other}/>
            </>
        );
    }, [filteredUsers]);

    return (
        <div className={mainContentContainerClass()}>
            <div id="page-head" className="no-padding-mobile">
                <div className="row">
                    <div className="col-lg-12 col-xs-12 col-xs-offset-0">
                        <div id="page-title" style={{padding: '0px'}}>
                            <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                <div className="nav-header-panel no-padding-print">
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <h3 className={"section-titles admin-page-header"}
                                            style={{display: "inline-block", margin: "0px"}}>
                                            Directory
                                        </h3>
                                        {(isAdminOrTeamLead(user) || canManageSpaces(user)) &&
                                            <div>
                                                <span className="blue-link"
                                                      onClick={toggleShowAnalytics}>{showAnalytics ? "Hide" : "Show"} analytics</span>
                                            </div>
                                        }
                                    </div>
                                    <p className="header"
                                       style={{color: 'var(--zero-dark-grey', marginBottom: "0px", paddingLeft: "2px"}}>
                                        Enter a colleague's name to view their check-in details.
                                    </p>
                                    <div className="row" style={{padding: "0 0.5rem"}}>
                                        <div className="col-xs-12 col-sm-6 col-reduce-padding">
                                            <div style={{width: "100%", marginTop: "8px"}}>
                                                <Input
                                                    placeholder="Search..."
                                                    value={userSearch}
                                                    onChange={e => setUserSearch(e.target.value)}
                                                    allowClear={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-reduce-padding">
                                            <div style={{width: "100%", marginTop: "8px"}}>
                                                <DatePicker
                                                    format={"MMM D, YYYY"}
                                                    value={dayjs(date, 'MMM D, YYYY')}
                                                    onChange={setDate}
                                                    disabledDate={disabledDates}
                                                    style={{width: '100%'}}
                                                    allowClear={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{padding: "0 0.5rem"}}>
                                        <div className="col-xs-12 col-sm-6 col-reduce-padding">
                                            <div style={{width: "100%", marginTop: "8px"}}>
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Filter by status, location..."
                                                    value={rawUserFilters}
                                                    onChange={onFilterChange}
                                                    allowClear={true}
                                                    style={{width: '100%'}}
                                                    virtual={false}
                                                >
                                                    <OptGroup label="Status">
                                                        <Option value="checked_in=true"><CheckCircleTwoTone
                                                            twoToneColor="#52c41a"/> Checked In</Option>
                                                        <Option value="checked_in=false"><CloseCircleTwoTone
                                                            twoToneColor="#B5B5B5"/> Not Checked In</Option>
                                                    </OptGroup>
                                                    <OptGroup label="Work Location">
                                                        <Option value={`locale=${CheckInLocale.OFFICE}`}>Office</Option>
                                                        <Option value={`locale=${CheckInLocale.REMOTE}`}>Remote</Option>
                                                        <Option value={`locale=${CheckInLocale.OTHER}`}>Other</Option>
                                                    </OptGroup>
                                                    {deskFiltersLoader.ok &&
                                                        <OptGroup label="Office Location">
                                                            {
                                                                deskFiltersLoader.data.locations.map(({uuid, name}) => (
                                                                    <Option key={uuid}
                                                                            value={`location=${name}`}>{name}</Option>
                                                                ))
                                                            }
                                                        </OptGroup>
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-reduce-padding">
                                            <div style={{width: "100%", marginTop: "8px"}}>
                                                <Select
                                                    placeholder="Filter by team..."
                                                    style={{width: '100%'}}
                                                    loading={!teamsLoader.ok && !teamsLoader.error}
                                                    allowClear={true}
                                                    value={teamUuids}
                                                    onChange={setTeamUuids}
                                                    virtual={false}
                                                    mode="multiple"
                                                    filterOption={(input, option) => {
                                                        if (typeof (option.children) === 'string') {
                                                            return option.children.toLowerCase().includes(input.toLowerCase());
                                                        }
                                                        return false;
                                                    }}
                                                >
                                                    <Option value="my_teams">My Teams</Option>
                                                    {teams.length > 0 &&
                                                        <Option value="" disabled>
                                                            <hr style={{marginTop: "10px", marginBottom: "0px"}}/>
                                                        </Option>
                                                    }
                                                    {
                                                        teams.map(team => (
                                                            <Option key={team.uuid} value={team.uuid}>
                                                                {team.name}
                                                            </Option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="page-content" className="no-padding-mobile">
                {(isAdminOrTeamLead(user) || canManageSpaces(user)) && showAnalytics &&
                    <div className="panel" style={{
                        maxWidth: "850px",
                        border: "1px solid #e2e2e2",
                        padding: "1rem",
                        margin: '0 auto 1rem'
                    }}>
                        <AnalyticsBlock items={analyticsBlockItems}/>
                    </div>
                }
                <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                    <div className="panel" style={{border: "1px solid #e2e2e2", padding: "0px 0px"}}>
                        <div className="table-responsive">
                            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                <thead className="zero-blue">
                                <tr>
                                    <th className="zero-blue">Name</th>
                                    <th className="zero-blue">Location</th>
                                    <th className="zero-blue">Office Phone</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableData}
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginTop: "10px", marginBottom: "10px"}}>
                            <Pagination
                                size={"small"}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                                pageSize={usersPerPage}
                                total={filteredUsers.length}
                                current={currentPage}
                                onChange={onPageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}