import React, {useCallback, useEffect, useRef, useState} from 'react';

import {get_forms} from '../../api/zero-api.js'
import {safe_get, sortByString} from '../../other/Helper.js';

import {Select, Modal} from 'antd'

import '../../assets/css/antd-custom.css'
import {unstable_batchedUpdates} from 'react-dom';
import {useOfflineDataCachesContext} from 'components/Offline/OfflineDataCaches.js';
import {isOfflineRoute} from 'offline/utils.js';
import { FormType } from 'other/Constants.js';


export default function SelectFormModal({...props}) {
    const offlineRoute = isOfflineRoute();
    const [loadingForms, setLoadingForms] = useState(true);
    const [forms, setForms] = useState([]);
    const [formTeams, setFormTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(undefined);
    const [selectedForm, setSelectedForm] = useState(undefined);
    const {forms: cachedForms} = useOfflineDataCachesContext();
    const confirmButtonClickedRef = useRef(false);

    const getForms = useCallback(async () => {
        try {
            const query = new URLSearchParams();
            query.set('team_uuid', 'my_teams');
            query.set('form_types', props.page_type);
            query.set('enabled', 'true');
            query.set('schedule_only', 'false');
            query.set('include_fields', 'false');

            const res = await get_forms(`?${query}`);
            const forms = safe_get((await res.json()), 'forms', []);

            unstable_batchedUpdates(() => {
                setForms(sortByString(forms, 'name'));
                setLoadingForms(false);
            });
        } catch (err) {
            console.error(err);
        }
    }, [props.page_type, setForms, setLoadingForms]);

    const handleFormSelect = useCallback((formId) => {
        let form = forms.find(f => f.form_uuid === formId);
        let myTeamIds = props.teams.map(team => team.uuid);
        let formTeams = safe_get(form, 'teams', []).filter(team => myTeamIds.includes(team.team_uuid));
        sortByString(formTeams, 'name');

        if (formTeams.length === 1) {
            props.confirm(formTeams[0].team_uuid, formId);
        } else {
            setSelectedForm(formId);
            setFormTeams(formTeams);
        }
    }, [forms, formTeams]);

    const confirm = useCallback(() => {
        if (confirmButtonClickedRef.current) {
            return;
        }
        confirmButtonClickedRef.current = true;
        props.confirm(selectedTeam, selectedForm);
    }, [props.confirm, selectedForm, selectedTeam, confirmButtonClickedRef]);

    useEffect(() => {
        if (offlineRoute) {
            setForms(cachedForms.filter(form => !form.schedule_only && form.form_type !== FormType.POST));
            setLoadingForms(false);
        } else {
            getForms();
        }
    }, [setForms, setLoadingForms, getForms, cachedForms, offlineRoute]);

    return (
        (<Modal
            title={`Select a ${selectedForm !== undefined ? "team" : props.page_type === "regular" ? "template" : "course"}`}
            open={props.show}
            maskClosable={false}
            onCancel={props.cancel}
            maskTransitionName="maskTransitionName"
            footer={
                <div style={{height: '33px'}}>
                    {
                        selectedForm &&
                        <button className="btn btn-discard" onClick={() => {
                            setSelectedForm(undefined)
                        }}>
                            Back
                        </button>
                    }
                    <button className="btn btn-modal" onClick={confirm} disabled={selectedTeam === undefined}>
                        Next
                    </button>
                </div>
            }
        >
            {
                selectedForm !== undefined && !loadingForms &&
                <div>
                    <p className="zero-dark-grey">Select a team for this submission:</p>
                    <Select
                        virtual={false}
                        style={{width: "100%",}}
                        className="move-team-select"
                        showArrow={true}
                        placeholder="Select a team"
                        dropdownStyle={{zIndex: "10000"}}
                        value={selectedTeam}
                        onChange={setSelectedTeam}>
                        {
                            formTeams.map((team) => (
                                <Select.Option key={team.team_uuid} value={team.team_uuid}>{team.name}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
            }
            {
                selectedForm === undefined &&
                <div>
                    <p className="zero-dark-grey">Select a {props.page_type === "regular" ? "template" : "course"} for
                        this submission:</p>
                    <Select
                        virtual={false}
                        style={{width: "100%",}}
                        className="move-team-select"
                        showArrow={true}
                        loading={loadingForms}
                        disabled={loadingForms}
                        placeholder={`Select a ${props.page_type === "regular" ? "template" : "course"}...`}
                        dropdownStyle={{zIndex: "10000"}}
                        value={selectedForm}
                        notFoundContent={`No ${props.page_type === "regular" ? "templates" : "courses"} available...`}
                        onChange={handleFormSelect}>
                        {
                            forms.map((form) => (
                                <Select.Option key={form.form_uuid} value={form.form_uuid}>{form.name}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
            }
        </Modal>)
    );
}
