import React, {Component} from 'react';
import {connect} from 'react-redux'

import {kiosk_get_sessions, kiosk_turn_off} from '../../../api/zero-api.js'
import {safe_get, scrollToTop, mainContentContainerClass, dateFormatterNoTime} from '../../../other/Helper.js';

import Skeleton from 'react-loading-skeleton'

import {Tag, Popover, Menu, Dropdown} from 'antd'
import {EditOutlined, DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';

import NewEditKioskModal from './NewEditKioskModal';
import ConfirmModal from '../../Shared/ConfirmModal.js';
import NotificationAlert from '../../../other/NotificationAlert';

import {IoIosMore as MoreIcon} from 'react-icons/io';
import kioskMode from "../AccountSettings/KioskMode";

class KioskModeSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            kiosk_sessions: []
        };

    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getKioskSessions();
    }

    getKioskSessions = () => {
        var self = this;
        kiosk_get_sessions().then(function (success) {
            success.json().then(success => {
                var kiosk_sessions = safe_get(success, "sessions", []);
                self.setState({kiosk_sessions: kiosk_sessions, loading: false, editKiosk: undefined});
            });
        }, function (error) {
            self.setState({loading: false});
            console.log(error);
        });
    }

    deviceNameFormatter(device) {
        return (
            <span className="zero-dark-grey">
        {device.device_name}       
      </span>
        );
    }

    statusFormatter(device) {
        if (device.aged) {
            return <Tag style={{color: "#505050"}}>Inactive &gt; 30 days</Tag>
        } else {
            return <Tag color="green" style={{borderColor: "#52c41a"}}>Active</Tag>
        }
    }


    optionsFormatter = (device) => {
        const menu = {
            onClick: e => this.handleMenuClick(e.key, device),
            items: [
                {
                    key: 'edit',
                    icon: <EditOutlined/>,
                    label: 'Edit Kiosk',
                },
                {
                    type: 'divider',
                },
                {
                    key: 'delete',
                    label: 'Delete Kiosk',
                    icon: <DeleteOutlined/>,
                    className: 'zero-delete-red',
                },
            ]
        }

        return (
            <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                <Dropdown menu={menu} trigger={['click']}>
                    <button className="ButtonLink ant-dropdown-link">
                        <MoreIcon style={{height: '20px', width: '20px'}}/>
                    </button>
                </Dropdown>
            </div>
        );
    }

    handleMenuClick = (key, device) => {
        // console.log(device)
        if (key === "edit") {
            this.setState({editKiosk: device, showKioskModal: true});
        } else if (key === "delete") {
            this.setState({remove_session: device, showDeleteModal: true});
        }
    }

    removeConfirmation = (device) => {
        this.setState({
            remove_session: device,
            showDeleteModal: true
        });
    }

    removeSession = () => {
        var self = this;
        var kiosk_devices = [...this.state.kiosk_sessions];

        var index = kiosk_devices.indexOf(this.state.remove_session);
        var is_current_session = this.state.remove_session.is_current

        kiosk_turn_off(this.state.remove_session.session_uuid).then(function (success) {
            if (index >= 0) {
                kiosk_devices.splice(index, 1);
                self.setState({remove_session: "", showDeleteModal: false, table: kiosk_devices});
                self.getKioskSessions();
                NotificationAlert('success', 'Success!', "Kiosk has been turned off.");
            }

            if (is_current_session) {
                window.location = "/login";
            }

        }, function (error) {
            self.setState({remove_session: "", showDeleteModal: false});
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to turn off kiosk.");
        });
    }

    render() {
        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.showKioskModal &&
                    <NewEditKioskModal
                        show={this.state.showKioskModal}
                        cancel={() => {
                            this.setState({showKioskModal: false})
                        }}
                        editKiosk={this.state.editKiosk}
                        refresh={this.getKioskSessions}
                    />
                }

                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        show={this.state.showDeleteModal}
                        cancel={() => {
                            this.setState({showDeleteModal: false})
                        }}
                        confirm={this.removeSession}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this Kiosk?"}
                        confirmButtonName={"Delete Kiosk"}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage Kiosks
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '145px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({showKioskModal: true});
                                            }} disabled={this.props.kiosk_mode}>
                                                <PlusCircleOutlined/> Create Kiosk
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({showKioskModal: true});
                                            }}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            As an Admin or Team Lead, you can enable Kiosk Mode on any device. Kiosk Mode allows many
                                            users to login securely from a single, shared device. To learn more,
                                            click <a rel="noopener noreferrer"
                                                     onClick={() => FreshworksWidget('open', 'article', {'id': 70000416835})}
                                                     className="blue-link">here</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile">
                    <div className="table-responsive" style={{
                        backgroundColor: "white",
                        border: "1px solid #DDDDDD",
                        borderRadius: "5px",
                        maxWidth: "850px",
                        margin: "0 auto"
                    }}>
                        <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                            <thead style={{color: "#1D2A35"}}>
                            <tr>
                                <th></th>
                                <th className="zero-blue">
                                    Kiosk Name
                                </th>
                                <th className="zero-blue">
                                    Created On
                                </th>
                                <th className="zero-blue">
                                    Last Access
                                </th>
                                <th className="zero-blue">
                                    Status
                                </th>
                            </tr>
                            </thead>
                            {
                                this.state.loading &&
                                <tbody style={{borderTop: "0px"}}>
                                {
                                    [...Array(8)].map((_item, index) => (
                                        <tr key={index} className="tr-hover">
                                            <td><Skeleton width={25}/></td>
                                            <td className="text-left"><Skeleton width={100}/></td>
                                            <td className="text-left"><Skeleton width={100}/></td>
                                            <td className="text-left"><Skeleton width={100}/></td>
                                            <td className="text-left"><Skeleton width={60} height={22}/></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                !this.props.loading &&
                                <tbody style={{borderTop: "0px"}}>
                                {
                                    this.state.kiosk_sessions.map((device, index) => (
                                        <tr key={index}
                                            className={"tr-hover" + (safe_get(device, "access_time", "").toLowerCase() === "current session" ? " tr-current-session" : "")}>
                                            <td>{this.optionsFormatter(device)}</td>
                                            <td className="text-left zero-dark-grey">{this.deviceNameFormatter(device)}</td>
                                            <td className="text-left zero-dark-grey">{dateFormatterNoTime(device.created_at)}</td>
                                            <td className="text-left zero-dark-grey">{device.last_access}</td>
                                            <td className="text-left zero-dark-grey">{this.statusFormatter(device)}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                !this.state.loading && this.state.kiosk_sessions.length === 0 &&
                                <tbody style={{borderTop: "0px"}}>
                                <tr>
                                    <td className="react-bs-table-no-data" colSpan="6"
                                        style={{color: 'var(--zero-dark-grey)'}}>
                                        No kiosks.
                                    </td>
                                </tr>
                                </tbody>
                            }
                        </table>
                    </div>

                    <div style={{margin: '0 auto', maxWidth: '800px', paddingLeft: '5px'}}>
                        <p id="postidnumber" className="text-md text-main text-bold pad-top text-center"
                           style={{color: '#BCBABC'}}>Note: To protect your account, we will automatically delete any
                            kiosks that have been inactive for 90 days.</p>
                    </div>

                </div>
            </div>

        );


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        kiosk_mode: safe_get(store, "app.kiosk_mode", false),
    }
}

export default connect(mapStateToProps)(KioskModeSettings);


