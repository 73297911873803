import {useEffect, useRef} from "react";

import useOfflineMode from "./useOfflineMode";
import {useOfflineDataCachesContext} from "./OfflineDataCaches";
import {DebugLogger} from 'other/DebugLogger';
import {useSelector} from "react-redux";
import { useLocation } from "react-router";
import { useZeroContext } from "components/ZeroContext";

const syncIntervalMs = 9 * 60 * 1000; // 9 minutes
const debugLogger = DebugLogger.getLogger("SyncManager");

function isFormSubmissionPage(pathname) {
    return pathname.includes("forms") && pathname.includes("submission");
}

export default function SyncManager() {
    const zeroContext = useZeroContext();
    const location = useLocation();
    const pauseSyncs = useRef(isFormSubmissionPage(location.pathname));
    const retrySyncAfterNavigation = useRef(null);
    const offlineAllowed = useSelector(state => state.org_helper.organization.feature_flags.offline);
    
    const {isOffline} = zeroContext;
    const {sync} = zeroContext.caches;

    function initialSync() {
        // allow one-time sync when coming from online/offline, even if offline feature is disabled
        if (!pauseSyncs.current) {
            sync(true);
        } else {
            debugLogger.log("Skipping sync because syncing is paused");
            retrySyncAfterNavigation.current = 'initial';
        }
    }

    function intervalSync() {
        if (offlineAllowed && !pauseSyncs.current) {
            sync();
        } else if (pauseSyncs.current) {
            debugLogger.log("Skipping interval sync because syncing is paused");
            if (retrySyncAfterNavigation.current !== 'initial') {
                retrySyncAfterNavigation.current = 'interval';
            }
        }
    }

    useEffect(() => {
        // don't sync when on a form submission page
        if (isFormSubmissionPage(location.pathname)) {
            pauseSyncs.current = true;
            debugLogger.log("On a form submission page: pausing sync cycle");
        } else if (pauseSyncs.current) {
            pauseSyncs.current = false;
            debugLogger.log("Unpausing sync cycle");
            if (retrySyncAfterNavigation.current === 'initial') {
                initialSync();
            } else if (retrySyncAfterNavigation.current === 'interval') {
                intervalSync();
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        let intervalTimer = null;
        let timeoutTimer = null;

        if (!isOffline) {
            timeoutTimer = setTimeout(initialSync, 200);
            intervalTimer = setInterval(intervalSync, syncIntervalMs);
        } else if (isOffline) {
            clearTimeout(timeoutTimer);
            clearInterval(intervalTimer);
        }

        return () => {
            clearTimeout(timeoutTimer);
            clearInterval(intervalTimer);
        }
    }, [isOffline, sync, offlineAllowed, pauseSyncs]);

    return null;
}