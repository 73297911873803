import { loadGoogleMaps } from "other/Helper";
import { useEffect, useState } from "react";

import Autocomplete from 'react-google-autocomplete';

export default function GoogleMapsAutocomplete(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadGoogleMaps().then(() => setLoading(false));
    }, []);

    if (loading) {
        return null;
    }

    return <Autocomplete options={{
        types: props.types,
        componentRestrictions: props.componentRestrictions
    }} {...props} />;
}