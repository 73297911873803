export default function reducer(state = {
    filter: undefined
}, action) {

    switch (action.type) {

        case "update_members_filter": {
            return Object.assign({}, state, {
                filter: action.payload
            });
        }

        default: {
            return state;
        }

    }

}