import {my_form_assignments} from "api/zero-api";
import BaseCache from "./BaseCache";

/**
 * Gets form assignments from API and overwrites existing DB with results.
 * @param {FormAssignmentsCache} cache
 */
export async function syncFormAssignments(cache) {
    try {
        const params = new URLSearchParams();
        params.set('only_open', 'true');
        params.set('form_types', 'regular');

        const res = await my_form_assignments(`?${params}`);
        const content = await res.json();
        if (content) {
            const assignments = content.assignments;
            await cache.setAll(assignments);
        } else {
            throw new Error('content is empty');
        }
    } catch (error) {
        console.error('Could not sync form assignments:', error);
    }
}

export default class FormAssignmentsCache extends BaseCache {
    /**
     * @param {string} orgId
     * @param {string} userId
     */
    constructor(orgId, userId) {
        super('form_assignments', 'assignment_uuid', orgId, userId);
    }

    /**
     * @param {string} assignmentId
     * @param {string} submissionId
     * @param {bool} completed
     */
    async linkSubmission(assignmentId, submissionId, completed) {
        const currentDoc = await this.get(assignmentId);
        if (currentDoc._id) {
            await this.set(assignmentId, {
                ...currentDoc,
                completed,
                completed_at: Date.now() / 1000,
                submission_uuid: submissionId
            });
        }
    }

    /**
     * @param {string} assignmentId
     * @param {string} submissionId
     */
    async unlinkSubmission(assignmentId, submissionId) {
        const currentDoc = await this.get(assignmentId);
        if (currentDoc._id && currentDoc.submission_uuid === submissionId) {
            await this.set(assignmentId, {
                ...currentDoc,
                completed: false,
                completed_at: null,
                submission_uuid: null
            });
        }
    }
}