import { Dropdown, Space, Tag } from "antd";
import AlternateWrapper from "components/Shared/AlternateWrapper";
import VideoThumbnail from "components/Shared/VideoThumbnail";
import dayjs from "dayjs";
import useOrganizationId from "hooks/useOrganizationId";
import { getFileThumbnail, isImage, isRestrictedTeamUser, isVideo, isViewer, postImageContain, safe_get } from "other/Helper";
import { useEffect, useLayoutEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import {IoIosMore as MoreIcon} from 'react-icons/io';

import ZEROlogo from '../../../assets/css/img/ZERO-(blue)-small.png'
import PostFormEmbeddedForm from "../PostForm/PostFormEmbeddedForm";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import { useZeroContext } from "components/ZeroContext";
import Flex from "@react-css/flex";

function OptionsMenu({user, team, menu}) {
    if (isViewer(user) || isRestrictedTeamUser(team, user)) {
        return null;
    }

    return (
        <div
            className="more pull-right"
            style={{display: "inline-block", marginTop: "3px", color: "grey", lineHeight: 0}}
        >
            <Dropdown menu={menu} trigger={['click']}
                        placement="bottomRight">
                <button className="ButtonLink ant-dropdown-link">
                    <MoreIcon style={{height: '20px', width: '20px'}}/>
                </button>
            </Dropdown>
        </div>
    )
}

function PostTitle({user, post, canEditTeam, postLink}) {
    const dueDate = post.due_date ? dayjs.unix(post.due_date) : undefined;

    const headerClassNames = ["bulletinViewTitle"];
    if (!postLink) {
        headerClassNames.push("not-a-link");
    }

    return (
        <AlternateWrapper
            MainWrapper={Link}
            to={postLink}
            useAltWrapper={!postLink}
        >
            <div style={{wordWrap: 'break-word'}}>
                <Flex gap='0.5rem' style={{paddingRight: '5px', paddingTop: '3px'}}>
                    <h2 className={headerClassNames.join(' ')}>{post.title}</h2>
                    {post.status === "open" && dueDate < dayjs() &&
                        <div>
                            <Tag color="red" style={{
                            fontSize: "11px",
                            fontWeight: "500",
                            margin: 0,
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '1px 8px'
                        }}>Overdue</Tag>
                        </div>
                    }
                    {
                        !canEditTeam && isRestrictedTeamUser(post.source_team, user) &&
                        <div>
                            <Tag color="gold" style={{
                                fontSize: "11px",
                                fontWeight: "500",
                                margin: 0,
                                padding: '1px 8px'
                            }}>View Only</Tag>
                        </div>
                    }
                </Flex>
            </div>
        </AlternateWrapper>
    )
}

function PostAttachment({file, index, onOpenPreview, handleFilePreviewer}) {
    const file_name = safe_get(file, "file_name", "");
    const thumbnail = getFileThumbnail(file);

        if (isVideo(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <VideoThumbnail
                        imgSrc={thumbnail}
                        imgAlt={file_name}
                        onPlay={() => {
                            onOpenPreview(index)
                        }}
                    />
                </li>
            )
        } else if (isImage(file_name)) {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px"}}>
                    <button className="ButtonLink" onClick={(e) => {
                        onOpenPreview(index);
                    }}>
                        <img
                            className="post-img"
                            src={thumbnail}
                            alt={file_name}
                            style={{
                                objectFit: 'contain',
                                width: "100px",
                                height: "100px",
                                borderRadius: "2px",
                                backgroundColor: "#f6f6f6",
                                border: "1px solid #e5e5e5"
                            }}
                        />
                    </button>
                </li>
            )
        } else {
            return (
                <li style={{marginRight: "5px", marginBottom: "5px", height: "100px", width: "100px"}}>
                    <button className="ButtonLink" style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "2px",
                        backgroundColor: "#f6f6f6",
                        border: "1px solid #e5e5e5",
                        position: "absolute"
                    }} onClick={() => {
                        handleFilePreviewer(file, index);
                    }}>
                        <img src={thumbnail} alt={file_name}
                             style={{
                                 objectFit: 'cover',
                                 width: "55px",
                                 height: "55px",
                                 padding: "5px",
                                 display: "block",
                                 margin: "0 auto"
                             }}/>
                        <p className="zero-dark-grey ellipsis-2-lines" style={{
                            margin: "0px",
                            padding: "0px 5px 5px 5px",
                            fontSize: "80%",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                        title={file_name}
                        >{file_name}</p>
                    </button>
                </li>
            )
        }
}

export function getPostBodyHtml(post) {
    const links = post.links ?? [];
    let text = post.body ?? "";

    if (links.length > 0) {
        const domParser = new DOMParser();
        const postBodyDom = domParser.parseFromString(text, 'text/html');
        const smartLinks = postBodyDom.querySelectorAll('a[data-zero-link]');

        for (let link of smartLinks) {
            const index = parseInt(link.dataset.zeroLink, 10);
            link.innerHTML = `<img src="${ZEROlogo}"/>${links[index]}`;
        }

        const fakeEl = document.createElement('div');
        fakeEl.append(...postBodyDom.body.childNodes);

        text = fakeEl.innerHTML;
    }
    return {__html: text}
}

export default function PostContent({post, postDetailsRef, canEditTeam, menu, postLink, attachments, onOpenPreview, handleFilePreviewer}) {
    const user = useSelector(state => state.user.user);
    const orgId = useOrganizationId();
    const [attachmentsLoading, setAttachmentsLoading] = useState(false);
    const [minHeight, setMinHeight] = useState(280);
    const embeddedSubmissionsLoader = useAsyncDataLoader({useBackgroundRefresh: true});
    const context = useZeroContext();
    const formService = context.services.forms;

    useEffect(() => {
        embeddedSubmissionsLoader.load(async () => {
            const submissions = [];

            for (const embeddedForm of post.embedded_forms) {
                try {
                    let submission = context.kv.get(embeddedForm.submission_uuid);
                    if (!submission) {
                        submission = await formService.loadEmbeddedSubmission(embeddedForm);
                        context.kv.set(embeddedForm.submission_uuid, submission);
                    }
                    submissions.push(submission);
                } catch (err) {
                    console.error(err);
                }
            }

            return submissions;
        })
    }, [post.embedded_forms]);

    useLayoutEffect(() => {
        if (postDetailsRef.current) {
            setMinHeight(postDetailsRef.current.getBoundingClientRect().height);
        }
    }, [postDetailsRef.current]);

    return (
        <div style={{minHeight}}>
            <OptionsMenu user={user} team={post.source_team} menu={menu} />
            <PostTitle user={user} post={post} postLink={postLink} canEditTeam={canEditTeam} />
            <div className="mar-y-10">
                <span className="post-view-body-content" dangerouslySetInnerHTML={getPostBodyHtml(post)}/>
            </div>
            { attachments.length > 0 &&
                <LazyLoad 
                    offset={250}
                    onContentVisible={() => {
                        setAttachmentsLoading(false);
                        postImageContain("img.post-img");
                    }}
                >
                    <ul className="thumb">
                        {
                            attachments.map((attachment, index) => (
                                <span key={index}>
                                    <PostAttachment file={attachment} index={index} onOpenPreview={onOpenPreview} handleFilePreviewer={handleFilePreviewer} />
                                </span>
                            ))
                        }
                    </ul>
                </LazyLoad>
            }
            { attachments.length > 0 && attachmentsLoading &&
                <ul className="thumb">
                    {
                        attachments.map((_, index) => (
                            <li key={index} style={{marginRight: "5px"}}>
                                <Skeleton height={100} width={100}/>
                            </li>
                        ))
                    }
                </ul>
            }
            { post.linked_submission_uuid &&
                <div className="mar-btm-5">
                    <i className="zero-dark-grey">Post created via <Link className="blue-link" to={`/${orgId}/home/team/my_teams/forms/submission_view/${post.linked_submission_uuid}`}>form submission</Link>.</i>
                </div>
            }
            { post.linked_incident_uuid &&
                <div className="mar-btm-5">
                    <i className="zero-dark-grey">Post created via <Link className="blue-link" to={`/${orgId}/home/incidents/location/all_locations/edit_incident/${post.linked_incident_uuid}/1`}>incident submission</Link>.</i>
                </div>
            }
            { embeddedSubmissionsLoader.status === 'success' &&
                embeddedSubmissionsLoader.data.map((sub) => (<PostFormEmbeddedForm submission={sub} key={sub.submission_uuid} isEditable={false} />))
            }
        </div>
    )
}