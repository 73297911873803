import { loadGoogleMaps } from 'other/Helper';
import React, {Component} from 'react';
import {withGoogleMap, GoogleMap, Marker} from "react-google-maps"

const mapStyles = require("../other/GoogleMapsStyle.json");

class GoogleMaps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
        this.onMarkerClick = this.onMarkerClick.bind(this)
    }

    componentDidMount() {
        loadGoogleMaps().then(() => this.setState({loaded: true}));
        this.setState({
            lat: this.props.lat,
            lng: this.props.lng,
        })
    }

    shouldComponentUpdate(nextprops, nextstate) {
        // If shouldComponentUpdate returns false, 
        // then render() will be completely skipped until the next state change.
        // In addition, componentWillUpdate and componentDidUpdate will not be called. 
        if (this.state !== nextstate) {
            return true
        }
        return false
    }

    onMarkerClick(e) {
        var url = "https://www.google.com/maps/search/" + this.props.location + "?hl=en"
        window.open(url, '_blank');
    }

    render() {
        if (!this.state.loaded) {
            return null;
        }
        
        const GoogleMapsComponent = withGoogleMap((props) =>
            <GoogleMap defaultZoom={14} defaultCenter={{lat: this.props.lat, lng: this.props.lng}}
                       defaultOptions={{styles: mapStyles}}>
                <Marker position={{lat: this.props.lat, lng: this.props.lng}} onClick={this.onMarkerClick}/>
            </GoogleMap>
        )

        if (this.props.onlyMap) {
            return (
                <GoogleMapsComponent
                    // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBz2aIVE8OM3coP8_As2X6ISuS0zeb9EHQ"
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `150px`, width: `250px`}}/>}
                    mapElement={<div style={{height: `100%`}}/>}
                />
            );
        } else {
            return (
                <GoogleMapsComponent
                    // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBz2aIVE8OM3coP8_As2X6ISuS0zeb9EHQ"
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `270px`, width: '100%'}}/>}
                    mapElement={<div style={{height: `100%`}}/>}
                />
            );
        }

    }
}


export default GoogleMaps;
