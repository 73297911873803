import React, {useCallback, useState} from 'react';
import {Modal} from 'antd';

import ButtonLoading from '../Shared/ButtonLoading';

function PostNotificationReportModal({confirm, cancel, hasConfirmed}) {
    const [confirming, setConfirming] = useState(false);

    const onGenerateClick = () => {
        confirm();
        setConfirming(true);
    }

    const renderFooter = useCallback(() => {
        return (
            <div>
                {
                    hasConfirmed &&
                    <>
                        <button className="btn btn-primary" onClick={cancel}>Done</button>
                    </>
                }
                {
                    !hasConfirmed &&
                    <>
                        <button className="btn btn-discard" onClick={cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={onGenerateClick}>
                            {confirming ? <ButtonLoading/> : 'Generate Report'}
                        </button>
                    </>
                }
            </div>
        );
    }, [hasConfirmed, confirming]);

    return (
        (<Modal
            title="Generate Post Notification Report"
            open={true}
            onCancel={cancel}
            footer={renderFooter()}
        >
            {
                hasConfirmed &&
                <p>We're generating that export for you! It should be in your inbox shortly. Please contact <a
                    className="blue-link" href={"mailto:support@teamzero.com"}>support@teamzero.com</a> with any
                    questions.</p>
            }
            {
                !hasConfirmed &&
                <p>Generate an excel report that contains a list of all users who recevied a notification about this
                    post and what types of notifications they received.</p>
            }
        </Modal>)
    );
}

export default PostNotificationReportModal;