import { useCachedApiLoader } from 'components/Util/CachedApiLoader';
import {useRef, useState} from 'react';

export default function useDataLoader(apiEndpoint, options = {}) {
    const cachedApiLoader = useCachedApiLoader();
    const [data, setData] = useState({});
    const timer = useRef(null);

    const fetchData = async (fetchOptions, metaFetchOptions = {}) => {
        try {
            timer.current = setTimeout(() => {
                setData({loading: true});
                timer.current = null;
            }, 250);

            let data;

            if (options.cacheResults) {
                data = await cachedApiLoader.fetch(apiEndpoint, fetchOptions, {bypassCache: metaFetchOptions?.bypassCache ?? false});
            } else {
                const response = await apiEndpoint(fetchOptions);
                data = await response.json();
            }

            clearTimeout(timer.current);
            timer.current = null;

            setData({
                ok: true,
                data,
            });
        } catch (err) {
            console.error(err);
            setData({
                error: err,
            });
        }
    }

    return [fetchData, data];
}