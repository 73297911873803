import {useEffect, useState} from 'react';

import {get_check_in} from 'api/zero-api.js';


export default function useCheckInLoader(checkInId) {
    const [checkIn, setCheckIn] = useState({loading: true});

    const fetchCheckIn = async () => {
        try {
            const response = await get_check_in(checkInId);
            const data = await response.json();

            setCheckIn({
                ok: true,
                data,
            });
        } catch (err) {
            setCheckIn({
                error: err,
            });
        }
    }

    useEffect(() => {
        if (checkInId) {
            fetchCheckIn();
        } else {
            setCheckIn({});
        }
    }, [checkInId]);

    return checkIn;
}