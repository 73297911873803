import { Input } from "antd";
import {useCallback, useEffect, useState} from "react"

export default function DelayedTextInput({
                                             delay = 1000, defaultValue = '', showCharacterCounter = false,
                                             useAntInput = false,
                                             onChange = () => {
                                             },
                                             ...props
                                         }) {
    const [value, setValue] = useState(defaultValue);
    const [originalValue, setOriginalValue] = useState(defaultValue);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if (originalValue !== defaultValue) {
            setOriginalValue(defaultValue);

            if (!timer) {
                setValue(defaultValue);
            }
        }
    }, [originalValue, defaultValue, timer]);

    const handleChange = useCallback((e) => {
        if (timer) clearTimeout(timer);

        const newValue = e.target.value;
        setValue(newValue);
        setTimer(setTimeout(
            () => {
                (newValue !== defaultValue) && onChange(newValue)
            },
            delay
        ));
    }, [timer, delay, defaultValue, onChange]);

    const handleBlur = useCallback((e) => {
        if (timer) clearTimeout(timer);
        if (value !== defaultValue) onChange(value);
        setTimer(null);
    }, [value, timer, defaultValue, onChange]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            // enter key was pressed
            handleBlur();
        }
    }, [handleBlur]);

    return (
        <>
            {useAntInput
                ? (
                    <Input
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        className="zero-dark-grey"
                        {...props}
                    />
                ) : (
                    <input
                        type='text'
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        {...props}
                    />
                )
            }
            
            {showCharacterCounter &&
                <p className="text-sm" style={{color: '#BCBABC', paddingTop: '3px', width: "250px"}}>
                    {props.maxLength &&
                        <>
                            <span>Max {props.maxLength} characters</span>
                            <span style={{float: "right"}}>{props.maxLength - value.length}</span>
                        </>
                    }
                    {!props.maxLength &&
                        <span style={{float: "right"}}>{value.length}</span>
                    }
                </p>
            }
        </>
    )
}