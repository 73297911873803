export function update_teams(teams) {
    return {
        type: "update_teams",
        payload: teams
    }
}

export function update_current_team(team) {
    return {
        type: "update_current_team",
        payload: team
    }
}

export function reset_teams() {
    return {
        type: "RESET_TEAMS",
        payload: []
    }
}

