import React, {Component} from 'react';
import {connect} from 'react-redux';
import {EditOutlined} from '@ant-design/icons';

import safe_get from '../../other/SafeGet.js';
import {scrollToTop, mainContentContainerClass} from '../../other/Helper.js';

import {get_form} from '../../api/zero-api.js';

import FormBuilderComponent from './FormBuilderComponent.js';

import LoadingIndicator from '../Shared/LoadingIndicator.js';

class EditForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "submit",
            form: undefined,
            form_title: "",
            loading: true,
        };

        this.getForm = this.getForm.bind(this);

    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getForm(this.props.match.params.form_uuid);
    }

    getForm(form_uuid) {
        var self = this;
        get_form(form_uuid).then(function (success) {
            success.json().then(success => {
                self.setState({
                    form: safe_get(success, "form", {}),
                    form_title: safe_get(success, "form.name", ""),
                    loading: false
                });
            });
        }, function (error) {
            self.setState({loading: false});
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <LoadingIndicator/>
            )
        }

        return (
            <div className={mainContentContainerClass()}>
                <FormBuilderComponent form_data={this.state.form} form_title={this.state.form_title} edit_mode={true}/>
            </div>
        )
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        organization: safe_get(store, "org_helper.organization", {}),
        current_team: safe_get(store, "teams_helper.team", ""),
        teams: safe_get(store, "teams_helper.teams", []),
    }
}

export default connect(mapStateToProps)(EditForm);
