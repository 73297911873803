import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

// import { get_share_form_options, make_form_copy } from '../../api/zero-api.js'

import {safe_get, dateFormatterFromString, dateFormatterNoTime} from '../../other/Helper.js';

import moment from 'moment'
import {Radio, Modal} from 'antd'
import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';

import '../../assets/css/antd-custom.css';

class AssignmentSelectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    onAssignmentSelect = (e) => {
        this.setState({
            selectedAssignment: e.target.value
        });
    }

    confirm = () => {
        this.props.confirm(this.state.selectedAssignment)
    }

    render() {
        return (
            (<Modal
                title="Complete open assignments?"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.submit}>
                            No, submit form
                        </button>
                        <button className="btn btn-modal" disabled={!this.state.selectedAssignment}
                                onClick={this.confirm}>
                            Yes, mark as complete
                        </button>
                    </div>
                }
            >
                <p>You currently have open assignments for this form. Select an assignment below to mark as
                    complete.</p>
                <Radio.Group onChange={this.onAssignmentSelect} style={{width: "100%"}}>
                    {
                        this.props.assignments.map((assignment, index) => {
                            if (!assignment.completed && !moment(assignment.due_date).isBefore(moment().format("YYYY-MM-DD"))) {
                                return (
                                    <React.Fragment key={index}>
                                        <Radio value={assignment.assignment_uuid}>
                                            <div style={{width: "100%"}}>
                                                <h5 className="mar-btm-5 zero-blue">
                                                    {assignment.scheduler_name}
                                                </h5>
                                                <p className="mar-btm-5 zero-dark-grey">
                                                    In {assignment.team_names[0]} {assignment.team_names.length - 1 > 0 && "and " + (assignment.team_names.length - 1) + " more..."}
                                                </p>
                                                <p>
                                                    <span className="zero-blue">
                                                        <ClockCircleOutlined className="mar-rgt-5"/>
                                                        Due {dateFormatterFromString(assignment.due_date)}
                                                    </span>
                                                </p>
                                            </div>
                                        </Radio>
                                        {index < (this.props.assignments.length - 1) &&
                                            <hr style={{margin: "10px 0px"}}/>
                                        }
                                    </React.Fragment>
                                )
                            }

                        })
                    }
                </Radio.Group>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(AssignmentSelectModal));