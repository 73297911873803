export function updateFormsFilterQuery(query) {
    return {
        type: "updateFormsFilterQuery",
        payload: query
    }
}

export function updateFormsPeriodQuery(query) {
    return {
        type: "updateFormsPeriodQuery",
        payload: query
    }
}

export function updateFormsTab(tab) {
    return {
        type: "updateFormsTab",
        payload: tab
    }
}

export function updateFormsPagePosition(position) {
    return {
        type: "updatePagePosition",
        payload: position
    }
}

export function updateFormsPagination(page) {
    return {
        type: "updatePagination",
        payload: page
    }
}

export function updateFiltersForms(forms) {
    return {
        type: "updateFiltersForms",
        payload: forms
    }
}

export function updateFiltersSchedules(schedules) {
    return {
        type: "updateFiltersSchedules",
        payload: schedules
    }
}
