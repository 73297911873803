import React, {Component} from 'react';
import {connect} from 'react-redux'

import {update_custom_post_field} from '../../../api/zero-api';
import {safe_get} from '../../../other/Helper.js'

import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Switch} from 'antd';

import NotificationAlert from '../../../other/NotificationAlert.js';

import * as organizationAction from '../../../store/actions/OrganizationActions'
import DelayedTextInput from 'components/Shared/DelayedTextInput';

var saveTimeout = null;

class ManageCustomField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custom_field_name: safe_get(this.props, "org.custom_post_field.name", ""),
            custom_field_enabled: safe_get(this.props, "org.custom_post_field.enabled", false),
        };

        this.onSwitch = this.onSwitch.bind(this);
        this.inputChange = this.inputChange.bind(this);
    }

    onSwitch(checked) {
        this.setState({custom_field_enabled: checked}, () => {
            this.save();
        });
    }

    inputChange(value) {
        this.setState({custom_field_name: value}, () => {
            this.save();
        });
    }

    save = () => {
        let self = this;
        let body = JSON.stringify({
            name: this.state.custom_field_name,
            enabled: this.state.custom_field_enabled
        });
        update_custom_post_field(body).then(success => {
            success.json().then(success => {
                let org = safe_get(success, "organization", {});
                self.props.dispatch(organizationAction.update_current_org(org));
                NotificationAlert("success", "", "Changes saved.");
            });
        }, error => {

        });
    }


    render() {

        return (
            <div>
                <div>
                    <p className="zero-blue" style={{display: 'inline-block'}}><b>Field Name</b></p>
                </div>
                <div style={{marginBottom: "5px"}}>

                    <div style={{display: "inline-block"}}>
                        <DelayedTextInput
                            className="form-control topic-field"
                            defaultValue={this.state.custom_field_name}
                            onChange={this.inputChange}
                            showCharacterCounter={true}
                            maxLength={15}
                        />
                    </div>

                    <Switch
                        checked={this.state.custom_field_enabled}
                        onChange={this.onSwitch}
                        style={{marginTop: '6px', marginLeft: '10px', verticalAlign: "top"}}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        org: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(ManageCustomField);