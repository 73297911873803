import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Alert, Dropdown, Menu} from 'antd';
import {
    UsergroupAddOutlined, UserAddOutlined, GlobalOutlined, PushpinOutlined, CheckCircleOutlined, ClockCircleOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {IoIosMore as MoreIcon} from 'react-icons/io';

import FloorPlan from './FloorPlan';
import {CheckInLocale, CheckInStatus, CheckInUserType} from 'other/Constants';
import {useCheckInStatsLoader} from 'hooks/spaces';
import {updateCheckIn, resetCheckIn} from 'store/slices/checkInOptions';
import NotificationAlert from 'other/NotificationAlert';


export default function CheckInAlert({style, checkInPage = false}) {
    const dispatch = useDispatch();
    const orgId = useSelector(state => state.org_helper.organization.organization_uuid);
    const user = useSelector(state => state.user.user);
    const createCheckInStatus = useSelector(state => state.checkInOptions.createCheckInStatus);
    const [hasDeclined, setHasDeclined] = useState(false);
    const [fetchStats, statsLoader] = useCheckInStatsLoader();
    const checkin = user.latest_checkin;

    const [message, setMessage] = useState('');
    const [description, setDescription] = useState('');
    const [checkInCount, setCheckInCount] = useState(0);

    useEffect(() => {
        fetchStats();
    }, []);

    useEffect(() => {
        if (!hasDeclined) return;

        const {ok, error, data} = createCheckInStatus;
        if (ok && data.status === CheckInStatus.FAILED) {
            window.location = `/${orgId}/checkin`;
        } else if (error) {
            NotificationAlert("error", "", "Unable to decline this check-in. Please try again.");
        }
    }, [createCheckInStatus, hasDeclined])

    const declineCheckIn = () => {
        setHasDeclined(true);
        dispatch(updateCheckIn({
            uuid: checkin.uuid,
            status: CheckInStatus.FAILED
        }));
    }

    useEffect(() => {
        if (checkin) {
            const isSelfCheckIn = checkin.user_type === CheckInUserType.SELF;

            if (checkin.status === CheckInStatus.PENDING) {
                const messageText = 'Check-in pending...';
                if (checkInPage) {
                    setMessage(messageText);
                } else {
                    setMessage(<Link to={`/${orgId}/checkin/${checkin.uuid}`}
                                     className="zero-blue link-hover">{messageText}</Link>)
                }
            } else {
                setMessage("You're checked in!");
            }

            const descriptionLines = {
                line1: null, // Desk name or Remote/Other text
                line2: null,
                line3: null,
            };

            if (checkin.locale === CheckInLocale.OFFICE) {
                const {desk} = checkin;
                const {space} = desk;
                const {location} = space;

                let fullDeskName = `${location.name} / ${space.name} / ${desk.name}`;
                if (!isSelfCheckIn && checkin.status === CheckInStatus.PENDING) {
                    fullDeskName = `${fullDeskName} [Checked in by ${checkin.created_by.full_name}]`;
                }

                if (space.floor_plan) {
                    descriptionLines.line1 = <FloorPlan floorPlan={space.floor_plan}>{fullDeskName}</FloorPlan>
                } else {
                    descriptionLines.line1 = <div>{fullDeskName}</div>
                }

                if (checkin.status === CheckInStatus.PENDING) {
                    if (checkInPage) {
                        descriptionLines.line2 =
                            <div>You are scheduled to go to the office today. Please check in below.</div>
                        descriptionLines.line3 =
                            <div>To decline this check-in and create a new one, <span onClick={declineCheckIn}
                                                                                      className="blue-link">click here.</span>
                            </div>
                    } else {
                        descriptionLines.line2 =
                            <div>You are scheduled to go to the office today. To complete check-in, <Link
                                to={`/${orgId}/checkin/${checkin.uuid}`} className="blue-link">click here.</Link></div>
                    }
                }
            } else if (checkin.locale === CheckInLocale.REMOTE) {
                descriptionLines.line1 = <div>You are working remotely today.</div>;
            } else {
                descriptionLines.line1 =
                    <div>{`Other: ${checkin.other_locale ? checkin.other_locale : 'No description given.'}`}</div>;
            }

            if (checkin.status === CheckInStatus.COMPLETED || checkin.status === CheckInStatus.NONE) {
                if (checkInPage) {
                    descriptionLines.line2 = <div>To create a new check-in, please continue below.</div>
                } else {
                    descriptionLines.line2 =
                        <div>To create a new check-in, <Link to={`/${orgId}/checkin`} className="blue-link">click
                            here.</Link></div>
                }
            }

            setDescription(
                <div>
                    {descriptionLines.line1}
                    {descriptionLines.line2}
                    {descriptionLines.line3}
                </div>
            )
        } else {
            setMessage(<Link to={`/${orgId}/checkin`} className="zero-blue link-hover">You're not checked in...</Link>);

            let count = checkInCount;
            if (statsLoader.ok) {
                count = statsLoader.data.users_checked_in;
                setCheckInCount(count);
            }

            setDescription(
                <>
                    <span>{`${count} ${count === 1 ? 'person has' : 'people have'} checked in so far today. `}</span>
                    <br></br>
                    <span>To check in, <Link to={`/${orgId}/checkin`}
                                             className="blue-link hover-cursor">click here.</Link></span>
                </>
            );
        }
    }, [checkin, statsLoader]);

    if (!user || !orgId) {
        return null;
    }

    if (!checkin && checkInPage) return null;

    let checkinUrl = `/${orgId}/checkin`;
    if (checkin?.status === CheckInStatus.PENDING) {
        checkinUrl = `/${orgId}/checkin/${checkin.uuid}`;
    }

    const menu = {
        items: [
            {
                label: <Link to={checkinUrl}><UserAddOutlined/> Check in</Link>
            },
            {
                label: <Link to={`/${orgId}/checkin?colleague=1`}><UsergroupAddOutlined/> Check in colleague</Link>
            },
            {
                label: <Link to={`/${orgId}/checkin?guest=1`}><UsergroupAddOutlined/> Check in guest</Link>
            },
            {
                label: <Link to={`/${orgId}/home/user_directory`}><GlobalOutlined/> View directory</Link>
            },
            {
                label: <Link to={`/${orgId}/home/view_maps`}><PushpinOutlined/> View map</Link>
            },
            {
                type: 'divider',
            },
            {
                label: <Link to={`/${orgId}/home/my_check_ins`}><CheckCircleOutlined/> My Check-Ins</Link>
            }
        ]
    }

    const outerMessage = (
        <div style={{display: 'flex', justifyContent: checkInPage ? 'flex-start' : 'space-between'}}>
            <div className="text-semibold zero-blue" style={{height: 28}}>{message}</div>
            {!checkInPage &&
                <div className="more" style={{fontSize: 14, lineHeight: 0, height: 'fit-content'}}>
                    <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
                        <button className="ButtonLink ant-dropdown-link">
                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                        </button>
                    </Dropdown>
                </div>
            }
        </div>
    )

    let alertType = 'warning';
    let icon = <ExclamationCircleOutlined/>;
    if (checkin) {
        if (checkin.status === CheckInStatus.PENDING) {
            alertType = 'warning';
            icon = <ClockCircleOutlined/>;
        } else {
            alertType = 'success';
            icon = <CheckCircleOutlined/>;
        }
    }

    return (
        <Alert
            className="ant-alert-message-no-bottom-margin"
            message={outerMessage}
            description={<span className="zero-dark-grey">{description}</span>}
            type={alertType}
            showIcon
            icon={icon}
            style={style}
        />
    );
}