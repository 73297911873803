import React, { Component } from "react";
import { connect } from "react-redux";

import { isAdmin, isTeamLead, safe_get } from "../../../other/Helper.js";

import { Radio, DatePicker } from "antd";

import Autocomplete from "components/Shared/GoogleMapsAutocomplete.js";
import $ from "jquery";
import dayjs from "dayjs";

class EmployeeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFields: isAdmin(this.props.user) || isTeamLead(this.props.user),
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.handleDoBChange = this.handleDoBChange.bind(this);
    this.handleDateHiredChange = this.handleDateHiredChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
  }

  componentDidMount() {
    $("#employee_street_address").val(this.props.street_address);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.props.onChange(name, value);
  }

  handleAddressSelect(location) {
    let address_components = safe_get(location, "address_components", []);

    var street = "";
    var city = "";
    var state = "";
    var zip = "";

    for (var i in address_components) {
      let item = address_components[i];
      let types = safe_get(item, "types", []);

      if (types.indexOf("street_number") >= 0) {
        street = street + safe_get(item, "long_name", "");
      } else if (types.indexOf("route") >= 0) {
        street = street + " " + safe_get(item, "short_name", "");
      } else if (types.indexOf("locality") >= 0) {
        city = safe_get(item, "short_name", "");
      } else if (types.indexOf("administrative_area_level_1") >= 0) {
        state = safe_get(item, "short_name", "");
      } else if (types.indexOf("postal_code") >= 0) {
        zip = safe_get(item, "long_name", "");
      }
    }

    // var address = location.formatted_address.split(", ");
    // var street = address[0];
    // var city = address[1];
    // var region = address[2].split(" ");
    // var state = region[0];
    // var zip = ""
    // for (var i = 1; i < region.length; i++) {
    //     zip = zip + " " + region[i]
    // }

    $("#employee_street_address").val(street);

    this.props.onChange("street_address", street);
    this.props.onChange("city", city);
    this.props.onChange("state", state);
    this.props.onChange("zip", zip);
  }

  handleDoBChange(value) {
    var date = null;
    if (value) {
      date = value.format("YYYY-MM-DD");
    }
    this.props.onChange("date_of_birth", date);
  }

  handleDateHiredChange(value) {
    var date = null;
    if (value) {
      date = value.format("YYYY-MM-DD");
    }
    this.props.onChange("date_hired", date);
  }

  handleGenderChange(event) {
    const target = event.target;
    const value = target.value;
    this.props.onChange("sex", value);
  }

  render() {
    const { fieldsDisabled } = this.props;

    return (
      <>
        <h3
          className="zero-blue print-only"
          style={{
            marginBottom: "20px",
            marginTop: "5px",
            fontSize: "120%",
            textDecoration: "underline",
          }}
        >
          Employee Info
        </h3>
        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0 required"
            style={{
              marginBottom: "5px",
              marginTop: "0px",
              fontSize: "100%",
              width: "50%",
            }}
          >
            Full Name
          </h3>
          <input
            name="full_name"
            className="form-control custom-placeholder"
            placeholder=""
            onChange={this.handleInputChange}
            value={this.props.full_name ? this.props.full_name : ""}
            style={{ width: "300px" }}
            disabled={fieldsDisabled}
          />
          {this.state.full_name_error && (
            <small className="error">{this.state.name_error}</small>
          )}
        </div>
        {!isAdmin(this.props.user) && !isTeamLead(this.props.user) && (
          <p
            className="blue-link mar-top-10 mar-btm-10 dont-print"
            style={{ fontWeight: "500" }}
            onClick={() => {
              this.setState({ showFields: !this.state.showFields });
            }}
          >
            Show {this.state.showFields ? "less" : "more"}
          </p>
        )}
        {this.state.showFields && (
          <>
            <div className="mar-btm-15">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                  width: "50%",
                }}
              >
                Job Title
              </h3>
              <input
                name="job_title"
                className="form-control custom-placeholder"
                placeholder=""
                onChange={this.handleInputChange}
                value={this.props.job_title}
                style={{ width: "300px" }}
                disabled={fieldsDisabled}
              />
              {this.state.job_title_error && (
                <small className="error">{this.state.job_title_error}</small>
              )}
            </div>
            <div className="row mar-btm-15">
              <div className="col-md-5">
                <h3
                  className="titles onboarding invite-link mt-0"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                    width: "50%",
                  }}
                >
                  Street
                </h3>
                <Autocomplete
                  id="employee_street_address"
                  className="form-control custom-placeholder"
                  style={{ width: "100%" }}
                  onPlaceSelected={this.handleAddressSelect}
                  onChange={this.handleInputChange}
                  types={["address"]}
                  name="street_address"
                  componentRestrictions={{ country: ["us", "ca"] }}
                  disabled={fieldsDisabled}
                  defaultValue={this.props.street_address}
                />
                {this.state.street_address_error && (
                  <small className="error">
                    {this.state.street_address_error}
                  </small>
                )}
              </div>
              <div className="col-md-3">
                <h3
                  className="titles onboarding invite-link mt-0"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                    width: "50%",
                  }}
                >
                  City
                </h3>
                <input
                  name="city"
                  className="form-control custom-placeholder"
                  placeholder=""
                  onChange={this.handleInputChange}
                  value={this.props.city}
                  disabled={fieldsDisabled}
                  // style={{ width: "300px"}}
                />
              </div>
              <div className="col-md-2">
                <h3
                  className="titles onboarding invite-link mt-0"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                    width: "50%",
                  }}
                >
                  State
                </h3>
                <input
                  name="state"
                  className="form-control custom-placeholder"
                  placeholder=""
                  onChange={this.handleInputChange}
                  value={this.props.state}
                  disabled={fieldsDisabled}
                  // style={{ width: "300px"}}
                />
              </div>
              <div className="col-md-2">
                <h3
                  className="titles onboarding invite-link mt-0"
                  style={{
                    marginBottom: "5px",
                    marginTop: "0px",
                    fontSize: "100%",
                    width: "50%",
                  }}
                >
                  ZIP
                </h3>
                <input
                  name="zip"
                  className="form-control custom-placeholder"
                  placeholder=""
                  onChange={this.handleInputChange}
                  value={this.props.zip}
                  disabled={fieldsDisabled}
                  // style={{ width: "300px"}}
                />
              </div>
            </div>

            <div className="mar-btm-15">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                }}
              >
                Date of Birth
              </h3>
              <div>
                <DatePicker
                  className="custom-range-picker"
                  placeholder="MM/DD/YYYY"
                  format={"MM/DD/YYYY"}
                  value={
                    this.props.date_of_birth
                      ? dayjs(this.props.date_of_birth, "YYYY-MM-DD")
                      : null
                  }
                  onChange={this.handleDoBChange}
                  allowClear={true}
                  style={{ width: "200px" }}
                  disabled={fieldsDisabled}
                />
              </div>
              {this.state.date_of_birth_error && (
                <small className="error">{this.state.city_error}</small>
              )}
            </div>
            <div className="mar-btm-15">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                }}
              >
                Date Hired
              </h3>
              <div>
                <DatePicker
                  className="custom-range-picker"
                  placeholder="MM/DD/YYYY"
                  format={"MM/DD/YYYY"}
                  value={
                    this.props.date_hired
                      ? dayjs(this.props.date_hired, "YYYY-MM-DD")
                      : null
                  }
                  onChange={this.handleDateHiredChange}
                  style={{ width: "200px" }}
                  disabled={fieldsDisabled}
                />
              </div>
              {this.state.date_hired_error && (
                <small className="error">{this.state.city_error}</small>
              )}
            </div>
            <div className="mar-btm-15">
              <h3
                className="titles onboarding invite-link mt-0"
                style={{
                  marginBottom: "5px",
                  marginTop: "0px",
                  fontSize: "100%",
                }}
              >
                Gender
              </h3>
              <div>
                <Radio.Group
                  disabled={fieldsDisabled}
                  onChange={this.handleGenderChange}
                  value={this.props.sex}
                >
                  <Radio className="zero-dark-grey" value={1}>
                    Male
                  </Radio>
                  <Radio className="zero-dark-grey" value={0}>
                    Female
                  </Radio>
                </Radio.Group>
              </div>
              {this.state.gender_error && (
                <small className="error">{this.state.gender_error}</small>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: safe_get(store, "user.user", {}),
    current_team: safe_get(store, "teams_helper.team", {}),
    teams: safe_get(store, "teams_helper.teams", []),
    all_assignments: safe_get(store, "assignments", {}),
  };
};

export default connect(mapStateToProps)(EmployeeInfo);
