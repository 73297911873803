import React, {Component} from 'react';

import {Modal} from 'antd';

import Skeleton from 'react-loading-skeleton';

import {get_schedules} from '../../api/zero-api.js'
import {safe_get} from '../../other/Helper';

import '../../assets/css/antd-custom.css'

class SchedulesListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            schedules: []
        };

    }

    componentDidMount() {
        var self = this;
        get_schedules().then(success => {
            success.json().then(success => {
                let schedules = safe_get(success, "schedulers", []).filter(function (schedule) {
                    return schedule.form.form_uuid === self.props.form.form_uuid
                });
                self.setState({schedules: schedules, loading: false});
            })
        }, error => {
            console.log(error);
        });
    }


    render() {
        return (
            (<Modal
                title={`Schedules using ${this.props.form.name}`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.props.cancel}>
                            Done
                        </button>
                    </div>

                }
            >
                <div>
                    {
                        this.state.loading &&
                        <div style={{maxHeight: "350px", overflow: "auto"}}>
                            {
                                [...Array(10)].map((_schedule, index) => (
                                    <div key={index}>
                                        <Skeleton width={150}/>
                                        <br/>
                                        <Skeleton width={80}/>
                                        <hr style={{margin: "10px 0px"}}/>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        !this.state.loading &&
                        <div style={{maxHeight: "350px", overflow: "auto"}}>
                            {
                                this.state.schedules.map((schedule, index) => (
                                    <div key={index}>
                                        <button className="ButtonLink btn-link" onClick={() => {
                                            this.props.goToSchedule(schedule.scheduler_uuid);
                                        }} style={{display: "block", fontWeight: "500"}}>
                                            {schedule.name}
                                        </button>
                                        <span className="zero-dark-grey" style={{fontStyle: "italic"}}>               
                                            {
                                                schedule.repeat_period === 0 ? "Daily" :
                                                    schedule.repeat_period === 1 ? "Weekday" :
                                                        schedule.repeat_period === 2 ? "Weekly" :
                                                            schedule.repeat_period === 3 ? "Monthly" :
                                                                schedule.repeat_period === 4 ? "Send Once" :
                                                                    schedule.repeat_period === 5 ? "Quarterly" :
                                                                        schedule.repeat_period === 6 ? "Biannually" :
                                                                            schedule.repeat_period === 7 ? "Annually" :
                                                                                schedule.repeat_period === 8 ? "Weekends (Sat/Sun)" : ""
                                            }         
                                        </span>
                                        <hr style={{margin: "10px 0px"}}/>
                                    </div>
                                ))
                            }
                        </div>

                    }
                </div>
            </Modal>)
        );
    }
}

export default SchedulesListModal;