// @ts-check

import { useZeroContext } from "components/ZeroContext";
import { isEqual } from "lodash";
import { useMemo } from "react";
import { useFeedDataGridContext } from "./FeedDataGrid.context";
import { EventNames } from "./FeedDataGrid.utils";

const defaultSortModel = [
    {
        colId: "revised_at",
        sort: "desc",
    },
];

export default function ResetGridLink() {
    const { events } = useZeroContext();
    const { settings } = useFeedDataGridContext();

    const reset = () => {
        events.emit(EventNames.RESET_TABLE);
    };

    const hasDefaultSettings = useMemo(() => {
        if (!settings) {
            return true;
        }

        if (settings.columnOrder) {
            return false;
        }

        if (!settings.sort) {
            return false;
        }

        if (settings.sort) {
            const sortModel = settings.sort.sortModel;
            if (!isEqual(sortModel, defaultSortModel)) {
                return false;
            }
        }

        return true;
    }, [settings]);

    if (!hasDefaultSettings) {
        return (
            <span className="blue-link" onClick={reset}>
                Reset table
            </span>
        );
    }

    return null;
}
