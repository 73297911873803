import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';
import {CloseCircleTwoTone, ExclamationCircleTwoTone, CheckCircleTwoTone} from "@ant-design/icons";

import {get_incident_options, get_location} from '../../api/zero-api'
import {safe_get} from '../../other/Helper.js';

import {
    updateIncidentsFilterQuery,
    updateIncidentsPeriodQuery,
    update_current_location,
    updateEventTypes,
    updateIncidentTypes
} from '../../store/actions/IncidentsActions';

import TimePeriodFilter from '../TimePeriodFilter.js';
import {Select} from 'antd';

class IncidentsSearchFilter extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        const {location_uuid} = this.props.current_location;
        let selected_locations = [];
        if (location_uuid && location_uuid !== 'all_locations') {
            selected_locations.push(location_uuid);
        }

        this.state = {
            incident_types: [],
            incident_causes: [],
            event_types: [],
            period_query: "all_time",
            selected_locations,
            selected_filters: []
        };

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        // if (this.props.current_location.location_uuid === "all_locations") {
        //     this.props.dispatch(updateIncidentsFilterQuery(""));
        //     this.props.dispatch(updateIncidentsPeriodQuery("&period=all_time"));
        // }
        this.getFilters();
        this.addPrefilters();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.periodQuery !== this.props.periodQuery || prevProps.filterQuery !== this.props.filterQuery) {
            this.addPrefilters();
        }
    }

    getFilters = () => {
        var self = this;
        get_incident_options().then(function (success) {
            success.json().then(success => {
                let incident_types = safe_get(success, "data.incident_types", []);

                let incident_causes = safe_get(success, "data.incident_causes", []).filter(function (obj) {
                    return obj.enabled;
                });

                var incidentCausesEnabled = false;
                for (var i in incident_causes) {
                    let incident_cause = incident_causes[i];
                    if (incident_cause.enabled) {
                        incidentCausesEnabled = true;
                        break;
                    }
                }

                let event_types = safe_get(success, "data.event_types", []).filter(function (obj) {
                    return obj.enabled;
                });

                if (self._isMounted) {
                    self.setState({
                        incident_causes: incidentCausesEnabled ? incident_causes : [],
                        incident_types: incident_types,
                        event_types: event_types
                    });
                }

                self.props.dispatch(updateIncidentTypes(incident_types));
                self.props.dispatch(updateEventTypes(event_types));

            });
        }, function (error) {
            console.log(error)
        });

    }

    addPrefilters = () => {

        var query = this.props.filterQuery + this.props.periodQuery;
        var filters = query.split("&");

        filters = filters.filter(function (n) {
            return n !== "" && n
        });

        // var selected_team = this.props.team_uuid;

        var selected_filters = [];
        var selected_filters_UI = [];

        let location_uuid = null;

        for (var i in filters) {
            var filter = filters[i].split("=");
            var filter_type = filter[0];
            var filter_value = filter[1];

            switch (filter_type) {
                // case "team_uuid":
                //     selected_team = filter_value;
                //     break;
                case "location_uuid":
                    location_uuid = filter_value;
                    break;
                case "incident_type":
                case "injury_type":
                case "incident_cause_uuid":
                case "event_type_uuid":
                case "status":
                    selected_filters.push(filters[i]);
                    selected_filters_UI.push(filters[i]);
                    break;
                case "search":
                    selected_filters.push(filters[i]);
                    selected_filters_UI.push(filter_value);
                    break;
                default:
                    break;
            }
        }

        const newState = {
            // selected_team: selected_team,
            selected_filters: selected_filters,
            selected_filters_UI: selected_filters_UI
        };

        if (location_uuid !== null) {
            newState['selected_locations'] = location_uuid.split(',');
        }

        this.setState(newState);
    }

    filterOption = (inputValue, option) => {
        let item = safe_get(option, "children", "");

        if (typeof item === "string") {
            return item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (typeof item === "object") {
            return item[1].toLowerCase().includes(inputValue.toLowerCase());
        }
    }

    filterSelect = (value) => {
        let selected_filters = [];
        let selected_filters_UI = [];
        let existing_filters = [...this.state.selected_filters];

        let caseTypeOptionsDisabled = false;
        let injuryTypeOptionsDisabled = false;
        let incidentCauseOptionsDisabled = false;
        let eventTypeOptionsDisabled = false;
        let statusOptionsDisabled = false;

        value.forEach(element => {
            var filter = element
            if (!filter.includes("=")) {
                filter = "search=" + filter;
            }

            if (!existing_filters.includes(filter)) {

                let canAddFilter = Boolean(
                    !caseTypeOptionsDisabled && filter.includes("incident_type=") ||
                    !injuryTypeOptionsDisabled && filter.includes("injury_type=") ||
                    !incidentCauseOptionsDisabled && filter.includes("incident_cause_uuid=") ||
                    !eventTypeOptionsDisabled && filter.includes("event_type_uuid=") ||
                    !statusOptionsDisabled && filter.includes("status=") ||
                    filter.includes("search=")
                )

                if (canAddFilter) {
                    selected_filters.push(filter)
                    selected_filters_UI.push(element)
                }
            } else {
                selected_filters.push(filter)
                selected_filters_UI.push(element)
            }

        });

        this.setState({
            selected_filters: selected_filters,
            selected_filters_UI: selected_filters_UI
        });

        if (this.state.selected_locations.length > 0) {
            selected_filters.push(`location_uuid=${this.state.selected_locations}`)
        }

        let query = selected_filters.join("&");
        this.props.dispatch(updateIncidentsFilterQuery(query))
    }

    handleLocationSelect = (location_uuids) => {
        let selected_filters = [...this.state.selected_filters];

        if (location_uuids.length > 0) {
            selected_filters.push(`location_uuid=${location_uuids.join(',')}`);
        }

        let query = selected_filters.join("&");
        this.props.dispatch(updateIncidentsFilterQuery(query))

        this.setState({selected_locations: location_uuids});

        if (location_uuids.length > 0) {
            this.getLocation(location_uuids[0]);
        } else {
            this.getLocation('all_locations');
        }
    }


    getLocation = (location_uuid) => {
        var self = this;

        var location = {"location_uuid": location_uuid};
        this.props.dispatch(update_current_location(location));
    }

    periodSelected = (period) => {
        this.setState({period_query: period});
    }

    updatePeriodQuery = (query) => {
        this.props.dispatch(updateIncidentsPeriodQuery(query));
    }

    render() {
        return (
            <div style={{display: (this.props.activeTab === "reports" ? "none" : "block")}}>
                <div className="row" style={{padding: "0 0.5rem"}}>
                    <Select
                        mode="tags"
                        placeholder={"Filter or search..."}
                        className="col-xs-12 col-sm-6 col-reduce-padding"
                        style={{marginTop: '0.8rem'}}
                        value={this.state.selected_filters_UI}
                        onChange={this.filterSelect}
                        filterOption={this.filterOption}
                        showArrow={false}
                        virtual={false}
                    >
                        <Select.OptGroup label="Status">
                            <Select.Option value="status=open"><CloseCircleTwoTone twoToneColor="#bbb"
                                                                                   className="mar-rgt-5"/>Open</Select.Option>
                            <Select.Option value="status=in_review"><ExclamationCircleTwoTone twoToneColor="orange"
                                                                                              className="mar-rgt-5"/>In
                                Review</Select.Option>
                            <Select.Option value="status=closed"><CheckCircleTwoTone twoToneColor="#52c41a"
                                                                                     className="mar-rgt-5"/>Closed</Select.Option>
                        </Select.OptGroup>
                        <Select.OptGroup label={"Case Type"}>
                            {
                                this.state.incident_types.map((incident_type, index) => (
                                    <Select.Option key={"0" + index} value={"incident_type=" + incident_type.value}>
                                        {incident_type.name}
                                    </Select.Option>
                                ))
                            }
                        </Select.OptGroup>
                        <Select.OptGroup label={"Incident Type"}>
                            <Select.Option value={"injury_type=injury"}>Injury</Select.Option>
                            <Select.Option value={"injury_type=illness"}>Illness</Select.Option>
                            <Select.Option value={"injury_type=notset"}>No Incident Type</Select.Option>
                        </Select.OptGroup>
                        {
                            this.state.event_types.length > 0 &&
                            <Select.OptGroup label={"Event Type"}>
                                {
                                    this.state.event_types.map((event_type, index) => (
                                        <Select.Option key={"2" + index}
                                                       value={"event_type_uuid=" + event_type.event_type_uuid}>
                                            {event_type.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        }
                        {
                            this.state.incident_causes.length > 0 &&
                            <Select.OptGroup label={"Incident Cause"}>
                                {
                                    this.state.incident_causes.map((incident_cause, index) => (
                                        <Select.Option key={"1" + index}
                                                       value={"incident_cause_uuid=" + incident_cause.incident_cause_uuid}>
                                            {incident_cause.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select.OptGroup>

                        }
                    </Select>
                    <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                        <TimePeriodFilter noWrapperStyle={true} period_query={this.props.periodQuery}
                                          updateQuery={this.updatePeriodQuery} periodSelected={this.periodSelected}/>
                    </div>
                </div>
                <div className="row" style={{padding: "0 0.5rem"}}>
                    <Select
                        value={this.state.selected_locations}
                        onChange={this.handleLocationSelect}
                        virtual={false}
                        className="col-xs-12 col-sm-6 col-reduce-padding"
                        style={{marginTop: '0.8rem'}}
                        placeholder="Filter by location..."
                        mode="multiple"
                    >
                        {
                            this.props.incidents_locations.map((location, index) => (
                                <Select.Option key={index}
                                               value={location.location_uuid}>{location.name}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", []),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        user: safe_get(store, "user.user", {}),
        filterQuery: safe_get(store, "incidents.filterQuery", ""),
        periodQuery: safe_get(store, "incidents.periodQuery", ""),
        current_location: safe_get(store, "incidents.location", {}),
        incidents_locations: safe_get(store, "incidents.locations", []),
        organization: safe_get(store, "org_helper.organization", {}),
        activeTab: safe_get(store, "incidents.tab", "incidents")
    }
}

export default withRouter(connect(mapStateToProps)(IncidentsSearchFilter));
