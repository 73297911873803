import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from 'antd';

function TeamListPopover({ ellipses = true, sorted = false, teams, teamNames = [], children}) {
    if (teams) {
        teamNames = teams.map(team => team.name);
    } else if (teamNames) {
        teamNames = teamNames;
    }

    if (!sorted) {
        teamNames = teamNames.sort((a, b) => a < b ? -1 : 1);
    }

    const popoverContent = teamNames.map((name, index) => (
        <div key={index}>{name}</div>
    ));

    let innerContent = 'None';

    if (children) {
        innerContent = children;
    } else if (teamNames.length === 1) {
        innerContent = teamNames[0];
    } else if (teamNames.length === 2) {
        innerContent = `${teamNames[0]} and ${teamNames[1]}`;
    } else {
        innerContent = `${teamNames[0]} and ${teamNames.length - 1} more${ellipses ? '...' : ''}`;
    }

    if (teamNames.length === 0) {
        return null;
    } else if (teamNames.length < 3) {
        return innerContent;
    }

    return (
        <Popover content={popoverContent} placement="bottom" overlayStyle={{maxWidth: '220px'}}>
            {innerContent}
        </Popover>
    );
}

TeamListPopover.propTypes = {
    teams: PropTypes.array,
    teamNames: PropTypes.array,
    sorted: PropTypes.bool,
    ellipses: PropTypes.bool
};

export default TeamListPopover;