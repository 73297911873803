export default function reducer(state = {
    user: {},
    user_stats: {}
}, action) {

    switch (action.type) {

        case "update_user": {
            const newUser = {...action.payload};

            if (!newUser.hasOwnProperty('latest_checkin')) {
                newUser.latest_checkin = state.user.latest_checkin;
            }

            if (!newUser.hasOwnProperty('show_check_in_dashboard_alert')) {
                newUser.show_check_in_dashboard_alert = state.user.show_check_in_dashboard_alert;
            }

            return Object.assign({}, state, {
                user: newUser
            });
        }

        case "update_user_analytics": {
            return Object.assign({}, state, {
                user_stats: action.payload
            });
        }

        case "RESET_USER": {
            return Object.assign({}, state, {
                user: {},
                user_stats: {}
            });
        }

        default: {
            return Object.assign({}, state, {
                user: state.user,
                user_stats: state.user_stats
            });
        }

    }


}