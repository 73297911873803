import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Pagination, Image} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';

import ConfirmModal from 'components/Shared/ConfirmModal';
import CheckInAlertModal from './CheckInAlertModal';
import AlertsTable from './AlertsTable';
import DesksTable from './DesksTable';
import ManageDesksFilters from './ManageDesksFilters';
import {useDesksFiltersLoader, useDesksLoader, useDirectoryLoader, useCheckInAlertLoader} from 'hooks/spaces';
import {useTeamsLoader} from 'hooks/teams';
import {mainContentContainerClass} from 'other/Helper';
import {actions as manageDesksActions, deleteDesk} from 'store/slices/manageDesks';
import {actions as manageAlertsActions, deleteAlert} from 'store/slices/manageCheckInAlerts';
import {actions as manageSpacesActions, fetchLocations} from 'store/slices/manageSpaces';
import CreateEditDeskModal from './CreateEditDeskModal';
import ManageSpacesLocations from './ManageSpacesLocations';
import ManagersTable from './ManagersTable';
import useDebugLogger from 'hooks/useDebugLogger';
import FutureCheckInsPane from 'components/Spaces/FutureCheckInsPane';
import { CachedApiLoaderProvider } from 'components/Util/CachedApiLoader';

const tabs = {
    DESKS: 'desks',
    LOCATIONS: 'locations',
    ALERTS: 'alerts',
    MANAGERS: 'managers',
    CHECK_INS: 'check_ins'
}

function useUnifiedDataLoader() {
    const [fetchDesks, desksLoader] = useDesksLoader();
    const [fetchDirectory, directoryLoader] = useDirectoryLoader();
    const [fetchDeskFilters, filtersLoader] = useDesksFiltersLoader();
    const currentPage = useSelector(state => state.manageDesks.pagination.currentPage);
    const deskFilterQuery = useSelector(state => state.manageDesks.deskFilterQuery);
    const dispatch = useDispatch();
    const debugLogger = useDebugLogger("ManageDesks > useUnifiedDataLoader");

    useEffect(() => {
        fetchDirectory('?include_self=true&include_last_check_in=false');
        fetchDeskFilters();
    }, []);

    useEffect(() => {
        debugLogger.log("useEffect2; currentPage: %o; deskFilterQuery: %o", currentPage, deskFilterQuery);
        dispatch(manageDesksActions.setLoading(true));
        fetchDesks({query: `?page=${currentPage}${deskFilterQuery}`});
    }, [currentPage, deskFilterQuery]);

    useEffect(() => {
        const loaders = [
            desksLoader,
            directoryLoader,
            filtersLoader
        ];
        debugLogger.log("useEffect3; loaders: %o", loaders);
        const error = loaders.find(loader => !!loader.error === true);
        const ok = loaders.every(loader => loader.ok === true);
        debugLogger.log("useEffect3; error: %o", error);
        debugLogger.log("useEffect3; ok: %o", ok);


        if (error) {
            dispatch(manageDesksActions.setError(error));
        } else if (ok) {
            dispatch(manageDesksActions.updateManagedDesksState({
                loading: false,
                error: false,
                desks: desksLoader.data.results,
                directory: directoryLoader.data,
                filters: filtersLoader.data,
                pagination: {
                    currentPage,
                    maxResults: desksLoader.data.max_results,
                    total: desksLoader.data.total
                }
            }));
        } else {
            dispatch(manageDesksActions.setLoading(true));
        }
    }, [desksLoader, directoryLoader, filtersLoader]);

    return {
        fetchDesks,
        fetchDirectory,
        fetchDeskFilters,
    }
}

function useAlertDataLoader() {
    const [fetchTeams, teamsLoader] = useTeamsLoader();
    const [fetchAlerts, alertsLoader] = useCheckInAlertLoader();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchAlerts();
        fetchTeams();
    }, []);

    useEffect(() => {
        const loaders = [
            teamsLoader,
            alertsLoader,
        ];
        const error = loaders.find(loader => !!loader.error === true);
        const ok = loaders.every(loader => loader.ok === true);

        if (error) {
            dispatch(manageAlertsActions.setError(error));
        } else if (ok) {
            dispatch(manageAlertsActions.updateState({
                loading: false,
                error: false,
                alerts: alertsLoader.data,
                teams: teamsLoader.data.teams
            }));
        } else {
            dispatch(manageAlertsActions.setLoading(true));
        }
    }, [teamsLoader, alertsLoader]);
}

export default function ManageDesks() {
    const {fetchDesks, fetchDirectory, fetchDeskFilters} = useUnifiedDataLoader();
    useAlertDataLoader();
    const {tabName} = useParams();

    const skippedFirstActiveTab = useRef(false);
    const [activeTab, setActiveTab] = useState(tabName || tabs.DESKS);
    const pagination = useSelector(state => state.manageDesks.pagination);
    const deskToEdit = useSelector(state => state.manageDesks.deskToEdit);
    const deskModalVisible = useSelector(state => state.manageDesks.deskModalVisible);
    const deleteDeskModalVisible = useSelector(state => state.manageDesks.deleteDeskModalVisible);
    const alertModalVisible = useSelector(state => state.manageCheckInAlerts.alertModalVisible);
    const deleteAlertModalVisible = useSelector(state => state.manageCheckInAlerts.deleteAlertModalVisible);
    const alertToEdit = useSelector(state => state.manageCheckInAlerts.alertToEdit);
    const [showAddManagersModal, setShowAddManagersModal] = useState(false);
    const dispatch = useDispatch();
    const [currentFloorPlan, setCurrentFloorPlan] = useState(null);

    useEffect(() => {
        dispatch(fetchLocations());
        return () => dispatch(manageDesksActions.resetManagedDesksState());
    }, [])

    useEffect(() => {
        if (skippedFirstActiveTab.current) {
            dispatch(fetchLocations());
            fetchDeskFilters();
        } else {
            skippedFirstActiveTab.current = true;
        }
    }, [activeTab])

    const changeTab = tabName => {
        setActiveTab(tabName);
    }

    const toggleFloorPlanVisible = (space, visible) => {
        if (space !== null) {
            setCurrentFloorPlan(space.floor_plan);
        } else if (!visible) {
            setCurrentFloorPlan(null);
        }
    }

    return (
        <CachedApiLoaderProvider>
            <div className={mainContentContainerClass()}>
                {currentFloorPlan !== null &&
                    <Image
                        preview={{
                            visible: true,
                            src: currentFloorPlan,
                            onVisibleChange: visible => toggleFloorPlanVisible(null, visible)
                        }}
                    />
                }
                {deskModalVisible &&
                    <CreateEditDeskModal existingDesk={deskToEdit}/>
                }
                {alertModalVisible &&
                    <CheckInAlertModal existingAlert={alertToEdit}/>
                }
                {deleteAlertModalVisible &&
                    <ConfirmModal
                        show={true}
                        cancel={() => {
                            dispatch(manageAlertsActions.hideDeleteModal())
                        }}
                        confirm={() => {
                            dispatch(deleteAlert({uuid: alertToEdit.uuid}))
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this alert?"}
                        confirmButtonName={"Delete"}
                    />
                }
                {deleteDeskModalVisible &&
                    <ConfirmModal
                        show={true}
                        cancel={() => {
                            dispatch(manageDesksActions.hideDeleteDeskModal())
                        }}
                        confirm={() => {
                            dispatch(deleteDesk({uuid: deskToEdit.uuid}))
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this desk?"}
                        confirmButtonName={"Delete"}
                    />
                }
                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage Desks
                                            </h3>
                                            {activeTab === tabs.DESKS &&
                                                <>
                                                    <button className="btn btn-primary pull-right hidden-xs" style={{
                                                        width: '145px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                        padding: "6px"
                                                    }} onClick={() => {
                                                        dispatch(manageDesksActions.showDeskModal())
                                                    }}>
                                                        <PlusCircleOutlined/> Create desk
                                                    </button>
                                                    <button className="btn btn-primary pull-right visible-xs" style={{
                                                        width: '50px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                        padding: "6px"
                                                    }} onClick={() => {
                                                        dispatch(manageDesksActions.showDeskModal())
                                                    }}>
                                                        <PlusCircleOutlined/>
                                                    </button>
                                                </>
                                            }
                                            {activeTab === tabs.ALERTS &&
                                                <>
                                                    <button className="btn btn-primary pull-right hidden-xs" style={{
                                                        width: '145px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                        padding: "6px"
                                                    }} onClick={() => {
                                                        dispatch(manageAlertsActions.showModal())
                                                    }}>
                                                        <PlusCircleOutlined/> Create alert
                                                    </button>
                                                    <button className="btn btn-primary pull-right visible-xs" style={{
                                                        width: '50px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                        padding: "6px"
                                                    }} onClick={() => {
                                                        dispatch(manageAlertsActions.showModal())
                                                    }}>
                                                        <PlusCircleOutlined/>
                                                    </button>
                                                </>
                                            }
                                            {activeTab === tabs.MANAGERS &&
                                                <>
                                                    <button className="btn btn-primary pull-right hidden-xs" style={{
                                                        width: '145px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                        padding: "6px"
                                                    }} onClick={() => {
                                                        setShowAddManagersModal(true)
                                                    }}>
                                                        <PlusCircleOutlined/> Add Manager
                                                    </button>
                                                    <button className="btn btn-primary pull-right visible-xs" style={{
                                                        width: '50px',
                                                        textAlign: 'center',
                                                        display: 'inline-block',
                                                        padding: "6px"
                                                    }} onClick={() => {
                                                        setShowAddManagersModal(true)
                                                    }}>
                                                        <PlusCircleOutlined/>
                                                    </button>
                                                </>
                                            }
                                        </div>
                                        <p className="header"
                                        style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            Create and edit desks for user check-ins.
                                        </p>
                                        <div
                                            className="members-search"
                                            style={{
                                                width: "100%",
                                                marginTop: "8px",
                                                display: activeTab === tabs.DESKS ? 'block' : 'none'
                                            }}
                                        >
                                            <ManageDesksFilters/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        <div className="tab-base" style={{marginBottom: "0px", padding: "0px"}}>
                            <ul className="nav nav-tabs">
                                <li className={activeTab === tabs.DESKS ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => changeTab(tabs.DESKS)}>Desks</button>
                                </li>
                                <li className={activeTab === tabs.LOCATIONS ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => changeTab(tabs.LOCATIONS)}>Locations
                                    </button>
                                </li>
                                <li className={activeTab === tabs.ALERTS ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => changeTab(tabs.ALERTS)}>Alerts</button>
                                </li>
                                <li className={activeTab === tabs.MANAGERS ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => changeTab(tabs.MANAGERS)}>Managers
                                    </button>
                                </li>
                                <li className={activeTab === tabs.CHECK_INS ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => changeTab(tabs.CHECK_INS)}>Check-Ins
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" style={{border: "1px solid #e2e2e2", padding: "0px 0px"}}>
                                {activeTab === tabs.DESKS &&
                                    <>
                                        <DesksTable onClickSpace={toggleFloorPlanVisible}/>
                                        <Pagination
                                            style={{marginBottom: '0.5rem'}}
                                            size={"small"}
                                            showSizeChanger={false}
                                            hideOnSinglePage={true}
                                            pageSize={pagination.maxResults}
                                            total={pagination.total}
                                            current={pagination.currentPage}
                                            onChange={page => dispatch(manageDesksActions.setPage(page))}
                                        />
                                    </>
                                }
                                {activeTab === tabs.LOCATIONS &&
                                    <ManageSpacesLocations/>
                                }
                                {activeTab === tabs.ALERTS &&
                                    <AlertsTable/>
                                }
                                {activeTab === tabs.MANAGERS &&
                                    <ManagersTable
                                        showAddManagersModal={showAddManagersModal}
                                        setShowAddManagersModal={setShowAddManagersModal}
                                    />
                                }
                                {activeTab === tabs.CHECK_INS &&
                                    <FutureCheckInsPane />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CachedApiLoaderProvider>
    );
}