import { useEffect, useRef, useState } from "react";
import { Pagination } from "antd";

import CheckInsTable, { tabOption } from "./CheckInsTable";
import { delete_check_in } from "api/zero-api";
import { useMyCheckInsLoader } from "hooks/spaces";
import NotificationAlert from "other/NotificationAlert";
import DelayedTextInput from "components/Shared/DelayedTextInput";

const RESULTS_PER_PAGE = 25;

export default function FutureCheckInsPane() {
    const [fetchFutureCheckIns, futureCheckInsLoader] = useMyCheckInsLoader({cacheResults: true});
    const page = useRef(1);
    const [searchValue, setSearchValue] = useState("");
    const searchRef = useRef(searchValue);
    searchRef.current = searchValue;

    const loadCheckIns = (bypassCache = false) => {
        const params = new URLSearchParams();
        params.set('per_page', RESULTS_PER_PAGE);
        params.set('page', page.current);
        params.set('state', 'future');
        params.set('order', 'asc');
        if (searchRef.current) {
            params.set('search', searchRef.current);
        }
        fetchFutureCheckIns({query: `?${params}`}, {bypassCache});
    };

    const onChangePage = (newPage) => {
        page.current = newPage;
        loadCheckIns()
    };

    const onDelete = (checkInId) => {
        delete_check_in(checkInId)
            .then(response => {
                loadCheckIns(true);
            })
            .catch(error => {
                NotificationAlert('error', '', 'Could not delete check-in.');
            })
    }

    const onSearchChange = (value) => {
        page.current = 1;
        searchRef.current = value;
        setSearchValue(value);
        loadCheckIns();
    }

    // when component mounts
    useEffect(() => {
        loadCheckIns();
    }, []);

    return (
        <div style={{padding: '1.5rem'}}>
            <h4 className="zero-blue">Future Check-Ins</h4>
            <p className="zero-dark-grey" style={{fontSize: 14}}>All future check-ins across all locations are listed below.</p>
            <DelayedTextInput
                delay={500}
                defaultValue={searchValue}
                onChange={onSearchChange}
                style={{marginBottom: '1rem'}}
                placeholder="Search..."
                useAntInput
                allowClear
            />
            <CheckInsTable
                dataLoader={futureCheckInsLoader}
                currentTab={tabOption.FUTURE}
                onDelete={onDelete}
            />
            <Pagination
                size={"small"}
                showSizeChanger={false}
                hideOnSinglePage={true}
                pageSize={RESULTS_PER_PAGE}
                total={futureCheckInsLoader?.data?.total ?? 0}
                current={page.current}
                onChange={onChangePage}
            />
        </div>
    )
}