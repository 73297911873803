import React from 'react';
import Skeleton from 'react-loading-skeleton'


export default function SkeletonLoader() {
    return (
        <div className="row">
            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                <div className="panel">
                    <div className="panel-body pad-15">
                        <Skeleton height={40} width={500}/>
                        <br/>
                        <Skeleton width={200}/>
                        <br/>
                        <br/>
                        <Skeleton height={20} width={100}/>
                        <br/>
                        <br/>
                        <Skeleton width={250}/>
                        <br/>
                        <span style={{marginRight: 5}}>
                            <Skeleton height={15} width={15} circle/>
                        </span>
                        <Skeleton width={100}/>
                        <br/>
                        <span style={{marginRight: 5}}>
                            <Skeleton height={15} width={15} circle/>
                        </span>
                        <Skeleton width={100}/>
                        <br/>
                        <span style={{marginRight: 5}}>
                            <Skeleton height={15} width={15} circle/>
                        </span>
                        <Skeleton width={100}/>
                    </div>
                </div>
            </div>
        </div>
    )
}