import React, {Component} from 'react';

import {
    createFormSubmissionAlert,
    patchFormSubmissionAlert,
    createIncidentAlert,
    patchIncidentAlert,
    newPostAlertsApi
} from '../../../api/zero-api.js'
import {safe_get, safeProfilePic, isAdmin} from '../../../other/Helper.js';

import NotificationAlert from '../../../other/NotificationAlert';
import ButtonLoading from '../../Shared/ButtonLoading.js';

import {Select, Modal} from 'antd'
import {CloseCircleOutlined} from '@ant-design/icons';

import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import MultiTeamSelect from 'components/Shared/MultiTeamSelect.js';

var searchTimeout = null;

class NewEditAlertModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_edit: this.props.alert !== undefined,
            loading_forms: false,
            available_teams: [],
            selected_team_uuids: [],
            selected_form_uuid: undefined,
            selected_form: undefined,
            availableUsers: [],
            filtered_users: [],
            selected_users: [],
            selected_users_uuids: [],
            existingResponders: [],
            existing_responders_uuids: [],
            selected_location_uuids: [],
            selected_category_uuid: null,
            allMembersAssigned: false,
            subscribers_count: 0,
            creating: false
        };

        this.fieldsCompleted = this.fieldsCompleted.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {
        if (this.props.alert) {
            let alert = this.props.alert;

            let selected_team_uuids = [];
            let selected_category_uuid = null;
            let available_users = [];
            let selected_users_uuids = [...alert.subscribed_users].map(user => user.uuid);

            if (alert.alert_type === 'form_submission') {
                this.handleFormSelect(alert.form.uuid);
                selected_team_uuids = [...alert.trigger_teams].map(team => team.uuid);
            } else if (alert.alert_type === 'new_post') {
                selected_category_uuid = alert.category.uuid;
                selected_team_uuids = alert.trigger_teams.map(team => team.uuid);
                available_users = this.props.feedOptions.users.filter(user => !selected_users_uuids.includes(user.uuid));
            } else if (alert.alert_type === 'new_incident') {
                available_users = this.props.allUsers.filter(user => !selected_users_uuids.includes(user.uuid));
            }

            this.setState({
                is_edit: true,
                selected_alert_type: alert.alert_type,
                selected_form_uuid: alert.form && alert.form.uuid,
                selected_users: [],
                selected_users_uuids: [],
                existingResponders: alert.subscribed_users,
                existing_responders_uuids: selected_users_uuids,
                availableUsers: available_users,
                filtered_users: available_users,
                selected_category_uuid,
                selected_team_uuids,
            }, () => {
                if (alert.alert_type === 'form_submission') {
                    this.handleTeamSelect(selected_team_uuids[0]);
                } else if (alert.alert_type === 'new_incident') {
                    let location_uuids = [];
                    if (alert.all_locations) {
                        location_uuids = ['all_locations']
                    } else {
                        location_uuids = alert.locations.map(location => location.location_uuid)
                    }
                    this.handleLocationSelect(location_uuids)
                }
            });
        }
    }

    handleTypeSelect = (value) => {
        if (this.state.selected_alert_type !== value) {
            let availableUsers = [];

            if (value === 'new_incident') {
                availableUsers = this.props.allUsers;
            } else if (value === 'new_post') {
                availableUsers = this.props.feedOptions.users;
            }

            const newState = {
                selected_alert_type: value,
                selected_users: [],
                selected_users_uuids: [],
                selected_team_uuids: [],
                selected_form_uuid: undefined,
                selected_form: undefined,
                availableUsers,
                filtered_users: availableUsers,
                selected_location_uuids: [],
            }

            this.setState(newState);
        }
    }


    handleFormSelect = (form_uuid) => {
        const {forms, teams, users} = this.props.formOptions;
        let form = forms.find(function (form) {
            return form.uuid === form_uuid
        });

        let formTeams = [];
        if (form) {
            formTeams = teams.filter(team => form.team_uuids.includes(team.uuid));
        }

        this.setState({
            selected_form_uuid: form_uuid,
            selected_form: form,
            form_teams: formTeams,
            available_teams: formTeams,
            selected_users: [],
            selected_users_uuids: [],
            selected_team_uuids: []
        });
    }

    handleTeamSelect = (team_uuid) => {
        this.setState({selected_team_uuids: [team_uuid], selected_users: [], selected_users_uuids: []}, () => {
            this.handleAvailableUsers();
            // this.setUpResponders();
        });
    }

    handleLocationSelect(value) {
        let selected_location_uuids = [];

        for (let i in value) {
            let uuid = value[i]

            if (uuid === 'all_locations') {
                selected_location_uuids = ['all_locations']
                break;
            } else if (selected_location_uuids.indexOf(uuid) < 0) {
                selected_location_uuids.push(uuid);
            }
        }

        this.setState({
            selected_location_uuids,
            selected_users: [],
            selected_users_uuids: []
        }, () => {
            this.handleAvailableUsers();
        })
    }

    handleAvailableUsers = () => {
        let availableUsers = [];

        let {selected_alert_type, selected_team_uuids, selected_location_uuids} = this.state;

        if (selected_alert_type === 'form_submission') {
            const {users} = this.props.formOptions;
            if (selected_team_uuids.length === 0) {
                availableUsers = [];
            } else {
                const teamId = selected_team_uuids[0];

                availableUsers = users.filter(user => {
                    if (this.state.existing_responders_uuids.includes(user.uuid)) {
                        return false;
                    }

                    if (isAdmin(user)) {
                        return true;
                    }

                    return user.team_uuids.includes(teamId);
                })
            }
        } else if (selected_alert_type === 'new_post') {
            if (selected_team_uuids.length === 0) {
                availableUsers = [];
            } else {
                const {users} = this.props.feedOptions
                const teamId = selected_team_uuids[0];

                availableUsers = users.filter(user => {
                    if (this.state.existing_responders_uuids.includes(user.uuid)) {
                        return false;
                    }

                    if (isAdmin(user)) {
                        return true;
                    }

                    return user.team_uuids.includes(teamId);
                })
            }
        } else if (selected_alert_type === 'new_incident') {
            if (selected_location_uuids.length === 0) {
                availableUsers = [];
            } else {
                if (selected_location_uuids[0] === 'all_locations') {
                    availableUsers = this.props.allUsers.filter(user => {
                        if (this.state.existing_responders_uuids.includes(user.uuid)) {
                            return false;
                        }
                        return true;
                    });
                } else {
                    // build a set of all possible users for every location selected
                    const userIdSet = new Set();
                    for (const locationId of selected_location_uuids) {
                        const location = this.props.locationOptions.find(location => location.uuid === locationId);
                        if (location) {
                            for (const uuid of location.user_uuids) {
                                userIdSet.add(uuid);
                            }
                        }
                    }

                    if (userIdSet.length == 0) {
                        availableUsers = [];
                    }

                    // convert set to array and filter out potential users
                    const allowedUserIds = [...userIdSet];
                    availableUsers = this.props.allUsers.filter(user => {
                        if (this.state.existing_responders_uuids.includes(user.uuid)) {
                            return false;
                        }

                        return allowedUserIds.includes(user.uuid)
                    });
                }
            }
        } else {
            availableUsers = this.props.allUsers;
        }

        this.setState({
            availableUsers: availableUsers,
            filtered_users: availableUsers
        });
    }

    handleUserSelect(value, option) {
        let hasAllTeamMembers = false;
        let allUsersIndex = -1;
        var selected_users_uuids = [];

        for (var i in value) {
            let user = value[i]
            let uuid = user.key

            if (uuid === "all_team_members") {
                hasAllTeamMembers = true;
                allUsersIndex = i;
                break;
            } else if (selected_users_uuids.indexOf(uuid) < 0) {
                selected_users_uuids.push(uuid);
            }
        }

        this.setState({
            selected_users: value,
            filtered_users: this.state.availableUsers,
            selected_users_uuids: selected_users_uuids
        });
    }

    searchUsers(value) {
        var self = this;
        self.setState({filtered_users: [], fetching: true})

        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(function () {
            if (value !== "") {
                var users = [...self.state.availableUsers];
                var existingResponders = [...self.state.existingResponders];
                var searchValue = value.toLowerCase();

                // Remove existing responders from dropdown menu
                if (self.state.assigned_to_team) {

                } else {
                    users = users.filter(function (user) {
                        let found = existingResponders.find(function (obj) {
                            return obj.user_uuid === user.uuid
                        })
                        return !found
                    })
                }

                var filterList = users.filter(function (user) {
                    var name = (user.name || user.full_name).toLowerCase();
                    var email = user.email.toLowerCase();

                    return (name.includes(searchValue) || email.includes(searchValue));
                });

                var newState = {};

                filterList.sort(function (a, b) {
                    var a_name = (a.name || a.full_name).toLowerCase();
                    var b_name = (b.name || b.full_name).toLowerCase();

                    return a_name > b_name ? 1 : -1
                });

                newState["dropdownStyle"] = {display: "block", zIndex: "10000"};

                newState["filtered_users"] = filterList;
                newState["fetching"] = false;
                newState["searchingUsers"] = false;

                self.setState(newState);

            } else {
                self.setState({filtered_users: self.state.availableUsers, fetching: false,});
            }
        }, 400);

    }

    toggleAssignAll = (allAssigned = false) => {
        var newState = {};
        newState["allMembersAssigned"] = allAssigned;
        if (allAssigned) {
            // newState["existingResponders"] = []
            // newState["selected_users_uuids"] = [];
        }
        this.setState(newState);
    }

    removeResponder = (user, allMembers = false) => {
        var new_state = {};
        if (allMembers) {
            this.toggleAssignAll(false);
        } else {
            var existing_responders = [...this.state.existingResponders];
            var responder_index = existing_responders.indexOf(user);

            if (responder_index >= 0) {
                existing_responders.splice(responder_index, 1);
                new_state["existingResponders"] = existing_responders
            }

            let user_uuid = user.uuid
            var existing_responders_uuids = [...this.state.existing_responders_uuids];
            let user_uuid_index = existing_responders_uuids.indexOf(user_uuid);

            if (user_uuid_index >= 0) {
                existing_responders_uuids.splice(user_uuid_index, 1);
                new_state["existing_responders_uuids"] = existing_responders_uuids;
            }

            this.setState(new_state);
        }
    }

    fieldsCompleted() {
        switch (this.state.selected_alert_type) {
            case 'form_submission':
                if (this.state.selected_team_uuids.length === 0) {
                    return false;
                }
                if (this.state.selected_form_uuid === undefined) {
                    return false;
                }
                return true;
            case 'new_incident':
                if (this.state.selected_location_uuids.length === 0) {
                    return false;
                }
                return true;
            case 'new_post':
                if (!this.state.selected_category_uuid) {
                    return false;
                }
                if (this.state.selected_team_uuids.length === 0) {
                    return false;
                }
                return true;
            default:
                return false;
        }
    }

    getNewTabName() {
        switch (this.state.selected_alert_type) {
            case 'form_submission':
                return 'forms';
            case 'new_incident':
                return 'incidents';
            case 'new_post':
                return 'feed';
            default:
                return null;
        }
    }

    confirm() {
        if (this.fieldsCompleted()) {
            this.setState({creating: true, error_msg: false});
            var self = this;


            if (this.state.is_edit) {
                let subscribed_users = this.state.selected_users_uuids.concat(this.state.existing_responders_uuids);
                let body;

                switch (this.state.selected_alert_type) {
                    case 'form_submission':
                        body = JSON.stringify({
                            subscribed_user_uuids: subscribed_users
                        });

                        patchFormSubmissionAlert(this.props.alert.uuid, body).then(success => {
                            NotificationAlert("success", "", "Alert updated.");
                            self.props.updateAlerts(this.getNewTabName());
                            self.props.cancel();
                        }, error => {

                        });

                        break;

                    case 'new_incident':
                        if (this.state.selected_location_uuids[0] === 'all_locations') {
                            body = JSON.stringify({
                                subscribed_user_uuids: subscribed_users,
                                all_locations: true,
                                location_uuids: []
                            });
                        } else {
                            body = JSON.stringify({
                                subscribed_user_uuids: subscribed_users,
                                all_locations: false,
                                location_uuids: this.state.selected_location_uuids
                            });
                        }

                        patchIncidentAlert(this.props.alert.uuid, body).then(success => {
                            NotificationAlert("success", "", "Alert updated.");
                            self.props.updateAlerts(this.getNewTabName());
                            self.props.cancel();
                        })

                        break;

                    case 'new_post':
                        body = JSON.stringify({
                            subscribed_user_uuids: subscribed_users
                        });

                        newPostAlertsApi.partialUpdate(this.props.alert.uuid, body).then(() => {
                            NotificationAlert("success", "", "Alert updated.");
                            self.props.updateAlerts(this.getNewTabName());
                            self.props.cancel();
                        });
                        break;
                    default:
                        break;
                }

            } else {
                let body2 = {
                    enabled: true,
                    subscribed_user_uuids: this.state.selected_users_uuids,
                    subscribed_team_uuids: []
                }

                let createAlertFunc = () => Promise.reject();

                switch (this.state.selected_alert_type) {
                    case 'form_submission':
                        createAlertFunc = createFormSubmissionAlert;

                        Object.assign(body2, {
                            trigger_team_uuids: this.state.selected_team_uuids,
                            form_uuid: this.state.selected_form_uuid,
                        });

                        break;

                    case 'new_incident':
                        createAlertFunc = createIncidentAlert;

                        if (this.state.selected_location_uuids[0] === 'all_locations') {
                            Object.assign(body2, {
                                all_locations: true,
                                location_uuids: []
                            });
                        } else {
                            Object.assign(body2, {
                                all_locations: false,
                                location_uuids: this.state.selected_location_uuids
                            });
                        }

                        break;

                    case 'new_post':
                        createAlertFunc = newPostAlertsApi.create;

                        Object.assign(body2, {
                            trigger_team_uuids: this.state.selected_team_uuids,
                            category_uuid: this.state.selected_category_uuid,
                        })
                        break;
                }

                createAlertFunc(JSON.stringify(body2)).then(success => {
                    NotificationAlert("success", "", "Alert created.");
                    self.props.updateAlerts(this.getNewTabName());
                    self.props.cancel();
                }, error => {

                });
            }
        } else {
            this.setState({error_msg: "Please complete all fields."});
        }

    }

    render() {

        let h3Style = {marginBottom: '2px', marginLeft: '0px', fontSize: '100%', marginTop: "15px"}
        let subscribersSelectDisabled = false;
        if (this.state.selected_alert_type === 'new_incident' && this.state.selected_location_uuids.length === 0) {
            subscribersSelectDisabled = true;
        } else if (['form_submission', 'new_post'].includes(this.state.selected_alert_type) && this.state.selected_team_uuids.length === 0) {
            subscribersSelectDisabled = true;
        }


        return (
            (<Modal
                title={this.state.is_edit ? "Edit alert" : "Create alert"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        {
                            this.state.error_msg &&
                            <small className="error"
                                   style={{display: "block", marginBottom: "5px"}}>{this.state.error_msg}</small>
                        }
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        {
                            this.state.creating &&
                            <button className="btn btn-modal">
                                <ButtonLoading/>
                            </button>
                        }
                        {
                            !this.state.creating &&
                            <button className="btn btn-modal" onClick={this.confirm}>
                                {this.state.is_edit ? "Update alert" : "Create alert"}
                            </button>
                        }
                    </div>
                }
            >
                {
                    this.state.loading_forms &&
                    <LoadingIndicator/>
                }
                {
                    !this.state.loading_forms &&
                    <div>
                        <h3 className="titles required" style={{
                            marginBottom: '2px',
                            marginLeft: '0px',
                            fontSize: '100%',
                            marginTop: "0px"
                        }}>Type</h3>
                        <Select
                            style={{width: "100%"}}
                            className="move-team-select"
                            showArrow={true}
                            placeholder="Select a type"
                            dropdownStyle={{zIndex: "10000"}}
                            value={this.state.selected_alert_type}
                            disabled={this.state.is_edit}
                            onChange={this.handleTypeSelect}
                            virtual={false}
                        >
                            <Select.Option value={"new_post"}>New Post Alert</Select.Option>
                            <Select.Option value={"form_submission"}>New Submission Alert</Select.Option>
                            <Select.Option value={"new_incident"}>New Incident Alert</Select.Option>
                        </Select>
                        {
                            this.state.selected_alert_type === 'new_post' &&
                            <>
                                <h3 className="titles required" style={h3Style}>Category</h3>
                                <Select
                                    style={{width: "100%",}}
                                    className="move-team-select"
                                    showArrow={true}
                                    placeholder="Select a category"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.selected_category_uuid}
                                    notFoundContent={"No categories available..."}
                                    disabled={this.state.is_edit}
                                    onChange={value => this.setState({selected_category_uuid: value})}
                                    virtual={false}
                                >
                                    {
                                        this.props.feedOptions.categories.map(category => (
                                            <Select.Option key={category.uuid}
                                                           value={category.uuid}>{category.name}</Select.Option>
                                        ))
                                    }
                                </Select>

                                <h3 className="titles required" style={h3Style}>Team</h3>
                                <Select
                                    style={{width: "100%",}}
                                    className="move-team-select"
                                    showArrow={true}
                                    showSearch={false}
                                    // mode="multiple"
                                    placeholder="Select a team"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.selected_team_uuids[0]}
                                    disabled={this.state.is_edit}
                                    onChange={this.handleTeamSelect}
                                    virtual={false}>
                                    {
                                        this.props.feedOptions.teams.map(team => (
                                            <Select.Option key={team.uuid} value={team.uuid}>{team.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </>
                        }
                        {
                            this.state.selected_alert_type === 'form_submission' &&
                            <>
                                <h3 className="titles required" style={h3Style}>Template</h3>
                                <Select
                                    style={{width: "100%",}}
                                    className="move-team-select"
                                    showArrow={true}
                                    placeholder="Select a template"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.selected_form_uuid}
                                    notFoundContent={"No templates available..."}
                                    disabled={this.state.is_edit}
                                    onChange={this.handleFormSelect}
                                    virtual={false}>
                                    {
                                        this.props.formOptions.forms.map((form, index) => (
                                            <Select.Option key={index} value={form.uuid}>{form.name}</Select.Option>
                                        ))
                                    }
                                </Select>

                                <h3 className="titles required" style={h3Style}>Team</h3>
                                <Select
                                    style={{width: "100%",}}
                                    className="move-team-select"
                                    showArrow={true}
                                    showSearch={false}
                                    // mode="multiple"
                                    placeholder="Select a team"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.selected_team_uuids[0]}
                                    disabled={this.state.is_edit || !this.state.selected_form_uuid}
                                    onChange={this.handleTeamSelect}
                                    virtual={false}>
                                    {
                                        this.state.available_teams.map((team, index) => (
                                            <Select.Option key={team.uuid || team.team_uuid}
                                                           value={team.uuid || team.team_uuid}>{team.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </>
                        }
                        {
                            this.state.selected_alert_type === 'new_incident' &&
                            <>
                                <h3 className="titles required" style={h3Style}>Locations</h3>
                                <Select
                                    style={{width: "100%",}}
                                    className="move-team-select"
                                    mode="multiple"
                                    showArrow={true}
                                    showSearch={false}
                                    placeholder="Select a location"
                                    notFoundContent={"No locations found."}
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.selected_location_uuids}
                                    onChange={this.handleLocationSelect.bind(this)}
                                    disabled={this.state.is_edit}
                                    virtual={false}>
                                    {
                                        <Select.Option key={'all_locations'} value={'all_locations'}>
                                            <span style={{fontWeight: 'bold'}}>All Locations</span>
                                        </Select.Option>
                                    }
                                    {
                                        this.props.locationOptions.map((location, index) => (
                                            <Select.Option key={location.uuid}
                                                           value={location.uuid}>{location.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </>
                        }
                    </div>
                }
                {
                    !this.state.loading_forms && this.state.selected_alert_type &&
                    <div>
                        <h3 className="titles" style={h3Style}>Notifications</h3>
                        {
                            this.state.selected_alert_type === "form_submission" &&
                            <p className="zero-dark-grey">When a new submission occurs, send an email notification
                                to:</p>
                        }
                        {
                            this.state.selected_alert_type === "new_incident" &&
                            <p className="zero-dark-grey">When a new incident occurs, send an email notification to:</p>
                        }
                        {
                            this.state.selected_alert_type === "new_post" &&
                            <p className="zero-dark-grey">When a new post occurs, send an email notification to:</p>
                        }
                        {
                            <Select
                                id="invite-emails"
                                mode="multiple"
                                value={this.state.selected_users}
                                placeholder="Type a name..."
                                onSearch={this.searchUsers.bind(this)}
                                onChange={this.handleUserSelect.bind(this)}
                                disabled={subscribersSelectDisabled}
                                filterOption={false}
                                style={{width: '100%'}}
                                className="user-select-dropdown"
                                labelInValue={true}
                                dropdownStyle={{zIndex: "10000"}}
                                loading={this.state.fetching}
                                optionLabelProp="name"
                                virtual={false}
                            >
                                {
                                    this.state.filtered_users.map((user, index) => (
                                        <Select.Option key={user.uuid} value={user.uuid} name={
                                            <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.name || user.full_name}</span>}>
                                            {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                            {user.name || user.full_name} &bull; <span
                                            style={{fontSize: '85%'}}>{user.email}</span>
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        }

                        {
                            this.state.is_edit &&
                            <div className="mar-top-10">
                                <p className="zero-blue" style={{fontWeight: "500", marginBottom: "4px"}}>Current
                                    Subscribers: </p>
                                <div className="">
                                    {
                                        this.state.existingResponders.length === 0 && !this.state.assigned_to_team &&
                                        <p>No subscribers assigned.</p>
                                    }
                                    {
                                        this.state.existingResponders.length > 0 && !this.state.assigned_to_team &&
                                        this.state.existingResponders.map((user, index) => (
                                            <li key={index} style={{
                                                listStyle: "none",
                                                display: "inline-block",
                                                marginRight: "5px",
                                                marginBottom: "5px",
                                                padding: "3px",
                                                backgroundColor: "#F6F6F6",
                                                border: "solid 1px #ECECEC",
                                                borderRadius: "3px"
                                            }}>
                                                <div>
                                                    {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                                    <div style={{
                                                        color: "#1d2a35",
                                                        display: "inline-block",
                                                        verticalAlign: "bottom"
                                                    }}
                                                         className="truncate name">{user.first_name + " " + user.last_name}</div>
                                                    <CloseCircleOutlined className="mar-lft-5 zero-blue"
                                                                         style={{verticalAlign: "middle"}}
                                                                         onClick={() => {
                                                                             this.removeResponder(user)
                                                                         }}/>
                                                </div>
                                            </li>
                                        ))
                                    }
                                    {
                                        this.state.assigned_to_team &&
                                        <li style={{
                                            listStyle: "none",
                                            display: "inline-block",
                                            marginRight: "5px",
                                            marginBottom: "5px",
                                            padding: "3px",
                                            backgroundColor: "#F6F6F6",
                                            border: "solid 1px #ECECEC",
                                            borderRadius: "3px"
                                        }}>
                                            <div>
                                                <div style={{
                                                    color: "#1d2a35",
                                                    display: "inline-block",
                                                    verticalAlign: "bottom"
                                                }}>All Team Members ({this.state.availableUsers.length})
                                                </div>
                                                <CloseCircleOutlined className="mar-lft-5 zero-blue"
                                                                     style={{verticalAlign: "middle"}} onClick={() => {
                                                    this.removeResponder(null, true)
                                                }}/>
                                            </div>
                                        </li>
                                    }

                                </div>
                            </div>
                        }
                    </div>

                }
            </Modal>)
        );

    }

}

export default NewEditAlertModal;