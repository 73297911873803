import React, {Component} from 'react';
import {connect} from 'react-redux'

import {get_api_keys, delete_api_key} from '../../../api/zero-api.js'
import {safe_get, scrollToTop, mainContentContainerClass, dateFormatterNoTime} from '../../../other/Helper.js';

import Skeleton from 'react-loading-skeleton'

import NewEditAPIKey from './NewEditAPIKey.js';

import {Tag, Popover, Menu, Dropdown} from 'antd'
import {EditOutlined, DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';

import ConfirmModal from '../../Shared/ConfirmModal.js';
import NotificationAlert from '../../../other/NotificationAlert';

import {IoIosMore as MoreIcon} from 'react-icons/io';

class ManageAPIKeys extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            api_keys: []
        };

    }

    componentDidMount() {
        scrollToTop("page-head");
        this.getAPIKeys();
    }

    getAPIKeys = () => {
        var self = this;
        get_api_keys().then(function (success) {
            success.json().then(success => {
                var api_keys = safe_get(success, "api_keys", []);
                self.setState({api_keys: api_keys, loading: false, key_to_edit: undefined});
            });
        }, function (error) {
            self.setState({loading: false});
            console.log(error);
        });
    }

    APIKeyNameFormatter(key) {
        return (
            <span className="zero-dark-grey">
        {key.name}       
      </span>
        );
    }

    statusFormatter(key) {
        if (key.enabled) {
            return <Tag color="green" style={{borderColor: "#52c41a"}}>Enabled</Tag>
        } else {
            return <Tag style={{color: "#505050"}}>Disabled</Tag>
        }
    }


    optionsFormatter = (key) => {
        const menu = {
            onClick: (e) => {
                this.handleMenuClick(e.key, key);
            },
            items: [
                {
                    key: 'edit',
                    icon: <EditOutlined/>,
                    label: 'Edit Key',
                },
                {
                    type: 'divider',
                },
                {
                    key: 'delete',
                    className: 'zero-delete-red',
                    icon: <DeleteOutlined/>,
                    label: 'Delete Key',
                },
            ]
        }

        return (
            <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                <Dropdown menu={menu} trigger={['click']}>
                    <button className="ButtonLink ant-dropdown-link">
                        <MoreIcon style={{height: '20px', width: '20px'}}/>
                    </button>
                </Dropdown>
            </div>
        );
    }

    handleMenuClick = (type, key) => {
        if (type === "edit") {
            this.setState({key_to_edit: key, showAPIKeyModal: true});
        } else if (type === "delete") {
            this.setState({key_to_edit: key, showDeleteModal: true});
        }
    }

    deleteAPIKey = () => {
        let self = this;

        delete_api_key(this.state.key_to_edit.api_key_uuid).then(_success => {
            NotificationAlert('success', '', "API key deleted.");
            self.setState({showDeleteModal: false});
            self.getAPIKeys();
        }, _error => {
            NotificationAlert('error', '', "Unable to delete API Key.");
            self.setState({showDeleteModal: false});
        });

    }

    render() {
        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.showAPIKeyModal &&
                    <NewEditAPIKey
                        show={this.state.showAPIKeyModal}
                        cancel={() => {
                            this.setState({showAPIKeyModal: false})
                        }}
                        refresh={this.getAPIKeys}
                        key_to_edit={this.state.key_to_edit}
                    />
                }

                {
                    this.state.showDeleteModal &&
                    <ConfirmModal
                        show={this.state.showDeleteModal}
                        cancel={() => {
                            this.setState({showDeleteModal: false})
                        }}
                        confirm={this.deleteAPIKey}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this API Key?"}
                        confirmButtonName={"Delete Key"}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage API Keys
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '145px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({showAPIKeyModal: true});
                                            }}>
                                                <PlusCircleOutlined/> Create Key
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({showAPIKeyModal: true});
                                            }}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            As an Admin, you can control access to your API keys. To learn more,
                                            click <a rel="noopener noreferrer"
                                                     onClick={() => FreshworksWidget('open', 'article', {'id': 70000619793})}
                                                     className="blue-link">here</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile">
                    <div className="table-responsive" style={{
                        backgroundColor: "white",
                        border: "1px solid #DDDDDD",
                        borderRadius: "5px",
                        maxWidth: "850px",
                        margin: "0 auto"
                    }}>
                        <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                            <thead style={{color: "#1D2A35"}}>
                            <tr>
                                <th></th>
                                <th className="zero-blue" style={{minWidth: "150px"}}>
                                    Name
                                </th>
                                <th className="zero-blue" style={{minWidth: "150px"}}>
                                    Created By
                                </th>
                                <th className="zero-blue" style={{minWidth: "105px"}}>
                                    Created On
                                </th>
                                <th className="zero-blue" style={{minWidth: "105px"}}>
                                    Last Access
                                </th>
                                <th className="zero-blue">
                                    Status
                                </th>
                                <th className="zero-blue">
                                    API Key
                                </th>
                            </tr>
                            </thead>
                            {
                                this.state.loading &&
                                <tbody style={{borderTop: "0px"}}>
                                {
                                    [...Array(8)].map((_item, index) => (
                                        <tr key={index} className="tr-hover">
                                            <td><Skeleton width={25}/></td>
                                            <td className="text-left"><Skeleton width={130}/></td>
                                            <td className="text-left"><Skeleton width={130}/></td>
                                            <td className="text-left"><Skeleton width={100}/></td>
                                            <td className="text-left"><Skeleton width={100}/></td>
                                            <td className="text-left"><Skeleton width={60} height={22}/></td>
                                            <td className="text-left"><Skeleton width={200}/></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                !this.props.loading && this.state.api_keys.length > 0 &&
                                <tbody style={{borderTop: "0px"}}>
                                {
                                    this.state.api_keys.map((key, index) => (
                                        <tr key={index} className={"tr-hover"}>
                                            <td>{this.optionsFormatter(key)}</td>
                                            <td className="text-left zero-dark-grey">{this.APIKeyNameFormatter(key)}</td>
                                            <td className="text-left zero-dark-grey">{key.created_by.full_name}</td>
                                            <td className="text-left zero-dark-grey">{dateFormatterNoTime(key.created_at)}</td>
                                            <td className="text-left zero-dark-grey">{key.last_activity_at ? dateFormatterNoTime(key.last_activity_at) : ""}</td>
                                            <td className="text-left zero-dark-grey">{this.statusFormatter(key)}</td>
                                            <td className="text-left zero-dark-grey">{key.key}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                            {
                                !this.state.loading && this.state.api_keys.length === 0 &&
                                <tbody style={{borderTop: "0px"}}>
                                <tr>
                                    <td className="react-bs-table-no-data" colSpan="6"
                                        style={{color: 'var(--zero-dark-grey)'}}>
                                        No API Keys.
                                    </td>
                                </tr>
                                </tbody>
                            }
                        </table>
                    </div>

                </div>
            </div>

        );


    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(ManageAPIKeys);


