import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom';

import safe_get from '../../other/SafeGet.js';
import {isAdmin, isContributor, isTeamLead} from '../../other/Helper';

import {
    HomeOutlined, LayoutOutlined,
    ExceptionOutlined, AuditOutlined, ReadOutlined
} from '@ant-design/icons';
import OfflineContext from 'components/Offline/OfflineContext.js';

class Footer extends Component {
    static contextType = OfflineContext;

    constructor(props, context) {
        super(props, context);

        this.state = {};

    }

    activeTab() {
        var location = this.props.location.pathname;
        if (location.indexOf("new_post") >= 0) {
            return "new_post";
        } else if (location.indexOf("feed") >= 0) {
            return "feed";
        } else if (location.indexOf("forms") >= 0) {
            return "forms";
        } else if (location.indexOf("library") >= 0) {
            return "library";
        } else if (location.indexOf("chat") >= 0) {
            return "chat";
        } else if (location.indexOf("analytics") >= 0) {
            return "analytics";
        } else if (location.indexOf("incidents") >= 0) {
            return "incidents";
        } else if (location.indexOf("dashboard" >= 0)) {
            return "dashboard"
        } else {
            return "dashboard";
        }
    }

    render() {
        const isOffline = this.context;

        var footerStyle = {
            position: "fixed",
            height: "calc(var(--sab) + 67px)",
            backgroundColor: "#f6f6f6",
            borderTop: "1px solid #e2e2e2",
            zIndex: "1000",
            paddingLeft: "15px",
            paddingRight: "15px"
        }

        var activeTab = this.activeTab();


        const ignoredPaths = [
            '/feed/new_post/',
            '/checkin'
        ]

        for (const ignoredPath of ignoredPaths) {
            if (this.props.location.pathname.includes(ignoredPath)) {
                return null;
            }
        }

        return (
            <footer id="footer" className="myfooter" style={footerStyle}>
                {isOffline &&
                    <>
                        <div className='offline-sidebar-mask'></div>
                    </>
                }
                <div className="text-center" style={{
                    margin: "0 auto",
                    width: "100%",
                    paddingTop: "0px",
                    display: "flex",
                    justifyContent: "space-evenly"
                }}>

                    <Link id="dashboard" to={"/" + this.props.organization.organization_uuid + "/home/dashboard"}
                          className="footer-nav-button">
            <span className={"teams-buttons " + (activeTab === "dashboard" ? 'active' : '')}>
              <HomeOutlined/>
                {
                    this.props.assignments_count > 0 &&
                    <span className="badge badge-warning" style={{
                        backgroundColor: "#d6b27e",
                        position: "absolute",
                        top: "6px"
                    }}>{this.props.assignments_count}</span>
                }
            </span>
                        <br/>
                        <span className={"footer-tabs " + (activeTab === "dashboard" ? "active" : "")}>Dashboard</span>
                    </Link>

                    {
                        safe_get(this.props, "organization.feature_flags.feed", true) &&
                        <Link id="bulletin-feed"
                              to={"/" + this.props.organization.organization_uuid + "/home/team/my_teams/feed"}
                              className="footer-nav-button">
              <span className={"teams-buttons " + (activeTab === "feed" ? 'active' : '')}>
                <LayoutOutlined/>
              </span>
                            <br/>
                            <span className={"footer-tabs " + (activeTab === "feed" ? "active" : "")}>Posts</span>
                        </Link>
                    }

                    {
                        safe_get(this.props, "organization.feature_flags.forms", true) &&
                        <Link id="forms"
                              to={"/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/forms"}
                              className="footer-nav-button">
              <span className={"teams-buttons " + (activeTab === "forms" ? 'active' : '')}>
                <AuditOutlined/>
              </span>
                            <br/>
                            <span className={"footer-tabs " + (activeTab === "forms" ? "active" : "")}>Forms</span>
                        </Link>
                    }


                    {
                        safe_get(this.props, "organization.feature_flags.incidents", true) &&
                        (isAdmin(this.props.user) || isTeamLead(this.props.user) || (this.props.organization.incidents_enabled)) &&
                        <Link id="incidents"
                              to={"/" + safe_get(this.props.organization, "organization_uuid", "undefined") + "/home/incidents/location/all_locations"}
                              className="footer-nav-button">
              <span className={"teams-buttons " + (activeTab === "incidents" ? 'active' : '')}>
                <ExceptionOutlined/>
              </span>
                            <br/>
                            <span
                                className={"footer-tabs " + (activeTab === "incidents" ? "active" : "")}>Incidents</span>
                        </Link>
                    }
                    {
                        safe_get(this.props, "organization.feature_flags.library", true) &&
                        !isContributor(this.props.user) &&
                        <Link id="library"
                              to={"/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library"}
                              className="footer-nav-button">
              <span className={"teams-buttons " + (activeTab === "library" ? 'active' : '')}>
                <ReadOutlined/>
              </span>
                            <br/>
                            <span className={"footer-tabs " + (activeTab === "library" ? "active" : "")}>Library</span>
                        </Link>
                    }

                </div>

            </footer>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        assignments_count: safe_get(store, "dashboard.assignmentsCount", 0),
        organization: safe_get(store, "org_helper.organization", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
    }
}
export default withRouter(connect(mapStateToProps)(Footer));
