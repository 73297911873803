import React, {Component} from 'react';
import {connect} from 'react-redux'

import safe_get from '../../../other/SafeGet.js';
import NotificationAlert from '../../../other/NotificationAlert'
import {end_session, get_sessions, test_push_notifications} from '../../../api/zero-api.js';

// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
// import 'font-awesome/css/font-awesome.min.css';

import Skeleton from 'react-loading-skeleton'

import {MinusCircleOutlined} from '@ant-design/icons';

class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table: [],
            loading: true
        };

        this.formatTableData = this.formatTableData.bind(this);
    }

    componentDidMount() {
        // this.getDeviceSessions();     
        this.formatTableData(this.props.sessions)
    }

    // getDeviceSessions = () => {
    //     var self = this;
    //     get_sessions().then(function(success){
    //         success.json().then(success => {
    //             var sessions = safe_get(success, "sessions", []);
    //             self.formatTableData(sessions);
    //         });
    //     }, function(error) {
    //         console.log(error);
    //     });
    // }     

    formatTableData(data) {
        data = data.filter(function (object) {
            return object.session_uuid !== "";
        })

        var formatted_table = [];

        for (var i = 0; i < data.length; i++) {
            var session = data[i]
            var formatted_data = {
                id: safe_get(session, "session_uuid", ""),
                device: safe_get(session, "device", ""),
                is_app: safe_get(session, "is_app", false),
                access_location: safe_get(session, "location", ""),
                access_time: safe_get(session, "current", false) ? "Current session" : safe_get(session, "last_access", "")
            }
            formatted_table.push(formatted_data);
        }
        this.setState({table: formatted_table, loading: false});

    }

    deviceFormatter(device) {
        return (
            <div>
                <p style={{marginBottom: "0px"}}>
                    {device.device}
                </p>
                {
                    device.is_app && <button className="ButtonLink blue-link" title="Send test push notification"
                                             onClick={() => this.testPN(device)}>Send test push notification</button>
                }
            </div>
        )
    }

    removeFormatter(device) {
        return (
            <button type="button" title="End session" onClick={() => this.removeSession(device)}
                    className="btn btn-danger">
                <MinusCircleOutlined/>
            </button>
        );
    }

    removeSession(device) {
        var self = this;
        var data = this.state.table

        end_session(device.id).then(function (success) {
            var index = data.indexOf(device);
            if (index !== -1) {
                data.splice(index, 1);
                self.setState({table: data})
                NotificationAlert("success", "", "Session deleted.")
            }
        }, function (error) {
            NotificationAlert("success", "", "Unable to logout of session.")
        });
    }

    testPN(device) {
        var body = JSON.stringify({
            session_uuid: device.id
        });
        test_push_notifications(body).then(function (success) {
            NotificationAlert("success", "", "Test notification sent!");
        });
    }

    render() {

        return (
            <div className="panel article" style={{maxWidth: '800px', margin: '0 auto', padding: "0px"}}>

                <div className="pad-btm">
                    <div className="account-header">
                        <h4 className="zero-blue">Manage Devices</h4>
                        <p className="zero-dark-grey">Logins to your account are tracked so you can always monitor
                            access. If you notice anything suspicious or want to log out of a session remotely, you can
                            do so below.</p>
                        <p className="zero-dark-grey">Note: To protect your account, you will be automatically logged
                            out if you have been inactive for 30 days.</p>
                    </div>

                    <div style={{textAlign: 'center', marginTop: '25px'}}>

                        <div className="panel mar-btm-0">

                            <div className="table-responsive"
                                 style={{border: "1px solid #DDDDDD", borderRadius: "5px"}}>
                                <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                    <thead style={{color: "#1D2A35"}}>
                                    <tr>
                                        <th></th>
                                        <th className="zero-blue" style={{width: "200px"}}>
                                            Device
                                        </th>
                                        <th className="zero-blue">
                                            Last Access
                                        </th>
                                        <th className="zero-blue">
                                            Location
                                        </th>
                                    </tr>
                                    </thead>
                                    {
                                        this.state.loading &&
                                        <tbody>
                                        {
                                            [...Array(10)].map((_item, index) => (
                                                <tr key={index} className="tr-hover">
                                                    <td><Skeleton width={39} height={33}/></td>
                                                    <td className="text-left"><Skeleton width={130}/></td>
                                                    <td className="text-left"><Skeleton width={130}/></td>
                                                    <td className="text-left"><Skeleton width={130}/></td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    }
                                    {
                                        !this.state.loading &&
                                        <tbody>
                                        {
                                            this.state.table.map((device, index) => (
                                                <tr key={index}
                                                className={"tr-hover" + (safe_get(device, "access_time", "").toLowerCase() === "current session" ? " tr-current-session" : "")}>
                                                    <td>{this.removeFormatter(device)}</td>
                                                    <td className="text-left zero-dark-grey">{this.deviceFormatter(device)}</td>
                                                    <td className="text-left zero-dark-grey">{device.access_time}</td>
                                                    <td className="text-left zero-dark-grey">{device.access_location}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    }
                                    {/* {
                                            !this.state.loading && this.state.filtered_locations.length === 0 &&
                                            <tbody>
                                                <tr>
                                                    <td className="react-bs-table-no-data" colSpan="6">
                                                        No locations.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        } */}

                                </table>
                            </div>

                        </div>


                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(Devices);