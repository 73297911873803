import React, {Component} from 'react';

import {subscribe_users} from '../../api/zero-api'
import {safe_get, safeProfilePic} from '../../other/Helper.js';

import {Modal, Checkbox} from 'antd';
import ButtonLoading from '../Shared/ButtonLoading.js';
import NotificationAlert from '../../other/NotificationAlert';

import '../../assets/css/antd-custom.css';

class SubscribersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            subscriber_options: [],
            all_selected_users: [],
            existing_subscribers_uuid: [],
            selected_users: []
        };

        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.selectAllUsers = this.selectAllUsers.bind(this);
        this.selectNoOne = this.selectNoOne.bind(this);
        this.saveChanges = this.saveChanges.bind(this);

    }

    componentDidMount() {
        var new_subscriber_options = [];

        let subscriber_options = safe_get(this.props, "subscriber_options", []);
        // let existing_responders = safe_get(this.props, "existing_responders", []);
        let existing_subscribers = safe_get(this.props, "existing_subscribers", []);

        subscriber_options.forEach(function (user) {
            new_subscriber_options.push(user);
        });

        var existing_subscribers_uuid = [...existing_subscribers].map(user => user.user_uuid || user.uuid);


        this.setState({
            all_selected_users: existing_subscribers_uuid,
            existing_subscribers_uuid: existing_subscribers_uuid,
            selected_users: existing_subscribers_uuid,
            subscriber_options: new_subscriber_options
        });
    }

    handleUserSelect(e) {

        let user_uuid = e.target.value;
        var selected_users_clone = [...this.state.selected_users];
        var index = selected_users_clone.indexOf(user_uuid);

        if (index >= 0) {
            selected_users_clone.splice(index, 1);
        } else {
            selected_users_clone.push(user_uuid)
        }

        this.setState({
            selected_users: selected_users_clone,
            selected_all: false
        })

    }

    selectAllUsers() {
        var selected_users = [...this.props.subscriber_options].map(user => user.uuid)

        this.setState({
            selected_users: selected_users,
            selected_all: true
        });

    }

    selectNoOne() {
        this.setState({
            selected_users: [],
            select_all: false
        });
    }


    saveChanges() {
        var self = this;

        if (!this.state.updating) {
            this.setState({updating: true});

            var subscribeBody = JSON.stringify({
                user_uuids: this.state.selected_users
            });

            subscribe_users(this.props.post_uuid, subscribeBody).then(function (success) {
                self.props.updateSubscribers();
                setTimeout(() => {
                    self.setState({updating: false});
                    self.props.cancel();
                }, 100);
            }, function (error) {
                NotificationAlert("error", "", "An unexpected error occurred.")
                self.props.cancel()
            });
        }
    }


    render() {
        return (
            (<Modal
                title="Who should be notified?"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.saveChanges}>
                            {
                                this.state.updating ? <ButtonLoading/> : "Save Changes"
                            }
                        </button>
                    </div>
                }
            >
                <span className="blue-link" onClick={this.selectAllUsers}>Select everyone</span> • <span
                className="blue-link" onClick={this.selectNoOne}>Select no one</span>
                <div className="subscribers-checkbox" style={{marginTop: "5px"}}>
                    {
                        this.state.subscriber_options.map((user, index) => (
                            <div key={index} className="form-check" style={{height: "30px"}}>
                                <Checkbox
                                    onChange={this.handleUserSelect}
                                    checked={this.state.selected_users.includes(user.uuid)}
                                    value={user.uuid}
                                >
                                    {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                    {user.first_name} {user.last_name}
                                </Checkbox>
                            </div>
                        ))
                    }
                </div>
            </Modal>)
        );
    }

}

export default SubscribersModal;