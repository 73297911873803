import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { dateFormatterNoTime, mainContentContainerClass, safe_get, scrollToTop } from '../../../other/Helper.js';

import { get_forms, get_forms_filters, update_form } from '../../../api/zero-api.js';

import Skeleton from 'react-loading-skeleton';

import {
    CalendarOutlined,
    EyeOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import { Pagination, Select, Tag } from 'antd';

import { ZeroContext } from 'components/ZeroContext.js';
import { syncForms } from 'offline/FormsCache.js';
import { Link } from 'react-router-dom';
import NotificationAlert from '../../../other/NotificationAlert.js';
import SchedulesListModal from '../../Shared/SchedulesListModal.js';
import FormOptionsModal from './FormOptionsModal.js';
import ManageFormsActions from './ManageFormsActions.js';
import ManageFormsNavigationTabs from './ManageFormsNavigationTabs.js';
import { FormType } from 'other/Constants.js';
import FormPreviewButton from './FormPreviewButton.js';

class ManageForms extends Component {
    static contextType = ZeroContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        let pathname = safe_get(this.props, "location.pathname", "");

        
        const params = new URLSearchParams(this.props.location.search);
        const filters = [];
        let currentPage = 1;
        params.forEach((value, key) => {
            if (key === 'page') {
                currentPage = parseInt(value, 10);
            } else {
                filters.push(`${key}=${value}`);
            }
        });

        this.state = {
            forms: [],
            form_categories: [],
            filtered_forms: Array(9).fill(0),
            filters,
            loading_forms: true,
            page_type: pathname.includes("incident") ? "incident" : pathname.includes("courses") ? "lms" : "regular",
            tableWidth: 870,
            tableHeight: 500,
            totalForms: 0,
            maxResults: 0,
            currentPage,
        };

        this.getForms = this.getForms.bind(this);

        this.goToEditForm = this.goToEditForm.bind(this);
        this.goToUser = this.goToUser.bind(this);

        this.formNameFormatter = this.formNameFormatter.bind(this);
        this.sharedTeamsRenderer = this.sharedTeamsRenderer.bind(this);
        this.optionsFormatter = this.optionsFormatter.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            scrollToTop("page-head");

            let pathname = safe_get(this.props, "location.pathname", "")
            this.setState({
                page_type: pathname.includes("incident") ? "incident" : pathname.includes("courses") ? "lms" : "regular",
            }, () => {
                this.getForms();
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let self = this;

        scrollToTop("page-head");
        this.getForms();

        get_forms_filters().then(success => {
            success.json().then(success => {
                self.setState({
                    form_categories: safe_get(success, "categories", [])
                });
            });
        });
    }

    adjustQueryParams() {
        const params = new URLSearchParams(this.state.filters.join('&'));
        params.set('page', this.state.currentPage);
        this.props.history.replace(`${this.props.location.pathname}?${params}`);
    }

    getFeFilters() {
        return this.state.filters.map(filter => filter.replace(/^search=/, ''));
    }

    getForms() {
        var self = this;
        if (this._isMounted) {
            this.setState({loading_forms: true}, () => {
                let query_params = [...self.state.filters].join("&");
                const params = new URLSearchParams(query_params);
                params.set('team_uuid', 'my_teams');
                params.set('all', 'true');
                params.set('form_types', this.state.page_type);
                params.set('include_fields', 'true');
                params.set('exclude_restricted', 'true');
                params.set('paginate', 'true');
                params.set('page', this.state.currentPage);
                params.set('per_page', '50');

                get_forms(`?${params}`).then(function (success) {
                    success.json().then(success => {
                        const forms = safe_get(success, "results", []);
                        forms.sort(function (a, b) {
                            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                        });

                        if (self._isMounted) {
                            self.setState({
                                forms: forms,
                                filtered_forms: forms,
                                loading_forms: false,
                                tableHeight: forms.length === 0 ? 100 : self.state.tableHeight,
                                maxResults: success.max_results,
                                totalForms: success.total,
                            });
                        }

                    });
                }, function (error) {
                    if (self._isMounted) {
                        self.setState({loading_forms: false});
                    }
                });
            });
        }
    }

    filterOption = (inputValue, option) => {
        return safe_get(option, "children", "").toLowerCase().includes(inputValue.toLowerCase())
    }

    selectFilter = (value) => {
        let filters = []

        value.forEach(filter => {
            if (filter.includes("category_uuid") || filter.includes("schedule_only")) {
                filters.push(filter)
            } else {
                filters.push(`search=${filter}`)
            }
        });

        this.setState({filters, currentPage: 1}, () => {
            this.adjustQueryParams();
            this.getForms();
        })
    }

    goToEditForm(form_uuid) {
        this.props.history.push(`/${this.props.org_uuid}/home/${this.getManageFormType()}/${form_uuid}/editor`);
    }

    goToUser(user_uuid) {
        this.props.history.push("/" + this.props.org_uuid + "/users/" + user_uuid);
    }

    getManageFormType() {
        if (this.state.page_type === "incident") {
            return "manage_incident_templates";
        } else if (this.state.page_type === "lms") {
            return "manage_courses";
        } else {
            return "manage_forms_templates";
        }
    }

    goToFormAnalytics = (form_uuid) => {
        this.props.history.push(`/${this.props.org_uuid}/home/${this.getManageFormType()}/${form_uuid}/analytics`);
    }

    formNameFormatter(form) {
        var name = form.name;
        var modified = form.edited_at;
        return (
            <div>
                <div className="flex" style={{gap: '0.5rem', alignItems: 'center'}}>
                    <button onClick={() => {
                        this.goToFormAnalytics(form.form_uuid)
                    }} style={{display: "block", fontWeight: "500", textAlign: "left"}} className="ButtonLink btn-link">
                        {name}
                    </button>
                    { form.is_public &&
                        <Tag color="green" style={{ borderColor: "#52c41a", fontSize: "11px" }}>Public</Tag>
                    }
                </div>
                <span className="zero-dark-grey" style={{fontSize: "12px", fontWeight: "400"}}>
          Last edited {dateFormatterNoTime(modified)}
        </span>
            </div>
        );
    }

    formTypeFormatter(form) {
        var type = form.form_type
        let form_type = form.schedule_only ? "Schedule Only" : type === 0 ? "Regular" : type === 2 ? "Incident" : type === 3 ? "Course" : ""
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400"}}>{form_type}</span>
        );
    }

    scheduledFormatter(form) {
        let schedulers_counts = form.schedulers_count
        if (schedulers_counts > 0) {
            return (
                <span className="blue-link" style={{fontWeight: "400"}} onClick={() => {
                    this.setState({showSchedules: true, selectedForm: form});
                }}>
          Yes
        </span>
            );
        } else {
            return (
                <span className="zero-dark-grey" style={{fontWeight: "400"}}>No</span>
            );
        }
    }

    sharedTeamsRenderer(form) {
        if (form.form_type === 2) {
            return <span style={{fontWeight: "400"}}>N/A</span>
        } else {
            var shared_teams = safe_get(form, "share_teams_count", 0);
            return (
                <div>
                    <button className="ButtonLink btn-link" onClick={() => {
                        this.setState({show_options_modal: true, team_access: true, form_to_edit: form});
                    }}>
                        {shared_teams}
                    </button>
                </div>
            );
        }
    }

    submissionsRendered(form) {
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400"}}>{form.submissions_count}</span>
        );
    }

    optionsFormatter(form) {
        return (
            <ManageFormsActions
                form={form}
                onDelete={this.onFormDelete}
                refresh={this.getForms}
            />
        );
    }

    updateForm(checked, form, index) {
        var self = this;

        var body = JSON.stringify({
            name: form.name,
            fields: form.fields,
            enabled: checked
        });

        update_form(form.form_uuid, body).then(function (success) {
            success.json().then(success => {
                var updated_form = safe_get(success, "form", {});
                self.setState(state => {
                    var updated_forms = state.filtered_forms;
                    updated_forms[index] = updated_form
                    return {
                        filtered_forms: updated_forms
                    }
                });
                NotificationAlert("success", "", "Template updated.");
            });
        }, function (error) {
            NotificationAlert("error", "", "Unable to update form.");
        });
    }

    onFormDelete = () => {
        this.getForms();
        if (this.state.page_type === "regular") {
            syncForms(this.context.caches.formsCache);
        }
    }

    onChangePage = (page) => {
        this.setState({currentPage: page}, () => {
            this.adjustQueryParams();
            this.getForms();
        });
    }

    render() {

        return (
            <div className={mainContentContainerClass()}>
                {
                    this.state.show_options_modal &&
                    <FormOptionsModal
                        show={this.state.show_options_modal}
                        cancel={() => {
                            this.setState({show_options_modal: false, form_to_edit: undefined, team_access: false});
                        }}
                        complete={() => {
                            this.setState({
                                form_to_edit: undefined,
                                show_options_modal: false
                            });
                        }}
                        form_to_edit={this.state.form_to_edit}
                        team_access={this.state.team_access}
                        forceFormType={this.state.page_type === "lms" ? FormType.LMS : this.state.page_type === "incident" ? FormType.INCIDENT : null}
                        refresh={this.getForms}
                    />
                }
                {
                    this.state.showSchedules &&
                    <SchedulesListModal
                        show={this.state.showSchedules}
                        cancel={() => {
                            this.setState({showSchedules: false});
                        }}
                        form={this.state.selectedForm}
                        goToSchedule={(schedule_uuid) => {
                            this.props.history.push("/" + this.props.org_uuid + "/home/manage_schedules/analytics/" + schedule_uuid);
                        }}
                    />
                }
                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                {
                                                    this.state.page_type === "incident" ? "Manage Incident Templates" : this.state.page_type === "lms" ? "Courses" : "Manage Templates"
                                                }
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '150px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({show_options_modal: true});
                                            }}>
                                                <PlusCircleOutlined/> Create {this.state.page_type === "lms" ? "Course" : "Template"}
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({show_options_modal: true});
                                            }}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            {
                                                this.state.page_type === "incident" ? "Create and edit custom forms for incidents." : this.state.page_type === "lms" ? "Create and edit courses." : "Create and edit form templates."
                                            }
                                        </p>

                                        <div className="members-search" style={{width: "100%", marginTop: "8px"}}>
                                            {this.state.loading_forms && <Skeleton height={32} />}

                                            {!this.state.loading_forms && <>
                                                <Select
                                                    mode="tags"
                                                    placeholder={"Filter or search..."}
                                                    style={{width: "100%"}}
                                                    filterOption={this.filterOption}
                                                    onChange={this.selectFilter}
                                                    allowClear={true}
                                                    virtual={false}
                                                    value={this.getFeFilters()}
                                                >
                                                    {
                                                        this.state.page_type !== "lms" && this.state.page_type !== "incident" &&
                                                        <Select.OptGroup label={"Type"}>
                                                            <Select.Option
                                                                value={"schedule_only=false"}>Regular</Select.Option>
                                                            <Select.Option value={"schedule_only=true"}>Schedule
                                                                Only</Select.Option>
                                                        </Select.OptGroup>
                                                    }
                                                    {
                                                        this.state.form_categories.length > 0 && this.state.page_type !== "lms" && this.state.page_type !== "incident" &&
                                                        <Select.OptGroup label={"Category"}>
                                                            {
                                                                this.state.form_categories.map((category, index) => (
                                                                    <Select.Option key={`catgeory:${index}`}
                                                                                value={"category_uuids=" + category.category_uuid}>{category.category_name}</Select.Option>
                                                                ))
                                                            }
                                                        </Select.OptGroup>
                                                    }
                                                </Select>
                                            </>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        <div className="tab-base" style={{
                            marginBottom: "0px",
                            padding: "0px",
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <ManageFormsNavigationTabs currentTab="templates" pageType={this.state.page_type} />
                            { this.state.page_type !== 'incident' &&
                                <div className="hidden-xs" style={{display: 'flex', alignItems: 'center'}}>
                                    <div
                                        className="hover-cursor zero-blue"
                                        style={{lineHeight: 1}}>
                                        {this.props.location.pathname.includes("manage_calendar") ?
                                            <Link to={`/${this.props.org_uuid}/home/manage_schedules`}>
                                                <UnorderedListOutlined className="zero-blue" style={{fontSize: '20px'}}/>
                                            </Link> :

                                            <Link to={`/${this.props.org_uuid}/home/manage_calendar/${this.state.page_type}`}>
                                                <CalendarOutlined className="zero-blue" style={{fontSize: '20px'}}/>
                                            </Link>
                                        }
                                    </div>
                                    <ReloadOutlined className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                                    style={{fontSize: '18px'}} onClick={this.getForms}/>
                                </div>
                            }
                        </div>
                        <div className="tab-content" style={{
                            border: "1px solid #e2e2e2",
                            padding: "4px 4px",
                            float: "left",
                            background: "white",
                            paddingBottom: "35px",
                            width: "100%"
                        }}>
                                <div className="table-responsive">
                                    <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                        <thead style={{color: "#1D2A35"}}>
                                        <tr>
                                            <th style={{width: '20px'}}></th>
                                            <th className="zero-blue" style={{width: "250px"}}>
                                                {this.state.loading_forms ? <Skeleton
                                                    width={130}/> : this.state.page_type === "lms" ? "Course" : "Template"}
                                            </th>
                                            <th className="zero-blue">
                                                {this.state.loading_forms ? <Skeleton width={50}/> : "Type"}
                                            </th>
                                            <th className="zero-blue text-center">
                                                {this.state.loading_forms ? <Skeleton width={50}/> : "Teams"}
                                            </th>
                                            <th className="zero-blue text-center">
                                                {this.state.loading_forms ? <Skeleton width={80}/> : "Submissions"}
                                            </th>
                                            <th className="zero-blue text-center">
                                                {this.state.loading_forms ? <Skeleton width={80}/> : "Scheduled"}
                                            </th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        {
                                            this.state.loading_forms &&
                                            <tbody>
                                            {
                                                [...Array(10)].map((_item, index) => (
                                                    <tr key={index} className="tr-hover">
                                                        <td><Skeleton width={25}/></td>
                                                        <td style={{width: "300px"}}>
                                                            <Skeleton width={115}/>
                                                            <Skeleton width={70}/>
                                                        </td>
                                                        <td style={{width: "120px"}}><Skeleton width={100}/></td>
                                                        <td className="text-center"><Skeleton width={25}/></td>
                                                        <td className="text-center"><Skeleton width={25}/></td>
                                                        <td className="text-center"><Skeleton width={25}/></td>
                                                        <td><Skeleton width={25}/></td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        }
                                        {
                                            !this.state.loading_forms && this.state.filtered_forms.length > 0 &&
                                            <tbody>
                                            {
                                                this.state.filtered_forms.map((form, index) => (
                                                    <tr key={index} className="tr-hover">
                                                        <td>{this.optionsFormatter(form)}</td>
                                                        <td style={{width: "300px"}}>{this.formNameFormatter(form)}</td>
                                                        <td style={{width: "120px"}}>{this.formTypeFormatter(form)}</td>
                                                        <td className="text-center">{this.sharedTeamsRenderer(form)}</td>
                                                        <td className="text-center">{this.submissionsRendered(form)}</td>
                                                        <td className="text-center">{this.scheduledFormatter(form)}</td>
                                                        <td>
                                                            <FormPreviewButton form={form} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        }
                                        {
                                            !this.state.loading_forms && this.state.filtered_forms.length === 0 &&
                                            <tbody>
                                            <tr>
                                                <td className="react-bs-table-no-data zero-dark-grey" colSpan="6">
                                                    {this.state.page_type === "lms" ? "No courses." : "No templates."}
                                                </td>
                                            </tr>
                                            </tbody>
                                        }

                                    </table>
                                </div>
                                <Pagination
                                    size={"small"}
                                    style={{padding: '1rem 0'}}
                                    showSizeChanger={false}
                                    hideOnSinglePage={true}
                                    pageSize={this.state.maxResults}
                                    total={this.state.totalForms}
                                    current={this.state.currentPage}
                                    onChange={this.onChangePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            // </div>

        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        teams: safe_get(store, "teams_helper.teams", [])
    }
}

export default withRouter(connect(mapStateToProps)(ManageForms));
