import React, { useMemo } from 'react';
import {Select} from 'antd';

import {actions} from './reducer';
import {safe_get} from 'other/Helper';
import { getPostSelectOptions } from './PostFormCategory';

export default function PostFormStatus({state, dispatch, postFieldNames, group}) {
    const statuses = group === 2 ? state.statuses2 : state.statuses;
    const selectedStatus = group === 2 ? state.selectedStatus2 : state.selectedStatus;
    const fieldName = group === 2
        ? safe_get(postFieldNames, "sub_status_2", "Status 2")
        : safe_get(postFieldNames, "sub_status", "Status");
    const statusChangedAction = group === 2 ? actions.STATUS_2_CHANGED : actions.STATUS_CHANGED;
    const statusError = group === 2 ? state.status2Error : state.statusError;

    const selectOptions = useMemo(() => {
        return getPostSelectOptions(statuses, "sub_status_uuid", selectedStatus);
    }, [statuses, selectedStatus]);

    if (statuses.filter(s => s.enabled).length == 0) return null;

    return (
        <>
            <div className="panel-heading bulletin-post ">
                <h3 className="bulletin-post-headers required">{fieldName}</h3>
            </div>
            <div className="panel-body bulletin-post">
                <Select
                    style={{maxWidth: "400px", width: "100%"}}
                    placeholder=""
                    value={selectedStatus}
                    onChange={newStatus => dispatch([statusChangedAction, newStatus])}
                    className="custom-bulletin"
                    virtual={false}
                    dropdownStyle={{zIndex: "999999999"}}
                    status={statusError ? "error" : null}
                >
                    { selectOptions }
                </Select>
                {
                    statusError &&
                    <small className="error" style={{display: "block"}}>{statusError}</small>
                }
            </div>
        </>
    )
}