import React, {Component} from 'react';
import {connect} from 'react-redux'

import {kiosk_rename_device, kiosk_turn_on, kiosk_turn_off, update_auth_token} from '../../../api/zero-api.js';

import NotificationAlert from '../../../other/NotificationAlert.js';
import ModalPopover from '../../ModalPopover';
import LoadingIndicator from '../../Shared/LoadingIndicator.js';
import ButtonLoading from '../../Shared/ButtonLoading.js';
import {safe_get, fieldIsNotEmpty} from '../../../other/Helper';

import Skeleton from 'react-loading-skeleton'

import {EditOutlined, CheckOutlined} from '@ant-design/icons';
import {Popover, Input} from 'antd';


class KioskMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            table: safe_get(this.props, "kiosk_sessions", []),
            removeConfirmation: "",
            settingKiosk: false
        };

        this.addKioskDevice = this.addKioskDevice.bind(this);
        this.removeConfirmation = this.removeConfirmation.bind(this);
        this.removeSession = this.removeSession.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (this.props.kiosk_sessions !== prevProps.kiosk_sessions) {
            this.setState({table: this.props.kiosk_sessions});
        }
    }

    inputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    addKioskDevice() {
        var self = this;

        if (!this.state.settingKiosk) {
            this.setState({settingKiosk: true});

            let device_name = safe_get(this.state, "kiosk_device_name", "");
            if (fieldIsNotEmpty(device_name)) {
                var body = JSON.stringify({
                    device_name: device_name
                });

                kiosk_turn_on(body).then(function (success) {
                    success.json().then(success => {
                        self.setState({settingKiosk: false});
                        update_auth_token(safe_get(success, "kiosk_mode_token", undefined))
                        window.location = "/kiosk_mode"
                    });
                }, function (error) {
                    console.log(error);
                    self.setState({settingKiosk: false});
                });
            }
        }


    }

    deviceNameFormatter(device) {
        if (safe_get(this.state, "deviceToEdit.session_uuid", "") === device.session_uuid) {
            return (
                <Input size="small" style={{width: "80%"}} defaultValue={device.device_name} onChange={(e) => {
                    this.setState({editedDeviceName: e.target.value})
                }} addonAfter={<span style={{cursor: "pointer"}} onClick={this.saveEdit}><CheckOutlined/></span>}/>
            )
        }
        return (
            <span className="zero-dark-grey">
              {device.device_name} <EditOutlined className="mar-rgt-5" style={{cursor: "pointer"}} onClick={() => {
                this.setState({deviceToEdit: device});
            }}/>
                {device.aged &&
                    <Popover
                        trigger={["click", "hover"]}
                        content={<p style={{margin: '0px', fontSize: '90%',}}>For security purposes, please consider
                            deleting inactive kiosks.</p>}
                        title=""
                        placement={"bottom"}
                        className="custom-popover"
                        style={{backgroundColor: '#eaeaea !important', padding: '5px'}}
                    >
                        <br className="visible-xs"/>
                        <span className="label label-danger">Inactive</span>
                    </Popover>
                }
          </span>
        );
    }

    removeFormatter(device) {
        return (
            <button onClick={() => this.removeConfirmation(device)} className="ButtonLink blue-link">
                Delete Kiosk
            </button>
        );
    }

    removeConfirmation(device) {
        this.setState({
            remove_session: device,
            showModal: true
        })
    }

    removeSession() {
        var self = this;
        var kiosk_devices = this.state.table;

        var index = kiosk_devices.indexOf(this.state.remove_session);
        var is_current_session = this.state.remove_session.is_current

        kiosk_turn_off(this.state.remove_session.session_uuid).then(function (success) {
            if (index >= 0) {
                kiosk_devices.splice(index, 1);
                self.setState({remove_session: "", showModal: false, table: kiosk_devices});
                NotificationAlert('success', 'Success!', "Kiosk has been turned off.");
            }

            if (is_current_session) {
                window.location = "/login";
            }

        }, function (error) {
            self.setState({remove_session: "", showModal: false});
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to turn off kiosk.");
        });

    }

    saveEdit = (row, newValue) => {
        let self = this;

        if (!this.state.editedDeviceName || this.state.editedDeviceName === this.state.deviceToEdit.device_name) {
            this.setState({editedDeviceName: null, deviceToEdit: null});
            return
        }

        var body = JSON.stringify({
            device_name: this.state.editedDeviceName
        });

        kiosk_rename_device(this.state.deviceToEdit.session_uuid, body).then(function (success) {
            NotificationAlert('success', 'Success!', "Kiosk name has been changed.");
            self.props.refresh();
            self.setState({deviceToEdit: null, editedDeviceName: null});
        }, function (error) {
            NotificationAlert('error', 'Oops!', "An Error occurred. Unable to change kiosk name.");
        });


    }

    render() {
        const options = {
            sortIndicator: false,
            // defaultSortName: 'name',
            defaultSortOrder: 'asc',
            hideSizePerPage: true,
            noDataText: "Once setup, all Kiosk devices will be listed here."
        };

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: (row, column, newValue, oldValue) => {
                this.saveEdit(row, newValue)
            }
        };

        function trClassFormat(rowData, rIndex) {
            return safe_get(rowData, "last_access", "").toLowerCase() === "current session" ? 'tr-current-session' : '';
        }

        let kioskNameLength = safe_get(this.state, "kiosk_device_name", "").length;

        return (
            <div className="panel" style={{padding: "15px"}}>
                {
                    this.state.showModal &&
                    <ModalPopover
                        show={this.state.showModal}
                        cancel={() => {
                            this.setState({showModal: false})
                        }}
                        confirm={this.removeSession}
                        title={"Confirmation"}
                        text={"Are you sure you want to delete this Kiosk?"}
                        confirmButtonName={"Delete Kiosk"}
                        small={true}
                    />
                }

                <div className="">
                    {
                        !this.props.kiosk_mode &&
                        <div>
                            <div className="account-header">
                                <h4 className="zero-blue mar-top-0">Create New Kiosk</h4>
                            </div>
                            <div className="account-header">
                                <p className="zero-dark-grey">Note: after you enable Kiosk Mode for this device, you
                                    will be immediately logged out.</p>
                                <div style={{marginTop: "5px"}}>
                                    <input
                                        name="kiosk_device_name"
                                        type="text"
                                        placeholder="Name this device (e.g., Operations iPad)"
                                        className={"form-control custom-placeholder"}
                                        style={{display: "inline-block", width: "60%"}}
                                        maxLength={30}
                                        value={safe_get(this.state, "kiosk_device_name", "")}
                                        onChange={this.inputChange}
                                    />
                                    <button className="btn btn-primary " style={{
                                        width: '100px',
                                        textAlign: 'center',
                                        display: 'inline-block',
                                        padding: "5px",
                                        marginLeft: "8px",
                                        verticalAlign: "top"
                                    }} onClick={this.addKioskDevice}>
                                        {
                                            this.state.settingKiosk ? <ButtonLoading/> : "Create Kiosk"
                                        }
                                    </button>
                                </div>
                                {
                                    kioskNameLength <= 30 &&
                                    <div style={{maxWidth: "60%", display: "block"}}>
                                        <p className="text-sm" style={{
                                            color: '#BCBABC',
                                            paddingTop: '3px',
                                            float: 'right',
                                            marginBottom: "0px"
                                        }}>{30 - kioskNameLength}</p>
                                    </div>
                                }
                            </div>
                            <hr/>
                        </div>
                    }

                    <div className="account-header">
                        {
                            this.props.kiosk_mode &&
                            <p>Kiosk Mode is currently turned ON for this device. To turn OFF Kiosk Mode, locate your
                                Current Session in the table below and select Turn OFF Kiosk Mode.</p>
                        }
                        <h4 className="zero-blue">Existing Kiosks</h4>
                        <p className="zero-dark-grey">Note: To protect your account, we will automatically delete any
                            kiosks that have been inactive for 90 days.</p>
                    </div>
                    <div className="panel mar-btm-0">

                        <div className="table-responsive" style={{border: "1px solid #DDDDDD", borderRadius: "5px"}}>
                            <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                <thead style={{color: "#1D2A35"}}>
                                <tr>
                                    <th></th>
                                    <th className="zero-blue">
                                        Kiosk Name
                                    </th>
                                    <th className="zero-blue">
                                        Last Access
                                    </th>
                                </tr>
                                </thead>
                                {
                                    this.props.loading &&
                                    <tbody>
                                    {
                                        [...Array(8)].map((_item, index) => (
                                            <tr key={index} className="tr-hover">
                                                <td><Skeleton width={85}/></td>
                                                <td className="text-left"><Skeleton width={150}/></td>
                                                <td className="text-left"><Skeleton width={120}/></td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.props.loading &&
                                    <tbody>
                                    {
                                        this.state.table.map((device, index) => (
                                            <tr key={index}
                                            className={"tr-hover" + (safe_get(device, "access_time", "").toLowerCase() === "current session" ? " tr-current-session" : "")}>
                                                <td>{this.removeFormatter(device)}</td>
                                                <td className="text-left zero-dark-grey">{this.deviceNameFormatter(device)}</td>
                                                <td className="text-left zero-dark-grey">{device.last_access}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                }
                                {
                                    !this.props.loading && this.state.table.length === 0 &&
                                    <tbody>
                                    <tr>
                                        <td className="react-bs-table-no-data" colSpan="6">
                                            Once setup, all Kiosk devices will be listed here.
                                        </td>
                                    </tr>
                                    </tbody>
                                }

                            </table>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
        kiosk_mode: safe_get(store, "app.kiosk_mode", false)
    }
}

export default connect(mapStateToProps)(KioskMode);