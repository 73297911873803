import {useOfflineDataCachesContext} from "components/Offline/OfflineDataCaches";
import useOfflineMode from "components/Offline/useOfflineMode"
import {useMemo} from "react";

export default function SideBarWrapper({Child, ...props}) {
    const {isOffline} = useOfflineMode();
    const {submissionDrafts: {drafts}} = useOfflineDataCachesContext();

    const errorCount = useMemo(() => {
        return drafts.filter(draft => draft.$error).length;
    }, [drafts]);

    return <Child isOffline={isOffline} draftErrorCount={errorCount} {...props}/>
}