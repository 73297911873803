import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {Modal} from 'antd';

import NotificationAlert from '../../other/NotificationAlert.js';
import ButtonLoading from '../Shared/ButtonLoading';

import {delete_location, get_locations} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet.js';

import * as incidentsActions from '../../store/actions/IncidentsActions'

import '../../assets/css/antd-custom.css'

class DeleteLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.deleteLocation = this.deleteLocation.bind(this);
        this.updateLocations = this.updateLocations.bind(this);

    }

    deleteLocation() {
        var self = this;
        if (!this.state.deleting) {
            this.setState({deleting: true});
            delete_location(this.props.location_uuid).then(function (success) {
                success.json().then(success => {
                    self.props.callback();
                });
            }, function (error) {
                self.setState({deleting: false});
                NotificationAlert("success", "", "Unable to delete location.")
            });
        }

    }

    updateLocations() {
        var self = this;
        get_locations().then(function (success) {
            success.json().then(success => {
                var locations = safe_get(success, "locations", []);
                self.props.dispatch(incidentsActions.update_incidents_locations(locations));
                self.setState({deleting: false});
                self.props.cancel();

                NotificationAlert("success", "", "Location deleted.")

                if (self.props.callback) {
                    self.props.callback(locations);
                } else if (locations.length > 0) {
                    var location = safe_get(locations[0], "location_uuid", "undefined");
                    self.props.history.push("/" + safe_get(self.props.organization, "organization_uuid", "undefined") + "/home/incidents/location/" + location)
                } else {
                    self.props.history.push("/" + safe_get(self.props.organization, "organization_uuid", "undefined") + "/home/incidents/new_location")
                }

            });
        }, function (error) {
            console.log(error);
            self.setState({deleting: false});
        });
    }


    render() {
        return (
            (<Modal
                title="Delete location"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.deleteLocation}>
                            {
                                this.state.deleting ? <ButtonLoading/> : "Delete location"
                            }
                        </button>
                    </div>
                }
            >
                <p>Are you sure you want to delete this location? All incidents in the location will be deleted. This
                    action cannot be undone.</p>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        incident_locations: safe_get(store, "incidents.locations", [])

    }
}

export default withRouter(connect(mapStateToProps)(DeleteLocationModal));