import React from 'react';

import ToggleRisk from 'components/Shared/ToggleRisk';
import {actions} from './reducer';
import {safe_get} from 'other/Helper';

export default function PostFormRiskLevel({state, dispatch, postFieldNames, riskEnabled}) {
    if (!riskEnabled) return null;
    return (
        <div>
            <div className="panel-heading bulletin-post">
                <h3 className="bulletin-post-headers">{safe_get(postFieldNames, "severity_level", "Risk Level")}</h3>
            </div>
            <div className="panel-body bulletin-post">
                {state.riskLevels.length > 0 &&
                    state.riskLevels.map((risk, index) => (
                        <ToggleRisk
                            key={index}
                            risk={risk}
                            name={risk.name}
                            onClickRisk={risk => dispatch([actions.RISK_LEVEL_CHANGED, risk])}
                            active={safe_get(state.selectedRiskLevel, "value", 0) === risk.value}
                        />
                    ))
                }
                {state.selectedRiskLevel &&
                    <p className="zero-dark-grey">{state.selectedRiskLevel.description}</p>
                }
            </div>
        </div>
    );
}