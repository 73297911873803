import React, {Component} from 'react';

import {Bar} from 'react-chartjs-2';

import {get_incident_dates_chart,} from '../../../api/zero-api.js'
import safe_get from '../../../other/SafeGet';
import Skeleton from 'react-loading-skeleton'

class IncidentsDateChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {},
            loading: true
        };

    }

    componentDidUpdate(prevProps) {
        if ((this.props.query !== prevProps.query) || (this.props.location_uuid !== prevProps.location_uuid)) {
            this.getAnalytics();
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;
        this.setState({loading: true});

        let query = "?" + this.props.query

        get_incident_dates_chart(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    data: safe_get(success, "data", {}),
                    options: safe_get(success, "options", {}),
                    loading: false
                });
            });
        }, function (error) {
            self.setState({loading: false});
        });

    }

    render() {
        return (
            <div className="col-lg-12 col-xs-12 col-print-12 col-reduce-padding print-avoid-page-break">
                <div className="panel thin-border print-no-y-margins">
                    <div className="panel-heading" style={{height: "25px"}}>
                        <h2 className="panel-title analytics" style={{display: 'inline-block', paddingRight: '0px'}}>
                            {(this.state.loading || this.props.loading) ?
                                <Skeleton width={150}/> : "Incidents by Date of Event"}
                        </h2>
                    </div>
                    <div className="pad-all no-padding-print print-incident-chart-wrapper" style={{paddingTop: "0px"}}>
                        {
                            (this.state.loading || this.props.loading) ?
                                <Skeleton width={"100%"} height={266}/> :
                                <Bar height={100} data={this.state.data} options={this.state.options}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default IncidentsDateChart;