import { EditOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { dateFormatterWithTime, safeProfilePic } from 'other/Helper';
import { useMemo, useState } from 'react';

import styles from "./Timeline.module.css";

function UsersNotified({usersNotified}) {
    if (usersNotified === null || usersNotified === undefined) {
        return null;
    } else if (usersNotified.length === 0) {
        return <>&nbsp;&bull;&nbsp;No one was notified</>
    }

    const count = usersNotified.length;

    const userList = useMemo(() => {
        return (
            <div className={styles.usersNotifiedPopover}>
                <span className="text-semibold">Notified:</span>
                {
                    usersNotified.map(user => (
                        <div key={user.uuid}>{user.full_name} ({user.email})</div>
                    ))
                }
            </div>
        );
    }, [usersNotified]);

    return (
        <Popover placement="bottom" content={userList}>
            &nbsp;&bull;&nbsp;{count} {count === 1 ? 'person was' : 'people were'} notified
        </Popover>
    )
}

export function TimelineEntry({icon, author, time, showDetailsLink, action, usersNotified, body, details}) {
    const [showDetails, setShowDetails] = useState(false);
    const Icon = icon ?? EditOutlined;

    const timelineEntryClasses = ['timeline-entry'];
    const timelineLabelClasses = ['timeline-label'];

    if (showDetails) {
        timelineEntryClasses.push(styles.showDetails);
        timelineLabelClasses.push(styles.showDetailsLabel);
    }

    return (
        <div className={timelineEntryClasses.join(' ')}>
            <div className="timeline-stat">
                <div className="timeline-icon">
                    <Icon className="mar-rgt-5"/>
                </div>
            </div>
            <div className={timelineLabelClasses.join(' ')}>
                <div className={styles.labelWrapper}>
                    <div>{safeProfilePic(author, "img-circle img-sm bulletin", "bulletin", {marginRight: "1rem"})}</div>
                    <div className={styles.commentDataWrapper}>
                        {action}
                        {showDetailsLink &&
                            <>&nbsp;<span className='blue-link dont-print'
                                          onClick={() => setShowDetails(!showDetails)}>{showDetails ? 'Hide' : 'Show'} details</span></>
                        }
                        <div className={styles.when}>
                            <span>{dateFormatterWithTime(time)}</span>
                            <span><UsersNotified usersNotified={usersNotified} /></span>
                        </div>
                        { body &&
                            <div className={styles.commentBody}><br/>{body}</div>
                        }
                        { showDetails && details }
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Timeline({className, style, children}) {
    return (
        <div className={className} style={style}>
            <div className="timeline timeline-small" style={{paddingBottom: '10px'}}>
                {children}
            </div>
        </div>
    )
}

Timeline.Entry = TimelineEntry;

