import React, {Component} from 'react';

class LoadingIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={this.props.columnClass} style={this.props.style}>
                <div className="panel" style={this.props.panelStyle}>
                    <div className="panel-body">
                        <div className="load3">
                            {
                                this.props.small ?
                                    <div className="loader" style={{margin: "10px auto"}}></div> :
                                    <div className="loader"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingIndicator;