import {} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import reducers from './reducers'


const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: hardSet,
    blacklist: ['checkInOptions', 'manageDesks', 'manageCheckInAlerts', 'manageSpaces']
}

/** @type {ReturnType<typeof configureStore<import('../../types/redux').AppRootState>>} */
let store;
let persistor;

export function getStore() {
    if (!store && !persistor) {
        store = configureStore({
            reducer: persistReducer(persistConfig, reducers),
            devTools: !window.location.hostname.includes('teamzero.com'),
            middleware: getDefaultMiddleware => (
                getDefaultMiddleware({
                    serializableCheck: false,
                    immutableCheck: false
                })
            ),
        });
        
        persistor = persistStore(store)
    }
    return {store, persistor}
}




