import React, {Component} from 'react';

import {get_submission} from '../../api/zero-api.js'
import {safe_get} from '../../other/Helper'

import {Modal} from 'antd';

import LoadingIndicator from '../Shared/LoadingIndicator.js';
import FormFieldsRenderer from './FormFieldsRenderer'

import '../../assets/css/antd-custom.css';
import { shouldFormFieldDisplay } from '../../other/Helper';


class FailedFieldsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            field_answers: [],
            form_fields: [],
        };

    }

    componentDidMount() {
        var self = this;
        get_submission(this.props.submission_uuid).then(function (success) {
            success.json().then(success => {
                let field_answers = safe_get(success, "submission.fields", []);
                let form_fields = safe_get(success, "submission.form.fields", []);

                let failed_fields = (
                    form_fields
                    .filter(field => {
                        let answer = field_answers.find(item => {
                            return item.id === field.id
                        });

                        if (answer) {
                            if (self.isFailedField(field, answer.value) && shouldFormFieldDisplay(field, field_answers)) {
                                return true;
                            }
                        }

                        return false
                    })
                    .map(field => ({...field, sectionId: undefined, custom_logic: undefined}))
                );

                self.setState({
                    submission: success.submission,
                    form_fields: failed_fields,
                    field_answers: field_answers,
                    loading: false
                });

            });
        }, function (error) {
            self.setState({loading: false});
        });
    }

    isFailedField = (field, answer) => {
        if (!answer || answer.length === 0 || !answer[0]) {
            return false
        }

        let found = safe_get(field, "options", []).find(item => {
            return item.key === answer[0]
        });

        return found ? found.is_failure : false
    }

    render() {
        return (
            (<Modal
                title="Failed Items"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        <button className="btn btn-modal" onClick={this.props.cancel}>
                            Done
                        </button>
                    </div>
                }
            >
                <div style={{maxHeight: "450px", overflow: "auto"}}>
                    {
                        this.state.loading &&
                        <LoadingIndicator/>
                    }
                    {
                        !this.state.loading && this.state.form_fields.length > 0 &&
                        <FormFieldsRenderer
                            form_uuid={safe_get(this.state, "submission.form.form_uuid", "")}
                            submission_uuid={safe_get(this.state, "submission.submission_uuid", "")}
                            form_fields={this.state.form_fields}
                            field_answers={this.state.field_answers}
                            pages={[this.state.form_fields]}
                            page={0}
                            saveDraft={() => {
                            }}
                            isAnalytics={true}
                            isComment={true}
                            isSubmissionView={true}
                        />
                    }
                    {
                        !this.state.loading && this.state.form_fields.length === 0 &&
                        <p>No failed items.</p>
                    }
                </div>
            </Modal>)
        );
    }

}

export default FailedFieldsModal;