import React, {Component} from 'react';

class ButtonLoading extends Component {
    render() {
        return (
            <div className={"spinner " + (this.props.className || '')} style={this.props.style || null}>
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        );
    }
}

export default ButtonLoading;