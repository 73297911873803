import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import FormFieldCommentAttachment from './FormFieldCommentAttachment.js';
import FormFieldEditorModal from './FormFieldEditorModal.js';

import ConfirmModal from '../Shared/ConfirmModal.js';

import safe_get from '../../other/SafeGet.js';
import {shouldFormFieldDisplay, floatingButtonMargin, generateUUID} from '../../other/Helper.js'

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

import moment from 'moment';
import {DatePicker, Select, Popover, Menu} from 'antd';

import SignatureCanvas from 'react-signature-canvas';

import '../../assets/css/form-builder.css'

var saveContentTimeout = null;

class ConfirmationFieldsRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            readyToSaveDraft: true,
            form_fields: [],
            field_answers: [],
            answers_data: [],
            saving: false,
            // lastRowFormFields: null,
            // lastRowConfirmFields: null,
        };

        this.initializeAnswersStructure = this.initializeAnswersStructure.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.is_builder &&
            (this.props.form_fields !== nextProps.form_fields || this.props.confirmation_fields !== nextProps.confirmation_fields)
        ) {
            this.setState({
                // loading: true,
                form_fields: nextProps.form_fields,
                field_answers: nextProps.field_answers,
                confirmation_fields: nextProps.confirmation_fields
            }, () => {
                this.initializeAnswersStructure();
            })
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.is_builder &&
    //         (props.form_fields !== state.lastRowFormFields || props.confirmation_fields !== state.lastRowConfirmFields)
    //     ) {
    //         this.setState({
    //             // loading: true,
    //             form_fields: state.lastRowFormFields,
    //             field_answers: state.field_answers,
    //             confirmation_fields: state.lastRowConfirmFields
    //         }, () => {
    //             this.initializeAnswersStructure();
    //         })
    //     }
    // }

    componentDidMount() {
        this.setState({
            confirmation_fields: this.props.confirmation_fields,
            form_fields: this.props.form_fields,
            field_answers: this.props.field_answers,
        }, () => {
            this.initializeAnswersStructure();
        });
    }

    initializeAnswersStructure() {
        var answers = []

        var fields = safe_get(this.state, "form_fields", []);
        var field_answers = safe_get(this.state, "field_answers", []);

        fields.forEach(function (field) {
            let found = field_answers.filter(function (obj) {
                return obj.id === field.id
            });

            var answer = {}

            answer["id"] = field.id;
            answer["element"] = field.element;
            answer["name"] = field.field_name;
            answer["required"] = field.required;

            let comment = found.length > 0 ? found[0].comment : "";
            answer["comment"] = comment;
            answer["comment_saved"] = comment !== "" && comment !== undefined;
            answer["attachments"] = found.length > 0 ? found[0].attachments : [];
            answer["related_post_uuid"] = found.length > 0 ? found[0].related_post_uuid : undefined;
            answer["value"] = found.length > 0 ? found[0].value : undefined;

            answers.push(answer);
        });

        this.setState({
            answers_data: answers,
            loading: false
        });
    }


    copyField = (field, index) => {
        let copyField = {...field}
        copyField["id"] = generateUUID();

        let fields = [...this.state.confirmation_fields];
        fields.splice(index, 0, copyField)

        this.props.saveForm(fields);
    }

    deleteField = () => {
        this.props.deleteField(this.state.deleteFieldIndex);
        setTimeout(() => {
            this.setState({showDeleteModal: false, deleteFieldIndex: undefined});
        }, 600);
    }

    addParagraph = (index) => {
        let field = [...this.props.toolbar_items][0];
        if (field) {
            this.addField(field, index + 1);
        }
    }

    addField = (new_field, index) => {
        var fields = [...this.state.confirmation_fields];

        let field = {...new_field};
        field["id"] = generateUUID();
        delete field["icon"];

        fields.splice(index, false, field);
        this.props.saveForm(fields);
        this.setState({activeIndex: -1});
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.confirmation_fields,
            result.source.index,
            result.destination.index
        );

        this.setState({confirmation_fields: items});
        this.props.saveForm(items);
    }

    render() {
        let {form_uuid, is_preview, is_builder, is_submission, toolbar_items, icons} = this.props
        let answers = [...this.state.answers_data];

        if (this.state.loading) {
            return null
        } else {
            return (
                <div className={"react-form-builder-form " + (is_builder ? "builder" : "")}>

                    {
                        this.state.showEditFieldModal &&
                        <FormFieldEditorModal
                            show={this.state.showEditFieldModal}
                            cancel={() => {
                                this.setState({showEditFieldModal: false, editFieldIndex: undefined});
                            }}
                            field_index={this.state.editFieldIndex}
                            fields={this.state.confirmation_fields}
                            conditional_fields={this.state.form_fields}
                            is_confirmation={this.props.is_confirmation}
                            saveField={this.props.saveField}
                        />
                    }
                    {
                        this.state.showDeleteModal &&
                        <ConfirmModal
                            show={this.state.showDeleteModal}
                            cancel={() => {
                                this.setState({showDeleteModal: false, deleteFieldIndex: undefined});
                            }}
                            title={"Confirmation"}
                            body={"Are you sure you want to delete this field?"}
                            confirmButtonName={"Delete field"}
                            confirm={this.deleteField}
                        />
                    }

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="form-droppable" isDropDisabled={!is_builder}>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} >
                                    {
                                        safe_get(this.props, "pages", []).map((page_fields, pageIndex) => (

                                            this.props.page === pageIndex &&
                                            page_fields.map((field, index) => {

                                                let element = field.element;

                                                if (is_builder || shouldFormFieldDisplay(field, answers)) {
                                                    return (
                                                        <Draggable key={"draggable" + element + index}
                                                                   draggableId={element + index} index={index}
                                                                   isDragDisabled={!is_builder}>
                                                            {(provided) => (
                                                                <div
                                                                    className={"SortableItem rfb-item " + (this.state.activeIndex === index ? "active" : "")} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}>
                                                                    <div className="form-group mar-btm-5 ">
                                                                        {
                                                                            this.props.is_builder &&
                                                                            <div className="field-button-group">
                                                                                <div style={{display: "inline"}}>
                                                                                    <p className="element-badge">{field.text}</p>
                                                                                    {
                                                                                        field.custom_logic &&
                                                                                        <p className="logic-badge">Logic
                                                                                            Applied</p>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    element !== "LineBreak" && element !== "PageBreak" &&
                                                                                    <button className="btn btn-discard"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    editFieldIndex: index,
                                                                                                    showEditFieldModal: true
                                                                                                });
                                                                                            }}>Edit</button>
                                                                                }
                                                                                <button className="btn btn-discard"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.copyField(field, index)
                                                                                        }}>Copy
                                                                                </button>
                                                                                <button className="btn btn-discard"
                                                                                        disabled={page_fields.length == 1}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({
                                                                                                deleteFieldIndex: index,
                                                                                                showDeleteModal: true
                                                                                            });
                                                                                        }}>Delete
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            element === "Paragraph" &&
                                                                            <div
                                                                                className="form-group mar-btm-5 mar-top-10">
                                                                                <span
                                                                                    dangerouslySetInnerHTML={{__html: safe_get(field, "content", "")}}/>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                } else return null;
                                            })


                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                </div>
            )
        }

    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team: safe_get(store, "teams_helper.team", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", "")
    }
}
export default withRouter(connect(mapStateToProps)(ConfirmationFieldsRenderer));


