export default function reducer(state = {
    openPostAssignments: [],
    closedPostAssignments: [],
    openFormAssignments: [],
    pastFormAssignments: [],
    closedAssignments: [],
    openAssignments: [],
    latestActivity: [],
    assignmentsCount: 0,
    loading: true
}, action) {

    switch (action.type) {

        case "updateOpenPostAssignments": {
            return Object.assign({}, state, {
                openPostAssignments: action.payload
            });
        }

        case "updateClosedPostAssignments" : {
            return Object.assign({}, state, {
                closedPostAssignments: action.payload
            });
        }

        case "updateOpenFormAssignments": {
            return Object.assign({}, state, {
                openFormAssignments: action.payload
            });
        }

        case "updatePastFormAssignments": {
            return Object.assign({}, state, {
                pastFormAssignments: action.payload
            });
        }

        case "updateOpenAssignments": {
            return Object.assign({}, state, {
                openAssignments: action.payload
            });
        }

        case "updateClosedAssignments": {
            return Object.assign({}, state, {
                closedAssignments: action.payload
            });
        }

        case "updateAssignmentsCount": {
            return Object.assign({}, state, {
                assignmentsCount: action.payload
            });
        }

        case "updateLatestActivity": {
            return Object.assign({}, state, {
                latestActivity: action.payload
            });
        }

        case "updateLoadingDashboard": {
            return Object.assign({}, state, {
                loading: action.payload
            });
        }

        case "RESET_ASSIGNMENTS": {
            return Object.assign({}, state, {
                openPostAssignments: [],
                closedPostAssignments: [],
                openFormAssignments: [],
                pastFormAssignments: [],
                latestActivity: [],
                assignmentsCount: 0
            });
        }

        default: {
            return state
        }

    }

}
