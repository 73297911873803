import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Link} from 'react-router-dom';

import {Modal, Select} from 'antd';

import NotificationAlert from '../../../other/NotificationAlert.js';
import ButtonLoading from '../../Shared/ButtonLoading';

import {update_location, get_list_users} from '../../../api/zero-api.js'
import {safe_get, safeProfilePic} from '../../../other/Helper'

import * as incidentsActions from '../../../store/actions/IncidentsActions'

import '../../../assets/css/antd-custom.css'

class AddMembersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersList: [],
            existing_user_uuids: props.location.access_list.map(user => user.uuid),
            loadingUsers: true,
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        var self = this;
        get_list_users("?status=active&roles=admin,team_lead").then(success => {
            success.json().then(success => {
                let existingUsers = [...self.state.existing_user_uuids];

                let users = safe_get(success, "users", []);

                users = users.filter(function (user) {
                    return user.status === "active" && user.uuid && !existingUsers.includes(user.uuid)
                }).sort(function (a, b) {
                    let a_name = (safe_get(a, "first_name", "") + " " + safe_get(a, "last_name", "")).toLowerCase();
                    let b_name = (safe_get(b, "first_name", "") + " " + safe_get(b, "last_name", "")).toLowerCase();

                    return a_name > b_name ? 1 : -1;
                });

                self.setState({
                    usersList: users,
                    loadingUsers: false
                });
            });
        });
    }

    onUserChange = (value) => {
        let user_uuids = value.map(x => x.value);
        this.setState({selected_user_uuids: user_uuids, selected_users: value})
    }

    updateLocation = () => {
        var self = this;

        this.setState({updating: true});

        let {
            name, street_address, city,
            state, zip, industry_description,
            naics, number_of_employees, hours_worked,
            access_list, reset_used_desks,
            incidents_enabled, spaces_enabled,
        } = this.props.location

        let existing_user_uuids = [...self.state.existing_user_uuids];

        let body = JSON.stringify({
            name: name,
            street_address: street_address,
            city: city,
            state: state,
            zip: zip,
            industry_description: industry_description,
            naics: naics,
            number_of_employees: parseInt(number_of_employees),
            hours_worked: parseInt(hours_worked),
            access_list_user_uuids: existing_user_uuids.concat(this.state.selected_user_uuids),
            reset_used_desks,
            incidents_enabled,
            spaces_enabled,
        });

        update_location(this.props.location.location_uuid, body).then(function (success) {
            success.json().then(success => {
                NotificationAlert("success", "", "Managers updated.");
                self.props.callback();
                self.props.cancel();
            });
        }, function (error) {
            NotificationAlert("success", "", "Unable to add manager.");
            self.props.cancel();
        });
    }

    render() {
        return (
            (<Modal
                title={`Add manager for ${this.props.location.name}`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.updateLocation} disabled={this.state.updating}>
                            {
                                this.state.updating ? <ButtonLoading/> : "Add"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">Managers can edit the location details, assign teams or managers for this
                    location, and view all incidents at this location. Only Admins and Team Leads can be managers.</p>
                <Select
                    id="invite-emails"
                    mode="multiple"
                    value={this.state.selected_users}
                    placeholder="Type a name..."
                    onChange={this.onUserChange}
                    filterOption={(inputValue, option) => {
                        return option.name.toLowerCase().includes(inputValue.toLowerCase())
                    }}
                    style={{width: '100%', display: 'block', margin: '0 auto'}}
                    className="user-select-dropdown"
                    labelInValue={true}
                    notFoundContent={this.state.loadingUsers ? "Loading..." : "No users found. Please try again..."}
                    dropdownStyle={{zIndex: "10000"}}
                    optionLabelProp="user"
                    loading={this.state.loadingUsers}
                    virtual={false}
                >
                    {
                        this.state.usersList.map((user, index) => (
                            <Select.Option key={"user: " + user.uuid} value={user.uuid} user={
                                <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.first_name} </span>}
                                           name={`${user.first_name} ${user.last_name}`}>
                                {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                {user.first_name} {user.last_name} &bull; <span
                                style={{fontSize: '85%'}}>{user.email || user.title}</span>
                            </Select.Option>
                        ))
                    }
                </Select>
                <p className="zero-dark-grey" style={{fontSize: "11px", paddingTop: "2px", fontStyle: "italic"}}>To add
                    people not on ZERO, you'll need to <Link className="link-hover zero-light-blue"
                                                             to={"/" + this.props.org_uuid + "/home/directory?invite=true"}>invite
                        them first.</Link></p>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        user: safe_get(store, "user.user", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        incident_locations: safe_get(store, "incidents.locations", [])
    }
}

export default connect(mapStateToProps)(AddMembersModal);