// @ts-check

import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useFeedDataGridContext } from "./FeedDataGrid.context";


const EXPORTED_ROW_COUNT = 150;

export default function FeedDataGridExportButton() {
    const { gridRef, setRowCount } = useFeedDataGridContext();

    const exportGrid = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            
            setRowCount(EXPORTED_ROW_COUNT);
            gridApi.setGridOption("loading", true);

            const handleModelUpdated = () => {
                const loadingBlockCount = getLoadingBlockCount(gridApi);
                if (loadingBlockCount === 0) {
                    exportToExcel(gridApi);
                    setRowCount(null);
                    gridApi.setGridOption("loading", false);
                    gridApi.removeEventListener('modelUpdated', handleModelUpdated);
                }
            }

            setTimeout(() => {
                // Wait 250ms so grid can start loading data if it needs to
                // Then check if we have any blocks currently loading
                const initialLoadingBlockCount = getLoadingBlockCount(gridApi);
                if (initialLoadingBlockCount === 0) {
                    // If not, go ahead and export
                    exportToExcel(gridApi);
                    setRowCount(null);
                    gridApi.setGridOption("loading", false);
                } else {
                    // Otherwise, wait until data is finished loading then export
                    gridApi.addEventListener('modelUpdated', handleModelUpdated);
                }
            }, 250);
        }
    }

    return (
        <DownloadOutlined
            className="hover-cursor zero-blue"
            style={{fontSize: "20px"}}
            onClick={exportGrid}
        />
    )
}

function exportToExcel(gridApi) {
    gridApi.exportDataAsExcel({
        fileName: "ZERO Posts Export",
        author: "ZERO",
        sheetName: "ZERO Posts Export",
        // Excludes the first column (action dropdown)
        columnKeys: gridApi.getColumnDefs().slice(1).map((/** @type {AgGrid.ColDef} */ col) => col.colId),
        processCellCallback,
    });
}

function getLoadingBlockCount(gridApi) {
    return Object.values(gridApi.getCacheBlockState())
        .filter(block => block.pageStatus === 'loading')
        .length;
}

/**
 * @param {AgGrid.ProcessCellForExportParams} params
 * @returns {string}
 */
function processCellCallback({value, formatValue, column}) {
    if (!value) {
        return null;
    }

    const {cellClass} = column.getColDef();

    if (cellClass === 'riskFormatter') {
        switch (value) {
            case 1: return "Low";
            case 2: return "Medium";
            case 3: return "High";
            default: return "";
        }
    } else if (cellClass === "dateFormatterNoTime") {
        if (typeof value === "number") {
            const date = new Date(value * 1000);
            return date.toISOString();
        } else if (typeof value === "string") {
            try {
                if (value === "-") {
                    return "";
                }
                return dayjs(value, ["MMM DD, YYYY", 'MMM D, YYYY'])?.toISOString() ?? "";
            } catch (error) {
                console.log('Time value is: ', value);
                console.log(error);
                return "";
            }
        }
        return "";
    }

    return formatValue(value);
}