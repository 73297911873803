import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {login_as_user, update_auth_token} from '../api/zero-api';
import {safe_get} from '../other/Helper';


async function exchangeAdminTokenForAuthToken(adminToken) {
    if (!adminToken) {
        return null;
    }

    try {
        const body = JSON.stringify({
            token: adminToken
        });
        const response = await login_as_user(body);
        const data = await response.json();

        return safe_get(data, 'token', null);
    } catch (err) {
        return null;
    }
}


function LoginAsUser(props) {
    const urlParams = new URLSearchParams(props.location.search);
    const adminToken = urlParams.get('token');
    const [authToken, setAuthToken] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const authToken = await exchangeAdminTokenForAuthToken(adminToken);
            setAuthToken(authToken);
        }

        fetchData()
    }, []);

    if (authToken === null) {
        return <div>Invalid Token</div>;
    }

    update_auth_token(authToken);
    location.replace('/');

    return <div>Valid token, redirecting...</div>;
}

export default withRouter(LoginAsUser);