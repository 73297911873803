import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

/**
 * @returns {string}
 */
export default function useOrganizationId() {
    const reduxOrgId = useSelector(state => state?.org_helper?.organization?.organization_uuid);
    const [orgId, setOrgId] = useState(reduxOrgId);

    useEffect(() => {
        setOrgId(reduxOrgId);
    }, [reduxOrgId, setOrgId]);

    return orgId;
}