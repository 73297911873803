import InitialDataCache from "offline/InitialDataCache";
import React, {useCallback, useEffect, useState} from "react";
import {unstable_batchedUpdates} from "react-dom";
import useDbChangeListener from "../useDbChangeListener";

export default function useInitialDataCacheLoader(isAppLoading, orgId, userId) {
    /** @type {[InitialDataCache, React.Dispatch<any>]} */
    const [cache, setCache] = useState(null);

    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (orgId && userId && !isAppLoading) {
                const cache = new InitialDataCache(orgId, userId);
                const initialData = await cache.get();

                unstable_batchedUpdates(() => {
                    setCache(cache);
                    setInitialData(initialData);
                });
            }
        }

        fetchData();
    }, [orgId, userId, isAppLoading, setCache, setInitialData]);

    const listenerCallback = useCallback(async () => {
        const initialData = await cache.get();
        setInitialData(initialData);
    }, [cache, setInitialData]);

    useDbChangeListener(cache?.db, 250, listenerCallback);

    return {cache, initialData};
}