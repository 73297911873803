import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Cascader} from 'antd';
import {ZoomInOutlined, ZoomOutOutlined} from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import {TransformWrapper, TransformComponent} from "@timmytango/react-zoom-pan-pinch";

import {mainContentContainerClass} from 'other/Helper';
import {useDeskPool} from 'hooks/spaces';
import {CheckInUserType} from 'other/Constants';

export default function ViewMapPage() {
    const user = useSelector(state => state.user.user);
    const {loading, error, ok, locations} = useDeskPool(CheckInUserType.SELF, user);
    const [options, setOptions] = useState([]);
    const [floorPlan, setFloorPlan] = useState(null);
    const transformRef = useRef(null);
    const imageEl = useRef(null);

    useEffect(() => {
        if (ok && locations.length > 0) {
            const newOptions = [...locations];
            newOptions.map(location => {
                if (location.spaces.length == 0) {
                    location.spaces.push({
                        cascadeName: 'No spaces enabled',
                        disabled: true,
                        uuid: null
                    })
                }
            });

            setOptions(newOptions);
        }
    }, [locations]);

    const onCascadeChange = ([locationId, spaceId]) => {
        let newFloorPlan = '';

        const location = options.find(l => l.uuid === locationId);
        if (location) {
            const space = location.spaces.find(s => s.uuid === spaceId);
            if (space && space.floor_plan.length > 0) {
                newFloorPlan = space.floor_plan;
            }
        }

        if (imageEl.current) {
            imageEl.current.onload = () => {
                if (transformRef.current) {
                    transformRef.current.zoomToElement('floor-plan-image');
                }
            }
            imageEl.current.src = newFloorPlan;
        }

        setFloorPlan(newFloorPlan);
    }

    return (
        (<div className={mainContentContainerClass()}>
            <div id="page-head" className="no-padding-mobile">
                <div className="row">
                    <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                        <div id="page-title" style={{padding: '0px'}}>
                            <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                <div className="nav-header-panel no-padding-print">
                                    <div className="team-name-header">
                                        <h3 className={"section-titles admin-page-header"}
                                            style={{display: "inline-block", margin: "0px"}}>
                                            Office Maps
                                        </h3>
                                    </div>
                                    <p className="header"
                                       style={{color: 'var(--zero-dark-grey', marginBottom: "0px", paddingLeft: "2px"}}>
                                        Select an office space below to see the map for that space.
                                    </p>
                                    {ok &&
                                        <Cascader
                                            fieldNames={{
                                                label: 'cascadeName',
                                                value: 'uuid',
                                                children: 'spaces'
                                            }}
                                            options={options}
                                            onChange={onCascadeChange}
                                            placeholder="Select a space"
                                            style={{width: '380px', maxWidth: '100%', marginTop: '1rem'}}
                                            popupClassName="zero-blue"
                                            allowClear={false}
                                            className="zero-dark-grey"
                                        />
                                    }
                                    {!ok &&
                                        <Skeleton width={130}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="page-content" className="no-padding-mobile">
                <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                    <div className="panel pad-all" style={{border: "1px solid #e2e2e2"}}>
                        <TransformWrapper
                            initialScale={0.5}
                            minScale={0.01}
                            centerZoomedOut={true}
                            centerOnInit={true}
                            ref={transformRef}
                        >
                            {({zoomIn, zoomOut, zoomToElement, ...rest}) => (
                                <>
                                    <div style={{marginBottom: '1rem', display: !floorPlan ? 'none' : 'block'}}>
                                        <button className="btn btn-plain" style={{marginRight: '1rem'}}
                                                onClick={() => zoomIn()}><ZoomInOutlined/></button>
                                        <button className="btn btn-plain" style={{marginRight: '1rem'}}
                                                onClick={() => zoomOut()}><ZoomOutOutlined/></button>
                                        <button className="btn btn-plain zero-dark-grey"
                                                onClick={() => zoomToElement('floor-plan-image')}>Reset
                                        </button>
                                    </div>
                                    <TransformComponent
                                        wrapperStyle={{maxWidth: '100%', maxHeight: 'calc(100vh - 300px)'}}>
                                        <img id="floor-plan-image" alt="floor plan" ref={imageEl}
                                             style={{display: !floorPlan ? 'none' : 'block'}}/>
                                    </TransformComponent>
                                </>
                            )}
                        </TransformWrapper>
                        {floorPlan === '' &&
                            <span className="zero-blue">This office space does not have a map.</span>
                        }
                        {floorPlan === null &&
                            <span className="zero-blue">Please select an office space above.</span>
                        }
                    </div>
                </div>
            </div>
        </div>)
    );
}