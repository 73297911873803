import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {getArticleActivities, notifyError, getArticleVersion, revertArticleVersion} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet.js'
import {dateFormatterWithTime, mainContentContainerClass, safeProfilePic} from '../../other/Helper'

import {Modal, Popover} from 'antd';
import {HistoryOutlined, EditOutlined, PlusCircleOutlined, EyeOutlined} from '@ant-design/icons';
import '../../assets/css/Library.css';
import ConfirmModal from "../Shared/ConfirmModal";
import NotificationAlert from "../../other/NotificationAlert";
import linkifyHtml from "linkify-html";
import LoadingIndicator from '../Shared/LoadingIndicator.js';

class ArticleHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {},
            activities: [],
            loading: true,
            team: {}
        };

    }

    componentDidMount() {
        this.getActivities();
    }

    getActivities() {
        var self = this;
        getArticleActivities(this.props.match.params.article_uuid).then(function (success) {
            success.json().then(success => {
                const allActivities = safe_get(success, "activities", []);

                const activities = allActivities.filter(activity => activity.action !== 'reverted');
                activities.sort(function (a, b) {
                    return a.action_at > b.action_at;
                });

                let currentVersionId = null;

                if (allActivities.length) {
                    let lastActivity = allActivities.slice(-1)[0];
                    currentVersionId = lastActivity.article_version_uuid;
                }

                self.setState({
                    currentVersionId: currentVersionId,
                    activities: activities,
                    loading: false
                });
            });
        }, function (error) {
            notifyError(error);
        });
    }

    handleRestoreClick = activity => {
        this.setState({showRestoreModal: true, versionToRestore: activity})
    }

    handlePreviewClick = activity => {
        console.log('preview clicked')
        this.getArticleVersion(activity);
    }

    closePreview = () => {
        console.log('closing preview');
        this.setState({showPreviewModal: false});
    }

    cancelModal = () => {
        this.setState({showRestoreModal: false, versionToRestore: null});
    }

    restoreArticle = () => {
        if (this.state.versionToRestore) {
            const articleUUID = this.state.versionToRestore.article_uuid;
            const versionUUID = this.state.versionToRestore.article_version_uuid;

            revertArticleVersion(articleUUID, versionUUID)
                .then(success => {
                    NotificationAlert("success", "", "Article was restored!");
                    this.setState({showRestoreModal: false, versionToRestore: null, loading: true});
                    this.getActivities();
                })
                .catch(error => {
                    NotificationAlert("error", "", "Could not restore version.");
                    this.setState({showRestoreModal: false, versionToRestore: null});
                })
        }
    }

    getArticleVersion(activity) {
        if (this.state.previewVersionUUID === activity.article_version_uuid) {
            this.setState({showPreviewModal: true});
        } else {
            getArticleVersion(activity.article_uuid, activity.article_version_uuid)
                .then(success => {
                    success.json().then(({article}) => {
                        this.setState({
                            showPreviewModal: true,
                            previewArticle: article,
                            previewVersionUUID: activity.article_version_uuid
                        });
                    })
                })
                .catch(error => {
                    NotificationAlert("error", "", "Could not load article preview.");
                })
        }
    }

    articleInnerHTML() {
        var options = {
            target: {
                url: "_blank"
            }
        }
        return {__html: linkifyHtml(this.state.previewArticle.body, options)};
    }

    articleBodyContent() {
        if (this.state.previewArticle) {
            return (
                <div dangerouslySetInnerHTML={this.articleInnerHTML()}></div>
            )
        }

        return <div>Could not load article.</div>
    }

    render() {
        return (
            (<div className={mainContentContainerClass()}>
                {
                    this.state.showRestoreModal && (
                        <ConfirmModal
                            show={this.state.showRestoreModal}
                            cancel={this.cancelModal}
                            confirm={this.restoreArticle}
                            title="Confirmation"
                            body="Are you sure you want to restore this version of the article?"
                            confirmButtonName="Restore this version"
                        />
                    )
                }
                {
                    this.state.showPreviewModal && (
                        <Modal
                            title="Preview Article"
                            open={true}
                            footer={null}
                            width={788}
                            onCancel={this.closePreview}
                        >
                            {this.articleBodyContent()}
                        </Modal>
                    )
                }
                <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>

                    <div className="panel thin-border" style={{maxWidth: '850px', margin: '0 auto'}}>
                        <div className="panel-body">
                            <HistoryOutlined className="zero-blue"/>
                            <p className="zero-blue" style={{
                                display: 'inline-block',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginLeft: '3px'
                            }}>Edit History</p>
                            {
                                this.state.loading &&
                                <LoadingIndicator/>
                            }
                            {
                                !this.state.loading &&
                                this.state.activities.map((activity, index) => (
                                    <div className="comments media-block"
                                         style={{paddingBottom: '5px', paddingTop: '5px', display: 'flex'}} key={index}>
                                        <div className="media-left">
                                            <Link className="media-left"
                                                  to={"/" + this.props.org_uuid + "/users/" + activity.action_by.uuid}>
                                                {safeProfilePic(safe_get(activity, "action_by", {}), "img-circle img-sm bulletin", "bulletin")}
                                            </Link>
                                        </div>
                                        <div className="media-body bulletin2"
                                             style={{paddingBottom: '0px', paddingLeft: "8px", flexGrow: 2}}>
                                            <h3 className="author bulletin comments"
                                                style={{width: "100%", marginBottom: "5px"}}>
                                                {
                                                    activity.action === "created" &&
                                                    <PlusCircleOutlined className="mar-rgt-5"/>
                                                }
                                                {
                                                    activity.action === "edited" &&
                                                    <EditOutlined className="mar-rgt-5"/>
                                                }
                                                <Link
                                                    to={"/" + this.props.org_uuid + "/users/" + activity.action_by.uuid}
                                                    className="link-hover zero-blue">
                                                    {activity.action_by.first_name} {activity.action_by.last_name}
                                                </Link>
                                                {
                                                    activity.action === "created" &&
                                                    <span
                                                        className="post-status created">&nbsp;created this article.</span>
                                                }
                                                {
                                                    activity.action === "edited" &&
                                                    <span
                                                        className="post-status created">&nbsp;edited this article.</span>
                                                }
                                            </h3>
                                            <br/>
                                            <h2 className="author bulletin date">
                                                {dateFormatterWithTime(activity.action_at)}
                                            </h2>
                                        </div>
                                        {
                                            this.state.currentVersionId === activity.article_version_uuid && (
                                                <div className="label label-info" style={{margin: 'auto 0'}}>Current
                                                    Version</div>
                                            )
                                        }
                                        {
                                            this.state.currentVersionId !== activity.article_version_uuid && (
                                                <div style={{margin: 'auto 0', display: 'flex'}}>
                                                    <button onClick={() => this.handleRestoreClick(activity)}
                                                            className="btn-link zero-blue" style={{padding: 0}}>
                                                        Restore this version
                                                    </button>
                                                    <Popover content="Preview this version">
                                                        <button onClick={() => this.handlePreviewClick(activity)}
                                                                className="btn-link zero-blue" style={{paddingRight: 0}}>
                                                            <EyeOutlined/>
                                                        </button>
                                                    </Popover>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }

                        </div>
                    </div>

                </div>
            </div>)
        );
    }
}

// export default ArticleHistory;

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", {})
    }
}

export default connect(mapStateToProps)(ArticleHistory);

