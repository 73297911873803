import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons"
import { useMemo } from "react";

export function PageSelectDropdown({pageCount, currentPage, onPageChange}) {
    if (pageCount < 2) {
        return null;
    }

    const items = useMemo(() => {
        const items = [];
        for (let i = 0; i < pageCount; i++) {
            items.push({
                key: i,
                label: `Page ${i+1}`,
            });
        }
        return items;
    }, [pageCount]);

    const onMenuClick = ({key}) => {
        const page = parseInt(key, 10);
        onPageChange(page);
    }

    return (
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Dropdown
                menu={{
                    items,
                    onClick: onMenuClick,
                }}
                trigger={['click']}
            >
                <span className="zero-dark-grey">
                    <Space>
                        <>Page {currentPage} of {pageCount}</>
                        <DownOutlined />
                    </Space>
                </span>
            </Dropdown>
        </div>
    )
}