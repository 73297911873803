import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import {
    update_auth_token,
    update_team_cookie,
    create_user,
    register_user,
} from "../../../api/zero-api.js";
import {validatePhoneNumber, safe_get} from "../../../other/Helper.js";

import PasswordRequirementsPopup from "../../../components/Shared/PasswordRequirementsPopup.js";
import ButtonLoading from "../../../components/Shared/ButtonLoading";

class UserSignupInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            password: "",
            passwordCheck: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordMeetsRequirements = this.passwordMeetsRequirements.bind(this);
    }

    componentDidUpdate(nextProps) {
        if (this.props.email !== nextProps.email) {
            this.setState({email: nextProps.email});
        }
    }

    componentDidMount() {
        this.setState({email: this.props.email});
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value =
            e.target.name === "checkbox" ? e.target.checked : e.target.value;
        this.setState({
            [name]: value,
        });
    };

    passwordMeetsRequirements(value, password) {
        this.setState({passwordCheck: value, password: password});
    }

    checkErrors() {
        let {
            password,
            repeatPassword,
            phone,
            positionTitle,
            firstName,
            lastName,
            checkbox,
        } = this.state;
        var newState = {};

        // let validPassword =
        //   password && repeatPassword && password === repeatPassword;

        // newState["pwError"] = validPassword ? null : "Password does not match";

        // newState["phoneError"] = phone.length === 0 ? "Phone is required" : validatePhoneNumber(phone) ? null : "Invalid Phone Number";

        // newState["positionTitleError"] = positionTitle ? null : "Title is required";

        // newState["firstNameError"] = firstName ? null : "First name is required";

        // newState["lastNameError"] = lastName ? null : "Last name is required";

        newState["checkBoxError"] = checkbox ? null : "To create an account, you must accept the terms and policies.";

        this.setState(newState);

        var allPassed = true;
        let keys = Object.keys(newState);
        for (var index in keys) {
            let error = keys[index];
            if (newState[error] != null) {
                allPassed = false;
                break;
            }
        }
        return allPassed;
    }

    handleSubmit() {
        this.setState({
            api_loading: true,
            firstNameError: null,
            lastNameError: null,
            emailError: null,
            pwError: null,
            positionTitleError: null,
            errorMsg: null
        });

        if (this.checkErrors()) {
            if (this.props.isInvite) {
                this.inviteByEmail();
            } else if (this.props.isRegister) {
                this.inviteByLink();
            }
        } else {
            this.setState({api_loading: false});
            return false;
        }
    }

    createUserBody() {
        var phone_value = this.state.phone;
        phone_value = phone_value.replace(/\s+/g, "");
        phone_value = phone_value.replace(/-/g, "");
        phone_value = phone_value.replace(/\(/g, "");
        phone_value = phone_value.replace(/\)/g, "");

        let body = JSON.stringify({
            email: this.state.email,
            confirmation_code: this.props.inviteCode,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            phone_number: phone_value,
            password: this.state.password,
            title: this.state.positionTitle,
            organization_name: "",
            avatar: "",
        });

        return body;
    }

    inviteByEmail() {
        var self = this;

        let body = this.createUserBody();
        create_user(body).then(function (success) {
                success.json().then((success) => {
                    self.handleCallback(true, success);
                });
            }, function (error) {
                self.handleCallback(false, error);
            }
        );
    }

    inviteByLink() {
        var self = this;

        let body = this.createUserBody();
        register_user(body).then(
            function (success) {
                success.json().then((success) => {
                    self.handleCallback(true, success);
                });
            },
            function (error) {
                self.handleCallback(false, error);
            }
        );
    }

    handleCallback(success, content) {
        var self = this;
        this.setState({api_loading: false});

        if (success) {
            self.props.history.push("/");
        } else {
            let error = content;
            if (error.status === 422) {
                error.json().then((error) => {
                    let newState = {};
                    newState["api_loading"] = false;

                    let error_messages = safe_get(error, "error_messages", []);

                    error_messages.forEach(error_msg => {
                        switch (error_msg.field) {
                            case "email":
                                newState["emailError"] = error_msg.message
                                break;
                            case "first_name":
                                newState["firstNameError"] = error_msg.message
                                break;
                            case "last_name":
                                newState["lastNameError"] = error_msg.message
                                break;
                            case "password":
                                newState["pwError"] = error_msg.message
                                break;
                            case "title":
                                newState["positionTitleError"] = error_msg.message
                                break;
                            case "phone_number":
                                newState["phoneError"] = error_msg.message;
                                break;
                            default:
                                newState["errorMsg"] = error_msg.message
                                break;
                        }
                    });
                    self.setState(newState);
                });
            } else {
                self.setState({
                    errorMsg: "An unexpected error occured. Error: " + error.status,
                    api_loading: false,
                });
            }
        }
    }

    render() {
        var mobileScreen = window.innerWidth <= 740;
        return (
            <form className="form-horizontal" data-toggle="validator">
                <div className="panel-body">
                    <div className="tab-content">
                        <div id="text2" className="text-center">
                            <div className="col-lg-10 col-lg-offset-1">
                                <h4 className="zero-blue" style={{marginTop: "0px"}}>
                                    Account Details
                                </h4>
                                <p className="text-muted">
                                    Please fill out your information to create your account.
                                </p>
                                <br/>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-lg-12 col-sm-12 signup-input">
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.onInputChange}
                                    className={"form-control signup-field custom-placeholder "}
                                    disabled={this.props.isInvite}
                                    style={{maxWidth: "250px", margin: "0 auto"}}
                                />
                                {this.state.emailError && (
                                    <small className={"error " + (mobileScreen ? "" : "")}>
                                        {this.state.emailError}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-lg-12 col-sm-12 signup-input">
                                <input
                                    required
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    onChange={this.onInputChange}
                                    className="form-control signup-field custom-placeholder"
                                    style={{maxWidth: "250px", margin: "0 auto"}}
                                />
                                {this.state.firstNameError && (
                                    <small className={"error " + (mobileScreen ? "" : "")}>
                                        {this.state.firstNameError}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-lg-12 col-sm-12 signup-input">
                                <input
                                    required
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    onChange={this.onInputChange}
                                    className={"form-control signup-field custom-placeholder "}
                                    style={{maxWidth: "250px", margin: "0 auto"}}
                                />
                                {this.state.lastNameError && (
                                    <small className={"error " + (mobileScreen ? "" : "")}>
                                        {this.state.lastNameError}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-lg-12 col-sm-12 signup-input">
                                <input
                                    required
                                    type="text"
                                    name="positionTitle"
                                    placeholder="Title"
                                    onChange={this.onInputChange}
                                    className="form-control signup-field custom-placeholder"
                                    style={{maxWidth: "250px", margin: "0 auto"}}
                                />
                                {this.state.positionTitleError && (
                                    <small className={"error " + (mobileScreen ? "" : "")}>
                                        {this.state.positionTitleError}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-lg-12 col-sm-12 signup-input">
                                <input
                                    required
                                    type="text"
                                    name="phone"
                                    placeholder="Phone (optional)"
                                    onChange={this.onInputChange}
                                    className={"form-control signup-field custom-placeholder"}
                                    style={{maxWidth: "250px", margin: "0 auto"}}
                                />
                                {this.state.phoneError && (
                                    <small className={"error " + (mobileScreen ? "" : "")}>
                                        {this.state.phoneError}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-lg-12 col-sm-12 signup-input">
                                <PasswordRequirementsPopup
                                    passwordMeetsRequirementsCallback={
                                        this.passwordMeetsRequirements
                                    }
                                />
                                {this.state.pwError && (
                                    <small className={"error " + (mobileScreen ? "" : "")}>
                                        {this.state.pwError}
                                    </small>
                                )}
                            </div>
                        </div>
                        {/* <div className="form-group">
              <div className="col-lg-12 col-sm-12 signup-input">
                <input
                  required
                  type="password"
                  name="repeatPassword"
                  placeholder="Confirm Password"
                  onChange={this.onInputChange}
                  className={"form-control signup-field custom-placeholder "}
                  style={{ maxWidth: "250px", margin: "0 auto" }}
                />
                {this.state.pwError && (
                  <small className={"error " + (mobileScreen ? "" : "")}>
                    {this.state.pwError}
                  </small>
                )}
              </div>
            </div> */}
                        {this.state.errorMsg && (
                            <small className={"error"}>{this.state.errorMsg}</small>
                        )}
                        <div className="checkbox pad-btm text-center">
                            <div className="col-lg-12">
                                <input
                                    id="demo-form-checkbox"
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="checkbox"
                                    onChange={this.onInputChange}
                                    checked={this.state.checkbox}
                                />
                                <label htmlFor="demo-form-checkbox">
                                    I accept the{" "}
                                    <a
                                        href="https://teamzero.com/user-terms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn-link sign-up text-bold"
                                    >
                                        User Terms of Service
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="https://teamzero.com/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn-link sign-up text-bold"
                                    >
                                        Privacy Policy
                                    </a>
                                </label>
                                <br/>
                                {this.state.checkBoxError && (
                                    <small className={"error "}>{this.state.checkBoxError}</small>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="box"
                    style={{marginBottom: "20px", marginTop: "18px"}}
                >
                    <button
                        type="button"
                        className="finish btn btn-primary"
                        style={{display: "block", margin: "0 auto", width: "250px"}}
                        onClick={this.handleSubmit}
                    >
                        {!this.state.api_loading || this.props.codeError ? (
                            "Finish"
                        ) : (
                            <ButtonLoading/>
                        )}
                    </button>
                    {(this.state.codeError || this.props.codeError) && (
                        <small className={"error "}>
                            {this.state.codeError || this.props.codeError}
                        </small>
                    )}
                </div>
            </form>
        );
    }
}

const mapStateToProps = (store) => {
    return {};
};
export default withRouter(connect(mapStateToProps)(UserSignupInfo));
