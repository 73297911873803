// @ts-check
import { FormType } from "other/Constants";
import ZERO_logo from "../../assets/css/img/ZERO-(blue)-small.png";
import LogicLessSyncSpinner from "components/Shared/LogicLessSyncSpinner";

/**
 * 
 * @param {{
 * formType: number;
 * submissionState: PublicFormSubmissionState;
 * }} props
 * @returns {JSX.Element}
 */
export default function PublicFormConfirmation({formType, submissionState}) {
    const isLms = formType === FormType.LMS;
    const isSubmitting = submissionState !== 'submitted';

    if (submissionState === 'none') {
        return null;
    }

    return (
        <div className="pad-20">
            <div className="panel-heading article text-center" style={{ height: "auto" }}>
                <img style={{ height: "100px" }} src={ZERO_logo} alt="ZERO Logo" />
            </div>
            <div className="panel-heading article" style={{ height: "auto" }}>
                { isSubmitting &&
                    <>
                        <h3 className="directory teams header">Submitting {isLms ? "Course" : "Form"}</h3>

                        <p className="zero-blue text-center" style={{ maxWidth: "350px", margin: "0 auto" }}>
                            Please stay on this page until the sync is complete.
                        </p>
                        <LogicLessSyncSpinner className="text-center mar-top-10" />
                    </>
                }
                { !isSubmitting &&
                    <>
                        <h3 className="directory teams header">{isLms ? "Course Completed" : "Form Submitted"}</h3>

                        <p className="zero-blue text-center" style={{ maxWidth: "350px", margin: "0 auto" }}>
                            {isLms
                                ? "Your course has been completed successfully."
                                : "Your form has been submitted successfully."}
                            <br />
                            <br />
                            Have a wonderful rest of the day.
                        </p>
                    </>
                }
            </div>
        </div>
    )
}