export function update_current_org(org) {
    return {
        type: "update_current_org",
        payload: org
    }
}

export function update_organizations(orgs) {
    return {
        type: "update_organizations",
        payload: orgs
    }
}

export function reset() {
    return {
        type: "RESET",
        payload: {}
    }
}
