import React, {Component} from 'react';
import {Redirect} from 'react-router'
import {makeArticle, updateArticle, getCategories, notifyError} from '../../api/zero-api.js'

import {Select} from 'antd';
import {connect} from 'react-redux'

import AttachmentBox from './AttachmentBox.js'
import InfoIconComponent from '../InfoIconComponent.js';
import ButtonLoading from '../Shared/ButtonLoading.js';

import {safe_get, fieldIsNotEmpty, mainContentContainerClass} from '../../other/Helper.js';

import '../../assets/css/Library.css';

class UploadAttachment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [],
            tags: [],
            selectedTags: [],
            article_uuid: "",
            redirectToLibrary: false,
            team: {},
            attachments: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.update_attachments = this.update_attachments.bind(this);
        this.handleTagSelection = this.handleTagSelection.bind(this);
        this.discard = this.discard.bind(this);
    }

    componentDidMount() {
        var self = this;

        var user = this.props.user
        this.setState({
            user: user,
        });

        makeArticle().then(function (success) {
            success.json().then(success => {
                let tags = self.props.topic !== "" ? [self.props.topic] : []
                self.setState({
                    article_uuid: safe_get(success, "article_uuid", ""),
                    tags: tags
                });
            });
        }, function (error) {
            notifyError(error)
        });

        getCategories().then(function (success) {
            success.json().then(success => {
                var tags = safe_get(success, "tags", [])
                tags.sort(function (a, b) {
                    return a > b
                });

                var formattedTags = []
                for (var i in tags) {
                    var tag = tags[i]
                    formattedTags.push(<Select.Option key={tag}>{tag}</Select.Option>);
                }
                self.setState({allTags: formattedTags});
            });
        }, function (error) {
            console.log(error);
        });
    }


    update_attachments(attachments) {
        this.setState({attachments: attachments});
    }

    handleTagSelection(value) {
        for (var i in value) {
            var tag = value[i]
            if (tag.length > 30) {
                value.splice(i, 1)
            }
        }
        this.setState({tags: value});
    }

    handleSubmit() {
        var self = this;

        // var attachments = this.refs.AttachmentBox.getAPIReadyAttachments()
        var attachments = this.state.attachments;
        var article_title = document.getElementById('article-title').value

        if (fieldIsNotEmpty(article_title)) {
            this.setState({saving: true});
            var body = JSON.stringify({
                title: article_title,
                body: "",
                tags: this.state.tags,
                attachments: attachments
            });

            updateArticle(this.state.article_uuid, body).then(function (success) {
                success.json().then(success => {
                    self.setState({saving: false, redirectToLibrary: true});
                });
            }, function (error) {
                self.setState({saving: false});
                notifyError(error);
            });
        } else {
            this.setState({titleError: "Please add a title for your article."});
        }


    }

    discard() {
        this.setState({redirectToLibrary: true});
    }


    render() {

        if (this.state.redirectToLibrary) {
            return <Redirect
                to={"/" + this.props.organization.organization_uuid + "/home/team/" + this.props.current_team.uuid + "/private_library"}/>
        }

        return (
            <div className={mainContentContainerClass()}>
                <div id="page-content" className="no-padding-mobile" style={{paddingTop: "20px"}}>
                    <div className="row">
                        <div className="col-lg-12 col-lg-offset-0 col-xs-12 col-xs-offset-0">
                            <div className="" style={{maxWidth: '850px', margin: '0 auto'}}>
                                <div className="panel" style={{marginBottom: "10px"}}>
                                    <div className="form-group" style={{marginBottom: "0px"}}>
                                        <input type="text" placeholder="Article Title" id="article-title"
                                               className="form-control input-lg custom-placeholder thin-border"
                                               maxLength="100"/>
                                        {
                                            this.state.titleError &&
                                            <small className="error"
                                                   style={{marginLeft: '5px'}}>{this.state.titleError}</small>
                                        }
                                    </div>
                                </div>


                                <div className="panel pad-btm thin-border ">
                                    <div className="panel-heading">
                                        <h3 className="titles">Topics</h3>
                                        <InfoIconComponent
                                            position={"right"}
                                            width={"160px"}
                                            text={"You can select up to two topics. If you'd like to create a new topic, add it below and hit enter."}
                                        />
                                    </div>
                                    <div className="panel-body pad-btm">
                                        <Select
                                            mode="tags"
                                            style={{width: '100%'}}
                                            placeholder="Select topics"
                                            onChange={this.handleTagSelection}
                                            value={this.state.tags}
                                            className="library-tags">
                                            {this.state.allTags}
                                        </Select>
                                    </div>
                                </div>


                                <AttachmentBox
                                    ref="AttachmentBox"
                                    existing_files={[]}
                                    user_uuid={this.state.user.uuid}
                                    article_uuid={this.state.article_uuid}
                                    update_attachments={this.update_attachments}
                                />

                                <div className="panel clear">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <button className="btn btn-primary btn-block discard-article"
                                                    onClick={this.discard}>Discard
                                            </button>
                                        </div>
                                        <div className="col-xs-6">
                                            <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>
                                                {
                                                    this.state.saving ? <ButtonLoading/> : "Post this article"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        topic: safe_get(store, "topic.topic", ""),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(UploadAttachment);

