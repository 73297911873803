import { useState } from "react";
import { dateFormatterNoTime, shouldFormFieldDisplay } from "./Helper";
import { FORM_ELEMENTS } from "./Constants";
import dayjs from "dayjs";
import _ from "lodash";

export const NO_EMBEDDED_FORM = Symbol();

/**
 * Separates a list of fields into pages based on page break elements.
 * @param {any[]} fields 
 * @param {{
 *  ignorePageBreaks?: boolean
 * }} [options]
 * @returns {any[][]}
 */
export function generateFormPages(fields, options = {}) {
    if (!Array.isArray(fields) || fields.length === 0) {
        return [];
    }

    const {ignorePageBreaks} = options;

    const pages = [];
    let page = [];
    for (const field of fields) {
        if (field.element === "PageBreak") {
            if (!ignorePageBreaks) {
                pages.push(page);
                page = [];
            }
        } else {
            page.push(field);
        }
    }

    pages.push(page);

    return pages;
}

/**
 * 
 * @param {any[][]} answerQueue 
 * @returns {any[]}
 */
export function combineSubmissionAnswerUpdates(answerQueue) {
    const combinedAnswersMap = {};
    for (const answers of answerQueue) {
        for (const answer of answers) {
            combinedAnswersMap[answer.id] = answer;
        }
    }
    return Object.values(combinedAnswersMap);
}

export class SubmissionAutoSaveManager {
    constructor({timeoutMs, saveFn, onSaveComplete} = {}) {
        this.isSaving = false;
        this.timer = null;
        this.latestAnswers = null;
        this.pendingAnswers = null;
        this.timeoutMs = timeoutMs ?? 250;
        this.saveFn = saveFn ?? (async () => {});
        this.onSaveComplete = onSaveComplete ?? (async () => {});
    }

    setAnswers(answers) {
        if (!this.isSaving) {
            this.latestAnswers = answers;
            this.timer = setTimeout(() => {
                this.save();
            }, this.timeoutMs);
        } else {
            this.pendingAnswers = answers;
        }
    }

    async save() {
        this.isSaving = true;
        clearTimeout(this.timer);
        this.timer = null;

        const results = await this.saveFn(this.latestAnswers);
        await this.onSaveComplete(results);

        if (this.pendingAnswers) {
            this.latestAnswers = this.pendingAnswers;
            this.pendingAnswers = null;
            this.timer = setTimeout(() => {
                this.save();
            }, this.timeoutMs);
        }

        this.isSaving = false;
    }
}

/**
 * 
 * @param {{
 *  timeoutMs: number,
 *  saveFn: (answers: any[]) => Promise<any[]>,
 *  initialAnswers?: any
 * }} props 
 * @returns {[any, SubmissionAutoSaveManager]}
 */
export function useSubmissionAutoSaveManager({timeoutMs, saveFn, initialAnswers}) {
    const [answers, setAnswers] = useState(initialAnswers);
    const [autosaveManager] = useState(() => {
        return new SubmissionAutoSaveManager({
            timeoutMs,
            saveFn,
            onSaveComplete: setAnswers
        });
    });

    return [answers, autosaveManager];
}

/**
 * Checks if a submission has any blank required fields. Returns an array of the form
 * [hasBlankRequiredFields, newFormFields] where newFormFields is the original formFields
 * array with a new property "required_error" added to each field that is required and blank.
 * @param {any[]} formFields 
 * @param {any[]} answersData 
 * @returns {[boolean, any[]]} [hasBlankRequiredFields, newFormFields]
 */
export function submissionHasBlankRequiredFields(formFields, answersData) {
    let requiredFieldsAnswered = true;
    let newFormFields = [];
    
    for (let i in formFields) {
        let field = formFields[i]
    
        let shouldShowSection = true;
        if (field.sectionId) {
            let section = formFields.find(obj => {
                return obj.id === field.sectionId
            });
            shouldShowSection = shouldFormFieldDisplay(section, answersData)
        }
    
        if (field.required && shouldFormFieldDisplay(field, answersData) && shouldShowSection) {
            let answer = answersData.find(function (obj) {
                return obj.id === field.id
            });
    
            if (!answer) {
                requiredFieldsAnswered = false
                field["required_error"] = true
            } else if (field.element === 'Attachments' && answer.attachments.length === 0) {
                requiredFieldsAnswered = false;
                field["required_error"] = true;
            } else if (field.element !== 'Attachments' && (answer.value === undefined || answer.value.length === 0)) {
                requiredFieldsAnswered = false
                field["required_error"] = true
            } else {
                field["required_error"] = false;
            }
        }
    
        newFormFields.push(field);
    }
    return [!requiredFieldsAnswered, newFormFields];
}


// Analytics

export function fieldHasResponses(field) {
    return field.data.data.datasets[0].data.reduce((acc, datum) => acc + datum) > 0
}

// Form element mapping

export function getSubmissionAnswer(formField, submissionField) {
    if (formField.element === 'UserDropdown') {
        return submissionField.value.map(user => `${user.first_name} ${user.last_name}`.trim()).join(', ');
    }

    if (formField.element === 'DatePicker') {
        if (submissionField.value) {
            return dateFormatterNoTime(dayjs(submissionField.value).unix());
        }
        return "";
    }

    if (formField.element === "TimePicker") {
        if (submissionField.value) {
            return dayjs(submissionField.value, "HH:ss").format("h:ss a");
        }
        return "";
    }

    if (FORM_ELEMENTS.WITH_FREE_DATA.includes(formField.element)) {
        return submissionField.value;
    }

    if (FORM_ELEMENTS.WITH_OPTIONS.includes(formField.element)) {
        const values = submissionField.value.map(sf => {
            const formOption = formField.options.find(o => o.key === sf);
            return formOption?.text ?? null;
        })
        return values.join(', ');
    }

    if (formField.element === 'Attachments') {
        return submissionField.attachments.length;
    }
}

export function reorderFormFieldsWithSection(formFields) {
    const [sectionFields, mainFields] = _.partition(formFields, 'sectionId');
    const groupedSectionFields = _.groupBy(sectionFields, 'sectionId');
    const reorderedFields = [];

    for (const field of mainFields) {
        reorderedFields.push(field);
        if (field.element === "Section") {
            reorderedFields.push(...(groupedSectionFields[field.id] ?? []));
        }
    }

    return reorderedFields;
}