import React, {Component} from 'react';

import {Modal, Tag} from 'antd'

import ButtonLoading from "../Shared/ButtonLoading";
import LoadingIndicator from "../Shared/LoadingIndicator";
import NotificationAlert from "../../other/NotificationAlert";

import {delete_incident, get_incident_drafts} from "../../api/zero-api";
import {dateFormatterWithTime, safe_get} from "../../other/Helper";

import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

import moment from 'moment';

class DraftsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drafts: [],
            loadingDrafts: true,
            showDeleteConfirm: false,
            indexToDelete: null,
            isDeleting: false
        };
    }

    componentDidMount() {
        this.getDrafts();
    }

    getDrafts() {
        this.setState({loadingDrafts: true});
        get_incident_drafts().then(success => {
            success.json().then(data => {
                const drafts = safe_get(data, 'incidents', []).sort(function (a, b) {
                    return a.edited_at < b.edited_at ? 1 : -1
                });

                this.setState({
                    drafts,
                    loadingDrafts: false
                });
            }).catch(err => {
                NotificationAlert("error", "", "Unable to load your drafts.");
                this.setState({
                    loadingDrafts: false
                });
            })
        })
    }

    showDeleteConfirmation(index) {
        this.setState({
            showDeleteConfirm: true,
            indexToDelete: index
        });
    }

    cancelDelete() {
        this.setState({
            showDeleteConfirm: false,
            indexToDelete: null,
            isDeleting: false
        });
    }

    confirmDelete() {
        const draftToDelete = this.state.drafts[this.state.indexToDelete];
        this.setState({
            isDeleting: true
        });
        delete_incident(safe_get(draftToDelete, 'incident_uuid', ''))
            .then(success => {
                this.cancelDelete();
                this.getDrafts();
            })
            .catch(err => {
                this.cancelDelete();
                NotificationAlert('error', '', 'Unable to delete draft.');
            });
    }

    editDraft(draft) {
        this.props.cancel();
        this.props.editDraft(draft);
    }

    renderFooter() {
        if (this.state.showDeleteConfirm) {
            return (
                <>
                    <button className="btn btn-discard" onClick={() => this.cancelDelete()}
                            disabled={this.state.isDeleting}>
                        Cancel
                    </button>
                    <button className="btn btn-modal" onClick={() => this.confirmDelete()}
                            disabled={this.state.isDeleting}>
                        {this.state.isDeleting ? <ButtonLoading/> : 'Delete'}
                    </button>
                </>
            )
        }
        return null;
    }

    assingmentDueDate(draft) {
        let due_date_timestamp = safe_get(draft, 'assignment.due_date_timestamp', null)
        if (!due_date_timestamp) {
            let dt = new Date();
            due_date_timestamp = dt.setUTCHours(23, 59, 0, 0) / 1000;
        }

        let due_date_time = `${moment.unix(due_date_timestamp).format("MMM D")}`;

        return (
            <span>{due_date_time}</span>
        )
    }

    renderModalBody() {
        if (this.state.loadingDrafts) {
            return <LoadingIndicator/>
        } else if (this.state.showDeleteConfirm) {
            return <div>Are you sure you want to delete this draft?</div>
        } else if (this.state.drafts.length === 0) {
            return <div>You currently do not have any drafts. When you do, they will be listed here.</div>
        } else {
            return (
                <div>
                    {this.state.drafts.map((draft, index) => {
                        return (
                            <div
                                key={index}
                                className="panel panel-light panel-colorful user-card-size thin-border"
                                style={{
                                    padding: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div>
                                    <span className="text-md text-semibold link-hover zero-blue"
                                          onClick={() => this.editDraft(draft)}>
                                        Case Number: {safe_get(draft, 'case_number', '')}
                                    </span>
                                    <br/>
                                    <span
                                        className="zero-dark-grey">Saved incident in {safe_get(draft, 'location_name', 'N/A')}</span>
                                    <br/>
                                    <span
                                        className="zero-dark-grey">Last edited on {dateFormatterWithTime(safe_get(draft, 'edited_at', safe_get(draft, 'created_at', 0)))}</span>
                                    <br/>
                                    {
                                        draft.assignment &&
                                        <Tag style={{color: "#505050"}}>Assignment
                                            Due {this.assingmentDueDate(draft)}</Tag>
                                    }
                                </div>
                                <div className="text-lg" style={{minWidth: "50px"}}>
                                    <EditOutlined className="link-hover zero-blue"
                                                  onClick={() => this.editDraft(draft)}/>
                                    <span className="v-divide"></span>
                                    <DeleteOutlined className="link-hover zero-delete-red"
                                                    onClick={() => this.showDeleteConfirmation(index)}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }
    }

    render() {
        return (
            (<Modal
                title={this.state.showDeleteConfirm ? "Confirmation" : `My Drafts (${this.state.drafts.length})`}
                open={this.props.show}
                maskClosable={false}
                closable={!this.state.showDeleteConfirm}
                onCancel={this.props.cancel}
                footer={this.renderFooter()}
                maskTransitionName='maskTransitionName'
            >{this.renderModalBody()}</Modal>)
        );
    }
}

export default DraftsModal;