import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {IoIosMore as MoreIcon} from 'react-icons/io';
import Skeleton from "react-loading-skeleton";

import SubmissionFieldCommentModal from "./SubmissionFieldCommentModal.js";

import { Dropdown, Popover, Progress, Tag } from "antd";

import { CheckCircleOutlined, CloudDownloadOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { delete_submission, get_form } from "../../api/zero-api.js";
import { dateFormatterNoTime, isAdmin, isAdminOrTeamLead, isTeamLead, safe_get } from "../../other/Helper.js";

import { useCachedApiLoader } from "components/Util/CachedApiLoader.js";
import { useCurrentTeam, useCurrentUser } from "hooks/reduxHooks.js";
import useOrganizationId from "hooks/useOrganizationId.js";
import _ from "lodash";
import { FORM_ELEMENTS } from "other/Constants.js";
import { getSubmissionAnswer, reorderFormFieldsWithSection } from "other/forms.js";
import { Link, useHistory } from "react-router-dom";
import NotificationAlert from "../../other/NotificationAlert.js";
import ConfirmModal from "../Shared/ConfirmModal.js";
import ExportSubmissionModal from "./ExportSubmissionModal.js";

function SubmissionStatusTag({ submission }) {
    const { status } = submission;

    const style = {
        fontWeight: "normal",
        verticalAlign: "bottom",
    };

    switch (status) {
        case "submitted":
            return <Tag style={style}>Submitted</Tag>;
        case "in_review":
            return (
                <Tag style={style} color="gold">
                    In Review
                </Tag>
            );
        case "closed":
            return (
                <Tag style={style} color="green">
                    <CheckCircleOutlined /> Closed
                </Tag>
            );
        default:
            return <Tag>N/A</Tag>;
    }
}

function RowMenu({ submission, onOptionClicked }) {
    const user = useCurrentUser();

    const userOwnsSubmission = user.uuid === submission.created_by.user_uuid;
    const restrictUserEdits = safe_get(submission, "form.restrict_user_edits", false);
    const userCanEditOwnSubmission = !restrictUserEdits || isAdminOrTeamLead(user);

    // can edit if user created this submission and restrict user edits is disabled, OR is an admin or team lead
    const submissionEditIsEnabled = (userOwnsSubmission && userCanEditOwnSubmission) || isAdminOrTeamLead(user);
    // can delete if user created this submission and restrict user edits is disabled, OR is an admin
    const submissionDeleteIsEnabled = (userOwnsSubmission && userCanEditOwnSubmission) || isAdminOrTeamLead(user);

    const canExport = userOwnsSubmission || isAdminOrTeamLead(user);

    const tableMenu = {
        onClick: (e) => onOptionClicked(e, submission),
        items: [
            canExport && {
                key: "export_submission",
                icon: <CloudDownloadOutlined className="mar-rgt-5" />,
                label: "Export",
            },
            {
                key: "edit_submission",
                icon: <EditOutlined />,
                label: "Edit",
                disabled: !submissionEditIsEnabled,
            },
            {
                key: "delete_submission",
                icon: <DeleteOutlined />,
                label: "Delete",
                className: submissionDeleteIsEnabled ? "zero-delete-red" : "",
                disabled: !submissionDeleteIsEnabled,
            },
        ].filter((item) => item !== false),
    };

    return (
        <div className="more pull-right" style={{ display: "inline-block", color: "grey", lineHeight: 0}}>
            <Dropdown menu={tableMenu} trigger={["click"]} placement="bottomRight">
                <button className="ButtonLink ant-dropdown-link">
                    <MoreIcon style={{ height: "20px", width: "20px" }} />
                </button>
            </Dropdown>
        </div>
    );
}

function generateColumns({ pageType, formFields }) {
    const makeColumn = (key, label, skeletonWidth, expandedWidth, regularWidth, className) => [
        key,
        label,
        skeletonWidth,
        expandedWidth,
        regularWidth,
        className,
    ];
    return [
        makeColumn("name", "Name", 180, 180, 180),
        makeColumn("formType", pageType === "regular" ? "Template" : "Course", 100, 180, 180),
        makeColumn("schedule", pageType === "regular" ? "Schedule" : "Progress", 100, 180, 180),
        makeColumn("team", "Team", 100, 180, 180),
        makeColumn("score", "Score", 80, 80, undefined, "text-center"),
        makeColumn("status", "Status", 80, 80, undefined, "text-center"),
        makeColumn("comments", "Comments", 100, 100, undefined, "text-center"),
        ...formFields.map((field) => {
            const label = _.truncate(field.label, { length: 100 });
            const width = _.clamp(label.length, 20, 30);
            return makeColumn(field.id, label, 180, `${width}ch`);
        }),
    ];
}

export default function SubmissionsTable({ tableRef, loading, pageType, submissions, refreshTable }) {
    const [submissionToExport, setSubmissionToExport] = useState(null);
    const showExportSubmissionModal = submissionToExport !== null;
    const [submissionToDelete, setSubmissionToDelete] = useState(null);
    const showDeleteSubmissionModal = submissionToDelete !== null;
    const [submissionForComments, setSubmissionForComments] = useState(null);
    const showCommentsModal = submissionForComments !== null;
    const cachedApiLoader = useCachedApiLoader();
    const [formFields, setFormFields] = useState([]);
    const isExpanded = formFields.length > 0;

    const currentTeam = useCurrentTeam();
    const user = useCurrentUser();
    const orgId = useOrganizationId();
    const formsQuery = useSelector((state) => state.forms.query);

    const history = useHistory();

    const columnDefs = useMemo(() => {
        return generateColumns({ pageType, formFields });
    }, [pageType, formFields]);

    const columnHeaders = useMemo(() => {
        return columnDefs.map(([key, label, skeletonWidth, expandedWidth, regularWidth, className]) => {
            const style = {
                minWidth: isExpanded ? expandedWidth : undefined,
                width: isExpanded ? undefined : regularWidth,
            };
            return (
                <th key={key} className={`zero-blue ${className}`} style={style}>
                    {loading ? <Skeleton width={skeletonWidth} /> : label}
                </th>
            );
        });
    }, [columnDefs, isExpanded, loading]);

    const submissionAnswers = useMemo(() => {
        if (formFields.length > 0) {
            const answers = {};
            submissions.forEach((submission) => {
                const submissionFields = _.keyBy(submission.fields, "id");
                answers[submission.submission_uuid] = formFields.map((formField) => {
                    const className = "";
                    const { id } = formField;

                    let value = "";
                    if (submissionFields[id]) {
                        value = getSubmissionAnswer(formField, submissionFields[id]);
                    }

                    let fullValue = "";
                    let truncated = false;
                    if (value.length > 25) {
                        fullValue = value;
                        truncated = true;
                        value = value.substring(0, 24) + "…";
                    }

                    return { id, value, className, fullValue, truncated };
                });
            });
            return answers;
        }

        return [];
    }, [submissions, formFields]);

    const formId = useMemo(() => {
        const params = new URLSearchParams(formsQuery);
        const formIds = (params.get("form_uuid") ?? "").split(",");
        if (formIds.length === 1) {
            return formIds[0];
        }
        return null;
    }, [formsQuery]);

    useEffect(() => {
        (async () => {
            setFormFields([]);
            if (formId) {
                const data = await cachedApiLoader.fetch(() => get_form(formId), "", {
                    cacheKey: `get_form:${formId}`,
                });
                const { form } = data;
                const formFields = reorderFormFieldsWithSection(form.fields).filter((field) =>
                    FORM_ELEMENTS.forTableView.includes(field.element)
                );
                setFormFields(formFields);
            }
        })();
    }, [formId, cachedApiLoader]);

    const deleteSubmission = async () => {
        try {
            await delete_submission(submissionToDelete.submission_uuid);
            NotificationAlert("success", "", "Submission deleted.");
            refreshTable(currentTeam.uuid);
        } catch (err) {
            NotificationAlert("error", "", "Unable to delete submission.");
        } finally {
            setSubmissionToDelete(null);
        }
    };

    const getSubmissionLink = (submission) => {
        const formTypePath = pageType === "regular" ? "forms" : "courses";
        return `/${orgId}/home/team/${submission.team.uuid}/${formTypePath}/submission_view/${submission.submission_uuid}`;
    };

    const getEditSubmissionLink = (submission) => {
        const formTypePath = pageType === "regular" ? "forms" : "courses";
        return `/${orgId}/home/team/${submission.team.uuid}/${formTypePath}/${submission.form.form_uuid}/submission/${submission.submission_uuid}?edit_submission=true`
    };

    const goToPost = (postId) => {
        history.push(`/${orgId}/home/team/my_teams/feed/post/${postId}`);
    };

    const handleMenuOptionClicked = (e, submission) => {
        if (e.key === "delete_submission") {
            setSubmissionToDelete(submission);
        } else if (e.key === "edit_submission") {
            history.push(getEditSubmissionLink(submission));
        } else if (e.key === "export_submission") {
            setSubmissionToExport(submission);
        }
    };

    return (
        <div>
            {showDeleteSubmissionModal && (
                <ConfirmModal
                    show={showDeleteSubmissionModal}
                    cancel={() => {
                        setSubmissionToDelete(null);
                    }}
                    confirm={deleteSubmission}
                    title={"Confirmation"}
                    body={"Are you sure you want to delete this submission? This action cannot be undone."}
                    confirmButtonName={"Delete"}
                />
            )}
            {showExportSubmissionModal && (
                <ExportSubmissionModal
                    show={showExportSubmissionModal}
                    submission_uuid={submissionToExport.submission_uuid}
                    cancel={() => {
                        setSubmissionToExport(null);
                    }}
                />
            )}
            {showCommentsModal && (
                <SubmissionFieldCommentModal
                    show={showCommentsModal}
                    cancel={() => {
                        setSubmissionForComments(null);
                    }}
                    submission_uuid={submissionForComments.submission_uuid}
                    goToPost={goToPost}
                />
            )}
            <div className="panel mar-btm-0">
                <div className="">
                    <div className="table-responsive" style={{ border: "none" }}>
                        <table ref={tableRef} className="table table-vcenter" style={{ marginBottom: "0px" }}>
                            <thead className="no-thead-borders" style={{ color: "#1D2A35" }}>
                                <tr style={{ display: "none" }}>
                                    <td colSpan={5}>ZERO Submissions Export</td>
                                </tr>
                                <tr style={{ display: "none" }}>
                                    <td colSpan={5}>Max of 25 submissions exported</td>
                                </tr>
                                <tr style={{ display: "none" }}>
                                    <td colSpan={5}></td>
                                </tr>
                                <tr>
                                    <th className="sheetjs-no-export"></th>
                                    {columnHeaders}
                                </tr>
                            </thead>
                            {loading && (
                                <tbody>
                                    {[...Array(10)].map((submission, index) => (
                                        <tr key={index} className="tr-hover">
                                            <td>{<Skeleton width={25} />}</td>
                                            <td>
                                                <span className="zero-dark-grey">
                                                    <Skeleton width={100} />
                                                </span>
                                            </td>
                                            <td>
                                                <span className="zero-dark-grey">
                                                    <Skeleton width={130} />
                                                </span>
                                            </td>
                                            <td>
                                                <span className="zero-dark-grey">
                                                    <Skeleton width={130} />
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                <span className="zero-dark-grey">
                                                    <Skeleton width={40} />
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                <span className="zero-dark-grey">
                                                    <Skeleton width={40} />
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                <span className="zero-dark-grey">
                                                    <Skeleton width={20} />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {!loading && submissions.length !== 0 && (
                                <tbody>
                                    {submissions.map((submission, index) => (
                                        <tr key={index} className="tr-hover">
                                            {(user.uuid === submission.created_by.user_uuid ||
                                                isAdmin(user) ||
                                                isTeamLead(user)) && (
                                                <td className="sheetjs-no-export">
                                                    <RowMenu
                                                        submission={submission}
                                                        onOptionClicked={handleMenuOptionClicked}
                                                    />
                                                </td>
                                            )}
                                            <td
                                                data-v={`${submission.created_by.first_name} ${
                                                    submission.created_by.last_name
                                                } - ${dateFormatterNoTime(submission.created_at)}`}
                                            >
                                                <Link to={getSubmissionLink(submission)}>
                                                    <span className="blue-link">
                                                        {submission.created_by.first_name}{" "}
                                                        {submission.created_by.last_name}
                                                    </span>
                                                    <br />
                                                    
                                                    <span className="zero-dark-grey" style={{ fontSize: "11px" }}>
                                                        {dateFormatterNoTime(submission.created_at)}
                                                    </span>
                                                </Link>
                                            </td>
                                            <td>
                                                <span className="zero-dark-grey">{submission.form.name}</span>
                                            </td>
                                            <td>
                                                <span className="zero-dark-grey">
                                                    {pageType === "regular" ? (
                                                        submission.scheduler_name
                                                    ) : submission.draft ? (
                                                        <Progress percent={submission.progress} size="small" />
                                                    ) : (
                                                        <span>
                                                            <CheckCircleOutlined style={{ color: "#52c41a" }} />{" "}
                                                            Completed
                                                        </span>
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="zero-dark-grey">
                                                    {submission.team.name}
                                                </span>
                                            </td>
                                            <td className="text-center" data-z="0%">
                                                <span className="zero-dark-grey">
                                                    {submission.score}
                                                    {!isNaN(submission.score) && "%"}
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                <SubmissionStatusTag submission={submission} />
                                            </td>
                                            <td className="text-center">
                                                <span className="zero-dark-grey">
                                                    {submission.comment_count > 0 ? (
                                                        <button
                                                            className="ButtonLink btn-link"
                                                            onClick={() => {
                                                                setSubmissionForComments(submission);
                                                            }}
                                                        >
                                                            Yes ({submission.comment_count})
                                                        </button>
                                                    ) : (
                                                        "--"
                                                    )}
                                                </span>
                                            </td>
                                            {isExpanded &&
                                                submissionAnswers[submission.submission_uuid].map(
                                                    ({ id, value, className, truncated, fullValue }) => (
                                                        <td key={id} className={`zero-dark-grey ${className ?? ""}`}>
                                                            {truncated ? (
                                                                <Popover trigger="click" content={fullValue}>
                                                                    <span className="hover-cursor">{value}</span>
                                                                </Popover>
                                                            ) : (
                                                                value
                                                            )}
                                                        </td>
                                                    )
                                                )}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {!loading && submissions.length === 0 && (
                                <tbody>
                                    <tr>
                                        <td
                                            className="react-bs-table-no-data"
                                            colSpan={columnDefs.length}
                                            style={{ color: "var(--zero-dark-grey)" }}
                                        >
                                            No submissions.
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
