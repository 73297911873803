import React from 'react';
import {Route, Switch} from 'react-router-dom';
// import createHistory from 'history/createBrowserHistory';
import {createBrowserHistory} from "history";
import {Router} from "react-router";

import ReactGA from 'react-ga';

import Login from './Login';
import OAuthLogin from './OAuthLogin';
import LoginAsUser from './LoginAsUser';

import KioskLogin from './KioskLogin';
import SignUp from './Signup/User/SignUp';
import SignUpAdmin from './Signup/Admin/SignUpAdmin';

import ResetPW from './ResetPassword';
import Main from './Main';

import ErrorBoundary from '../components/ErrorBoundry';
import Error403 from './Error403';
import LockedAccount from './LockedAccount'

import LinkRedirect from './LinkRedirect';

import {get_organization_uuid} from '../api/zero-api';
import OfflineMonitor from 'components/Offline/OfflineMonitor';
import AppUpdateAlert from 'components/AppUpdateAlert';
import OfflineAlert from 'components/Offline/OfflineAlert';
import PublicRouter from './PublicRouter';

ReactGA.initialize('UA-102456376-2');

let lastLocationPathname = "";

const history = createBrowserHistory()
history.listen((location, action) => {
    var org_uuid = get_organization_uuid();
    ReactGA.set({page: location.pathname});
    ReactGA.set({organization_uuid: org_uuid});
    ReactGA.pageview(location.pathname);

    if (lastLocationPathname !== location.pathname) {
        lastLocationPathname = location.pathname;

        setTimeout(() => {
            if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
                window.scrollTo(0, 0);
            }
        }, 150);
    }

});

window.zeroVersion = (process.env.REACT_APP_COMMIT_HASH || "abcd").substring(0, 4);

export default () => (
    <ErrorBoundary>
        <Router history={history}>
            <OfflineMonitor>
                <AppUpdateAlert/>
                <OfflineAlert/>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/login/:redirect" exact component={Login}/>
                    <Route path="/oauth_login" component={OAuthLogin}/>
                    {/* <Route path="/oauth_login/:redirect" exact component={OAuthLogin}/> */}
                    <Route path="/kiosk_mode" component={KioskLogin}/>
                    <Route path="/signup" exact component={SignUpAdmin}/>
                    <Route path="/signup/invite/:code" exact component={SignUp}/>
                    <Route path="/register/:code" exact component={SignUp}/>
                    <Route path="/resetpassword/:params" component={ResetPW}/>
                    <Route path="/resetpassword" component={ResetPW}/>
                    <Route path="/l/:linkId" exact component={LinkRedirect}/>
                    <Route path="/403" component={Error403}/>
                    <Route path="/account_locked" component={LockedAccount}/>
                    <Route path="/login_as_user" component={LoginAsUser}/>
                    <Route path="/public/:teamId?" component={PublicRouter}/>
                    <Route path="/" component={Main}/>
                </Switch>
            </OfflineMonitor>
        </Router>
    </ErrorBoundary>
);

