import React, { Component } from "react";
import { connect } from "react-redux";

import safe_get from "../../../other/SafeGet.js";

import { Radio } from "antd";

import Autocomplete from "components/Shared/GoogleMapsAutocomplete.js";

import $ from "jquery";

class HealthCareInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  componentDidMount() {
    $("#health_care_street_address").val(this.props.health_care_street);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.props.onChange(name, value);
  }

  handleAddressSelect(location) {
    // var address = location.formatted_address.split(", ");
    // var street = address[0];
    // var city = address[1];
    // var region = address[2].split(" ");
    // var state = region[0];
    // var zip = region[1];

    let address_components = safe_get(location, "address_components", []);

    var street = "";
    var city = "";
    var state = "";
    var zip = "";

    for (var i in address_components) {
      let item = address_components[i];
      let types = safe_get(item, "types", []);

      if (types.indexOf("street_number") >= 0) {
        street = street + safe_get(item, "long_name", "");
      } else if (types.indexOf("route") >= 0) {
        street = street + " " + safe_get(item, "short_name", "");
      } else if (types.indexOf("locality") >= 0) {
        city = safe_get(item, "short_name", "");
      } else if (types.indexOf("administrative_area_level_1") >= 0) {
        state = safe_get(item, "short_name", "");
      } else if (types.indexOf("postal_code") >= 0) {
        zip = safe_get(item, "long_name", "");
      }
    }

    $("#health_care_street_address").val(street);

    this.props.onChange("health_care_street", street);
    this.props.onChange("health_care_city", city);
    this.props.onChange("health_care_state", state);
    this.props.onChange("health_care_zip", zip);
  }

  onStateChange(value) {
    this.props.onChange("health_care_state", value);
  }

  handleRadioChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.props.onChange(name, value);
  }

  render() {
    const { fieldsDisabled } = this.props;

    return (
      <>
        <h3
          className="zero-blue print-only"
          style={{
            marginBottom: "20px",
            marginTop: "5px",
            fontSize: "120%",
            textDecoration: "underline",
          }}
        >
          Health Care Info
        </h3>
        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{ marginBottom: "5px", marginTop: "0px", fontSize: "100%" }}
          >
            Name of physician or other health care professional
          </h3>
          <input
            name="health_care_name"
            className="form-control custom-placeholder"
            placeholder=""
            onChange={this.handleInputChange}
            value={this.props.health_care_name}
            disabled={fieldsDisabled}
          />
          {this.state.name_error && (
            <small className="error">{this.state.name_error}</small>
          )}
        </div>
        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{ marginBottom: "5px", marginTop: "0px", fontSize: "100%" }}
          >
            If treatment was given away from the worksite, where was it given?
          </h3>
          <h3
            className="titles onboarding invite-link mt-0"
            style={{
              marginBottom: "5px",
              marginTop: "0px",
              fontSize: "100%",
              width: "50%",
            }}
          >
            Facility
          </h3>
          <input
            name="health_care_facility"
            className="form-control custom-placeholder"
            placeholder=""
            onChange={this.handleInputChange}
            value={this.props.health_care_facility}
            disabled={fieldsDisabled}
          />
          {this.state.street_address_error && (
            <small className="error">{this.state.street_address_error}</small>
          )}
        </div>
        <div className="row mar-btm-15">
          <div className="col-md-5">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              Street
            </h3>
            <Autocomplete
              id="health_care_street_address"
              className="form-control custom-placeholder"
              style={{ width: "100%" }}
              onPlaceSelected={this.handleAddressSelect}
              onChange={this.handleInputChange}
              types={["address"]}
              componentRestrictions={{ country: ["us", "ca"] }}
              name="health_care_street"
              disabled={fieldsDisabled}
              defaultValue={this.props.health_care_street}
            />
            {this.state.street_address_error && (
              <small className="error">{this.state.street_address_error}</small>
            )}
          </div>
          <div className="col-md-3">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              City
            </h3>
            <input
              name="health_care_city"
              className="form-control custom-placeholder"
              placeholder=""
              onChange={this.handleInputChange}
              value={this.props.health_care_city}
              disabled={fieldsDisabled}
            />
          </div>
          <div className="col-md-2">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              State
            </h3>
            <input
              name="health_care_state"
              className="form-control custom-placeholder"
              placeholder=""
              onChange={this.handleInputChange}
              value={this.props.health_care_state}
              disabled={fieldsDisabled}
            />
          </div>
          <div className="col-md-2">
            <h3
              className="titles onboarding invite-link mt-0"
              style={{
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "100%",
                width: "50%",
              }}
            >
              Zip
            </h3>
            <input
              name="health_care_zip"
              className="form-control custom-placeholder"
              placeholder=""
              onChange={this.handleInputChange}
              value={this.props.health_care_zip}
              disabled={fieldsDisabled}
            />
          </div>
        </div>

        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{ marginBottom: "5px", marginTop: "0px", fontSize: "100%" }}
          >
            Was the employee treated in an emergency room?
          </h3>
          <div>
            <Radio.Group
              disabled={fieldsDisabled}
              name="emergency_room_used"
              onChange={this.handleRadioChange}
              value={this.props.emergency_room_used}
            >
              <Radio className="zero-dark-grey" value={true}>
                Yes
              </Radio>
              <Radio className="zero-dark-grey" value={false}>
                No
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="mar-btm-15">
          <h3
            className="titles onboarding invite-link mt-0"
            style={{ marginBottom: "5px", marginTop: "0px", fontSize: "100%" }}
          >
            Was the employee hospitalized overnight as an in-patient?
          </h3>
          <div>
            <Radio.Group
              disabled={fieldsDisabled}
              name="hospitalized"
              onChange={this.handleRadioChange}
              value={this.props.hospitalized}
            >
              <Radio className="zero-dark-grey" value={true}>
                Yes
              </Radio>
              <Radio className="zero-dark-grey" value={false}>
                No
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: safe_get(store, "user.user", {}),
    current_team: safe_get(store, "teams_helper.team", {}),
    teams: safe_get(store, "teams_helper.teams", []),
    all_assignments: safe_get(store, "assignments", {}),
  };
};

export default connect(mapStateToProps)(HealthCareInfo);
