import React, {Component} from 'react';

import {Modal} from 'antd';
import '../../assets/css/antd-custom.css'
import {export_submission} from "../../api/zero-api";
import NotificationAlert from "../../other/NotificationAlert";
import ButtonLoading from "../Shared/ButtonLoading";

class ExportSubmissionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            exported: false
        };
    }

    exportSubmission = () => {
        if (!this.state.loading) {
            this.setState({loading: true});
            export_submission(this.props.submission_uuid).then(response => {
            }).catch(error => {
                NotificationAlert('error', 'Oops!', "Unable to export submission.");
            }).finally(() => {
                this.setState({exported: true, loading: false});
            });
        }
    }

    renderFooter() {
        if (this.state.exported) {
            return (
                <div>
                    <button className="btn btn-primary" onClick={this.props.cancel}>Done</button>
                </div>
            )
        } else {
            return (
                <div>
                    <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                    <button className="btn btn-primary" onClick={this.exportSubmission}>
                        {
                            this.state.loading ? <ButtonLoading/> : "Export"
                        }
                    </button>
                </div>
            );
        }
    }

    renderBodyText() {
        const {exported} = this.state
        if (exported) {
            return (
                <>We're generating that export for you! It should be in your inbox shortly. Please contact <a
                    className="blue-link" href={"mailto:support@teamzero.com"}>support@teamzero.com</a> with any
                    questions.</>
            )
        }

        return "Export submission to PDF. We'll send you an email with the file.";
    }

    render() {
        return (
            (<Modal
                title="Export submission"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={this.renderFooter()}
            >
                <p className="zero-dark-grey">{this.renderBodyText()}</p>
            </Modal>)
        );
    }

}

export default ExportSubmissionModal;