import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import CountryPhoneInput from 'antd-country-phone-input';
import {isAdmin, safe_get} from '../../other/Helper';
import InfoIconComponent from '../InfoIconComponent';
import {get_timezones} from '../../api/zero-api';
import {useSelector} from 'react-redux';

const DEFAULT_PHONE_PARTS = {code: 1, short: 'US'};

function FormGroupTextInput({label, name, placeholder, value, onChange, error = '', disabled = false, info = ''}) {
    return (
        <FormGroupCustomInput label={label} error={error} info={info}>
            <input
                type="text"
                name={name}
                placeholder={placeholder}
                className="form-control signup-field custom-placeholder account"
                style={{maxWidth: '260px'}}
                value={value}
                onChange={disabled ? null : onChange}
                disabled={disabled}
            />
        </FormGroupCustomInput>
    );
}

FormGroupTextInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    info: PropTypes.string
}

function FormGroupCustomInput({label, children, error = '', info = ''}) {
    return (
        <div className="form-group">
            <label className="col-lg-5 col-sm-5 col-xs-12 control-label account zero-blue">
                {label}
                {
                    info &&
                    <InfoIconComponent
                        position={"bottom"}
                        width={"160px"}
                        text={info}
                    />
                }
            </label>
            <div className="col-lg-7 col-sm-7 signup-input">
                {children}
                {
                    error &&
                    <small className="error" style={{display: 'block', marginTop: '4px'}}>{error}</small>
                }
            </div>
        </div>
    );
}

FormGroupCustomInput.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    info: PropTypes.string,
    children: PropTypes.element.isRequired
}

function AccountEditFields({
                               user,
                               emailEditable,
                               showTimezone = false,
                               showPassword,
                               showRole = false,
                               changePasswordOnClick,
                               onFieldsChange,
                               fieldErrors = {}
                           }) {

    const mobilePhoneParts = safe_get(user, 'mobile_phone_number_parts', {});
    const emergencyPhoneParts = safe_get(user, 'emergency_phone_parts', {});
    const userRoles = safe_get(user, 'roles', []);

    const [fields, setFields] = useState({
        first_name: safe_get(user, 'first_name', ''),
        last_name: safe_get(user, 'last_name', ''),
        title: safe_get(user, 'title', ''),
        phone_number: safe_get(user, 'phone_number', ''),
        mobile_phone_number_parts: mobilePhoneParts.phone ? mobilePhoneParts : DEFAULT_PHONE_PARTS,
        emergency_phone_parts: emergencyPhoneParts.phone ? emergencyPhoneParts : DEFAULT_PHONE_PARTS,
        email: safe_get(user, 'email', ''),
        alternate_email: safe_get(user, 'alternate_email', ''),
        timezone: safe_get(user, 'timezone', ''),
        role: userRoles?.[0] || null,
    });

    const [timezones, setTimezones] = useState([]);
    const initiatorUser = useSelector(state => state.user.user);

    useEffect(() => {
        if (showTimezone) {
            get_timezones().then(success => {
                success.json().then(data => {
                    setTimezones(safe_get(data, 'timezones', []));
                });
            });
        }
    }, [showTimezone])

    useEffect(() => {
        const newFields = {...fields};
        if (!showTimezone) {
            delete newFields['timezone'];
        }
        onFieldsChange(newFields);
    }, [fields]);

    const updateField = (name, value) => {
        setFields({
            ...fields,
            [name]: value
        });
    }

    const onTextInputChange = event => {
        updateField(event.target.name, event.target.value);
    }

    const updatePhoneParts = (name, value) => {
        if (value.phone && value.phone.length) {
            value.phone = value.phone.replace(/\D/g, '').substring(0, 15);
        }
        updateField(name, value);
    }

    const updateTimezone = value => {
        updateField('timezone', value);
    }

    return (
        <>
            <FormGroupTextInput
                label="First Name*"
                name="first_name"
                placeholder="First Name"
                value={fields.first_name}
                onChange={onTextInputChange}
                error={fieldErrors.first_name}
            />
            <FormGroupTextInput
                label="Last Name*"
                name="last_name"
                placeholder="Last Name"
                value={fields.last_name}
                onChange={onTextInputChange}
                error={fieldErrors.last_name}
            />
            {
                (fields.email || emailEditable) &&
                <FormGroupTextInput
                    label="Email*"
                    name="email"
                    placeholder="Email"
                    value={fields.email}
                    onChange={onTextInputChange}
                    error={fieldErrors.email}
                    disabled={!emailEditable}
                />
            }
            {showRole &&
                <FormGroupCustomInput
                    label="Role"
                    error={fieldErrors.role}
                >
                    <Select
                        style={{maxWidth: '260px', width: '100%'}}
                        showArrow={true}
                        placeholder="Select a role"
                        dropdownStyle={{zIndex: "10000"}}
                        onChange={role => updateField('role', role)}
                        value={fields.role}
                        filterOption={false}
                        virtual={false}
                    >
                        <Select.Option key={"admin"} value={"admin"} disabled={!isAdmin(initiatorUser)}>
                            <span className="badge badge-success badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Admin
                        </Select.Option>
                        <Select.Option key={"team_lead"} value={"team_lead"}>
                            <span className="badge badge-info badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Team
                            Lead
                        </Select.Option>
                        <Select.Option key={"user"} value={"user"}>
                            <span className="badge badge-purple badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Member
                        </Select.Option>
                        <Select.Option key={"contributor"} value={"user_restricted"}>
                            <span className="badge badge-warning badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Contributor
                        </Select.Option>
                        <Select.Option key={"viewer"} value={"viewer"}>
                            <span className="badge badge-danger badge-icon " style={{margin: "0px 5px 2px 0px"}}/>Viewer
                        </Select.Option>
                    </Select>
                </FormGroupCustomInput>
            }
            <FormGroupTextInput
                label="Title"
                name="title"
                placeholder="Position Title"
                value={fields.title}
                onChange={onTextInputChange}
                error={fieldErrors.title}
            />
            <FormGroupTextInput
                label="Office Phone"
                name="phone_number"
                placeholder="Phone Number"
                value={fields.phone_number}
                onChange={onTextInputChange}
                error={fieldErrors.phone_number}
            />
            <FormGroupCustomInput
                label="Mobile Phone (SMS)"
                error={fieldErrors.mobile_phone_number_parts}
                info="The Mobile Phone is used to send you text message notifications about things that happen in ZERO."
            >
                <div className="country-phone-input-wrapper">
                    <CountryPhoneInput
                        inline
                        selectProps={{dropdownStyle: {maxWidth: 260}}}
                        style={{maxWidth: 260}}
                        name="mobile_phone_number_parts"
                        type="tel"
                        value={{...fields.mobile_phone_number_parts}}
                        onChange={value => updatePhoneParts('mobile_phone_number_parts', value)}
                    />
                </div>
            </FormGroupCustomInput>
            <FormGroupCustomInput
                label="Emergency Phone (Voice)"
                error={fieldErrors.emergency_phone_parts}
                info="The Emergency Phone is used for urgent post announcements. ZERO will call you on your emergency phone number if instructed to do so by an Administrator."
            >
                <div className="country-phone-input-wrapper">
                    <CountryPhoneInput
                        inline
                        selectProps={{dropdownStyle: {maxWidth: 260}}}
                        style={{maxWidth: 260}}
                        name="emergency_phone_parts"
                        type="tel"
                        value={{...fields.emergency_phone_parts}}
                        onChange={value => updatePhoneParts('emergency_phone_parts', value)}
                    />
                </div>
            </FormGroupCustomInput>
            <FormGroupTextInput
                label="Alternate Email"
                name="alternate_email"
                placeholder="Alternate Email"
                value={fields.alternate_email}
                onChange={onTextInputChange}
                error={fieldErrors.alternate_email}
                info="The Alternate Email is used for urgent post announcements. ZERO will send an email to your alternate email address if instructed to do so by an Administrator."
            />
            {
                showPassword &&
                <FormGroupCustomInput label="Password">
                    <div className="signup-input account" style={{paddingTop: 7}}>
                        <span style={{verticalAlign: "sub"}}>*******&nbsp;</span>
                        <button onClick={changePasswordOnClick} className="ButtonLink"
                                style={{textDecoration: 'underline', color: '#68C4FA',}}>Change...
                        </button>
                    </div>
                </FormGroupCustomInput>
            }
            {
                showTimezone &&
                <FormGroupCustomInput label="Time Zone" error={fieldErrors.timezone}>
                    <div class="account-edit-timezone-selector-wrapper">
                        <Select style={{width: 260}} value={fields.timezone} onChange={updateTimezone}>
                            {
                                timezones.map((item, i) => (
                                    <Select.Option key={i} value={item.value}>{item.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </FormGroupCustomInput>
            }
        </>
    );
}

AccountEditFields.propTypes = {
    user: PropTypes.object.isRequired,
    showTimezone: PropTypes.bool,
    showPassword: PropTypes.bool.isRequired,
    showRole: PropTypes.bool,
    emailEditable: PropTypes.bool.isRequired,
    changePasswordOnClick: PropTypes.func.isRequired,
    onFieldsChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object
};


export default AccountEditFields;