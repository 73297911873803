import React, {Component} from 'react';
import {connect} from 'react-redux';

import {get_popular_key_words_analytics, notifyError} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet';

import {KeyOutlined} from '@ant-design/icons'
import LoadingIndicator from '../Shared/LoadingIndicator';


class PopularKeywords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            keywords: [],
            wordcloud: "",
            wordcloud_count: 0
        };

        this.get_analytics = this.get_analytics.bind(this);
        this.formatKeywords = this.formatKeywords.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.date_query !== prevProps.date_query) || (this.props.feed_query !== prevProps.feed_query)) {
            this.get_analytics(this.props.feed_query, this.props.date_query);
        }
    }

    componentDidMount() {
        this.get_analytics(this.props.feed_query, this.props.date_query);
    }

    get_analytics = (feed_query = "", date_query = "") => {
        var self = this;
        var query = ""
        if (feed_query === "" || date_query === "") {
            query = "?team_uuid=" + this.props.team_uuid + "&period=all_time";
        }

        query = feed_query + date_query;

        this.setState({loading: true});

        get_popular_key_words_analytics(query).then(function (success) {
            success.json().then(success => {
                var wordcloud2 = safe_get(success, "wordcloud", "");
                var wordcloud2_count = safe_get(success, "wordcloud_count", "");
                self.formatKeywords(safe_get(success, "keywords", []));

                self.setState({
                    wordcloud: wordcloud2,
                    wordcloud_count: wordcloud2_count,
                    loading: false
                });
            });
        }, function (error) {
            notifyError(error);
            self.setState({loading: false});
        });
    }

    formatKeywords(keywords) {
        var new_keywords = []
        for (var i in keywords) {
            var kw = keywords[i]

            var new_kw = {
                value: kw,
                count: keywords.length - (i * 2)
            }

            new_keywords.push(new_kw)
        }

        this.setState({
            keywords: new_keywords,
            loading: false
        })
    }

    wordcloudinnerHTML() {
        const word_string = "<img align='left' style='width: 100%' height='400px' src='data:image/png;charset=utf-8;base64, " + this.state.wordcloud + "' />";

        return {__html: word_string};
    }

    render() {
        const customRenderer = (tag, size, color) => (
            <span
                key={tag.value}
                style={{
                    fontSize: `${size}px`,
                    marginTop: `${Math.floor(Math.random() * 10)}px`,
                    marginBottom: `${Math.ceil(Math.random() * 10)}px`,
                    marginLeft: `${Math.ceil(Math.random() * 10)}px`,
                    marginRight: `${Math.floor(Math.random() * 10)}px`,
                    display: 'inline-block',
                    color: `${color}`,
                }}>
                {tag.value}
            </span>
        );
        if (this.state.loading) {
            return (
                <LoadingIndicator columnClass={"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-print-12"}/>
            );
        } else {

            return (
                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-print-12 col-reduce-padding"}>
                    <div className="panel">
                        <div className="panel-heading">
                            <h2 className="panel-title analytics"
                                style={{display: 'inline-block', paddingLeft: '10px'}}>
                                <KeyOutlined className="mar-rgt-5"/>Keywords
                            </h2>
                        </div>
                        <div className="pad-hor" style={{height: '400px', paddingBottom: '10px'}}
                             dangerouslySetInnerHTML={this.wordcloudinnerHTML()}></div>
                    </div>
                </div>
            );
        }

    }
}


const mapStateToProps = store => {
    return {
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", "")
    }
}

export default connect(mapStateToProps)(PopularKeywords);
  

