import React, {Component} from 'react';
import {Modal} from 'antd';
import ButtonLoading from './ButtonLoading';
import {safe_get} from '../../other/Helper';

import '../../assets/css/antd-custom.css'

class ConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {

    }

    confirm = () => {
        this.setState({confirming: true});
        this.props.confirm();
    }

    cancel = () => {
        if (this.props.cancelAction) {
            this.setState({canceling: true});
            this.props.cancelAction();
        } else {
            this.props.cancel();
        }
    }

    render() {
        return (
            (<Modal
                title={this.props.title}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.cancel}>
                            {
                                this.state.canceling ? <ButtonLoading
                                    className="inverted"/> : safe_get(this.props, "cancelButtonName", "Cancel")
                            }
                        </button>
                        <button className="btn btn-modal" onClick={this.confirm}>
                            {
                                this.state.confirming ? <ButtonLoading/> : this.props.confirmButtonName
                            }
                        </button>
                    </div>
                }
            >
                {
                    this.props.body &&
                    <p className="zero-dark-grey">{this.props.body}</p>
                }
                {
                    this.props.deactivateUser &&
                    <>
                        <p className="zero-dark-grey">Here's what happens when you deactivate someone:</p>
                        <ul className="zero-dark-grey" style={{paddingLeft: "15px"}}>
                            <li>The user will no longer be able to login to this account.</li>
                            <li>The user will be removed from all teams, locations, schedules, alerts, etc.</li>
                            <li>The user's data will still be accessible in ZERO.</li>
                        </ul>
                    </>
                }
            </Modal>)
        );
    }
}

export default ConfirmModal;