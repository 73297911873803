import react, {Component} from 'react';
import {WebSocketContext} from "../other/WebSockets";
import {safe_get} from "../other/Helper";
import {connect} from "react-redux";

class BrowserNotifications extends Component {
    static contextType = WebSocketContext;

    constructor(props) {
        super(props);
    }

    createNotification = (org_uuid, title, body, url, tag = null) => {
        if (!("Notification" in window) || Notification.permission !== 'granted') {
            return;
        }

        if (this.context.browserNotificationSettings[org_uuid]) {
            const notification = new Notification(title, {body, tag});
            if (url) {
                notification.onclick = event => {
                    event.preventDefault();
                    notification.close();

                    if (org_uuid !== this.props.organization.organization_uuid) {
                        window.location.href = url;
                    } else {
                        this.props.history.push(url);
                    }

                    window.focus();
                }
            }
        }
    }

    createFeedNotification = (message) => {
        const url = `/${message.organization_uuid}/home/team/my_teams/feed/post/${message.data.object_uuid}`;
        this.createNotification(
            message.organization_uuid,
            message.data.title,
            message.data.body,
            url,
            message.data.notification_uuid
        );
    }

    componentDidMount() {
        this.context.addMessageHandler('feed_notification', this.createFeedNotification);
    }

    componentWillUnmount() {
        this.context.removeMessageHandler('feed_notification', this.createFeedNotification);
    }

    render() {
        return null;
    }
}

const mapStateToProps = store => {
    return {
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default connect(mapStateToProps)(BrowserNotifications);