// @ts-check
import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloudDownloadOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    ShareAltOutlined,
    UserAddOutlined,
    ToolOutlined,
} from "@ant-design/icons";
import { IoIosMore as MoreIcon } from 'react-icons/io';

import { Dropdown, Tag } from "antd";
import { assign_user, getBulletinPost } from "api/zero-api";
import RespondersPopover from "components/Shared/RespondersPopover";
import UserPopoverCard from "components/Shared/UserPopoverCard";
import { useCurrentOrganization, useCurrentUser } from "hooks/reduxHooks";
import useOrganizationId from "hooks/useOrganizationId";
import useZeroSelector from "hooks/useZeroSelector";
import { isAdmin, isContributor, isRestrictedTeamUser, isTeamLead, isUser, isViewer, safeProfilePic, safe_get } from "other/Helper";
import { Link, useHistory } from "react-router-dom";
import RiskBadge from "../RiskBadge";
import { useFeedDataGridContext } from "./FeedDataGrid.context";

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function TitleCell(props) {
    const history = useHistory();
    const organization = useCurrentOrganization();

    if (!props.data) {
        return props.value;
    }

    const linkUrl = `/${organization.organization_uuid}/home/team/my_teams/feed/post/${props.data.post_uuid}`

    const onClick = () => {
        history.replace(history.location.pathname, {gridState: props.api.getState()});
    }

    return <Link
        to={linkUrl}
        className="blue-link"
        onClick={onClick}
    >{props.value}</Link>
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function StatusCell(props) {
    if (!props.data) {
        return null;
    }
    
    if (props.value === "closed") {
        return (
            <Tag color="green" style={{ borderColor: "#52c41a", fontSize: "11px" }}>
                {/* @ts-ignore */}
                <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Closed
            </Tag>
        );
    }

    return <Tag style={{ color: "#505050", fontSize: "11px" }}>Open</Tag>;
}

export function RiskCell({ value }) {
    if (value === 0) {
        return null;
    }

    return <RiskBadge value={value} />;
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function RespondersCell(props) {
    const { value: responders, data: post, setValue } = props;
    const orgId = useOrganizationId();
    const user = useZeroSelector((state) => state.user.user);

    const handleRefresh = async () => {
        try {
            const data = await getBulletinPost(post.post_uuid).then(r => r.json());
            const newAssigns = safe_get(data, "post.assigns", []);
            setValue(newAssigns);
        } catch (err) {
            console.log(err);
        }
    };

    const handleUpdateResponders = async (newIds) => {
        try {
            const body = JSON.stringify({
                user_uuids: newIds
            });
    
            const data = await assign_user(post.post_uuid, body).then(r => r.json());
            const newAssigns = safe_get(data, "post.assigns", []);
            setValue(newAssigns);
        } catch (err) {
            console.log(err);
        }
    };

    if (!responders) {
        return null;
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', height: '100%'}}>
            {responders.length !== 0 &&
                [...responders].slice(0, 3).map((user, index) => (
                    <UserPopoverCard
                        key={index}
                        user={user}
                        responders={responders}
                        post_uuid={post.post_uuid}
                        org_uuid={orgId}
                        refresh={handleRefresh}
                        showRemoveResponder={true}
                    >
                        {
                            safeProfilePic(
                                user,
                                "img-sm img-circle bulletin",
                                "bulletin",
                                {
                                    margin: "0",
                                    display: "inline-block",
                                    cursor: "pointer"
                                }
                            )
                        }
                    </UserPopoverCard>
                ))}
            {responders.length > 3 && !isViewer(user) && !isRestrictedTeamUser(post.source_team, user) && (
                <RespondersPopover
                    post_uuid={post.post_uuid}
                    selected_responders={responders}
                    updateResponders={handleUpdateResponders}
                >
                    <div
                        className={"default-avatar-circle bulletin"}
                        style={{ cursor: "pointer", display: "inline-block", border: "1px solid #505050" }}
                    >
                        <p className="initials" style={{ color: "#505050" }}>
                            +{responders.length - 3}
                        </p>
                    </div>
                </RespondersPopover>
            )}
            {responders.length <= 3 && !isViewer(user) && !isRestrictedTeamUser(post.source_team, user) && (
                <RespondersPopover
                    post_uuid={post.post_uuid}
                    selected_responders={responders}
                    updateResponders={handleUpdateResponders}
                >
                    <button className="ButtonLink">
                        {/* @ts-ignore */}
                        <UserAddOutlined style={{ fontSize: "19px" }} />
                    </button>
                </RespondersPopover>
            )}
        </div>
    );
}

export function PostActionCell(props) {
    const user = useCurrentUser();
    const {setActiveModal} = useFeedDataGridContext();
    const post = props.data;

    if (!post) {
        return null;
    }

    const is_Admin = isAdmin(user);
    const is_TeamLead = isTeamLead(user);
    const is_Contributor = isContributor(user);
    const is_user = isUser(user);
    const is_Author = user.uuid === safe_get(post, "revised_by.user_uuid", null);

    const canChangeStatus = is_Admin || is_TeamLead || is_Contributor || is_user;
    const canEdit = (is_Admin || is_TeamLead || is_Contributor || is_user || is_Author) && post.status === "open";
    const canDelete = (is_Admin || is_Author || is_TeamLead) && post.status === "open";

    const tableMenu = {
        onClick: (e) => {
            setActiveModal({
                key: e.key,
                post,
            });
        },
        items: [
            {
                key: "status",
                disabled: !canChangeStatus,
                icon:
                    post.status === "open" ? (
                        <CheckCircleOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />
                    ) : (
                        <ToolOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />
                    ),
                label: post.status === "open" ? "Close" : "Reopen",
            },
            {
                key: "share",
                icon: <ShareAltOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />,
                label: "Share",
            },
            {
                key: "move",
                icon: <ExportOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />,
                label: "Move",
            },
            {
                key: "copy",
                icon: <CopyOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />,
                label: "Copy",
            },
            {
                key: "export",
                icon: <CloudDownloadOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />,
                label: "Export",
            },
            {
                key: "edit",
                disabled: !canEdit,
                icon: <EditOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />,
                label: "Edit",
            },
            {
                key: "delete",
                disabled: !canDelete,
                className: canDelete ? "zero-delete-red" : "",
                icon: <DeleteOutlined className="mar-rgt-5" style={{ fontSize: "15px" }} />,
                label: "Delete",
            },
        ],
    };

    if (isViewer(user) || isRestrictedTeamUser(post.source_team, user)) {
        return null;
    }

    return (
        <div className="more" style={{ display: "inline-block", color: "grey", lineHeight: 0, margin: "0 auto" }}>
            <Dropdown menu={tableMenu} trigger={["click"]} placement="bottomRight">
                <button className="ButtonLink ant-dropdown-link">
                    <MoreIcon style={{ height: "20px", width: "20px" }} />
                </button>
            </Dropdown>
        </div>
    );
}