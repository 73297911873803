import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import AdminSignupInfo from './AdminSignupInfo.js'

import {update_auth_token, register} from '../../../api/zero-api.js'
import {getParameterByName, safe_get} from '../../../other/Helper';

import '../../../assets/css/Login.css';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            continue: false,
            pwError: "",
            email: "",
            inviteCode: "",
            isRegister: false,
        };

        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handleEmailKeyPress = this.handleEmailKeyPress.bind(this);
        this.handleCode = this.handleCode.bind(this);
    }

    componentDidMount() {
        update_auth_token(undefined);

        var code = getParameterByName("confirmation_code");
        var email = getParameterByName("email");
        if (code && email) {
            this.setState({
                email: email,
                inviteCode: code,
                step: 3
            });

            // GTAG
            window.gtag('event', 'conversion', {'send_to': 'AW-795109293/KWSGCMnYvIkBEK3PkfsC'});
        }
    }

    handlePassword = (e) => {
        this.setState({password: e.target.value});
    }

    confirmPassword = () => {
        if (this.state.password === "ZeroEHS#1") {
            this.setState({step: 1});
        }
    }

    handleEmailSubmit() {
        var self = this;

        let email = document.getElementById('email-input').value;
        var body = JSON.stringify({
            email: email
        });

        register(body).then(function (success) {
            self.setState({emailError: null, email: email, step: 2});

            // GTAG
            window.gtag('event', 'conversion', {'send_to': 'AW-795109293/tutsCOvfyYkBEK3PkfsC'});

        }, function (error) {
            if (error.status === 422) {
                error.json().then(error => {
                    self.setState({
                        emailError: error.error_messages[0].message
                    });
                });
            }

        });
    }


    handleEmailKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleEmailSubmit();
        }
    }

    handleCode() {
        var code = document.getElementById('confirmation-code').value
        if (code.length === 0) {
            this.setState({codeError: "Invalid Code"});
        } else {
            this.setState({step: 3, inviteCode: code, codeError: null});
        }
    }

    render() {

        var headerStyle = {
            zIndex: '1001',
            position: 'fixed',
            width: "100%",
            display: "block"
        }

        return (
            <div id="container" className="cls-container auth signup">
                <header style={headerStyle}>
                    <div className="navbar-1 w-nav">
                        <div className="content-wrapper-nav w-container">
                            <a href="https://www.teamzero.com" target="_blank" rel="noopener noreferrer"
                               className="brand w-nav-brand w--current">
                                <img alt="zero-logo"
                                     src="https://uploads-ssl.webflow.com/5a15e7876b2f3a0001d2c242/5a15eab3cc80bd0001ecacff_ZERO%20(blue).png"
                                     width="120" className="image-2"/>
                            </a>
                            <nav role="navigation" className="nav-menu w-nav-menu">
                                {/* <a href="https://www.teamzero.com/features" className="navlink w-nav-link hidden-xs">Features</a>
                                <a href="https://www.teamzero.com/blog" className="navlink w-nav-link hidden-xs" >Blog</a>
                                <a href="https://help.teamzero.com" className="navlink w-nav-link hidden-xs">Support</a> */}
                                <Link to="/login" target="_blank" className="navlink login w-nav-link">Login</Link>
                            </nav>

                        </div>
                    </div>

                </header>

                <div className="cls-content" style={{paddingTop: (this.state.step === 3 ? '100px' : '200px')}}>

                    <div className="cls-content-lg panel white" style={{maxWidth: '550px', marginTop: "10px"}}>
                        <div className="panel thin-border">
                            {
                                this.state.step === 0 &&
                                <div className="panel-body">
                                    <h1 className="h3 zero-blue " style={{marginTop: "15px"}}>Password</h1>
                                    {/* <p style={{fontSize: "14px"}}>To create a new account, please enter your work email address.</p> */}

                                    <form className="">
                                        <div className="form-group" style={{marginTop: "15px"}}>
                                            <div className="">
                                                <input
                                                    type="password"
                                                    className="form-control custom-placeholder admin-signup"
                                                    placeholder="password" required
                                                    style={{
                                                        maxWidth: '300px',
                                                        display: "inline-block",
                                                        borderColor: "#03a9f4",
                                                        fontSize: "15px",
                                                        height: "35px"
                                                    }}
                                                    onChange={this.handlePassword}
                                                />
                                                <button type="button" className="btn btn-primary next" style={{
                                                    verticalAlign: "top",
                                                    marginLeft: "6px",
                                                    height: "35px"
                                                }} onClick={this.confirmPassword}>Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {
                                this.state.step === 1 &&
                                <div className="panel-body">
                                    <h1 className="h3 zero-blue " style={{marginTop: "15px"}}>Create a new account</h1>
                                    <p style={{fontSize: "14px"}}>To create a new account, please enter your work email
                                        address.</p>

                                    <form className="">
                                        <div className="form-group" style={{marginTop: "15px"}}>
                                            <div className="">
                                                <input
                                                    type="email"
                                                    className="form-control custom-placeholder admin-signup"
                                                    id="email-input"
                                                    name="email"
                                                    placeholder="name@company.com" required
                                                    style={{
                                                        maxWidth: '300px',
                                                        display: "inline-block",
                                                        borderColor: "#03a9f4",
                                                        fontSize: "15px",
                                                        height: "35px"
                                                    }}
                                                    onKeyPress={this.handleEmailKeyPress}
                                                />
                                                <button type="button" className="btn btn-primary next" style={{
                                                    verticalAlign: "top",
                                                    marginLeft: "6px",
                                                    height: "35px"
                                                }} onClick={this.handleEmailSubmit}>Confirm
                                                </button>
                                            </div>
                                            {
                                                this.state.emailError &&
                                                <small className="error single"
                                                       style={{float: "left"}}>{this.state.emailError}</small>
                                            }
                                        </div>
                                    </form>
                                </div>
                            }
                            {
                                this.state.step === 2 &&
                                <div className="panel-body">
                                    <h1 className="h3 zero-blue" style={{marginTop: "10px"}}>Verify Email</h1>
                                    <p style={{fontSize: "14px", paddingBottom: "10px"}}>A confirmation email has been
                                        sent to <span style={{fontWeight: "700"}}>{this.state.email}</span>. Click on
                                        the confirmation link in the email to activate your account. <br/><br/>Remember
                                        to try your spam folder!</p>
                                </div>
                            }
                            {
                                this.state.step === 3 &&
                                <div className="panel-body">
                                    <h1 className="h3 zero-blue">Account Details</h1>
                                    <p>Please fill out your information to create your account.</p>
                                    <AdminSignupInfo
                                        inviteCode={this.state.inviteCode}
                                        email={this.state.email}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {}
}

export default connect(mapStateToProps)(SignUp);
