import axios from "axios";
import {compareLists} from "other/Helper";
import {get_presigned_url} from "api/zero-api";

/**
 * @typedef {object} ProcessAttachmentsOptions
 * @property {string} attachmentIdPropName
 */

/**
 * @type {ProcessAttachmentsOptions}
 */
const defaultOptions = {
    attachmentIdPropName: 'attachment_uuid'
}

/**
 * Compares attachments to form saved in cache and adds/removes attachments as Blobs.
 * @typedef {import("./BaseCache").default} BaseCache
 * @param {BaseCache} cache
 * @param {string} documentId
 * @param {object[]} attachments
 * @param {ProcessAttachmentsOptions} options
 */
export async function processAttachments(cache, documentId, attachments, options = defaultOptions) {
    const attachmentIdProp = options.attachmentIdPropName;

    const doc = await cache.get(documentId);
    const docAttachments = doc?._attachments || {};
    const docAttachmentIds = Object.keys(docAttachments).filter(id => !id.includes('offline:'));

    const newAttachmentIds = attachments.map(a => a[attachmentIdProp]).filter(id => !id.includes('offline:'));
    const newAttachmentsMap = attachments.reduce((map, att) => {
        map[att[attachmentIdProp]] = att;
        return map;
    }, {});

    const [idsToCreate, idsToDelete, existingAttachmentIds] = compareLists(docAttachmentIds, newAttachmentIds);

    for (const id of idsToDelete) {
        await cache.deleteAttachment(documentId, id);
    }

    const blobData = await cache.downloadAttachments(idsToCreate, newAttachmentsMap);

    for (const {attachmentId, blob, mimeType} of blobData) {
        await cache.cacheAttachment(documentId, attachmentId, blob, mimeType);
    }

    // Make sure existing attachment IDs have object URLs
    for (const attachmentId of existingAttachmentIds) {
        if (!docAttachmentIds.includes(attachmentId)) {
            // Shouldn't get here, but being safe
            continue;
        }
        
        if (attachmentId in cache.blobs) {
            // Already have an object URL for this attachment
            continue;
        }

        // Get attachment and create object URL, then save in blobs object
        const blob = await cache.db.getAttachment(documentId, attachmentId);
        if (blob) {
            cache.blobs = {
                ...cache.blobs,
                [attachmentId]: {
                    blob,
                    url: URL.createObjectURL(blob)
                }
            };
        }
    }
}

export function isOfflineRoute() {
    return window.location.pathname.includes('/offline/');
}

async function uploadAttachmentUsingPreSignedUrl(params, attachment, blob) {
    const response = await get_presigned_url(JSON.stringify(params));
    const {url, fields} = await response.json();

    const formData = new FormData();

    Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
    });

    formData.append('file', blob);

    const uploadResponse = await axios.post(url, formData);

    const newAttachment = {
        ...attachment,
        file_path: fields.key,
        public_url: `${url}/${fields.key}`,
    };

    delete newAttachment['attachment_uuid'];

    return newAttachment;
}

export async function uploadSubmissionAttachment(submission, field, attachment, blob) {
    const params = {
        feature_key: 'forms_submission',
        form_uuid: submission.form.form_uuid,
        field_uuid: field.id,
        content_type: attachment.mime_type,
        file_name: attachment.file_name,
        embedded: false
    };

    return uploadAttachmentUsingPreSignedUrl(params, attachment, blob);
}


/**
 * 
 * @param {string} postId 
 * @param {Attachment} attachment 
 * @param {Blob} blob 
 * @returns {Attachment}
 */
export async function uploadPostAttachment(postId, attachment, blob) {
    const params = {
        feature_key: "bulletin_post",
        post_uuid: postId,
        content_type: attachment.mime_type,
        file_name: attachment.file_name,
        embedded: false,
    };

    return uploadAttachmentUsingPreSignedUrl(params, attachment, blob);
}

export function replaceAttachmentUrlWithBlob(attachment, blobs) {
    try {
        const attachmentId = attachment?.attachment_uuid;
        if (attachmentId in blobs) {
            const blobAttachment = {...attachment};
            blobAttachment.public_url = blobs[attachmentId].url;
            return blobAttachment;
        } else {
            return attachment;
        }
    } catch (err) {
        return attachment;
    }
}