import { Alert } from "antd";
import { AlertOutlined } from '@ant-design/icons';
import { titleCase } from "other/Helper";
import { useZeroContext } from "components/ZeroContext";
import Button from "components/Shared/Button";
import { useEffect, useState } from "react";
import { usePostIsSyncing } from "hooks/offlineHooks";
import { actions } from "./reducer";

function simpleProxy(value, setValue) {
    return {
        get value() {
            return value;
        },
        set value(newValue) {
            setValue(newValue);
        }
    }
}

function useStateProxy(initialValue) {
    const [internalValue, setInternalValue] = useState(initialValue);
    const [proxy, setProxy] = useState(simpleProxy(internalValue, setInternalValue));
    
    useEffect(() => {
        setProxy(simpleProxy(internalValue, setInternalValue));
    }, [internalValue, setInternalValue]);

    return proxy;
}

export default function PostFormSyncError({state, dispatch}) {
    const {isOffline, services} = useZeroContext();
    const postService = services.post;

    const isSyncing = usePostIsSyncing(postService);

    const isLoading = useStateProxy(false);

    if (!state.$meta?.error) {
        return null;
    }

    const retrySync = async () => {
        if (isOffline) {
            return;
        }

        isLoading.value = true;
        const {success, localPost} = await postService.syncLocalPostToRemote(state.postUuid, {ignorePrevSyncError: true});
        if (success) {
            dispatch([actions.UPDATE_STATE, {
                $meta: localPost.$meta,
            }])
        }
        isLoading.value = false;
    }

    /** @type {LocalPost['$meta']['error']} */
    const error = state.$meta.error;

    let descriptionText;

    switch (error.type) {
        case 'submit':
            descriptionText = 'Post could not be submitted. Please review your post below, correct any errors, and try again.';
            break;
        case 'create':
            descriptionText = 'Post could not be created.';
            break;
        case 'update':
            descriptionText = 'Post could not be updated.';
            break;
        case 'delete':
            descriptionText = 'Post could not be deleted.';
            break;
        default:
            descriptionText = 'There was an issue syncing the post.';
    }

    const fullDescription = (
        <>
            <p>{descriptionText}</p>
            <Button type="primary" small loading={isLoading.value} disabled={isSyncing} onClick={retrySync}>Retry Sync</Button>
        </>
    )

    return (
        <Alert
            // className="ant-alert-message-no-bottom-margin"
            message={<span className="text-semibold">Sync Error ({titleCase(error.type)})</span>}
            description={fullDescription}
            type="warning"
            showIcon
            icon={<AlertOutlined />}
        />
    );
}
