import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

import {get_bulletin_categories_analytics, notifyError} from '../../api/zero-api.js'
import safe_get from '../../other/SafeGet';

import {DatabaseOutlined} from '@ant-design/icons';

import * as feedHelperActions from '../../store/actions/FeedHelperActions';
import AnalyticsBox from './AnalyticsBox.js';

class CategoryAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            loading: true,
            showChart: false,
        };

        this.redirectFeed = this.redirectFeed.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.date_query !== prevProps.date_query) || this.props.feed_query !== prevProps.feed_query) {
            this.getAnalytics(this.props.feed_query, this.props.date_query);
        }
    }

    componentDidMount() {
        this.getAnalytics(this.props.feed_query, this.props.date_query);
    }

    getAnalytics = (feed_query = "", date_query = "") => {
        var self = this;
        var query = "";

        if (date_query === "") {
            date_query = "&period=all_time";
        }

        if (this.props.isMyPostsFeed) {
            var filters = feed_query.split("?team_uuid=" + this.props.team_uuid);
            if (filters.length === 2) {
                filters = filters[1]
            } else {
                filters = "";
            }
            query = "?user_uuid=" + this.props.user.uuid + filters + date_query;
        } else {
            query = feed_query + date_query;
        }

        this.setState({loading: true});

        get_bulletin_categories_analytics(query).then(function (success) {
            success.json().then(success => {
                var count = 0;
                var categories = safe_get(success, "categories", []).sort(function (a, b) {
                    return a.value < b.value ? 1 : -1
                });

                self.setState({
                    categories: categories,
                    loading: false
                });
            });
        }, function (error) {
            notifyError(error);
            self.setState({loading: false});
        });
    }

    redirectFeed(query) {
        // var final_query = "?team_uuid=" + this.props.team_uuid; 
        var final_query = this.props.feed_query;

        if (!final_query.includes(query)) {
            final_query += query;
        }

        if (this.props.isMyPostsFeed && !final_query.includes("my_posts_only=1")) {
            final_query += "&my_posts_only=1";
        } else if (!final_query.includes("hide_shared=1")) {
            final_query += "&hide_shared=1";
        }


        this.props.dispatch(feedHelperActions.update_feed_query(final_query));
        this.props.dispatch(feedHelperActions.update_feed_tab("posts"));
        this.props.history.push("/" + this.props.org_uuid + "/home/team/" + this.props.team_uuid + "/feed");

        // WAIT FOR DISPATCH
        setTimeout(() => {
            this.props.updateFeed();
        }, 50);

    }

    render() {
        return (
            <AnalyticsBox
                fieldName={this.props.org.post_field_names.category}
                Icon={DatabaseOutlined}
                loading={this.state.loading}
                items={this.state.categories.map(category => ({
                    id: category.category_uuid,
                    label: category.name,
                    value: category.value,
                    badge: category.leading_indicator && "Leading",
                    onClick: () => this.redirectFeed("&category_uuid=" + category.category_uuid),
                }))}
            />
        )
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        org: safe_get(store, "org_helper.organization", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", "undefined"),
        feed_categories: safe_get(store, "feed_helper.categories", []),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", "")

    }
}

export default withRouter(connect(mapStateToProps)(CategoryAnalytics));

