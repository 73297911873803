import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {UserAddOutlined} from '@ant-design/icons';

import UserPopoverCard from 'components/Shared/UserPopoverCard';
import RespondersPopover from 'components/Shared/RespondersPopover';
import {safeProfilePic} from 'other/Helper';
import {updateDesk} from 'store/slices/manageDesks';

export default function DeskAssignedUsers({desk}) {
    const [assignedUsers, setAssignedUsers] = useState([]);
    const manageDesksState = useSelector(state => state.manageDesks);
    const {directory} = manageDesksState;
    const dispatch = useDispatch();

    const usersToShow = 39;

    useEffect(() => {
        setAssignedUsers(
            Array.from(desk.assigned_users).sort((a, b) => {
                let a_name = a.first_name + " " + a.last_name;
                let b_name = b.first_name + " " + b.last_name
                return a_name.toLowerCase() > b_name.toLowerCase() ? 1 : -1
            })
        );
    }, [desk.assigned_users]);

    const onChangeAssignments = userIds => {
        const payload = {
            uuid: desk.uuid,
            body: {
                assigned_user_uuids: userIds
            }
        }
        dispatch(updateDesk(payload));
    }

    return (
        <>
            {
                assignedUsers.slice(0, usersToShow).map((user, index) => (
                    <UserPopoverCard
                        key={index}
                        user={user}
                        subscribers={assignedUsers}
                        showRemoveAssignment={true}
                        updateSubscribers={onChangeAssignments}
                    >
                        <span className="hover-cursor">
                            {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                marginRight: "5px",
                                display: "inline-block"
                            })}
                        </span>
                    </UserPopoverCard>
                ))
            }
            <RespondersPopover
                title="Assign to Desk"
                available_responders={directory}
                selected_responders={assignedUsers}
                updateResponders={onChangeAssignments}
            >
                {
                    assignedUsers.length > usersToShow &&
                    <span>
                            <div className={"default-avatar-circle bulletin"}
                                 style={{marginRight: "5px", display: "inline-block", border: "1px solid #505050"}}>
                                <p className="initials"
                                   style={{color: "#505050"}}>+{assignedUsers.length - usersToShow}</p>
                            </div> 
                        </span>
                }
                {
                    assignedUsers.length <= usersToShow &&
                    <button className="ButtonLink post-details-content mar-top-5"
                            style={{verticalAlign: "bottom", height: "26px", width: "26px"}}>
                        <UserAddOutlined style={{fontSize: "19px"}}/>
                    </button>
                }
            </RespondersPopover>
        </>
    )
}