import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';

import useOfflineMode from "./useOfflineMode";

import styles from './OfflineAlert.module.css';

export default function OfflineAlert() {
    const location = useLocation();
    const onOfflinePage = location.pathname.includes('/offline/');
    const onSubmissionPage = location.pathname.includes('/submission/') && !location.pathname.includes('/courses/') && !window.location.search.includes("edit_submission=true");
    const onNewPostPage = location.pathname.includes('/feed/new_post') || location.pathname.includes('/feed/post_submitted');
    const {isOffline} = useOfflineMode();
    let offlineAllowed = useSelector(state => state?.org_helper?.organization?.feature_flags?.offline);
    const orgId = useSelector(state => state.org_helper.organization.organization_uuid);
    const [isDismissed, setIsDismissed] = useState(false);

    useEffect(() => {
        setIsDismissed(false);
    }, [isOffline, setIsDismissed]);

    const handleDismissClick = () => {
        setIsDismissed(true);
    }

    const alertClassNames = [styles.alertOffline];
    let message = null;

    if (isDismissed) {
        return null;
    }

    if (isOffline && !onOfflinePage && !onSubmissionPage && !onNewPostPage) {
        //offline enabled
        if (!offlineAllowed || location.pathname.includes('/kiosk_mode')) {
            message = (
                <>
                    <div className={styles.messageHeader}>
                        <div>You're offline. Trying to reconnect...</div>
                    </div>
                </>
            );
        } else { // offline feature setting disabled
            message = (
                <>
                    <div className={styles.messageHeader}>
                        <div>You're offline. Trying to reconnect...</div>
                    </div>
                    <br/>
                    <Link to={`/${orgId}/offline/dashboard`} className={styles.link}>Use ZERO in offline mode.</Link>
                </>
            );
        }
    } else if (!isOffline && onOfflinePage && !onNewPostPage) {
        message = (
            <>
                <div className={styles.messageHeader}>
                    <div><CheckCircleFilled className={styles.icon}/>You are online.</div>
                    <CloseOutlined onClick={handleDismissClick}/>
                </div>
                <br/>
                <Link to={`/${orgId}/home/dashboard`} className={styles.link}>Switch to Online Mode.</Link>
            </>
        );
        alertClassNames.push(styles.alertReconnected)
    }

    if (!message) {
        return null;
    }

    return (
        <>
            {(isOffline && !onOfflinePage && !onSubmissionPage && !onNewPostPage) &&
                <div className={styles.mask}></div>
            }
            <div className={alertClassNames.join(' ')}>{message}</div>
        </>
    )
}