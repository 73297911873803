import React, {Component} from 'react';
import {withRouter} from 'react-router';

import {connect} from 'react-redux'

import {getArticle, updateArticle, get_list_teams, notifyError} from '../../api/zero-api.js'
import {
    safe_get,
    getErrorMessage,
    fieldIsNotEmpty,
    mainContentContainerClass,
    showFooterMobileApp,
    hideFooterMobileApp,
    isAdmin,
    isTeamLead
} from '../../other/Helper.js'

import NotificationAlert from '../../other/NotificationAlert';

import {Select} from 'antd';

import TextEditor from './TextEditor.js'
import AttachmentBox from './AttachmentBox.js'
import InfoIconComponent from '../InfoIconComponent.js';
import ButtonLoading from '../Shared/ButtonLoading.js';
import LoadingIndicator from '../Shared/LoadingIndicator';

import '../../assets/css/Library.css';

const Option = Select.Option;

class EditPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingTeams: true,
            tags: [],
            article: {},
            articleTitle: "",
            attachments: [],
            existing_files: null,
            embeddedAttachments: [],
            fileList: [],
            finalFileList: [],
            team: {},
            user_teams: [],
            editable_team_uuids: [],
            all_selected_team_uuids: [],
            all_teams: false
        };
        this.discard = this.discard.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTagSelection = this.handleTagSelection.bind(this);
        this.handleTagDeselect = this.handleTagDeselect.bind(this);
        this.updateAttachments = this.updateAttachments.bind(this);
        this.updateEmbeddedAttachments = this.updateEmbeddedAttachments.bind(this);
        this.removeEmbeddedAttachment = this.removeEmbeddedAttachment.bind(this);
        // this.format_attachments = this.format_attachments.bind(this);
    }

    componentWillUnmount() {
        showFooterMobileApp();
    }

    componentDidMount() {
        var self = this;

        window.scrollTo(0, 0);
        hideFooterMobileApp();

        if (isAdmin(this.props.user)) {
            this.getTeams();
        } else {
            this.setState({user_teams: this.props.teams, loadingTeams: false}, () => {
                this.getArticle();
            });
        }
    }

    getTeams() {
        var self = this;
        get_list_teams().then(function (success) {
            success.json().then(success => {

                var teams = safe_get(success, "teams", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                });

                self.setState({
                    user_teams: teams,
                    loadingTeams: false,
                }, () => {
                    self.getArticle();
                });
            })
        }, function (error) {
            notifyError(error);
        });
    }

    getArticle = () => {
        let self = this;
        getArticle(this.props.match.params.article_uuid).then(function (success) {
            success.json().then(success => {
                var article = safe_get(success, "article", {});
                let teams = safe_get(article, "teams", []);

                let user_teams = [...self.state.user_teams].map(team => team.uuid);

                let all_selected_team_uuids = [];
                let editable_team_uuids = [];

                if (teams.length > 0) {
                    teams.forEach(team => {
                        all_selected_team_uuids.push(team.team_uuid);
                        if (user_teams.includes(team.team_uuid)) {
                            editable_team_uuids.push(team.team_uuid)
                        }
                    });
                } else if (teams.length === 0) {
                    // OLD PREEXISTING ARTICLES NEED TO BE POPULATED WITH ALL TEAMS
                    all_selected_team_uuids = user_teams;
                    editable_team_uuids = user_teams;
                }

                self.setState({
                    article: article,
                    articleTitle: article.title,
                    attachments: safe_get(article, "attachments", []),
                    tags: safe_get(article, "tags", []),
                    editable_team_uuids: editable_team_uuids,
                    all_selected_team_uuids: all_selected_team_uuids,
                    loading: false,
                });

            });
        }, function (error) {
            notifyError(error);
        });
    }

    onTitleChange = (e) => {
        this.setState({articleTitle: e.target.value});
    }

    updateAttachments(attachments) {
        this.setState({attachments: attachments});
    }

    updateEmbeddedAttachments(file) {
        var embeddedAttachments = this.state.embeddedAttachments
        embeddedAttachments.push(file)
        this.setState({embeddedAttachments: embeddedAttachments})
    }

    removeEmbeddedAttachment(src) {
        var attachments = this.state.embeddedAttachments
        for (var i in attachments) {
            var file = attachments[i]
            if (file.public_url === src) {
                attachments.splice(i, 1);
                this.setState({embeddedAttachments: attachments})
                break;
            }
        }
    }

    handleTagInput = (e) => {
        let value = e.target.value;
        if (value.length > 29) {
            e.preventDefault();
            return;
        }
    }

    handleTagSelection(value) {
        this.setState({tags: value});
    }

    handleTagDeselect(value) {
        let tags = [...this.state.tags];
        var index = tags.indexOf(value);
        tags.splice(index, 1);
        this.setState({tags: tags});
    }

    handleTeamSelect = (value) => {
        this.setState({editable_team_uuids: value});
    }

    selectAllTeams = () => {
        let team_uuids = [...this.state.user_teams].map(team => team.uuid);
        this.setState({editable_team_uuids: team_uuids, all_teams: true});
    }

    selectNoTeams = () => {
        this.setState({editable_team_uuids: [], all_teams: false});
    }

    handleSubmit() {
        var self = this;

        this.setState({saving: true, titleError: null, tagsError: null});

        let article_uuid = this.props.match.params.article_uuid;
        let article_title = this.state.articleTitle;
        var tags = this.state.tags
        var attachments = [...this.state.attachments];
        var textEditorContent = this.refs.TextEditor.state.content;
        var content = fieldIsNotEmpty(textEditorContent) ? textEditorContent : this.state.article.body;

        let team_uuids = isAdmin(this.props.user) ? [...this.state.editable_team_uuids] : [...new Set(this.state.editable_team_uuids.concat(this.state.all_selected_team_uuids))];

        var body = JSON.stringify({
            article_uuid: article_uuid,
            title: article_title,
            body: content,
            tags: tags,
            attachments: attachments,
            team_uuids: team_uuids,
            all_teams: this.state.all_teams
        });

        updateArticle(article_uuid, body).then(function (success) {
            success.json().then(success => {
                NotificationAlert("success", "", "Article updated.")
                self.setState({saving: false});
                self.props.history.goBack();
            });
        }, function (error) {
            self.setState({saving: false});
            if (error.status === 422) {
                error.json().then(error => {
                    let field = error.error_messages[0].field;
                    let errorMessage = getErrorMessage(error, "An error occured.");
                    if (field === "title") {
                        self.setState({titleError: errorMessage})
                    } else if (field === "tags") {
                        self.setState({tagsError: errorMessage});
                    } else if (field === "team_uuids") {
                        self.setState({teamsError: errorMessage});
                    }
                });
            } else {
                NotificationAlert('error', 'Oops!', "An unexpected error occured.");
            }
        });

    }

    discard() {
        this.props.history.goBack();
    }

    render() {
        let article_uuid = safe_get(this.state, "article.uuid", this.props.match.params.article_uuid)
        let loading = this.state.loading || this.state.loadingTeams

        return (
            <div className={mainContentContainerClass()}>
                <div id="page-content" className="no-padding-mobile" style={{paddingTop: "10px"}}>
                    {
                        loading &&
                        <LoadingIndicator/>
                    }

                    <div style={{display: (loading ? "none" : "block")}}>
                        <div className="panel thin-border">
                            <div className="panel-body pad-15">
                                <h3 className="titles"
                                    style={{marginTop: "0px", marginLeft: "0px", marginBottom: "8px"}}>Title</h3>
                                <div className="form-group" style={{marginBottom: "0px"}}>
                                    <input
                                        type="text"
                                        placeholder="Add a title..."
                                        className="form-control input-lg custom-placeholder thin-border"
                                        maxLength="100"
                                        value={this.state.articleTitle}
                                        onChange={this.onTitleChange}
                                    />
                                    <small className="pull-right">{100 - this.state.articleTitle.length}</small>
                                    {
                                        this.state.titleError &&
                                        <small className="error"
                                               style={{marginLeft: '5px'}}>{this.state.titleError}</small>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="panel thin-border">

                            <div className="panel-body pad-15">
                                <h3 className="titles"
                                    style={{marginTop: "0px", marginLeft: "0px", marginBottom: "8px"}}>Teams</h3>
                                {/* <InfoIconComponent
                                    position={"right"}
                                    width={"160px"}
                                    text={"You can add up to two topics. To create a new topic, type it below and hit enter."}
                                /> */}
                                <Select
                                    mode="multiple"
                                    style={{width: '100%'}}
                                    placeholder="Select team(s)..."
                                    onChange={this.handleTeamSelect}
                                    value={this.state.editable_team_uuids}
                                    className="library-tags"
                                >
                                    {
                                        (isAdmin(this.props.user) || isTeamLead(this.props.user)) &&
                                        <Option disabled>
                                            <span className="blue-link"
                                                  onClick={this.selectAllTeams}>Select All</span> &bull; <span
                                            className="blue-link" onClick={this.selectNoTeams}>Select None</span>
                                        </Option>
                                    }
                                    {
                                        this.state.user_teams.map((team, index) => (
                                            <Option key={team.uuid} value={team.uuid}>{team.name}</Option>
                                        ))
                                    }
                                </Select>
                                {
                                    this.state.teamsError &&
                                    <small className="error">{this.state.teamsError}</small>
                                }
                            </div>
                        </div>

                        <div className="panel thin-border">

                            <div className="panel-body pad-15">
                                <h3 className="titles"
                                    style={{marginTop: "0px", marginLeft: "0px", marginBottom: "8px"}}>Topics</h3>
                                <InfoIconComponent
                                    position={"right"}
                                    width={"160px"}
                                    text={"You can add up to two topics. To create a new topic, type it below and hit enter."}
                                />
                                <Select
                                    mode="tags"
                                    style={{width: '100%'}}
                                    placeholder="Select topics"
                                    onChange={this.handleTagSelection}
                                    onDeselect={this.handleTagDeselect}
                                    onInputKeyDown={this.handleTagInput}
                                    value={this.state.tags}
                                    className="library-tags"
                                    maxTagTextLength={30}
                                >
                                    {
                                        this.props.library_tags.map((tag, index) => (
                                            <Option key={tag}>{tag}</Option>
                                        ))
                                    }
                                </Select>
                                {
                                    this.state.tagsError &&
                                    <small className="error">{this.state.tagsError}</small>
                                }
                            </div>
                        </div>

                        <div className="panel thin-border">
                            <div className="panel-body" style={{padding: "15px"}}>
                                <TextEditor
                                    ref="TextEditor"
                                    user_uuid={this.props.user.uuid}
                                    article_uuid={article_uuid}
                                    article_content={this.state.article.body}
                                    addEmbeddedAttachment={this.updateEmbeddedAttachments}
                                    removeEmbeddedAttachment={this.removeEmbeddedAttachment}/>
                            </div>

                        </div>

                        {
                            !loading &&
                            <AttachmentBox
                                ref="AttachmentBox"
                                attachments={this.state.attachments}
                                user_uuid={this.props.user.uuid}
                                article_uuid={article_uuid}
                                update_attachments={this.updateAttachments}
                            />
                        }

                        <div className="panel clear">
                            <div className="row">
                                <div className="col-xs-6">
                                    <button className="btn btn-primary btn-block discard-article"
                                            onClick={this.discard}>Discard Changes
                                    </button>
                                </div>
                                <div className="col-xs-6">
                                    <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>
                                        {
                                            this.state.saving ? <ButtonLoading/> : "Update Article"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );

    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        library_tags: safe_get(store, "library.topics", []),
    }
}

export default withRouter(connect(mapStateToProps)(EditPost));

