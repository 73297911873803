import React, {Component} from 'react';

import {Modal} from 'antd';
import ButtonLoading from '../Shared/ButtonLoading.js';
import '../../assets/css/antd-custom.css'

class ExportPostModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.confirmExport = this.confirmExport.bind(this);

    }

    componentDidMount() {

    }

    confirmExport() {
        this.setState({exportingPost: true, postExportFinished: false});
        this.props.confirm()
    }


    render() {
        return (
            (<Modal
                title="Export post"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.confirmExport}>
                            {
                                this.state.exportingPost ? <ButtonLoading/> : "Export"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">Export this post to PDF.</p>
            </Modal>)
        );
    }

}

export default ExportPostModal;