import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {get_filter_options, get_team_promise, get_my_teams_data, update_team_cookie} from '../../api/zero-api'
import {isAdmin, safe_get} from '../../other/Helper.js';

import {update_current_team} from '../../store/actions/TeamsActions';
import * as feedHelperActions from '../../store/actions/FeedHelperActions';

import TimePeriodFilter from '../TimePeriodFilter.js';

import {Select, Divider} from 'antd';
import {CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleTwoTone} from '@ant-design/icons';

import LocationFilter from 'components/Shared/LocationFilter';

class BulletinSearchFilter extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            optionsLoading: true,
            tags: [],
            categories: [],
            sub_status_options: [],
            sub_status_2_options: [],
            risk_levels: [],
            date_range_selected: "all_time",
            selected_filters: [],
            selected_team: "my_teams",
            search_queries: [],
            filter_query: "",
            selected_filters_UI: [],
            period_query: this.props.period_query,
            location: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.feed_tab !== prevProps.feed_tab) {
            this.addPrefilters()
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.onLocationSelected) this.props.onLocationSelected('');
    }

    componentDidMount() {
        this._isMounted = true;
        var self = this;

        get_filter_options().then(function (success) {
            success.json().then(success => {

                let newState = {};

                let filters = safe_get(success, "filters", []);

                let categories = filters.find(function (obj) {
                    return obj.filter_key === "category_uuid";
                });

                if (categories) {
                    categories = categories.options.filter(function (category) {
                        return category.enabled
                    });
                    newState["categories"] = categories;
                }

                self.props.dispatch(feedHelperActions.update_feed_categories(categories))

                let tags = filters.find(function (obj) {
                    return obj.filter_key === "tag_uuid";
                });

                if (tags) {
                    tags = tags.options.filter(function (tag) {
                        return tag.enabled && tag.value !== -1
                    });
                    newState["tags"] = tags;
                }

                self.props.dispatch(feedHelperActions.update_feed_tags(tags));


                let sub_status = filters.find(function (obj) {
                    return obj.filter_key === "sub_status_uuid";
                });

                if (sub_status) {
                    sub_status = sub_status.options.filter(function (status) {
                        return status.enabled && status.value !== -1
                    });
                    newState["sub_status_options"] = sub_status;
                }

                self.props.dispatch(feedHelperActions.update_feed_statuses(sub_status, 1));

                const sub_status_2 = filters
                    .find(({filter_key}) => filter_key === 'sub_status_2_uuid')
                    ?.options.filter(status => status.enabled && status.value !== -1);
                if (sub_status_2) {
                    newState["sub_status_2_options"] = sub_status_2;
                }
                self.props.dispatch(feedHelperActions.update_feed_statuses(sub_status_2 ?? [], 2));

                let risk_levels = filters.find(function (obj) {
                    return obj.filter_key === "severity_level";
                });

                if (risk_levels.options) {
                    newState["risk_levels"] = risk_levels.options;
                }

                newState["optionsLoading"] = false;

                if (self._isMounted) {
                    self.setState(newState, () => {
                        self.addPrefilters();
                    });
                }
            });
        }, function (error) {
            console.log(error);
        });

    }

    addPrefilters = () => {
        var query = decodeURIComponent(this.props.feed_query + this.props.period_query);
        var filters = query.substring(1).split("&");

        filters = filters.filter(function (n) {
            return n !== "" && n
        });

        var selected_team = this.props.team_uuid;

        var selected_filters = [];
        var selected_filters_UI = [];

        for (const f of filters) {
            var filter = f.split("=");
            var filter_type = filter[0];
            var filter_value = filter[1] || "";
            let filter_values = filter_value.split(",");
            let search_filter_values = filter_value.split("|");

            switch (filter_type) {
                case "status":
                    filter_values.forEach(status => {
                        selected_filters.push("status=" + status);
                        selected_filters_UI.push("status=" + status);
                    });
                    break;
                case "category_uuid":
                    filter_values.forEach(uuid => {
                        selected_filters.push("category_uuid=" + uuid);
                        selected_filters_UI.push("category_uuid=" + uuid);
                    });
                    break;
                case "tag_uuid":
                    filter_values.forEach(uuid => {
                        selected_filters.push(`tag_uuid=${uuid}`);
                        selected_filters_UI.push(`tag_uuid=${uuid}`);
                    });
                    break;
                case "sub_status_uuid":
                    filter_values.forEach(uuid => {
                        selected_filters.push("sub_status_uuid=" + uuid);
                        selected_filters_UI.push("sub_status_uuid=" + uuid);
                    });
                    break;
                case "sub_status_2_uuid":
                    filter_values.forEach(uuid => {
                        selected_filters.push("sub_status_2_uuid=" + uuid);
                        selected_filters_UI.push("sub_status_2_uuid=" + uuid);
                    });
                    break;
                case "severity_level":
                    filter_values.forEach(uuid => {
                        selected_filters.push("severity_level=" + uuid);
                        selected_filters_UI.push("severity_level=" + uuid);
                    });
                    break;
                case "search":
                    search_filter_values.forEach(search => {
                        selected_filters.push(search);
                        selected_filters_UI.push(decodeURIComponent(search))
                    });
                    break;
                case "hide_shared":
                case "Hide Shared":
                    selected_filters.push("hide_shared=" + filter_value);
                    selected_filters_UI.push("hide_shared=" + filter_value);
                    break;
                case "team_uuid":
                    selected_team = filter_value;
                    break;
                case "my_posts_only":
                    selected_team = "my_posts";
                    break;
                case "my_stuff":
                    const myStuff = filter_values.at(-1);
                    selected_filters.push("my_stuff=" + myStuff);
                    selected_filters_UI.push("my_stuff=" + myStuff);
                    break;
                default:
                    break;
            }
        }

        this.setState({
            selected_team: selected_team,
            selected_filters: selected_filters,
            selected_filters_UI: selected_filters_UI
        });
    }

    updateFeed = () => {
        if (this.props.feed_view !== "table") {
            this.props.updateFeed();
        }
    }

    
    filterSelect = (/** @type {string[]} */ values) => {
        let selected_filters = [];
        let selected_filters_UI = [];

        const myStuffValues = values.filter(v => v.startsWith("my_stuff="));
        myStuffValues.pop();
        const filteredValues = values.filter(v => !myStuffValues.includes(v));

        filteredValues.forEach(v => {
            let filter = v
            if (!filter.includes("=")) {
                filter = "search=" + filter;
            }

            selected_filters.push(filter);
            selected_filters_UI.push(v);
        });

        this.setState({
            selected_filters: selected_filters,
            selected_filters_UI: selected_filters_UI
        });

        let status = [];
        let category = [];
        let tag = [];
        let sub_status = [];
        let sub_status_2 = [];
        let severity_level = [];
        let hide_shared = [];
        let search = [];
        let my_stuff = [];

        selected_filters.forEach(element => {
            if (element.includes("status=")) {
                status.push(element.split("=")[1])
            } else if (element.includes("category_uuid=")) {
                category.push(element.split("=")[1]);
            } else if (element.includes("tag_uuid=")) {
                tag.push(element.split("=")[1])
            } else if (element.includes("sub_status_uuid=")) {
                sub_status.push(element.split("=")[1])
            } else if (element.includes("sub_status_2_uuid=")) {
                sub_status_2.push(element.split("=")[1])
            } else if (element.includes("severity_level=")) {
                severity_level.push(element.split("=")[1])
            } else if (element.includes("hide_shared=")) {
                hide_shared.push(element.split('=')[1])
            } else if (element.includes("my_stuff=")) {
                my_stuff.push(element.split("=")[1]);
            } else {
                search.push(element.split('=')[1])
            }
        });

        const query = new URLSearchParams(this.props.feed_query);

        if (status.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }

        if (category.length > 0) {
            query.set('category_uuid', category.join(','));
        } else {
            query.delete('category_uuid');
        }

        if (tag.length > 0) {
            query.set('tag_uuid', tag.join(','));
        } else {
            query.delete('tag_uuid');
        }

        if (sub_status.length > 0) {
            query.set('sub_status_uuid', sub_status.join(','));
        } else {
            query.delete('sub_status_uuid');
        }

        if (sub_status_2.length > 0) {
            query.set('sub_status_2_uuid', sub_status_2.join(','));
        } else {
            query.delete('sub_status_2_uuid');
        }

        if (severity_level.length > 0) {
            query.set('severity_level', severity_level.join(','));
        } else {
            query.delete('severity_level');
        }

        if (search.length > 0) {
            // we are double encoding this because API Gateway decode this before sending it to the API
            // This addresses issue #ZP-452
            // query.set('search', encodeURIComponent(search.join('|')));
            query.set('search', search.join('|'));
        } else {
            query.delete('search');
        }

        if (hide_shared.length > 0) {
            query.set('hide_shared', hide_shared.at(-1));
        } else {
            query.delete('hide_shared');
        }

        if (my_stuff.length > 0) {
            query.set('my_stuff', my_stuff.at(-1));
        } else {
            query.delete('my_stuff');
        }

        this.props.dispatch(feedHelperActions.update_feed_query(`?${query}`));
        this.props.dispatch(feedHelperActions.update_feed_position(0));

        // WAIT FOR DISPATCH
        setTimeout(() => {
            this.updateFeed();
        }, 50);
    }

    handleTeamSelect = (team_uuid) => {
        this.get_team(team_uuid);
        this.setState({selected_team: team_uuid});

        const query = new URLSearchParams(this.props.feed_query);

        if (team_uuid === "my_posts") {
            query.set('team_uuid', 'my_teams');
            query.set('my_posts_only', '1');
        } else {
            query.set('team_uuid', team_uuid);
            query.delete('my_posts_only');
        }

        this.props.dispatch(feedHelperActions.update_feed_query(`?${query}`));
        this.props.dispatch(feedHelperActions.update_feed_position(0));

        // WAIT FOR DISPATCH
        setTimeout(() => {
            this.updateFeed();
        }, 50);
    }

    get_team = (team_uuid) => {
        var self = this;

        update_team_cookie(team_uuid);

        if (team_uuid === "my_teams" || team_uuid === "my_posts") {
            get_my_teams_data().then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var my_teams = safe_get(success, "team", {});
                        self.props.dispatch(update_current_team(my_teams));
                    }
                });
            }, function (error) {
                var no_team = {
                    uuid: "Unknown",
                    name: "Unknown",
                    description: "",
                    users_count: 0,
                }
                self.props.dispatch(update_current_team(no_team));
            });
        } else if (team_uuid === "organization_teams") {
            var team = {
                uuid: "organization_teams",
                name: "All Account Teams",
                description: "",
                users_count: 0,
            }
            self.props.dispatch(update_current_team(team));
        } else {
            get_team_promise(team_uuid).then(function (success) {
                success.json().then(success => {
                    if (self._isMounted) {
                        var team = safe_get(success, "team", {});
                        self.props.dispatch(update_current_team(team));
                    }
                });
            }, function (error) {
                var no_team = {
                    uuid: "Unknown",
                    name: "Unknown",
                    description: "",
                    users_count: 0,
                }
                self.props.dispatch(update_current_team(no_team));
            });
        }
    }

    periodSelected = (period) => {
        this.setState({period_query: period});
    }

    updatePeriodQuery = (query) => {
        this.props.dispatch(feedHelperActions.update_date_query(query));
        this.props.dispatch(feedHelperActions.update_feed_position(0));

        // WAIT FOR DISPATCH
        setTimeout(() => {
            this.updateFeed();
        }, 50);
    }

    onLocationSelected = (location) => {
        this.setState({location});
        if (this.props.onLocationSelected) this.props.onLocationSelected(location);

        const query = new URLSearchParams(this.props.feed_query);

        if (location) {
            query.set('location_uuid', location);
        } else {
            query.delete('location_uuid');
        }

        this.props.dispatch(feedHelperActions.update_feed_query(`?${query}`));
        this.props.dispatch(feedHelperActions.update_feed_position(0));

        setTimeout(() => {
            this.updateFeed();
        }, 50);
    }

    render() {
        return (
            <>
                <div>
                    <div className="filter-menu">
                        <div className="row print-feed-filter-wrapper" style={{padding: "0 0.5rem"}}>
                            {
                                this.props.feed_tab !== "members" &&
                                <Select
                                    loading={this.state.optionsLoading || this.props.loading}
                                    mode="tags"
                                    virtual={false}
                                    placeholder="Filter or search..."
                                    onChange={this.filterSelect}
                                    value={this.state.selected_filters_UI}
                                    filterOption={(inputValue, option) => {
                                        return safe_get(option, "option", "").toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    className="col-xs-12 col-sm-6 col-reduce-padding"
                                    style={{marginTop: '0.8rem'}}
                                >
                                    <Select.OptGroup label="My Stuff">
                                        <Select.Option value={"my_stuff=posts"}>
                                            My Posts (Created + Assigned)
                                        </Select.Option>
                                        <Select.Option value={"my_stuff=assignments"}>
                                            My Assignments
                                        </Select.Option>
                                    </Select.OptGroup>
                                    <Select.OptGroup label="Status">
                                        <Select.Option value={"status=open"} option={"open"}>
                                            <CloseCircleTwoTone twoToneColor={"#ccc"} className="mar-rgt-5"/>
                                            Open
                                        </Select.Option>
                                        <Select.Option value={"status=closed"} option={"closed"}>
                                            <CheckCircleTwoTone twoToneColor={"#52c41a"} className="mar-rgt-5"/>
                                            Closed
                                        </Select.Option>
                                        <Select.Option value={"status=overdue"} option={"overdue"}>
                                            <ExclamationCircleTwoTone twoToneColor="red" className="mar-rgt-5"/>
                                            Overdue
                                        </Select.Option>
                                    </Select.OptGroup>
                                    {
                                        this.state.categories.length > 0 &&
                                        <Select.OptGroup
                                            label={safe_get(this.props, "post_field_names.category", "Category")}>
                                            {
                                                this.state.categories.map((option, index) => (
                                                    <Select.Option key={option.value}
                                                                   value={"category_uuid=" + option.value}
                                                                   option={option.name}>
                                                        {option.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select.OptGroup>
                                    }
                                    {
                                        this.state.tags.length > 0 &&
                                        <Select.OptGroup label={safe_get(this.props, "post_field_names.tag", "Tag")}>
                                            <Select.Option value={"tag_uuid=-1"}>
                                                No tag
                                            </Select.Option>
                                            {
                                                this.state.tags.map((option, index) => (
                                                    <Select.Option key={option.value} value={"tag_uuid=" + option.value}
                                                                   option={option.name}>
                                                        {option.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select.OptGroup>
                                    }
                                    {
                                        this.state.sub_status_options.length > 0 &&
                                        <Select.OptGroup
                                            label={safe_get(this.props, "post_field_names.sub_status", "Status")}>
                                            <Select.Option value={"sub_status_uuid=-1"}>
                                                No status
                                            </Select.Option>
                                            {
                                                this.state.sub_status_options.map((option, index) => (
                                                    <Select.Option key={option.value}
                                                                   value={"sub_status_uuid=" + option.value}
                                                                   option={option.name}>
                                                        {option.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select.OptGroup>
                                    }
                                    {
                                        this.state.sub_status_2_options.length > 0 &&
                                        <Select.OptGroup
                                            label={safe_get(this.props, "post_field_names.sub_status_2", "Status 2")}>
                                            <Select.Option value={"sub_status_2_uuid=-1"}>
                                                No status
                                            </Select.Option>
                                            {
                                                this.state.sub_status_2_options.map((option, index) => (
                                                    <Select.Option key={option.value}
                                                                   value={"sub_status_2_uuid=" + option.value}
                                                                   option={option.name}>
                                                        {option.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select.OptGroup>
                                    }
                                    {
                                        this.state.risk_levels.length > 0 && this.props.risk_enabled &&
                                        <Select.OptGroup
                                            label={safe_get(this.props, "post_field_names.severity_level", "Risk Level")}>
                                            {
                                                this.state.risk_levels.map((option, index) => (
                                                    <Select.Option key={option.value}
                                                                   value={"severity_level=" + option.value}
                                                                   option={option.name}>
                                                        <span className="badge badge-icon badge-fw pull-left"
                                                              style={{backgroundColor: option.color}}/>
                                                        {option.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select.OptGroup>
                                    }
                                    <Select.OptGroup label="Shared Posts">
                                        <Select.Option value={"hide_shared=1"}>
                                            Hide Shared Posts
                                        </Select.Option>
                                    </Select.OptGroup>
                                </Select>
                            }
                            {
                                this.props.feed_tab === "members" &&
                                <Select
                                    value={this.state.peopleFilter}
                                    mode="tags"
                                    virtual={false}
                                    placeholder="Filter or search..."
                                    onChange={this.props.onChangePeopleFilter}
                                    showArrow={false}
                                    style={{marginTop: '0.8rem'}}
                                    className="col-xs-12 col-sm-6 col-reduce-padding"
                                >
                                    <Select.Option key={0} value={"admin"}>
                                        <span className="badge badge-success badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Admins
                                    </Select.Option>
                                    <Select.Option key={1} value={"team_lead"}>
                                        <span className="badge badge-info badge-icon "
                                              style={{margin: "0px 5px 2px 0px"}}/>Team Leads
                                    </Select.Option>
                                    <Select.Option key={2} value={"user"}>
                                        <span className="badge badge-purple badge-icon"
                                              style={{margin: "0px 5px 2px 0px"}}/>Members
                                    </Select.Option>
                                    <Select.Option key={3} value={"contributor"}>
                                        <span className="badge badge-warning badge-icon"
                                              style={{margin: "0px 5px 2px 0px"}}/>Contributors
                                    </Select.Option>
                                    <Select.Option key={4} value={"viewer"}>
                                        <span className="badge badge-danger badge-icon"
                                              style={{margin: "0px 5px 2px 0px"}}/>Viewers
                                    </Select.Option>
                                    {/* <Select.Option key={5} value={"pending"}>Invited/Pending</Select.Option> */}
                                </Select>
                            }
                            {
                                this.props.feed_tab !== "members" &&
                                <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                                    <div>
                                        <TimePeriodFilter noWrapperStyle={true} period_query={this.props.period_query}
                                                          updateQuery={this.updatePeriodQuery}
                                                          periodSelected={this.periodSelected}/>
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="row print-feed-filter-wrapper" style={{padding: "0 0.5rem"}}>
                            <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                                <LocationFilter
                                    style={{width: '100%'}}
                                    value={this.state.location}
                                    onLocationSelected={this.onLocationSelected}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-reduce-padding" style={{marginTop: '0.8rem'}}>
                                <Select
                                    value={this.state.selected_team}
                                    onChange={this.handleTeamSelect}
                                    virtual={false}
                                    style={{width: '100%'}}
                                >
                                    {
                                        isAdmin(this.props.user) &&
                                        <Select.Option value={"organization_teams"}>All Account Teams</Select.Option>
                                    }
                                    <Select.Option value={"my_teams"}>All My Teams</Select.Option>
                                    <Select.Option value={""} disabled>
                                        <hr style={{marginTop: "10px", marginBottom: "0px"}}/>
                                    </Select.Option>
                                    {
                                        this.props.teams.map((team, index) => (
                                            <Select.Option key={index} value={team.uuid}>{team.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>


                    </div>

                </div>
            </>
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        user: safe_get(store, "user.user", {}),
        post_field_names: safe_get(store, "org_helper.organization.post_field_names", {}),
        feed_tags: safe_get(store, "feed_helper.tags", []),
        feed_categories: safe_get(store, "feed_helper.categories", []),
        feed_tab: safe_get(store, "feed_helper.tab", ""),
        feed_query: safe_get(store, "feed_helper.query", ""),
        feed_view: safe_get(store, "feed_helper.feed_view", "posts"),
        period_query: safe_get(store, "feed_helper.date_query", ""),
        risk_enabled: safe_get(store, "org_helper.organization.risk_level_enabled", true)
    }
}

export default withRouter(connect(mapStateToProps)(BulletinSearchFilter));


