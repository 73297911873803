import { useEffect, useState } from "react";

import Button from "components/Shared/Button";
import { BorderedPanel, MainContent, PageContent, PageHead } from "components/Shared/PageParts";
import { useZeroContext } from "components/ZeroContext";
import { generateCombinedDiff, useCachedPostDrafts } from "services/postService";
import { getStoredLastSyncAt } from "./OfflineDataCaches";
import { LocalStorageHelper, dateFormatterWithTime } from "other/Helper";
import { Dropdown, Switch } from "antd";
import { useSelector } from "react-redux";
import {Link} from 'react-router-dom';

/**
 * @param {object} props
 * @param {LocalPost} props.post 
 */
function PostItem({post, reloadPosts}) {
    const zeroContext = useZeroContext();
    const postService = zeroContext.services.post;
    const [postDiff, setPostDiff] = useState(null);
    const [showRawData, setShowRawData] = useState(false);
    const orgId = useSelector(state => state.org_helper.organization.organization_uuid);
    const [forceErrorLoading, setForceErrorLoading] = useState(false);

    useEffect(() => {
        async function getDiff() {
            if (post.$meta.originalPost) {
                const diff = await postService.getDiffResultsForPost(post, post.$meta.originalPost);
                const combinedDiff = generateCombinedDiff(post, diff);
                setPostDiff(combinedDiff);
            } else {
                setPostDiff(null);
            }
        }

        getDiff();
    }, [post]);

    function renderProperty(propName) {
        return (<div><strong>{propName}:</strong> {post.$meta[propName] ? "yes" : "no"}</div>)
    }

    async function syncPost() {
        try {
            await postService.syncLocalPostToRemote(post._id);
            reloadPosts();
        } catch (err) {
            console.error(err);
        }
    }

    async function deletePost() {
        try {
            await postService.deletePostDraft(post._id, {reverse: post.$meta.deleted});
            reloadPosts();
        } catch (err) {
            console.error(err);
        }
    }

    async function forceDeletePost() {
        try {
            await postService.deletePostDraft(post._id, {force: true});
            reloadPosts();
        } catch (err) {
            console.error(err);
        }
    }

    const linkToEditPost = `/${orgId}/home/team/my_teams/feed/new_post/${post.post_uuid}`;

    const postTitle = post.title ? `${post.title} (${post._id})` : post._id;

    const items = [
        {
            label: 'Clear Error',
            key: 'clear'
        },
        {
            type: 'divider'
        },
        {
            label: 'Submit',
            key: 'submit'
        },
        {
            label: 'Create',
            key: 'create'
        },
        {
            label: 'Update',
            key: 'update'
        },
        {
            label: 'Delete',
            key: 'delete'
        },
        {
            label: 'Conflict',
            key: 'conflict'
        },
        {
            type: 'divider'
        },
        {
            label: 'Unkown',
            key: 'unknown'
        },
    ];

    async function onForceError({key}) {
        if (key === 'clear') {
            // clear error
            post.$meta.error = undefined;
        } else {
            post.$meta.error = {
                type: key
            };
            post.$meta.updated = true;
            if (key === 'submit') {
                post.$meta.submitted = true;
            } else if (key === 'delete') {
                post.$meta.deleted = true;
            }
        }
        setForceErrorLoading(true);
        await postService.saveLocalPost(post);
        setForceErrorLoading(false);
    }

    return (
        <div style={{border: '1px solid #eee', padding: '0.5rem', width: '100%'}}>
            <div>
                <Link to={linkToEditPost} className="blue-link text-bold">{postTitle}</Link>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "1rem", padding: "1rem 0"}}>
                <Button type="primary" small onClick={() => syncPost()}>Sync</Button>
                <Button type="danger" small onClick={() => deletePost()}>{post.$meta.deleted ? "Restore" : "Delete"}</Button>
                <Button type="danger" small onClick={() => forceDeletePost()}>Force Delete</Button>
                <Dropdown
                    menu={{ items, onClick: onForceError }}
                    trigger={["click"]}
                ><Button small disabled={forceErrorLoading}>Force Sync Error</Button></Dropdown>
            </div>
            {renderProperty("offline")}
            {renderProperty("updated")}
            {renderProperty("submitted")}
            {renderProperty("deleted")}
            <div><strong>error:</strong> {post.$meta.error ? post.$meta.error.type : "no"}</div>
            <div>
                <strong>raw data:</strong> <Button small onClick={() => setShowRawData(!showRawData)}>Toggle</Button>
                { showRawData && 
                    <>
                        <br />
                        <pre style={{overflow: "auto", maxHeight: "310px", margin: "0.5rem 1rem"}}>
                            {JSON.stringify(post, null, 2)}
                        </pre>
                    </>
                }
            </div>
            { postDiff &&
                <div>
                    <strong>original post data diff:</strong>
                    <br />
                    <pre style={{overflow: "auto", maxHeight: "310px", margin: "0.5rem 1rem"}}>
                        {JSON.stringify(postDiff, null, 2)}
                    </pre>
                </div>
            }
        </div>
    )
}

function PostList({posts, reloadPosts}) {
    if (posts.length === 0) {
        return <div>No locally-saved posts.</div>
    }

    const renderedPosts = posts.map(post => (<PostItem key={post._id} post={post} reloadPosts={reloadPosts} />));

    return (
        <div style={{display: "flex", gap: "1rem", flexDirection: "column"}}>
            {renderedPosts}
        </div>
    )
}

export default function OfflineDebugPage() {
    const zeroContext = useZeroContext();
    const postService = zeroContext.services.post;

    const {drafts: posts, refresh: reloadPosts} = useCachedPostDrafts(postService, {includeDeleted: true});
    const [showObjectUrls, setShowObjectUrls] = useState(false);
    const [showPostOptions, setShowPostOptions] = useState(false);
    const [postOptions, setPostOptions] = useState(null);
    const [postSyncEnabled, setPostSyncEnabled] = useState(LocalStorageHelper.get('post-sync-enabled', 'boolean', true));
    const orgId = useSelector(state => state.org_helper.organization.organization_uuid);

    let lastSyncAt = getStoredLastSyncAt();
    if (lastSyncAt > 0) {
        lastSyncAt = dateFormatterWithTime(lastSyncAt);
    } else {
        lastSyncAt = "Not Synced";
    }

    const onPostSyncEnabledChange = (checked) => {
        setPostSyncEnabled(checked);
        LocalStorageHelper.set('post-sync-enabled', checked);
    }

    useEffect(() => {
        postService.getPostOptions().then(options => {
            setPostOptions(options);
        });
    }, [postService]);

    return (
        <MainContent>
            <PageHead>
                <BorderedPanel>
                    <h3 className="zero-blue">Offline-Data Debug Page</h3>
                    <Link to={`/${orgId}/offline/dashboard`} className="blue-link text-bold">Go to Offline Dashboard</Link>
                </BorderedPanel>
            </PageHead>
            <PageContent>
                <BorderedPanel className="zero-blue">
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "1rem 0"}}>
                        <h4>Posts:</h4>
                        <Button type="primary" onClick={() => reloadPosts()}>Refresh</Button>
                    </div>
                    <div className="mar-btm-10" style={{display: "flex", flexDirection: "column", gap: '1rem'}}>
                        <div>
                            <strong>Last Sync At:</strong>&nbsp;{lastSyncAt}
                        </div>
                        <div>
                            <strong>Post Sync Enabled:</strong>&nbsp;<Switch checked={postSyncEnabled} onChange={onPostSyncEnabledChange} />
                        </div>
                        <div>
                            <strong>Object URLs:</strong>{" "}
                            <Button small onClick={() => setShowObjectUrls(!showObjectUrls)}>Toggle</Button>
                            { showObjectUrls &&
                                <pre style={{overflow: "auto", maxHeight: "310px", margin: "0.5rem 1rem"}}>
                                    { JSON.stringify(postService.objectUrls, null, 2) }
                                </pre>
                            }
                        </div>
                        <div>
                            <strong>Post Options:</strong>{" "}
                            <Button small onClick={() => setShowPostOptions(!showPostOptions)}>Toggle</Button>
                            { showPostOptions &&
                                <pre style={{overflow: "auto", maxHeight: "310px", margin: "0.5rem 1rem"}}>
                                    { postOptions ? JSON.stringify(postOptions, null, 2) : "Loading..." }
                                </pre>
                            }
                        </div>
                    </div>
                    <PostList posts={posts} reloadPosts={() => reloadPosts()} />
                </BorderedPanel>
            </PageContent>
        </MainContent>
    )
}