function TextConfig() {
};

// NOTE - DESC => Static Text Description

//
// AUTHENTICATION TEXT
//

TextConfig.LOGIN_HEADER = "Login to ZERO";

TextConfig.SIGNUP_EMAIL_DESC = "Please enter your email below to get started!";
TextConfig.VERIFY_EMAIL_DESC = "We've sent a confirmation code to your email. Enter it below.";
TextConfig.USER_INFO_DESC = "Please fill out your information to create your account.";

TextConfig.FORGOT_PW_DESC = "Please enter your email to reset your password.";
TextConfig.RESET_PW_DESC = "Enter a new password for your ZERO account.";

//
// BULLETIN TEXT
//

TextConfig.BULLETIN_FEED_DESC = "A place to share hazards in the workplace.";
TextConfig.SEVERITY_LEVEL_DESC = "Etiam efficitur lorem vel massa ornare tempor.";

//
// LIBRARY TEXT 
//

TextConfig.LIBRARY_DESC = "Here you can see x, y, z."

// 
// DASHBOARD TEXT
//

TextConfig.MANAGE_DESC = "Everyone below will be able to login to ZERO and collaborate with you in this team.";

// 
// CHAT TEXT
//

TextConfig.CHAT_DESC = ""

//
// MANAGE TAGS
//

TextConfig.TAGS_DESC = "Tags help create structure inside ZERO and give people ideas about what types of posts to make. You can customize your tags below. Please note: any changes you make will be applied to all teams and will affect your historical data";

module.exports = {

    //
    // AUTHENTICATION
    //
    login_text: function () {
        return TextConfig.LOGIN_HEADER;
    },

    signup_email_desc: function () {
        return TextConfig.SIGNUP_EMAIL_DESC;
    },

    verify_email_desc: function () {
        return TextConfig.VERIFY_EMAIL_DESC;
    },

    user_info_desc: function () {
        return TextConfig.USER_INFO_DESC;
    },

    forgot_password_desc: function () {
        return TextConfig.FORGOT_PW_DESC;
    },

    reset_password_desc: function () {
        return TextConfig.RESET_PW_DESC;
    },

    //
    // BULLETIN 
    //
    bulletin_feed_desc: function () {
        return TextConfig.BULLETIN_FEED_DESC;
    },

    severity_level_desc: function () {
        return TextConfig.SEVERITY_LEVEL_DESC;
    },

    //
    // LIBRARY
    //
    library_desc: function () {
        return TextConfig.LIBRARY_DESC;
    },

    //
    // DASHBOARD
    //
    manage_desc: function () {
        return TextConfig.MANAGE_DESC;
    },

    //
    // CHAT
    //
    chat_desc: function () {
        return TextConfig.CHAT_DESC;
    },

    tags_desc: function () {
        return TextConfig.TAGS_DESC
    }
}