import React from 'react';
import {useSelector} from 'react-redux';

import {actions} from './reducer';
import {PostFormTypes} from './PostForm';
import ButtonLoading from 'components/Shared/ButtonLoading';
import AutoCloseCheckbox from 'components/Bulletin/AutoCloseCheckbox';
import UrgentPostCheckbox from 'components/Bulletin/UrgentPostCheckbox';
import VoiceNotificationsCheckbox from 'components/Bulletin/VoiceNotificationsCheckbox';

export default function PostFormControls({state, dispatch, onCancel, onSavePost, formType}) {
    const user = useSelector(state => state.user.user);

    let confirmButtonText = 'Post';
    if (formType === PostFormTypes.EDIT_POST) {
        confirmButtonText = 'Update Post';
    }

    return (
        <div className="panel clear" style={{marginBottom: '5px', marginTop: "15px"}}>
            <div className="row">
                <div className="col-xs-6">
                    <button className="btn btn-discard btn-block" onClick={onCancel}>
                        {
                            state.discarding ? <ButtonLoading/> : "Cancel"
                        }
                    </button>
                </div>
                <div className="col-xs-6">
                    <button className="btn btn-primary btn-block" onClick={onSavePost}>
                        {
                            state.posting ? <ButtonLoading/> : confirmButtonText
                        }
                    </button>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginTop: '1rem',
                wordBreak: 'keep-all'
            }}>
                {(formType !== PostFormTypes.EDIT_POST) &&
                    <>
                        <AutoCloseCheckbox
                            checked={state.autoClose}
                            onChange={(event) => {
                                dispatch([actions.AUTO_CLOSE_CHANGED, event.target.checked])
                            }}
                        />
                        <UrgentPostCheckbox
                            user={user}
                            checked={state.isUrgent}
                            onChange={(event) => {
                                dispatch([actions.IS_URGENT_CHANGED, event.target.checked])
                            }}
                        />
                        <VoiceNotificationsCheckbox
                            user={user}
                            urgentChecked={state.isUrgent}
                            checked={state.voiceNotifications}
                            onChange={(event) => {
                                dispatch([actions.VOICE_NOTIFICATIONS_CHANGED, event.target.checked])
                            }}
                        />
                    </>
                }
            </div>
        </div>
    )
}