import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {
    safe_get, isAdmin, isTeamLead, safeProfilePic,
    dateFormatterWithTime, isViewer, isContributor
} from '../../other/Helper.js';

class UserViews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            resendText: "",
            resendSuccessful: false,
            showRemoveOptions: false,
        };

    }

    componentDidMount() {
        let user = this.props.user;

        var user_uuid = safe_get(user, "uuid", "")
        if (user_uuid === "") {
            user_uuid = safe_get(user, "user_uuid", "");
        }

        this.setState({
            user: user,
            user_uuid: user_uuid,
        });
    }

    render() {
        return (
            <div className="">
                <div className="panel panel-light panel-colorful user-card-size thin-border"
                     style={{maxHeight: this.props.showTimeStamp ? '100%' : '80px'}}>
                    <div style={{padding: "15px 15px 10px 15px"}}>
                        <div className="media">
                            <div className="media-left profile-img">
                                <Link to={"/" + this.props.org_uuid + "/users/" + this.state.user_uuid}>
                                    {safeProfilePic(this.state.user, "img-sm img-circle views", "views")}
                                </Link>
                            </div>
                            <div className="media-body account-card " style={{verticalAlign: 'middle'}}>

                                {
                                    this.props.user.status === "pending" &&
                                    <div>
                                        <span
                                            className="text-md text-semibold profile zero-blue">Awaiting Registration</span>
                                        <p className="text-sm profile title pending">Pending</p>
                                        {/* <br className="profile-space"></br> */}
                                    </div>
                                }

                                {
                                    this.props.user.status === "active" &&
                                    <div>
                                        <Link className="zero-blue"
                                              to={"/" + this.props.org_uuid + "/users/" + this.state.user_uuid}
                                              style={{lineHeight: "1em"}}>
                                            <p className="text-md text-semibold profile truncate name zero-blue"
                                               style={{
                                                   display: 'inline-block',
                                                   marginBottom: '0px',
                                                   marginLeft: '2px',
                                                   maxWidth: "145px"
                                               }}>
                                                {this.props.user.first_name} {this.props.user.last_name}
                                            </p>
                                        </Link>
                                        {
                                            isAdmin(this.props.user) ?
                                                <p className="role-text zero-dark-grey" style={{fontSize: "0.9em"}}>
                                                    <span className="badge badge-success badge-icon badge-fw "
                                                          style={{margin: "2px 5px 5px 2px",}}></span>Admin</p> :
                                                isTeamLead(this.props.user) ?
                                                    <p className="role-text zero-dark-grey" style={{fontSize: "0.9em"}}>
                                                        <span className="badge badge-info badge-icon badge-fw "
                                                              style={{margin: "2px 5px 5px 2px"}}></span>Lead</p> :
                                                    isViewer(this.props.user) ? <p className="role-text zero-dark-grey"
                                                                                   style={{fontSize: "0.9em"}}><span
                                                            className="badge badge-danger badge-icon badge-fw"
                                                            style={{margin: "2px 5px 5px 2px"}}></span>Viewer</p> :
                                                        isContributor(this.props.user) ?
                                                            <p className="role-text zero-dark-grey"
                                                               style={{fontSize: "0.9em"}}><span
                                                                className="badge badge-warning badge-icon badge-fw"
                                                                style={{margin: "2px 5px 5px 2px"}}></span>Contributor
                                                            </p> :
                                                            <p className="role-text zero-dark-grey"
                                                               style={{fontSize: "0.9em"}}><span
                                                                className="badge badge-purple badge-icon badge-fw "
                                                                style={{margin: "2px 5px 5px 2px"}}></span>Member</p>
                                        }
                                        <p className="text-sm profile title truncate title zero-dark-grey">{this.props.user.title}</p>
                                    </div>

                                }

                                <p className="text-sm profile truncate email zero-dark-grey">{this.props.user.email}</p>
                            </div>
                            {
                                this.props.showTimeStamp &&
                                <div>
                                    <hr style={{marginTop: '9px', marginBottom: '10px'}}/>
                                    <p className="text-xs profile pull-right zero-dark-grey"
                                       style={{marginBottom: '0px'}}>{dateFormatterWithTime(this.props.viewedAt)}</p>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default UserViews;