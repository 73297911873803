import { InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload } from "antd";
import useFileUploadManager from "hooks/attachments/useFileUploadManager";
import { createContext, useCallback, useContext, useEffect } from "react";
import AttachmentList from "./AttachmentList";
import {v4 as uuid4} from 'uuid';
import { fileUpload } from 'other/Helper';
import NotificationAlert from 'other/NotificationAlert';


export const FileUploadContext = createContext(null);

export function useFileUploadContext() {
    return useContext(FileUploadContext);
}

export function FileUploadContextManager({
    attachments,
    generateParamsCallback,
    onUploadSuccess,
    onUploadError,
    onShowDragChange,
    customUpload,
    options,
    children
}) {
    const uploadManager = useFileUploadManager(attachments, options);

    const {
        setError,
        addUploadingFile,
        removeUploadingFile,
        updateUploadProgress,
        showDragger
    } = uploadManager;

    useEffect(() => {
        onShowDragChange?.(showDragger);
    }, [showDragger]);

    const defaultErrorMessage = "Unable to upload file.";

    const handleFileUpload = useCallback(({file}) => {
        const params = generateParamsCallback(file);
        const serializedParams = typeof params === "string" ? params : JSON.stringify(params);
        const uid = file.uid || uuid4();

        addUploadingFile(uid);

        fileUpload(null, serializedParams, file, (progress) => {
            updateUploadProgress(uid, progress);
        }, (pre_signed_url, file_key) => {
            // handleAdd(file, pre_signed_url, file_key);
            removeUploadingFile(uid);
            NotificationAlert("", "", "File uploaded.");
            onUploadSuccess?.(file, pre_signed_url, file_key);
        }, (error) => {
            removeUploadingFile(uid);
            setError(defaultErrorMessage);
            onUploadError?.(error);
        });
    }, [generateParamsCallback, addUploadingFile, updateUploadProgress, removeUploadingFile, onUploadSuccess, setError, onUploadError]);

    const customFileUploadHandler = useCallback(async ({file}) => {
        if (typeof(customUpload) === "function") {
            const uid = uuid4();

            addUploadingFile(uid);

            try {
                await customUpload(file, (progress) => {
                    updateUploadProgress(uid, progress);
                });
            } catch (err) {
                if (typeof err === 'string') {
                    setError(err);
                } else {
                    setError(defaultErrorMessage)
                }
            } finally {
                removeUploadingFile(uid);
            }
        }
    }, [customUpload, addUploadingFile, setError, removeUploadingFile]);

    const contextValue = {
        ...uploadManager,
        attachments,
        generateParamsCallback,
        onUploadSuccess,
        onUploadError,
        handleFileUpload: customUpload ? customFileUploadHandler : handleFileUpload,
    };

    return (
        <FileUploadContext.Provider value={contextValue}>
            {children}
        </FileUploadContext.Provider>
    );
}

export function FileUploadIconButton({hideWhenDragging=true, uploadProps={}}) {
    const context = useContext(FileUploadContext);
    const {
        beforeUploadHandler,
        attachments,
        attachmentLimit,
        showDragger,
        handleFileUpload,
    } = context;

    if (showDragger && hideWhenDragging) {
        return null;
    }

    return (
        <Upload
            {...uploadProps}
            action="/"
            accept={""}
            beforeUpload={beforeUploadHandler}
            customRequest={handleFileUpload}
            showUploadList={false}
            multiple={true}
            disabled={(attachments.length >= attachmentLimit) || uploadProps?.disabled}
        >
            <div style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#FAFAFA",
                padding: "8px",
                border: "1px dashed #d9d9d9",
                borderRadius: "4px",
                textAlign: "center",
                display: "inline-block"
            }}>
                <PaperClipOutlined/>
            </div>
        </Upload>
    );
}

export function FileUploadDragger({uploadProps={}, alwaysShow=false, children}) {
    const context = useContext(FileUploadContext);
    const {
        beforeUploadHandler,
        attachments,
        attachmentLimit,
        showDragger,
        setShowDragger,
        handleFileUpload
    } = context;

    if (!alwaysShow && !showDragger) {
        return null;
    }

    return (
        <Upload.Dragger
            {...uploadProps}
            action="/"
            accept={""}
            beforeUpload={beforeUploadHandler}
            customRequest={(file, list) => {
                handleFileUpload(file, list);
                setShowDragger(false);
            }}
            showUploadList={false}
            multiple={true}
            disabled={(attachments.length >= attachmentLimit) || uploadProps?.disabled}
        >
            <div>
                { children || (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="zero-dark-grey">Upload to ZERO</p>
                    </>
                )}
            </div>
        </Upload.Dragger>
    );
}

export function FileUploadFullPageDrop() {
    const context = useContext(FileUploadContext);
    const {
        beforeUploadHandler,
        showDragger,
        setShowDragger,
        handleFileUpload
    } = context;

    if (!showDragger) {
        return null;
    }

    function onDrop(event) {
        event.preventDefault();
        const files = event.dataTransfer.files;

        if (files.length == 0) {
            return;
        }

        for (const file of files) {
            const response = beforeUploadHandler(file, files);

            if (response === false || response === Upload.LIST_IGNORE) {
                setShowDragger(false);
                return;
            }
        }

        for (const file of files) {
            handleFileUpload({file});
        }
        setShowDragger(false);
    }

    return (
        <div
            onDrop={onDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{position: "fixed", top: 0, right: 0, bottom: 0, left: 0}}
        ></div>
    )
}

export function FileUploadList({className = "", onPreview = () => {}, onDelete = () => {}, blobs}) {
    const { attachments, fileList } = useContext(FileUploadContext);

    return (
        <AttachmentList
            attachments={attachments}
            blobs={blobs}
            uploadingFileList={fileList}
            className={className}
            onPreview={onPreview}
            onDelete={onDelete}
        />
    );
}

export function FileUploadError({inline = false}) {
    const { error } = useContext(FileUploadContext);
    if (!error) {
        return null;
    }
    
    const inlineEl = <small className="error">{error}</small>;
    
    if (inline) {
        return inlineEl;
    }

    return (
        <div>{inlineEl}</div>
    );
}

export function FileUpload({
    attachments,
    generateParamsCallback,
    onUploadSuccess,
    onUploadError,
    customUpload,
    onPreview,
    onDelete,
    options = {},
}) {
    return (
        <FileUploadContextManager
            attachments={attachments}
            options={options}
            generateParamsCallback={generateParamsCallback}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            customUpload={customUpload}
        >
            <FileUploadFullPageDrop />
            <FileUploadIconButton hideWhenDragging={true} />
            <FileUploadDragger />
            <FileUploadError />
            <FileUploadList className="mar-top-10 mar-btm-0" onPreview={onPreview} onDelete={onDelete} />
        </FileUploadContextManager>
    );
}

export function FileUploadNoContext({onPreview, onDelete}) {
    return (
        <>
            <FileUploadFullPageDrop />
            <FileUploadIconButton hideWhenDragging={true} />
            <FileUploadDragger />
            <FileUploadError />
            <FileUploadList className="mar-top-10 mar-btm-0" onPreview={onPreview} onDelete={onDelete} />
        </>
    )
}
