// @ts-check
import { useRef } from "react";
import { Map as ImmutableMap } from "immutable";

const DEFAULT_EXPIRES_SECONDS = 60;

/**
 * @typedef {{
 *  value: unknown;
 *  expiresAt: number;
 * }} CacheValue
 */

/**
 * @param {unknown} value 
 * @param {number} expires 
 * @returns {CacheValue}
 */
function makeCacheValue(value, expires) {
    return {
        value,
        expiresAt: Date.now() + (expires * 1000)
    }
}

/**
 * @param {number} defaultExpiresSeconds 
 * @returns {KeyValueCache}
 */
export function useKeyValueCache(defaultExpiresSeconds = DEFAULT_EXPIRES_SECONDS) {
    const kv = useRef(/** @type {ImmutableMap<string, CacheValue>} */(ImmutableMap()));

    /** @type {KeyValueCache['get']} */
    const get = (key) => {
        const hit = kv.current.get(key);
        if (hit && (hit.expiresAt <= 0 || hit.expiresAt > Date.now())) {
            return hit.value;
        }
    };

    /** @type {KeyValueCache['getAll']} */
    const getAll = () => {
        return kv.current.toObject();
    }

    /** @type {KeyValueCache['set']} */
    const set = (key, value, expires = defaultExpiresSeconds) => {
        kv.current = kv.current.set(key, makeCacheValue(value, expires));
    }

    /** @type {KeyValueCache['setMany']} */
    const setMany = (entries, expires = defaultExpiresSeconds) => {
        kv.current = kv.current.withMutations((map) => {
            for (const [key, value] of entries) {
                map.set(key, makeCacheValue(value, expires));
            }
        });
    }

    /** @type {KeyValueCache['refresh']} */
    const refresh = (key, expires = defaultExpiresSeconds) => {
        const hit = kv.current.get(key);
        if (hit) {
            set(key, hit.value, expires);
        }
    }

    /** @type {KeyValueCache['invalidate']} */
    const invalidate = (key) => {
        const hit = kv.current.get(key);
        if (hit) {
            kv.current = kv.current.set(key, {
                value: hit.value,
                expiresAt: 1,
            });
        }
    }

    return {
        get,
        getAll,
        set,
        setMany,
        refresh,
        invalidate,
    }
}