import React, {useEffect, useMemo} from 'react';
import {Select} from 'antd';

import {useLocationsLoader} from 'hooks/locations';


function locationOptions(locationLoader) {
    const {ok, data} = locationLoader;
    const locations = data?.locations || [];

    if (ok && locations.length > 0) {
        const options = [
            {
                value: '',
                label: 'All My Locations'
            },
        ];

        return options.concat(locations.map(({location_uuid, name}) => (
            {
                value: location_uuid,
                label: name
            }
        )));
    } else if (ok && locations.length === 0) {
        return [{
            label: 'No locations available',
            disabled: true
        }];
    } else {
        return [];
    }
}


export default function LocationFilter({
                                           location = '', onLocationSelected = () => {
    }, ...props
                                       }) {
    const [fetchLocations, locationLoader] = useLocationsLoader('?for_teams=true');
    const {loading, error} = locationLoader;

    const options = useMemo(() => locationOptions(locationLoader), [locationLoader]);

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <Select
            virtual={false}
            value={location}
            onChange={onLocationSelected}
            placeholder="Filter by location..."
            disabled={loading || error}
            options={options}
            {...props}
        />
    )
}