// @ts-check

/**
 * @param {URLSearchParams} q 
 * @param {string} key 
 * @param {string} value 
 */
function setDefaultSearchParam(q, key, value) {
    if (!q.has(key)) {
        q.set(key, value);
    }
}

/** 
 * @typedef {{
 *  perPage?: number;
 *  includeEmbeddedForms?: boolean;
 * }} PostQueryParamOptions
 */

/**
 * @param {string} feedQuery 
 * @param {string} dateQuery 
 * @param {PostQueryParamOptions} options 
 * @returns {URLSearchParams}
 */
export function generatePostQueryParams(feedQuery, dateQuery, options = {}) {
    const { perPage, includeEmbeddedForms } = options;

    const q = new URLSearchParams(feedQuery + dateQuery);
    setDefaultSearchParam(q, 'team_uuid', 'my_teams');
    setDefaultSearchParam(q, 'period', 'all_time');
    setDefaultSearchParam(q, 'per_page', String(perPage ?? 30));
    setDefaultSearchParam(q, 'include_embedded_forms', String(includeEmbeddedForms ?? true));

    return q;
}