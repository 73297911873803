export function updateOpenPostAssignments(assignments) {
    return {
        type: "updateOpenPostAssignments",
        payload: assignments
    }
}

export function updateClosedPostAssignments(assignments) {
    return {
        type: "updateClosedPostAssignments",
        payload: assignments
    }
}

export function updateOpenFormAssignments(assignments) {
    return {
        type: "updateOpenFormAssignments",
        payload: assignments
    }
}


export function updatePastFormAssignments(assignments) {
    return {
        type: "updatePastFormAssignments",
        payload: assignments
    }
}

export function updateOpenAssignments(assignments) {
    return {
        type: "updateOpenAssignments",
        payload: assignments
    }
}


export function updateClosedAssignments(assignments) {
    return {
        type: "updateClosedAssignments",
        payload: assignments
    }
}


export function updateAssignmentsCount(count) {
    return {
        type: "updateAssignmentsCount",
        payload: count
    }
}

export function updateLatestActivity(posts) {
    return {
        type: "updateLatestActivity",
        payload: posts
    }
}

export function updateLoadingDashboard(loading) {
    return {
        type: "updateLoadingDashboard",
        payload: loading
    }
}

export function reset_assignments() {
    return {
        type: "RESET_ASSIGNMENTS",
        payload: []
    }
}