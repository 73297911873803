// @ts-check
import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    PieChartOutlined,
    LinkOutlined,
} from '@ant-design/icons';
import { Dropdown } from "antd";
import { delete_form } from "api/zero-api";
import ConfirmModal from "components/Shared/ConfirmModal";
import useOrganizationId from "hooks/useOrganizationId";
import { FormType } from "other/Constants";
import { isAdmin } from "other/Helper";
import NotificationAlert from "other/NotificationAlert";
import { useState } from "react";
import {IoIosMore as MoreIcon} from 'react-icons/io';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormOptionsModal from "./FormOptionsModal";
import ShareFormLinkModal from './ShareFormLinkModal';

/**
 * @param {{
 *  form: object,
 *  onDelete: (formId: string) => void,
 *  refresh: () => void
 * }} props 
 * @returns {JSX.Element}
 */
export default function ManageFormsActions({form, onDelete, refresh}) {
    const user = useSelector(state => state.user.user);
    const teams = useSelector(state => state.teams_helper.teams);
    const orgId = useOrganizationId();
    const history = useHistory();
    const [currentModal, setCurrentModal] = useState(/** @type {"options" | "copy" | "delete" | "share_link" | null} */ (null));

    const formId = form.form_uuid;
    const formType = form.form_type;
    const organization = useSelector(state => state.org_helper.organization);
    const embeddedFormId = organization.post_embedded_form_uuid;
    const deleteIsDisabled = isAdmin(user) === false || embeddedFormId === formId;


    const items = [
        {
            key: 'edit_settings',
            icon: <EditOutlined/>,
            label: 'Edit Settings'
        },
        formType !== FormType.INCIDENT && {
            key: 'analytics',
            icon: <PieChartOutlined/>,
            label: 'View Analytics'
        },
        FormType.canBeShared(formType) && {
            key: 'share_link',
            icon: <LinkOutlined style={{fontSize: '1.4rem'}} />,
            label: 'Share Link/QR',
            disabled: form.teams.length === 0
        },
        {
            type: 'divider',
        },
        {
            key: 'edit',
            icon: <EditOutlined/>,
            label: `Edit ${formType === FormType.LMS ? "Course" : "Template"}`
        },
        {
            key: 'copy',
            icon: <CopyOutlined/>,
            label: `Copy ${formType === FormType.LMS ? "Course" : "Template"}`
        },
        {
            key: 'delete',
            icon: <DeleteOutlined/>,
            label: `Archive ${formType === FormType.LMS ? "Course" : "Template"}`,
            className: deleteIsDisabled ? "" : "zero-delete-red",
            disabled: deleteIsDisabled
        },
    ].filter(props => typeof props === "object");

    const goToEditForm = () => {
        if (formType === FormType.INCIDENT) {
            history.push(`/${orgId}/home/manage_incident_templates/edit_form/${formId}`);
        } else if (formType === FormType.LMS) {
            history.push(`/${orgId}/home/manage_courses/edit_form/${formId}`);
        } else if (formType === FormType.POST) {
            history.push(`/${orgId}/home/manage_post_templates/edit_form/${formId}`);
        } else {
            history.push(`/${orgId}/home/manage_forms_templates/edit_form/${formId}`);
        }
    }

    const goToFormAnalytics = () => {
        if (formType === FormType.LMS) {
            history.push(`/${orgId}/home/manage_courses/analytics/${formId}`);
        } else if (formType === FormType.POST) {
            history.push(`/${orgId}/home/manage_post_templates/analytics/${formId}`);
        }  else {
            history.push(`/${orgId}/home/manage_forms_templates/analytics/${formId}`);
        }
    }

    const handleMenuClick = (key) => {
        if (key === "edit") {
            goToEditForm();
        } else if (key === "edit_settings") {
            setCurrentModal("options");
        } else if (key === "copy") {
            setCurrentModal("copy");
        } else if (key === "analytics") {
            goToFormAnalytics();
        } else if (key === "delete") {
            setCurrentModal("delete");
        } else if (key === "share_link") {
            setCurrentModal("share_link");
        }
    };

    const deleteForm = async () => {
        try {
            await delete_form(formId);
            NotificationAlert("success", "", "Template archived.");
            onDelete(formId);
        } catch (err) {
            NotificationAlert("error", "", "Unable to archive form.");
        } finally {
            setCurrentModal(null);
        }
    }

    return (
        <>
            { currentModal === 'delete' &&
                <ConfirmModal
                    show={true}
                    cancel={() => setCurrentModal(null)}
                    confirm={deleteForm}
                    title={"Confirmation"}
                    body={`Are you sure you want to archive this ${formType === FormType.LMS ? "course" : "form"}?`}
                    confirmButtonName={"Archive"}
                />
            }
            { (currentModal === 'options' || currentModal === 'copy') &&
                <FormOptionsModal
                    show={true}
                    cancel={() => setCurrentModal(null)}
                    complete={null}
                    form_to_edit={currentModal === 'options' ? form : null}
                    formToCopy={currentModal === 'copy' ? form : null}
                    team_access={false}
                    forceFormType={formType === FormType.REGULAR ? null : formType}
                    refresh={refresh}
                />
            }
            { currentModal === 'share_link' &&
                <ShareFormLinkModal
                    form={form}
                    cancel={() => setCurrentModal(null)}
                />
            }
            <div className="more" style={{ lineHeight: 0, height: 'fit-content' }}>
                <Dropdown
                    trigger={["click"]}
                    menu={{
                        onClick: ({key}) => handleMenuClick(key),
                        // @ts-ignore
                        items
                    }}
                >
                    <button className="ButtonLink ant-dropdown-link">
                        <MoreIcon style={{height: '20px', width: '20px'}}/>
                    </button>
                </Dropdown>
            </div>
        </>
    );
}