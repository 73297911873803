// @ts-check

import React, { createContext, useContext, useRef, useState } from "react";
import { useDataGridSettings } from "./FeedDataGrid.hooks";

export const FeedDataGridContext = createContext(null);

/** @returns {ReturnType<useContextValue>} */
export function useFeedDataGridContext() {
    return useContext(FeedDataGridContext);
}

function useContextValue() {
    const [settings, setSettings] = useDataGridSettings('feed');
    const [activeModal, setActiveModal] = useState(null);
    const [rowCount, setRowCount] = useState(null);
    /** @type {import("react").MutableRefObject<AgGridReactLib.AgGridReact>} */
    const gridRef = useRef();

    return {
        settings,
        setSettings,
        gridRef,
        activeModal,
        setActiveModal,
        rowCount,
        setRowCount,
    }
}

export function FeedDataGridContextProvider({children}) {
    const ctxValue = useContextValue();

    return (
        <FeedDataGridContext.Provider value={ctxValue}>
            {children}
        </FeedDataGridContext.Provider>
    )
}

