import React from 'react';
import {connect} from 'react-redux'

import {mainContentContainerClass, safe_get, switchOrganization} from '../other/Helper';

import {Result, Select} from 'antd';

import '../assets/css/antd-custom.css'
import {LogoutOutlined} from "@ant-design/icons";
import {logout, deleteCookies, update_auth_token} from "../api/zero-api";

class LockedAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    switchOrg = (value) => {
        switchOrganization(this, value)
    }

    logout() {
        sessionStorage.clear();
        logout().then(function (success) {
            success.json().then(success => {
                deleteCookies();
                if (success.kiosk_mode_on) {
                    update_auth_token(safe_get(success, "kiosk_mode_token", undefined));
                    window.location = "/kiosk_mode";
                } else {
                    window.location = "/login";
                }
            });
        }, function (error) {
            console.log(error);
        });
    }

    render() {
        let sortedOrganizations = this.props.organizations.filter(org => {
            return !org.account_locked
        }).sort(function (a, b) {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });

        return (
            <div id="content-container" className="container-with-header">
                <div className={mainContentContainerClass()} style={{marginLeft: "0px"}}>
                    <div id="page-content" style={{paddingTop: "10px"}}>
                        <div className="panel bulletin-post-border">
                            <Result
                                status="403"
                                title="Organization Deactivated"
                                subTitle={
                                    <div>
                                        Your organization does not have an active ZERO subscription.
                                        <br/>
                                        Please contact your company's administration for further information.
                                    </div>
                                }
                                extra={
                                    <div>
                                        {
                                            sortedOrganizations.length > 0 &&
                                            <>
                                                <p className="zero-dark-grey mar-rgt-10"
                                                   style={{display: "inline-block"}}>Switch Account</p>
                                                <Select onChange={this.switchOrg} style={{width: "200px"}}>
                                                    {
                                                        sortedOrganizations.map((org, index) => (
                                                            <Select.Option key={index} value={org.organization_uuid}
                                                                           disabled={org.account_locked}>
                                                                {org.name}
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </>
                                        }
                                        <br/>
                                        <br/>
                                        <p className="zero-dark-grey center-block">
                                            <button onClick={this.logout}>
                                                <LogoutOutlined/>
                                                <span className="menu-title mar-lft-5">Logout</span>
                                            </button>
                                        </p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = store => {
    return {
        organization: safe_get(store, "org_helper.organization", {}),
        organizations: safe_get(store, "org_helper.organizations", []),

    }
}

export default connect(mapStateToProps)(LockedAccount);

