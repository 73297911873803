import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Link} from 'react-router-dom';

import {Modal, Select} from 'antd';

import NotificationAlert from '../../../other/NotificationAlert.js';
import ButtonLoading from '../../Shared/ButtonLoading';

import {get_list_users, add_users_to_team} from '../../../api/zero-api.js'
import {safe_get, safeProfilePic} from '../../../other/Helper'
import {updateTeamsOnMembershipChange} from "store/helpers/teams";

import '../../../assets/css/antd-custom.css'

class AddMembersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamUsers: [],
            nonTeamUsers: [],
            loadingUsers: true,
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        var self = this;
        get_list_users("?status=active").then(success => {
            success.json().then(success => {

                let users = safe_get(success, "users", []).sort(function (a, b) {
                    let a_name = (safe_get(a, "first_name", "") + " " + safe_get(a, "last_name", "")).toLowerCase();
                    let b_name = (safe_get(b, "first_name", "") + " " + safe_get(b, "last_name", "")).toLowerCase();

                    return a_name > b_name ? 1 : -1;
                });

                let teamUsers = [];
                let nonTeamUsers = [];
                users.forEach((user) => {
                    if (user.status === "active") {
                        let user_teams = user.teams.map(x => x.uuid);
                        if (user_teams.includes(self.props.team.uuid)) {
                            teamUsers.push(user);
                        } else {
                            nonTeamUsers.push(user);
                        }
                    }
                });

                self.setState({
                    teamUsers: teamUsers,
                    nonTeamUsers: nonTeamUsers,
                    loadingUsers: false
                });
            });
        });
    }

    filterUsers = (inputValue, option) => {
        let search = inputValue.toLowerCase();
        return option.name.toLowerCase().includes(search);
    }

    onUserChange = (value) => {
        let user_uuids = value.map(x => x.value);
        this.setState({selected_user_uuids: user_uuids, selected_users: value})
    }

    updateTeam = () => {
        var self = this;

        this.setState({updating: true});

        var body = JSON.stringify({
            user_uuids: [...this.state.selected_user_uuids]
        });

        add_users_to_team(this.props.team.uuid, body).then(function (_) {
            self.props.cancel();
            self.props.refresh();
            updateTeamsOnMembershipChange(
                self.props.user,
                self.props.teams,
                self.props.team,
                self.state.selected_user_uuids,
                self.props.dispatch
            );
            NotificationAlert('success', 'Success!', "Team members updated.");
        }, function (_) {
            self.props.cancel();
            NotificationAlert('success', 'Success!', "Unable to update team members.");
        });
    }

    render() {
        return (
            (<Modal
                title={`Add people to ${this.props.team.name}`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.updateTeam} disabled={this.state.updating}>
                            {
                                this.state.updating ? <ButtonLoading/> : "Add"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">New members will receive an email invitation to this team.</p>
                <Select
                    id="invite-emails"
                    mode="multiple"
                    value={this.state.selected_users}
                    placeholder="Type a name..."
                    onChange={this.onUserChange}
                    filterOption={this.filterUsers}
                    style={{width: '100%', display: 'block', margin: '0 auto'}}
                    className="user-select-dropdown"
                    labelInValue={true}
                    notFoundContent={"No users found. Please try again..."}
                    dropdownStyle={{zIndex: "10000"}}
                    optionLabelProp="user"
                    loading={this.state.loadingUsers}
                    virtual={false}
                >
                    {
                        this.state.nonTeamUsers.map((user, index) => (
                            <Select.Option key={"user: " + user.uuid} value={user.uuid} user={
                                <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.first_name} </span>}
                                           name={`${user.first_name} ${user.last_name}`}>
                                {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                {user.first_name} {user.last_name} &bull; <span
                                style={{fontSize: '85%'}}>{user.email || user.title}</span>
                            </Select.Option>
                        ))
                    }
                </Select>
                <p className="zero-dark-grey" style={{fontSize: "11px", paddingTop: "2px", fontStyle: "italic"}}>To add
                    people not on ZERO, you'll need to <Link className="link-hover zero-light-blue"
                                                             to={"/" + this.props.org_uuid + "/home/directory?invite=true"}>invite
                        them first.</Link></p>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
    }
}

export default connect(mapStateToProps)(AddMembersModal);