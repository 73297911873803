import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Input} from 'antd';

import safe_get from '../../../other/SafeGet.js'

import Textarea from "react-textarea-autosize";

class CaseInfo2 extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.props.onChange(name, value);
    }

    render() {
        const {fieldsDisabled} = this.props;

        return (
            <>
                <h3 className="zero-blue print-only"
                    style={{marginBottom: "5px", marginTop: "5px", fontSize: "120%", textDecoration: "underline"}}>
                    Case Details
                </h3>
                <div className="mar-btm-15">
                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%"}}>
                        Where did the event occur?
                    </h3>
                    <p style={{color: "#505050"}}>Example: Loading dock north end</p>
                    {
                        this.props.print ? <p className="print-prewrap">{this.props.where_occurred}</p> :
                            <>
                                <input
                                    type="text"
                                    name="where_occurred"
                                    onChange={this.handleInputChange}
                                    value={this.props.where_occurred}
                                    disabled={fieldsDisabled}
                                    maxLength="200"
                                    placeholder="Add some detail..."
                                    className="form-control custom-placeholder"
                                />
                                <p className="text-sm mar-btm-0" style={{
                                    color: '#BCBABC',
                                    paddingTop: '3px',
                                    float: "right"
                                }}>{200 - (this.props.where_occurred.length)}</p>
                            </>
                    }
                    {
                        this.state.where_occurred_error &&
                        <small className="error">{this.state.where_occurred_error}</small>
                    }
                </div>
                <div className="mar-btm-15">
                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%"}}>
                        What was the employee doing just before the incident occurred?
                    </h3>
                    <p style={{color: "#505050"}}>Describe the activity, as well as the tools, equipment, or material
                        the employee was using. Be specific. Examples: "climbing a ladder while carrying roofing
                        materials"; "spraying chlorine from hand sprayer"; "daily computer key-entry."</p>
                    {
                        this.props.print ? <p className="print-prewrap">{this.props.before_incident}</p> :
                            <Textarea type="text" name="before_incident"
                                      className="form-control custom-placeholder" minRows={2}
                                      placeholder="Add some detail..." maxLength="2500"
                                      onChange={this.handleInputChange} value={this.props.before_incident}
                                      disabled={fieldsDisabled}
                            />
                    }
                    {
                        this.state.before_incident_error &&
                        <small className="error">{this.state.before_incident_error}</small>
                    }
                </div>
                <div className="mar-btm-15">
                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%"}}>
                        What Happened?
                    </h3>
                    <p style={{color: "#505050"}}>Tell us how the injury occurred. Examples: "When ladder slipped on wet
                        floor, worker fell 20 feet"; "Worker was sprayed with chlorine when gasket broke during
                        replacement"; "Worker developed soreness in wrist over time."</p>
                    {
                        this.props.print ? <p className="print-prewrap">{this.props.what_happened}</p> :
                            <Textarea type="text" name="what_happened"
                                      className="form-control custom-placeholder" minRows={2}
                                      placeholder="Add some detail..." maxLength="2500"
                                      onChange={this.handleInputChange} value={this.props.what_happened}
                                      disabled={fieldsDisabled}
                            />
                    }
                    {
                        this.state.what_happened_error &&
                        <small className="error">{this.state.what_happened_error}</small>
                    }
                </div>
                <div className="mar-btm-15">

                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%"}}>
                        What was the injury or illness?
                    </h3>
                    <p style={{color: "#505050"}}>Tell us the part of the body that was affected and how it was
                        affected. Examples: "strained back"; "chemical burn, hand"; "carpal tunnel syndrome."</p>
                    {
                        this.props.print ? <p className="print-prewrap">{this.props.what_body_part}</p> :
                            <Textarea type="text" name="what_body_part"
                                      className="form-control custom-placeholder" minRows={2}
                                      placeholder="Add some detail..." maxLength="2500"
                                      onChange={this.handleInputChange} value={this.props.what_body_part}
                                      disabled={fieldsDisabled}
                            />
                    }
                    {
                        this.state.gender_error &&
                        <small className="error">{this.state.gender_error}</small>
                    }

                </div>
                <div className="mar-btm-15">

                    <h3 className="titles onboarding invite-link mt-0"
                        style={{marginBottom: "5px", marginTop: "0px", fontSize: "100%"}}>
                        What object or substance directly harmed the employee?
                    </h3>
                    <p style={{color: "#505050"}}>Examples: "concrete floor"; "chlorine"; "radial arm saw." <span>If this question does not apply, leave it blank.</span>
                    </p>
                    {
                        this.props.print ? <p className="print-prewrap">{this.props.what_object}</p> :
                            <Textarea type="text" name="what_object" rows="2"
                                      className="form-control custom-placeholder" minRows={2}
                                      placeholder="Add some detail..." maxLength="2500"
                                      onChange={this.handleInputChange} value={this.props.what_object}
                                      disabled={fieldsDisabled}
                            />
                    }
                    {
                        this.state.what_object_error &&
                        <small className="error">{this.state.what_object_error}</small>
                    }

                </div>

            </>


        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        all_assignments: safe_get(store, "assignments", {})
    }
}

export default connect(mapStateToProps)(CaseInfo2);

