export function update_incidents_locations(locations) {
    return {
        type: "update_incidents_locations",
        payload: locations
    }
}

export function reset_incidents_locations() {
    return {
        type: "reset_incidents_locations",
        payload: []
    }
}

export function update_current_location(location) {
    return {
        type: "update_current_location",
        payload: location
    }
}

export function update_current_incident(incident) {
    return {
        type: "update_current_incident",
        payload: incident
    }
}

export function updateIncidentsFilterQuery(query) {
    return {
        type: "updateIncidentsFilterQuery",
        payload: query
    }
}

export function updateIncidentsPeriodQuery(query) {
    return {
        type: "updateIncidentsPeriodQuery",
        payload: query
    }
}

export function updateIncidentsPagination(page) {
    return {
        type: "updateIncidentPagination",
        payload: page
    }
}

export function updateIncidentsPagePosition(position) {
    return {
        type: "updateIncidentsPagePosition",
        payload: position
    }
}

export function updateIncidentsTab(tab) {
    return {
        type: "updateIncidentsTab",
        payload: tab
    }
}

export function updateIncidentTypes(types) {
    return {
        type: "updateIncidentTypes",
        payload: types
    }
}

export function updateEventTypes(types) {
    return {
        type: "updateEventTypes",
        payload: types
    }
}

export function reset() {
    return {
        type: "RESET",
        payload: []
    }
}
