import { BookOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';

import Previewer from 'components/Previewer';
import ConfirmModal from 'components/Shared/ConfirmModal';
import { FileUploadContext, FileUploadError, FileUploadList } from 'components/Shared/FileUpload';
import PostUpload from '../PostUpload';
import Templates from '../Templates';
import { actions } from './reducer';

export default function PostFormAttachments({state, dispatch}) {
    const { showDragger } = useContext(FileUploadContext);
    const [showTemplatesModal, setShowTemplatesModal] = useState(false);
    const [indexToPreview, setIndexToPreview] = useState(null);
    const [indexToDelete, setIndexToDelete] = useState(null);

    const onTemplateInserted = (body) => {
        dispatch([actions.BODY_CHANGED, body]);
        setShowTemplatesModal(false);
    }

    const deleteAttachment = () => {
        if (indexToDelete !== null) {
            let attachments = [...state.attachments];
            attachments.splice(indexToDelete, 1);
            setIndexToDelete(null);
            dispatch([actions.ATTACHMENTS_CHANGED, attachments]);
        }
    }

    const updateAttachments = (attachments) => {
        dispatch([actions.ATTACHMENTS_CHANGED, attachments]);
    }

    return (
        <>
            {showTemplatesModal &&
                <Templates
                    show={showTemplatesModal}
                    cancel={() => {
                        setShowTemplatesModal(false)
                    }}
                    insertTemplate={onTemplateInserted}
                />
            }
            {indexToPreview !== null &&
                <Previewer
                    show={indexToPreview !== null}
                    close={() => {
                        setIndexToPreview(null)
                    }}
                    attachments={state.attachments}
                    index={indexToPreview}
                />
            }
            {indexToDelete !== null &&
                <ConfirmModal
                    show={indexToDelete !== null}
                    cancel={() => {
                        setIndexToDelete(null)
                    }}
                    title={"Confirmation"}
                    body={"Are you sure you want to delete this attachment?"}
                    confirmButtonName={"Delete"}
                    confirm={deleteAttachment}
                />
            }
            <div className="bulletin-post">
                <div style={{marginTop: "10px", display: showDragger ? 'block' : 'flex'}}>
                    <PostUpload
                        attachments={state.attachments}
                        update_attachments={updateAttachments}
                    />
                    {
                        !showDragger &&
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                backgroundColor: "#FAFAFA",
                                padding: "8px",
                                border: "1px dashed #d9d9d9",
                                borderRadius: "4px",
                                textAlign: "center",
                                display: "inline-block",
                                marginLeft: "8px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                setShowTemplatesModal(true)
                            }}
                        >
                            <BookOutlined/>
                        </div>
                    }
                </div>

                <FileUploadList
                    className="mar-top-10"
                    onPreview={index => setIndexToPreview(index)}
                    onDelete={index => setIndexToDelete(index)}
                />

                <FileUploadError />
            </div>
        </>
    );
}