import { useEffect, useState } from "react";
import { PostEvents } from "services/postService";

/**
 * @param {PostService} postService 
 * @returns {boolean}
 */
export function usePostIsSyncing(postService) {
    const [isSyncing, setIsSyncing] = useState(postService.isSyncing);

    useEffect(() => {
        const unsubscribe = postService.subscribe((ev) => {
            if (ev.data.type === PostEvents.IS_SYNCING_CHANGE) {
                setIsSyncing(ev.data.isSyncing);
            }
        })
        return () => { unsubscribe(); };
    }, []);

    return isSyncing;
}