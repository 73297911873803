import { useCallback, useEffect, useMemo, useState } from 'react';

import { Modal } from 'antd';
import NotificationAlert from "../../other/NotificationAlert";
import LoadingIndicator from "../Shared/LoadingIndicator";

import { PostDraftsListContext, PreloadedPostDraftsList } from 'components/Offline/Posts/PostDraftsList';
import { useZeroContext } from 'components/ZeroContext';
import { dateFormatterWithTime } from 'other/Helper';
import SyncInProgressSpinner from 'components/Offline/SyncInProgressSpinner';
import { PostEvents } from 'services/postService';
import { usePostIsSyncing } from 'hooks/offlineHooks';

function DraftsModalTitle({drafts}) {
    const context = useZeroContext();
    const {caches, isOffline} = context;
    const isSyncing = usePostIsSyncing(context.services.post);
    const [syncError, setSyncError] = useState("");


    const {lastSyncAt, sync} = caches;

    const tryToSync = useCallback(() => {
        if (isOffline) {
            setSyncError("You're offline. Please try again later.");
        } else if (!isSyncing) {
            setSyncError('');
            sync(true);
        }
    }, [isOffline, isSyncing, sync, setSyncError]);

    return (
        <>
            <div>My Drafts ({drafts.length})</div>
            <small className="ant-modal-sub-title">
                <>Last sync:&nbsp;</>
                {
                    lastSyncAt > 0 ? (
                        <>{dateFormatterWithTime(lastSyncAt)}</>
                    ) : (
                        <>Not Synced</>
                    )
                }
                { !isSyncing &&
                    <span className="blue-link mar-lft-10" onClick={tryToSync}>Sync now</span>
                }
                <SyncInProgressSpinner forPosts style={{paddingTop: '1rem'}}/>
                {syncError &&
                    <>
                        <br/>
                        <small className="error">{syncError}</small>
                    </>
                }
            </small>
        </>
    )
}

export default function DraftsModal({onCancel}) {
    const zeroContext = useZeroContext();
    const postService = zeroContext.services.post;

    const [isLoading, setIsLoading] = useState(true);
    /** @type {[LocalPost[], function]} */
    const [drafts, setDrafts] = useState([]);
    const [subModalVisible, setSubModalVisible] = useState(false);

    async function loadDrafts() {
        setDrafts(await postService.getLocalPosts({onlyDrafts: true, includeDeleted: false}));
        setIsLoading(false);
    }

    /** @param {LocalPost} draft */
    async function deleteDraft(draft) {
        try {
            await postService.deletePostDraft(draft.post_uuid);
            await loadDrafts();
        } catch (err) {
            console.error(err);
            NotificationAlert("error", "", "Could not delete post draft");
        }
    }

    const renderedDraftList = useMemo(() => {
        return (
            <PreloadedPostDraftsList
                drafts={drafts}
                onDelete={deleteDraft}
                onReload={loadDrafts}
            />
        );
    }, [drafts, subModalVisible]);

    const modalBody = useMemo(() => {
        if (isLoading) {
            return null;
        } else if (drafts.length === 0) {
            return <div>You currently do not have any drafts. When you do, they will be listed here.</div>;
        } else {
            return renderedDraftList;
        }
    }, [isLoading, renderedDraftList, drafts]);

    // on mount - load drafts and setup post service listener
    useEffect(() => {
        loadDrafts();
        const unsubscribe = postService.subscribe((ev) => {
            if (ev.data.type === PostEvents.POSTS_SYNCED) {
                loadDrafts();
            }
        });

        // on unmount - unsubscribe from post service events
        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <PostDraftsListContext.Provider value={{insideModal: true, subModalVisible, setSubModalVisible}} >
            <Modal
                title={<DraftsModalTitle drafts={drafts} />}
                open={true}
                maskClosable={false}
                onCancel={onCancel}
                footer={null}
                maskTransitionName='maskTransitionName'
            >{modalBody}</Modal>
        </PostDraftsListContext.Provider>
    )
}
