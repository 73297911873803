import {useCallback, useState} from "react";
import {Input} from 'antd';

const {TextArea} = Input;

import {createIncidentUserComment} from 'api/zero-api';
import AssignUsersWithPopover from "components/Shared/AssignUsersWithPopover";


export default function IncidentCommentInput({
                                                 managers, incidentId, onCommentCreated = () => {
    }
                                             }) {
    const [assignedUsers, setAssignedUsers] = useState([]);
    const userCount = assignedUsers.length;
    const [body, setBody] = useState('');
    const [error, setError] = useState('');

    const handleAssignedUserChange = useCallback((uuids) => {
        const newAssignedUsers = managers.filter(user => uuids.includes(user.uuid));
        setAssignedUsers(newAssignedUsers);
    }, [managers, setAssignedUsers]);

    const handleCancelButton = useCallback(() => {
        setAssignedUsers([]);
        setBody('');
        setError('');
    }, [setAssignedUsers, setBody]);

    const handleCreateButton = useCallback(async () => {
        if (body.length === 0) return;

        const requestBody = {
            body,
            notify_user_uuids: assignedUsers.map(user => user.uuid),
        };

        try {
            await createIncidentUserComment(incidentId, JSON.stringify(requestBody));
            if (onCommentCreated) onCommentCreated();
            handleCancelButton();
        } catch (err) {
            setError('Could not create comment.');
        }
    }, [body, assignedUsers, incidentId, onCommentCreated, handleCancelButton]);

    return (
        <div>
            <div className="text-semibold">Add comment</div>
            <div className="zero-dark-grey">When I add a comment, {userCount} {userCount === 1 ? 'person' : 'people'} will be notified. All
                comments are logged on the activity timeline.
            </div>
            <AssignUsersWithPopover
                assignedUsers={assignedUsers}
                availableUsers={managers}
                onChange={handleAssignedUserChange}
                respondersPopoverTitle="Notifications"
            />
            <TextArea
                value={body}
                onChange={e => setBody(e.target.value)}
                placeholder="Add your comment..."
                maxLength={5000}
                style={{marginTop: '1rem'}}
                autoSize={{
                    minRows: 4,
                    maxRows: 10
                }}
            />
            <div style={{display: 'flex', gap: '0 0.8rem', marginTop: '0.8rem'}}>
                <button className="btn btn-discard" onClick={handleCancelButton}>Cancel</button>
                <button className="btn btn-primary" disabled={body.length === 0} onClick={handleCreateButton}>Add
                    comment
                </button>
            </div>
            {error && <small className="error">{error}</small>}
        </div>
    );
}