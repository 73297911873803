import {Component, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'

import {Collapse} from 'react-collapse';
import ManageTags from '../ManageTags/ManageTags.js';
import ManageCategories from '../ManageCategories/ManageCategories.js'
import ManageRisksSettings from './ManageRisksSettings.js';
import ManageLocationSettings from './ManageLocationSettings.js';
import ManageStatusSettings from './ManageStatusSettigs/ManageStatusSettings.js';
import ManageCustomField from './ManageCustomField.js';

import {
    getPostBulkOptions,
    toggle_post_due_date_enabled,
    update_post_field_names,
} from '../../../api/zero-api.js'

import {safe_get} from '../../../other/Helper.js';
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {Switch} from "antd";
import * as organizationAction from "../../../store/actions/OrganizationActions";
import NotificationAlert from "../../../other/NotificationAlert";
import { ZeroContext } from 'components/ZeroContext.js';
import ZeroTag from 'components/Shared/ZeroTag.js';
import DelayedTextInput from 'components/Shared/DelayedTextInput.js';


function EnabledTag({always=false}) {
    return <ZeroTag className="mar-lft-5 text-normal v-top" color="green">{always ? "Always Enabled" : "Enabled"}</ZeroTag>
}

function FieldNameInput({initialName, fieldKey}) {
    const dispatch = useDispatch();
    const org = useSelector(state => state.org_helper.organization);
    const postFieldNames = org.post_field_names;
    const [name, setName] = useState(initialName);

    const changeFieldName = (newName) => {
        setName(newName);

        const body = JSON.stringify({
            ...postFieldNames,
            [fieldKey]: newName,
        });

        update_post_field_names(body)
            .then(res => res.json())
            .then(data => {
                dispatch(organizationAction.update_current_org(data.organization));
                NotificationAlert("success", "", "Changes saved.");
            })
            .catch(err => {
                console.error(err);
                NotificationAlert("error", "", "Unable to save changes");
                setName(postFieldNames[fieldKey]);
            })
    }

    return (
        <div>
            <p className="zero-blue"><b>Field Name</b></p>
            <DelayedTextInput
                className="form-control topic-field"
                defaultValue={name}
                onChange={changeFieldName}
                showCharacterCounter={true}
                maxLength={15}
            />
        </div>
    )

}

function SettingCollapse({fieldKey, isEnabled, alwaysEnabled, description, children}) {
    const org = useSelector(state => state.org_helper.organization);
    const {post_field_names: fieldNames} = org;
    const [isExpanded, setIsExpanded] = useState(false);

    let fieldName = fieldNames[fieldKey];
    if (fieldKey === 'custom_post_field') {
        fieldName = org.custom_post_field.name;
    } else if (fieldKey === 'due_date') {
        fieldName = "Due Date";
    } else if (!fieldName) {
        fieldName = "Unknown";
    }

    return (
        <>
            <h4 className="zero-blue" style={{display: "inline-block", marginTop: "0px"}}>
                {fieldName}
                {(isEnabled || alwaysEnabled) && <EnabledTag always={alwaysEnabled} />}
            </h4>
            <button
                className="btn btn-xs btn-default expand-button pull-right"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isExpanded ? "Close" : "Expand"}
            </button>
            <p className="zero-dark-grey">{description}</p>
            <Collapse key={fieldKey} isOpened={isExpanded}>
                {children}
            </Collapse>
        </>
    )
}

class PostSettings extends Component {
    static contextType = ZeroContext;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        this.state = {
            categories: [],
            categoriesEnabled: false,
            tags: [],
            tagsEnabled: false,
            statuses1: [],
            statuses1Enabled: false,
            statuses2: [],
            statuses2Enabled: false,
            dueDateEnabled: safe_get(this.props.organization, 'post_due_date_enabled', true),
        };
    }

    componentDidMount() {
        this.getPostOptions();
    }

    getPostOptions = async () => {
        try {
            const response = await getPostBulkOptions();
            const options = await response.json();
            const { categories, tags, sub_statuses, sub_statuses_2 } = options;
            const isEnabled = (group) => group.some(v => v.enabled);
            this.setState({
                categories,
                tags,
                tagsEnabled: isEnabled(tags),
                statuses1: sub_statuses,
                statuses1Enabled: isEnabled(sub_statuses),
                statuses2: sub_statuses_2,
                statuses2Enabled: isEnabled(sub_statuses_2),
            });
            await this.context.services.post.localDbService.syncPostOptions(options);
        } catch (err) {
            console.error(err);
        }
    }

    toggleDueDateEnabled = (value) => {
        this.setState({dueDateEnabled: value}, () => {
            toggle_post_due_date_enabled().then(response => {
                response.json().then(data => {
                    let organization = safe_get(data, "organization", {});
                    this.props.dispatch(organizationAction.update_current_org(organization));
                    NotificationAlert("success", "", `Due date has been ${organization.post_due_date_enabled ? 'enabled' : 'disabled'}.`);
                    this.setState({dueDateEnabled: organization.post_due_date_enabled});
                })
            }).catch(error => {
                NotificationAlert("error", "", "Unable to toggle post due date setting.");
                this.setState({dueDateEnabled: !value});
            })
        });
    }

    render() {
        const fieldNames = this.props.organization.post_field_names;

        return (
            <div className="panel pad-top pad-btm" style={{maxWidth: '800px', margin: '0 auto'}}>

                <SettingCollapse
                    fieldKey="category"
                    alwaysEnabled
                    description={
                        <>
                            The main field on the post. This field helps create structure in ZERO and
                            gives people ideas about what types of posts to make.
                        </>
                    }
                >
                    <ManageCategories categories={this.state.categories} update={this.getPostOptions}/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="tag"
                    isEnabled={this.state.tagsEnabled}
                    description={
                        <>
                            Additional (optional) field on the post. You can enable or disable this field at any time.
                        </>
                    }
                >
                    <ManageTags tags={this.state.tags} update={this.getPostOptions}/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="sub_status"
                    isEnabled={this.state.statuses1Enabled}
                    description={
                        <>
                            Additional (optional) field on the post. You can enable or disable this field at any time.
                        </>
                    }
                >
                    <ManageStatusSettings statuses={this.state.statuses1} update={this.getPostOptions} group={1}/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="sub_status_2"
                    isEnabled={this.state.statuses2Enabled}
                    description={
                        <>
                            Additional (optional) field on the post. You can enable or disable this field at any time.
                        </>
                    }
                >
                    <ManageStatusSettings statuses={this.state.statuses2} update={this.getPostOptions} group={2}/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="custom_post_field"
                    isEnabled={this.props.organization.custom_post_field.enabled}
                    description={
                        <>
                            Additional (optional) field on the post. This field allows users to enter free-form
                            text (i.e., no pre-set options to choose from). You can enable or disable this field at any time.
                        </>
                    }
                >
                    <ManageCustomField/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="title"
                    alwaysEnabled
                    description={
                        <>
                            The title or heading of the post. You can customize the name of this field.
                        </>
                    }
                >
                    <FieldNameInput fieldKey="title" initialName={fieldNames.title} />
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="body"
                    alwaysEnabled
                    description={
                        <>
                            The body of the post. Free-form text field offering rich text editing. You can customize the name of this field.
                        </>
                    }
                >
                    <FieldNameInput fieldKey="body" initialName={fieldNames.body} />
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="location"
                    isEnabled={this.props.organization.post_location_enabled}
                    description={
                        <>
                            Additional (optional) field on the post. This is a free-form text field that autosaves user responses for selection in
                            future posts by other users. You can customize the name of this field and
                            enable/disable it at any time.
                        </>
                    }
                >
                    <ManageLocationSettings/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="severity_level"
                    isEnabled={this.props.organization.risk_level_enabled}
                    description={
                        <>
                            This field allows users to rank their posts by selecting from four
                            options: N/A, Low, Medium, and High. You can customize the name of
                            this field, as well as the descriptions for each of the responses below.
                        </>
                    }
                >
                    <ManageRisksSettings/>
                </SettingCollapse>

                <hr/>

                <SettingCollapse
                    fieldKey="due_date"
                    isEnabled={this.props.organization.post_due_date_enabled}
                    description={
                        <>
                            This field allows users to set a due date for their post.
                        </>
                    }
                >
                    <b className="zero-blue">Enabled</b>
                    <Switch
                        checked={this.state.dueDateEnabled}
                        onChange={this.toggleDueDateEnabled}
                        style={{marginLeft: '10px', verticalAlign: 'baseline'}}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                    />
                </SettingCollapse>

            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        current_user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        organization: safe_get(store, "org_helper.organization", {}),
    }
}

export default connect(mapStateToProps)(PostSettings);
