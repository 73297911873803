import React, { useContext, useRef } from 'react';

export const CachedApiLoaderContext = React.createContext();

export function CachedApiLoaderProvider({children}) {
    const cachedApiLoader = useRef(new CachedApiLoader());

    return (
        <CachedApiLoaderContext.Provider value={cachedApiLoader.current}>
            {children}
        </CachedApiLoaderContext.Provider>
    )
}

export function useCachedApiLoader() {
    /** @type {CachedApiLoader} */
    const cachedApiLoader = useContext(CachedApiLoaderContext);
    return cachedApiLoader;
}

export class CachedApiLoader {
    constructor(expiresAfterSeconds = 300) {
        this.cachedApiCalls = {};
        this.expiresAfterSeconds = expiresAfterSeconds;
    }

    async fetch(apiFn, query = "", cacheOptions = {}) {
        const {bypassCache, cacheKey} = cacheOptions;
        
        let queryParams = query;
        if (typeof(query) === "object" && "query" in query) {
            queryParams = query.query;
        }
        const cachedName = cacheKey ?? `${apiFn.name}?${queryParams.replace(/^\?/, '')}`;

        const cachedResult = this.cachedApiCalls[cachedName]
        if (!bypassCache && cachedResult && cachedResult.expiresAt > Date.now()) {
            return cachedResult.data;
        }

        const request = await apiFn(query);
        const data = await request.json();
        this.cachedApiCalls[cachedName] = {
            data,
            expiresAt: Date.now() + (this.expiresAfterSeconds * 1000)
        };

        return data;
    }
}