import React, {Component} from 'react';

import {get_incident_totals_analytics, notifyError} from '../../../api/zero-api.js'
import safe_get from '../../../other/SafeGet';
import Skeleton from 'react-loading-skeleton'

class IncidentsTotalsAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            panels_info: {},
            total: 0,
            total_recordable: 0,
            total_non_recordable: 0,
            total_injury: 0,
            total_illness: 0,
            dart: "--",
            trir: "--",
            loading_analytics: true,
            loading_panels: true,
            showChart: false
        };

    }

    componentDidUpdate(prevProps) {
        if ((this.props.query !== prevProps.query) || (this.props.location_uuid !== prevProps.location_uuid)) {
            this.getAnalytics();
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = () => {
        var self = this;
        this.setState({loading_panels: true});

        // let query = "";
        // if (this.props.location_uuid === "all_locations") {
        //     query = "?" + this.props.query;
        // }
        // else {
        //     query = "?location_uuid=" + this.props.location_uuid + "&" + this.props.query;
        // }

        let query = "?" + this.props.query

        get_incident_totals_analytics(query).then(function (success) {
            success.json().then(success => {
                self.setState({
                    total: safe_get(success, "data.total", 0),
                    total_recordable: safe_get(success, "data.total_recordable", 0),
                    total_non_recordable: safe_get(success, "data.total_non_recordable", 0),
                    total_injury: safe_get(success, "data.total_injury", 0),
                    total_illness: safe_get(success, "data.total_illness", 0),
                    total_other: safe_get(success, "data.total_other", 0),
                    dart: safe_get(success, "data.dart", "--"),
                    trir: safe_get(success, "data.trir", "--"),
                    loading_panels: false,
                })
            });
        }, function (error) {
            notifyError(error);
            self.setState({loading_panels: false});
        });
    }


    render() {
        if (this.state.loading_panels) {
            return (
                // <LoadingIndicator small={true}/>
                <div className="panel" style={{margin: "0px"}}>
                    <div style={{padding: "10px"}}>
                        <div className="row justify-content-start">

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center"><Skeleton width={70}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "2.5em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center"><Skeleton width={70}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "2.5em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center"><Skeleton width={70}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "2.5em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center"><Skeleton width={70}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "2.5em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center"><Skeleton width={70}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "2.5em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center"><Skeleton width={70}/></p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "2.5em"}}>
                                        <Skeleton width={"60px"} height={"50px"}/>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div className="panel" style={{margin: "0px"}}>
                    <div style={{padding: "10px"}}>
                        <div className="row justify-content-start">

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center">Total Incidents</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                       style={{fontSize: "3em"}}>{this.state.total}</p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center">Recordable </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.total_recordable}</p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center">Non Recordable </p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.total_non_recordable}</p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center">Other</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{this.state.total_other}</p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center">DART</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{!isNaN(this.state.dart) ? (this.state.dart > 10 ? ">10" : Math.round(this.state.dart * 10) / 10) : "--"}</p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-2 col-xs-6 col-print-4" style={{display: "inline-block"}}>
                                <p className="text-sm text-bold zero-blue text-center">TRIR</p>
                                <div className="text-lg">
                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                       style={{fontSize: "3em"}}>{!isNaN(this.state.trir) ? (this.state.trir > 10 ? ">10" : Math.round(this.state.trir * 10) / 10) : "--"}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            );
        }

    }
}

export default IncidentsTotalsAnalytics;