import {get_form, get_forms} from 'api/zero-api';
import {processAttachments} from './utils';
import BaseCache from './BaseCache';
import { FormType } from 'other/Constants';

let isSyncingForms = false;

/**
 * Gets forms from API and overwrites existing DB with results.
 * @param {FormsCache} cache
 */
export async function syncForms(cache) {
    try {
        if (isSyncingForms) {
            return;
        }
        isSyncingForms = true;
        let page = 0;
        const perPage = 50;
        let total = 0;

        async function getFormsByPage() {
            const params = new URLSearchParams();
            params.set('team_uuid', 'my_teams');
            params.set('form_types', 'regular,post_embedded');
            params.set('enabled', 'true');
            params.set('include_fields', 'true');
            params.set('paginate', 'true');
            params.set('page', page);
            params.set('per_page', perPage);
    
            const res = await get_forms(`?${params}`);
            const content = await res.json();
            if (content) {
                total = content.total;
                return content.results;
            } else {
                throw new Error('content is empty');
            }
        }

        const forms = [];

        do {
            page++;
            forms.push(...await getFormsByPage());
        } while (perPage * page < total);

        await cache.setAll(forms);
        for (const form of forms) {
            const attachments = getAttachments(form);
            await processAttachments(cache, form.form_uuid, attachments);
        }
    } catch (error) {
        console.error('Could not sync forms:', error);
    } finally {
        isSyncingForms = false;
    }
}

/**
 * Gets form from API or formData parameter, and overwrites existing DB with results.
 * @param {FormsCache} cache
 * @param {string} formId
 * @param {*} [formData] Existing form data, skips API call and saves directly to cache if provided.
 */
export async function syncForm(cache, formId, formData = null) {
    let formDataFromApi;

    if (!formData) {
        const res = await get_form(formId);
        formDataFromApi = (await res.json())?.form;
    }

    if (formData || formDataFromApi) {
        const form = formData || formDataFromApi;

        if (!FormType.allowedOffline(form.form_type)) { 
            return null;
        }

        await cache.set(formId, form);

        const attachments = getAttachments(form);
        await processAttachments(cache, formId, attachments);

        return await cache.get(formId);
    } else {
        throw new Error("Invalid form: no content");
    }
}

/**
 * Returns an array that contains all attachments from all File Upload fields in form.
 * @param {object} form
 * @returns {object[]}
 */
function getAttachments(form) {
    let attachments = [];
    for (const field of form.fields) {
        if (field.element !== 'FileUploads') {
            continue;
        }

        const fieldAttachments = field.attachments || [];
        attachments = [...attachments, ...fieldAttachments];
    }
    return attachments;
}

export default class FormsCache extends BaseCache {
    /**
     * @param {string} orgId
     * @param {string} userId
     */
    constructor(orgId, userId) {
        super('forms', 'form_uuid', orgId, userId);

        if (window.zeroDebugDestroyFormsDb === undefined) {
            window.zeroDebugDestroyFormsDb = () => {
                this.db.destroy();
                window.location.reload();
            }
        }
    }
}
