import {useCallback, useContext, useEffect, useState} from "react";
import {CloseCircleFilled} from '@ant-design/icons';

import {AppContext} from "App";
import ButtonLoading from "./Shared/ButtonLoading";
import {useLocation} from "react-router";
import useOfflineMode from "./Offline/useOfflineMode";

export default function AppUpdateAlert() {
    const { isOffline } = useOfflineMode();
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(!location.pathname.startsWith('/login'));
    const [isUpdating, setIsUpdating] = useState(false);
    const {hasUpdate, wb} = useContext(AppContext);

    useEffect(() => {
        if (hasUpdate) {
            if (location.pathname.startsWith('/login')) {
                wb.messageSkipWaiting();
            } else {
                setIsVisible(true);
            }
        }
    }, [hasUpdate, setIsVisible, location, wb]);

    const handleUpdateClicked = useCallback(async () => {
        setIsUpdating(true);
        wb.messageSkipWaiting();
    }, [setIsUpdating, wb]);

    if (isOffline || !isVisible || !hasUpdate) return null;

    return (
        <div className="update-message">
            <div className="update-message-header">
                <h3>A new version of ZERO is available!</h3>
                <div className="update-message-close" onClick={() => setIsVisible(false)}><CloseCircleFilled/></div>
            </div>
            {
                isUpdating ? (
                    <ButtonLoading style={{width: '4rem'}}/>
                ) : (
                    <p><span className="underline hover-cursor" onClick={handleUpdateClicked}>Click here</span> to
                        update.</p>
                )
            }
        </div>
    );
}