import React, {Component} from 'react';

import {Modal, Checkbox} from 'antd';
import {share_options, get_subscriber_user_options} from '../../api/zero-api.js'
import {
    safe_get,
    safeProfilePic,
    deepObjectCompare,
    isContributor,
    isAdmin,
    isTeamLead,
    isUser
} from '../../other/Helper.js';

import Skeleton from 'react-loading-skeleton';

import LoadingIndicator from '../Shared/LoadingIndicator.js';
import ButtonLoading from '../Shared/ButtonLoading.js';

import '../../assets/css/antd-custom.css'

class SharePostModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: "",
            step: "share",
            original_team_options: [],
            original_selected_teams: [],
            selected_teams: [],
            team_options: [],
            selected_users: [],
            subscriber_options: [],
            ready_for_next: false
        };

        this.getShareOptions = this.getShareOptions.bind(this);
        this.teamSelect = this.teamSelect.bind(this);
        this.selectAllTeams = this.selectAllTeams.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.selectAllUsers = this.selectAllUsers.bind(this);
        this.selectNoOne = this.selectNoOne.bind(this);
        this.confirmShare = this.confirmShare.bind(this);
    }

    componentDidMount() {
        this.getShareOptions(this.props.post_uuid);
    }

    getShareOptions(post_uuid) {
        var self = this;
        this.setState({loadingShareOptions: true})
        share_options(post_uuid).then(function (success) {
            success.json().then(success => {
                let teams = safe_get(success, "teams", []).sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                });

                let team_options = []
                let selected_teams = [];

                let original_team_options = [];
                let original_selected_teams = [];

                teams.forEach(team => {
                    if (team.shared) {
                        original_selected_teams.push(team.team_uuid)
                        original_team_options.push(team);
                    } else {
                        team_options.push(team)
                    }
                });

                self.setState({
                    original_team_options: original_team_options,
                    original_selected_teams: original_selected_teams,
                    team_options: team_options,
                    selected_teams: [],
                    loadingShareOptions: false
                });

            });
        }, function (error) {
            console.log(error)
        });
    }

    originalTeamsSelect = (e) => {
        let team_uuid = e.target.value;
        let original_selected_teams = [...this.state.original_selected_teams];

        var index = original_selected_teams.indexOf(team_uuid);

        if (index >= 0) {
            original_selected_teams.splice(index, 1);
        } else {
            original_selected_teams.push(team_uuid)
        }

        this.setState({
            original_selected_teams: original_selected_teams,
            // ready_for_next: !deepObjectCompare(this.state.original_selected_teams, selected_teams_clone),
        });
    }

    selectAllOriginalTeams = () => {
        var original_selected_teams = [...this.state.original_team_options].map(team => team.team_uuid);
        this.setState({original_selected_teams: original_selected_teams});
    }

    selectNoneOrignalTeams = () => {
        this.setState({original_selected_teams: [this.props.source_team.team_uuid]});
    }

    teamSelect(e) {
        let team_uuid = e.target.value;
        var selected_teams_clone = [...this.state.selected_teams];
        var index = selected_teams_clone.indexOf(team_uuid);

        if (index >= 0) {
            selected_teams_clone.splice(index, 1);
        } else {
            selected_teams_clone.push(team_uuid)
        }

        this.setState({
            selected_teams: selected_teams_clone,
            // ready_for_next: !deepObjectCompare(this.state.original_selected_teams, selected_teams_clone),
        });
    }

    selectAllTeams() {
        var selected_teams = [...this.state.team_options].map(team => team.team_uuid);
        this.setState({selected_teams: selected_teams});
    }

    selectNone() {
        this.setState({selected_teams: []});
    }

    getSubscriberOptions() {
        var self = this;

        var team_uuids = this.state.selected_teams.concat(this.state.original_selected_teams)

        var body = JSON.stringify({
            team_uuids: team_uuids
        });

        get_subscriber_user_options(body).then(function (success) {
            success.json().then(success => {

                var users = safe_get(success, "users", []).sort((a, b) => {
                    return a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1;
                })

                self.setState({
                    subscriber_options: users,
                    step: "subscribe",
                    loading: false
                });

            });
        }, function (error) {
            console.log(error)
        });
    }

    handleUserSelect(e) {
        let user_uuid = e.target.value;
        var selected_users = [...this.state.selected_users];
        var index = selected_users.indexOf(user_uuid);

        if (index >= 0) {
            selected_users.splice(index, 1);
        } else {
            selected_users.push(user_uuid)
        }

        this.setState({
            selected_users: selected_users,
            select_all_users: selected_users.length === this.state.subscriber_options.length
        });
    }

    selectAllUsers() {
        var selected_users = [...this.state.subscriber_options].map(user => user.uuid)
        this.setState({
            selected_users: selected_users,
            select_all_users: true
        });
    }

    selectNoOne() {
        this.setState({
            selected_users: [],
            select_all_users: false
        });
    }

    back = () => {
        this.setState({step: "share"});
    }

    confirmShare() {
        if (this.state.step === "share") {
            this.setState({loading: true});
            this.getSubscriberOptions();
        } else {
            this.setState({loading: true});
            this.props.confirm(this.state.selected_teams.concat(this.state.original_selected_teams), this.state.selected_users, this.state.select_all_users)
        }

    }

    render() {
        let is_Author = safe_get(this.props, "user.user_uuid", "") === safe_get(this.props, 'post.revised_by.user_uuid', null);

        let is_Admin = isAdmin(this.props.user);
        let is_TeamLead = isTeamLead(this.props.user);
        let is_Contributor = isContributor(this.props.user);
        let is_user = isUser(this.props.user);

        let canShare = (is_Admin || is_TeamLead || is_Author || is_Contributor || is_user) && (this.props.teams.length > 1);

        return (
            (<Modal
                title={this.state.step === "share" ? "Share post" : "Who should be notified?"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <p className="zero-dark-grey pull-left mar-top-5">Step {this.state.step === "share" ? "1" : "2"} of
                            2</p>
                        {
                            this.state.step === "share" &&
                            <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        }
                        {
                            this.state.step === "subscribe" &&
                            <button className="btn btn-discard" onClick={() => {
                                this.setState({step: "share"})
                            }}>Back</button>
                        }
                        <button className="btn btn-modal" onClick={this.confirmShare}
                                disabled={(this.state.selected_teams.length === 0 && this.state.original_selected_teams.length === this.state.original_team_options.length) || !canShare}>
                            {
                                this.state.loading ?
                                    <ButtonLoading/> : this.state.step === "share" ? "Next" : "Share post"
                            }
                        </button>
                    </div>
                }
            >
                {
                    canShare && this.state.step === "share" &&
                    <div>
                        {/* <p className="zero-dark-grey">Select the teams you'd like to share this post with:</p> */}
                        {
                            this.state.loadingShareOptions &&
                            <div className="form-group mar-btm-0">
                                <div style={{width: "50%", display: "inline-block"}}>
                                    <span className="blue-link"><Skeleton width={160}/></span>
                                    <div className="subscribers-checkbox" style={{marginTop: "8px"}}>
                                        {
                                            [...Array(5)].map((e, index) => (
                                                <div key={index} className="form-check" style={{height: "30px"}}>
                                                    <Skeleton width={120}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div style={{width: "50%", display: "inline-block"}}>
                                    <span className="blue-link"><Skeleton width={160}/></span>
                                    <div className="subscribers-checkbox" style={{marginTop: "8px"}}>
                                        {
                                            [...Array(5)].map((e, index) => (
                                                <div key={index} className="form-check">
                                                    <Skeleton width={120}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !this.state.loadingShareOptions &&
                            <div className="form-group mar-btm-0">
                                <div style={{width: "50%", display: "inline-block", verticalAlign: "top"}}>
                                    <p style={{fontWeight: "600"}}>Source Team</p>
                                    <div className="mar-btm-15" style={{marginTop: "8px"}}>
                                        <Checkbox
                                            checked={true}
                                            value={this.props.source_team.team_uuid}
                                            disabled={true}
                                        >
                                            {this.props.source_team.name}
                                        </Checkbox>
                                    </div>
                                    <p style={{fontWeight: "600"}}>Shared With</p>
                                    {/* <span className="blue-link" onClick={this.selectAllOriginalTeams}>Select All</span> &bull; <span className="blue-link" onClick={this.selectNoneOrignalTeams}>Select None</span> */}
                                    <div className="subscribers-checkbox" style={{marginTop: "8px"}}>
                                        {
                                            safe_get(this.state, "original_team_options", []).map((team, index) => {
                                                if (team.team_uuid !== this.props.source_team.team_uuid) {
                                                    return (
                                                        <div key={index} className="form-check">
                                                            <Checkbox
                                                                onChange={this.originalTeamsSelect}
                                                                checked={this.state.original_selected_teams.includes(team.team_uuid)}
                                                                value={team.team_uuid}
                                                                disabled={team.team_uuid === this.props.source_team.team_uuid || team.disabled}
                                                            >
                                                                {team.name}
                                                            </Checkbox>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            safe_get(this.state, "original_team_options", []).length - 1 <= 0 &&
                                            <p className="zero-dark-grey">No Teams.</p>
                                        }
                                    </div>
                                </div>
                                <div style={{width: "50%", display: "inline-block"}}>
                                    <p style={{fontWeight: "600"}}>Select Teams to Share With</p>
                                    {
                                        safe_get(this.state, "team_options", []).length > 0 &&
                                        <>
                                            <span className="blue-link"
                                                  onClick={this.selectAllTeams}>Select All</span> &bull; <span
                                            className="blue-link" onClick={this.selectNone}>Select None</span>
                                        </>
                                    }
                                    <div className="subscribers-checkbox" style={{marginTop: "8px"}}>
                                        {
                                            safe_get(this.state, "team_options", []).length > 0 &&
                                            safe_get(this.state, "team_options", []).map((team, index) => (
                                                <div key={index} className="form-check">
                                                    <Checkbox
                                                        onChange={this.teamSelect}
                                                        checked={this.state.selected_teams.includes(team.team_uuid)}
                                                        value={team.team_uuid}
                                                        disabled={team.team_uuid === this.props.source_team.team_uuid || team.disabled}
                                                    >
                                                        {team.name}
                                                    </Checkbox>
                                                </div>
                                            ))
                                        }
                                        {
                                            safe_get(this.state, "team_options", []).length <= 0 &&
                                            <p className="zero-dark-grey">No Teams.</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.step === "subscribe" &&
                    <div>
                        <p className="zero-dark-grey">When I share this post, the following people will be notified:</p>
                        <span className="blue-link" onClick={this.selectAllUsers}>Select everyone</span> &bull; <span
                        className="blue-link" onClick={this.selectNoOne}>Select no one</span>
                        <div className="subscribers-checkbox" style={{marginTop: "8px"}}>
                            {
                                this.state.subscriber_options.map((user, index) => (
                                    <div key={index} className="form-check" style={{height: "30px"}}>
                                        <Checkbox
                                            onChange={this.handleUserSelect}
                                            checked={this.state.selected_users.includes(user.uuid)}
                                            value={user.uuid}
                                        >
                                            {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                            {user.first_name} {user.last_name}
                                        </Checkbox>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    !canShare &&
                    <p className="zero-dark-grey">No teams available to share with. You're only a member of one
                        team.</p>
                }
            </Modal>)
        );
    }

}

export default SharePostModal;