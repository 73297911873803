import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Checkbox, Space} from 'antd';

import {setSurveyCompleted} from 'store/slices/checkInOptions.js';
import {CheckInUserType} from "../../other/Constants";


export default function CheckInSurvey({surveyLoader, existingCheckIn, selectedUserType, checkInForToday}) {
    const surveyCompleted = useSelector(state => state.checkInOptions.surveyCompleted);
    const selectedDesk = useSelector(state => state.checkInOptions.selectedDesk);
    const [checkedCount, setCheckedCount] = useState(0);
    const [checkboxState, setCheckboxState] = useState([]);
    const dispatch = useDispatch();

    const handleCheckboxChange = (e, index) => {
        const checked = e.target.checked;

        const newCheckboxState = [...checkboxState];
        newCheckboxState[index] = checked;
        setCheckboxState(newCheckboxState);

        if (checked) {
            setCheckedCount(checkedCount + 1);
        } else {
            setCheckedCount(checkedCount - 1);
        }
    }

    useEffect(() => {
        if (surveyLoader.ok && (surveyLoader.survey === null || surveyLoader.survey.questions.length === checkedCount)) {
            if (!surveyCompleted) dispatch(setSurveyCompleted(true));
        } else {
            if (surveyCompleted) dispatch(setSurveyCompleted(false));
        }
    }, [checkedCount, surveyLoader]);

    useEffect(() => {
        setCheckboxState([]);
        setCheckedCount(0);
    }, [selectedDesk]);

    if (!surveyLoader.error && !surveyLoader.ok) {
        return <div>Loading...</div>
    } else if (surveyLoader.error) {
        return <div>Could not load survey.</div>
    } else if (surveyLoader.survey === null) {
        return <div>No survey for this location. Please click "Check In" below to proceed.</div>
    } else if (selectedUserType === CheckInUserType.SELF && !checkInForToday) {
        return <div>You will need to complete a check-in survey for each day that you come to the office. You will
            receive an email notification to complete the survey on the selected dates.</div>
        // else if (selectedUserType === CheckInUserType.GUEST && !checkInForToday) {
        //     return <div>You will need to complete a check-in survey for each day that you come to the office. You will receive an email notification to complete the survey on the selected dates.</div>
    } else if (!existingCheckIn && selectedUserType === CheckInUserType.USER) {
        return <div>Your colleague will need to complete a check-in survey in order to come to the office. Your
            colleague will receive an email notification to complete the survey.</div>
    } else if (selectedUserType === CheckInUserType.GUEST) {
        return <div>Your guest will need to complete a check-in survey in order to come to the office.</div>
    } else {
        return (
            <div className="zero-dark-grey">
                <Space direction="vertical" size={4}>
                    {surveyLoader.survey.start_paragraph &&
                        <span
                            className="location-survey"
                            style={{margin: '0 0 1rem 0'}}
                            dangerouslySetInnerHTML={{__html: surveyLoader.survey.start_paragraph}}
                        ></span>
                    }
                    {
                        surveyLoader.survey.questions.map((question, index) => (
                            <Checkbox
                                key={index}
                                checked={checkboxState[index]}
                                onChange={e => handleCheckboxChange(e, index)}
                                className="zero-dark-grey"
                            >
                                {question}
                            </Checkbox>
                        ))
                    }
                    {surveyLoader.survey.end_paragraph &&
                        <span
                            className="location-survey"
                            style={{margin: '1rem 0 0 0'}}
                            dangerouslySetInnerHTML={{__html: surveyLoader.survey.end_paragraph}}
                        ></span>
                    }
                </Space>
            </div>
        );
    }
}
