import React from 'react';
import PropTypes from 'prop-types'

import {Checkbox} from "antd";
import InfoIconComponent from "../InfoIconComponent";
import {isAdmin, isTeamLead} from "../../other/Helper";

function UrgentPostCheckbox(props) {
    const {checked = false, onChange, user, style = {marginTop: '0.5rem'}} = props;
    const infoText = "[Admin and Team Lead only] If this box is checked, ZERO will override user notification settings and send an urgent notification to all subscribers via email, push, and SMS.";

    if (!isAdmin(user) && !isTeamLead(user)) return null;

    return (
        <Checkbox style={style} checked={checked} onChange={onChange}>
            <>Urgent post</>
            <InfoIconComponent position={"top"} width={"350px"} text={infoText}/>
        </Checkbox>
    )
}

UrgentPostCheckbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    style: PropTypes.object
}

export default UrgentPostCheckbox;