import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Link} from 'react-router-dom';

import {Modal, Select} from 'antd';

import Skeleton from 'react-loading-skeleton'

import NotificationAlert from '../../../other/NotificationAlert.js';
import ButtonLoading from '../../Shared/ButtonLoading';

import {update_location} from '../../../api/zero-api.js'
import {
    safe_get,
    safeProfilePic,
    isAdmin,
    isTeamLead,
    isContributor,
    isViewer,
    isMem,
    isUser
} from '../../../other/Helper'

import '../../../assets/css/antd-custom.css'

class LocationMembersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: props.locationUsers,
            loadingUsers: false,
        };
    }

    componentDidMount() {
    }

    removeUser = (userIndex) => {
        let self = this;

        let {
            name, street_address, city,
            state, zip, industry_description,
            naics, number_of_employees, hours_worked,
            access_list, reset_used_desks,
            incidents_enabled, spaces_enabled,
            location_uuid
        } = this.props.location

        let users = [...this.state.users];
        users.splice(userIndex, 1);

        let user_uuids = users.map(x => x.uuid);

        let body = JSON.stringify({
            name: name,
            street_address: street_address,
            city: city,
            state: state,
            zip: zip,
            industry_description: industry_description,
            naics: naics,
            number_of_employees: parseInt(number_of_employees),
            hours_worked: parseInt(hours_worked),
            access_list_user_uuids: user_uuids,
            reset_used_desks,
            incidents_enabled,
            spaces_enabled,
        });

        update_location(location_uuid, body).then(function (_success) {
            NotificationAlert("success", "", "User Removed.");
            self.setState({users: users});
            self.props.callback();
        }, function (_error) {
            NotificationAlert("success", "", "Unable to remove user.");
            self.props.cancel();
        });
    }

    render() {
        return (
            (<Modal
                title={`Location Managers (${this.state.users.length})`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        {/* <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button> */}
                        <button className="btn btn-modal" onClick={this.props.cancel} disabled={this.state.updating}>
                            {
                                this.state.updating ? <ButtonLoading/> : "Done"
                            }
                        </button>
                    </div>
                }
            >
                <div className="views-modal" style={{overflowX: "hidden"}}>
                    {
                        this.state.loadingUsers &&
                        this.state.users.map((_, index) => (
                            <div key={index} className="panel panel-light panel-colorful user-card-size thin-border"
                                 style={{maxHeight: '80px'}}>
                                <div style={{padding: "10px"}}>
                                    <div className="media">
                                        <div className="media-left profile-img">
                                            <Skeleton circle={true} width={65} height={65}/>
                                        </div>
                                        <div className="media-body account-card " style={{verticalAlign: 'middle'}}>
                                            <Skeleton width={150}/>
                                            <br/>
                                            <Skeleton width={100}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        !this.state.loadingUsers &&
                        this.state.users.map((user, index) => (
                            <div key={index} className="panel panel-light panel-colorful user-card-size thin-border"
                                 style={{maxHeight: '80px'}}>
                                <div style={{padding: "10px"}}>
                                    <div className="media">
                                        <div className="media-left profile-img">
                                            <Link to={"/" + this.props.org_uuid + "/users/" + user.uuid}>
                                                {safeProfilePic(user, "img-sm img-circle views", "views")}
                                            </Link>
                                        </div>
                                        <div className="media-body account-card " style={{verticalAlign: 'middle'}}>

                                            <Link className="zero-blue"
                                                  to={"/" + this.props.org_uuid + "/users/" + user.uuid}
                                                  style={{lineHeight: "1em"}}>
                                                <p className="text-md text-semibold profile truncate name zero-blue"
                                                   style={{
                                                       display: 'inline-block',
                                                       marginBottom: '0px',
                                                       marginLeft: '2px',
                                                       maxWidth: "145px"
                                                   }}>
                                                    {user.first_name} {user.last_name}
                                                </p>
                                            </Link>
                                            {
                                                isAdmin(user) ?
                                                    <p className="role-text zero-dark-grey" style={{fontSize: "0.9em"}}>
                                                        <span className="badge badge-success badge-icon badge-fw "
                                                              style={{margin: "2px 5px 5px 2px",}}></span>Admin</p> :
                                                    isTeamLead(user) ? <p className="role-text zero-dark-grey"
                                                                          style={{fontSize: "0.9em"}}><span
                                                            className="badge badge-info badge-icon badge-fw "
                                                            style={{margin: "2px 5px 5px 2px"}}></span>Lead</p> :
                                                        isViewer(user) ? <p className="role-text zero-dark-grey"
                                                                            style={{fontSize: "0.9em"}}><span
                                                                className="badge badge-danger badge-icon badge-fw"
                                                                style={{margin: "2px 5px 5px 2px"}}></span>Viewer</p> :
                                                            isContributor(user) ?
                                                                <p className="role-text zero-dark-grey"
                                                                   style={{fontSize: "0.9em"}}><span
                                                                    className="badge badge-warning badge-icon badge-fw"
                                                                    style={{margin: "2px 5px 5px 2px"}}></span>Contributor
                                                                </p> :
                                                                <p className="role-text zero-dark-grey"
                                                                   style={{fontSize: "0.9em"}}><span
                                                                    className="badge badge-purple badge-icon badge-fw "
                                                                    style={{margin: "2px 5px 5px 2px"}}></span>Member
                                                                </p>
                                            }
                                        </div>
                                        <div className="media-right pad-0" style={{verticalAlign: "middle"}}>
                                            <button className="btn btn-delete" onClick={() => {
                                                this.removeUser(index);
                                            }}>Remove
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        user: safe_get(store, "user.user", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
    }
}

export default connect(mapStateToProps)(LocationMembersModal);