import React, {Component} from 'react';

import {Select, Modal} from 'antd'
import '../../assets/css/antd-custom.css'

class SelectLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_location: undefined
        };

        this.handleLocationSelect = this.handleLocationSelect.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {

    }

    handleLocationSelect(value) {
        this.setState({
            selected_location: value
        });
    }

    confirm() {
        this.props.cancel();
        this.props.confirm(this.state.selected_location);
    }

    render() {
        return (
            (<Modal
                title="Select a location"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName='maskTransitionName'
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.confirm}
                                disabled={this.state.selected_location === undefined}>
                            Next
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">Select where to log this incident:</p>
                <Select
                    style={{width: "100%",}}
                    className="move-team-select"
                    showArrow={true}
                    placeholder="Select a location"
                    dropdownStyle={{zIndex: "10000"}}
                    value={this.state.selected_location}
                    onChange={this.handleLocationSelect.bind(this)}>
                    {
                        this.props.locations.map((location, index) => (
                            <Select.Option key={location.location_uuid}
                                           value={location.location_uuid}>{location.name}</Select.Option>
                        ))
                    }
                </Select>
            </Modal>)
        );
    }
}

export default SelectLocationModal;