// @ts-check
import { Input, Modal } from "antd";
import Button from "components/Shared/Button";
import { validateEmail } from "other/Helper";
import { useState } from "react";

export default function SaveDraftModal({onCancel, onSaveDraft}) {
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSave = () => {
        setIsSubmitting(true);
        setErrorMessage("");
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            setIsSubmitting(false);
        } else {
            onSaveDraft(email)
            .catch((msg) => setErrorMessage(msg))
            .finally(() => {
                setIsSubmitting(false);
            });
        }
    }

    return (
        <Modal
            open
            title="Save Draft?"
            onCancel={onCancel}
            maskClosable={false}
            maskTransitionName="maskTransitionName"
            footer={
                <div>
                    <Button type="discard" disabled={isSubmitting} onClick={onCancel}>Cancel</Button>
                    <Button type="modal" disabled={isSubmitting} loading={isSubmitting} onClick={handleSave}>Save and send draft</Button>
                </div>
            }
        >
            <p className="zero-dark-grey">This form has not been submitted and contains unsaved changes. You can save it as a draft to submit later.</p>
            <p className="zero-dark-grey">Please enter an email address where we can send a link to your saved draft.</p>
            <label style={{width: '100%'}}>
                <span className="text-bold">Email:</span>
                <Input
                    type="email"
                    placeholder="name@company.com"
                    value={email}
                    status={errorMessage ? "error" : ""}
                    onChange={(e) => setEmail(e.target.value)}
                />
                { errorMessage &&
                    <small className="error">{errorMessage}</small>
                }
            </label>
        </Modal>
    )
}