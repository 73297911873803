import Header from 'components/Navigation/Header';
import InlineSideBar from 'components/Navigation/InlineSideBar';
import PublicFormSubmissionPage from 'components/Public/PublicFormSubmission/PublicFormSubmissionPage';
import useIsDesktopSize from 'hooks/useIsDesktopSize';
import { useEffect, useState } from 'react';
import {Route, useParams} from 'react-router';
import {Switch} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { is_desktop_size } from 'store/actions/AppActions';
import SideBar from 'components/Navigation/SideBar';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Public404Page from 'components/Public/Public404Page';
import ZeroContextManager from 'components/ZeroContext';
import OfflineDataCaches from 'components/Offline/OfflineDataCaches';
import { useAsyncDataLoader } from 'hooks/useAsyncDataLoader';
import { update_current_org } from 'store/actions/OrganizationActions';
import { update_current_user } from 'store/actions/UserActions';
import { update_current_team } from 'store/actions/TeamsActions';
import { update_main_loading } from 'store/actions/AppActions';
import { publicOrgFromTeamId } from 'api/zero-api';
import { isPublicUser } from 'other/Helper';
import { PublicContext } from 'components/Public/PublicContext';

function PublicPageWrapper({loaderStatus, publicOrgId, children}) {
    if (loaderStatus === "success") {
        return (
            <OfflineDataCaches>
                <ZeroContextManager>
                    <PublicContext.Provider value={{publicOrgId}}>
                        {children}
                    </PublicContext.Provider>
                </ZeroContextManager>
            </OfflineDataCaches>
        )
    } else if (loaderStatus === "error") {
        throw new Error("Failed to setup data for public router.");
    }

    return null;
}


export default function PublicRouter() {
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const user = useSelector(state => state.user.user);
    const { teamId } = useParams();
    const isDesktopSize = useIsDesktopSize();
    const dispatch = useDispatch();
    const asyncLoader = useAsyncDataLoader();
    const [publicOrgId, setPublicOrgId] = useState("");

    useEffect(() => {
        if (teamId) {
            asyncLoader.load(async () => {
                dispatch(update_main_loading(true));
                const orgRes = await publicOrgFromTeamId(teamId);
                const { organization, team, user: publicUser } = await orgRes.json();
                dispatch(update_main_loading(false));

                setPublicOrgId(organization.organization_uuid);

                if (Object.keys(user).length === 0 || isPublicUser(user)) {
                    dispatch(update_current_org(organization));
                    dispatch(update_current_team(team));
                    dispatch(update_current_user(publicUser));
                }
            });
        }
    }, [teamId]);

    useEffect(() => {
        dispatch(is_desktop_size(isDesktopSize));
        if (isDesktopSize) {
            setSideMenuOpen(false);
        }
    }, [isDesktopSize]);

    let containerClassList = "effect aside-bright aside-float";
    if (isDesktopSize) {
        containerClassList += " mainnav-lg mainnav-fixed";
    } else if (sideMenuOpen) {
        containerClassList += " mainnav-in";
    }

    return (
        <PublicPageWrapper loaderStatus={asyncLoader.status} publicOrgId={publicOrgId}>
            <div>
                <div id="container" className={containerClassList}>
                    <div className="boxed">
                        <Header
                            forPublicView={true}
                            toggleSideMenu={setSideMenuOpen}
                            openAside={false}
                            openSettings={false}
                            asideOpen={false}
                        />
                        <div
                            id="content-container"
                            className="container-with-header"
                            style={{ maxWidth: "1100px" }}
                        >
                            { isDesktopSize &&
                                <InlineSideBar
                                    isOffline={false}
                                    isPublicView={true}
                                    draftErrorCount={0}
                                />
                            }
                            <Switch>
                                <Route exact path={`/public/:teamId/forms/submissions/:submissionId`} component={PublicFormSubmissionPage} />
                                <Route exact path={`/public/:teamId/forms/:formId`} component={PublicFormSubmissionPage} />
                                <Route exact path={`/public/404`} component={Public404Page} />
                                <Redirect to={`/public/404`} />
                            </Switch>
                        </div>
                        { sideMenuOpen &&
                            <SideBar
                                isOffline={false}
                                isPublicView={true}
                                draftErrorCount={0}
                                closeSideMenu={() => setSideMenuOpen(false)}
                            />
                        }
                    </div>
                </div>
            </div>
        </PublicPageWrapper>
    )
}