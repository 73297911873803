// @ts-check

import { AgGridReact } from "@ag-grid-community/react";
import "../../assets/css/ag-grid-theme-builder.css";

/** @type {AgGridReactLib.AgGridReactProps['excelStyles']} */
const excelStyles = [
    {
        id: "cell",
        alignment: {
            horizontal: "Left",
        }
    },
    {
        id: "header",
        font: {
            bold: true,
        }
    },
    {
        id: 'stringType',
        dataType: 'String',
    },
    {
        id: 'dateFormatterNoTime',
        dataType: "DateTime",
        numberFormat: {
            format: "mmm d, yyyy"
        }
    }
]

export default function ZeroDataGrid({gridRef, ...props}) {
    return (
        <div className="ag-theme-custom">
            <AgGridReact
                domLayout="autoHeight"
                rowModelType="infinite"
                excelStyles={excelStyles}
                rowBuffer={0}
                cacheBlockSize={100}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={1}
                infiniteInitialRowCount={60}
                maxBlocksInCache={10}
                ref={gridRef}
                maintainColumnOrder={true}
                suppressMultiSort={true}
                {...props}
            />
        </div>
    );
}
