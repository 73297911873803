import {useMemo} from "react";
import {useLocation} from "react-router";

export function useIsOfflineRoute() {
    const location = useLocation();

    const isOfflineRoute = useMemo(() => {
        return location?.pathname?.includes('/offline/') || false;
    }, [location?.pathname]);

    return isOfflineRoute;
}