export const AgGridFilter = {
    text: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
        },
    },
    date: {
        filter: 'agDateColumnFilter',
        filterParams: {
            maxNumConditions: 1,
        },
    },
    contains: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: ['contains', 'notContains',],
        },
    },
};