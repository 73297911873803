import React, {Component} from 'react';

import {
    get_list_users,
    create_report_schedule,
    update_report_schedule,
    patch_report_schedule
} from '../../../api/zero-api.js'
import {safe_get, safeProfilePic, isViewer, intToMonth} from '../../../other/Helper.js';

import NotificationAlert from '../../../other/NotificationAlert';
import ButtonLoading from '../../Shared/ButtonLoading.js';

import moment from 'moment';

import {Select, DatePicker, TimePicker, Modal, Popconfirm} from 'antd'
import {CloseCircleOutlined} from '@ant-design/icons';

import LoadingIndicator from '../../Shared/LoadingIndicator.js';

import '../../../assets/css/antd-custom.css';

var searchTimeout = null;

class ReportScheduleModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            repeat_period: "daily",
            start_time: "05:00:00",
            users_list: [],
            recipients_uuids: [],
            current_recipients: [],
            current_recipients_uuids: [],
        };

    }

    componentDidMount() {
        let self = this;

        console.log(this.props)
        let recipients_uuids = []
        let current_recipients = [];
        let current_recipients_uuids = [];

        if (this.props.isCreate) {
            recipients_uuids.push(this.props.user.uuid);
        } else if (this.props.schedule) {
            let custom_start_time = moment.unix(this.props.schedule.start_utc_timestamp).format("HH:mm:ss");

            this.setState({
                repeat_period: this.props.schedule.repeat_period,
                start_time: custom_start_time,
            });

            safe_get(this.props, "schedule.subscribers", []).forEach(user => {
                current_recipients.push(user);
                current_recipients_uuids.push(user.uuid);
            });

        }

        get_list_users("?status=active&roles=admin,team_lead").then(success => {
            success.json().then(success => {

                let users = safe_get(success, "users", []).filter(function (user) {
                    return user.status === "active" && user.uuid && !current_recipients_uuids.includes(user.uuid)
                }).sort(function (a, b) {
                    let a_name = (safe_get(a, "first_name", "") + " " + safe_get(a, "last_name", "")).toLowerCase();
                    let b_name = (safe_get(b, "first_name", "") + " " + safe_get(b, "last_name", "")).toLowerCase();

                    return a_name > b_name ? 1 : -1;
                });

                self.setState({
                    users_list: users,
                    recipients_uuids: recipients_uuids,
                    current_recipients: current_recipients,
                    current_recipients_uuids: current_recipients_uuids,
                    loading: false
                });

            });
        });
    }

    handlePeriodSelect = (value) => {
        this.setState({
            repeat_period: value
        });
    }

    handleStartTimeSelect = (value) => {
        // let custom_start_time = `${value}:00:00`;
        // this.setState({ custom_start_time: custom_start_time });
        this.setState({start_time: value});
    }

    handleUserSelect(value) {
        this.setState({recipients_uuids: value});
    }

    removeCurrentRecipient = (index, allMembers = false) => {
        if (allMembers) {
            this.toggleAssignAll(false);
        } else {
            let current_recipients = [...this.state.current_recipients];
            let current_recipients_uuids = [...this.state.current_recipients_uuids];

            current_recipients.splice(index, 1);
            current_recipients_uuids.splice(index, 1);

            this.setState({
                current_recipients: current_recipients,
                current_recipients_uuids: current_recipients_uuids
            });
        }
    }

    createSchedule = () => {
        let self = this;

        this.setState({submitting: true});

        let body = JSON.stringify({
            enabled: true,
            history_uuid: this.props.history_uuid,
            report_type: this.props.reportType,
            repeat_period: this.state.repeat_period,
            start_time: this.state.start_time,
            start_timezone: this.props.user.timezone,
            report_params: this.props.reportParams,
            subscriber_uuids: [...new Set(this.state.current_recipients_uuids.concat(this.state.recipients_uuids))]
        });

        create_report_schedule(body).then(success => {
            if (self.props.goToHistory) {
                self.props.goToHistory();
            } else if (self.props.refresh) {
                self.props.refresh();
            }
            NotificationAlert("success", "", "Schedule created.");
            self.props.cancel();
        }, error => {
            self.setState({submitting: false});
        });
    }

    updateSchedule = () => {
        let self = this;

        this.setState({submitting: true});

        let body = JSON.stringify({
            enabled: true,
            report_type: this.props.reportType,
            repeat_period: this.state.repeat_period,
            start_time: this.state.start_time,
            start_timezone: this.props.user.timezone,
            report_params: this.props.reportParams,
            subscriber_uuids: [...new Set(this.state.current_recipients_uuids.concat(this.state.recipients_uuids))]
        });

        update_report_schedule(this.props.schedule.report_schedule_uuid, body).then(success => {
            if (self.props.refresh) {
                self.props.refresh();
            }
            NotificationAlert("success", "", "Schedule updated.");
            self.props.cancel();
        }, error => {
            self.setState({submitting: false});
        });

    }

    turnOffSchedule = () => {
        let self = this;

        let body = JSON.stringify({
            enabled: false,
            report_type: this.props.reportType,
            repeat_period: this.state.repeat_period,
            start_time: this.state.start_time,
            start_timezone: this.props.user.timezone,
        });

        patch_report_schedule(this.props.schedule.report_schedule_uuid, body).then(success => {
            if (self.props.refresh) {
                self.props.refresh();
            }
            NotificationAlert("success", "", "Schedule turned off.");
            self.props.cancel();
        }, errro => {

        })
    }

    reportPeriodFormatter(report_params) {
        let params = report_params;
        let period = params.period;

        switch (period) {
            case "all_time":
                period = "All Time";
                break;
            case "month":
                period = `Monthly (${intToMonth(params.month)} ${params.year})`;
                break;
            case "quarter":
                period = `Quarterly (Q${params.quarter} ${params.year})`;
                break;
            case "annual":
                period = `Annual (${params.year})`;
                break;
            case "custom":
                let a = moment(params.from_date)
                let b = moment(params.to_date)
                if (a.diff(b, 'days') === -6) {
                    period = `Weekly (${a.format("MM/DD/YYYY")} - ${b.format("MM/DD/YYYY")})`;
                } else {
                    period = `Custom (${a.format("MM/DD/YYYY")} - ${b.format("MM/DD/YYYY")})`;
                }
                break;
            default:
                break;
        }

        return (
            <span style={{textTransform: "capitalize"}}>{period}</span>
        )
    }

    render() {
        let h3Style = {marginBottom: '2px', marginLeft: '0px', fontSize: '100%', marginTop: "15px"}
        let now = moment(new Date, "HH:mm:ss");

        return (
            (<Modal
                title={this.props.isCreate ? "Create a schedule" : "Edit schedule"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        {/* {
                      // this.state.error_msg &&
                      <small className="error" style={{display: "block", marginBottom: "5px"}}>error</small>
                  } */}
                        {
                            !this.props.isCreate &&
                            <Popconfirm
                                title="Are you sure you want to turn off this schedule?"
                                onConfirm={this.turnOffSchedule}
                                okText="Yes"
                                cancelText="No"
                                cancelButtonProps={{
                                    className: "discard"
                                }}
                            >
                                <button className="ButtonLink zero-light-blue link-hover pull-left">Turn off schedule
                                </button>
                            </Popconfirm>
                        }
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        {
                            this.state.submitting &&
                            <button className="btn btn-modal">
                                <ButtonLoading/>
                            </button>
                        }
                        {
                            !this.state.submitting &&
                            <button className="btn btn-modal"
                                    onClick={this.props.isCreate ? this.createSchedule : this.updateSchedule}>
                                {this.props.isCreate ? "Create schedule" : "Update schedule"}
                            </button>
                        }
                    </div>
                }
            >
                {
                    this.state.loading &&
                    <LoadingIndicator/>
                }
                {
                    !this.state.loading &&
                    <div>
                        <h3 className="titles" style={{
                            marginBottom: '2px',
                            marginLeft: '0px',
                            fontSize: '110%',
                            marginTop: "0px",
                            textTransform: "capitalize"
                        }}>
                            {this.props.reportType === "submission" ? "Template" : this.props.reportType} report
                            - {this.reportPeriodFormatter(this.props.reportParams)}
                        </h3>

                        <div style={{verticalAlign: "bottom", display: "inline-block", width: "100%"}}>
                            <h3 className="titles required" style={h3Style}>When do you want to receive this
                                report?</h3>
                            <div style={{marginLeft: '0px', display: "inline-block", width: "50%"}}>
                                <Select
                                    style={{width: "100%"}}
                                    className="move-team-select"
                                    showArrow={true}
                                    placeholder="Select a repeat period"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.repeat_period}
                                    onChange={this.handlePeriodSelect}
                                    disabled={this.state.is_edit}
                                >
                                    <Select.Option value={"daily"}>Send Every Day</Select.Option>
                                    <Select.Option value={"weekday"}>Send Every Weekday</Select.Option>
                                    <Select.Option value={"weekly"}>Send Every Week</Select.Option>
                                    <Select.Option value={"monthly"}>Send Every Month</Select.Option>
                                </Select>
                            </div>
                            <div style={{marginLeft: '1%', display: "inline-block", width: "49%"}}>
                                <p className="zero-dark-grey mar-btm-0" style={{
                                    position: "absolute",
                                    paddingTop: "5px",
                                    paddingLeft: "10px",
                                    zIndex: "100"
                                }}>at</p>
                                <Select
                                    style={{width: "100%"}}
                                    className="schedule-time-picker"
                                    showArrow={true}
                                    placeholder="Select a time"
                                    dropdownStyle={{zIndex: "10000"}}
                                    value={this.state.start_time}
                                    onChange={this.handleStartTimeSelect}
                                >
                                    <Select.Option value={"05:00:00"}>5 AM</Select.Option>
                                    <Select.Option value={"06:00:00"}>6 AM</Select.Option>
                                    <Select.Option value={"07:00:00"}>7 AM</Select.Option>
                                    <Select.Option value={"08:00:00"}>8 AM</Select.Option>
                                    <Select.Option value={"09:00:00"}>9 AM</Select.Option>
                                    <Select.Option value={"10:00:00"}>10 AM</Select.Option>
                                    <Select.Option value={"11:00:00"}>11 AM</Select.Option>
                                    <Select.Option value={"12:00:00"}>12 PM</Select.Option>
                                    <Select.Option value={"13:00:00"}>1 PM</Select.Option>
                                    <Select.Option value={"14:00:00"}>2 PM</Select.Option>
                                    <Select.Option value={"15:00:00"}>3 PM</Select.Option>
                                    <Select.Option value={"16:00:00"}>4 PM</Select.Option>
                                    <Select.Option value={"17:00:00"}>5 PM</Select.Option>
                                </Select>
                            </div>
                        </div>
                        <h3 className="titles" style={h3Style}>Who should receive this report?</h3>
                        <Select
                            id="invite-emails"
                            mode="multiple"
                            value={this.state.recipients_uuids}
                            placeholder="Type a name..."
                            onChange={this.handleUserSelect.bind(this)}
                            className="user-select-dropdown"
                            style={{width: '100%', display: 'block', margin: '0 auto'}}
                            labelInValue={false}
                            dropdownStyle={{zIndex: "10000"}}
                            loading={this.state.loading}
                            optionLabelProp="user"
                            filterOption={(inputValue, option) => {
                                return option.name.toLowerCase().includes(inputValue.toLowerCase())
                            }}
                        >
                            {
                                this.state.users_list.map((user, index) => (
                                    <Select.Option style={{marginBottom: '5px'}} key={"user: " + user.uuid}
                                                   value={user.uuid} user={
                                        <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.first_name}</span>}
                                                   name={`${user.first_name} ${user.last_name}`}>
                                        {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                        {user.first_name} {user.last_name} &bull; <span
                                        style={{fontSize: '85%'}}>{user.email || user.title}</span>
                                    </Select.Option>
                                ))
                            }
                        </Select>

                        {
                            !this.props.isCreate &&
                            <div className="mar-top-10">
                                <p className="zero-blue" style={{fontWeight: "500", marginBottom: "4px"}}>Current
                                    Recipients:</p>
                                <div className="">
                                    {
                                        this.state.current_recipients.length === 0 &&
                                        <p>No recipients assigned.</p>
                                    }
                                    {
                                        this.state.current_recipients.length > 0 &&
                                        this.state.current_recipients.map((user, index) => (
                                            <li key={index} style={{
                                                listStyle: "none",
                                                display: "inline-block",
                                                marginRight: "5px",
                                                marginBottom: "5px",
                                                padding: "3px",
                                                backgroundColor: "#F6F6F6",
                                                border: "solid 1px #ECECEC",
                                                borderRadius: "3px"
                                            }}>
                                                <div>
                                                    {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                                    <div style={{
                                                        color: "#1d2a35",
                                                        display: "inline-block",
                                                        verticalAlign: "bottom"
                                                    }}
                                                         className="truncate name">{user.first_name + " " + user.last_name}</div>
                                                    <CloseCircleOutlined className="mar-lft-5 zero-blue"
                                                                         style={{verticalAlign: "middle"}}
                                                                         onClick={() => {
                                                                             this.removeCurrentRecipient(index);
                                                                         }}/>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </Modal>)
        );

    }

}

export default ReportScheduleModal;