import React, {useContext, useEffect, useState} from 'react';
import {configContext} from 'antd-country-phone-input';


function CountryCodeSorter(props) {
    const config = useContext(configContext);
    const [override, setOverride] = useState(true);

    useEffect(() => {
        if (config.areas.length > 200 && override) {
            let areas = [...config.areas];
            let sortedAreas = [
                areas[1],
                areas[0]
            ]

            areas.splice(0, 2);
            areas.sort((a, b) => a.name < b.name ? -1 : 1);

            config.areas = [
                ...sortedAreas,
                ...areas
            ];
            setOverride(false);
        }
    }, [config.areas, override]);

    return props.children;
}

export default CountryCodeSorter;