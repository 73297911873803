import {useEffect, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {Select} from 'antd';

import {get_dynamic_lists} from 'api/zero-api';

async function loadDynamicLists() {
    try {
        const res = await get_dynamic_lists();
        let lists = await res.json();
        lists = lists.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
        return {
            status: 'ok',
            lists,
            options: lists.map(list => ({
                label: list.name,
                value: list.uuid,
            })),
        }
    } catch (err) {
        return {
            status: 'error',
            lists: [],
            options: [],
        }
    }
}

export default function DynamicListSelector({selectedListId, onListSelected}) {
    const [fetchStatus, setFetchStatus] = useState('loading');
    const [lists, setLists] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedList, setSelectedList] = useState(null);

    // loads dynamic lists on component mount
    useEffect(() => {
        loadDynamicLists().then(fetchState => {
            unstable_batchedUpdates(() => {
                setFetchStatus(fetchState.status);
                setLists(fetchState.lists);
                setOptions(fetchState.options);
            })
        });
    }, []);

    // matches selected list UUID to fetched list item
    useEffect(() => {
        if (fetchStatus === 'ok') {
            const matchingList = lists.find(list => list.uuid === selectedListId);
            setSelectedList(matchingList || null);
        }
    }, [selectedListId, lists, fetchStatus]);

    const itemCountText = () => {
        let innerText = `are ${selectedList.item_count} response options`;

        if (selectedList.item_count === 1) {
            innerText = 'is 1 response option';
        }

        return `There ${innerText} in this list.`
    }

    return (
        <div>
            <div>
                <Select
                    style={{width: '100%', maxWidth: '45rem'}}
                    placeholder="Please select a list"
                    options={options}
                    loading={fetchStatus === 'loading'}
                    disabled={fetchStatus !== 'ok'}
                    virtual={false}
                    dropdownStyle={{zIndex: '1200'}}
                    onChange={onListSelected}
                    value={selectedList?.uuid || null}
                />
            </div>
            {fetchStatus === 'error' &&
                <small className='error'>Could not load dynamic lists.</small>
            }
            {selectedListId && !selectedList &&
                <small className='error'>Previously selected list has been deleted.</small>
            }
            {selectedList &&
                <p className="zero-blue mar-btm-0 mar-top-10">{itemCountText()}</p>
            }
        </div>
    )
}