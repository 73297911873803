import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

import {submit_incident_form, update_submission, delete_submission} from '../../../api/zero-api.js';
import safe_get from '../../../other/SafeGet.js'

import NotificationAlert from '../../../other/NotificationAlert.js';
import InfoIconComponent from '../../InfoIconComponent.js';
import ConfirmModal from '../../Shared/ConfirmModal.js';

import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {Select, Collapse, Menu, Popover} from 'antd';

import FormFieldsRenderer from '../../Forms/FormFieldsRenderer.js';
import '../../../assets/css/form-builder.css'

class CustomForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            form_submissions: props.form_submissions,
            selected_form: {},
            selected_form_uuid: undefined,
            readyToSaveDraft: true,
            submission_found: false
        }

        this.saveDraft = this.saveDraft.bind(this);
        this.deleteSubmission = this.deleteSubmission.bind(this);
        this.submitForm = this.submitForm.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.form_submissions.length !== prevProps.form_submissions.length) {
            let form_submissions = [...this.props.form_submissions]
            form_submissions.sort((a, b) => {
                return a.created_at > b.created_at ? 1 : -1
            })
            this.setState({form_submissions: form_submissions});
        }
    }


    componentDidMount() {
        let form_submissions = [...this.props.form_submissions]
        form_submissions.sort((a, b) => {
            return a.created_at > b.created_at ? 1 : -1
        })

        this.setState({
            form_submissions: form_submissions,
            loading: false
        })
    }

    handleVisibleChange = (visible) => {
        this.setState({optionsVisible: visible});
    }

    handleFormSelect = (value) => {
        let form_uuid = value.key;
        let form = this.props.incident_forms.find(function (obj) {
            return obj.form_uuid === form_uuid
        });

        this.setState({
            selected_form: form,
            selected_form_uuid: form_uuid,
            optionsVisible: false
        }, () => {
            this.submitForm([])
        });
    }

    // handleFormChange(value) {
    //     let forms = this.props.incident_forms;

    //     var selected_form = {};
    //     for (var i in forms) {
    //         let form = forms[i];
    //         if (form.form_uuid === value) {
    //             selected_form = form;
    //             break;
    //         }
    //     }

    //     var submission_found = false;
    //     var form_submission = {}
    //     let submissions = this.props.form_submissions
    //     for (var j in submissions) {
    //         let submission = submissions[j]
    //         if (submission.form.form_uuid === value) {
    //             submission_found = true
    //             form_submission = submission
    //             break;
    //         }
    //     }

    //     this.setState({
    //         selected_form: selected_form,
    //         selected_form_uuid: value,
    //         submission_found: submission_found,
    //         form_submission: form_submission
    //     }, () => {
    //         this.submitForm([])
    //     });
    // }

    submitForm(answers) {
        var self = this;

        var answer_fields = [];
        let fields = this.state.selected_form.fields;

        for (var i in fields) {
            let field = fields[i]
            let element = field.element
            if (element !== "Header" && element !== "Paragraph" && element !== "LineBreak") {
                let answer = {
                    "id": field.id,
                    "label": field.label,
                    "element": field.element,
                    "name": field.name,
                    "value": [],
                }
                answer_fields.push(answer)
            }
        }

        var body = JSON.stringify({
            submission: {
                fields: answer_fields,
                form_uuid: this.state.selected_form_uuid
            }
        });

        submit_incident_form(this.props.incident_uuid, body).then(function (success) {
            success.json().then(success => {
                self.props.updateIncident()
                // self.setState({
                //     form_submission: safe_get(success, "submission", {}),  
                //     selected_form: safe_get(success, "submission.form", {}),
                //     submission_found: true,
                // });

            });
        }, function (error) {
            NotificationAlert("success", "", "Unable to submit form.");
        });
    }

    saveDraft(answers, submission_uuid) {
        var self = this;

        if (this.state.readyToSaveDraft) {
            this.setState({readyToSaveDraft: false, showSavingText: true, showSavedText: false});

            var body = JSON.stringify({
                commit: false,
                fields: answers,
            });

            update_submission(submission_uuid, body).then(function (success) {
                success.json().then(success => {
                    self.setState({
                        readyToSaveDraft: true,
                        showSavedText: true,
                        showSavingText: false,
                        // form_submission: success.submission
                    });
                    self.props.updateIncident()
                });
            }, function (error) {
                self.setState({readyToSaveDraft: true, showSavingText: false});
            });

        } else {
            return
        }

    }

    deleteSubmission() {
        var self = this;
        delete_submission(this.state.delete_submission_uuid).then(function (succes) {
            self.props.updateIncident();
            setTimeout(() => {
                self.setState({
                    form_submission: {},
                    submission_found: false,
                    selected_form: {},
                    selected_form_uuid: undefined,
                    showModal: false
                });
            }, 500);

        }, function (error) {
            console.log(error);
        });
    }

    render() {
        const {fieldsDisabled} = this.props;

        var menu = (
            <Menu className="org-switch-menu" onClick={this.handleFormSelect} style={{overflowX: "hidden"}}>
                {
                    this.props.incident_forms.length > 0 &&
                    this.props.incident_forms.map((form, index) => (
                        <Menu.Item key={form.form_uuid} style={{display: "", width: "100%", cursor: "pointer"}}>
                            <p style={{width: "100%"}}>
                                {form.name}
                            </p>
                        </Menu.Item>
                    ))
                }
                {
                    this.props.incident_forms.length === 0 &&
                    <span>No custom forms.</span>
                }
            </Menu>
        );

        const deleteSubmissionIcon = (submission_uuid) => (
            <DeleteOutlined
                className="zero-delete-red"
                onClick={event => {
                    event.stopPropagation();
                    this.setState({showModal: true, delete_submission_uuid: submission_uuid});
                }}
            />
        );


        return (
            <div>
                {
                    this.state.showModal &&
                    <ConfirmModal
                        show={this.state.showModal}
                        cancel={() => {
                            this.setState({showModal: false});
                        }}
                        confirm={this.deleteSubmission}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this submission?"}
                        confirmButtonName={"Delete"}
                    />
                }
                <div className="mar-btm-10">
                    {
                        this.state.form_submissions.length > 0 && !this.props.print &&
                        <Collapse className="incident-collapse dont-print">
                            {
                                this.state.form_submissions.map((submission, index) => (
                                    <Collapse.Panel header={submission.form.name} key={index}
                                                    extra={fieldsDisabled ? null : deleteSubmissionIcon(submission.submission_uuid)}>
                                        <FormFieldsRenderer
                                            form_uuid={safe_get(submission, "form.form_uuid", "")}
                                            submission_uuid={safe_get(submission, "submission_uuid", "")}
                                            form_fields={safe_get(submission, "form.fields", [])}
                                            field_answers={safe_get(submission, "fields", [])}
                                            pages={[safe_get(submission, "form.fields", [])]}
                                            page={0}
                                            is_submission={!fieldsDisabled}
                                            updateAnswers={(answers) => this.saveDraft(answers, submission.submission_uuid)}
                                            isSubmissionView={fieldsDisabled}
                                            hideScores={fieldsDisabled}
                                        />
                                    </Collapse.Panel>
                                ))
                            }
                        </Collapse>
                    }
                    {
                        this.state.form_submissions.length > 0 && this.props.print &&
                        <div>
                            {
                                this.state.form_submissions.map((submission, index) => (
                                    <div className="incident-block">
                                        <h3 className="zero-blue print-only" style={{
                                            marginBottom: "20px",
                                            marginTop: "5px",
                                            fontSize: "120%",
                                            textDecoration: "underline"
                                        }}>
                                            Custom Form: {submission.form.name}
                                        </h3>
                                        <div style={{padding: '8px'}} key={index}>
                                            <FormFieldsRenderer
                                                form_uuid={safe_get(submission, "form.form_uuid", "")}
                                                submission_uuid={safe_get(submission, "submission_uuid", "")}
                                                form_fields={safe_get(submission, "form.fields", [])}
                                                field_answers={safe_get(submission, "fields", [])}
                                                pages={[safe_get(submission, "form.fields", [])]}
                                                page={0}
                                                updateAnswers={(answers) => this.saveDraft(answers, submission.submission_uuid)}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {!fieldsDisabled &&
                        <Popover placement={"bottomLeft"} content={menu} trigger="click" style={{position: "fixed"}}
                                 onOpenChange={this.handleVisibleChange} open={this.state.optionsVisible}>
                            <button className="ButtonLink blue-link mar-top-10 dont-print" style={{fontSize: "13px"}}>
                                <PlusCircleOutlined className="mar-rgt-5"/>
                                Add a custom form
                            </button>
                        </Popover>
                    }
                </div>


            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        current_team: safe_get(store, "teams_helper.team", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        all_assignments: safe_get(store, "assignments", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(CustomForm));

