import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';

import * as organizationActions from '../../../store/actions/OrganizationActions'
import * as userActions from '../../../store/actions/UserActions'

import {update_organization} from '../../../api/zero-api.js'
import {safe_get, isIE, fileUpload, beforeFileUpload, fileHasZeroSize} from '../../../other/Helper.js';
import NotificationAlert from '../../../other/NotificationAlert.js';
import ModalPopover from '../../ModalPopover.js';
import ButtonLoading from '../../Shared/ButtonLoading';
import ConfirmModal from '../../Shared/ConfirmModal';

import $ from 'jquery';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import {Upload, Modal} from 'antd';
import Previewer from '../../Previewer';

class OrganizationEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: true,
            editMode: false,
            super_admins: [],
            filtered_super_admins: [],
            previewVisible: false,
            previewImage: '',
            fileList: [],
            newOrgName: this.props.organization.name
        };
        this.edit = this.edit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.update = this.update.bind(this);

        this.updateOrgName = this.updateOrgName.bind(this);

        this.removeLogo = this.removeLogo.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.confirmDeleteLogo = this.confirmDeleteLogo.bind(this);

        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleImageSubmit = this.handleImageSubmit.bind(this);
    }

    componentDidMount() {
        var organization = this.props.organization;
        $("#account-name").prop("value", safe_get(organization, "name", ""));

        var newState = {};
        if (organization.logo) {
            var logo = {
                uid: safe_get(organization, "logo", ""),
                name: "org-logo.png",
                status: "done",
                url: safe_get(organization, "logo", ""),
                Location: safe_get(organization, "logo", "")
            }

            newState["fileList"] = [logo];
            newState["logo_url"] = logo.Location;
        }

        newState["organization_uuid"] = safe_get(organization, "organization_uuid", "undefined");
        newState["organization_name"] = safe_get(organization, "name", "");
        this.setState(newState);

        this.ObjectFit();
    }

    dateFormatter(post_date) {
        var revisedDate = new Date(post_date * 1000);
        var revised = revisedDate.toDateString();
        var formatDate = revised.split(" ");

        if (formatDate[3] > 2030) {
            revised = post_date.toDateString();
            formatDate = revised.split(" ");
        }

        revised = formatDate[1] + " " + formatDate[2] + ", " + formatDate[3];

        return revised;
    }

    ObjectFit() {
        if (isIE()) {
            var className = ".ant-upload-list-item-thumbnail";
            var height = "86px";

            setTimeout(() => {
                $(className).each(function () {
                    var imgSrc = $(this).attr('href');
                    var fitType = 'contain';
                    $(this).parent().css({
                        'background': 'transparent url("' + imgSrc + '") no-repeat center center /' + fitType,
                        'height': height
                    });
                    $(this).remove();
                });
            }, 150);

        }

    }

    beforeUpload(file) {
        if (fileHasZeroSize(file)) {
            return false;
        }
        var name = file.name.toLowerCase();
        if (name.indexOf(".png") < 0 && name.indexOf(".jpg") < 0 && name.indexOf(".jpeg") < 0) {
            this.setState({uploadError: 'Cannot upload this file type. Must be png, jpg, or jpeg.'});
            return false;
        }

        let [allow, error_msg] = beforeFileUpload(file, '', 0);

        if (allow) {
            return true;
        } else {
            this.setState({uploadError: error_msg});
            // this.props.uploadError(error_msg);
            return false;
        }
    }


    handleImageSubmit(file) {
        var self = this;

        var params = JSON.stringify({
            feature_key: "organization_profile",
            content_type: file.file.type,
            file_name: file.file.name,
            embedded: false
        })

        var uploading_file = {
            key: file.file.uid,
            uid: file.file.uid,
            status: "uploading",
            name: "Uploading...",
            percent: 0
        }

        var fileList = [uploading_file];
        this.setState({fileList: fileList});

        fileUpload(this, params, file, function (progress) {
            // upload progress
            var index = fileList.indexOf(uploading_file);
            if (index >= 0) {
                var file = fileList[index];
                file["percent"] = progress;
                fileList[index] = file;
                self.setState({fileList: fileList});
            }
        }, function (pre_signed_url, file_key) {

            var file_location = pre_signed_url + '/' + file_key

            var returned_file = {
                originFileObj: file.file,
                type: file.file.type,
                name: file.file.name,
                size: file.file.size,
                uid: file.file.uid
            }

            returned_file["status"] = "done";
            returned_file["percent"] = 100;
            returned_file["thumbUrl"] = file_location;

            self.setState({
                // logoData: data,
                logo_url: file_location,
                fileList: [returned_file]
            }, () => {
                var updated_org = self.props.organization;
                updated_org["logo"] = file_location;
    
                self.update();
                NotificationAlert("success", "", "Account logo updated.")
            });

        }, function (error) {
            console.log(error);
        });

    }


    handleCancel = () => this.setState({previewVisible: false})

    handlePreview = (file) => {
        var image = safe_get(this.props, "organization.logo_thumbnails.original", safe_get(file, "url", safe_get(this.props, "organization.logo", "")));
        this.setState({
            previewImage: image,
            previewVisible: true,
        });
    }

    removeLogo(file) {
        this.setState({
            showDeleteModal: true,
        });
    }

    cancelModal() {
        this.setState({showDeleteModal: false});
    }

    confirmDeleteLogo() {
        this.setState({logo_url: "", showDeleteModal: false, fileList: []}, () => {
            this.update()
        })
    }

    updateOrgName(e) {
        this.setState({newOrgName: e.target.value});
    }

    update() {
        var self = this;

        this.setState({saving: true});

        var body = JSON.stringify({
            name: this.state.newOrgName,
            logo: this.state.logo_url,
        })

        update_organization(body).then(function (success) {
            success.json().then(success => {

                let updated_org = safe_get(success, "organization", {});

                var orgs = self.props.organizations;
                var old_org = orgs.find(obj => {
                    return obj.organization_uuid === updated_org.organization_uuid;
                });

                var index = orgs.indexOf(old_org);
                if (index >= 0) {
                    orgs[index] = updated_org;
                }

                self.props.dispatch(organizationActions.update_organizations(orgs));
                self.props.dispatch(organizationActions.update_current_org(updated_org));

                NotificationAlert('success', "", "Changes have been saved.");
                self.setState({editMode: false, saving: false});
            });
        }, function (error) {
            if (error.status === 403) {
                self.setState({isAdmin: false})
                var user = self.props.user;
                var roles = safe_get(user, "roles", [])
                var index = roles.indexOf("admin");

                if (index > -1) {
                    roles.splice(index, 1)
                    user["roles"] = roles
                    self.props.dispatch(userActions.update_current_user(user));
                }
            } else {
                NotificationAlert('error', 'Oops!', "Could not save updates.");
                self.setState({saving: false});
            }
        });

    }

    edit() {
        this.setState({editMode: true}, () => {
            $("#account-name").val(this.props.organization.name);
            $("#account-name2").val(this.props.organization.name);
        });
    }

    cancel() {
        this.setState({editMode: false});
    }

    render() {
        const {previewVisible, previewImage, fileList} = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        if (!this.state.isAdmin) {
            return (
                <Redirect to="/"/>
            );
        } else {
            return (
                (<div className="panel pad-top pad-btm" style={{maxWidth: '800px', margin: '0 auto'}}>
                    {
                        this.state.showDeleteModal &&
                        <ConfirmModal
                            show={this.state.showDeleteModal}
                            cancel={() => {
                                this.setState({showDeleteModal: false});
                            }}
                            confirm={this.confirmDeleteLogo}
                            title={"Confirmation"}
                            body={"Are you sure you want to remove this logo?"}
                            confirmButtonName={"Remove"}
                        />
                    }
                    <div className="panel-heading" style={{height: "auto", minHeight: "auto",}}>
                        <h3 className="titles account" style={{marginRight: "15px", marginTop: "0px"}}>Account Logo</h3>
                        <p className="zero-dark-grey">Adding your logo is a way to visually identify your ZERO account
                            and add your brand to ZERO. Your logo is located in the sidebar above your profile picture
                            and is visible on the web and in the mobile app to all members on your account.</p>
                        <div style={{height: "105px"}}>
                            <Upload
                                action="/"
                                accept="image/*"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={this.handlePreview}
                                // onChange={this.handleChange}
                                onRemove={this.removeLogo}
                                beforeUpload={this.beforeUpload}
                                customRequest={this.handleImageSubmit}
                                className="org-logo-upload"
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            {
                                this.state.uploadError &&
                                <small className="error">{this.state.uploadError}</small>
                            }
                            <Previewer 
                                show={previewVisible}
                                close={this.handleCancel}
                                attachments={[{
                                    file_name: 'organization.png',
                                    public_url: previewImage
                                }]}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className="panel-heading" style={{height: "auto", minHeight: "auto"}}>
                        <h3 className="titles account mar-top-0" style={{marginRight: "15px"}}>Account Name</h3>
                        <div className="form-group"
                             style={{textAlign: 'center', display: "inline-block", marginBottom: "0px"}}>
                            {
                                !this.state.editMode &&
                                <div>
                                    <p className="zero-dark-grey" style={{
                                        display: "inline-block",
                                        marginBottom: "0px"
                                    }}>{this.props.organization.name}</p>
                                    <EditOutlined style={{cursor: "pointer", marginLeft: '8px'}} onClick={this.edit}/>
                                </div>
                            }

                            {
                                this.state.editMode &&
                                <div className="hidden-xs" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="text" id="account-name"
                                           className="form-control topic-field account-name " maxLength="250"
                                           onChange={this.updateOrgName}/>
                                    <button className="btn btn-discard"
                                            style={{marginLeft: '8px', verticalAlign: "top"}}
                                            onClick={this.cancel}>Cancel
                                    </button>
                                    <button className="btn btn-primary"
                                            style={{marginLeft: '8px', verticalAlign: "top"}} onClick={this.update}>
                                        {this.state.saving ? <ButtonLoading/> : "Save"}
                                    </button>
                                </div>
                            }
                            {
                                this.state.editMode &&
                                <div className="visible-xs" style={{marginTop: '10px'}}>
                                    <input type="text" id="account-name2"
                                           className="form-control topic-field account-name "
                                           style={{marginBottom: "8px"}} maxLength="250" onChange={this.updateOrgName}/>
                                    <button className="btn btn-discard pull-left" style={{marginLeft: '0px'}}
                                            onClick={this.cancel}>Cancel
                                    </button>
                                    <button className="btn btn-primary pull-left" style={{marginLeft: '8px'}}
                                            onClick={this.update}>
                                        {this.state.saving ? <ButtonLoading/> : "Save"}
                                    </button>
                                </div>
                            }

                        </div>
                    </div>
                    <hr/>
                    <div className="panel-heading" style={{height: "auto", minHeight: "auto"}}>
                        <h3 className="titles account" style={{marginRight: "28px", marginTop: "0px"}}>Date Created</h3>
                        <p className="zero-dark-grey" style={{
                            display: "inline-block",
                            marginBottom: "0px"
                        }}>{this.dateFormatter(this.props.organization.created_at)}</p>
                    </div>
                </div>)
            );
        }


    }
}

// export default OrganizationEdit;

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        organization: safe_get(store, "org_helper.organization", {}),
        organizations: safe_get(store, "org_helper.organizations", [])
    }
}

export default connect(mapStateToProps)(OrganizationEdit);
  