import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Select} from 'antd';

import {actions as manageDesksActions} from 'store/slices/manageDesks';
import {DeskType, PrettyDeskTypeMap} from 'other/Constants';

export default function ManageDesksFilters() {
    const dispatch = useDispatch();
    const filterOptions = useSelector(state => state.manageDesks.filters);
    const deskCount = useSelector(state => state.manageDesks?.pagination?.total || 0);
    const [rawFilters, setRawFilters] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dispatchFilterQueryTimeout, setDispatchFilterQueryTimeout] = useState(null);

    const locations = filterOptions?.locations;
    const spaces = filterOptions?.spaces;

    const handleFilterChange = values => {
        if (dispatchFilterQueryTimeout) {
            clearTimeout(dispatchFilterQueryTimeout);
            setDispatchFilterQueryTimeout(null);
        }

        const rawValues = Array.of(...values);

        const searchFilters = [];
        const typeFilters = [];
        const locationFilters = [];
        const spaceFilters = [];
        let enabledFilter = null;

        const queryFilterMap = {
            'type=': typeFilters,
            'location=': locationFilters,
            'space=': spaceFilters,
        }

        values.map(value => {
            for (const [key, filterList] of Object.entries(queryFilterMap)) {
                if (value.startsWith(key)) {
                    filterList.push(value.slice(key.length));
                    return;
                }
            }

            if (value.startsWith('enabled=')) {
                if (enabledFilter !== null) {
                    const foundIndex = rawValues.findIndex(v => v.startsWith('enabled='));
                    if (foundIndex >= 0) {
                        rawValues.splice(foundIndex, 1);
                    }
                }
                enabledFilter = value.slice('enabled='.length);
            } else {
                searchFilters.push(value.trim().toLowerCase());
            }
        });

        setRawFilters(rawValues);

        let q = '';

        if (locationFilters.length > 0) {
            q += `&location_uuids=${locationFilters.join(',')}`;
        }
        if (spaceFilters.length > 0) {
            q += `&space_uuids=${spaceFilters.join(',')}`;
        }
        if (typeFilters.length > 0) {
            q += `&types=${typeFilters.join(',')}`;
        }
        if (enabledFilter !== null) {
            q += `&enabled=${enabledFilter}`;
        }
        if (searchFilters.length > 0) {
            q += `&search=${searchFilters.join('|')}`;
        }

        let queryTimeout = 0;
        if (dropdownVisible && values.length > 0) {
            queryTimeout = 500;
        }

        setDispatchFilterQueryTimeout(setTimeout(() => {
            dispatch(manageDesksActions.setDeskFilterQuery(q));
        }, queryTimeout));

        dispatch(manageDesksActions.setPage(1));
    }

    const filterOption = (inputValue, {children}) => {
        if (children) {
            return children.toLowerCase().includes(inputValue.toLowerCase());
        }

        return false;
    };

    return (
        <>
            <Select
                mode="tags"
                style={{width: '100%'}}
                placeholder={"Filter or search..."}
                allowClear={true}
                virtual={false}
                onChange={handleFilterChange}
                value={rawFilters}
                filterOption={filterOption}
                onDropdownVisibleChange={setDropdownVisible}
            >

                <Select.OptGroup label={<span className="text-semibold">Status</span>}>
                    <Select.Option value={`enabled=true`}>Enabled</Select.Option>
                    <Select.Option value={`enabled=false`}>Disabled</Select.Option>
                </Select.OptGroup>
                {
                    locations &&
                    <Select.OptGroup label={<span className="text-semibold">Location</span>}>
                        {
                            locations.map(({uuid, name}) => (
                                <Select.Option key={uuid} value={`location=${uuid}`}>{name}</Select.Option>
                            ))
                        }
                    </Select.OptGroup>
                }
                {
                    spaces &&
                    <Select.OptGroup label={<span className="text-semibold">Space</span>}>
                        {
                            spaces.map(({uuid, name}) => (
                                <Select.Option key={uuid} value={`space=${uuid}`}>{name}</Select.Option>
                            ))
                        }
                    </Select.OptGroup>
                }
                <Select.OptGroup label={<span className="text-semibold">Desk Type</span>}>
                    {
                        Object.entries(PrettyDeskTypeMap).map(entry => {
                            const [key, value] = entry;
                            return <Select.Option key={key} value={`type=${key}`}>{value}</Select.Option>
                        })
                    }
                </Select.OptGroup>
            </Select>
            <p className={"badge members_total "}>Desks: {deskCount}</p>
        </>
    )
}
