import React, {useContext, useMemo} from 'react';
import {
    PlusCircleOutlined, EditOutlined, ExportOutlined, CheckCircleOutlined, MessageOutlined, FormOutlined, FileOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import moment from 'moment';

import UserPopoverCard from 'components/Shared/UserPopoverCard';
import {
    IncidentCommentType,
    PrettyIncidentStatusMap,
    PrettyIncidentFieldNameMap,
    orderedIncidentFields
} from 'other/Constants';

import styles from './IncidentTimeline.module.css';
import CommentDiff from 'components/Shared/CommentDiff';
import SubmissionEditDiff from 'components/Shared/SubmissionEditDiff';
import {TimelineContext} from 'other/ReactContexts';
import { Timeline } from 'components/Shared/Timeline';

function getTimelineIcon(comment) {
    const {comment_type, data} = comment;
    switch (comment_type) {
        case IncidentCommentType.INCIDENT_SUBMITTED:
            return PlusCircleOutlined;
        case IncidentCommentType.INCIDENT_MOVED:
            return ExportOutlined;
        case IncidentCommentType.STATUS_CHANGE:
            return (data?.new_status === 'closed' ? CheckCircleOutlined : EditOutlined);
        case IncidentCommentType.USER_COMMENT:
            return MessageOutlined;
        case IncidentCommentType.SUBMISSION_EDITED:
            return FormOutlined;
        case IncidentCommentType.SUBMISSION_CREATED:
            return FileOutlined;
        case IncidentCommentType.SUBMISSION_DELETED:
            return DeleteOutlined;
        default:
            return EditOutlined;
    }
}

function CommentAction({comment}) {
    const {created_by, comment_type, data} = comment;

    const who = <UserPopoverCard user={created_by}><span
        className={styles.author}>{created_by.full_name}</span></UserPopoverCard>

    switch (comment_type) {
        case IncidentCommentType.INCIDENT_SUBMITTED:
            let locationElement = null;
            if (data?.location) {
                locationElement = <> in <span className="text-semibold">{data.location.name}</span></>;
            }
            return <>{who} opened this incident{locationElement}.</>;
        case IncidentCommentType.INCIDENT_MOVED:
            return <>{who} moved this incident to <span className="text-semibold">{data.new_location.name}</span>.</>;
        case IncidentCommentType.STATUS_CHANGE:
            if (data?.new_status === 'closed') {
                return <>{who} closed this incident.</>;
            }
            return <>{who} changed the status to <span
                className="text-semibold">{PrettyIncidentStatusMap[data.new_status]}</span>.</>;
        case IncidentCommentType.USER_COMMENT:
            return <>{who} commented on this incident.</>;
        case IncidentCommentType.SUBMISSION_EDITED:
            return <>{who} edited a custom form.</>;
        case IncidentCommentType.SUBMISSION_CREATED:
            return <>{who} added a custom form.</>;
        case IncidentCommentType.SUBMISSION_DELETED:
            return <>{who} deleted a custom form.</>;
        default:
            return <>{who} edited this incident.</>;
    }
}

function prettifyDiffValue(key, value, options) {
    if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
        return <em>None</em>
    }

    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    }

    switch (key) {
        case 'event_type_uuid':
            return options.eventTypes[value] || 'N/A';
        case 'incident_type':
            return options.incidentTypes[value.toString()] || 'N/A';
        case 'sex':
            return value === 0 ? 'Female' : 'Male';
        case 'incident_cause_uuid':
            return options.incidentCauses[value] || 'N/A';
        case 'case_classification':
            return options.caseClassifications[value.toString()] || 'N/A';
        case 'corrective_actions':
            return (
                <ol style={{margin: 0, paddingLeft: '1.5rem'}}>
                    {
                        value.map((action, index) => <li key={index} className={styles.correctiveAction}>{action}</li>)
                    }
                </ol>
            );
        case 'body_front_parts':
            return value.map(partId => options.frontBodyParts[partId]).sort().join(', ');
        case 'body_back_parts':
            return value.map(partId => options.backBodyParts[partId]).sort().join(', ');
        case 'time_began_work':
        case 'event_time':
            return moment(value, moment.HTML5_FMT.TIME).format('h:mm a');
        case 'custom_field_1': {
            const fieldOptions = options.customField1?.options || [];
            const option = fieldOptions.find(option => option.uuid === value);
            return option?.label || 'N/A';
        }
    }

    return value;
}

function prettifyDiffField(field, value, options) {
    switch (field) {
        case 'corrective_actions':
            return <><span className='underline'>{PrettyIncidentFieldNameMap[field]}</span>: {value.length || 0}</>
        case 'custom_field_1':
            return options.customField1?.name || 'Custom Field 1';
        default:
            return PrettyIncidentFieldNameMap[field];
    }
}

function IncidentEditDiff({comment}) {
    const {options} = useContext(TimelineContext);
    const {injuries = {}, illnesses = {}} = options;
    const {data} = comment;

    if (!data || typeof data !== 'object') {
        return null;
    }

    let injuryOrIllness = {old_value: null, new_value: null};
    const injury = data['incident_injury_uuid'] || {};
    const illness = data['incident_illness'] || {};

    if (injury.old_value) {
        injuryOrIllness.old_value = injuries[injury.old_value] || "N/A";
    } else if (illness.old_value) {
        injuryOrIllness.old_value = illnesses[illness.old_value] || "N/A";
    }

    if (injury.new_value) {
        injuryOrIllness.new_value = injuries[injury.new_value] || "N/A";
    } else if (illness.new_value) {
        injuryOrIllness.new_value = illnesses[illness.new_value] || "N/A";
    }

    delete data['incident_injury_uuid'];
    delete data['incident_illness'];

    if (injuryOrIllness.old_value || injuryOrIllness.new_value) {
        data['$injury_or_illness'] = injuryOrIllness;
    }

    const diffs = orderedIncidentFields.filter(field => data[field]).map(field => {
        const value = data[field];
        return {
            key: field,
            oldLabel: prettifyDiffField(field, value.old_value, options),
            oldValue: prettifyDiffValue(field, value.old_value, options),
            newLabel: prettifyDiffField(field, value.new_value, options),
            newValue: prettifyDiffValue(field, value.new_value, options),
        }
    });

    return (
        <CommentDiff diffs={diffs}/>
    );
}

function TimelineEntry({comment}) {
    const {created_by, created_at, edited_at, body, comment_type, users_notified} = comment;
    const isEditedType = comment_type === IncidentCommentType.INCIDENT_EDITED;
    const showDetailsLink = [
        IncidentCommentType.INCIDENT_EDITED,
        IncidentCommentType.SUBMISSION_CREATED,
        IncidentCommentType.SUBMISSION_DELETED,
        IncidentCommentType.SUBMISSION_EDITED,
    ].includes(comment_type);
    const isSubmissionType = showDetailsLink && !isEditedType;
    const timeToDisplay = (edited_at && showDetailsLink) ? edited_at : created_at;

    const validCommentTypes = [
        IncidentCommentType.INCIDENT_SUBMITTED,
        IncidentCommentType.USER_COMMENT,
    ];

    let details;
    if (isEditedType) {
        details = <IncidentEditDiff comment={comment} />
    } else if (isSubmissionType) {
        details = <SubmissionEditDiff data={comment.data} />
    }

    return (
        <Timeline.Entry
            icon={getTimelineIcon(comment)}
            author={created_by}
            time={timeToDisplay}
            showDetailsLink={showDetailsLink}
            action={<CommentAction comment={comment} />}
            usersNotified={validCommentTypes.includes(comment_type) ? users_notified : null}
            body={body}
            details={details}
        />
    )
}

function generateOptionsMaps(options) {
    const {
        injuries = [],
        illnesses = [],
        event_types = [],
        incident_types = [],
        case_classifications = [],
        incident_causes = [],
        bodyParts = {},
        customField1 = {},
    } = options;

    const injuriesMap = {};
    injuries.forEach(injury => {
        injuriesMap[injury.incident_injury_uuid] = `Injury (${injury.name})`;
    });

    const illnessesMap = {};
    illnesses.forEach(illness => {
        illnessesMap[illness.value.toString()] = `Illness (${illness.name})`;
    });

    const eventTypesMap = {};
    event_types.forEach(type => {
        eventTypesMap[type.event_type_uuid] = type.name;
    })

    const incidentTypesMap = {};
    incident_types.forEach(type => {
        incidentTypesMap[type.value.toString()] = type.name;
    });

    const caseClassificationMap = {};
    case_classifications.forEach(caseClassification => {
        caseClassificationMap[caseClassification.value.toString()] = caseClassification.name;
    });

    const causeMap = {};
    incident_causes.forEach(cause => {
        causeMap[cause.incident_cause_uuid] = cause.name;
    });

    const frontBodyPartsMap = {};
    for (const [key, value] of Object.entries(bodyParts).filter(([key, _value]) => key.startsWith('frt'))) {
        frontBodyPartsMap[key] = value.hover || 'N/A';
    }

    const backBodyPartsMap = {};
    for (const [key, value] of Object.entries(bodyParts).filter(([key, _value]) => key.startsWith('bck'))) {
        backBodyPartsMap[key] = value.hover || 'N/A';
    }

    return {
        injuries: injuriesMap,
        illnesses: illnessesMap,
        eventTypes: eventTypesMap,
        incidentTypes: incidentTypesMap,
        caseClassifications: caseClassificationMap,
        incidentCauses: causeMap,
        frontBodyParts: frontBodyPartsMap,
        backBodyParts: backBodyPartsMap,
        customField1
    };
}


export default function IncidentTimeline({comments, options, style = {}, className = '', submissions = []}) {
    const optionsMappings = useMemo(
        () => generateOptionsMaps(options),
        [
            options.injuries,
            options.illnesses,
            options.event_types,
            options.incident_types,
            options.case_classifications,
            options.incident_causes,
            options.frontBodyParts,
            options.backBodyParts,
            options.customField1,
        ]
    )

    const contextValue = {
        options: optionsMappings,
        submissions
    };

    if (comments.length === 0) {
        return <div style={{width: '100%'}}>No history for this incident.</div>
    }

    return (
        <TimelineContext.Provider value={contextValue}>
            <Timeline className={className} style={style}>
                {
                    comments.map(comment => (
                        <TimelineEntry comment={comment} key={comment.uuid}/>
                    ))
                }
            </Timeline>
        </TimelineContext.Provider>
    );
}