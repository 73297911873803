import { EyeOutlined } from '@ant-design/icons';
import FormPreviewModal from './FormPreviewModal';
import { useState } from 'react';


export default function FormPreviewButton({form}) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            { showModal &&
                <FormPreviewModal
                    show={showModal}
                    cancel={() => setShowModal(false)}
                    title={form.name}
                    fields={form.fields}
                    is_builder={true}
                    form_uuid={null}
                />
            }
            <span className="blue-link" onClick={() => setShowModal(true)}>
                <EyeOutlined />
            </span>
        </>
    )
}