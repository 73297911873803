import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {
    get_form_submissions,
    get_form,
    get_form_analytics_fields,
    get_simple_form_submissions,
    get_submission
} from '../../../api/zero-api.js';
import {
    safe_get,
    dateFormatterNoTime,
    scrollToTop,
    mainContentContainerClass,
    dateFormatterWithTime
} from '../../../other/Helper.js';

import Skeleton from 'react-loading-skeleton'

import {Doughnut} from 'react-chartjs-2';
import {Select, Button, Popover} from 'antd'

import FormFieldsRenderer from '../../Forms/FormFieldsRenderer';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import ChartLabels from 'components/Shared/ChartLabels.js';

import '../../../assets/css/antd-custom.css';
import { fieldHasResponses } from 'other/forms.js';

class ManageFormsAnalytics extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        let pathname = safe_get(this.props, "location.pathname", "");

        this.state = {
            page_type: pathname.includes("courses") ? "lms" : "regular",
            selectedTab: "summary",
            form: {},
            all_teams: [],
            teams: [],
            selectedTeam: "all_teams",
            submissions: [],
            total_submissions: 0,
            selectedSubmission: {},
            selectedSubmissionIndex: 0,
            formFields: [],
            loading_form: true,
            loading_submissions: true,
            chartLabelsOnTop: false
        };

    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('beforeprint', this.beforePrintHandler);
        window.removeEventListener('afterprint', this.afterPrintHandler);
    }

    componentDidMount() {
        this._isMounted = true;
        scrollToTop("page-head");
        this.getFormData();
        this.getSubmissions();

        window.addEventListener('beforeprint', this.beforePrintHandler);
        window.addEventListener('afterprint', this.afterPrintHandler);
    }

    beforePrintHandler = (ev) => {
        const containers = document.querySelectorAll('.responsive-chart-container');
        for (const container of containers) {
            container.style.width = '80%';
        }
    }

    afterPrintHandler = (ev) => {
        const containers = document.querySelectorAll('.responsive-chart-container');
        for (const container of containers) {
            container.style.width = '100%';
        }
    }

    getFormData = () => {
        var self = this;
        let form_uuid = safe_get(this.props, "match.params.form_uuid", "");
        get_form(form_uuid).then(function (success) {
            success.json().then(success => {
                if (self._isMounted) {
                    self.setState({
                        form: safe_get(success, "form", {}),
                        all_teams: safe_get(success, "form.teams", []),
                        teams: safe_get(success, "form.submissions_teams", []),
                        loading_form: false
                    });
                }
            });
        }, function (error) {
            self.setState({
                loading_form: false
            });
        });

        get_form_analytics_fields("?form_uuid=" + form_uuid + "&period=all_time").then(function (success) {
            success.json().then(success => {
                if (self._isMounted) {
                    self.setState({
                        formFields: success.fields,
                        total_submissions: success.total_submissions,
                        totalTeamSubmissions: success.total_submissions,
                    });
                }
            });
        });
    }

    getSubmissions = () => {
        var self = this;
        let form_uuid = safe_get(this.props, "match.params.form_uuid", "");
        get_simple_form_submissions("?form_uuid=" + form_uuid).then(function (success) {
            success.json().then(success => {
                if (self._isMounted) {
                    let submissions = safe_get(success, "submissions", []);

                    self.setState({
                        submissions: submissions,
                        // selectedSubmission: submissions[0],
                        loading_submissions: false
                    });
                    if (submissions.length > 0) {
                        self.getIndividualSubmission(submissions[0].submission_uuid);
                    }
                }
            });
        }, function (error) {
            self.setState({
                loading_submissions: false
            });
        });
    }

    getIndividualSubmission = (sub_uuid) => {
        var self = this;
        get_submission(sub_uuid).then(success => {
            success.json().then(success => {
                self.setState({
                    selectedSubmission: success.submission,
                    loadingSubmission: false
                });
            })
        })
    }

    tabChange = (e) => {
        this.setState({
            selectedTab: e.target.value,
        });
    }

    onTeamSelect = (team_uuid) => {
        var self = this;

        let query = "?form_uuid=" + this.state.form.form_uuid + "&period=all_time";
        if (team_uuid !== "all_teams") {
            query = "?form_uuid=" + this.state.form.form_uuid + "&team_uuid=" + team_uuid + "&period=all_time";
        }

        get_form_analytics_fields(query).then(function (success) {
            success.json().then(success => {
                if (self._isMounted) {
                    self.setState({
                        formFields: success.fields,
                        totalTeamSubmissions: success.total_submissions
                    });
                }
            });
        });

        get_simple_form_submissions(query).then(function (success) {
            success.json().then(success => {
                let submissions = safe_get(success, "submissions", []);
                if (self._isMounted) {
                    self.setState({
                        submissions: submissions,
                        // selectedSubmission: submissions[0],
                        loading_submissions: false
                    });
                    if (submissions.length > 0) {
                        self.getIndividualSubmission(submissions[0].submission_uuid);
                    }
                }
            });
        }, function (error) {
            self.setState({
                loading_submissions: false
            });
        });

        this.setState({selectedTeam: team_uuid});
    }

    submissionSelect = (sub_uuid) => {
        this.setState({loadingSubmission: true}, () => {
            this.getIndividualSubmission(sub_uuid);
        });

        let submissions = [...this.state.submissions];
        let sub = submissions.find(function (obj) {
            return obj.submission_uuid === sub_uuid;
        });

        let index = submissions.indexOf(sub);

        this.setState({
            // selectedSubmission: sub,
            selectedSubmissionIndex: index
        });

        if (document.activeElement.blur) {
            document.activeElement.blur()
        }
    }

    previousSubmission = () => {
        let submissions = [...this.state.submissions];
        let currentIndex = this.state.selectedSubmissionIndex;

        let newIndex = currentIndex - 1;

        if (newIndex >= 0) {
            this.setState({loadingSubmission: true}, () => {
                this.getIndividualSubmission(submissions[newIndex].submission_uuid)
                this.setState({
                    selectedSubmissionIndex: newIndex,
                    // selectedSubmission: submissions[newIndex],
                    // loadingSubmission: false
                });
            });
        }

    }

    nextSubmission = () => {
        let submissions = [...this.state.submissions];
        let currentIndex = this.state.selectedSubmissionIndex;

        let newIndex = currentIndex + 1;

        if (newIndex < submissions.length) {
            this.setState({loadingSubmission: true}, () => {
                this.getIndividualSubmission(submissions[newIndex].submission_uuid)
                this.setState({
                    selectedSubmissionIndex: newIndex,
                    // selectedSubmission: submissions[newIndex],
                    // loadingSubmission: false
                });
            });
        }
    }

    renderTeamsList = (teams) => {
        return (
            <div style={{maxHeight: "150px", overflow: "auto"}}>
                {
                    teams.map((team, index) => (
                        <div key={index}>
                            {team}
                        </div>
                    ))
                }
            </div>
        )
    }

    render() {
        let selectedSubmission = this.state.selectedSubmission;
        let team_names = safe_get(this.state, "all_teams", []).map(e => e.name).sort(function (a, b) {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1
        });

        return (
            <div className={mainContentContainerClass() + ' print-7in'}>

                <div id="page-head" className="no-padding-mobile" style={{padding: "0px"}}>
                    {/* <div className="row">                                                                   
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0" >                                  
                            <div id="page-title" style={{padding: '0px'}}>                                                                
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">                   
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"} style={{display: "inline-block", margin: "0px"}}>
                                                {this.state.form.name}                                                                     
                                            </h3>                                                                                                                       
                                        </div>   
                                        
                                        <p className="header" style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            Number of submissions: {this.state.submissions.length}
                                        </p>  
                                                                                                                                                                                                                                                                                                                         
                                    </div>
                                </div>                                                                                                            
                            </div> 
                        </div>                                                                          
                    </div> */}
                </div>


                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{paddingLeft: "5px", paddingRight: "5px"}}>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border" style={{height: "200px"}}>
                                <div className="panel-heading analytics">
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                        {this.state.page_type === "lms" ? "Course" : "Template"} Info
                                    </h2>
                                </div>
                                <div className="panel-body">
                                    <p className="zero-blue truncate" style={{maxWidth: "400px"}}>
                                        <span style={{fontWeight: "600"}}>Name:</span>&nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>                                                
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={100}/> :
                                                    safe_get(this.state.form, "name", "")
                                            }
                                        </span>
                                    </p>
                                    <hr style={{marginTop: "10px", marginBottom: "10px"}}/>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Teams: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={12}/> :
                                                    <span>
                                                    <Popover content={this.renderTeamsList(team_names)}
                                                             placement="bottom" overlayStyle={{maxWidth: "220px"}}>
                                                        {team_names[0]} {team_names.length - 1 > 0 && "and " + (team_names.length - 1) + " more..."}
                                                    </Popover>
                                                </span>
                                                // safe_get(this.state, "form.share_teams_count", 0)
                                            }
                                        </span>
                                    </p>
                                    <hr style={{marginTop: "10px", marginBottom: "10px"}}/>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Total Submissions: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={12}/> :
                                                    safe_get(this.state, "total_submissions", 0)
                                            }
                                        </span>
                                    </p>
                                    <hr style={{marginTop: "10px", marginBottom: "10px"}}/>
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Scheduled: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={12}/> :
                                                    this.state.form.schedulers_count > 0 ? "Yes" : "No"
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-reduce-padding">
                            <div className="panel thin-border" style={{height: "200px"}}>
                                <div className="panel-heading analytics">
                                    <h2 className="panel-title analytics"
                                        style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px',}}>
                                        {this.state.page_type === "lms" ? "Course" : "Template"} Details
                                    </h2>
                                </div>
                                <div className="panel-body">
                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Created on: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={90}/> :
                                                    dateFormatterWithTime(this.state.form.created_at)
                                            }
                                        </span>
                                    </p>
                                    <hr style={{marginTop: "10px", marginBottom: "10px"}}/>

                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Created by: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={120}/> :
                                                    safe_get(this.state.form, "created_by.first_name", "")} {safe_get(this.state.form, "created_by.last_name", "")
                                        }
                                        </span>
                                    </p>
                                    <hr style={{marginTop: "10px", marginBottom: "10px"}}/>

                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Last edited on: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={90}/> :
                                                    dateFormatterWithTime(this.state.form.edited_at)
                                            }
                                        </span>
                                    </p>
                                    <hr style={{marginTop: "10px", marginBottom: "10px"}}/>

                                    <p className="zero-blue" style={{fontWeight: "600"}}>
                                        Last edited by: &nbsp;
                                        <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                                            {
                                                this.state.loading_form ?
                                                    <Skeleton width={120}/> :
                                                    safe_get(this.state.form, "edited_by.first_name", "")} {safe_get(this.state.form, "edited_by.last_name", "")
                                        }
                                        </span>
                                    </p>
a

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        {
                            this.state.loading_submissions &&
                            <LoadingIndicator/>
                        }

                        {
                            !this.state.loading_submissions &&
                            <div className="panel thin-border">
                                <div className="panel-body">

                                    <div className="team-name-header">
                                        <h3 className={"section-titles admin-page-header"}
                                            style={{display: "inline-block", margin: "0px"}}>
                                            {this.state.form.name}
                                        </h3>
                                    </div>


                                    {/* <Radio.Group defaultValue="summary" buttonStyle="solid" onChange={this.tabChange} style={{ marginTop: "10px"}}>
                                        <Radio.Button value="summary">SUMMARY</Radio.Button>
                                        <Radio.Button value="individual">INDIVIDUAL</Radio.Button>                                      
                                    </Radio.Group> */}

                                    <div className="btn-group btn-group-toggle form-analytics mar-top-10"
                                         data-toggle="buttons">
                                        <label
                                            className={"btn btn-secondary " + (this.state.selectedTab === "summary" && "active")}>
                                            <input type="radio" name="options" value="summary"
                                                   onChange={this.tabChange}/>
                                            Summary
                                        </label>
                                        <label
                                            className={"btn btn-secondary " + (this.state.selectedTab === "individual" && "active")}>
                                            <input type="radio" name="options" value="individual"
                                                   onChange={this.tabChange}/>
                                            Individual
                                        </label>
                                    </div>

                                    <div className="mar-top-10">
                                        <Select
                                            style={{maxWidth: "300px", width: "100%"}}
                                            placeholder="Select a team"
                                            onChange={this.onTeamSelect}
                                            value={this.state.selectedTeam}
                                        >
                                            <Select.Option value={"all_teams"}>
                                                All Teams
                                            </Select.Option>
                                            {
                                                this.state.teams.map((team, index) => (
                                                    <Select.Option key={index} value={team.team_uuid}>
                                                        {team.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>

                                    {
                                        safe_get(this.state, "totalTeamSubmissions", 0) === 0 &&
                                        <p className="mar-top-10">No submissions yet.</p>
                                    }

                                    {
                                        this.state.selectedTab === "summary" &&
                                        safe_get(this.state, "totalTeamSubmissions", 0) > 0 &&
                                        <div style={{marginTop: "10px"}}>
                                            <hr/>
                                            {
                                                this.state.formFields.map((field, index) => (
                                                    field.is_chart &&
                                                    <div className="mar-top-10 print-avoid-breaks" key={index}>
                                                        <h4 className="zero-blue">
                                                            {field.label}
                                                        </h4>
                                                        {
                                                            fieldHasResponses(field) === false ? (
                                                                <div>No responses yet.</div>
                                                            ) : (
                                                                <>
                                                                    <ChartLabels data={field.data.data}/>
                                                                    <div
                                                                        className="responsive-chart-container"
                                                                        style={{
                                                                            position: 'relative',
                                                                            width: '100%',
                                                                            height: '350px'
                                                                        }}
                                                                    >
                                                                        <Doughnut
                                                                            data={field.data.data}
                                                                            options={{
                                                                                ...field.data.options,
                                                                                responsive: true,
                                                                                maintainAspectRatio: false,
                                                                                legend: {
                                                                                    display: false
                                                                                },
                                                                                tooltips: {
                                                                                    callbacks: {
                                                                                        label: (tooltipItem, data) => {
                                                                                            let label = data.labels[tooltipItem.index] || '';
                                                                                            return label;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {index !== this.state.formFields.length - 1 &&
                                                            <hr style={{margin: '35px 0'}}/>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.selectedTab === "individual" &&
                                        safe_get(this.state, "totalTeamSubmissions", 0) > 0 &&
                                        <div>
                                            <div className="mar-top-10">
                                                <Select
                                                    style={{maxWidth: "300px", width: "100%"}}
                                                    placeholder="Select a submissions"
                                                    onChange={this.submissionSelect}
                                                    value={safe_get(selectedSubmission, "submission_uuid", undefined)}
                                                >
                                                    {
                                                        this.state.submissions.map((submission, index) => (
                                                            <Select.Option key={index}
                                                                           value={submission.submission_uuid}>
                                                                {submission.user_name}, {dateFormatterNoTime(submission.submission_date)}
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                                <div className="pull-right hidden-xs">
                                                    <Button shape="round" className="mar-rgt-5"
                                                            onClick={this.previousSubmission}
                                                            disabled={this.state.loadingSubmission}>Previous</Button>
                                                    <Button shape="round" onClick={this.nextSubmission}
                                                            disabled={this.state.loadingSubmission}>Next</Button>
                                                </div>
                                            </div>

                                            {
                                                selectedSubmission &&
                                                <div
                                                    style={{visibility: this.state.loadingSubmission ? 'hidden' : 'visible'}}>
                                                    <hr/>
                                                    <p className="zero-dark-grey">
                                                        {safe_get(selectedSubmission, "edited_by.first_name", "")} {safe_get(selectedSubmission, "edited_by.last_name", "")} submitted {safe_get(selectedSubmission, "form.name", "")} {selectedSubmission.team && `in ${safe_get(selectedSubmission, "team.name", "")}`} on {dateFormatterWithTime(selectedSubmission.edited_at)}
                                                    </p>
                                                    <div className="panel thin-border"
                                                         style={{margin: "0px", marginBottom: "10px"}}>
                                                        <div className="panel" style={{padding: "10px", margin: "0px"}}>
                                                            <div className="row justify-content-start">
                                                                <div className="col-xs-4">
                                                                    <p className="text-sm text-bold zero-blue text-center"
                                                                       style={{margin: "5px"}}>
                                                                        Submission Score
                                                                    </p>
                                                                    <div className="text-lg">
                                                                        <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                                                           style={{fontSize: "3em"}}>
                                                                            {selectedSubmission.score}{!isNaN(selectedSubmission.score) &&
                                                                            <span style={{fontSize: "30%"}}>%</span>}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xs-4">
                                                                    <p className="text-sm text-bold zero-blue text-center"
                                                                       style={{margin: "5px"}}>Failed Items</p>
                                                                    <div className="text-lg">
                                                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                                           style={{fontSize: "3em"}}>
                                                                            {selectedSubmission.failed_items_count ? selectedSubmission.failed_items_count : "0"}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xs-4">
                                                                    <p className="text-sm text-bold zero-blue text-center"
                                                                       style={{margin: "5px"}}>Comments</p>
                                                                    <div className="text-lg">
                                                                        <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                                           style={{fontSize: "3em"}}>
                                                                            {selectedSubmission.comment_count}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="thin-border" style={{padding: "15px"}}>
                                                        <FormFieldsRenderer
                                                            form_uuid={safe_get(selectedSubmission, "form.form_uuid", "undefined")}
                                                            submission_uuid={safe_get(selectedSubmission, "submission_uuid", "")}
                                                            form_fields={safe_get(selectedSubmission, "form.fields", [])}
                                                            pages={[safe_get(selectedSubmission, "form.fields", [])]}
                                                            page={0}
                                                            field_answers={safe_get(selectedSubmission, "fields", [])}
                                                            saveDraft={() => {
                                                            }}
                                                            isAnalytics={true}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {/* {
                                                this.state.loadingSubmission &&
                                                <LoadingIndicator />
                                            } */}


                                        </div>
                                    }

                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>

        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        teams: safe_get(store, "teams_helper.teams", [])
    }
}

export default withRouter(connect(mapStateToProps)(ManageFormsAnalytics));
  