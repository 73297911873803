import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {UserAddOutlined} from '@ant-design/icons';

import UserPopoverCard from 'components/Shared/UserPopoverCard';
import RespondersPopover from 'components/Shared/RespondersPopover';
import InfoIconComponent from 'components/InfoIconComponent';
import {safeProfilePic} from 'other/Helper';

export default function Subscribers({subscribers, availableSubscribers, onSubscribersChanged, ...props}) {
    const currentUser = useSelector(state => state.user.user);
    const filteredAvailableSubscribers = useMemo(
        () => availableSubscribers.filter(user => user.uuid !== currentUser.uuid),
        [availableSubscribers, currentUser]
    );

    return (
        <>
            <h3 className="bulletin-post-headers" style={{marginLeft: 0, marginBottom: 0}}>Who should be notified?</h3>
            <p className="zero-dark-grey" style={{marginBottom: "0px"}}>
                When I submit this incident, {subscribers.length} {subscribers.length === 1 ? "person" : "people"} will
                be notified.
            </p>
            <div>
                {
                    subscribers.length > 0 &&
                    subscribers.map(user => (
                        <UserPopoverCard
                            key={user.uuid}
                            user={user}
                            subscribers={subscribers}
                            updateSubscribers={onSubscribersChanged}
                            showRemoveSubscriber={true}
                        >
                            <span
                                style={{cursor: "pointer"}}>{safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                                marginRight: "5px",
                                display: "inline-block"
                            })}</span>
                        </UserPopoverCard>
                    ))
                }
                <RespondersPopover
                    available_responders={filteredAvailableSubscribers}
                    selected_responders={subscribers}
                    updateResponders={onSubscribersChanged}
                    title="Notifications"
                >
                    <button className="ButtonLink post-details-content mar-top-5"
                            style={{verticalAlign: "bottom", height: "26px", width: "26px"}}>
                        <UserAddOutlined style={{fontSize: "19px"}}/>
                    </button>
                </RespondersPopover>
            </div>
        </>
    );
}