export function update_feed_position(position) {
    return {
        type: "update_feed_position",
        payload: position
    }
}

export function update_feed_query(query) {
    return {
        type: "update_feed_query",
        payload: query
    }
}

export function update_date_query(query) {
    return {
        type: "update_date_query",
        payload: query
    }
}

export function update_feed_and_date_queries(query) {
    return {
        type: "update_feed_and_date_queries",
        payload: query
    }
}

export function update_feed_tab(tab) {
    return {
        type: "update_feed_tab",
        payload: tab
    }
}

export function update_feed_categories(categories) {
    return {
        type: "update_feed_categories",
        payload: categories
    }
}

export function update_feed_statuses(statuses, group) {
    return {
        type: group === 2 ? "update_feed_statuses_2" : "update_feed_statuses",
        payload: statuses
    }
}

export function update_feed_tags(tags) {
    return {
        type: "update_feed_tags",
        payload: tags
    }
}


export function toggle_feed_view(view) {
    return {
        type: "toggle_feed_view",
        payload: view
    }
}

export function update_feed_options(options) {
    return {
        type: "update_options",
        payload: options,
    }
}
