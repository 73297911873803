import React, {Component} from 'react';

import Skeleton from 'react-loading-skeleton'
import {Checkbox, Input} from 'antd'

import InfoIconComponent from '../InfoIconComponent.js';

import {
    safeProfilePic, safe_get
} from '../../other/Helper.js';

export default class RespondersSelector extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            filtered_available_responders: this.props.availableResponders,
            searchValue: null
        };
    }

    searchUsers = (e) => {
        let searchValue = safe_get(e, "target.value", "").toLowerCase();

        if (searchValue !== "") {
            const filterList = this.props.availableResponders.filter(function (user) {
                let full_name = `${user.first_name} ${user.last_name}`
                return full_name.toLowerCase().includes(searchValue)
            });

            this.setState({filtered_available_responders: filterList, searchValue});
        } else {
            this.setState({filtered_available_responders: this.props.availableResponders, searchValue});
        }
    }

    handleUserSelect = (e) => {
        let user_uuid = e.target.value

        let index = this.props.selectedResponderIds.indexOf(user_uuid)
        this.props.onSelect(user_uuid, !(index === -1));
    }

    selectAll = () => {
        this.props.onSelectAll(!this.props.assignedToTeam);
    }

    save = () => {
        if (this.props.onSave) {
            this.props.onSave();
        }
    }


    render() {

        const checkboxMinHeight = this.props.checkboxMinHeight || 165;

        return (
            <>
                <div className="mar-btm-10">
                    <h3 className="bulletin-post-headers" style={{margin: "0px"}}>
                        Assignees
                        <InfoIconComponent
                            position={"bottom"}
                            width={"170px"}
                            text={<span>Assignees will receive a notification that they've been assigned.</span>}
                        />
                    </h3>
                    {!this.props.hideSaveButton &&
                        <button className="btn btn-primary" style={{float: "right", padding: "2px 5px"}}
                                onClick={this.save}>Save</button>
                    }
                </div>

                <Input
                    placeholder="Search..."
                    onChange={this.searchUsers}
                    value={this.state.searchValue}
                    style={{width: "100%"}}
                />
                <div className="subscribers-checkbox" style={{marginTop: "8px", minHeight: checkboxMinHeight}}>
                    {this.props.availableResponders.length > 0 &&
                        <div className="form-check mar-btm-5">
                            <Checkbox
                                onChange={this.selectAll}
                                checked={this.props.assignedToTeam}
                            >
                                <span
                                    style={{fontWeight: "500"}}>All Team Members ({this.props.availableResponders.length})</span>
                            </Checkbox>
                        </div>
                    }
                    {this.state.filtered_available_responders.length > 0 &&
                        this.state.filtered_available_responders.map((user, index) => {
                            let truncate = {
                                width: "200px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginBottom: "0px"
                            }
                            return (
                                <div key={index} className="form-check mar-btm-5">
                                    <Checkbox
                                        onChange={this.handleUserSelect}
                                        checked={this.props.assignedToTeam || this.props.selectedResponderIds.includes(user.uuid)}
                                        value={user.uuid}
                                        disabled={this.props.assignedToTeam}
                                    >
                                        {safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                                            marginRight: "5px",
                                            display: "inline-block"
                                        })}
                                        <p style={truncate}>{safe_get(user, "first_name", "")} {safe_get(user, "last_name", "")}</p>
                                    </Checkbox>
                                </div>
                            )
                        })
                    }
                    {
                        !this.state.loading_responders &&
                        this.state.filtered_available_responders.length === 0 &&
                        <span className="zero-dark-grey">No members</span>
                    }
                </div>
            </>
        )
    }
}