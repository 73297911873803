import { safe_get } from 'other/Helper.js';
import { isLoggedIn, logoutAndCleanupState } from 'api/zero-api.js';
import { Component } from 'react';
import { connect } from 'react-redux';
import UserSignupInfo from "./UserSignupInfo.js";

import '../../../assets/css/Login.css';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.search.split("?email=").pop(),
            isInvite: false,
            isRegister: false,
            inviteCode: this.props.match.params.code,
        };
    }

    componentDidMount() {
        this.initialize();
    }

    async initialize() {
        if (isLoggedIn()) {
            await logoutAndCleanupState();
        }
        if (this.props.match.path.includes("/signup/invite/")) {
            this.setState({
                isInvite: true,
                isRegister: false,
            });
        } else if (this.props.match.path.includes("/register/")) {
            this.setState({
                isRegister: true,
                isInvite: false,
            });
        }
    }


    render() {
        return (
            <div id="container" className="cls-container auth signup">
                <div className="cls-content" style={{padding: '50px 0px 0px 0px'}}>
                    <div className="cls-content-lg panel white" style={{maxWidth: '550px'}}>
                        <div className="panel thin-border">
                            <div>
                                <div className=" pad-top">
                                    <div className="mar-ver">
                                        <h1 className="h3 zero-blue">Welcome to ZERO</h1>
                                        <p>Let's set up your account.</p>
                                    </div>
                                    <hr style={{marginBottom: '10px'}}/>
                                </div>

                                <UserSignupInfo
                                    isRegister={this.state.isRegister}
                                    isInvite={this.state.isInvite}
                                    inviteCode={this.state.inviteCode}
                                    email={this.state.email}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        kiosk_mode: safe_get(store, "app.kiosk_mode", false),
    }
}
export default connect(mapStateToProps)(SignUp);
