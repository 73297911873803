import { useEffect, useState } from "react";

const desktopSize = 1100;

export default function useIsDesktopSize() {
    const [isDesktopSize, setIsDesktopSize] = useState(window.innerWidth > desktopSize);

    const handleResize = () => {
        setIsDesktopSize(window.innerWidth > desktopSize);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return isDesktopSize;
}