import React, {Component} from 'react';
import {connect} from 'react-redux'

import NotificationItem from './NotificationItem.js'

import {formatNotifications, safe_get} from '../../other/Helper.js';
import {notifications_mark_as_read, get_notifications} from '../../api/zero-api.js'

import * as notificationsActions from '../../store/actions/NotificationsActions'

import beachImg from '../../assets/css/img/Beach.png'

// import notificationCenterImg from '../../assets/css/img/notifications.png'

class NotificationAside extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "new-notifications",
            read_notifications: [],
            unread_notifications: []
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.markAllAsRead = this.markAllAsRead.bind(this);
        this.closeAside = this.closeAside.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getNotifications();
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // clicked outside of notifications aside;
            this.props.closeAside();
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }

    getNotifications() {
        var self = this;
        get_notifications().then(function (success) {
            success.json().then(success => {

                let notifications = safe_get(success, "notifications", []);
                formatNotifications(self, notifications, self.props.user);

                let unread_notifications_counter = safe_get(success, "total_unread", 0)
                self.props.dispatch(notificationsActions.update_unread_notifications_counter(unread_notifications_counter))

            });
        }, function (error) {
            console.log(error);
        });
    }


    markAllAsRead() {
        var self = this;
        this.setState({loading: true})
        notifications_mark_as_read().then(function (success) {
            var unread = [...self.props.unread_notifications];
            var read = [...self.props.read_notifications];
            var read_notifications = unread.concat(read);
            read_notifications.sort(function (a, b) {
                return b.timestamp - a.timestamp
            });

            self.props.dispatch(notificationsActions.update_notifications([]));
            self.props.dispatch(notificationsActions.update_read_notifications(read_notifications));
            self.props.dispatch(notificationsActions.update_unread_notifications_counter(0));

            setTimeout(() => {
                self.setState({loading: false})
            }, 75);

        }, function (error) {
            self.setState({loading: false})
        });
    }

    closeAside() {
        this.props.closeAside()
    }

    render() {

        return (
            <aside id="aside-container" className={this.state.animationName} ref={this.setWrapperRef} style={{
                maxHeight: '100vh',
                position: 'fixed',
                width: (window.innerWidth > 736 ? "400px" : "255px")
            }}>
                <div id="aside" style={{paddingTop: '0px', overflowY: 'scroll', height: '95vh', paddingBottom: '40px'}}>

                    <ul className="nav nav-tabs nav-justified">
                        <li className={this.state.activeTab === "new-notifications" ? "active" : ""}>
                            <button className="ButtonLink" style={{height: "55px"}} onClick={() => {
                                this.setState({activeTab: "new-notifications"})
                            }}>
                                New{window.innerWidth > 736 ? " " : <br/>}Notifications
                            </button>
                        </li>
                        <li className={this.state.activeTab === "old-notifications" ? "active" : ""}>
                            <button className="ButtonLink" style={{height: "55px"}} onClick={() => {
                                this.setState({activeTab: "old-notifications"})
                            }}>
                                Previous Notifications
                            </button>
                        </li>
                    </ul>

                    <div className="tab-content">
                        {
                            this.state.activeTab === "new-notifications" &&
                            <div className="tab-pane fade in active" id="new-notifications">
                                {
                                    this.state.loading &&
                                    <div className="load3">
                                        <div className="loader" style={{margin: "50px auto"}}></div>
                                    </div>
                                }
                                {
                                    !this.state.loading &&
                                    <p className="pad-hor text-main text-md"
                                       style={{fontWeight: '500', marginTop: "10px", marginBottom: "2px"}}>
                                        {
                                            this.props.unread_notifications.length > 0 &&
                                            <span className="pull-right text-xs mark-as-read"
                                                  onClick={this.markAllAsRead}>
                                                Mark all as read
                                            </span>
                                        }
                                        {
                                            // (this.props.unread_notifications.length > 0 || this.props.read_notifications.length > 0) &&
                                            <span style={{color: '#03a9f4', fontWeight: '600'}}>
                                                New for you <span className="badge "
                                                                  style={{backgroundColor: '#03a9f4'}}>{this.props.unread_notifications_counter}</span>
                                            </span>
                                        }

                                    </p>
                                }
                                {/* {
                                    this.props.unread_notifications.length === 0 && this.props.read_notifications.length === 0 && !this.state.loading &&
                                    <div style={{marginTop: "20px"}}>
                                        <p className="pad-hor text-main text-md text-center" style={{fontWeight: '400', fontSize: "12px", color: "#505050"}}>
                                            Welcome to the ZERO notification center! Sit back and relax. Your notifications will be coming in soon.
                                        </p>
                                        <img src={notificationCenterImg} alt="notification-img-1" style={{width: "140px", display: "block", margin: "auto"}}/>
                                    </div>                        
                                }     */}
                                {
                                    this.props.unread_notifications.length === 0 && !this.state.loading &&
                                    <div style={{marginTop: "20px"}}>
                                        <img src={beachImg} alt="notification-img-2"
                                             style={{width: "160px", display: "block", margin: "auto"}}/>
                                        <p className="pad-hor text-main text-md text-center" style={{
                                            fontWeight: '400',
                                            fontSize: "12px",
                                            color: "#505050",
                                            marginTop: "20px"
                                        }}>
                                            Nothing new, you're all caught up!
                                        </p>
                                    </div>
                                }
                                {
                                    !this.state.loading && this.props.unread_notifications.length !== 0 &&
                                    <div className="list-group bg-trans">
                                        {
                                            this.props.unread_notifications.map((item, index) => (
                                                <NotificationItem key={"unread" + index} notification={item}
                                                                  itemIndex={index} closeAside={this.closeAside}/>
                                            ))
                                        }
                                    </div>
                                }

                            </div>
                        }

                        {
                            this.state.activeTab === "old-notifications" &&
                            <div className="tab-pane fade in active" id="old-notifications">
                                {/* {
                                    this.props.unread_notifications.length === 0 && this.props.read_notifications.length === 0 && !this.state.loading &&
                                    <div style={{marginTop: "20px"}}>
                                        <p className="pad-hor text-main text-md text-center" style={{fontWeight: '400', fontSize: "12px", color: "#505050"}}>
                                            Welcome to the ZERO notification center! Sit back and relax. Your notifications will be coming in soon.
                                        </p>
                                        <img src={notificationCenterImg} alt="notification-img-1" style={{width: "140px", display: "block", margin: "auto"}}/>
                                    </div>                        
                                }       */}
                                {
                                    this.props.read_notifications.length === 0 && !this.state.loading &&
                                    <div style={{marginTop: "20px"}}>
                                        <img src={beachImg} alt="notification-img-2"
                                             style={{width: "160px", display: "block", margin: "auto"}}/>
                                        <p className="pad-hor text-main text-md text-center" style={{
                                            fontWeight: '400',
                                            fontSize: "12px",
                                            color: "#505050",
                                            marginTop: "20px"
                                        }}>
                                            Nothing here yet! Check back soon.
                                        </p>
                                    </div>
                                }
                                <div className="list-group bg-trans">
                                    {
                                        this.props.read_notifications.map((item, index) => (
                                            <NotificationItem key={"read" + index} notification={item}
                                                              closeAside={this.closeAside}/>
                                        ))
                                    }
                                </div>
                            </div>
                        }

                    </div>


                </div>
            </aside>
        );
    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        unread_notifications: safe_get(store, "notifications.notifications", []),
        read_notifications: safe_get(store, "notifications.read_notifications", []),
        unread_notifications_counter: safe_get(store, "notifications.unread_notifications_counter", 0)
    }
}

export default connect(mapStateToProps)(NotificationAside);




