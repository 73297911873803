import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {Doughnut} from 'react-chartjs-2';

import {get_severity_level_analytics, notifyError} from '../../../api/zero-api.js'
import safe_get from '../../../other/SafeGet';

import Skeleton from 'react-loading-skeleton';

import {PieChartOutlined} from '@ant-design/icons'

import * as feedHelperActions from '../../../store/actions/FeedHelperActions';


class RiskLevelsChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {},
            loading: true,
        };

        this.elementClick = this.elementClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.date_query !== prevProps.date_query) || this.props.feed_query !== prevProps.feed_query) {
            this.getAnalytics(this.props.feed_query, this.props.date_query);
        }
    }

    componentDidMount() {
        this.getAnalytics(this.props.feed_query, this.props.date_query);
    }

    getAnalytics = (feed_query = "", date_query = "") => {
        var self = this;

        var query = "";
        if (date_query === "") {
            date_query = "&period=all_time";
        }

        if (this.props.isMyPostsFeed) {
            var filters = feed_query.split("?team_uuid=" + this.props.team_uuid);
            if (filters.length === 2) {
                filters = filters[1]
            } else {
                filters = "";
            }
            query = "?user_uuid=" + this.props.user.uuid + filters + date_query;
        } else {
            query = feed_query + date_query;
        }

        this.setState({loading: true});

        get_severity_level_analytics(query).then(function (success) {
            success.json().then(success => {
                var empty = true;
                var datapoints = success.data.datasets[0].data;
                for (var i in datapoints) {
                    if (datapoints[i] !== 0) {
                        empty = false;
                    }
                }
                self.setState({
                    data: safe_get(success, "data", {}),
                    options: safe_get(success, "options", {}),
                    from_date: safe_get(success, "from_date", ""),
                    to_date: safe_get(success, "to_date", ""),
                    subtitle: safe_get(success, "subtitle", ""),
                    is_data_empty: empty,
                    loading: false
                });
            });
        }, function (error) {
            notifyError(error);
            self.setState({loading: false});
        });
    }

    elementClick(e) {
        if (e.length > 0) {
            var obj = e[0];
            var risk_level = obj._index
            var query = `&severity_level=${risk_level}`

            // var final_query = "?team_uuid=" + this.props.team_uuid; 
            var final_query = this.props.feed_query;

            if (!final_query.includes(query)) {
                final_query += query;
            }

            if (this.props.isMyPostsFeed && !final_query.includes("my_posts_only=1")) {
                final_query += "&my_posts_only=1";
            } else if (!final_query.includes("hide_shared=1")) {
                final_query += "&hide_shared=1";
            }

            this.props.dispatch(feedHelperActions.update_feed_query(final_query));
            this.props.dispatch(feedHelperActions.update_feed_tab("posts"));
            this.props.history.push("/" + this.props.org_uuid + "/home/team/" + this.props.team_uuid + "/feed");

            // WAIT FOR DISPATCH
            setTimeout(() => {
                this.props.updateFeed();
            }, 50);
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-print-6 col-reduce-padding">
                    <div className="panel analytics-bottom-border">
                        <div className="panel-heading analytics">
                            <h2 className="panel-title analytics"
                                style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px'}}>
                                <Skeleton width={150}/>
                            </h2>
                        </div>
                        <div className="pad-all mar-btm text-center">
                            <Skeleton circle={true} height={150} width={150}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="print-avoid-page-break">
                    <div className="panel analytics-bottom-border" style={{margin: '0 0.5rem 1rem'}}>
                        <div className="panel-heading analytics">
                            <h2 className="panel-title analytics"
                                style={{display: 'inline-block', paddingRight: '0px', paddingLeft: '10px'}}>
                                <PieChartOutlined
                                    className="mar-rgt-5"/>{this.props.org.post_field_names.severity_level}
                            </h2>
                        </div>

                        {
                            !this.state.is_data_empty && !this.state.loading &&
                            <div className="pad-all mar-btm print-no-x-pad pad-top-0">
                                <Doughnut
                                    data={this.state.data}
                                    options={{
                                        ...this.state.options,
                                        maintainAspectRatio: false
                                    }}
                                    onElementsClick={this.elementClick}
                                    height={200}
                                />
                            </div>
                        }

                        {
                            this.state.is_data_empty && !this.state.loading &&
                            <div style={{padding: "8px 12px", height: "200px"}}>
                                <p className="item lg" style={{color: 'var(--zero-dark-grey)', marginBottom: "0px"}}>No
                                    posts.</p>
                            </div>
                        }
                    </div>
                </div>
            );
        }

    }
}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        org: safe_get(store, "org_helper.organization", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        feed_query: safe_get(store, "feed_helper.query", ""),
        date_query: safe_get(store, "feed_helper.date_query", "")
    }
}

export default withRouter(connect(mapStateToProps)(RiskLevelsChart));
  