import React, {Component} from 'react';
import {connect} from 'react-redux'

import * as userActions from '../store/actions/UserActions';
import * as teamsActions from '../store/actions/TeamsActions';
import * as notificationActions from '../store/actions/NotificationsActions';
import * as dashboardActions from '../store/actions/DashboardActions';

import {kiosk_login, kiosk_get_users, update_auth_token} from '../api/zero-api.js';
import {safe_get, safeProfilePic} from '../other/Helper';

import ButtonLoading from '../components/Shared/ButtonLoading';
import OfflineContext from 'components/Offline/OfflineContext.js';

import {Select, Spin, message} from 'antd';

import '../assets/css/Login.css';
import logo from '../assets/css/img/ZERO-(white)-small.png';

import $ from 'jquery';

var noDropdown = {display: "none"}
var searchTimeout = null;

const FETCH_USERS_STATES = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    ERROR: 3,
};

class Login extends Component {
    static contextType = OfflineContext;

    constructor(props) {
        super(props);

        this.state = {
            redirectRoute: "",
            user: {},
            team: {},
            selectedUser: undefined,
            users: [],
            filtered_users: [],
            img: logo,
            dropdownStyle: noDropdown,
            loading: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
        this.userSelect = this.userSelect.bind(this);

        this.fetchUsersState = FETCH_USERS_STATES.NONE;
    }

    componentDidMount() {
        this.props.dispatch(userActions.reset_user());
        this.props.dispatch(teamsActions.reset_teams());
        this.props.dispatch(notificationActions.reset_notifications());
        this.props.dispatch(dashboardActions.reset_assignments());
        
        const isOffline = this.context;
        if (!isOffline) {
            this.loadUsers();
        }
    }

    componentDidUpdate() {
        const isOffline = this.context;

        if (!isOffline && this.fetchUsersState !== FETCH_USERS_STATES.SUCCESS) {
            this.loadUsers();
        }
    }

    async loadUsers() {
        if (this.fetchUsersState === FETCH_USERS_STATES.LOADING) {
            return;
        }
        
        this.fetchUsersState = FETCH_USERS_STATES.LOADING;
        try {
            const response = await kiosk_get_users();
            const content = await response.json();
            let users = safe_get(content, "users", []).filter(user => (
                user.status === "active" &&
                user.user_uuid !== undefined &&
                user.user_uuid !== "" &&
                user.first_name !== "" &&
                user.first_name !== undefined &&
                user.last_name !== "" &&
                user.last_name !== undefined
            ));
            this.fetchUsersState = FETCH_USERS_STATES.SUCCESS;
            this.setState({users});
        } catch (err) {
            this.fetchUsersState = FETCH_USERS_STATES.ERROR;
            this.setState({usersError: "Unable to load users."});
        }
    }

    searchUsers(value) {
        var self = this;
        var users = self.state.users

        this.setState({filtered_users: [], fetching: true});

        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {

            if (value !== "") {
                var searchValue = value.toLowerCase();
                var filterList = users.filter(function (object) {
                    let name = object.first_name.toLowerCase() + " " + object.last_name.toLowerCase();
                    return (name.includes(searchValue) || object.email.includes(searchValue))
                });

                self.setState({filtered_users: filterList, fetching: false, dropdownOpen: true});
            } else {
                self.setState({filtered_users: [], fetching: false, dropdownOpen: false});
            }

        }, 200);
    }

    userSelect(value) {
        let user = [...this.state.users].find(user => user.user_uuid === value);
        var user_name = user.first_name + " " + user.last_name;
        this.setState({selectedUser: value, selected_user: user, user_name: user_name});
    }


    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.loading) {
            this.setState({loading: true});
            var self = this;
            var password = "";
            const passwordElement = $("#password");
            passwordElement ? password = passwordElement.val() : password = ""

            var body = JSON.stringify({
                user_uuid: this.state.selectedUser,
                password: password
            });

            kiosk_login(body).then(function (success) {
                success.json().then(success => {
                    var user = safe_get(success, "user", {});
                    var auth_token = safe_get(success, "jwt", undefined);

                    if (auth_token === undefined) {
                        alert("Unable to get auth token.");
                        self.setState({loading: false});
                    } else {
                        update_auth_token(auth_token);
                        setTimeout(() => {
                            self.setState({loading: false});
                            self.props.dispatch(userActions.update_current_user(user));
                            const queryParams = new URLSearchParams(window.location.search);
                            const redirect = queryParams.get('redirect');
                            if (redirect) {
                                window.location = "/?redirect=" + redirect;
                            } else {
                                window.location = "/"
                            }
                        }, 200);
                    }

                });
            }, function (error) {
                self.setState({loading: false});
                if (error.status !== 422) {
                    alert("Error: status " + error.status)
                } else {
                    var newState = {};
                    error.json().then(error => {
                        let error_messages = safe_get(error, "error_messages", []);
                        if (error_messages.length !== 0) {
                            let errorField = safe_get(error_messages[0], "field", "");
                            let errorMsg = safe_get(error_messages[0], "message", "");

                            if (errorField === "password") {
                                newState["pwError"] = errorMsg;
                                newState["emailError"] = null;
                            }
                            self.setState(newState);
                        }

                    });
                }
            });
        }


    }

    render() {

        var divStyle = {
            background: 'none',
            boxShadow: 'none',
            border: 'none',
            height: '160px'
        };

        return (
            <div id="container" className="cls-container auth">
                <div className="cls-content">
                    <div className="cls-content-sm panel img" style={divStyle}>
                        <div className="" style={{marginTop: "25px"}}>
                            {
                                this.state.selected_user &&
                                safeProfilePic(this.state.selected_user, "img-circle kiosk", "kiosk")
                            }
                            {
                                !this.state.selected_user &&
                                <img src={this.state.img} alt="ZERO Logo" style={{height: "140px"}}/>
                            }
                        </div>
                    </div>
                    <div className="cls-content-sm panel" style={{width: "95%", maxWidth: "400px"}}>
                        <div className="panel" style={{backgroundColor: "transparent"}}>
                            <div className="panel-body">

                                <form onSubmit={this.handleSubmit}>
                                    {
                                        this.state.selectedUser === undefined &&
                                        <div className="form-group">
                                            <Select
                                                mode="select"
                                                showSearch={true}
                                                showArrow={false}
                                                value={this.state.selectedUser}
                                                placeholder="Type a name..."
                                                onSearch={this.searchUsers}
                                                onChange={this.userSelect}
                                                filterOption={false}
                                                style={{
                                                    width: "100%",
                                                    fontSize: "110%"
                                                }}
                                                className="user-select-dropdown-kiosk"
                                                loading={this.state.fetching}
                                                notFoundContent={this.state.fetching ? <Spin size="small"/> : null}
                                            >
                                                {
                                                    this.state.filtered_users.map((user, index) => (
                                                        <Select.Option key={index} value={user.user_uuid}
                                                                       name={user.first_name + " " + user.last_name}>
                                                            {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                                            {user.first_name} {user.last_name} &bull; {user.email !== "" ? user.email : user.title}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    }
                                    {
                                        this.state.selectedUser !== undefined &&
                                        <div>
                                            <div className="form-group">
                                                <input id="selected-user" type="text"
                                                       className="form-control custom-placeholder mar-btm"
                                                       style={{height: "40px"}} value={this.state.user_name} disabled/>
                                                <input id="password" type="password"
                                                       className="form-control custom-placeholder"
                                                       placeholder="Password" autoFocus style={{height: "40px"}}/>
                                                {
                                                    this.state.pwError &&
                                                    <small className="error"
                                                           style={{color: "white"}}>{this.state.pwError}</small>
                                                }
                                            </div>
                                            <button className="btn btn-primary btn-lg btn-block" type="submit"
                                                    style={{marginBottom: "30px"}}>{!this.state.loading ? "Login" :
                                                <ButtonLoading/>}</button>
                                            <button className="ButtonLink" onClick={() => {
                                                this.setState({
                                                    selectedUser: undefined,
                                                    img: logo,
                                                    selected_user: undefined
                                                })
                                            }} style={{color: "white", textDecoration: "underline"}}>Not you? Go Back.
                                            </button>
                                        </div>
                                    }

                                </form>


                            </div>

                        </div>


                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
    };
}

export default connect(mapStateToProps)(Login);

