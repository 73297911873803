import React, { useMemo } from 'react';
import {Select} from 'antd';

import {actions} from './reducer';
import {safe_get} from 'other/Helper';

/**
 * 
 * @param {Array} options 
 * @param {string} idField 
 * @param {string} selectedOptionId 
 * @returns {JSX.Element[]}
 */
export function getPostSelectOptions(options, idField, selectedOptionId) {
    const visibleOptions = options.filter(opt => opt.enabled || opt[idField] === selectedOptionId);
    function getName(option) {
        let name = option.name;
        if (!option.enabled) {
            name += " (Disabled)";
        }
        return name;
    }
    return visibleOptions.map(option => (
        <Select.Option
            key={option[idField]}
            value={option[idField]}
            disabled={!option.enabled}
        >{getName(option)}</Select.Option>
    ));
}

export default function PostFormCategory({state, dispatch, postFieldNames}) {
    const selectOptions = useMemo(() => {
        return getPostSelectOptions(state.categories, "category_uuid", state.selectedCategory);
    }, [state.categories, state.selectedCategory]);

    return (
        <>
            <div className="panel-heading bulletin-post ">
                <h3 className="bulletin-post-headers required">{safe_get(postFieldNames, "category", "Category")}</h3>
            </div>
            <div className="panel-body bulletin-post">
                <Select
                    style={{maxWidth: "400px", width: "100%"}}
                    placeholder=""
                    value={state.selectedCategory}
                    onChange={newCategory => dispatch([actions.CATEGORY_CHANGED, newCategory])}
                    className="custom-bulletin"
                    virtual={false}
                    dropdownStyle={{zIndex: "999999999"}}
                    status={state.categoryError ? "error" : null}
                >
                    { selectOptions }
                </Select>
                {
                    state.categoryError &&
                    <small className="error" style={{display: "block"}}>{state.categoryError}</small>
                }
            </div>
        </>
    )
}