import { Tag } from "antd";

/**
 * 
 * @param {{
 *  color: "green" | "grey";
 *  children: any;
 *  props?: any;
 * }} props 
 * @returns {JSX.Element}
 */
export default function ZeroTag({color, children, ...props}) {
    const baseStyle = {};

    let antdColor;

    if (color === "green") {
        antdColor = "green";
        baseStyle.borderColor = "#52c41a";
    } else if (color === "grey") {
        baseStyle.color = "#505050";
    };

    const { className, style, ...restProps } = props;

    return (
        <Tag color={antdColor} className={className} style={{...baseStyle, ...(style ?? {})}} {...restProps}>{children}</Tag>
    );
}