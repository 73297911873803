import React, {useEffect} from 'react';

import {mainContentContainerClass, showFooterMobileApp, hideFooterMobileApp} from 'other/Helper.js'
import 'assets/css/Library.css';
import PostForm, {PostFormTypes} from 'components/Bulletin/PostForm';

export default function BulletinEditPost() {
    useEffect(() => {
        hideFooterMobileApp();

        return () => {
            showFooterMobileApp()
        }
    }, []);

    return (
        <div className={mainContentContainerClass()}>
            <div id="page-head" className="no-padding-mobile"/>
            <div id="page-content" className="no-padding-mobile" style={{paddingTop: "0px"}}>
                <div className="row">
                    <div className="col-lg-12 col-lg-offset-0 col-xs-12 col-xs-offset-0">
                        <PostForm formType={PostFormTypes.EDIT_POST}/>
                    </div>
                </div>
            </div>
        </div>
    );
}