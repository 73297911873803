import React, {Component} from 'react';


import {Select, Modal} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons';

import {get_list_users, update_schedule} from '../../../api/zero-api.js'
import {safeProfilePic, safe_get, isViewer, arrayIntersect} from '../../../other/Helper.js';

import NotificationAlert from '../../../other/NotificationAlert';
import ButtonLoading from '../../Shared/ButtonLoading.js';

class RespondersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingUsers: true,
            schedule_teams: this.props.schedule.teams.map(team => team.team_uuid),
            existing_responders: [],
            selected_users_uuids: [],
            available_users: [],
        };

    }

    componentDidMount() {
        this.getUsers();
        this.setUpResponders();
    }

    setUpResponders = () => {
        let assigned_to_team = safe_get(this.props, "schedule.assigned_to_team", false);
        let existing_responders = safe_get(this.props, "schedule.subscribers", []);
        let selected_users_uuids = existing_responders.map(user => user.user_uuid);

        this.setState({
            existing_responders: existing_responders,
            selected_users_uuids: assigned_to_team ? [] : selected_users_uuids,
            assigned_to_team: assigned_to_team
        });
    }

    getUsers = () => {
        var self = this
        get_list_users("?status=active").then(function (success) {
            success.json().then(success => {
                var users = safe_get(success, "users", []);

                users = users.filter(function (user) {
                    let user_teams = user.teams.map(team => team.uuid)
                    let intersect = arrayIntersect(self.state.schedule_teams, user_teams)
                    return user.status === "active" && !isViewer(user) && intersect.length > 0

                }).sort(function (a, b) {
                    let a_name = (safe_get(a, "first_name", "") + " " + safe_get(a, "last_name", "")).toLowerCase();
                    let b_name = (safe_get(b, "first_name", "") + " " + safe_get(b, "last_name", "")).toLowerCase();
                    return a_name > b_name ? 1 : -1;
                });

                self.setState({
                    all_users: users,
                    available_users: users,
                    loadingUsers: false
                });
            });
        }, function (error) {
            console.log(error)
        });
    }

    searchUsers = (inputValue, option) => {
        let input = inputValue.toLowerCase();
        let name = option.name.toLowerCase();
        let email = safe_get(option, "email", "").toLowerCase();
        return name.includes(input) || email.includes(input);
    }

    selectResponder = (value, option) => {
        let hasAllTeamMembers = false;
        let allUsersIndex = -1;
        var selected_users_uuids = [...this.state.selected_users_uuids]

        for (var i in value) {
            let uuid = value[i].key

            if (uuid === "all_team_members") {
                hasAllTeamMembers = true;
                allUsersIndex = i;
                break;
            } else if (selected_users_uuids.indexOf(uuid) < 0) {
                selected_users_uuids.push(uuid)
            }
        }

        if (hasAllTeamMembers) {
            let allmembers = value[allUsersIndex];
            value = [allmembers]
        }

        this.setState({
            selected_users: value,
            selected_users_uuids: selected_users_uuids,
            allMembersAssigned: hasAllTeamMembers
        });
    }

    removeResponder = (user, allMembers = false) => {
        var new_state = {};
        if (allMembers) {
            this.setState({
                selected_users: [],
                selected_users_uuids: [],
                existing_responders: [],
                allMembersAssigned: false,
                assigned_to_team: false
            });
        } else {
            var existing_responders = [...this.state.existing_responders];
            var responder_index = existing_responders.indexOf(user);

            if (responder_index >= 0) {
                existing_responders.splice(responder_index, 1);
                new_state["existing_responders"] = existing_responders
            }

            let user_uuid = user.user_uuid

            var selected_users_uuids = [...this.state.selected_users_uuids];
            let user_uuid_index = selected_users_uuids.indexOf(user_uuid);

            if (user_uuid_index >= 0) {
                selected_users_uuids.splice(user_uuid_index, 1);
                new_state["selected_users_uuids"] = selected_users_uuids
            }

            this.setState(new_state);
        }
    }


    assignResponders = () => {
        var self = this;

        this.setState({assigning_responders: true});

        let body = JSON.stringify({
            name: this.props.schedule.name,
            enabled: true,
            team_uuids: this.props.schedule.teams.map(team => team.team_uuid),
            form_uuid: this.props.schedule.form.form_uuid,
            user_uuids: this.state.selected_users_uuids,
            assigned_to_team: this.state.allMembersAssigned,
            repeat_period: this.props.schedule.repeat_period,
            start_date: this.props.schedule.start_date,
            custom_start_time: this.props.schedule.custom_start_time,
            custom_start_timezone: this.props.schedule.custom_start_timezone,
            end_date: this.props.schedule.end_date
        });

        update_schedule(this.props.schedule.scheduler_uuid, body).then(function (success) {
            NotificationAlert("success", "", "Schedule updated.");
            self.props.updateSchedules();
            self.props.cancel();
        }, function (error) {
            NotificationAlert("success", "", "Unable to update schedule.");
            self.props.cancel();
        });

    }

    render() {
        return (
            (<Modal
                title={`Assign Responders`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>Cancel</button>
                        <button className="btn btn-modal" onClick={this.assignResponders}>
                            {
                                this.state.assigning_responders ? <ButtonLoading/> : "Save Changes"
                            }
                        </button>
                    </div>
                }
            >
                <p className="zero-dark-grey">New responders will receive assignments in accordance with the schedule
                    settings.</p>
                <Select
                    id="invite-emails"
                    mode="multiple"
                    virtual={false}
                    value={this.state.selected_users}
                    placeholder="Type a name..."
                    onChange={this.selectResponder}
                    filterOption={this.searchUsers}
                    style={{width: '100%', display: 'block', margin: '0 auto'}}
                    className="user-select-dropdown"
                    labelInValue={true}
                    dropdownStyle={{zIndex: "10000"}}
                    loading={this.state.loadingUsers}
                    // notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                    optionLabelProp="user"
                >
                    <Select.Option value={"all_team_members"}
                                   user={<span>All Members ({this.state.available_users.length})</span>}
                                   name={"All Members"}>
                        All Members ({this.state.available_users.length})
                    </Select.Option>
                    {
                        this.state.available_users.map((user, index) => (
                            <Select.Option key={"user: " + user.uuid} value={user.uuid} user={
                                <span>{safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})} {user.first_name}</span>}
                                           name={`${user.first_name} ${user.last_name}`} email={user.email}>
                                {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                {user.first_name} {user.last_name} &bull; <span
                                style={{fontSize: '85%'}}>{user.email || user.title}</span>
                            </Select.Option>
                        ))
                    }
                </Select>
                {
                    !this.state.loadingUsers &&
                    <div>
                        <hr className="bulletin" style={{margin: "10px 0px"}}/>
                        <p className="zero-blue" style={{fontWeight: "500", marginBottom: "4px"}}>Current
                            Responders: </p>
                        <div className="">
                            {
                                this.state.existing_responders.length === 0 && !this.state.assigned_to_team &&
                                <p>No responders assigned.</p>
                            }
                            {
                                this.state.existing_responders.length > 0 && !this.state.assigned_to_team &&
                                this.state.existing_responders.map((user, index) => (
                                    <li key={index} style={{
                                        listStyle: "none",
                                        display: "inline-block",
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        padding: "3px",
                                        backgroundColor: "#F6F6F6",
                                        border: "solid 1px #ECECEC",
                                        borderRadius: "3px"
                                    }}>
                                        <div>
                                            {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                                            <div style={{
                                                color: "#1d2a35",
                                                display: "inline-block",
                                                verticalAlign: "bottom"
                                            }} className="truncate name">{user.first_name + " " + user.last_name}</div>
                                            <CloseCircleOutlined className="mar-lft-5 zero-blue"
                                                                 style={{verticalAlign: "middle"}} onClick={() => {
                                                this.removeResponder(user, false);
                                            }}/>
                                        </div>
                                    </li>
                                ))
                            }
                            {
                                this.state.assigned_to_team &&
                                <li style={{
                                    listStyle: "none",
                                    display: "inline-block",
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                    padding: "3px",
                                    backgroundColor: "#F6F6F6",
                                    border: "solid 1px #ECECEC",
                                    borderRadius: "3px"
                                }}>
                                    <div>
                                        <div style={{
                                            color: "#1d2a35",
                                            display: "inline-block",
                                            verticalAlign: "bottom"
                                        }}>All Team Members ({this.state.all_users.length})
                                        </div>
                                        <CloseCircleOutlined className="mar-lft-5 zero-blue"
                                                             style={{verticalAlign: "middle"}} onClick={() => {
                                            this.removeResponder(null, true);
                                        }}/>
                                    </div>
                                </li>
                            }

                        </div>
                        {
                            this.state.error_msg &&
                            <small className={"error"}>{this.state.error_msg}</small>
                        }
                    </div>
                }
            </Modal>)
        );

    }

}

export default RespondersModal;