import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Radio, Space, Spin, Tag } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { get_submission } from "api/zero-api";
import Button from "components/Shared/Button";
import useOrganizationId from "hooks/useOrganizationId";
import { getAttachments, syncLocalDraft } from "offline/SubmissionDraftsCache";
import { processAttachments } from "offline/utils";
import { dateFormatterWithTime } from "other/Helper";
import { useOfflineDataCachesContext } from "../OfflineDataCaches";
import useOfflineMode from "../useOfflineMode";

import { useZeroContext } from "components/ZeroContext";
import GenericDraftCard from "../GenericDraftCard";

const SubmissionDraftsListContext = React.createContext({});

function ConfirmDeleteModal({onCancel, onConfirm, isDeleting}) {
    const {insideModal} = useContext(SubmissionDraftsListContext);

    const footer = (
        <>
            <Button type="discard" onClick={onCancel} disabled={isDeleting}>Cancel</Button>
            <Button type="modal" onClick={onConfirm} loading={isDeleting}>Delete</Button>
        </>
    );

    return (
        (<Modal
            title="Confirmation"
            open={true}
            maskClosable={false}
            closable={false}
            onCancel={onCancel}
            footer={footer}
            transitionName={insideModal ? "" : "maskTransitionName"} // https://ant.design/components/modal/#How-to-disable-motion
            maskTransitionName={insideModal ? "" : "maskTransitionName"}
            mask={!insideModal}
        >Are you sure you want to delete this draft?</Modal>)
    );
}

/**
 * @param {{draft: BaseCache_Document}} param0
 * @returns
 */
function ResolveDraftErrorModal({draft, onCancel}) {
    const history = useHistory();
    const orgId = useOrganizationId();
    const {isOffline} = useOfflineMode();
    const {submissionDrafts: {cache}} = useOfflineDataCachesContext();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [versionToKeep, setVersionToKeep] = useState(null);
    const [remoteDraft, setRemoteDraft] = useState(null);
    const {insideModal} = useContext(SubmissionDraftsListContext);


    useEffect(() => {
        async function fetchData() {
            if (draft.$error === 'sync-conflict') {
                try {
                    const response = await get_submission(draft.submission_uuid);
                    const content = await response.json();
                    setRemoteDraft(content.submission);
                } catch (err) {
                    console.error('Could not load remote draft:', err);
                    setErrorMessage('Could not load remote draft at this time.');
                }
            }
        }

        fetchData();
    }, [draft.$error, draft.submission_uuid]);

    const deleteDraft = useCallback(async () => {
        try {
            setIsLoading(true);
            cache.onChangeCallbacks.push(() => {
                onCancel()
            });
            await cache.delete(draft._id, {immediate: true, includeAttachments: true});
        } catch (err) {
            console.error('Could not delete draft:', err);
            setIsLoading(false);
            setErrorMessage('Could not delete draft. Please contact support.');
        }
    }, [draft, cache, setErrorMessage, setIsLoading]);

    const retrySync = useCallback(async (event) => {
        try {
            setIsLoading(true);
            const error = await syncLocalDraft(cache, draft);
            if (error) {
                throw error;
            } else {
                cache.onChangeCallbacks.push(() => {
                    onCancel()
                });
            }
        } catch (err) {
            console.error('Could not sync draft:', err);
            setIsLoading(false);
            setErrorMessage('Could not sync draft. Please retry when connected to wifi, or contact support.');
            onCancel();
        }
    }, [draft, cache, setErrorMessage, setIsLoading]);

    const retrySubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            const error = await syncLocalDraft(cache, {...draft, $submitted: true});
            if (error) {
                const updatedDraft = await cache.get(draft._id);
                if (updatedDraft.$error === 'submit') {
                    // Submit failed, go to draft so user can try to submit
                    history.push(`/${orgId}/home/team/${draft.team.uuid}/forms/${draft.form.form_uuid}/submission/${draft.submission_uuid}`);
                } else {
                    throw error;
                }
            } else {
                onCancel();
            }
        } catch (err) {
            console.error('Could not sync draft:', err);
            setIsLoading(false);
            setErrorMessage('Could not sync draft. Please contact support.');
            onCancel();
        }
    }, [history, orgId, draft]);

    const resolveSyncConflict = useCallback(async () => {
        try {
            setIsLoading(true);

            if (versionToKeep === 'local') {
                draft.$error = null;
                await syncLocalDraft(cache, draft);
                onCancel();
            } else {
                await cache.delete(draft._id, {immediate: true, includeAttachments: true});
                cache.onChangeCallbacks.push(() => {
                    onCancel()
                });
                await cache.set(remoteDraft.submission_uuid, remoteDraft);
                const attachments = getAttachments(remoteDraft);
                await processAttachments(cache, remoteDraft.submission_uuid, attachments);
            }
        } catch (err) {
            console.error('Could not resolve sync conflict:', err);
            setIsLoading(false);
            setErrorMessage('Could not resolve sync conflict. Please contact support.');
            onCancel();
        }
    }, [draft, remoteDraft, versionToKeep, cache, setIsLoading, setErrorMessage]);

    const title = draft.$error === 'sync-conflict' ? 'Sync Conflict' : 'Sync Error';
    let buttons = [];
    let text = '';
    const retryButton = <Button key="retry" type="primary" disabled={isLoading || isOffline}
                                onClick={retrySync}>Retry</Button>
    switch (draft.$error) {
        case 'create':
            text = 'Could not create this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(retryButton);
            break;
        case 'update':
            text = 'Could not update this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(retryButton);
            break;
        case 'delete':
            text = 'Could not delete this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions';
            buttons.push(retryButton);
            break;
        case 'submit':
            text = 'Could not submit this draft. Please ensure you have a stable internet connection and click retry below. Contact support if you have any questions.';
            buttons.push(
                <Button key="goToDraft" type="primary" disabled={isLoading || isOffline}
                        onClick={retrySubmit}>Retry</Button>
            );
            break;
        case 'sync-conflict':
            text = (
                <>
                    <div>You have 2 versions of this draft (one local and one remote). Which version would you like to
                        keep?
                    </div>
                    <Radio.Group className="mar-top-10" onChange={e => setVersionToKeep(e.target.value)}
                                 value={versionToKeep}>
                        <Space direction="vertical" size={0}>
                            <Radio value="local">
                                <span className="text-semibold">Local version</span>: last edited
                                on {dateFormatterWithTime(draft.$editedAt)}
                            </Radio>
                            <Radio disabled={remoteDraft === null} value="remote">
                                <span className="text-semibold">Remote version</span>:
                                {remoteDraft ? (
                                    <> last edited on {dateFormatterWithTime(remoteDraft.edited_at)}</>
                                ) : (
                                    <Spin/>
                                )}
                            </Radio>
                        </Space>
                    </Radio.Group>
                </>
            );
            buttons.push(
                <Button key="create" type="primary" disabled={isLoading || versionToKeep === null || isOffline}
                        onClick={resolveSyncConflict}>Confirm</Button>,
            );
            break;
    }

    if (!draft.$error) {
        onCancel();
        return null;
    }

    const footer = (
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div>
                <Button type="discard" onClick={onCancel}>Cancel</Button>
                {buttons}
            </div>
        </div>
    )

    if (showDeleteConfirmation) {
        return <ConfirmDeleteModal
            onConfirm={deleteDraft}
            onCancel={() => setShowDeleteConfirmation(false)}
            isDeleting={isLoading}
        />
    }

    return (
        (<Modal
            title={title}
            open={true}
            maskClosable={false}
            closable={false}
            onCancel={onCancel}
            footer={footer}
            transitionName={insideModal ? "" : "maskTransitionName"} // https://ant.design/components/modal/#How-to-disable-motion
            maskTransitionName={insideModal ? "" : "maskTransitionName"}
            mask={!insideModal}
        >
            <div>{text}</div>
            {isOffline &&
                <>
                    <br/>
                    <div>You're offline, please cancel and try again later.</div>
                </>
            }
            {errorMessage &&
                <small className="error">{errorMessage}</small>
            }
        </Modal>)
    );
}

/**
 * @param {{draft: BaseCache_Document}} param0
 * @returns
 */
function StatusTag({draft, setShowResolveDraftErrorModal}) {
    let text = 'In Sync';
    let color = null;
    let style = {
        color: 'var(--zero-dark-grey)'
    };

    if (draft.$error) {
        switch (draft.$error) {
            case 'sync-conflict':
                text = (
                    <span className="link-hover" onClick={() => setShowResolveDraftErrorModal(true)}>
                        <InfoCircleOutlined className="mar-rgt-5"/>Sync Conflict
                    </span>
                );
                color = 'red';
                style = null;
                break;
            case 'delete':
            case 'create':
            case 'update':
            case 'submit':
                text = (
                    <span className="link-hover" onClick={() => setShowResolveDraftErrorModal(true)}>
                        <InfoCircleOutlined className="mar-rgt-5"/>Sync Error
                    </span>
                );
                color = 'orange';
                style = {
                    borderColor: '#FA8C13'
                };
                break;
            default:
                text = 'Unknown Error';
                color = 'red';
                style = null;
        }
    } else if (draft.$submitted) {
        text = 'Submit Pending';
        color = 'green';
        style = null;
    } else if (draft.$updated) {
        text = 'Sync Required';
    } else if (draft.$offline) {
        text = 'Offline Only';
        color = 'blue';
        style = null;
    }

    return (
        <Tag style={style} color={color}>{text}</Tag>
    )
}

/**
 * @param {{draft: BaseCache_Document, onDelete: function}} param0
 * @returns
 */
function DraftCard({draft, onDelete}) {
    const history = useHistory();
    const editedAt = draft.$editedAt || draft.edited_at;
    const organizationUuid = useSelector(state => state.org_helper.organization.organization_uuid);
    const [showDeleteModal, _setShowDeleteModal] = useState(false);
    const [showResolveDraftErrorModal, _setShowResolveDraftErrorModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const {showingModalForDraftId, setShowingModalForDraftId} = useContext(SubmissionDraftsListContext);
    const {isSyncing, submissionDrafts } = useOfflineDataCachesContext();

    const draftUrl = `/${organizationUuid}/home/team/${draft.team.uuid}/forms/${draft.form.form_uuid}/submission/${draft._id}`;

    const setShowDeleteModal = (value) => {
        _setShowDeleteModal(value);
        setShowingModalForDraftId(value ? draft.id : null);
    }
    
    const setShowResolveDraftErrorModal = (value) => {
        _setShowResolveDraftErrorModal(value);
        setShowingModalForDraftId(value ? draft.id : null);
    }

    const deleteDraft = useCallback(() => {
        setIsDeleting(true);
        onDelete(draft._id);
        setTimeout(() => setShowDeleteModal(false), 500);
    }, [setIsDeleting, setShowDeleteModal, onDelete, draft._id]);

    if (showingModalForDraftId && showingModalForDraftId !== draft._id) {
        return null;
    }

    function editDraftClickHandler() {
        if (['sync-conflict', 'create'].includes(draft.$error)) {
            setShowResolveDraftErrorModal(true);
        } else {
            if (draft.$submitted) {
                submissionDrafts.cache.updateDraft(draft._id, {$submitted: false}).then(() => {
                    history.push(draftUrl);
                })
            } else {
                history.push(draftUrl);
            }
        }
    }

    return (
        <>
            {showDeleteModal &&
                <ConfirmDeleteModal
                    onCancel={() => setShowDeleteModal(false)}
                    onConfirm={deleteDraft}
                    isDeleting={isDeleting}
                />
            }
            {showResolveDraftErrorModal &&
                <ResolveDraftErrorModal
                    draft={draft}
                    onCancel={() => setShowResolveDraftErrorModal(false)}
                />
            }
            { showingModalForDraftId === null &&
                <GenericDraftCard
                    title={draft.form.name}
                    titleLink={(draft.$error || draft.$submitted || isSyncing) ? null : draftUrl}
                    subtitle1={<>Saved submission in {draft.team.name}</>}
                    subtitle2={<>Last edited on {dateFormatterWithTime(editedAt)}</>}
                    tag={<StatusTag draft={draft} setShowResolveDraftErrorModal={setShowResolveDraftErrorModal}/>}
                    editAction={{
                        handler: editDraftClickHandler,
                        disabled: isSyncing
                    }}
                    deleteAction={{
                        handler: () => {setShowDeleteModal(true)},
                        disabled: isSyncing
                    }}
                />
            }
        </>
    );
}

export default function SubmissionDraftsList({insideModal = false}) {
    const {submissionDrafts: {regularDrafts: drafts, cache}, formAssignmentsCache} = useOfflineDataCachesContext();
    const [showingModalForDraftId, setShowingModalForDraftId] = useState(null);
    const {services: {forms: formsService}} = useZeroContext();

    const deleteDraft = useCallback(async (id) => {
        const draft = drafts.find(d => d._id === id);
        if (!draft) {
            return;
        }

        await formsService.deleteDraft(draft._id, draft.assignment_uuid, draft.form.form_type);
    }, [cache, drafts, formAssignmentsCache]);

    const contextValue = {
        insideModal,
        showingModalForDraftId,
        setShowingModalForDraftId: insideModal ? setShowingModalForDraftId : () => {},
    };

    return (
        <SubmissionDraftsListContext.Provider value={contextValue}>
            {
                drafts.length > 0 ? (
                    drafts.map(draft => (
                        <DraftCard key={draft._id} draft={draft} onDelete={deleteDraft} />
                    ))
                ) : (
                    <div className="text-center zero-dark-grey">No drafts saved to device.</div>
                )
            }
        </SubmissionDraftsListContext.Provider>
    );
}