import React, {Component} from 'react';
import {connect} from 'react-redux'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Switch} from 'antd';

import {safe_get} from '../../../../other/Helper.js'
import DelayedTextInput from 'components/Shared/DelayedTextInput.js';
import DragIcon from 'components/Shared/DragIcon.js';

class StatusComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item_text: safe_get(this.props, "item.name", ""),
            item_enabled: safe_get(this.props, "item.enabled", false),
        };

        this.onSwitch = this.onSwitch.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.updateItem = this.updateItem.bind(this);
    }

    onSwitch(checked) {
        this.setState({item_enabled: checked}, () => {
            this.updateItem();
        });
    }

    inputChange(value) {
        this.setState({item_text: value}, () => {
            this.updateItem();
        });
    }


    updateItem() {
        var item = {
            name: this.state.item_text,
            enabled: this.state.item_enabled
        }

        if (this.props.isStatus) {
            item["sub_status_uuid"] = this.props.item.sub_status_uuid
        } else if (this.props.isInjuryCause) {
            item["incident_cause_uuid"] = this.props.item.incident_cause_uuid
        } else if (this.props.isEventType) {
            item["event_type_uuid"] = this.props.item.event_type_uuid
        } else if (this.props.isIncidentInjury) {
            item["incident_injury_uuid"] = this.props.item.incident_injury_uuid
        }

        this.props.updateMade(item)
    }

    render() {
        const isIncidentField = this.props.isIncidentInjury || this.props.isInjuryCause || this.props.isEventType;
        const maxLength = isIncidentField ? 60 : 30;

        const inputClassList = ['form-control']
        if (isIncidentField) inputClassList.push('incident-field');
        else inputClassList.push('topic-field');

        return (
            <div
                className="hover-parent"
                style={{marginBottom: "5px", display: 'flex', alignItems: 'center'}}
            >
                <DelayedTextInput
                    id={"status-input-" + this.props.index}
                    className={inputClassList.join(' ')}
                    defaultValue={this.state.item_text}
                    onChange={this.inputChange}
                    maxLength={maxLength}
                />

                <Switch
                    checked={this.state.item_enabled}
                    onChange={this.onSwitch}
                    style={{marginTop: '0px', marginLeft: '10px', float: 'none'}}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />

                { this.props.isReorderable &&
                    <DragIcon />
                }

            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        feed_categories: safe_get(store, "feed_helper.categories", [])

    }
}

export default connect(mapStateToProps)(StatusComponent);