import React, {Component} from 'react';

import {Modal} from 'antd';

import {safe_get} from '../../other/Helper.js';
import {getPostViewers, getArticleViewers} from '../../api/zero-api.js'

import UserViews from './UserViews.js';
import LoadingIndicator from './LoadingIndicator.js';

class PostViewsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: "",
            viewers: [],
            loading_viewers: true,
        };

        this.getViewers = this.getViewers.bind(this);
    }

    componentDidMount() {
        this.getViewers(this.props.post_uuid);
    }

    getViewers = (post_uuid) => {
        var self = this;

        if (this.props.isLibrary) {
            getArticleViewers(post_uuid).then(function (success) {
                success.json().then(success => {
                    var viewers = safe_get(success, "viewers", []);
                    viewers.sort(function (a, b) {
                        return a.viewed_at - b.viewed_at
                    });
                    self.setState({viewers: viewers, loading_viewers: false});
                });
            }, function (error) {
                console.log(error);
            });
        } else {
            getPostViewers(post_uuid).then(function (success) {
                success.json().then(success => {
                    var viewers = safe_get(success, "viewers", []);
                    viewers.sort(function (a, b) {
                        return a.viewed_at - b.viewed_at
                    });
                    self.setState({viewers: viewers, loading_viewers: false});
                });
            }, function (error) {
                console.log(error);
            });
        }

    }

    render() {
        let count = this.state.viewers.length;
        return (
            (<Modal
                title={"Viewed " + count + (count === 1 ? " time" : " times")}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                footer={null}
                maskTransitionName="maskTransitionName"
            >
                {
                    this.state.loading_viewers &&
                    <LoadingIndicator/>
                }
                {
                    !this.state.loading_viewers &&
                    <div className="views-modal" style={{overflowX: "hidden"}}>
                        {
                            !this.props.articleViews &&
                            this.state.viewers.map((user, index) => (
                                <UserViews
                                    key={index}
                                    user={user.user}
                                    currentTeam={this.props.current_team}
                                    index={index}
                                    showTimeStamp={true}
                                    viewedAt={user.viewed_at}
                                />
                            ))
                        }
                        {
                            this.props.articleViews &&
                            this.state.viewers.map((user, index) => (
                                <UserViews
                                    key={index}
                                    user={user.user}
                                    currentTeam={this.props.current_team}
                                    index={index}
                                    showTimeStamp={true}
                                    viewedAt={user.viewed_at}
                                    removeUser={this.removeUser}/>
                            ))
                        }
                    </div>
                }
            </Modal>)
        );

    }

}

export default PostViewsModal;