import React, {useState} from 'react';

const defaultImgStyle = {
    objectFit: 'contain',
    marginBottom: "0px",
    padding: "0px",
    borderRadius: "2px",
    backgroundColor: '#f6f6f6',
    border: "1px solid #e5e5e5",
    maxWidth: "100%",
    maxHeight: "100%",
};

export default function VideoThumbnail({
                                           imgSrc = "",
                                           imgAlt = "",
                                           imgProps = {},
                                           imgWidth = 100,
                                           imgHeight = 100,
                                           onPlay = () => {
                                           }
                                       }) {
    const [imgLoadingError, setImgLoadingError] = useState(false);

    return (
        <>
            <div
                className="print-only print-display-block"
                style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "2px",
                    backgroundColor: "#f6f6f6",
                    border: "1px solid #e5e5e5"
                }}
            >
                {!imgLoadingError &&
                    <img src={imgSrc} style={{objectFit: 'contain', width: "100%", height: "100%"}}/>
                }
            </div>
            <div className="overlay dont-print" style={{width: imgWidth, height: imgHeight}}>
                {!imgLoadingError &&
                    <img
                        className="thumbnail"
                        src={imgSrc}
                        style={defaultImgStyle}
                        width={imgWidth}
                        height={imgHeight}
                        alt={imgAlt}
                        onError={() => {
                            setImgLoadingError(true);
                        }}
                        {...imgProps}
                    />
                }
                <span
                    onClick={onPlay}
                    className={`playWrapper ${imgLoadingError ? "imgError" : ""}`}
                />
            </div>
        </>
    )
}
