import { FileUploadContextManager, FileUploadNoContext, useFileUploadContext } from './FileUpload';
import { ZeroQuill } from './ZeroQuill';


function ButtonAreaWithContext({buttonArea}) {
    const ctx = useFileUploadContext();

    if (typeof buttonArea === 'function') {
        return buttonArea(ctx);
    }

    return buttonArea;
}


export default function AttachmentCommentBox({
    textValue,
    onTextChange,
    attachments = [],
    commentError = null,
    buttonArea = null,
    onPreview = () => {},
    onDelete = () => {},
    generateParamsCallback,
    onUploadSuccess,
    onUploadError,
    customUpload,
}) {
    return (
        <FileUploadContextManager
            attachments={attachments}
            generateParamsCallback={generateParamsCallback}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            customUpload={customUpload}
        >
            <ZeroQuill
                value={textValue}
                onChange={onTextChange}
            />
            { commentError &&
                <small className="error">{commentError}</small>
            }
            <div className="mar-top-5">
                <FileUploadNoContext
                    onPreview={onPreview}
                    onDelete={onDelete}
                />
            </div>
            { buttonArea && <ButtonAreaWithContext buttonArea={buttonArea} /> }
        </FileUploadContextManager>
    )
}