import React, {Component, useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import safe_get from '../../other/SafeGet.js';
import {
    mainContentContainerClass,
    dateFormatterWithTime,
    scrollToTop,
    isObjEmpty,
    isAdmin,
    isAdminOrTeamLead,
    shouldFormFieldDisplay
} from '../../other/Helper.js';

import {
    get_submission,
    delete_submission,
    update_team_cookie,
    link_post_to_field,
    getSubmissionComments,
    updateSubmissionStatus
} from '../../api/zero-api.js';

import {IoIosMore as MoreIcon} from 'react-icons/io';
import {Menu, Dropdown, Modal, Collapse, Tabs} from 'antd';
import {EditOutlined, DeleteOutlined, CloudDownloadOutlined, CloseCircleOutlined} from '@ant-design/icons';

import Skeleton from 'react-loading-skeleton'

import ConfirmModal from '../Shared/ConfirmModal.js';
import NotificationAlert from '../../other/NotificationAlert';

import FormFieldsRenderer from './FormFieldsRenderer'

import ExportSubmissionModal from "./ExportSubmissionModal";
import SubmissionFieldCommentModal from './SubmissionFieldCommentModal'
import FailedFieldsModal from './FailedFieldsModal.js';

import '../../assets/css/form-builder.css'
import SubmissionTimeline from './SubmissionTimeline.js';
import StatusSelect from 'components/Shared/StatusSelect.js';
import { generateFormPages } from 'other/forms.js';
import { FormType } from 'other/Constants.js';

/** @type {StatusSelectOption[]} */
const submissionStatusOptions = [
    {
        value: "submitted",
        label: "Submitted",
        color: "grey",
    },
    {
        value: "in_review",
        label: "In Review",
        color: "yellow",
    },
    {
        value: "closed",
        label: "Closed",
        color: "green",
    },
];

function SubmissionStatusSelect({submission, onStatusUpdated}) {
    const [status, setStatus] = useState(submission.status);
    const [updating, setUpdating] = useState(false);

    const updateStatusHandler = useCallback(async (newStatus) => {
        if (!updating) {
            setStatus(newStatus);
            setUpdating(true);
            const body = JSON.stringify({status: newStatus});

            try {
                const response = await updateSubmissionStatus(submission.submission_uuid, body);
                const data = await response.json();
                if (safe_get(data, 'submission.status', newStatus) !== newStatus) {
                    setStatus(data.submission.status);
                }
                onStatusUpdated();
            } catch (err) {
                console.error(err);
                NotificationAlert("error", "", "Could not update status.");
                setStatus(submission.status);
            } finally {
                setUpdating(false);
            }
        }
        setStatus(newStatus);
    }, [submission, onStatusUpdated, updating, setUpdating]);

    return (
        <StatusSelect
            print
            value={status}
            options={submissionStatusOptions}
            disabled={updating}
            onChange={updateStatusHandler}
        />
    )
}

class SubmissionView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submission_loading: true,
            submission: {},
            sub_fields: [],
            pages: [],
            page: 0,
            activeKeys: [],
            loadingComments: true,
            comments: [],
            commentError: false,
        };

    }

    componentDidUpdate(prevProps) {
        scrollToTop("page-head");
        if (this.props.match.params.team_uuid !== prevProps.match.params.team_uuid) {
            update_team_cookie(this.props.match.params.team_uuid);
            this.getSubmission();
        } else if (this.props.match.params.submission_uuid !== prevProps.match.params.submission_uuid) {
            this.getSubmission();
        }
    }

    componentDidMount() {
        scrollToTop("page-head");

        if (window.location.search.includes('multi_page=false')) {
            this.setState({multi_page_override: true});
        }

        window.addEventListener('beforeprint', this.handlePrintStart);
        window.addEventListener('afterprint', this.handlePrintEnd);

        this.getSubmission();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeprint', this.handlePrintStart);
        window.removeEventListener('afterprint', this.handlePrintEnd);
    }

    handlePrintStart = () => {
        if (!this.state.multi_page_override) {
            let activeKeys = this.state.pages.map((_page, index) => index)
            this.setState({multi_page_override: true, activeKeys: activeKeys});
            // this.initFormFields(this.state.submission.form.fields);
        }
    }

    handlePrintEnd = () => {
        if (!window.location.search.includes('multi_page=false')) {
            this.setState({multi_page_override: false});
            // this.initFormFields(this.state.submission.form.fields);
        }
    }

    getSubmission = () => {
        var self = this;
        this.setState({submission_loading: true});

        let sub_uuid = this.props.match.params.submission_uuid;
        get_submission(sub_uuid).then(function (success) {
            success.json().then(success => {
                var submission = safe_get(success, "submission", {});
                self.setState({
                    submission: submission,
                }, () => {
                    self.initFormFields(submission.form.fields);
                });
            });
        }, function (error) {
            self.setState({submissionNotFound: true, submission_loading: false});
        });

        this.getComments();
    }

    getComments = () => {
        let sub_uuid = this.props.match.params.submission_uuid;

        getSubmissionComments(sub_uuid)
        .then(res => res.json())
        .then(data => {
            this.setState({loadingComments: false, commentError: false, comments: data});
        })
        .catch(err => {
            this.setState({loadingComments: false, commentError: true});
        })
    }

    initFormFields = (fields) => {
        const pages = generateFormPages(fields);

        this.setState({
            pages,
            activeKeys: pages.map((_page, index) => `${index}`),
            submission_loading: false
        })
    }

    onChangePanels = (key) => {
        this.setState({activeKeys: key});
    }

    calculatePageScore = (page) => {
        let total_points = 0;
        let scored_points = 0;

        let answers = safe_get(this.state, "submission.fields", [])
        let form_fields = page;

        answers.forEach(answer => {
            let field = form_fields.find(function (obj) {
                return obj.id === answer.id
            });

            if (!field) {
                return
            }

            let answer_val = answer.value;

            if ((field.is_scored || field.audit) && answer_val.length > 0 && answer_val[0] && shouldFormFieldDisplay(field, answers)) {

                let score = 0;
                if (field.element === "Checkboxes") {
                    answer_val.forEach(element => {

                        let answer_score = safe_get(field.options.find(function (obj) {
                            return obj.key === element;
                        }), "score", 0);

                        score += answer_score;
                    });
                } else {
                    if (field.audit) {
                        const option = field.options.find(obj => obj.key === answer_val[0])
                        if (option) {
                            score = safe_get(option, "score", null);

                            if (score === null) {
                                const text = safe_get(option, "text", "");
                                if (text === 'YES') {
                                    score = 1;
                                } else if (text === 'NO') {
                                    score = 0;
                                }
                            }
                        }
                    } else {
                        score = safe_get(field.options.find(obj => obj.key === answer_val[0]), "score", 0);
                    }
                }

                if (!(field.audit && answer_val[0] === "radiobuttons_option_na") && this.shouldShowFieldScore(field, answer_val)) {
                    total_points += field.points;
                }

                scored_points += score;
            }

        });

        if (total_points > 0) {
            let final_score = Math.floor((scored_points / total_points) * 100);
            if (!isNaN(final_score)) {
                return `${scored_points}/${total_points} (${final_score}%)`
            }
        } else {
            return ""
        }

    }

    shouldShowFieldScore = (field, answer) => {
        let shouldShow = false;

        answer.forEach(answer_val => {
            let option = field.options.find(obj => {
                return obj.key === answer_val
            });

            if (option) {
                shouldShow = true;
            }
        });

        return shouldShow;
    }

    optionsMenuRender = () => {
        const submission = this.state.submission;
        const userOwnsSubmission = this.props.user.uuid === submission?.created_by?.user_uuid;
        const restrictUserEdits = submission?.form?.restrict_user_edits ?? false;
        const userCanEditOwnSubmission = !restrictUserEdits || isAdminOrTeamLead(this.props.user);

        // can edit if user created this submission and restrict user edits is disabled, OR is an admin or team lead
        const submissionEditIsEnabled = (userOwnsSubmission && userCanEditOwnSubmission) || isAdminOrTeamLead(this.props.user);
        // can delete if user created this submission and restrict user edits is disabled, OR is an admin
        const submissionDeleteIsEnabled= (userOwnsSubmission && userCanEditOwnSubmission) || isAdmin(this.props.user);

        const canExport = userOwnsSubmission || isAdminOrTeamLead(this.props.user);

        return (
            {
                onClick: this.handleTableMenuClick,
                items: [
                    canExport && {
                        key: "export_submission",
                        icon: <CloudDownloadOutlined/>,
                        label: "Export",
                    },
                    {
                        key: "edit_submission",
                        icon: <EditOutlined/>,
                        label: "Edit",
                        disabled: !submissionEditIsEnabled,
                    },
                    {
                        key: "delete_submission",
                        icon: <DeleteOutlined/>,
                        label: "Delete",
                        className: submissionDeleteIsEnabled ? "zero-delete-red" : "",
                        disabled: !submissionDeleteIsEnabled,
                    },
                ].filter(item => item !== false)
            }
        )
    }

    handleTableMenuClick = (e) => {
        if (e.key === "delete_submission") {
            this.setState({
                showDeleteSubmissionModal: true,
            });
        } else if (e.key === "edit_submission") {
            let submission = this.state.submission;
            const formType = submission.form.form_type === FormType.LMS ? "courses" : "forms";
            this.props.history.push(`/${this.props.org_uuid}/home/team/${submission.team.uuid}/${formType}/${submission.form.form_uuid}/submission/${submission.submission_uuid}?edit_submission=true`)
        } else if (e.key === 'export_submission') {
            this.setState({showExportSubmissionModal: true})
        }

    }

    deleteSubmission = () => {
        var self = this;
        let formy_type = safe_get(this.state, "submission.form.form_type", "")
        if (formy_type === 3) {
            formy_type = 'courses';
        } else {
            formy_type = 'forms';
        }
        delete_submission(this.state.submission.submission_uuid).then(function (succcess) {
            NotificationAlert("success", "", "Submission deleted.");
            self.props.history.push(`/${self.props.org_uuid}/home/team/my_teams/${formy_type}`);
        }, function (error) {
            NotificationAlert("success", "", "Unable to delete submission.");
            self.setState({showDeleteSubmissionModal: false});
        });
    }

    linkPostToField = (post_uuid, field_id) => {
        if (this.state.submission.submission_uuid) {
            link_post_to_field(this.state.submission.submission_uuid, JSON.stringify({
                post_uuid,
                field_id
            })).catch(() => {
                NotificationAlert("error", "", "Unable to link post to comment.");
            })
        }
    }

    render() {
        let shared_teams_names = safe_get(this.state, "submission.shared_teams", []).map(e => e.name);

        if (this.state.submission_loading) {
            return (
                <div className={mainContentContainerClass()}>
                    <div id="page-head" className="no-padding-mobile">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                <div id="page-title" style={{padding: '0px'}}>
                                    <div className="panel thin-border"
                                         style={{margin: '0 auto', display: 'flex', alignItems: 'center'}}>
                                        <div className="nav-header-panel"
                                             style={{width: "100%", display: "inline-block"}}>
                                            <div className="team-name-header">
                                                <h3 className={"section-titles admin-page-header"}
                                                    style={{margin: "0px"}}>
                                                    <Skeleton width={400}/>
                                                </h3>
                                            </div>
                                            <p className="header" style={{color: "#505050", marginBottom: "0px"}}>
                                                <Skeleton/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="page-content" className="no-padding-mobile">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">

                                <div className="panel thin-border" style={{margin: "0px", marginBottom: "10px"}}>
                                    <div className="panel no-padding-print top-margin-print"
                                         style={{padding: "10px", margin: "0px"}}>
                                        <div className="row justify-content-start">

                                            <div className="col-xs-4 no-padding-print">
                                                <p className="text-sm text-bold zero-blue text-center"
                                                   style={{margin: "5px"}}>
                                                    <Skeleton width={100}/>
                                                </p>
                                                <div className="text-lg text-center" style={{cursor: "pointer"}}>
                                                    <Skeleton width={65} height={58}/>
                                                </div>
                                            </div>

                                            <div className="col-xs-4 no-padding-print">
                                                <p className="text-sm text-bold zero-blue text-center"
                                                   style={{margin: "5px"}}>
                                                    <Skeleton width={130}/>
                                                </p>
                                                <div className="text-lg text-center" style={{cursor: "pointer"}}>
                                                    <Skeleton width={58} height={58}/>
                                                </div>
                                            </div>

                                            <div className="col-xs-4 no-padding-print">
                                                <p className="text-sm text-bold zero-blue text-center"
                                                   style={{margin: "5px"}}>
                                                    <Skeleton width={130}/>
                                                </p>
                                                <div className="text-lg text-center" style={{cursor: "pointer"}}>
                                                    <Skeleton width={58} height={58}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className={"panel thin-border"}>
                                    <div className="panel-body pad-15">
                                        <Skeleton height={35} width={300}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={150}/>
                                        <br/>
                                        <Skeleton height={40}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={250}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={250}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15} circle/>
                    </span>
                                        <Skeleton width={100}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={200}/>
                                        <br/>
                                        <Skeleton height={55}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={250}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>
                                        <br/>
                                        <span style={{marginRight: 5}}>
                      <Skeleton height={15} width={15}/>
                    </span>
                                        <Skeleton width={100}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={200}/>
                                        <br/>
                                        <Skeleton height={30}/>

                                        <br/>
                                        <br/>

                                        <Skeleton width={200}/>
                                        <br/>
                                        <Skeleton height={30}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                (<div className={mainContentContainerClass() + ' print-7in'}>
                    {
                        this.state.submissionNotFound &&
                        <Modal
                            title={<span><CloseCircleOutlined style={{marginRight: "5px", color: "#f56946"}}/>Submission not found</span>}
                            open={this.state.submissionNotFound}
                            maskClosable={false}
                            closable={false}
                            onCancel={this.props.cancel}
                            maskTransitionName="maskTransitionName"
                            footer={
                                <div style={{height: "33px"}}>
                                    <button className="btn btn-modal" onClick={() => {
                                        this.props.history.push("/" + this.props.org_uuid + "/home/team/my_teams/forms");
                                    }}>
                                        Ok
                                    </button>
                                </div>
                            }
                        >
                            <p className="zero-dark-grey">This submission cannot be found. It was likely deleted.</p>
                        </Modal>
                    }
                    {
                        this.state.showDeleteSubmissionModal &&
                        <ConfirmModal
                            show={this.state.showDeleteSubmissionModal}
                            cancel={() => {
                                this.setState({showDeleteSubmissionModal: false, edit_schedule: undefined});
                            }}
                            title={"Confirmation"}
                            body={"Are you sure you want to delete this submission?"}
                            confirmButtonName={"Delete submission"}
                            confirm={this.deleteSubmission}
                        />
                    }
                    {
                        this.state.showCommentsModal &&
                        <SubmissionFieldCommentModal
                            show={this.state.showCommentsModal}
                            cancel={() => {
                                this.setState({showCommentsModal: false});
                            }}
                            submission_uuid={this.state.submission.submission_uuid}
                            goToPost={this.goToPost}
                        />
                    }
                    {
                        this.state.showFailedFieldsModal &&
                        <FailedFieldsModal
                            show={this.state.showFailedFieldsModal}
                            cancel={() => {
                                this.setState({showFailedFieldsModal: false});
                            }}
                            submission_uuid={this.state.submission.submission_uuid}
                            goToPost={this.goToPost}
                        />
                    }
                    {
                        this.state.showExportSubmissionModal &&
                        <ExportSubmissionModal
                            show={this.state.showExportSubmissionModal}
                            submission_uuid={this.state.submission.submission_uuid}
                            cancel={() => {
                                this.setState({showExportSubmissionModal: false});
                            }}
                        />
                    }
                    <div id="page-head" className="no-padding-mobile no-padding-print">
                        <div className="row">
                            <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                                <div id="page-title" style={{padding: '0px'}}>
                                    <div className="panel thin-border" style={{margin: '0 auto'}}>

                                        <div className="nav-header-panel no-padding-print">
                                            <div className="team-name-header" style={{display: "flex", justifyContent: "space-between"}}>
                                                <h3 className={"section-titles admin-page-header"}
                                                    style={{display: "inline-block", margin: "0px"}}>
                                                    {safe_get(this.state, "submission.form.name", "")}
                                                </h3>
                                                <SubmissionStatusSelect
                                                    submission={this.state.submission}
                                                    onStatusUpdated={() => {
                                                        this.getComments();
                                                    }}
                                                />
                                            </div>
                                            <p className="header"
                                                style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                                Submitted by {safe_get(this.state, "submission.created_by.first_name", "")} {safe_get(this.state, "submission.created_by.last_name", "")} on {dateFormatterWithTime(safe_get(this.state, "submission.created_at", undefined))}.
                                                Last edited
                                                by {safe_get(this.state, "submission.edited_by.first_name", "")} {safe_get(this.state, "submission.edited_by.last_name", "")} on {dateFormatterWithTime(safe_get(this.state, "submission.edited_at", undefined))}.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="page-content" className="no-padding-mobile no-padding-print"
                         style={{paddingTop: "10px", paddingLeft: "15px", paddingRight: "15px"}}>
                        <div className="row">
                            <div className="tab-base col-reduce-padding" style={{marginBottom: "10px"}}>

                                <div className="panel thin-border" style={{margin: "0px", marginBottom: "10px"}}>
                                    <div className="panel no-padding-print top-margin-print"
                                         style={{padding: "10px", margin: "0px"}}>
                                        <div className="row justify-content-start">

                                            <div className="col-xs-4 no-padding-print">
                                                <p className="text-sm text-bold zero-blue text-center"
                                                   style={{margin: "5px"}}>
                                                    Score
                                                </p>
                                                <div className="text-lg">
                                                    <p className="text-thin text-main zero-blue text-center mar-btm-0 "
                                                       style={{fontSize: "3em", paddingLeft: "10px"}}>
                                                        {this.state.submission.score}{!isNaN(this.state.submission.score) &&
                                                        <span style={{fontSize: "30%"}}>%</span>}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-xs-4 no-padding-print">
                                                <p className="text-sm text-bold zero-blue text-center"
                                                   style={{margin: "5px"}}>Failed Items</p>
                                                <div className="text-lg" style={{cursor: "pointer"}} onClick={() => {
                                                    this.setState({showFailedFieldsModal: true});
                                                }}>
                                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                       style={{fontSize: "3em"}}>
                                                        {safe_get(this.state, "submission.failed_items_count", undefined) ? this.state.submission.failed_items_count : "0"}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-xs-4 no-padding-print">
                                                <p className="text-sm text-bold zero-blue text-center"
                                                   style={{margin: "5px"}}>Comments</p>
                                                <div className="text-lg" style={{cursor: "pointer"}} onClick={() => {
                                                    this.setState({showCommentsModal: true});
                                                }}>
                                                    <p className="text-thin text-main zero-blue text-center mar-btm-0"
                                                       style={{fontSize: "3em"}}>
                                                        {safe_get(this.state, "submission.comment_count", 0)}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className="panel thin-border">
                                    <div className={"panel-body pad-15"}>
                                        <Tabs
                                            defaultActiveKey="submission"
                                            tabBarExtraContent={
                                                <div
                                                    className="more dont-print"
                                                    style={{display: "inline-block", lineHeight: 0, height: 'fit-content', color: "grey"}}
                                                >
                                                    <Dropdown
                                                        menu={this.optionsMenuRender()}
                                                        trigger={['click']}
                                                        placement="bottomRight"
                                                    >
                                                        <button className="ButtonLink ant-dropdown-link">
                                                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                                                        </button>
                                                    </Dropdown>
                                                </div>
                                            }
                                        >
                                            <Tabs.TabPane tab="Submission" key="submission">
                                                {
                                                    !isObjEmpty(this.state.submission) && this.state.pages.length > 1 &&
                                                    <Collapse className="form-collapse" activeKey={this.state.activeKeys}
                                                            onChange={this.onChangePanels}>
                                                        {
                                                            this.state.pages.map((page, index) => (
                                                                <Collapse.Panel key={index} header={`Page ${index + 1}`}
                                                                                extra={this.calculatePageScore(page)}>
                                                                    <FormFieldsRenderer
                                                                        form_uuid={safe_get(this.state, "submission.form.form_uuid", "")}
                                                                        submission_uuid={safe_get(this.state, "submission.submission_uuid", "")}
                                                                        form_fields={safe_get(this.state, "submission.form.fields", [])}
                                                                        field_answers={safe_get(this.state, "submission.fields", [])}
                                                                        pages={this.state.pages}
                                                                        page={index}
                                                                        saveDraft={() => {
                                                                        }}
                                                                        isAnalytics={false}
                                                                        isSubmissionView={true}
                                                                        linkPostToField={this.linkPostToField}
                                                                    />
                                                                </Collapse.Panel>
                                                            ))
                                                        }
                                                    </Collapse>
                                                }
                                                {
                                                    !isObjEmpty(this.state.submission) && this.state.pages.length === 1 &&
                                                    <FormFieldsRenderer
                                                        form_uuid={safe_get(this.state, "submission.form.form_uuid", "")}
                                                        submission_uuid={safe_get(this.state, "submission.submission_uuid", "")}
                                                        form_fields={safe_get(this.state, "submission.form.fields", [])}
                                                        field_answers={safe_get(this.state, "submission.fields", [])}
                                                        pages={this.state.pages}
                                                        page={0}
                                                        saveDraft={() => {
                                                        }}
                                                        isAnalytics={false}
                                                        isSubmissionView={true}
                                                        linkPostToField={this.linkPostToField}
                                                    />
                                                }
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab="Activity" key="activity">
                                                <SubmissionTimeline
                                                    loading={this.state.loadingComments}
                                                    error={this.state.commentError}
                                                    comments={this.state.comments}
                                                    submission={this.state.submission}
                                                />
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {
                            safe_get(this.state, "submission.reference_number", "") !== "" &&
                            <div style={{margin: '0 auto', maxWidth: '700px', paddingLeft: '5px'}}>
                                <p id="postidnumber" className="text-md text-main text-bold pad-top text-center"
                                   style={{color: '#BCBABC'}}>Submission ID
                                    Number: {safe_get(this.state, "submission.reference_number", "")}</p>
                            </div>
                        }
                    </div>
                </div>)
            );
        }

    }

}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", "")
    }
}

export default withRouter(connect(mapStateToProps)(SubmissionView));
