import React, {Component} from 'react';
import {connect} from 'react-redux'

import {Link} from 'react-router-dom';

import {Modal, Select} from 'antd';

import Skeleton from 'react-loading-skeleton'

import NotificationAlert from '../../../other/NotificationAlert.js';
import ButtonLoading from '../../Shared/ButtonLoading';

import {get_users_in_team, remove_user_from_team} from '../../../api/zero-api.js'
import {safe_get, safeProfilePic, isAdmin, isTeamLead, isContributor, isViewer} from '../../../other/Helper'
import {updateTeamsOnMembershipChange} from "store/helpers/teams";

import '../../../assets/css/antd-custom.css'

class TeamMembersModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            canRemove: false,
            loadingUsers: true,
        };
    }

    componentDidMount() {
        this.getUsers();
        let userTeams = this.props.teams.map(x => x.uuid);
        let canRemove = isAdmin(this.props.user) || userTeams.includes(this.props.team.uuid);
        this.setState({canRemove: canRemove})
    }

    getUsers = () => {
        var self = this;
        get_users_in_team(this.props.team.uuid, "?status=active").then(success => {
            success.json().then(success => {

                let users = safe_get(success, "users", []).sort(function (a, b) {
                    let a_name = (safe_get(a, "first_name", "") + " " + safe_get(a, "last_name", "")).toLowerCase();
                    let b_name = (safe_get(b, "first_name", "") + " " + safe_get(b, "last_name", "")).toLowerCase();

                    return a_name > b_name ? 1 : -1;
                });

                self.setState({
                    users: users,
                    loadingUsers: false
                });
            });
        });
    }

    removeUser = (user, userIndex) => {
        let self = this;

        let users = [...this.state.users];
        users.splice(userIndex, 1);

        let body = JSON.stringify({
            user_uuid: user.uuid
        });

        remove_user_from_team(this.props.team.uuid, body).then(success => {
            NotificationAlert("success", "", "Team members updated.");
            self.setState({users});
            updateTeamsOnMembershipChange(
                self.props.user,
                self.props.teams,
                self.props.team,
                users.map(u => u.uuid),
                self.props.dispatch
            )
            self.props.callback();
        }, error => {
            NotificationAlert("success", "", "Unable to remove user.");
            self.props.cancel();
        });

    }

    render() {
        return (
            (<Modal
                title={`Team Members (${this.state.users.length})`}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        {/* <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button> */}
                        <button className="btn btn-modal" onClick={this.props.cancel} disabled={this.state.updating}>
                            {
                                this.state.updating ? <ButtonLoading/> : "Done"
                            }
                        </button>
                    </div>
                }
            >
                <div className="views-modal" style={{overflowX: "hidden"}}>
                    {
                        this.state.loadingUsers &&
                        [0, 0, 0, 0].map((_, index) => (
                            <div key={index} className="panel panel-light panel-colorful user-card-size thin-border"
                                 style={{maxHeight: '80px'}}>
                                <div style={{padding: "10px"}}>
                                    <div className="media">
                                        <div className="media-left profile-img">
                                            <Skeleton circle={true} width={65} height={65}/>
                                        </div>
                                        <div className="media-body account-card " style={{verticalAlign: 'middle'}}>
                                            <Skeleton width={150}/>
                                            <br/>
                                            <Skeleton width={100}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        !this.state.loadingUsers &&
                        this.state.users.map((user, index) => (
                            <div key={index} className="panel panel-light panel-colorful user-card-size thin-border"
                                 style={{maxHeight: '80px'}}>
                                <div style={{padding: "10px"}}>
                                    <div className="media">
                                        <div className="media-left profile-img">
                                            <Link to={"/" + this.props.org_uuid + "/users/" + user.uuid}>
                                                {safeProfilePic(user, "img-sm img-circle views", "views")}
                                            </Link>
                                        </div>
                                        <div className="media-body account-card " style={{verticalAlign: 'middle'}}>

                                            <Link className="zero-blue"
                                                  to={"/" + this.props.org_uuid + "/users/" + user.uuid}
                                                  style={{lineHeight: "1em"}}>
                                                <p className="text-md text-semibold profile truncate name zero-blue"
                                                   style={{
                                                       display: 'inline-block',
                                                       marginBottom: '0px',
                                                       marginLeft: '2px',
                                                       maxWidth: "145px"
                                                   }}>
                                                    {user.first_name} {user.last_name}
                                                </p>
                                            </Link>
                                            {
                                                isAdmin(user) ?
                                                    <p className="role-text zero-dark-grey" style={{fontSize: "0.9em"}}>
                                                        <span className="badge badge-success badge-icon badge-fw "
                                                              style={{margin: "2px 5px 5px 2px",}}></span>Admin</p> :
                                                    isTeamLead(user) ? <p className="role-text zero-dark-grey"
                                                                          style={{fontSize: "0.9em"}}><span
                                                            className="badge badge-info badge-icon badge-fw "
                                                            style={{margin: "2px 5px 5px 2px"}}></span>Lead</p> :
                                                        isViewer(user) ? <p className="role-text zero-dark-grey"
                                                                            style={{fontSize: "0.9em"}}><span
                                                                className="badge badge-danger badge-icon badge-fw"
                                                                style={{margin: "2px 5px 5px 2px"}}></span>Viewer</p> :
                                                            isContributor(user) ?
                                                                <p className="role-text zero-dark-grey"
                                                                   style={{fontSize: "0.9em"}}><span
                                                                    className="badge badge-warning badge-icon badge-fw"
                                                                    style={{margin: "2px 5px 5px 2px"}}></span>Contributor
                                                                </p> :
                                                                <p className="role-text zero-dark-grey"
                                                                   style={{fontSize: "0.9em"}}><span
                                                                    className="badge badge-purple badge-icon badge-fw "
                                                                    style={{margin: "2px 5px 5px 2px"}}></span>Member
                                                                </p>
                                            }
                                        </div>
                                        {
                                            this.state.canRemove &&
                                            <div className="media-right pad-0" style={{verticalAlign: "middle"}}>
                                                <button className="btn btn-delete" onClick={() => {
                                                    this.removeUser(user, index);
                                                }}>Remove
                                                </button>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
    }
}

export default connect(mapStateToProps)(TeamMembersModal);